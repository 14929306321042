import { AriaAttributes } from "react";
import {
  ASCENDING,
  DESCENDING,
  SortType,
  UNSORTED,
} from "../components/Table/SortButton";

export const ariaSort = (sortType: SortType): AriaAttributes["aria-sort"] => {
  switch (sortType) {
    case UNSORTED:
      return "other";
    case ASCENDING:
      return "ascending";
    case DESCENDING:
      return "descending";
  }
};
