import { Icon, IconButton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Table } from "src/components/Table/Table";
import { UploadBoundaryConfigForm } from "../schemas";

type BoundaryGroupConfigFile = {
  filename: string;
  fileType: string;
  fieldName: string;
  setFieldValue: Function;
};

const boundaryGroupColumns: ColumnDef<BoundaryGroupConfigFile>[] = [
  {
    id: "file",
    accessorFn: (row) => {
      return row.filename.split(".")[0];
    },
  },
  {
    id: "type",
    accessorKey: "fileType",
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const { fieldName, setFieldValue } = row.original;
      return (
        <IconButton
          aria-label="Delete configuration file"
          icon={<Icon as={RiDeleteBin6Line} />}
          variant="outline"
          onClick={() => {
            setFieldValue(fieldName, new File([], "new.json"));
          }}
        />
      );
    },
  },
];

/*
 * Displays Formik form data (file uploads) as table rows.
 *
 * In the Boundary Group create flow the uploaded files are represented as table rows.
 * The selected geojson and connections files can be changed during this create flow.
 * Nothing is saved or uploaded until the form is submitted.
 */
export const BoundaryConfigUploadTable = () => {
  const { values, setFieldValue } =
    useFormikContext<UploadBoundaryConfigForm>();

  const rows = useMemo(
    () => transformValuesIntoRows(values, setFieldValue),
    [values, setFieldValue]
  );

  return <Table columns={boundaryGroupColumns} data={rows} />;
};

function transformValuesIntoRows(
  values: Record<string, File>,
  setFieldValue: Function
) {
  const { geojson, connections } = values;

  const rows = [];

  if (geojson && geojson.size > 0) {
    rows.push({
      filename: geojson.name,
      fileType: "GeoJSON",
      fieldName: "geojson",
      setFieldValue,
    });
  }

  if (connections && connections.size > 0) {
    rows.push({
      filename: connections.name,
      fileType: "Connections",
      fieldName: "connections",
      setFieldValue,
    });
  }

  return rows;
}
