import {
  Button,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import { useQueryParams } from "src/hooks/useQueryParams";
import * as breadcrumb from "src/services/breadcrumb";
import { HasuraRole } from "src/types/hasuraRole";
import { ScheduledExportsList } from "./exports/ScheduledExportsList";
import {
  DataServiceTab,
  DataServiceType,
  DEFAULT_DATA_SERVICE_TYPE,
  useCreateDataService,
} from "./useCreateDataService";
import { WebhookList } from "./webhooks/List";

export const DataServices = () => {
  const organization = useOrganization();
  const { tab: tabParam } = useQueryParams();
  const [selectedServiceType, setSelectedServiceType] =
    React.useState<DataServiceType>(DEFAULT_DATA_SERVICE_TYPE);
  const { services, onClick, dialog } =
    useCreateDataService(selectedServiceType);

  const onTabChange = (tabIndex: number) => {
    const service = services[tabIndex];
    if (service) setSelectedServiceType(service.type);
  };

  const initTabIndex = React.useMemo(
    () =>
      Math.max(
        0,
        services.findIndex((s) => s.tab === tabParam)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabParam]
  );

  useEffect(() => {
    onTabChange(initTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTabIndex]);

  const getPanelTab = (tab: DataServiceTab) => {
    if (tab === "API Credentials")
      return <Text>API Credentials - Under Construction</Text>;

    if (tab === "Webhooks") {
      return <WebhookList doCreateDataService={onClick}></WebhookList>;
    }

    if (tab === "Scheduled exports") {
      return <ScheduledExportsList />;
    }

    return <></>;
  };

  return (
    <Flex direction="column" rowGap={6}>
      <Breadcrumb
        items={breadcrumb.dataServices.getBreadcrumbsForList(organization)}
      />
      <HStack justifyContent="space-between">
        <Heading as="h1">Data services</Heading>

        <HStack justifyContent="flex-end">
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ORG_ADMIN, HasuraRole.ADMIN]}
          >
            <WithUserPermissions permissions={["data_services:create"]}>
              <Button
                colorScheme="primary"
                as={Button}
                size="xl"
                onClick={onClick}
              >
                Create data service
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
        </HStack>
      </HStack>
      <Tabs defaultIndex={initTabIndex} isLazy onChange={onTabChange}>
        <TabList>
          {services.map((service) => (
            <Tab key={service.tab} value={service.tab}>
              {service.tab}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {services.map((service) => (
            <TabPanel key={service.tab}>{getPanelTab(service.tab)}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {dialog}
    </Flex>
  );
};
