export function getLocalStorageRecord<T>(key: string): T | null {
  const value = localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      localStorage.removeItem(key);
    }
  }

  return null;
}

export function saveLocalStorageRecord<T>(
  key: string,
  payload: T | null
): void {
  if (payload) {
    localStorage.setItem(key, JSON.stringify(payload));
  } else {
    localStorage.removeItem(key);
  }
}
