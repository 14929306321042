import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { extractQuestionProps } from "src/scenes/parent/forms/components/Inputs/FormQuestion";
import {
  ClonedQuestion,
  CustomQuestion,
  EmailType,
  FreeTextType,
  PhoneNumberType,
  SingleSelectType,
  WithId,
} from "src/types/formTemplate";
import { Applicant } from "src/types/formTemplateFilters";
import {
  GetCustomQuestion_question_by_pk_custom_question_custom_question_type,
  GetSelectedPersonAnswerBank,
  GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank,
  custom_question_answer_bank_relationship_constraint,
  custom_question_answer_bank_relationship_update_column,
  person_answer_bank_insert_input,
  person_answer_insert_input,
} from "src/types/graphql";
import { formatQueriedAnswerBankRecords } from "../api/helpers";
import {
  CloneIdsGroupedBySourceIds,
  mapCloneIdsBySourceIds,
} from "../context/helpers";
import {
  mapFormAnswersToBankAnswers,
  transformAnswerBankFieldsToValues,
} from "../helpers";
import { AnswerBankOption, AnswerBankRecord } from "../list/schemas/AnswerBank";
import * as GQL from "src/types/graphql";

type FormatAvelaQuestionAsProperties = {
  applicant: Applicant;
  formId: uuid;
  fieldQuestions: ClonedQuestion<WithId>[];
};

export function formatCustomQuestionTypeFieldAsProperties(
  props: FormatAvelaQuestionAsProperties
) {
  const { applicant, formId, fieldQuestions } = props;

  return fieldQuestions.map((question) => {
    return extractQuestionProps(question, formId, applicant, false);
  });
}

export function formatEmptyInitialValues(fieldIds: uuid[]) {
  const emptyInitialValues = fieldIds.reduce((accumulator, currentFieldId) => {
    accumulator.set(currentFieldId, "");
    return accumulator;
  }, new Map());
  return Object.fromEntries(emptyInitialValues);
}

export function formatInitialValues(
  answerBankOption: AnswerBankOption,
  mapping: CloneIdsGroupedBySourceIds
): CustomQuestionAnswersByQuestionId {
  const formValues = transformAnswerBankFieldsToValues(answerBankOption.fields);
  return mapFormAnswersToBankAnswers(formValues, mapping);
}

export function getSelectedAnswerBankAsFormAnswer(
  customQuestionData: {
    customQuestion: CustomQuestion<WithId>;
    customQuestionType: GetCustomQuestion_question_by_pk_custom_question_custom_question_type;
  } | null,
  bankAnswerData: GetSelectedPersonAnswerBank
): {
  bankAnswer: CustomQuestionAnswersByQuestionId;
  answerBankId: string;
} | null {
  if (!customQuestionData) return null;

  const sourceIdCloneIdMapping = mapCloneIdsBySourceIds(
    customQuestionData.customQuestionType,
    customQuestionData.customQuestion.nestedQuestions
  );

  const { custom_question_answer_bank_relationship } = bankAnswerData;
  const answerBankQueried =
    custom_question_answer_bank_relationship[0]?.person_answer_bank;
  if (!answerBankQueried) return null;
  const bankAnswer = mapSelectedAnswerBankEntryToFormAnswer(
    answerBankQueried,
    sourceIdCloneIdMapping
  );
  const answerBankId = answerBankQueried.id;

  return {
    bankAnswer,
    answerBankId,
  };
}

function mapSelectedAnswerBankEntryToFormAnswer(
  answerBankQueried: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank,
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds
): CustomQuestionAnswersByQuestionId {
  const answerBankRecord: AnswerBankRecord = formatQueriedAnswerBankRecords(
    [answerBankQueried], // function expects a list; this is a shortcut to reuse the function
    sourceIdCloneIdMapping
  )[0]!; // TODO: remove ! assertion
  return transformAnswerBankFieldsToValues(answerBankRecord.fields);
}

type FormatInsertPersonAnswerBankPayload = {
  answersByQuestionId: CustomQuestionAnswersByQuestionId;
  customQuestionTypeId: uuid;
  fieldQuestions: ClonedQuestion<WithId>[];
  personId: uuid;
  customQuestionRelationshipProps?: {
    formId: uuid;
    questionId: uuid;
  };
};

export function formatInsertPersonAnswerBankPayload(
  props: FormatInsertPersonAnswerBankPayload
): person_answer_bank_insert_input {
  const {
    answersByQuestionId,
    customQuestionTypeId,
    fieldQuestions,
    personId,
  } = props;
  return {
    custom_question_type_id: customQuestionTypeId,
    last_used_at: new Date().toISOString(),
    person_id: personId,
    person_answers: {
      data: fieldQuestions.map((field) => {
        return formatInsertPersonAnswerPayload(field, answersByQuestionId);
      }),
    },
  };
}

function formatInsertPersonAnswerPayload(
  field: ClonedQuestion<WithId>,
  answersByQuestionId: CustomQuestionAnswersByQuestionId
): person_answer_insert_input {
  const { id, type } = field;
  const questionResponse = answersByQuestionId[id];

  switch (type) {
    case FreeTextType:
    case EmailType:
    case PhoneNumberType:
      return {
        custom_question_type_field_id: id,
        answer: questionResponse,
      };
    case SingleSelectType:
      // handle optional SingleSelect fields (where a form_question_option is not selected)
      const optionWasNotSelected =
        questionResponse === undefined || questionResponse === "";

      return {
        custom_question_type_field_id: id,
        person_answer_options: {
          data: optionWasNotSelected
            ? []
            : [{ form_question_option_id: questionResponse }],
        },
      };
    default: {
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
    }
  }
}

type FormatInsertPersonAnswersWithAnswerBankIdPayload = {
  answerBankId: uuid;
  answersByQuestionId: CustomQuestionAnswersByQuestionId;
  fieldQuestions: ClonedQuestion<WithId>[];
};

export function formatInsertPersonAnswersWithAnswerBankIdPayload(
  props: FormatInsertPersonAnswersWithAnswerBankIdPayload
): person_answer_insert_input[] {
  const { answerBankId, answersByQuestionId, fieldQuestions } = props;
  return fieldQuestions.map((field) => ({
    person_answer_bank_id: answerBankId,
    ...formatInsertPersonAnswerPayload(field, answersByQuestionId),
  }));
}

type FormatInsertPersonAnswerBankWithCustomQuestionRelationshipPayload =
  FormatInsertPersonAnswerBankPayload & {
    formId: uuid;
    questionId: uuid;
  };

export function formatInsertPersonAnswerBankWithCustomQuestionRelationshipPayload(
  props: FormatInsertPersonAnswerBankWithCustomQuestionRelationshipPayload
): GQL.person_answer_bank_insert_input {
  const { formId, questionId } = props;
  return {
    ...formatInsertPersonAnswerBankPayload(props),
    custom_question_answer_bank_relationships: {
      data: [
        {
          form_id: formId,
          custom_question_id: questionId,
        },
      ],
      on_conflict: {
        constraint:
          custom_question_answer_bank_relationship_constraint.custom_question_answer_bank_rela_form_id_custom_question_id_key,
        update_columns: [
          custom_question_answer_bank_relationship_update_column.person_answer_bank_id,
        ],
      },
    },
  };
}
