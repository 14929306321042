import * as GQL from "src/types/graphql";

const EXCLUDED_FORM_STATUSES = [
  GQL.form_status_enum.Cancelled,
  GQL.form_status_enum.InProgress,
];

type FormSchoolRank = {
  form: { status: GQL.form_status_enum };
  offers: { status: GQL.offer_status_enum }[];
  waitlists: { status: GQL.waitlist_status_enum }[];
};

type FormSchool = {
  school: { id: uuid } | null;
};

export function canAddToWaitlists(
  formSchoolRanks: FormSchoolRank[],
  formSchools: FormSchool[]
): boolean {
  if (formSchools.some((appSchool) => !appSchool.school)) {
    return false;
  }

  return formSchoolRanks.every((rank) => {
    if (EXCLUDED_FORM_STATUSES.includes(rank.form.status)) return false;

    if (
      GQL.form_status_enum.Admissions === rank.form.status &&
      (rank.offers[0]?.status === GQL.offer_status_enum.Offered ||
        rank.offers[0]?.status === GQL.offer_status_enum.Accepted ||
        rank.waitlists[0]?.status === GQL.waitlist_status_enum.Waitlisted)
    )
      return false;

    return true;
  });
}
