import { CalendarIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  extendTheme,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { toZonedTime } from "date-fns-tz";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// This is needed so that the date and time pickers don't collapse into one column
const dateTimePickerTheme = extendTheme({
  styles: {
    global: {
      ".react-datepicker-popper": {
        width: "max-content",
      },
    },
  },
});

type DateTimePickerProps = {
  timezoneName: string;
  initialValue?: string; // date time in UTC as ISO string
  onChange?: (date: Date | null) => void;
};

// Date time picker using react-datepicker with Chakra UI styling
export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  timezoneName,
  initialValue,
  onChange,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialValue ? toZonedTime(new Date(initialValue), timezoneName) : null
  );

  // Filter out past times so only future times can be selected
  const filterPastTime = (time: Date) => {
    const currentDate = toZonedTime(new Date(), timezoneName);
    const targetDate = new Date(time);
    return currentDate.getTime() < targetDate.getTime();
  };

  return (
    <ChakraProvider theme={dateTimePickerTheme}>
      {/* VStack and w="auto" on InputGroup are needed for the InputRightElement to appear within the Input box */}
      {/* Use higher zIndex so that the react-datepicker popover appears on top */}
      <VStack alignItems="start" zIndex={1} position="relative">
        <InputGroup w="auto">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              onChange && onChange(date);
            }}
            customInput={<Input w="auto" />}
            showTimeSelect
            filterTime={filterPastTime}
            dateFormat="MM/dd/yyyy, hh:mm aa"
            placeholderText="Select an option"
          />
          <InputRightElement pointerEvents="none">
            <CalendarIcon fontSize="sm" />
          </InputRightElement>
        </InputGroup>
      </VStack>
    </ChakraProvider>
  );
};
