import { PropsWithChildren } from "react";
import { Freeze } from "react-freeze";
import { useOutlet } from "react-router-dom";

/**
 * Hide and preserve the state of a parent component tree in a nested Route.
 * @example
 *   <Route
 *     path={parentPath}
 *     element={<PreserveState>{parentContent}</PreserveState>}
 *   >
 *     <Route path={nestedPath} element={childContent} />
 *   </Route>
 */
export function PreserveState({ children }: PropsWithChildren) {
  const outlet = useOutlet();
  return (
    <>
      {outlet}
      <Freeze freeze={!!outlet}>{children}</Freeze>
    </>
  );
}
