import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { useMemo } from "react";
import { BsChevronExpand } from "react-icons/bs";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import * as GQL from "src/types/graphql";
import { z } from "zod";

export const UNSORTED = "unsorted";
export const ASCENDING = "asc";
export const DESCENDING = "desc";

export type SortType = typeof UNSORTED | typeof ASCENDING | typeof DESCENDING;

export function parseSortType(value: unknown): SortType | undefined {
  const result = z
    .union([z.literal(UNSORTED), z.literal(ASCENDING), z.literal(DESCENDING)])
    .safeParse(value);
  if (result.success) {
    return result.data;
  }

  return undefined;
}

export function fromGQL(sortType: GQL.order_by): SortType {
  switch (sortType) {
    case GQL.order_by.asc:
    case GQL.order_by.asc_nulls_first:
    case GQL.order_by.asc_nulls_last:
      return "asc";

    case GQL.order_by.desc:
    case GQL.order_by.desc_nulls_first:
    case GQL.order_by.desc_nulls_last:
      return "desc";

    default:
      const _exhaustiveCheck: never = sortType;
      return _exhaustiveCheck;
  }
}

interface SortButtonProps {
  onChange: (headerId: string, sortType: SortType) => void;
  headerId: string;
  headerLabel: string;
  sort?: SortType;
}

export const SortButton = ({
  onChange,
  headerId,
  sort,
  headerLabel,
}: SortButtonProps) => {
  const iconForSort = useMemo(() => {
    if (sort === UNSORTED) {
      return <Icon as={BsChevronExpand} />;
    } else if (sort === DESCENDING) {
      return <Icon as={RiArrowDownSLine} />;
    } else if (sort === ASCENDING) {
      return <Icon as={RiArrowUpSLine} />;
    }
  }, [sort]);

  const handleChange = () => {
    if (sort === UNSORTED) {
      onChange(headerId, ASCENDING);
    } else if (sort === ASCENDING) {
      onChange(headerId, DESCENDING);
    } else {
      onChange(headerId, UNSORTED);
    }
  };

  return (
    <Button
      aria-label="Change sort order"
      rightIcon={iconForSort}
      onClick={handleChange}
      variant="ghost"
      size="xs"
      backgroundColor={
        sort === UNSORTED || sort === undefined ? "none" : "gray.100"
      }
      borderRadius="300px"
    >
      {headerLabel.toUpperCase()}
    </Button>
  );
};
