import { Box, Flex, FlexProps, Link, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { InputControl } from "formik-chakra-ui";
import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormikAddressBook } from "src/components/Inputs/Address/Book";
import { StudentFormAvatar } from "src/components/Layout/Avatar";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { StudentFormType } from "src/schemas/Student";
import * as Url from "src/services/url";
import { NameForm } from "./NameForm";

type Props = FlexProps & {
  editMode?: boolean;
  student?: {
    first_name: string;
    last_name: string;
  };
};

export const StudentForm: FunctionComponent<Props> = (props) => {
  const { editMode = false, student, ...flexProps } = props;

  const organization = useOrganization();
  const formik = useFormikContext<StudentFormType>();

  return (
    <Flex direction="column" align="left" gap={3} width="100%" {...flexProps}>
      <Flex alignItems="center" direction="column" gap={3}>
        <StudentFormAvatar student={formik.values} />
      </Flex>
      <NameForm />
      <Box>
        <InputControl
          inputProps={{
            type: "date",
            isDisabled: editMode,
          }}
          id="birth_date"
          name="birth_date"
          label="Date of birth"
          isRequired={true}
          labelProps={{ requiredIndicator: <></> }}
        />
        {editMode && (
          <Box marginTop={1.5}>
            <Text color="gray.600" fontWeight="400" fontSize="sm">
              Need to change {student?.first_name} {student?.last_name}'s birth
              date?
            </Text>
            <Link
              marginTop={0}
              as={RouterLink}
              to={organization
                .map((org) => Url.Parent.help(org))
                .withDefault("#")}
              fontWeight="400"
              fontSize="sm"
            >
              Click here for help
            </Link>
          </Box>
        )}
        {!editMode && (
          <Box marginTop={1.5}>
            <Text color="gray.600" fontWeight="400" fontSize="sm">
              <Glossary>
                Birth date can't be changed after adding a student. Some grades
                may have birth date cutoffs.
              </Glossary>
            </Text>
          </Box>
        )}
      </Box>
      <FormikAddressBook fieldName="address" addressIsRequired />
    </Flex>
  );
};
