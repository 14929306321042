import React from "react";
import { Props as UseGlossaryProps, useGlossary } from "src/hooks/useGlossary";

type Props = { children: string } & Partial<UseGlossaryProps>;
export const Glossary: React.FC<Props> = ({ children, ...props }) => {
  let useGlossaryProps: UseGlossaryProps | undefined;
  if (props.enrollmentPeriodId !== undefined) {
    useGlossaryProps = { enrollmentPeriodId: props.enrollmentPeriodId };
  } else if (props.organizationId !== undefined) {
    useGlossaryProps = { organizationId: props.organizationId };
  } else {
    useGlossaryProps = undefined;
  }

  const { glossary } = useGlossary(useGlossaryProps);
  return <>{glossary`${children}`}</>;
};
