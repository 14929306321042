import Immutable from "immutable";
import React from "react";
import * as State from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/statusesState";
import * as Draft from "../../types/statusesDraft";
import {
  Change,
  FormTemplateId,
  FormTemplateIdType,
  hasChanges
} from "../../types/common";
import { StatusDescriptionsFormType } from "./StatusDescriptionsForm";

const LocalStorageStatusesKey = "statuses";

export type StatusesInitialValues = {
  statuses: StatusDescriptionsFormType;
};

export type Action =
  | {
      type: "UpdateStatuses";
      statuses: Draft.StatusesDraft;
      formTemplateId: uuid;
    }
  | { type: "Load"; json: string }
  | {
      type: "Refresh";
      initialValues: StatusesInitialValues;
      formTemplateId: uuid;
    }
  | { type: "Save"; formTemplateId: uuid }
  | { type: "SaveComplete" }
  | { type: "Reset"; formTemplateId: uuid }
  | { type: "ResetComplete" };

export function reducer(state: State.State, action: Action): State.State {
  switch (action.type) {
    case "Save":
      return state.set("save", action.formTemplateId);

    case "SaveComplete":
      return state.set("save", undefined);

    case "Load":
      return load(state, action.json);

    case "Reset":
      return reset(state, action.formTemplateId);

    case "ResetComplete":
      return state.set("reset", undefined);

    case "UpdateStatuses":
      return updateStatuses(state, action.statuses, action.formTemplateId);

    case "Refresh":
      return refresh(action.initialValues, action.formTemplateId);

    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

function reset(state: State.State, formTemplateId: uuid): State.State {
  const statuses = state.get("statuses").map(clearChange);

  return state.set("statuses", statuses).set("reset", formTemplateId);
}

function clearChange<T, U>(change: Change<T, U>): Change<T, U> {
  return { ...change, draft: undefined };
}

function updateStatuses(
  state: State.State,
  draft: Draft.StatusesDraft,
  formTemplateId: uuid
): State.State {
  const originalStatusesState = state
    .get("statuses")
    .get(FormTemplateId(formTemplateId));
  if (!originalStatusesState) {
    return state;
  }

  const original = originalStatusesState.original;
  const hasChangesResult = hasChanges(original, draft);

  const updatedState: State.State = state.update("statuses", (statuses) =>
    statuses.set(FormTemplateId(formTemplateId), {
      ...originalStatusesState,
      draft: hasChangesResult ? draft : undefined
    })
  );

  return updatedState;
}

function toState(
  initialValues: StatusesInitialValues,
  formTemplateId: uuid
): State.State {
  const statuses: [FormTemplateIdType, State.StatusesChange][] = [
    [
      FormTemplateId(formTemplateId),
      { original: initialValues.statuses, draft: undefined }
    ]
  ];

  return State.NewState({
    statuses: Immutable.Map(statuses)
  });
}

function refresh(
  initialValues: StatusesInitialValues,
  formTemplateId: uuid
): State.State {
  return reset(toState(initialValues, formTemplateId), formTemplateId);
}

export function useFormTemplateStatusesProcessor(
  initialValues: StatusesInitialValues,
  formTemplateId: uuid
) {
  const initialState = toState(initialValues, formTemplateId);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const json = loadFromLocalstorage(formTemplateId);
    dispatch({
      type: "Load",
      json
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state.get("save")) {
      saveToLocalstorage(state.set("save", undefined), formTemplateId);
      dispatch({ type: "SaveComplete" });
    }

    if (state.get("reset")) {
      clearLocalstorage(formTemplateId);
      dispatch({ type: "ResetComplete" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return { state, dispatch };
}

function loadFromLocalstorage(formTemplateId: uuid): string {
  return (
    localStorage.getItem(`${formTemplateId}-${LocalStorageStatusesKey}`) ?? "{}"
  );
}

function load(state: State.State, json: string): State.State {
  return State.deserialize(state, JSON.parse(json));
}

function saveToLocalstorage(state: State.State, formTemplateId: uuid): void {
  const stateWithoutOriginal = State.serialize(state);
  localStorage.setItem(
    `${formTemplateId}-${LocalStorageStatusesKey}`,
    JSON.stringify(stateWithoutOriginal)
  );
}

function clearLocalstorage(formTemplateId: uuid): void {
  localStorage.removeItem(`${formTemplateId}-${LocalStorageStatusesKey}`);
}
