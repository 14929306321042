import { AddressSchema, AddressValidator } from "src/schemas/Address";
import {
  BASE_INITIAL_PARENT_FORM,
  ParentSchema,
  ParentValidator,
} from "src/schemas/Parent";
import { emptyStringAsNull } from "src/services/zodExtra";
import { z } from "zod";

export const ParentProfileSchema = ParentSchema.extend({
  reference_id: emptyStringAsNull(z.string()),
}).merge(AddressSchema);

export const ParentProfileValidator = ParentValidator.extend({
  reference_id: emptyStringAsNull(z.string()),
}).merge(AddressValidator);

export const EmailRequiredParentProfileValidator =
  ParentProfileValidator.extend({
    email_address: z.string().min(1, "Required"),
    phone_number: z.string(),
  });

export const PhoneRequiredParentProfileValidator =
  ParentProfileValidator.extend({
    phone_number: z.string().min(12, "Required"),
    email_address: z.string(),
  });

export type ParentProfileFormType = z.infer<typeof ParentProfileSchema>;

export const INITIAL_PARENT_FORM: ParentProfileFormType = {
  ...BASE_INITIAL_PARENT_FORM,
  reference_id: "",
  street_address: "",
  street_address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
};
