import { Box, Flex, Icon, IconButton, Image, Spacer } from "@chakra-ui/react";
import { RiMenuLine } from "react-icons/ri";
import { useDrawerNav } from "src/components/Navigation/DrawerNav";
import { usePreferredLanguage } from "src/hooks/usePreferredLanguage";
import { WeglotSelect } from "src/plugins/weglot";

type Props = { logo?: { url: string; alt?: string } };

export function Header({ logo }: Props) {
  const { drawerNav, onOpen } = useDrawerNav();
  const preferredLanguageSetting = usePreferredLanguage();

  return (
    <Flex
      as="header"
      width="100%"
      height="100%"
      background="primary.500"
      alignItems="center"
      padding={3}
      gap={3}
      borderBottom="1px solid"
      borderColor="whiteAlpha.200"
    >
      <IconButton
        icon={<Icon as={RiMenuLine} boxSize={6} />}
        color="white"
        aria-label="Open Menu"
        onClick={onOpen}
      />
      {drawerNav}
      <Box>
        {logo && (
          <Image
            height="2.25rem"
            maxWidth="6.25rem"
            src={logo.url}
            alt={logo.alt ?? "Logo"}
          />
        )}
      </Box>
      <Spacer />
      <WeglotSelect preferredLanguageSetting={preferredLanguageSetting} />
    </Flex>
  );
}
