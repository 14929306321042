import { gql } from "@apollo/client";

export const APPLICANT_ATTENDING_SCHOOLS_FRAGMENT = gql`
  fragment ApplicantAttendingSchoolsFragment on person {
    applicant_attending_schools {
      person_id
      enrollment_period_id
      attendance_status
      school_id
      description
    }
  }
`;

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on person {
    id
    first_name
    middle_name
    last_name
    birth_date
    street_address
    street_address_line_2
    city
    state
    zip_code
    active
    reference_id
    phone_number
    sms_okay
    email_address
    email_okay
    avatar
    updated_at
    preferred_language
    user {
      id
      name
    }
  }

  fragment PersonTotals on person_aggregate {
    totals: aggregate {
      total: count
    }
  }
`;

export const PERSON_BASIC_FRAGMENT = gql`
  fragment PersonBasicFragment on person {
    id
    first_name
    middle_name
    last_name
    phone_number
    reference_id
    email_address
    sms_okay
    email_okay
  }
`;
