import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { OrganizationError as OrganizationErrorType } from "src/hooks/useOrganization";
import { NotFound } from "./NotFound";

type Props = {
  error: OrganizationErrorType;
};
export function OrganizationError({ error }: Props) {
  switch (error.kind) {
    case "NotFoundError":
      return <NotFound />;
    case "ServerError": {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>Server Error</AlertDescription>
          {/* TODO: better error message */}
        </Alert>
      );
    }
  }
}
