import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FETCH_ANNOUNCEMENTS } from "./graphql/queries";
import { useOrganization } from "src/hooks/useOrganization";
import React from "react";
import { AnnouncementBanner } from "./AnnouncementBanner";
import { AnnouncementDialog } from "./AnnouncementDialog";

export interface ParentAnnouncementProps {
  condition: GQL.announcement_condition_enum;
}

export const ParentAnnouncement = ({ condition }: ParentAnnouncementProps) => {
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.FetchAnnouncements,
    GQL.FetchAnnouncementsVariables
  >(FETCH_ANNOUNCEMENTS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      entry_point: GQL.announcement_entry_point_enum.PARENT_PORTAL,
      condition,
    },
  });

  const announcement = React.useMemo(() => {
    return remoteData.toNullable()?.announcement[0];
  }, [remoteData]);

  if (!announcement) return <></>;

  return (
    <>
      {announcement.display_type ===
        GQL.announcement_display_type_enum.BANNER && (
        <AnnouncementBanner announcement={announcement} />
      )}

      {announcement.display_type ===
        GQL.announcement_display_type_enum.DIALOG && (
        <AnnouncementDialog announcement={announcement} />
      )}
    </>
  );
};
