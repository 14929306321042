import {
  Alert,
  AlertDescription,
  CloseButton,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import { FunctionComponent } from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import { toTuple3 } from "src/types/remoteData";
import {
  useGetCustomQuestionAnswerQuery,
  useGetCustomQuestionQuery,
  useGetSelectedPersonAnswerBankRawQuery,
  useSaveCustomQuestionAnswers,
} from "../api";
import { mapCloneIdsBySourceIds } from "../context/helpers";
import { transformValuesIntoAnswerBankRecordFields } from "../helpers";
import { UpdateOutdatedAnswerDialog } from "./UpdateOutdatedAnswerDialog";
import { getSelectedAnswerBankAsFormAnswer } from "./helpers";

type OutdatedAnswerAlertProps = {
  formId: uuid;
  questionId: uuid;
  nestedQuestions: ClonedQuestion<WithId>[];
  closeAlert: () => void;
  refetchAnswers?: boolean;
};

export const OutdatedAnswerAlert: FunctionComponent<
  OutdatedAnswerAlertProps
> = (props) => {
  const { formId, questionId, nestedQuestions, closeAlert, refetchAnswers } =
    props;

  const {
    isOpen: updateModalIsOpen,
    onOpen: updateModalOnOpen,
    onClose: updateModalOnClose,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const toast = useAvelaToast({
    position: "bottom",
    containerStyle: { marginBottom: 16 },
    isClosable: true,
  });

  const customQuestionRemoteData = useGetCustomQuestionQuery({
    question_id: questionId,
  });

  const bankAnswerRemoteData = useGetSelectedPersonAnswerBankRawQuery({
    form_id: formId,
    question_id: questionId,
  });

  const formAnswerRemoteData = useGetCustomQuestionAnswerQuery({
    form_id: formId,
    question_id: questionId,
  });

  const saveCustomQuestionAnswers = useSaveCustomQuestionAnswers();

  return (
    <RemoteDataView
      remoteData={toTuple3(
        customQuestionRemoteData,
        bankAnswerRemoteData,
        formAnswerRemoteData
      )}
      error={(e) => {
        console.error(e);
        return <GenericError message="Failed to load answer" />;
      }}
    >
      {([customQuestion, bankAnswerData, formAnswer]) => {
        const result = getSelectedAnswerBankAsFormAnswer(
          customQuestion,
          bankAnswerData
        );
        if (
          !customQuestion ||
          !formAnswer ||
          !result ||
          isEqual(result.bankAnswer, formAnswer)
        ) {
          return <></>;
        }
        const { bankAnswer, answerBankId } = result;
        const sourceIdCloneIdMapping = mapCloneIdsBySourceIds(
          customQuestion.customQuestionType,
          nestedQuestions
        );

        const onSubmit = async () => {
          try {
            await saveCustomQuestionAnswers({
              formId,
              customQuestion: customQuestion.customQuestion,
              answerBankId,
              answersByQuestionId: bankAnswer,
              refetchAnswers,
            });
            toast({
              title: `${customQuestion.customQuestion.question} saved`,
            });
          } catch (error) {
            console.error(error);
            toast({
              title: `Error saving ${customQuestion.customQuestion.question}`,
              description:
                "Please try again later or report the problem to our support team.",
              status: "error",
            });
          }
        };

        return (
          <>
            <Alert status="warning" borderRadius="md" color="orange.900">
              <AlertDescription>
                This answer is outdated.{" "}
                <Text
                  fontWeight="bold"
                  textDecoration="underline"
                  display="inline"
                  _hover={{ cursor: "pointer" }}
                  onClick={updateModalOnOpen}
                >
                  Update
                </Text>{" "}
                it?
              </AlertDescription>
              <Spacer />
              <CloseButton onClick={() => closeAlert()} />
            </Alert>
            <UpdateOutdatedAnswerDialog
              isOpen={updateModalIsOpen}
              onClose={updateModalOnClose}
              bankAnswerFields={transformValuesIntoAnswerBankRecordFields(
                bankAnswer,
                nestedQuestions,
                sourceIdCloneIdMapping
              )}
              formAnswerFields={transformValuesIntoAnswerBankRecordFields(
                formAnswer,
                nestedQuestions,
                sourceIdCloneIdMapping
              )}
              onSubmit={onSubmit}
            />
          </>
        );
      }}
    </RemoteDataView>
  );
};
