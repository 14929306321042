import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { CARD_PROPS } from "../Step";

type Props = {
  resetSteps: () => void;
};

export const Error: FunctionComponent<Props> = (props) => {
  const { resetSteps } = props;

  return (
    <Flex direction="column" gap={6} alignItems="center">
      <Heading as="h2" size="lg" fontSize="1.25rem" fontWeight={700}>
        We’ve encountered an error
      </Heading>
      <Text fontSize="1rem" textAlign="center">
        We were unable to lookup your account information. Please try again
        later.
      </Text>
      <Flex {...CARD_PROPS} padding={8} gap={4} textAlign="center">
        <Button
          onClick={() => {
            resetSteps();
          }}
        >
          Try again
        </Button>
      </Flex>
    </Flex>
  );
};
