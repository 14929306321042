import { Code, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { Table } from "src/components/Table/Table";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { DeleteGradeConfigButton } from "./DeleteButton";

interface GradesConfigListProps {
  gradesConfig: GQL.GetOrganizationsByGradesConfig_organization[];
  onDelete: () => void;
}

export const GradesConfigList: React.FC<GradesConfigListProps> = ({
  gradesConfig,
  onDelete,
}) => {
  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const columns: ColumnDef<GQL.GetOrganizationsByGradesConfig_organization>[] =
    useMemo(
      () => [
        {
          accessorKey: "id",
          header: "organization id",
        },
        {
          id: "Grades Configuration",
          cell: (props) =>
            props.row.original.grade_configs.map((grade_config) => (
              <HStack paddingY={2} key={grade_config.id}>
                <Text>ID:</Text>
                <Code>{grade_config.id}</Code>
                <Text>Label:</Text>
                <Code>{grade_config.label}</Code>
                <Text>Order:</Text>
                <Code>{grade_config.order}</Code>
                {grade_config.value && (
                  <>
                    <Text>Value key:</Text>
                    <Code>{grade_config.value}</Code>
                  </>
                )}
              </HStack>
            )),
        },
        {
          id: "Actions",
          cell: (props) => {
            const id: string = props.row.original.id;
            return (
              <>
                <IconButton
                  as={NavLink}
                  to={Url.Admin.GradesConfig.edit(id)}
                  aria-label={`Edit grade config ${id}`}
                  icon={<Icon as={RiPencilLine} />}
                  variant="outline"
                />
                <DeleteGradeConfigButton id={id} onDelete={handleDelete} />
              </>
            );
          },
        },
      ],
      [handleDelete]
    );

  if (gradesConfig.length === 0)
    return <span>There are no grades configuration</span>;

  return (
    <Table<GQL.GetOrganizationsByGradesConfig_organization>
      data={gradesConfig}
      columns={columns}
    />
  );
};
