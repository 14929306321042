import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Link,
  Spacer,
  Tag,
  TagLeftIcon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Card } from "src/components/Layout/Card";
import { REPEAT_MODE_OPTIONS } from "../../forms/export/dateUtils";
import { ScheduleResponse } from "./types";

type ScheduledExportItemProps = {
  item: ScheduleResponse;
  timezoneName: string;
  // editLink: string;
  onDelete: (pk: string) => void;
  isDeleting: boolean;
  // isPatching: boolean;
};

export const ScheduledExportItem = ({
  item,
  timezoneName,
  // editLink,
  onDelete,
  isDeleting,
}: // isPatching,
ScheduledExportItemProps) => {
  const exportName = item.exportName.length > 0 ? item.exportName : "form";
  const description = formatScheduleDescription(item, timezoneName);
  return (
    <Card
      display="flex"
      showBorder
      padding={6}
      width="100%"
      justifyContent="space-between"
    >
      <VStack width="100%" alignItems="start" gap={4}>
        {item.status === "FAILED" && (
          <Tag colorScheme="orange" size="sm">
            <TagLeftIcon as={WarningTwoIcon} />
            <Text>Not run</Text>
          </Tag>
        )}
        <HStack width="100%">
          <Text fontSize="lg" textColor="gray.800" fontWeight="600">
            {exportName}
          </Text>
          <Spacer />
          <Button
            variant="outline"
            colorScheme="gray"
            size="sm"
            leftIcon={<RiDeleteBin6Line />}
            onClick={() => onDelete(item.pk)}
            isLoading={isDeleting}
          >
            Delete
          </Button>

          {/* TODO: edit scheduled export https://app.asana.com/0/1203752480528846/1207946344388263/f
          <Button
            as={NavLink}
            to={editLink}
            variant="outline"
            colorScheme="gray"
            leftIcon={<RiEditLine />}
          >
            Edit
          </Button> */}
        </HStack>
        <Text textColor="gray.600">{description}</Text>
      </VStack>
    </Card>
  );
};

function formatScheduleDescription(
  item: ScheduleResponse,
  timezoneName: string
) {
  const phrases: string[] = [];
  const { exportTime: scheduleConfig, startTime, status, url } = item;

  // Show information about latest export for this schedule, if any
  if (status === "COMPLETED") {
    if (!url || !startTime) {
      throw new Error("Missing attributes on completed export");
    }
    const latestExportStartTime = toZonedTime(startTime, timezoneName);
    const formattedTime = format(latestExportStartTime, "MMMM d 'at' h:mm aa");
    if (scheduleConfig.repeatMode) {
      phrases.push(`Last run on ${formattedTime}.`);
    } else {
      phrases.push(`Ran on ${formattedTime}.`);
    }
  } else if (status === "FAILED") {
    phrases.push("There was a problem with the export.");
  } else {
    phrases.push("Never run.");
  }

  // Show details about the schedule config
  const scheduledTime = toZonedTime(scheduleConfig.startTime, timezoneName);
  if (scheduleConfig.repeatMode) {
    phrases.push(
      `Runs ${REPEAT_MODE_OPTIONS[scheduleConfig.repeatMode].formatDescription(
        scheduledTime
      )}.`
    );
  } else if (!status) {
    // one-time scheduled export not completed yet
    phrases.push(
      `Scheduled for ${format(scheduledTime, "MMMM d, yyyy 'at' h:mm aa")}.`
    );
  }
  return (
    <>
      {phrases.join(" ")}
      {/* Add download link if available */}
      {url && (
        <>
          {" "}
          <Link href={url} textColor="primary.500" textDecoration="underline">
            Download
          </Link>
        </>
      )}
    </>
  );
}
