import { useOrganizationPath } from "src/hooks/useOrganizationPath";

export enum AuthenticationMethodType {
  PHONE = "phone number",
  EMAIL = "email",
}

/**
 * From https://github.com/Avela-Education/apply-frontend/pull/1633:
 *
 * Our unauthenticated redirect logic should work when navigating to an authenticated route.
 * TODO: this was not verify-able on localhost:3001, test on dev2
 */
export function useSpecificAuthenticationLink(
  page: "login" | "signup",
  method: AuthenticationMethodType | null | undefined
) {
  // our Auth0 redirect query string expects "phone" and not "phone number"
  const remappedMethod =
    (method === AuthenticationMethodType.PHONE ? "phone" : method) ??
    AuthenticationMethodType.EMAIL; // default to email if no method is provided

  const organizationPath = useOrganizationPath();
  return `/${organizationPath}?page=${page}&method=${remappedMethod}&hide=switch-page,switch-method`;
}
