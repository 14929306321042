import { MenuItem, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { CLEAR_SUB_STATUS_BULK_FORM } from "../graphql/mutations";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import { FormSchoolStatus } from "../types";

interface Props {
  selectedFormSchoolStatuses: RD.RemoteData<unknown, FormSchoolStatus[]>;
  onRefetch?: () => void;
}

const CONFIRMATION_BODY_TEXT =
  "Clearing the sub-status will delete any offer or waitlist information, including acceptances, programs or waitlists positions. This can’t be undone nor recovered.";

const DISABLED_TOOLTIP_TEXT =
  "This form is associated with another, revoke the offer or remove from waitlist instead";

export const ClearSubstatus = ({
  selectedFormSchoolStatuses,
  onRefetch,
}: Props) => {
  const [clearSubstatusBulkForm] = useRemoteDataMutation<
    GQL.ClearSubstatusBulkForm,
    GQL.ClearSubstatusBulkFormVariables
  >(CLEAR_SUB_STATUS_BULK_FORM);

  const toast = useAvelaToast();

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "Cancel",
      },
      confirmButton: {
        label: "Yes, clear",
        colorScheme: "blue",
      },
      translate: true,
    });

  const isMenuItemDisabled = React.useMemo(
    () =>
      selectedFormSchoolStatuses.hasData() &&
      selectedFormSchoolStatuses.data.some(
        (result) =>
          (result.offers?.[0] &&
            result.offers?.[0].assigned_forms.length > 0) ||
          (result.waitlists?.[0] &&
            result.waitlists?.[0].assigned_forms.length > 0)
      ),
    [selectedFormSchoolStatuses]
  );

  const isTooltipVisible = !isMenuItemDisabled;

  const handleOnClick = React.useCallback(
    async (evt: React.MouseEvent) => {
      if (!selectedFormSchoolStatuses.hasData()) {
        throw new Error("Invalid selected rows");
      }

      const schoolForms = selectedFormSchoolStatuses.data;
      const count = schoolForms.length;

      setHeader(<Text>{`Clear sub-status from ${count} items?`}</Text>);
      setBody(<Text>{CONFIRMATION_BODY_TEXT}</Text>);

      if (await confirm()) {
        const loadingToastId = toast({
          title: `Working hard`,
          description: `Clearing sub-status from ${count} items...`,
          status: "loading",
          duration: undefined,
        });

        try {
          await clearSubstatusBulkForm({
            variables: {
              updateWhere: {
                id: { _in: schoolForms.map((result) => result.id) },
              },
              delete_waitlists_where: {
                _or: schoolForms.map((result) => ({
                  deleted_at: { _is_null: true },
                  form_id: { _eq: result.form_id },
                  school_id: { _eq: result.school_id },
                })),
              },
              delete_offers_where: {
                _or: schoolForms.map((result) => ({
                  deleted_at: { _is_null: true },
                  form_id: { _eq: result.form_id },
                  school_id: { _eq: result.school_id },
                })),
              },
            },
            refetchQueries: [FETCH_OFFER_WAITLIST_STATUS],
          });

          if (onRefetch) onRefetch();

          toast.close(loadingToastId);
          toast({
            title: "Hooray",
            description: `${schoolForms.length} items cleared`,
            status: "success",
          });
        } catch (err: any) {
          toast.close(loadingToastId);
          if (err.message === "data") {
            toast.error({
              title: "Invalid grades in forms",
              description:
                "One or more items in your selection have mismatching grades",
            });
          } else {
            toast.error({
              title: "Something went wrong!",
              description: "Check your network and try again.",
            });
          }
          console.error(err);
        }
      }
    },
    [
      confirm,
      clearSubstatusBulkForm,
      onRefetch,
      selectedFormSchoolStatuses,
      setBody,
      setHeader,
      toast,
    ]
  );

  return (
    <>
      <RemoteDataView
        remoteData={selectedFormSchoolStatuses}
        loading={<Skeleton height={5} margin={3} />}
        error={() => <GenericError />}
        config={{ showDataWhileReloading: false }}
      >
        {(formSchools) => (
          <Tooltip
            label={<Text align="center">{DISABLED_TOOLTIP_TEXT}</Text>}
            placement="left"
            hasArrow
            arrowSize={15}
            gutter={20}
            minW="22rem"
            isDisabled={isTooltipVisible}
          >
            <MenuItem
              textColor="gray.700"
              fontWeight="400"
              fontSize="sm"
              onClick={handleOnClick}
              isDisabled={isMenuItemDisabled}
            >
              Clear sub-status
            </MenuItem>
          </Tooltip>
        )}
      </RemoteDataView>
      {confirmationDialog}
    </>
  );
};
