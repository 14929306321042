import {
  Checkbox,
  Flex,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiMore2Fill } from "react-icons/ri";
import { HighlightedText } from "../Text/HighlightedText";
import { TextWithOverflowTooltip } from "../TextWithOverflowTooltip";
import { Selected, Tag } from "./types";
import { UpdateTagDescriptionDialog } from "./UpdateTagDescriptionDialog";
import { FormType } from "./Form";

type Config = {
  allowEditTag?: boolean;
  allowDeleteTag?: boolean;
};
type Props = {
  config?: Config;
  tag: Tag;
  searchKeyword?: string;
  onTagUpdate?: (id: string, selected: Selected) => void;
  onEditTag?: (tag: Tag) => void;
  onDeleteTag?: (tag: Tag) => void;
  onEditTagSaving?: (tag: Tag, onSuccess?: () => void) => Promise<void>;
  onToggleTagDescription?: (state: boolean) => void;
};
export const TagItem: React.FC<Props> = ({
  config,
  tag,
  searchKeyword,
  onTagUpdate,
  onDeleteTag,
  onEditTag,
  onEditTagSaving,
  onToggleTagDescription,
}) => {
  const { allowDeleteTag = false, allowEditTag = false } = config || {};
  const [focus, setFocus] = React.useState(false);
  const [keepOpen, setKeepOpen] = React.useState(false);
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const isChecked = tag.selected === "All";
  const isIndeterminate = tag.selected === "Partial";
  const hasMenu = allowEditTag || allowDeleteTag;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenTagDescription = useCallback(() => {
    if (onToggleTagDescription) {
      onToggleTagDescription(false);
    }
    onOpen();
  }, [onOpen, onToggleTagDescription]);

  const handleOnCloseTagDescription = useCallback(() => {
    if (onToggleTagDescription) {
      onToggleTagDescription(true);
    }
    onClose();
  }, [onClose, onToggleTagDescription]);

  const handleOnUpdateTagDescription = React.useCallback(
    (values: FormType) => {
      if (onEditTagSaving) {
        onEditTagSaving(
          {
            ...tag,
            description: values.description ?? null,
          },
          onClose
        );
      }

      if (onToggleTagDescription) {
        onToggleTagDescription(true);
      }
    },
    [onEditTagSaving, tag, onClose, onToggleTagDescription]
  );

  return (
    <Flex
      as={ListItem}
      listStyleType="none"
      onFocus={() => setFocus(true)}
      onBlur={(event) => {
        if (event.relatedTarget !== menuButtonRef.current) {
          setFocus(false);
        }
      }}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
      padding="1.5"
      paddingX="2"
      background={focus || keepOpen ? "gray.100" : undefined}
      alignItems="center"
    >
      <Checkbox
        isIndeterminate={isIndeterminate}
        isChecked={isChecked}
        paddingLeft="0.2rem"
        isReadOnly={tag.isUpdating}
        variant="ellipsis"
        onChange={() => {
          if (onTagUpdate) {
            onTagUpdate(tag.id, nextSelected(tag));
          }
        }}
      >
        <TextWithOverflowTooltip content={tag.name} hasArrow>
          {searchKeyword ? (
            <HighlightedText text={tag.name} keyword={searchKeyword} />
          ) : (
            tag.name
          )}
        </TextWithOverflowTooltip>
      </Checkbox>
      <Spacer />
      {tag.isUpdating ? <Spinner color="gray.500" size="sm" /> : null}
      {!tag.isUpdating && hasMenu && (focus || keepOpen) ? (
        <Menu
          colorScheme="gray"
          onOpen={() => setKeepOpen(true)}
          onClose={() => setKeepOpen(false)}
        >
          <MenuButton
            aria-label="more"
            ref={menuButtonRef}
            as={IconButton}
            size="xs"
            colorScheme="gray"
            variant="ghost"
            icon={<Icon as={RiMore2Fill} boxSize="5" />}
          />
          <MenuList>
            {allowEditTag && (
              <MenuItem
                onClick={() => {
                  if (onEditTag) {
                    onEditTag(tag);
                  }
                }}
              >
                Rename
              </MenuItem>
            )}
            {allowEditTag && (
              <MenuItem onClick={handleOpenTagDescription}>
                Update description
              </MenuItem>
            )}
            {allowDeleteTag && (
              <MenuItem
                onClick={() => {
                  if (onDeleteTag) {
                    onDeleteTag(tag);
                  }
                }}
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      ) : null}

      <UpdateTagDescriptionDialog
        tag={tag}
        isOpen={isOpen}
        onEditTagSaving={handleOnUpdateTagDescription}
        onClose={handleOnCloseTagDescription}
      />
    </Flex>
  );
};

function nextSelected(tag: Tag): Selected {
  switch (tag.selected) {
    case "All":
      return "None";

    case "None":
      return "All";

    case "Partial":
      return "None";

    default:
      const _exhaustiveCheck: never = tag.selected;
      return _exhaustiveCheck;
  }
}
