import { DEFAULT_CONFIG } from "./constants";

export type Selected = "None" | "Partial" | "All";
export type Tag = {
  id: uuid;
  name: citext;
  description: string | null;
  selected: Selected;
  isUpdating: boolean;
  lastUsedAt: Date | null;
};
export type ModeAction = Mode;
export type Mode =
  | { type: "New"; initialName: string | null }
  | { type: "List" }
  | { type: "Edit"; tag: Tag }
  | {
      type: "SelectGroup";
    };

export type TagsListEventHandler = {
  onSearch?: (keyword: string) => void;
  onTagUpdate?: (id: string, selected: Selected) => void;
  onNewTag?: (tag: string | null) => void;
  onEditTag?: (tag: Tag) => void;
  onDeleteTag?: (tag: Tag) => Promise<void>;
  onSelectTagGroup?: (id: string) => void;
};

export const extractEventHandlers = ({
  onSearch,
  onTagUpdate,
  onDeleteTag,
  onEditTag,
  onNewTag,
  onSelectTagGroup,
}: TagsListEventHandler): TagsListEventHandler => {
  return {
    onDeleteTag,
    onTagUpdate,
    onEditTag,
    onNewTag,
    onSearch,
    onSelectTagGroup,
  };
};

export type Config = {
  title?: string;
  maxLastUsedTagsCount?: number;
  allowNewTag?: boolean;
  allowEditTag?: boolean;
  allowDeleteTag?: boolean;
};

export type ConfigWithDefault = typeof DEFAULT_CONFIG;
