import { DeleteButton } from "src/components/Buttons/DeleteButton";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_TEAM } from "./graphql/mutations";

interface DeleteTeamButtonProps {
  id: string;
  name: string;
  onDelete: () => void;
}

export const DeleteTeamButton = ({
  id,
  name,
  onDelete,
}: DeleteTeamButtonProps) => {
  const { glossary } = useGlossary();
  const [deleteTeam, { remoteData }] = useRemoteDataMutation<
    GQL.DeleteTeam,
    GQL.DeleteTeamVariables
  >(DELETE_TEAM);

  const toast = useAvelaToast();

  const handleDelete = async () => {
    try {
      await deleteTeam({ variables: { teamId: id } });
      onDelete();
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Error deleting team`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <DeleteButton
      label={`Edit ${name}`}
      loading={remoteData.isLoading()}
      onDelete={handleDelete}
      confirmationHeader={glossary`Delete team`}
    />
  );
};
