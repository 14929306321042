import { ApolloError } from "@apollo/client";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormLayout } from "src/components/Layout/FormLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { RouterLink } from "src/components/Links/RouterLink";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useWeglotToast } from "src/plugins/weglot";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { StartNewFormSection } from "./StartNewFormSection";
import { useStartForm } from "../../hooks/useStartForm";
import { Loading } from "src/components/Feedback/Loading";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import { getContext } from "src/scenes/parent/state/localStorage";
import { Glossary } from "src/components/Text/Glossary";

// since formTemplateId can change with enrollmentPeriod selection
// the form validation should not check it
const BasicStartNewFormSchema = z.object({
  studentId: z.string().uuid("This field is required."),
  enrollmentPeriodId: z.string().uuid("This field is required."),
});

// full schema.   used to validate and enable Next button
const StartNewFormSchema = BasicStartNewFormSchema.extend({
  formTemplateId: z.string().uuid("This field is required."),
});

export type StartNewFormType = z.infer<typeof StartNewFormSchema>;

type Props = {
  studentsByGuardian: GQL.GetStudentsByGuardianId_person[];
  enrollmentPeriods: GQL.GetActiveEnrollmentPeriods_enrollment_period[];
};

/**
 * Formik wrapper for StartNewForm page. Beyond being a form with three Select fields,
 * it also orchestrates a "preselect when only singular option" behavior in each of them.
 *
 * 1. Preselect the student, which is based on async request response.
 * 2. Preselect the enrollment period, which is also based on async request response.
 * 3. Preselect the form.
 *    a. Based on the preselected enrollment period.
 *    b. Based on the user selected enrollment period.
 *
 * Behaviors 1, 2, 3a are handled via Formik initialValues.
 * 3b is handled by ./SelectEnrollmentPeriod.tsx
 */
export const StartNewFormWrapper: FunctionComponent<Props> = (props) => {
  const { studentsByGuardian, enrollmentPeriods } = props;
  const { glossary } = useGlossary();
  const navigate = useNavigate();
  const organization = useOrganization();
  const { studentId } = useQueryParams();

  const toast = useWeglotToast();
  const genericErrorToast = () => {
    toast({
      title: "Unable to create an form. Please try again later.",
      status: "error",
      isClosable: true,
    });
  };

  const { startForm } = useStartForm();

  const [isProcessingNext, setProcessingNext] = useState<boolean>(false);

  const onSubmit = async (values: StartNewFormType) => {
    try {
      const org = organization.toNullable();
      if (!org) {
        console.error("Invalid organization");
        genericErrorToast();
        return;
      }

      setProcessingNext(true);
      const formId = await startForm(values);
      if (!formId) {
        genericErrorToast();
        return;
      }

      setProcessingNext(false);
      navigate(Url.Parent.Form.edit(org, formId));
    } catch (err: unknown) {
      console.error(err);
      setProcessingNext(false);
      if (err instanceof ApolloError && uniquenessConstraint(err)) {
        toast({
          title: glossary`Form already exists for the selected student and enrollment period.`,
          status: "error",
          isClosable: true,
        });
      } else {
        genericErrorToast();
      }
    }
  };

  const initialFormTemplates = getInitialFormTemplates(enrollmentPeriods);

  const initialValue: StartNewFormType = {
    studentId: getInitialStudentId(studentsByGuardian, studentId as string),
    enrollmentPeriodId:
      enrollmentPeriods.length === 1 ? enrollmentPeriods[0]?.id ?? "" : "",
    formTemplateId: initialFormTemplates[0]?.id ?? "",
  };

  const isNextEnabled = (values: StartNewFormType) =>
    StartNewFormSchema.safeParse(values).success;

  return (
    <Formik<StartNewFormType>
      initialValues={initialValue}
      onSubmit={onSubmit}
      validate={validateWithZod(BasicStartNewFormSchema)}
      validateOnMount={true}
    >
      {({ isValid, values }) =>
        isProcessingNext ? (
          <Loading />
        ) : (
          <FormLayout
            as={Form}
            buttons={
              !isProcessingNext && (
                <ParentFormsLayoutFooter justifyContent="space-between">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    as={RouterLink}
                    to={organization.map(Url.Parent.index).withDefault("#")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    rightIcon={<ArrowForwardIcon />}
                    isDisabled={!isNextEnabled(values)}
                    isLoading={isProcessingNext}
                  >
                    Next
                  </Button>
                </ParentFormsLayoutFooter>
              )
            }
            heading="Start form"
            backLink={{
              url: organization.map(Url.Parent.index).withDefault("#"),
              label: "Back",
            }}
          >
            {getContext()?.exploreSavedSchools?.length && (
              <InfoAlert
                children={
                  <Text>
                    <Glossary>
                      Your saved schools are ready to add to a new form.
                    </Glossary>
                  </Text>
                }
              ></InfoAlert>
            )}
            <StartNewFormSection
              studentsByGuardian={studentsByGuardian}
              enrollmentPeriods={enrollmentPeriods}
            />
          </FormLayout>
        )
      }
    </Formik>
  );
};

function uniquenessConstraint(apolloError: ApolloError): boolean {
  return (
    apolloError.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "form_form_template_id_person_id_key"'
  );
}

function getInitialStudentId(
  studentsByGuardian: GQL.GetStudentsByGuardianId_person[],
  queryParamStudentId: string
) {
  if (queryParamStudentId) {
    return String(queryParamStudentId);
  }

  if (studentsByGuardian.length === 1) {
    return studentsByGuardian[0]?.id ?? "";
  }

  return "";
}

/**
 *
 */
function getInitialFormTemplates(
  enrollmentPeriods: GQL.GetActiveEnrollmentPeriods_enrollment_period[]
) {
  if (enrollmentPeriods.length === 1) {
    return enrollmentPeriods.flatMap((enrollmentPeriod) => {
      return enrollmentPeriod.form_templates;
    });
  }

  return [];
}
