import { getRelatedSchool } from "./related";

type School = {
  id: uuid;
};
type FormSchoolRankAndForm = {
  form: {
    previous_offer: { school: School } | null;
    previous_waitlist: { school: School } | null;
  } | null;
  form_school_rank: { school_id: uuid } | null;
};
export function getSchool(row: FormSchoolRankAndForm): School | undefined {
  if (row.form_school_rank) {
    return { id: row.form_school_rank.school_id };
  }

  if (row.form) {
    return getRelatedSchool<School>(row.form);
  }
}
