import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

interface Props {
  header: React.ReactNode;
  leftNav: React.ReactNode;
}

export const BaseLayout = (props: Props) => {
  return (
    <Grid
      width="100vw"
      height="100vh"
      templateRows="5.125rem 1fr"
      templateColumns="auto 1fr"
      templateAreas='"header header""leftNav main"'
    >
      <GridItem area="header" zIndex="11">
        {props.header}
      </GridItem>
      <GridItem
        display="flex"
        area="leftNav"
        position="relative"
        overflow="hidden"
      >
        {props.leftNav}
      </GridItem>
      <GridItem as="main" area="main" overflow="auto" padding="10">
        <Outlet />
      </GridItem>
    </Grid>
  );
};
