import { PropsWithChildren } from "react";
import { AccessDenied } from "../Feedback/AccessDenied";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import * as OrgConfig from "@avela/organization-config-sdk";
import { RemoteDataView } from "../Layout/RemoteDataView";

type RequireConfigProps = PropsWithChildren<{
  configType: OrgConfig.Type;
}>;

export const RequireConfig: React.FC<RequireConfigProps> = ({
  children,
  configType,
}) => {
  const configRD = useOrgConfig(configType);

  return (
    <RemoteDataView remoteData={configRD} error={() => null}>
      {(config) =>
        config.disabled ? (
          <AccessDenied message="You don't have access to this page, redirecting..." />
        ) : (
          <>{children}</>
        )
      }
    </RemoteDataView>
  );
};
