import React from "react";
import { createContext, FunctionComponent, useContext } from "react";
import { State } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/statusesState";
import { Action, useFormTemplateStatusesProcessor } from "./processor";
import { StatusDescriptionsFormType } from "./StatusDescriptionsForm";

type ContextValue = {
  state?: State;
  dispatch?: (action: Action) => void;
  formTemplateId?: uuid;
};

const EditFormTemplateStatusesContext = createContext<ContextValue>({
  state: undefined,
  dispatch: undefined,
  formTemplateId: undefined
});

type Props = {
  children: React.ReactNode;
  statusesInitialValues: StatusDescriptionsFormType;
  formTemplateId: uuid;
};

export const EditFormTemplateStatusesProvider: FunctionComponent<Props> = (
  props
) => {
  const { children, statusesInitialValues, formTemplateId } = props;
  const formTemplateStatusesState = useFormTemplateStatusesProcessor(
    { statuses: statusesInitialValues },
    formTemplateId
  );

  return (
    <EditFormTemplateStatusesContext.Provider
      value={{
        ...formTemplateStatusesState,
        formTemplateId
      }}
    >
      {children}
    </EditFormTemplateStatusesContext.Provider>
  );
};

export function useEditFormTemplateStatusesContext() {
  const context = useContext<ContextValue>(EditFormTemplateStatusesContext);

  if (context === undefined) {
    throw new Error(
      "useEditFormTemplateStatusesContext must be used within a FormTemplateEditStatusesProvider"
    );
  }

  return context;
}
