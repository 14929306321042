import { Badge, Flex } from "@chakra-ui/layout";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import {
  GetParent_person_by_pk,
  GetStudentByIdAdminPortal_person_by_pk,
  person_type_enum,
} from "src/types/graphql";
import { AdminHeading } from "../Text/AdminHeading";

type detailData =
  | GetStudentByIdAdminPortal_person_by_pk
  | GetParent_person_by_pk;

interface HeaderProps {
  data: detailData;
  type: person_type_enum;
  onActiveChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}

export const DetailHeader: React.FC<HeaderProps> = ({
  data,
  type,
  onActiveChange,
  isLoading,
  isActive,
  isDisabled = false,
}) => {
  return (
    <Flex
      as="header"
      padding="24px"
      backgroundColor="gray.50"
      align="center"
      borderRadius="12px"
    >
      <AdminHeading
        id={data.id}
        title={`${data.first_name} ${data.last_name}`}
        badge={
          <Badge
            variant="solid"
            colorScheme="orange"
            padding="8px"
            borderRadius="50px"
          >
            {type.toUpperCase()}
          </Badge>
        }
        actionButton={
          <FormControl display="flex" alignItems="center" width="fit-content">
            <FormLabel htmlFor="status" mb="0">
              Status: {data.active ? "Active" : "Inactive"}
            </FormLabel>
            <Switch
              id="status"
              onChange={onActiveChange}
              isChecked={isActive === undefined ? data.active : isActive}
              isDisabled={isDisabled || isLoading}
            />
          </FormControl>
        }
      />
    </Flex>
  );
};
