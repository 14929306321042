import * as GQL from "src/types/graphql";

type FormSchoolRank = {
  form: { status: GQL.form_status_enum };
  offers: { status: GQL.offer_status_enum }[];
};

type FormSchool = {
  school: { id: uuid } | null;
};

export function canRevokeOffers(
  formSchoolRanks: FormSchoolRank[],
  formSchools: FormSchool[]
): boolean {
  if (formSchools.some((appSchool) => !appSchool.school)) {
    return false;
  }

  return formSchoolRanks.every((rank) => {
    if (GQL.form_status_enum.Admissions !== rank.form.status) return false;

    return (
      rank.offers[0]?.status === GQL.offer_status_enum.Offered ||
      rank.offers[0]?.status === GQL.offer_status_enum.Accepted
    );
  });
}
