import { groupBy, sortBy } from "lodash";
import { FORM_SCHOOL_RANK_SUB_STATUS, FORM_STATUS } from "src/constants";
import * as GQL from "../types/graphql";
import * as BackwardCompatibility from "./backwardCompatibility";
import { formatUSPhoneNumber } from "./format";
import { includeAllAdditionalQuestions } from "./formTemplate/question";
import { isNotNull } from "./predicates";

// Shorthand for long type name.
type RowEvent = GQL.GetFormHistory_audit_form_transaction_logged_actions;

export enum EventType {
  Updated = "updated to",
  Implied = "",
  AddedTo = "added to",
  RemovedFrom = "removed from",
  Created = "created",
  Submitted = "Submitted to",
  Cleared = "cleared",
}

type Change<T> = {
  field: string;
  type: EventType;
  value: T;
};

export type HistoryEvent<T> = Change<T> & {
  author: {
    full_name: string | null;
    type: GQL.person_type_enum | null;
  } | null;
  isoTimestamp: string | null;
};

/**
 * Interprets the changes contained in an individual database transaction.  Note
 * that changes via the parent portal are generally limited to one change per
 * transaction.  However, changes in the admin portal affect entire sections at
 * once, with the additional complication that even fields that weren't changed
 * generate row events that should be filtered out.
 */
export function resolveTransaction(
  transaction: GQL.GetFormHistory_audit_form_transaction,
  formId: string,
  formTemplate: GQL.FormTemplateFragment,
  schools: Pick<GQL.SimpleSchoolFragment, "id" | "name">[],
  grades: Pick<GQL.GetFormHistory_grade, "id" | "program">[],
  tags: Pick<GQL.GetFormHistory_enrollment_period_tag, "id" | "name">[]
): HistoryEvent<string>[] {
  // TODO: After we support bulk modifications to forms, a single
  // transaction may affect multiple forms, so we may need to isolate
  // changes to specific forms.
  const rowEvents = transaction.logged_actions;
  const author = rowEvents[0]?.user?.people[0] ?? null;
  return Array.from(
    resolveChanges(rowEvents, formId, formTemplate, schools, grades, tags),
    (change) => ({
      ...change,
      author: author && {
        full_name: author.full_name,
        // TODO: Fall back to `x-hasura-role`?
        type: author.person_type ?? null,
      },
      isoTimestamp: transaction.action_tstamp_tx,
    })
  );
}

function* resolveChanges(
  rowEvents: RowEvent[],
  formId: string,
  formTemplate: GQL.FormTemplateFragment,
  schools: Pick<GQL.SimpleSchoolFragment, "id" | "name">[],
  grades: Pick<GQL.GetFormHistory_grade, "id" | "program">[],
  tags: Pick<GQL.GetFormHistory_enrollment_period_tag, "id" | "name">[]
): Generator<Change<string>> {
  const {
    form_events,
    form_answer_events,
    form_answer_option_events,
    form_school_rank_events,
    form_verification_result_events,
    form_disclaimer_events,
    grades_answer_events,
    waitlist_events,
    offer_events,
    form_school_offer_status_history_events,
    form_school_tag_events,
    form_tag_events,
    form_address_events,
  } = BackwardCompatibility.renameProperties(
    groupBy(
      rowEvents,
      (e) => BackwardCompatibility.tableName(e.table_name) + "_events"
    )
  );

  const allQuestions = includeAllAdditionalQuestions(
    formTemplate.sections.flatMap((s) => s.questions)
  );

  // Verifies if the event is a transition from NotConsidered to another status.
  // If so, it shouldn't appear as a cleared status log.
  const isNotConsideredTransition = (
    event: GQL.GetFormHistory_audit_form_transaction_logged_actions
  ): boolean => {
    if (!form_school_rank_events) return false;

    for (const transitionEvent of form_school_rank_events) {
      if (
        transitionEvent.action === "U" &&
        transitionEvent.changed_fields?.status ===
          GQL.form_school_rank_status_enum.NotConsidered
      ) {
        const { form_id: eventFormId, school_id: eventSchoolId } =
          event.row_data;
        const {
          form_id: notConsideredFormId,
          school_id: notConsideredSchoolId,
        } = transitionEvent.row_data;

        if (
          eventFormId === notConsideredFormId &&
          eventSchoolId === notConsideredSchoolId
        ) {
          return true;
        }
      }
    }

    return false;
  };

  if (form_answer_events) {
    for (const [questionId, specific_answer_events] of Object.entries(
      groupBy(form_answer_events, (e) => e.row_data.question_id)
    )) {
      const question = allQuestions.find((q) => q.id === questionId);
      const field = question?.question ?? `Question id:${questionId}`;

      switch (question?.type) {
        case GQL.question_type_enum.SingleSelect:
        case GQL.question_type_enum.MultiSelect:
          if (form_answer_option_events) {
            const typeAndValue = interpretQuestionSelectionChange(
              form_answer_option_events,
              question
            );

            if (typeAndValue) {
              yield { field, ...typeAndValue };
            }
          }
          break;
        case GQL.question_type_enum.FreeText:
        case GQL.question_type_enum.Email:
          const typeAndValue = interpretQuestionFreeTextChange(
            specific_answer_events
          );
          if (typeAndValue) {
            yield { field, ...typeAndValue };
          }
          break;

        case GQL.question_type_enum.PhoneNumber:
          const typeAndValuePhoneNumber = interpretQuestionFreeTextChange(
            specific_answer_events
          );
          if (typeAndValuePhoneNumber) {
            yield {
              field,
              ...typeAndValuePhoneNumber,
              value: formatUSPhoneNumber(typeAndValuePhoneNumber.value),
            };
          }
          break;

        case GQL.question_type_enum.FileUpload:
          // TODO: Handle this eventually.
          break;
        case GQL.question_type_enum.Grades:
        case GQL.question_type_enum.Address:
        case GQL.question_type_enum.CustomQuestion:
        case undefined:
          // Not handled (here).
          break;
      }
    }
  }

  if (grades_answer_events) {
    for (const [questionId, specific_answer_events] of Object.entries(
      groupBy(grades_answer_events, (e) => e.row_data.question_id)
    )) {
      const question = allQuestions.find((q) => q.id === questionId) as
        | GQL.QuestionFragment // Includes grades_question subtype.
        | undefined;
      if (question?.grades_question) {
        const typeAndValue = interpretGradesQuestionChange(
          specific_answer_events,
          question.grades_question
        );
        if (typeAndValue) {
          yield {
            field: question.question ?? `Grade applying for`,
            ...typeAndValue,
          };
        }
      }
    }
  }
  if (form_school_rank_events) {
    // show INSERT and UPDATE as EventType.Updated
    // show DELETE as EventType.Deleted
    // filter any UPDATE with lottery_order (this is not ranking changes)

    const valueOf = (row: any): CorrelatedValue => {
      return {
        value: row.school_id,
        sortKey: row.rank,
      };
    };

    const labelOf = (
      corrValue: CorrelatedValue,
      list: GQL.GetFormHistory_audit_form_transaction_logged_actions[]
    ) => {
      const found = list.find(
        (entry) => entry.row_data.school_id === corrValue.value
      );

      if (!found) {
        return null;
      }

      const rank = found.changed_fields?.rank ?? found.row_data.rank;

      return {
        value: `${rank + 1}. ${
          schools.find((s) => s.id === found.row_data.school_id)?.name
        }`,
        sortKey: rank,
      };
    };

    const {
      I: inserted = [],
      D: deleted = [],
      U: updated = [],
    } = groupBy(form_school_rank_events, (e) => e.action);

    const notConsideredStatusChangeEntries = updated.filter(
      (e) =>
        e.changed_fields?.status ===
        GQL.form_school_rank_status_enum.NotConsidered
    );

    for (const notConsideredEntry of notConsideredStatusChangeEntries) {
      updated.splice(updated.indexOf(notConsideredEntry));
      const school_name =
        schools.find((s) => s.id === notConsideredEntry.row_data?.school_id)
          ?.name ?? "ranked school";

      yield {
        field: `${school_name} status`,
        type: EventType.Updated,
        value:
          FORM_SCHOOL_RANK_SUB_STATUS[
            GQL.form_school_rank_status_enum.NotConsidered
          ]?.label ?? GQL.form_school_rank_status_enum.NotConsidered,
      };
    }

    const clearedStatusEntries = updated.filter(
      (e) => e.changed_fields?.status === null
    );
    for (const clearedStatusEntry of clearedStatusEntries) {
      updated.splice(updated.indexOf(clearedStatusEntry));

      const school_name =
        schools.find((s) => s.id === clearedStatusEntry.row_data?.school_id)
          ?.name ?? "ranked school";
      yield {
        field: `${school_name} status`,
        type: EventType.Cleared,
        value: "",
      };
    }

    const clearedOfferAndWaitlistEntries = updated.filter(
      (e) => e.changed_fields && "rank" in e.changed_fields
    );

    const updatedEntries = [
      ...inserted,
      ...clearedOfferAndWaitlistEntries.filter(
        (e) =>
          e.changed_fields?.lottery_order === null ||
          e.changed_fields?.lottery_order === undefined
      ),
    ];

    const correlatedList = correlateInsertsWithDeletes(
      deleted,
      updatedEntries,
      valueOf
    );

    const correlatedDeletes = correlatedList
      .filter((e) => e.wasPresentBefore && !e.wasPresentAfter)
      .map((e) => labelOf(e, deleted))
      .filter(isNotNull);

    const correlatedUpdates = correlatedList
      .filter((e) => e.wasPresentAfter)
      .map((e) => labelOf(e, updatedEntries))
      .filter(isNotNull);

    if (correlatedDeletes.length > 0) {
      yield {
        field: "School ranking",
        type: EventType.RemovedFrom,
        value: sortBy(correlatedDeletes, (x) => x.sortKey)
          .map((x) => x.value)
          .join(", "),
      };
    }

    if (correlatedUpdates.length > 0) {
      yield {
        field: "School ranking",
        type: EventType.Updated,
        value: sortBy(correlatedUpdates, (x) => x.sortKey)
          .map((x) => x.value)
          .join(", "),
      };
    }
  }

  if (form_verification_result_events) {
    for (const [formVerificationId, verificationResultEvents] of Object.entries(
      groupBy(
        form_verification_result_events,
        (e) => e.row_data.form_verification_id
      )
    )) {
      const typeAndValue = interpretTableChange(
        verificationResultEvents,
        (row) => ({
          value: row.verification_status ?? "Pending",
          sortKey: row.verification_status ?? "Pending",
        })
      );
      if (typeAndValue) {
        yield {
          field:
            formTemplate.form_verifications.find(
              (v) => v.id === formVerificationId
            )?.label + " verification",
          type: EventType.Updated,
          value: typeAndValue.value,
        };
      }
    }
  }

  if (form_disclaimer_events) {
    // TODO: Are there other kinds of events possible here?
    // Re: Not yet, but maybe in the future we need to revisit.
    yield {
      field: "Form signed",
      type: EventType.Implied,
      value: "",
    };
  }

  if (form_events) {
    for (const formEvent of form_events) {
      if (formEvent.row_data && formEvent.row_data.id !== formId) {
        continue;
      }

      if (formEvent.action === "I") {
        yield {
          field: "Form",
          type: EventType.Created,
          value: "",
        };
      } else if (formEvent.changed_fields?.status) {
        const status = formEvent.changed_fields.status;
        yield {
          field: "Form status",
          type: EventType.Updated,
          value: FORM_STATUS[status as GQL.form_status_enum]?.label ?? status,
        };
      }
    }
  }

  if (form_school_offer_status_history_events) {
    for (const schoolSubmittedEvent of form_school_offer_status_history_events) {
      if (
        schoolSubmittedEvent.row_data &&
        schoolSubmittedEvent.row_data.form_id !== formId
      ) {
        continue;
      }

      if (
        (schoolSubmittedEvent.action === "I" &&
          schoolSubmittedEvent.row_data?.submitted_at) ||
        schoolSubmittedEvent.changed_fields?.submitted_at
      ) {
        yield {
          field: "",
          type: EventType.Submitted,
          value:
            schools.find(
              (s) => s.id === schoolSubmittedEvent.row_data.school_id
            )?.name ?? "ranked school",
        };
      }
    }
  }

  if (offer_events) {
    for (const offerEvent of offer_events) {
      if (offerEvent.row_data && offerEvent.row_data.form_id !== formId) {
        continue;
      }
      const school_name =
        schools.find((s) => s.id === offerEvent.row_data?.school_id)?.name ??
        "";
      const gradeId =
        offerEvent.changed_fields?.grade_id ?? offerEvent.row_data.grade_id;
      const program = grades.find((g) => g.id === gradeId)?.program?.label;
      if (
        offerEvent.action === "I" ||
        (offerEvent.action === "U" && offerEvent.changed_fields?.status)
      ) {
        const status = (
          offerEvent.action === "U"
            ? offerEvent.changed_fields?.status
            : offerEvent.row_data.status
        ) as GQL.offer_status_enum;
        const offerStatusValue =
          FORM_SCHOOL_RANK_SUB_STATUS[status]?.label ?? status;
        yield {
          field: `${school_name} status`,
          type: EventType.Updated,
          value:
            offerStatusValue +
            // TODO: Make the "to" not bold.
            (program ? ` to ${program}` : ""),
        };
      }

      if (offerEvent.action === "D" && !isNotConsideredTransition(offerEvent)) {
        yield {
          field: `${school_name} status`,
          type: EventType.Cleared,
          value: "",
        };
      }
    }
  }

  if (waitlist_events) {
    // Group by school to hide redundant waitlist events.
    const waitlistEventsBySchool = groupBy(
      waitlist_events.filter((e) => e.row_data.form_id === formId),
      (e) => e.row_data.school_id
    );

    for (const waitlistEvents of Object.values(waitlistEventsBySchool)) {
      // If multiple waitlist events are in the same transaction for a single
      // school, then we can assume they all represent the same type of event,
      // so we can just look at the first one.
      const waitlistEvent = waitlistEvents[0];
      if (!waitlistEvent) {
        continue;
      }

      const school_name =
        schools.find((s) => s.id === waitlistEvent.row_data.school_id)?.name ??
        "";
      if (
        waitlistEvent.action === "I" ||
        (waitlistEvent.action === "U" && waitlistEvent.changed_fields?.status)
      ) {
        const status = (
          waitlistEvent.action === "U"
            ? waitlistEvent.changed_fields?.status
            : waitlistEvent.row_data.status
        ) as GQL.waitlist_status_enum;
        const waitlistStatusValue =
          FORM_SCHOOL_RANK_SUB_STATUS[status]?.label ?? status;
        yield {
          field: `${school_name} status`,
          type: EventType.Updated,
          value: waitlistStatusValue,
        };
      }

      const isWaitlistToOfferTransition = (): boolean => {
        if (!offer_events) return false;

        for (const transitionEvent of offer_events) {
          if (transitionEvent.action === "I") {
            const {
              form_id: waitlistFormId,
              school_id: waitlistSchoolId,
              grade_id: waitlistGradeId,
            } = waitlistEvent.row_data;
            const {
              form_id: offerFormId,
              school_id: offerSchoolId,
              grade_id: offerGradeId,
            } = transitionEvent.row_data;

            if (
              waitlistFormId === offerFormId &&
              waitlistSchoolId === offerSchoolId &&
              waitlistGradeId === offerGradeId
            ) {
              return true;
            }
          }
        }

        return false;
      };

      if (
        waitlistEvent.action === "D" &&
        !isWaitlistToOfferTransition() &&
        !isNotConsideredTransition(waitlistEvent)
      ) {
        yield {
          field: `${school_name} status`,
          type: EventType.Cleared,
          value: "",
        };
      }
    }
  }

  if (form_school_tag_events) {
    for (const tagEvent of form_school_tag_events) {
      const schoolName =
        schools.find((s) => s.id === tagEvent.row_data?.school_id)?.name ?? "";
      const tag =
        tags.find((t) => t.id === tagEvent.row_data?.tag_id)?.name ?? "";
      let value = "";
      let eventType = EventType.Implied;
      switch (tagEvent.action) {
        case "I": {
          // Tag [tag name] added to [school name] by [user role] [user name].
          value = schoolName;
          eventType = EventType.AddedTo;
          break;
        }
        case "D": {
          value = schoolName;
          eventType = EventType.RemovedFrom;
          break;
        }
        default: {
          // do nothing
          break;
        }
      }

      if (value) {
        yield {
          field: value,
          value: `Tag ${tag}`,
          type: eventType,
        };
      }
    }
  }

  if (form_tag_events) {
    for (const tagEvent of form_tag_events) {
      const tag =
        tags.find((t) => t.id === tagEvent.row_data?.tag_id)?.name ?? "";
      let eventType = EventType.Implied;
      switch (tagEvent.action) {
        case "I": {
          // Tag [tag name] added to form by [user role] [user name].
          eventType = EventType.AddedTo;
          break;
        }
        case "D": {
          eventType = EventType.RemovedFrom;
          break;
        }
        default: {
          // do nothing
          break;
        }
      }

      yield {
        field: "form",
        value: `Tag ${tag}`,
        type: eventType,
      };
    }
  }

  if (form_address_events) {
    for (const formAddressEvent of form_address_events) {
      const { row_data, action, changed_fields } = formAddressEvent;
      const question = allQuestions.find((q) => q.id === row_data.question_id);

      const mergedFields = { ...row_data, ...changed_fields };

      const value = `${mergedFields.street_address}, ${mergedFields.street_address_line_2}, ${mergedFields.city}, ${mergedFields.state}, ${mergedFields.zip_code}`;
      yield {
        field: question?.question ?? `Question id:${row_data.question_id}`,
        value,
        type: action === "I" ? EventType.AddedTo : EventType.Updated,
      };
    }
  }

  // Handle custom question answers by looking for form answer events
  // associated with custom question field questions
  const allCustomQuestions = formTemplate.sections.flatMap((s) =>
    s.questions.filter((q) => isNotNull(q.custom_question))
  );
  for (const customQuestion of allCustomQuestions) {
    const fieldQuestions =
      customQuestion.custom_question?.custom_question_relationships.map(
        (cqr) => cqr.cloned_question
      ) ?? [];
    const fieldEvents = fieldQuestions
      .map((fieldQuestion) =>
        getChangeByCustomQuestionField(
          fieldQuestion,
          form_answer_events ?? [],
          form_answer_option_events ?? []
        )
      )
      .filter(isNotNull);
    if (fieldEvents.length) {
      yield aggregateCustomQuestionFieldEvents(fieldEvents, customQuestion);
    }
  }
}

type DisplayValue = {
  value: string;
  sortKey: number | string;
};

type CorrelatedValue = DisplayValue & {
  wasPresentBefore?: boolean;
  wasPresentAfter?: boolean;
};

// Pairs up insertion events with deletion events by the values they represent.
function correlateInsertsWithDeletes(
  deleted: RowEvent[],
  inserted: RowEvent[],
  valueOf: (row_data: jsonb) => DisplayValue
) {
  const entries: CorrelatedValue[] = deleted.map(({ row_data }) => ({
    ...valueOf(row_data),
    wasPresentBefore: true,
  }));
  for (const insertEvent of inserted) {
    const insertedValue = valueOf(insertEvent.row_data);
    const entry = entries.find((e) => e.value === insertedValue.value);
    if (entry) {
      entry.wasPresentAfter = true;
    } else {
      entries.push({
        ...insertedValue,
        wasPresentAfter: true,
      });
    }
  }
  return entries;
}

// Interprets a collection of insert, delete, and update events as a single
// change, or returns null if no meaningful changes were actually made.
function interpretTableChange(
  rowEvents: RowEvent[],
  valueOf: (row_data: jsonb) => DisplayValue
) {
  const {
    I: inserted,
    D: deleted,
    U: updated,
  } = groupBy(rowEvents, (e) => e.action);
  if (updated) {
    const changes = updated.filter(
      (e) =>
        valueOf(e.row_data).value !==
        valueOf({ ...e.row_data, ...e.changed_fields }).value
    );
    if (changes.length === 0) return null;
    // TODO: Treat null/empty strings as added/removed instead of updated.
    return {
      type: EventType.Updated,
      value: sortBy(
        changes.map((e) => valueOf({ ...e.row_data, ...e.changed_fields })),
        (x) => x.sortKey
      )
        .map((x) => x.value)
        .join(", "),
    };
  } else if (inserted || deleted) {
    const correlated = correlateInsertsWithDeletes(
      deleted ?? [],
      inserted ?? [],
      valueOf
    );
    if (correlated.every((v) => v.wasPresentBefore === v.wasPresentAfter)) {
      return null;
    }
    return {
      type: inserted
        ? deleted
          ? EventType.Updated
          : EventType.AddedTo
        : EventType.RemovedFrom,
      value: sortBy(
        correlated.filter((value) =>
          inserted ? value.wasPresentAfter : value.wasPresentBefore
        ),
        (x) => x.sortKey
      )
        .map((x) => x.value)
        .join(", "),
    };
  }
  // There were no inserts, deletes, or updates.
  return null;
}

function interpretQuestionSelectionChange(
  form_answer_option_events: RowEvent[],
  question: GQL.FormQuestionWithoutBranchingFragment
) {
  const questionOptions = question.form_question?.form_question_options ?? [];
  const relevantAnswerEvents = form_answer_option_events.filter((e) =>
    questionOptions.find((o) => o.id === e.row_data.form_question_option_id)
  );
  // This relies on the fact that, in the current implementation, all currently
  // selected options are removed and the new ones are inserted, even if there
  // was overlap between the two sets.  Hence we can know the completed new set
  // of selected options just by looking at the inserted values.
  return interpretTableChange(relevantAnswerEvents, (row) => {
    const option = questionOptions.find(
      (o) => o.id === row.form_question_option_id
    );
    return {
      value: option?.label ?? row.form_question_option_id,
      sortKey: option?.order ?? Infinity,
    };
  });
}

function interpretGradesQuestionChange(
  grades_answer_events: RowEvent[],
  question: GQL.QuestionFragment_grades_question
) {
  const questionOptions =
    question.grades.map((g) => g.grade_config).filter(isNotNull) ?? [];
  return interpretTableChange(grades_answer_events, (row) => {
    const option = questionOptions.find((o) => o.id === row.grade_config_id);
    return {
      value: option?.label ?? row.grade_config_id,
      sortKey: option?.order ?? Infinity,
    };
  });
}

function interpretQuestionFreeTextChange(form_answer_events: RowEvent[]) {
  // TODO: Resolve changes to/from the empty string.
  return interpretTableChange(form_answer_events, (row) => ({
    value: row.free_text_answer ?? "",
    sortKey: row.free_text_answer ?? "",
  }));
}

function getChangeByCustomQuestionField(
  fieldQuestion: GQL.FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question,
  form_answer_events: GQL.GetFormHistory_audit_form_transaction_logged_actions[],
  form_answer_option_events: GQL.GetFormHistory_audit_form_transaction_logged_actions[]
): Change<string> | undefined {
  const fieldAnswerEvents =
    form_answer_events?.filter(
      (formAnswerEvent) =>
        formAnswerEvent.row_data.question_id === fieldQuestion.id
    ) ?? [];
  const field = fieldQuestion?.question ?? "";

  // This logic is essentially the same as for processing non-CQT form_answer_events.
  // TODO: combine / refactor the code
  switch (fieldQuestion?.type) {
    case GQL.question_type_enum.SingleSelect:
      if (form_answer_option_events) {
        const typeAndValue = interpretQuestionSelectionChange(
          form_answer_option_events,
          fieldQuestion
        );
        if (typeAndValue) {
          return { field, ...typeAndValue };
        }
      }
      break;
    case GQL.question_type_enum.FreeText:
    case GQL.question_type_enum.Email:
      const typeAndValue = interpretQuestionFreeTextChange(fieldAnswerEvents);
      if (typeAndValue) {
        return { field, ...typeAndValue };
      }
      break;
    case GQL.question_type_enum.PhoneNumber:
      const typeAndValuePhoneNumber =
        interpretQuestionFreeTextChange(fieldAnswerEvents);
      if (typeAndValuePhoneNumber) {
        return {
          field,
          ...typeAndValuePhoneNumber,
          value: formatUSPhoneNumber(typeAndValuePhoneNumber.value),
        };
      }
      break;
    case undefined:
      break;
  }
}

function aggregateCustomQuestionFieldEvents(
  fieldEvents: Change<string>[],
  customQuestion: GQL.FormTemplateFragment_sections_questions
): Change<string> {
  const field: string = customQuestion.question;
  // If any fields were updated, consider the entire custom question answer to be updated.
  // Otherwise, if any fields were added, consider the entire custom question to have been added.
  // If no fields were updated or added, the custom question answer was deleted.
  const type: EventType = fieldEvents.find(
    (fieldEvent) => fieldEvent.type === EventType.Updated
  )
    ? EventType.Updated
    : fieldEvents.find((fieldEvent) => fieldEvent.type === EventType.AddedTo)
    ? EventType.AddedTo
    : EventType.RemovedFrom;
  const value: string = fieldEvents
    .map((fieldEvent) => `${fieldEvent.field}: ${fieldEvent.value}`)
    .join(", ");
  return {
    field,
    type,
    value,
  };
}
