import { Box, Button } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { ReactComponent as ConnectSvg } from "src/images/connect.svg";

export type NowWebhooksProps = {
  doCreateDataService: () => void;
};

export const NowWebhooks = ({ doCreateDataService }: NowWebhooksProps) => {
  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description={
          <WithUserPermissions permissions={["data_services:create"]}>
            <Box marginTop="1.5rem">
              <Button
                onClick={doCreateDataService}
                variant="outline"
                colorScheme="gray"
              >
                Create data service
              </Button>
            </Box>
          </WithUserPermissions>
        }
        heading="No Webhooks"
        Svg={ConnectSvg}
      />
    </Box>
  );
};
