import { Flex, Spacer, Text } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { VerificationTag } from "src/components/DataDisplay/VerificationTag";
import * as GQL from "src/types/graphql";

type Props = {
  verificationSummary: GQL.GetVerificationsForAdmin;
};

export const VerificationSummary: React.FC<Props> = ({
  verificationSummary,
}) => {
  if (verificationSummary.form_verification.length === 0) {
    return null;
  }

  return (
    <Flex
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      direction="column"
      padding={3}
      gap={3}
      minWidth="15rem"
    >
      <Text fontWeight="600">Verifications</Text>
      {_.sortBy(
        verificationSummary.form_verification,
        (verifications) => verifications.label
      ).map((verification, index) => {
        const result = verificationSummary.form_verification_result.find(
          (result) => result.form_verification_id === verification.id
        );
        return (
          <Flex key={index} minWidth="max-content" alignItems="center" gap={2}>
            <Text fontSize=".875rem">{verification.label}</Text>
            <Spacer />
            <VerificationTag
              status={
                result?.verification_status ??
                GQL.verification_status_enum.Pending
              }
              label={verification.label}
              showText="status"
            />
          </Flex>
        );
      })}
    </Flex>
  );
};
