import { useReactiveVar } from "@apollo/client";
import { useBranding } from "src/components/Providers/BrandingProvider";
import { DefaultBranding } from "src/configs/branding";
import { WindowWithAuth } from "./types";
import { useCallback, useMemo, useState } from "react";
import * as OrgConfig from "@avela/organization-config-sdk";

declare const window: WindowWithAuth;

export const useBrandingForAuth = () => {
  const { branding } = useBranding();
  return branding ?? DefaultBranding;
};

export const useIsParent = () => {
  const isParent = useReactiveVar(window.isParent);
  return isParent;
};

export const usePage = () => {
  const page = useReactiveVar(window.page);
  return page;
};

export const useHideFlags = () => {
  const hide = useReactiveVar(window.hide);
  return hide;
};

// TODO: use more precise type that include error state
export const useLoginMethods = ():
  | OrgConfig.Login.LoginMethod[]
  | undefined => {
  return useReactiveVar(window.loginMethods);
};

export const useAccountLookupUrl = (): string | undefined => {
  return useReactiveVar(window.accountLookupUrl);
};

export const useLoginMethodInUse = () => {
  const [loginMethodInUse, setLoginMethodInUse] =
    useState<OrgConfig.Login.LoginMethod | null>(null);

  const isSmsLoading = useMemo(() => {
    return loginMethodInUse?.method === "sms";
  }, [loginMethodInUse?.method]);

  const isEmailLoading = useMemo(() => {
    return loginMethodInUse?.method === "email";
  }, [loginMethodInUse?.method]);

  const isSsoLoading = useCallback(
    (identityProvider: string) => {
      return (
        loginMethodInUse?.method === "sso" &&
        loginMethodInUse.identityProvider === identityProvider
      );
    },
    [loginMethodInUse]
  );

  const isLoading = useMemo(() => {
    return loginMethodInUse !== null;
  }, [loginMethodInUse]);

  return {
    isLoading,
    setLoginMethodInUse,
    isSmsLoading,
    isEmailLoading,
    isSsoLoading,
  };
};
