import { List } from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import * as FormSchoolRank from "./FormSchoolRank";
import * as BoundaryService from "src/components/Boundary/services";
import { useMemo } from "react";
import * as RD from "src/types/remoteData";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";

export type Form = FormSchoolRank.Form;
export type RankedSchool = FormSchoolRank.RankedSchool & {
  readonly offers: readonly Offer[];
  readonly waitlists: readonly Waitlist[];
  readonly school: School;
};
export type School = BoundaryService.WithBoundaryTags<FormSchoolRank.School>;
export type Offer = FormSchoolRank.Offer;
export type Waitlist = FormSchoolRank.Waitlist;
interface FormSchoolRanksProps {
  formTemplateId: uuid;
  form: Form;
  formSchoolRanks: RankedSchool[];
  gradeLabel: string;
  studentName: string;
  rankingEnabled: boolean;
}

export function FormSchoolRanks(props: FormSchoolRanksProps) {
  const { form, formSchoolRanks, gradeLabel, studentName, rankingEnabled } =
    props;

  const acceptedOfferSchoolName = formSchoolRanks.find(
    (schoolRank) =>
      schoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted
  )?.school?.name;

  const { withBoundaryTags } = BoundaryService.useBoundaryTags<School>({
    formId: form.id,
  });

  const rankedSchoolsWithBoundaryTagsRemoteData = useMemo(() => {
    return RD.combine(
      formSchoolRanks.map((rankedSchool) => {
        const schoolWithBoundaryTags = withBoundaryTags(rankedSchool.school);

        return schoolWithBoundaryTags.map((school) => {
          return {
            ...rankedSchool,
            school,
          };
        });
      })
    );
  }, [formSchoolRanks, withBoundaryTags]);

  return (
    <List
      display="flex"
      flexDirection="column"
      fontSize="sm"
      gap={4}
      styleType={rankingEnabled ? "none" : "disc"}
      ml={0}
      mt={4}
    >
      <RemoteDataView
        error={(error) => <GenericError error={error} />}
        remoteData={rankedSchoolsWithBoundaryTagsRemoteData}
      >
        {(rankedSchools) => (
          <>
            {rankedSchools.map((schoolRank) => (
              <FormSchoolRank.FormSchoolRank
                formTemplateId={props.formTemplateId}
                acceptedOfferSchoolName={acceptedOfferSchoolName}
                form={form}
                offer={schoolRank.offers[0] ?? null}
                formSchoolRank={schoolRank}
                waitlist={schoolRank.waitlists[0] ?? null}
                gradeLabel={gradeLabel}
                key={schoolRank.rank}
                school={schoolRank.school}
                studentName={studentName}
                rankingEnabled={rankingEnabled}
              />
            ))}
          </>
        )}
      </RemoteDataView>
    </List>
  );
}
