import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import React from "react";

type Props = { isNew?: boolean };
export const UnpublishedChangesAlert: React.FC<Props> = ({ isNew = false }) => {
  return (
    <Alert status="warning" rounded="md">
      <AlertIcon />
      <AlertDescription>
        {isNew
          ? "This question is newly created but not published. It is not currently visible to parents."
          : "This question has changes that haven't been published and are not visible to parents."}
      </AlertDescription>
    </Alert>
  );
};
