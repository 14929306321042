import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { FormikAddressBook } from "src/components/Inputs/Address/Book";
import { InputControlWithBadge } from "src/components/Inputs/InputControlWithBadge";
import { PhoneInputWithBadge } from "src/components/Inputs/PhoneInputWithBadge";
import { NameForm } from "./NameForm";

type ParentFormProps = FlexProps & {
  username?: string;
  phoneLinked?: boolean;
  emailLinked?: boolean;
};

export const ParentForm: React.FC<ParentFormProps> = ({
  username,
  phoneLinked,
  emailLinked,
  ...flexProps
}) => {
  const phone = () => (
    <PhoneInputWithBadge
      badge={phoneLinked ? "Login" : ""}
      label={phoneLinked ? "Phone" : "Additional phone"}
      id="phone_number"
      name="phone_number"
      isDisabled={true}
    />
  );

  const email = () => (
    <InputControlWithBadge
      badge={emailLinked ? "Login" : ""}
      label={emailLinked ? "Email" : "Additional email"}
      id="email_address"
      name="email_address"
      isDisabled={true}
    />
  );

  return (
    <Flex
      align="left"
      width="100%"
      direction="column"
      gap={4}
      flexGrow="1"
      {...flexProps}
    >
      <NameForm
        firstName={{ label: "Parent/guardian first name" }}
        middleName={{ label: "Parent/guardian middle name" }}
        lastName={{ label: "Parent/guardian last name" }}
      />
      <FormikAddressBook fieldName="address" addressIsRequired />

      {phoneLinked ? (
        <>
          {phone()}
          {email()}
        </>
      ) : (
        <>
          {email()}
          {phone()}
        </>
      )}
    </Flex>
  );
};
