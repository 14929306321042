import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT } from "./queries";

export const CREATE_PROFILE = gql`
  ${PROFILE_FRAGMENT}
  mutation CreateProfile($person: person_insert_input!) {
    insert_person_one(object: $person) {
      id
      ...ProfileFragment
    }
  }
`;

export const UPDATE_PROFILE = gql`
  ${PROFILE_FRAGMENT}
  mutation UpdateProfile($id: uuid!, $person: person_set_input) {
    update_person_by_pk(pk_columns: { id: $id }, _set: $person) {
      ...ProfileFragment
    }
  }
`;

export const UPSERT_PROFILE_NAME = gql`
  mutation UpsertProfileName(
    $user_id: String!
    $first_name: String!
    $middle_name: String!
    $last_name: String!
    $organization_id: uuid!
  ) {
    insert_person_one(
      object: {
        user_id: $user_id
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        organization_id: $organization_id
      }
      on_conflict: {
        constraint: unique_user_organization
        update_columns: [first_name, last_name, middle_name]
      }
    ) {
      id
      first_name
      middle_name
      last_name
    }
  }
`;

export const UPSERT_PROFILE_ADDRESS = gql`
  mutation UpsertProfileAddress(
    $user_id: String!
    $street_address: String!
    $street_address_line_2: String!
    $city: String!
    $state: String!
    $zip_code: String!
    $organization_id: uuid!
  ) {
    insert_person_one(
      object: {
        user_id: $user_id
        street_address: $street_address
        street_address_line_2: $street_address_line_2
        city: $city
        state: $state
        zip_code: $zip_code
        organization_id: $organization_id
      }
      on_conflict: {
        constraint: unique_user_organization
        update_columns: [
          street_address
          street_address_line_2
          city
          state
          zip_code
        ]
      }
    ) {
      id
      street_address
      street_address_line_2
      city
      state
      zip_code
    }
  }
`;
