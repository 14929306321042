import {
  extendTheme,
  theme as baseTheme,
  ThemeOverride,
} from "@chakra-ui/react";
import { Branding } from "../../schemas/Branding";
import { radioTheme } from "./radio";

import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const buildDrawerTheme = (branding: Branding) => {
  const baseStyle = definePartsStyle({
    dialog: {
      bg: branding.colors.primary[50],
      paddingX: "4",
      paddingY: "2",
      gap: "6",
    },
    header: {
      fontSize: "1.5rem",
      fontWeight: "500",
      padding: "0",
    },
    closeButton: {
      position: "static",
      marginRight: "-2",
    },
    body: {
      paddingX: "0",
    },
  });
  return defineMultiStyleConfig({ baseStyle });
};

export const buildTheme = (branding: Branding) => {
  const theme: ThemeOverride = {
    fonts: {
      body: "Inter, sans-serif",
      heading: "Inter, sans-serif",
    },
    fontSizes: {
      xxs: "0.625rem",
      xs: "0.75rem",
      sm: "0.875rem",
      md: "1rem",
      lg: "1.25rem",
      xl: "1.5rem",
      "2xl": "1.875rem",
      "3xl": "2rem",
      "4xl": "2.875rem",
      "5xl": "3rem",
      "6xl": "4.25rem",
      // "7xl": "68px", // TODO: define 7xl font size
      // "8xl": "68px", // TODO: define 8xl font size
      // "9xl": "68px", // TODO: define 9xl font size
    },
    colors: {
      primary: branding.colors.primary,
      gray: {
        50: "#F7FAFC",
        100: "#EDF2F7",
        200: "#E2E8F0",
        300: "#CBD5E0",
        400: "#A0AEC0",
        500: "#718096",
        600: "#4A5568",
        700: "#2D3748",
        800: "#1A202C",
        900: "#171923",
      },
      green: {
        50: "#F0FFF4",
        100: "#C6F6D5",
        200: "#9AE6B4",
        300: "#68D391",
        400: "#48BB78",
        500: "#38A169",
        600: "#25855A",
        700: "#276749",
        800: "#22543D",
        900: "#1C4532",
      },
      red: {
        50: "#FFF5F5",
        100: "#FED7D7",
        200: "#FEB2B2",
        300: "#FC8181",
        400: "#F56565",
        500: "#E53E3E",
        600: "#C53030",
        700: "#9B2C2C",
        800: "#822727",
        900: "#63171B",
      },
    },
    components: {
      Drawer: buildDrawerTheme(branding),
      Badge: {
        variants: {
          solid: ({ colorScheme }) => {
            if (colorScheme === "green") {
              return {
                bg: "green.600",
              };
            }

            return {};
          },
        },
      },
      Button: {
        defaultProps: {
          colorScheme: "primary",
        },
        variants: {
          link: {
            display: "block",
          },
          outline: ({ colorScheme }) => {
            if (colorScheme === "gray") {
              return {
                borderColor: "gray.300",
              };
            }

            if (colorScheme === "white") {
              return {
                _hover: { bg: "blackAlpha.400" },
                _active: { bg: "whiteAlpha.300" },
              };
            }
            return {};
          },
          solid: ({ colorScheme }) => {
            if (colorScheme === "gray") {
              return {
                bg: "gray.200",
                _hover: { bg: "gray.300" },
              };
            }
            return {};
          },
          tag: (props) => ({
            ...baseTheme.components.Button.variants?.ghost(props),
            color: "gray.700",
            fontWeight: "600",
            _active: {
              bg: "blackAlpha.200",
            },
            _hover: {
              bg: "blackAlpha.200",
            },
          }),
          banner: (props) => ({
            ...baseTheme.components.Button.variants?.ghost(props),
            color: "gray.700",
            fontSize: "sm",
            _active: {
              bg: "gray.300",
            },
            _hover: {
              bg: "gray.300",
            },
          }),
        },
        sizes: {
          xl: {
            height: "48px",
            fontSize: "lg",
            padding: "10px 24px",
          },
        },
      },
      Checkbox: {
        baseStyle: {
          container: {
            _hover: {
              borderColor: "blue.500",
            },
          },
        },
        variants: {
          ellipsis: {
            container: {
              overflowX: "hidden",
            },
            label: {
              display: "block",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            },
          },
          opaque: {
            control: {
              bg: "white",
            },
          },
        },
      },
      Radio: radioTheme,
      FormLabel: {
        baseStyle: {
          fontSize: "sm",
        },
      },
      Input: {
        variants: {
          outline: {
            field: {
              bg: "white",
            },
          },
        },
      },
      Select: {
        variants: {
          outline: {
            field: {
              bg: "white",
            },
          },
        },
      },
      Heading: {
        sizes: {
          md: {
            fontSize: "md",
            fontWeight: 600,
          },
          lg: {
            fontSize: "2xl",
            fontWeight: 600,
          },
          xl: {
            fontSize: "3xl",
            fontWeight: 400,
          },
        },
      },
      Divider: {
        baseStyle: {
          borderColor: "gray.300",
        },
      },
      Text: {
        variants: {
          placeholder: {
            opacity: "36%",
            fontStyle: "italic",
          },
          filterHeader: {
            fontSize: "lg",
            fontWeight: 700,
          },
          filterLabel: {
            fontSize: "md",
            fontWeight: 700,
          },
          helperText: {
            fontSize: "xs",
            fontWeight: 400,
            color: "gray.500",
          },
        },
      },
      Tag: {
        variants: {
          tag: {
            container: {
              fontSize: "xs",
              borderRadius: "md",
              color: "gray.700",
              fontWeight: "500",
              bg: "blackAlpha.200",
            },
          },
        },
      },
    },
  };

  return extendTheme(theme);
};
