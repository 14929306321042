import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { Glossary } from "src/components/Text/Glossary";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  emptySchool,
  SchoolFormType,
  SchoolValidator,
} from "src/schemas/School";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { SchoolForm } from "../components/Forms/SchoolForm";
import { CREATE_SCHOOL } from "./graphql/mutations";
import { GET_SCHOOLS } from "./graphql/queries";

export const NewSchool = () => {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);

  const navigate = useNavigate();
  const organization = useOrganization();
  const [createSchool] = useRemoteDataMutation<
    GQL.CreateSchool,
    GQL.CreateSchoolVariables
  >(CREATE_SCHOOL);
  const [getSchools] = useLazyRemoteDataQuery<
    GQL.GetSchools,
    GQL.GetSchoolsVariables
  >(GET_SCHOOLS, { fetchPolicy: "network-only" });
  const toast = useAvelaToast();

  const handleSubmit = async (values: SchoolFormType) => {
    organization.do(async (org) => {
      setSubmitting(true);
      try {
        await createSchool({
          variables: {
            school: {
              ...values,
              organization_id: org.id,
            },
          },
        });
        await getSchools({
          variables: {
            organization_id: org.id,
            order_by: [{ name: GQL.order_by.asc }],
          },
        });
        navigate(Url.OrgAdmin.Schools.index(org));
      } catch (error) {
        console.error(error);
        toast.error({ title: glossary`Error adding school` });
      } finally {
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Breadcrumb
        items={breadcrumb.school.getBreadcrumbsForNew(organization)}
        mb={8}
      />

      <Formik<SchoolFormType>
        initialValues={emptySchool}
        onSubmit={handleSubmit}
        validate={validateWithZod(SchoolValidator)}
      >
        {() => (
          <Flex as={Form} direction="column" gap={6}>
            <Heading as="h1" fontSize="2xl" fontWeight="600">
              <Glossary>Create school</Glossary>
            </Heading>
            <SchoolForm glossary={glossary} />
            <Flex align="center" gap={3}>
              <Spacer />
              <Button
                as={NavLink}
                to={organization
                  .map((org) => Url.OrgAdmin.Schools.index(org))
                  .withDefault("#")}
                variant="link"
              >
                Cancel
              </Button>
              <Button type="submit" marginLeft={4} isLoading={submitting}>
                Create
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </>
  );
};
