import { Button, Flex } from "@chakra-ui/react";
import { RiAddFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { ParentFormsLayoutInner } from "src/components/Layout/ParentFormsLayoutInner";
import { ParentFormsLayoutOuter } from "src/components/Layout/ParentFormsLayoutOuter";
import { Glossary } from "src/components/Text/Glossary";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { StudentListItem } from "./components/StudentListItem";
import { Empty } from "./Empty";
import { GET_STUDENTS_BY_GUARDIAN } from "./graphql/queries";

export function List() {
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const userProfile = useUserProfile();

  const { refetch, remoteData: studentsRemoteData } = useRemoteDataQuery<
    GQL.GetStudentsByGuardianId,
    GQL.GetStudentsByGuardianIdVariables
  >(GET_STUDENTS_BY_GUARDIAN, {
    fetchPolicy: "no-cache",
    skip: !organization.hasData() || !userProfile.hasData(),

    variables: {
      guardian_id: userProfile.map((user) => user.id).withDefault(""),
      include_form_count: true,
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
  });

  return (
    <ParentFormsLayoutOuter heading={glossary`Students`}>
      <ParentRemoteDataLayout remoteData={studentsRemoteData}>
        {(students) => {
          if (students.person.length === 0) {
            return (
              <ParentFormsLayoutInner>
                <Empty />
              </ParentFormsLayoutInner>
            );
          }

          const footers = (
            <ParentFormsLayoutFooter justifyContent="stretch">
              <Button
                as={Link}
                flexGrow={1}
                leftIcon={<RiAddFill />}
                to={organization.map(Url.Parent.Student.new).withDefault("#")}
              >
                <Glossary>New student</Glossary>
              </Button>
            </ParentFormsLayoutFooter>
          );

          return (
            <ParentFormsLayoutInner footers={footers}>
              <Flex flexDirection="column" gap={5}>
                {students.person.map((person) => (
                  <StudentListItem
                    key={person.id}
                    onDeleteStudentSuccess={refetch}
                    organization={organization}
                    person={person}
                  />
                ))}
              </Flex>
            </ParentFormsLayoutInner>
          );
        }}
      </ParentRemoteDataLayout>
    </ParentFormsLayoutOuter>
  );
}
