import { PriorityGroup, TagGroup } from "src/types/priorityTemplate";

export const findPriorityGroupForTags = (
  tagNames: string[],
  priorityGroups: PriorityGroup[]
) => {
  const index = priorityGroups.findIndex((priorityGroup) =>
    priorityGroup.some((tagGroup) => matchesTagGroup(tagNames, tagGroup))
  );
  return index >= 0 ? index : null;
};

function matchesTagGroup(tagNames: string[], tagGroup: TagGroup) {
  for (const tag of tagGroup) {
    if (typeof tag === "string") {
      if (!tagNames.includes(tag)) return false;
    } else {
      if (tagNames.includes(tag.not)) return false;
    }
  }
  return true;
}
