import { Box, BoxProps, usePrefersReducedMotion } from "@chakra-ui/react";
import { motion, MotionProps } from "framer-motion";
import * as React from "react";

const BoxWithForwardRef = React.forwardRef<any, React.PropsWithChildren>(
  (props, ref) => {
    return (
      <Box {...props} ref={ref}>
        {props.children}
      </Box>
    );
  }
);

type BoxPropsWithoutTransition = Omit<BoxProps, "transition">;
const MotionBoxPrimitive = motion<BoxPropsWithoutTransition>(BoxWithForwardRef);

type Props = { disableAnimation?: boolean } & BoxPropsWithoutTransition &
  MotionProps & {
    forwardedref?: React.MutableRefObject<any>;
  };

export const MotionBox = ({
  disableAnimation = false,
  children,
  ...props
}: Props): React.ReactElement => {
  const prefersReducedMotion = usePrefersReducedMotion();

  if (disableAnimation) {
    const { layout, transition, ...boxProps } = props;
    return <Box {...boxProps}>{children}</Box>;
  }

  return (
    <MotionBoxPrimitive
      {...props}
      ref={props.forwardedref}
      layout={prefersReducedMotion ? false : props.layout}
    >
      {children}
    </MotionBoxPrimitive>
  );
};
