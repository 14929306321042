import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = {
  heading: string;
  description: string;
};

export type StepProps = {
  goToNext: () => void;
};

export const CARD_PROPS = {
  direction: "column" as FlexProps["direction"],
  bg: "white",
  borderRadius: "md",
  boxShadow: "md",
  width: "24rem",
  maxWidth: "24rem",
};

export const AccountLookupStep: FunctionComponent<PropsWithChildren<Props>> = (
  props
) => {
  const { heading, description, children } = props;

  return (
    <Flex direction="column" gap={6} alignItems="center">
      <Flex direction="column" textAlign="center" gap={2}>
        <Text fontSize="lg" fontWeight={600} color="gray.700">
          {heading}
        </Text>
        <Text fontSize="md" fontWeight={400} color="gray.600">
          {description}
        </Text>
      </Flex>
      <Flex {...CARD_PROPS} padding={6}>
        {children}
      </Flex>
    </Flex>
  );
};
