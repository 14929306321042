import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Messages",
      href: organization
        .map((org) => Url.OrgAdmin.Messages.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForSendMessage = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  params: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Send message",
      href: organization
        .map((org) => Url.OrgAdmin.Messages.new(org, params.toString()))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForView = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  message: GQL.GetMessageById_message_adhoc_by_pk
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Message",
      href: Url.OrgAdmin.Messages.view(organization, message.id),
    },
  ];
};
