import { Flex } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FunctionComponent, useCallback } from "react";
import { AddressAutocomplete } from "src/components/Inputs/Address/Autocomplete/AddressAutocompleteInput";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { AddressFormType } from "src/schemas/Address";
import { GoogleMapsAutoCompleteAddress } from "src/services/googlePlaces/transforms";

export type Props = {
  isRequired?: boolean;
  isDisabled?: boolean;
  fieldPrefix?: string;
  autoFocus?: boolean;
};

export const AddressForm: FunctionComponent<Props> = (props) => {
  const { isRequired, isDisabled, fieldPrefix, autoFocus } = props;
  const formik = useFormikContext<AddressFormType>();

  const withPrefix = useCallback(
    (name: string) => {
      if (!fieldPrefix) {
        return name;
      }

      return [fieldPrefix, name].join(".");
    },
    [fieldPrefix]
  );

  const setAddressFields = (address: GoogleMapsAutoCompleteAddress) => {
    for (const [key, value] of Object.entries(address)) {
      formik.setFieldValue(withPrefix(key), value ?? "");
    }

    setTimeout(() => {
      formik.validateForm();
    });
  };

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
      alignItems="flex-end"
      gap={{ md: 3 }}
    >
      <AddressAutocomplete onAddressSelected={setAddressFields}>
        <InputWithValidationControl
          isRequired={isRequired}
          label="Address"
          inputProps={{ placeholder: "E.g.: 532 Oakland Ave", autoFocus }}
          name={withPrefix("street_address")}
          id="street_address"
          isDisabled={isDisabled}
        />
      </AddressAutocomplete>
      <InputWithValidationControl
        isRequired={false}
        inputProps={{ placeholder: "E.g.: 5B" }}
        name={withPrefix("street_address_line_2")}
        id="street_address_line_2"
        label="Apartment/suite (Optional)"
        isDisabled={isDisabled}
      />
      <InputWithValidationControl
        isRequired={isRequired}
        inputProps={{ placeholder: "E.g.: New Orleans" }}
        name={withPrefix("city")}
        id="city"
        label="City"
        isDisabled={isDisabled}
      />
      <Flex gap={3} alignItems="flex-end">
        <InputWithValidationControl
          isRequired={isRequired}
          inputProps={{ placeholder: "E.g.: LA" }}
          name={withPrefix("state")}
          id="state"
          label="State"
          isDisabled={isDisabled}
        />
        <InputWithValidationControl
          isRequired={isRequired}
          inputProps={{ placeholder: "E.g.: 10181" }}
          name={withPrefix("zip_code")}
          id="zip_code"
          label="Zip code"
          isDisabled={isDisabled}
        />
      </Flex>
    </Grid>
  );
};
