import * as DateFns from "date-fns";
import {
  AdditionalQuestions,
  Eligibility,
  Option,
  PermissionLevel,
  QuestionLink,
  Requirement,
  Verification,
} from "src/components/Form/QuestionForm/formik/types";
import * as AF from "src/types/formTemplate";
import {
  BirthdateEligibilityFilter,
  EligibleFilterType,
} from "src/types/formTemplateFilters";

export const QUESTION_TYPE_TEXT: Record<
  AF.QuestionType,
  { label: string; description: string }
> = {
  SingleSelect: {
    label: "Single select",
    description: "Parent can select one of the options below.",
  },
  FreeText: {
    label: "Free text",
    description: "Parent can enter any text to answer this question.",
  },
  FileUpload: {
    label: "File upload",
    description: "Parent will be prompted to upload a file.",
  },
  MultiSelect: {
    label: "Multi select",
    description:
      "Parent can select as many of the options below as they'd like.",
  },
  Grades: {
    label: "Grades",
    description: "Parent will be prompted to enter in their child's grades.",
  },
  Email: {
    label: "Email address",
    description: "Parent will be prompted to enter a valid email address",
  },
  PhoneNumber: {
    label: "Phone number",
    description: "Parent will be prompted to enter a valid phone number",
  },
  Address: {
    label: "Address",
    description: "Parent will be prompted to enter a valid address",
  },
  CustomQuestion: {
    label: "Custom question type",
    description: "",
  },
};

export const DEFAULT_QUESTION_TYPE = AF.FreeTextType;

export const DEFAULT_VERIFICATION: Verification = {
  type: "disabled",
  id: "",
  label: "",
};

export const DEFAULT_ELIGIBILITY: Eligibility = {
  type: "disabled",
};

export const DEFAULT_REQUIREMENT: Requirement = undefined;

export const DEFAULT_ADDITIONAL_QUESTIONS: AdditionalQuestions = {
  type: "disabled",
  questionIds: [],
};

export const DEFAULT_PERMISSION_LEVEL: PermissionLevel = {
  type: "disabled",
  level: undefined,
};

export const DEFAULT_QUESTION_LINK: QuestionLink = {
  type: "disabled",
  url: "",
  text: "",
};

export const DEFAULT_BIRTHDATE_FILTER: BirthdateEligibilityFilter = {
  type: EligibleFilterType.BirthdateBetweenFilter,
  config: {
    startDate: DateFns.format(new Date(), "yyyy-MM-dd"),
    endDate: DateFns.format(new Date(), "yyyy-MM-dd"),
    keys: [],
  },
};

export const DEFAULT_OPTION: Option = { label: "", isNew: true };
