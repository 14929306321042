import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { FormCustomQuestionPathParams } from "src/services/url/Parent";
import { CustomQuestionDataView } from "./DataView";
import { ListAnswerBankRecords } from "./ListAnswerBankRecords";
import { CustomQuestionPageLayout } from "./PageLayout";
import { CustomQuestionContextProvider } from "./context/CustomQuestionContext";
import { CreateAnswerBankEntryForm } from "./form/CreateAnswerBankEntryForm";
import { EditAnswerBankEntryForm } from "./form/EditAnswerBankEntryForm";
import { AnswerBankRecord } from "./list/schemas/AnswerBank";

export const SelectAnswerBank: FunctionComponent<{}> = () => {
  const [showAnswerBank, setShowAnswerBank] = useState(true);
  const [answerToEdit, setAnswerToEdit] = useState<AnswerBankRecord | null>(
    null
  );
  const { formId = "" } = useParams<FormCustomQuestionPathParams>();

  return (
    <CustomQuestionDataView>
      {(data) => {
        const {
          organization,
          userProfile,
          customQuestion,
          customQuestionType,
        } = data;

        return (
          <CustomQuestionContextProvider
            organization={organization}
            customQuestion={customQuestion}
            customQuestionType={customQuestionType}
            formId={formId}
            personId={userProfile.id}
            setShowAnswerBank={setShowAnswerBank}
            setAnswerToEdit={setAnswerToEdit}
          >
            <CustomQuestionPageLayout
              organization={organization}
              formId={formId}
              customQuestion={customQuestion}
            >
              {showAnswerBank ? (
                <ListAnswerBankRecords />
              ) : answerToEdit ? (
                <EditAnswerBankEntryForm initialAnswer={answerToEdit} />
              ) : (
                <CreateAnswerBankEntryForm />
              )}
            </CustomQuestionPageLayout>
          </CustomQuestionContextProvider>
        );
      }}
    </CustomQuestionDataView>
  );
};
