import {
  Button,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { UploadJsonInput } from "src/components/Inputs/UploadJsonInput";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { CREATE_GRADES_CONFIG } from "./graphql/mutations";
import { GET_ORGANIZATIONS_BY_GRADES_CONFIG } from "./graphql/queries";
import { GradesConfigList } from "./List";
import { GradesConfig, GradeType } from "./types";

export function Index() {
  const toast = useToast();

  const { remoteData, refetch } =
    useRemoteDataQuery<GQL.GetOrganizationsByGradesConfig>(
      GET_ORGANIZATIONS_BY_GRADES_CONFIG
    );

  const [createGradesConfig] =
    useRemoteDataMutation<GQL.CreateGradesConfig>(CREATE_GRADES_CONFIG);

  const handleJsonUpload = async (data: string) => {
    try {
      const parsedJSON = JSON.parse(data);
      const { success } = GradesConfig.safeParse(parsedJSON);
      if (!success) {
        throw new Error("We were unable to parse the JSON");
      }
      const variables: GQL.CreateGradesConfigVariables = {
        grades_config: parsedJSON.grades.map(
          (grade: GradeType): GQL.grade_config_insert_input => ({
            organization_id: parsedJSON.organizationId,
            label: grade.label,
            order: grade.order,
            value: grade.value,
          })
        ),
      };
      await createGradesConfig({
        variables,
        refetchQueries: [GET_ORGANIZATIONS_BY_GRADES_CONFIG],
      });
      toast({
        id: "uploading-grade-config-json",
        title: "Grades configured",
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      console.error(error);
      toast({
        id: "parsing-grade-config-json",
        title: "Parsing JSON",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleDelete = () => {
    refetch();
    toast({
      title: "Grades configuration deleted",
      status: "success",
      isClosable: true,
    });
  };

  return (
    <VStack align="left" gap={8}>
      <Heading as="h1">Grades configuration</Heading>
      <HStack align="center">
        <UploadJsonInput onUpload={handleJsonUpload} />
        <Text>or</Text>
        <Button
          as={NavLink}
          to={Url.Admin.GradesConfig.new()}
          variant="outline"
        >
          Create from scratch
        </Button>
      </HStack>
      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <GradesConfigList
            gradesConfig={data.organization}
            onDelete={handleDelete}
          />
        )}
      </GQLRemoteDataView>
    </VStack>
  );
}

export { Edit } from "./Edit";
export { New } from "./New";
