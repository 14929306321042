import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { Card } from "src/components/Layout/Card";
import { useDeleteConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import { NowWebhooks } from "./NoWebhooks";
import { WebhookBasic } from "./types";
import { useWebhookManagementAPI } from "./useWebhookManagementAPI";

export type WebhookListProps = {
  doCreateDataService: () => void;
};

export const WebhookList = ({ doCreateDataService }: WebhookListProps) => {
  const organization = useOrganization();
  const toast = useToast();
  const api = useWebhookManagementAPI();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isError, setError] = React.useState<boolean>(false);
  const [webhooks, setWebhooks] = React.useState<WebhookBasic[]>([]);
  const [deleting, setDeleting] = React.useState<string>();
  const [patching, setPatching] = React.useState<string>();
  const { confirm, confirmationDialog } = useDeleteConfirmationDialog({});

  const loadWebhook = React.useCallback(() => {
    if (!organization.hasData()) return;

    setLoading(true);

    api
      .getWebhooks(organization.data.id)
      .then((webhooks): void => {
        if (webhooks) {
          setWebhooks(webhooks);
        }
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.hasData, api.getWebhooks]);

  const onDelete = React.useCallback(
    async (pk: string) => {
      if (!organization.hasData()) return;

      if (!(await confirm())) return;

      setDeleting(pk);

      api
        .deleteWebhook(organization.data.id, pk)
        .then(() => {
          toast({
            id: "delete-webhook",
            title: `Webhook deleted`,
            isClosable: true,
            status: "info",
          });
          loadWebhook();
        })
        .catch((error) => {
          console.log(error);
          toast({
            id: "delete-webhook",
            title: `Something went wrong!`,
            description: "Check your network and try again.",
            isClosable: true,
            status: "error",
          });
        })
        .finally(() => setDeleting(undefined));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization.hasData, api.deleteWebhook]
  );

  const onActiveToggle = React.useCallback(
    async (pk: string) => {
      if (!organization.hasData()) return;

      setPatching(pk);

      api
        .patchWebhook(organization.data.id, pk, {
          is_active: !webhooks.find((w) => w.pk === pk)?.is_active,
        })
        .then(() => {
          toast({
            id: "patch-webhook",
            title: `Webhook updated`,
            isClosable: true,
            status: "info",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            id: "patch-webhook",
            title: `Something went wrong!`,
            description: "Check your network and try again.",
            isClosable: true,
            status: "error",
          });
        })
        .finally(() => {
          setPatching(undefined);
          loadWebhook();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization.hasData, api.patchWebhook, webhooks]
  );

  React.useEffect(() => {
    loadWebhook();
  }, [loadWebhook]);

  if (isLoading) {
    return (
      <Box display="grid" minHeight="50vh" placeContent="center">
        <Spinner color="primary.500" size="lg" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="grid" minHeight="50vh" placeContent="center">
        <Text fontSize="xl" textColor="red.500" fontWeight="700">
          Something went wrong...
        </Text>
      </Box>
    );
  }

  return (
    <>
      {webhooks.length === 0 && (
        <NowWebhooks doCreateDataService={doCreateDataService}></NowWebhooks>
      )}

      {webhooks.length > 0 && (
        <Flex direction="column" gap="6">
          {webhooks.map((webhook) => (
            <WebhookListItem
              key={webhook.pk}
              item={webhook}
              editLink={organization
                .map((org) => `${Url.OrgAdmin.Webhooks.edit(org, webhook.pk)}`)
                .withDefault("#")}
              onDelete={onDelete}
              onActiveToggle={onActiveToggle}
              isDeleting={deleting === webhook.pk}
              isPatching={patching === webhook.pk}
            ></WebhookListItem>
          ))}
          {confirmationDialog}
        </Flex>
      )}
    </>
  );
};

type WebhookListItemProps = {
  item: WebhookBasic;
  editLink: string;
  onDelete: (pk: string) => void;
  onActiveToggle: (pk: string) => void;
  isDeleting: boolean;
  isPatching: boolean;
};

const WebhookListItem = ({
  item,
  editLink,
  onDelete,
  onActiveToggle,
  isDeleting,
  isPatching,
}: WebhookListItemProps) => {
  return (
    <Card
      display="flex"
      showBorder
      padding={6}
      width="100%"
      justifyContent="space-between"
    >
      <Center width="100%" gap="6">
        <Text fontSize="lg" textColor="gray.800" fontWeight="500">
          {item.name}
        </Text>
        <Spacer />
        <Switch
          disabled={isPatching}
          isChecked={item.is_active}
          onChange={() => onActiveToggle(item.pk)}
        >
          Active
        </Switch>
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => onDelete(item.pk)}
          isLoading={isDeleting}
        >
          Delete
        </Button>
        <Button
          as={NavLink}
          to={editLink}
          variant="outline"
          colorScheme="gray"
          leftIcon={<RiEditLine />}
        >
          Edit
        </Button>
      </Center>
    </Card>
  );
};
