import React, { useEffect } from "react";
import { ReactComponent as NoAlarmSvg } from "src/images/no-alarm.svg";
import { EmptyState } from "src/components/EmptyState";
import { EligibilityServiceError as ErrorClass } from "src/services/eligibility/useEligibilityService";
import { GenericError } from "src/components/Feedback/GenericError";

type Props = {
  error: Error;
};
export const EligibilityServiceError: React.FC<Props> = ({ error }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);
  if (error instanceof ErrorClass) {
    return (
      <EmptyState
        paddingBottom={10}
        description="Our engines are not powering up. Try again later."
        heading="Unable to check eligibility"
        Svg={NoAlarmSvg}
      />
    );
  }

  return <GenericError error={error} />;
};
