import { ApolloError } from "@apollo/client";
import { Alert, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import { NotFound } from "src/components/Feedback/NotFound";
import { OrganizationError as OrganizationErrorView } from "src/components/Feedback/OrganizationError";
import { Student } from "src/components/Layout/Avatar";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import {
  OrganizationError,
  isOrganizationError,
  useOrganization,
} from "src/hooks/useOrganization";
import { usePreviousFormSchools } from "src/hooks/usePreviousFormSchools";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as FormTemplate from "src/services/formTemplate";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { failure, success, toTuple4 } from "src/types/remoteData";
import { Step } from "./Step";
import { StudentHeader } from "./components/Layout/StudentHeader";
import {
  CLEAR_IMPORT_SOURCE,
  UPDATE_IN_PROGRESS_FORM,
} from "./graphql/mutations";
import {
  GET_FORM_ANSWERS_BY_ID,
  GET_FORM_BY_ID,
  GET_SCHOOLS_RANK,
} from "./graphql/queries";
import { EditFormProvider } from "./state/provider";

function handleError(
  error: Error | ApolloError | OrganizationError
): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  if (isOrganizationError(error)) {
    return <OrganizationErrorView error={error} />;
  }

  return <Alert status="error">{error.message}</Alert>;
}

type Data = {
  form: GQL.FormFragment;
  sections: AF.Sections<AF.WithId>;
  enrollmentPeriodName: string;
  applicant: AFF.Types.Applicant & Student;
};

export const Edit = () => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isFormLocked, setIsFormLocked] = useState<boolean>(false);
  const { formId = "", step: stepString = "0" } = useParams();
  const step = parseInt(stepString);

  const [clearImportSource] = useRemoteDataMutation<
    GQL.ClearImportSource,
    GQL.ClearImportSourceVariables
  >(CLEAR_IMPORT_SOURCE, { variables: { form_id: formId } });

  React.useEffect(() => {
    clearImportSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateInProgressForm] = useRemoteDataMutation<
    GQL.UpdateInProgressForm,
    GQL.UpdateInProgressFormVariables
  >(UPDATE_IN_PROGRESS_FORM);

  const { remoteData: gqlData } = useRemoteDataQuery<
    GQL.GetFormById,
    GQL.GetFormByIdVariables
  >(GET_FORM_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      const form = data.form[0];
      if (form?.status === GQL.form_status_enum.Submitted) {
        await updateInProgressForm({
          variables: {
            form_id: formId,
          },
        });
        form.status = GQL.form_status_enum.InProgress;
        setEditMode(true);
      }

      if (
        form?.status === GQL.form_status_enum.Verified ||
        form?.status === GQL.form_status_enum.LotteryReady
      ) {
        setIsFormLocked(true);

        if (organization.hasData()) {
          setTimeout(() => {
            navigate(`/${organization.data.path}`);
          }, 2000);
        }
      }
    },
  });

  const remoteData = React.useMemo(() => {
    return gqlData
      .mapError<ApolloError | Error>((error) => error)
      .andThen((data) => {
        const form = data.form[0];
        if (!form?.form_template) {
          return failure<ApolloError | Error, Data>(
            new Error("Form not found") // TODO: better error message
          );
        }
        try {
          const formTemplate = FormTemplate.fromGQL(form.form_template);
          const enrollmentPeriodName =
            form.form_template.enrollment_period.name;

          return success<ApolloError, Data>({
            form: form,
            sections: formTemplate.sections,
            enrollmentPeriodName,
            applicant: {
              first_name: form.person.first_name ?? "",
              last_name: form.person.last_name ?? "",
              avatar: form.person.avatar,
              birth_date: form.person.birth_date,
            },
          });
        } catch (error: unknown) {
          console.error(error);
          return failure<ApolloError | Error, Data>(error as Error);
        }
      });
  }, [gqlData]);

  const { remoteData: answersRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    fetchPolicy: "no-cache",
    skip: Number.isNaN(step) || isFormLocked,
    variables: { form_id: formId },
  });

  const { remoteData: schoolRanksRemoteData, refetch: refetchSchoolRanks } =
    useRemoteDataQuery<GQL.GetSchoolsRank, GQL.GetSchoolsRankVariables>(
      GET_SCHOOLS_RANK,
      {
        fetchPolicy: "no-cache",
        skip: Number.isNaN(step) || isFormLocked,
        variables: { form_id: formId },
      }
    );

  const previousFormSchools = usePreviousFormSchools(formId);

  if (isNaN(step)) {
    return <NotFound />;
  }

  if (isFormLocked) {
    return <InfoAlert children="Redirecting..." />;
  }

  const combinedRemoteData = toTuple4(
    remoteData,
    answersRemoteData,
    schoolRanksRemoteData,
    previousFormSchools
  );

  return (
    <ParentRemoteDataLayout error={handleError} remoteData={combinedRemoteData}>
      {([
        { form, sections, enrollmentPeriodName, applicant },
        answers,
        schoolRanks,
        previousFormSchools,
      ]) => {
        return (
          <EditFormProvider>
            <Step
              formTemplateId={form.form_template.id}
              answers={answers}
              headers={
                <>
                  <Box mx={4} mt={4}>
                    <StudentHeader
                      student={applicant}
                      enrollmentPeriodName={enrollmentPeriodName}
                    />
                  </Box>
                  {editMode && (
                    <Box mx={4} mt={4} alignSelf="center" maxWidth="600px">
                      <InfoAlert
                        children={
                          <Text>
                            Form is back in progress. Don't forget to re-submit!
                          </Text>
                        }
                      />
                    </Box>
                  )}
                </>
              }
              currentStep={step}
              form={form}
              refetchSchoolRanks={refetchSchoolRanks}
              schoolRanksRemoteData={schoolRanksRemoteData}
              previousFormSchoolIds={previousFormSchools}
              sections={sections}
              formId={form.id}
              applicant={applicant}
              verificationResults={form.form_verification_results}
              hasBeenSubmittedBefore={form.submitted_before}
            />
          </EditFormProvider>
        );
      }}
    </ParentRemoteDataLayout>
  );
};
