import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GET_PROGRAM_GROUPS } from "./graphql/queries";
import { ProgramsList } from "./List";

export const Index = (): React.ReactElement => {
  const toast = useToast();

  const { remoteData, refetch } =
    useRemoteDataQuery<GQL.GetProgramGroups>(GET_PROGRAM_GROUPS);

  const handleDelete = () => {
    refetch();
    toast({
      title: "Program group deleted",
      status: "success",
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Heading as="h1" size="xl">
        Programs
      </Heading>
      <Button as={NavLink} to={Url.Admin.Programs.new()} size="xl">
        Create program group
      </Button>
      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <ProgramsList
            programGroups={data.program_group}
            onDelete={handleDelete}
          />
        )}
      </GQLRemoteDataView>
    </Flex>
  );
};

export { Edit } from "./Edit";
export { New } from "./New";
