import { DocumentNode, useApolloClient } from "@apollo/client";
import React from "react";
import { Props as TagsProps } from "src/components/Tags/TagsList";
import { TagsPopover } from "src/components/Tags/TagsPopover";
import * as RemoteData from "src/types/remoteData";
import { useTags } from "./useTags";
import { SchoolFormId } from "../forms/types";

type Props = {
  enrollmentPeriodId: uuid;
  tagGroupId: uuid;
  formSchoolId: SchoolFormId;
  refetchQueries?: DocumentNode[];
} & Omit<TagsProps, "tags" | "onSearch" | "onTagUpdate">;
export const SingleTagsPopover: React.FC<Props> = ({
  enrollmentPeriodId,
  tagGroupId,
  formSchoolId,
  refetchQueries,
  ...overrideTagsProps
}) => {
  const client = useApolloClient();
  const { resetTags, setSelectedRows, tagsProps, isDirty } = useTags({
    enrollmentPeriodId,
    tagGroupId,
  });

  const onClose = React.useCallback(() => {
    if (isDirty) {
      client.refetchQueries({
        include: refetchQueries,
      });
    }
    resetTags(false);
  }, [client, isDirty, refetchQueries, resetTags]);

  const onOpen = React.useCallback(async () => {
    setSelectedRows(RemoteData.success([formSchoolId]));
  }, [formSchoolId, setSelectedRows]);

  return (
    <TagsPopover
      buttonProps={{
        size: "xs",
        variant: "tag",
      }}
      onClose={onClose}
      onOpen={onOpen}
      {...tagsProps}
      {...overrideTagsProps}
    />
  );
};
