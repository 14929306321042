import { EmailVerificationOverride } from "src/hooks/useUser";

export enum Status {
  LOADING = "loading",
  UNAUTHENTICATED = "unauthenticated",
  OK = "ok",
  NOT_VERIFIED = "not_verified",
}

export type Unauthenticated = {
  status: Status.UNAUTHENTICATED;
};
export const unauthenticated: Unauthenticated = {
  status: Status.UNAUTHENTICATED,
};
export type Loading = {
  status: Status.LOADING;
};
export const loading: Loading = {
  status: Status.LOADING,
};
export type Ok<DATA> = {
  status: Status.OK;
  data: DATA;
};
export function ok<DATA>(data: DATA): Ok<DATA> {
  return {
    status: Status.OK,
    data,
  };
}
export type NotVerified<DATA> = {
  status: Status.NOT_VERIFIED;
  data: DATA;
  emailVerificationOverride: EmailVerificationOverride;
};
export function notVerified<DATA>(
  data: DATA,
  emailVerificationOverride: EmailVerificationOverride
): NotVerified<DATA> {
  return {
    status: Status.NOT_VERIFIED,
    data,
    emailVerificationOverride,
  };
}
export type AuthData<DATA> = Ok<DATA> | Loading | Unauthenticated;
export type UserData<DATA> = AuthData<DATA> | NotVerified<DATA>;
