import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spacer,
  VStack
} from "@chakra-ui/react";
import React, { FunctionComponent, RefObject, useMemo } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useParams } from "react-router";
import { DeleteFormTemplateButton } from "src/components/Buttons/DeleteFormTemplateButton";
import { NotFound } from "src/components/Feedback/NotFound";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import {
  NestedRouteOutletTabs,
  NestedRouteTab
} from "src/components/Layout/NestedRouteOutletTabs";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { AdminHeading } from "src/components/Text/AdminHeading";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as breadcrumb from "src/services/breadcrumb";
import { triggerDownload } from "src/services/dataTransfer";
import * as FormTemplate from "src/services/formTemplate";
import {
  FORM_TEMPLATES_CONTENT_PATH,
  FORM_TEMPLATES_SETTINGS_PATH,
  FORM_TEMPLATES_STATUSES_PATH,
  FormTemplateContent,
  FormTemplateSettings,
  FormTemplateStatuses,
  MESSAGE_TEMPLATES_PATH,
  MessageTemplate
} from "src/services/url/OrgAdmin";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import {
  GET_FORM_TEMPLATE_BY_ID,
  GET_FORM_TEMPLATE_ORGANIZATION,
  GET_FORM_VERIFICATIONS
} from "./graphql/queries";

interface Data {
  enrollmentPeriod: GQL.GetFormTemplateById_form_template_by_pk_enrollment_period;
  closedAtDate: string;
  organizationId: uuid;
  formTemplate: AF.FormTemplate<AF.WithId>;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  organization: GQL.Organization;
}

export const Edit: FunctionComponent<{}> = () => {
  const { formTemplateId = "" } = useParams();

  const { remoteData: gqlData } = useRemoteDataQuery<
    GQL.GetFormTemplateById,
    GQL.GetFormTemplateByIdVariables
  >(GET_FORM_TEMPLATE_BY_ID, {
    variables: { form_template_id: formTemplateId },
    fetchPolicy: "network-only"
  });

  const { remoteData: formVerificationsData } = useRemoteDataQuery<
    GQL.GetFormVerifications,
    GQL.GetFormVerificationsVariables
  >(GET_FORM_VERIFICATIONS, {
    variables: { formTemplateId: formTemplateId },
    fetchPolicy: "network-only"
  });

  const { remoteData: organizationData } = useRemoteDataQuery<
    GQL.GetFormTemplateOrganization,
    GQL.GetFormTemplateOrganizationVariables
  >(GET_FORM_TEMPLATE_ORGANIZATION, {
    variables: { formTemplateId: formTemplateId },
    fetchPolicy: "network-only"
  });

  const remoteData = React.useMemo(() => {
    return RD.toTuple3(
      gqlData.mapError<ApolloError>((error) => error),
      formVerificationsData.mapError<ApolloError>((error) => error),
      organizationData.mapError<ApolloError>((error) => error)
    ).andThen(([data, formVerifications, organizationData]) => {
      if (!data.form_template_by_pk) {
        return RD.failure<ApolloError, Data>(
          new ApolloError({ errorMessage: "Form not found" })
        );
      }
      try {
        return RD.success<ApolloError, Data>({
          enrollmentPeriod: data.form_template_by_pk.enrollment_period,
          closedAtDate: data.form_template_by_pk.closed_at ?? "",
          organizationId:
            data.form_template_by_pk.enrollment_period.organization_id ?? "",
          formTemplate: FormTemplate.fromGQL(
            data.form_template_by_pk,
            "lenient"
          ),
          verificationOptions: formVerifications.form_verification.map((v) => ({
            id: v.id,
            label: v.label
          })),
          organization: organizationData.organization[0] as GQL.Organization
        });
      } catch (error: unknown) {
        console.error(error);
        return RD.failure<ApolloError, Data>(
          new ApolloError({ errorMessage: "Cannot parse form" })
        );
      }
    });
  }, [formVerificationsData, gqlData, organizationData]);

  return (
    <GQLRemoteDataView
      remoteData={remoteData}
      reloading={null}
      loading={<EditFormTemplateLoader />}
    >
      {(data: Data) => {
        if (data === null) {
          return <NotFound />;
        }

        return (
          <EditFormTemplate
            organizationId={data.organizationId}
            formTemplate={data.formTemplate}
            verificationOptions={data.verificationOptions}
            closedAtDate={data.closedAtDate}
            organization={data.organization}
            enrollmentPeriod={data.enrollmentPeriod}
          />
        );
      }}
    </GQLRemoteDataView>
  );
};

type EditFormTemplateProps = {
  organizationId: uuid;
  formTemplate: AF.FormTemplate<AF.WithId>;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  closedAtDate: string;
  organization: GQL.Organization;
  enrollmentPeriod: GQL.GetFormTemplateById_form_template_by_pk_enrollment_period;
};

export type FormTemplateOutletContext = EditFormTemplateProps & {
  actionBarRef: RefObject<HTMLDivElement>;
};

const EditFormTemplate: React.FC<EditFormTemplateProps> = (props) => {
  const { formTemplate, enrollmentPeriod } = props;

  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();
  const { selectedEditFormTemplate } = useFormTemplates();

  const ref = React.useRef<HTMLDivElement>(null);
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  const navigationTabs: NestedRouteTab[] = useMemo(() => {
    return [
      {
        label: "Content",
        type: FORM_TEMPLATES_CONTENT_PATH,
        path: organization
          .map((org) =>
            FormTemplateContent.edit(org, enrollmentPeriodId, formTemplate.id)
          )
          .withDefault("#"),
        isVisible: isAvelaAdmin
      },
      {
        label: "Statuses",
        type: FORM_TEMPLATES_STATUSES_PATH,
        path: organization
          .map((org) =>
            FormTemplateStatuses.edit(org, enrollmentPeriodId, formTemplate.id)
          )
          .withDefault("#"),
        isVisible: true
      },
      {
        label: "Automatic messages",
        type: MESSAGE_TEMPLATES_PATH,
        path: organization
          .map((org) =>
            MessageTemplate.index(org, enrollmentPeriodId, formTemplate.id)
          )
          .withDefault("#"),
        isVisible: isAvelaAdmin
      },
      {
        label: "Settings",
        type: FORM_TEMPLATES_SETTINGS_PATH,
        path: organization
          .map((org) =>
            FormTemplateSettings.edit(org, enrollmentPeriodId, formTemplate.id)
          )
          .withDefault("#"),
        isVisible: true
      }
    ];
  }, [formTemplate, organization, enrollmentPeriodId, isAvelaAdmin]);

  return (
    <Flex direction="column" gap={10} minHeight="100%">
      <Breadcrumb
        items={breadcrumb.enrollmentPeriod.getBreadcrumbsForEditFormTemplate(
          organization,
          enrollmentPeriod,
          formTemplate
        )}
      />
      <AdminHeading
        title={formTemplate.name}
        id={formTemplate.id}
        actionButton={
          <HStack alignItems="start">
            <DeleteFormTemplateButton
              id={formTemplate.id}
              formTemplateName={formTemplate.name}
              isDisabled={
                !!selectedEditFormTemplate?.forms_aggregate.aggregate?.count
              }
            />
            <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
              <Button
                leftIcon={<RiDownloadLine />}
                onClick={() =>
                  triggerDownload(
                    new Blob([JSON.stringify(formTemplate, undefined, 2)], {
                      type: "text/json"
                    }),
                    "formTemplate.json"
                  )
                }
              >
                Export JSON
              </Button>
            </WithRequiredHasuraRoles>
          </HStack>
        }
      />
      <NestedRouteOutletTabs<FormTemplateOutletContext>
        tabs={navigationTabs.filter((tab) => tab.isVisible)}
        outletContext={{ ...props, actionBarRef: ref }}
      />
      <Spacer />
      <AdminFormButtons sticky>
        <Box w="100%" ref={ref}></Box>
      </AdminFormButtons>
    </Flex>
  );
};

const EditFormTemplateLoader: React.FC = () => {
  return (
    <Flex direction="column" gap={16}>
      <VStack align="flex-start">
        <Skeleton height="2rem" width="15rem" />
        <Skeleton height="1rem" width="20rem" />
      </VStack>
      <Flex direction="column" gap={3}>
        <Skeleton height="2rem" width="15rem" />
        <Skeleton height="8rem" width="100%" />
        <Skeleton height="8rem" width="100%" />
        <Skeleton height="8rem" width="100%" />
      </Flex>
    </Flex>
  );
};
