import { Box, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { StudentAvatar } from "src/components/Layout/Avatar";
import { Card } from "src/components/Layout/Card";
import { RouterLink } from "src/components/Links/RouterLink";
import { OrganizationError } from "src/hooks/useOrganization";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { fullName } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { StudentListItemMenu } from "./StudentListItemMenu";

interface StudentListItemProps {
  onDeleteStudentSuccess?: () => void;
  organization: RemoteData<OrganizationError, GQL.Organization>;
  person: GQL.GetStudentsByGuardianId_person;
}

export function StudentListItem(props: StudentListItemProps) {
  const { onDeleteStudentSuccess, organization, person } = props;

  return (
    <LinkBox alignItems="center" as={Card} display="flex" gap={4}>
      <StudentAvatar size="lg" student={person} />

      <Box flexGrow={1}>
        <LinkOverlay
          as={RouterLink}
          className={WEGLOT_SKIP_CLASS}
          fontSize="lg"
          fontWeight={700}
          to={organization
            .map((org) => Url.Parent.Student.edit(org, person.id))
            .withDefault("#")}
          wordBreak="break-word"
        >
          {fullName(person)}
        </LinkOverlay>
      </Box>

      <StudentListItemMenu
        onDeleteStudentSuccess={onDeleteStudentSuccess}
        organization={organization}
        person={person}
      />
    </LinkBox>
  );
}
