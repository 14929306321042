import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { StickyBottom } from "src/components/Layout/StickyBottom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import {
  PolicyManagementError,
  usePolicyManagement,
} from "src/hooks/usePolicyManagement";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useUser from "src/hooks/useUser";
import { TeamForm } from "src/scenes/orgAdmin/components/Forms/TeamForm";
import {
  ASSIGN_MEMBERS_TO_TEAM,
  CREATE_TEAM,
} from "src/scenes/orgAdmin/team/graphql/mutations";
import { MemberType, TeamFormType, emptyTeam } from "src/schemas/Team";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { GET_AVAILABLE_MEMBERS } from "./graphql/queries";

export const NewTeam = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [policy, setPolicy] = useState("");
  const [selectedMembers, setSelectedMembers] = useState<MemberType[]>([]);
  const organization = useOrganization();
  const organizationId = organization.map((org) => org.id).withDefault("");
  const user = useUser();

  const [createTeam] = useRemoteDataMutation<
    GQL.CreateTeam,
    GQL.CreateTeamVariables
  >(CREATE_TEAM);

  const [assignMembersToTeam] = useRemoteDataMutation<
    GQL.AssignMembersToTeam,
    GQL.AssignMembersToTeamVariables
  >(ASSIGN_MEMBERS_TO_TEAM);

  const { remoteData: availableMembersData } = useRemoteDataQuery<
    GQL.GetAvailableMembers,
    GQL.GetAvailableMembersVariables
  >(GET_AVAILABLE_MEMBERS, {
    variables: {
      organizationId,
    },
    fetchPolicy: "no-cache",
  });

  const { storePolicy } = usePolicyManagement({
    type: "DEFAULT",
    organizationId,
  });

  const handleSubmit = useCallback(
    async (value: TeamFormType) => {
      setSubmitting(true);
      let teamId: string | undefined;
      try {
        if (!organization.hasData()) throw Error("Invalid organization.");

        const newTeamPayload: GQL.CreateTeamVariables = {
          name: value.name,
          organizationId: organization.data.id,
        };
        const createTeamResponse = await createTeam({
          variables: newTeamPayload,
        });

        teamId = createTeamResponse.data?.insert_team_one?.id;
        if (!teamId) throw Error("Invalid team ID.");

        if (selectedMembers.length > 0) {
          const assignMembersPayload = selectedMembers.map((member) => ({
            team_id: teamId,
            person_id: member.id,
          }));

          const assignMembersResponse = await assignMembersToTeam({
            variables: {
              teamId: teamId,
              input: assignMembersPayload,
            },
          });

          if (
            assignMembersResponse.data?.insert_person_team?.affected_rows !==
            selectedMembers.length
          ) {
            throw Error("Failed to assign members to team.");
          }
        }

        toast({
          id: "create-team",
          title: "Team created",
          isClosable: true,
          status: "info",
        });

        const key = `organizations/${organization.data.id}/teams/${teamId}.cedar`;
        if (user.status === "ok" && user.data.role === HasuraRole.ADMIN) {
          // for now only avela admin can update policy
          await storePolicy(key, policy);
        }

        navigate(Url.OrgAdmin.Team.index(organization.data));
      } catch (err) {
        const description = (): React.ReactNode => {
          if (err instanceof PolicyManagementError) {
            return err.message;
          }
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "Team already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to create team.";
            }
          }
          return "Check your network and try again.";
        };
        toast({
          id: "create-team",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
        // If there's a problem with the policy, redirect to the team edit page
        if (
          err instanceof PolicyManagementError &&
          organization.hasData() &&
          teamId
        ) {
          navigate(Url.OrgAdmin.Team.editTeam(organization.data, teamId));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [
      organization,
      createTeam,
      selectedMembers,
      toast,
      user,
      navigate,
      assignMembersToTeam,
      storePolicy,
      policy,
    ]
  );

  if (availableMembersData.isLoading()) {
    return <Loading></Loading>;
  }
  return (
    <GQLRemoteDataView remoteData={availableMembersData}>
      {(availableMembers) => {
        return (
          <>
            <Breadcrumb
              items={breadcrumb.team.getBreadcrumbsForNewTeam(organization)}
              mb={8}
            />

            <Formik<TeamFormType>
              initialValues={emptyTeam}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Flex as={Form} direction="column" gap={6} height="100%">
                    <Heading as="h1" fontSize="2xl" fontWeight="600">
                      Create team
                    </Heading>
                    <TeamForm
                      onSelectMember={setSelectedMembers}
                      allTeamMembers={availableMembers.person}
                      initialMembers={selectedMembers}
                      initialPolicy=""
                      onChangePolicy={setPolicy}
                    />
                    <Spacer />
                    <StickyBottom gap={6} justifyContent="space-between">
                      <Button
                        as={NavLink}
                        to={organization
                          .map((org) => Url.OrgAdmin.Team.index(org))
                          .withDefault("#")}
                        isLoading={organization.isLoading()}
                        colorScheme="gray"
                        variant="outline"
                      >
                        Back
                      </Button>
                      <Button type="submit" isLoading={submitting}>
                        Create team
                      </Button>
                    </StickyBottom>
                  </Flex>
                );
              }}
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
