import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { RiSurveyLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { DividerWithText } from "src/components/DividerWithText";
import { EmptyStateCard } from "src/components/EmptyState";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as GeneralFilesSvg } from "src/images/general-files.svg";
import * as Url from "src/services/url";

export function NoForms() {
  const organization = useOrganization();

  const returnUrl = organization.map(Url.Parent.index).withDefault("#");

  return (
    <EmptyStateCard
      description="Click below to get started"
      heading="You're ready to start a new form."
      Svg={GeneralFilesSvg}
    >
      <Button
        as={RouterLink}
        leftIcon={<RiSurveyLine />}
        to={organization.map(Url.Parent.Form.new).withDefault("#")}
        variant="solid"
        width="100%"
      >
        <Glossary>Start form</Glossary>
      </Button>

      <DividerWithText text="Or" />

      <Button
        as={RouterLink}
        leftIcon={<AddIcon boxSize={3} />}
        to={organization
          .map((org) => Url.Parent.Student.new(org, { returnUrl }))
          .withDefault("#")}
        variant="outline"
        width="100%"
      >
        <Glossary>Add another student</Glossary>
      </Button>
    </EmptyStateCard>
  );
}
