import { Button, Icon } from "@chakra-ui/react";
import { uniq } from "lodash";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import {
  GqlSchoolForm,
  SchoolFormId,
  SchoolFormKeyRecord,
  SchoolFormKeyRecordFactory,
} from "../types";
import { BulkVerificationsState } from "../verifications";

interface BulkVerifyButtonProps {
  formTemplate: GQL.FormTemplateFragment;
  onFetchAllIds: () => Promise<SchoolFormId[]>;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
}

export const BulkVerifyButton: React.FC<BulkVerifyButtonProps> = ({
  formTemplate,
  onFetchAllIds,
  selection,
}) => {
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleBulkVerify = async () => {
    const keys = await selection.materializeKeys(async () =>
      (await onFetchAllIds()).map(SchoolFormKeyRecordFactory)
    );
    const formIds = uniq(keys.map((key) => key.formId));

    navigate(
      organization
        .map((org) =>
          Url.OrgAdmin.Forms.verifications({
            organization: org,
            formTemplateId: formTemplate.id,
            params: searchParams.toString(),
          })
        )
        .withDefault("#"),
      {
        state: {
          formTemplateId: formTemplate.id,
          formIds,
        } as BulkVerificationsState,
      }
    );
  };

  return (
    <Button
      leftIcon={<Icon as={RiCheckboxMultipleLine} />}
      onClick={handleBulkVerify}
      size="sm"
      variant="banner"
    >
      Verify
    </Button>
  );
};
