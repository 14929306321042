import type { ColumnDef } from "@tanstack/table-core";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { formatUuid } from "src/services/id";
import type { GlossaryFn } from "src/types/glossary";
import { ColumnId } from "./constants";

type SchoolIdColumnDefConfig = {
  glossary: GlossaryFn;
};

type Row = {
  form_school_rank: { school: { id: uuid } | null } | null;
};
export function buildSchoolIdColumnDef<T extends Row>({
  glossary,
}: SchoolIdColumnDefConfig): ColumnDef<T> {
  return {
    id: ColumnId.SchoolId,
    header: glossary`school id`,

    cell: (props) => (
      <TextWithOverflowTooltip
        content={formatUuid(
          props.row.original.form_school_rank?.school?.id ?? ""
        )}
        maxWidth="9em"
      />
    ),
  };
}
