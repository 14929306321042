/**
 * Helper to access form references, which Apollo/Hasura is unable to
 * properly infer will never be null.
 */
type Form<T> = { form?: T | null | undefined };
export function getForm<T>(asr: Form<T>) {
  if (!asr.form) {
    throw new Error("Missing form");
  }

  return asr.form;
}
