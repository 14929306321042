import { MenuItem, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { EXCLUDE_BULK_FORM } from "../graphql/mutations";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import { FormSchoolStatus } from "../types";

interface Props {
  selectedFormSchoolStatuses: RD.RemoteData<unknown, FormSchoolStatus[]>;
  onRefetch?: () => void;
}

const CONFIRMATION_BODY_TEXT =
  "Excluding these items from consideration will prevent them from receiving offers.";

export const ExcludeForm = ({
  selectedFormSchoolStatuses: selectedFormSchoolsStatuses,
  onRefetch,
}: Props) => {
  const [excludeBulkForm] = useRemoteDataMutation<
    GQL.ExcludeBulkForm,
    GQL.ExcludeBulkFormVariables
  >(EXCLUDE_BULK_FORM);

  const toast = useAvelaToast();

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "Cancel",
      },
      confirmButton: {
        label: "Yes, exclude",
        colorScheme: "blue",
      },
      translate: true,
    });

  const handleOnClick = React.useCallback(
    async (evt: React.MouseEvent) => {
      if (!selectedFormSchoolsStatuses.hasData()) {
        throw new Error("Invalid selected rows");
      }

      const schoolForms = selectedFormSchoolsStatuses.data;
      const count = schoolForms.length;

      setHeader(<Text>{`Exclude ${count} items?`}</Text>);
      setBody(<Text>{CONFIRMATION_BODY_TEXT}</Text>);

      if (await confirm()) {
        const loadingToastId = toast({
          title: `Working hard`,
          description: `Excluding ${count} forms...`,
          status: "loading",
          duration: undefined,
        });

        try {
          await excludeBulkForm({
            variables: {
              updateWhere: {
                id: { _in: schoolForms.map((result) => result.id) },
              },
              delete_waitlists_where: {
                _or: schoolForms.map((result) => ({
                  deleted_at: { _is_null: true },
                  form_id: { _eq: result.form_id },
                  school_id: { _eq: result.school_id },
                })),
              },
              delete_offers_where: {
                _or: schoolForms.map((result) => ({
                  deleted_at: { _is_null: true },
                  form_id: { _eq: result.form_id },
                  school_id: { _eq: result.school_id },
                })),
              },
            },
            refetchQueries: [FETCH_OFFER_WAITLIST_STATUS],
          });

          if (onRefetch) onRefetch();

          toast.close(loadingToastId);
          toast({
            title: "Hooray",
            description: `${schoolForms.length} forms excluded`,
            status: "success",
          });
        } catch (err: any) {
          toast.close(loadingToastId);
          if (err.message === "data") {
            toast.error({
              title: "Invalid grades in forms",
              description:
                "One or more items in your selection have mismatching grades",
            });
          } else {
            toast.error({
              title: "Something went wrong!",
              description: "Check your network and try again.",
            });
          }
          console.error(err);
        }
      }
    },
    [
      confirm,
      excludeBulkForm,
      onRefetch,
      selectedFormSchoolsStatuses,
      setBody,
      setHeader,
      toast,
    ]
  );
  return (
    <>
      <RemoteDataView
        remoteData={selectedFormSchoolsStatuses}
        loading={<Skeleton height={5} margin={3} />}
        error={() => <GenericError />}
        config={{ showDataWhileReloading: false }}
      >
        {(formSchools) => (
          <MenuItem
            textColor="gray.700"
            fontWeight="400"
            fontSize="sm"
            onClick={handleOnClick}
          >
            Exclude
          </MenuItem>
        )}
      </RemoteDataView>
      {confirmationDialog}
    </>
  );
};
