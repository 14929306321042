import { gql } from "@apollo/client";
import { DefaultBranding } from "src/configs/branding";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";

export const GET_MESSAGE_BRANDING = gql`
  query GetMessageBranding($organizationId: uuid!) {
    organization_by_pk(id: $organizationId) {
      name
      organization_branding {
        color_500
      }
      message_branding {
        support_email
        logo_url
        footer
      }
    }
  }
`;

export type Branding = {
  organizationName: string;
  logoUrl?: string;
  primaryColor: string;
  supportEmail: string;
  emailBody: {
    footer: string;
  };
};

const DefaultOrgBranding = {
  color_500: DefaultBranding.colors.primary["500"],
};

const DefaultSupportEmail = "info@avela.org";
const DefaultMessageBranding = {
  logo_url: undefined,
  support_email: DefaultSupportEmail,
  footer: `Please do not reply to this email, the account does not accept
    responses and you won't get a reply. Instead, please contact
    <a href="${DefaultSupportEmail}">${DefaultSupportEmail}</a>. Thanks!
    <br /><br />
    Por favor no responda a este correo electrónico, la cuenta no acepta
    respuestas y no recibirá contestación. Comuníquese con
    <a href="mailto:${DefaultSupportEmail}">${DefaultSupportEmail}</a>en su
    lugar. ¡Gracias!`,
}; // satisfies Partial<GQL.GetMessageBranding_organization_by_pk_message_branding>; // (Need to upgrade TS.)

export function useMessageBranding(): Branding | undefined {
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageBranding,
    GQL.GetMessageBrandingVariables
  >(GET_MESSAGE_BRANDING, {
    variables: { organizationId: organization.toNullable()?.id ?? "" },
    skip: !organization.hasData(),
  });

  if (!organization.hasData() || !remoteData.hasData()) return undefined;

  const orgBranding =
    remoteData.data.organization_by_pk?.organization_branding ??
    DefaultOrgBranding;
  const messageBranding =
    remoteData.data.organization_by_pk?.message_branding ??
    DefaultMessageBranding;
  return {
    organizationName: organization.data.name,
    logoUrl: messageBranding.logo_url,
    primaryColor: orgBranding.color_500,
    supportEmail: messageBranding.support_email,
    emailBody: { footer: messageBranding.footer },
  };
}
