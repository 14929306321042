import { Flex, Image } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useBrandingForAuth } from "src/auth/hooks";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useFlagsRemoteData } from "src/components/Providers/FeatureFlagProvider";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import { usePreferredLanguageBrowserOnly } from "src/hooks/usePreferredLanguage";
import { WeglotSelect } from "src/plugins/weglot";
import * as RD from "src/types/remoteData";
import { Footer } from "./Footer";
import { AccountLookupForm } from "./Form";
import { CARD_PROPS } from "./steps/Step";

/**
 * Based on /src/auth/AuthLayout.tsx.
 */
export const AccountLookup: FunctionComponent<{}> = () => {
  const branding = useBrandingForAuth();
  const configRD = useOrgConfig("AccountLookup");
  const preferredLanguageSetting = usePreferredLanguageBrowserOnly();

  const flagsData = useFlagsRemoteData(["account-lookup"]);
  return (
    <RemoteDataView
      remoteData={RD.toTuple(configRD, flagsData)}
      loading={<Loading />}
      error={() => <GenericError />}
    >
      {([config, flags]) => {
        if (config.disabled || !flags["account-lookup"].enabled) {
          return <NotFound />;
        }

        return (
          <Flex alignItems="center" direction="column" padding={4} gap={8}>
            <Flex alignSelf="flex-end">
              <WeglotSelect
                colorScheme="gray"
                variant="link"
                preferredLanguageSetting={preferredLanguageSetting}
              />
            </Flex>
            <Image
              paddingX={6}
              width={CARD_PROPS.width}
              maxWidth={CARD_PROPS.maxWidth}
              src={branding.logo.color.url}
              alt={branding.logo.color.alt}
            />
            <AccountLookupForm config={config} />
            <Footer config={config} />
          </Flex>
        );
      }}
    </RemoteDataView>
  );
};
