import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import { BrandingProvider } from "./BrandingProvider";

export function PathBrandingProvider({ children }: React.PropsWithChildren) {
  const organizationPath = useOrganizationPath();
  return (
    <BrandingProvider organizationPath={organizationPath}>
      {children}
    </BrandingProvider>
  );
}
