import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    user: {
      fields: {
        students: {
          merge: (existing, incoming) => {
            return incoming;
          },
        },
      },
    },
    question: {
      fields: {
        form_question: {
          merge: (existing, incoming) => {
            if (existing?.question_id === incoming?.question_id)
              return incoming;
            else return existing;
          },
        },
      },
    },
  },
});
