import { InputControl, InputControlProps } from "formik-chakra-ui";
import { Ref } from "react";

/**
 * This is a workaround for formik-chakra-ui type limitation:
 * https://github.com/thekaganugur/formik-chakra-ui/issues/106
 */
export const ForwardRefInputControl: React.FC<
  InputControlProps & { ref?: Ref<HTMLInputElement> }
> = InputControl;
