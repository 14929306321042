import { gql } from "@apollo/client";

export const SIMPLE_SCHOOL_FRAGMENT = gql`
  fragment SimpleSchoolFragment on school {
    id
    name
    street_address
  }
`;

export const SCHOOL_GRADES_FRAGMENT = gql`
  fragment SchoolGradesFragment on school {
    grades(
      where: {
        enrollment_period: {
          deleted_at: { _is_null: true }
          form_templates: {
            deleted_at: { _is_null: true }
            forms: { id: { _eq: $form_id } }
          }
        }
      }
    ) {
      id
      grade_config_id
      enrollment_period {
        name
      }
      grade_config {
        label
      }
    }
  }
`;

export const SCHOOL_WITH_GRADES_FRAGMENT = gql`
  ${SCHOOL_GRADES_FRAGMENT}
  fragment SchoolWithGradesFragment on school {
    id
    name
    street_address
    ...SchoolGradesFragment
  }
`;
