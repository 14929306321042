import { Button } from "@chakra-ui/react";
import { RiEdit2Line, RiSearchEyeLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";

interface CardActionProps {
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;
}

export function CardAction(props: CardActionProps) {
  const { form } = props;

  const organization = useOrganization();

  if (form.status === GQL.form_status_enum.InProgress) {
    return (
      <Button
        as={RouterLink}
        colorScheme="gray"
        leftIcon={<RiEdit2Line />}
        size="sm"
        to={organization
          .map((org) => Url.Parent.Form.edit(org, form.id))
          .withDefault("#")}
        variant="outline"
        width="100%"
      >
        <Glossary>Resume form</Glossary>
      </Button>
    );
  }
  return (
    <Button
      as={RouterLink}
      colorScheme="gray"
      leftIcon={<RiSearchEyeLine />}
      size="sm"
      to={organization
        .map((org) => Url.Parent.Form.view(org, form.id))
        .withDefault("#")}
      variant="outline"
      width="100%"
    >
      <Glossary>View form</Glossary>
    </Button>
  );
}
