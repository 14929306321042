import { Flex, FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";

export const ImportantDatesForm: React.FC = () => {
  return (
    <>
      <Heading fontSize="xl" fontWeight="600" color="gray.700" my={2}>
        Important dates
      </Heading>

      <Flex alignItems="center">
        <FormControl>
          <FormLabel>Opens</FormLabel>
          <InputWithValidationControl
            id="openAt"
            name="openAt"
            inputProps={{ type: "datetime-local" }}
          />
        </FormControl>

        <Text mx={2}>and</Text>

        <FormControl>
          <FormLabel>Closes</FormLabel>
          <InputWithValidationControl
            id="closedAt"
            name="closedAt"
            inputProps={{ type: "datetime-local" }}
          />
        </FormControl>

        <Text mx={2}>and</Text>
        <FormControl>
          <FormLabel>Reopens</FormLabel>
          <InputWithValidationControl
            id="reopenAt"
            name="reopenAt"
            inputProps={{ type: "datetime-local" }}
          />
        </FormControl>
      </Flex>
    </>
  );
};
