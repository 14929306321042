import { Image } from "@chakra-ui/react";
import heic2any from "heic2any";
import {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DocViewerContext } from "../state";
import { MAX_ZOOM_LEVEL, resetState, setNumPages } from "../state/actions";
import { transformImage } from "../state/utils";
import { DocRenderer } from "./DocRenderer";

export const HeicImageRenderer: DocRenderer = ({ documentData }) => {
  const { state, dispatch } = useContext(DocViewerContext);

  const [url, setUrl] = useState<string>();

  const [dimension, setDimension] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    if (!documentData) return;

    heic2any({
      blob: new Blob([documentData]),
      toType: "image/jpg",
      quality: 1,
    })
      .then((newImage) => {
        const url = URL.createObjectURL(newImage as Blob);
        setUrl(url);
      })
      .catch((error) => {});
  }, [documentData, dispatch]);

  const transformation = useMemo<string>(
    () => transformImage(state, dimension.width, dimension.height),
    [state, dimension]
  );

  const onImageLoad = ({ target }: SyntheticEvent<HTMLImageElement, Event>) => {
    dispatch(resetState());
    dispatch(setNumPages(1));

    const { naturalWidth, naturalHeight } = target as HTMLImageElement;
    setDimension({ width: naturalWidth, height: naturalHeight });
  };

  return (
    <Image
      src={url}
      onLoad={onImageLoad}
      maxWidth={`${MAX_ZOOM_LEVEL * 200}%`}
      width={
        dimension.width > 0 ? dimension.width * state.zoomLevel : undefined
      }
      transform={transformation}
      transformOrigin="top left"
    />
  );
};
