import { Image, useDisclosure } from "@chakra-ui/react";
import {
  RiBookmark2Line,
  RiListCheck,
  RiListCheck2,
  RiQuestionnaireLine,
  RiRefreshLine,
  RiSortAsc,
  RiTreasureMapLine,
} from "react-icons/ri";
import { BaseLayout } from "src/components/Layout/BaseLayout";
import { Header } from "src/components/Layout/Header";
import { LeftNav, NavGroup, NavLinkItem } from "src/components/Navigation";
import { NavigationFoldButton } from "src/components/Navigation/NavigationFoldButton";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useBranding } from "src/components/Providers/BrandingProvider";
import {
  Boundaries,
  Glossaries,
  GradesConfig,
  Organizations,
  PriorityTemplates,
  Programs,
  QuestionTypes,
} from "src/services/url/Admin";

export const Layout: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { branding } = useBranding();
  const logo = (
    <>
      <NavigationFoldButton isOpen={isOpen} onToggle={onToggle} />
      {branding && (
        <Image
          src={branding.logo.white.url}
          alt={branding.logo.white.alt}
          height="3em"
          maxWidth="9rem"
        />
      )}
    </>
  );
  return (
    <BaseLayout
      header={<Header logo={logo} />}
      leftNav={
        <LeftNav isOpen={isOpen}>
          <NavGroup label="Avela Admin">
            <WithUserPermissions
              permissions={["navigation.admin.organizations"]}
            >
              <NavLinkItem
                url={Organizations.index()}
                label="Organizations"
                leadIcon={RiRefreshLine}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.grades"]}>
              <NavLinkItem
                url={GradesConfig.index()}
                label="Grades configuration"
                leadIcon={RiListCheck}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.programs"]}>
              <NavLinkItem
                url={Programs.index()}
                label="Programs"
                leadIcon={RiListCheck2}
              />
            </WithUserPermissions>
            <WithUserPermissions
              permissions={["navigation.admin.priority_templates"]}
            >
              <NavLinkItem
                url={PriorityTemplates.index()}
                label="Priority templates"
                leadIcon={RiSortAsc}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.boundaries"]}>
              <NavLinkItem
                url={Boundaries.index()}
                label="Boundaries"
                leadIcon={RiTreasureMapLine}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.glossaries"]}>
              <NavLinkItem
                url={Glossaries.index()}
                label="Glossaries"
                leadIcon={RiBookmark2Line}
              />
            </WithUserPermissions>
            <WithUserPermissions
              permissions={["navigation.admin.question_types"]}
            >
              <NavLinkItem
                leadIcon={RiQuestionnaireLine}
                label="Question types"
                url={QuestionTypes.index()}
              />
            </WithUserPermissions>
          </NavGroup>
        </LeftNav>
      }
    ></BaseLayout>
  );
};
