import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { RiArrowDropDownLine, RiUploadLine } from "react-icons/ri";
import { HasuraRole } from "src/types/hasuraRole";
import { WithRequiredHasuraRoles } from "../Permissions/WithRequiredHasuraRoles";
import { BulkUploadPeopleInput } from "./BulkUploadPeopleButton";
import { UploadRelationships } from "./UploadRelationshipsButton";
import { UploadAttendingSchools } from "./UploadAttendingSchoolsButton";
import { useFlags } from "flagsmith/react";

interface UploadPeopleMenuProps {
  onComplete: () => void;
  personType: "applicant" | "guardian";
}

const LABELS: Record<UploadPeopleMenuProps["personType"], string> = {
  applicant: "Students",
  guardian: "Parents",
};

export function UploadPeopleMenu({
  onComplete,
  personType,
}: UploadPeopleMenuProps) {
  const flags = useFlags(["current-applying-schools"]);
  return (
    // This box prevents a warning about CSS margin styles when opening the menu.
    <Box>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            size="xl"
            leftIcon={<Icon as={RiUploadLine} />}
            rightIcon={<Icon as={RiArrowDropDownLine} />}
          >
            Import
          </MenuButton>
          <MenuList>
            <BulkUploadPeopleInput
              onComplete={onComplete}
              personType={personType}
            >
              <MenuItem>{LABELS[personType]}</MenuItem>
            </BulkUploadPeopleInput>
            <UploadRelationships>
              <MenuItem>Relationships</MenuItem>
            </UploadRelationships>
            {flags["current-applying-schools"].enabled && (
              <UploadAttendingSchools>
                <MenuItem>Attended schools</MenuItem>
              </UploadAttendingSchools>
            )}
          </MenuList>
        </Menu>
      </WithRequiredHasuraRoles>
    </Box>
  );
}
