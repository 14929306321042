import * as DateFns from "date-fns";
import * as DateFnsTz from "date-fns-tz";
import React from "react";
import { UseOrganizationType } from "./useOrganization";

interface FormTemplate {
  id: string;
  lottery_offers_enabled: boolean;
  offer_start_at: string | null;
}

export function useIsOfferEnabled(
  organization: UseOrganizationType,
  formTemplate?: FormTemplate
): boolean {
  const isOfferStarted = React.useMemo(() => {
    if (!formTemplate?.lottery_offers_enabled) return false;

    return calcIsOfferStarted(organization, formTemplate);
  }, [formTemplate, organization]);

  return isOfferStarted;
}

function calcIsOfferStarted(
  organization: UseOrganizationType | undefined,
  formTemplate?: FormTemplate
): boolean {
  const timezoneName = organization?.hasData()
    ? organization.data.timezone_name
    : undefined;

  // to be compatible with existing enrollment_period that did not set offer_start_at datetime
  // and allow offer at anytime.
  // TBD when changing this to form_template.offer_start_at datetime, it does not have to be `true`
  if (!formTemplate?.offer_start_at) return true;

  const offerStartAt = DateFnsTz.toDate(formTemplate.offer_start_at, {
    timeZone: timezoneName ?? "America/New_York",
  });
  const currentTime = DateFnsTz.toDate(new Date(), { timeZone: timezoneName });

  return DateFns.isAfter(currentTime, offerStartAt);
}
