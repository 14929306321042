import { Alert, Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AccessDenied } from "src/components/Feedback/AccessDenied";
import { Loading } from "src/components/Feedback/Loading";
import {
  BaseAddressSchema,
  CURRENT_ADDRESS,
  isAddressBlank,
  NO_ADDRESS,
} from "src/components/Inputs/Address/Book";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useUser from "src/hooks/useUser";
import { useUserProfile } from "src/hooks/useUserProfile";
import { AddressSchema } from "src/schemas/Address";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { ParentForm } from "../components/Forms/ParentForm";
import { UPDATE_PROFILE } from "./graphql/mutations";
import { GET_PROFILE_BY_USER_ID_AND_ORG_ID } from "./graphql/queries";
import {
  ContactInfoSchema,
  ParentProfileFormType,
  ParentProfileSchema,
  ParentProfileValidator,
} from "./schemas";

export function Edit() {
  const organization = useOrganization();
  const toast = useAvelaToast();
  const navigate = useNavigate();
  const user = useUser();
  const profile = useUserProfile();
  const userId = user.status === "ok" ? user.data.id : null;

  const { remoteData } = useRemoteDataQuery<
    GQL.GetProfileByUserIdAndOrgId,
    GQL.GetProfileByUserIdAndOrgIdVariables
  >(GET_PROFILE_BY_USER_ID_AND_ORG_ID, {
    variables: {
      user_id: userId ?? "",

      organizationId: organization.map((org) => org.id).withDefault(""),
      include_user: true,
    },
    skip: userId === null || !organization.hasData(),
  });

  const [updateProfile, { remoteData: updateRemoteData }] =
    useRemoteDataMutation<GQL.UpdateProfile, GQL.UpdateProfileVariables>(
      UPDATE_PROFILE
    );

  const handleSubmit = React.useCallback(
    async (values: ParentProfileFormType) => {
      RD.toTuple(organization, profile).do(async ([org, person]) => {
        try {
          const parentValidator =
            ParentProfileValidator.merge(ContactInfoSchema);

          const { address, ...validatedParent } = parentValidator.parse(values);
          const parsedAddress = BaseAddressSchema.parse(address);

          await updateProfile({
            variables: {
              id: person.id,
              person: {
                ...validatedParent,
                ...parsedAddress,
              },
            },
          });
          navigate(Url.Parent.index(org));
        } catch (error) {
          console.error(error);
          toast.error({
            title: "Error updating profile",
          });
        }
      });
    },
    [navigate, organization, profile, toast, updateProfile]
  );

  if (user.status === "loading") return <Loading />;
  if (user.status === "unauthenticated") return <AccessDenied />;

  return (
    <ParentRemoteDataLayout remoteData={remoteData}>
      {(data) => {
        // allow pre-existing null address column values
        const parsedAddress = AddressSchema.parse(data.person[0]);
        const addressKind = isAddressBlank(parsedAddress)
          ? NO_ADDRESS
          : CURRENT_ADDRESS;

        const parsed = ParentProfileSchema.safeParse({
          ...data.person[0],
          address: {
            kind: addressKind,
            ...parsedAddress,
          },
        });

        if (!parsed.success) {
          console.error(parsed.error);
          return <Alert status="error">Error Loading Profile</Alert>;
        }
        const user = data.person[0]?.user;

        return (
          <Formik<ParentProfileFormType>
            initialValues={parsed.data}
            onSubmit={handleSubmit}
            validate={validateWithZod(ParentProfileValidator)}
            validateOnMount={true}
          >
            {(formikProps) => (
              <ParentFormsLayout
                as={Form}
                paddingX={0}
                heading="Profile settings"
                footers={
                  <ParentFormsLayoutFooter>
                    <Button
                      flexGrow={1}
                      isDisabled={!formikProps.isValid || !formikProps.dirty}
                      isLoading={
                        updateRemoteData.isLoading() || remoteData.isLoading()
                      }
                      type="submit"
                    >
                      Update profile
                    </Button>
                  </ParentFormsLayoutFooter>
                }
              >
                <ParentForm
                  username={user?.name ?? undefined}
                  paddingX={6}
                  phoneLinked={user?.name === parsed.data.phone_number}
                  emailLinked={user?.name === parsed.data.email_address}
                />
              </ParentFormsLayout>
            )}
          </Formik>
        );
      }}
    </ParentRemoteDataLayout>
  );
}
