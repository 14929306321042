import * as RD from "src/types/remoteData";
import { identity, isEmpty } from "lodash";
import {
  AddressAnswer,
  CustomQuestionAnswer,
  CustomQuestionAnswerSchema,
  CustomQuestionAnswerWithType,
  CustomQuestionAnswersWithFieldQuestionTypes,
} from "src/components/Form/QuestionForm/formik";
import { BaseAddressSchema } from "src/components/Inputs/Address/Book";
import { isAddressBlank } from "src/components/Inputs/Address/Book/helpers";
import { mapCloneIdsBySourceIds } from "src/components/Inputs/QuestionDynamicInputs/CustomQuestion/modules/context/helpers";
import {
  formatInsertPersonAnswerBankWithCustomQuestionRelationshipPayload,
  formatInsertPersonAnswersWithAnswerBankIdPayload,
} from "src/components/Inputs/QuestionDynamicInputs/CustomQuestion/modules/form/helpers";
import { mapFormAnswersToBankAnswers } from "src/components/Inputs/QuestionDynamicInputs/CustomQuestion/modules/helpers";
import { CustomQuestionTypeFieldTypes } from "src/schemas/CustomQuestionType";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { isNotNull } from "../predicates";
import { formatNestedQuestionAsProps } from "./customQuestion";
import {
  findGeoEligibilityQuestion,
  findGradesQuestion,
  getCompleteQuestions,
} from "./question";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";

export type FormikFieldValue =
  | string
  | string[]
  | AddressAnswer
  | CustomQuestionAnswer
  | undefined;
export type FormikValues = { [questionId: string]: FormikFieldValue };

export function isFreeTextAnswer(
  answer: FormikFieldValue
): answer is string | undefined {
  return typeof answer === "string" || answer === undefined;
}

export function isMultiSelectAnswer(
  answer: FormikFieldValue
): answer is string[] | undefined {
  return Array.isArray(answer) || answer === undefined;
}

export function isGradesAnswer(
  answer: FormikFieldValue
): answer is string | undefined {
  return isSingleSelectAnswer(answer);
}

export function isSingleSelectAnswer(
  answer: FormikFieldValue
): answer is string | undefined {
  return typeof answer === "string" || answer === undefined;
}

export function isAddressAnswer(
  answer: FormikFieldValue
): answer is AddressAnswer {
  const answerIsAnAddress = BaseAddressSchema.safeParse(answer).success;
  return typeof answer === "object" && answerIsAnAddress;
}

export function isCustomQuestionAnswer(
  answer: FormikFieldValue
): answer is CustomQuestionAnswer {
  const answerIsCustomQuestionAnswer =
    CustomQuestionAnswerSchema.safeParse(answer).success;
  return typeof answer === "object" && answerIsCustomQuestionAnswer;
}

export function getFreeTextAnswersInserts(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_answer_insert_input[] {
  const freeTextQuestionsId = questions
    .filter(
      (q) =>
        q.type === GQL.question_type_enum.FreeText ||
        q.type === GQL.question_type_enum.Email ||
        q.type === GQL.question_type_enum.PhoneNumber
    )
    .map((q) => q.id);

  const freeTextAnswers: GQL.form_answer_insert_input[] =
    freeTextQuestionsId.map((id) => {
      const answer = answers[id];
      if (!isFreeTextAnswer(answer))
        throw new Error(
          `Expecting a string for free text answer for question(${id}), but got ${answer}`
        );

      const freeTextAnswer: GQL.form_answer_insert_input = {
        form_id: formId,
        question_id: id,
        free_text_answer: answer,
      };
      return freeTextAnswer;
    });

  return freeTextAnswers;
}

export function getSingleSelectAnswersInserts(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_answer_insert_input[] {
  const singleSelectQuestionsId = questions
    .filter((q) => q.type === "SingleSelect")
    .map((q) => q.id);
  const singleSelectAnswers: GQL.form_answer_insert_input[] =
    singleSelectQuestionsId.map((id) => {
      const answer = answers[id];
      if (!isSingleSelectAnswer(answer)) {
        throw new Error(
          `Expecting a string[] for free text answer for question(${id}), but got ${answer}`
        );
      }
      const singleSelectAnswer: GQL.form_answer_insert_input = {
        form_id: formId,
        question_id: id,
        form_answer_options: {
          data: !answer ? [] : [{ form_question_option_id: answer }],
        },
      };
      return singleSelectAnswer;
    });

  return singleSelectAnswers;
}

export function getMultiSelectAnswersInserts(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_answer_insert_input[] {
  const singleSelectQuestionsId = questions
    .filter((q) => q.type === "MultiSelect")
    .map((q) => q.id);
  const singleSelectAnswers: GQL.form_answer_insert_input[] =
    singleSelectQuestionsId.map((id) => {
      const answer = answers[id];
      if (!isMultiSelectAnswer(answer)) {
        throw new Error(
          `Expecting a "string[]" for question ${id}, but got ${answer} instead`
        );
      }

      const singleSelectAnswer: GQL.form_answer_insert_input = {
        form_id: formId,
        question_id: id,
        form_answer_options: {
          data: (answer ?? [])?.map((optionId) => ({
            form_question_option_id: optionId,
          })),
        },
      };
      return singleSelectAnswer;
    });

  return singleSelectAnswers;
}

export function getGradesAnswersInserts(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_answer_insert_input[] {
  const gradesQuestionsId = questions
    .filter((q) => q.type === "Grades")
    .map((q) => q.id);
  const gradesAnswers: GQL.grades_answer_insert_input[] = gradesQuestionsId.map(
    (id) => {
      const answer = answers[id];
      if (!isGradesAnswer(answer)) {
        throw new Error(
          `Expecting a "string" for question ${id}, but got ${answer} instead`
        );
      }

      const gradesAnswer: GQL.grades_answer_insert_input = {
        form_id: formId,
        question_id: id,
        grade_config_id: answer === "" ? null : answer,
      };
      return gradesAnswer;
    }
  );

  return gradesAnswers;
}

export function getAddressAnswersDeletes(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_address_bool_exp[] {
  const addressQuestionsId = questions
    .filter((q) => q.type === AF.AddressType)
    .map((q) => q.id);
  const formAddressDeletes: GQL.form_address_bool_exp[] =
    addressQuestionsId.flatMap((id) => {
      const answer = answers[id];
      if (!isAddressAnswer(answer)) {
        throw new Error(
          `Expecting an "object" for question ${id}, but got ${answer} instead`
        );
      }

      if (isAddressBlank(answer)) {
        return [{ question_id: { _eq: id }, form_id: { _eq: formId } }];
      }

      return [];
    });

  return formAddressDeletes;
}

export function getAddressAnswersInserts(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): GQL.form_answer_insert_input[] {
  const addressQuestionsId = questions
    .filter((q) => q.type === AF.AddressType)
    .map((q) => q.id);
  const addressAnswers: GQL.form_address_insert_input[] =
    addressQuestionsId.map((id) => {
      const answer = answers[id];
      if (!isAddressAnswer(answer)) {
        throw new Error(
          `Expecting an "object" for question ${id}, but got ${answer} instead`
        );
      }
      const addressAnswer: GQL.form_address_insert_input = {
        form_id: formId,
        question_id: id,
        street_address: answer.street_address,
        street_address_line_2: answer.street_address_line_2,
        city: answer.city,
        state: answer.state,
        zip_code: answer.zip_code,
      };
      return addressAnswer;
    });

  return addressAnswers;
}

/**
 * This is used by the Admin view to generate the payload for editing CQT based answers.
 * Unlike the Parent/guardian UX, this is a part of a single form submission.
 * That means the "Do not answer" behavior needs to be baked into the form submission.
 */
export function getCustomQuestionAnswerUpsertPayload(
  formId: uuid,
  personId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues,
  customQuestionTypes: GQL.GetCustomQuestionTypesByOrg_custom_question_type[]
): {
  customQuestionAnswers: GQL.custom_question_answer_insert_input[];
  answerBankIds: uuid[];
  answerBankAnswers: GQL.person_answer_insert_input[];
  newAnswerBankEntries: GQL.person_answer_bank_insert_input[];
  customQuestionAnswersToDelete: uuid[];
  customQuestionAnswerFieldsToDelete: uuid[];
} {
  const customQuestions = questions.filter(
    (q) => q.type === AF.CustomQuestionType
  ) as AF.CustomQuestion<AF.WithId>[];
  const answerBankIds: uuid[] = [];
  const answerBankAnswers: GQL.person_answer_insert_input[] = [];
  const newAnswerBankEntries: GQL.person_answer_bank_insert_input[] = [];
  const customQuestionAnswersToDelete: uuid[] = [];
  const customQuestionAnswerFieldsToDelete: uuid[] = [];

  const customQuestionAnswers = customQuestions
    .flatMap((question) => {
      const answer = answers[question.id];
      const result = CustomQuestionAnswerSchema.safeParse(answer);
      if (!result.success) {
        throw new Error(
          `Expecting an "object" for question ${question.id}, but got ${answer} instead`
        );
      }

      if (answerIsEmpty(result.data.answersByQuestionId)) {
        customQuestionAnswersToDelete.push(question.id);
        customQuestionAnswerFieldsToDelete.push(
          ...Object.keys(result.data.answersByQuestionId)
        );
        return null;
      }

      const answerBankId = result.data.answerBankId;
      const customQuestionType = customQuestionTypes.find(
        (cqt) => cqt.id === question.customQuestionTypeId
      );
      if (!customQuestionType) {
        throw new Error(
          `Custom question type with id ${question.customQuestionTypeId} for question ${question.id} does not exist`
        );
      }
      const fieldQuestions: AF.ClonedQuestion<AF.WithId>[] =
        customQuestionType.custom_question_type_fields
          .map((field) => {
            return formatNestedQuestionAsProps(field.question);
          })
          .filter(isNotNull);
      const sourceIdCloneIdMapping = mapCloneIdsBySourceIds(
        customQuestionType,
        question.nestedQuestions
      );

      if (answerBankId) {
        answerBankIds.push(answerBankId);
        answerBankAnswers.push(
          ...formatInsertPersonAnswersWithAnswerBankIdPayload({
            answerBankId,
            answersByQuestionId: mapFormAnswersToBankAnswers(
              result.data.answersByQuestionId,
              sourceIdCloneIdMapping
            ),
            fieldQuestions,
          })
        );
      } else {
        newAnswerBankEntries.push(
          formatInsertPersonAnswerBankWithCustomQuestionRelationshipPayload({
            answersByQuestionId: mapFormAnswersToBankAnswers(
              result.data.answersByQuestionId,
              sourceIdCloneIdMapping
            ),
            customQuestionTypeId: customQuestionType.id,
            fieldQuestions,
            personId,
            formId,
            questionId: question.id,
          })
        );
      }

      return getSingleCustomQuestionAnswerUpsertPayload(
        formId,
        question as AF.CustomQuestion<AF.WithId>,
        result.data.answersByQuestionId
      );
    })
    .filter(isNotNull);

  return {
    customQuestionAnswers,
    answerBankIds,
    answerBankAnswers,
    newAnswerBankEntries,
    customQuestionAnswersToDelete,
    customQuestionAnswerFieldsToDelete,
  };
}

export function answerIsEmpty(customQuestionAnswer: Record<string, string>) {
  for (const [, answer] of Object.entries(customQuestionAnswer)) {
    if (answer.length > 0) {
      return false;
    }
  }
  return true;
}

export function getSingleCustomQuestionAnswerUpsertPayload(
  formId: uuid,
  question: AF.CustomQuestion<AF.WithId>,
  answers: Record<string, string>
): GQL.custom_question_answer_insert_input[] {
  const customQuestionAnswersWithQuestionTypes: CustomQuestionAnswersWithFieldQuestionTypes =
    {};
  for (const nestedQuestionId in answers) {
    const answer = answers[nestedQuestionId];
    customQuestionAnswersWithQuestionTypes[nestedQuestionId] = {
      type: getNestedQuestionType(question, nestedQuestionId),
      value: answer,
    };
  }
  return formatCustomQuestionAnswerUpsertPayload(
    formId,
    question.id,
    customQuestionAnswersWithQuestionTypes
  );
}

function getNestedQuestionType(
  question: AF.CustomQuestion<AF.WithId>,
  nestedQuestionId: string
): CustomQuestionTypeFieldTypes {
  const nestedQuestion = question.nestedQuestions.find(
    (nestedQuestion) => nestedQuestion.id === nestedQuestionId
  );
  if (!nestedQuestion) {
    throw new Error(
      `Nested question with id ${nestedQuestionId} not found in custom question with id ${question.id}`
    );
  }
  return nestedQuestion.type;
}

/**
 * 1. custom_question_answers points to top level question_id.
 * 2. An individual custom_question_answer's associated form_answer points to the nested question:
 *
 *  form_templates: {
 *    id: f1
 *    questions: [
 *      { normal question, question_id: q1 }
 *      { cqt question, question_id: q2, fields: [
 *        { nested_question, question_id: q3},
 *        { nested_question, question_id: q4},
 *        ]
 *      }
 *    ]
 *  }
 *
 *  custom_question_answers: [
 *    { form_id: f1, question_id: q2, form_answer: { form_id: null, question_id: q3 } }
 *    { form_id: f1, question_id: q2, form_answer: { form_id: null, question_id: q4 } }
 *  ]
 */
function formatCustomQuestionAnswerUpsertPayload(
  formId: uuid,
  questionId: uuid,
  value: CustomQuestionAnswersWithFieldQuestionTypes
): GQL.custom_question_answer_insert_input[] {
  const customQuestionAnswers = Object.entries(value);

  return customQuestionAnswers.map(([nestedQuestionId, answer]) => {
    return {
      form_id: formId,
      question_id: questionId,
      form_answer: {
        data: {
          form_id: formId,
          question_id: nestedQuestionId,
          ...getAnswerInputForQuestionType(answer),
        },
        on_conflict: {
          constraint:
            GQL.form_answer_constraint.form_answer_form_id_form_question_id_key,
          update_columns: [GQL.form_answer_update_column.free_text_answer],
        },
      },
    };
  });
}

function getAnswerInputForQuestionType(answer: CustomQuestionAnswerWithType) {
  const { value, type } = answer;

  switch (type) {
    case AF.SingleSelectType:
      const uuidParse = z.string().uuid().safeParse(value);
      const valueIsUuid = uuidParse.success;
      return {
        form_answer_options: {
          data: valueIsUuid
            ? [{ form_question_option_id: uuidParse.data }]
            : [],
        },
      };
    default:
      return { free_text_answer: value };
  }
}

/**
 * Deleting a CustomQuestionAnswer requires deleting the custom_question_answer and its related form_answer.
 * We do not do cascading deletes, so we must specify the related rows to be deleted.
 *
 * CQT-TODO, update this to delete form_answer_option
 */
export function getCustomQuestionAnswerDeletePayload(
  formId: uuid,
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): [uuid[], uuid[]] {
  const customQuestions = questions.filter(
    (q) => q.type === AF.CustomQuestionType
  ) as AF.CustomQuestion<AF.WithId>[];

  if (customQuestions.length === 0) {
    return [[], []];
  }

  const customQuestionIdsAndNestedQuestionIdsToDelete = customQuestions.reduce(
    (accumulator, customQuestion) => {
      const { id, nestedQuestions } = customQuestion;
      const answer = answers[id];

      const [customQuestionIds, nestedQuestionIds] = accumulator;

      const result = CustomQuestionAnswerSchema.safeParse(answer);
      if (!result.success) {
        throw new Error(
          `Expecting an "object" for question ${id}, but got ${answer} instead`
        );
      }

      if (isEmpty(result.data.answersByQuestionId)) {
        customQuestionIds.push(id);

        const nestedQuestionIdsForCurrentQuestion = nestedQuestions.map(
          (question) => {
            return question.id;
          }
        );

        nestedQuestionIds.push(...nestedQuestionIdsForCurrentQuestion);
      }

      return accumulator;
    },
    [[], []] as [uuid[], uuid[]]
  );

  return customQuestionIdsAndNestedQuestionIdsToDelete;
}

function getAnswer(
  question: AF.Question<AF.WithId>,
  answers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  customQuestionAnswerBankRelationships: GQL.CustomQuestionAnswerBankRelationshipsFragment[]
): FormikFieldValue {
  const answer = answers.find((a) => a.question_id === question.id);
  switch (question.type) {
    case AF.FreeTextType:
    case AF.EmailType:
    case AF.PhoneNumberType:
      return answer?.free_text_answer ?? "";

    case AF.MultiSelectType:
      return (
        answer?.form_answer_options?.map((o) => o.form_question_option_id) ?? []
      );

    case AF.SingleSelectType:
      if (!answer?.form_answer_options) {
        return "";
      }
      return answer.form_answer_options[0]?.form_question_option_id ?? "";
    case AF.FileUploadType:
      return getDocumentIDs(answer);
    case AF.GradesType:
      return (
        gradesAnswers.find((a) => a.question_id === question.id)?.grade_config
          ?.id ?? ""
      );

    case AF.AddressType:
      /**
       * The address object is treated as a nested field in the FormTemplate Formik context.
       */
      const questionId = question.id;
      const currentAddress = addressAnswers.find(
        (a) => a.question_id === questionId
      );

      if (currentAddress === undefined) {
        return {
          street_address: "",
          street_address_line_2: "",
          city: "",
          state: "",
          zip_code: "",
        };
      }

      const { street_address, street_address_line_2, city, state, zip_code } =
        currentAddress;

      return {
        street_address,
        street_address_line_2: street_address_line_2 ?? "",
        city,
        state,
        zip_code,
      };

    case AF.CustomQuestionType:
      const relevantCustomQuestionAnswers = customQuestionAnswers.filter(
        (answer) => answer.question_id === question.id
      );
      const answersGroupedByParentQuestionId = question.nestedQuestions.reduce(
        (accumulator, nestedQuestion) => {
          const fieldAnswer = relevantCustomQuestionAnswers.find(
            (answer) => answer.form_answer.question_id === nestedQuestion.id
          );
          const value = fieldAnswer
            ? nestedQuestion.type === AF.SingleSelectType
              ? fieldAnswer.form_answer.form_answer_options[0]
                  ?.form_question_option_id ?? ""
              : fieldAnswer.form_answer.free_text_answer
            : "";
          accumulator.set(nestedQuestion.id, value);
          return accumulator;
        },
        new Map()
      );

      const answerBank = customQuestionAnswerBankRelationships.find(
        (relationship) => relationship.custom_question_id === question.id
      )?.person_answer_bank;

      return {
        kind: "CURRENT_ANSWER",
        answersByQuestionId: Object.fromEntries(
          answersGroupedByParentQuestionId
        ),
        answerBankId: answerBank?.id,
        referenceId:
          answerBank?.person_answer_bank_external_relationship?.external_id,
      };

    default:
      const _exhaustiveCheck: never = question;
      return _exhaustiveCheck;
  }
}

function getAllQuestions(
  questions: AF.Question<AF.WithId>[]
): AF.Question<AF.WithId>[] {
  const additionalQuestions = questions
    .flatMap((q) =>
      q.type === AF.SingleSelectType || q.type === AF.GradesType
        ? q.options
        : []
    )
    .flatMap((o) => o.additionalQuestions ?? []);
  return [...questions, ...additionalQuestions];
}

function getDocumentIDs(answer: GQL.FormAnswerFragment | undefined): string[] {
  if (!answer?.document_metadata.length) return [];

  const documentIds = answer.document_metadata.map((doc) => doc.document_id);
  return documentIds;
}

export function getAllDocumentIDs(
  questions: AF.Question<AF.WithId>[],
  answers: GQL.FormAnswerFragment[]
): string[] {
  const allQuestions = getAllQuestions(questions);

  const documentIDs = allQuestions
    .filter((question) => {
      return question.type === "FileUpload";
    })
    .flatMap((question) => {
      const answer = answers.find((a) => a.question_id === question.id);

      return getDocumentIDs(answer);
    });

  return documentIDs;
}

export function getAllQuestionsAndAnswers(
  questions: AF.Question<AF.WithId>[],
  form_answers: GQL.FormAnswerFragment[],
  grades_answers: GQL.GradesAnswersFragment[],
  address_answers: GQL.AddressAnswersFragment[],
  custom_question_answers: GQL.CustomQuestionAnswersFragment[],
  custom_question_answer_bank_relationships: GQL.CustomQuestionAnswerBankRelationshipsFragment[]
): [AF.Question<AF.WithId>, FormikFieldValue][] {
  const allQuestions = getAllQuestions(questions);

  return allQuestions.map((q) => [
    q,
    getAnswer(
      q,
      form_answers,
      grades_answers,
      address_answers,
      custom_question_answers,
      custom_question_answer_bank_relationships
    ),
  ]);
}

export function getFormikInitialValues(
  questions: AF.Question<AF.WithId>[],
  form_answers: GQL.FormAnswerFragment[],
  grades_answers: GQL.GradesAnswersFragment[],
  address_answers: GQL.AddressAnswersFragment[],
  custom_question_answers: GQL.CustomQuestionAnswersFragment[],
  custom_question_answer_bank_relationships: GQL.CustomQuestionAnswerBankRelationshipsFragment[] = []
): FormikValues {
  return Object.fromEntries(
    getAllQuestionsAndAnswers(
      questions,
      form_answers,
      grades_answers,
      address_answers,
      custom_question_answers,
      custom_question_answer_bank_relationships
    ).map(([q, a]) => [q.id, a])
  );
}

export function findFormAddressAnswer(
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): AddressAnswer | undefined {
  const completeQuestions = getCompleteQuestions(questions);
  const geoEligibilityQuestion = findGeoEligibilityQuestion(completeQuestions);
  if (geoEligibilityQuestion === undefined) {
    return undefined;
  }

  const address = answers[geoEligibilityQuestion.id];
  if (!isAddressAnswer(address)) {
    console.error(
      `Invalid address answer: ${address} for question: ${geoEligibilityQuestion.id}`
    );
    return undefined;
  }

  return address;
}

export function findGradeAnswer(
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): { gradeConfigId: uuid } | undefined {
  const completeQuestions = getCompleteQuestions(questions);
  const gradeQuestion = findGradesQuestion(completeQuestions);

  if (gradeQuestion === undefined) {
    return undefined;
  }

  const gradeConfigId = answers[gradeQuestion.id];
  if (!isGradesAnswer(gradeConfigId)) {
    console.error(
      `Invalid grades answer: ${gradeConfigId} for question: ${gradeQuestion.id}`
    );
    return undefined;
  }

  if (gradeConfigId === undefined) {
    return undefined;
  }

  return { gradeConfigId };
}

export function useAnswers(
  formId: uuid
): RD.RemoteData<Error, GQL.GetFormAnswersById> {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "network-only",
  });

  return remoteData.mapError<Error>(identity);
}

// Data structure for passing answer that can be "undo"-ed.
// Use the before value to undo the answer.
export type UndoableAnswer<T> = {
  before: T;
  after: T;
};
