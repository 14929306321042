import React from "react";
import { ConfigFormLayout } from "./ConfigFormLayout";

type Props = {};
export const MatchConfigForm: React.FC<Props> = () => {
  return (
    <ConfigFormLayout
      type="Match"
      title="Match"
      label="Enable running Match"
      description=""
    >
      {() => null}
    </ConfigFormLayout>
  );
};
