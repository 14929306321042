import { Checkbox, CheckboxProps } from "@chakra-ui/react";
import { Card } from "src/components/Layout/Card";

type Props = {
  label: string;
  checkboxProps?: Omit<CheckboxProps, "isChecked" | "onChange">;
  isChecked: boolean;
  children: React.ReactNode;
  onCheck?: (value: boolean) => void;
};
export const CheckboxCard: React.FC<Props> = ({
  label,
  children,
  isChecked,
  onCheck,
  checkboxProps,
}) => {
  return (
    <Card
      showBorder
      padding="2"
      borderColor="gray.100"
      display="flex"
      flexDirection="column"
      gap="2"
      bg={isChecked ? "gray.100" : undefined}
    >
      <Checkbox
        isChecked={isChecked}
        onChange={() => {
          if (onCheck) onCheck(!isChecked);
        }}
        {...checkboxProps}
      >
        {label}
      </Checkbox>
      {children}
    </Card>
  );
};
