import { useField } from "formik";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { CustomQuestionAnswer } from "src/components/Form/QuestionForm/formik";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { FormCustomQuestion } from "src/services/url/Parent";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import { HasuraRole } from "src/types/hasuraRole";
import { toTuple } from "src/types/remoteData";
import { BaseInputProps } from "../Question";
import { AdminCustomQuestion } from "./modules/AdminCustomQuestion";
import { ParentGuardianCustomQuestion } from "./modules/ParentGuardianCustomQuestion";
import { useGetCustomQuestionTypeQuery } from "./modules/api";

export type CustomQuestionProps = BaseInputProps & {
  question: string;
  customQuestionTypeId: uuid;
  formId: uuid;
  nestedQuestions: ClonedQuestion<WithId>[];
};

export const CustomQuestion: FunctionComponent<CustomQuestionProps> = (
  props
) => {
  const {
    customQuestionTypeId,
    formId,
    nestedQuestions,
    id: questionId,
    question: questionLabel,
    isRequired,
    isDisabled,
  } = props;

  const organizationRemoteData = useOrganization();
  const customQuestionTypeRemoteData = useGetCustomQuestionTypeQuery({
    custom_question_type_id: customQuestionTypeId,
  });

  const userIsAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.DISTRICT_ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.SCHOOL_ADMIN,
  ]);

  const [{ value }] = useField<CustomQuestionAnswer | undefined>(questionId);
  const answersByQuestionId = value?.answersByQuestionId ?? {};

  // step only exists on parent view, handle Admin view
  const { step = "" } = useParams();

  return (
    <RemoteDataView
      remoteData={toTuple(organizationRemoteData, customQuestionTypeRemoteData)}
      error={() => <GenericError message="Failed to load answer bank" />}
    >
      {([organization, customQuestionType]) => {
        if (!customQuestionType) {
          return null;
        }

        if (userIsAdmin) {
          return (
            <AdminCustomQuestion
              formId={formId}
              customQuestionId={questionId}
              nestedQuestions={nestedQuestions}
              isRequired={isRequired}
              isDisabled={isDisabled}
            />
          );
        }

        const answerCustomQuestionRoute = FormCustomQuestion.edit(
          organization,
          formId,
          parseInt(step, 10),
          questionId
        );

        return (
          <ParentGuardianCustomQuestion
            answerCustomQuestionRoute={answerCustomQuestionRoute}
            formId={formId}
            questionId={questionId}
            questionLabel={questionLabel.toLocaleLowerCase()}
            nestedQuestions={nestedQuestions}
            answersByQuestionId={answersByQuestionId}
          />
        );
      }}
    </RemoteDataView>
  );
};
