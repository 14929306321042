import { Box, Text, VStack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { SearchAndFilterTypes } from "src/constants";
import { handleFilterChange } from "src/services/filter";
import { GradesQuestionFilter } from "../graphql/utils";

interface FormFiltersGradesQuestionProps {
  isLoading?: boolean;
  gradesQuestionFilters?: GradesQuestionFilter;
}

export const FormFiltersGradesQuestion: React.FC<
  FormFiltersGradesQuestionProps
> = ({ gradesQuestionFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFormMultiSelectFilterChange = (
    newValues: { label: string; id: string }[]
  ) => {
    setSearchParams(
      handleFilterChange(
        searchParams,
        SearchAndFilterTypes.Grades,
        newValues[newValues.length - 1]?.id ?? ""
      )
    );
  };

  const handleRemoveMultiSelectFilter = (value: {
    id: string;
    label: string;
  }) => {
    setSearchParams(
      handleFilterChange(searchParams, SearchAndFilterTypes.Grades, value.id)
    );
  };

  const handleInitialValues = (options: { label: string; id: string }[]) =>
    options.filter((option) =>
      searchParams.get(SearchAndFilterTypes.Grades)?.includes(option.id)
    );

  return (
    <VStack align="inherit">
      <Text size="sm" fontWeight="400">
        {gradesQuestionFilters?.questionLabel}
      </Text>
      <MultiSelectInput
        size="md"
        options={gradesQuestionFilters?.options ?? []}
        onChange={handleFormMultiSelectFilterChange}
        renderDropdownItem={(item) => (
          <Box>
            <span>{item.label}</span>
          </Box>
        )}
        initialValues={handleInitialValues(
          gradesQuestionFilters?.options ?? []
        )}
        onRemoveTag={handleRemoveMultiSelectFilter}
      />
    </VStack>
  );
};
