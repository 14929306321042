import React from "react";
import { createContext, FunctionComponent, useContext } from "react";
import * as GQL from "src/types/graphql";
import { State } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/editMessagesState";
import { Action, useFormTemplateEditMessagesProcessor } from "./processor";

import { FormType as EditMessagesFormType } from "./components/types";
type ContextValue = {
  state?: State;
  dispatch?: (action: Action) => void;
  messageTemplateType?: GQL.message_template_type_enum;
  formTemplateId?: uuid;
};

const EditFormTemplateEditMessagesContext = createContext<ContextValue>({
  state: undefined,
  dispatch: undefined,
  messageTemplateType: undefined,
  formTemplateId: undefined
});

type Props = {
  children: React.ReactNode;
  editMessagesInitialValues: EditMessagesFormType;
  messageTemplateType: GQL.message_template_type_enum;
  formTemplateId: uuid;
};

export const EditFormTemplateEditMessagesProvider: FunctionComponent<Props> = (
  props
) => {
  const {
    children,
    editMessagesInitialValues,
    messageTemplateType,
    formTemplateId
  } = props;
  const formTemplateEditMessagesState = useFormTemplateEditMessagesProcessor(
    { editMessages: editMessagesInitialValues },
    messageTemplateType,
    formTemplateId
  );

  return (
    <EditFormTemplateEditMessagesContext.Provider
      value={{
        ...formTemplateEditMessagesState,
        messageTemplateType
      }}
    >
      {children}
    </EditFormTemplateEditMessagesContext.Provider>
  );
};

export function useEditFormTemplateEditMessagesContext() {
  const context = useContext<ContextValue>(EditFormTemplateEditMessagesContext);

  if (context === undefined) {
    throw new Error(
      "useEditFormTemplateEditMessagesContext must be used within a FormTemplateEditMessagesProvider"
    );
  }

  return context;
}
