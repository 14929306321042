import { gql } from "@apollo/client";

export const GET_FORM_IMPORT = gql`
  query GetFormImport($id: uuid!) {
    form_import(where: { id: { _eq: $id } }) {
      id
      status
      review_status
      errors
      warnings
      created_at
      updated_at
      form_template_id
      enrollment_period {
        id
        name
      }
    }
  }
`;

export const GET_FORM_DATA_IMPORT_ROW_AGGREGATES = gql`
  query GetFormImportRowAggregates($form_import_id: uuid!) {
    form_import(where: { id: { _eq: $form_import_id } }) {
      id
    }
    in_progress: form_import_row_aggregate(
      where: {
        _and: [
          { form_import_id: { _eq: $form_import_id } }
          { status: { _eq: InProgress } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    completed: form_import_row_aggregate(
      where: {
        _and: [
          { form_import_id: { _eq: $form_import_id } }
          { status: { _eq: Completed } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    failed: form_import_row_aggregate(
      where: {
        _and: [
          { form_import_id: { _eq: $form_import_id } }
          { status: { _eq: Failed } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FORM_DATA_IMPORT_ROW_ERRORS = gql`
  query GetFormImportRowErrors($form_import_id: uuid!) {
    form_import_row(
      where: {
        _and: [
          { form_import_id: { _eq: $form_import_id } }
          { status: { _eq: Failed } }
        ]
      }
      order_by: { row_number: asc }
    ) {
      errors
      row_number
    }
  }
`;
