import Immutable from "immutable";
import { z } from "zod";
import * as Draft from "./editMessagesDraft";
import { FormType as EditMessagesType } from "../EditFormTemplateTabs/AutomaticMessages/Edit/components/types";
import { Change, MessageTemplateIdType, WithDraft } from "./common";

export type EditMessagesChange = Change<
  EditMessagesType,
  Draft.EditMessagesDraft
>;

type StateInternal = {
  editMessages: Immutable.Map<MessageTemplateIdType, EditMessagesChange>;
  save: uuid | undefined;
  reset: uuid | undefined;
};
export type State = Immutable.Record<StateInternal>;
export const NewState = Immutable.Record<StateInternal>({
  editMessages: Immutable.Map(),
  save: undefined,
  reset: undefined
});
/**
 * Local storage state
 */
export const LocalstorageSchema = z.object({
  editMessages: z.record(z.string(), z.object({ draft: z.any() }))
});

export type LocalStorageState = z.infer<typeof LocalstorageSchema>;

export function serialize(state: State): LocalStorageState {
  const editMessages = state
    .get("editMessages")
    .map((change) => {
      return { draft: change.draft } as const;
    })
    .filter((change): change is WithDraft<Draft.EditMessagesDraft> => {
      return change.draft !== undefined;
    })
    .mapKeys((key) => key.get("messageTemplateId"))
    .toObject();

  const localStorageState: LocalStorageState = {
    editMessages
  };

  return localStorageState;
}

export function deserialize(state: State, json: unknown): State {
  const result = LocalstorageSchema.safeParse(json);
  const savedState: LocalStorageState | undefined = result.success
    ? result.data
    : undefined;

  if (!savedState) {
    return state;
  }

  return NewState({
    editMessages: state.get("editMessages").map((change, key) => {
      return {
        ...change,
        draft: savedState.editMessages[key.get("messageTemplateId")]?.draft as
          | Draft.EditMessagesDraft
          | undefined
      };
    })
  });
}
