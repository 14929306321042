import { Button, MenuItem, Text } from "@chakra-ui/react";
import React from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  GET_ACTIVE_FORM_TEMPLATES,
  GET_OFFERS,
} from "src/scenes/parent/formTemplates/graphql/queries";
import * as GQL from "src/types/graphql";
import { DECLINE_OFFER } from "../../graphql/mutations";

interface Props {
  offerId: uuid;
  school: string;
  isButton?: boolean;
}

export const DeclineOffer = ({ offerId, school, isButton = false }: Props) => {
  const [declineOffer] = useRemoteDataMutation<
    GQL.DeclineOffer,
    GQL.DeclineOfferVariables
  >(DECLINE_OFFER);

  const toast = useAvelaToast({ position: "bottom" });

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "No, cancel",
      },
      confirmButton: {
        label: "Yes, decline",
        colorScheme: "red",
      },
      translate: true,
    });

  const handleOnClick = async (evt: React.MouseEvent) => {
    setHeader(<Text>Decline offer?</Text>);
    setBody(
      <Text>{`Declining the offer from ${school} can't be undone nor recovered.`}</Text>
    );

    if (await confirm()) {
      try {
        await declineOffer({
          variables: {
            offer_id: offerId,
          },
          refetchQueries: [GET_ACTIVE_FORM_TEMPLATES, GET_OFFERS],
        });

        toast({
          title: "Declined offer",
          status: "info",
        });
      } catch (err) {
        toast.error({
          title: "Something went wrong!",
          description: "Check your network and try again.",
        });
        console.error(err);
      }
    }
  };

  if (isButton) {
    return (
      <>
        <Button
          variant="outline"
          colorScheme="gray"
          size="sm"
          width="50%"
          onClick={handleOnClick}
        >
          Decline
        </Button>
        {confirmationDialog}
      </>
    );
  }

  return (
    <>
      <MenuItem
        textColor="gray.700"
        fontWeight="400"
        fontSize="sm"
        onClick={handleOnClick}
      >
        Decline offer
      </MenuItem>
      {confirmationDialog}
    </>
  );
};
