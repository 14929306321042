import { z } from "zod";
import { isValidDate } from "../services/formValidations";
import { REQUIRED_SHORT_VALIDATION_ERROR } from "../services/url/constants";
import {
  asRequiredField,
  asValidator,
  nullableWithDefault,
} from "../services/zodExtra";

export const emptyNameForm: NameFormType = {
  first_name: "",
  middle_name: "",
  last_name: "",
  birth_date: "",
};

export const NameSchema = z.object({
  first_name: nullableWithDefault(z.string().trim(), ""),
  middle_name: nullableWithDefault(z.string().trim(), ""),
  last_name: nullableWithDefault(z.string().trim(), ""),
  birth_date: nullableWithDefault(z.string().trim(), ""),
});

export const NameValidator = asValidator(
  asRequiredField(NameSchema, "first_name", "last_name"),
  "birth_date",
  isValidDate,
  REQUIRED_SHORT_VALIDATION_ERROR
);

export type NameFormType = z.infer<typeof NameSchema>;
