import {
  Box,
  Container,
  Flex,
  Hide,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RiFileTextLine } from "react-icons/ri";
import { DocViewerControls } from "./DocViewerControls";
import { ProxyRenderer } from "./renderers/ProxyRenderer";
import { DocViewerProvider } from "./state";

type Props = {
  title: string;
  uri: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
};

export const DocViewer = ({ title, uri, isOpen, onClose }: Props) => {
  const [contentType, setContentType] = useState<string>();
  const [documentData, setDocumentData] = useState<ArrayBuffer>();

  useEffect(() => {
    fetch(uri, {
      method: "GET",
    })
      .then((response) => {
        const contentTypeRaw = response.headers.get("content-type");
        const contentTypes = contentTypeRaw?.split(";") || [];
        const contentType = contentTypes.length ? contentTypes[0] : undefined;
        setContentType(contentType);

        return response.arrayBuffer();
      })
      .then((documentData) => {
        setDocumentData(documentData);
      });
  }, [uri, setDocumentData]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        width="100vw"
        height="100dvh"
        m={0}
        maxW="100vw"
        maxHeight="100vh"
        borderRadius={0}
        background="transparent"
      >
        <DocViewerProvider>
          <ModalHeader
            as={Flex}
            display="flex"
            gap={2}
            fontSize="md"
            fontWeight="400"
            alignItems="center"
            background="white"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: { lg: "33vw" },
              }}
            >
              <RiFileTextLine /> {title}
            </Box>
            <Hide below="lg">
              <DocViewerControls uri={uri} />
            </Hide>
            <ModalCloseButton top="none" />
          </ModalHeader>
          <ModalBody p="2">
            <Container centerContent>
              <ProxyRenderer
                contentType={contentType}
                documentData={documentData}
              />
            </Container>
          </ModalBody>
          <Hide above="lg">
            <ModalFooter justifyContent="center" background="white">
              <DocViewerControls uri={uri} />
            </ModalFooter>
          </Hide>
        </DocViewerProvider>
      </ModalContent>
    </Modal>
  );
};
