import { useCallback, useState } from "react";
import * as GQL from "src/types/graphql";
import { SetVisibilityMenu } from "./SetVisibilityMenu";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { UPDATE_BULK_FORM_VISIBILITY } from "../graphql/mutations";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { SchoolFormId } from "../types";
import _ from "lodash";
import { isNotNull } from "src/services/predicates";

interface SetVisibilityMenuBulkProps {
  fetchSelectedFormKeys: () => Promise<SchoolFormId[]>;
  refetchForms: () => Promise<unknown>;
}
const MAX_COUNT = 30000;
const setBulkVisibilityToastId = "setBulkVisibilityToastId";

export function SetVisibilityMenuBulk({
  fetchSelectedFormKeys,
  refetchForms,
}: SetVisibilityMenuBulkProps) {
  const toast = useAvelaToast();
  const [selectedFormIds, setSelectedFormIds] = useState<string[]>();

  const [UpdateBulkFormVisibility] = useRemoteDataMutation<
    GQL.UpdateBulkFormVisibility,
    GQL.UpdateBulkFormVisibilityVariables
  >(UPDATE_BULK_FORM_VISIBILITY);

  const handleInitialClick = useCallback(async () => {
    const formKeys = await fetchSelectedFormKeys();
    const formIds = _.uniq(formKeys.map((key) => key.formId).filter(isNotNull));
    setSelectedFormIds(formIds);
  }, [fetchSelectedFormKeys]);

  const handleVisibilityChange = useCallback(
    async (visibility: boolean) => {
      if (!selectedFormIds) return;

      if (selectedFormIds.length > MAX_COUNT) {
        if (!toast.isActive(setBulkVisibilityToastId)) {
          toast({
            id: setBulkVisibilityToastId,
            title: "Error updating visibility",
            description: "Too many forms selected",
            status: "error",
            isClosable: true,
          });
        }
      }
      try {
        await UpdateBulkFormVisibility({
          variables: {
            form_ids: selectedFormIds,
            visibility: visibility,
          },
        });

        if (!toast.isActive(setBulkVisibilityToastId)) {
          toast({
            id: setBulkVisibilityToastId,
            title: `Setting to ${visibility ? "Not Visible" : "Visible"}`,
            description: "Visibility will be set shortly",
            isClosable: true,
          });
        }

        await refetchForms();
      } catch (error) {
        console.error(error);
        if (!toast.isActive(setBulkVisibilityToastId)) {
          toast({
            id: setBulkVisibilityToastId,
            title: "Error updating visibility",
            status: "error",
            isClosable: true,
          });
        }
      }
    },
    [UpdateBulkFormVisibility, toast, selectedFormIds, refetchForms]
  );
  return (
    <SetVisibilityMenu
      onVisibilityChange={handleVisibilityChange}
      isBulkAction={true}
      formsCount={selectedFormIds?.length}
      onInitialClick={handleInitialClick}
    />
  );
}
