import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { Glossary } from "src/components/Text/Glossary";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { SchoolSchema, SchoolValidator } from "src/schemas/School";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { SchoolForm } from "../components/Forms/SchoolForm";
import { UPDATE_SCHOOL } from "./graphql/mutations";
import { GET_SCHOOL_BY_ID } from "./graphql/queries";

export const EditSchool = () => {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);

  const { id = "" } = useParams();
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.GetSchool,
    GQL.GetSchoolVariables
  >(GET_SCHOOL_BY_ID, {
    variables: { id },
  });
  const [updateSchool] = useRemoteDataMutation<
    GQL.UpdateSchool,
    GQL.UpdateSchoolVariables
  >(UPDATE_SCHOOL);
  const navigate = useNavigate();
  const toast = useAvelaToast();

  const handleSubmit = async (values: GQL.school_set_input) => {
    organization.do(async (org) => {
      setSubmitting(true);
      try {
        await updateSchool({ variables: { id, school: values } });
        navigate(Url.OrgAdmin.Schools.index(org));
      } catch (err) {
        console.error(err);
        toast.error({ title: glossary`Error updating school` });
      } finally {
        setSubmitting(false);
      }
    });
  };

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.school_by_pk === null) {
          return <NotFound />;
        }
        const parsed = SchoolSchema.safeParse(data.school_by_pk);
        if (!parsed.success) {
          console.error(parsed.error);
          return <GenericError />;
        }

        return (
          <>
            <Breadcrumb
              items={breadcrumb.school.getBreadcrumbsForEdit(
                organization,
                data.school_by_pk
              )}
              mb={8}
            />
            <Formik
              initialValues={parsed.data}
              onSubmit={handleSubmit}
              validate={validateWithZod(SchoolValidator)}
            >
              {(formikProps) => {
                return (
                  <Flex as={Form} direction="column" gap={12}>
                    <Heading as="h2" fontSize="2xl">
                      <Glossary>Edit school</Glossary>
                    </Heading>
                    <SchoolForm glossary={glossary} />
                    <Flex align="center">
                      <Spacer />
                      <Button
                        as={NavLink}
                        to={organization
                          .map((org) => Url.OrgAdmin.Schools.index(org))
                          .withDefault("#")}
                        variant="link"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        marginLeft={4}
                        isLoading={submitting}
                      >
                        Update
                      </Button>
                    </Flex>
                  </Flex>
                );
              }}
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
