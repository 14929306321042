import { gql } from "@apollo/client";

export const UPLOAD_RELATIONSHIPS = gql`
  mutation UploadRelationships(
    $relationships: [relationship_person_insert_input!]!
  ) {
    insert_relationship_person(objects: $relationships) {
      affected_rows
    }
  }
`;

export const BULK_UPLOAD_PEOPLE = gql`
  mutation BulkUploadPeople($people: [person_insert_input!]!) {
    insert_person(objects: $people) {
      affected_rows
    }
  }
`;

export const DELETE_DOCUMENT_ITEM = gql`
  mutation DeleteDocumentItem($document_id: uuid!) {
    update_document_metadata_by_pk(
      pk_columns: { document_id: $document_id }
      _set: { deleted_at: "now()" }
    ) {
      document_id
    }
  }
`;

export const INSERT_FORM_ANSWER = gql`
  mutation InsertFormAnswerOne($form_answer: form_answer_insert_input!) {
    insert_form_answer_one(object: $form_answer) {
      id
    }
  }
`;

export const ADD_PERSON_RELATIONSHIP = gql`
  mutation AddPersonRelationship(
    $relationship: relationship_person_insert_input!
  ) {
    insert_relationship_person_one(
      object: $relationship
      on_conflict: {
        update_columns: active
        constraint: relationship_person_first_person_second_person_key
      }
    ) {
      id
    }
  }
`;

export const DELETE_PERSON_RELATIONSHIP = gql`
  mutation DeletePersonRelationship($id: uuid!) {
    update_relationship_person_by_pk(
      pk_columns: { id: $id }
      _set: { active: false }
    ) {
      id
    }
  }
`;

export const DELETE_FORM_TEMPLATE = gql`
  mutation DeleteFormTemplate($id: uuid!) {
    update_form_template_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }

    update_assigned_form(
      where: { form_template_id: { _eq: $id } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ENROLLMENT_PERIOD = gql`
  mutation DeleteEnrollmentPeriod($id: uuid!) {
    update_enrollment_period_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const UPLOAD_APPLICANT_ATTENDING_SCHOOLS = gql`
  mutation UploadApplicantAttendingSchools(
    $data: [applicant_attending_school_insert_input!]!
  ) {
    insert_applicant_attending_school(
      objects: $data
      on_conflict: {
        constraint: applicant_attending_school_person_id_enrollment_period_id_key
        update_columns: [school_id, attendance_status, description]
      }
    ) {
      affected_rows
    }
  }
`;
