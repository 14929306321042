import { ListItem, OrderedList, VisuallyHidden } from "@chakra-ui/react";
import React from "react";
import { StepProps } from "../Layout/FormStepLayout";

export const Stepper: React.FC<StepProps> = ({ currentStep, steps }) => {
  return (
    <OrderedList
      display="flex"
      flexDirection="row"
      margin="0"
      justifyContent="center"
      gap="0.5"
      width="full"
    >
      {steps.map((step, index) => {
        const isCurrent = currentStep - 1 === index;
        const isPrevious = currentStep - 1 > index;
        const backgroundColor = isCurrent
          ? "primary.200"
          : isPrevious
          ? "primary.500"
          : "blackAlpha.100";
        return (
          <ListItem
            key={step.id}
            padding="0"
            margin="0"
            display="flex"
            height="2"
            aria-current={isCurrent}
            bg={backgroundColor}
            position="relative"
            flexGrow={1}
            _first={{
              borderLeftRadius: 4,
            }}
            _last={{
              borderRightRadius: 4,
            }}
          >
            <VisuallyHidden>
              {isCurrent
                ? `Current step is Step ${index + 1} ${step}`
                : `Step ${index + 1} ${step}`}
            </VisuallyHidden>
          </ListItem>
        );
      })}
    </OrderedList>
  );
};
