import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { GET_GRADE_CONFIG_ANSWER } from "src/components/Form/graphql/queries";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";

type Props = {
  formId: uuid;
};
type ReturnType = RemoteData<ApolloError, uuid | undefined>;
export function useGradeAnswer({ formId }: Props): ReturnType {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetGradeConfigAnswer,
    GQL.GetGradeConfigAnswerVariables
  >(GET_GRADE_CONFIG_ANSWER, {
    variables: {
      form_id: formId,
    },
  });

  const grade = useMemo(
    () =>
      remoteData.map((data) => {
        return data.grades_answer[0]?.grade_config_id ?? undefined;
      }),
    [remoteData]
  );

  return grade;
}
