import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useDeleteConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import { NoScheduledExports } from "./NoScheduledExports";
import { ScheduledExportItem } from "./ScheduledExportItem";
import { ListSchedulesResponse } from "./types";
import { useExportManagementAPI } from "./useExportManagementAPI";

export const ScheduledExportsList = () => {
  const organization = useOrganization();
  const toast = useToast();
  const api = useExportManagementAPI();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isError, setError] = React.useState<boolean>(false);
  const [schedules, setSchedules] = React.useState<ListSchedulesResponse>([]);
  const [deleting, setDeleting] = React.useState<string>();
  // const [patching, setPatching] = React.useState<string>();
  const { confirm, confirmationDialog } = useDeleteConfirmationDialog({});

  const loadSchedules = React.useCallback(() => {
    if (!organization.hasData()) return;

    setLoading(true);

    api
      .getScheduledExports(organization.data.id)
      .then((schedules): void => {
        if (schedules) {
          setSchedules(schedules);
        }
        setError(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.hasData, api.getScheduledExports]);

  const onDelete = React.useCallback(
    async (pk: string) => {
      if (!organization.hasData()) return;
      if (!(await confirm())) return;
      setDeleting(pk);
      const toastId = "delete-schedule";
      api
        .deleteSchedule(organization.data.id, pk)
        .then(() => {
          toast({
            id: toastId,
            title: `Schedule deleted`,
            isClosable: true,
            status: "info",
          });
          loadSchedules();
        })
        .catch((error) => {
          console.log(error);
          toast({
            id: toastId,
            title: `Something went wrong!`,
            description: "Check your network and try again.",
            isClosable: true,
            status: "error",
          });
        })
        .finally(() => setDeleting(undefined));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization.hasData, api.deleteSchedule]
  );

  React.useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);

  if (isLoading) {
    return (
      <Box display="grid" minHeight="50vh" placeContent="center">
        <Spinner color="primary.500" size="lg" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="grid" minHeight="50vh" placeContent="center">
        <Text fontSize="xl" textColor="red.500" fontWeight="700">
          Something went wrong...
        </Text>
      </Box>
    );
  }

  return (
    <>
      {schedules.length === 0 && <NoScheduledExports />}

      {schedules.length > 0 && (
        <Flex direction="column" gap="6">
          {schedules.map((schedule) => (
            <ScheduledExportItem
              key={schedule.pk}
              item={schedule}
              timezoneName={organization
                .map((org) => org.timezone_name)
                .withDefault("")}
              // TODO: edit scheduled export https://app.asana.com/0/1203752480528846/1207946344388263/f
              // editLink={organization
              //   .map((org) => `${Url.OrgAdmin.Webhooks.edit(org, webhook.pk)}`)
              //   .withDefault("#")}
              onDelete={onDelete}
              isDeleting={deleting === schedule.pk}
              // isPatching={patching === webhook.pk}
            />
          ))}
          {confirmationDialog}
        </Flex>
      )}
    </>
  );
};
