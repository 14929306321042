import { Box, Text } from "@chakra-ui/react";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { OrderedItem } from "./helpers";

interface DropdownProps {
  itemType: string;
  options: OrderedItem[];
  initialValues: OrderedItem[];
  onChange: (values: OrderedItem[]) => void;
  onRemoveItem?: (item: OrderedItem) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  itemType,
  options,
  initialValues,
  onChange,
  onRemoveItem,
}) => (
  <Box width="100%">
    <Text fontSize="xs" color="gray.800">
      {itemType}
    </Text>
    <MultiSelectInput<OrderedItem>
      size="md"
      options={options}
      initialValues={initialValues}
      onChange={onChange}
      labelAttr="label"
      renderDropdownItem={(item: OrderedItem) => (
        <Box>
          <span>{item.label}</span>
        </Box>
      )}
      placeholder={"Select multiple " + itemType.toLowerCase()}
      onRemoveTag={onRemoveItem}
      marginY={1}
    />
  </Box>
);
