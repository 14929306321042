import { Icon, IconButton } from "@chakra-ui/react";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";

export type NavigationFoldButtonProps = {
  isOpen: boolean;
  onToggle: () => void;
};

export const NavigationFoldButton = ({
  isOpen,
  onToggle,
}: NavigationFoldButtonProps) => {
  return (
    <IconButton
      aria-label={isOpen ? "Collapse Navigation" : "Expand Navigation"}
      onClick={onToggle}
      variant={"ghost"}
      color={"white"}
      background="none"
      _hover={{ background: "none" }}
      _active={{ background: "none" }}
    >
      <Icon as={isOpen ? RiMenuFoldLine : RiMenuUnfoldLine} boxSize={6} />
    </IconButton>
  );
};
