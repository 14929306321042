import {
  RiCheckLine,
  RiCloseFill,
  RiErrorWarningLine,
  RiTimeLine,
} from "react-icons/ri";
import {
  StatusTag,
  StatusTagMetadata,
} from "src/components/DataDisplay/StatusTag";
import * as GQL from "src/types/graphql";

const MessageStatusTypes = {
  ...GQL.message_status_enum,
  PartiallySent: "PartiallySent",
};
type MessageStatusTagType =
  (typeof MessageStatusTypes)[keyof typeof MessageStatusTypes];

interface MessageStatusTagProps {
  status?: MessageStatusTagType;
  totalRecipients?: number;
  sentRecipients?: number;
}

const MESSSAGE_STATUS: Record<MessageStatusTagType, StatusTagMetadata> = {
  [MessageStatusTypes.Sent]: {
    label: "Sent",
    longLabel: "Sent",
    icon: RiCheckLine,
    colorScheme: "green",
  },
  [MessageStatusTypes.Failed]: {
    label: "Not sent",
    longLabel: "Not sent",
    icon: RiCloseFill,
    colorScheme: "red",
  },
  [MessageStatusTypes.NotSent]: {
    label: "Not sent",
    longLabel: "Not sent",
    icon: RiCloseFill,
    colorScheme: "red",
  },
  [MessageStatusTypes.Pending]: {
    label: "Pending",
    longLabel: "Pending",
    icon: RiTimeLine,
    colorScheme: "gray",
  },
  [MessageStatusTypes.PartiallySent]: {
    label: "Partially sent",
    longLabel: "Partially sent",
    icon: RiErrorWarningLine,
    colorScheme: "yellow",
  },
};

export const MessageStatusTag: React.FC<MessageStatusTagProps> = ({
  status,
  totalRecipients,
  sentRecipients,
}) => {
  let statusMetadata = MESSSAGE_STATUS[MessageStatusTypes.NotSent];

  if (status) {
    statusMetadata = MESSSAGE_STATUS[status];
  } else if (sentRecipients === 0) {
    statusMetadata = MESSSAGE_STATUS[MessageStatusTypes.Failed];
  } else if (totalRecipients !== sentRecipients) {
    statusMetadata = MESSSAGE_STATUS[MessageStatusTypes.PartiallySent];
  } else if (sentRecipients === totalRecipients) {
    statusMetadata = MESSSAGE_STATUS[MessageStatusTypes.Sent];
  }

  if (!statusMetadata) return <></>;

  return <StatusTag status={statusMetadata} />;
};
