import { useDisclosure } from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  ButtonProps,
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "../components/Dialogs/ConfirmationDialog";

type Helpers = {
  setButton: (button: ButtonProps) => void;
  close: () => void;
};
export type ConfirmProps = {
  header?: React.ReactNode;
  body?: React.ReactNode;
  confirmButton?: ButtonProps;
  cancelButton?: ButtonProps;
  onConfirm?: (helpers: Helpers) => void;
  onCancel?: () => void;
};

export function useConfirmationDialog({
  body,
  header,
  ...props
}: Omit<ConfirmationDialogProps, "onCancel" | "onConfirm" | "isOpen">) {
  const [dynamicBody, setBody] = React.useState<React.ReactNode>(body);
  const [dynamicHeader, setHeader] = React.useState<React.ReactNode>(header);
  const [dynamicCancelButton, setDynamicCancelButton] = React.useState<
    ButtonProps | undefined
  >(undefined);
  const [dynamicConfirmButton, setDynamicConfirmButton] = React.useState<
    ButtonProps | undefined
  >(undefined);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const promiseRef = React.useRef<
    { resolve: (value: boolean) => void } | undefined
  >();
  const [dynamicOnConfirm, setDynamicOnConfirm] = React.useState<
    ((helpers: Helpers) => void) | undefined
  >(undefined);
  const [dynamicOnCancel, setDynamicOnCancel] = React.useState<
    ((helpers: Helpers) => void) | undefined
  >(undefined);

  const confirm = async (confirmProps?: ConfirmProps): Promise<boolean> => {
    if (confirmProps?.header) {
      setHeader(confirmProps.header);
    }
    if (confirmProps?.body) {
      setBody(confirmProps.body);
    }
    if (confirmProps?.confirmButton) {
      setDynamicConfirmButton(confirmProps.confirmButton);
    } else {
      setDynamicConfirmButton(props.confirmButton);
    }
    if (confirmProps?.cancelButton) {
      setDynamicCancelButton(confirmProps.cancelButton);
    } else {
      setDynamicCancelButton(props.cancelButton);
    }
    if (confirmProps?.onConfirm) {
      const onConfirm = confirmProps.onConfirm;
      setDynamicOnConfirm(() => onConfirm);
    } else {
      setDynamicOnConfirm(undefined);
    }
    if (confirmProps?.onCancel) {
      const onCancel = confirmProps.onCancel;
      setDynamicOnCancel(() => onCancel);
    } else {
      setDynamicOnCancel(undefined);
    }

    onOpen();
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve };
    });
  };

  const helpers: Helpers = useMemo(() => {
    return {
      setButton: setDynamicConfirmButton,
      close: onClose,
    };
  }, [onClose]);

  const onCancel = React.useCallback(() => {
    if (dynamicOnCancel) {
      dynamicOnCancel(helpers);
    } else {
      onClose();
    }
    if (promiseRef.current) promiseRef.current.resolve(false);
  }, [dynamicOnCancel, helpers, onClose]);

  const onConfirm = React.useCallback(() => {
    if (dynamicOnConfirm) {
      dynamicOnConfirm(helpers);
    } else {
      onClose();
    }
    if (promiseRef.current) promiseRef.current.resolve(true);
  }, [dynamicOnConfirm, helpers, onClose]);

  return {
    confirm,
    confirmationDialog: (
      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
        header={dynamicHeader}
        body={dynamicBody}
        {...props}
        confirmButton={
          dynamicConfirmButton ? dynamicConfirmButton : props.confirmButton
        }
        cancelButton={
          dynamicCancelButton ? dynamicCancelButton : props.cancelButton
        }
      />
    ),
    setBody,
    setHeader,
    helpers,
  };
}

export function useDeleteConfirmationDialog({
  header = "Delete Confirmation",
  body = "Are you sure? You can't undo this action afterwards.",
  ...props
}: Omit<
  ConfirmationDialogProps,
  "onCancel" | "onConfirm" | "isOpen" | "header" | "body"
> & { header?: string; body?: React.ReactNode }) {
  return useConfirmationDialog({
    header,
    body,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, delete", colorScheme: "red" },
    ...props,
  });
}
