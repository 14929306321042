import { AddressSchema, AddressValidator } from "src/schemas/Address";
import {
  BASE_INITIAL_STUDENT_FORM,
  StudentSchema,
  StudentValidator,
  StudentAttendanceSchema,
  StudentAttendanceItemSchema,
} from "src/schemas/Student";
import { z } from "zod";

export const StudentProfileSchema = StudentSchema.merge(AddressSchema);

export const StudentProfileValidator = StudentValidator.merge(AddressValidator);

export type StudentProfileFormAttendanceItem = z.infer<
  typeof StudentAttendanceItemSchema
>;

export type StudentProfileFormAttendance = z.infer<
  typeof StudentAttendanceSchema
>;

export type StudentProfileForm = z.infer<typeof StudentProfileSchema>;

export const INITIAL_STUDENT_PROFILE: StudentProfileForm = {
  ...BASE_INITIAL_STUDENT_FORM,
  street_address: "",
  street_address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
};
