import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ProgramGroupForm } from "./Form";
import { CREATE_PROGRAM_GROUP } from "./graphql/mutations";
import { GET_PROGRAM_GROUPS } from "./graphql/queries";
import { ProgramGroupType, validate } from "./types/program";

export const New = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [createProgramGroup, { remoteData }] = useRemoteDataMutation<
    GQL.CreateProgramGroup,
    GQL.CreateProgramGroupVariables
  >(CREATE_PROGRAM_GROUP);

  const handleSubmit = React.useCallback(
    async (programGroup: ProgramGroupType) => {
      try {
        if (!validate(programGroup)) {
          throw new Error(
            "Program group is invalid. Make sure all fields are non-empty."
          );
        }
        await createProgramGroup({
          variables: {
            program_group: {
              organization_id: programGroup.organization_id,
              name: programGroup.name,
              programs: { data: programGroup.programs },
            },
          },
          refetchQueries: [GET_PROGRAM_GROUPS],
        });
        toast({
          id: "NewProgramGroup",
          title: "Program group created",
          isClosable: true,
          status: "success",
        });
        navigate(Url.Admin.Programs.index());
      } catch (error: any) {
        console.error(error);
        toast({
          id: "NewProgramGroup",
          title: "Error creating program group",
          description: error.message,
          isClosable: true,
          status: "error",
        });
      }
    },
    [createProgramGroup, navigate, toast]
  );

  return (
    <ProgramGroupForm
      onSubmit={handleSubmit}
      submitting={remoteData.isLoading()}
    />
  );
};
