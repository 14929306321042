import flagsmith from "flagsmith";
import * as RD from "src/types/remoteData";
import * as Flagsmith from "flagsmith/react";
import { FlagsmithProvider } from "flagsmith/react";
import { IInitConfig } from "flagsmith/types";
import { ReactElement, useEffect, useMemo } from "react";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import useUser from "src/hooks/useUser";
import * as Env from "src/services/env";
import { Status } from "src/types/authData";
import { FeatureFlag } from "src/types/featureFlag";

type Props = {
  children: ReactElement;
};
export function FeatureFlagProvider({ children }: Props) {
  const env = Env.read();
  const user = useUser();
  const organization = useOrganizationPath();

  useEffect(() => {
    if (user.status === Status.OK) {
      flagsmith.identify(user.data.id, {
        role: user.data.role,
        ...(organization ? { organization } : {}),
      });
    } else if (user.status === Status.UNAUTHENTICATED) {
      flagsmith.identify("unauthenticated", {
        ...(organization ? { organization } : {}),
      });
    }
  }, [organization, user]);

  const options: IInitConfig = useMemo(
    () => ({
      api: `${env.REACT_APP_FLAGSMITH_URL}api/v1/`,
      environmentID: env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY,
      cacheFlags: true,
    }),
    [env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY, env.REACT_APP_FLAGSMITH_URL]
  );

  return (
    <FlagsmithProvider options={options} flagsmith={flagsmith}>
      {children}
    </FlagsmithProvider>
  );
}

// useFlag with type safety from type generated by `npm run feature-flag:codegen` script
export function useFlags(requiredFlags: FeatureFlag[]) {
  return Flagsmith.useFlags<FeatureFlag>(requiredFlags);
}

export function useFlagsRemoteData(requiredFlags: FeatureFlag[]) {
  const flags = Flagsmith.useFlags<FeatureFlag>(requiredFlags);
  const loading = Flagsmith.useFlagsmithLoading();
  return RD.fromFlagsmith(loading, flags);
}
