import { AddIcon } from "@chakra-ui/icons";
import { Button, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { HasuraRole } from "src/types/hasuraRole";
import * as GQL from "src/types/graphql";
import { ManageTagGroupDialog } from "./ManageTagGroupDialog";

type AddTagGroupProps = {
  onNewTagGroupSaving?: (
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onCloseManageTagGroupDialog?: () => void;
  error?: string;
};
export const AddTagGroup: React.FC<AddTagGroupProps> = ({
  onNewTagGroupSaving,
  onCloseManageTagGroupDialog,
  error,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCancelClick = useCallback(() => {
    if (onCloseManageTagGroupDialog) onCloseManageTagGroupDialog();
    onClose();
  }, [onClose, onCloseManageTagGroupDialog]);
  return (
    <>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}>
        <WithUserPermissions permissions={["tag_group:create"]}>
          <Button
            variant="outline"
            size="xs"
            leftIcon={<AddIcon />}
            colorScheme="gray"
            onClick={onOpen}
          >
            New group
          </Button>
        </WithUserPermissions>
      </WithRequiredHasuraRoles>

      <ManageTagGroupDialog
        isOpen={isOpen}
        onNewTagGroupSaving={onNewTagGroupSaving}
        onCancelButtonClick={handleCancelClick}
        error={error}
        onClose={onClose}
      />
    </>
  );
};
