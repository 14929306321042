import { AddressValidationSchema } from "src/components/Inputs/Address/Book";
import { z } from "zod";

export const AccountLookupFormSchema = z.object({
  fullName: z.string().min(1, "Full name is required"),
  addresses: z
    .array(AddressValidationSchema)
    .min(1, "An address is required")
    .max(3, "Maximum of 3 address allowed"),
  captchaToken: z.string().min(1, "CAPTCHA result is required"),
});

export type FormikAccountLookup = z.infer<typeof AccountLookupFormSchema>;

export const EMPTY_ADDRESS = {
  street_address: "",
  street_address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
};

export const INITIAL_VALUES = {
  fullName: "",
  addresses: [EMPTY_ADDRESS],
  captchaToken: "",
};

const SuccessAccountLookupResponseSchema = z.object({
  type: z.literal("success").optional(),
  parentGuardianExists: z.boolean(),
  auth0UserType: z.union([z.literal("auth0"), z.literal("sms"), z.null()]),
  emailAddress: z.string().nullable(),
  phoneNumber: z.string().nullable(),
});

const DuplicateAccountLookupResponseSchema = z.object({
  type: z.literal("duplicate"),
});

export const AccountLookupResponseSchema = z.union([
  SuccessAccountLookupResponseSchema,
  DuplicateAccountLookupResponseSchema,
]);

export type SuccessAccountLookupResponse = z.infer<
  typeof SuccessAccountLookupResponseSchema
>;
export type AccountLookupResponse = z.infer<typeof AccountLookupResponseSchema>;
