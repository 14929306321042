import { Button, Flex, Text } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { EmptyState } from "src/components/EmptyState";
import { AuthLayout } from "src/components/Layout/AuthLayout";
import { ReactComponent as EmailSvg } from "src/images/email.svg";
import * as Url from "src/services/url";

export const Success = () => {
  const { organization } = useParams();
  const logout = Url.useLogout(organization ? `/${organization}` : undefined);
  return (
    <AuthLayout>
      <Flex direction="column" fontSize="md" alignItems="center" gap={8}>
        <EmptyState
          description="Your email has been verified, you can now log in"
          heading="Well done"
          Svg={EmailSvg}
        >
          <Button as={RouterLink} to={logout} variant="outline" width="100%">
            Log in
          </Button>
        </EmptyState>

        <Flex
          alignItems="center"
          direction="column"
          gap={4}
          marginBlockStart={16}
        >
          <Text>Not you?</Text>
          <Button variant="link" as={RouterLink} to={logout}>
            Log in with a different account
          </Button>
        </Flex>
      </Flex>
    </AuthLayout>
  );
};
