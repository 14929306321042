import { Flex, FlexProps, FormLabel, Grid, Text } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { InputControlWithBadge } from "src/components/Inputs/InputControlWithBadge";
import { PhoneInputWithBadge } from "src/components/Inputs/PhoneInputWithBadge";
import { getLanguageName } from "src/services/languages";

export declare type Props = FlexProps & {
  emailLinked?: boolean;
  phoneLinked?: boolean;
  isDisabled?: boolean;
};

export const ContactForm: React.FC<Props> = ({ ...props }) => {
  const [preferredLanguageField] = useField<string>("preferred_language");
  const preferredLanguageName = getLanguageName(preferredLanguageField.value);

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
      columnGap={4}
      rowGap={6}
    >
      <InputControlWithBadge
        badge={props.emailLinked ? "Linked" : ""}
        label="Email"
        name="email_address"
        id="email_address"
        isRequired={props.emailLinked}
        isDisabled={props.isDisabled}
      />

      <PhoneInputWithBadge
        badge={props.phoneLinked ? "Linked" : ""}
        label="Phone"
        name="phone_number"
        id="phone_number"
        isRequired={props.phoneLinked}
        isDisabled={props.isDisabled}
      />

      <Flex direction="column">
        <FormLabel as={Text}>Preferred language</FormLabel>
        {preferredLanguageName ? (
          <Text lineHeight="2.5rem">{preferredLanguageName}</Text>
        ) : (
          <Text lineHeight="2.5rem" variant="placeholder">
            No preferred language
          </Text>
        )}
      </Flex>
    </Grid>
  );
};
