import { SearchAndFilterTypes } from "src/constants";

export const handleFilterChange = (
  searchParams: URLSearchParams,
  filterType: SearchAndFilterTypes,
  newValue: string
) => {
  if (searchParams.get(filterType)?.includes(`_${newValue}`)) {
    const newFilters = searchParams
      .get(filterType)
      ?.replace(`_${newValue}`, "");
    searchParams.set(filterType, newFilters ?? "");
  } else if (searchParams.get(filterType)?.includes(`${newValue}_`)) {
    const newFilters = searchParams
      .get(filterType)
      ?.replace(`${newValue}_`, "");

    searchParams.set(filterType, newFilters ?? "");
  } else if (searchParams.get(filterType)?.includes(newValue)) {
    searchParams.delete(filterType);
  } else if (searchParams.get(filterType)) {
    const newFilters = searchParams
      .get(filterType)
      ?.replace(
        searchParams.get(filterType) ?? "",
        searchParams.get(filterType) + `_${newValue}`
      );
    searchParams.set(filterType, newFilters ?? "");
  } else {
    searchParams.append(filterType, newValue);
  }

  return searchParams;
};

export function removeSearchParams(
  searchParams: URLSearchParams,
  filterType: SearchAndFilterTypes,
  searchParamsToRemove: string[]
): URLSearchParams {
  const currentSearchParams = searchParams.get(filterType)?.split("_") ?? [];
  const newSearchParams = currentSearchParams.filter(
    (searchParam) => !searchParamsToRemove.includes(searchParam)
  );

  if (newSearchParams.length === 0) {
    searchParams.delete(filterType);
  } else {
    searchParams.set(filterType, newSearchParams.join("_"));
  }

  return searchParams;
}
