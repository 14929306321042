import { Box, Flex, FormLabel, Select, Switch } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  FormType,
  getPermissionLevelFormValue,
} from "src/components/Form/QuestionForm/formik";
import { CustomFormInput } from "src/components/Inputs/CustomFormInput";
import * as AF from "src/types/formTemplate";

const PermissionLevels = [
  { label: "Admin", value: "admin" },
  { label: "District Admin", value: "district-admin" },
  { label: "Org Admin", value: "org-admin" },
  { label: "School Admin", value: "school-admin" },
];
type Props = { questionId: uuid };
export const PermissionLevel: React.FC<Props> = ({ questionId }) => {
  const form = useFormikContext<FormType>();
  const permissionLevel = getPermissionLevelFormValue(questionId, form.values);

  const onChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        permissionLevels: {
          ...form.touched.permissionLevels,
          [questionId]: { level: true },
        },
      });
      form.setValues(
        (values) => ({
          ...values,
          permissionLevels: {
            ...values.permissionLevels,
            [questionId]: {
              ...permissionLevel,
              level: value,
            },
          },
        }),
        true
      );
    },
    [form, permissionLevel, questionId]
  );

  if (permissionLevel.type === "disabled") {
    return null;
  }

  const touched = form.touched.permissionLevels?.[questionId]?.level;
  const error = form.errors.permissionLevels?.[questionId]?.level;

  return (
    <Flex direction="column" gap="3">
      <CustomFormInput
        label="Permission level"
        error={touched && typeof error === "string" ? error : undefined}
      >
        <Select
          placeholder="Select a permission level"
          value={permissionLevel.level}
          onChange={onChangeHandler}
        >
          {PermissionLevels.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
      </CustomFormInput>
    </Flex>
  );
};

const EXCLUDED_QUESTION_TYPE_FOR_PERMISSION_LEVEL: AF.QuestionType[] = [
  AF.GradesType,
];
type PermissionLevelSwitchProps = {
  questionId: uuid;
  questionType?: AF.Question<AF.WithId>["type"] | "";
};
export const PermissionLevelSwitch: React.FC<PermissionLevelSwitchProps> = ({
  questionId,
  questionType,
}) => {
  const form = useFormikContext<FormType>();
  const permissionLevel = getPermissionLevelFormValue(questionId, form.values);

  React.useEffect(() => {
    if (permissionLevel.type === "disabled") {
      return;
    }

    if (
      questionType === "" ||
      questionType === undefined ||
      EXCLUDED_QUESTION_TYPE_FOR_PERMISSION_LEVEL.includes(questionType)
    ) {
      form.setValues((values) => {
        return {
          ...values,
          permissionLevel: {
            type: "disabled",
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  const onChangeHandler = React.useCallback(() => {
    const nextValue =
      permissionLevel.type === "disabled" ? "checked" : "disabled";

    form.setValues((values) => {
      if (nextValue === "disabled")
        return {
          ...values,
          permissionLevels: {
            ...values.permissionLevels,
            [questionId]: {
              type: nextValue,
              level: "",
            },
          },
        };
      return {
        ...values,
        permissionLevels: {
          ...values.permissionLevels,
          [questionId]: {
            type: nextValue,
            level: "admin",
          },
        },
      };
    }, false);
  }, [form, permissionLevel.type, questionId]);

  if (
    questionType === undefined ||
    questionType === "" ||
    EXCLUDED_QUESTION_TYPE_FOR_PERMISSION_LEVEL.includes(questionType)
  ) {
    return null;
  }

  return (
    <FormLabel
      htmlFor={`permissionLevelSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`permissionLevelSwitch${questionId}`}
        isChecked={permissionLevel.type !== "disabled"}
        onChange={onChangeHandler}
      />
      <Box>Internal-Only question</Box>
    </FormLabel>
  );
};
