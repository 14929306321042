import type { BoxProps } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { HorizontallyBoundedContainer } from "./HorizontallyBoundedContainer";

type ParentFormsLayoutInnerProps = BoxProps & {
  children: ReactNode;
  footers?: ReactNode;
  headers?: ReactNode;
};

export function ParentFormsLayoutInner(props: ParentFormsLayoutInnerProps) {
  const { children, footers, headers, ...containerProps } = props;

  return (
    <>
      {headers}

      <HorizontallyBoundedContainer
        display="flex"
        flexDir="column"
        flexGrow={1}
        padding={4}
        gap={4}
        {...containerProps}
      >
        {children}
      </HorizontallyBoundedContainer>

      {footers}
    </>
  );
}
