import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { getReferenceId } from "src/services/id";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { DeleteSchoolButton } from "./DeleteButton";

interface SchoolsListProps {
  schools: GQL.SchoolFragment[];
  limit: number;
  offset: number;
  count: number;
  onFetchMore: (limit: number, offset: number) => void;
  onChange: () => void;
  onSort: (columnName: string, sortType: SortType) => void;
}

export const SchoolsList = ({
  schools,
  onChange,
  limit,
  offset,
  count,
  onFetchMore,
  onSort,
}: SchoolsListProps) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();

  const handleDelete = useCallback(() => {
    onChange();
  }, [onChange]);

  const columns: ColumnDef<GQL.SchoolFragment>[] = useMemo(
    () => [
      {
        id: "id",
        header: "Id",
        accessorFn: (row) => getReferenceId(row),
      },
      { accessorKey: "name", header: "Name" },
      { accessorKey: "status", header: "Status" },
      ...(userPermissions.hasSome(["school:update", "school:delete"])
        ? [
            {
              id: "Actions",
              cell: (props: any) => {
                const id: string = props.row.original.id;
                const name: string = props.row.original.name;
                return (
                  <>
                    <WithUserPermissions permissions={["school:update"]}>
                      <IconButton
                        as={NavLink}
                        to={organization
                          .map((org) => Url.OrgAdmin.Schools.edit(org, id))
                          .withDefault("#")}
                        aria-label={`Edit ${name}`}
                        icon={<Icon as={RiPencilLine} />}
                        variant="outline"
                      />
                    </WithUserPermissions>
                    <WithUserPermissions permissions={["school:delete"]}>
                      <DeleteSchoolButton
                        id={id}
                        name={name}
                        onDelete={handleDelete}
                      />
                    </WithUserPermissions>
                  </>
                );
              },
            },
          ]
        : []),
    ],
    [userPermissions, organization, handleDelete]
  );

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );
  if (!schools?.length)
    return (
      <span>
        <Glossary>You have not uploaded any schools</Glossary>
      </span>
    );

  return (
    <PaginatedTable<GQL.SchoolFragment>
      data={schools}
      columns={columns}
      limit={limit}
      offset={offset}
      count={count}
      onFetchMore={onFetchMore}
      onChangeSort={handleChangeSort}
    />
  );
};
