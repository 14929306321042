import {
  geojson_insert_input,
  school_boundary_insert_input,
} from "src/types/graphql";
import {
  ConnectionsJsonSchema,
  TConnectionsAssociation,
  UploadBoundaryConfigForm,
} from "./schemas";

export async function parseBoundaryConfigFiles(
  props: UploadBoundaryConfigForm
): Promise<geojson_insert_input> {
  const { geojson, connections } = props;

  // text() default uses UTF-8 encoding
  const geojsonAsJson = JSON.parse(await geojson.text());
  const { organizationId, geoJSONPropertyKey, associations, tags } =
    ConnectionsJsonSchema.parse(JSON.parse(await connections.text()));

  const schoolBoundaries = constructSchoolBoundaryPayload(associations);
  const boundaryTags = tags.map((tag) => {
    return {
      name: tag.name,
      organization_id: organizationId,
      is_inside: true,
    };
  });

  return {
    geojson: geojsonAsJson,
    geojson_property_key: geoJSONPropertyKey,
    is_active: true,
    organization_id: organizationId,
    school_boundaries: {
      data: schoolBoundaries,
    },
    boundary_tags: {
      data: boundaryTags,
    },
  };
}

function constructSchoolBoundaryPayload(associations: TConnectionsAssociation) {
  return associations.reduce<school_boundary_insert_input[]>(
    (accumulator, currentValue) => {
      const { enrollmentPeriodId, geoJSONPropertyValue, eligibility, schools } =
        currentValue;

      const baseSchoolBoundary = {
        enrollment_period_id: enrollmentPeriodId,
        geojson_property_value: geoJSONPropertyValue,
        geo_eligibilities: {
          data: eligibility ? [{ is_eligible_inside: true }] : [],
        },
      };

      schools.forEach((school) => {
        const { id: schoolId, grades } = school;

        if (grades.length === 0) {
          // null grade_id means it's associated for all grades
          accumulator.push({
            ...baseSchoolBoundary,
            school_id: schoolId,
            grade_id: null,
          });
        } else {
          grades.forEach((grade) => {
            accumulator.push({
              ...baseSchoolBoundary,
              school_id: schoolId,
              grade_id: grade.id,
            });
          });
        }
      });

      return accumulator;
    },
    []
  );
}
