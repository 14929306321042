import * as RD from "src/types/remoteData";
import * as GQL from "src/types/graphql";
import * as AF from "src/types/formTemplate";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { gql } from "@apollo/client";
import { FORM_TEMPLATE_SECTION_FRAGMENT } from "src/operations/fragments/formTemplate";
import * as PreRankingSection from "./preRankingSection";
import * as SchoolRankingSection from "./schoolsRankingSection";
import { identity } from "lodash";

export function usePreRankingSectionRemoteData(
  formTemplateId: uuid
): RD.RemoteData<Error, AF.PreRankingSection<AF.WithId>> {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetPreRankingSectionByFormTemplateId,
    GQL.GetPreRankingSectionByFormTemplateIdVariables
  >(
    gql`
      ${FORM_TEMPLATE_SECTION_FRAGMENT}

      query GetPreRankingSectionByFormTemplateId($formTemplateId: uuid!) {
        form_template_section(
          where: {
            form_template_id: { _eq: $formTemplateId }
            type: { _eq: PreRankingSection }
          }
        ) {
          ...FormTemplateSectionFragment
        }
      }
    `,
    { variables: { formTemplateId } }
  );

  return remoteData.mapError<Error>(identity).andThen((data) => {
    const section = data.form_template_section[0];
    if (!section) {
      return RD.failure(new Error("Unable to get PreRankingSection"));
    }
    return RD.success(PreRankingSection.fromGQL(section));
  });
}

export function useSchoolRankingSectionRemoteData(
  formTemplateId: uuid
): RD.RemoteData<Error, AF.SchoolRankingSection<AF.WithId>> {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetSchoolRankingSectionByFormTemplateId,
    GQL.GetSchoolRankingSectionByFormTemplateIdVariables
  >(
    gql`
      ${FORM_TEMPLATE_SECTION_FRAGMENT}

      query GetSchoolRankingSectionByFormTemplateId($formTemplateId: uuid!) {
        form_template_section(
          where: {
            form_template_id: { _eq: $formTemplateId }
            type: { _eq: SchoolRankingSection }
          }
        ) {
          ...FormTemplateSectionFragment
        }
      }
    `,
    { variables: { formTemplateId } }
  );

  return remoteData.mapError<Error>(identity).andThen((data) => {
    const section = data.form_template_section[0];
    if (!section) {
      return RD.failure(new Error("Unable to get SchoolRankingSection"));
    }
    return RD.success(SchoolRankingSection.fromGQL(section));
  });
}
