import { gql } from "@apollo/client";
import { PERSON_FRAGMENT } from "src/operations/fragments/person";

export const GET_STUDENTS_BY_GUARDIAN = gql`
  ${PERSON_FRAGMENT}
  query GetStudentsByGuardianId(
    $organization_id: uuid!
    $guardian_id: uuid!
    $include_form_count: Boolean = false
  ) {
    person(
      where: {
        organization_id: { _eq: $organization_id }
        person_type: { _eq: applicant }
        active: { _eq: true }
        _or: [
          {
            second_relationship: {
              _and: {
                active: { _eq: true }
                first_person: { _eq: $guardian_id }
              }
            }
          }
          {
            first_relationship: {
              _and: {
                active: { _eq: true }
                second_person: { _eq: $guardian_id }
              }
            }
          }
        ]
      }
    ) {
      id
      ...PersonFragment
      forms(
        where: {
          _and: [
            { status: { _neq: Deleted } }
            {
              _or: [
                { is_hidden_from_parent: { _eq: false } }
                { is_hidden_from_parent: { _is_null: true } }
              ]
            }
          ]
        }
      ) {
        form_template_id
      }
      assigned_forms(where: { has_started: { _eq: false } }) {
        form_template {
          name
          description
          id
          open_at
        }
      }
      forms_aggregate(
        where: {
          _and: [
            { status: { _neq: Deleted } }
            {
              _or: [
                { is_hidden_from_parent: { _eq: false } }
                { is_hidden_from_parent: { _is_null: true } }
              ]
            }
          ]
        }
      ) @include(if: $include_form_count) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_AVATARS = gql`
  query GetAvatars($organization_id: uuid!, $guardian_id: uuid!) {
    person(where: { active: { _eq: true }, id: { _eq: $guardian_id } }) {
      id
      first_name
      last_name
      first_relationship(
        where: {
          active: { _eq: true }
          second: {
            organization_id: { _eq: $organization_id }
            person_type: { _eq: applicant }
            avatar: { _is_null: false }
          }
        }
      ) {
        second {
          id
          avatar
        }
      }
      second_relationship(
        where: {
          active: { _eq: true }
          first: {
            organization_id: { _eq: $organization_id }
            person_type: { _eq: applicant }
            avatar: { _is_null: false }
          }
        }
      ) {
        first {
          id
          avatar
        }
      }
    }
  }
`;

export const GET_STUDENT_BY_ID_PARENT_PORTAL = gql`
  ${PERSON_FRAGMENT}
  query GetStudentByIdParentPortal($id: uuid!) {
    person_by_pk(id: $id) {
      ...PersonFragment
      forms_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
