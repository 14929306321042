import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { RiSearchEyeLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { StudentAvatar } from "src/components/Layout/Avatar";
import { Card } from "src/components/Layout/Card";
import { Glossary } from "src/components/Text/Glossary";
import { FORM_STATUS } from "src/constants";
import { useOrganization } from "src/hooks/useOrganization";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as Format from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";

type Props = {
  form: GQL.FormSummaryFragment;
  enrollmentPeriod: GQL.GetInactiveForms_form_form_template_enrollment_period;
};
export const View: React.FC<Props> = ({ form, enrollmentPeriod }) => {
  const organization = useOrganization();
  const name = Format.fullName(form.person);

  return (
    <Box>
      <Card
        display="flex"
        gap={4}
        flexDirection="column"
        accentColor={
          FORM_STATUS[form.status].accentColor ??
          FORM_STATUS[form.status].colorScheme
        }
        position="relative" // Required to make the card go on top of the alert
      >
        <Box display="flex" alignItems="flex-start">
          <StudentAvatar student={form.person} size="sm" />
          <Text className={WEGLOT_SKIP_CLASS} marginTop={1} marginLeft={3}>
            {name}
          </Text>
        </Box>
        <Text fontSize="sm" fontWeight={600}>
          {enrollmentPeriod.name}
        </Text>

        {form.form_school_ranks.map((formSchoolRank) => (
          <Text key={formSchoolRank.school.name} fontSize="sm">
            {formSchoolRank.school.name}
          </Text>
        ))}

        <StatusTag status={FORM_STATUS[form.status]} variant="long" />
        <Flex direction="column" gap={2}>
          <Button
            as={RouterLink}
            colorScheme="gray"
            leftIcon={<RiSearchEyeLine />}
            size="sm"
            to={organization
              .map((org) => Url.Parent.Form.view(org, form.id))
              .withDefault("#")}
            variant="outline"
            width="100%"
          >
            <Glossary>View form</Glossary>
          </Button>
        </Flex>
      </Card>
    </Box>
  );
};
