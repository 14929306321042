import * as qs from "query-string";
import { ORGANIZATION } from "./constants";

type QueryParams = Record<string, any>;

type Organization = { path: string };

function template(
  entity: string,
  organization?: Organization,
  action?: string,
  queryParams: QueryParams = {}
): string {
  let query = qs.stringify(queryParams);
  query = query ? `?${query}` : "";

  return `/${organization?.path ?? ORGANIZATION}/${entity}${
    action ?? ""
  }${query}`;
}

export const ImportantDate = {
  index: (organization: Organization): string => {
    return template("important-dates", organization);
  },

  indexPath: template("important-dates"),
};

export const Student = {
  index: (organization: Organization): string => {
    return template("students", organization);
  },

  new: (organization: Organization, queryParams?: QueryParams): string => {
    return template("students", organization, "/new", queryParams);
  },

  edit: (organization: Organization, id: string) => {
    return template("students", organization, `/${id}/edit`);
  },

  indexPath: template("students"),
  newPath: template("students", undefined, "/new"),
  editPath: template("students", undefined, "/:id/edit"),
};

export const Profile = {
  edit: (organization: Organization): string => {
    return template("profile", organization, "/edit");
  },
  new: (organization: Organization, step: 1 | 2 = 1): string => {
    return template("profile", organization, `/new/step/${step}`);
  },
  editPath: template("profile", undefined, "/edit"),
  newPath: template("profile", undefined, "/new/step/:step"),
};

export type FormPathParams = {
  formId: uuid;
  step: string; // this must be parsed into a number
};

export const Form = {
  edit: (organization: Organization, formId: string, step = 1): string => {
    return template("forms", organization, `/${formId}/step/${step}`);
  },

  new: (organization: Organization, queryParams?: QueryParams): string => {
    return template("forms", organization, "/new", queryParams);
  },

  view: (organization: Organization, id: string): string => {
    return template("forms", organization, `/${id}/view`);
  },

  lateEdit: (organization: Organization, id: string): string => {
    return template("forms", organization, `/${id}/late-edit`);
  },

  newPath: template("forms", undefined, "/new"),
  editPath: template("forms", undefined, "/:formId/step/:step"),
  viewPath: template("forms", undefined, "/:id/view"),
  lateEditPath: template("forms", undefined, "/:id/late-edit"),
};

export type FormCustomQuestionPathParams = FormPathParams & {
  customQuestionId: uuid;
};

export const FormCustomQuestion = {
  edit: (
    organization: Organization,
    formId: uuid,
    step: number,
    customQuestionId: uuid
  ): string => {
    return `${Form.edit(organization, formId, step)}/${customQuestionId}`;
  },
  editPath: `${Form.editPath}/:customQuestionId`,
};

export const History = {
  index: (organization: Organization): string => {
    return template("history", organization);
  },

  indexPath: template("history"),
};

export const indexPath = template("");
export const index = (org: Organization): string => {
  return template("", org);
};
export const helpPath = template("help");
export const help = (org: Organization): string => {
  return template("help", org);
};

export const offersPath = template("offers", undefined, "/:appId");
export const offers = (org: Organization, appId: string): string => {
  return template("offers", org, `/${appId}`);
};

export const AccountLookup = {
  path: template("account-lookup", undefined),
  index: (org: Organization): string => {
    return template("account-lookup", org);
  },
};
