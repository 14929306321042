import { useApolloClient } from "@apollo/client";
import * as DateFns from "date-fns";
import _ from "lodash";
import React, { useMemo } from "react";
import { Selected, Tag, TagsPopoverProps } from "src/components/Tags";
import { Mode } from "src/components/Tags/types";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as List from "src/services/list";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RemoteData from "src/types/remoteData";
import { FormId } from "../forms/types";
import { ADD_FORM_TAG, REMOVE_FORM_TAG } from "./graphql/mutations";
import {
  GET_FORM_TAGS,
  GET_INTERNAL_DEFAULT_TAG_GROUP,
  GET_TAGS_BY_ENROLLMENT_PERIOD,
} from "./graphql/queries";
import { useOptimisticUpdate } from "./useOptimisticUpdate";
import { useDeleteTag, useEditTag, useNewTag } from "./useTags";

const BATCH_SIZE = 1000;

type Props = {
  enrollmentPeriodId: uuid;
};

type ReturnType = {
  tagsProps: TagsPopoverProps;
  setSelectedRows: (
    selectedRows: RemoteData.RemoteData<unknown, FormId[]>
  ) => void;
  isDirty: boolean;
  resetFormTags: () => void;
};

export function useFormTags({ enrollmentPeriodId }: Props): ReturnType {
  const [isDirty, setIsDirty] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<
    RemoteData.RemoteData<unknown, FormId[]>
  >(RemoteData.loading());

  const { remoteData: internalDefaultTagGroup } = useRemoteDataQuery<
    GQL.GetInternalDefaultTagGroup,
    GQL.GetInternalDefaultTagGroupVariables
  >(GET_INTERNAL_DEFAULT_TAG_GROUP, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
    },
    skip: !enrollmentPeriodId,
  });

  const internalTagGroupId = useMemo(() => {
    if (!internalDefaultTagGroup.hasData()) return null;
    return internalDefaultTagGroup.data.tag_group[0]?.id ?? null;
  }, [internalDefaultTagGroup]);

  const { remoteData: enrollmentPeriodTags } = useRemoteDataQuery<
    GQL.GetTagsByEnrollmentPeriod,
    GQL.GetTagsByEnrollmentPeriodVariables
  >(GET_TAGS_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      tag_group_id: internalTagGroupId ?? "",
    },
    skip: !enrollmentPeriodId || !internalTagGroupId,
  });

  const [queryFormTags, formTags] = useLazyRemoteDataQuery<
    GQL.GetFormTags,
    GQL.GetFormTagsVariables
  >(GET_FORM_TAGS, { fetchPolicy: "cache-and-network" });

  const [addFormTag] = useRemoteDataMutation<
    GQL.AddFormTag,
    GQL.AddFormTagVariables
  >(ADD_FORM_TAG);

  const [removeFormTag] = useRemoteDataMutation<
    GQL.RemoveFormTag,
    GQL.RemoveFormTagVariables
  >(REMOVE_FORM_TAG);

  const [searchKeyword, setSearchKeyword] = React.useState("");
  const onSearch = async (keyword: string) => {
    setSearchKeyword(keyword);
  };
  const filterByKeyword = React.useCallback(
    (tagName: string): boolean => {
      if (searchKeyword.trim() === "") return true;
      return tagName.toLowerCase().includes(searchKeyword.toLowerCase());
    },
    [searchKeyword]
  );

  const {
    optimisticUpdateMap,
    applyTagOptimistically,
    removeTagOptimistically,
    resetOptimisticUpdate,
  } = useOptimisticUpdate();

  const tags: RemoteData.RemoteData<unknown, Tag[]> = React.useMemo(() => {
    return RemoteData.toTuple3(
      enrollmentPeriodTags,
      formTags.remoteData,
      selectedRows
    ).map(([tags, allTags, rows]) => {
      const tagsCounter = countTags(tags, allTags);
      return List.filterMap(tags.enrollment_period_tag, (tag) => {
        if (!filterByKeyword(tag.name)) {
          return null;
        }

        let selected: Selected;
        let isUpdating = false;
        const optimisticSelected = optimisticUpdateMap.get(tag.id);
        if (optimisticSelected) {
          isUpdating = true;
          selected = optimisticSelected;
        } else {
          const totalFormSchool = rows.length;
          const selectedCount = tagsCounter.get(tag.id);
          selected =
            selectedCount === 0
              ? "None"
              : selectedCount === totalFormSchool
              ? "All"
              : "Partial";
        }

        return {
          id: tag.id,
          name: tag.name,
          description: tag.description,
          lastUsedAt: tag.last_used_at
            ? DateFns.parseISO(tag.last_used_at)
            : null,
          selected,
          isUpdating,
        };
      });
    });
  }, [
    enrollmentPeriodTags,
    formTags.remoteData,
    selectedRows,
    filterByKeyword,
    optimisticUpdateMap,
  ]);

  const toast = useAvelaToast();
  const client = useApolloClient();

  const applyTag = React.useCallback(
    async (tagId: string) => {
      try {
        if (!selectedRows.hasData())
          throw new Error("Unable to apply tags since no rows are selected");
        // do this in batch for better performance
        // benchmark data:
        // without batch: 5000 rows => 17.36s
        // batch (1000): 5000 rows => 3.78s
        const chunk = _.chunk(selectedRows.data, BATCH_SIZE);
        await Promise.all(
          chunk.map(async (rows) => {
            const form_tags = rows.flatMap((row) => [
              { tag_id: tagId, form_id: row.formId },
            ]);

            await addFormTag({ variables: { form_tags } });
          })
        );

        setIsDirty(true);
        await client.refetchQueries({
          include: [GET_FORM_TAGS, GET_TAGS_BY_ENROLLMENT_PERIOD],
        });
      } catch (error) {
        console.error(error);
        toast.error({ title: "Unable to apply tags" });
      }
    },
    [addFormTag, client, selectedRows, toast]
  );

  const removeTag = React.useCallback(
    async (tagId: string) => {
      try {
        if (!selectedRows.hasData())
          throw new Error("Unable to remove tags since no rows are selected");

        // need to delete in batches since we'll hit the limit of the size of the condition
        // and postgres will run out of memory.
        const chunk = _.chunk(selectedRows.data, BATCH_SIZE);
        await Promise.all(
          chunk.map(async (rows) => {
            await removeFormTag({
              variables: {
                condition: {
                  _or: rows.flatMap((row) => [
                    {
                      _and: [
                        { tag_id: { _eq: tagId } },
                        { form_id: { _eq: row.formId } },
                      ],
                    },
                  ]),
                },
              },
            });
          })
        );
        setIsDirty(true);
        await client.refetchQueries({
          include: [GET_FORM_TAGS],
        });
      } catch (error) {
        console.error(error);
        toast.error({
          title: "Unable to remove tags",
        });
      }
    },
    [selectedRows, client, removeFormTag, toast]
  );

  const onTagUpdate = React.useCallback(
    async (tagId: string, selected: Selected) => {
      if (selected === "All") {
        applyTagOptimistically(tagId);
        await applyTag(tagId);
        resetOptimisticUpdate(tagId);
      } else if (selected === "None") {
        removeTagOptimistically(tagId);
        await removeTag(tagId);
        resetOptimisticUpdate(tagId);
      }
    },
    [
      applyTagOptimistically,
      applyTag,
      resetOptimisticUpdate,
      removeTagOptimistically,
      removeTag,
    ]
  );

  const setSelectedRowsWrapper = React.useCallback(
    async (selectedRows: RemoteData.RemoteData<unknown, FormId[]>) => {
      setIsDirty(false);
      setSelectedRows(selectedRows);
      if (selectedRows.hasData()) {
        await queryFormTags({
          variables: {
            form_ids: selectedRows.data.flatMap((row) => [row.formId]),
          },
        });
      }
    },
    [queryFormTags]
  );

  const isOrgAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);

  const { setMode, ...tagForm } = useTagForm({
    setIsDirty,
    enrollmentPeriodId,
    setSearchKeyword,
    internalTagGroupId,
  });

  const resetFormTags = React.useCallback(() => {
    setIsDirty(false);
    setSearchKeyword("");
  }, [setSearchKeyword]);

  return {
    tagsProps: {
      searchKeyword,
      tags,
      onTagUpdate,
      onSearch,
      ...tagForm,
      config: {
        title: "Form tags",
        allowNewTag: isOrgAdmin,
        allowDeleteTag: isOrgAdmin,
        allowEditTag: isOrgAdmin,
      },
    },
    setSelectedRows: setSelectedRowsWrapper,
    isDirty,
    resetFormTags,
  };
}

type TagFormProps = {
  enrollmentPeriodId: uuid;
  setSearchKeyword: (keyword: string) => void;
  setIsDirty: (isDirty: boolean) => void;
  internalTagGroupId: uuid | null;
};

function useTagForm({
  enrollmentPeriodId,
  setSearchKeyword,
  setIsDirty,
  internalTagGroupId,
}: TagFormProps) {
  const [error, setError] = React.useState<string | undefined>();
  const initialMode: Mode = {
    type: "List",
  };

  const [mode, setMode] = React.useReducer(
    (_previousMode: Mode, action: Mode) => {
      switch (action.type) {
        case "List":
        case "Edit":
        case "New":
        case "SelectGroup":
          return action;
      }
    },
    initialMode
  );

  const clearError = React.useCallback(() => {
    setError(undefined);
  }, []);

  const newTag = useNewTag({
    setMode,
    enrollmentPeriodId,
    setSearchKeyword,
    setError,
    selectedTagGroupId: internalTagGroupId,
  });

  const editTag = useEditTag({
    setMode,
    setSearchKeyword,
    setError,
  });

  const deleteTag = useDeleteTag({ setIsDirty });

  const handleCloseManageTagGroupDialog = React.useCallback(() => {
    clearError();
  }, [clearError]);

  return {
    mode,
    setMode,
    ...newTag,
    ...editTag,
    ...deleteTag,
    error,
    clearError,
    onCloseManageTagGroupDialog: handleCloseManageTagGroupDialog,
  };
}

function countTags(
  enrollmentPeriodTags: GQL.GetTagsByEnrollmentPeriod,
  allTags: GQL.GetFormTags
): Map<uuid, number> {
  // use imperative style for performance
  const counter: Map<uuid, number> = new Map(
    enrollmentPeriodTags.enrollment_period_tag.map((tag) => {
      return [tag.id, 0];
    })
  );

  // count form_tag
  for (const currentValue of allTags.form_tag) {
    if (currentValue.tag_id === null) continue;

    counter.set(
      currentValue.tag_id,
      (counter.get(currentValue.tag_id) ?? 0) + 1
    );
  }
  return counter;
}
