import React from "react";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import {
  GET_CUSTOM_QUESTION_ANSWERS,
  GET_FORM_ANSWERS_BY_ID,
  GET_LAST_DELETED_FORM,
  GET_SCHOOLS_RANK,
  GET_SOURCE_AND_TARGET_FORM_TEMPLATE_IDS,
} from "../graphql/queries";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { CLONE_FORM } from "../graphql/mutations";

export function useCloneForm() {
  const [getLastDeletedForm] = useLazyRemoteDataQuery<
    GQL.GetLastDeletedForm,
    GQL.GetLastDeletedFormVariables
  >(GET_LAST_DELETED_FORM, { fetchPolicy: "network-only" });

  const [getFormAnswersById] = useLazyRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, { fetchPolicy: "network-only" });

  const [getCustomQuestionAnswers] = useLazyRemoteDataQuery<
    GQL.GetCustomQuestionAnswers,
    GQL.GetCustomQuestionAnswersVariables
  >(GET_CUSTOM_QUESTION_ANSWERS, {
    fetchPolicy: "network-only",
  });

  const [getSchoolRanks] = useLazyRemoteDataQuery<
    GQL.GetSchoolsRank,
    GQL.GetSchoolsRankVariables
  >(GET_SCHOOLS_RANK, { fetchPolicy: "network-only" });

  const [getSourceAndTargetFormTemplateIds] = useLazyRemoteDataQuery<
    GQL.GetSourceAndTargetFormTemplateIds,
    GQL.GetSourceAndTargetFormTemplateIdsVariables
  >(GET_SOURCE_AND_TARGET_FORM_TEMPLATE_IDS, { fetchPolicy: "network-only" });

  const [cloneFormMutation] = useRemoteDataMutation<
    GQL.CloneForm,
    GQL.CloneFormVariables
  >(CLONE_FORM);

  const cloneForm = React.useCallback(
    async (props: { sourceFormId: uuid; targetFormId: uuid }) => {
      // check to make sure source form and target form has the same formTemplateId
      const result = await getSourceAndTargetFormTemplateIds({
        variables: {
          source_form_id: props.sourceFormId,
          target_form_id: props.targetFormId,
        },
      });

      if (
        !result.data ||
        !result.data.source_form ||
        !result.data.target_form ||
        result.data.source_form.form_template_id !==
          result.data.target_form.form_template_id
      ) {
        throw new Error(
          "Unable to clone forms with different form_template_id"
        );
      }

      // get source form data answers
      const [
        formAnswersResult,
        schoolRanksResult,
        customQuestionAnswersResult,
      ] = await Promise.all([
        getFormAnswersById({ variables: { form_id: props.sourceFormId } }),
        getSchoolRanks({ variables: { form_id: props.sourceFormId } }),
        getCustomQuestionAnswers({
          variables: { form_id: props.sourceFormId },
        }),
      ]);

      if (!formAnswersResult.data) {
        throw new Error("Unable to query form answers");
      }

      if (!schoolRanksResult.data) {
        throw new Error("Unable to query school ranks");
      }

      const questionsInCustomQuestions = getQuestionsInCustomQuestions(
        customQuestionAnswersResult.data
      );

      // transform source form data into mutation for target form
      const mutations = generateMutations(
        { targetFormId: props.targetFormId },
        formAnswersResult.data,
        schoolRanksResult.data,
        customQuestionAnswersResult.data
          ?.custom_question_answer_bank_relationship ?? [],
        questionsInCustomQuestions
      );

      // update target form
      await cloneFormMutation({ variables: mutations });
    },
    [
      cloneFormMutation,
      getCustomQuestionAnswers,
      getFormAnswersById,
      getSchoolRanks,
      getSourceAndTargetFormTemplateIds,
    ]
  );

  const tryCloneDeletedForm = React.useCallback(
    async (props: {
      studentId: uuid;
      formTemplateId: uuid;
      targetFormId: uuid;
    }) => {
      try {
        const result = await getLastDeletedForm({
          variables: {
            form_template_id: props.formTemplateId,
            student_id: props.studentId,
          },
        });

        const deletedForm = result.data?.form[0];
        if (deletedForm) {
          await cloneForm({
            sourceFormId: deletedForm.id,
            targetFormId: props.targetFormId,
          });
        }
      } catch (error) {
        // Error in cloning shouldn't prevent user from progressing, so we just log the error on move on.
        console.error(error);
      }
    },
    [cloneForm, getLastDeletedForm]
  );

  return {
    tryCloneDeletedForm,
  };
}

export function generateMutations(
  { targetFormId }: { targetFormId: uuid },
  formAnswers: GQL.GetFormAnswersById,
  schoolRanks: GQL.GetSchoolsRank,
  customQuestionAnswerBankRelationships: GQL.GetCustomQuestionAnswers_custom_question_answer_bank_relationship[],
  questionsInCustomQuestions: uuid[]
): GQL.CloneFormVariables {
  const insert_form_address: GQL.form_address_insert_input[] =
    generateFormAddressMutations({ targetFormId }, formAnswers);

  const insert_form_answer: GQL.form_answer_insert_input[] =
    generateFormAnswersMutations(
      { targetFormId },
      formAnswers,
      questionsInCustomQuestions
    );

  const insert_grades_answer: GQL.grades_answer_insert_input[] =
    generateGradesAnswerMutations({ targetFormId }, formAnswers);

  const insert_form_school_rank: GQL.form_school_rank_insert_input[] =
    generateFormSchoolRankMutations({ targetFormId }, schoolRanks);

  const {
    insert_custom_question_answer,
    insert_custom_question_answer_bank_relationship,
  } = generateCustomQuestionAnswerMutations(
    { targetFormId },
    formAnswers,
    customQuestionAnswerBankRelationships
  );

  return {
    insert_form_answer,
    insert_form_address,
    insert_grades_answer,
    insert_form_school_rank,
    insert_custom_question_answer,
    insert_custom_question_answer_bank_relationship,
  };
}

type TargetFormId = { targetFormId: uuid };

function generateCustomQuestionAnswerMutations(
  { targetFormId }: TargetFormId,
  formAnswers: GQL.GetFormAnswersById,
  customQuestionAnswerBankRelationships: GQL.GetCustomQuestionAnswers_custom_question_answer_bank_relationship[]
): {
  insert_custom_question_answer: GQL.custom_question_answer_insert_input[];
  insert_custom_question_answer_bank_relationship: GQL.custom_question_answer_bank_relationship_insert_input[];
} {
  const insert_custom_question_answer: GQL.custom_question_answer_insert_input[] =
    [];

  for (const customAnswer of formAnswers.custom_question_answer) {
    const formAnswer = generateFormAnswerMutation(
      { targetFormId },
      customAnswer.form_answer,
      []
    )[0];

    if (!formAnswer) {
      continue;
    }

    insert_custom_question_answer.push({
      form_id: targetFormId,
      question_id: customAnswer.question_id,
      form_answer: {
        data: formAnswer,
      },
    });
  }

  const insert_custom_question_answer_bank_relationship =
    customQuestionAnswerBankRelationships.map(
      (row): GQL.custom_question_answer_bank_relationship_insert_input => {
        return {
          form_id: targetFormId,
          custom_question_id: row.custom_question_id,
          person_answer_bank_id: row.person_answer_bank_id,
        };
      }
    );

  return {
    insert_custom_question_answer,
    insert_custom_question_answer_bank_relationship,
  };
}

function generateFormSchoolRankMutations(
  { targetFormId }: TargetFormId,
  schoolRanks: GQL.GetSchoolsRank
): GQL.form_school_rank_insert_input[] {
  return schoolRanks.form_school_rank.map((sr) => {
    return {
      form_id: targetFormId,
      school_id: sr.school.id,
      rank: sr.rank,
      schools_ranking_section_id: sr.schools_ranking_section_id,
    };
  });
}

function generateGradesAnswerMutations(
  { targetFormId }: TargetFormId,
  formAnswers: GQL.GetFormAnswersById
): GQL.grades_answer_insert_input[] {
  return formAnswers.grades_answer.map((ga) => {
    return {
      form_id: targetFormId,
      question_id: ga.question_id,
      grade_config_id: ga.grade_config?.id,
    };
  });
}

function generateFormAddressMutations(
  { targetFormId }: TargetFormId,
  formAnswers: GQL.GetFormAnswersById
): GQL.form_address_insert_input[] {
  return formAnswers.form_address.map(
    (formAddress): GQL.form_address_insert_input => {
      return {
        form_id: targetFormId,
        question_id: formAddress.question_id,
        street_address: formAddress.street_address,
        street_address_line_2: formAddress.street_address_line_2,
        city: formAddress.city,
        state: formAddress.state,
        zip_code: formAddress.zip_code,
      };
    }
  );
}

function generateFormAnswersMutations(
  { targetFormId }: TargetFormId,
  formAnswers: GQL.GetFormAnswersById,
  questionsInCustomQuestions: uuid[]
): GQL.form_answer_insert_input[] {
  return formAnswers.form_answer.flatMap(
    (formAnswer): GQL.form_answer_insert_input[] => {
      return generateFormAnswerMutation(
        { targetFormId },
        formAnswer,
        questionsInCustomQuestions
      );
    }
  );
}

function getQuestionsInCustomQuestions(
  customQuestionAnswers: GQL.GetCustomQuestionAnswers | undefined
): uuid[] {
  return (
    customQuestionAnswers?.custom_question_answer.map((cqa) => {
      return cqa.form_answer.question_id;
    }) ?? []
  );
}

function generateFormAnswerMutation(
  { targetFormId }: TargetFormId,
  formAnswer: GQL.GetFormAnswersById_form_answer,
  questionsInCustomQuestions: uuid[]
) {
  if (
    formAnswer.form_question.question.type === GQL.question_type_enum.FileUpload
  ) {
    return [];
  }

  if (
    formAnswer.form_question.question.type ===
    GQL.question_type_enum.CustomQuestion
  ) {
    // Custom question will be handled by different mutation generator
    return [];
  }

  if (questionsInCustomQuestions.includes(formAnswer.question_id)) {
    // skip answers for question part of CQT
    return [];
  }

  return [
    {
      form_id: targetFormId,
      question_id: formAnswer.question_id,
      free_text_answer: formAnswer.free_text_answer,
      form_answer_options:
        formAnswer.form_answer_options.length > 0
          ? {
              data: formAnswer.form_answer_options.map((option) => {
                return {
                  form_question_option_id: option.form_question_option_id,
                };
              }),
            }
          : undefined,
    },
  ];
}
