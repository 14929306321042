import { Menu, useDisclosure } from "@chakra-ui/react";
import { UndoButton } from "./UndoButton";
import React, { useRef } from "react";

type Props = {
  children: (
    initialFocusRef: React.RefObject<HTMLButtonElement>
  ) => React.ReactNode;
};
export const UndoMenu: React.FC<Props> = (props) => {
  const { children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });

  const initialFocusRef = useRef<HTMLButtonElement>(null);
  return (
    <Menu initialFocusRef={initialFocusRef} onOpen={onOpen} onClose={onClose}>
      <UndoButton />
      {isOpen ? children(initialFocusRef) : null}
    </Menu>
  );
};
