import { FormControl, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import { InputControl } from "formik-chakra-ui";
import _ from "lodash";
import React from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { DEBOUNCE_WAIT_IN_MILIS } from "src/services/url/constants";
import { DisclaimerSection, WithId } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { UPSERT_FORM_DISCLAIMER } from "../../graphql/mutations";

type Props = {
  section: DisclaimerSection<WithId>;
  formId: uuid;
};

export const SignatureInput: React.FC<Props> = ({ section, formId }) => {
  const [upsertDisclaimer] = useRemoteDataMutation<
    GQL.UpsertFormDisclaimer,
    GQL.UpsertFormDisclaimerVariables
  >(UPSERT_FORM_DISCLAIMER);
  const [field, meta] = useField("signature");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveSignature = React.useCallback(
    _.debounce(async (value: string) => {
      await upsertDisclaimer({
        variables: {
          form_id: formId,
          disclaimer_section_id: section.id,
          signature: value,
        },
      });
    }, DEBOUNCE_WAIT_IN_MILIS),
    []
  );

  React.useEffect(() => {
    if (meta.touched) {
      saveSignature(field.value);
    }
  }, [saveSignature, field.value, meta.touched]);

  return (
    <FormControl isRequired>
      <FormLabel htmlFor="signature">
        Please enter your full name here to confirm your understanding of the
        above:
      </FormLabel>
      <InputControl id="signature" {...field} />
    </FormControl>
  );
};
