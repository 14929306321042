export const ORGANIZATION = ":organization";
export const AUTH = "auth";

export const DEBOUNCE_WAIT_IN_MILIS = 500;

/** In case we want to show validation error */
export const REQUIRED_FIELD_VALIDATION_ERROR = "This information is required";
export const EMAIL_TYPE_VALIDATION_ERROR =
  "The entered email address is not valid";
export const PHONE_NUMBER_TYPE_VALIDATION_ERROR =
  "The entered phone number needs to be 10 digits";
export const REQUIRED_SHORT_VALIDATION_ERROR = "Required";
