import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Skeleton,
} from "@chakra-ui/react";

export function ModalSkeleton() {
  return (
    <>
      <ModalHeader>
        <Skeleton height="10" />
      </ModalHeader>
      <ModalBody>
        <Skeleton height="40" />
      </ModalBody>
      <ModalFooter>
        <Skeleton width="20" height="10" />
      </ModalFooter>
    </>
  );
}
