import { Flex, SystemStyleObject, Text } from "@chakra-ui/react";
import type { ComponentProps } from "react";

const dividerBarStyle: SystemStyleObject = {
  bg: "blackAlpha.200",
  content: "''",
  flexGrow: 1,
  height: "1px",
};

type DividerWithTextProps = Omit<ComponentProps<typeof Flex>, "children"> & {
  text: string;
};

export function DividerWithText(props: DividerWithTextProps) {
  const { text, ...flexProps } = props;

  return (
    <Flex
      _after={dividerBarStyle}
      _before={dividerBarStyle}
      alignItems="center"
      gap={6}
      width="100%"
      {...flexProps}
    >
      <Text color="gray.600">{text}</Text>
    </Flex>
  );
}
