import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { SortType } from "src/components/Table/SortButton";
import { Table } from "src/components/Table/Table";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { EnrollmentPeriodGeneral } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { DeleteEnrollmentPeriodButton } from "src/components/Buttons/DeleteEnrollmentPeriodButton";

interface EnrollmentsListProps {
  enrollments: GQL.GetEnrollments_enrollment_period[];
  onSort: (columnName: string, sortType: SortType) => void;
}

export const EnrollmentsList = ({
  enrollments,
  onSort,
}: EnrollmentsListProps) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();

  const columns: ColumnDef<GQL.GetEnrollments_enrollment_period>[] = useMemo(
    () => [
      { accessorKey: "id", header: "Id" },
      { accessorKey: "name", header: "name" },
      {
        accessorKey: "active",
        header: "status",
        cell: (props) => (props.getValue() === true ? "Active" : "Not Active"),
      },

      ...(userPermissions.hasSome([
        "enrollment_period:update",
        "enrollment_period:delete",
      ])
        ? [
            {
              id: "Actions",
              cell: (props: any) => {
                const id: string = props.row.original.id;
                const name: string = props.row.original.name;
                const hasForms: boolean =
                  !!props.row.original.form_templates_aggregate.aggregate.count;

                return (
                  <Flex gap={1}>
                    <WithUserPermissions
                      permissions={["enrollment_period:update"]}
                    >
                      <IconButton
                        as={NavLink}
                        to={organization
                          .map((org) => EnrollmentPeriodGeneral.edit(org, id))
                          .withDefault("#")}
                        aria-label={`Edit ${name}`}
                        icon={<Icon as={RiPencilLine} />}
                        variant="outline"
                      />
                    </WithUserPermissions>
                    <WithUserPermissions
                      permissions={["enrollment_period:delete"]}
                    >
                      <DeleteEnrollmentPeriodButton
                        id={id}
                        enrollmentPeriodName={name}
                        isIconButton
                        isDisabled={hasForms}
                      />
                    </WithUserPermissions>
                  </Flex>
                );
              },
            },
          ]
        : []),
    ],
    [userPermissions, organization]
  );

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  if (enrollments.length === 0)
    return <span>There are no enrollment periods</span>;

  return (
    <Table<GQL.GetEnrollments_enrollment_period>
      data={enrollments}
      columns={columns}
      sortableColumnIds={["name"]}
      onChangeSort={handleChangeSort}
    />
  );
};
