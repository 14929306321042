import * as z from "zod";
import { RepeatMode } from "../../forms/export/dateUtils";

export enum SelectionType {
  Inclusive = "Inclusive",
  Exclusive = "Exclusive",
}

const SchoolFormIdSchema = z.object({
  formId: z.string().uuid(),
  schoolId: z.string().uuid().nullable(),
  formSchoolRankId: z.string().uuid().nullable(),
});

const SelectionSchema = z.object({
  selectionType: z.nativeEnum(SelectionType),
  ids: z.array(SchoolFormIdSchema),
});
export type SelectionObject = z.infer<typeof SelectionSchema>;

const ExportConfigWithoutOrgIdSchema = z.object({
  formTemplateId: z.string().uuid(),
  searchParamsString: z.string(),
  selection: SelectionSchema,
});
export type ExportConfigWithoutOrgId = z.infer<
  typeof ExportConfigWithoutOrgIdSchema
>;

const ExportConfigSchema = ExportConfigWithoutOrgIdSchema.extend({
  organizationId: z.string().uuid(),
});
export type ExportConfig = z.infer<typeof ExportConfigSchema>;

const ScheduleConfigSchema = z.object({
  startTime: z.string().datetime(),
  repeatMode: z.nativeEnum(RepeatMode).nullable(),
});
export type ScheduleConfig = z.infer<typeof ScheduleConfigSchema>;

export enum SFTPAuthMethod {
  Password = "Password",
  SSHKey = "SSH Key",
}

export const SFTPConfigSchema = z.object({
  host: z.string(),
  port: z.number(),
  username: z.string(),
  authMethod: z.nativeEnum(SFTPAuthMethod),
  secret: z.string(),
  path: z.string(),
});

const ExportRequestSchema = z.object({
  exportConfig: ExportConfigWithoutOrgIdSchema,
  exportName: z.string().optional(),
  email: z.string(),
  sftpConfig: SFTPConfigSchema.optional(),
  questionIdsInOrder: z.array(z.string().uuid()), // Ideally we wouldn't need this, but it's a lot of form-related code to construct this from formTemplateId
});
export type ExportRequest = z.infer<typeof ExportRequestSchema>;

export const ExportResponseSchema = z.object({
  status: z.string(),
  url: z.string().optional(),
});
export type ExportResponse = z.infer<typeof ExportResponseSchema>;

const ScheduleRequestSchema = ExportRequestSchema.extend({
  scheduleConfig: ScheduleConfigSchema,
  orgConfig: z.string(),
});
export type ScheduleRequest = z.infer<typeof ScheduleRequestSchema>;

const ScheduleResponseSchema = z.object({
  pk: z.string(),
  entityType: z.string(),
  exportTime: ScheduleConfigSchema,
  exportConfig: ExportConfigSchema,
  exportName: z.string(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  status: z.string().optional(),
  url: z.string().optional(),
});
export type ScheduleResponse = z.infer<typeof ScheduleResponseSchema>;

export const ListSchedulesResponseSchema = z.array(ScheduleResponseSchema);
export type ListSchedulesResponse = z.infer<typeof ListSchedulesResponseSchema>;
