import { Box, Tag as ChakraTag, TagProps } from "@chakra-ui/react";
import React from "react";

type Tag = string | { name: string };
type Props = {
  tag: Tag;
} & TagProps;
export const BoundaryTag: React.FC<Props> = ({ tag, ...tagProps }) => {
  const name = typeof tag === "string" ? tag : tag.name;
  return (
    <ChakraTag variant="outline" colorScheme="primary" {...tagProps}>
      <Box whiteSpace="nowrap">{name}</Box>
    </ChakraTag>
  );
};
