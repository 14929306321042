import { Icon, IconButton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback } from "react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { DeleteTeamButton } from "src/scenes/orgAdmin/team/DeleteTeamButton";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";

interface TeamListProps {
  teams: GQL.GetTeams_team[];
  limit: number;
  offset: number;
  count: number;
  refetch: () => void;
  onFetchMore: (limit: number, offset: number) => void;
  onSort: (columnName: string, sortType: SortType) => void;
}

export const TeamList: React.FC<TeamListProps> = ({
  teams,
  limit,
  offset,
  count,
  refetch,
  onFetchMore,
  onSort,
}) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();
  const canEdit =
    useRequiredHasuraRoles([HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]) &&
    userPermissions.hasOne("team:update");
  const canDelete =
    useRequiredHasuraRoles([HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]) &&
    userPermissions.hasOne("team:delete");

  const columns: ColumnDef<GQL.GetTeams_team>[] = [
    {
      id: "id",
      accessorFn: (row) => row.id || "",
    },
    {
      id: "team_name",
      header: "team name",
      accessorFn: (row) => row?.name,
    },
    ...(canEdit || canDelete
      ? [
          {
            id: "Actions",
            cell: (props: any) => {
              const id: string = props.row.original.id;
              const name: string = props.row.original.name;
              return (
                <>
                  <WithUserPermissions permissions={["team:update"]}>
                    <IconButton
                      as={NavLink}
                      to={createRowURL(id)}
                      aria-label={`Edit ${name}`}
                      icon={<Icon as={RiPencilLine} />}
                      variant="outline"
                    />
                  </WithUserPermissions>
                  <WithUserPermissions permissions={["team:delete"]}>
                    <DeleteTeamButton id={id} name={name} onDelete={refetch} />
                  </WithUserPermissions>
                </>
              );
            },
          },
        ]
      : []),
  ];

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  function createRowURL(id: string): string {
    return organization
      .map((org) => Url.OrgAdmin.Team.editTeam(org, id))
      .withDefault("#");
  }

  if (teams.length === 0) return <span>You do not have any teams</span>;

  return (
    <PaginatedTable<GQL.GetTeams_team>
      data={teams}
      columns={columns}
      limit={limit}
      offset={offset}
      count={count}
      onFetchMore={onFetchMore}
      sortableColumnIds={["name"]}
      onChangeSort={handleChangeSort}
    />
  );
};
