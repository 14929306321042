import React from "react";
import { createContext, FunctionComponent, useContext } from "react";
import { State } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/state";
import * as AF from "src/types/formTemplate";
import { GetQuestionTypesByOrganization_custom_question_type } from "src/types/graphql";
import { Action, useFormTemplateProcessor } from "./processor";

type ContextValue = {
  state?: State;
  dispatch?: (action: Action) => void;
  formTemplateId?: uuid;
  enrollmentPeriodId?: uuid;
  customQuestionTypes?: GetQuestionTypesByOrganization_custom_question_type[];
};

const EditFormTemplateContext = createContext<ContextValue>({
  state: undefined,
  dispatch: undefined,
  formTemplateId: undefined,
  enrollmentPeriodId: undefined,
  customQuestionTypes: undefined
});

type Props = {
  children: React.ReactNode;
  formTemplate: AF.FormTemplate<AF.WithId>;
  customQuestionTypes: GetQuestionTypesByOrganization_custom_question_type[];
};

export const EditFormTemplateProvider: FunctionComponent<Props> = (props) => {
  const { children, formTemplate, customQuestionTypes } = props;
  const formTemplateState = useFormTemplateProcessor(formTemplate);

  return (
    <EditFormTemplateContext.Provider
      value={{
        ...formTemplateState,
        customQuestionTypes,
        formTemplateId: formTemplate.id,
        enrollmentPeriodId: formTemplate.enrollmentPeriodId
      }}
    >
      {children}
    </EditFormTemplateContext.Provider>
  );
};

export function useEditFormTemplateContext() {
  const context = useContext<ContextValue>(EditFormTemplateContext);

  if (context === undefined) {
    throw new Error(
      "useEditFormTemplateContext must be used within a FormTemplateEditProvider"
    );
  }

  return context;
}
