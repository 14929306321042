import {
  FreeTextLiteral,
  EmailLiteral,
  PhoneNumberLiteral,
  SingleSelectLiteral,
} from "src/schemas/CustomQuestionType";
import { z } from "zod";

const FieldAnswerSchema = z.object({
  questionLabel: z.string(),
  clonedQuestionId: z.string().uuid(),
});

const TextFieldAnswerSchema = FieldAnswerSchema.extend({
  questionType: z.union([FreeTextLiteral, EmailLiteral, PhoneNumberLiteral]),
  answer: z.string(),
});

const SelectFieldAnswerSchema = FieldAnswerSchema.extend({
  questionType: SingleSelectLiteral,
  clonedQuestionSelectOptionId: z.string().uuid().optional(),
  clonedQuestionSelectOptionLabel: z.string().optional(),
});

const AnswerBankTextFieldSchema = TextFieldAnswerSchema.extend({
  customQuestionTypeFieldId: z.string().uuid(),
});

const AnswerBankSelectFieldSchema = SelectFieldAnswerSchema.extend({
  customQuestionTypeFieldSelectOptionId: z.string().uuid().optional(),
  customQuestionTypeFieldSelectOptionLabel: z.string().optional(),
});

export const AnswerBankRecordFieldSchema = z.union([
  AnswerBankTextFieldSchema,
  AnswerBankSelectFieldSchema,
]);
export type AnswerBankRecordField = z.infer<typeof AnswerBankRecordFieldSchema>;
