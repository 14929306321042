import { Box, StyleProps } from "@chakra-ui/react";
import React from "react";

interface HorizontallyBoundedContainerProps extends StyleProps {
  children: React.ReactNode;
}

export const HorizontallyBoundedContainer = ({
  children,
  ...styleProps
}: HorizontallyBoundedContainerProps) => {
  return (
    <Box width="100%" maxWidth="600px" marginX="auto" {...styleProps}>
      {children}
    </Box>
  );
};
