import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb as ChakraBreadrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  StyleProps,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Glossary } from "src/components/Text/Glossary";
import { BreadcrumbType } from "src/services/breadcrumb";

type BreadcrumbProps = StyleProps & {
  items: BreadcrumbType[];
};

export const Breadcrumb = ({ items, ...restProps }: BreadcrumbProps) => {
  return (
    <ChakraBreadrumb
      separator={<Icon as={ChevronRightIcon} color="gray.500" marginX={1} />}
      {...restProps}
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        return (
          <BreadcrumbItem
            fontSize="xs"
            fontWeight="bold"
            isCurrentPage={isLast}
            key={index}
          >
            <BreadcrumbLink
              as={isLast ? undefined : Link}
              to={item.href}
              color={isLast ? "gray.700" : "primary.500"}
            >
              <Glossary>{item.label}</Glossary>
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </ChakraBreadrumb>
  );
};
