import { Box, HStack, Spacer } from "@chakra-ui/react";
import React, {
  isValidElement,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { DefaultOverflowMenu } from "./DefaultOverflowMenu";

export type VisibilityMap = {
  [key: string]: boolean;
};

type ResponsiveToolbarProps = {
  leftItems?: React.ReactNode;
  overflowMenu?: (children: React.ReactNode) => ReactElement;
};

export const ResponsiveToolbar: React.FC<
  React.PropsWithChildren<ResponsiveToolbarProps>
> = ({ children, leftItems, overflowMenu }) => {
  const navRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      const observer = new IntersectionObserver(handleIntersection, {
        root: node,
        threshold: 1,
      });

      Array.from(node.children).forEach((item) => {
        observer.observe(item);
      });

      // Note: Using refs with callbacks doesn't support a cleanup handler like
      // useEffect(), so this cleanup operation would never be run.
      // TODO: Properly clean up the observer.
      // TODO: Alternatively, use a library for this, e.g.
      //   https://www.npmjs.com/package/react-intersection-observer
      // return () => observer.disconnect();
    }
  }, []);
  const [visibilityMap, setVisibilityMap] = useState<VisibilityMap>({});

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries: VisibilityMap = {};
    entries.forEach((entry) => {
      if (entry.target instanceof HTMLElement) {
        const targetid = entry.target.dataset.targetid;
        if (targetid) updatedEntries[targetid] = entry.isIntersecting;
      }
    });

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  const overflowChildren = React.Children.map(children, (child, index) =>
    visibilityMap[index] ? null : child
  );

  return (
    <HStack direction="row" width="100%">
      {leftItems}
      <Spacer />
      <HStack overflow="hidden" ref={navRef}>
        {React.Children.map(children, (child, index) => {
          return isValidElement(child) ? (
            <Box
              key={index}
              minW="fit-content"
              visibility={visibilityMap[index] ? "visible" : "hidden"}
              data-targetid={index}
            >
              {child}
            </Box>
          ) : null;
        })}
      </HStack>
      {overflowMenu ? (
        <Box minW="fit-content">{overflowMenu(overflowChildren)}</Box>
      ) : (
        <DefaultOverflowMenu>{overflowChildren}</DefaultOverflowMenu>
      )}
    </HStack>
  );
};
