import { Flex, Link, Text } from "@chakra-ui/react";
import { ReactComponent as AvelaLogo } from "src/images/logo-gray.svg";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = {
  config: OrgConfig.AccountLookup.Config;
};

export const Footer: React.FC<Props> = (props) => {
  const { config } = props;

  return (
    <Flex direction="column" gap={3} alignItems="center">
      <AvelaLogo aria-label="Avela" height="1.5rem" width="6rem" />
      <Text fontSize="0.75rem" color="gray.600">
        Stuck?{" "}
        <Link href={config.supportUrl} isExternal textDecoration="underline">
          Get help now
        </Link>
      </Text>
    </Flex>
  );
};
