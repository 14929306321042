import { Button, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { AccountLookupStep } from "../Step";
import { SuccessAccountLookupResponse } from "../helpers";
import {
  AuthenticationMethodType,
  useSpecificAuthenticationLink,
} from "./helpers";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = Omit<SuccessAccountLookupResponse, "parentGuardianExists"> & {
  config: OrgConfig.AccountLookup.Config;
};

export const FoundAnAccount: FunctionComponent<Props> = (props) => {
  const { auth0UserType, emailAddress, phoneNumber, config } = props;
  const loginMethod = getLoginMethod(auth0UserType);
  const loginLink = useSpecificAuthenticationLink("login", loginMethod!);

  return (
    <AccountLookupStep
      heading="🎉 We found an account 🎉"
      description={`The ${loginMethod} below can be used to login.`}
    >
      <Stack gap={4} textAlign="center">
        <Text fontSize="2xl" fontWeight={600}>
          {emailAddress ?? phoneNumber}
        </Text>

        <Button as={NavLink} to={loginLink}>
          Login with {loginMethod}
        </Button>

        <Button
          as={NavLink}
          variant="outline"
          colorScheme="gray"
          to={config.supportUrl}
        >
          I don't have access to this
        </Button>

        <Text size="sm" fontWeight={400} color="gray.600">
          The {loginMethod} is redacted for privacy reasons. If you do not
          recognize this {loginMethod}, reach out to an administrator.
        </Text>
      </Stack>
    </AccountLookupStep>
  );
};

function getLoginMethod(
  auth0UserType: SuccessAccountLookupResponse["auth0UserType"]
): AuthenticationMethodType | null {
  if (auth0UserType === "auth0") {
    return AuthenticationMethodType.EMAIL;
  }

  if (auth0UserType === "sms") {
    return AuthenticationMethodType.PHONE;
  }

  return null;
}
