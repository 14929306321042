import { Button, Flex } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { WeglotText } from "src/plugins/weglot";
import { SaveStatus as SaveStatusType } from "src/services/formTemplate/question";

export type LateEditFormButtonsProps = {
  saveStatus?: SaveStatusType;
  overrideNextButton?: {
    label?: string;
    action?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    hide?: boolean;
  };
  overridePreviousButton?: {
    label?: string;
    action?: () => void;
    showIcon?: boolean;
    hide?: boolean;
  };
};

export const LateEditFormButtons = ({
  overrideNextButton,
  overridePreviousButton,
}: LateEditFormButtonsProps) => {
  const overrideNextAction = overrideNextButton?.action;
  const overridePreviousAction = overridePreviousButton?.action;

  return (
    <ParentFormsLayoutFooter alignItems="center">
      <Flex direction="column" width="100%" gap={2}>
        <Flex justifyContent="space-between">
          {!overridePreviousButton?.hide ? (
            <Button
              colorScheme="gray"
              leftIcon={<FaArrowLeft />}
              onClick={overridePreviousAction}
              size="md"
              variant="ghost"
            >
              <WeglotText>{overridePreviousButton?.label}</WeglotText>
            </Button>
          ) : null}
          {!overrideNextButton?.hide && (
            <Button
              colorScheme="gray"
              isLoading={overrideNextButton?.isLoading}
              type="submit"
              isDisabled={overrideNextButton?.disabled}
              rightIcon={<FaArrowRight />}
              size="md"
              variant="ghost"
              onClick={overrideNextAction}
            >
              <WeglotText>{overrideNextButton?.label}</WeglotText>
            </Button>
          )}
        </Flex>
      </Flex>
    </ParentFormsLayoutFooter>
  );
};
