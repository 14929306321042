import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RiLightbulbLine } from "react-icons/ri";
import FormDescriptionInformationImage from "src/images/form-description-information-dialog.svg";
import FormNameInformationImage from "src/images/form-name-information-dialog.svg";

interface Props {
  id: InformationPopoverTypes;
}

export enum InformationPopoverTypes {
  Name = "name",
  Key = "key",
  Description = "description",
  LotteryAndOffers = "lotteryAndOffers"
}

export const InformationPopover: React.FC<Props> = ({ id }) => {
  const popOverImage = useMemo(() => {
    switch (id) {
      case InformationPopoverTypes.Name:
        return <Image src={FormNameInformationImage} />;
      case InformationPopoverTypes.Key:
        return <></>;
      case InformationPopoverTypes.Description:
        return <Image src={FormDescriptionInformationImage} />;
      case InformationPopoverTypes.LotteryAndOffers:
        return <></>;
    }
  }, [id]);

  const popoverBody = useMemo(() => {
    switch (id) {
      case InformationPopoverTypes.Name:
        return (
          <Flex direction="column" gap={2}>
            <Heading as="h1" fontSize="md" fontWeight="700" color="gray.700">
              Actionable form names
            </Heading>

            <Text fontWeight="400" color="gray.700">
              Increase your form responses by naming your forms in a
              descriptive, actionable manner.
            </Text>
          </Flex>
        );
      case InformationPopoverTypes.Key:
        return (
          <Flex direction="column" gap={2}>
            <Heading as="h1" fontSize="md" fontWeight="700" color="gray.700">
              Identify forms with keys
            </Heading>

            <Text fontWeight="400" color="gray.700">
              Use unique keys to identify each form within the enrollment
              period.
            </Text>
          </Flex>
        );
      case InformationPopoverTypes.Description:
        return (
          <Flex direction="column" gap={2}>
            <Heading as="h1" fontSize="md" fontWeight="700" color="gray.700">
              Guide families to the right form
            </Heading>

            <Text fontWeight="400" color="gray.700">
              Ensure people pick the right option by providing brief
              descriptions of each form.
            </Text>
          </Flex>
        );
      case InformationPopoverTypes.LotteryAndOffers:
        return (
          <Flex direction="column" gap={2}>
            <Heading as="h1" fontSize="md" fontWeight="700" color="gray.700">
              Lottery & offers
            </Heading>

            <Text fontWeight="400" color="gray.700">
              Turn this on to enable waitlists and the ability to make or revoke
              offers, simultaneously enabling seat capacities for the enrollment
              period.
            </Text>
          </Flex>
        );
    }
  }, [id]);

  return (
    <Popover placement="right-end" trigger="hover">
      <PopoverTrigger>
        <IconButton
          aria-label="Basic Information"
          size="sm"
          icon={<InfoOutlineIcon />}
          variant="ghost"
          color="black"
        />
      </PopoverTrigger>
      <PopoverContent>
        {popOverImage}
        <Box margin={6}>
          <Tag size="md" variant="solid" colorScheme="purple" mb={2}>
            <Icon boxSize={4} as={RiLightbulbLine} paddingRight={1} />
            <Text size="xs">Tip</Text>
          </Tag>
          {popoverBody}
        </Box>
      </PopoverContent>
    </Popover>
  );
};
