import { Button, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { AccountLookupStep } from "../Step";
import { CreateAnAccountProps } from "./CreateAnAccount";
import {
  AuthenticationMethodType,
  useSpecificAuthenticationLink,
} from "./helpers";

export const OneOptionSignUp: FunctionComponent<CreateAnAccountProps> = (
  props
) => {
  const { emailAddress, phoneNumber, config } = props;
  // be mindful of email address and phone number being empty strings
  const signUpMethodType = emailAddress
    ? AuthenticationMethodType.EMAIL
    : AuthenticationMethodType.PHONE;
  const signUpLink = useSpecificAuthenticationLink("signup", signUpMethodType!);

  return (
    <AccountLookupStep
      heading="🎉 Create your account 🎉"
      description={`The ${signUpMethodType} below can be used to create a new account.`}
    >
      <Stack gap={4} textAlign="center">
        <Text fontSize="2xl" fontWeight={600}>
          {emailAddress ?? phoneNumber}
        </Text>

        <Button as={NavLink} to={signUpLink}>
          Sign up with {signUpMethodType}
        </Button>

        <Button
          as={NavLink}
          variant="outline"
          colorScheme="gray"
          to={config.supportUrl}
        >
          I don't have access to this
        </Button>

        <Text size="sm" fontWeight={400} color="gray.600">
          The {signUpMethodType} is redacted for privacy reasons. If you do not
          recognize this {signUpMethodType}, reach out to an administrator.
        </Text>
      </Stack>
    </AccountLookupStep>
  );
};
