import { Button, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import { AccountLookupStep } from "../Step";
import { SuccessAccountLookupResponse } from "../helpers";
import {
  AuthenticationMethodType,
  useSpecificAuthenticationLink,
} from "./helpers";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = Omit<
  SuccessAccountLookupResponse,
  "parentGuardianExists" | "emailAddress" | "phoneNumber"
> & {
  config: OrgConfig.AccountLookup.Config;
  // email address and phone number are non-null
  emailAddress: string;
  phoneNumber: string;
};

export const FoundAnAccountWithMultipleContacts: FunctionComponent<Props> = (
  props
) => {
  const { auth0UserType, emailAddress, phoneNumber, config } = props;

  const mainMethod = getLoginMethod(auth0UserType);
  const emailLoginLink = useSpecificAuthenticationLink(
    "login",
    AuthenticationMethodType.EMAIL
  );
  const emailSignupLink = useSpecificAuthenticationLink(
    "signup",
    AuthenticationMethodType.EMAIL
  );
  const phoneLink = useSpecificAuthenticationLink(
    "login",
    AuthenticationMethodType.PHONE
  );
  const [showLogin, setShowLogin] = useState(true);

  return (
    <AccountLookupStep
      heading="🎉 We found an account 🎉"
      description={`The email or phone below can be used to login.`}
    >
      <Stack gap={4} textAlign="center">
        <Text fontSize="2xl" fontWeight={600}>
          {phoneNumber}
        </Text>
        <Text fontSize="2xl" fontWeight={600}>
          {emailAddress}
        </Text>

        <Button as={NavLink} to={phoneLink}>
          Login with phone number
        </Button>

        {mainMethod !== AuthenticationMethodType.EMAIL ? (
          <Button
            as={NavLink}
            to={showLogin ? emailLoginLink : emailSignupLink}
            target="_blank"
            onClick={() => setTimeout(() => setShowLogin(false), 100)}
          >
            {showLogin ? "Login with" : "Sign up with"} email
          </Button>
        ) : (
          <Button as={NavLink} to={emailLoginLink}>
            Login with email
          </Button>
        )}

        <Button
          as={NavLink}
          variant="outline"
          colorScheme="gray"
          to={config.supportUrl}
        >
          I don't have access to this
        </Button>

        <Text size="sm" fontWeight={400} color="gray.600">
          The data is redacted for privacy reasons. If you do not recognize this
          data, reach out to an administrator.
        </Text>
      </Stack>
    </AccountLookupStep>
  );
};

function getLoginMethod(
  auth0UserType: SuccessAccountLookupResponse["auth0UserType"]
): AuthenticationMethodType | null {
  if (auth0UserType === "auth0") {
    return AuthenticationMethodType.EMAIL;
  }

  if (auth0UserType === "sms") {
    return AuthenticationMethodType.PHONE;
  }

  return null;
}
