export enum ColumnId {
  ID = "id",
  Grade = "apl_grade",
  School = "school",
  SchoolId = "school_id",
  SchoolRank = "school_rank",
  Status = "status",
  Student = "student",
  SubStatus = "sub-status-form-school-rank",
  Tags = "tags",
  Tiebreaker = "tiebreaker",
  Verifications = "verifications",
  Visibility = "is_hidden_from_parent",
  WaitlistPosition = "position",
}
