import axios from "axios";
import React from "react";
import useAccessToken from "src/hooks/useAccessToken";
import { Status } from "src/types/authData";
import {
  ExportRequest,
  ExportResponse,
  ExportResponseSchema,
  ListSchedulesResponse,
  ListSchedulesResponseSchema,
  ScheduleRequest,
} from "./types";

export class ExportManagementError extends Error {
  constructor(message: string, readonly cause?: any) {
    super(message);
    this.cause = cause;
  }
}

export const useExportManagementAPI = () => {
  const baseurl = process.env.REACT_APP_BULK_EXPORT_MANAGEMENT_API;
  const accessToken = useAccessToken();
  const [bearerToken, setBearerToken] = React.useState<string>();

  React.useEffect(
    () => {
      if (accessToken.status === Status.OK)
        setBearerToken(`Bearer ${accessToken.data}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken.status === Status.OK && accessToken.data, accessToken.status]
  );

  const createExport = React.useCallback(
    async (organizationId: string, request: ExportRequest) => {
      if (!baseurl || !bearerToken) return;
      const response = await axios.post<{ pk: string }>(
        `${baseurl}/organizations/${organizationId}/export`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 201) {
        throw new ExportManagementError("Failed to create export", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
      return response.data;
    },
    [bearerToken, baseurl]
  );

  const getOneExport = React.useCallback(
    async (organizationId: string, pk: string) => {
      if (!baseurl || !bearerToken) return;
      const response = await axios.get<ExportResponse>(
        `${baseurl}/organizations/${organizationId}/export/${encodeURIComponent(
          pk
        )}`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        throw new ExportManagementError("Failed to get export", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
      return ExportResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  const scheduleExport = React.useCallback(
    async (organizationId: string, request: ScheduleRequest) => {
      if (!baseurl || !bearerToken) return;
      const response = await axios.post<{ pk: string }>(
        `${baseurl}/organizations/${organizationId}/schedule`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 201) {
        throw new ExportManagementError("Failed to schedule export", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
      return response.data;
    },
    [bearerToken, baseurl]
  );

  const getScheduledExports = React.useCallback(
    async (organizationId: string) => {
      if (!baseurl || !bearerToken) return;
      const response = await axios.get<ListSchedulesResponse>(
        `${baseurl}/organizations/${organizationId}/schedule`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 404) {
        return [];
      }
      if (response.status !== 200) {
        throw new ExportManagementError("Failed to get scheduled exports", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
      return ListSchedulesResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  const deleteSchedule = React.useCallback(
    async (organizationId: string, pk: string) => {
      if (!baseurl || !bearerToken) return;
      const response = await axios.delete(
        `${baseurl}/organizations/${organizationId}/schedule/${encodeURIComponent(
          pk
        )}`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 204) {
        throw new ExportManagementError("Failed to delete schedule", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
      return response.data;
    },
    [bearerToken, baseurl]
  );

  return {
    createExport,
    getOneExport,
    scheduleExport,
    getScheduledExports,
    deleteSchedule,
  };
};
