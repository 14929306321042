import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { RiAddCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import * as Url from "src/services/url";
import {
  programFormInputToProgramType,
  ProgramFormInputType,
  ProgramGroupType,
  programToProgramFormInputType,
} from "./types/program";

interface ProgramGroupFormProps {
  initialProgramGroup?: ProgramGroupType;
  onSubmit: (programGroup: ProgramGroupType) => Promise<void>;
  submitting: boolean;
}
export const ProgramGroupForm: React.FC<ProgramGroupFormProps> = ({
  initialProgramGroup,
  onSubmit,
  submitting,
}) => {
  const [organizationId, setOrganizationId] = useState<string>(
    initialProgramGroup?.organization_id ?? ""
  );
  const [name, setName] = useState<string>(initialProgramGroup?.name ?? "");
  const [programs, setPrograms] = useState<ProgramFormInputType[]>(
    initialProgramGroup?.programs.map((program) =>
      programToProgramFormInputType(program)
    ) ?? [{ label: "", order: "" }]
  );
  const [programsToDeleteIds, setProgramsToDeleteIds] = useState<string[]>([]);

  const getProgramGroup = useCallback(
    () => ({
      organization_id: organizationId,
      name: name,
      programs: programs.map((program) =>
        programFormInputToProgramType(program)
      ),
      programs_to_delete_ids: programsToDeleteIds,
    }),
    [name, organizationId, programs, programsToDeleteIds]
  );

  return (
    <Flex direction="column" minHeight="100%">
      <VStack align="left" spacing={8}>
        <Heading>
          {initialProgramGroup ? "Edit program group" : "Create program group"}
        </Heading>
        <HStack width="100%" mb={4} gap={2}>
          <FormControl>
            <FormLabel htmlFor="organizationId">Organization ID</FormLabel>
            <Input
              type="text"
              id="organizationId"
              name="organizationId"
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              isDisabled={initialProgramGroup !== undefined}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="name">Program group</FormLabel>
            <Input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </HStack>

        <Heading as="h2" size="md" mb={2}>
          Program configuration
        </Heading>
        <VStack gap={2}>
          {programs.map((program, index) => (
            <HStack width="100%" align="end" key={index} gap={2}>
              <FormControl>
                <FormLabel htmlFor={`programs[${index}].label`}>
                  Program label
                </FormLabel>
                <Input
                  type="text"
                  id={`programs[${index}].label`}
                  name={`programs[${index}].label`}
                  value={program.label}
                  onChange={(e) => {
                    const newPrograms = [...programs];
                    newPrograms.splice(index, 1, {
                      id: program.id,
                      label: e.target.value,
                      order: program.order,
                    });
                    setPrograms(newPrograms);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`programs[${index}].order`}>
                  Order
                </FormLabel>
                <Input
                  type="number"
                  id={`programs[${index}].order`}
                  name={`programs[${index}].order`}
                  value={program.order}
                  onChange={(e) => {
                    const newPrograms = [...programs];
                    newPrograms.splice(index, 1, {
                      id: program.id,
                      label: program.label,
                      order: e.target.value,
                    });
                    setPrograms(newPrograms);
                  }}
                />
              </FormControl>
              <IconButton
                aria-label="Remove program"
                icon={<RiDeleteBin6Line />}
                onClick={() => {
                  const newPrograms = [...programs];
                  newPrograms.splice(index, 1);
                  setPrograms(newPrograms);
                  program.id &&
                    setProgramsToDeleteIds([
                      ...programsToDeleteIds,
                      program.id,
                    ]);
                }}
                variant="outline"
              />
            </HStack>
          ))}
          <Button
            aria-label="Add program"
            leftIcon={<RiAddCircleLine />}
            onClick={() => {
              setPrograms([...programs, { label: "", order: "" }]);
            }}
          >
            Add program
          </Button>
        </VStack>
      </VStack>
      <Spacer minH={8} />
      <AdminFormButtons justifyContent="space-between">
        <Button as={NavLink} to={Url.Admin.Programs.index()} variant="outline">
          Cancel
        </Button>
        <Button
          type="submit"
          marginLeft={4}
          onClick={() => onSubmit(getProgramGroup())}
          isLoading={submitting}
        >
          Submit
        </Button>
      </AdminFormButtons>
    </Flex>
  );
};
