import { Box, Button, HStack } from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import React, { useMemo } from "react";
import { EmptyState } from "src/components/EmptyState";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import { useSendMessage } from "src/hooks/useSendMessage";
import { ReactComponent as NoMessagesSvg } from "src/images/no-messages.svg";
import { getGuardians, isEligibleForMessaging } from "src/services/person";
import { checkUnverifiedCommsPerOrg } from "src/services/unverifiedCommsHelpers";
import * as GQL from "src/types/graphql";

interface NoMessagesProps {
  form: GQL.GetFormViewById_form_by_pk;
}

export const NoMessages: React.FC<NoMessagesProps> = ({ form }) => {
  const organization = useOrganization();
  const flags = useFlags(["unverified-communications"]);
  const { handleSendMessage } = useSendMessage(form.form_template.id);
  const formParents = getGuardians(form.person);
  const parentIds = formParents.map((parent) => parent.id);

  const isUnverifiedCommsEnabledForOrg = useMemo(
    () =>
      checkUnverifiedCommsPerOrg(
        organization,
        flags["unverified-communications"].enabled
      ),
    [flags, organization]
  );

  const isEligibleForMessage = formParents.some((parent) =>
    isEligibleForMessaging(parent, isUnverifiedCommsEnabledForOrg)
  );

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description="There are no messages for this form response"
        heading=""
        Svg={NoMessagesSvg}
      >
        <HStack>
          <WithUserPermissions permissions={["message:create"]}>
            {isEligibleForMessage && (
              <Button
                onClick={() =>
                  handleSendMessage(parentIds, [form.person.id], [form.id])
                }
                variant="outline"
              >
                Send new message
              </Button>
            )}
          </WithUserPermissions>
        </HStack>
      </EmptyState>
    </Box>
  );
};
