import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { Programs } from "src/services/url/Admin";
import { Index } from ".";
import { Edit } from "./Edit";
import { New } from "./New";

export const AdminProgramsRoutes = (
  <Route
    path={Programs.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.programs">
          <Index />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={Programs.newPath}
      element={
        <RequireUserPermissions permission="program:create">
          <New />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Programs.editPath}
      element={
        <RequireUserPermissions permission="program:update">
          <Edit />
        </RequireUserPermissions>
      }
    />
  </Route>
);
