import * as DateFns from "date-fns";
import { FormikErrors } from "formik";
import _ from "lodash";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { z } from "zod";
import { canonicalPhoneNumber } from "./format";

export const required =
  (message: string) =>
  <T>(value: T) => {
    return !hasValue(value) ? message : undefined;
  };

export function hasValue<T>(value: T): boolean {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value === "string") {
    return value.trim() !== "";
  }

  return true;
}

export function isValidDate(dateStr: string): boolean {
  return DateFns.isMatch(dateStr, "yyyy-MM-dd");
}

export function isValidEmail(answer: string): boolean {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regex.test(answer);
}

export function isNumberString(answer: string): boolean {
  const regex = /^\d+$/;
  return regex.test(answer);
}

export function isValidPhoneNumber(answer: string): boolean {
  return isPossiblePhoneNumber(canonicalPhoneNumber(answer));
}

export function isValidUsPhoneNumber(answer: string): boolean {
  const canonicalNumber = canonicalPhoneNumber(answer);
  return canonicalNumber.startsWith("+1") && canonicalNumber.length === 12;
}

function createValidationError<T>(e: z.ZodError): FormikErrors<T> {
  const initialErrors: FormikErrors<T> = {};
  const validationErrors = e.errors.reduce(
    (accumulatedErrors, err) => ({
      ...accumulatedErrors,
      ...createNestedErrors(accumulatedErrors, err),
      [err.path.join(".")]: err.message,
    }),
    initialErrors
  );
  return validationErrors;
}

export function createNestedErrors<T>(
  accumulatedErrors: FormikErrors<T>,
  e: Pick<z.ZodIssue, "path" | "message">
): FormikErrors<T> {
  const path = e.path;
  return _.merge(
    accumulatedErrors,
    path.reduceRight((acc, cur, index) => {
      if (index === path.length - 1) {
        return { [cur]: e.message };
      }
      return { [cur]: acc };
    }, {})
  );
}

export const validateWithZod =
  <T>(schema: z.ZodSchema<T>) =>
  async (values: T): Promise<FormikErrors<T> | undefined> => {
    try {
      await schema.parseAsync(values);
    } catch (err: unknown) {
      if (err instanceof z.ZodError) {
        return createValidationError(err as z.ZodError<T>);
      }
    }
  };
