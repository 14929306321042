import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import React from "react";

export type CustomFormInputProps = {
  id?: string;
  error?: string;
  label?: string;
  flex?: string | number;
  isRequired?: boolean;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
  children: React.ReactNode;
};

export const CustomFormInput = ({
  id,
  label,
  error,
  flex,
  isRequired = false,
  formControlProps,
  formLabelProps,
  formErrorMessageProps,
  children,
}: CustomFormInputProps) => (
  <FormControl
    flex={flex}
    isInvalid={Boolean(error)}
    isRequired={isRequired}
    height="100%"
    {...formControlProps}
  >
    {label && (
      <FormLabel htmlFor={id} {...formLabelProps}>
        {label}
      </FormLabel>
    )}
    {children}
    {error && (
      <FormErrorMessage {...formErrorMessageProps}>{error}</FormErrorMessage>
    )}
  </FormControl>
);
