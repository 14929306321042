import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useSelect } from "downshift";
import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";

export type Item = { key: string; disabled?: boolean };
export type CustomSelectProps<T extends Item> = {
  disabled?: boolean;
  label: React.ReactNode;
  items: T[];
  selectedItem: T | null;
  onChange: (item: T | null) => void;
  renderItem: (item: T) => React.ReactNode;
  renderSelectedItem: (item: T | null) => React.ReactNode;
  renderEmptyItems?: () => React.ReactNode;
  error?: string;
  alert?: React.ReactNode;
  /*
    Some cases the dropdown needs to be open up, 
    because it would break the page layout or create a unecessary scroll.
  */
  openDirection?: "up" | "down";
  maxHeight?: string;
};
/**
 * Custom Select component capable of rendering custom option.
 */
export function CustomSelect<T extends Item>(props: CustomSelectProps<T>) {
  const {
    items,
    onChange,
    renderItem,
    renderSelectedItem,
    error,
    disabled,
    renderEmptyItems,
    alert,
    openDirection = "down",
  } = props;

  const itemToString = React.useCallback((value: T | null) => {
    return value ? value.key : "";
  }, []);
  const {
    selectedItem,
    getItemProps,
    getLabelProps,
    getToggleButtonProps,
    isOpen,
    getMenuProps,
    highlightedIndex,
  } = useSelect<T>({
    items,
    itemToString,
    selectedItem: props.selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
      onChange(newSelectedItem ?? null),
    isItemDisabled: (item, index) => {
      return items.find((i) => i.key === item.key)?.disabled ?? false;
    },
  });

  const errorStyle: FlexProps =
    !disabled && error
      ? {
          borderWidth: "1px",
          borderColor: "red.500",
          boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
        }
      : {
          borderWidth: "1px",
          borderColor: "inherit",
        };

  const disabledStyle: FlexProps = disabled
    ? {
        cursor: "not-allowed",
        opacity: 0.4,
      }
    : {};

  const focusStyle: FlexProps = disabled
    ? {}
    : {
        outline: "0px",
        border: "1px solid",
        borderColor: "blue.500",
        boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
      };

  return (
    <FormControl
      isInvalid={!disabled && error !== undefined}
      isDisabled={disabled}
    >
      <FormLabel {...getLabelProps()}>{props.label}</FormLabel>
      {alert && <Box marginBottom={2}> {alert}</Box>}
      <Flex
        borderStyle="solid"
        borderRadius={6}
        padding={2}
        paddingLeft={4}
        justifyContent="space-between"
        alignItems="center"
        transitionProperty="border-color,box-shadow"
        transitionDuration="0.2s"
        _focus={focusStyle}
        {...getToggleButtonProps({ disabled })}
        {...errorStyle}
        {...disabledStyle}
      >
        <Box>
          {items.length === 0 && renderEmptyItems
            ? renderEmptyItems()
            : renderSelectedItem(selectedItem)}
        </Box>
        <Icon as={RiArrowDownSLine} boxSize={6} />
      </Flex>
      <List
        {...getMenuProps()}
        paddingY={2}
        borderColor="inherit"
        borderWidth={1}
        borderStyle="solid"
        display={isOpen ? "flex" : "none"}
        position="absolute"
        bg="white"
        width="100%"
        borderRadius={4}
        flexDirection="column"
        boxShadow="lg"
        bottom={
          openDirection === "up" ? (props.label ? "66%" : "100%") : undefined
        }
        maxHeight={props.maxHeight}
        overflow={props.maxHeight ? "scroll" : undefined}
      >
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              key={item.key}
              paddingX={4}
              paddingY={2}
              transition="background 0.2s"
              background={
                highlightedIndex === index
                  ? "gray.200"
                  : selectedItem === item
                  ? "gray.100"
                  : undefined
              }
              {...getItemProps({ item, index })}
              {...(item.disabled ? { opacity: 0.4 } : {})}
            >
              {renderItem(item)}
            </ListItem>
          ))}
      </List>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
