import { useApolloClient } from "@apollo/client";
import { chunk, uniq } from "lodash";
import { useCallback } from "react";
import { CHUNKED_BATCH_SIZE } from "src/constants";
import {
  ADD_TO_WAITLIST_BULK,
  ADD_TO_WAITLIST_BULK_MAX_INPUT_SIZE,
  REMOVE_FROM_WAITLIST_BULK,
  REMOVE_FROM_WAITLIST_BULK_MAX_INPUT_SIZE,
} from "src/scenes/orgAdmin/waitlists/graphql/mutations";
import {
  FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS,
  FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS_MAX_INPUT_SIZE,
  FETCH_PRIORITY_TEMPLATES_BY_GRADE,
} from "src/scenes/orgAdmin/waitlists/graphql/queries";
import { asyncFlatMap, asyncMap } from "src/services/asyncHelpers";
import { findPriorityGroupForTags } from "src/services/findPriorityGroup";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import {
  DEFAULT_PRIORITY_TEMPLATE,
  PriorityConfig,
} from "src/types/priorityTemplate";
import { useRemoteDataMutation } from "./useRemoteDataMutation";

export interface FormSchool {
  form_id: string;
  school_id: string;
}

export const useFetchPriorityTemplates = () => {
  const client = useApolloClient();
  return useCallback(
    async (gradeIds: uuid[]) => {
      const result = await client.query<
        GQL.FetchPriorityTemplatesByGrade,
        GQL.FetchPriorityTemplatesByGradeVariables
      >({
        query: FETCH_PRIORITY_TEMPLATES_BY_GRADE,
        variables: {
          grade_ids: gradeIds,
        },
      });

      if (!result.data) {
        throw new Error("data");
      }
      return result.data.grade_resolved_priority_template;
    },
    [client]
  );
};

const useFetchSchoolFormsTags = () => {
  const client = useApolloClient();
  return useCallback(
    (formSchools: FormSchool[]) =>
      asyncFlatMap(
        chunk(formSchools, FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS_MAX_INPUT_SIZE),
        async (formSchoolsChunk) => {
          const result = await client.query<
            GQL.FetchFormSchoolGradeIdsAndTags,
            GQL.FetchFormSchoolGradeIdsAndTagsVariables
          >({
            query: FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS,
            variables: {
              matching: {
                _or: formSchoolsChunk.map((item) => ({
                  form_id: { _eq: item.form_id },
                  school_id: { _eq: item.school_id },
                })),
              },
            },
          });

          if (!result.data) {
            throw new Error("data");
          }
          const formSchoolGrades = result.data.form_school_grade;
          if (
            // TODO: temporarily remove this check to allow AddToWaitlist to work with programs
            // PENDING: long-term waitlist requirements come up then remove this line with new solution
            // formSchoolGrades.length !== formSchools.length ||
            formSchoolGrades.some(
              (item) =>
                !item.form_id ||
                !item.school_id ||
                !item.grade_id ||
                !item.form_school_rank
            )
          ) {
            throw new Error("data");
          }
          return formSchoolGrades;
        },
        CHUNKED_BATCH_SIZE
      ),
    [client]
  );
};

export const useAddToWaitListBulk = () => {
  const fetchPriorityTemplates = useFetchPriorityTemplates();
  const fetchSchoolFormsGradesTags = useFetchSchoolFormsTags();

  const [addToWaitlist] = useRemoteDataMutation<
    GQL.AddToWaitlistsBulk,
    GQL.AddToWaitlistsBulkVariables
  >(ADD_TO_WAITLIST_BULK);

  return useCallback(
    async (formSchools: FormSchool[]) => {
      const schoolFormsGradesTags = await fetchSchoolFormsGradesTags(
        formSchools
      );
      const gradeIds = uniq(
        schoolFormsGradesTags.map((item) => item.grade_id ?? "")
      );
      const priorityTemplates = await fetchPriorityTemplates(gradeIds);
      const findPriorityTemplate = (gradeId: uuid) =>
        (priorityTemplates.find(
          (gradePriorityTemplate) => gradePriorityTemplate.grade_id === gradeId
        )?.priority_template?.config as PriorityConfig | null | undefined) ??
        DEFAULT_PRIORITY_TEMPLATE;

      const results = await asyncMap(
        chunk(schoolFormsGradesTags, ADD_TO_WAITLIST_BULK_MAX_INPUT_SIZE),
        (schoolFormsGradesTagsChunk) =>
          addToWaitlist({
            variables: {
              delete_waitlists_where: {
                deleted_at: { _is_null: true },
                _or: schoolFormsGradesTagsChunk.map((item) => ({
                  form_id: { _eq: item.form_id },
                  school_id: { _eq: item.school_id },
                  grade_id: { _eq: item.grade_id },
                })),
              },
              insert_waitlists: schoolFormsGradesTagsChunk.map((item) => ({
                form_id: item.form_id,
                school_id: item.school_id,
                grade_id: item.grade_id,
                status: GQL.waitlist_status_enum.Waitlisted,
                priority_group: findPriorityGroupForTags(
                  item.form_school_rank?.tags.map(
                    (tag) => tag.enrollment_period_tag.name
                  ) ?? [],
                  findPriorityTemplate(item.grade_id ?? "").priorityGroups
                ),
              })),
              form_ids: schoolFormsGradesTagsChunk
                .map((entry) => entry.form_id)
                .filter(isNotNull),
              delete_offers_where: {
                deleted_at: { _is_null: true },
                _or: schoolFormsGradesTagsChunk.map((item) => ({
                  form_id: { _eq: item.form_id },
                  school_id: { _eq: item.school_id },
                  grade_id: { _eq: item.grade_id },
                })),
              },
              update_form_school_rank: {
                _or: schoolFormsGradesTagsChunk.map((item) => ({
                  form_id: { _eq: item.form_id },
                  school_id: { _eq: item.school_id },
                })),
                status: { _is_null: false },
              },
            },
          })
      );
      const errors = results.flatMap((r) => r.errors ?? []);
      const inserted = results.reduce(
        (sum, r) => sum + (r.data?.insert_waitlist?.affected_rows ?? 0),
        0
      );
      return {
        errors: errors.length ? errors : undefined,
        inserted,
      };
    },
    [fetchPriorityTemplates, fetchSchoolFormsGradesTags, addToWaitlist]
  );
};

export const useRemoveFromWaitlistBulk = () => {
  const [removeFromWaitlist] = useRemoteDataMutation<
    GQL.RemoveFromWaitlistsBulk,
    GQL.RemoveFromWaitlistsBulkVariables
  >(REMOVE_FROM_WAITLIST_BULK);

  return useCallback(
    async (formSchools: FormSchool[]) => {
      const results = await asyncMap(
        chunk(formSchools, REMOVE_FROM_WAITLIST_BULK_MAX_INPUT_SIZE),
        (formSchoolChunk) =>
          removeFromWaitlist({
            variables: {
              where: {
                _or: formSchoolChunk.map((item) => ({
                  form_id: { _eq: item.form_id },
                  school_id: { _eq: item.school_id },
                })),
              },
            },
          })
      );
      const errors = results.flatMap((r) => r.errors ?? []);
      const removed = results.reduce(
        (sum, r) => sum + (r.data?.update_waitlist?.affected_rows ?? 0),
        0
      );
      return {
        errors: errors.length ? errors : undefined,
        removed,
      };
    },
    [removeFromWaitlist]
  );
};
