import { Collapse, HStack, StackProps } from "@chakra-ui/react";

interface BannerProps {
  isCollapsed: boolean;
  backgroundColor: string;
}

export function Banner({
  isCollapsed,
  children,
  ...stackProps
}: BannerProps & StackProps) {
  return (
    <Collapse in={!isCollapsed} animateOpacity>
      <HStack
        width="100%"
        borderRadius="0.4em"
        padding="0.6em"
        paddingLeft="1em"
        {...stackProps}
      >
        {children}
      </HStack>
    </Collapse>
  );
}
