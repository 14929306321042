import { gql } from "@apollo/client";
import { ENROLLMENT_PERIOD_BASICS_FRAGMENT } from "src/operations/fragments/formTemplate";

export const GET_ENROLLMENT_PERIOD_BY_ID = gql`
  ${ENROLLMENT_PERIOD_BASICS_FRAGMENT}
  query GetEnrollment($id: uuid!) {
    enrollment_period_by_pk(id: $id) {
      id
      name
      active
      key
      form_templates_aggregate(where: { deleted_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ENROLLMENT_PERIODS = gql`
  ${ENROLLMENT_PERIOD_BASICS_FRAGMENT}
  query GetEnrollments(
    $organization_id: uuid
    $order_by: enrollment_period_order_by!
  ) {
    enrollment_period(
      order_by: [$order_by]
      where: {
        organization_id: { _eq: $organization_id }
        deleted_at: { _is_null: true }
      }
    ) {
      ...EnrollmentPeriodBasics
      form_templates_aggregate(where: { deleted_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`;
