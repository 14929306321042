import { Box, HStack, Spacer, Text } from "@chakra-ui/react";
import { Student, StudentAvatar } from "src/components/Layout/Avatar";
import { HorizontallyBoundedContainer } from "src/components/Layout/HorizontallyBoundedContainer";
import { WeglotBlock } from "src/plugins/weglot/components";
import { fullName } from "src/services/format";

export type StudentHeaderProps = {
  student: Student;
  enrollmentPeriodName: string;
};

export const StudentHeader = ({
  student,
  enrollmentPeriodName,
}: StudentHeaderProps) => (
  <Box as="header" position="sticky">
    <HorizontallyBoundedContainer>
      <HStack>
        <StudentAvatar size="xs" student={student} />
        <WeglotBlock skip={true}>
          <Text fontSize="sm" color="gray.600" fontWeight="600">
            {fullName(student)}
          </Text>
        </WeglotBlock>
        <Spacer />
        <Text fontSize="sm" color="gray.600">
          {enrollmentPeriodName}
        </Text>
      </HStack>
    </HorizontallyBoundedContainer>
  </Box>
);
