import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { isNotNull } from "src/services/predicates";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GradesConfigForm } from "./Form";
import { UPDATE_GRADE_CONFIG } from "./graphql/mutations";
import {
  GET_ORGANIZATIONS_BY_GRADES_CONFIG,
  GET_ORG_GRADES_CONFIG_BY_PK,
} from "./graphql/queries";
import { GradesConfigType, validate } from "./types";

export const Edit = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetOrgGradesConfigByPk,
    GQL.GetOrgGradesConfigByPkVariables
  >(GET_ORG_GRADES_CONFIG_BY_PK, {
    variables: { id: id },
  });

  const [updateGradeConfig, { remoteData: updatedGradeConfig }] =
    useRemoteDataMutation<
      GQL.UpdateGradeConfig,
      GQL.UpdateGradeConfigVariables
    >(UPDATE_GRADE_CONFIG);

  const handleSubmit = React.useCallback(
    async (gradesConfig: GradesConfigType) => {
      try {
        if (!validate(gradesConfig)) {
          throw new Error(
            "Grades config is invalid. Make sure all required fields are non-empty."
          );
        }
        const gradeConfigUpdates = gradesConfig.grades
          .filter((grade) => isNotNull(grade.id))
          .map((grade) => ({
            where: { id: { _eq: grade.id } },
            _set: {
              label: grade.label,
              order: grade.order,
              value: grade.value,
            },
          }));
        const newGradeConfigs = gradesConfig.grades
          .filter((grade) => grade.id === undefined)
          .map((grade) => ({
            organization_id: gradesConfig.organizationId,
            label: grade.label,
            order: grade.order,
            value: grade.value,
          }));
        await updateGradeConfig({
          variables: {
            grade_config_updates: gradeConfigUpdates,
            new_grade_configs: newGradeConfigs,
          },
          refetchQueries: [GET_ORGANIZATIONS_BY_GRADES_CONFIG],
        });
        toast({
          id: "UpdatedGradesConfig",
          title: "Grades config updated",
          isClosable: true,
          status: "success",
        });
        navigate(Url.Admin.GradesConfig.index());
      } catch (error: any) {
        console.error(error);
        toast({
          id: "UpdatedGradesConfig",
          title: "Error updating program group",
          description: error.message,
          isClosable: true,
          status: "error",
        });
      }
    },
    [navigate, toast, updateGradeConfig]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.organization_by_pk === null) {
          return <NotFound />;
        }
        return (
          <GradesConfigForm
            initialGradesConfig={{
              organizationId: data.organization_by_pk.id,
              grades: data.organization_by_pk.grade_configs.map(
                (gradeConfig) => ({
                  ...gradeConfig,
                  value: gradeConfig.value ?? undefined,
                })
              ),
            }}
            onSubmit={handleSubmit}
            submitting={updatedGradeConfig.isLoading()}
          />
        );
      }}
    </GQLRemoteDataView>
  );
};
