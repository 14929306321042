import { Flex, Portal } from "@chakra-ui/react";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import {
  GET_GRADES_CONFIG_BY_ORGANIZATION,
  GET_QUESTION_TYPES_BY_ORGANIZATION
} from "../../graphql/queries";
import { EditFormTemplateBackButton } from "../components/BackButton";
import { EditFormTemplateProvider } from "./context";
import { FormButtons } from "./FormButtons";
import { Tab } from "./Tab";

export const ContentTab: React.FC<{}> = () => {
  const {
    organizationId,
    formTemplate,
    verificationOptions,
    actionBarRef: submitButtonRef
  } = useOutletContext<FormTemplateOutletContext>();

  const { remoteData: gradesConfigData } = useRemoteDataQuery<
    GQL.GetGradesConfigByOrganization,
    GQL.GetGradesConfigByOrganizationVariables
  >(GET_GRADES_CONFIG_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId
    },
    fetchPolicy: "network-only",
    skip: !organizationId
  });

  const { remoteData: questionTypesRemoteData } = useRemoteDataQuery<
    GQL.GetQuestionTypesByOrganization,
    GQL.GetQuestionTypesByOrganizationVariables
  >(GET_QUESTION_TYPES_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId
    },
    fetchPolicy: "network-only",
    skip: !organizationId
  });

  return (
    <EditFormTemplateProvider
      formTemplate={formTemplate}
      customQuestionTypes={
        questionTypesRemoteData.hasData()
          ? questionTypesRemoteData.data.custom_question_type
          : []
      }
    >
      <Tab
        organizationId={organizationId}
        verificationOptions={verificationOptions}
        gradesConfig={
          gradesConfigData.hasData() ? gradesConfigData.data.grade_config : []
        }
      />
      <Portal containerRef={submitButtonRef}>
        <Flex justifyContent="space-between">
          <EditFormTemplateBackButton />
          <FormButtons formTemplate={formTemplate} />
        </Flex>
      </Portal>
    </EditFormTemplateProvider>
  );
};
