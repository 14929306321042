import { useCallback } from "react";
import useRankedSchools from "src/hooks/useSchoolRank";
import { RankedSchool } from "src/services/formTemplate/preRankingSection";
import { deletedRankedSchoolsVar } from "../store";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { INSERT_FORM_SCHOOLS_RANK } from "../graphql/mutations";
import { ApolloError } from "@apollo/client";
import * as RD from "src/types/remoteData";
import * as AF from "src/types/formTemplate";

type Props = {
  formId: string;
  schoolRanksRemoteData: RD.RemoteData<ApolloError, GQL.GetSchoolsRank>;
  schoolRankingSection: AF.Section<AF.WithId> | undefined;
  refetchSchoolRanks: () => Promise<unknown>;
};
export const useDeleteRankedSchools = ({
  formId,
  schoolRanksRemoteData,
  schoolRankingSection,
  refetchSchoolRanks,
}: Props) => {
  const {
    getDeletedRanks,
    getUpsertedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
  } = useRankedSchools([]);

  const [insertSchoolRank] = useRemoteDataMutation<
    GQL.InsertFormSchoolsRank,
    GQL.InsertFormSchoolsRankVariables
  >(INSERT_FORM_SCHOOLS_RANK);

  // When grades change, we need to delete the schools that are no longer eligible, this is a workaround
  // for a weird bug where the useRankedSchools hook doesn't show the correct state after the parent navigate
  // from schoolRanksStep to preRankingStep.
  const getDeleteSchoolsAfterGradesChange = useCallback(
    (schoolsToRemove: uuid[]): GQL.form_school_rank_bool_exp => ({
      _or: schoolsToRemove.map((schoolId) => ({
        form_id: { _eq: formId },
        schools_ranking_section_id: { _eq: schoolRankingSection?.id },
        school_id: { _eq: schoolId },
      })),
    }),
    [formId, schoolRankingSection?.id]
  );

  const setSchoolRanks = useCallback(
    async (schoolIds: string[], schoolsToRemove?: string[]) => {
      const upsertedSchoolRanks = getUpsertedRanks(
        schoolIds.map((schoolId, rank) => ({
          form_id: formId,
          schools_ranking_section_id: schoolRankingSection?.id ?? "",
          school_id: schoolId,
        }))
      );

      const deletedSchoolRanks = schoolsToRemove
        ? getDeleteSchoolsAfterGradesChange(schoolsToRemove)
        : getDeletedRanks(upsertedSchoolRanks);

      const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
      const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);

      await insertSchoolRank({
        variables: {
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
          deleted_school_ranks: deletedSchoolRanks,
          upserted_school_ranks: upsertedSchoolRanks,
        },
      });
      await refetchSchoolRanks();
    },
    [
      formId,
      getDeleteSchoolsAfterGradesChange,
      getDeletedOffers,
      getDeletedRanks,
      getDeletedWaitlists,
      getUpsertedRanks,
      insertSchoolRank,
      refetchSchoolRanks,
      schoolRankingSection,
    ]
  );

  const deleteRankedSchools = useCallback(
    async (rankedSchools: readonly RankedSchool[], schoolsToRemove: uuid[]) => {
      const updatedSelectedSchoolIds = rankedSchools
        .filter((s) => !schoolsToRemove.find((id) => s.school.id === id))
        .map((s) => s.school.id);
      await setSchoolRanks(updatedSelectedSchoolIds, schoolsToRemove);

      const currentDeletedRankedSchools = deletedRankedSchoolsVar();
      deletedRankedSchoolsVar(
        currentDeletedRankedSchools.concat(schoolsToRemove)
      );
    },
    [setSchoolRanks]
  );

  return { deleteRankedSchools };
};
