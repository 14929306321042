import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

export type CardProps = {
  accentColor?: string;
  showBorder?: boolean;
  children: React.ReactNode;
  useFlex?: boolean;
} & BoxProps;
export const Card: React.FC<CardProps> = ({
  accentColor,
  children,
  showBorder = false,
  useFlex: flex = false,
  padding = 4,
  ...props
}) => {
  const borderProps: BoxProps = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "gray.300",
  };
  return (
    <Box
      {...(accentColor
        ? {
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            borderLeftColor: accentColor,
          }
        : {})}
      {...(flex ? { display: "flex", flexDirection: "column", gap: "3" } : {})}
      borderRadius="md"
      padding={padding}
      backgroundColor="white"
      {...(showBorder ? borderProps : {})}
      {...props}
    >
      {children}
    </Box>
  );
};
