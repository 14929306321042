import { Flex, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { QUESTION_TYPE_TEXT } from "src/components/Form/QuestionForm/constants";
import {
  FormType,
  getQuestionTypeFormValue,
  QuestionTypeSchema,
} from "src/components/Form/QuestionForm/formik";
import { useEditFormTemplateContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content";
import * as AF from "src/types/formTemplate";

export type Props = {
  questionId: uuid;
  isDisabled?: boolean;
  error?: string | undefined;
};

type QuestionTypeOption = {
  label: string;
  value: string;
};

/**
 * There is a tight coupling between our static AF.QuestionType and our Avela Form framework.
 * Custom Question Types (CQTs) fit into that tight coupling by creating an additional type
 * AF.CustomQuestionType ("CustomQuestion").
 *
 * CQTs are user defined and so we navigate the tight coupling of static question types by
 * using AF.CustomQuestionType as a shim to assign or determine the CQT associated with a question.
 */
const BASE_QUESTION_TYPE_OPTIONS = AF.QuestionTypeList.filter((type) => {
  // AF.CustomQuestionType is not displayed to the user
  return type !== AF.CustomQuestionType;
}).map((type) => {
  return { label: QUESTION_TYPE_TEXT[type].label, value: type };
});

export const QuestionType: FC<Props> = ({
  isDisabled = true,
  error,
  questionId,
}) => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormType>();
  const { customQuestionTypes = [] } = useEditFormTemplateContext();

  const questionTypeOptions: QuestionTypeOption[] = useMemo(() => {
    const customQuestionTypeOptions = customQuestionTypes?.map((cqt) => {
      return {
        label: cqt.name,
        value: cqt.id,
      };
    });

    return [...BASE_QUESTION_TYPE_OPTIONS, ...customQuestionTypeOptions];
  }, [customQuestionTypes]);

  const onChangeStandardQuestionType = useCallback(
    (newQuestionType: AF.QuestionType) => {
      const questionTypeFieldForId = ["questionTypes", questionId].join(".");
      setFieldTouched(questionTypeFieldForId, true);
      setFieldValue(questionTypeFieldForId, newQuestionType);
    },
    [questionId, setFieldTouched, setFieldValue]
  );

  const onChangeCustomQuestionType = useCallback(
    (newQuestionType: uuid) => {
      onChangeStandardQuestionType(AF.CustomQuestionType);
      const customQuestionTypeFieldForId = [
        "customQuestionTypeIds",
        questionId,
      ].join(".");
      setFieldTouched(customQuestionTypeFieldForId, true);
      setFieldValue(customQuestionTypeFieldForId, newQuestionType);
    },
    [onChangeStandardQuestionType, questionId, setFieldTouched, setFieldValue]
  );

  let questionTypeValue: AF.QuestionType | uuid = getQuestionTypeFormValue(
    questionId,
    values
  );
  if (questionTypeValue === AF.CustomQuestionType) {
    questionTypeValue = values.customQuestionTypeIds[questionId] ?? "";
  }

  return (
    <Flex direction="column" gap="5">
      <FormControl isInvalid={error !== undefined}>
        <FormLabel htmlFor={`questionType${questionId}`}>
          Question type
        </FormLabel>
        <Select
          id={`questionType${questionId}`}
          isDisabled={isDisabled}
          value={questionTypeValue}
          onChange={(event) => {
            const result = QuestionTypeSchema.safeParse(event.target.value);
            if (result.success) {
              onChangeStandardQuestionType(result.data);
            } else {
              onChangeCustomQuestionType(event.target.value);
            }
          }}
        >
          {questionTypeOptions.map((option) => {
            const { value, label } = option;
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </Flex>
  );
};
