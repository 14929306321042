import * as Turf from "@turf/helpers";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { GoogleMap, PolygonF } from "@react-google-maps/api";
import React from "react";
import { SchoolBoundaries } from "src/components/Boundary/schemas";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";

type Props = { boundaries: SchoolBoundaries };
export const ViewSchoolBoundaries: React.FC<Props> = ({ boundaries }) => {
  const { isLoaded } = useLoadGooglePlacesScript();
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Box fontSize="xs" color="gray.800" flex="1" textAlign="left">
            Boundaries
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                width: "500px",
                height: "500px",
              }}
              zoom={10}
              onLoad={(map) => {
                const bounds = calculateBounds(
                  boundaries.boundaries.map((b) => b.geometry)
                );
                map.fitBounds(bounds);
              }}
            >
              {boundaries.boundaries.map((boundary, index) => {
                try {
                  const path = geometryToPath(boundary.geometry);
                  return <PolygonF path={path} key={index} />;
                } catch (err) {
                  console.error(err);
                  return null;
                }
              })}
            </GoogleMap>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

function calculateBounds(
  geometries: Turf.Geometry[]
): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();

  geometries.forEach((geometry) => {
    const path = geometryToPath(geometry);
    path.forEach((point) => bounds.extend(point));
  });

  return bounds;
}

function geometryToPath(geometry: Turf.Geometry): google.maps.LatLngLiteral[] {
  const { type, coordinates } = geometry;

  switch (type) {
    case "Polygon":
      const polygon = (coordinates as Turf.Position[][])[0];
      if (polygon === undefined) {
        throw new Error("Invalid polygon");
      }
      return polygon.map(([lng, lat]) => {
        if (!lat || !lng) {
          throw new Error("Invalid lat or lng");
        }
        return {
          lat,
          lng,
        };
      });
    case "MultiPolygon":
      const multiPolygon = (coordinates as Turf.Position[][][])[0]?.[0];
      if (!multiPolygon) {
        throw new Error("Invalid multipolygon");
      }
      return multiPolygon.map(([lng, lat]) => {
        if (!lat || !lng) {
          throw new Error("Invalid lat or lng");
        }
        return {
          lat,
          lng,
        };
      });
    default:
      throw new Error(`Unsupported geometry type: ${type}`);
  }
}
