import * as z from "zod";
import { WebhookSchema } from "./base";

export const UpdateWebhookRequestSchema = WebhookSchema.omit({
  pk: true,
  events: true,
  organization_id: true,
}).strict();

export type UpdateWebhookRequest = z.infer<typeof UpdateWebhookRequestSchema>;

export const UpdateWebhookResponseSchema = z.object({});

export type UpdateWebhookResponse = z.infer<typeof UpdateWebhookResponseSchema>;

export const PatchWebhookRequestSchema = z
  .object({
    is_active: z.boolean().optional(),
  })
  .strict();

export type PatchWebhookRequest = z.infer<typeof PatchWebhookRequestSchema>;
