import { Heading } from "@chakra-ui/react";
import React from "react";
import { ApolloError } from "src/components/Feedback/ApolloError";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { OrganizationError, useOrganization } from "src/hooks/useOrganization";

export function Index() {
  const remoteData = useOrganization();

  const renderError = React.useCallback((error: OrganizationError) => {
    switch (error.kind) {
      case "NotFoundError":
        return <NotFound />;
      case "ServerError":
        return <ApolloError error={error.error} />;
    }
  }, []);

  return (
    <RemoteDataView remoteData={remoteData} error={renderError}>
      {(org) => <Heading>{org.name} Admin Portal</Heading>}
    </RemoteDataView>
  );
}

export * as Capacities from "./capacities";
export { Layout } from "./Layout";
export * as MatchTemplates from "./matchTemplates";
export * as Waitlists from "./waitlists";
