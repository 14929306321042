import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { formatUuid } from "src/services/id";

interface Props {
  id?: string;
  title: string;
  upperTitle?: string;
  actionButton?: React.ReactNode;
  actionBar?: React.ReactNode;
  badge?: React.ReactNode;
}
export const AdminHeading: React.FC<Props> = ({
  id,
  title,
  upperTitle,
  actionButton,
  actionBar,
  badge,
}) => {
  return (
    <Flex direction="column" gap={2} w="100%">
      {upperTitle && (
        <Text fontSize="md" fontWeight="500" color="gray.500">
          {upperTitle}
        </Text>
      )}

      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Flex gap={2}>
            <Heading fontSize="2xl" fontWeight="600">
              {title}
            </Heading>
            {badge}
          </Flex>
          {id && (
            <Text fontSize="lg" textColor="gray.500" fontWeight="400">
              ID: {formatUuid(id)}
            </Text>
          )}
        </Flex>
        {actionButton}
      </Flex>

      {actionBar}
    </Flex>
  );
};
