import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RiCheckLine, RiCloseLine, RiInformationLine } from "react-icons/ri";
import { AdminQuestionAnswer } from "src/components/Form/QuestionAnswer";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { UPSERT_VERIFICATION_STATUS } from "src/scenes/orgAdmin/forms/graphql/mutations";
import {
  GET_FORM_VIEW_BY_ID,
  GET_VERIFICATIONS_FOR_ADMIN,
} from "src/scenes/orgAdmin/forms/graphql/queries";
import { Answer, Question } from "src/services/formTemplate";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";

type Props = {
  form: GQL.GetFormViewById_form_by_pk;
  questions: AF.Question<AF.WithId>[];
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  custom_question_answer_bank_relationships?: GQL.CustomQuestionAnswerBankRelationshipsFragment[];
  rankedSchoolIds: uuid[];
  previousFormSchoolIds: uuid[];
};
const tooltipVerifyText =
  "If not, input the right value if you know it or reach out to the family for the correct information";
const tooltipLockedText =
  "  This information has been verified and can no longer be edited by a parent/guardian";

export const QuestionList: React.FC<Props> = ({
  form,
  questions,
  form_answers,
  grades_answers,
  address_answers,
  custom_question_answers,
  custom_question_answer_bank_relationships,
  rankedSchoolIds,
  previousFormSchoolIds,
}) => {
  const isParentUser = useRequiredHasuraRoles([HasuraRole.USER]);
  const userPermissions = useUserPermissions();

  const answers = useMemo(
    () =>
      Answer.getFormikInitialValues(
        questions,
        form_answers,
        grades_answers,
        address_answers,
        custom_question_answers,
        custom_question_answer_bank_relationships
      ),
    [
      questions,
      form_answers,
      grades_answers,
      address_answers,
      custom_question_answers,
      custom_question_answer_bank_relationships,
    ]
  );
  const completeQuestions = useMemo(
    () =>
      Question.groupByVerifications(
        Question.getCompleteApplicableQuestions(questions, answers, {
          rankedSchoolIds,
          previousFormSchoolIds,
        })
      ),
    [questions, answers, rankedSchoolIds, previousFormSchoolIds]
  );

  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: "Reset verification?",
    body: "Resetting this status will set the verification back to a pending state.",
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, reset",
      colorScheme: "red",
    },
    translate: true,
  });

  const toast = useAvelaToast();

  const [upsertVerification, { remoteData: verificationRefetchData }] =
    useRemoteDataMutation<
      GQL.UpsertVerificationStatus,
      GQL.UpsertVerificationStatusVariables
    >(UPSERT_VERIFICATION_STATUS);

  const verificationHandler = async (
    formId: uuid,
    verificationId: uuid,
    verificationLabel: string,
    status: GQL.verification_status_enum
  ) => {
    const refetchQueries = [GET_FORM_VIEW_BY_ID, GET_VERIFICATIONS_FOR_ADMIN];

    try {
      if (
        status === GQL.verification_status_enum.Pending &&
        !(await confirm())
      ) {
        return;
      }

      await upsertVerification({
        variables: {
          form_id: formId,
          form_verification_id: verificationId,
          verification_status: status,
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
      toast({ title: `${verificationLabel} verification updated` });
    } catch (error: unknown) {
      console.error(error);
      toast.error({
        title: `Error updating ${verificationLabel} verification`,
      });
    }
  };

  return (
    <VStack gap={2} align="stretch">
      {confirmationDialog}
      {completeQuestions.map(({ verification, questions }, index) => {
        const children = questions.map((question, qidx) => (
          <Box key={qidx}>
            <AdminQuestionAnswer
              marginBottom={4}
              question={question}
              answer={answers[question.id]}
              formId={form.id}
            />
          </Box>
        ));

        if (!verification || isParentUser) {
          return children;
        }

        const verificationResult = form.form_verification_results.find(
          (result) => result.form_verification.id === verification.id
        );
        const isVerified =
          verificationResult?.verification_status ===
          GQL.verification_status_enum.Verified;

        const isRejected =
          verificationResult?.verification_status ===
          GQL.verification_status_enum.Rejected;

        return (
          <Flex
            key={index}
            direction="column"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            minW="30rem"
            width="75%"
            p={4}
            gap={4}
            alignItems="flex-start"
          >
            <Flex gap={2}>
              <Tag
                size="sm"
                variant="solid"
                color="blackAlpha.900"
                bg="gray.200"
                lineHeight={1.3}
              >
                {verification?.label} verification
              </Tag>
              <Box
                visibility={!isVerified ? "hidden" : undefined}
                bgColor="gray.500"
                borderRadius="2"
                paddingLeft={1}
                paddingRight={1}
                fontSize="sm"
                fontWeight="700"
                textColor="white"
              >
                <Tooltip
                  label={<Text align="center">{tooltipLockedText}</Text>}
                  placement="top"
                  hasArrow
                  arrowSize={15}
                  gutter={20}
                  minW="22rem"
                >
                  LOCKED
                </Tooltip>
              </Box>
            </Flex>
            {children}
            <Flex w="100%" gap={1}>
              <HStack>
                <Text lineHeight="2">
                  Is the {verification.label.toLocaleLowerCase()} information
                  correct?
                </Text>
                <Tooltip
                  label={<Text align="center">{tooltipVerifyText}</Text>}
                  placement="top"
                  hasArrow
                  arrowSize={15}
                  gutter={20}
                  minW="22rem"
                >
                  <IconButton
                    size="sm"
                    aria-label={tooltipVerifyText}
                    icon={<RiInformationLine />}
                    variant="ghost"
                  />
                </Tooltip>
              </HStack>
              <Spacer width={6} />
              <HStack>
                <Button
                  size="sm"
                  leftIcon={<RiCloseLine />}
                  colorScheme="gray"
                  color="gray.700"
                  variant={isRejected ? "solid" : "ghost"}
                  _disabled={{}}
                  isDisabled={!userPermissions.hasOne("form:update")}
                  onClick={() =>
                    verificationHandler(
                      form.id,
                      verification.id,
                      verification.label,
                      isRejected
                        ? GQL.verification_status_enum.Pending
                        : GQL.verification_status_enum.Rejected
                    )
                  }
                  isLoading={verificationRefetchData.isLoading()}
                >
                  No
                </Button>
                <Spacer width={0} />
                <Button
                  size="sm"
                  leftIcon={<RiCheckLine />}
                  colorScheme="gray"
                  color="gray.700"
                  variant={isVerified ? "solid" : "ghost"}
                  _disabled={{}}
                  isDisabled={!userPermissions.hasOne("form:update")}
                  onClick={() =>
                    verificationHandler(
                      form.id,
                      verification.id,
                      verification.label,
                      isVerified
                        ? GQL.verification_status_enum.Pending
                        : GQL.verification_status_enum.Verified
                    )
                  }
                  isLoading={verificationRefetchData.isLoading()}
                >
                  Yes
                </Button>
              </HStack>
            </Flex>
          </Flex>
        );
      })}
    </VStack>
  );
};
