import { Button, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { CreateFormTemplateDialog } from "./CreateFormTemplateDialog";
import { CreateFromScratchDialog } from "./CreateFromScratchDialog";

export const CreateNewFormTemplateButton: React.FC = () => {
  const {
    isOpen: createFormTemplateIsOpen,
    onOpen: onOpenCreateFormTemplate,
    onClose: onCloseCreateFormTemplate
  } = useDisclosure();

  const {
    isOpen: createFromScratchIsOpen,
    onOpen: onOpenCreateFromScratch,
    onClose: onCloseCreateFromScratch
  } = useDisclosure();

  const handleCreateFromScratch = useCallback(() => {
    onOpenCreateFromScratch();
    onCloseCreateFormTemplate();
  }, [onCloseCreateFormTemplate, onOpenCreateFromScratch]);

  const handleCancelCreateFromScratch = useCallback(() => {
    onOpenCreateFormTemplate();
    onCloseCreateFromScratch();
  }, [onCloseCreateFromScratch, onOpenCreateFormTemplate]);

  return (
    <>
      <Button onClick={onOpenCreateFormTemplate}>Create new form</Button>
      <CreateFormTemplateDialog
        isOpen={createFormTemplateIsOpen}
        onCancel={onCloseCreateFormTemplate}
        onCreateFromScratch={handleCreateFromScratch}
      />
      <CreateFromScratchDialog
        isOpen={createFromScratchIsOpen}
        onCancel={handleCancelCreateFromScratch}
      />
    </>
  );
};
