import { useField } from "formik";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { UPSERT_GRADES_ANSWER } from "../graphql/mutations";
import { SingleSelectChangeProps } from "./useFormQuestion";

export function useGradesQuestion(
  formId: uuid,
  question: AF.Question<AF.WithId>
) {
  const [upsertGradesAnswer] = useRemoteDataMutation<
    GQL.UpsertGradesAnswer,
    GQL.UpsertGradesAnswerVariables
  >(UPSERT_GRADES_ANSWER);
  const [, , helpers] = useField<FormikFieldValue>(question.id);

  return {
    saveGradesAnswer: async (
      initialValue: FormikFieldValue,
      value: FormikFieldValue,
      confirmGradesChange?: (value: SingleSelectChangeProps) => Promise<boolean>
    ): Promise<boolean> => {
      if (typeof value !== "string" && value !== undefined) {
        console.error(
          `Expecting a "string" from grades question ${question.id}`
        );
        return false;
      }

      if (question.type !== "Grades") {
        console.error(
          `Expecting Grades type, but got a ${question.type} instead`
        );
        return false;
      }

      const options = question.options;
      const beforeValue = initialValue;
      const before = options.find((o) => o.id === beforeValue);
      const after = options.find((o) => o.id === value);

      if (
        confirmGradesChange &&
        !(await confirmGradesChange({ before, after }))
      ) {
        helpers.setTouched(false);
        helpers.setValue(beforeValue);
        return false;
      }

      await upsertGradesAnswer({
        variables: {
          form_id: formId,
          question_id: question.id,
          grade_config_id: value === "" ? null : value,
        },
      });
      return true;
    },
  };
}
