import { Badge, Box, Icon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface InfoBadgeProps {
  status: InfoBadgeMetadata;
  variant?: "long" | "short";
}

export interface InfoBadgeMetadata {
  label: string;
  longLabel: string;
  icon?: IconType;
  variant?: string;
  colorScheme: string;
}

export const InfoBadge: React.FC<InfoBadgeProps> = ({
  status,
  variant = "short",
}) => {
  let label: string;
  switch (variant) {
    case "long":
      label = status.longLabel;
      break;

    case "short":
      label = status.label;
      break;
  }
  return (
    <Box display="flex" alignItems="flex-start">
      <Badge
        colorScheme={status.colorScheme}
        fontSize="xs"
        variant={status.variant ?? "subtle"}
      >
        {status.icon && <Icon aria-hidden={true} as={status.icon} />}
        {label.toUpperCase()}
      </Badge>
    </Box>
  );
};
