import _ from "lodash";
import { Glossary, Level } from "src/hooks/useGlossary/types";
import * as GQL from "src/types/graphql";

/**
 * Helper functions
 */
function getLevel(gql: GQL.GetGlossary["glossary_by_organization"][0]): Level {
  if (gql.enrollment_period_id !== null) {
    return "enrollmentPeriod";
  }

  if (gql.organization_id !== null) {
    return "organization";
  }

  return "default";
}
function getLevelUuid(
  gql: GQL.GetGlossary["glossary_by_organization"][0]
): string {
  if (gql.enrollment_period_id !== null) {
    return gql.enrollment_period_id;
  }

  if (gql.organization_id !== null) {
    return gql.organization_id;
  }

  return "default";
}
export function fromGQL(gql: GQL.GetGlossary): Glossary {
  return gql.glossary_by_organization.reduce((acc, glossaryByOrganization) => {
    if (
      !glossaryByOrganization.glossary?.type ||
      !glossaryByOrganization.default_singular ||
      !glossaryByOrganization.default_plural ||
      !glossaryByOrganization.alias_singular ||
      !glossaryByOrganization.alias_plural
    ) {
      console.warn(`Invalid glossary: ${glossaryByOrganization}`);
      return acc;
    }
    const level = getLevel(glossaryByOrganization);
    const levelUuid = getLevelUuid(glossaryByOrganization);

    return {
      ...acc,
      [glossaryByOrganization.glossary.type]: {
        ...acc[glossaryByOrganization.glossary.type],
        [levelUuid]: {
          level: level,
          default: {
            singular: glossaryByOrganization.default_singular,
            plural: glossaryByOrganization.default_plural,
          },
          alias: {
            singular: glossaryByOrganization.alias_singular,
            plural: glossaryByOrganization.alias_plural,
          },
        },
      },
    };
  }, {} as Glossary);
}

/**
 * Replace words while preserving capitalization:
 * - first letter is uppercase, e.g.: School -> District
 * - all upper case words., e.g.: SCHOOL -> DISTRICT
 * - use all lower case for the rest
 */
export function replaceWords(
  text: string,
  words: { oldWord: string; newWord: string }[]
): string {
  function replaceWord(str: string, oldWord: string, newWord: string) {
    const regex = new RegExp(oldWord, "gi");
    return str.replace(regex, (match, offset) => {
      if (match === match.toUpperCase()) {
        return newWord.toUpperCase();
      } else if (match === match.toLowerCase()) {
        return newWord.toLowerCase();
      } else if (lowercaseFirstLetter(match) === match.toLowerCase()) {
        return newWord[0]?.toUpperCase() + newWord.slice(1);
      } else {
        return newWord.toLowerCase();
      }
    });
  }

  function lowercaseFirstLetter(str: string): string {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toLowerCase();
    return firstLetter + str.slice(1);
  }

  // In case some words are substrings of others, start with longest words first.
  return _.sortBy(words, (word) => -word.oldWord.length).reduce(
    (replacedText: string, { oldWord, newWord }): string => {
      return replaceWord(replacedText, oldWord, newWord);
    },
    text
  );
}
