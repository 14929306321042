import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  getGlossaryTypeError,
  getGlossaryTypeTouched,
} from "src/scenes/admin/glossaries/services";
import { FormType } from "src/scenes/admin/glossaries/types";
import * as Format from "src/services/format";
import * as GQL from "src/types/graphql";

type GlossaryTypeFormProps = {
  glossaryType: GQL.glossary_type_enum;
  onSingularChange: (label: GQL.glossary_type_enum, value: string) => void;
  onPluralChange: (label: GQL.glossary_type_enum, value: string) => void;
};
export const GlossaryTypeForm = ({
  glossaryType,
  onSingularChange,
  onPluralChange,
}: GlossaryTypeFormProps) => {
  const formik = useFormikContext<FormType>();
  const onSingularChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSingularChange(glossaryType, event.target.value);
    },
    [glossaryType, onSingularChange]
  );

  const onPluralChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onPluralChange(glossaryType, event.target.value);
    },
    [glossaryType, onPluralChange]
  );

  const errors = getGlossaryTypeError(glossaryType, formik.errors);
  const touched = getGlossaryTypeTouched(glossaryType, formik.touched);

  return (
    <Flex gap="4">
      <Flex direction="column" minW="10rem">
        <FormLabel>Label</FormLabel>
        <Text display="flex" height="2.5rem" alignItems="center">
          {Format.capitalizeFirstLetter(glossaryType)}
        </Text>
      </Flex>
      <FormControl
        width="auto"
        isInvalid={errors.singular !== undefined && touched.singular}
      >
        <FormLabel>Custom label (singular)</FormLabel>
        <Input
          onChange={onSingularChangeHandler}
          value={formik.values.labels[glossaryType]?.singular ?? ""}
        />
        <FormErrorMessage>{errors.singular}</FormErrorMessage>
      </FormControl>
      <FormControl
        width="auto"
        isInvalid={errors.plural !== undefined && touched.plural}
      >
        <FormLabel>Custom label (plural)</FormLabel>
        <Input
          onChange={onPluralChangeHandler}
          value={formik.values.labels[glossaryType]?.plural ?? ""}
        />
        <FormErrorMessage>{errors.plural}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
