import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
  SpaceProps,
  UnorderedList,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCombobox, useMultipleSelection } from "downshift";
import React, { useCallback, useRef } from "react";
import { ClosableTag } from "../Feedback/ClosableTag";

interface MultiSelectMenuProps<T> {
  size: "sm" | "md" | "lg";
  options: T[];
  initialValues: T[];
  onChange: (values: T[]) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  labelAttr?: string;
  renderDropdownItem: (item: T) => React.ReactElement;
  placeholder?: string;
  onRemoveTag?: (item: T) => void;
  marginY?: SpaceProps["marginY"];
}

interface BaseGenericType {
  id: string;
  [key: string]: any;
}
export function MultiSelectInput<T extends BaseGenericType>({
  size,
  options,
  initialValues,
  onChange,
  isLoading,
  labelAttr: labelAttrProp,
  renderDropdownItem,
  placeholder = "Select multiple",
  onRemoveTag,
  marginY = 2,
  isDisabled = false,
}: MultiSelectMenuProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: ref,
    handler: () => closeMenu(),
  });
  const [inputValue, setInputValue] = React.useState<string>("");
  const [selectedItems, setSelectedItems] =
    React.useState<(T | null | undefined)[]>(initialValues);
  const labelAttr = labelAttrProp || "label";

  React.useEffect(() => {
    setSelectedItems(initialValues);
  }, [initialValues]);

  const getFilteredItemsForDropdown = useCallback(
    (selectedItems: T[], inputValue: string) => {
      const lowerCasedInputValue = inputValue?.toLowerCase() || "";
      return options.filter(function filterItemsForDropdown(item) {
        return (
          !selectedItems.find(
            (selectedItem) => selectedItem[labelAttr] === item[labelAttr]
          ) &&
          (item[labelAttr]?.toLowerCase().includes(lowerCasedInputValue) ||
            item[labelAttr]?.toLowerCase().includes(lowerCasedInputValue))
        );
      });
    },
    [options, labelAttr]
  );

  const itemsForDropdown: T[] = React.useMemo(() => {
    const notNullItems = selectedItems.filter((i): i is T => !!i);
    return getFilteredItemsForDropdown(notNullItems, inputValue);
  }, [getFilteredItemsForDropdown, selectedItems, inputValue]);

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(
            newSelectedItems?.filter((i): i is T => i !== undefined) ?? []
          );
          break;
        default:
          break;
      }
    },
  });
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    items: itemsForDropdown,
    itemToString(item) {
      return item ? item.title : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: true,
          };
        default:
          return changes;
      }
    },
    async onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          const nextItems: T[] = [...selectedItems].filter((i): i is T => !!i);
          if (newSelectedItem) {
            nextItems.push(newSelectedItem);
          }
          onChange(nextItems);
          setSelectedItems(nextItems);
          break;

        case useCombobox.stateChangeTypes.InputChange:
          openMenu();
          setInputValue(newInputValue ?? "");
          break;

        case useCombobox.stateChangeTypes.InputBlur:
          closeMenu();
          break;

        default:
          break;
      }
    },
  });

  const openMenuUnlessDisabled = useCallback(() => {
    if (!isDisabled) {
      openMenu();
    }
  }, [isDisabled, openMenu]);

  return (
    <Box padding="1px" ref={ref}>
      <Flex gap={1} marginY={marginY} flexWrap="wrap">
        {selectedItems.map(function renderSelectedItem(
          selectedItemForRender,
          index
        ) {
          return (
            <Box
              key={`selected-item-${index}`}
              {...getSelectedItemProps({
                selectedItem: selectedItemForRender,
                index,
              })}
              display="inline-block"
            >
              <ClosableTag
                size={size}
                label={
                  selectedItemForRender && selectedItemForRender[labelAttr]
                }
                isDisabled={isDisabled}
                onClose={(e) => {
                  e.stopPropagation();

                  const nextItems: T[] = selectedItems.filter(
                    (i): i is T => i !== selectedItemForRender && !!i
                  );

                  if (onRemoveTag && selectedItemForRender) {
                    onRemoveTag(selectedItemForRender);
                  } else {
                    onChange(nextItems);
                  }
                  setSelectedItems(nextItems);
                }}
              />
            </Box>
          );
        })}
      </Flex>
      <Box position="relative">
        <InputGroup
          onClick={openMenuUnlessDisabled}
          onFocus={openMenuUnlessDisabled}
          display="flex"
          justifyContent="center"
        >
          <InputLeftElement children={<SearchIcon color="gray.500" />} />

          <Input
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            value={
              getInputProps(getDropdownProps({ preventKeyAction: isOpen }))
                .value || ""
            }
            placeholder={placeholder}
            size={size}
          />

          <InputRightElement children={<ChevronDownIcon color="gray.500" />} />
        </InputGroup>

        <UnorderedList
          {...getMenuProps()}
          display={isOpen ? "block" : "none"}
          position="absolute"
          background="white"
          marginLeft={0}
          zIndex="200"
          boxShadow="lg"
          width="100%"
          marginTop={1}
          overflow="scroll"
          maxHeight="280px"
        >
          {itemsForDropdown.map((item, index) => (
            <ListItem
              key={item.id}
              backgroundColor={highlightedIndex === index ? "gray" : "white"}
              listStyleType="none"
              {...getItemProps({ item, index })}
            >
              <Flex align="center" minHeight={12} padding={2}>
                {renderDropdownItem(item)}
              </Flex>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  );
}
