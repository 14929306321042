import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { FormSchool } from "../types";
import { RunMatchDialog } from "./RunMatchDialog";

interface RunMatchButtonProps {
  selectedFormSchools: FormSchool[];
}

export const RunMatch: React.FC<RunMatchButtonProps> = ({
  selectedFormSchools,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <MenuItem
        textColor="gray.700"
        fontWeight="400"
        fontSize="sm"
        onClick={onOpen}
        isDisabled={!selectedFormSchools.length}
      >
        Run lottery
      </MenuItem>
      <RunMatchDialog
        schoolForms={selectedFormSchools}
        isOpen={isOpen}
        onCancel={onClose}
      />
    </>
  );
};
