import { Box, Icon, Tooltip } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { RiEyeOffLine } from "react-icons/ri";
import { ColumnId } from "./constants";

type Row = { form: { is_hidden_from_parent: boolean | null } | null };
export function buildVisibilityColumnDef<T extends Row>(): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form?.is_hidden_from_parent,
    id: ColumnId.Visibility,
    header: "",
    size: 20, // We can't precisely control the width of the column, so this will only "nudge" the column to use less space.
    cell: (props) =>
      props.row.original.form?.is_hidden_from_parent === true ? (
        <Tooltip label="Not visible to families" hasArrow placement="top">
          <Box>
            <Icon as={RiEyeOffLine} color="gray.500" boxSize="1.25rem" />
          </Box>
        </Tooltip>
      ) : null,
  };
}
