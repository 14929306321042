import * as GQL from "./graphql";

export enum HasuraRole {
  USER = "user",
  SCHOOL_ADMIN = "school-admin",
  DISTRICT_ADMIN = "district-admin",
  ORG_ADMIN = "org-admin",
  ADMIN = "admin",
}

export function personTypeToHasuraRole(
  personType: GQL.person_type_enum
): HasuraRole {
  const lookup: Record<GQL.person_type_enum, HasuraRole> = {
    admin: HasuraRole.ADMIN,
    orgAdmin: HasuraRole.ORG_ADMIN,
    districtAdmin: HasuraRole.DISTRICT_ADMIN,
    schoolAdmin: HasuraRole.SCHOOL_ADMIN,
    guardian: HasuraRole.USER,
    applicant: HasuraRole.USER,
  };

  return lookup[personType];
}
