import { useField } from "formik";
import { useCallback, useMemo } from "react";
import { CustomSelect, CustomSelectProps, Item } from "./CustomSelect";

export type FormikCustomSelectProps<T extends Item> = {
  name: string;
} & Omit<CustomSelectProps<T>, "selectedItem" | "onChange">;
export function FormikCustomSelect<T extends Item>({
  name,
  ...props
}: FormikCustomSelectProps<T>) {
  const { items } = props;

  const [field, meta, helper] = useField(name);
  const selectedItem = useMemo(() => {
    return items.find((item) => item.key === field.value) ?? null;
  }, [field.value, items]);

  const onChange = useCallback(
    (item: T | null) => {
      helper.setTouched(true);
      if (item === null) {
        helper.setValue(undefined);
        return;
      }

      helper.setValue(item.key);
    },
    [helper]
  );

  return (
    <CustomSelect<T>
      {...props}
      error={meta.error}
      onChange={onChange}
      selectedItem={selectedItem}
    />
  );
}
