import { gql } from "@apollo/client";

export const GET_MESSAGES = gql`
  query GetMessages(
    $organization_id: uuid
    $offset: Int
    $limit: Int
    $order_by: [message_adhoc_order_by!]
    $message_filter: message_adhoc_bool_exp!
    $message_receiver_filter: message_adhoc_receiver_bool_exp!
  ) {
    message_adhoc_aggregate(
      where: {
        message_adhoc_receivers: {
          receiver_person: { organization_id: { _eq: $organization_id } }
          _and: [$message_receiver_filter]
        }
        _and: [$message_filter]
      }
    ) {
      aggregate {
        count
      }
    }
    message_adhoc(
      where: {
        message_adhoc_receivers: {
          receiver_person: { organization_id: { _eq: $organization_id } }
          _and: [$message_receiver_filter]
        }
        _and: [$message_filter]
      }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      sender_person {
        full_name
      }
      message_adhoc_receivers(where: $message_receiver_filter) {
        sent_at
        message_status
      }
      message_adhoc_receivers_aggregate(where: $message_receiver_filter) {
        aggregate {
          count
        }
      }
      message_adhoc_receivers_aggregate_sent: message_adhoc_receivers_aggregate(
        where: {
          message_status: { _eq: Sent }
          _and: [$message_receiver_filter]
        }
      ) {
        aggregate {
          count
        }
      }
      message_adhoc_payload_email {
        message_body
      }
      message_adhoc_payload_sms {
        message_body
      }
    }
  }
`;

export const GET_RECIPIENTS_DETAILS = gql`
  query GetRecipientsDetails(
    $parents_id: [uuid!]
    $offset: Int
    $limit: Int
    $order_by: [person_order_by!]
  ) {
    person(
      where: { id: { _in: $parents_id } }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      full_name
      second_relationship(where: { active: { _eq: true } }) {
        first {
          id
          full_name
        }
      }
      first_relationship(where: { active: { _eq: true } }) {
        second {
          id
          full_name
        }
      }
      email_address
      phone_number
      email_okay
      sms_okay
      user {
        id
        name
      }
      preferred_language
    }
  }
`;

export const GET_MESSAGE_BY_ID = gql`
  query GetMessageById(
    $message_id: uuid!
    $message_receiver_filter: message_adhoc_receiver_bool_exp!
  ) {
    message_adhoc_by_pk(id: $message_id) {
      id
      message_adhoc_receivers(where: $message_receiver_filter) {
        receiver_person {
          id
          full_name
          email_address
          phone_number
          email_okay
          sms_okay
          preferred_language
        }
        message_status
      }
      message_adhoc_payload_email {
        message_body
        message_subject
      }
      message_adhoc_payload_sms {
        message_body
      }
    }
  }
`;

export const GET_SENDER_ID = gql`
  query GetSenderId($userId: String!, $organizationId: uuid!) {
    person(
      where: {
        _or: [
          {
            user_id: { _eq: $userId }
            organization_id: { _eq: $organizationId }
          }
          # avela admin doesn't have organization_id
          {
            user_id: { _eq: $userId }
            organization_id: { _is_null: true }
            person_type: { _eq: admin }
          }
        ]
      }
    ) {
      id
    }
  }
`;
