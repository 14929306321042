import {
  GetMessageTemplateTypes,
  message_template_insert_input,
  message_template_type_enum,
  message_template_updates,
  UpdateMessageTemplatesVariables
} from "src/types/graphql";
import {
  defaultTemplateRankDisabled,
  defaultTemplateRankEnabled
} from "../messageTemplateBody";
import { ListMessageTemplatesForm, MessageTemplateType } from "./schemas";

export function formatMessageTemplates(
  data: GetMessageTemplateTypes,
  formTemplateId: uuid
): MessageTemplateType[] {
  const { message_template_type, message_template } = data;

  return message_template_type.map(({ value }) => {
    const messageTemplate = message_template.find(
      (template) => template.type === value
    );

    return {
      id: messageTemplate?.id,
      formTemplateId,
      type: value as message_template_type_enum,
      enabled: messageTemplate?.enabled ?? false
    };
  });
}

export function formatInitialValues(
  messageTemplates: MessageTemplateType[]
): ListMessageTemplatesForm {
  return messageTemplates.reduce((accumulator, currentMessageTemplate) => {
    accumulator[currentMessageTemplate.type] = currentMessageTemplate;
    return accumulator;
  }, {} as ListMessageTemplatesForm);
}

export function transformFormValuesToMutationVariables(
  formValues: ListMessageTemplatesForm,
  rankingEnabled: boolean
): UpdateMessageTemplatesVariables {
  // the object keys are the template types, which is duplicated in the object value
  const messageTemplates = Object.values(formValues);

  // inserts message template objects that do not have an id and enabled is set to true.
  const messageTemplatesToInsert = messageTemplates.filter((template) => {
    const { id, enabled } = template;
    return id === undefined && enabled === true;
  });

  // update message templates that have an id
  const messageTemplatesToUpdate = messageTemplates.filter((template) => {
    const { id } = template;
    return id !== undefined;
  });

  return {
    messageTemplateInserts: insertMessageTemplatePayload(
      messageTemplatesToInsert,
      rankingEnabled
    ),
    messageTemplateUpdates: updateMessageTemplatePayload(
      messageTemplatesToUpdate
    )
  };
}

function insertMessageTemplatePayload(
  messageTemplates: MessageTemplateType[],
  rankingEnabled: boolean
): message_template_insert_input[] {
  return messageTemplates.map((template) => {
    const { formTemplateId, type, enabled } = template;

    const defaultTemplate = rankingEnabled
      ? defaultTemplateRankEnabled
      : defaultTemplateRankDisabled;

    const { emailSubject, emailText, emailHtml, sms } = defaultTemplate[type];

    return {
      form_template_id: formTemplateId,
      type,
      email_subject: emailSubject,
      email_plain_text: emailText,
      email_markup: emailHtml,
      sms_body: sms,
      enabled
    };
  });
}

function updateMessageTemplatePayload(
  messageTemplates: MessageTemplateType[]
): message_template_updates[] {
  return messageTemplates.map((template) => {
    const { id, formTemplateId, type, enabled } = template;

    return {
      where: {
        id: { _eq: id },
        form_template_id: { _eq: formTemplateId },
        type: { _eq: type },
        enabled: { _neq: enabled } // prevent no-op mutations
      },
      _set: {
        enabled: enabled
      }
    };
  });
}
