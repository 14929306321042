import { Icon, IconButton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { FunctionComponent, useMemo } from "react";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { RemoteDataTable } from "src/components/Table/RemoteDataTable";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useDeleteConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  DeleteBoundaryGroup,
  DeleteBoundaryGroupVariables,
  GetBoundaryGroupList,
} from "src/types/graphql";
import { DELETE_BOUNDARY_GROUP } from "../graphql/mutations";
import { GET_BOUNDARY_GROUP_LIST } from "../graphql/queries";

export type BoundaryGroupTableRow = {
  organizationName: string;
  boundaryGroupId: string;
  // sub rows
  enrollmentPeriodName: string;
  schoolName: string;
  gradeLabel: string;
  schoolBoundaries?: BoundaryGroupTableRow[];
};

const baseTableColumns: ColumnDef<BoundaryGroupTableRow>[] = [
  {
    id: "expander",
    header: "",
    size: 40,
    cell: ({ row }) => {
      if (row.depth !== 0) {
        return null;
      }

      return (
        <IconButton
          variant="ghost"
          aria-label="Click to expand/collapse boundary groups"
          onClick={row.getToggleExpandedHandler()}
          icon={row.getIsExpanded() ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
        />
      );
    },
  },
  { accessorKey: "organizationName", header: "Organization" },
  { accessorKey: "enrollmentPeriodName", header: "Enrollment Period" },
  { accessorKey: "schoolName", header: "School Name" },
  { accessorKey: "gradeLabel", header: "Grade" },
  { accessorKey: "boundaryGroupId", header: "Boundary Group ID" },
];

export const BoundaryGroupsTable: FunctionComponent = () => {
  const { remoteData } = useRemoteDataQuery<GetBoundaryGroupList>(
    GET_BOUNDARY_GROUP_LIST
  );

  const [deleteBoundaryGroup] = useRemoteDataMutation<
    DeleteBoundaryGroup,
    DeleteBoundaryGroupVariables
  >(DELETE_BOUNDARY_GROUP, { refetchQueries: [GET_BOUNDARY_GROUP_LIST] });

  const { confirm, confirmationDialog } = useDeleteConfirmationDialog({
    header: "Delete boundary group?",
    body: "Deleting the boundary group will reset any eligibilities associated with it. This can't be undone or recovered.",
  });

  const toast = useAvelaToast();

  const tableColumns: ColumnDef<BoundaryGroupTableRow>[] = useMemo(() => {
    return [
      ...baseTableColumns,
      {
        header: "Actions",
        size: 40,
        cell: ({ row }) => {
          if (row.depth !== 0) {
            return null;
          }

          return (
            <WithUserPermissions permissions={["boundaries:delete"]}>
              <IconButton
                aria-label="Delete boundary group"
                icon={<Icon as={RiDeleteBin6Line} />}
                variant="outline"
                onClick={async () => {
                  if (!(await confirm())) {
                    return;
                  }

                  deleteBoundaryGroup({
                    variables: { id: row.original.boundaryGroupId },
                  });

                  toast({
                    id: "boundary-deleted",
                    title: "Boundary group deleted",
                    isClosable: true,
                  });
                }}
              />
            </WithUserPermissions>
          );
        },
      },
    ];
  }, [confirm, deleteBoundaryGroup, toast]);

  const boundaryGroupData = useMemo(() => {
    return remoteData.map(transformQueryResponseToTableRows);
  }, [remoteData]);

  return (
    <>
      <RemoteDataTable
        columns={tableColumns}
        data={boundaryGroupData}
        getSubRows={(row) => row.schoolBoundaries}
      />
      {confirmationDialog}
    </>
  );
};

function transformQueryResponseToTableRows(
  boundaryGroupList: GetBoundaryGroupList
) {
  return boundaryGroupList.geojson.map((geojsonDatabaseRow) => {
    const { id, organization, school_boundaries } = geojsonDatabaseRow;

    return {
      organizationName: organization.name,
      boundaryGroupId: id,
      enrollmentPeriodName: "",
      schoolName: "",
      gradeLabel: "",
      schoolBoundaries: school_boundaries.map((row) => {
        const { school, enrollment_period, grade } = row;
        const gradeLabel = grade ? grade?.grade_config.label : "ALL";

        return {
          organizationName: "",
          boundaryGroupId: "",
          enrollmentPeriodName: enrollment_period.name,
          schoolName: school.name,
          gradeLabel,
          schoolBoundaries: [],
        };
      }),
    };
  });
}
