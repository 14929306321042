import {
  Button,
  FormLabel,
  HStack,
  Stack,
  FormControl,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { Ref } from "react";
import { validateWithZod } from "src/services/formValidations";
import { FormSchema, FormType } from "./Form";
import { NameInput } from "./NameInput";
import { InputWithValidationControl } from "../Inputs/InputWithValidationControl";
import * as GQL from "src/types/graphql";

type Props = {
  onSaving?: (values: GQL.enrollment_period_tag_insert_input) => Promise<void>;
  onCancelling?: () => void;
  initialName?: string | null;
  inputRef?: Ref<HTMLInputElement>;
  error?: string;
  clearError?: () => void;
};

export const New: React.FC<Props> = ({
  initialName,
  onSaving,
  onCancelling,
  inputRef,
  error,
  clearError,
}) => {
  const [saving, setSaving] = React.useState(false);
  const initialValues: FormType = { name: initialName ?? "" };
  const handleSubmit = React.useCallback(
    async (values: FormType) => {
      if (onSaving) {
        setSaving(true);
        await onSaving(values);
        setSaving(false);
      }
    },
    [onSaving]
  );

  return (
    <Formik<FormType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateWithZod(FormSchema)}
      validateOnBlur={false}
    >
      {(formik) => {
        return (
          <Stack as={Form} padding={4}>
            <NameInput
              label="Create new tag"
              ref={inputRef}
              error={error}
              clearError={clearError}
            />
            <FormControl>
              <FormLabel>Description</FormLabel>
              <InputWithValidationControl id="description" name="description" />
            </FormControl>

            <HStack justifyContent="flex-end">
              <Button
                type="button"
                variant="outline"
                colorScheme="gray"
                onClick={onCancelling}
                size="sm"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outline"
                colorScheme="gray"
                size="sm"
                isDisabled={!formik.isValid}
                loadingText="Creating tag"
                isLoading={saving}
              >
                Create tag
              </Button>
            </HStack>
          </Stack>
        );
      }}
    </Formik>
  );
};
