import { Heading, Stack, UnorderedList } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import * as List from "src/services/list";
import * as RD from "src/types/remoteData";
import { GenericError } from "../Feedback/GenericError";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { NoTagsFound } from "./NoTagsFound";
import { TagItem } from "./TagItem";
import { TagsLoading } from "./TagsLoading";
import { ConfigWithDefault, Tag, TagsListEventHandler } from "./types";

type WithRecentTagsListProps = {
  tags: RD.RemoteData<unknown, Tag[]>;
  config: ConfigWithDefault;
  searchKeyword: string | undefined;
  eventHandlers: TagsListEventHandler;
  onEditTagSaving?: (tag: Tag, onSuccess?: () => void) => Promise<void>;
  onToggleTagDescription?: (state: boolean) => void;
};
export const WithRecentTagsList: React.FC<WithRecentTagsListProps> = ({
  tags,
  config,
  searchKeyword,
  eventHandlers,
  onEditTagSaving,
  onToggleTagDescription,
}) => {
  const { onDeleteTag, onEditTag, onTagUpdate } = eventHandlers;
  const recentTags: RD.RemoteData<unknown, RecentlyUsedTags> =
    React.useMemo(() => {
      if (searchKeyword) {
        return RD.notAsked();
      }

      return tags.map((tags) => {
        return getLastUsedTags(tags, config.maxLastUsedTagsCount);
      });
    }, [config.maxLastUsedTagsCount, searchKeyword, tags]);

  return (
    <RemoteDataView
      config={{ showDataWhileReloading: true }}
      reloading={null}
      remoteData={recentTags}
      loading={<TagsLoading />}
      error={(error: unknown) => {
        return <GenericError />;
      }}
    >
      {({ recentlyUsedTags: lastUsedTags, allTags }) => (
        <Stack spacing="4">
          {lastUsedTags.length > 0 && (
            <Stack>
              <Heading
                as="h4"
                size="sm"
                color="blackAlpha.600"
                id="recent-tags"
              >
                Recent
              </Heading>
              <UnorderedList
                marginInlineStart="0"
                aria-labelledby="recent-tags"
              >
                {lastUsedTags.map((tag) => (
                  <TagItem
                    config={config}
                    key={tag.id}
                    tag={tag}
                    searchKeyword={searchKeyword}
                    onDeleteTag={onDeleteTag}
                    onEditTag={onEditTag}
                    onTagUpdate={onTagUpdate}
                    onEditTagSaving={onEditTagSaving}
                    onToggleTagDescription={onToggleTagDescription}
                  />
                ))}
              </UnorderedList>
            </Stack>
          )}
          {allTags.length > 0 && (
            <Stack>
              <Heading as="h4" size="sm" color="blackAlpha.600" id="all-tags">
                All
              </Heading>
              <UnorderedList marginInlineStart="0" aria-labelledby="all-tags">
                {allTags.map((tag) => (
                  <TagItem
                    config={config}
                    key={tag.id}
                    tag={tag}
                    searchKeyword={searchKeyword}
                    onDeleteTag={onDeleteTag}
                    onEditTag={onEditTag}
                    onTagUpdate={onTagUpdate}
                    onEditTagSaving={onEditTagSaving}
                    onToggleTagDescription={onToggleTagDescription}
                  />
                ))}
              </UnorderedList>
            </Stack>
          )}
          {allTags.length === 0 && (
            <NoTagsFound
              searchKeyword={searchKeyword}
              config={config}
              eventHandlers={eventHandlers}
            />
          )}
        </Stack>
      )}
    </RemoteDataView>
  );
};
type RecentlyUsedTags = Readonly<{
  recentlyUsedTags: readonly Tag[];
  allTags: readonly Tag[];
}>;

export function getLastUsedTags(
  tags: Tag[],
  maxLastUsedTagsCount: number
): RecentlyUsedTags {
  const sortedTags = _.orderBy(tags, "lastUsedAt", "desc");
  const { recentlyUsedTags: lastUsedTags, allTags } = sortedTags.reduce(
    (acc: RecentlyUsedTags, current: Tag) => {
      if (
        current.lastUsedAt !== null &&
        acc.recentlyUsedTags.length < maxLastUsedTagsCount
      ) {
        return {
          allTags: [...acc.allTags, current],
          recentlyUsedTags: [...acc.recentlyUsedTags, current],
        };
      }

      return { ...acc, allTags: [...acc.allTags, current] };
    },
    { recentlyUsedTags: [], allTags: [] }
  );

  return {
    recentlyUsedTags: List.orderByIgnoreCase(lastUsedTags, (tag) => tag.name),
    allTags: List.orderByIgnoreCase(allTags, (tag) => tag.name),
  };
}
