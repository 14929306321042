import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useReducer,
} from "react";
import { EditFormState, initialEditFormState, reducer } from "./reducer";
import { EditFormActions } from "./actions";

export const EditFormContext = createContext<{
  state: EditFormState;
  dispatch: Dispatch<EditFormActions>;
}>({
  state: initialEditFormState,
  dispatch: () => null,
});

export const EditFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialEditFormState);

  return (
    <EditFormContext.Provider value={{ state, dispatch }}>
      {children}
    </EditFormContext.Provider>
  );
};
