import { gql } from "@apollo/client";

export const DELETE_FORM = gql`
  mutation DeleteForm($form_id: uuid!) {
    update_form_by_pk(
      pk_columns: { id: $form_id }
      _set: { status: Deleted, deleted_at: "now()" }
    ) {
      id
      status
    }
    update_offer(
      _set: { deleted_at: "now()", status: Declined }
      where: { form_id: { _eq: $form_id }, deleted_at: { _is_null: true } }
    ) {
      affected_rows
      returning {
        id
      }
    }
    update_waitlist(
      _set: { deleted_at: "now()", status: Withdrawn }
      where: { form_id: { _eq: $form_id }, deleted_at: { _is_null: true } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ACCEPT_OFFER = gql`
  mutation AcceptOffer($offer_id: uuid!) {
    update_offer_by_pk(
      _set: { status: Accepted }
      pk_columns: { id: $offer_id }
    ) {
      id
    }
  }
`;

export const DECLINE_OFFER = gql`
  mutation DeclineOffer($offer_id: uuid!) {
    update_offer_by_pk(
      _set: { status: Declined }
      pk_columns: { id: $offer_id }
    ) {
      id
    }
  }
`;

export const WITHDRAW_FORM = gql`
  mutation WithdrawForm($form_id: uuid!, $school_id: uuid!) {
    update_waitlist(
      where: { form_id: { _eq: $form_id }, school_id: { _eq: $school_id } }
      _set: { status: Withdrawn }
    ) {
      returning {
        id
      }
    }
  }
`;
