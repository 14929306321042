import { AddIcon } from "@chakra-ui/icons";
import { Button, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ParentAnnouncement } from "src/components/Announcement/ParentAnnouncement";
import { EmptyStateCard } from "src/components/EmptyState";
import { Glossary } from "src/components/Text/Glossary";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as AddUsersSvg } from "src/images/add-users.svg";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";

interface EmptyProps {
  returnUrl?: string;
}

export const Empty: React.FC<EmptyProps> = (props: EmptyProps) => {
  const { returnUrl } = props;

  const { glossary } = useGlossary();
  const organization = useOrganization();

  return (
    <VStack gap={4}>
      <ParentAnnouncement
        condition={GQL.announcement_condition_enum.NO_STUDENT}
      />
      <EmptyStateCard
        description={`Start by adding a ${glossary`student`}`}
        heading="Welcome, we're happy you're here."
        Svg={AddUsersSvg}
      >
        <Button
          as={RouterLink}
          leftIcon={<AddIcon boxSize={3} />}
          to={organization
            .map((org) =>
              Url.Parent.Student.new(org, { returnUrl, studentCount: 0 })
            )
            .withDefault("#")}
          width="100%"
        >
          <Glossary>Add student</Glossary>
        </Button>
      </EmptyStateCard>
    </VStack>
  );
};
