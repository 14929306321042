import { gql } from "@apollo/client";

export const SOFT_DELETE_STUDENT = gql`
  mutation SoftDeleteStudent($id: uuid!) {
    update_person_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;
