import { Box, Flex, Tag } from "@chakra-ui/react";
import React from "react";
import { WithRequiredHasuraRoles } from "../../Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { WithUserPermissions } from "../../Permissions/WithUserPermissions";
import { SingleTagsPopover } from "src/scenes/orgAdmin/tags/SingleTagsPopover";
import { GET_SCHOOLS_RANK_VIEW } from "src/scenes/orgAdmin/forms/graphql/queries";
import * as GQL from "src/types/graphql";
import { SchoolRankWithTags } from "src/types/schoolRank";
import { TagGroupName } from "./TagGroupName";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";

type Props = {
  enrollmentPeriodId: uuid;
  tagGroups?: GQL.GetSchoolsRankView_tag_group[];
  schoolRank: SchoolRankWithTags;
};

export const TagGroups: React.FC<Props> = ({
  enrollmentPeriodId,
  tagGroups,
  schoolRank,
}) => {
  const typedSchoolRank = schoolRank as GQL.GetSchoolsRankView_form_school_rank;

  const groupedTags = tagGroups?.map((tagGroup) => ({
    id: tagGroup.id,
    name: tagGroup.name,
    tags: typedSchoolRank.tags.filter(
      (tag) => tag.enrollment_period_tag.tag_group_id === tagGroup.id
    ),
    isExternal: tagGroup.is_external,
  }));

  const isAvelaOrgAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);

  return (
    <Flex direction="column" gap={4} mb={2} ml={6}>
      <WithRequiredHasuraRoles
        roles={[
          HasuraRole.ADMIN,
          HasuraRole.DISTRICT_ADMIN,
          HasuraRole.ORG_ADMIN,
          HasuraRole.SCHOOL_ADMIN,
        ]}
      >
        {groupedTags?.map((tagGroup) => (
          <Flex key={tagGroup.id} direction="column" gap={2}>
            <TagGroupName
              name={tagGroup.name}
              isExternal={tagGroup.isExternal}
              fontSize="sm"
            />

            <Flex gap={2} wrap="wrap">
              {tagGroup.tags.map((tag) => (
                <Tag
                  key={tag.enrollment_period_tag.id}
                  variant="tag"
                  fontSize="xs"
                >
                  <Box whiteSpace="nowrap">
                    {tag.enrollment_period_tag.name}
                  </Box>
                </Tag>
              ))}
              {(isAvelaOrgAdmin || !tagGroup.isExternal) && (
                <WithUserPermissions permissions={["form:update"]}>
                  <SingleTagsPopover
                    enrollmentPeriodId={enrollmentPeriodId}
                    tagGroupId={tagGroup.id}
                    formSchoolId={{
                      formSchoolRankId: schoolRank.id,
                      formId: schoolRank.form.id,
                      schoolId: schoolRank.school.id,
                    }}
                    refetchQueries={[GET_SCHOOLS_RANK_VIEW]}
                  />
                </WithUserPermissions>
              )}
            </Flex>
          </Flex>
        ))}
      </WithRequiredHasuraRoles>
    </Flex>
  );
};
