import { useEnv } from "src/services/env";
import * as Question from "src/services/formTemplate/question";
import { useCallback, useMemo } from "react";
import * as Answer from "src/services/formTemplate/answer";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { useApolloClient } from "@apollo/client";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { GET_FORM_TEMPLATE_BY_ID } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/graphql/queries";
import * as AFService from "src/services/formTemplate";
import * as AF from "src/types/formTemplate";
import * as _ from "lodash";
import { getAllEligibilityQuestions } from "../formTemplate/preRankingSection/determineEligibility";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";

// Global cache outside of the component
const globalCache: { [key: string]: string[] } = {};

export function useEligibilityService({ formTemplateId }: Props) {
  const flags = useFlags(["eligibility-service"]);
  const env = useEnv();
  const orgPath = useOrganizationPath();

  const getIneligibleSchoolIdsByAnswers: GetIneligibleSchoolIdsByAnswersFn =
    useCallback(
      async ({ answers }) => {
        if (!orgPath) {
          throw new EligibilityServiceError("Failed to get eligibility", {
            cause: "Missing orgPath from the route",
          });
        }

        const cacheKey = JSON.stringify({
          orgPath,
          formTemplateId,
          answers,
        });

        const cacheValue = globalCache[cacheKey];
        if (cacheValue) {
          return cacheValue;
        }

        try {
          const response = await fetch(
            `${env.REACT_APP_ELIGIBILITY_SERVICE_URL}/organizations/${orgPath}/formTemplates/${formTemplateId}/findEligibility`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(toPayload(answers)),
            }
          );
          if (response.status !== 200) {
            throw new Error("Failed to get eligibility", {
              cause: response.statusText,
            });
          }
          const raw = await response.json();
          const json = ResponseSchema.parse(raw);
          const result = json.ineligibleSchools.map((school) => school.id);

          // Store the result in the global cache
          globalCache[cacheKey] = result;
          return result;
        } catch (error) {
          console.error(error);
          throw new EligibilityServiceError("Failed to get eligibility", {
            cause: error,
          });
        }
      },
      [env.REACT_APP_ELIGIBILITY_SERVICE_URL, formTemplateId, orgPath]
    );

  const apolloClient = useApolloClient();
  // TODO: https://app.asana.com/0/1207937326674859/1208484114249633/f
  const getIneligibleSchoolIdsByFormId: GetIneligibleSchoolIdsByFormIdFn =
    useCallback(
      async ({ formId }) => {
        const [questionsResponse, answersResponse] = await Promise.all([
          apolloClient.query<
            GQL.GetFormTemplateById,
            GQL.GetFormTemplateByIdVariables
          >({
            query: GET_FORM_TEMPLATE_BY_ID,
            variables: { form_template_id: formTemplateId },
          }),
          apolloClient.query<
            GQL.GetFormAnswersById,
            GQL.GetFormAnswersByIdVariables
          >({
            query: GET_FORM_ANSWERS_BY_ID,
            variables: { form_id: formId },
          }),
        ]);
        const formTemplateData = questionsResponse.data.form_template_by_pk;
        if (!formTemplateData) {
          throw new Error("Unable to get eligibility", {
            cause: `Invalid formTemplateId: ${formTemplateId}`,
          });
        }
        const formTemplate = AFService.fromGQL(formTemplateData);
        const preRankingSection = AFService.findPreRankingSection(formTemplate);

        const answers = !preRankingSection
          ? {}
          : Answer.getFormikInitialValues(
              preRankingSection.questions,
              answersResponse.data.form_answer,
              answersResponse.data.grades_answer,
              answersResponse.data.form_address,
              answersResponse.data.custom_question_answer
            );
        return getIneligibleSchoolIdsByAnswers({ answers });
      },
      [apolloClient, formTemplateId, getIneligibleSchoolIdsByAnswers]
    );

  const service = useMemo(
    () => ({
      getIneligibleSchoolIdsByAnswers,
      getIneligibleSchoolIdsByFormId,
      shouldRunEligibilityCheck,
    }),
    [getIneligibleSchoolIdsByAnswers, getIneligibleSchoolIdsByFormId]
  );

  if (!flags["eligibility-service"].enabled) {
    return {
      getIneligibleSchoolIdsByAnswers: (async () => {
        throw new Error("Eligibility service is disabled");
      }) as GetIneligibleSchoolIdsByAnswersFn,
      getIneligibleSchoolIdsByFormId: (async () => {
        throw new Error("Eligibilty service is disabled");
      }) as GetIneligibleSchoolIdsByFormIdFn,
      shouldRunEligibilityCheck,
    };
  }

  return service;
}

export class EligibilityServiceError extends Error {}

/**
 * Check whether eligibilility check should be run again
 */
export const shouldRunEligibilityCheck: ShouldRunEligibilityCheckFn = ({
  questions,
  answers,
}) => {
  const { previous, current } = answers;

  // Check if grade answers has changed
  const previousGradeAnswer = Answer.findGradeAnswer(questions, previous);
  const currentGradeAnswer = Answer.findGradeAnswer(questions, current);
  if (!_.isEqual(previousGradeAnswer, currentGradeAnswer)) {
    return true;
  }

  // check if geo-eligibility is enabled, and address answers has changed
  const geoEligibilityQuestion = Question.findGeoEligibilityQuestion(questions);
  if (geoEligibilityQuestion) {
    const previousAddressAnswer = Answer.findFormAddressAnswer(
      questions,
      previous
    );
    const currentAddressAnswer = Answer.findFormAddressAnswer(
      questions,
      current
    );
    if (!_.isEqual(previousAddressAnswer, currentAddressAnswer)) {
      return true;
    }
  }

  // check if any of the eligibility question answer has changed
  const eligiblityQuestions = getAllEligibilityQuestions(questions);
  for (const eligiblityQuestion of eligiblityQuestions) {
    const previousAnswer = previous[eligiblityQuestion.id];
    const currentAnswer = current[eligiblityQuestion.id];
    if (!_.isEqual(previousAnswer, currentAnswer)) {
      return true;
    }
  }

  return false;
};

function toPayload(answers: Readonly<Answer.FormikValues>): Payload {
  const questionIdToAnswer: QuestionIdToAnswer = {};

  for (const questionId of Object.keys(answers)) {
    const answer = answers[questionId];
    if (!answer) {
      continue;
    }

    if (Answer.isSingleSelectAnswer(answer) || Answer.isGradesAnswer(answer)) {
      questionIdToAnswer[questionId] = answer;
    } else if (Answer.isMultiSelectAnswer(answer)) {
      questionIdToAnswer[questionId] = answer;
    } else if (Answer.isAddressAnswer(answer)) {
      questionIdToAnswer[questionId] = {
        streetAddress: answer.street_address,
        streetAddressLine2: answer.street_address_line_2,
        city: answer.city,
        state: answer.state,
        zipCode: answer.zip_code,
      };
    }
  }

  return { questionIdToAnswer: questionIdToAnswer };
}

/* Schemas */
const AddressAnswerSchema = z.object({});
const AnswerSchema = z.union([
  z.string(),
  z.array(z.string()),
  AddressAnswerSchema,
]);

const QuestionIdToAnswerSchema = z.record(z.string(), AnswerSchema);
type QuestionIdToAnswer = z.infer<typeof QuestionIdToAnswerSchema>;
const PayloadSchema = z.object({
  questionIdToAnswer: QuestionIdToAnswerSchema,
});
type Payload = z.infer<typeof PayloadSchema>;
const SchoolSchema = z.object({
  id: z.string(),
});
const ResponseSchema = z.object({
  ineligibleSchools: z.array(SchoolSchema),
});

/* Types */
type Props = {
  formTemplateId: string;
};

type GetIneligibleSchoolIdsByAnswersFn = (value: {
  answers: Readonly<Answer.FormikValues>;
}) => Promise<string[]>;

type GetIneligibleSchoolIdsByFormIdFn = (value: {
  formId: string;
}) => Promise<string[]>;

type ShouldRunEligibilityCheckFn = (args: {
  questions: AF.Question<AF.WithId>[];
  answers: { previous: Answer.FormikValues; current: Answer.FormikValues };
}) => boolean;
