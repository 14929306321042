import { Button, MenuItem, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  GET_ACTIVE_FORM_TEMPLATES,
  GET_OFFERS,
} from "src/scenes/parent/formTemplates/graphql/queries";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ACCEPT_OFFER } from "../../graphql/mutations";
import { useFormTemplateHint } from "src/scenes/parent/hint";

interface Props {
  acceptedOfferSchoolName?: string;
  formTemplateId: uuid;
  offerId: uuid;
  school: string;
  studentName: string;
  isButton?: boolean;
}

export const AcceptOffer = ({
  acceptedOfferSchoolName,
  formTemplateId,
  offerId,
  school,
  studentName,
  isButton = false,
}: Props) => {
  const [acceptOffer] = useRemoteDataMutation<
    GQL.AcceptOffer,
    GQL.AcceptOfferVariables
  >(ACCEPT_OFFER);

  const toast = useAvelaToast({ position: "bottom" });
  const navigate = useNavigate();
  const organization = useOrganization();

  const { pushEvent } = useFormTemplateHint();

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "No, cancel",
      },
      confirmButton: {
        label: "Yes, accept",
        colorScheme: "primary",
      },
      translate: true,
    });

  const handleOnClick = async (evt: React.MouseEvent) => {
    setHeader(<Text>Accept offer?</Text>);
    if (acceptedOfferSchoolName) {
      setBody(
        <Text>
          {`Accepting the offer from ${school} will decline the previously accepted offer from ${acceptedOfferSchoolName}.`}{" "}
          <Text pt={8}>This can't be undone nor recovered.</Text>
        </Text>
      );
    } else {
      setBody(
        <Text>{`Accepting the offer from ${school} will not affect any other offers made to ${studentName}.`}</Text>
      );
    }

    if (await confirm()) {
      try {
        await acceptOffer({
          variables: {
            offer_id: offerId,
          },
          refetchQueries: [GET_ACTIVE_FORM_TEMPLATES, GET_OFFERS],
        });
        toast({
          title: "Hooray!",
          description: "Offer accepted, nicely done!",
          status: "success",
        });
        navigate(organization.map(Url.Parent.index).withDefault("#"));

        pushEvent({
          type: "offer",
          formTemplateId,
          studentName,
          status: GQL.offer_status_enum.Accepted,
        });
      } catch (err) {
        toast.error({
          title: "Something went wrong!",
          description: "Check your network and try again.",
        });
        console.error(err);
      }
    }
  };

  if (isButton) {
    return (
      <>
        <Button size="sm" width="50%" onClick={handleOnClick}>
          Accept offer
        </Button>
        {confirmationDialog}
      </>
    );
  }

  return (
    <>
      <MenuItem
        textColor="gray.700"
        fontWeight="400"
        fontSize="sm"
        onClick={handleOnClick}
      >
        Accept offer
      </MenuItem>
      {confirmationDialog}
    </>
  );
};
