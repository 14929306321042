import { Route } from "react-router-dom";
import { SelectAnswerBank } from "src/components/Inputs/QuestionDynamicInputs/CustomQuestion";
import { RequireHasuraRoles } from "src/components/Permissions/RequireHasuraRoles";
import { RequireOrganization } from "src/components/Permissions/RequireOrganization";
import { RequireUserProfile } from "src/components/Permissions/RequireUserProfile";
import * as Url from "src/services/url";
import { HasuraRole } from "src/types/hasuraRole";
import * as Parent from "./";

export const ParentPortalRoutes = (
  <Route element={<RequireHasuraRoles roles={[HasuraRole.USER]} />}>
    <Route path={Url.Parent.Profile.newPath} element={<Parent.Profile.New />} />
    <Route element={<RequireUserProfile />}>
      {/* Layout need to be inside RequireUserProfile to avoid it being render when we're redirecting user to create Profile.newPath */}
      <Route element={<Parent.Layout />}>
        <Route element={<RequireOrganization />}>
          <Route path={Url.Parent.indexPath} element={<Parent.List />} />

          <Route
            path={Url.Parent.ImportantDate.indexPath}
            element={<Parent.ImportantDate.List />}
          />

          <Route
            path={Url.Parent.Student.indexPath}
            element={<Parent.Student.List />}
          />

          <Route
            path={Url.Parent.Student.newPath}
            element={<Parent.Student.New />}
          />
          <Route
            path={Url.Parent.Student.editPath}
            element={<Parent.Student.Edit />}
          />
          <Route
            path={Url.Parent.Form.newPath}
            element={<Parent.Form.StartNewForm />}
          />
          <Route
            path={Url.Parent.Form.editPath}
            element={<Parent.Form.Edit />}
          />
          <Route
            path={Url.Parent.FormCustomQuestion.editPath}
            element={<SelectAnswerBank />}
          />
          <Route
            path={Url.Parent.Form.viewPath}
            element={<Parent.Form.ViewForm />}
          />
          <Route
            path={Url.Parent.Form.lateEditPath}
            element={<Parent.Form.LateEdit />}
          />
          <Route
            path={Url.Parent.Profile.editPath}
            element={<Parent.Profile.Edit />}
          />
          <Route path={Url.Parent.offersPath} element={<Parent.Offers />} />

          <Route
            path={Url.Parent.History.indexPath}
            element={<Parent.History.List />}
          />
        </Route>
      </Route>
    </Route>
    <Route element={<Parent.Layout />}>
      <Route path={Url.Parent.helpPath} element={<Parent.Help />} />
    </Route>
  </Route>
);
