import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "src/components/Layout/Card";
import {
  FormStepLayout,
  StepProps,
} from "src/components/Layout/FormStepLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { DisclaimerText } from "src/components/Text/DisclaimerText";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useWeglotToast } from "src/plugins/weglot";
import * as Url from "src/services/url";
import { REQUIRED_FIELD_VALIDATION_ERROR } from "src/services/url/constants";
import { DisclaimerSection, WithId } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { SignatureInput } from "./components/Inputs/SignatureInput";
import { FormButtons } from "./components/Layout/FormButtons";
import { SUBMIT_FORM } from "./graphql/mutations";
import { GET_FORM_DISCLAIMER_BY_ID } from "./graphql/queries";

type FormType = {
  signature: string;
};

type Props = {
  section: DisclaimerSection<WithId>;
  formId: uuid;
  hasBeenSubmittedBefore: boolean | null;
} & StepProps;

export const StepDisclaimerSection: React.FC<Props> = ({
  section,
  formId,
  hasBeenSubmittedBefore,
  ...stepProps
}) => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormDisclaimerById,
    GQL.GetFormDisclaimerByIdVariables
  >(GET_FORM_DISCLAIMER_BY_ID, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "no-cache",
  });
  const toast = useWeglotToast();

  const [submitForm, submitStatus] = useRemoteDataMutation<
    GQL.SubmitForm,
    GQL.SubmitFormVariables
  >(SUBMIT_FORM);

  const handleSubmit = async () => {
    try {
      await submitForm({
        variables: {
          form_id: formId,
        },
      });

      organization.do((org) => {
        navigate(Url.Parent.index(org));
      });

      toast({
        title: "Hooray!",
        description: "Form successfully submitted.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error submitting form",
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
        isClosable: true,
      });
    }
  };

  const validate = (values: FormType) => {
    if (!values.signature) {
      return { signature: REQUIRED_FIELD_VALIDATION_ERROR };
    }
    return {};
  };

  return (
    <ParentRemoteDataLayout remoteData={remoteData}>
      {(data) => {
        const initialValues: FormType = {
          signature: data.form_disclaimer[0]?.signature ?? "",
        };

        return (
          <Formik<FormType>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnMount={true}
          >
            {(formikProps) => (
              <FormStepLayout
                as={Form}
                title={section.title}
                description={section.description}
                buttons={(buttonsProps) => (
                  <FormButtons
                    {...buttonsProps}
                    overrideNextButton={{
                      disabled: !formikProps.isValid,
                      isLoading: submitStatus.remoteData.isLoading(),
                      label: "Submit",
                      action: () => {
                        // Do nothing here, and instead, next button will trigger form submission
                      },
                    }}
                    hasBeenSubmittedBefore={hasBeenSubmittedBefore}
                  />
                )}
                {...stepProps}
                content={
                  <Card accentColor="primary.500">
                    <DisclaimerText text={section.disclaimer} />

                    <SignatureInput section={section} formId={formId} />
                  </Card>
                }
              ></FormStepLayout>
            )}
          </Formik>
        );
      }}
    </ParentRemoteDataLayout>
  );
};
