import { Box, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import * as AF from "src/types/formTemplate";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import { Props, Loading } from "./Undo";
import { gql } from "@apollo/client";
import _ from "lodash";
import { Timestamp } from "./Timestamp";
import { NoUndoAvailable } from "./NoUndoAvailable";
import { Answer } from "src/services/formTemplate";
import {
  removeConsecutiveDuplicates,
  filterEmpty,
  removeCurrentAnswer,
} from "./helpers";

type GradesProps = Omit<Props, "question"> & {
  initialFocusRef: React.RefObject<HTMLButtonElement>;
  question: AF.Grades<AF.WithId>;
};
export const GradesUndo: React.FC<GradesProps> = (props) => {
  const { formId, question, setAnswer, initialFocusRef, answer } = props;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetGradesAnswerHistory,
    GQL.GetGradesAnswerHistoryVariables
  >(GET_GRADES_ANSWER_HISTORY, {
    variables: {
      formId,
      questionId: question.id,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <RemoteDataView
      error={GenericError}
      remoteData={remoteData}
      loading={<Loading />}
    >
      {(data) => {
        const history = consolidate(
          data.audit_form_transaction,
          question,
          answer
        );
        if (history.length === 0) {
          return <NoUndoAvailable />;
        }

        return (
          <MenuList maxHeight="20rem" overflowY="auto">
            {history.map((item, index) => {
              return (
                <MenuItem
                  onClick={() => setAnswer(item.value)}
                  key={index}
                  display="flex"
                  gap="2"
                  ref={index === 0 ? initialFocusRef : undefined}
                  alignItems="center"
                >
                  <Box>{item.label}</Box>
                  {item.timestamp && <Timestamp timestamp={item.timestamp} />}
                </MenuItem>
              );
            })}
          </MenuList>
        );
      }}
    </RemoteDataView>
  );
};

export type UndoItem = {
  value: string;
  label: string;
  timestamp?: string;
};
export function consolidate(
  list: AuditFormTransaction[],
  question: AF.Grades<AF.WithId>,
  answer: Answer.FormikFieldValue
): UndoItem[] {
  return _.flow(
    toUndoItem(question),
    removeConsecutiveDuplicates,
    filterEmpty,
    removeCurrentAnswer(answer)
  )(list);
}

const toUndoItem =
  (question: AF.Grades<AF.WithId>) => (list: AuditFormTransaction[]) => {
    const gradesMap = new Map<string, string>(
      question.options.map((o) => [o.id, o.label])
    );
    return list.flatMap((transaction) => {
      return transaction.logged_actions.flatMap((action) => {
        const gradeId = action.row_data.grade_config_id;
        if (!gradeId) return [];

        const label = gradesMap.get(gradeId);
        if (!label) return [];

        return [{ value: gradeId, label, timestamp: action.action_tstamp_tx }];
      });
    });
  };

export const GET_GRADES_ANSWER_HISTORY = gql`
  query GetGradesAnswerHistory($formId: uuid!, $questionId: uuid!) {
    audit_form_transaction(
      where: { form_id: { _eq: $formId } }
      order_by: { action_tstamp_tx: desc }
    ) {
      form_id
      logged_actions(
        where: {
          form_id: { _eq: $formId }
          table_name: { _in: ["grades_answer"] }
          row_data: { _contains: { question_id: $questionId } }
          action: { _in: ["I", "U"] }
        }
      ) {
        action_tstamp_tx
        row_id
        row_data
        table_name
        action
      }
    }
  }
`;

type AuditFormTransaction = GQL.GetGradesAnswerHistory_audit_form_transaction;
