import { Grid } from "@chakra-ui/layout";
import { Box, FlexProps, FormLabel, GridProps } from "@chakra-ui/react";
import { Field } from "formik";
import { FormControl } from "formik-chakra-ui";
import { useMemo } from "react";
import { CedarEditor } from "src/components/CedarEditor";
import { FormInput } from "src/components/Inputs/FormInput";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { MemberType } from "src/schemas/Team";
import { HasuraRole } from "src/types/hasuraRole";

type Props = {
  onSelectMember: (values: MemberType[]) => void;
  onChangePolicy: (policy: string) => void;
  allTeamMembers: MemberType[];
  initialMembers: MemberType[];
  initialPolicy?: string;
} & FlexProps &
  GridProps;

export const TeamForm: React.FC<Props> = ({
  onSelectMember: handleSelectMember,
  onChangePolicy: handleChangePolicy,
  allTeamMembers,
  initialMembers,
  initialPolicy = "",
  ...props
}) => {
  const allMembersLabeled = useMemo(() => {
    return allTeamMembers.map((member) => {
      const hasFirstOrLastName = member.first_name || member.last_name;
      return {
        ...member,
        label: hasFirstOrLastName
          ? `${member.first_name} ${member.last_name}`
          : member.id,
      };
    });
  }, [allTeamMembers]);

  const initialMembersLabeled = useMemo(() => {
    return initialMembers.map((member) => {
      const hasFirstOrLastName = member.first_name || member.last_name;
      return {
        ...member,
        label: hasFirstOrLastName
          ? `${member.first_name} ${member.last_name}`
          : member.id,
      };
    });
  }, [initialMembers]);

  const renderDropdownItem = (item: MemberType) => {
    return (
      <Box>
        {item.first_name || item.last_name ? (
          <Box>
            {item.first_name} {item.last_name}
          </Box>
        ) : (
          <Box>{item.id}</Box>
        )}
      </Box>
    );
  };

  return (
    <Grid gap={8}>
      <FormInput
        placeholder="E.g: IT"
        w="100%"
        as={Field}
        type="text"
        pattern="^[a-zA-Z0-9 ]+$"
        title="Name must be alphanumeric"
        id="name"
        name="name"
        label="Name"
        isRequired
      />
      <FormControl name="team_members" w="100%">
        <FormLabel htmlFor="team_members">Members on the team</FormLabel>
        <MultiSelectInput<MemberType>
          size="md"
          options={allMembersLabeled || []}
          initialValues={initialMembersLabeled || []}
          onChange={handleSelectMember}
          renderDropdownItem={renderDropdownItem}
        />
      </FormControl>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
        <Box>
          <FormLabel>Policies for the team</FormLabel>
          <CedarEditor
            initialPolicy={initialPolicy}
            onChangePolicy={(value) => handleChangePolicy(value || "")}
          />
        </Box>
      </WithRequiredHasuraRoles>
    </Grid>
  );
};
