import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { RiPriceTag3Line } from "react-icons/ri";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { Edit } from "./Edit";
import { New } from "./New";
import { Props as TagsProps, TagsList } from "./TagsList";
import { Mode, Tag } from "./types";
import { SelectTagGroup } from "./SelectTagGroup";
import * as GQL from "src/types/graphql";

export type Props = {
  buttonProps?: ButtonProps;
  mode?: Mode;
  onNewTagSaving?: (
    values: GQL.enrollment_period_tag_insert_input
  ) => Promise<void>;
  onNewTagCancelling?: () => void;
  onEditTagSaving?: (tag: Tag, onSucess?: () => void) => Promise<void>;
  onEditTagCancelling?: () => void;
  onNewTagGroupSaving?: (
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onEditTagGroupSaving?: (
    id: uuid,
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onDeleteTagGroupSaving?: (id: uuid, onSuccess: () => void) => Promise<void>;
  error?: string;
  clearError: () => void;
  onCloseManageTagGroupDialog?: () => void;
  isBulkAction?: boolean;
  tagGroups?: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
  onBackClick?: () => void;
} & PopoverProps &
  TagsProps;
export const TagsPopover: React.FC<Props> = ({
  buttonProps,
  mode = { type: "List" },
  onNewTagCancelling,
  onNewTagSaving,
  onEditTagCancelling,
  onEditTagSaving,
  onDeleteTag,
  error,
  clearError,
  onOpen,
  onClose,
  onSelectTagGroup,
  onNewTagGroupSaving,
  onEditTagGroupSaving,
  onCloseManageTagGroupDialog,
  onDeleteTagGroupSaving,
  isBulkAction,
  tagGroups,
  onBackClick,
  ...props
}) => {
  const [closeOnBlur, setCloseOnBlur] = React.useState(true);
  const { isOpen, onOpen: setOpen, onClose: setClose } = useDisclosure();

  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: `Delete tag?`,
    body: "Deleting this tag will remove it from any record it was applied to.",
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: { label: "Yes, delete", colorScheme: "red" },
  });

  const searchInputRef = React.createRef<HTMLInputElement>();
  const newTagInputRef = React.createRef<HTMLInputElement>();
  const editTagInputRef = React.createRef<HTMLInputElement>();
  const selectTagGroupInputRed = React.createRef<HTMLInputElement>();

  const onOpenWrapper = React.useCallback(() => {
    setOpen();
    if (onOpen) onOpen();
  }, [setOpen, onOpen]);

  const onCloseWrapper = React.useCallback(() => {
    setClose();
    if (onClose) onClose();
  }, [setClose, onClose]);

  const onToggleTagDescription = React.useCallback((state: boolean) => {
    setCloseOnBlur(state);
  }, []);

  let content;
  let initialFocusRef;
  switch (mode.type) {
    case "List":
      content = (
        <TagsList
          searchInputRef={searchInputRef}
          onDeleteTag={async (tag: Tag) => {
            if (
              onDeleteTag &&
              (await confirm({ header: `Delete "${tag.name}"?` }))
            ) {
              onDeleteTag(tag);
            }
          }}
          onClose={onCloseWrapper}
          onEditTagSaving={onEditTagSaving}
          isBulkAction={isBulkAction}
          onBackClick={onBackClick}
          onToggleTagDescription={onToggleTagDescription}
          {...props}
        />
      );
      initialFocusRef = searchInputRef;
      break;

    case "Edit":
      content = (
        <Edit
          onCancelling={onEditTagCancelling}
          onSaving={onEditTagSaving}
          tag={mode.tag}
          inputRef={editTagInputRef}
          error={error}
          clearError={clearError}
        />
      );
      initialFocusRef = editTagInputRef;
      break;

    case "New":
      initialFocusRef = newTagInputRef;
      content = (
        <New
          onCancelling={onNewTagCancelling}
          onSaving={onNewTagSaving}
          initialName={mode.initialName}
          inputRef={newTagInputRef}
          error={error}
          clearError={clearError}
        />
      );
      break;
    case "SelectGroup":
      initialFocusRef = selectTagGroupInputRed;
      content = (
        <SelectTagGroup
          tagGroups={tagGroups ?? []}
          error={error}
          onSelectTagGroup={onSelectTagGroup}
          onNewTagGroupSaving={onNewTagGroupSaving}
          onEditTagGroupSaving={onEditTagGroupSaving}
          onDeleteTagGroupSaving={onDeleteTagGroupSaving}
          onCloseManageTagGroupDialog={onCloseManageTagGroupDialog}
        />
      );
      break;
    default:
      const _exhaustiveCheck = mode;
      content = _exhaustiveCheck;
      break;
  }

  return (
    <Box>
      {confirmationDialog}
      <Popover
        placement="bottom-end"
        closeOnBlur={closeOnBlur}
        onOpen={onOpenWrapper}
        onClose={onCloseWrapper}
        {...props}
        initialFocusRef={initialFocusRef}
      >
        <PopoverTrigger>
          <Button
            isActive={isOpen}
            leftIcon={<Icon as={RiPriceTag3Line} />}
            variant="banner"
            {...buttonProps}
          >
            Tag
          </Button>
        </PopoverTrigger>
        <PopoverContent maxH="60vh" overflowY="auto">
          {content}
        </PopoverContent>
      </Popover>
    </Box>
  );
};
