import { Box, Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { DisclaimerText } from "src/components/Text/DisclaimerText";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { UPSERT_FORM_DISCLAIMER } from "src/scenes/parent/forms/graphql/mutations";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { Answer } from "src/services/formTemplate";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { GET_FORM_VIEW_BY_ID } from "../../graphql/queries";

type Props = {
  formId: uuid;
  disclaimerSection: AF.DisclaimerSection<AF.WithId>;
  formDisclaimer?: GQL.FormDisclaimerFragment;
  onCloseEdit: () => void;
};
export const DisclaimerSectionEditForm: React.FC<Props> = ({
  formId,
  disclaimerSection,
  formDisclaimer,
  onCloseEdit,
}) => {
  const [upsertDisclaimer, upsertDisclaimerData] = useRemoteDataMutation<
    GQL.UpsertFormDisclaimer,
    GQL.UpsertFormDisclaimerVariables
  >(UPSERT_FORM_DISCLAIMER);

  const initialDisclaimer = {
    signature: formDisclaimer?.signature || "",
  };

  const toast = useAvelaToast();
  const submitHandler = async (answers: Answer.FormikValues) => {
    try {
      await upsertDisclaimer({
        variables: {
          form_id: formId,
          disclaimer_section_id: disclaimerSection.id,
          signature: answers.signature?.toString() ?? "",
        },
        refetchQueries: [GET_FORM_VIEW_BY_ID, GET_FORM_ANSWERS_BY_ID],
        awaitRefetchQueries: true,
      });

      onCloseEdit();
    } catch (error: unknown) {
      console.error(error);
      toast.error({ title: "Error updating form" });
    }
  };

  return (
    <Formik<Answer.FormikValues>
      initialValues={initialDisclaimer}
      onSubmit={submitHandler}
    >
      {(formikProps) => {
        return (
          <Flex direction="column" as={Form} noValidate gap={3}>
            <Box>
              <DisclaimerText text={disclaimerSection.disclaimer} />

              <FormControl>
                <FormLabel htmlFor="signature">
                  Please enter your full name here to confirm your understanding
                  of the above:
                </FormLabel>
                <InputControl id="signature" name="signature" />
              </FormControl>
            </Box>
            <Flex justifyContent="flex-end" gap={3}>
              <Button
                type="button"
                variant="ghost"
                colorScheme="gray"
                onClick={onCloseEdit}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isDisabled={!formikProps.isValid}
                isLoading={upsertDisclaimerData.remoteData.isLoading()}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
};
