import { Credentials } from "./types";
import { WebhookFormType } from "./WebhookForm";

export const mapCredentials = (webhook: WebhookFormType): Credentials => {
  if (webhook.credentials_type === "api_key")
    return {
      type: webhook.credentials_type,
      api_key_header: webhook.api_key_header,
      api_key: webhook.api_key,
    };
  if (webhook.credentials_type === "basic_auth")
    return {
      type: webhook.credentials_type,
      basic_username: webhook.basic_username,
      basic_password: webhook.basic_password,
    };
  if (webhook.credentials_type === "bearer_token")
    return {
      type: webhook.credentials_type,
      bearer_token: webhook.bearer_token,
    };
  if (webhook.credentials_type === "custom")
    return {
      type: webhook.credentials_type,
      custom_headers: webhook.custom_headers,
    };

  return {
    type: "none",
  };
};
