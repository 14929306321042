import * as GQL from "src/types/graphql";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import { GET_PREVIOUS_FORM_SCHOOLS } from "src/scenes/parent/forms/graphql/queries";
import { useMemo } from "react";

export function usePreviousFormSchools(formId: uuid | undefined) {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetPreviousFormSchools,
    GQL.GetPreviousFormSchoolsVariables
  >(GET_PREVIOUS_FORM_SCHOOLS, {
    skip: !formId,
    variables: {
      form_id: formId ?? "",
    },
  });

  const previousFormSchoolIds = useMemo(() => {
    return remoteData.map(getPreviousFormSchools);
  }, [remoteData]);

  return previousFormSchoolIds;
}

export function getPreviousFormSchools(
  previousFormSchols: GQL.GetPreviousFormSchools
): uuid[] {
  if (!previousFormSchols.form_by_pk) {
    return [];
  }

  if (previousFormSchols.form_by_pk.previous_offer) {
    return [previousFormSchols.form_by_pk.previous_offer.school_id];
  }

  if (previousFormSchols.form_by_pk.previous_waitlist) {
    return [previousFormSchols.form_by_pk.previous_waitlist.school_id];
  }

  return (
    previousFormSchols.form_by_pk.previous_form?.form_school_ranks.map(
      (schoolRank) => schoolRank.school_id
    ) ?? []
  );
}
