import { gql } from "@apollo/client";

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation UpdateMessageTemplate($objects: message_template_insert_input!) {
    insert_message_template_one(
      object: $objects
      on_conflict: {
        update_columns: [
          email_markup
          email_plain_text
          email_subject
          sms_body
          enabled
        ]
        constraint: message_template_type_form_template_id_key
      }
    ) {
      id
      enabled
    }
  }
`;

export const UPDATE_MESSAGE_TEMPLATES = gql`
  mutation UpdateMessageTemplates(
    $messageTemplateInserts: [message_template_insert_input!] = []
    $messageTemplateUpdates: [message_template_updates!] = []
  ) {
    insert_message_template(objects: $messageTemplateInserts) {
      affected_rows
      returning {
        id
        type
      }
    }
    update_message_template_many(updates: $messageTemplateUpdates) {
      affected_rows
    }
  }
`;
