import {
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  ApiCredentialFormType,
  emptyApiCredential as emptyApiCredentialForm,
} from "src/schemas/ApiCredential";
import { UserGroupType } from "src/schemas/TeamMember";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ApiCredentialForm } from "../components/Forms/ApiCredentialForm";
import { GET_USER_GROUPS } from "../team/graphql/queries";
import { CREATE_M2M_CREDENTIAL } from "./graphql/mutations";

type ApiCredentialResult = {
  client_name: string;
  user_group: string;
  client_id: string | null | undefined;
  client_secret: string | null | undefined;
};

export const NewApiCredential = () => {
  const toast = useToast();
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupType>();
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState<ApiCredentialResult | null>(null);
  const [createM2MCredential] = useRemoteDataMutation<
    GQL.CreateM2MCredential,
    GQL.CreateM2MCredentialVariables
  >(CREATE_M2M_CREDENTIAL);
  const organization = useOrganization();

  const { remoteData: userGroupData } = useRemoteDataQuery<GQL.GetUserGroups>(
    GET_USER_GROUPS,
    { fetchPolicy: "no-cache" }
  );

  const handleSubmit = useCallback(
    async (value: ApiCredentialFormType) => {
      setSubmitting(true);
      try {
        if (!organization.hasData()) throw Error("Invalid organization.");
        if (!selectedUserGroup) throw new Error("no selected user group");

        const result = await createM2MCredential({
          variables: {
            client_name: value.client_name,
            organization_id: organization.data.id,
            user_group_id: selectedUserGroup.id,
          },
        });
        toast({
          id: "create-api-credential",
          title: "API credential created",
          isClosable: true,
          status: "info",
        });

        setResult({
          client_name: value.client_name,
          user_group: selectedUserGroup.label,
          client_id: result.data?.create_m2m_credential?.client_id,
          client_secret: result.data?.create_m2m_credential?.client_secret,
        });
      } catch (err) {
        const description = (): string => {
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "API credential already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to create API credential.";
            }
          }
          console.error(err);
          return "Check your network and try again.";
        };
        toast({
          id: "create-api-credential",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [createM2MCredential, toast, organization, selectedUserGroup]
  );

  if (userGroupData.isLoading()) {
    return <Loading></Loading>;
  }
  return (
    <GQLRemoteDataView remoteData={userGroupData}>
      {(userGroupData) => {
        return (
          <>
            <Breadcrumb
              items={breadcrumb.dataServices.getBreadcrumbsForNewApiCredential(
                organization
              )}
              mb={8}
            />

            <Formik<ApiCredentialFormType>
              initialValues={emptyApiCredentialForm}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Flex as={Form} direction="column" gap={6}>
                    <Heading as="h1" fontSize="2xl" fontWeight="600">
                      Create API Credential
                    </Heading>
                    {result ? (
                      <Flex direction="column" gap={2}>
                        <Text fontWeight={"600"}>Client Name</Text>
                        <Text ml={6}>{result.client_name}</Text>

                        <Text fontWeight={"600"}>User Group</Text>
                        <Text ml={6}>{result.user_group}</Text>

                        <Divider mt={6} mb={6}></Divider>

                        <Text fontStyle={"italic"} mb={6}>
                          Client secrets will onlly show once here. Please
                          securely save the information below.
                        </Text>

                        <Text fontWeight={"600"}>Client ID</Text>
                        <Text ml={6}>{result.client_id}</Text>

                        <Text fontWeight={"600"}>Client Secret</Text>
                        <Text ml={6}>{result.client_secret}</Text>
                      </Flex>
                    ) : (
                      <ApiCredentialForm
                        onChangeUserRole={setSelectedUserGroup}
                        userGroups={userGroupData.user_group}
                      />
                    )}
                    <Flex align="center" gap={6}>
                      <Spacer />
                      {result ? (
                        <>
                          <Button
                            as={NavLink}
                            to={organization
                              .map((org) => Url.OrgAdmin.Team.index(org))
                              .withDefault("#")}
                            isLoading={organization.isLoading()}
                            variant="link"
                          >
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            as={NavLink}
                            to={organization
                              .map((org) => Url.OrgAdmin.Team.index(org))
                              .withDefault("#")}
                            isLoading={organization.isLoading()}
                            variant="link"
                          >
                            Cancel
                          </Button>
                          <Button type="submit" isLoading={submitting}>
                            Create
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Flex>
                );
              }}
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
