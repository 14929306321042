import { ApolloError, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import organizationContacts from "../data/organizations/contacts.json";
import * as GQL from "../types/graphql";
import * as RemoteData from "../types/remoteData";
import { useRemoteDataQuery } from "./useRemoteDataQuery";

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on organization {
    id
    name
    path
    timezone_name
  }
`;

export const GET_ORGANIZATION_ID = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganizationId($path: String!) {
    organization(where: { path: { _eq: $path } }, limit: 1) {
      ...Organization
    }
  }
`;

export type UseOrganizationType = RemoteData.RemoteData<
  OrganizationError,
  GQL.Organization
>;

export type OrganizationError = ServerError | NotFoundError;
export class ServerError {
  readonly kind = "ServerError";
  constructor(public error: ApolloError) {}
}
export class NotFoundError {
  readonly kind = "NotFoundError";
}

export function isOrganizationError(
  error: unknown
): error is OrganizationError {
  return error instanceof ServerError || error instanceof NotFoundError;
}

export function useOrganization(): UseOrganizationType {
  const { organization } = useParams();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetOrganizationId,
    GQL.GetOrganizationIdVariables
  >(GET_ORGANIZATION_ID, {
    variables: { path: organization ?? "" },
    skip: organization === undefined,
  });

  return remoteData
    .mapError<OrganizationError>((error) => new ServerError(error))
    .andThen((data) => {
      const organizationData = data.organization[0];
      if (organizationData === undefined)
        return RemoteData.failure(new NotFoundError());

      return RemoteData.success(organizationData);
    });
}

export function useTimezoneName(): string {
  const organization = useOrganization();
  const timezoneName: string = organization
    .map((o) => o.timezone_name)
    .withDefault("+00:00");
  return timezoneName;
}

// stubbed function to return data from contacts.json
export const useOrganizationContacts = (
  organizationPath: string | undefined
) => {
  const organizationContact = organizationContacts.organization.find(
    (org) => org.path === organizationPath
  );

  if (!organizationContact) return [];

  return organizationContact.contacts;
};

export function hasOrganizations(
  organization: UseOrganizationType,
  ...orgPaths: string[]
) {
  if (organization.hasData()) {
    return orgPaths.some((path) => path === organization.data.path);
  }

  return false;
}
