import { Divider, HStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import {
  RiAddFill,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSubtractLine,
} from "react-icons/ri";
import { DocDownloadButton } from "./buttons/DocDownloadButton";
import { DocViewerButton } from "./buttons/DocViewerButton";
import { DocViewerContext } from "./state";
import {
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  ROTATION_INCREMENTS,
  setCurrentPage,
  setRotation,
  setZoomLevel,
  ZOOM_LEVEL_INCREMENTS,
} from "./state/actions";

type DocViewerControlsProps = {
  uri?: string;
};

export const DocViewerControls = ({ uri }: DocViewerControlsProps) => {
  const {
    state: { zoomLevel, rotation, numPages, currentPage },
    dispatch,
  } = useContext(DocViewerContext);

  return (
    <HStack spacing={2} fontSize="xs" fontWeight="600">
      <DocViewerButton
        ariaLabel="Previous page"
        disabled={currentPage <= 1}
        onClick={() => dispatch(setCurrentPage(currentPage - 1))}
        icon={RiArrowLeftSLine}
      />
      <Text minWidth={10} width={10} align="center">
        {numPages > 0 ? `${currentPage} of ${numPages}` : "-"}
      </Text>
      <DocViewerButton
        ariaLabel="Next page"
        disabled={currentPage >= numPages}
        onClick={() => dispatch(setCurrentPage(currentPage + 1))}
        icon={RiArrowRightSLine}
      />

      <Divider orientation="vertical" />
      <DocViewerButton
        ariaLabel="Zoom in"
        disabled={zoomLevel >= MAX_ZOOM_LEVEL}
        onClick={() =>
          dispatch(setZoomLevel(zoomLevel + ZOOM_LEVEL_INCREMENTS))
        }
        icon={RiAddFill}
      />
      <Text align="center">{`${Math.round(zoomLevel * 100)}%`}</Text>
      <DocViewerButton
        ariaLabel="Zoom out"
        disabled={zoomLevel <= MIN_ZOOM_LEVEL}
        onClick={() =>
          dispatch(setZoomLevel(zoomLevel - ZOOM_LEVEL_INCREMENTS))
        }
        icon={RiSubtractLine}
      />

      <Divider orientation="vertical" />

      <DocViewerButton
        ariaLabel="Rotate left"
        onClick={() => dispatch(setRotation(rotation - ROTATION_INCREMENTS))}
        icon={RiArrowGoBackLine}
      />
      <DocViewerButton
        ariaLabel="Rotate right"
        onClick={() => dispatch(setRotation(rotation + ROTATION_INCREMENTS))}
        icon={RiArrowGoForwardLine}
      />

      <Divider orientation="vertical" />

      {uri && <DocDownloadButton href={uri} />}
    </HStack>
  );
};
