import { formatInTimeZone, fromZonedTime } from "date-fns-tz";
import { OFFER_STATUS } from "src/constants";
import * as GQL from "src/types/graphql";
import {
  FormTemplateRuleType,
  FormTemplateSettingsType,
  FormTemplateStatusRuleType,
  WhatShouldHappenEnum
} from "./schemas";

type FormTemplateRulesType =
  GQL.GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules[];
type StatusRuleType =
  GQL.GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules_status_rule | null;

const getRuleStatusOrSubstatus = (rule: StatusRuleType) => {
  if (!rule) return null;

  if (rule.form_status) {
    return rule.form_status;
  }

  if (rule.waitlist_status) {
    return rule.waitlist_status;
  }
  if (rule.offer_status) {
    return rule.offer_status;
  }
};

// Update this function when we have multiple rules.
const toRulesInitialValues = (rules: FormTemplateRulesType) => {
  return rules.length
    ? rules.map((rule) => {
        switch (rule.type) {
          case GQL.rule_type_enum.StatusRule:
            return {
              id: rule.id,
              type: GQL.rule_type_enum.StatusRule,
              whatShouldHappen: WhatShouldHappenEnum.SubmitAndContinue,
              nextTemplateId: rule.status_rule?.next_form_template_id,
              statusOrSubstatus: getRuleStatusOrSubstatus(rule.status_rule)
            };
          default:
            return null;
        }
      })
    : [
        {
          type: GQL.rule_type_enum.StatusRule,
          whatShouldHappen: WhatShouldHappenEnum.Submit,
          nextTemplateId: undefined,
          statusOrSubstatus: GQL.form_status_enum.InProgress
        }
      ];
};

const getFormTemplateRuleUpdates = (formTemplateRule: FormTemplateRuleType) => {
  if (!formTemplateRule) return null;

  const { rules } = formTemplateRule;

  const removedRules = rules.filter(
    (rule) => rule?.whatShouldHappen === WhatShouldHappenEnum.Submit && rule.id
  );
  const addedRules = rules
    .filter(
      (rule) =>
        rule?.whatShouldHappen === WhatShouldHappenEnum.SubmitAndContinue
    )
    .map((updatedRule) =>
      updatedRule?.id
        ? updatedRule
        : { ...updatedRule, id: window.crypto.randomUUID() }
    );

  // Nothing changed.
  if (addedRules.length === 0 && removedRules.length === 0) return null;

  // All rules removed.
  if (removedRules.length === rules.length) {
    return { id: null, removedRules, addedRules };
  }

  // Rules added or updated and might have some removals.
  // If if the first rule, create a randomUUID for form_template_rule.id.
  return {
    id: formTemplateRule.id ?? window.crypto.randomUUID(),
    removedRules,
    addedRules
  };
};

const toGQLStatusRule = (
  rule: FormTemplateStatusRuleType
): GQL.status_rule_insert_input => {
  if (!rule) return {};

  const { id, nextTemplateId, statusOrSubstatus } = rule;

  switch (statusOrSubstatus) {
    case GQL.form_status_enum.InProgress:
    case GQL.form_status_enum.Submitted:
    case GQL.form_status_enum.Verified:
    case GQL.form_status_enum.LotteryReady:
    case GQL.form_status_enum.Admissions:
      return {
        rule_id: id,
        next_form_template_id: nextTemplateId,
        type: GQL.status_rule_type_enum.FormStatus,
        form_status: statusOrSubstatus,
        waitlist_status: null,
        offer_status: null
      };
    case GQL.waitlist_status_enum.Waitlisted:
    case GQL.waitlist_status_enum.Withdrawn:
      return {
        rule_id: id,
        next_form_template_id: nextTemplateId,
        type: GQL.status_rule_type_enum.WaitlistStatus,
        form_status: null,
        waitlist_status: statusOrSubstatus,
        offer_status: null
      };
    case GQL.offer_status_enum.Offered:
    case GQL.offer_status_enum.Accepted:
    case GQL.offer_status_enum.Declined:
      return {
        rule_id: id,
        next_form_template_id: nextTemplateId,
        type: GQL.status_rule_type_enum.OfferStatus,
        form_status: null,
        waitlist_status: null,
        offer_status: statusOrSubstatus
      };
    default:
      return {};
  }
};

export const toInitialValues = (
  formTemplateByPk: GQL.GetFormTemplateSettingsById_form_template_by_pk,
  timezoneName: string
): FormTemplateSettingsType => {
  const {
    active,
    locked,
    name,
    key,
    description,
    open_at,
    closed_at,
    offer_start_at,
    lottery_offers_enabled,
    reopen_at,
    form_template_rule
  } = formTemplateByPk ?? {};

  const openAt = open_at
    ? formatInTimeZone(open_at, timezoneName, "yyyy-MM-dd HH:mm")
    : "";

  const closedAt = closed_at
    ? formatInTimeZone(closed_at, timezoneName, "yyyy-MM-dd HH:mm")
    : "";

  const offerStartAt = offer_start_at
    ? formatInTimeZone(offer_start_at, timezoneName, "yyyy-MM-dd HH:mm")
    : "";

  const reopenAt = reopen_at
    ? formatInTimeZone(reopen_at, timezoneName, "yyyy-MM-dd HH:mm")
    : "";

  const formTemplateRule = form_template_rule?.rules ?? [];

  return {
    active: !!active,
    locked: !!locked,
    name: name ?? "",
    key: key ?? "",
    description: description ?? "",
    openAt,
    closedAt,
    offerStartAt,
    reopenAt,
    lotteryAndOffersEnabled: lottery_offers_enabled ?? false,
    formTemplateRule: {
      id: form_template_rule?.id ?? undefined,
      // TODO FUTURE: Add operator and iterate over all rules from attached forms.
      rules: toRulesInitialValues(formTemplateRule)
    }
  };
};

export const toGQLFormTemplateSettings = (
  values: FormTemplateSettingsType,
  timezoneName: string
) => {
  const {
    active,
    locked,
    name,
    key,
    description,
    openAt,
    closedAt,
    offerStartAt,
    lotteryAndOffersEnabled,
    reopenAt
  } = values;

  const open_at = openAt
    ? fromZonedTime(`${openAt}`, timezoneName)?.toISOString()
    : null;

  const closed_at = closedAt
    ? fromZonedTime(`${closedAt}`, timezoneName)?.toISOString()
    : null;

  const offer_start_at =
    lotteryAndOffersEnabled && offerStartAt
      ? fromZonedTime(`${offerStartAt}`, timezoneName)?.toISOString()
      : null;

  const reopen_at = reopenAt
    ? fromZonedTime(`${reopenAt}`, timezoneName)?.toISOString()
    : null;

  return {
    active,
    locked,
    name,
    key,
    description,
    open_at,
    closed_at,
    offer_start_at,
    lottery_offers_enabled: lotteryAndOffersEnabled,
    reopen_at
  };
};

export const toGQLFormTemplateRule = (
  formTemplateRule: FormTemplateRuleType
):
  | {
      rootFormTemplateRuleId: string | null;
      upsertFormTemplateRule: GQL.form_template_rule_insert_input[];
      upsertRules: GQL.rule_insert_input[];
      upsertStatusRules: GQL.status_rule_insert_input[];
      deletedRules: uuid[];
      deletedFormTemplateRules: uuid[];
    }
  | undefined => {
  const formTemplateRuleUpdates = getFormTemplateRuleUpdates(formTemplateRule);

  if (!formTemplateRuleUpdates) return undefined;

  const { id, removedRules, addedRules } = formTemplateRuleUpdates;

  return {
    rootFormTemplateRuleId: id,
    // FUTURE TODO: Add operator and iterate over all rules from attached forms.
    upsertFormTemplateRule: id
      ? [
          {
            id,
            operator: GQL.operator_enum.or
          }
        ]
      : [],
    upsertRules: addedRules.map((rule) => ({
      id: rule?.id,
      type: rule?.type,
      form_template_rule_id: id
    })),
    upsertStatusRules: addedRules.map((rule) => toGQLStatusRule(rule)),
    deletedRules: removedRules.map((rule) => rule?.id ?? ""),
    deletedFormTemplateRules: !id ? [formTemplateRule?.id ?? ""] : []
  };
};

export const FormTemplateWhatShouldHappenOptions = [
  {
    label: "Submit",
    value: WhatShouldHappenEnum.Submit
  },
  {
    label: "Submit and continue to another form",
    value: WhatShouldHappenEnum.SubmitAndContinue
  }
];

export const FormTemplateRuleTypes = [
  {
    label: "Status or Sub-status",
    value: GQL.rule_type_enum.StatusRule
  }
];

type Option = {
  label: string;
  key: string;
  value: string;
  disabled?: boolean;
  isSubStatus?: boolean;
};

export const FormTemplateStatusRuleOptions: Option[] = [
  {
    label: OFFER_STATUS[GQL.offer_status_enum.Accepted].label,
    value: GQL.offer_status_enum.Accepted,
    key: GQL.offer_status_enum.Accepted,
    isSubStatus: true
  }
];
