import { Box } from "@chakra-ui/react";
import React from "react";
import { useOrganization } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDateTime } from "src/services/format";

type Props = { timestamp: string };
export const Timestamp: React.FC<Props> = ({ timestamp }) => {
  const org = useOrganization();
  return (
    <Box color="gray.500" fontSize="xs">
      {formatIsoDateToOrgDateTime(timestamp, org)}
    </Box>
  );
};
