import Immutable from "immutable";
import * as AF from "src/types/formTemplate";
import { z } from "zod";
import * as Draft from "./draft";

export type Change<T, V = T> = {
  readonly original: T;
  readonly draft: V | undefined;
};

export type ChangeWithDraft<T, V = T> = {
  readonly original: T;
} & WithDraft<V>;

export type QuestionChange = Change<AF.Question<AF.WithId>, Draft.Question>;
export type DisclaimerChange = Change<
  AF.DisclaimerSection<AF.WithId>,
  Draft.Disclaimer
>;
export type SectionChange = Change<AF.Section<AF.WithId>, Draft.Section>;

type NewVerification = {
  readonly questionId: uuid;
  readonly verificationLabel: string;
};

export type QuestionId = Immutable.Record<{ questionId: uuid }>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionId = (questionId: uuid) =>
  Immutable.Record<{ questionId: uuid }>({
    questionId
  })({ questionId });
export type SectionId = Immutable.Record<{ sectionId: uuid }>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionId = (sectionId: uuid) =>
  Immutable.Record<{ sectionId: uuid }>({
    sectionId
  })({ sectionId });
export type SortedQuestions = Immutable.Map<
  SectionId,
  Immutable.List<QuestionId>
>;

export type SortedSections = Immutable.List<SectionId>;

type StateInternal = {
  questions: Immutable.Map<QuestionId, QuestionChange>;
  newQuestions: Immutable.Map<QuestionId, Draft.NewQuestion>;
  disclaimers: Immutable.Map<SectionId, DisclaimerChange>;
  sortedQuestions: SortedQuestions;
  save: uuid | undefined;
  reset: uuid | undefined;
  newVerification: NewVerification | undefined;
  sections: Immutable.Map<SectionId, SectionChange>;
  newSections: Immutable.Map<SectionId, Draft.NewSection>;
  sortedSections: SortedSections;
};
export type State = Immutable.Record<StateInternal>;
export const NewState = Immutable.Record<StateInternal>({
  questions: Immutable.Map(),
  disclaimers: Immutable.Map(),
  newQuestions: Immutable.Map(),
  sortedQuestions: Immutable.Map(),
  save: undefined,
  reset: undefined,
  newVerification: undefined,
  sections: Immutable.Map(),
  newSections: Immutable.Map(),
  sortedSections: Immutable.List()
});
/**
 * Local storage state
 */
export type WithDraft<T> = { readonly draft: T };
// type WithQuestionDraft = Readonly<WithDraft<AF.Question<AF.WithId>>>;
// type WithDisclaimerDraft = Readonly<WithDraft<AF.DisclaimerSection<AF.WithId>>>;
export const LocalstorageSchema = z.object({
  questions: z.record(z.string().uuid(), z.object({ draft: z.any() })),
  disclaimers: z.record(z.string().uuid(), z.object({ draft: z.any() })),
  newQuestions: z.record(z.string().uuid(), z.any()),
  sortedQuestions: z.record(z.string().uuid(), z.array(z.string())),
  sections: z.record(z.string().uuid(), z.object({ draft: z.any() })),
  newSections: z.record(z.string().uuid(), z.any()),
  sortedSections: z.array(z.string())
});

export type LocalstorageState = z.infer<typeof LocalstorageSchema>;

export function serialize(state: State): LocalstorageState {
  // only keep questions with non empty draft
  const questions = state
    .get("questions")
    .map((change) => {
      return { draft: change.draft } as const;
    })
    .filter((change): change is WithDraft<Draft.Question> => {
      return change.draft !== undefined;
    })
    .mapKeys((key) => key.get("questionId"))
    .toObject();

  // only keep disclaimers with non empty draft
  const disclaimers = state
    .get("disclaimers")
    .map((change) => ({ draft: change.draft } as const))
    .mapKeys((key) => key.get("sectionId"))
    .filter((change): change is WithDraft<Draft.Disclaimer> => {
      return change.draft !== undefined;
    })
    .toObject();

  // save newQuestions
  const newQuestions = state
    .get("newQuestions")
    .mapKeys((key) => key.get("questionId"))
    .toObject();

  // save sorted order of questions
  const sortedQuestions = state
    .get("sortedQuestions")
    .map((questionIds) =>
      questionIds.map((key) => key.get("questionId")).toArray()
    )
    .mapKeys((key) => key.get("sectionId"))
    .toObject();

  // only keep sections with non empty draft
  const sections = state
    .get("sections")
    .map((change) => ({ draft: change.draft } as const))
    .filter((change): change is WithDraft<Draft.Section> => {
      return change.draft !== undefined;
    })
    .mapKeys((key) => key.get("sectionId"))
    .toObject();

  const newSections = state
    .get("newSections")
    .mapKeys((key) => key.get("sectionId"))
    .toObject();

  // save sorted order of sections
  const sortedSections = state
    .get("sortedSections")
    .map((sectionId) => sectionId.toObject().sectionId)
    .toArray();

  const localStoroageState: LocalstorageState = {
    questions,
    disclaimers,
    newQuestions,
    sortedQuestions,
    sections,
    newSections,
    sortedSections
  };

  return localStoroageState;
}

export function deserialize(state: State, json: unknown): State {
  const result = LocalstorageSchema.safeParse(json);
  const savedState: LocalstorageState | undefined = result.success
    ? result.data
    : undefined;

  if (!savedState) {
    return state;
  }

  return NewState({
    questions: state.get("questions").map((change, key) => {
      return {
        ...change,
        draft: savedState.questions[key.get("questionId")]?.draft as
          | Draft.Question
          | undefined
      };
    }),
    disclaimers: state.get("disclaimers").map((change, key) => {
      return {
        ...change,
        draft: savedState.disclaimers[key.get("sectionId")]?.draft as
          | Draft.Disclaimer
          | undefined
      };
    }),
    newQuestions: Immutable.Map<QuestionId, Draft.NewQuestion>(
      Object.keys(savedState.newQuestions).map((key) => {
        return [QuestionId(key), savedState.newQuestions[key]];
      })
    ),
    sortedQuestions: Immutable.Map<SectionId, Immutable.List<QuestionId>>(
      Object.keys(savedState.sortedQuestions).map((sectionId) => {
        return [
          SectionId(sectionId),
          Immutable.List(
            savedState.sortedQuestions[sectionId]?.map(QuestionId) ?? []
          )
        ];
      })
    ),
    sections: state.get("sections").map((change, key) => {
      return {
        ...change,
        draft: savedState.sections[key.get("sectionId")]?.draft as
          | Draft.Section
          | undefined
      };
    }),
    newSections: Immutable.Map<SectionId, Draft.NewSection>(
      Object.keys(savedState.newSections).map((key) => {
        return [SectionId(key), savedState.newSections[key]];
      })
    ),
    sortedSections: Immutable.List<SectionId>(
      savedState.sortedSections.map((sectionId) => SectionId(sectionId))
    )
  });
}
