import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ProgramGroupForm } from "./Form";
import { UPDATE_PROGRAM_GROUP } from "./graphql/mutations";
import { GET_PROGRAM_GROUPS, GET_PROGRAM_GROUP_BY_PK } from "./graphql/queries";
import { ProgramGroupType, validate } from "./types/program";

export const Edit = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetProgramGroupByPk,
    GQL.GetProgramGroupByPkVariables
  >(GET_PROGRAM_GROUP_BY_PK, {
    variables: { program_group_id: id },
  });
  const [updateProgramGroup, { remoteData: updatedProgramGroup }] =
    useRemoteDataMutation<
      GQL.UpdateProgramGroup,
      GQL.UpdateProgramGroupVariables
    >(UPDATE_PROGRAM_GROUP);

  const handleSubmit = React.useCallback(
    async (programGroup: ProgramGroupType) => {
      try {
        if (!validate(programGroup)) {
          throw new Error(
            "Program group is invalid. Make sure all fields are non-empty."
          );
        }
        await updateProgramGroup({
          variables: {
            program_group_id: id,
            program_group_set: {
              organization_id: programGroup.organization_id,
              name: programGroup.name,
            },
            programs: programGroup.programs.map((program) => ({
              program_group_id: id,
              ...program,
            })),
            programs_to_delete_ids: programGroup.programs_to_delete_ids ?? [],
          },
          refetchQueries: [GET_PROGRAM_GROUPS],
        });
        toast({
          id: "UpdatedProgramGroup",
          title: "Program group updated",
          isClosable: true,
          status: "success",
        });
        navigate(Url.Admin.Programs.index());
      } catch (error: any) {
        console.error(error);
        toast({
          id: "UpdatedProgramGroup",
          title: "Error updating program group",
          description: error.message,
          isClosable: true,
          status: "error",
        });
      }
    },
    [id, navigate, toast, updateProgramGroup]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.program_group_by_pk === null) {
          return <NotFound />;
        }
        return (
          <ProgramGroupForm
            initialProgramGroup={data.program_group_by_pk}
            onSubmit={handleSubmit}
            submitting={updatedProgramGroup.isLoading()}
          />
        );
      }}
    </GQLRemoteDataView>
  );
};
