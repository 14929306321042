import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { Boundaries } from "src/services/url/Admin";
import { CreateBoundaryGroupPage } from "./CreateBoundaryGroupPage";
import { ListBoundaryGroupsPage } from "./ListBoundaryGroupsPage";

export const AdminBoundariesRoutes = (
  <Route
    path={Boundaries.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.boundaries">
          <ListBoundaryGroupsPage />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={Boundaries.newPath}
      element={
        <RequireUserPermissions permission="boundaries:create">
          <CreateBoundaryGroupPage />
        </RequireUserPermissions>
      }
    />
  </Route>
);
