import { Text } from "@chakra-ui/react";
import { GenericError } from "src/components/Feedback/GenericError";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import * as Format from "src/services/format";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";

type EligibilityProps = {
  option: AF.Option<AF.WithId>;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const Eligibility: React.FC<EligibilityProps> = ({
  option,
  schools,
}) => {
  switch (option.eligibilityFilter) {
    case "NotApplicable":
    case undefined:
      return null;

    case "Eligible":
    case "NotEligible":
      return (
        <RemoteDataView remoteData={schools} error={() => <GenericError />}>
          {(schools) => {
            return (
              <Text as="dd" marginLeft="8" fontSize="xs" color="blackAlpha.700">
                {toEligiblityLabel(option, schools)}
              </Text>
            );
          }}
        </RemoteDataView>
      );

    default:
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
  }
};
function toEligiblityLabel(
  option: AF.Eligible | AF.NotEligible,
  schools: SchoolItem[]
): string {
  switch (option.eligibilityFilter) {
    case "Eligible":
      return `Includes schools: ${toSchoolNames(
        option.eligibleSchoolIds,
        schools
      )}`;

    case "NotEligible":
      return `Excludes schools: ${toSchoolNames(
        option.notEligibleSchoolIds,
        schools
      )}`;

    default:
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
  }
}
function toSchoolNames(schoolIds: uuid[], schools: SchoolItem[]): string {
  return Format.oxfordComma(
    schoolIds.flatMap((schoolId) => {
      const school = schools.find((school) => school.id === schoolId);
      if (!school) {
        return [];
      }

      return [school.label];
    })
  );
}
