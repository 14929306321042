import { Branding } from "../schemas/Branding";

const CDN_BASE_URL = "https://dz5s42cjdcdej.cloudfront.net"; // TODO: use env variable to determine the right URL for CDN
export const DefaultBranding: Branding = {
  colors: {
    primary: {
      "50": "#ECEEF8",
      "100": "#CBCEEC",
      "200": "#A9AFDF",
      "300": "#888FD3",
      "400": "#6670C7",
      "500": "#4551BA",
      "600": "#374195",
      "700": "#293070",
      "800": "#1B204B",
      "900": "#0E1025",
    },
  },
  logo: {
    white: {
      url: `${CDN_BASE_URL}/avela-logo.svg`,
      alt: "Avela Logo",
    },
    color: {
      url: `${CDN_BASE_URL}/avela-logo-color.svg`,
      alt: "Avela Logo",
    },
  },
};
