import { HasuraRole } from "src/types/hasuraRole";
import { z } from "zod";
import * as GQL from "../types/graphql";

export const SchoolSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type SchoolType = z.infer<typeof SchoolSchema>;

export const UserGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  label: z.string(),
  person_type: z.nativeEnum(GQL.person_type_enum),
});

export type UserGroupType = z.infer<typeof UserGroupSchema>;

export const TeamSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type TeamType = z.infer<typeof TeamSchema>;

export const PersonTeamSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type PersonTeamType = z.infer<typeof PersonTeamSchema>;

export const createEmptyTeamMember = (
  permission: "school:all" | "school:attending" | "school:applying"
): TeamMemberFormType => ({
  first_name: "",
  last_name: "",
  email_address: "",
  user_group_id: "",
  enable_applying_schools: ["school:all", "school:applying"].includes(
    permission
  ),
  enable_attending_schools: ["school:all", "school:attending"].includes(
    permission
  ),
});

export const toAccessPermission = (
  value: TeamMemberFormType,
  role: HasuraRole
): string => {
  // Coerce regular school admins, who are by definition applying only admins.
  if (role === HasuraRole.SCHOOL_ADMIN) return "school:applying";
  else if (value.enable_attending_schools && value.enable_applying_schools)
    return "school:all";
  else if (!value.enable_attending_schools && value.enable_applying_schools)
    return "school:applying";
  else if (value.enable_attending_schools && !value.enable_applying_schools)
    return "school:attending";
  else return "";
};

export const parseAccessPermission = (
  values: { school_access_permission: string | null }[]
): { enable_attending_schools: boolean; enable_applying_schools: boolean } => {
  if (values.some((x) => x.school_access_permission === "school:all"))
    return { enable_applying_schools: true, enable_attending_schools: true };
  else if (
    values.some((x) => x.school_access_permission === "school:attending")
  )
    return { enable_applying_schools: false, enable_attending_schools: true };
  else if (values.some((x) => x.school_access_permission === "school:applying"))
    return { enable_applying_schools: true, enable_attending_schools: false };
  else
    return { enable_applying_schools: false, enable_attending_schools: false };
};

export const TeamMemberSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email_address: z.string(),
  user_group_id: z.string().nullable(),
  enable_attending_schools: z.boolean(),
  enable_applying_schools: z.boolean(),
});

export const TeamMemberValidator = {};

export type TeamMemberFormType = z.infer<typeof TeamMemberSchema>;
