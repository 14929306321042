import { Flex, Grid, ListItem } from "@chakra-ui/react";
import * as FormSchoolRankDetail from "./FormSchoolRankDetail";

export type Form = FormSchoolRankDetail.Form;
export type Offer = FormSchoolRankDetail.Offer;
export type RankedSchool = FormSchoolRankDetail.RankedSchool & {
  readonly rank: number;
};
export type School = FormSchoolRankDetail.School;
export type Waitlist = FormSchoolRankDetail.Waitlist;
interface FormSchoolRankProps {
  formTemplateId: uuid;
  acceptedOfferSchoolName: string | undefined;
  form: Form;
  offer: Offer | null;
  formSchoolRank: RankedSchool;
  waitlist: Waitlist | null;
  gradeLabel: string;
  school: School;
  studentName: string;
  rankingEnabled: boolean;
}

export function FormSchoolRank(props: FormSchoolRankProps) {
  const { formSchoolRank, rankingEnabled } = props;

  // ListItem cannot have `display="flex"` or bullets won't show up
  return (
    <ListItem ml={rankingEnabled ? 0 : 5}>
      <Flex alignItems="center" gap={2}>
        {rankingEnabled && (
          <Grid
            alignSelf="stretch"
            borderInlineEndColor="gray.400"
            borderInlineEndStyle="solid"
            borderInlineEndWidth="1px"
            flexBasis={6}
            flexGrow={0}
            flexShrink={0}
            placeContent="center"
          >
            {formSchoolRank.rank + 1}
          </Grid>
        )}
        <FormSchoolRankDetail.FormSchoolRankDetail {...props} />
      </Flex>
    </ListItem>
  );
}
