import { Button } from "@chakra-ui/button";
import { Flex, Heading, HStack } from "@chakra-ui/layout";
import React from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { FETCH_MATCH_TEMPLATES } from "./graphql/queries";
import { MatchTemplateList } from "./List";

export const Index = (): React.ReactElement => {
  const organization = useOrganization();

  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.FetchMatchTemplates,
    GQL.FetchMatchTemplatesVariables
  >(FETCH_MATCH_TEMPLATES, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      order_by: { name: GQL.order_by.asc },
    },
    skip: !organization.toNullable()?.id,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Breadcrumb
        items={breadcrumb.matchTemplates.getBreadcrumbsForList(organization)}
      />
      <HStack width="100%" justifyContent="space-between">
        <Heading as="h1" size="xl">
          Match templates
        </Heading>
        <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
          <Button
            as={NavLink}
            to={organization
              .map((org) => Url.OrgAdmin.MatchTemplates.new(org))
              .withDefault("#")}
            size="xl"
          >
            Create new template
          </Button>
        </WithRequiredHasuraRoles>
      </HStack>
      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <MatchTemplateList
            matchTemplates={data.match_template}
            onRefetch={refetch}
          />
        )}
      </GQLRemoteDataView>
    </Flex>
  );
};

// export { Edit } from "./Edit";
export { New } from "./New";
