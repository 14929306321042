import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RiSurveyLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { ParentFormsLayoutInner } from "src/components/Layout/ParentFormsLayoutInner";
import { ParentFormsLayoutOuter } from "src/components/Layout/ParentFormsLayoutOuter";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { Empty } from "../students";
import { NoForms } from "./components/NoForms";
import { StudentFormTemplateCards } from "./components/StudentFormTemplateCards";
import { Glossary } from "src/components/Text/Glossary";
import {
  GET_PARENT_ASSIGNED_FORMS,
  GET_PARENT_FORMS_INDEX,
  SCHOOLS_BY_SCHOOL_REFERENCE_IDS,
} from "./graphql/queries";
import {
  saveContext,
  getContext,
  School,
} from "src/scenes/parent/state/localStorage";
import {
  getContext as getSearchContext,
  saveContext as saveSearchContext,
} from "src/scenes/state/localStorage";
import { InfoAlert } from "src/components/Feedback/InfoAlert";

export function List() {
  const organization = useOrganization();
  const userProfile = useUserProfile();

  const [numSavedSchools, setNumSavedSchools] = useState(
    getContext()?.exploreSavedSchools.length ?? 0
  );

  const exploreSavedSchoolRefIDs: string[] =
    getSearchContext()?.exploreSavedSchoolRefIDs ?? [];

  const {
    remoteData: assignedFormRemoteData,
    startPolling,
    stopPolling,
  } = useRemoteDataQuery<
    GQL.GetParentAssignedForms,
    GQL.GetParentAssignedFormsVariables
  >(GET_PARENT_ASSIGNED_FORMS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
    skip: !userProfile.hasData() || !organization.hasData(),
    pollInterval: 30000,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      stopPolling();
    }, 600000);
    return () => clearInterval(interval);
  }, [startPolling, stopPolling]);

  const { remoteData: parentFormIndexRemoteData } = useRemoteDataQuery<
    GQL.GetParentFormsIndex,
    GQL.GetParentFormsIndexVariables
  >(GET_PARENT_FORMS_INDEX, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      guardian_id: userProfile.map((user) => user.id).withDefault(""),
    },
    skip: !userProfile.hasData() || !organization.hasData(),
    fetchPolicy: "cache-and-network",
  });

  const { remoteData: exploreSavedSchoolIDs } = useRemoteDataQuery<
    GQL.SchoolsBySchoolReferenceIDs,
    GQL.SchoolsBySchoolReferenceIDsVariables
  >(SCHOOLS_BY_SCHOOL_REFERENCE_IDS, {
    variables: {
      school_reference_ids: exploreSavedSchoolRefIDs,
    },
    skip: !exploreSavedSchoolRefIDs.length,
  });

  useEffect(() => {
    const refIDs = getSearchContext()?.exploreSavedSchoolRefIDs ?? [];
    if (exploreSavedSchoolIDs.hasData() && refIDs.length > 0) {
      saveSearchContext({ exploreSavedSchoolRefIDs: [] });
      const schools: School[] = exploreSavedSchoolIDs.data.school.map(
        (item) => ({
          id: item.id,
          referenceID: item.reference_id ?? "",
          name: item.name,
          gradeConfigIDs: item.grades.map((g) => g.grade_config.id),
        })
      );

      saveContext({
        exploreSavedSchools: schools,
        unresolvedReferenceIDs: refIDs.filter(
          (item) => !schools.map((school) => school.referenceID).includes(item)
        ),
      });
      setNumSavedSchools(schools.length);
    }
  }, [exploreSavedSchoolIDs]);

  return (
    <ParentFormsLayoutOuter heading="Home">
      <ParentRemoteDataLayout
        remoteData={parentFormIndexRemoteData}
        error={() => <GenericError />}
      >
        {(parentFormIndex) => {
          const allStudents = parentFormIndex.person;
          const studentsWithForms = allStudents.filter(
            (student) => student.forms.length > 0
          );

          let content = null;
          let footers = null;

          if (allStudents.length === 0) {
            content = (
              <Empty
                returnUrl={organization.map(Url.Parent.index).withDefault("#")}
              />
            );
          } else if (studentsWithForms.length === 0) {
            content = <NoForms />;
          } else {
            content = (
              <Flex direction="column" gap={8}>
                {studentsWithForms.map((student) => (
                  <StudentFormTemplateCards
                    forms={student.forms}
                    assignedForms={
                      assignedFormRemoteData.toNullable()?.assigned_form ??
                      parentFormIndex.assigned_form
                    }
                    key={student.id}
                    student={student}
                  />
                ))}
              </Flex>
            );

            footers = (
              <ParentFormsLayoutFooter justifyContent="stretch">
                <Button
                  as={Link}
                  flexGrow={1}
                  leftIcon={<RiSurveyLine />}
                  to={organization.map(Url.Parent.Form.new).withDefault("#")}
                >
                  Start form
                </Button>
              </ParentFormsLayoutFooter>
            );
          }

          return (
            <ParentFormsLayoutInner footers={footers}>
              {numSavedSchools > 0 && (
                <InfoAlert
                  children={
                    <Text>
                      <Glossary>
                        Your saved schools are ready to add to a new form.
                      </Glossary>
                    </Text>
                  }
                ></InfoAlert>
              )}
              {content}
            </ParentFormsLayoutInner>
          );
        }}
      </ParentRemoteDataLayout>
    </ParentFormsLayoutOuter>
  );
}
