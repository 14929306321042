import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { CedarEditor } from "src/components/CedarEditor";
import { Loading } from "src/components/Feedback/Loading";
import { NotFound } from "src/components/Feedback/NotFound";
import { StickyBottom } from "src/components/Layout/StickyBottom";
import {
  PolicyManagementError,
  usePolicyManagement,
} from "src/hooks/usePolicyManagement";
import * as Url from "src/services/url";

type EditPolicyContainerProps = {
  organizationId: string;
  initialPolicy: string;
};

export const EditPolicyContainer = (props: EditPolicyContainerProps) => {
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [policy, setPolicy] = useState(props.initialPolicy);

  const navigate = useNavigate();

  const { initialPolicy, organizationId } = props;

  const { storePolicy } = usePolicyManagement({
    type: "DEFAULT",
    organizationId,
  });

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      const key = `organizations/${organizationId}.cedar`;
      await storePolicy(key, policy);

      toast({
        id: "update-organization",
        title: "Organization updated",
        isClosable: true,
        status: "info",
      });

      navigate(Url.Admin.Organizations.edit(organizationId));
    } catch (err) {
      const description = (): React.ReactNode => {
        if (err instanceof PolicyManagementError) {
          return err.message;
        }
        return "Check your network and try again.";
      };
      toast({
        id: "update-organization",
        title: `Something went wrong!`,
        description: description(),
        isClosable: true,
        status: "error",
      });
    } finally {
      setSubmitting(false);
    }
  }, [storePolicy, toast, navigate, organizationId, policy]);

  return (
    <>
      <CedarEditor
        height="60vh"
        initialPolicy={initialPolicy}
        onChangePolicy={(value) => setPolicy(value || "")}
      />
      <Spacer minHeight={6} />
      <StickyBottom gap={6} justifyContent="space-between">
        <Flex align="center" gap={6}>
          <Spacer />
          <Button
            as={NavLink}
            to={Url.Admin.Organizations.edit(organizationId)}
            variant="outline"
            colorScheme="gray"
          >
            Back
          </Button>
        </Flex>
        <Button type="submit" isLoading={submitting} onClick={handleSubmit}>
          Save access policies
        </Button>
      </StickyBottom>
    </>
  );
};

export const EditPolicy = () => {
  const { id = "" } = useParams();

  const { policy: policyRemoteData } = usePolicyManagement({
    type: "GET_POLICY",
    key: `organizations/${id}.cedar`,
    organizationId: id,
  });

  if (policyRemoteData.isLoading()) return <Loading />;

  if (!policyRemoteData.hasData()) return <NotFound />;

  return (
    <Flex direction="column" height="100%">
      <Heading as="h1" fontSize="2xl" fontWeight="600">
        Edit access policies
      </Heading>
      <Spacer minHeight={12} />
      <EditPolicyContainer
        organizationId={id}
        initialPolicy={policyRemoteData.data}
      />
    </Flex>
  );
};
