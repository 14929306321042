import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { SortType, UNSORTED } from "src/components/Table/SortButton";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { isGqlOrderBy } from "src/types/graphqlExtra";
import { FETCH_PRIORITY_TEMPLATES } from "./graphql/queries";
import { PriorityTemplateList } from "./List";

export const Index = (): React.ReactElement => {
  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.FetchPriorityTemplates,
    GQL.FetchPriorityTemplatesVariables
  >(FETCH_PRIORITY_TEMPLATES, {
    variables: {
      order_by: {},
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSort = useCallback(
    (columnName: string, sortType: SortType) => {
      refetch({ order_by: getOrderByClause(columnName, sortType) });
    },
    [refetch]
  );

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Heading as="h1" size="xl">
        Priority templates
      </Heading>
      <Button as={NavLink} to={Url.Admin.PriorityTemplates.new()} size="xl">
        Create new template
      </Button>
      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <PriorityTemplateList
            priorityTemplates={data.priority_template}
            onSort={handleSort}
            onRefetch={refetch}
          />
        )}
      </GQLRemoteDataView>
    </Flex>
  );
};

function getOrderByClause(
  columnName: string,
  sortType: SortType
): GQL.priority_template_order_by {
  if (sortType === UNSORTED || !isGqlOrderBy(sortType)) {
    return {};
  }
  switch (columnName) {
    case "enrollment period":
      return { enrollment_period: { name: sortType } };
    case "organization":
      return { enrollment_period: { organization: { name: sortType } } };
    case "template":
      return { name: sortType };
    default:
      return {};
  }
}

// export { Edit } from "./Edit";
export { New } from "./New";
