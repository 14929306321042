import { Button, Flex, useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { JsonEditor } from "src/scenes/admin/components/JsonEditor";
import formTemplateSchema from "src/schemas/formTemplate.json";
import * as breadcrumb from "src/services/breadcrumb";
import * as FormTemplateService from "src/services/formTemplate";
import { FormTemplateContent } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { toTuple } from "src/types/remoteData";
import { GET_ENROLLMENT_PERIOD_BY_ID } from "../../graphql/queries";
import { EditFormTemplateBackButton } from "./EditFormTemplateTabs/components/BackButton";
import { CREATE_FORM_TEMPLATE } from "./graphql/mutations";
import {
  GET_FORM_TEMPLATE_BY_ID,
  GET_QUESTION_TYPES_BY_ORGANIZATION
} from "./graphql/queries";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";

export function New() {
  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [json, setJson] = React.useState("");

  const [createFormTemplate] = useRemoteDataMutation<
    GQL.CreateFormTemplate,
    GQL.CreateFormTemplateVariables
  >(CREATE_FORM_TEMPLATE);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetEnrollment,
    GQL.GetEnrollmentVariables
  >(GET_ENROLLMENT_PERIOD_BY_ID, {
    variables: { id: enrollmentPeriodId },
    fetchPolicy: "no-cache"
  });

  const { remoteData: questionTypesRemoteData } = useRemoteDataQuery<
    GQL.GetQuestionTypesByOrganization,
    GQL.GetQuestionTypesByOrganizationVariables
  >(GET_QUESTION_TYPES_BY_ORGANIZATION, {
    variables: {
      organizationId: organization.toNullable()?.id ?? ""
    },
    fetchPolicy: "no-cache",
    skip: !organization.hasData()
  });

  const combinedRemoteData = toTuple(remoteData, questionTypesRemoteData);

  const uploadJSONHandler = async (
    customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[]
  ) => {
    const formConfig = FormTemplateService.decodeJSON(json);
    const formTemplateId: uuid = crypto.randomUUID();
    const variables = FormTemplateService.toVariable(
      formConfig,
      customQuestionTypes,
      formTemplateId
    );

    try {
      await createFormTemplate({
        variables,
        refetchQueries: [
          GET_FORM_TEMPLATE_BY_ID,
          GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD
        ],
        onCompleted: (data) => {
          const formTemplateId = data.insert_form_template_one?.id || "";
          navigate(
            organization
              .map((org) =>
                FormTemplateContent.edit(
                  org,
                  enrollmentPeriodId,
                  formTemplateId
                )
              )
              .withDefault("#")
          );
        }
      });

      toast({
        title: "Form created",
        status: "success",
        isClosable: true
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error creating form",
        status: "error",
        isClosable: true
      });
    }
  };

  return (
    <GQLRemoteDataView remoteData={combinedRemoteData}>
      {([enrollmentPeriodData, customQuestionTypesData]) => {
        if (enrollmentPeriodData.enrollment_period_by_pk === null) {
          return <NotFound />;
        }

        const { enrollment_period_by_pk: enrollmentPeriod } =
          enrollmentPeriodData;
        return (
          <Flex direction="column" gap={4} height="100%">
            <Breadcrumb
              items={breadcrumb.enrollmentPeriod.getBreadcrumbsForNewFormTemplate(
                organization,
                enrollmentPeriod
              )}
            />
            <JsonEditor
              title="Create form"
              schema={formTemplateSchema}
              text={json}
              onChange={setJson}
              height="100%"
              filename="formTemplate.json"
            />

            <AdminFormButtons sticky>
              <Flex w="100%" justifyContent="space-between">
                <EditFormTemplateBackButton />
                <Button
                  type="button"
                  onClick={() => {
                    uploadJSONHandler(
                      customQuestionTypesData.custom_question_type
                    );
                  }}
                >
                  Create form
                </Button>
              </Flex>
            </AdminFormButtons>
          </Flex>
        );
      }}
    </GQLRemoteDataView>
  );
}
