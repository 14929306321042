import { MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { gql } from "@apollo/client";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import * as AF from "src/types/formTemplate";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import _ from "lodash";
import { Props, Loading } from "./Undo";
import { Timestamp } from "./Timestamp";
import { formatUSPhoneNumber } from "src/services/format";
import { NoUndoAvailable } from "./NoUndoAvailable";
import { Answer } from "src/services/formTemplate";
import {
  removeConsecutiveDuplicates,
  filterEmpty,
  removeCurrentAnswer,
} from "./helpers";

type FreeTextProps = Omit<Props, "question"> & {
  initialFocusRef: React.RefObject<HTMLButtonElement>;
  question:
    | AF.FreeText<AF.WithId>
    | AF.Email<AF.WithId>
    | AF.PhoneNumber<AF.WithId>;
};
export const FreeTextUndo: React.FC<FreeTextProps> = (props) => {
  const { formId, question, setAnswer, initialFocusRef, answer } = props;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetFreeTextAnswerHistory,
    GQL.GetFreeTextAnswerHistoryVariables
  >(GET_FREE_TEXT_ANSWER_HISTORY, {
    variables: {
      formId,
      questionId: question.id,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <RemoteDataView
      error={GenericError}
      remoteData={remoteData}
      loading={<Loading />}
    >
      {(data) => {
        const history = consolidate(data.audit_form_transaction, answer);
        if (history.length === 0) {
          return <NoUndoAvailable />;
        }

        return (
          <MenuList maxHeight="20rem" overflowY="auto">
            {history.map((item, index) => {
              return (
                <MenuItem
                  onClick={() => setAnswer(item.value)}
                  key={index}
                  ref={index === 0 ? initialFocusRef : undefined}
                  display="flex"
                  gap="2"
                  alignItems="center"
                >
                  {format(item.value, question.type)}

                  {item.timestamp && <Timestamp timestamp={item.timestamp} />}
                </MenuItem>
              );
            })}
          </MenuList>
        );
      }}
    </RemoteDataView>
  );
};

function format(value: string, questionType: AF.QuestionType): string {
  switch (questionType) {
    case AF.PhoneNumberType:
      return formatUSPhoneNumber(value);
    default:
      return value;
  }
}

const GET_FREE_TEXT_ANSWER_HISTORY = gql`
  query GetFreeTextAnswerHistory($formId: uuid!, $questionId: uuid!) {
    audit_form_transaction(
      where: { form_id: { _eq: $formId } }
      order_by: { action_tstamp_tx: desc }
    ) {
      logged_actions(
        where: {
          table_name: { _in: ["form_answer"] }
          action: { _in: ["I", "U"] }
          row_data: { _contains: { question_id: $questionId } }
        }
      ) {
        action_tstamp_tx
        row_id
        row_data
      }
    }
  }
`;

export type UndoItem = { value: string; timestamp?: string };
function consolidate(
  list: GQL.GetFreeTextAnswerHistory_audit_form_transaction[],
  answer: Answer.FormikFieldValue
): UndoItem[] {
  return _.flow(
    toFreeTextItem,
    removeConsecutiveDuplicates,
    filterEmpty,
    removeCurrentAnswer(answer)
  )(list);
}
function toFreeTextItem(
  list: readonly GQL.GetFreeTextAnswerHistory_audit_form_transaction[]
): UndoItem[] {
  return list.flatMap((i) => {
    return i.logged_actions.map((action) => {
      return {
        value: action.row_data.free_text_answer,
        timestamp: action.action_tstamp_tx,
      };
    });
  });
}
