import {
  Flex,
  FlexProps,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { CustomQuestionAnswerSchema } from "src/components/Form/QuestionForm/formik";
import { BaseAddressSchema } from "src/components/Inputs/Address/Book";
import { PERMISSION_LEVELS } from "src/constants";
import { Answer } from "src/services/formTemplate";
import { formatUSPhoneNumber } from "src/services/format";
import * as AF from "src/types/formTemplate";
import { InfoBadge } from "../DataDisplay/InfoBadge";
import { QuestionFileItem } from "../UploadButtons/UploadFileQuestion/QuestionFileItem";
import { CustomQuestionAnswer } from "./CustomQuestionAnswer";

type Props = {
  question: AF.Question<AF.WithId>;
  answer: Answer.FormikFieldValue;
  formId: uuid;
} & FlexProps;

export const ParentQuestionAnswer: React.FC<Props> = ({
  question,
  answer,
  formId,
  ...props
}) => {
  return (
    <QuestionAnswer
      question={question}
      answer={answer}
      formId={formId}
      questionColor="gray.700"
      questionFontWeight="500"
      answerColor="blackAlpha.700"
      answerFontWeight="400"
      {...props}
    />
  );
};

export const AdminQuestionAnswer: React.FC<Props> = ({
  question,
  answer,
  formId,
  ...props
}) => {
  return (
    <QuestionAnswer
      question={question}
      answer={answer}
      formId={formId}
      questionColor="gray.600"
      questionFontWeight="500"
      answerColor="gray.800"
      answerFontWeight="400"
      {...props}
    />
  );
};

type QuestionAnswerProps = {
  questionColor?: string;
  questionFontWeight?: string;
  answerColor?: string;
  answerFontWeight?: string;
} & Props;

export const QuestionAnswer: React.FC<QuestionAnswerProps> = ({
  question,
  answer,
  formId,
  questionColor,
  questionFontWeight,
  answerColor,
  answerFontWeight,
  ...props
}) => {
  let children: React.ReactNode;
  switch (question.type) {
    case AF.PhoneNumberType:
      children = (
        <Text color={answerColor} fontWeight={answerFontWeight}>
          {answer && typeof answer === "string"
            ? formatUSPhoneNumber(answer)
            : ""}
        </Text>
      );
      break;

    case AF.EmailType:
    case AF.FreeTextType:
      children = (
        <Text color={answerColor} fontWeight={answerFontWeight}>
          {typeof answer === "string" && answer}
        </Text>
      );
      break;

    case AF.SingleSelectType:
    case AF.GradesType:
      children = (
        <Text color={answerColor} fontWeight={answerFontWeight}>
          {question.options.find((o) => o.id === answer)?.label}
        </Text>
      );
      break;

    case AF.MultiSelectType:
      const optionIds: string[] = Array.isArray(answer) && answer ? answer : [];
      children =
        optionIds.length > 0 ? (
          <UnorderedList>
            {question.options
              .filter((o) => optionIds.includes(o.id))
              .map((o) => (
                <ListItem key={o.id}>{o.label}</ListItem>
              ))}
          </UnorderedList>
        ) : null;
      break;

    case AF.FileUploadType:
      children = (
        <>
          {Array.isArray(answer) &&
            answer.map((documentId) => (
              <QuestionFileItem
                key={documentId}
                documentId={documentId}
                onDeleteDocument={() => {}}
                viewMode={true}
              />
            ))}
        </>
      );
      break;
    case AF.AddressType:
      if (answer === undefined) {
        children = null;
        break;
      }

      const parsedAddress = BaseAddressSchema.safeParse(answer);
      if (!parsedAddress.success) {
        console.error(parsedAddress.error);
        children = null;
        break;
      }

      const { street_address, street_address_line_2, city, state, zip_code } =
        parsedAddress.data;
      children =
        street_address && city ? (
          <Text color={answerColor} fontWeight={answerFontWeight}>
            <span>{street_address}</span>
            <br />
            {street_address_line_2 && (
              <>
                <span>{street_address_line_2}</span>
                <br />
              </>
            )}
            <span>
              {city}, {state} {zip_code}
            </span>
          </Text>
        ) : (
          <NoAnswerText
            answerColor={answerColor}
            answerFontWeight={answerFontWeight}
          />
        );
      break;
    case AF.CustomQuestionType:
      if (answer === undefined || question.nestedQuestions.length === 0) {
        children = null;
        break;
      }
      const result = CustomQuestionAnswerSchema.safeParse(answer);
      if (!result.success) {
        console.error(result.error);
        children = null;
        break;
      }
      children = (
        <CustomQuestionAnswer
          questionId={question.id}
          formId={formId}
          nestedQuestions={question.nestedQuestions}
          answer={result.data.answersByQuestionId}
          referenceId={result.data.referenceId}
        />
      );
      break;
    default:
      const _exhaustiveCheck: never = question;
      children = _exhaustiveCheck;
      break;
  }

  return question.permissionLevel ? (
    <Flex direction="column" {...props}>
      <Flex gap={3} alignItems="center">
        <Text
          fontSize="sm"
          color={questionColor}
          fontWeight={questionFontWeight}
          whiteSpace="pre-wrap"
        >
          {question.question}
        </Text>
        <InfoBadge status={PERMISSION_LEVELS.admin} />
      </Flex>
      {answer ? (
        children
      ) : (
        <NoAnswerText
          answerColor={answerColor}
          answerFontWeight={answerFontWeight}
        />
      )}
    </Flex>
  ) : (
    <Flex direction="column" {...props}>
      <Text
        fontSize="sm"
        color={questionColor}
        fontWeight={questionFontWeight}
        whiteSpace="pre-wrap"
      >
        {question.question}
      </Text>
      {answer ? (
        children
      ) : (
        <NoAnswerText
          answerColor={answerColor}
          answerFontWeight={answerFontWeight}
        />
      )}
    </Flex>
  );
};

export const NoAnswerText: React.FC<{
  answerColor?: string;
  answerFontWeight?: string;
}> = ({ answerColor, answerFontWeight }) => (
  <Text color={answerColor} fontWeight={answerFontWeight} variant="placeholder">
    No answer
  </Text>
);
