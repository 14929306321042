import { MenuButton, IconButton, Tooltip } from "@chakra-ui/react";
import { RiArrowGoBackLine } from "react-icons/ri";

export const UndoButton: React.FC = () => {
  return (
    <Tooltip label="Previous answers" placement="right">
      <MenuButton
        as={IconButton}
        aria-label="Undo"
        icon={<RiArrowGoBackLine />}
        variant="ghost"
        size="xs"
        height="1rem"
        width="10px"
        padding="0"
      />
    </Tooltip>
  );
};
