import _ from "lodash";
import * as AFF from "src/services/formTemplateFilters";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { toOptionWithoutBranching } from "../../option";
import { toBaseQuestion, toFormVerification } from "../../question";
import { sortByOrder } from "../../sorter";
import { toBaseGeneralQuestion, toQuestionWithoutBranching } from "../question";

export function toSingleSelectWithBranching(
  value: GQL.QuestionFragment
): AF.SingleSelect<AF.WithId> {
  validateSingleSelect(value);
  if (!value.form_question) {
    throw new Error("Missing question detail data");
  }
  switch (value.form_question.category) {
    case GQL.form_question_category_enum.EligibilityQuestion:
      return {
        ...toBaseEligibilityQuestion(value),
        type: AF.SingleSelectType,
        options: _.sortBy(
          value.form_question?.form_question_options ?? [],
          sortByOrder
        ).map(toEligibilityOptionWithBranching),
        filters: AFF.parseFilters(value.form_question.filters),
      };
    case GQL.form_question_category_enum.GeneralQuestion:
      return {
        ...toBaseGeneralQuestion(value),
        type: AF.SingleSelectType,
        options: _.sortBy(
          value.form_question?.form_question_options ?? [],
          sortByOrder
        ).map(toOptionWithBranching),
        filters: AFF.parseFilters(value.form_question.filters),
      };
  }
}

export function toSingleSelectWithoutBranching(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.SingleSelect<AF.WithId> {
  validateSingleSelect(value);
  if (!value.form_question) {
    throw new Error("Missing question detail data");
  }

  let question: Omit<AF.FormQuestion<AF.WithId>, "type">;
  switch (value.form_question.category) {
    case GQL.form_question_category_enum.EligibilityQuestion:
      question = toBaseEligibilityQuestion(value);
      break;

    case GQL.form_question_category_enum.GeneralQuestion:
      question = toBaseGeneralQuestion(value);
      break;
  }
  return {
    ...question,
    type: AF.SingleSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toEligibilityOptionWithoutBranching),
    filters: AFF.parseFilters(value.form_question.filters),
  };
}

export function toMultiSelectWithBranching(
  value: GQL.QuestionFragment
): AF.MultiSelect<AF.WithId> {
  validateMultiSelect(value);
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.MultiSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toOptionWithBranching),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

export function toMultiSelectWithoutBranching(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): AF.MultiSelect<AF.WithId> {
  validateMultiSelect(value);
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.MultiSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toOptionWithoutBranching),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

// ================================================================================
// Helpers
// ================================================================================

function toBaseEligibilityQuestion(
  value: GQL.FormQuestionWithoutBranchingFragment
): Omit<AF.FormQuestion<AF.WithId>, "type"> {
  return {
    ...toBaseQuestion(value),
    category: AF.EligibilityCategoryType,
    formVerification: toFormVerification(value),
  };
}

function toOptionWithBranching(
  value: GQL.FormQuestionOptionFragment
): AF.Option<AF.WithId> {
  const optionWithoutBranching = toOptionWithoutBranching(value);
  const additionalQuestions = value.additional_questions.filter((q) =>
    isNotNull(q.question)
  );
  return {
    ...optionWithoutBranching,
    additionalQuestions:
      additionalQuestions.length > 0
        ? _.sortBy(additionalQuestions, (q) => q.question.order)
            .map((q) => toQuestionWithoutBranching(q.question))
            .filter(isNotNull)
        : undefined,
  };
}

function toEligibilityOptionWithBranching(
  value: GQL.FormQuestionOptionFragment
): AF.Option<AF.WithId> {
  const optionWithoutBranching = toEligibilityOptionWithoutBranching(value);
  const additionalQuestions = value.additional_questions.filter((q) =>
    isNotNull(q.question)
  );
  return {
    ...optionWithoutBranching,
    additionalQuestions:
      additionalQuestions.length > 0
        ? _.sortBy(additionalQuestions, (q) => q.question.order)
            .map((q) => toQuestionWithoutBranching(q.question))
            .filter(isNotNull)
        : undefined,
  };
}

function toEligibilityOptionWithoutBranching(
  value: GQL.QuestionOptionFragment
): AF.Option<AF.WithId> {
  let eligibilityFilter: AF.Eligibility;
  if (value.eligibility_schools.length === 0) {
    eligibilityFilter = {};
  } else if (value.eligibility_schools.every((el) => el.is_eligible)) {
    eligibilityFilter = {
      eligibilityFilter: "Eligible",
      eligibleSchoolIds: value.eligibility_schools.map((el) => el.school_id),
    };
  } else if (value.eligibility_schools.every((el) => !el.is_eligible)) {
    eligibilityFilter = {
      eligibilityFilter: "NotEligible",
      notEligibleSchoolIds: value.eligibility_schools.map((el) => el.school_id),
    };
  } else {
    throw new Error("Invalid data, mixed eligibility type found");
  }
  return {
    id: value.id,
    label: value.label,
    value: value.value ?? undefined,
    translate_options: value.translate_options === true,
    ...eligibilityFilter,
  };
}

/**
 * Validation
 */
function validateSingleSelect(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): void {
  if (value.type !== GQL.question_type_enum.SingleSelect) {
    throw new Error(
      `Invalid question type, expecting "SingleSelect", got a ${value.type}`
    );
  }
  if ((value.form_question?.form_question_options ?? []).length === 0) {
    throw new Error("Missing options for SingleSelect question");
  }
}

function validateMultiSelect(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): void {
  if (value.type !== GQL.question_type_enum.MultiSelect) {
    throw new Error(
      `Invalid question type, expecting "MultiSelect", got a ${value.type}`
    );
  }
  if ((value.form_question?.form_question_options ?? []).length === 0) {
    throw new Error("Missing options for MultiSelect question");
  }
}
