import { z } from "zod";

const Grade = z.object({
  id: z.string().optional(),
  label: z.string(),
  order: z.number(),
  value: z.string().optional(),
});

export const GradesConfig = z.object({
  organizationId: z.string(),
  grades: z.array(Grade),
});

export type GradesConfigType = z.infer<typeof GradesConfig>;
export type GradeType = z.infer<typeof Grade>;
export type GradeFormType = {
  id?: string;
  label: string;
  order: string;
  value: string;
};

export function validate(gradesConfig: GradesConfigType) {
  const { organizationId, grades } = gradesConfig;
  return (
    organizationId.length > 0 &&
    grades.every(({ label, order }) => label.length > 0 && !Number.isNaN(order))
  );
}
