import { Box } from "@chakra-ui/react";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { DocViewerContext } from "../state";
import { resetState, setNumPages } from "../state/actions";
import { DocRenderer } from "./DocRenderer";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const PdfRenderer: DocRenderer = ({ documentData }) => {
  const { state, dispatch } = useContext(DocViewerContext);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    dispatch(resetState());
    dispatch(setNumPages(numPages));
  };

  return (
    <Box>
      <Document
        loading="Loading..."
        file={documentData}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          loading="Loading..."
          renderTextLayer={true}
          renderAnnotationLayer={false}
          scale={state.zoomLevel}
          pageNumber={state.currentPage}
          rotate={state.rotation}
        />
      </Document>
    </Box>
  );
};
