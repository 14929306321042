import { gql } from "@apollo/client";

export const CREATE_ENROLLMENT_PERIOD = gql`
  mutation CreateEnrollment($enrollment: enrollment_period_insert_input!) {
    insert_enrollment_period_one(object: $enrollment) {
      id
    }
  }
`;

export const CREATE_DEFAULT_INTERNAL_TAG_GROUP = gql`
  mutation CreateDefaultInternalTagGroup($enrollment_period_id: uuid!) {
    insert_tag_group_one(
      object: {
        enrollment_period_id: $enrollment_period_id
        name: "Internal"
        is_external: false
        is_default: true
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ENROLLMENT_PERIOD_INFO_BY_ID = gql`
  mutation UpdateEnrollmentPeriodInfoById(
    $id: uuid!
    $enrollment_period_info: enrollment_period_set_input!
  ) {
    update_enrollment_period_by_pk(
      pk_columns: { id: $id }
      _set: $enrollment_period_info
    ) {
      id
    }
  }
`;
