import { Link } from "@chakra-ui/react";
import { forwardRef, type ComponentProps } from "react";
import { Link as UnstyledRouterLink } from "react-router-dom";

/*
 * `forwardRef` is needed here because Chakra-UI makes assumptions about
 * components being ref-able in some places, including where this link is used.
 */
export const RouterLink = forwardRef<
  any,
  ComponentProps<typeof Link> & ComponentProps<typeof UnstyledRouterLink>
>((props, ref) => {
  return <Link ref={ref} {...props} as={UnstyledRouterLink} />;
});
