import { gql } from "@apollo/client";

export const CREATE_GRADES_CONFIG = gql`
  mutation CreateGradesConfig($grades_config: [grade_config_insert_input!]!) {
    insert_grade_config(objects: $grades_config) {
      affected_rows
    }
  }
`;

export const DELETE_GRADE_CONFIG = gql`
  mutation DeleteGradeConfig($id: uuid!) {
    delete_grade_config(where: { organization_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_GRADE_CONFIG = gql`
  mutation UpdateGradeConfig(
    $grade_config_updates: [grade_config_updates!]!
    $new_grade_configs: [grade_config_insert_input!]!
  ) {
    update_grade_config_many(updates: $grade_config_updates) {
      affected_rows
    }
    insert_grade_config(objects: $new_grade_configs) {
      affected_rows
    }
  }
`;
