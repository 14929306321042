import { ButtonProps, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import * as React from "react";
import { IconType } from "react-icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DeleteConfirmation } from "../Dialogs/DeleteConfirmation";

type DeleteButtonProps = {
  label: string;
  iconType?: IconType;
  loading: boolean;
  onDelete: () => Promise<void>;
  confirmationHeader?: string;
  confirmationCancelLabel?: string;
  confirmationDeleteLabel?: string;
  confirmationBody?: React.ReactNode;
} & ButtonProps;

export const DeleteButton = ({
  label,
  iconType,
  loading,
  onDelete,
  confirmationHeader,
  confirmationBody,
  confirmationDeleteLabel,
  confirmationCancelLabel,
  ...buttonProps
}: DeleteButtonProps) => {
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

  const handleDelete = async () => {
    setConfirmOpen(false);
    await onDelete();
  };
  const handleConfirmDelete = () => {
    setConfirmOpen(true);
  };
  const handleCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      <IconButton
        {...buttonProps}
        aria-label={label}
        icon={<Icon as={iconType ?? RiDeleteBin6Line} />}
        variant="outline"
        onClick={handleConfirmDelete}
        isLoading={loading}
        marginLeft="0.25em"
        marginRight="0.25em"
      />
      <DeleteConfirmation
        header={confirmationHeader || "Delete"}
        body={confirmationBody}
        isOpen={confirmOpen}
        onDelete={handleDelete}
        onCancel={handleCancel}
        deleteLabel={confirmationDeleteLabel}
        cancelLabel={confirmationCancelLabel}
      />
    </>
  );
};
