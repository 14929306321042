import { Box, Heading, HStack, Spacer, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { SearchAndFilterTypes } from "src/constants";
import { useGlossary } from "src/hooks/useGlossary";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";
import * as breadcrumb from "src/services/breadcrumb";
import * as GQL from "src/types/graphql";
import { ExportAllCapacitiesButton } from "./ExportAllCapacitiesButton";
import {
  GET_SCHOOL_GRADE_CAPACITY,
  GET_SCHOOLS_FOR_ENROLLMENT_PERIOD,
} from "./graphql/queries";
import { CapacityList } from "./List";
import { GradeProgramCapacity } from "./types";
import { UploadCapacitiesButton } from "./UploadCapacitiesButton";

export const Index = (): React.ReactElement | null => {
  const organization = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const { schoolFilter } = useSchoolAdminPermissions();

  const enrollmentPeriodId =
    searchParams.get(SearchAndFilterTypes.EnrollmentPeriod) ?? "";
  const schoolId = searchParams.get(SearchAndFilterTypes.School) ?? "";

  const { remoteData: schoolListData } = useRemoteDataQuery<
    GQL.GetSchoolsForEnrollmentPeriod,
    GQL.GetSchoolsForEnrollmentPeriodVariables
  >(GET_SCHOOLS_FOR_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      school_filter: schoolFilter(),
    },
    skip: !enrollmentPeriodId,
  });

  const { remoteData: capacityData } = useRemoteDataQuery<
    GQL.GetSchoolGradeCapacity,
    GQL.GetSchoolGradeCapacityVariables
  >(GET_SCHOOL_GRADE_CAPACITY, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      school_id: schoolId,
    },
    skip: !enrollmentPeriodId || !schoolId,
    notifyOnNetworkStatusChange: true,
  });

  const [selection, setSelection] = useState(
    Selection.create((row: GradeProgramCapacity) => row.id)
  );

  const handleChangeSchool = async (event: ChangeEvent<HTMLSelectElement>) => {
    searchParams.set(SearchAndFilterTypes.School, event.target.value);
    // This is a bit of a hack---I'd prefer this to be colocated with the
    // pagination logic.
    searchParams.delete(SearchAndFilterTypes.Offset);
    setSearchParams(searchParams);
    setSelection((s) => s.clear());
  };

  const { glossary } = useGlossary({ enrollmentPeriodId });

  return (
    <VStack align="left">
      <Breadcrumb
        items={breadcrumb.capacities.getBreadcrumbsForList(organization)}
        mb={4}
      />

      <HStack>
        <Heading as="h1" size="lg">
          Capacities
        </Heading>
        <Spacer />
        <WithUserPermissions permissions={["school.grade:update"]}>
          <Box>
            <UploadCapacitiesButton />
          </Box>
        </WithUserPermissions>
        <WithUserPermissions permissions={["school.grade:read"]}>
          <Box>
            <ExportAllCapacitiesButton
              enrollmentPeriodId={enrollmentPeriodId}
            />
          </Box>
        </WithUserPermissions>
      </HStack>

      <Spacer flexBasis={2} />

      <GQLRemoteDataView remoteData={schoolListData}>
        {(data) => (
          <Select
            onChange={handleChangeSchool}
            value={schoolId}
            placeholder={glossary`Select school`}
          >
            {data.school.map((school) => (
              <option value={school.id} key={school.id}>
                {school.name}
              </option>
            ))}
          </Select>
        )}
      </GQLRemoteDataView>

      <GQLRemoteDataView remoteData={capacityData}>
        {(data) => (
          <CapacityList
            data={data}
            isLoading={capacityData.isLoading()}
            selection={selection}
            onSelectionChange={setSelection}
          ></CapacityList>
        )}
      </GQLRemoteDataView>
    </VStack>
  );
};
