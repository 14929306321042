import { gql } from "@apollo/client";

export const GET_SCHOOLS_FOR_ENROLLMENT_PERIOD = gql`
  query GetSchoolsForEnrollmentPeriod(
    $enrollment_period_id: uuid!
    $school_filter: school_bool_exp!
  ) {
    school(
      where: {
        grades: { enrollment_period_id: { _eq: $enrollment_period_id } }
        _and: [$school_filter]
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

const CAPACITY_FRAGMENT = gql`
  fragment CapacityFragment on grade {
    id
    enrollment_period_id
    capacity
    seats_taken
    seats_available
    school {
      id
      name
    }
    grade_config {
      label
      id
      order
    }
    program {
      label
      id
      order
    }
    offered_offers_aggregate: offers_aggregate(
      where: { status: { _eq: Offered }, deleted_at: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }
    accepted_offers_aggregate: offers_aggregate(
      where: { status: { _eq: Accepted }, deleted_at: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }
    waitlists_aggregate(
      where: { status: { _eq: Waitlisted }, deleted_at: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SCHOOL_GRADE_CAPACITY = gql`
  ${CAPACITY_FRAGMENT}
  query GetSchoolGradeCapacity(
    $enrollment_period_id: uuid!
    $school_id: uuid!
  ) {
    grade(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        school_id: { _eq: $school_id }
      }
      order_by: [{ grade_config: { order: asc } }, { program: { order: asc } }]
    ) {
      ...CapacityFragment
    }
    enrollment_period_by_pk(id: $enrollment_period_id) {
      organization {
        program_groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_CAPACITY_FOR_ENROLLMENT_PERIOD = gql`
  ${CAPACITY_FRAGMENT}
  query GetAllCapacityForEnrollmentPeriod(
    $enrollment_period_id: uuid!
    $school_filter: school_bool_exp!
  ) {
    grade(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        school: $school_filter
      }
      order_by: [
        { school: { name: asc } }
        { grade_config: { order: asc } }
        { program: { order: asc } }
      ]
    ) {
      ...CapacityFragment
    }
    enrollment_period_by_pk(id: $enrollment_period_id) {
      organization {
        program_groups {
          id
          name
        }
      }
    }
  }
`;
