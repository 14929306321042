import * as ls from "src/services/localStorage";
import { AUTH_CONTEXT } from "src/types/localStorageKeys";

export type AuthContext = {
  organization: string;
  clientId: string;
};

export function getAuthContext(): AuthContext | null {
  return ls.getLocalStorageRecord<AuthContext>(AUTH_CONTEXT);
}

export function saveAuthContext(ctx: AuthContext | null): void {
  return ls.saveLocalStorageRecord<AuthContext>(AUTH_CONTEXT, ctx);
}
