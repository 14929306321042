import { Button, Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FunctionComponent, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AccountLookupStep, StepProps } from "./Step";
import { FormikAccountLookup } from "./helpers";

export const SolveCaptchaStep: FunctionComponent<StepProps> = () => {
  const [, { error, touched }, helpers] = useField("captchaToken");
  const { isSubmitting } = useFormikContext<FormikAccountLookup>();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleOnCaptchaChange = useCallback(
    async (token: string | null) => {
      if (token) {
        helpers.setValue(token);
        helpers.setTouched(true);
      }
    },
    [helpers]
  );

  return (
    <AccountLookupStep
      heading="Complete the CAPTCHA"
      description="The last step before we try to find your account email or phone number!"
    >
      <Flex as={FormControl} direction="column" gap={4} alignItems="center">
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
          onChange={handleOnCaptchaChange}
        />
        {touched && error && <FormErrorMessage>{error}</FormErrorMessage>}
        <Button type="submit" width="100%" isLoading={isSubmitting}>
          Submit
        </Button>
      </Flex>
    </AccountLookupStep>
  );
};
