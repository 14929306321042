import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { useFormImportFileUploadFlow } from "src/components/FormImport/useFormImportFileUploadFlow";

interface CreateFormsDialogProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
  isOpen: boolean;
  onClose: () => void;
  onTemplateDownloadClick: () => void;
}

export function CreateFormsDialog(props: CreateFormsDialogProps) {
  const {
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
    isOpen,
    onClose,
    onTemplateDownloadClick,
  } = props;

  const { openFilePicker } = useFormImportFileUploadFlow({
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
  });

  const {
    state: { formImportId },
  } = useFormImportFlow();

  const handleDownloadClick = useCallback(() => {
    onClose();
    onTemplateDownloadClick();
  }, [onClose, onTemplateDownloadClick]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Create responses</ModalHeader>

        <ModalBody as={VStack}>
          <p>
            To create responses, follow the format as seen on exports or{" "}
            <Text as="button" color="primary.500" onClick={handleDownloadClick}>
              download a template
            </Text>
            .
          </p>

          <p>
            Any preexisting form responses matching imported data will be
            skipped.
          </p>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose} variant="ghost">
            Cancel
          </Button>

          <Button isDisabled={!!formImportId} onClick={openFilePicker}>
            Create from file
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
