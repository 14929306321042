import { z } from "zod";
import { AnswerBankRecordFieldSchema } from "./AnswerBankField";

/**
 * There is an important relationship between the following database tables:
 * CustomQuestionType x (Person)AnswerBank x CustomQuestion x CustomQuestionAnswer
 *
 * The following pseudo-data attempts to highlight a few important details:
 * 1. PersonAnswerBank records use CustomQuestionTypeId and CustomQuestionTypeFieldIds.
 * 2. CustomQuestion records have nestedQuestions, that were cloned from CustomQuestionTypeFields.
 * 3. CustomQuestionAnswer records must reference the question_ids of the nestedQuestions.
 * 4. Importantly, answering a question with a PersonAnswerBank requires using the nestedQuestions values.
 *
 * customQuestionType: {
 *  id: cqt1,
 *  fields: [
 *    { id: q1, type: "FreeText" },
 *    { id: q2, type: "FreeText" }
 *  ]
 * }
 *
 * personAnswerBank: {
 *  id: ab1,
 *  customQuestionTypeId: cqt1,
 *  personAnswer: [
 *    { id: pa1, customQuestionTypeFieldId: q1, answer: "Hello" } <---|
 *    { id: pa2, customQuestionTypeFieldId: q2, answer: "World" }     <---|
 *  ]                                                                 |   |
 * }                                                                  |   |
 *                                                                    |   |
 * customQuestion: {                                                  |   |
 *  id: cq1                                                           |   |
 *  nestedQuestions: [                                                |   |
 *    { id: q3, customQuestionTypeFieldId: q1, ... }              <---|   |
 *    { id: q4, customQuestionTypeFieldId: q2, ... }                  <---|
 *  ]
 * }
 *
 * customQuestionAnswers: [
 *  { id: cqa1, question_id: cq1, form_answer: { question_id: q3, ... } }
 *  { id: cqa2, question_id: cq1, form_answer: { question_id: q4, ... } }
 * ]
 */

// --------------------------------------------------------------------------------

export const AnswerBankRecordKind = "ANSWER_BANK_RECORD";
const AnswerBankRecordSchema = z.object({
  kind: z.literal(AnswerBankRecordKind),
  fields: z.array(AnswerBankRecordFieldSchema),
  answerBankId: z.string().uuid(),
});
export type AnswerBankRecord = z.infer<typeof AnswerBankRecordSchema>;

export const DoNotAnswerKind = "DO_NOT_ANSWER";
const DoNotAnswerSchema = z.object({
  kind: z.literal(DoNotAnswerKind),
  fields: z.array(z.never()),
});
export type DoNotAnswer = z.infer<typeof DoNotAnswerSchema>;

const AnswerBankOptionSchema = z.discriminatedUnion("kind", [
  AnswerBankRecordSchema,
  DoNotAnswerSchema,
]);

export type AnswerBankOption = z.infer<typeof AnswerBankOptionSchema>;
