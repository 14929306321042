import { hasPermission } from "@avela/avela-authorization-sdk";
import { Box, Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import _, { isEqual } from "lodash";
import React from "react";
import {
  FORM_AUTHORIZATION_SCOPE,
  useAuthorizationScoped,
} from "src/components/Providers/AuthorizationProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { FormQuestion } from "src/scenes/parent/forms/components/Inputs/FormQuestion";
import { DELETE_ANSWERS } from "src/scenes/parent/forms/graphql/mutations";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { Answer } from "src/services/formTemplate";
import {
  getAddressAnswersDeletes,
  getAddressAnswersInserts,
  getCustomQuestionAnswerUpsertPayload,
  getFreeTextAnswersInserts,
  getGradesAnswersInserts,
  getMultiSelectAnswersInserts,
  getSingleSelectAnswersInserts,
} from "src/services/formTemplate/answer";
import { RankedSchool } from "src/services/formTemplate/preRankingSection";
import * as Question from "src/services/formTemplate/question";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { UPSERT_FORM_ANSWERS } from "../../graphql/mutations";
import {
  GET_FORM_VIEW_BY_ID,
  GET_SCHOOLS_RANK_VIEW,
} from "../../graphql/queries";
import { Undo } from "./Undo";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";

type Props = {
  formId: uuid;
  personId: uuid;
  applicant: AFF.Types.Applicant;
  questions: AF.Question<AF.WithId>[];
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  custom_question_answer_bank_relationships?: GQL.CustomQuestionAnswerBankRelationshipsFragment[];
  customQuestionTypes: GQL.GetCustomQuestionTypesByOrg_custom_question_type[];
  rankedSchools: RankedSchool[];
  onCloseEdit: () => void;
  previousFormSchoolIds: uuid[];
};

export const GeneralSectionEditForm: React.FC<Props> = ({
  formId,
  personId,
  applicant,
  questions,
  form_answers,
  grades_answers,
  address_answers,
  custom_question_answers,
  custom_question_answer_bank_relationships,
  customQuestionTypes,
  rankedSchools,
  onCloseEdit,
  previousFormSchoolIds,
}) => {
  const hasAdminOrgAdminPermission = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);

  const authorizationMap = useAuthorizationScoped(FORM_AUTHORIZATION_SCOPE);

  const [upsertFormAnswers, upsertFormAnswersStatus] = useRemoteDataMutation<
    GQL.UpsertFormAnswer,
    GQL.UpsertFormAnswerVariables
  >(UPSERT_FORM_ANSWERS);
  const [deleteAnswers] = useRemoteDataMutation<
    GQL.DeleteAnswers,
    GQL.DeleteAnswersVariables
  >(DELETE_ANSWERS);

  const canEditQuestion = React.useCallback(
    (question: AF.Question<AF.WithId>) => {
      if (authorizationMap.hasData()) {
        return hasPermission({
          action: {
            type: "Form::Action",
            id: "edit",
          },
          resource: {
            type: "FormQuestion",
            id: question.key || question.id,
          },
          authorizationMap: authorizationMap.data,
        });
      }
      return false;
    },
    [authorizationMap]
  );

  const initialAnswers = Answer.getFormikInitialValues(
    questions,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships
  );
  const rankedSchoolIds = rankedSchools.map(
    (rankedSchool) => rankedSchool.school.id
  );
  const toast = useAvelaToast();
  const submitHandler = async (answers: Answer.FormikValues) => {
    try {
      const completeQuestions = Question.getCompleteQuestions(questions);
      const applicableQuestions = Question.getCompleteApplicableQuestions(
        questions,
        answers,
        { rankedSchoolIds, previousFormSchoolIds }
      );
      const modifiedQuestions = applicableQuestions.filter(
        (q) => !isEqual(answers[q.id], initialAnswers[q.id])
      );

      const freeTextAnswers = getFreeTextAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleSelectAnswers = getSingleSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const multiSelectAnswers = getMultiSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleOrMultiSelectAnswers =
        singleSelectAnswers.concat(multiSelectAnswers);
      const gradesAnswers = getGradesAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const addressAnswers = getAddressAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const addressAnswersDeletes = getAddressAnswersDeletes(
        formId,
        modifiedQuestions,
        answers
      );
      const {
        customQuestionAnswers,
        answerBankIds,
        answerBankAnswers,
        newAnswerBankEntries,
        customQuestionAnswersToDelete,
        customQuestionAnswerFieldsToDelete,
      } = getCustomQuestionAnswerUpsertPayload(
        formId,
        personId,
        modifiedQuestions,
        answers,
        customQuestionTypes
      );

      const customQuestions = modifiedQuestions.filter(
        (q) => q.type === AF.CustomQuestionType
      );

      const customQuestionIds = customQuestions.map((cq) => cq.id);

      const customQuestionSingleSelectQuestionIds: uuid[] =
        customQuestions.flatMap((q) =>
          (q as AF.CustomQuestion<AF.WithId>).nestedQuestions
            .filter((nq) => nq.type === AF.SingleSelectType)
            .map((nq) => nq.id)
        );
      // Existing single and multi select question answers,
      // including those that are nested in custom questions,
      // will be deleted
      const singleAndMultiSelectQuestionsIds: uuid[] =
        singleOrMultiSelectAnswers
          .map((a) => a.question_id)
          .filter(isNotNull)
          .concat(customQuestionSingleSelectQuestionIds)
          .concat(customQuestionAnswerFieldsToDelete); // this deliberately overloads "singleAndMultiSelectQuestionsIds" to support "Do not answer"

      const inapplicableQuestions = Question.findInapplicableQuestionsToDelete(
        completeQuestions,
        applicableQuestions,
        answers
      );
      await Promise.all([
        upsertFormAnswers({
          variables: {
            form_id: formId,
            answers: freeTextAnswers.concat(singleOrMultiSelectAnswers),
            single_or_multi_select_question_ids:
              singleAndMultiSelectQuestionsIds,
            grades_answers: gradesAnswers,
            address_answers: addressAnswers,
            address_answer_deletes: addressAnswersDeletes,
            custom_question_ids: customQuestionIds,
            custom_question_answers: customQuestionAnswers,
            answer_bank_ids: answerBankIds,
            answer_bank_answers: answerBankAnswers,
            new_answer_bank_entries: newAnswerBankEntries,
            deleted_answers_custom_question_ids: customQuestionAnswersToDelete,
          },
          refetchQueries: [
            GET_FORM_VIEW_BY_ID,
            GET_FORM_ANSWERS_BY_ID,
            GET_SCHOOLS_RANK_VIEW,
          ],
          awaitRefetchQueries: true,
        }),
        inapplicableQuestions.length > 0
          ? deleteAnswers({
              variables: {
                form_id: formId,
                question_ids: inapplicableQuestions,
              },
            })
          : _.noop(),
      ]);

      onCloseEdit();
    } catch (error: unknown) {
      console.error(error);
      toast.error({ title: "Error updating form" });
    }
  };

  const checkAdminPermission = (question: AF.Question<AF.WithId>) =>
    question.permissionLevel === GQL.person_type_enum.admin &&
    !hasAdminOrgAdminPermission
      ? false
      : true;

  const flags = useFlags(["admin-form-undo"]);

  return (
    <Formik<Answer.FormikValues>
      initialValues={initialAnswers}
      onSubmit={submitHandler}
      validate={validateFormTemplate(questions, {
        rankedSchoolIds,
        previousFormSchoolIds,
      })}
    >
      {(formikProps) => {
        const completeQuestions = Question.getCompleteApplicableQuestions(
          questions,
          formikProps.values,
          { rankedSchoolIds, previousFormSchoolIds }
        );
        return (
          <Flex direction="column" as={Form} noValidate gap={3}>
            <Box>
              {completeQuestions.map(
                (question) =>
                  checkAdminPermission(question) && (
                    <Flex direction="row" gap="2">
                      <FormQuestion
                        formId={formId}
                        applicant={applicant}
                        key={question.id}
                        question={question}
                        marginBottom={4}
                        disableAddressBook
                        isDisabled={!canEditQuestion(question)}
                        labelPostElement={
                          flags["admin-form-undo"].enabled ? (
                            <Undo
                              formId={formId}
                              question={question}
                              answer={formikProps.values[question.id]}
                              setAnswer={(value) =>
                                formikProps.setFieldValue(question.id, value)
                              }
                            />
                          ) : null
                        }
                      />
                    </Flex>
                  )
              )}
            </Box>
            <Flex justifyContent="flex-end" gap={3}>
              <Button
                type="button"
                variant="ghost"
                colorScheme="gray"
                onClick={onCloseEdit}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isDisabled={!formikProps.isValid}
                isLoading={upsertFormAnswersStatus.remoteData.isLoading()}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
};
