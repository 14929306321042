import { useFormikContext } from "formik";
import { useCallback } from "react";
import { FormType } from "src/components/Form/QuestionForm/formik";
import { FormInput, FormInputProps } from "src/components/Inputs/FormInput";

interface QuestionKeyProps extends FormInputProps {
  questionId: uuid;
}

export function QuestionKey(props: QuestionKeyProps) {
  const { questionId, ...formInputProps } = props;

  const form = useFormikContext<FormType>();
  const error = form.touched.questionKeys?.[questionId]
    ? form.errors.questionKeys?.[questionId]
    : undefined;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setTouched({
        ...form.touched,
        questionKeys: { ...form.touched.questionKeys, [questionId]: true },
      });

      form.setValues((values) => ({
        ...values,
        questionKeys: {
          ...values.questionKeys,
          [questionId]: event.target.value,
        },
      }));
    },

    [form, questionId]
  );

  return (
    <FormInput
      {...formInputProps}
      error={error}
      label="Question key (optional)"
      onChange={handleChange}
      placeholder="E.g.: reference-value"
      value={form.values.questionKeys[questionId] ?? ""}
    />
  );
}
