import { useField } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { isValidEmail } from "src/services/formValidations";
import { BaseInputProps } from "./Question";

export const EmailInput: React.FC<BaseInputProps> = ({ id, isDisabled }) => {
  const [field, , helpers] = useField(id);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      // set as touched so it triggers autosave on change instead of on blur.
      helpers.setTouched(true);
      field.onChange(event);
    }
  };
  return (
    <InputControl
      id={id}
      name={id}
      onChange={handleOnChange}
      inputProps={{ type: "email" }}
      isDisabled={isDisabled}
    />
  );
};
