import { Box } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { ReactComponent as ConnectSvg } from "src/images/connect.svg";

export const NoScheduledExports: React.FC = () => {
  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description={
          <Box marginTop="1.5rem">
            They will be listed here once you schedule exports
          </Box>
        }
        heading="No Scheduled Exports"
        Svg={ConnectSvg}
      />
    </Box>
  );
};
