import { Box, Link, Text, Tooltip, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiIndeterminateCircleLine, RiSearchEyeLine } from "react-icons/ri";
import { DELETE_DOCUMENT_ITEM } from "src/components/graphql/mutations";
import useAccessToken from "src/hooks/useAccessToken";
import { useDocumentStorage } from "src/hooks/useDocumentStorage";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { ConfirmationDialog } from "../../Dialogs/ConfirmationDialog";
import { DocViewer } from "../../DocumentViewer";
interface QuestionFileItemProps {
  documentId: string;
  viewMode?: boolean;
  onDeleteDocument: (documentId: string) => void;
  onGenerateDocumentComplete?: () => void;
}

export const QuestionFileItem: React.FC<QuestionFileItemProps> = ({
  documentId,
  viewMode = false,
  onDeleteDocument,
  onGenerateDocumentComplete,
}) => {
  const toast = useToast();
  const accessToken = useAccessToken();
  const [previewFile, setPreviewFile] = useState<boolean>(false);
  const [documentUrl, setDocumentUrl] = useState<string | undefined>();
  const [fileToDelete, setFileToDelete] = useState<boolean>(false);
  const [documentInfo, setDocumentInfo] =
    useState<GQL.GetDocumentoInfo_document_metadata_by_pk | null>();

  const [deleteDocumentItem] = useRemoteDataMutation<
    GQL.DeleteDocumentItem,
    GQL.DeleteDocumentItemVariables
  >(DELETE_DOCUMENT_ITEM);

  const { getDownloadUrl, getDocumentInfo } = useDocumentStorage();

  useEffect(() => {
    const handleDocumentInfo = async () => {
      if (!documentId) return;

      const info = await getDocumentInfo(documentId);

      setDocumentInfo(info);
    };

    handleDocumentInfo();
  }, [documentId, getDocumentInfo]);

  useEffect(() => {
    const handleDownload = async () => {
      if (!documentId || accessToken.status !== Status.OK) return;

      const url = await getDownloadUrl(documentId).finally(
        onGenerateDocumentComplete
      );
      setDocumentUrl(url);
    };

    handleDownload();
  }, [documentId, accessToken, getDownloadUrl, onGenerateDocumentComplete]);

  const handleClickOnFilePreview = () => {
    setPreviewFile(true);
  };

  const toggleConfirmationDialog = () => {
    setFileToDelete(true);
  };

  const handleDeleteFile = async () => {
    if (!documentId) return;

    try {
      await deleteDocumentItem({
        variables: {
          document_id: documentId,
        },
      });

      setFileToDelete(false);
      onDeleteDocument(documentId);
    } catch (err: unknown) {
      console.error(err);
      toast({
        title: "Error deleting document",
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <>
      {documentInfo && documentUrl ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link
            onClick={handleClickOnFilePreview}
            textDecoration="none"
            textColor="primary.500"
            display="flex"
            alignItems="center"
          >
            <Text display="flex" gap={1} alignItems="inherit">
              {documentInfo?.filename}
              <RiSearchEyeLine />
            </Text>
          </Link>

          {!viewMode && (
            <Tooltip label="Delete file" hasArrow>
              <div>
                <RiIndeterminateCircleLine
                  cursor="pointer"
                  onClick={toggleConfirmationDialog}
                  color="red"
                />
              </div>
            </Tooltip>
          )}
        </Box>
      ) : (
        <Text color="gray.600" fontSize="sm">
          Generating preview...
        </Text>
      )}

      <DocViewer
        isOpen={!!previewFile}
        uri={documentUrl ?? ""}
        title={documentInfo?.filename ?? ""}
        onClose={() => setPreviewFile(false)}
      />

      <ConfirmationDialog
        isOpen={fileToDelete}
        header="Delete file?"
        body="This action can't be undone."
        onCancel={() => setFileToDelete(false)}
        onConfirm={handleDeleteFile}
        cancelButton={{ label: "No, cancel" }}
        confirmButton={{ label: "Yes, delete", colorScheme: "red" }}
      />
    </>
  );
};
