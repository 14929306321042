import { useCallback } from "react";
import {
  Person,
  PersonRow,
  useBulkUploadPeople,
} from "src/hooks/useUploadPeople";
import { BulkUploadInput } from "../Inputs/BulkUploadInput";

interface BulkUploadPeopleProps {
  onComplete: () => void;
  personType: "applicant" | "guardian";
  children?: React.ReactNode;
}

function isValid(data: unknown): data is PersonRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

function isValidRow(data: unknown): data is PersonRow {
  const { success } = Person.safeParse(data);
  return success;
}

export const BulkUploadPeopleInput = ({
  onComplete,
  personType,
  children,
}: BulkUploadPeopleProps) => {
  const bulkUploadPeople = useBulkUploadPeople(personType);
  const handleUpload = useCallback(
    (data: unknown[]) => bulkUploadPeople(data).then(() => onComplete()),
    [onComplete, bulkUploadPeople]
  );
  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      {children}
    </BulkUploadInput>
  );
};
