import * as ls from "src/services/localStorage";
import { EXPLORE_SAVED_SCHOOL_REF_IDS } from "src/types/localStorageKeys";

export type Context = {
  exploreSavedSchoolRefIDs: string[];
};

export function getContext(): Context | null {
  return ls.getLocalStorageRecord<Context>(EXPLORE_SAVED_SCHOOL_REF_IDS);
}

export function saveContext(ctx: Context | null): void {
  return ls.saveLocalStorageRecord<Context>(EXPLORE_SAVED_SCHOOL_REF_IDS, ctx);
}
