import { Flex } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren } from "react";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";

export const PublicLayout: FunctionComponent<PropsWithChildren<{}>> = (
  props
) => {
  const { children } = props;
  return (
    <Flex
      fontSize="sm"
      minWidth="100vw"
      minHeight="100vh"
      bg="gray.200"
      padding={3}
      direction="column"
      className={WEGLOT_APPLY_CLASS}
      alignItems="stretch"
      justifyContent="stretch"
      gap={3}
    >
      {children}
    </Flex>
  );
};
