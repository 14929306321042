import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Students",
      href: organization
        .map((org) => Url.OrgAdmin.Students.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNew = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create Student",
      href: organization
        .map((org) => Url.OrgAdmin.Students.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  student: GQL.GetStudentByIdAdminPortal_person_by_pk
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: `${student.first_name} ${student.last_name}`,
      href: organization
        .map((org) => Url.OrgAdmin.Students.edit(org, student.id))
        .withDefault("#"),
    },
  ];
};
