export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
export const SET_ROTATION = "SET_ROTATION";
export const SET_NUM_PAGES = "SET_NUM_PAGES";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const RESET_STATE = "RESET_STATE";

export const MIN_ZOOM_LEVEL = 0.125;
export const MAX_ZOOM_LEVEL = 2;
export const ZOOM_LEVEL_INCREMENTS = 0.125;
export const ROTATION_INCREMENTS = 90;

export interface DocViewerActions {
  type:
    | typeof SET_ZOOM_LEVEL
    | typeof SET_ROTATION
    | typeof SET_NUM_PAGES
    | typeof SET_CURRENT_PAGE
    | typeof RESET_STATE;

  value: number;
}

export const setZoomLevel = (value: number): DocViewerActions => ({
  type: SET_ZOOM_LEVEL,
  value,
});

export const setRotation = (value: number): DocViewerActions => ({
  type: SET_ROTATION,
  value,
});

export const setNumPages = (value: number): DocViewerActions => ({
  type: SET_NUM_PAGES,
  value,
});

export const setCurrentPage = (value: number): DocViewerActions => ({
  type: SET_CURRENT_PAGE,
  value,
});

export const resetState = (): DocViewerActions => ({
  type: RESET_STATE,
  value: 0,
});
