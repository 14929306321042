import { Route } from "react-router-dom";
import { RequireHasuraRoles } from "src/components/Permissions/RequireHasuraRoles";
import { Path } from "src/services/url/Admin";
import { HasuraRole } from "src/types/hasuraRole";
import { Index } from ".";
import { AdminBoundariesRoutes } from "./boundaries/routes";
import { AdminGlossariesRoutes } from "./glossaries/routes";
import { AdminGradesConfigRoutes } from "./gradesConfig/routes";
import { Layout } from "./Layout";
import { AdminOrganizationsRoutes } from "./organizations/routes";
import { AdminPriorityTemplatesRoutes } from "./priorityTemplates/routes";
import { AdminProgramsRoutes } from "./programs/routes";
import { AdminQuestionTypeRoutes } from "./questionTypes/routes";

export const AvelaAdminRoutes = (
  <Route element={<RequireHasuraRoles roles={[HasuraRole.ADMIN]} />}>
    <Route element={<Layout />}>
      <Route path={Path} element={<Index />} />
      {AdminOrganizationsRoutes}
      {AdminPriorityTemplatesRoutes}
      {AdminGradesConfigRoutes}
      {AdminProgramsRoutes}
      {AdminGlossariesRoutes}
      {AdminBoundariesRoutes}
      {AdminQuestionTypeRoutes}
    </Route>
  </Route>
);
