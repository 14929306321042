import { Boundaries } from "src/services/url/Admin";
import { BreadcrumbType } from ".";

function getBreadcrumbsForList(): BreadcrumbType[] {
  return [
    {
      label: "Boundaries",
      href: Boundaries.index(),
    },
  ];
}

export function getBreadcrumbsForEdit(): BreadcrumbType[] {
  return [
    ...getBreadcrumbsForList(),
    {
      label: "New boundary group",
      href: Boundaries.new(),
    },
  ];
}
