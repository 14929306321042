import { ReactNode, useMemo } from "react";
import { Loading } from "src/components/Feedback/Loading";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { Permission } from "src/types/permissions";

type Props = {
  children: ReactNode | ReactNode[];
  permissions: Permission[];
};

export const WithUserPermissions: React.FC<Props> = ({
  children,
  permissions,
}: Props) => {
  const userPermissions = useUserPermissions();
  const hasPermissions = useMemo(
    () => userPermissions.hasSome(permissions),
    [userPermissions, permissions]
  );

  if (userPermissions.isLoading) {
    return <Loading />;
  }
  return hasPermissions ? <>{children}</> : null;
};
