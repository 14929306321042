import { Button, useDisclosure } from "@chakra-ui/react";
import { useFormImportTemplateDownload } from "src/components/FormImport/useFormImportTemplateDownload";
import { CreateFormsDialog } from "./CreateFormsDialog";

interface CreateFormsButtonProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function CreateFormsButton(props: CreateFormsButtonProps) {
  const { enrollmentPeriodId, formTemplateId, formTemplateName } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { startDownload } = useFormImportTemplateDownload({
    formTemplateId,
  });

  return (
    <>
      <Button onClick={onOpen}>Create responses</Button>

      <CreateFormsDialog
        enrollmentPeriodId={enrollmentPeriodId}
        formTemplateId={formTemplateId}
        formTemplateName={formTemplateName}
        isOpen={isOpen}
        onClose={onClose}
        onTemplateDownloadClick={startDownload}
      />
    </>
  );
}
