import { ApolloError as ApolloErrorType } from "@apollo/client";
import { Alert } from "@chakra-ui/react";
import React from "react";

type Props = { error: ApolloErrorType };

export function ApolloError({ error }: Props): React.ReactElement {
  React.useEffect(() => {
    console.error(error.message);
  }, [error]);
  return <Alert status="error">GraphQL Error</Alert>; // TODO: Implement proper error renderer
}
