import {
  CreateToastFnReturn,
  ToastId,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { WEGLOT_APPLY_CLASS } from "../plugins/weglot/constants";

type ErrorToastProps = {
  title: string;
  toastId?: string;
  description?: string;
} & UseToastOptions;

type AvelaToastFn = (options?: UseToastOptions) => ToastId;

export interface AvelaToast
  extends AvelaToastFn,
    Pick<
      CreateToastFnReturn,
      "close" | "closeAll" | "isActive" | "promise" | "update"
    > {
  error(props: ErrorToastProps): void;
}

export const useAvelaToast = (defaultOptions?: UseToastOptions): AvelaToast => {
  const toast = useToast(defaultOptions);

  const avelaToast: AvelaToast = useCallback<AvelaToastFn>(
    (options?: UseToastOptions): ToastId => {
      return toast({
        position:
          options?.position ?? defaultOptions?.position ?? "bottom-right",
        isClosable: true,
        ...options,
        title: options?.title ? (
          <span className={WEGLOT_APPLY_CLASS}>{options.title}</span>
        ) : undefined,
        description: options?.description ? (
          <span className={WEGLOT_APPLY_CLASS}>{options.description}</span>
        ) : undefined,
      });
    },
    [defaultOptions?.position, toast]
  ) as AvelaToast;

  avelaToast.update = toast.update;
  avelaToast.promise = toast.promise;
  avelaToast.closeAll = toast.closeAll;
  avelaToast.close = toast.close;
  avelaToast.isActive = toast.isActive;

  avelaToast.error = ({
    title,
    toastId,
    description,
    ...toastProps
  }: ErrorToastProps): ToastId => {
    return toast({
      position: defaultOptions?.position ?? "bottom-right",
      id: toastId,
      title: <span className={WEGLOT_APPLY_CLASS}>{title}</span>,
      description:
        description !== undefined ? (
          description
        ) : (
          <span className={WEGLOT_APPLY_CLASS}>
            Please try again later or report the problem to our support team.
          </span>
        ),
      isClosable: true,
      status: "error",
      ...toastProps,
    });
  };

  return avelaToast;
};
