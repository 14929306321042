import * as _ from "lodash";
import { useCallback, useState } from "react";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";

export type RankedSchool = {
  form_id: string;
  schools_ranking_section_id: string;
  school_id: string;
  rank?: number;
};

type UseUpdateSchoolRank = {
  getDeletedRanks: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.form_school_rank_bool_exp;
  getDeletedOffers: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.offer_bool_exp;
  getDeletedWaitlists: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.waitlist_bool_exp;
  getUpsertedRanks: (
    newRanks: RankedSchool[]
  ) => GQL.form_school_rank_insert_input[];
};

type UseSchoolRank = UseUpdateSchoolRank & {
  setRanks: (initalRanks: RankedSchool[]) => void;
};

export default function useRankedSchools(
  initialRankedSchools: RankedSchool[]
): UseSchoolRank {
  const [ranks, setRanks] = useState<RankedSchool[]>(initialRankedSchools);
  const {
    getDeletedRanks,
    getDeletedOffers,
    getUpsertedRanks,
    getDeletedWaitlists,
  } = useUpdateRankedSchools(ranks);

  return {
    getDeletedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
    getUpsertedRanks,
    setRanks,
  };
}

export function useUpdateRankedSchools(
  ranks: RankedSchool[]
): UseUpdateSchoolRank {
  const getDeletedRanks = useCallback(
    (
      newRanks: GQL.form_school_rank_insert_input[]
    ): GQL.form_school_rank_bool_exp => {
      return {
        _or: ranks
          .filter(
            (rank) =>
              !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
          )
          .map((rank) => ({
            form_id: { _eq: rank.form_id },
            schools_ranking_section_id: {
              _eq: rank.schools_ranking_section_id,
            },
            school_id: { _eq: rank.school_id },
          })),
      };
    },
    [ranks]
  );

  const getDeletedOffers = useCallback(
    (newRanks: GQL.form_school_rank_insert_input[]): GQL.offer_bool_exp => {
      return {
        _or: ranks
          .filter(
            (rank) =>
              !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
          )
          .map((rank) => ({
            deleted_at: { _is_null: true },
            form_id: { _eq: rank.form_id },
            school_id: { _eq: rank.school_id },
          })),
      };
    },
    [ranks]
  );

  const getDeletedWaitlists = useCallback(
    (newRanks: GQL.form_school_rank_insert_input[]): GQL.waitlist_bool_exp => {
      return {
        _or: ranks
          .filter(
            (rank) =>
              !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
          )
          .map((rank) => ({
            deleted_at: { _is_null: true },
            form_id: { _eq: rank.form_id },
            school_id: { _eq: rank.school_id },
          })),
      };
    },
    [ranks]
  );

  const getUpsertedRanks = useCallback(
    (newRanks: RankedSchool[]): GQL.form_school_rank_insert_input[] => {
      return newRanks.map((rank, index) => ({
        form_id: rank.form_id,
        schools_ranking_section_id: rank.schools_ranking_section_id,
        school_id: rank.school_id,
        rank: index,
      }));
    },
    []
  );

  return {
    getDeletedRanks,
    getUpsertedRanks,
    getDeletedWaitlists,
    getDeletedOffers,
  };
}

export function useUpdateRankedSchoolsRemoteData(
  remoteData: RD.RemoteData<Error, RankedSchool[]>
): RD.RemoteData<Error, UseUpdateSchoolRank> {
  const value = useUpdateRankedSchools(remoteData.withDefault([]));

  if (remoteData.isLoading()) {
    return RD.loading();
  }

  if (remoteData.hasError()) {
    return RD.failure(remoteData.error);
  }

  return RD.success(value);
}

function areEquals(rank1: RankedSchool, rank2: RankedSchool) {
  return (
    rank1.form_id === rank2.form_id &&
    rank1.schools_ranking_section_id === rank2.schools_ranking_section_id &&
    rank1.school_id === rank2.school_id
  );
}
