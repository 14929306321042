import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

const StickyBottomContainer = {
  position: "sticky",
  shadow: "dark-lg",
  bottom: "calc(-1 * var(--chakra-space-10))",
  justifyContent: "end",
  width: "calc(100% + (var(--chakra-space-10) * 2))",
  marginLeft: "calc(-1 * var(--chakra-space-10))",
  marginBottom: "calc(-1 * var(--chakra-space-10))",
  paddingX: "10",
  paddingY: "5",
  bg: "white",
  zIndex: "1",
} as const;

export type StickyBottomProps = {
  children: React.ReactNode;
} & FlexProps;

export const StickyBottom: React.FC<StickyBottomProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex align="center" {...StickyBottomContainer} {...props}>
      {children}
    </Flex>
  );
};
