import { gql } from "@apollo/client";

export const INSERT_GLOSSARY_BY_ORGANIZATION = gql`
  mutation InsertGlossaryByOrganization(
    $organizationId: uuid!
    $glossary: [glossary_insert_input!]!
  ) {
    delete_glossary(where: { organization_id: { _eq: $organizationId } }) {
      affected_rows
    }
    insert_glossary(objects: $glossary) {
      affected_rows
    }
  }
`;

export const DELETE_GLOSSARY_BY_ORGANIZATION = gql`
  mutation DeleteGlossaryByOrganization($organizationId: uuid!) {
    delete_glossary(where: { organization_id: { _eq: $organizationId } }) {
      affected_rows
    }
  }
`;
