import { useCallback } from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { SOFT_DELETE_STUDENT } from "src/scenes/parent/students/graphql/mutations";
import * as GQL from "src/types/graphql";

interface UseDeleteStudentActionOptions {
  onSuccess?: () => void;
  studentId: string;
}

const noop = () => {};

export function useDeleteStudentAction(options: UseDeleteStudentActionOptions) {
  const { onSuccess = noop, studentId } = options;

  const { glossary } = useGlossary();

  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: "Are you sure? You can't undo this action afterwards.",
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, delete", colorScheme: "red" },
    header: glossary`Delete student?`,
  });

  const [mutate, { remoteData }] = useRemoteDataMutation<
    GQL.SoftDeleteStudent,
    GQL.SoftDeleteStudentVariables
  >(SOFT_DELETE_STUDENT);

  const toast = useAvelaToast();

  const requestDeleteStudent = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    const toastId = `deleteStudent-${studentId}`;

    try {
      await mutate({ variables: { id: studentId } });

      onSuccess();
    } catch (error) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
          title: glossary`Error deleting student`,
        });
      }
    }
  }, [confirm, glossary, mutate, onSuccess, studentId, toast]);

  return {
    confirmationDialog,
    remoteData,
    requestDeleteStudent,
  };
}
