import { useLocation } from "react-router-dom";
import { State } from "../components/Navigation";

export function useNavLinkState(url: string | undefined): State {
  const location = useLocation();
  if (url === undefined) {
    return "inactive";
  }

  return location.pathname === url ? "active" : "inactive";
}
