import { gql } from "@apollo/client";

export const UPDATE_PREFERRED_LANGUAGE = gql`
  mutation UpdatePreferredLanguage(
    $person_id: uuid!
    $preferred_language: String!
  ) {
    update_person(
      _set: { preferred_language: $preferred_language }
      where: { id: { _eq: $person_id } }
    ) {
      returning {
        id
        preferred_language
      }
    }
  }
`;
