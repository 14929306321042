import { Button, Icon, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useCallback } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_ENROLLMENT_PERIOD } from "../graphql/mutations";
import { Glossary } from "../Text/Glossary";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { GET_ENROLLMENT_PERIODS } from "src/scenes/orgAdmin/enrollmentPeriods/graphql/queries";
import * as Url from "src/services/url";

interface DeleteEnrollmentPeriodButtonProps {
  id: string;
  enrollmentPeriodName: string;
  isDisabled?: boolean;
  isIconButton?: boolean;
}

export const DeleteEnrollmentPeriodButton = ({
  id,
  enrollmentPeriodName,
  isDisabled,
  isIconButton,
}: DeleteEnrollmentPeriodButtonProps) => {
  const { glossary } = useGlossary();
  const navigate = useNavigate();
  const organization = useOrganization();

  const toast = useAvelaToast();

  const [deleteEnrollmentPeriod] = useRemoteDataMutation<
    GQL.DeleteEnrollmentPeriod,
    GQL.DeleteEnrollmentPeriodVariables
  >(DELETE_ENROLLMENT_PERIOD, {
    refetchQueries: [GET_ENROLLMENT_PERIODS],
  });

  const handleDeleteEnrollmentPeriod = useCallback(async () => {
    try {
      await deleteEnrollmentPeriod({ variables: { id: id } });

      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Enrollment period deleted`,
          isClosable: true,
        });
      }

      navigate(
        organization
          .map((org) => Url.OrgAdmin.Enrollments.index(org))
          .withDefault("#")
      );
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Error deleting enrollment period`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  }, [deleteEnrollmentPeriod, glossary, id, toast, navigate, organization]);

  const { confirm, confirmationDialog: deleteConfirmationDialog } =
    useConfirmationDialog({
      header: glossary`Delete ${enrollmentPeriodName}?`,
      body: (
        <Text>
          <Glossary>
            Deleting this enrollment period will remove it from the
            organization, it will no longer be visible to any user. This action
            can’t be undone nor recovered.
          </Glossary>
        </Text>
      ),
      cancelButton: {
        label: "Cancel",
        colorScheme: "primary",
      },
      confirmButton: {
        label: "Yes, delete",
      },
      translate: true,
    });

  const handleDeleteClick = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    handleDeleteEnrollmentPeriod();
  }, [confirm, handleDeleteEnrollmentPeriod]);

  return (
    <>
      {isDisabled ? (
        <Tooltip
          label={
            <Text align="center">
              This enrollment period can be deleted once it has no form
              templates in it
            </Text>
          }
          hasArrow
          placement="left"
          borderRadius="md"
          p={2}
        >
          {isIconButton ? (
            <IconButton
              aria-label="Delete enrollment period"
              icon={<RiDeleteBin6Line />}
              onClick={handleDeleteClick}
              variant="outline"
              isDisabled={isDisabled}
            />
          ) : (
            <Button
              aria-label="Delete enrollment period"
              leftIcon={<Icon as={RiDeleteBin6Line} />}
              variant="outline"
              onClick={handleDeleteClick}
              isDisabled={isDisabled}
            >
              Delete
            </Button>
          )}
        </Tooltip>
      ) : (
        <>
          {isIconButton ? (
            <IconButton
              aria-label="Delete enrollment period"
              icon={<RiDeleteBin6Line />}
              onClick={handleDeleteClick}
              variant="outline"
            />
          ) : (
            <Button
              aria-label="Delete enrollment period"
              leftIcon={<Icon as={RiDeleteBin6Line} />}
              variant="outline"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          )}
        </>
      )}

      {deleteConfirmationDialog}
    </>
  );
};
