import { html } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/utils";
import * as GQL from "src/types/graphql";

export const OFFER_EXTENDED = {
  type: GQL.message_template_type_enum.OfferExtended,
  emailSubject:
    "{{student.name}} received an offer from {{organization.name}}!/¡{{student.name}}  recibió una oferta de {{organization.name}}!",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    {{student.name}} received an offer from {{organization.name}} for grade
    {{grade}} for {{enrollmentPeriod.name}}!<br />
    <br />
    Please log in to accept or decline the offer:
    <a href="{{{applyUrl}}}">{{{applyUrl}}}</a><br />
    <br />
    If you need help, please contact {{supportEmail}}.<br />
    <br />
    -{{organization.name}} Team<br />
    <br />
    —--<br />
    <br />
    Estimado/a {{parent.name}},<br />
    <br />
    {{student.name}} recibió una oferta de {{organization.name}} para el grado
    {{grade}} para {{enrollmentPeriod.name}}!<br />
    <br />
    Por favor, inicie sesión para aceptar o declinar la oferta:
    <a href="{{{applyUrl}}}">{{{applyUrl}}}</a><br />
    <br />
    Si necesita ayuda, por favor contacte a {{supportEmail}}.<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}},

{{student.name}} received an offer from {{organization.name}} for grade {{grade}} for {{enrollmentPeriod.name}}!

Please log in to accept or decline the offer: {{{applyUrl}}}

If you need help, please contact {{supportEmail}}.

-{{organization.name}} Team

—--

Estimado/a {{parent.name}},

{{student.name}} recibió una oferta de {{organization.name}} para el grado {{grade}} para {{enrollmentPeriod.name}}!

Por favor, inicie sesión para aceptar o declinar la oferta: {{{applyUrl}}}

Si necesita ayuda, por favor contacte a {{supportEmail}}.

-Equipo de {{organization.name}}`,
  sms: "{{student.name}} received an offer from {{organization.name}}! Log in to accept/decline it. {{student.name}} recibió una oferta de {{organization.name}}! Inicie sesión para aceptarla/declinarla."
};
