import { Text } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import * as Form from "src/services/form";
import { ColumnId } from "./constants";
import { PreviousForm } from "src/services/form/related";

type Row = {
  form: {
    grades_answers: { grade_config: { label: string | null } | null }[];
    previous_offer: {
      grade: { grade_config: { label: string } | null };
    } | null;
    previous_waitlist: {
      grade: { grade_config: { label: string } | null };
    } | null;
    previous_form: PreviousForm;
  } | null;
};
export function buildGradeColumnDef<T extends Row>(): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form?.grades_answers[0]?.grade_config?.label,
    id: ColumnId.Grade,
    header: "apl. grade",

    cell: (props) => {
      const grade = Form.getGrade(props.row.original.form);

      if (grade) {
        return (
          <TextWithOverflowTooltip
            content={grade}
            maxWidth="120px"
            minWidth="120px"
          />
        );
      }

      return (
        <Text maxWidth="120px" minWidth="120px" variant="placeholder">
          No grade
        </Text>
      );
    },
  };
}
