import { gql } from "@apollo/client";
import { useMemo } from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  CreateQuestionType,
  CreateQuestionTypeVariables,
  ListQuestionTypesForOrganization,
} from "src/types/graphql";

export function useListQuestionTypesRemoteData() {
  const { remoteData } = useRemoteDataQuery<ListQuestionTypesForOrganization>(
    LIST_QUESTION_TYPES_FOR_ORGANIZATION
  );

  return useMemo(() => {
    return remoteData.map((data) => {
      return data.custom_question_type;
    });
  }, [remoteData]);
}

const LIST_QUESTION_TYPES_FOR_ORGANIZATION = gql`
  query ListQuestionTypesForOrganization {
    custom_question_type {
      id
      name
      key
      organization {
        id
        name
      }
    }
  }
`;

export const GET_QUESTION_TYPE_BY_ID = gql`
  query GetQuestionType($id: uuid!) {
    custom_question_type_by_pk(id: $id) {
      id
      name
      organization_id
      key
      custom_question_type_fields(order_by: { question: { order: asc } }) {
        question {
          question
          type
          is_required
          key
          form_question {
            form_question_options(order_by: { order: asc }) {
              label
              value
            }
          }
        }
      }
    }
  }
`;

export function useCreateQuestionTypeMutation() {
  const [createQuestionType] = useRemoteDataMutation<
    CreateQuestionType,
    CreateQuestionTypeVariables
  >(CREATE_QUESTION_TYPE, {
    refetchQueries: [LIST_QUESTION_TYPES_FOR_ORGANIZATION],
  });
  return createQuestionType;
}

const CREATE_QUESTION_TYPE = gql`
  mutation CreateQuestionType(
    $question_type: custom_question_type_insert_input!
  ) {
    insert_custom_question_type_one(object: $question_type) {
      id
    }
  }
`;
