import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { DefaultBranding } from "src/configs/branding";
import { buildTheme } from "src/services/theme/themeBuilder";
import { useBranding } from "./BrandingProvider";

type Props = {
  children: React.ReactNode;
};
export const BrandedChakraProvider: React.FC<Props> = ({ children }) => {
  const { branding } = useBranding();

  const theme = buildTheme(branding ?? DefaultBranding);
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
