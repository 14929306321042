import { Heading, VStack } from "@chakra-ui/react";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import {
  useOrderByParams,
  usePaginationParams,
} from "src/hooks/useCommonSearchParams";
import { useOrganization } from "src/hooks/useOrganization";
import { usePaginatedRemoteDataQuery } from "src/hooks/usePaginatedRemoteDataQuery";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";
import * as breadcrumb from "src/services/breadcrumb";
import * as GQL from "src/types/graphql";
import { MessagesList } from "./List";
import { GET_MESSAGES } from "./graphql/queries";

export const Messages = () => {
  const organization = useOrganization();
  const { orderBy, setOrderBy } = useOrderByParams();
  const { pagination, setPagination } = usePaginationParams();
  const { messageAdhocFilter, messageAdhocReceiverFilter } =
    useSchoolAdminPermissions();

  const buildOrderBy = (): GQL.message_adhoc_order_by => {
    if (!orderBy) {
      return { created_at: GQL.order_by.desc };
    }

    if (orderBy.sortKey === "from") {
      return { sender_person: { full_name: orderBy.sortType } };
    }

    if (orderBy.sortKey === "sent") {
      return { created_at: orderBy.sortType };
    }

    return {};
  };

  const { usePaginatedQuery } = usePaginatedRemoteDataQuery<
    GQL.GetMessages,
    GQL.GetMessagesVariables
  >(GET_MESSAGES, {
    organization_id: organization.map((org) => org.id).withDefault(""),
    limit: pagination.limit,
    offset: pagination.offset,
    order_by: [buildOrderBy()],
    message_filter: messageAdhocFilter(),
    message_receiver_filter: messageAdhocReceiverFilter(),
  });

  const { remoteData } = usePaginatedQuery({
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  return (
    <VStack align="left">
      <Breadcrumb
        items={breadcrumb.messages.getBreadcrumbsForList(organization)}
        mb={4}
      />
      <Heading as="h1" pb={8}>
        Messages
      </Heading>

      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <MessagesList
            messages={data.message_adhoc}
            offset={pagination.offset}
            limit={pagination.limit}
            onSort={setOrderBy}
            count={data.message_adhoc_aggregate.aggregate?.count ?? 0}
            onFetchMore={setPagination}
          />
        )}
      </GQLRemoteDataView>
    </VStack>
  );
};
