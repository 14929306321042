import { gql } from "@apollo/client";

export const SCHOOL_FRAGMENT = gql`
  fragment SchoolTotals on school_aggregate {
    totals: aggregate {
      total: count
    }
  }
  fragment SchoolFragment on school {
    id
    name
    reference_id
    status
    street_address
    street_address_line_2
    city
    state
    zip_code
  }
`;

export const GET_SCHOOL_BY_ID = gql`
  ${SCHOOL_FRAGMENT}
  query GetSchool($id: uuid!) {
    school_by_pk(id: $id) {
      ...SchoolFragment
    }
  }
`;

export const GET_SCHOOLS = gql`
  ${SCHOOL_FRAGMENT}
  query GetSchools(
    $organization_id: uuid!
    $limit: Int = 25
    $offset: Int = 0
    $order_by: [school_order_by!]
  ) {
    school_aggregate(where: { organization_id: { _eq: $organization_id } }) {
      ...SchoolTotals
    }
    school(
      where: { _and: { organization_id: { _eq: $organization_id } } }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...SchoolFragment
    }
  }
`;
