import {
  Flex,
  Icon,
  IconButton,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { RiIndeterminateCircleLine } from "react-icons/ri";
import {
  FORM_SCHOOL_RANK_SUB_STATUS,
  FormSchoolRankSubStatusIndex,
} from "src/constants";
import { SchoolWithFakeRank } from "src/scenes/parent/forms/LateEdit";
import * as GQL from "src/types/graphql";
import { StatusTagMetadata } from "../DataDisplay/StatusTag";
import { School } from "../Inputs/MultiSelectSchoolRank";

type Props = {
  schoolsRank: readonly GQL.GetSchoolsRank_form_school_rank[];
  onDeleteSchoolRank: (schoolRank: GQL.GetSchoolsRank_form_school_rank) => void;
  onDeleteNewSchool: (school: School) => void;
  newSchools: SchoolWithFakeRank[];
  renderSelectItem: (
    item: School,
    subStatus?: StatusTagMetadata
  ) => React.ReactElement;
  rankingEnabled: boolean;
};
export const SchoolRankingEditList: React.FC<Props> = ({
  schoolsRank,
  onDeleteSchoolRank,
  onDeleteNewSchool,
  newSchools,
  renderSelectItem,
  rankingEnabled,
}) => {
  return (
    <OrderedList width="100%">
      {_.sortBy(schoolsRank, (schoolRank) => schoolRank.rank).map(
        (schoolRank) => {
          const hasRank = rankingEnabled && typeof schoolRank.rank === "number";
          const subStatus =
            FORM_SCHOOL_RANK_SUB_STATUS[
              schoolRank.sub_status as FormSchoolRankSubStatusIndex
            ];
          const subStatusWithWaitlistPosition = subStatus
            ? {
                ...subStatus,
                longLabel: getSubstatusLabel(schoolRank) ?? subStatus.longLabel,
              }
            : undefined;

          return (
            <ListItem display="flex" key={schoolRank.school.name} pb={6}>
              <Flex
                direction="row"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex alignItems="center">
                  {hasRank && (
                    <Text fontWeight={600} mr={4}>
                      {schoolRank.rank + 1}
                    </Text>
                  )}
                  <Flex direction="column" gap="2">
                    {renderSelectItem(
                      schoolRank.school,
                      subStatusWithWaitlistPosition
                    )}
                  </Flex>
                </Flex>

                {schoolRank.sub_status !== GQL.offer_status_enum.Accepted && (
                  <IconButton
                    aria-label="late-delete-school-rank"
                    variant="link"
                    colorScheme="red"
                    size="sm"
                    icon={<Icon w={5} h={5} as={RiIndeterminateCircleLine} />}
                    onClick={() => onDeleteSchoolRank(schoolRank)}
                  />
                )}
              </Flex>
            </ListItem>
          );
        }
      )}
      {newSchools.map((school) => (
        <ListItem display="flex" key={school.name} pb={6}>
          <Flex direction="row" width="100%" justifyContent="space-between">
            <Flex alignItems="center">
              {rankingEnabled && (
                <Text fontWeight={600} mr={4}>
                  {school.rank + 1}
                </Text>
              )}
              <Text fontWeight={600} fontSize="md">
                {school.name}
              </Text>
            </Flex>

            <IconButton
              aria-label="late-delete-new-school"
              variant="link"
              colorScheme="red"
              size="sm"
              icon={<Icon w={5} h={5} as={RiIndeterminateCircleLine} />}
              onClick={() => onDeleteNewSchool(school)}
            />
          </Flex>
        </ListItem>
      ))}
    </OrderedList>
  );
};

// This is for adding waitlist position to the Waitlisted substatus tag
const getSubstatusLabel = (schoolRank: GQL.GetSchoolsRank_form_school_rank) => {
  const waitlistPosition = schoolRank.waitlists[0]?.waitlist_position?.position;
  if (
    !schoolRank.offers[0] &&
    schoolRank.waitlists[0]?.status === GQL.waitlist_status_enum.Waitlisted &&
    waitlistPosition
  ) {
    return `${GQL.waitlist_status_enum.Waitlisted}, #${waitlistPosition}`;
  }
  return undefined;
};
