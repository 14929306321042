import { gql } from "@apollo/client";

export const GET_USER_ADDRESSES = gql`
  query GetUserAddresses($user_id: String!) {
    user_address(
      limit: 3
      where: { user_id: { _eq: $user_id } }
      order_by: { updated_at: desc }
    ) {
      id
      street_address
      street_address_line_2
      city
      state
      zip_code
    }
  }
`;
