import { ColumnDef } from "@tanstack/react-table";
import { useCallback } from "react";
import { CheckboxCell } from "../components/Table/CheckboxCell";
import { Key, Selection, useMultiselectState } from "./useMultiselectState";

export type TableSelectionProps<K extends Key, V> = {
  getId: (item: V) => K;
  count: number;
};

export function useTableSelection<K extends Key, V>({
  count,
  getId,
}: TableSelectionProps<K, V>) {
  const { selection, setSelection, toggleSelectAll, toggleSelectItem } =
    useMultiselectState<K, V>(Selection.create(getId));

  const checkboxColumnDef = useCallback(
    ({ id }: { id: string }): ColumnDef<V> => {
      return {
        id,
        header: () => (
          <CheckboxCell
            isChecked={selection.size === count}
            isIndeterminate={selection.size > 0 && selection.size < count}
            onChange={() => toggleSelectAll(count)}
          />
        ),
        cell: ({ row }) => (
          <CheckboxCell
            isChecked={selection.includes(row.original)}
            onChange={() => toggleSelectItem(row.original)}
          />
        ),
        size: 40,
      };
    },
    [count, selection, toggleSelectAll, toggleSelectItem]
  );

  return {
    selection,
    setSelection,
    toggleSelectAll,
    toggleSelectItem,
    checkboxColumnDef,
  };
}

export type checkboxColumnDefProps<K extends Key, V> = {
  id: string;
  selection: Selection<K, V>;
  onSelectionChange: (selection: Selection<K, V>) => void;
  count: number;
};

export function checkboxColumnDef<K extends Key, V>({
  id,
  selection,
  onSelectionChange,
  count,
}: checkboxColumnDefProps<K, V>): ColumnDef<V> {
  return {
    id,
    size: 40,
    header: () => (
      <CheckboxCell
        isChecked={selection.size === count}
        isIndeterminate={selection.size > 0 && selection.size < count}
        onChange={() =>
          onSelectionChange(
            selection.size < count
              ? selection.selectAll(count)
              : selection.clear()
          )
        }
      />
    ),
    cell: ({ row }) => {
      // For rows that have subrows:
      // - Checkbox style depends on subrows included in selection
      // - Don't include the row itself in selection
      // Note: This logic assumes that:
      // 1. Rows cannot be selected if they have subrows.
      // 2. Subrows do not have further subrows.
      if (row.originalSubRows !== undefined) {
        const selectables = row.originalSubRows;
        const allSelected = row.originalSubRows.every((subR) =>
          selection.includes(subR)
        );
        const someSelected =
          !allSelected &&
          row.originalSubRows.some((subR) => selection.includes(subR));

        const handleOnChange = () => {
          return allSelected
            ? selection.remove(...selectables)
            : selection.add(...selectables);
        };

        return (
          <CheckboxCell
            left={row.depth * 4}
            width="100%"
            zIndex={1}
            isChecked={allSelected}
            isIndeterminate={someSelected}
            onChange={() => onSelectionChange(handleOnChange())}
          />
        );
      }

      // For bottom level rows
      const handleOnChange = () => {
        return selection.includes(row.original)
          ? selection.remove(row.original)
          : selection.add(row.original);
      };
      return (
        <CheckboxCell
          left={row.depth * 4}
          width="100%"
          zIndex={1}
          isChecked={selection.includes(row.original)}
          onChange={() => onSelectionChange(handleOnChange())}
        />
      );
    },
  };
}
