import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS_BY_GRADES_CONFIG = gql`
  query GetOrganizationsByGradesConfig {
    organization {
      id
      grade_configs {
        id
        label
        order
        value
      }
    }
  }
`;

export const GET_ORG_GRADES_CONFIG_BY_PK = gql`
  query GetOrgGradesConfigByPk($id: uuid!) {
    organization_by_pk(id: $id) {
      id
      grade_configs {
        id
        label
        order
        value
      }
    }
  }
`;
