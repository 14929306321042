type ObjectWithNestedFields = {
  [key: string]: any;
};

/**
 * Removes a list of fields from an object recursively without mutating the original object.
 * @param obj - The object from which fields should be removed.
 * @param fieldsToRemove - An array of field names to be removed.
 * @returns A new object with the specified fields removed recursively.
 */
export function removeFields(
  obj: ObjectWithNestedFields,
  ...fieldsToRemove: string[]
): ObjectWithNestedFields {
  const newObj: ObjectWithNestedFields = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        const newArray = value.map((item: ObjectWithNestedFields) =>
          removeFields(item, ...fieldsToRemove)
        );

        newObj[key] = newArray;
      } else if (typeof value === "object" && value !== null) {
        newObj[key] = removeFields(value, ...fieldsToRemove);
      } else if (!fieldsToRemove.includes(key)) {
        newObj[key] = value;
      }
    }
  }

  return newObj;
}

/**
 * Converts a JavaScript object into a JSON string representation and then parses it back into an object.
 * Creates a deep clone of the input object, removing any references to the original object and its properties.
 *
 * This will effectively remove all undefined, Function, and symbols from the object
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description
 *
 * @param object - The input object to be converted to JSON and then parsed back into an object.
 * @returns The parsed object, which is a deep clone of the original input object.
 */

export function toJSON<T extends {}>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}
