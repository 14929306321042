import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { GET_FORM_ADDRESS_ANSWERS_FOR_FILTER } from "src/components/Form/graphql/queries";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";

type Props = {
  formId: uuid;
};

export type FormAddress = Exclude<
  GQL.GetFormAddressAnswersForFilter["form_address"][0],
  "__typename"
>;
type ReturnType = RemoteData<ApolloError, FormAddress | undefined>;
export function useFormAddresForFilter({ formId }: Props): ReturnType {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormAddressAnswersForFilter,
    GQL.GetFormAddressAnswersForFilterVariables
  >(GET_FORM_ADDRESS_ANSWERS_FOR_FILTER, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "network-only",
  });

  const address = useMemo(
    () => remoteData.map((data) => data.form_address[0]),
    [remoteData]
  );

  return address;
}
