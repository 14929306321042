import { EditFormActions, SET_CURRENT_QUESTION_ID } from "./actions";

export interface EditFormState {
  currentQuestionId?: string;
}

export const initialEditFormState: EditFormState = {
  currentQuestionId: undefined,
};

export const reducer = (
  state = initialEditFormState,
  action: EditFormActions
): EditFormState => {
  switch (action.type) {
    case SET_CURRENT_QUESTION_ID: {
      return { ...state, currentQuestionId: action.value };
    }
  }
};
