import Immutable from "immutable";
import { z } from "zod";
import * as Draft from "./statusesDraft";
import { Change, FormTemplateIdType, WithDraft } from "./common";
import { StatusDescriptionsFormType } from "../EditFormTemplateTabs/Statuses/StatusDescriptionsForm";

export type StatusesChange = Change<
  StatusDescriptionsFormType,
  Draft.StatusesDraft
>;

type StateInternal = {
  statuses: Immutable.Map<FormTemplateIdType, StatusesChange>;
  save: uuid | undefined;
  reset: uuid | undefined;
};
export type State = Immutable.Record<StateInternal>;

export const NewState = Immutable.Record<StateInternal>({
  statuses: Immutable.Map(),
  save: undefined,
  reset: undefined
});
/**
 * Local storage state
 */
export const LocalstorageSchema = z.object({
  statuses: z.record(z.string().uuid(), z.object({ draft: z.any() }))
});

export type LocalstorageState = z.infer<typeof LocalstorageSchema>;

export function serialize(state: State): LocalstorageState {
  const statuses = state
    .get("statuses")
    .map((change) => {
      return { draft: change.draft } as const;
    })
    .filter((change): change is WithDraft<Draft.StatusesDraft> => {
      return change.draft !== undefined;
    })
    .mapKeys((key) => key.get("formTemplateId"))
    .toObject();

  const localStoroageState: LocalstorageState = {
    statuses
  };

  return localStoroageState;
}

export function deserialize(state: State, json: unknown): State {
  const result = LocalstorageSchema.safeParse(json);
  const savedState: LocalstorageState | undefined = result.success
    ? result.data
    : undefined;

  if (!savedState) {
    return state;
  }

  return NewState({
    statuses: state.get("statuses").map((change, key) => {
      return {
        ...change,
        draft: savedState.statuses[key.get("formTemplateId")]?.draft as
          | Draft.StatusesDraft
          | undefined
      };
    })
  });
}
