import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { QuestionTypes } from "src/services/url/Admin";
import { CreateQuestionType } from "./CreateQuestionType";
import { ListQuestionTypes } from "./ListQuestionTypes";
import { ViewQuestionType } from "./ViewQuestionType";

export const AdminQuestionTypeRoutes = (
  <Route
    path={QuestionTypes.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.question_types">
          <ListQuestionTypes />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={QuestionTypes.newPath}
      element={
        <RequireUserPermissions permission="question_type:create">
          <CreateQuestionType />
        </RequireUserPermissions>
      }
    />
    <Route
      path={QuestionTypes.viewPath}
      element={
        <RequireUserPermissions permission="question_type:read">
          <ViewQuestionType />
        </RequireUserPermissions>
      }
    />
  </Route>
);
