import {
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { RiCloseLine, RiSearchLine } from "react-icons/ri";

interface SearchInputProps {
  label?: string;
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
  onClear?: () => void;
  value?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  label,
  placeholder = "Search by...",
  onSearch,
  onClear,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(value ?? "");
  useEffect(() => {
    setSearchTerm(value ?? "");
  }, [value]);

  const handleSearchAction = () => {
    onSearch(searchTerm);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") return;

    handleSearchAction();
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
    if (onClear) onClear();
  };

  return (
    <Stack width="100%">
      {label && <Text mb={2}>{label}</Text>}
      <InputGroup>
        <InputRightElement
          children={
            searchTerm ? (
              <IconButton
                aria-label="clear-search-icon"
                icon={<RiCloseLine />}
                colorScheme="gray"
                background="none"
                size="sm"
              />
            ) : (
              ""
            )
          }
          onClick={handleClearSearchTerm}
          mr={12}
        />
        <Input
          type="text"
          placeholder={placeholder}
          onChange={handleChangeInput}
          onKeyDown={handleKeyDown}
          value={searchTerm}
        />
        <InputRightAddon
          children={
            <IconButton
              aria-label="search-icon"
              icon={<RiSearchLine />}
              colorScheme="gray"
              background="none"
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
            />
          }
          onClick={handleSearchAction}
          background="none"
          padding="0"
        />
      </InputGroup>
    </Stack>
  );
};
