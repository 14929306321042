import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  SystemStyleObject,
  Text,
} from "@chakra-ui/react";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { maybePluralize } from "src/services/format";
import * as GQL from "src/types/graphql";
import { FormSchoolRanks } from "./FormSchoolRanks/FormSchoolRanks";
import { shouldShowApplicationSchoolRankOptionsMenu } from "./helpers";

interface FormSchoolRanksDisclosureProps {
  formTemplateId: uuid;
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;
  formSchoolRanks: GQL.FormSummaryFragment_form_school_ranks[];
  gradeLabel: string;
  offerCount: number;
  studentName: string;
  rankingEnabled: boolean;
}

const dividerBarStyle: SystemStyleObject = {
  bg: "gray.200",
  content: "''",
  flexGrow: 1,
  height: "1px",
};

export function FormSchoolRanksDisclosure(
  props: FormSchoolRanksDisclosureProps
) {
  const {
    form,
    formSchoolRanks,
    gradeLabel,
    offerCount,
    studentName,
    rankingEnabled,
  } = props;

  const organization = useOrganization();

  const buttonSchoolsText = maybePluralize(
    formSchoolRanks.length,
    "See 1 school",
    `See ${formSchoolRanks.length} schools`
  );

  const subStatusTexts: string[] = [];

  const waitlistCount = formSchoolRanks.filter(
    (rank) => rank.waitlists[0]?.status === GQL.waitlist_status_enum.Waitlisted
  ).length;
  if (waitlistCount > 0) {
    subStatusTexts.push(
      maybePluralize(waitlistCount, `${waitlistCount} waitlist`)
    );
  }

  if (offerCount > 0) {
    subStatusTexts.push(maybePluralize(offerCount, `${offerCount} offer`));
  }

  const acceptanceCount = formSchoolRanks.filter(
    (rank) => rank.offers[0]?.status === GQL.offer_status_enum.Accepted
  ).length;
  if (acceptanceCount > 0) {
    subStatusTexts.push(
      maybePluralize(acceptanceCount, `${acceptanceCount} acceptance`)
    );
  }

  const subStatusText = subStatusTexts.join(", ");
  let defaultIndex;
  if (
    formSchoolRanks.some(
      (rank) =>
        organization.hasData() &&
        shouldShowApplicationSchoolRankOptionsMenu(
          rank.offers[0] ?? null,
          rank.waitlists[0] ?? null,
          organization.data
        )
    )
  ) {
    defaultIndex = 0;
  }

  return (
    <Accordion allowToggle border="none" defaultIndex={defaultIndex}>
      <AccordionItem border="none" borderTopWidth={0}>
        <Flex _after={dividerBarStyle} alignItems="center">
          <AccordionButton
            borderColor="gray.300"
            borderRadius="9999em"
            borderStyle="solid"
            borderWidth="1px"
            color="gray.700"
            fontSize="xs"
            paddingBlock={1}
            paddingInline={2}
            textAlign="start"
            width="fit-content"
          >
            <AccordionIcon fontSize="xs" marginInlineEnd={2} />

            <span>
              <Text as="span" fontWeight={600}>
                <Glossary>{buttonSchoolsText}</Glossary>
              </Text>

              {!!subStatusText && (
                <Text as="span" fontWeight={300}>
                  &nbsp;|&nbsp;
                  <Glossary>{subStatusText}</Glossary>
                </Text>
              )}
            </span>
          </AccordionButton>
        </Flex>

        <AccordionPanel
          borderBlockEndColor="blackAlpha.100"
          borderBlockEndStyle="solid"
          borderBlockEndWidth="1px"
          p={0}
          paddingBlockEnd={6}
        >
          <FormSchoolRanks
            formTemplateId={props.formTemplateId}
            form={form}
            formSchoolRanks={formSchoolRanks}
            gradeLabel={gradeLabel}
            studentName={studentName}
            rankingEnabled={rankingEnabled}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
