import {
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import { mapCredentials } from "./functions";
import { CreateWebhookRequest } from "./types";
import {
  useWebhookManagementAPI,
  WehookManagementError,
} from "./useWebhookManagementAPI";
import {
  EMPTY_FORM_TYPE,
  EventType,
  WebhookForm,
  WebhookFormType,
} from "./WebhookForm";

export const NewWebhook = () => {
  const toast = useToast();
  const organization = useOrganization();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const api = useWebhookManagementAPI();
  const [selectedEvents, setSelectedEvents] = React.useState<EventType[]>([]);

  const initialValues = EMPTY_FORM_TYPE;

  const handleSubmit = React.useCallback(
    async (webhook: WebhookFormType) => {
      if (!organization.hasData()) throw Error("Invalid organization.");

      setSubmitting(true);
      try {
        const organization_id = organization.data.id;

        const request: CreateWebhookRequest = {
          name: webhook.name,
          endpoint_url: webhook.endpoint_url,
          credentials: mapCredentials(webhook),
        };

        const response = await api.createWebhook(organization_id, request);

        if (!response?.pk) {
          throw new WehookManagementError(
            "Webhook did not have a primary key",
            { request, response }
          );
        }

        await api.subscribeEvents(organization_id, response.pk, {
          event_names: selectedEvents.map((value) => value.id),
        });

        navigate(
          organization
            .map(
              (org) => `${Url.OrgAdmin.DataServices.index(org)}?tab=Webhooks`
            )
            .withDefault("#")
        );

        toast({
          id: "create-webhook",
          title: `Webhook created`,
          isClosable: true,
          status: "info",
        });
      } catch (err) {
        const description = (): string => {
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "Webhook already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to create webhook.";
            }
          }
          console.error(err);
          return "Check your network and try again.";
        };
        toast({
          id: "create-webhook",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [api, navigate, organization, selectedEvents, toast]
  );

  return (
    <>
      <Breadcrumb
        items={breadcrumb.dataServices.getBreadcrumbsForNewWebhook(
          organization
        )}
        mb={8}
      />

      <Formik<WebhookFormType>
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Flex as={Form} direction="column" gap={6} height="95%">
              <Heading as="h1" fontSize="2xl" fontWeight="600">
                New webhook
              </Heading>
              <Text>
                Name your new webhook and indicate the type of connection.
              </Text>
              <WebhookForm
                onSelectedEvents={setSelectedEvents}
                selectedEvents={selectedEvents}
                values={values}
              />
              <Spacer />
              <Flex align="center" gap={6}>
                <Button
                  as={NavLink}
                  to={organization
                    .map(
                      (org) =>
                        `${Url.OrgAdmin.DataServices.index(org)}?tab=Webhooks`
                    )
                    .withDefault("#")}
                  isLoading={organization.isLoading()}
                  variant="outline"
                  colorScheme="gray"
                >
                  Back
                </Button>
                <Spacer />
                <Button type="submit" isLoading={submitting}>
                  Create
                </Button>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </>
  );
};
