import { gql } from "@apollo/client";

export const ADD_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS = gql`
  mutation AddFormSchoolTagAndUpdatePriorityGroups(
    $form_school_tags: [form_school_tag_insert_input!]!
    $waitlist_updates: [waitlist_updates!]!
  ) {
    insert_form_school_tag(
      objects: $form_school_tags
      on_conflict: {
        update_columns: []
        constraint: form_school_tag_form_id_school_id_tag_id_key
      }
    ) {
      affected_rows
    }
    update_waitlist_many(updates: $waitlist_updates) {
      affected_rows
    }
  }
`;

export const ADD_FORM_TAG = gql`
  mutation AddFormTag($form_tags: [form_tag_insert_input!]!) {
    insert_form_tag(
      objects: $form_tags
      on_conflict: {
        update_columns: []
        constraint: form_tag_tag_id_form_id_key
      }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS = gql`
  mutation RemoveFormSchoolTagAndUpdatePriorityGroups(
    $condition: form_school_tag_bool_exp!
    $waitlist_updates: [waitlist_updates!]!
  ) {
    delete_form_school_tag(where: $condition) {
      affected_rows
    }
    update_waitlist_many(updates: $waitlist_updates) {
      affected_rows
    }
  }
`;

export const REMOVE_FORM_TAG = gql`
  mutation RemoveFormTag($condition: form_tag_bool_exp!) {
    delete_form_tag(where: $condition) {
      affected_rows
    }
  }
`;

export const NEW_TAG = gql`
  mutation NewTag(
    $enrollment_period_id: uuid!
    $name: citext!
    $description: String
    $tag_group_id: uuid
  ) {
    insert_enrollment_period_tag_one(
      object: {
        name: $name
        description: $description
        enrollment_period_id: $enrollment_period_id
        tag_group_id: $tag_group_id
      }
    ) {
      id
      enrollment_period_id
      name
    }
  }
`;

export const NEW_TAG_GROUP = gql`
  mutation NewTagGroup(
    $enrollment_period_id: uuid!
    $name: String!
    $is_external: Boolean!
    $max_tags: Int
  ) {
    insert_tag_group_one(
      object: {
        name: $name
        enrollment_period_id: $enrollment_period_id
        is_external: $is_external
        max_tags: $max_tags
      }
    ) {
      id
      name
    }
  }
`;

export const EDIT_TAG_GROUP = gql`
  mutation EditTagGroup(
    $tag_group_id: uuid!
    $name: String!
    $is_external: Boolean!
  ) {
    update_tag_group_by_pk(
      _set: { name: $name, is_external: $is_external }
      pk_columns: { id: $tag_group_id }
    ) {
      id
      name
    }
  }
`;

export const DELETE_TAG_GROUP = gql`
  mutation DeleteTagGroup(
    $tag_group_id: uuid!
    $tag_ids: [uuid!]!
    $internal_tag_group_id: uuid!
  ) {
    update_enrollment_period_tag_many(
      updates: {
        where: { id: { _in: $tag_ids } }
        _set: { tag_group_id: $internal_tag_group_id }
      }
    ) {
      affected_rows
    }
    delete_tag_group_by_pk(id: $tag_group_id) {
      id
    }
  }
`;

export const EDIT_TAG = gql`
  mutation EditTag($tag_id: uuid!, $name: citext!, $description: String) {
    update_enrollment_period_tag_by_pk(
      _set: { name: $name, description: $description }
      pk_columns: { id: $tag_id }
    ) {
      id
      enrollment_period_id
      name
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($tag_id: uuid!) {
    delete_enrollment_period_tag_by_pk(id: $tag_id) {
      id
    }
  }
`;
