import { Button, Icon, Text, Tooltip } from "@chakra-ui/react";
import { useCallback } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_FORM_TEMPLATE } from "../graphql/mutations";
import { Glossary } from "../Text/Glossary";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "../graphql/queries";
import { useNavigate, useParams } from "react-router-dom";
import { EnrollmentPeriodFormTemplates } from "src/services/url/OrgAdmin";
import { useOrganization } from "src/hooks/useOrganization";
import { GET_ENROLLMENT_PERIODS } from "src/scenes/orgAdmin/enrollmentPeriods/graphql/queries";

interface DeleteFormTemplateButtonProps {
  id: string;
  formTemplateName: string;
  isDisabled?: boolean;
}

export const DeleteFormTemplateButton = ({
  id,
  formTemplateName,
  isDisabled,
}: DeleteFormTemplateButtonProps) => {
  const { glossary } = useGlossary();
  const navigate = useNavigate();
  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();

  const toast = useAvelaToast();

  const [deleteFormTemplate] = useRemoteDataMutation<
    GQL.DeleteFormTemplate,
    GQL.DeleteFormTemplateVariables
  >(DELETE_FORM_TEMPLATE, {
    refetchQueries: [
      GET_ENROLLMENT_PERIODS,
      GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD,
    ],
  });

  const handleDeleteFormTemplate = useCallback(async () => {
    try {
      await deleteFormTemplate({ variables: { id: id } });

      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Form deleted`,
          isClosable: true,
        });
      }

      navigate(
        organization
          .map((org) =>
            EnrollmentPeriodFormTemplates.index(org, enrollmentPeriodId)
          )
          .withDefault("#")
      );
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Error deleting form`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  }, [
    deleteFormTemplate,
    glossary,
    id,
    toast,
    navigate,
    organization,
    enrollmentPeriodId,
  ]);

  const { confirm, confirmationDialog: deleteConfirmationDialog } =
    useConfirmationDialog({
      header: glossary`Delete ${formTemplateName}?`,
      body: (
        <Text>
          <Glossary>
            Deleting this form will remove it from the organization, it will no
            longer be visible to any user. This action can’t be undone nor
            recovered.
          </Glossary>
        </Text>
      ),
      cancelButton: {
        label: "Cancel",
        colorScheme: "primary",
      },
      confirmButton: {
        label: "Yes, delete",
      },
      translate: true,
    });

  const handleDeleteClick = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    handleDeleteFormTemplate();
  }, [confirm, handleDeleteFormTemplate]);

  return (
    <>
      {isDisabled ? (
        <Tooltip
          label={
            <Text align="center">
              This form can be deleted once it has no form responses in it
            </Text>
          }
          hasArrow
          placement="left"
          borderRadius="md"
          p={2}
        >
          <Button
            aria-label="Delete form"
            leftIcon={<Icon as={RiDeleteBin6Line} />}
            variant="outline"
            onClick={handleDeleteClick}
            isDisabled={isDisabled}
          >
            Delete
          </Button>
        </Tooltip>
      ) : (
        <Button
          aria-label="Delete form"
          leftIcon={<Icon as={RiDeleteBin6Line} />}
          variant="outline"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      )}

      {deleteConfirmationDialog}
    </>
  );
};
