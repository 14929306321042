import { Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { EnrollmentPeriodFormTemplates } from "src/services/url/OrgAdmin";

export type EditFormTemplateBackButtonProps = {
  backPath?: string;
};

export const EditFormTemplateBackButton: FunctionComponent<
  EditFormTemplateBackButtonProps
> = (props) => {
  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();

  const {
    backPath = organization
      .map((org) =>
        EnrollmentPeriodFormTemplates.index(org, enrollmentPeriodId)
      )
      .withDefault("#")
  } = props;
  const navigate = useNavigate();

  return (
    <Button
      variant="outline"
      colorScheme="gray"
      onClick={() => {
        navigate(backPath);
      }}
      gridColumn="0"
    >
      Back
    </Button>
  );
};
