import { ApolloProvider } from "@apollo/client";
import React from "react";
import useAccessToken from "src/hooks/useAccessToken";
import useUser from "src/hooks/useUser";
import createApolloClient from "src/services/createApolloClient";
import * as Env from "src/services/env";
import { Status } from "src/types/authData";
import { Loading } from "../Feedback/Loading";

type Props = { children: React.ReactElement };
export const ApolloProviderWithCredential: React.FC<Props> = ({ children }) => {
  const user = useUser();
  const accessToken = useAccessToken();
  const env = Env.read();

  if (accessToken.status === Status.LOADING || user.status === Status.LOADING) {
    return <Loading />;
  }

  if (
    accessToken.status === Status.UNAUTHENTICATED ||
    user.status === Status.UNAUTHENTICATED
  ) {
    // This should not be possible, but the type system doesn't know since we wrap the real handler for this inside useEffect
    return null;
  }

  return (
    <ApolloProvider
      client={createApolloClient(
        env.REACT_APP_HASURA_URL,
        accessToken.data,
        user.data.role
      )}
    >
      {children}
    </ApolloProvider>
  );
};
