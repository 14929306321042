import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Stepper } from "../Feedback/Stepper";
import { StepProps } from "../Layout/FormStepLayout";
import { Overlay } from "../Layout/Overlay";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";

export function AccordionStepper(props: StepProps) {
  const { currentStep, steps } = props;
  const [openIndex, setOpenIndex] = useState<number>();
  const navigate = useNavigate();
  const organization = useOrganization();
  const { formId = "" } = useParams();

  const handleItemClick = useCallback(
    (stepIndex: number, isCurrent: boolean) => {
      if (isCurrent) {
        return setOpenIndex(1);
      }
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, stepIndex))
      );
    },
    [formId, navigate, organization]
  );

  const handleOnChange = useCallback(() => {
    openIndex === 0 ? setOpenIndex(1) : setOpenIndex(0);
  }, [openIndex]);

  return (
    <>
      <Accordion
        allowToggle
        zIndex={4}
        onChange={handleOnChange}
        index={openIndex}
      >
        <AccordionItem border="none">
          <AccordionButton
            borderRadius={20}
            paddingBlock={2}
            textAlign="start"
            background="white"
            boxShadow="lg"
            _expanded={{ borderBottomRadius: 0 }}
            _hover={{ background: "white" }}
          >
            <Box w="100%">
              <Stepper currentStep={currentStep} steps={steps} />
            </Box>
            <AccordionIcon ml={2} />
          </AccordionButton>

          <AccordionPanel
            background="white"
            p={0}
            borderBottomRadius={20}
            maxH="calc(100vh - 250px)"
            overflow="scroll"
            position="absolute"
            w="100vw"
            maxW={{ base: "calc(100vw - 32px)", md: 568 }}
          >
            {steps.map((step, index) => {
              const isCurrent = currentStep - 1 === index;
              const isPrevious = currentStep - 1 > index;

              return (
                <Flex
                  onClick={
                    isPrevious
                      ? () => handleItemClick(index + 1, isCurrent)
                      : () => {}
                  }
                  key={index}
                  color={
                    isCurrent ? "primary.500" : isPrevious ? "" : "gray.400"
                  }
                  alignItems="center"
                  justifyContent="space-between"
                  px={4}
                  py={2}
                  background={isCurrent ? "primary.50" : "white"}
                  _last={{ borderBottomRadius: 20 }}
                  cursor="pointer"
                >
                  <Text fontWeight={isCurrent ? 700 : 500}>{step.title}</Text>
                  {isCurrent && <Text fontSize="xs">Current section</Text>}
                </Flex>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {openIndex === 0 && <Overlay onClick={handleOnChange} />}
    </>
  );
}
