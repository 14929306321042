import { Grid } from "@chakra-ui/layout";
import { FlexProps, FormLabel, GridProps } from "@chakra-ui/react";
import { Field } from "formik";
import { FormControl } from "formik-chakra-ui";
import { FormInput } from "src/components/Inputs/FormInput";
import { Option } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { SingleSelectInput } from "src/components/Inputs/QuestionDynamicInputs/SingleSelectInput";
import { UserGroupType } from "src/schemas/TeamMember";

type Props = {
  onChangeUserRole: (userGroup: UserGroupType | undefined) => void;
  userGroups: UserGroupType[];
  disableRole?: boolean;
} & FlexProps &
  GridProps;

export const ApiCredentialForm: React.FC<Props> = ({
  onChangeUserRole,
  userGroups,
  disableRole = false,
  ...props
}) => {
  const onUserRoleOptionSelected = (option: Option) => {
    onChangeUserRole(userGroups.find((group) => group.id === option.key));
  };

  return (
    <Grid templateColumns={"repeat(2, 1fr)"} gap={8}>
      <FormInput
        placeholder="e.g. Demo API Client"
        w="100%"
        as={Field}
        type="text"
        id="client_name"
        name="client_name"
        label="API Client Name"
        isRequired
      />
      <FormControl name="user_group_id" isRequired w="100%">
        <FormLabel htmlFor="user_group_id">Role</FormLabel>
        <SingleSelectInput
          id="user_group_id"
          isRequired
          onOptionSelected={onUserRoleOptionSelected}
          options={userGroups
            .filter((group) => group.name === "org_admin")
            .map((group) => ({
              key: group.id,
              label: group.label,
            }))}
          isDisabled={disableRole}
        ></SingleSelectInput>
      </FormControl>
    </Grid>
  );
};
