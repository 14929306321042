import { gql } from "@apollo/client";

export const PROFILE_FRAGMENT = gql`
  fragment ProfileFragment on person {
    id
    first_name
    middle_name
    last_name
    street_address
    street_address_line_2
    city
    state
    zip_code
    active
    user_id
    phone_number
    email_address
    sms_okay
    email_okay
    avatar
    preferred_language
  }
`;
export const GET_PROFILE_BY_USER_ID_AND_ORG_ID = gql`
  ${PROFILE_FRAGMENT}
  query GetProfileByUserIdAndOrgId(
    $user_id: String!
    $organizationId: uuid!
    $include_user: Boolean = false
  ) {
    person(
      where: {
        user_id: { _eq: $user_id }
        organization_id: { _eq: $organizationId }
      }
    ) {
      ...ProfileFragment
      user @include(if: $include_user) {
        name
      }
    }
  }
`;
