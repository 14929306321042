import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { FlowStatus } from "src/components/FormImport/state/constants";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useOrganization } from "src/hooks/useOrganization";
import * as breadcrumb from "src/services/breadcrumb";
import { DoneButton } from "./DoneButton";
import { ImportStatusContent } from "./ImportStatusContent";

export function FormImport() {
  const organization = useOrganization();

  const { importStatus } = useFormImportFlow();
  const { selectedNavFormTemplate } = useFormTemplates();

  const breadcrumbItems: breadcrumb.BreadcrumbType[] = [
    ...breadcrumb.form.getBreadcrumbsForList(
      organization,
      selectedNavFormTemplate
    ),
    { label: "Create responses", href: "" },
  ];

  let footer;
  if (
    importStatus === FlowStatus.SUCCESS ||
    importStatus === FlowStatus.FAILURE
  ) {
    footer = (
      <Flex
        bg="white"
        justifyContent="end"
        marginBlockEnd="calc(-1 * var(--chakra-space-10))"
        marginBlockStart="auto"
        marginInlineStart="calc(-1 * var(--chakra-space-10))"
        paddingX="10"
        paddingY="5"
        position="sticky"
        shadow="dark-lg"
        width="calc(100% + (var(--chakra-space-10) * 2))"
      >
        <DoneButton />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="0.5rem" minBlockSize="100%">
      <Breadcrumb items={breadcrumbItems} mb={4} />

      <HStack pb={8}>
        <Heading as="h1" size="lg">
          Create responses
        </Heading>
      </HStack>

      <HStack alignItems="start">
        <Box alignSelf="stretch" width={"100%"}>
          <ImportStatusContent />
        </Box>
      </HStack>

      {footer}
    </Flex>
  );
}
