import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as GQL from "src/types/graphql";
import { SectionFormType } from "./SectionFormDialog";

export const createNewSection = (values: SectionFormType): Draft.NewSection => {
  const base = {
    id: window.crypto.randomUUID(),
    type: values.type,
    title: values.title,
    description: values.description,
    key: values.key,
    isDraft: true,
    isNew: true,
    permissionLevel: values.permissionLevel,
    familyEditableStatuses:
      values.familyEditableStatuses?.map((status) => status.id) ?? [],
  };
  switch (values.type) {
    case GQL.form_template_section_type_enum.SchoolRankingSection:
      return {
        ...base,
        minSchools: values.minSchools,
        maxSchools: values.maxSchools,
        exploreUrl: values.exploreUrl,
        rankingEnabled: values.rankingEnabled,
        schoolRankingFormSectionId: window.crypto.randomUUID(),
      } as Draft.NewSchoolRanking;
    case GQL.form_template_section_type_enum.DisclaimerSection:
      return {
        ...base,
        disclaimerFormSectionId: window.crypto.randomUUID(),
        disclaimer: "",
      } as Draft.NewDisclaimer;
    default:
      return {
        ...base,
        questions: [],
      } as Draft.NewSection;
  }
};
