import { BoxProps, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { ParentFormsLayout } from "./ParentFormsLayout";
import { BackLinkProps } from "./ParentFormsLayoutOuter";

type Props = {
  heading: React.ReactNode;
  headers?: React.ReactNode;
  buttons: React.ReactNode;
  children: React.ReactNode;
  backLink?: BackLinkProps;
} & BoxProps;

export const FormLayout: React.FC<Props> = ({
  children,
  buttons,
  backLink,
  heading,
  headers,
  ...props
}: Props) => {
  return (
    <ParentFormsLayout
      heading={heading}
      backLink={backLink}
      headers={headers}
      footers={buttons}
      {...props}
    >
      <VStack width="100%" minHeight="100%" flexGrow="1">
        <Flex gap={5} direction="column" flexGrow="1" width="100%">
          {children}
        </Flex>
      </VStack>
    </ParentFormsLayout>
  );
};
