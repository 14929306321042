import { Flex, FlexProps } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";

type Props = {
  firstName?: { label?: string };
  middleName?: { label?: string };
  lastName?: { label?: string };
} & FlexProps;
export const NameForm: React.FC<Props> = ({
  firstName,
  middleName,
  lastName,
  ...props
}) => {
  return (
    <Flex direction="column" gap={4} {...props}>
      <InputControl
        inputProps={{ placeholder: "E.g.: Alex" }}
        name="first_name"
        id="first_name"
        label={firstName?.label ?? "First name"}
        isRequired={true}
        labelProps={{ requiredIndicator: <></> }}
      />
      <InputControl
        isRequired={false}
        inputProps={{ placeholder: "E.g.: Dan" }}
        name="middle_name"
        id="middle_name"
        label={`${middleName?.label ?? "Middle name"} (Optional)`}
      />
      <InputControl
        inputProps={{ placeholder: "E.g.: Doe" }}
        name="last_name"
        id="last_name"
        label={lastName?.label ?? "Last name"}
        isRequired={true}
        labelProps={{ requiredIndicator: <></> }}
      />
    </Flex>
  );
};
