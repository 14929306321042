import { useNavigate, useParams } from "react-router-dom";
import { StepProps } from "../components/Layout/FormStepLayout";
import * as Url from "../services/url";
import { useOrganization } from "./useOrganization";
import { useContext } from "react";
import { EditFormContext } from "src/scenes/parent/forms/state/provider";
import { SUBMIT_FORM } from "src/scenes/parent/forms/graphql/mutations";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { useWeglotToast } from "src/plugins/weglot";

export function useFormSteps({ currentStep, steps }: StepProps) {
  const navigate = useNavigate();
  const organization = useOrganization();
  const { formId = "" } = useParams();
  const { state } = useContext(EditFormContext);

  const toast = useWeglotToast();

  const [submitForm] = useRemoteDataMutation<
    GQL.SubmitForm,
    GQL.SubmitFormVariables
  >(SUBMIT_FORM);

  const handleSubmit = async () => {
    try {
      await submitForm({
        variables: {
          form_id: formId,
        },
      });

      organization.do((org) => {
        navigate(Url.Parent.index(org));
      });

      toast({
        title: "Hooray!",
        description: "Form successfully submitted.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error submitting form",
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
        isClosable: true,
      });
    }
  };

  let onPrevious: () => void;
  if (currentStep === 1) {
    onPrevious = () =>
      organization.do((org) => navigate(Url.Parent.index(org)));
  } else if (currentStep > steps.length) {
    onPrevious = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, steps.length))
      );
  } else {
    onPrevious = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, currentStep - 1))
      );
  }

  let onNext: () => void;
  // the nav state below is used in FormQuestion.tsx::scrollToQuestionAfterNavigate
  if (currentStep === steps.length) {
    // last step
    onNext = handleSubmit;
  } else if (currentStep > steps.length) {
    onNext = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, steps.length), {
          state: { questionId: state?.currentQuestionId },
        })
      );
  } else {
    onNext = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, currentStep + 1), {
          state: { questionId: state?.currentQuestionId },
        })
      );
  }

  return { onPrevious, onNext };
}
