import * as GQL from "src/types/graphql";

type FormSchoolRank = {
  form: { status: GQL.form_status_enum };
  offers: { status: GQL.offer_status_enum }[];
};

type FormSchool = {
  school: { id: uuid } | null;
};

function canDeclineOfferByStatus(formSchoolRanks: FormSchoolRank[]): boolean {
  return formSchoolRanks.every((rank) => {
    if (GQL.form_status_enum.Admissions !== rank.form.status) return false;

    return rank.offers?.[0]?.status === GQL.offer_status_enum.Offered;
  });
}

export function canDeclineOffers(
  formSchoolRanks: FormSchoolRank[],
  formSchools: FormSchool[]
): boolean {
  if (!formSchoolRanks || formSchoolRanks.length === 0) {
    return false;
  }

  if (formSchools.some((appSchool) => !appSchool.school)) {
    return false;
  }

  const byStatus = canDeclineOfferByStatus(formSchoolRanks);

  return byStatus;
}
