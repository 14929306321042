import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { RiArrowLeftSLine, RiCloseLine, RiSearchLine } from "react-icons/ri";
import * as RD from "src/types/remoteData";
import { DEFAULT_CONFIG } from "./constants";
import { SearchResult } from "./SearchResult";
import {
  Config,
  ConfigWithDefault,
  extractEventHandlers,
  Tag,
  TagsListEventHandler,
} from "./types";
import { WithRecentTagsList } from "./WithRecentTagsList";

export type Props = {
  config?: Config;
  searchKeyword?: string;
  tags: RD.RemoteData<unknown, Tag[]>;
  searchInputRef?: React.LegacyRef<HTMLInputElement>;
  onClose?: () => void;
  onEditTagSaving?: (tag: Tag, onSuccess?: () => void) => Promise<void>;
  isBulkAction?: boolean;
  onBackClick?: () => void;
  onToggleTagDescription?: (state: boolean) => void;
} & TagsListEventHandler;

export const TagsList: React.FC<Props> = (props) => {
  const {
    config,
    searchKeyword,
    tags,
    onSearch,
    onNewTag,
    searchInputRef,
    // onClose,
    onEditTagSaving,
    isBulkAction,
    onBackClick,
    onToggleTagDescription,
  } = props;
  const configWithDefault: ConfigWithDefault = {
    ...DEFAULT_CONFIG,
    ...config,
  };
  const eventHandlers = extractEventHandlers(props);
  const { title, allowNewTag } = configWithDefault;

  const newButtonClickHandler = React.useCallback(() => {
    if (onNewTag) {
      onNewTag(searchKeyword ?? null);
    }
  }, [onNewTag, searchKeyword]);

  return (
    <Stack spacing="0">
      <Stack position="sticky" top="0" zIndex="1" spacing="0" bg="white">
        <Stack padding="4" spacing="3">
          <Stack direction="row" justifyContent="space-between">
            <Flex alignItems="center" gap={2}>
              {isBulkAction && (
                <RiArrowLeftSLine
                  aria-label="Back to tag groups"
                  onClick={onBackClick}
                  cursor="pointer"
                />
              )}
              {title && (
                <Heading as="h3" size="md">
                  {title} tags
                </Heading>
              )}
            </Flex>
            {allowNewTag && (
              <Button
                variant="outline"
                size="xs"
                leftIcon={<AddIcon />}
                colorScheme="gray"
                onClick={newButtonClickHandler}
              >
                New tag
              </Button>
            )}
          </Stack>
          <InputGroup>
            <InputLeftElement>
              <Icon as={RiSearchLine} color="gray.400" />
            </InputLeftElement>
            <Input
              ref={searchInputRef}
              type="text"
              aria-label="Search tags"
              placeholder="Search tags"
              value={searchKeyword ?? ""}
              onChange={(event) => {
                if (onSearch) {
                  onSearch(event.target.value);
                }
              }}
            />
            {searchKeyword && (
              <InputRightElement>
                <IconButton
                  color="blackAlpha.600"
                  icon={<RiCloseLine size="1rem" />}
                  size="sm"
                  variant="ghost"
                  aria-label={"clear search"}
                  onClick={() => {
                    if (onSearch) {
                      onSearch("");
                    }
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Stack>
        <Divider />
      </Stack>
      <Stack margin="4" spacing="0" overflowY="auto">
        {searchKeyword ? (
          <SearchResult
            config={configWithDefault}
            searchResult={tags}
            searchKeyword={searchKeyword}
            eventHandlers={eventHandlers}
          />
        ) : (
          <WithRecentTagsList
            config={configWithDefault}
            searchKeyword={searchKeyword}
            tags={tags}
            eventHandlers={eventHandlers}
            onEditTagSaving={onEditTagSaving}
            onToggleTagDescription={onToggleTagDescription}
          />
        )}
      </Stack>
      {/* Removing this button until further product meeting */}
      {/* {isBulkAction && (
        <Stack>
          <Divider />
          <Flex justifyContent="end" px={4} pb={4} pt={2}>
            <Button
              onClick={onClose}
              variant="outline"
              colorScheme="gray"
              size="sm"
            >
              Update
            </Button>
          </Flex>
        </Stack>
      )} */}
    </Stack>
  );
};
