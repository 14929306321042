import { useCallback, useEffect, useState } from "react";
import useAccessToken from "src/hooks/useAccessToken";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import useUser from "src/hooks/useUser";
import { triggerDownload } from "src/services/dataTransfer";
import { Status } from "src/types/authData";

interface FormImportTemplateDownloadOptions {
  formTemplateId: string;
}

type FormImportTemplateDownload = {
  startDownload(): void;
  status: "stopped" | "started" | "success" | "failure";
};

export function useFormImportTemplateDownload(
  props: FormImportTemplateDownloadOptions
): FormImportTemplateDownload {
  const { formTemplateId } = props;

  const [status, setStatus] =
    useState<FormImportTemplateDownload["status"]>("stopped");

  const toast = useAvelaToast();
  const user = useUser();
  const accessToken = useAccessToken();

  const startDownload = useCallback(async () => {
    if (!process.env.REACT_APP_FORM_IMPORT_TEMPLATE_URL) {
      return;
    }

    const path = `${process.env.REACT_APP_FORM_IMPORT_TEMPLATE_URL}/${formTemplateId}`;

    if (status === "started") {
      // Limit downloads to one per enrollment period.
      return;
    }

    if (user.status !== Status.OK) {
      console.error(new Error("Cannot fetch without user"));
      return;
    }

    if (accessToken.status !== Status.OK) {
      console.error(new Error("Cannot fetch without access token"));
      return;
    }

    setStatus("started");

    const response = await fetch(path, {
      headers: {
        Authorization: `Bearer ${accessToken.data}`,
        "x-hasura-role": user.data.role,
      },
      method: "GET",
    });

    if (response.status === 200) {
      triggerDownload(
        await response.blob(),
        `form-data-template-${formTemplateId}.csv`
      );

      setStatus("success");
    } else {
      setStatus("failure");
    }
  }, [accessToken, status, user, formTemplateId]);

  useEffect(() => {
    if (status === "started") {
      toast({
        description: "Download will start shortly",
        id: "DownloadFormImportTemplateStarted",
        isClosable: false,
      });
    } else if (status === "failure") {
      toast({
        description:
          "Please try again later or report the problem to our support team.",
        id: "DownloadFormImportTemplateError",
        isClosable: true,
        status: "error",
        title: "Error downloading form data template",
      });
    }
  }, [status, toast]);

  return { startDownload, status };
}
