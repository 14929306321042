import { z } from "zod";

export const emptyApiCredential: ApiCredentialFormType = {
  client_name: "",
  client_description: "",
};

export const ApiCredentialFormSchema = z.object({
  client_name: z.string(),
  client_description: z.string(),
});

export const ApiCredentialValidator = {};

export type ApiCredentialFormType = z.infer<typeof ApiCredentialFormSchema>;
