import axios from "axios";
import React from "react";
import useAccessToken from "src/hooks/useAccessToken";
import { Status } from "src/types/authData";
import {
  CreateWebhookRequest,
  CreateWebhookRequestSchema,
  CreateWebhookResponse,
  CreateWebhookResponseSchema,
  GetWebhookByPkResponse,
  GetWebhookByPkResponseSchema,
  ListWebhooksResponse,
  ListWebhooksResponseSchema,
} from "./types";
import {
  SubscribeEventsRequest,
  SubscribeEventsResponse,
} from "./types/subscribeEvents";
import {
  UpdateWebhookRequest,
  UpdateWebhookRequestSchema,
  UpdateWebhookResponse,
  PatchWebhookRequestSchema,
  PatchWebhookRequest,
} from "./types/updateWebhook";

export class WehookManagementError extends Error {
  constructor(message: string, readonly cause?: any) {
    super(message);
    this.cause = cause;
  }
}

export const useWebhookManagementAPI = () => {
  const baseurl = process.env.REACT_APP_WEBHOOK_MANAGEMENT_URL;
  const accessToken = useAccessToken();
  const [bearerToken, setBearerToken] = React.useState<string>();

  React.useEffect(
    () => {
      if (accessToken.status === Status.OK)
        setBearerToken(`Bearer ${accessToken.data}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken.status === Status.OK && accessToken.data, accessToken.status]
  );

  /** GET webhooks - list webhooks for organizaton */
  const getWebhooks = React.useCallback(
    async (organizationId: string) => {
      if (!baseurl || !bearerToken) return;

      const response = await axios.get<ListWebhooksResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "form/json",
          },
        }
      );

      if (response.status === 404) {
        return [];
      }

      if (response.status !== 200) {
        throw new WehookManagementError("Failed to get webhooks", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return ListWebhooksResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  /** GET webhook by PK */
  const getWebhookByPk = React.useCallback(
    async (organizationId: string, pk: string) => {
      if (!baseurl || !bearerToken) return;

      const response = await axios.get<GetWebhookByPkResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks/${pk}`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "form/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new WehookManagementError(`Failed to get webhook by pk ${pk}`, {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return GetWebhookByPkResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  /** POST webhook - create webhook */
  const createWebhook = React.useCallback(
    async (organizationId: string, request: CreateWebhookRequest) => {
      if (!baseurl || !bearerToken) return;

      CreateWebhookRequestSchema.parse(request);

      const response = await axios.post<CreateWebhookResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new WehookManagementError("Failed to create webhooks", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return CreateWebhookResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  /** PUT webhook - update webhook */
  const updateWebhook = React.useCallback(
    async (
      organizationId: string,
      pk: string,
      request: UpdateWebhookRequest
    ) => {
      if (!baseurl || !bearerToken) return;

      UpdateWebhookRequestSchema.parse(request);

      const response = await axios.put<UpdateWebhookResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks/${pk}`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new WehookManagementError("Failed to update webhooks", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return CreateWebhookResponseSchema.parse(response.data);
    },
    [bearerToken, baseurl]
  );

  /** PATCH webhook - patch webhook */
  const patchWebhook = React.useCallback(
    async (
      organizationId: string,
      pk: string,
      request: PatchWebhookRequest
    ) => {
      if (!baseurl || !bearerToken) return;

      PatchWebhookRequestSchema.parse(request);

      const response = await axios.patch<UpdateWebhookResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks/${pk}`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 204) {
        throw new WehookManagementError("Failed to patch webhook", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }
    },
    [bearerToken, baseurl]
  );

  /** PUT webhook events - update webhook events */
  const subscribeEvents = React.useCallback(
    async (
      organizationId: string,
      pk: string,
      request: SubscribeEventsRequest
    ) => {
      if (!baseurl || !bearerToken) return;

      const response = await axios.put<SubscribeEventsResponse>(
        `${baseurl}/organizations/${organizationId}/webhooks/${pk}/events`,
        request,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new WehookManagementError("Failed to update webhook events", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return response.data;
    },
    [bearerToken, baseurl]
  );

  /** DELETE webhook */
  const deleteWebhook = React.useCallback(
    async (organizationId: string, pk: string) => {
      if (!baseurl || !bearerToken) return;

      const response = await axios.delete<string>(
        `${baseurl}/organizations/${organizationId}/webhooks/${pk}`,
        {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 204) {
        throw new WehookManagementError("Failed to delete webhooks", {
          cause: {
            status: response.status,
            statusText: response.statusText,
          },
        });
      }

      return response.data;
    },
    [bearerToken, baseurl]
  );

  return {
    getWebhooks,
    getWebhookByPk,
    createWebhook,
    updateWebhook,
    patchWebhook,
    deleteWebhook,
    subscribeEvents,
  };
};
