import { DocViewerState } from "./reducer";

export const transformImage = (
  state: DocViewerState,
  width: number,
  height: number
) => {
  const { rotation, zoomLevel } = state;
  const rw = zoomLevel * width;
  const rh = zoomLevel * height;

  switch (rotation) {
    case 0:
      return `rotate(${rotation}deg) translate(0, 0)`;
    case 90:
      return `rotate(${rotation}deg) translate(0, -${rh}px)`;
    case 180:
      return `rotate(${rotation}deg) translate(-${rw}px, -${rh}px)`;
    case 270:
      return `rotate(${rotation}deg) translate(-${rw}px, 0)`;
  }
  return "";
};
