import { CheckIcon } from "@chakra-ui/icons";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { SaveStatus as Status } from "src/services/formTemplate/question";
type Props = {
  status: Status;
  notSaving?: React.ReactNode;
};
export const SaveStatus: React.FC<Props> = ({ status, notSaving }) => {
  switch (status) {
    case "Saving":
      return (
        <Flex alignItems="center" gap={3} opacity="60%">
          <Spinner size="xs" />
          <Text>Saving</Text>
        </Flex>
      );

    case "Saved":
      return (
        <Flex alignItems="center" gap={3} opacity="60%">
          <CheckIcon boxSize={3} />
          <Text>Saved</Text>
        </Flex>
      );

    case "NotSaving":
      return <>{notSaving}</> ?? null;

    default:
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
  }
};
