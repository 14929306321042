import { gql } from "@apollo/client";

export const GET_GLOSSARY_LIST = gql`
  query GetGlossaryList {
    organization {
      id
      name
    }
    glossary(
      where: {
        _or: [
          { organization_id: { _is_null: false } }
          { enrollment_period_id: { _is_null: false } }
        ]
      }
    ) {
      organization_id
      enrollment_period {
        organization_id
      }
    }
  }
`;

export const GET_GLOSSARY_BY_ORGANIZATION = gql`
  query GetGlossaryByOrganization($organizationId: uuid!) {
    organization_by_pk(id: $organizationId) {
      name
    }
    glossary_by_organization(
      where: {
        _or: [
          { organization_id: { _eq: $organizationId } }
          { organization_id: { _is_null: true } }
        ]
      }
    ) {
      glossary {
        type
      }
      organization_id
      enrollment_period_id
      default_singular
      default_plural
      alias_singular
      alias_plural
    }
  }
`;
