import _ from "lodash";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { isNotNull } from "../../predicates";
import { sortByOrder } from "../sorter";
import { toQuestionWithBranching } from "./question";

export function fromGQL(
  value: GQL.FormTemplateSectionFragment
): AF.PreRankingSection<AF.WithId> {
  return {
    id: value.id,
    type: AF.PreRankingSectionType,
    title: value.title,
    key: value.key,
    description: value.description ?? "",
    permissionLevel: value.permission_level,
    familyEditableStatuses: value.family_editable_statuses,
    questions: _.sortBy(value.questions, sortByOrder)
      .map(toQuestionWithBranching)
      .filter(isNotNull),
    filters: AFF.parseFilters(value.filters),
  };
}
