import { useApolloClient } from "@apollo/client";
import React from "react";
import { SchoolFormId } from "src/scenes/orgAdmin/forms/types";
import { GET_TAG_NAME_FROM_ID } from "src/scenes/orgAdmin/tags/graphql/queries";
import { TagUpdate } from "src/scenes/orgAdmin/tags/useTags";
import { GET_WAITLIST_TAGS } from "src/scenes/orgAdmin/waitlists/graphql/queries";
import { findPriorityGroupForTags } from "src/services/findPriorityGroup";
import { isNotNull } from "src/services/predicates";
import * as GQL from "../types/graphql";
import { useFetchPriorityTemplates } from "./useWaitlistActions";

export type PriorityGroupUpdate = {
  where: GQL.waitlist_bool_exp;
  _set: { priority_group: number | null };
};

export const useGetPriorityGroupUpdates = () => {
  const getSinglePriorityGroupUpdate = useGetSinglePriorityGroupUpdate();
  return React.useCallback(
    async function (
      rows: SchoolFormId[],
      tagUpdate: TagUpdate
    ): Promise<PriorityGroupUpdate[]> {
      return (
        await Promise.all(
          rows.map(async (row) => {
            if (!row.schoolId) {
              return null;
            }
            return getSinglePriorityGroupUpdate(
              row.formId,
              row.schoolId,
              tagUpdate
            );
          })
        )
      ).filter(isNotNull);
    },
    [getSinglePriorityGroupUpdate]
  );
};

const useGetSinglePriorityGroupUpdate = () => {
  const getWaitlistTags = useGetWaitlistTags();
  const fetchPriorityTemplates = useFetchPriorityTemplates();

  return React.useCallback(
    async function (
      appId: uuid,
      schoolId: uuid,
      tagUpdate: TagUpdate
    ): Promise<PriorityGroupUpdate | null> {
      const waitlistData = await getWaitlistTags(appId, schoolId, tagUpdate);
      if (!waitlistData) {
        return null;
      }
      const { tagNames, gradeId } = waitlistData;

      const templates = await fetchPriorityTemplates([gradeId]);
      const priorityGroups =
        templates[0]?.priority_template?.config.priorityGroups;
      if (!priorityGroups) {
        return null;
      }

      const priorityGroupIdx = findPriorityGroupForTags(
        tagNames,
        priorityGroups
      );
      return {
        where: { form_id: { _eq: appId }, school_id: { _eq: schoolId } },
        _set: { priority_group: priorityGroupIdx },
      };
    },
    [fetchPriorityTemplates, getWaitlistTags]
  );
};

type WaitlistTags = {
  tagNames: string[];
  waitlistId: uuid;
  gradeId: uuid;
};

const useGetWaitlistTags = () => {
  const client = useApolloClient();
  return React.useCallback(
    async (
      appId: uuid,
      schoolId: uuid,
      tagUpdate: TagUpdate
    ): Promise<WaitlistTags | null> => {
      const result = await client.query<
        GQL.GetWaitlistTags,
        GQL.GetWaitlistTagsVariables
      >({
        query: GET_WAITLIST_TAGS,
        variables: {
          form_id: appId,
          school_id: schoolId,
        },
        fetchPolicy: "network-only",
      });

      if (
        !result.data ||
        result.data.form_school_rank.length !== 1 ||
        !result.data.form_school_rank[0]
      ) {
        return null;
      }

      // Return null if substatus is not "Waitlisted"
      if (
        result.data.form_school_rank[0].waitlists[0]?.status !==
        GQL.waitlist_status_enum.Waitlisted
      ) {
        return null;
      }

      // Compute tags with tag update
      const tagNames = result.data.form_school_rank[0].tags.map(
        (tag) => tag.enrollment_period_tag.name
      );
      const tagNameResult = await client.query<
        GQL.GetTagNameFromId,
        GQL.GetTagNameFromIdVariables
      >({
        query: GET_TAG_NAME_FROM_ID,
        variables: {
          tag_id: tagUpdate.tagId,
        },
      });
      const tagNameToUpdate =
        tagNameResult.data.enrollment_period_tag_by_pk?.name;
      if (!tagNameToUpdate) {
        throw new Error("Invalid tag id");
      }
      switch (tagUpdate.tagUpdateType) {
        case "apply":
          !tagNames.includes(tagNameToUpdate) && tagNames.push(tagNameToUpdate);
          break;
        case "remove":
          tagNames.splice(tagNames.indexOf(tagNameToUpdate), 1);
          break;
        default:
          throw new Error("Invalid tag update");
      }
      return {
        tagNames,
        waitlistId: result.data.form_school_rank[0].waitlists[0].id,
        gradeId: result.data.form_school_rank[0].waitlists[0].grade_id,
      };
    },
    [client]
  );
};
