import { Map as ImmutableMap } from "immutable";
import React from "react";
import { Selected } from "src/components/Tags/types";

type OptimisticUpdateAction =
  | { type: "Apply"; tagId: uuid }
  | { type: "Remove"; tagId: uuid }
  | { type: "Reset"; tagId: uuid };
export function optimisticUpdateReducer(
  state: OptimisticUpdateMap,
  action: OptimisticUpdateAction
) {
  switch (action.type) {
    case "Apply":
      return state.update(action.tagId, () => "All");
    case "Remove":
      return state.update(action.tagId, () => "None");
    case "Reset":
      return state.remove(action.tagId);
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

export type OptimisticUpdateMap = ImmutableMap<uuid, Selected>;
const initialState: OptimisticUpdateMap = ImmutableMap();
export function useOptimisticUpdate() {
  const [state, dispatch] = React.useReducer(
    optimisticUpdateReducer,
    initialState
  );

  const applyTagOptimistically = React.useCallback(
    (tagId: uuid) => {
      return dispatch({ type: "Apply", tagId });
    },
    [dispatch]
  );

  const removeTagOptimistically = React.useCallback(
    (tagId: uuid) => {
      return dispatch({ type: "Remove", tagId });
    },
    [dispatch]
  );

  const resetOptimisticUpdate = React.useCallback(
    (tagId: uuid) => {
      return dispatch({ type: "Reset", tagId });
    },
    [dispatch]
  );
  return {
    optimisticUpdateMap: state,
    applyTagOptimistically,
    removeTagOptimistically,
    resetOptimisticUpdate,
  };
}
