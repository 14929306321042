import { Box, Button, InputGroup, Text } from "@chakra-ui/react";
import { InputHTMLAttributes, useRef } from "react";
import { RiUploadCloud2Line } from "react-icons/ri";

export interface UploadFileInputProps {
  onUpload: (data: File) => void;
  buttonLabel?: string;
  label?: string;
  disabled?: boolean;
  accept?: InputHTMLAttributes<HTMLInputElement>["accept"];
}

export const UploadFileInput: React.FC<UploadFileInputProps> = ({
  onUpload,
  buttonLabel = "Upload file",
  label,
  disabled = false,
  accept = "image/png, image/jpg, image/jpeg, application/pdf, .heic, .heif",
  ...props
}) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const handleChangeUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.currentTarget?.files
      ? event.currentTarget.files[0]
      : undefined;

    if (file === undefined) return;

    onUpload(file);
    // clear the file to allow re-upload of same file
    event.target.value = "";
  };

  return (
    <Box my={2}>
      {label && <Text mb="10px">{label}</Text>}
      <InputGroup flexBasis="fit-content">
        <input
          type="file"
          accept={accept}
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleChangeUpload}
          disabled={disabled}
          {...props}
        ></input>
        <Button
          leftIcon={<RiUploadCloud2Line />}
          onClick={() => fileRef.current?.click()}
          variant="outline"
          colorScheme="gray"
          width="100%"
          isDisabled={disabled}
        >
          {buttonLabel}
        </Button>
      </InputGroup>
    </Box>
  );
};
