import {
  Button,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { SortType, UNSORTED } from "src/components/Table/SortButton";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { TeamList } from "src/scenes/orgAdmin/team/ListTeams";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { GET_MEMBERS, GET_TEAMS } from "./graphql/queries";
import { MemberList } from "./ListMembers";

export const Team = () => {
  const organization = useOrganization();
  const [membersPagination, setMembersPagination] = useState({
    limit: 25,
    offset: 0,
  });
  const [teamsPagination, setTeamsPagination] = useState({
    limit: 25,
    offset: 0,
  });
  const {
    remoteData: remoteDataMembers,
    refetch: refetchMembers,
    fetchMore: fetchMoreMembers,
  } = useRemoteDataQuery<GQL.GetMembers, GQL.GetMembersVariables>(GET_MEMBERS, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
      limit: membersPagination.limit,
      offset: membersPagination.offset,
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const {
    remoteData: remoteDataTeams,
    refetch: refetchTeams,
    fetchMore: fetchMoreTeams,
  } = useRemoteDataQuery<GQL.GetTeams, GQL.GetTeamsVariables>(GET_TEAMS, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
      orderBy: [
        {
          created_at: GQL.order_by.desc,
        },
      ],
      limit: teamsPagination.limit,
      offset: teamsPagination.offset,
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onFetchMoreMembers = (limit: number, offset: number) => {
    fetchMoreMembers({
      variables: {
        limit: limit,
        offset: offset,
      },
      updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
    });

    setMembersPagination({ limit, offset });
  };

  const onFetchMoreTeams = (limit: number, offset: number) => {
    fetchMoreTeams({
      variables: {
        limit: limit,
        offset: offset,
      },
      updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
    });

    setTeamsPagination({ limit, offset });
  };

  const handleMembersSort = useCallback(
    (columnName: string, sortType: SortType) => {
      const sort = sortType === UNSORTED ? null : (sortType as GQL.order_by);

      if (columnName === "user_group") {
        refetchMembers({ orderBy: [{ user: { user_group: { name: sort } } }] });
      } else {
        refetchMembers({ orderBy: [{ [columnName]: sort }] });
      }
    },
    [refetchMembers]
  );

  const handleTeamsSort = useCallback(
    (columnName: string, sortType: SortType) => {
      const sort = sortType === UNSORTED ? null : (sortType as GQL.order_by);

      refetchTeams({ orderBy: [{ [columnName]: sort }] });
    },
    [refetchTeams]
  );

  return (
    <Flex direction="column" rowGap={6}>
      <Breadcrumb items={breadcrumb.team.getBreadcrumbsForList(organization)} />
      <HStack justifyContent="space-between">
        <Heading as="h1">Teams & members</Heading>

        <HStack justifyContent="flex-end">
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            <WithUserPermissions permissions={["user:create"]}>
              <Button
                colorScheme="primary"
                variant="ghost"
                as={NavLink}
                to={organization
                  .map((org) => Url.OrgAdmin.ApiCredentials.new(org))
                  .withDefault("#")}
                size="xl"
              >
                Create API credential
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ORG_ADMIN, HasuraRole.ADMIN]}
          >
            <WithUserPermissions permissions={["team:create"]}>
              <Button
                colorScheme="primary"
                variant="outline"
                as={NavLink}
                to={organization
                  .map((org) => Url.OrgAdmin.Team.newTeam(org))
                  .withDefault("#")}
                size="xl"
              >
                Create team
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ORG_ADMIN, HasuraRole.ADMIN]}
          >
            <WithUserPermissions permissions={["user:create"]}>
              <Button
                colorScheme="primary"
                as={NavLink}
                to={organization
                  .map((org) => Url.OrgAdmin.Team.newMember(org))
                  .withDefault("#")}
                size="xl"
              >
                Create team member
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
        </HStack>
      </HStack>

      <Tabs defaultIndex={0} isLazy>
        <TabList>
          <Tab key="members" value="members">
            Members
          </Tab>
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            <Tab key="teams" value="teams">
              Teams
            </Tab>
          </WithRequiredHasuraRoles>
        </TabList>
        <TabPanels>
          <TabPanel key="members">
            <GQLRemoteDataView remoteData={remoteDataMembers}>
              {(data) => (
                <MemberList
                  members={data.person}
                  limit={membersPagination.limit}
                  offset={membersPagination.offset}
                  count={data.person_aggregate.totals?.total ?? 0}
                  onFetchMore={onFetchMoreMembers}
                  onSort={handleMembersSort}
                />
              )}
            </GQLRemoteDataView>
          </TabPanel>
          <TabPanel key="teams">
            <GQLRemoteDataView remoteData={remoteDataTeams}>
              {(data) => (
                <TeamList
                  teams={data.team}
                  limit={teamsPagination.limit}
                  offset={teamsPagination.offset}
                  count={data.team_aggregate.totals?.total ?? 0}
                  refetch={refetchTeams}
                  onFetchMore={onFetchMoreTeams}
                  onSort={handleTeamsSort}
                />
              )}
            </GQLRemoteDataView>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
