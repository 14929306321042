import { ReactNode } from "react";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";

type Props = {
  children: ReactNode | ReactNode[];
  roles: HasuraRole[];
};

export const WithRequiredHasuraRoles: React.FC<Props> = ({
  children,
  roles,
}: Props) => {
  const hasRequiredRoles = useRequiredHasuraRoles(roles);
  return hasRequiredRoles ? <>{children}</> : null;
};
