import { Box, Spinner, Text, VStack } from "@chakra-ui/react";

export function ImportingFile() {
  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <VStack spacing="16px">
        <Spinner color="primary.500" label="" size="xl" />

        <Text color="gray.700" fontSize="xl">
          Importing file
        </Text>
      </VStack>
    </Box>
  );
}
