import { Button, IconButton } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Heading, HStack, VStack } from "@chakra-ui/layout";
import { Flex, Spacer } from "@chakra-ui/react";
import _ from "lodash";
import { useMemo, useState } from "react";
import { RiAddCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import * as Url from "src/services/url";
import { GradeFormType, GradesConfigType, validate } from "./types";

interface GradesConfigFormProps {
  initialGradesConfig?: GradesConfigType;
  onSubmit: (values: GradesConfigType) => void;
  submitting: boolean;
}

export const GradesConfigForm: React.FC<GradesConfigFormProps> = ({
  initialGradesConfig,
  onSubmit,
  submitting,
}) => {
  const [organizationId, setOrganizationId] = useState<string>(
    initialGradesConfig?.organizationId ?? ""
  );
  const [grades, setGrades] = useState<GradeFormType[]>(
    initialGradesConfig?.grades.map((grade) => ({
      id: grade.id ?? undefined,
      label: grade.label ?? "",
      order: grade.order?.toString() ?? "",
      value: grade.value ?? "",
    })) ?? [{ label: "", order: "", value: "" }]
  );
  const gradesConfig: GradesConfigType = useMemo(
    () => ({
      organizationId,
      grades: grades.map((grade) => ({
        ...grade,
        order: parseInt(grade.order),
      })),
    }),
    [grades, organizationId]
  );
  const hasChanges = useMemo(
    () =>
      !(initialGradesConfig && _.isMatch(initialGradesConfig, gradesConfig)),
    [gradesConfig, initialGradesConfig]
  );

  return (
    <Flex direction="column" minHeight="100%">
      <VStack align="left" spacing={8}>
        <Heading>
          {initialGradesConfig ? "Edit grades" : "Create grades"}
        </Heading>
        <FormControl>
          <FormLabel htmlFor="organizationId">Organization ID</FormLabel>
          <Input
            type="text"
            id="organizationId"
            name="organizationId"
            value={organizationId}
            onChange={(e) => setOrganizationId(e.target.value)}
            isDisabled={initialGradesConfig !== undefined}
            placeholder="E.g.: 8fb9823c-10c6-47bd-9fed-893898c413ee"
          />
        </FormControl>

        <Heading as="h2" size="md">
          Grades configuration
        </Heading>
        <VStack gap={4}>
          {grades.map((grade, index) => (
            <HStack width="100%" align="end" key={index} gap={2}>
              <FormControl>
                <FormLabel htmlFor={`grades[${index}].label`}>
                  Grade label
                </FormLabel>
                <Input
                  type="text"
                  id={`grades[${index}].label`}
                  name={`gradess[${index}].label`}
                  value={grade.label}
                  onChange={(e) => {
                    const newGrades = [...grades];
                    newGrades.splice(index, 1, {
                      id: grade.id,
                      label: e.target.value,
                      order: grade.order,
                      value: grade.value,
                    });
                    setGrades(newGrades);
                  }}
                  placeholder="E.g.: First grade"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`grades[${index}].order`}>Order</FormLabel>
                <Input
                  type="number"
                  id={`grades[${index}].order`}
                  name={`grades[${index}].order`}
                  value={grade.order}
                  onChange={(e) => {
                    const newGrades = [...grades];
                    newGrades.splice(index, 1, {
                      id: grade.id,
                      label: grade.label,
                      order: e.target.value,
                      value: grade.value,
                    });
                    setGrades(newGrades);
                  }}
                  placeholder="E.g.: 1"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`grades[${index}].value`}>
                  Value key (optional)
                </FormLabel>
                <Input
                  type="string"
                  id={`grades[${index}].value`}
                  name={`grades[${index}].value`}
                  value={grade.value}
                  onChange={(e) => {
                    const newGrades = [...grades];
                    newGrades.splice(index, 1, {
                      id: grade.id,
                      label: grade.label,
                      order: grade.order,
                      value: e.target.value,
                    });
                    setGrades(newGrades);
                  }}
                  placeholder="E.g.: refrence-value"
                />
              </FormControl>
              <IconButton
                aria-label="Remove program"
                icon={<RiDeleteBin6Line />}
                onClick={() => {
                  const newGrades = [...grades];
                  newGrades.splice(index, 1);
                  setGrades(newGrades);
                }}
                variant="outline"
                isDisabled={grade.id !== undefined}
              />
            </HStack>
          ))}
          <Button
            aria-label="Add program"
            leftIcon={<RiAddCircleLine />}
            onClick={() => {
              setGrades([...grades, { label: "", order: "", value: "" }]);
            }}
          >
            Add grade
          </Button>
        </VStack>
      </VStack>
      <Spacer minH={8} />
      <AdminFormButtons justifyContent="space-between">
        <Button
          as={NavLink}
          to={Url.Admin.GradesConfig.index()}
          variant="outline"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          marginLeft={4}
          onClick={() => onSubmit(gradesConfig)}
          isLoading={submitting}
          isDisabled={!(hasChanges && validate(gradesConfig))}
        >
          Submit
        </Button>
      </AdminFormButtons>
    </Flex>
  );
};
