import { Textarea } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { ChangeEvent, useCallback } from "react";
import type { FormType } from "src/components/Form/QuestionForm/formik";
import { FormInput, FormInputProps } from "src/components/Inputs/FormInput";

interface QuestionTitleProps extends FormInputProps {
  questionId: uuid;
}

export function QuestionTitle(props: QuestionTitleProps) {
  const { questionId, ...formInputProps } = props;

  const form = useFormikContext<FormType>();
  const error = form.touched.questionTitles?.[questionId]
    ? form.errors.questionTitles?.[questionId]
    : undefined;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      form.setTouched({
        ...form.touched,
        questionTitles: { ...form.touched.questionTitles, [questionId]: true },
      });
      form.setValues((values) => ({
        ...values,
        questionTitles: {
          ...values.questionTitles,
          [questionId]: event.target.value,
        },
      }));
    },
    [form, questionId]
  );

  return (
    <FormInput
      {...formInputProps}
      error={error}
      id={questionId}
      label="Question title"
      onChange={handleChange}
      value={form.values.questionTitles[questionId] ?? ""}
      as={Textarea}
      resize="vertical"
      minHeight="2em"
    />
  );
}
