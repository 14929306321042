import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "src/images/announcement-info.svg";
import * as GQL from "src/types/graphql";
import Markdown from "markdown-to-jsx";

export interface AnnouncementBannerProps {
  announcement: GQL.FetchAnnouncements_announcement;
}

export const AnnouncementBanner = ({
  announcement,
}: AnnouncementBannerProps) => {
  return (
    <Flex
      gap={4}
      bg="blue.100"
      borderRadius="md"
      boxShadow="md"
      p={4}
      direction="column"
    >
      <Flex gap={4}>
        <Box h="100%">
          {announcement.type === GQL.announcement_type_enum.INFO && (
            <InfoIcon width="1.5em" height="1.5em" />
          )}
        </Box>
        <Flex direction="column" gap={4}>
          <Text color="gray.700" fontSize="md" fontWeight="700" width="100%">
            {announcement.title}
          </Text>
          <Text color="gray.700" fontSize="md" width="100%">
            <Markdown>{announcement.description}</Markdown>
          </Text>
        </Flex>
      </Flex>

      {announcement.link_url && announcement.link_text && (
        <Flex w="100%">
          <Button
            as={Link}
            variant="solid"
            href={announcement.link_url}
            w="100%"
          >
            {announcement.link_text}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
