import { Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { BreadcrumbType } from "src/services/breadcrumb";
import { QuestionTypes } from "src/services/url/Admin";
import {
  custom_question_type_field_insert_input,
  custom_question_type_insert_input,
  form_question_category_enum,
} from "src/types/graphql";
import { FormikJsonEditor } from "../components/FormikJsonEditor";
import { useCreateQuestionTypeMutation } from "./api";
import createQuestionTypeSchema from "./createQuestionTypeSchema.json";
import { QuestionTypeField } from "./types";

export const CreateQuestionType: FunctionComponent<{}> = () => {
  const createQuestionType = useCreateQuestionTypeMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const mutationPayload = formatJsonPayload(values.jsonPayload);
        const result = await createQuestionType({
          variables: { question_type: mutationPayload },
        });
        const id = result.data?.insert_custom_question_type_one?.id;
        if (id === undefined) throw new Error("Create question type failed");
        toast({
          id: "NewQuestionType",
          title: "Question type created",
          isClosable: true,
          status: "success",
        });
        navigate(QuestionTypes.view(id));
      } catch (error: any) {
        console.error(error);
        toast({
          id: "NewQuestionType",
          title: "Error creating question type",
          description: error.message,
          isClosable: true,
          status: "error",
        });
      }
    },
    [createQuestionType, navigate, toast]
  );

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      <Flex as={Form} direction="column" gap={4} height="100%">
        <Breadcrumb items={getEditQuestionTypeBreadcrumb()} />
        <FormikJsonEditor
          title="Create question type"
          fieldName="jsonPayload"
          schema={createQuestionTypeSchema}
          filename="questionType.json"
          height="100%"
        />
        <AdminFormButtons>
          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => {
              navigate(QuestionTypes.index());
            }}
          >
            Back
          </Button>
          <Spacer />
          <Button type="submit">Create</Button>
        </AdminFormButtons>
      </Flex>
    </Formik>
  );
};

function getEditQuestionTypeBreadcrumb(): BreadcrumbType[] {
  return [
    {
      label: "Question types",
      href: QuestionTypes.index(),
    },
    {
      label: "New question type",
      href: QuestionTypes.new(),
    },
  ];
}

function formatJsonPayload(
  jsonPayload: string
): custom_question_type_insert_input {
  const { name, organization_id, key, fields } = JSON.parse(jsonPayload);

  const customQuestionTypeFields: custom_question_type_field_insert_input[] =
    fields.map((field: QuestionTypeField, fieldIdx: number) => {
      if (
        field.type === "SingleSelect" &&
        (!field.options || field.options.length === 0)
      ) {
        throw new Error(
          "Options are required if question type is SingleSelect"
        );
      }
      return {
        question: {
          data: {
            question: field.question,
            type: field.type,
            key: field.key,
            is_required: field.is_required,
            order: fieldIdx,
            form_question: {
              data: {
                // category is irrelevant
                category: form_question_category_enum.GeneralQuestion,
                filters: null,
                form_question_options: {
                  data:
                    field.options?.map((option, optionIdx) => ({
                      order: optionIdx,
                      ...option,
                    })) ?? [],
                },
                requires_verification: false,
              },
            },
          },
        },
      };
    });

  return {
    name,
    organization_id,
    key,
    custom_question_type_fields: { data: customQuestionTypeFields },
  };
}
