import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Table } from "src/components/Table/Table";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { HistoryEvent, resolveTransaction } from "src/services/auditLog";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { GET_FORM_HISTORY } from "../../graphql/queries";
import { HistoryLogEntry } from "./HistoryLogEntry";

interface HistoryLogProps {
  formId: uuid;
}

export const HistoryLog = ({ formId }: HistoryLogProps) => {
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormHistory,
    GQL.GetFormHistoryVariables
  >(GET_FORM_HISTORY, {
    variables: {
      form_id: formId,
      organization_id: organization.map((org) => org.id).toNullable() ?? "",
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
  });

  const columns: ColumnDef<HistoryEvent<string>>[] = useMemo(
    () => [
      {
        id: "entry",
        cell: (props) => <HistoryLogEntry event={props.row.original} />,
      },
    ],
    []
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => (
        <Table<HistoryEvent<string>>
          data={data.audit_form_transaction
            .flatMap(
              (transaction) =>
                resolveTransaction(
                  transaction,
                  formId,
                  // TODO: Remove non-null assertion.
                  data.form_by_pk!.form_template,
                  data.school,
                  data.grade,
                  data.enrollment_period_tag
                ).reverse() // Order from oldest to newest.
            )
            .filter(isNotNull)}
          columns={columns}
          hideHeader={true}
        />
      )}
    </GQLRemoteDataView>
  );
};
