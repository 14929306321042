import { Box, Flex, Text } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { FilterTypes, SearchAndFilterTypes } from "src/constants";
import { TagOption } from "../graphql/utils";
import { verifyFilterInclusion } from "./utils";
import * as GQL from "src/types/graphql";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  GET_FORM_TAGS_FOR_FILTERS,
  GET_TAG_GROUPS_FOR_FILTERS,
} from "../graphql/queries";
import { useMemo } from "react";
import { handleFilterChange } from "src/services/filter";

interface FormFiltersTagsProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
}

export const FormFiltersTags: React.FC<FormFiltersTagsProps> = ({
  enrollmentPeriodId,
  formTemplateId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { remoteData: formTagsRemoteData } = useRemoteDataQuery<
    GQL.GetFormTagsForFilters,
    GQL.GetFormTagsForFiltersVariables
  >(GET_FORM_TAGS_FOR_FILTERS, {
    skip: !formTemplateId,
    variables: {
      form_template_id: formTemplateId,
    },
  });

  const formTags = useMemo(() => {
    if (
      !formTagsRemoteData.hasData() ||
      !formTagsRemoteData.data.enrollment_period_tag.length
    )
      return [];

    return formTagsRemoteData.data.enrollment_period_tag.map((tag) => ({
      id: tag.id,
      value: tag.id,
      label: tag.name,
    }));
  }, [formTagsRemoteData]);

  const selectedFormTags = useMemo(() => {
    if (!formTags.length) return [];

    return formTags.filter((tag) =>
      searchParams.get(SearchAndFilterTypes.Tag)?.includes(tag.id ?? "")
    );
  }, [formTags, searchParams]);

  const { remoteData: tagGroupsRemoteData } = useRemoteDataQuery<
    GQL.GetTagGroupsForFilters,
    GQL.GetTagGroupsForFiltersVariables
  >(GET_TAG_GROUPS_FOR_FILTERS, {
    skip: !enrollmentPeriodId,
    variables: {
      enrollment_period_id: enrollmentPeriodId,
    },
  });

  const tagGroupsForFilters = useMemo(() => {
    if (
      !tagGroupsRemoteData.hasData() ||
      !tagGroupsRemoteData.data.tag_group.length
    )
      return [];

    return tagGroupsRemoteData.data.tag_group.map((tagGroup) => {
      const tags = tagGroup.tags.map((tag) => ({
        id: tag.id,
        value: tag.id,
        label: tag.name,
      }));

      const selectedTags = tags.filter((tag) =>
        searchParams.get(SearchAndFilterTypes.Tag)?.includes(tag.id ?? "")
      );

      return {
        id: tagGroup.id,
        name: tagGroup.name,
        tags,
        selectedTags,
      };
    });
  }, [tagGroupsRemoteData, searchParams]);

  // tag filter handler
  const handleTagFilterChange = (newTags: TagOption[]) => {
    const newTagFilters = handleFilterChange(
      searchParams,
      SearchAndFilterTypes.Tag,
      newTags[newTags.length - 1]?.id ?? ""
    );

    setSearchParams(newTagFilters);
  };

  const renderDropdownItem = (item: TagOption) => (
    <Box>
      <span>{item.label}</span>
    </Box>
  );

  return (
    <>
      {verifyFilterInclusion(searchParams, FilterTypes.Tags) && (
        <Flex direction="column" gap={4}>
          <Flex direction="column">
            <Text variant="filterLabel">Form tags</Text>
            <Box width="100%">
              <MultiSelectInput
                size="md"
                initialValues={selectedFormTags ?? []}
                options={formTags ?? []}
                onChange={handleTagFilterChange}
                renderDropdownItem={renderDropdownItem}
                placeholder="Type a tag"
              />
            </Box>
          </Flex>
          {tagGroupsForFilters.map((tagGroup) => (
            <Flex key={tagGroup.id} direction="column">
              <Text variant="filterLabel">{tagGroup.name} tags</Text>
              <Box width="100%">
                <MultiSelectInput
                  size="md"
                  initialValues={tagGroup.selectedTags ?? []}
                  options={tagGroup.tags ?? []}
                  onChange={handleTagFilterChange}
                  renderDropdownItem={renderDropdownItem}
                  placeholder="Type a tag"
                />
              </Box>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
};
