import { Button, Card, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { FunctionComponent, useMemo } from "react";
import { CustomQuestionAnswer } from "src/components/Form/QuestionForm/formik";
import { Question } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import { formatCustomQuestionTypeFieldAsProperties } from "../helpers";

type CustomQuestionTypeFormProps = {
  fieldQuestions: ClonedQuestion<WithId>[];
  isEditingAnswer: boolean;
};

// special cased logic for SAISD based on the CQT field
// These fields cannot be edited by parents after creation
const IDENTIFYING_CONTACT_FIELDS = ["first_name", "last_name"];

/**
 * This is a pseudo nested HTML form. Actual nested form elements are disallowed.
 */
export const AnswerBankEntryFormFields: FunctionComponent<
  CustomQuestionTypeFormProps
> = (props) => {
  const { fieldQuestions, isEditingAnswer } = props;
  const { customQuestion, formId } = useCustomQuestionContext();
  const { errors, touched } = useFormikContext<CustomQuestionAnswer>();

  const customQuestionTypeFieldProps = useMemo(() => {
    return formatCustomQuestionTypeFieldAsProperties({
      applicant: { first_name: null, birth_date: null },
      fieldQuestions,
      formId,
    });
  }, [fieldQuestions, formId]);

  const showValidationErrors = !isEmpty(errors) && !isEmpty(touched);

  return (
    <FormControl isInvalid={showValidationErrors}>
      <Card
        padding={4}
        gap={4}
        border={showValidationErrors ? "2px solid red" : "2px solid white"}
      >
        {customQuestionTypeFieldProps.map((fieldProps) => {
          return (
            <Question
              key={fieldProps.id}
              {...fieldProps}
              isDisabled={
                IDENTIFYING_CONTACT_FIELDS.includes(fieldProps.key ?? "") &&
                isEditingAnswer
              }
            />
          );
        })}
        <Button type="submit" size="sm" colorScheme="gray" width="100%">
          Save {customQuestion.question.toLocaleLowerCase()}
        </Button>
      </Card>
      {showValidationErrors && (
        <FormErrorMessage>This information is required.</FormErrorMessage>
      )}
    </FormControl>
  );
};
