import { Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useField } from "formik";
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { STATUS_ACTIVE, STATUS_INACTIVE } from "src/constants";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import * as GQL from "src/types/graphql";

type Props = {
  templateType: GQL.message_template_type_enum;
  lotteryAndOffersEnabled: boolean;
  isOfferRelatedMessage?: boolean;
};

/**
 * Variant of src/components/Inputs/SwitchInput.tsx
 */
export const StatusSwitch: FunctionComponent<Props> = (props) => {
  const { templateType, lotteryAndOffersEnabled, isOfferRelatedMessage } =
    props;
  // nested field
  const [{ value }, , { setValue }] = useField(`${templateType}.enabled`);

  const isSwitchDisabled = useMemo(() => {
    if (isOfferRelatedMessage) {
      return !lotteryAndOffersEnabled;
    }
    return false;
  }, [lotteryAndOffersEnabled, isOfferRelatedMessage]);

  const { confirm, confirmationDialog, setHeader, setBody } =
    useConfirmationDialog({
      header: getDialogHeader(value),
      body: getDialogBody(value),
      cancelButton: {
        label: "Cancel",
        colorScheme: "primary"
      },
      confirmButton: {
        label: getDialogConfirmButtonText(value)
      }
    });

  useEffect(() => {
    setHeader(getDialogHeader(value));
    setBody(getDialogBody(value));
  }, [setBody, setHeader, value]);

  const onToggleSwitch = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const confirmed = await confirm();
      if (confirmed) {
        const newValue = !event.target.checked;
        setValue(newValue);
      }
    },
    [confirm, setValue]
  );

  return (
    <FormLabel margin="0">
      <Flex alignItems="center" gap={2}>
        <Switch
          isChecked={value}
          onChange={onToggleSwitch}
          isDisabled={isSwitchDisabled}
        />
        <Text>{value ? STATUS_ACTIVE : STATUS_INACTIVE}</Text>
        {confirmationDialog}
      </Flex>
    </FormLabel>
  );
};

function getDialogHeader(value: boolean) {
  return `${value ? "Deactivate" : "Activate"} automatic message?`;
}

function getDialogBody(value: boolean) {
  return value
    ? "This will prevent parents/guardians from receiving any automatic messages for this trigger after this is published."
    : "This will allow parents/guardians to receive all automatic messages for this trigger after publishing, it will not be applied retroactively.";
}

function getDialogConfirmButtonText(value: boolean) {
  return `${value ? "Deactivate" : "Activate"} automatic message`;
}
