import { Alert } from "@chakra-ui/react";
import { useEffect } from "react";

type Props = { message?: string; error?: Error };
export const GenericError: React.FC<Props> = ({ message, error }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);
  return (
    <Alert status="error">{message ?? "Oops! Something went wrong!"}</Alert>
  ); // TODO better default generic error
};
