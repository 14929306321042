import { RiCheckFill, RiCloseLine, RiTimeLine } from "react-icons/ri";
import * as GQL from "src/types/graphql";
import { StatusTag } from "./StatusTag";

const tagMap = {
  [GQL.verification_status_enum.Pending]: {
    icon: RiTimeLine,
    colorScheme: "orange",
  },
  [GQL.verification_status_enum.Rejected]: {
    icon: RiCloseLine,
    colorScheme: "red",
  },
  [GQL.verification_status_enum.Verified]: {
    icon: RiCheckFill,
    colorScheme: "green",
  },
};

type Props = {
  label: string;
  status: GQL.verification_status_enum;
  showText?: "status" | "label";
  variant?: "long" | "short";
  colorScheme?: string;
};

export const VerificationTag: React.FC<Props> = ({
  label,
  status,
  showText = "label",
  variant = "short",
  colorScheme,
}) => {
  const labelText = showText === "label" ? label : status;
  const statusTag = tagMap[status];

  const verification = {
    label: labelText,
    longLabel: labelText,
    icon: statusTag.icon,
    colorScheme: colorScheme ?? statusTag.colorScheme,
  };

  return <StatusTag status={verification} variant={variant} />;
};
