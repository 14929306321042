import { Stack, UnorderedList } from "@chakra-ui/react";
import React from "react";
import * as List from "src/services/list";
import * as RD from "src/types/remoteData";
import { GenericError } from "../Feedback/GenericError";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { NoTagsFound } from "./NoTagsFound";
import { TagItem } from "./TagItem";
import { TagsLoading } from "./TagsLoading";
import { ConfigWithDefault, Tag, TagsListEventHandler } from "./types";

type SearchResultProps = {
  searchResult: RD.RemoteData<unknown, Tag[]>;
  searchKeyword: string | undefined;
  config: ConfigWithDefault;
  eventHandlers: TagsListEventHandler;
};
export const SearchResult: React.FC<SearchResultProps> = (props) => {
  const { searchResult, searchKeyword, config, eventHandlers } = props;
  const sortedSearchResult = React.useMemo(
    () =>
      searchResult.map((data) =>
        List.orderByIgnoreCase(data, (tag) => tag.name)
      ),
    [searchResult]
  );
  return (
    <RemoteDataView
      config={{ showDataWhileReloading: true }}
      reloading={null}
      remoteData={sortedSearchResult}
      loading={<TagsLoading />}
      error={(error: unknown) => {
        return <GenericError />;
      }}
    >
      {(tags) => {
        return tags.length > 0 ? (
          <Stack>
            <UnorderedList marginInlineStart="0" aria-label="Search Result">
              {tags.map((tag) => (
                <TagItem
                  config={config}
                  key={tag.id}
                  tag={tag}
                  searchKeyword={searchKeyword}
                  {...eventHandlers}
                />
              ))}
            </UnorderedList>
          </Stack>
        ) : (
          <NoTagsFound
            searchKeyword={searchKeyword}
            config={config}
            eventHandlers={eventHandlers}
          />
        );
      }}
    </RemoteDataView>
  );
};
