import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as Url from "src/services/url";
import { z } from "zod";
import { AccessDenied } from "../Feedback/AccessDenied";
import { ApolloError } from "../Feedback/ApolloError";
import { Loading } from "../Feedback/Loading";
import { OrganizationError } from "../Feedback/OrganizationError";

const NameSchema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
});

export function RequireUserProfile() {
  const userProfile = useUserProfile();
  const organization = useOrganization();
  const navigate = useNavigate();

  const shouldNavigate =
    (userProfile.hasError() &&
      userProfile.error.kind === "ProfileNotFoundError") ||
    (userProfile.hasData() &&
      NameSchema.safeParse(userProfile.data).success === false);

  React.useEffect(() => {
    if (organization.hasData()) {
      if (shouldNavigate) {
        navigate(Url.Parent.Profile.new(organization.data));
      }
    }
  }, [navigate, organization, shouldNavigate, userProfile]);

  if (
    userProfile.isLoading() ||
    userProfile.isNotAsked() ||
    organization.isLoading() ||
    organization.isNotAsked() ||
    shouldNavigate
  ) {
    return <Loading />;
  }

  if (organization.hasError()) {
    return <OrganizationError error={organization.error} />;
  }

  if (userProfile.hasError()) {
    switch (userProfile.error.kind) {
      case "ServerError":
        return <ApolloError error={userProfile.error.error} />;
      case "UnauthenticatedError":
        return <AccessDenied />;
      case "ProfileNotFoundError":
        return null;
    }
  }

  return <Outlet />;
}
