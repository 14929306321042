import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { RiLightbulbLine } from "react-icons/ri";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import * as RD from "src/types/remoteData";
import { HintContent } from "./FormTemplateHint";
import { ModalSkeleton } from "./ModalSkeleton";
import { HintImage } from "./HintImage";

type Props = {
  hint: RD.RemoteData<unknown, HintContent> | undefined;
  onClose: () => void;
};
export const HintModal: React.FC<Props> = ({ hint, onClose }) => {
  return (
    <Modal isOpen={hint !== undefined} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {hint && (
          <RemoteDataView
            remoteData={hint}
            error={() => <GenericError />}
            loading={<ModalSkeleton />}
          >
            {(data) => {
              return (
                <>
                  <ModalHeader>{data.title}</ModalHeader>
                  <ModalBody>
                    <Flex direction="column" gap="4">
                      {data.imageType && <HintImage image={data.imageType} />}
                      <Tag
                        bg="purple.500"
                        color="white"
                        alignSelf="flex-start"
                        size="sm"
                      >
                        <TagLeftIcon as={RiLightbulbLine} />
                        <TagLabel>Tip</TagLabel>
                      </Tag>
                      <Text whiteSpace="pre-wrap">{data.body}</Text>
                    </Flex>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                      Done
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </RemoteDataView>
        )}
      </ModalContent>
    </Modal>
  );
};
