import { gql } from "@apollo/client";

export const GET_PROGRAM_GROUPS = gql`
  query GetProgramGroups {
    program_group(order_by: { organization_id: asc }) {
      id
      name
      organization_id
      programs {
        id
        label
        order
      }
    }
  }
`;

export const GET_PROGRAM_GROUP_BY_PK = gql`
  query GetProgramGroupByPk($program_group_id: uuid!) {
    program_group_by_pk(id: $program_group_id) {
      id
      name
      organization_id
      programs {
        id
        label
        order
      }
    }
  }
`;
