import { Flex, Tag, TagLeftIcon } from "@chakra-ui/react";
import { RiCheckFill } from "react-icons/ri";
import * as AF from "src/types/formTemplate";

type Props = { question: AF.Question<AF.WithId> };

export const RequirementTag: React.FC<Props> = ({ question }) => {
  if (question.type === AF.GradesType || question.requirement === undefined) {
    return null;
  }

  return (
    <Flex direction="row">
      <Tag size="sm">
        <TagLeftIcon aria-hidden={true} as={RiCheckFill} />
        {question.requirement}
      </Tag>
    </Flex>
  );
};

export function hasRequirementTag(question: AF.Question<AF.WithId>): boolean {
  return RequirementTag({ question }) !== null;
}
