import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { ChangeEvent, SyntheticEvent } from "react";
import { FORM_STATUS_DESCRIPTION_MAX_CHARS } from "src/constants";
import { NextStepsInfoPopover } from "./NextStepsInfoPopover";

interface Props {
  value: string;
  name: string;
  onChange: (e: SyntheticEvent) => void;
  onCancelClick: () => void;
  onUpdateStatusClick: () => void;
}

export const FormStatusDescriptionFormControl: React.FC<Props> = ({
  value,
  name,
  onChange,
  onCancelClick,
  onUpdateStatusClick,
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= FORM_STATUS_DESCRIPTION_MAX_CHARS) {
      onChange(e);
    }
  };

  const errorMessage =
    value.length >= FORM_STATUS_DESCRIPTION_MAX_CHARS
      ? `Description has exceeded maximum allowed characters ${FORM_STATUS_DESCRIPTION_MAX_CHARS}.`
      : value.length === 0
      ? `Description is required.`
      : undefined;

  const isError = !!errorMessage;

  return (
    <FormControl isInvalid={isError}>
      <Flex direction="row" align="center">
        <FormLabel htmlFor={name}>
          <HStack spacing={0}>
            <Text marginRight={2}>Status description</Text>
            <NextStepsInfoPopover />
          </HStack>
        </FormLabel>
      </Flex>
      <Textarea id={name} name={name} value={value} onChange={handleChange} />
      {!isError ? (
        <FormHelperText>
          {FORM_STATUS_DESCRIPTION_MAX_CHARS - value.length}
        </FormHelperText>
      ) : (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}

      <Flex justifyContent="flex-end" gap="2">
        <Button variant="ghost" colorScheme="gray" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button onClick={onUpdateStatusClick}>Update status</Button>
      </Flex>
    </FormControl>
  );
};
