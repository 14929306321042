import { Flex } from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { CreateNewFormTemplateButton } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/components/CreateNewFormTemplateButton";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { EnrollmentPeriodOutletContext } from "../../Edit";
import { FormTemplatesList } from "./List";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";

export const FormTemplatesTab: React.FC = () => {
  const { enrollmentPeriod } =
    useOutletContext<EnrollmentPeriodOutletContext>();
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormTemplatesByEnrollmentPeriod,
    GQL.GetFormTemplatesByEnrollmentPeriodVariables
  >(GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period: enrollmentPeriod.id,
      include_rules: isAvelaAdmin
    },
    fetchPolicy: "network-only"
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => (
        <Flex direction="column">
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            <Flex flexDirection="row-reverse" mb={4}>
              <CreateNewFormTemplateButton />
            </Flex>
          </WithRequiredHasuraRoles>

          <FormTemplatesList formTemplates={data.form_template} />
        </Flex>
      )}
    </GQLRemoteDataView>
  );
};
