import { Flex, Heading, Link, Tag, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import * as Form from "src/services/form";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { GET_PREVIOUS_FORM_BY_FORM_ID } from "../graphql/queries";
import { FormTagsPopover } from "../../tags/FormTagsPopover";

type Props = {
  form: GQL.GetPreviousFormByFormId_form_by_pk;
};
export const FormDetails: React.FC<Props> = ({ form }) => {
  const organization = useOrganization();
  const relatedForms = Form.Related.getRelatedForms(form);
  const relatedGrade = Form.Related.getRelatedGrade(form);
  const relatedSchool = Form.Related.getRelatedSchool(form);
  const tags = form.form_tags.map((tag) => tag.enrollment_period_tag.name);
  const tagsContent = tags ? (
    <Flex gap="2" flexWrap="wrap">
      {tags.map((tag) => (
        <Tag key={tag} variant="tag" fontSize="sm">
          {tag}
        </Tag>
      ))}
      <WithUserPermissions permissions={["form:update"]}>
        <FormTagsPopover
          enrollmentPeriodId={form.form_template.enrollment_period_id}
          formId={{
            formId: form.id,
          }}
          refetchQueries={[GET_PREVIOUS_FORM_BY_FORM_ID]}
        />
      </WithUserPermissions>
    </Flex>
  ) : null;

  const hasRelatedForms = relatedForms.length > 0;
  const hasRelatedGrade = relatedGrade !== undefined;
  const hasRelatedSchool = relatedSchool !== undefined;
  const hasFormDetails = hasRelatedForms || hasRelatedGrade || hasRelatedSchool;

  return (
    <Flex
      marginX="-4"
      direction="column"
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="blackAlpha.80"
      padding="4"
      gap="4"
    >
      <Heading as="h2" fontSize="md" fontWeight="600">
        Form details
      </Heading>
      <Flex direction="column" gap="3">
        {!hasFormDetails && (
          <Text>
            Any additional information collected during the process will be
            displayed here
          </Text>
        )}
        {hasRelatedForms && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              Related form
            </Text>
            {relatedForms.map((relatedForm) => {
              return (
                <Link
                  key={relatedForm.id}
                  color="primary.500"
                  as={RouterLink}
                  to={Url.OrgAdmin.Forms.view({
                    organization,
                    id: relatedForm.id,
                    formTemplateId: relatedForm.form_template_id,
                  })}
                >
                  {relatedForm.form_template.name} ID {relatedForm.id}
                </Link>
              );
            })}
          </Flex>
        )}
        {hasRelatedGrade && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              Grade
            </Text>
            <Text>{relatedGrade}</Text>
          </Flex>
        )}
        {hasRelatedSchool && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              School
            </Text>
            <Text>{relatedSchool.name}</Text>
          </Flex>
        )}
      </Flex>

      <WithRequiredHasuraRoles
        roles={[
          HasuraRole.ADMIN,
          HasuraRole.DISTRICT_ADMIN,
          HasuraRole.ORG_ADMIN,
          HasuraRole.SCHOOL_ADMIN,
        ]}
      >
        {tagsContent}
      </WithRequiredHasuraRoles>
    </Flex>
  );
};
