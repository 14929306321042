import { ELEVATED_SCHOOL_ADMIN_GROUP_NAME } from "src/constants";
import { Status } from "../types/authData";
import { HasuraRole } from "../types/hasuraRole";
import useUser from "./useUser";

export default function useRequiredHasuraRoles(roles: HasuraRole[]): boolean {
  const user = useUser();

  if (
    user.status === Status.LOADING ||
    user.status === Status.UNAUTHENTICATED
  ) {
    return false;
  }

  let role = user.data.role;
  if (
    role === HasuraRole.ORG_ADMIN &&
    user.data.userGroup === ELEVATED_SCHOOL_ADMIN_GROUP_NAME
  ) {
    role = HasuraRole.SCHOOL_ADMIN;
  }

  if (user.status === Status.OK && !roles.includes(role)) {
    return false;
  }

  return true;
}
