import React from "react";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { QuestionFormEdit } from "./Edit";
import { QuestionFormView } from "./View";

type Mode = "View" | "Edit";
type Props = {
  sectionType: AF.SectionType;
  question: Draft.Question;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  onUpdate?: (question: Draft.Question) => Promise<void>;
  onRemove?: (question: Draft.Question) => Promise<void>;
  hasChanges: boolean;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
  otherQuestions: readonly Draft.Question[];
};
export const QuestionForm: React.FC<Props> = ({
  sectionType,
  question,
  verificationOptions,
  onUpdate,
  onRemove,
  schools,
  hasChanges,
  gradesConfig,
  otherQuestions,
}) => {
  const [mode, setMode] = React.useState<Mode>("View");

  const onEditButtonClick = React.useCallback(() => {
    setMode("Edit");
  }, []);
  const onCancelButtonClick = React.useCallback(() => {
    setMode("View");
  }, []);

  const onUpdateButtonClick = React.useCallback(
    async (updatedQuestion: Draft.Question) => {
      if (onUpdate) {
        await onUpdate(updatedQuestion);
      }
      setMode("View");
    },
    [onUpdate]
  );

  const onRemoveButtonClick = React.useCallback(() => {
    if (onRemove) {
      onRemove(question);
    }
  }, [onRemove, question]);

  switch (mode) {
    case "Edit":
      return (
        <QuestionFormEdit
          sectionType={sectionType}
          question={question}
          onCancelButtonClick={onCancelButtonClick}
          onUpdate={onUpdateButtonClick}
          verificationOptions={verificationOptions}
          schools={schools}
          gradesConfig={gradesConfig}
          otherQuestions={otherQuestions}
        />
      );

    case "View":
      return (
        <QuestionFormView
          question={question}
          hasChanges={hasChanges}
          onEditButtonClick={onEditButtonClick}
          onRemoveButtonClick={onRemoveButtonClick}
          schools={schools}
        />
      );

    default:
      const _exhaustiveCheck: never = mode;
      return _exhaustiveCheck;
  }
};

export { QuestionFormEdit } from "./Edit";
export { QuestionFormView } from "./View";
