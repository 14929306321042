import { HStack, Tag, Text, Tooltip } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import _ from "lodash";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import * as GQL from "src/types/graphql";
import { TagGroupName } from "src/components/Tags/TagGroups/TagGroupName";
import { ColumnId } from "./constants";

type Row = {
  form: {
    tags: {
      created_at: timestamptz | null;
      enrollment_period_tag: { name: string };
    }[];
  } | null;
  form_school_rank: {
    tags: {
      created_at: timestamptz | null;
      enrollment_period_tag: { name: string; tag_group_id: string | null };
    }[];
  } | null;
};

export function buildTagsColumnDef<T extends Row>(
  tagGroup: GQL.GetTagGroupsByEnrollmentPeriod_tag_group
): ColumnDef<T> {
  return {
    id: tagGroup.name,
    header: () => (
      <TagGroupName name={tagGroup.name} isExternal={tagGroup.is_external} />
    ),

    cell: (props) => {
      const tagsWithinGroup = props.row.original.form_school_rank?.tags
        ? props.row.original.form_school_rank?.tags.filter(
            (tag) => tag.enrollment_period_tag.tag_group_id === tagGroup.id
          )
        : [];

      if (!tagsWithinGroup.length) {
        return <></>;
      }

      const mostRecentTag = _.maxBy<(typeof tagsWithinGroup)[0]>(
        tagsWithinGroup,
        function (tag) {
          return tag.created_at;
        }
      );

      const allTagsString = tagsWithinGroup
        .map((tag: any) => tag.enrollment_period_tag.name)
        .join(", ");

      const maxWidth = "6rem";

      if (tagsWithinGroup.length > 1 && mostRecentTag) {
        return (
          <Tooltip hasArrow label={allTagsString}>
            <HStack tabIndex={0} width="fit-content">
              <Tag variant="tag">
                <Text
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth={maxWidth}
                >
                  {mostRecentTag.enrollment_period_tag.name}
                </Text>
              </Tag>
              <Tag variant="tag">+{tagsWithinGroup.length - 1}</Tag>
            </HStack>
          </Tooltip>
        );
      }

      if (mostRecentTag) {
        return (
          <Tag variant="tag">
            <TextWithOverflowTooltip
              maxWidth={maxWidth}
              content={mostRecentTag?.enrollment_period_tag.name}
              hasArrow
            />
          </Tag>
        );
      }

      return null;
    },
  };
}

export function buildFormTagsColumnDef<T extends Row>(): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form_school_rank?.tags,
    id: ColumnId.Tags,
    header: "form tags",

    cell: (props) => {
      const formTags = props.row.original.form?.tags ?? [];

      if (!formTags.length) {
        return <></>;
      }

      const mostRecentTag = _.maxBy<(typeof formTags)[0]>(
        formTags,
        function (tag) {
          return tag.created_at;
        }
      );

      const allTagsString = formTags
        .map((tag: any) => tag.enrollment_period_tag.name)
        .join(", ");

      const maxWidth = "6rem";

      if (formTags.length > 1 && mostRecentTag) {
        return (
          <Tooltip hasArrow label={allTagsString}>
            <HStack tabIndex={0} width="fit-content">
              <Tag variant="tag">
                <Text
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth={maxWidth}
                >
                  {mostRecentTag.enrollment_period_tag.name}
                </Text>
              </Tag>
              <Tag variant="tag">+{formTags.length - 1}</Tag>
            </HStack>
          </Tooltip>
        );
      }

      if (mostRecentTag) {
        return (
          <Tag variant="tag">
            <TextWithOverflowTooltip
              maxWidth={maxWidth}
              content={mostRecentTag?.enrollment_period_tag.name}
              hasArrow
            />
          </Tag>
        );
      }

      return null;
    },
  };
}
