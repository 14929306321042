import { Flex, FormLabel, Text } from "@chakra-ui/react";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { AdditionalQuestions } from "src/components/Form/QuestionForm/View/AdditionalQuestions";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as Question from "src/services/formTemplate/question";
import * as RD from "src/types/remoteData";
import { Eligibility } from "./Eligibility";

type AvailableAnswersProps = {
  question: Draft.Question;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const AvailableAnswers: React.FC<AvailableAnswersProps> = ({
  question,
  schools,
}) => {
  if (!Question.hasOptions(question)) {
    return null;
  }

  const options = question.options;
  if (options.length === 0) return null;
  return (
    <Flex direction="column" gap="2">
      <FormLabel as={Text} margin="0">
        Available answers
      </FormLabel>
      <Flex direction="column" gap="2" as="dl">
        {options.map((option) => (
          <Flex key={option.id} gap="2" flexDirection="column">
            <Text as="dt" color="blackAlpha.700">
              {option.label}
              {!!option.value && ` | Answer value: ${option.value}`}
            </Text>

            <Eligibility option={option} schools={schools} />
            <AdditionalQuestions option={option} schools={schools} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
