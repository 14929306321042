import * as Turf from "@turf/helpers";
import { useEffect, useMemo, useState } from "react";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import {
  findFormAddressAnswer,
  findGradeAnswer,
  FormikValues,
} from "src/services/formTemplate/answer";
import {
  calculateSchoolsToRemove,
  RankedSchool,
} from "src/services/formTemplate/preRankingSection";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import { addressLookup } from "src/components/Boundary/services";
import { useBoundariesMap } from "src/components/Boundary/useBoundary";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";

type Props = {
  formId: uuid;
  rankedSchools: readonly RankedSchool[];
  completeQuestions: readonly AF.Question<AF.WithId>[];
  answers: FormikValues;
};

export const useFrontEndRankedSchoolsRemoval = ({
  formId,
  rankedSchools,
  completeQuestions,
  answers,
}: Props) => {
  const flags = useFlags(["eligibility-service"]);
  const boundariesMap = useBoundariesMap({ formId });
  const [location, setLocation] = useState<
    RD.RemoteData<unknown, Turf.Position | undefined>
  >(RD.notAsked());

  useLoadGooglePlacesScript();
  const shouldSkip = flags["eligibility-service"].enabled;

  const gradeConfigId = useMemo(() => {
    if (shouldSkip) {
      return;
    }
    return findGradeAnswer(completeQuestions, answers);
  }, [answers, completeQuestions, shouldSkip]);

  const initialRankedSchoolsToRemove = useMemo((): RankedSchool[] => {
    if (shouldSkip) {
      return [];
    }
    if (location.hasData() && boundariesMap.hasData()) {
      return calculateSchoolsToRemove(
        rankedSchools,
        completeQuestions,
        answers,
        gradeConfigId?.gradeConfigId,
        location.data,
        boundariesMap.data
      );
    }

    return [];
  }, [
    shouldSkip,
    answers,
    boundariesMap,
    completeQuestions,
    gradeConfigId,
    location,
    rankedSchools,
  ]);

  const [rankedSchoolsToRemove, refreshRankedSchoolsToRemove] = useState<
    RD.RemoteData<Error, RankedSchool[]>
  >(RD.success(initialRankedSchoolsToRemove));

  useEffect(() => {
    if (shouldSkip) {
      return;
    }
    if (!window.google?.maps) {
      return;
    }

    setLocation(RD.loading());
    const address = findFormAddressAnswer(completeQuestions, answers);
    if (address === undefined) {
      setLocation(RD.success(undefined));
      return;
    }

    addressLookup(new google.maps.Geocoder(), address)
      .then((location) => {
        setLocation(RD.success(location));
      })
      .catch((e) => setLocation(RD.failure(e)));
  }, [shouldSkip, answers, completeQuestions]);

  useEffect(() => {
    if (shouldSkip) {
      return;
    }
    RD.toTuple(location, boundariesMap).do(([location, boundariesMap]) => {
      refreshRankedSchoolsToRemove(
        RD.success(
          calculateSchoolsToRemove(
            rankedSchools,
            completeQuestions,
            answers,
            gradeConfigId?.gradeConfigId,
            location,
            boundariesMap
          )
        )
      );
    });
  }, [
    shouldSkip,
    location,
    gradeConfigId,
    boundariesMap,
    rankedSchools,
    completeQuestions,
    answers,
  ]);

  return rankedSchoolsToRemove;
};
