import { Box, StyleProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { HorizontallyBoundedContainer } from "src/components/Layout/HorizontallyBoundedContainer";

interface ParentFormsLayoutFooterProps extends StyleProps {
  children: ReactNode;
}

export function ParentFormsLayoutFooter(props: ParentFormsLayoutFooterProps) {
  const { children, ...styleProps } = props;

  return (
    <Box
      bg="white"
      borderBlockStart="1px solid"
      borderColor="gray.200"
      bottom={0}
      position="sticky"
      width="100%"
    >
      <HorizontallyBoundedContainer
        columnGap={4}
        display="flex"
        paddingX={4}
        paddingY={2}
        rowGap={2}
        {...styleProps}
      >
        {children}
      </HorizontallyBoundedContainer>
    </Box>
  );
}
