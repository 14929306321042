import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { CHUNKED_BATCH_SIZE } from "src/constants";
import { asyncFlatMap } from "src/services/asyncHelpers";
import * as GQL from "src/types/graphql";
import {
  FETCH_GRADE_IDS_WITH_FORM_SCHOOL,
  FETCH_GRADE_IDS_WITH_FORM_SCHOOL_MAX_INPUT_SIZE,
  FETCH_SEATS_AVAILABLE,
} from "./queries";

export const useFetchGradeIdsWithFormSchool = () => {
  const client = useApolloClient();
  return React.useCallback(
    (schoolForms: FormSchool[], program_id: string | null) =>
      asyncFlatMap(
        _.chunk(schoolForms, FETCH_GRADE_IDS_WITH_FORM_SCHOOL_MAX_INPUT_SIZE),
        async (schoolFormChunk) => {
          const result = await client.query<
            GQL.FetchGradeIdsWithFormSchool,
            GQL.FetchGradeIdsWithFormSchoolVariables
          >({
            query: FETCH_GRADE_IDS_WITH_FORM_SCHOOL,
            variables: {
              query: getFormSchoolGradeQuery(schoolFormChunk, program_id),
            },
          });
          const formSchoolGrades = result.data.form_school_grade;
          if (formSchoolGrades.length !== schoolFormChunk.length) {
            throw new Error(
              `Mismatch between forms and grades: ${schoolFormChunk.length} vs. ${formSchoolGrades.length}`
            );
          }
          return formSchoolGrades;
        },
        CHUNKED_BATCH_SIZE
      ),
    [client]
  );
};

export const useFetchSeatsAvailable = () => {
  const client = useApolloClient();
  return React.useCallback(
    async (gradeIds: string[]) => {
      const result = await client.query<
        GQL.FetchSeatsAvailable,
        GQL.FetchSeatsAvailableVariables
      >({
        query: FETCH_SEATS_AVAILABLE,
        variables: {
          grade_ids: gradeIds,
        },
      });
      return result.data.grade;
    },
    [client]
  );
};

type FormSchool = {
  form_id: uuid;
  school_id: uuid;
};

const getFormSchoolGradeQuery = (
  schoolForms: FormSchool[],
  program_id: string | null
): GQL.form_school_grade_bool_exp => {
  const program_bool_exp: GQL.grade_bool_exp = program_id
    ? { program_id: { _eq: program_id } }
    : { program_id: { _is_null: true } };

  return {
    grade: program_bool_exp,
    _or: schoolForms.map((appSchool): GQL.form_school_grade_bool_exp => {
      return {
        form_id: { _eq: appSchool.form_id },
        school_id: { _eq: appSchool.school_id },
      };
    }),
  };
};
