import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";
import * as Url from "../url";

export * as capacities from "./capacities";
export * as enrollmentPeriod from "./enrollmentPeriod";
export * as form from "./form";
export * as glossaries from "./glossaries";
export * as matchTemplates from "./matchTemplates";
export * as messages from "./messages";
export * as parent from "./parent";
export * as school from "./school";
export * as student from "./student";
export * as team from "./team";
export * as dataServices from "./dataServices";

export interface BreadcrumbType {
  label: string;
  href: string;
}

export const getBreadcrumbsForHome = (
  organization: RD.RemoteData<unknown, Organization>
): BreadcrumbType[] => {
  return [
    {
      label: "Home",
      href: organization.map((org) => Url.OrgAdmin.index(org)).withDefault("#"),
    },
  ];
};
