import { Flex } from "@chakra-ui/react";
import React from "react";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import * as History from ".";
import { GET_INACTIVE_FORMS } from "./graphql/queries";

export const List: React.FC = () => {
  const organization = useOrganization();
  const { remoteData: historyRemoteData } = useRemoteDataQuery<
    GQL.GetInactiveForms,
    GQL.GetInactiveFormsVariables
  >(GET_INACTIVE_FORMS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <ParentFormsLayout heading="History">
      <GQLRemoteDataView remoteData={historyRemoteData}>
        {(historyData) => {
          return (
            <Flex direction="column" gap={8}>
              {historyData.form.map((form) => (
                <History.View
                  key={form.id}
                  enrollmentPeriod={form.form_template.enrollment_period}
                  form={form}
                />
              ))}
            </Flex>
          );
        }}
      </GQLRemoteDataView>
    </ParentFormsLayout>
  );
};
