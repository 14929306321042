import { z } from "zod";

export const FreeTextLiteral = z.literal("FreeText");
export const EmailLiteral = z.literal("Email");
export const PhoneNumberLiteral = z.literal("PhoneNumber");
export const SingleSelectLiteral = z.literal("SingleSelect");

/**
 * Based on GQL.QuestionFragment_custom_question_custom_question_type
 */

// the question types are restricted, specifically to exclude FileUpload and Grade
// [CQT-TODO] we will incrementally broaden this to support MultiSelect, Address
export const CustomQuestionTypeFieldTypesSchema = z.union([
  FreeTextLiteral,
  EmailLiteral,
  PhoneNumberLiteral,
  SingleSelectLiteral,
]);
export type CustomQuestionTypeFieldTypes = z.infer<
  typeof CustomQuestionTypeFieldTypesSchema
>;

// subset of BaseQuestion
const QuestionSchema = z.object({
  id: z.string().uuid().optional(),
  key: z.string(),
  type: CustomQuestionTypeFieldTypesSchema,
  question: z.string(),
  order: z.number(),
  is_required: z.boolean(),
  // requirement?: typeof Requirement;
  // specificToSchools?: uuid[];
  link_url: z.string().optional(),
  link_text: z.string().optional(),
  permission_level: z.string().optional(),
  // filters?: AFF.FormTemplateFilter
});

export const CustomQuestionTypeField = z.object({
  question_id: z.string().optional(),
  question: QuestionSchema,
});

export type CustomQuestionTypeFieldType = z.infer<
  typeof CustomQuestionTypeField
>;

export const CustomQuestionTypeSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  organization_id: z.string(),
  custom_question_type_fields: z.array(CustomQuestionTypeField),
});

export type CustomQuestionType = z.infer<typeof CustomQuestionTypeSchema>;
