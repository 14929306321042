import * as Papa from "papaparse";

export type Filename<EXT extends string> = `${string}.${EXT}`;

export async function triggerDownload(data: Blob, filename?: string) {
  const url = URL.createObjectURL(data);
  await triggerDownloadFromUrl(url, filename);
  URL.revokeObjectURL(url);
}

export function triggerDownloadFromUrl(url: string, filename?: string) {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = filename ?? "";
  document.body.appendChild(link);
  link.click();

  return new Promise((resolve) =>
    setTimeout((value) => {
      link.parentNode?.removeChild(link);
      resolve(value);
    }, 1000)
  );
}

export function csvExport<T>(records: T[], filename?: Filename<"csv">) {
  const csvData = Papa.unparse(records, { escapeFormulae: true });
  triggerDownload(new Blob([csvData], { type: "text/csv" }), filename);
}

export function parseCsvFile(
  file: File,
  onComplete: (results: any) => void,
  onError: (error: any) => void
) {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: onComplete,
    error: onError,
  });
}
