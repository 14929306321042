export const SET_CURRENT_QUESTION_ID = "SET_CURRENT_QUESTION_ID";

export interface EditFormActions {
  type: typeof SET_CURRENT_QUESTION_ID;
  value: string;
}

export const setCurrentQuestionId = (value: string): EditFormActions => ({
  type: SET_CURRENT_QUESTION_ID,
  value,
});
