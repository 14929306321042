import { ColorProps, Text } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = {
  color?: ColorProps["color"];
};

export const DataLabel: FunctionComponent<PropsWithChildren<Props>> = (
  props
) => {
  const { color = "gray.600", children } = props;
  return (
    <Text fontSize="sm" color={color} fontWeight="500" whiteSpace="pre-wrap">
      {children}
    </Text>
  );
};
