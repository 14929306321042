import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Flex, Heading, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import { SegmentedMessage } from "sms-segments-calculator";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { MESSAGE_CONTENT_LIMIT } from "src/constants";
import { maybePluralize } from "src/services/format";

type Props = {
  hasRecipientsWithEmail: boolean;
  hasRecipientsWithPhone: boolean;
};
export const MessageForm: React.FC<Props> = ({
  hasRecipientsWithEmail,
  hasRecipientsWithPhone,
}) => {
  const [field, fieldValidation, helpers] = useField("sms_body");

  const handleOnChangeSmsBody = useCallback(() => {
    if (fieldValidation.touched) return;

    helpers.setTouched(true);
  }, [helpers, fieldValidation.touched]);

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h2" size="md">
        Email
      </Heading>

      {hasRecipientsWithEmail ? (
        <Flex direction="column" gap={2}>
          <InputWithValidationControl
            inputProps={{ placeholder: "Message subject", my: 2 }}
            name="subject"
            id="subject"
          />
          <TextareaWithValidationControl
            textareaProps={{ placeholder: "Email content", my: 2 }}
            name="email_body"
            id="email_body"
          />
        </Flex>
      ) : (
        <Text color="blackAlpha.700" fontWeight="400">
          No email recipients
        </Text>
      )}

      <Heading as="h2" size="md">
        SMS
      </Heading>

      {hasRecipientsWithPhone ? (
        <Flex direction="column">
          <TextareaWithValidationControl
            textareaProps={{ placeholder: "SMS content", my: 2 }}
            name="sms_body"
            id="sms_body"
            onChange={handleOnChangeSmsBody}
          />
          {(!fieldValidation.touched || !fieldValidation.error) && (
            <SmsInputStats value={field.value} />
          )}
        </Flex>
      ) : (
        <Text color="blackAlpha.700" fontWeight="400">
          No SMS recipients
        </Text>
      )}
    </Flex>
  );
};

export const SmsInputStats: React.FC<{
  value: string;
  maxSmsLength?: number;
}> = ({ value, maxSmsLength = MESSAGE_CONTENT_LIMIT }) => {
  const segmentedMessage = useMemo(() => new SegmentedMessage(value), [value]);
  return (
    <Flex justify="space-between" fontSize="sm" mb={4}>
      <Text>
        {segmentedMessage.numberOfCharacters}/{maxSmsLength}
      </Text>
      <Text>
        {segmentedMessage.segmentsCount}{" "}
        {maybePluralize(segmentedMessage.segmentsCount, "segment")}
        <Tooltip
          label={
            <Text align="center">
              Segmentation may vary as it is impacted by special characters in a
              language
            </Text>
          }
          hasArrow
          placement="top"
          borderRadius="md"
        >
          <IconButton
            aria-label="Segmentation Information"
            size="sm"
            icon={<InfoOutlineIcon />}
            variant="ghost"
            color="black"
          />
        </Tooltip>
      </Text>
    </Flex>
  );
};
