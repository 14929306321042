import { PropsWithChildren } from "react";
import * as OrgConfig from "@avela/organization-config-sdk";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import { RemoteDataView } from "../Layout/RemoteDataView";

type Props = PropsWithChildren<{
  configType: OrgConfig.Type;
}>;

export const WithConfig: React.FC<Props> = ({ children, configType }) => {
  const configRD = useOrgConfig(configType);

  return (
    <RemoteDataView remoteData={configRD} error={() => null}>
      {(config) => (config.disabled ? null : <>{children}</>)}
    </RemoteDataView>
  );
};
