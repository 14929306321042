import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

/**
 * Chakra-UI Radio has three sub-components: control, label, and container
 * This file customizes the default Radio theme.
 *
 * https://chakra-ui.com/docs/components/radio/theming
 */

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    marginTop: 1,
  },
  label: {},
  container: {
    alignItems: "top",
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
