import * as GQL from "src/types/graphql";

export const normalizeSearchQuery = (query: string) =>
  query.match(/\S+/g)?.join(" ") ?? "";

export function ilikeNormalizedSearchFilter(query: string) {
  const normalized = normalizeSearchQuery(query);
  return normalized ? { _ilike: `%${normalized}%` } : undefined;
}

export const getStudentSearchQuery = (
  searchTerm: string
): GQL.person_bool_exp => ({
  searchable_text: ilikeNormalizedSearchFilter(searchTerm),
});

export const getParentSearchQuery = (
  searchTerm: string
): GQL.person_bool_exp => {
  const ilikeFilter = ilikeNormalizedSearchFilter(searchTerm);
  return {
    _or: [
      { searchable_text: ilikeFilter },
      { email_address: ilikeFilter },
      { phone_number: ilikeFilter },
    ],
  };
};
