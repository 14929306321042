import * as AF from "src/types/formTemplate";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import * as Answer from "src/services/formTemplate/answer";
import * as RD from "src/types/remoteData";
import { ApolloError } from "@apollo/client";
import * as GQL from "src/types/graphql";
import { useFrontEndEligibilityAnswersChange } from "./useFrontEndEligibilityAnswersChange";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useCallback, useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useEligibilityService } from "src/services/eligibility/useEligibilityService";
import { rankedSchoolsVar } from "../store";
import { RankedSchoolsRemovalConfirmationDialogBody } from "../components/Dialogs/RankedSchoolsRemovalConfirmationDialogBody";
import { useDeleteRankedSchools } from "./useDeleteRankedSchools";
import { Glossary } from "src/components/Text/Glossary";

export type HooksFn = (props: {
  formId: string;
  formTemplateId: string;
  schoolRankingSection: AF.Section<AF.WithId> | undefined;
  schoolRanksRemoteData: RD.RemoteData<ApolloError, GQL.GetSchoolsRank>;
  refetchSchoolRanks: () => Promise<unknown>;
  rankingEnabled: boolean;
}) => {
  dialog: React.ReactNode;
  confirmAddress: (
    answers: Answer.FormikValues,
    question: AF.Question<AF.WithId>,
    addressAnswer: Answer.UndoableAnswer<AddressAnswer | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
  confirmGrades: (
    answers: Answer.FormikValues,
    question: AF.Question<AF.WithId>,
    newGradeConfigId: Answer.UndoableAnswer<uuid | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
  confirmEligibilityQuestion: (
    answers: Answer.FormikValues,
    question: AF.Question<AF.WithId>,
    answer: Answer.UndoableAnswer<AF.Option<AF.WithId> | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
};
export const useEligibilityAnswersChange: HooksFn = (props) => {
  const flags = useFlags(["eligibility-service"]);

  // backend
  const { glossary } = useGlossary();
  const { confirm, confirmationDialog, helpers } = useConfirmationDialog({
    body: null,
    header: glossary`Edit family/student information`,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, continue" },
    translate: true,
  });

  const { deleteRankedSchools } = useDeleteRankedSchools({
    ...props,
  });

  const eligibilityService = useEligibilityService({
    formTemplateId: props.formTemplateId,
  });
  const confirmEligibilityChange = useCallback(
    async (
      answers: Answer.FormikValues,
      question: AF.Question<AF.WithId>,
      answer: Answer.UndoableAnswer<EligibilityAnswer>
    ) => {
      const rankedSchools = rankedSchoolsVar();
      if (rankedSchools.length === 0) {
        // no need to check for eligibiltiy yet since no school selected
        return true;
      }

      const previousAnswers: Answer.FormikValues = {
        ...answers,
        [question.id]: isOption(answer.before)
          ? answer.before?.id
          : answer.before,
      };

      if (
        !eligibilityService.shouldRunEligibilityCheck({
          questions: [question],
          answers: {
            previous: previousAnswers,
            current: answers,
          },
        })
      ) {
        // No need to run eligibility check, go ahead and save the answer
        return true;
      }

      let proceed = await confirm({
        body: (
          <Flex direction="column" gap={3}>
            <Box>
              <Glossary>
                By changing the response on this question, you may impact
                eligibility and certain schools may be removed from your
                selection.
              </Glossary>
            </Box>
            <Box fontWeight="bold">Check eligibility?</Box>
          </Flex>
        ),
        onConfirm: (helpers) => {
          helpers.setButton({ label: "Yes, continue", isLoading: true });
        },
      });
      if (!proceed) {
        return false;
      }

      const ineligibleSchools =
        await eligibilityService.getIneligibleSchoolIdsByAnswers({ answers });

      const schoolsToRemove = rankedSchools.filter((rs) =>
        ineligibleSchools.includes(rs.school.id)
      );

      if (schoolsToRemove.length === 0) {
        helpers.close();
        return true;
      }

      proceed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            rankingEnabled={props.rankingEnabled}
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, your eligibility will change and these schools will be removed from your list:`}
            confirmation="Are you sure?"
          />
        ),
        confirmButton: {
          label: "Yes, continue",
          isLoading: false,
        },
      });

      if (!proceed) {
        return false;
      }

      deleteRankedSchools(
        rankedSchools,
        schoolsToRemove.map(({ school: { id } }) => id)
      );
      return true;
    },
    [
      confirm,
      deleteRankedSchools,
      eligibilityService,
      glossary,
      helpers,
      props.rankingEnabled,
    ]
  );

  const backend = useMemo(
    () => ({
      confirmAddress: confirmEligibilityChange,
      confirmGrades: confirmEligibilityChange,
      confirmEligibilityQuestion: confirmEligibilityChange,
      dialog: confirmationDialog,
    }),
    [confirmEligibilityChange, confirmationDialog]
  );

  // frontend
  const frontend = useFrontEndEligibilityAnswersChange(props);
  if (!flags["eligibility-service"].enabled) {
    return frontend;
  }

  // backend
  return backend;
};

type EligibilityAnswer =
  | AddressAnswer
  | uuid
  | AF.Option<AF.WithId>
  | undefined;
function isOption(
  newAnswer: EligibilityAnswer
): newAnswer is AF.Option<AF.WithId> {
  return (
    typeof newAnswer === "object" && "id" in newAnswer && "label" in newAnswer
  );
}
