import { FunctionComponent, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserProfile } from "src/hooks/useUserProfile";
import { FormCustomQuestionPathParams } from "src/services/url/Parent";
import { CustomQuestion, WithId } from "src/types/formTemplate";
import {
  GetCustomQuestion_question_by_pk_custom_question_custom_question_type,
  Organization,
  ProfileFragment,
} from "src/types/graphql";
import { toTuple3 } from "src/types/remoteData";
import { useGetCustomQuestionQuery } from "./api";

export type CustomQuestionData = {
  organization: Organization;
  userProfile: ProfileFragment;
  customQuestion: CustomQuestion<WithId>;
  customQuestionType: GetCustomQuestion_question_by_pk_custom_question_custom_question_type;
};

type CustomQuestionDataViewProps = {
  children: (data: CustomQuestionData) => ReactNode;
};

/**
 * Consolidated "DataView" that handles fetches most of the requisite data.
 * See the queries and their remoteData mapping for more details.
 */
export const CustomQuestionDataView: FunctionComponent<
  CustomQuestionDataViewProps
> = (props) => {
  const { children } = props;
  const { customQuestionId = "" } = useParams<FormCustomQuestionPathParams>();

  const organizationRemoteData = useOrganization();
  const userProfileRemoteData = useUserProfile();
  const customQuestionRemoteData = useGetCustomQuestionQuery({
    question_id: customQuestionId,
  });

  const remoteData = toTuple3(
    organizationRemoteData,
    userProfileRemoteData,
    customQuestionRemoteData
  );

  return (
    <RemoteDataView
      remoteData={remoteData}
      error={() => <GenericError message="Failed to load question data." />}
    >
      {([organization, userProfile, customQuestion]) => {
        if (!customQuestion) {
          return null;
        }

        return (
          <>
            {children({
              organization,
              userProfile,
              ...customQuestion,
            })}
          </>
        );
      }}
    </RemoteDataView>
  );
};
