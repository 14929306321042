import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Card } from "src/components/Layout/Card";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";

type Props = {
  original: AF.SchoolRankingSection<AF.WithId>;
  draft: Draft.SchoolRanking | Draft.NewSchoolRanking | undefined;
  onUpdate?: (
    schoolRanking: Draft.SchoolRanking | Draft.NewSchoolRanking
  ) => void;
};
export const SchoolRankingForm: React.FC<Props> = ({ original, draft }) => (
  <Card display="flex" flexDirection="column" showBorder gap="3">
    <SchoolRankingFormView schoolRanking={draft ?? original} />
  </Card>
);

type ViewProps = {
  schoolRanking: AF.SchoolRankingSection<AF.WithId>;
};
export const SchoolRankingFormView: React.FC<ViewProps> = ({
  schoolRanking,
}) => {
  return (
    <Flex direction="column" gap="3">
      <Text fontWeight="bold">Link URL</Text>
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {schoolRanking.exploreUrl ?? "None"}
      </Text>
      <Text fontWeight="bold">Min. Schools</Text>
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {schoolRanking.minSchools}
      </Text>
      <Text fontWeight="bold">Max. Schools</Text>
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {schoolRanking.maxSchools}
      </Text>
      <Text fontWeight="bold">Ordered Ranking</Text>
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {schoolRanking.rankingEnabled ? "Yes" : "No"}
      </Text>
    </Flex>
  );
};
