import { Grid, HStack, VStack } from "@chakra-ui/layout";
import {
  Box,
  FlexProps,
  FormHelperText,
  FormLabel,
  GridProps,
  Switch,
} from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Field, FieldProps } from "formik";
import { FormControl } from "formik-chakra-ui";
import { FormInput } from "src/components/Inputs/FormInput";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { Option } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { SingleSelectInput } from "src/components/Inputs/QuestionDynamicInputs/SingleSelectInput";
import { ELEVATED_SCHOOL_ADMIN_GROUP_NAME } from "src/constants";

import { SchoolType, TeamType, UserGroupType } from "src/schemas/TeamMember";
import * as GQL from "src/types/graphql";

type InputSwitchProps = {
  name: string;
};

const InputSwitch: React.FC<InputSwitchProps> = ({ name }) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps<number, unknown>) => (
        <Switch
          size="md"
          alignSelf="start"
          {...field}
          id={name}
          value={field.value}
          isChecked={!!field.value}
          onChange={(event) => {
            form.setFieldValue(name, event.target.checked);
          }}
        />
      )}
    </Field>
  );
};

type Props = {
  onChangeUserRole: (userGroup: UserGroupType | undefined) => void;
  onChangePersonTeam: (team: TeamType | undefined) => void;
  onSelectSchool: (values: SchoolType[]) => void;
  selectedUserGroup?: UserGroupType;
  allSchools: SchoolType[];
  initialSchools: SchoolType[];
  userGroups: UserGroupType[];
  teams: TeamType[];
  disableEmail?: boolean;
  disableRole?: boolean;
  showTeam?: boolean;
} & FlexProps &
  GridProps;

export const TeamMemberForm: React.FC<Props> = ({
  onChangeUserRole,
  onChangePersonTeam,
  onSelectSchool: handleSelectSchool,
  selectedUserGroup,
  allSchools,
  initialSchools,
  userGroups,
  teams,
  disableEmail = false,
  disableRole = false,
  ...props
}) => {
  const flags = useFlags(["current-applying-schools"]);
  const renderDropdownItem = (item: SchoolType) => {
    return (
      <Box>
        <span>{item.name}</span>
      </Box>
    );
  };

  const onUserRoleOptionSelected = (option: Option) => {
    onChangeUserRole(userGroups.find((group) => group.id === option.key));
  };

  const onPersonTeamOptionSelected = (option: Option) => {
    onChangePersonTeam(teams.find((team) => team.id === option.key));
  };

  const onPersonTeamCleared = () => {
    onChangePersonTeam(undefined);
  };

  return (
    <Grid templateColumns={"repeat(2, 1fr)"} gap={8}>
      <FormInput
        placeholder="e.g. John"
        w="100%"
        as={Field}
        type="text"
        id="first_name"
        name="first_name"
        label="First Name"
        isRequired
      />
      <FormInput
        placeholder="e.g. Smith"
        w="100%"
        as={Field}
        type="text"
        id="last_name"
        name="last_name"
        label="Last Name"
        isRequired
      />
      <FormInput
        placeholder="e.g. foo@example.com"
        w="100%"
        as={Field}
        type="email"
        id="email_address"
        name="email_address"
        label="Email Address"
        isRequired
        isDisabled={disableEmail}
      />
      <FormControl name="user_group_id" isRequired w="100%">
        <FormLabel htmlFor="user_group_id">Role</FormLabel>
        <SingleSelectInput
          onOptionSelected={onUserRoleOptionSelected}
          id="user_group_id"
          isRequired
          options={userGroups.map((group) => ({
            key: group.id,
            label: group.label,
          }))}
          isDisabled={disableRole}
        ></SingleSelectInput>
      </FormControl>
      <FormControl name="person_team.name">
        <FormLabel htmlFor="person_team.name">Team</FormLabel>
        <SingleSelectInput
          onOptionSelected={onPersonTeamOptionSelected}
          onSelectionClear={onPersonTeamCleared}
          id="person_team_id"
          options={teams.map((team) => ({
            key: team.id,
            label: team.name,
          }))}
        />
      </FormControl>
      <div></div>
      {(selectedUserGroup?.person_type === GQL.person_type_enum.schoolAdmin ||
        selectedUserGroup?.name === ELEVATED_SCHOOL_ADMIN_GROUP_NAME) && (
        <>
          <FormControl name="assigned_schools" w="100%">
            <FormLabel htmlFor="assigned_schools">Assigned to:</FormLabel>
            <MultiSelectInput<SchoolType>
              size="md"
              options={allSchools || []}
              initialValues={initialSchools || []}
              onChange={handleSelectSchool}
              labelAttr="name"
              renderDropdownItem={renderDropdownItem}
            />
          </FormControl>
          <div></div>
          {flags["current-applying-schools"].enabled &&
            selectedUserGroup?.name === ELEVATED_SCHOOL_ADMIN_GROUP_NAME && (
              <FormControl name="access_to">
                <FormLabel htmlFor="access_to" marginBottom="1rem">
                  Access to:
                </FormLabel>
                <HStack>
                  <InputSwitch name="enable_attending_schools" />
                  <VStack alignItems="start" gap={0} marginLeft="0.25rem">
                    <FormLabel htmlFor="assigned_schools" marginBottom={0}>
                      Current Students
                    </FormLabel>
                    <FormHelperText marginTop="0.25rem" whiteSpace={"nowrap"}>
                      This member can see students whose current school matches
                      the one the member is assigned to
                    </FormHelperText>
                  </VStack>
                </HStack>
                <HStack marginTop="1rem">
                  <InputSwitch name="enable_applying_schools" />
                  <VStack alignItems="start" gap={0} marginLeft="0.25rem">
                    <FormLabel htmlFor="assigned_schools" marginBottom={0}>
                      Applying Students
                    </FormLabel>
                    <FormHelperText marginTop="0.25rem" whiteSpace={"nowrap"}>
                      This member can see students applying to the school the
                      member is assigned to
                    </FormHelperText>
                  </VStack>
                </HStack>
              </FormControl>
            )}
        </>
      )}
    </Grid>
  );
};
