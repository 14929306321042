import { ApolloError } from "@apollo/client";
import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { concat, identity } from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { StickyBottom } from "src/components/Layout/StickyBottom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import {
  PolicyManagementError,
  usePolicyManagement,
} from "src/hooks/usePolicyManagement";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useUser from "src/hooks/useUser";
import { TeamForm } from "src/scenes/orgAdmin/components/Forms/TeamForm";
import { MemberType, TeamFormType } from "src/schemas/Team";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { ASSIGN_MEMBERS_TO_TEAM, EDIT_TEAM } from "./graphql/mutations";
import { GET_AVAILABLE_MEMBERS, GET_TEAM } from "./graphql/queries";

function handleError(error: Error | ApolloError): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  return <GenericError />;
}

type EditTeamFormData = {
  teamId: string;
  team: TeamFormType;
  initialMembers: MemberType[];
  availableMembers: MemberType[];
  initialPolicy: string;
};

type EditTeamContainerProps = {
  data: EditTeamFormData;
  teamId: string;
};

const EditTeamContainer: FC<EditTeamContainerProps> = ({ data, teamId }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [policy, setPolicy] = useState(data.initialPolicy);
  const [selectedMembers, setSelectedMembers] = useState<MemberType[]>(
    data.initialMembers
  );
  const organization = useOrganization();
  const organizationId = organization.map((org) => org.id).withDefault("");
  const user = useUser();

  const { availableMembers, initialMembers, team, initialPolicy } = data;

  const [editTeam] = useRemoteDataMutation<GQL.EditTeam, GQL.EditTeamVariables>(
    EDIT_TEAM
  );

  const [assignMembersToTeam] = useRemoteDataMutation<
    GQL.AssignMembersToTeam,
    GQL.AssignMembersToTeamVariables
  >(ASSIGN_MEMBERS_TO_TEAM);

  const { storePolicy } = usePolicyManagement({
    type: "DEFAULT",
    organizationId,
  });

  const handleSubmit = useCallback(
    async (value: TeamFormType) => {
      setSubmitting(true);
      try {
        if (!organization.hasData()) throw Error("Invalid organization.");

        const editTeamPayload: GQL.EditTeamVariables = {
          name: value.name,
          teamId,
        };
        await editTeam({
          variables: editTeamPayload,
        });

        const assignMembersPayload = selectedMembers.map((member) => ({
          team_id: teamId,
          person_id: member.id,
        }));

        const assignMembersResponse = await assignMembersToTeam({
          variables: {
            teamId,
            input: assignMembersPayload,
          },
        });

        const key = `organizations/${organization.data.id}/teams/${teamId}.cedar`;
        if (user.status === "ok" && user.data.role === HasuraRole.ADMIN) {
          // for now only avela admin can update policy
          await storePolicy(key, policy);
        }

        if (
          assignMembersResponse.data?.insert_person_team?.affected_rows !==
          selectedMembers.length
        ) {
          throw Error("Failed to assign members to team.");
        }

        toast({
          id: "create-team",
          title: "Team updated",
          isClosable: true,
          status: "info",
        });

        navigate(Url.OrgAdmin.Team.index(organization.data));
      } catch (err) {
        const description = (): React.ReactNode => {
          if (err instanceof PolicyManagementError) {
            return err.message;
          }
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "Team already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to create team.";
            }
          }
          return "Check your network and try again.";
        };
        toast({
          id: "create-team",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [
      organization,
      teamId,
      editTeam,
      selectedMembers,
      assignMembersToTeam,
      user,
      toast,
      navigate,
      storePolicy,
      policy,
    ]
  );

  return (
    <>
      <Breadcrumb
        items={breadcrumb.team.getBreadcrumbsForEditTeam(organization, teamId)}
        mb={8}
      />

      <Formik<TeamFormType> initialValues={team} onSubmit={handleSubmit}>
        {() => {
          return (
            <Flex as={Form} direction="column" gap={6} height="100%">
              <Heading as="h1" fontSize="2xl" fontWeight="600">
                Edit team
              </Heading>
              <TeamForm
                onSelectMember={setSelectedMembers}
                allTeamMembers={availableMembers}
                initialMembers={initialMembers}
                initialPolicy={initialPolicy}
                onChangePolicy={setPolicy}
              />
              <Spacer />
              <StickyBottom gap={6} justifyContent="space-between">
                <Button
                  as={NavLink}
                  to={organization
                    .map((org) => Url.OrgAdmin.Team.index(org))
                    .withDefault("#")}
                  isLoading={organization.isLoading()}
                  colorScheme="gray"
                  variant="outline"
                >
                  Back
                </Button>
                <Button type="submit" isLoading={submitting}>
                  Save team
                </Button>
              </StickyBottom>
            </Flex>
          );
        }}
      </Formik>
    </>
  );
};

export const EditTeam: FC<{}> = () => {
  const { id = "" } = useParams();
  const organization = useOrganization();
  const organizationId = organization.map((org) => org.id).withDefault("");
  const user = useUser();

  const { remoteData: teamData } = useRemoteDataQuery<
    GQL.GetTeam,
    GQL.GetTeamVariables
  >(GET_TEAM, {
    variables: {
      teamID: id,
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: availableMembersData } = useRemoteDataQuery<
    GQL.GetAvailableMembers,
    GQL.GetAvailableMembersVariables
  >(GET_AVAILABLE_MEMBERS, {
    variables: {
      organizationId,
    },
    fetchPolicy: "no-cache",
  });

  const { policy: policyRemoteData } = usePolicyManagement({
    type: "GET_POLICY",
    key: `organizations/${organizationId}/teams/${id}.cedar`,
    organizationId,
    skip: user.status === "ok" && user.data.role !== HasuraRole.ADMIN,
  });

  const remoteData: RD.RemoteData<ApolloError | Error, EditTeamFormData> =
    useMemo(
      () =>
        RD.toTuple3(teamData, availableMembersData, policyRemoteData)
          .mapError<ApolloError | Error>(identity)
          .andThen(([teamData, availableMembers, initialPolicy]) => {
            const team = teamData.team[0];

            if (!team || !team.id) {
              return RD.failure(
                new ApolloError({ errorMessage: "Unable to load team data" })
              );
            }
            const initialMembers = team.team_members.map(
              (member) => member.person
            );
            const availableMembersPlusInitial = concat(
              initialMembers,
              availableMembers.person
            ) as MemberType[];

            return RD.success({
              teamId: team.id,
              team: {
                name: team.name,
              },
              initialMembers,
              availableMembers: availableMembersPlusInitial,
              initialPolicy,
            });
          }),
      [teamData, availableMembersData, policyRemoteData]
    );

  return (
    <RemoteDataView
      remoteData={remoteData}
      error={handleError}
      loading={<Loading />}
    >
      {(data) => {
        return (
          <EditTeamContainer
            data={data}
            teamId={data.teamId}
          ></EditTeamContainer>
        );
      }}
    </RemoteDataView>
  );
};
