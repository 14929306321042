import * as RD from "src/types/remoteData";
import { useOrgConfig } from "./useOrgConfig";
import * as OrgConfig from "@avela/organization-config-sdk";

export function useLoginMethods(
  pathType: "admin" | "parent"
): RD.RemoteData<unknown, OrgConfig.Login.LoginMethod[]> {
  return useOrgConfig("Login").map((config) =>
    pathType === "admin" ? config.admin : config.parent
  );
}
