import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { ArrayHelpers, FieldArray, useField, useFormikContext } from "formik";
import { FunctionComponent } from "react";
import { AddressForm } from "src/scenes/orgAdmin/components/Forms/AddressForm";
import { AccountLookupStep, StepProps } from "./Step";
import { EMPTY_ADDRESS, FormikAccountLookup } from "./helpers";

const MAX_NUMBER_OF_ADDRESSES = 3;

export const EnterAddressStep: FunctionComponent<StepProps> = (props) => {
  const { goToNext } = props;
  const { values } = useFormikContext<FormikAccountLookup>();
  const numberOfAddresses = values.addresses.length;
  const addAddressIsDisabled = numberOfAddresses >= MAX_NUMBER_OF_ADDRESSES;
  const [, { error, touched }, helpers] = useField("addresses");

  return (
    <AccountLookupStep
      heading="Enter one or more addresses"
      description="Unsure which address? No problem. You can enter up to three separate addresses."
    >
      <FieldArray name="addresses">
        {(arrayHelpers) => {
          return (
            <Stack>
              {values.addresses.map((address, index) => {
                return (
                  <CompositeAddressField
                    key={index}
                    addressIndex={index}
                    numberOfAddresses={numberOfAddresses}
                    arrayTouched={touched}
                    arrayHelpers={arrayHelpers}
                  />
                );
              })}

              <Button
                isDisabled={addAddressIsDisabled}
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  arrayHelpers.push(EMPTY_ADDRESS);
                }}
              >
                Add another address
              </Button>
            </Stack>
          );
        }}
      </FieldArray>

      <Button
        type="submit"
        marginTop={4}
        onClick={() => {
          helpers.setTouched(true);

          if (touched && error === undefined) {
            goToNext();
          }
        }}
      >
        Next step
      </Button>
    </AccountLookupStep>
  );
};

type CompositeAddressFieldProps = {
  addressIndex: number;
  numberOfAddresses: number;
  arrayTouched: boolean;
  arrayHelpers: ArrayHelpers;
};

const CompositeAddressField: FunctionComponent<CompositeAddressFieldProps> = (
  props
) => {
  const { addressIndex, numberOfAddresses, arrayTouched, arrayHelpers } = props;
  const fieldName = `addresses.${addressIndex}`;
  const [, { error }] = useField(fieldName);
  const showErrorMessage = error && arrayTouched;

  return (
    <Stack as={FormControl} isInvalid={error !== undefined} gap={0}>
      <AddressForm isRequired={true} fieldPrefix={fieldName} autoFocus />
      {numberOfAddresses > 1 && (
        <Button
          marginBottom={4}
          variant="outline"
          size="sm"
          colorScheme="gray"
          onClick={() => {
            arrayHelpers.remove(addressIndex);
          }}
        >
          Remove address
        </Button>
      )}
      {showErrorMessage && (
        <FormErrorMessage marginTop={0} marginBottom={6}>
          Address {addressIndex + 1} is required
        </FormErrorMessage>
      )}
      <Divider marginBottom={4} />
    </Stack>
  );
};
