import { CardAlertProps } from "src/components/Layout/CardWithAlert";
import { maybePluralize } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";

export function canAcceptApplicationOffer(
  applicationOffer: { status: GQL.offer_status_enum } | null
): boolean {
  return applicationOffer?.status === GQL.offer_status_enum.Offered;
}

export function canDeclineApplicationOffer(
  applicationOffer: { status: GQL.offer_status_enum } | null,
  organization: Pick<GQL.Organization, "path">
): boolean {
  if (!applicationOffer) {
    return false;
  }

  const { status } = applicationOffer;
  const isNolaps = organization.path === "nolaps";

  return (
    (status === GQL.offer_status_enum.Offered ||
      status === GQL.offer_status_enum.Accepted) &&
    !isNolaps
  );
}

export function canWithdrawApplication(
  applicationWaitlist: {
    status: GQL.waitlist_status_enum;
  } | null
): boolean {
  return applicationWaitlist?.status === GQL.waitlist_status_enum.Waitlisted;
}

export function shouldShowApplicationSchoolRankOptionsMenu(
  applicationOffer: { status: GQL.offer_status_enum } | null,
  applicationWaitlist: { status: GQL.waitlist_status_enum } | null,
  organization: Pick<GQL.Organization, "path">
): boolean {
  return (
    canAcceptApplicationOffer(applicationOffer) ||
    canDeclineApplicationOffer(applicationOffer, organization) ||
    canWithdrawApplication(applicationWaitlist)
  );
}

type OfferAlertProps = {
  formId: string;
  offerCount: number;
  studentName: string;
  organization: RemoteData<unknown, { path: string }>;
};

export function getOfferAlert(props: OfferAlertProps): CardAlertProps {
  const { formId, offerCount, studentName, organization } = props;

  const offerOrOffers = maybePluralize(offerCount, "offer");
  const pluralizeOfferCount = offerCount === 1 ? "an" : `${offerCount}`;

  return {
    content: {
      description: `🎉 ${studentName} has received ${pluralizeOfferCount} ${offerOrOffers}!`,
      button: {
        type: "link",
        label: `Respond to ${offerOrOffers}`,
        url: organization
          .map((org) => Url.Parent.offers(org, formId))
          .withDefault("#"),
      },
    },
    status: "success",
  };
}

type AssignedForm = {
  id: uuid;
  form_template: {
    id: uuid;
    name: string;
  };
  person_id: uuid;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
};
type Form = {
  id: uuid;
};
export function getAssignedForms(
  form: Form,
  assignedForms: AssignedForm[]
): AssignedForm[] {
  return assignedForms.filter((af) => af.previous_form_id === form.id);
}
