import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { TAG_NAME_LIMIT } from "src/constants";
import { ForwardRefInputControl } from "src/services/formikExtra";

type NameInputProps = {
  error?: string;
  clearError?: () => void;
  label: string;
};
export const NameInput = React.forwardRef<HTMLInputElement, NameInputProps>(
  ({ error, clearError, label }, ref) => {
    const [field, meta, fieldHelper] = useField<string>("name");
    const [characterLimitCounter, setCharacterLimitCounter] = React.useState(
      TAG_NAME_LIMIT - (field.value?.trim().length ?? 0)
    );
    React.useEffect(() => {
      if (error) {
        fieldHelper.setError(error);
        fieldHelper.setTouched(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    React.useEffect(() => {
      // clear error when meta.value changed and we have error
      if (error && clearError && characterLimitCounter >= 0) {
        clearError();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.value]);

    React.useEffect(() => {
      const counter = TAG_NAME_LIMIT - (field.value?.trim().length ?? 0);
      setCharacterLimitCounter(counter);
      if (counter < 0) {
        fieldHelper.setTouched(true);
        fieldHelper.setError("Limit reached");
      }
    }, [field.value, fieldHelper]);

    const hasCharacterLimitError = characterLimitCounter < 0;

    return (
      <Stack as={FormControl} spacing="3">
        <Heading as={FormLabel} htmlFor="name" size="md">
          {label}
        </Heading>
        <ForwardRefInputControl
          id="name"
          name="name"
          ref={ref}
          inputProps={{ type: "text" }}
          errorMessageProps={{
            display: hasCharacterLimitError ? "none" : undefined,
          }}
        />
        {(!meta.error || hasCharacterLimitError) && (
          <FormHelperText
            _notFirst={{ marginTop: "2" }}
            color={hasCharacterLimitError ? "red.500" : undefined}
          >
            {hasCharacterLimitError ? "Limit reached " : ""}
            {characterLimitCounter}
          </FormHelperText>
        )}
      </Stack>
    );
  }
);
