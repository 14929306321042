import { Button, Icon } from "@chakra-ui/react";
import { RiChat2Line } from "react-icons/ri";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useSendMessage } from "src/hooks/useSendMessage";
import { SchoolFormId } from "../types";
import { useGetMessageRecipients } from "src/hooks/useGetMessageRecipients";

interface SendMessageButtonProps {
  fetchSelectedFormKeys: () => Promise<SchoolFormId[]>;
}

export const SendMessageButton: React.FC<SendMessageButtonProps> = ({
  fetchSelectedFormKeys,
}) => {
  const { selectedNavFormTemplate } = useFormTemplates();
  const { getApplicantAndRecipientsForForms } = useGetMessageRecipients();
  const { handleSendMessage } = useSendMessage(selectedNavFormTemplate?.id);

  const onSendMessageClick = async () => {
    const formIdsList = (await fetchSelectedFormKeys()).map(
      (key) => key.formId
    );
    const { recipients, applicants } = await getApplicantAndRecipientsForForms(
      formIdsList
    );

    handleSendMessage(recipients, applicants, formIdsList);
  };

  return (
    <Button
      leftIcon={<Icon as={RiChat2Line} />}
      onClick={onSendMessageClick}
      size="sm"
      variant="banner"
    >
      Send message
    </Button>
  );
};
