import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { AddressQuestionForm } from "src/components/Form/QuestionForm/Edit/AddressQuestion";
import { AnswersOptions } from "src/components/Form/QuestionForm/Edit/AnswersOptions";
import { GradeQuestionForm } from "src/components/Form/QuestionForm/Edit/GradeQuestion";
import { QuestionTitle } from "src/components/Form/QuestionForm/Edit/QuestionTitle";
import { QuestionType } from "src/components/Form/QuestionForm/Edit/QuestionType";
import { Verification } from "src/components/Form/QuestionForm/Edit/Verification";
import {
  createQuestion,
  FormType,
  getQuestionTypeFormValue,
  toEmptyFormValues,
} from "src/components/Form/QuestionForm/formik";
import { ConfigureQuestion } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureQuestion";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { useFormValidation } from "src/components/Form/QuestionForm/useFormValidation";
import { Card } from "src/components/Layout/Card";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { PermissionLevel } from "../Edit/PermissionLevel";
import { QuestionKey } from "../Edit/QuestionKey";
import { QuestionLink } from "../Edit/QuestionLink";

export type QuestionFormNewProps = {
  sectionType: AF.SectionType;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  onCancelButtonClick: () => void;
  onUpdate: (question: Draft.NewQuestion) => Promise<void>;
  newQuestionId: uuid;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
  otherQuestions: readonly Draft.Question[];
};
export function QuestionFormNew({
  sectionType,
  onCancelButtonClick,
  onUpdate,
  verificationOptions,
  schools,
  newQuestionId,
  gradesConfig,
  otherQuestions,
}: QuestionFormNewProps) {
  const initialValues: FormType = toEmptyFormValues(newQuestionId);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const toast = useAvelaToast();

  const validate = useFormValidation({ verificationOptions });

  const onSubmit = React.useCallback(
    async (values: FormType) => {
      setIsSubmitting(true);
      try {
        await onUpdate(
          createQuestion(newQuestionId, values, verificationOptions)
        );
      } catch (err: unknown) {
        console.error(err);
        toast.error({
          title: "Oops! Something went wrong",
          containerStyle: { marginBottom: "7rem" },
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [newQuestionId, onUpdate, toast, verificationOptions]
  );

  return (
    <Card display="flex" flexDirection="column" showBorder gap="3" padding={6}>
      <Formik<FormType>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {() => (
          <QuestionFormNewInternal
            newQuestionId={newQuestionId}
            verificationOptions={verificationOptions}
            sectionType={sectionType}
            schools={schools}
            onCancelButtonClick={onCancelButtonClick}
            isSubmitting={isSubmitting}
            gradesConfig={gradesConfig}
            otherQuestions={otherQuestions}
          />
        )}
      </Formik>
    </Card>
  );
}

type QuestionFormNewInternalProps = {
  newQuestionId: uuid;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  sectionType: AF.SectionType;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  onCancelButtonClick: () => void;
  isSubmitting: boolean;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
  otherQuestions: readonly Draft.Question[];
};
const QuestionFormNewInternal: React.FC<QuestionFormNewInternalProps> = ({
  newQuestionId,
  verificationOptions,
  sectionType,
  schools,
  onCancelButtonClick,
  isSubmitting,
  gradesConfig,
  otherQuestions,
}) => {
  const formik = useFormikContext<FormType>();
  const questionTypeValue = getQuestionTypeFormValue(
    newQuestionId,
    formik.values
  );

  let questionForm;
  switch (questionTypeValue) {
    case AF.GradesType:
      questionForm = (
        <GradeQuestionForm
          questionId={newQuestionId}
          gradesConfig={gradesConfig}
        />
      );
      break;

    case AF.AddressType:
      questionForm = (
        <AddressQuestionForm
          questionId={newQuestionId}
          verificationOptions={verificationOptions ?? []}
          schools={schools}
          sectionType={sectionType}
          otherQuestions={otherQuestions}
        />
      );
      break;

    default:
      questionForm = (
        <>
          <Flex direction="row" gap="4">
            <ConfigureQuestion
              questionId={newQuestionId}
              questionType={questionTypeValue}
            />
          </Flex>
          <Flex direction="column" flexGrow="1" gap="3">
            <Verification
              questionId={newQuestionId}
              verificationOptions={verificationOptions ?? []}
            />
            <QuestionLink questionId={newQuestionId} />
            <PermissionLevel questionId={newQuestionId} />
            <AnswersOptions
              sectionType={sectionType}
              questionId={newQuestionId}
              schools={schools}
              verificationOptions={verificationOptions}
              question={undefined}
            />
          </Flex>
        </>
      );
      break;
  }

  return (
    <Flex as={Form} direction="column" gap="6">
      <QuestionTitle questionId={newQuestionId} />
      <QuestionKey questionId={newQuestionId} />
      <QuestionType isDisabled={false} questionId={newQuestionId} />
      {questionForm}

      <Flex gap="3" justifyContent="right" paddingY="3">
        <Button
          variant="ghost"
          colorScheme="gray"
          onClick={onCancelButtonClick}
        >
          Cancel
        </Button>
        <Button type="submit" isLoading={isSubmitting}>
          Add question
        </Button>
      </Flex>
    </Flex>
  );
};
