import React from "react";
import { ReactComponent as AssignedFormInstructionSvg } from "src/images/messageImageType/AssignedFormInstruction.svg";
import * as GQL from "src/types/graphql";

type Props = { image: GQL.message_image_type_enum };
export const HintImage: React.FC<Props> = ({ image }) => {
  switch (image) {
    case GQL.message_image_type_enum.AssignedFormInstruction:
      return <AssignedFormInstructionSvg width="100%" height="auto" />;

    default:
      const _exhaustiveCheck: never = image;
      console.error("Unhandled image type: " + _exhaustiveCheck);
      return null;
  }
};
