import { useToast } from "@chakra-ui/react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { formatAttendingSchool } from "src/services/person";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { BulkUploadInput } from "../Inputs/BulkUploadInput";
import { UPLOAD_APPLICANT_ATTENDING_SCHOOLS } from "../graphql/mutations";

const AttendingSchool = z.object({
  person_id: z.string(),
  enrollment_period_id: z.string(),
  attendance_status: z
    .string()
    .transform((item) => item as GQL.attendance_status_type_enum)
    .nullish(),
  school_id: z.string().nullish(),
  description: z.string().nullish(),
});

type AttendingSchoolRow = z.infer<typeof AttendingSchool>;

function isValidRow(data: unknown): data is AttendingSchoolRow {
  const { success } = AttendingSchool.safeParse(data);
  return success;
}

function isValid(data: unknown): data is AttendingSchoolRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

export const UploadAttendingSchools: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const toast = useToast();

  const [UploadAttendingSchools] = useRemoteDataMutation<
    GQL.UploadApplicantAttendingSchools,
    GQL.UploadApplicantAttendingSchoolsVariables
  >(UPLOAD_APPLICANT_ATTENDING_SCHOOLS);

  const handleUpload = async (raw: unknown[]) => {
    let data: AttendingSchoolRow[];
    try {
      data = raw
        .map((row) => AttendingSchool.parse(row))
        .filter((row) => row.person_id && row.enrollment_period_id)
        .map((row) => formatAttendingSchool(row));
    } catch (e) {
      toast({
        id: "parsing-csv",
        title: "Error parsing CSV",
        description: `We were unable to parse the CSV: ${e}`,
        isClosable: true,
        status: "error",
      });
      return;
    }

    if (data.length > 0) {
      try {
        await UploadAttendingSchools({
          variables: {
            data: data,
          },
        });
        toast({
          id: "upload-attending-schools-success",
          title: "Attending schools uploaded",
          isClosable: true,
          status: "success",
        });
      } catch (err: unknown) {
        console.error(JSON.stringify(err));
        toast({
          id: "upload-attending-schools-error",
          title: "Error uploading attending schools",
          description: "Error: invalid ids",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      {children}
    </BulkUploadInput>
  );
};
