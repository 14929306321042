import { gql } from "@apollo/client";
import { SIMPLE_SCHOOL_FRAGMENT } from "src/operations/fragments/school";

export const SAVE_FORM_SCHOOLS_RANK_ADMIN = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}

  mutation SaveFormSchoolsRankAdmin(
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
    $deleted_school_ranks: form_school_rank_bool_exp!
    $upserted_school_ranks: [form_school_rank_insert_input!]!
  ) {
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    delete_form_school_rank(where: $deleted_school_ranks) {
      affected_rows
    }
    insert_form_school_rank(
      objects: $upserted_school_ranks
      on_conflict: {
        constraint: form_school_rank_form_id_schools_ranking_sect_key
        update_columns: rank
      }
    ) {
      returning {
        id
      }
    }
  }
`;
