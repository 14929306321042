import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { AttendanceTypes, SearchAndFilterTypes } from "src/constants";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";

export type TableHeaderProps = {
  count: number;
  setAttendance: (data: string) => void;
};

const labels = {
  [AttendanceTypes.Applying]: "Applying students",
  [AttendanceTypes.Attending]: "Current students",
} as const;

export const TableHeader: React.FC<TableHeaderProps> = ({
  count,
  setAttendance,
}) => {
  const flags = useFlags(["current-applying-schools"]);

  return (
    <HStack minWidth="100%">
      <Text flex="1" textTransform="uppercase" fontSize="xs" fontWeight="bold">
        Items: {count}
      </Text>

      {flags["current-applying-schools"].enabled && (
        <AttendanceSelector setAttendance={setAttendance} />
      )}
    </HStack>
  );
};

const AttendanceSelector: React.FC<{
  setAttendance: (data: string) => void;
}> = ({ setAttendance }) => {
  const { isElevatedSchoolAdmin, hasApplyingSchools, hasAttendingSchools } =
    useSchoolAdmin();

  const [searchParams] = useSearchParams();
  const attendance = searchParams.get(SearchAndFilterTypes.Attendance) || "";

  const options = [
    ...(hasApplyingSchools ? [AttendanceTypes.Applying] : []),
    ...(hasAttendingSchools ? [AttendanceTypes.Attending] : []),
  ];
  const isDisabled = options.length < 2;

  if (!isElevatedSchoolAdmin || !options.length) return null;

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<RiArrowDropDownLine />}
        size="sm"
        variant="banner"
        isDisabled={isDisabled}
      >
        {labels[attendance as AttendanceTypes] ?? "Select student filter"}
      </MenuButton>
      <MenuList>
        {options.map((opt) => (
          <MenuItem key={opt} value={opt} onClick={() => setAttendance(opt)}>
            {labels[opt]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
