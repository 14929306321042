import { z } from "zod";
import { STATUS_ACTIVE } from "../constants";
import { asRequiredField, nullableWithDefault } from "../services/zodExtra";
import { AddressSchema, AddressValidator } from "./Address";

export const emptySchool: SchoolFormType = {
  name: "",
  status: STATUS_ACTIVE,
  street_address: "",
  street_address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
  reference_id: "",
};

export const SchoolSchema = z
  .object({
    name: nullableWithDefault(z.string(), ""),
    status: nullableWithDefault(z.string(), STATUS_ACTIVE),
    reference_id: nullableWithDefault(z.string().trim(), ""),
  })
  .merge(AddressSchema);

export const SchoolValidator = asRequiredField(SchoolSchema, "name").merge(
  AddressValidator
);

export type SchoolFormType = z.infer<typeof SchoolSchema>;
