import { gql } from "@apollo/client";

export const GET_ENROLLMENT_SCHOOLS_AND_CONFIGS = gql`
  query GetEnrollmentSchoolsAndConfigs(
    $organization_id: uuid
    $order_by: school_order_by!
  ) {
    school(
      where: {
        organization_id: { _eq: $organization_id }
        status: { _eq: "Active" }
      }
      order_by: [$order_by]
    ) {
      grades {
        id
        enrollment_period_id
        grade_config {
          id
          label
        }
      }
      name
      id
      status
    }
    grade_config(where: { organization_id: { _eq: $organization_id } }) {
      id
      label
      organization_id
      order
    }
    program_group(where: { organization_id: { _eq: $organization_id } }) {
      id
      name
      organization_id
      programs {
        id
        label
        order
      }
    }
  }
`;

export const GET_SCHOOLS_AND_GRADES_FOR_ENROLLMENT_PERIOD = gql`
  query GetSchoolsAndGradesForEnrollmentPeriod(
    $organization_id: uuid
    $enrollment_period_id: uuid
  ) {
    school(
      where: { organization_id: { _eq: $organization_id } }
      order_by: { name: asc }
    ) {
      name
      id
      status
      grades(
        where: { enrollment_period_id: { _eq: $enrollment_period_id } }
        order_by: { grade_config: { order: asc } }
      ) {
        id
        grade_config {
          id
          label
        }
      }
    }
  }
`;

export const GET_GRADE_PROGRAMS_BY_ENROLLMENT_SCHOOL = gql`
  query GetGradeProgramsByEnrollmentSchool(
    $enrollment_period_id: uuid!
    $school_id: uuid!
  ) {
    school_by_pk(id: $school_id) {
      grades(where: { enrollment_period_id: { _eq: $enrollment_period_id } }) {
        id
        enrollment_period_id
        grade_config {
          id
          label
          order
        }
      }
      name
      id
      status
    }
  }
`;

export const GET_GRADES_GROUPS_BY_ENROLLMENT_SCHOOL = gql`
  query GetGradesGroupsByEnrollmentSchool(
    $enrollment_period_id: uuid!
    $school_id: uuid!
  ) {
    grades_group(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        school_id: { _eq: $school_id }
      }
    ) {
      id
      enrollment_period_id
      school_id
      grade_programs {
        id
        enrollment_period_id
        school_id
        grade_config {
          id
          label
          order
        }
        program {
          id
          label
          order
        }
      }
    }
  }
`;
