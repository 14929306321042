import { useAuth0 } from "@auth0/auth0-react";
import { matchPath } from "react-router-dom";
import { AUTH, ORGANIZATION } from "./constants";

export * as Admin from "./Admin";
export * as OrgAdmin from "./OrgAdmin";
export * as Parent from "./Parent";

export const AuthCallback = {
  path: `/${AUTH}`,
  orgPath: `/${ORGANIZATION}/${AUTH}`,
};

export const logoutPath = "/logout";
export const logout = (returnTo: string | undefined): string => {
  return returnTo !== undefined ? `${logoutPath}?path=${returnTo}` : logoutPath;
};

export const useLogout = (returnTo: string | undefined) => {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return `${returnTo}`;
  }

  return logout(returnTo);
};

export const AvelaMarketingPage = {
  URL: "https://avela.org/",
};

export const verifyEmailPath = `/${ORGANIZATION}/verify-email`;
export const verifyEmail = (org: string) => {
  return `/${org}/verify-email`;
};

export const emailVerificationPath = `/${ORGANIZATION}/email-verification`;
export const emailVerification = emailVerificationPath;

export const redirectPath = "/redirect";
export const redirect = redirectPath;

export const isParentPortalPath = (path: string): boolean => {
  const adminPath = matchPath(
    { path: "/admin", end: false, caseSensitive: false },
    path
  );
  const orgAdminPath = matchPath(
    { path: `/${ORGANIZATION}/admin`, end: false, caseSensitive: false },
    path
  );
  return adminPath === null && orgAdminPath === null;
};
