import { gql } from "@apollo/client";

export const PERSON_ANSWER_BANK_FRAGMENT = gql`
  fragment PersonAnswerBankFragment on person_answer_bank {
    id
    person_answers(
      order_by: { custom_question_type_field: { question: { order: asc } } }
    ) {
      id
      custom_question_type_field {
        question_id
        question {
          id
          type
          question
        }
      }
      answer
      person_answer_options {
        id
        form_question_option {
          id
          label
          value
        }
      }
    }
  }
`;
