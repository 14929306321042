import { Text, TextProps, Tooltip } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";

const isCutOff = <T extends HTMLElement>(node: T | null) =>
  node ? node.offsetWidth < node.scrollWidth : false;

const useIsTextCutOff = <T extends HTMLElement>(
  ref: React.RefObject<T>
): [boolean, () => void] => {
  const [isTextCutOff, setIsTextCutOff] = useState(isCutOff(ref.current));

  const refresh = useCallback(() => {
    setIsTextCutOff(isCutOff(ref.current));
  }, [ref]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [isTextCutOff, refresh];
};

interface Props {
  content: string;
  children?: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  hasArrow?: boolean;
}

export const TextWithOverflowTooltip: React.FC<Props & TextProps> = ({
  content,
  children,
  minWidth = "0px",
  maxWidth = "none",
  hasArrow = false,
  ...textProps
}) => {
  const labelRef = useRef<HTMLParagraphElement>(null);
  const [isTextCutOff, refresh] = useIsTextCutOff(labelRef);

  return (
    <Tooltip isDisabled={!isTextCutOff} label={content} hasArrow={hasArrow}>
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        minWidth={minWidth}
        maxWidth={maxWidth}
        onMouseEnter={refresh}
        ref={labelRef}
        {...textProps}
      >
        {children ?? content}
      </Text>
    </Tooltip>
  );
};
