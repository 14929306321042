import { FORM_DESCRIPTION_MAX_CHARS } from "src/constants";
import * as GQL from "src/types/graphql";
import { z } from "zod";

// Add new options on the "What should happen after this form?" dropdown here.
export enum WhatShouldHappenEnum {
  Submit = "submit",
  SubmitAndContinue = "submitAndContinue"
}

const WhatShouldHappenSchema = z
  .union([
    z.literal(WhatShouldHappenEnum.Submit),
    z.literal(WhatShouldHappenEnum.SubmitAndContinue)
  ])
  .optional();

const StatusOrSubstatusSchema = z
  .union([
    z.literal(GQL.form_status_enum.InProgress),
    z.literal(GQL.form_status_enum.Submitted),
    z.literal(GQL.form_status_enum.Verified),
    z.literal(GQL.form_status_enum.LotteryReady),
    z.literal(GQL.form_status_enum.Admissions),
    z.literal(GQL.waitlist_status_enum.Waitlisted),
    z.literal(GQL.waitlist_status_enum.Withdrawn),
    z.literal(GQL.offer_status_enum.Offered),
    z.literal(GQL.offer_status_enum.Accepted),
    z.literal(GQL.offer_status_enum.Declined),
    // Just to type checking
    z.literal(GQL.form_status_enum.Deleted),
    z.literal(GQL.form_status_enum.Cancelled),
    z.literal(GQL.form_status_enum.Withdrawn),
    z.literal(GQL.waitlist_status_enum.Removed),
    z.literal(GQL.offer_status_enum.Revoked)
  ])
  .nullish()
  .optional();

export const FormTemplateStatusRuleSchema = z
  .object({
    id: z.string().optional(),
    type: z.literal(GQL.rule_type_enum.StatusRule).optional(),
    whatShouldHappen: WhatShouldHappenSchema,
    nextTemplateId: z.string().optional(),
    statusOrSubstatus: StatusOrSubstatusSchema
  })
  .nullish();

export const FormTemplateRuleSchema = z
  .object({
    id: z.string().optional(),
    // TODO FUTURE: Change this to be a union of all the different types of rules and discrimate by type.
    rules: z.array(FormTemplateStatusRuleSchema)
  })
  .nullish()
  .optional();
export const FormTemplateSettingsSchema = z.object({
  name: z.string().min(1, "Form name is required."),
  key: z.string().min(1, "Form key is required."),
  description: z
    .string()
    .max(
      FORM_DESCRIPTION_MAX_CHARS,
      `Description must be ${FORM_DESCRIPTION_MAX_CHARS} characters or less.`
    ),
  openAt: z.string().optional(),
  closedAt: z.string().optional(),
  offerStartAt: z.string().optional(),
  reopenAt: z.string().optional(),
  lotteryAndOffersEnabled: z.boolean(),
  active: z.boolean(),
  locked: z.boolean(),
  formTemplateRule: FormTemplateRuleSchema
});

export type FormTemplateSettingsType = z.infer<
  typeof FormTemplateSettingsSchema
>;
export type FormTemplateStatusRuleType = z.infer<
  typeof FormTemplateStatusRuleSchema
>;
export type FormTemplateRuleType = z.infer<typeof FormTemplateRuleSchema>;
