import * as qs from "query-string";
import React from "react";
import { useLocation } from "react-router";

export function useQueryParams() {
  const { search } = useLocation();
  return qs.parse(search);
}

export type Form = {
  formId?: string;
  schoolIds?: string[];
};

export function useFormQueryParams(): Form {
  const formId = useFormIdQueryParams();
  const schoolIds = useSchoolIdsQueryParams();

  return React.useMemo(() => {
    if (formId) {
      return { formId };
    } else if (schoolIds) {
      return { schoolIds };
    }

    return {};
  }, [formId, schoolIds]);
}

function useSchoolIdsQueryParams(): string[] | undefined {
  const queryParams = useQueryParams();
  const schoolIds = queryParams["schoolId"];

  return React.useMemo(
    () =>
      typeof schoolIds === "string"
        ? schoolIds.split(",").map((id) => id.trim())
        : undefined,
    [schoolIds]
  );
}

function useFormIdQueryParams(): string | undefined {
  const queryParams = useQueryParams();
  const formId = queryParams["formId"];

  return React.useMemo(
    () => (typeof formId === "string" ? formId : undefined),
    [formId]
  );
}
