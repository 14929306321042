import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useEffect } from "react";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import {
  useFetchGradeIdsWithFormSchool,
  useFetchSeatsAvailable,
} from "../graphql/hooks";
import { GET_PROGRAM_GROUPS_BY_ORG } from "../graphql/queries";
import { FormSchoolStatus } from "../types";

type Confirmed = { result: "Confirmed"; programId: string | null };
type Cancelled = { result: "Cancelled" };
export type ConfirmationResult = Confirmed | Cancelled;

const CONFIRMATION_BODY_TEXT =
  "You will extend offers to the selected schools for families to either accept or decline. You can revoke offers anytime.";

export const useMakeOfferConfirmationDialog = (
  schoolForms: RD.RemoteData<unknown, FormSchoolStatus[]>
) => {
  const promiseRef = React.useRef<
    { resolve: (value: ConfirmationResult) => void } | undefined
  >();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [header, setHeader] = React.useState<string>("Make offers?");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [availablePrograms, setAvailablePrograms] = React.useState<
    GQL.GetProgramGroupsByOrg_program_group_programs[] | null
  >();
  const [programSelected, setProgramSelected] = React.useState<string | null>(
    null
  );
  const [schoolGradesOverCapacity, setSchoolGradesOverCapacity] =
    React.useState<{ school: string; grade: string }[]>();
  const [closedCapacityWarning, setClosedCapacityWarning] =
    React.useState(false);

  const organization = useOrganization();

  const { remoteData: programGroupData } = useRemoteDataQuery<
    GQL.GetProgramGroupsByOrg,
    GQL.GetProgramGroupsByOrgVariables
  >(GET_PROGRAM_GROUPS_BY_ORG, {
    variables: {
      organization_id: organization.map((org) => org.id).toNullable(),
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (programGroupData.hasData()) {
      const programs = programGroupData.data.program_group.flatMap(
        (group) => group.programs
      );
      const hasAvailablePrograms = programs && programs.length > 0;
      if (!hasAvailablePrograms) {
        setProgramSelected(null);
      }
      setAvailablePrograms(hasAvailablePrograms ? programs : null);
    }
  }, [programGroupData]);

  const confirm = React.useCallback(
    async (schoolForms: FormSchoolStatus[]): Promise<ConfirmationResult> => {
      setHeader(`Make ${schoolForms.length} offers?`);
      onOpen();
      return new Promise<ConfirmationResult>((resolve, reject) => {
        promiseRef.current = { resolve };
      });
    },
    [onOpen]
  );

  const onCancel = () => {
    onClose();
    if (promiseRef.current) promiseRef.current.resolve({ result: "Cancelled" });
    // reset state on closing the dialog
    setClosedCapacityWarning(false);
    if (programSelected) {
      setSchoolGradesOverCapacity(undefined);
      setProgramSelected(null);
    }
  };

  const onConfirm = () => {
    onClose();
    if (promiseRef.current)
      promiseRef.current.resolve({
        result: "Confirmed",
        programId: programSelected,
      });
  };

  const fetchGradeIdsWithFormSchool = useFetchGradeIdsWithFormSchool();
  const fetchSeatsAvailable = useFetchSeatsAvailable();
  const checkCapacitiesForProgram = React.useCallback(
    async (programId: string | null) => {
      try {
        if (!schoolForms.hasData()) {
          throw new Error("Invalid selected rows");
        }

        // TODO: This function is also called when making offers in `MakeOffer.tsx`
        // and error handling is handled there rather than here.
        // We might want to just do the error handling here instead.
        const formSchoolGrades = await fetchGradeIdsWithFormSchool(
          schoolForms.data,
          programId
        );
        const gradeIds = _.uniq(
          formSchoolGrades.map((result) => {
            if (!result.form_id || !result.school_id || !result.grade_id) {
              throw new Error("data");
            }
            return result.grade_id;
          })
        );
        const seatsAvailableByGrade = await fetchSeatsAvailable(gradeIds);
        const gradesOverCapacity = seatsAvailableByGrade
          .filter(
            (grade) =>
              formSchoolGrades.filter((result) => result.grade_id === grade.id)
                .length > (grade.seats_available ?? 0)
          )
          .map((gradeOverCapacity) => ({
            school: gradeOverCapacity.school.name,
            grade: gradeOverCapacity.grade_config.label,
          }));
        setSchoolGradesOverCapacity(gradesOverCapacity);
      } catch (err: any) {
        console.error(err);
        setSchoolGradesOverCapacity(undefined);
      }
    },
    [fetchGradeIdsWithFormSchool, fetchSeatsAvailable, schoolForms]
  );

  useEffect(() => {
    if (programSelected !== undefined && schoolForms.hasData())
      checkCapacitiesForProgram(programSelected);
  }, [
    checkCapacitiesForProgram,
    closedCapacityWarning,
    programSelected,
    schoolForms,
  ]);

  const renderSchoolGradesOverCapacity = () => {
    if (!schoolGradesOverCapacity) return;
    const content = "By making offers, you will be over capacity at";
    const schoolGradeStrings = schoolGradesOverCapacity.map(
      (schoolGrade) => schoolGrade.school + ", " + schoolGrade.grade
    );
    if (schoolGradeStrings.length === 1) {
      return content + " " + schoolGradeStrings[0] + ".";
    }
    return (
      <>
        <p>{content}</p>
        {schoolGradeStrings.map((str, idx) => (
          <p key={idx}>
            {str}
            {idx < schoolGradeStrings.length - 1 ? "," : "."}
          </p>
        ))}
      </>
    );
  };

  const confirmationDialog = (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      isCentered
    >
      <AlertDialogOverlay className={WEGLOT_APPLY_CLASS}>
        <AlertDialogContent marginX={4}>
          <AlertDialogHeader fontSize="lg">{header}</AlertDialogHeader>
          <AlertDialogBody fontSize="md">
            <VStack gap={1}>
              <Text>
                <Glossary>{CONFIRMATION_BODY_TEXT}</Glossary>
              </Text>
              {availablePrograms && availablePrograms.length > 0 && (
                <FormControl>
                  <FormLabel>Funding source</FormLabel>
                  <Select
                    name="program"
                    id="program"
                    isRequired={true}
                    placeholder="Select an option"
                    onChange={(event) => {
                      const target = event.target as HTMLSelectElement;
                      setProgramSelected(target.value);
                      setClosedCapacityWarning(false);
                    }}
                    value={programSelected ?? ""}
                  >
                    {availablePrograms.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {!closedCapacityWarning &&
                schoolGradesOverCapacity &&
                schoolGradesOverCapacity.length > 0 && (
                  <Alert status="warning" variant="subtle" borderRadius={6}>
                    <AlertIcon alignSelf="flex-start" />
                    <AlertDescription>
                      {renderSchoolGradesOverCapacity()}
                    </AlertDescription>
                    <CloseButton
                      alignSelf="flex-start"
                      position="relative"
                      right={-1}
                      top={-1}
                      onClick={() => {
                        setClosedCapacityWarning(true);
                      }}
                    />
                  </Alert>
                )}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              type="button"
              ref={cancelRef}
              colorScheme="gray"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button onClick={onConfirm} colorScheme="blue" ml={3}>
              Yes, make offers
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  return { confirm, confirmationDialog };
};
