import { Badge, Box, Heading, LinkBox, Text } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { Card } from "src/components/Layout/Card";
import { ParentCopyright } from "src/constants/strings";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as NoAppointmentsSvg } from "src/images/no-appointments.svg";
import * as GQL from "src/types/graphql";
import { ImportantDates } from "./ImportantDates";
import { StudentsWithForms } from "./StudentsWithForms";
import { useMemo } from "react";

type FormTemplateListItemProps = {
  enrollmentPeriod: GQL.GetImportantDatesByEnrollmentPeriod_enrollment_period;
};

export function ImportantDateListItem(props: FormTemplateListItemProps) {
  const { enrollmentPeriod } = props;
  const organization = useOrganization();

  const allFormsForEnrollmentPeriod = useMemo(() => {
    return enrollmentPeriod.form_templates.flatMap((formTemplate) => {
      return formTemplate.forms;
    });
  }, [enrollmentPeriod]);

  const formTemplateWithSchoolRank = useMemo(() => {
    return enrollmentPeriod.form_templates.find((formTemplate) => {
      return formTemplate.sections.length > 0;
    });
  }, [enrollmentPeriod.form_templates]);

  const enrollmentPeriodOpen = useMemo(() => {
    return enrollmentPeriod.form_templates.some((formTemplate) => {
      return formTemplate.active;
    });
  }, [enrollmentPeriod]);

  const descriptions = ParentCopyright.FormTemplateDescription(
    organization,
    formTemplateWithSchoolRank
  );

  const description = useMemo(() => {
    if (!enrollmentPeriodOpen) {
      return null;
    }

    return enrollmentPeriod.form_templates[0]?.forms.length === 0
      ? descriptions.NoForms
      : descriptions.WithForms;
  }, [enrollmentPeriodOpen, descriptions, enrollmentPeriod.form_templates]);

  const badge = useMemo(() => {
    if (enrollmentPeriodOpen) {
      return (
        <Badge colorScheme="green" variant="solid" verticalAlign="middle">
          Open
        </Badge>
      );
    } else {
      return (
        <Badge variant="solid" verticalAlign="middle">
          Closed
        </Badge>
      );
    }
  }, [enrollmentPeriodOpen]);

  const body = useMemo(() => {
    if (!enrollmentPeriodOpen) {
      return (
        <EmptyState
          heading="This enrollment period is closed"
          description="We are closed for new forms."
          Svg={NoAppointmentsSvg}
        />
      );
    }

    return <ImportantDates formTemplates={enrollmentPeriod.form_templates} />;
  }, [enrollmentPeriodOpen, enrollmentPeriod.form_templates]);

  return (
    <LinkBox as={Card} display="flex" flexDirection="column" gap={4}>
      <Box>
        <Box display="inline" verticalAlign="middle">
          <Heading
            as="h2"
            display="inline"
            flexGrow={0}
            fontSize="lg"
            fontWeight="600"
            marginInlineEnd={2}
            verticalAlign="middle"
          >
            {enrollmentPeriod.name}
          </Heading>

          {badge}
        </Box>

        {!!description && <Text marginBlockStart={1}>{description}</Text>}
      </Box>

      {body}
      {(allFormsForEnrollmentPeriod.length > 0 || enrollmentPeriodOpen) && (
        <StudentsWithForms forms={allFormsForEnrollmentPeriod} />
      )}
    </LinkBox>
  );
}
