import { Box, Button } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FormLayout } from "src/components/Layout/FormLayout";
import { HorizontallyBoundedContainer } from "src/components/Layout/HorizontallyBoundedContainer";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { RouterLink } from "src/components/Links/RouterLink";
import { Form, FormCustomQuestionPathParams } from "src/services/url/Parent";
import { Organization } from "src/types/graphql";
import { CustomQuestionData } from "./DataView";

type CustomQuestionPageLayoutProps = Pick<
  CustomQuestionData,
  "organization" | "customQuestion"
> & {
  formId: uuid;
};

export const CustomQuestionPageLayout: FunctionComponent<
  PropsWithChildren<CustomQuestionPageLayoutProps>
> = (props) => {
  const { organization, customQuestion, formId, children } = props;

  const { step = "", customQuestionId = "" } =
    useParams<FormCustomQuestionPathParams>();
  const parsedStep = parseInt(step, 10);
  return (
    <FormLayout
      buttons={
        <FooterButtons
          organization={organization}
          formId={formId}
          customQuestionId={customQuestionId}
          step={parsedStep}
        />
      }
      heading={`Select ${customQuestion.question.toLocaleLowerCase()}`}
      backLink={{
        url: Form.edit(organization, formId, parsedStep),
        label: "Back",
        state: { questionId: customQuestionId },
      }}
      width="100%"
    >
      <HorizontallyBoundedContainer
        display="flex"
        gap={5}
        flexDirection="column"
        flexGrow="1"
        width="100%"
      >
        <Box fontSize="sm">{children}</Box>
      </HorizontallyBoundedContainer>
    </FormLayout>
  );
};

type FooterButtonsProps = {
  organization: Organization;
  formId: uuid;
  customQuestionId: uuid;
  step: number;
};

const FooterButtons: FunctionComponent<FooterButtonsProps> = (props) => {
  const { organization, formId, customQuestionId, step } = props;

  return (
    <ParentFormsLayoutFooter justifyContent="space-between">
      <Button
        leftIcon={<FaArrowLeft />}
        colorScheme="gray"
        variant="ghost"
        as={RouterLink}
        to={Form.edit(organization, formId, step)}
        state={{ questionId: customQuestionId }}
      >
        Back
      </Button>
    </ParentFormsLayoutFooter>
  );
};
