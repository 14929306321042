import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { extractQuestionProps } from "src/scenes/parent/forms/components/Inputs/FormQuestion";
import {
  ClonedQuestion,
  EmailType,
  FreeTextType,
  PhoneNumberType,
  SingleSelectType,
  WithId,
} from "src/types/formTemplate";
import { Applicant } from "src/types/formTemplateFilters";
import { CloneIdsGroupedBySourceIds } from "./context/helpers";
import { AnswerBankRecordField } from "./list/schemas/AnswerBankField";

// AnswerBankRecordField[] > CustomQuestionAnswerByQuestionId (form answers)
export function transformAnswerBankFieldsToValues(
  fields: AnswerBankRecordField[]
): CustomQuestionAnswersByQuestionId {
  return fields.reduce<Record<uuid, string>>((accumulator, currentField) => {
    const { clonedQuestionId, questionType } = currentField;

    switch (questionType) {
      case FreeTextType:
      case EmailType:
      case PhoneNumberType:
        accumulator[clonedQuestionId] = currentField.answer;
        break;
      case SingleSelectType:
        accumulator[clonedQuestionId] =
          currentField.clonedQuestionSelectOptionId ?? "";
        break;
      default: {
        const _exhaustiveCheck: never = questionType;
        return _exhaustiveCheck;
      }
    }

    return accumulator;
  }, {});
}

// CustomQuestionAnswerByQuestionId (form answers) > AnswerBankRecordField[]
export function transformValuesIntoAnswerBankRecordFields(
  answersByQuestionId: CustomQuestionAnswersByQuestionId,
  nestedQuestions: ClonedQuestion<WithId>[],
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds
): AnswerBankRecordField[] {
  return nestedQuestions.map((clonedQuestion) => {
    const { id, question, type } = clonedQuestion;
    const field = Object.entries(sourceIdCloneIdMapping).find(
      ([_, value]) => value.clonedQuestionId === id
    );

    if (!field) {
      throw new Error(`Could not find field for ${id}`);
    }

    const commonProperties = {
      questionLabel: question,
      clonedQuestionId: id,
      customQuestionTypeFieldId: field[0],
    };

    switch (type) {
      case FreeTextType:
      case EmailType:
      case PhoneNumberType:
        return {
          ...commonProperties,
          questionType: type,
          answer: answersByQuestionId[id] ?? "",
        };
      case SingleSelectType:
        const selectOption =
          type === SingleSelectType
            ? clonedQuestion.options.find((option) => {
                return option.id === answersByQuestionId[id];
              })
            : undefined;

        return {
          ...commonProperties,
          questionType: type,
          clonedQuestionSelectOptionId: answersByQuestionId[id] ?? "",
          clonedQuestionSelectOptionLabel: selectOption?.label ?? "",
        };
      default: {
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
      }
    }
  });
}

// answer bank answers > form answers
export function mapBankAnswersToFormAnswers(
  answerBankValues: CustomQuestionAnswersByQuestionId,
  mapping: CloneIdsGroupedBySourceIds
): CustomQuestionAnswersByQuestionId {
  const mappedFormikValues = Object.entries(answerBankValues).reduce(
    (accumulator, currentAnswerBankValue) => {
      const [customQuestionTypeFieldId, answer] = currentAnswerBankValue;
      const clonedQuestion = mapping[customQuestionTypeFieldId];
      if (!clonedQuestion) {
        throw new Error(
          `Could not find cloned question mapping for sourceId ${customQuestionTypeFieldId}`
        );
      }
      const { clonedQuestionId, optionIdMapping } = clonedQuestion;
      const clonedOptionId = optionIdMapping?.[answer];
      const formAnswer = clonedOptionId ? clonedOptionId : answer;

      accumulator.set(clonedQuestionId, formAnswer);

      return accumulator;
    },
    new Map()
  );

  return Object.fromEntries(mappedFormikValues);
}

// form answers > answer bank answers
export function mapFormAnswersToBankAnswers(
  formAnswers: CustomQuestionAnswersByQuestionId,
  mapping: CloneIdsGroupedBySourceIds
): CustomQuestionAnswersByQuestionId {
  const answerBankAnswers = Object.entries(formAnswers).reduce(
    (accumulator, currentFormAnswer) => {
      const [formQuestionId, answer] = currentFormAnswer;
      const customQuestionTypeField = Object.entries(mapping).find(
        ([_, value]) => value.clonedQuestionId === formQuestionId
      );
      if (!customQuestionTypeField) {
        throw new Error(
          `Could not find custom question type field for cloneId ${formQuestionId}`
        );
      }
      const [fieldQuestionId, { optionIdMapping }] = customQuestionTypeField;
      const fieldOptionEntry = optionIdMapping
        ? Object.entries(optionIdMapping).find(
            ([_, clonedOptionId]) => clonedOptionId === answer
          )
        : undefined;
      const answerBankAnswer = fieldOptionEntry ? fieldOptionEntry[0] : answer;

      accumulator.set(fieldQuestionId, answerBankAnswer);

      return accumulator;
    },
    new Map()
  );

  return Object.fromEntries(answerBankAnswers);
}

// custom question type field > question props
type FormatAvelaQuestionAsProperties = {
  applicant: Applicant;
  formId: uuid;
  fieldQuestions: ClonedQuestion<WithId>[];
};
export function formatCustomQuestionTypeFieldAsProperties(
  props: FormatAvelaQuestionAsProperties
) {
  const { applicant, formId, fieldQuestions } = props;

  return fieldQuestions.map((question) => {
    return extractQuestionProps(question, formId, applicant, false);
  });
}
