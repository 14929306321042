import { Text, Flex, Heading } from "@chakra-ui/react";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import * as OrgConfig from "@avela/organization-config-sdk";
import { useFormikContext } from "formik";
import { OrganizationFormSchema } from "../Form";
// import { Debug } from "src/components/Debug";

type Props<T extends OrgConfig.Type> = {
  type: T;
  title: string;
  label: string;
  description: string;
  children: (config: OrgConfig.Config<T>) => React.ReactNode;
};

export function ConfigFormLayout<T extends OrgConfig.Type>(props: Props<T>) {
  const formik = useFormikContext<OrganizationFormSchema>();
  const { type, title, label, description } = props;
  const fieldValue = formik.values.organizationConfigs[type];
  return (
    <Flex direction="column" gap={4}>
      <Heading as="h3" size="md">
        {title}
      </Heading>

      <Flex direction="column">
        <SwitchInput<true | undefined>
          label={label}
          name={`organizationConfigs.${props.type}.disabled`}
          checked={[undefined, ""]}
          unchecked={[true, ""]}
          direction="row"
        />

        <Flex direction="column" flex={1} marginLeft={12} gap={2}>
          <Text fontSize="xs" color="blackAlpha.700">
            {description}
          </Text>
          {fieldValue.disabled === true
            ? null
            : props.children(fieldValue as OrgConfig.Config<T>)}
        </Flex>
      </Flex>
    </Flex>
  );
}
