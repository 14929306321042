import * as GQL from "src/types/graphql";

export type Level = "default" | "organization" | "enrollmentPeriod";
export type GlossaryType = GQL.glossary_type_enum;
export const GLOSSARY_TYPES = Object.values(GQL.glossary_type_enum);
export type Alias = {
  readonly level: Level;
  readonly default: {
    readonly singular: string;
    readonly plural: string;
  };
  readonly alias: {
    readonly singular: string;
    readonly plural: string;
  };
};
export type GlossaryByLevel = {
  readonly ["default"]: Alias;
  readonly [levelKey: string]: Alias;
};
export type Glossary = {
  readonly [type in GlossaryType]?: GlossaryByLevel;
};
export type GetGlossaryFunction = (
  glossaryType: GlossaryType,
  level?: {
    organizationId?: uuid;
    enrollmentPeriodId?: uuid;
  }
) => Alias | undefined;
