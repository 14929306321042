import { z } from "zod";

const ColorsSchema = z.object({
  50: z.string(),
  100: z.string(),
  200: z.string(),
  300: z.string(),
  400: z.string(),
  500: z.string(),
  600: z.string(),
  700: z.string(),
  800: z.string(),
  900: z.string(),
});

const UrlSchema = z.object({ url: z.string() });
const ImageSchema = z.object({ alt: z.string() }).merge(UrlSchema);

const BrandingSchema = z.object({
  colors: z.object({ primary: ColorsSchema }),
  logo: z.object({
    white: ImageSchema,
    color: ImageSchema,
  }),
});

export const PublicBrandingSchema = z.object({
  name: z.string(),
  colors: z.object({ primary: ColorsSchema }),
  logos: z.object({
    whiteSvg: z.string(),
    colorSvg: z.string(),
  }),
});

export type Branding = z.infer<typeof BrandingSchema>;
