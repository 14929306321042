import axios from "axios";
import { useEffect } from "react";
import useAccessToken from "src/hooks/useAccessToken";
import { Status } from "src/types/authData";
import { z } from "zod";
import { useFormImportFlow } from "./FormImportFlowContext";
import { FormImportFlowActionType } from "./state/actions";
import { FlowStatus } from "./state/constants";

const GenerateResponseSchema = z.object({
  formImportId: z.string(),
  presignedUploadUrl: z.string(),
});

interface FormImportFileUploadOptions {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
  file: File | undefined;
}

export function useFormImportFileUpload(
  options: FormImportFileUploadOptions
): void {
  const { dispatch } = useFormImportFlow();

  const { enrollmentPeriodId, formTemplateId, formTemplateName, file } =
    options;

  const token = useAccessToken();

  useEffect(() => {
    async function effectFn() {
      if (
        !file ||
        !process.env.REACT_APP_FORM_IMPORT_GENERATE_URL ||
        token.status !== Status.OK
      ) {
        return;
      }

      dispatch({
        type: FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE,
        value: FlowStatus.STARTED,
      });

      let generateResponse;
      try {
        generateResponse = await axios.post(
          process.env.REACT_APP_FORM_IMPORT_GENERATE_URL,
          {
            formTemplateId,
            fileName: file.name || `${formTemplateId}_${Date.now()}`,
          },

          {
            headers: {
              Authorization: `Bearer ${token.data}`,
              "x-hasura-role": "admin",
            },
          }
        );
      } catch (error) {
        console.error(error);

        dispatch({
          type: FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE,
          value: FlowStatus.FAILURE,
        });

        return;
      }

      const { formImportId, presignedUploadUrl } = GenerateResponseSchema.parse(
        generateResponse.data
      );

      try {
        const uploadResponse = await axios.put(presignedUploadUrl, file, {
          headers: { "Content-Type": "text/csv" },
        });

        if (uploadResponse.status === 200) {
          dispatch({
            type: FormImportFlowActionType.FILE_UPLOAD_SUCCESS,
            value: {
              formImportId,
              enrollmentPeriodId,
              formTemplateId,
              formTemplateName,
            },
          });
        } else {
          console.error(
            new Error(
              `Form data file upload returned a status ${uploadResponse.status}`
            )
          );

          dispatch({
            type: FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE,
            value: FlowStatus.FAILURE,
          });
        }
      } catch (error) {
        console.error(error);

        dispatch({
          type: FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE,
          value: FlowStatus.FAILURE,
        });
      }
    }

    void effectFn();
  }, [
    dispatch,
    file,
    token,
    formTemplateId,
    formTemplateName,
    enrollmentPeriodId,
  ]);
}
