import { Button, Icon } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { IconType } from "react-icons/lib";

type DocViewerProps = {
  ariaLabel: string;
  onClick: () => void;
  icon: IconType;
  disabled?: boolean;
};

export const DocViewerButton: FC<PropsWithChildren<DocViewerProps>> = ({
  ariaLabel,
  onClick,
  icon,
  disabled,
}: PropsWithChildren<DocViewerProps>) => {
  return (
    <Button
      width={8}
      height={8}
      background="gray.100"
      color="black"
      aria-label={ariaLabel}
      variant="ghost"
      padding={0}
      isDisabled={disabled}
      onClick={onClick}
    >
      <Icon as={icon} size={25} />
    </Button>
  );
};
