import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Loading } from "src/components/Feedback/Loading";
import { useQueryParams } from "src/hooks/useQueryParams";

export function Logout() {
  const { logout } = useAuth0();
  const { path } = useQueryParams();

  React.useEffect(() => {
    const returnTo = new URL(window.location.origin);
    if (typeof path === "string") {
      returnTo.searchParams.append("path", path);
      logout({ returnTo: returnTo.href });
    } else {
      returnTo.searchParams.append("path", "redirect");
      logout({ returnTo: returnTo.href });
    }
  }, [logout, path]);

  return <Loading />;
}
