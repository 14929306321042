import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { CARD_PROPS } from "../Step";
import * as OrgConfig from "@avela/organization-config-sdk";
import { NavLink } from "react-router-dom";

type Props = {
  config: OrgConfig.AccountLookup.Config;
};

export const DuplicateAccountFound: FunctionComponent<Props> = (props) => {
  const { config } = props;

  return (
    <Flex direction="column" gap={6} alignItems="center">
      <Flex {...CARD_PROPS} padding={8} gap={4} textAlign="center">
        <Heading as="h2" size="lg" fontSize="1.25rem" fontWeight={700}>
          We’ve found multiple accounts
        </Heading>
        <Text fontSize="1rem" textAlign="center">
          Reach out to the school district to help determine the best way for
          you to log in with the right account.
        </Text>
        <Button as={NavLink} colorScheme="primary" to={config.supportUrl}>
          Reach out to school district
        </Button>
      </Flex>
    </Flex>
  );
};
