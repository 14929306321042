import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ResendEmailVerificationButton } from "src/components/Buttons/ResendEmailVerificationButton";
import { EmptyState } from "src/components/EmptyState";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { AuthLayout } from "src/components/Layout/AuthLayout";
import { usePreferredLanguageBrowserOnly } from "src/hooks/usePreferredLanguage";
import { useQueryParams } from "src/hooks/useQueryParams";
import useUser from "src/hooks/useUser";
import { ReactComponent as NewMailboxSvg } from "src/images/new-mailbox.svg";
import * as Url from "src/services/url";
import { Status } from "src/types/authData";

export const VerifyEmail: React.FC = () => {
  const user = useUser();

  const { path } = useQueryParams();
  const returnTo: string = typeof path === "string" ? path : "/";
  const navigate = useNavigate();
  const { organization } = useParams();

  const preferredLanguageSetting = usePreferredLanguageBrowserOnly();

  React.useEffect(() => {
    if (user.status === Status.OK) {
      navigate(returnTo);
    } else if (
      user.status === Status.NOT_VERIFIED &&
      user.emailVerificationOverride.isVerified
    ) {
      // this should be impossible
      navigate(returnTo);
    }
  }, [navigate, returnTo, user]);

  if (user.status === Status.LOADING) {
    return <Loading />;
  }

  if (user.status === Status.OK) {
    return <Loading />;
  }

  if (user.status !== Status.NOT_VERIFIED) {
    return <GenericError />;
  }

  let email: string;
  switch (user.emailVerificationOverride.type) {
    case "no-verification":
      return null;
    case "secondary-identity": {
      email = user.emailVerificationOverride.email;
      break;
    }
    case "primary-identity": {
      if (user.data.loginType.type === "sms") {
        // this should be impossible since email should be primary identity
        return null;
      }
      email = user.data.loginType.email;
    }
  }

  return (
    <AuthLayout languageSelector={{ preferredLanguageSetting }}>
      <Flex direction="column" fontSize="md" alignItems="center" gap={8}>
        <EmptyState
          description={
            <>
              We sent a verification link to{" "}
              <Text as="span" fontWeight="600" wordBreak="break-word">
                {email}
              </Text>
              . If you don't see it in a few minutes, check spam and other
              folders.
            </>
          }
          heading="Check your email"
          Svg={NewMailboxSvg}
        >
          <ResendEmailVerificationButton
            emailVerificationOverrider={user.emailVerificationOverride}
          />
        </EmptyState>

        <Flex direction="column" alignItems="center" gap={4} marginTop={16}>
          <Text>Not you?</Text>
          <Button variant="link" as={RouterLink} to={Url.logout(organization)}>
            Log in with a different account
          </Button>
        </Flex>
      </Flex>
    </AuthLayout>
  );
};
