import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { RiLightbulbLine } from "react-icons/ri";
import NextStepsImg from "./images/next-steps.png";

export const NextStepsInfoPopover: React.FC = () => {
  return (
    <Popover placement="right-end" trigger="hover">
      <PopoverTrigger>
        <IconButton
          aria-label="Next Steps Information"
          size="sm"
          icon={<InfoOutlineIcon />}
          variant="ghost"
          color="black"
        />
      </PopoverTrigger>
      <PopoverContent>
        <Image src={NextStepsImg} />
        <Box margin={6}>
          <Tag size="md" variant="solid" colorScheme="purple">
            <Icon boxSize={4} as={RiLightbulbLine} paddingRight={1} />
            <Text size="xs">Tip</Text>
          </Tag>
          <Heading as="h3" size="md" marginY={3}>
            Guide families through statuses
          </Heading>
          <Text>
            Ensure they know what to do at any point by describing their next
            steps
          </Text>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
