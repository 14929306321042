import { Box, FormErrorMessage, Text } from "@chakra-ui/react";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { BaseInputProps } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { GET_FORM_ANSWER_ID } from "src/components/graphql/queries";
import { MAX_NUMBER_OF_FILES } from "src/constants";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import * as GQL from "src/types/graphql";
import { UploadFileInput } from "../../Inputs/UploadFileInput";
import { QuestionFileItem } from "./QuestionFileItem";
import { UploadFileItem } from "./UploadFileItem";

export interface UploadFileQuestionProps extends BaseInputProps {
  formId: string;
}

export const UploadFileQuestion: React.FC<UploadFileQuestionProps> = ({
  id,
  formId,
  isDisabled,
}) => {
  const [field, meta, helpers] = useField<FormikFieldValue>(id);
  const [uploadingFiles, setUploadingFiles] = useState<File[] | undefined>();
  const [questionFiles, setQuestionFiles] = useState<string[] | undefined>();
  const [formAnswerId, setFormAnswerId] = useState<string | undefined>();
  const [disableFileUpload, setDisableFileUpload] = useState<boolean>(
    isDisabled ?? false
  );

  useRemoteDataQuery<GQL.GetFormAnswerId, GQL.GetFormAnswerIdVariables>(
    GET_FORM_ANSWER_ID,
    {
      variables: {
        question_id: id,
        form_id: formId,
      },
      onCompleted: (data) => {
        setFormAnswerId(data.form_answer[0]?.id);
      },
    }
  );

  useEffect(() => {
    if (Array.isArray(field.value)) {
      setQuestionFiles(field.value);
    }
  }, [field]);

  const handleFileUpload = (data: File) => {
    helpers.setTouched(true);
    if (uploadingFiles?.find((uploadlingFile) => uploadlingFile === data))
      return;

    const newFiles = uploadingFiles?.length
      ? [...uploadingFiles, data]
      : [data];

    setDisableFileUpload(true);
    setUploadingFiles(newFiles);
  };

  const handleFileUploadComplete = (file: File, document_id: string) => {
    handleRemoveFile(file);

    const newDocumentIds = questionFiles?.length
      ? [...questionFiles, document_id]
      : [document_id];

    helpers.setValue(newDocumentIds);
  };

  const handleDeleteFile = (documentId: string) => {
    const newDocumentIds = questionFiles?.filter(
      (questionFile) => questionFile !== documentId
    );
    helpers.setValue(newDocumentIds);
  };

  const handleRemoveFile = (file: File) => {
    const newFiles = uploadingFiles?.filter(
      (uploadingFile) => uploadingFile !== file
    );
    setUploadingFiles(newFiles);
  };

  const handleUpdateFormAnswerId = (formAnswerIdResponse: string) => {
    if (formAnswerId) return;

    setFormAnswerId(formAnswerIdResponse);
  };

  const handleEnableFileUpload = () => {
    setDisableFileUpload(isDisabled ?? false);
  };

  return (
    <Box
      onBlur={() => {
        helpers.setTouched(true);
      }}
    >
      {questionFiles?.map((documentId) => (
        <QuestionFileItem
          key={documentId}
          documentId={documentId}
          onDeleteDocument={handleDeleteFile}
          onGenerateDocumentComplete={handleEnableFileUpload}
          viewMode={isDisabled}
        />
      ))}
      {uploadingFiles?.map((uploadingFile) => (
        <UploadFileItem
          key={uploadingFile.name}
          file={uploadingFile}
          formAnswerId={formAnswerId}
          formId={formId}
          questionId={id}
          onRemoveFile={handleRemoveFile}
          onUploadFile={handleFileUploadComplete}
          onFormAnswerCreation={handleUpdateFormAnswerId}
          onFileUploadError={handleEnableFileUpload}
        />
      ))}
      <UploadFileInput
        onUpload={handleFileUpload}
        disabled={
          disableFileUpload || questionFiles?.length === MAX_NUMBER_OF_FILES
        }
      />
      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      <Text fontSize="xs" color="gray" mt={2}>
        You can upload <strong>{MAX_NUMBER_OF_FILES}</strong> files, supported
        formats: JPG/JPEG, PNG, HEIC, PDF.
        {window.screen.width < 1024 && " You can tap to preview the file."}
      </Text>
    </Box>
  );
};
