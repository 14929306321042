import { gql } from "@apollo/client";

export const INSERT_PERSON_ANSWERS = gql`
  mutation InsertPersonAnswerBank($entry: person_answer_bank_insert_input!) {
    insert_person_answer_bank_one(object: $entry) {
      id
    }
  }
`;

export const UPDATE_PERSON_ANSWERS = gql`
  mutation UpdatePersonAnswerBank(
    $answer_bank_id: uuid!
    $last_used_at: timestamptz!
    $person_answers: [person_answer_insert_input!]!
  ) {
    delete_person_answer(
      where: { person_answer_bank_id: { _eq: $answer_bank_id } }
    ) {
      affected_rows
    }

    update_person_answer_bank(
      _set: { last_used_at: $last_used_at }
      where: { id: { _eq: $answer_bank_id } }
    ) {
      returning {
        id
      }
    }

    insert_person_answer(objects: $person_answers) {
      affected_rows
    }
  }
`;
