import React from "react";
import { CustomInput } from "../CustomInput";
import {
  BulkIsValidCallback,
  BulkOnSuccessCallback,
  useBulkUploadInput,
} from "./useBulkUploadInput";

interface BulkUploadInputProps {
  isValid: BulkIsValidCallback;
  onUpload: BulkOnSuccessCallback;
  children: React.ReactNode;
}

export const BulkUploadInput: React.FC<BulkUploadInputProps> = ({
  isValid,
  onUpload,
  children,
}) => {
  const { handleInputChange } = useBulkUploadInput(isValid, onUpload);

  return (
    <CustomInput type="file" accept=".csv" onChange={handleInputChange}>
      {children}
    </CustomInput>
  );
};
