import { Button, Icon } from "@chakra-ui/react";
import { RiUploadLine } from "react-icons/ri";
import { BulkUploadInput } from "src/components/Inputs/BulkUploadInput";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { BULK_CREATE_SCHOOLS } from "./graphql/mutations";

interface BulkUploadSchoolsProps {
  onComplete: () => void;
}

const School = z.object({
  reference_id: z.string().nullish(),
  name: z.string(),
  status: z.string().nullish(),
  organization_id: z.string().nullish(),
  street_address: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip_code: z.string().nullish(),
});

type SchoolRow = z.infer<typeof School>;

function isValid(data: unknown): data is SchoolRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

function isValidRow(data: unknown): data is SchoolRow {
  const { success } = School.safeParse(data);
  return success;
}

export const BulkUploadSchools = ({ onComplete }: BulkUploadSchoolsProps) => {
  const organization = useOrganization();
  const [bulkCreateSchools] = useRemoteDataMutation<
    GQL.BulkCreateSchools,
    GQL.BulkCreateSchoolsVariables
  >(BULK_CREATE_SCHOOLS);

  const parseData = (data: unknown[]): SchoolRow[] => {
    const parsedSchools: (SchoolRow | null)[] = data.map((row) => {
      const school = School.parse(row);
      if (school) {
        return {
          ...school,
          organization_id: organization.map((d) => d.id).toNullable() as string,
        };
      } else {
        return null;
      }
    });

    const schools: SchoolRow[] = parsedSchools.filter(
      (s): s is SchoolRow => !!s
    );

    return schools;
  };

  const handleUpload = async (data: unknown[]) => {
    let schools;
    try {
      schools = parseData(data);
    } catch (e) {
      console.error("We were unable to parse the csv", e);
    }

    if (schools && schools.length > 0) {
      await bulkCreateSchools({ variables: { schools } });
      onComplete();
    }
  };

  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      <Button leftIcon={<Icon as={RiUploadLine} />} size="xl" variant="outline">
        <Glossary>Import schools</Glossary>
      </Button>
    </BulkUploadInput>
  );
};
