export const VARIABLES: Record<string, string> = {
  "organization.name": "Organization name",
  "organization.path": "Organization path",
  "parent.name": "Parent full name",
  "student.name": "Student full name",
  "enrollmentPeriod.name": "Enrollment period name",
  "form.name": "Form name",
  "form.closeDate": "Form close date",
  "form.offerDate": "Form offer date",
  grade: "Grade name",
  "#schools.name": "School name",
  "#schools.rank": "School rank",
  applyUrl: "Avela apply URL",
  supportEmail: "Support email",
  "offer.schoolName": "School name for offer (only for offer related message)",
  "waitlist.schoolName":
    "School name for waitlist (only for waitlist related message)"
};
