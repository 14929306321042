import { Flex, HStack } from "@chakra-ui/layout";
import { FormControl } from "formik-chakra-ui";
import { SingleSelectInput } from "src/components/Inputs/QuestionDynamicInputs/SingleSelectInput";
import {
  FlexProps,
  FormLabel,
  RadioGroup,
  Radio,
  Icon,
  Button,
  IconButton,
  FormHelperText,
  Link,
} from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import { UseOrganizationType } from "src/hooks/useOrganization";
import { RiAddCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import React from "react";
import {
  Field,
  FieldArray,
  FieldProps,
  FormikErrors,
  useFormikContext,
} from "formik";
import {
  StudentProfileForm,
  StudentProfileFormAttendance,
  StudentProfileFormAttendanceItem,
} from "../../students/schemas";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { useNavigate } from "react-router-dom";
import * as Url from "src/services/url";

export type AttendanceSelectionData = {
  enrollment_periods: {
    id: string;
    name: string;
  }[];
  schools: {
    id: string;
    name: string;
  }[];
};

type AttendanceSchoolProps = {
  name_prefix: string;
  attendance_selection_data: AttendanceSelectionData;
  organization: UseOrganizationType;
  showHelperText: boolean;
  onClickDelete: () => void;
};
const AttendanceSchool: React.FC<AttendanceSchoolProps> = ({
  name_prefix,
  attendance_selection_data,
  organization,
  showHelperText,
  onClickDelete,
}) => {
  const navigate = useNavigate();
  const userPermissions = useUserPermissions();

  const onClickLink = async () => {
    organization.do(async (org) => {
      navigate(Url.OrgAdmin.Schools.new(org));
    });
  };
  const canCreateSchool = userPermissions.hasOne("school:create");
  const helperText = canCreateSchool ? (
    <FormHelperText whiteSpace={"nowrap"}>
      School not on this list?{" "}
      <Link
        color="primary.500"
        textDecoration="underline"
        onClick={onClickLink}
      >
        Add it
      </Link>
    </FormHelperText>
  ) : (
    <FormHelperText whiteSpace={"nowrap"}>
      School not on this list? Ask the organization owner to add it
    </FormHelperText>
  );

  return (
    <FormControl name={`${name_prefix}.school_id.school`} height="100%">
      <FormLabel htmlFor={`${name_prefix}.school_id`}>Current school</FormLabel>
      <HStack>
        <SingleSelectInput
          id={`${name_prefix}.school_id`}
          options={attendance_selection_data.schools.map((item) => ({
            key: item.id,
            label: item.name,
          }))}
          isDisabled={false}
        />
        <IconButton
          aria-label="Delete current school"
          variant="outline"
          colorScheme="gray"
          marginLeft="auto"
          alignSelf="flex-start"
          icon={<Icon as={RiDeleteBin6Line} />}
          onClick={onClickDelete}
        />
      </HStack>
      {showHelperText && helperText}
    </FormControl>
  );
};

type AttendanceDescriptionProps = {
  name_prefix: string;
  onClickDelete: () => void;
};
const AttendanceDescription: React.FC<AttendanceDescriptionProps> = ({
  name_prefix,
  onClickDelete,
}) => {
  return (
    <FormControl name={`${name_prefix}.description`} w="100%">
      <FormLabel
        htmlFor={`${name_prefix}.description`}
        display={"flex"}
        whiteSpace={"nowrap"}
      >
        What are they currently doing? (Optional)
      </FormLabel>
      <HStack>
        <SingleSelectInput
          id={`${name_prefix}.description`}
          options={["New to the organization", "Homeschooling", "Other"].map(
            (item) => ({
              key: item,
              label: item,
              translate: false,
              value: item,
            })
          )}
          isDisabled={false}
        />
        <IconButton
          aria-label="Delete current school"
          variant="outline"
          colorScheme="gray"
          marginLeft="auto"
          icon={<Icon as={RiDeleteBin6Line} />}
          onClick={onClickDelete}
        />
      </HStack>
    </FormControl>
  );
};

type AttendanceRadioProps = FlexProps & {
  name: string;
  organization: UseOrganizationType;
  index: number;
};

const AttendanceRadio: React.FC<AttendanceRadioProps> = ({
  name,
  organization,
  index,
}) => {
  const orgName: string = organization?.hasData() ? organization.data.name : "";

  const formik = useFormikContext<StudentProfileForm>();
  const itemErrors = formik?.errors.applicant_attending_schools?.[index];
  const hasError =
    (itemErrors as FormikErrors<StudentProfileFormAttendanceItem>)
      ?.attendance_status !== undefined;

  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        const { onChange, ...rest } = field;
        return (
          <FormControl
            id={name}
            name={name}
            alignSelf="flex-start"
            isInvalid={hasError && formik.submitCount > 0}
          >
            <FormLabel htmlFor={name} display="flex" whiteSpace={"nowrap"}>
              Are they a current {orgName} student?
            </FormLabel>
            <RadioGroup
              {...rest}
              id={name}
              name={name}
              alignItems={"center"}
              alignSelf={"stretch"}
              display="flex"
              flexDirection="row"
              gap="2"
              height={"100%"}
              marginTop="1em"
            >
              <Radio
                onChange={onChange}
                value={GQL.attendance_status_type_enum.CURRENT}
              >
                Yes
              </Radio>
              <Radio
                onChange={onChange}
                value={GQL.attendance_status_type_enum.NEW}
              >
                No
              </Radio>
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

type AttendanceFormItemProps = FlexProps & {
  attendance_selection_data: AttendanceSelectionData;
  name_prefix: string;
  organization: UseOrganizationType;
  value: StudentProfileFormAttendanceItem;
  index: number;
  onClickDelete: () => void;
};

const AttendanceFormItem: React.FC<AttendanceFormItemProps> = ({
  attendance_selection_data,
  name_prefix,
  organization,
  value,
  index,
  onClickDelete,
}) => {
  const formik = useFormikContext<StudentProfileForm>();
  const itemErrors = formik?.errors.applicant_attending_schools?.[index];
  const hasSchoolIdError =
    (itemErrors as FormikErrors<StudentProfileFormAttendanceItem>)
      ?.school_id !== undefined;
  const schoolIdTouched =
    !!formik.touched.applicant_attending_schools?.[index]?.school_id;

  return (
    <Flex display="inline-flex" width="100%" paddingTop="1rem">
      <HStack display={"flex"} width="100%">
        <FormControl
          name={`${name_prefix}.enrollment_period_id.select`}
          height={"100%"}
        >
          <FormLabel
            htmlFor={`${name_prefix}.enrollment_period_id`}
            display={"flex"}
            whiteSpace={"nowrap"}
          >
            Select enrollment period
          </FormLabel>
          <SingleSelectInput
            id={`${name_prefix}.enrollment_period_id`}
            options={attendance_selection_data.enrollment_periods.map(
              (item) => ({
                key: item.id,
                label: item.name,
              })
            )}
            isDisabled={false}
          ></SingleSelectInput>
        </FormControl>

        <AttendanceRadio
          name={`${name_prefix}.attendance_status`}
          organization={organization}
          index={index}
        />
      </HStack>

      <HStack gap={4} width="100%">
        {value.attendance_status ===
          GQL.attendance_status_type_enum.CURRENT && (
          <AttendanceSchool
            name_prefix={name_prefix}
            attendance_selection_data={attendance_selection_data}
            organization={organization}
            onClickDelete={onClickDelete}
            showHelperText={!hasSchoolIdError || !schoolIdTouched}
          ></AttendanceSchool>
        )}
        {value.attendance_status === GQL.attendance_status_type_enum.NEW && (
          <AttendanceDescription
            name_prefix={name_prefix}
            onClickDelete={onClickDelete}
          ></AttendanceDescription>
        )}
        {!value.attendance_status && (
          <IconButton
            aria-label="Delete current school"
            variant="outline"
            colorScheme="gray"
            marginLeft="auto"
            icon={<Icon as={RiDeleteBin6Line} />}
            onClick={onClickDelete}
          />
        )}
      </HStack>
    </Flex>
  );
};

export type AttendanceFormProps = FlexProps & {
  organization: UseOrganizationType;
  attendance_selection_data: AttendanceSelectionData;
  values: StudentProfileFormAttendance;
};

export const AttendanceForm: React.FC<AttendanceFormProps> = ({
  organization,
  attendance_selection_data,
  values,
  ...props
}) => {
  return (
    <Flex direction="column" gap={0} {...props} width="100%">
      <FieldArray name="applicant_attending_schools">
        {({ remove, push }) => (
          <>
            {values.applicant_attending_schools.length > 0 &&
              values.applicant_attending_schools.map((value, index) => (
                <div key={`applicant_attending_schools.${index}`}>
                  <AttendanceFormItem
                    attendance_selection_data={attendance_selection_data}
                    name_prefix={`applicant_attending_schools.${index}`}
                    organization={organization}
                    value={value}
                    onClickDelete={() => {
                      remove(index);
                    }}
                    index={index}
                    width="100%"
                  ></AttendanceFormItem>
                </div>
              ))}

            <Button
              variant="outline"
              colorScheme="gray"
              leftIcon={<RiAddCircleLine />}
              alignSelf="flex-start"
              isDisabled={
                attendance_selection_data.enrollment_periods.length < 1
              }
              onClick={() => {
                push({ enrollment_period_id: "", school_id: "" });
              }}
              marginTop="1rem"
            >
              Add enrollment period
            </Button>
          </>
        )}
      </FieldArray>
    </Flex>
  );
};
