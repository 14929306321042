import { gql } from "@apollo/client";
import { PERSON_FRAGMENT } from "src/operations/fragments/person";
import { USER_FRAGMENT } from "src/operations/fragments/user";

export const GET_PARENT_BY_ID = gql`
  ${PERSON_FRAGMENT}
  ${USER_FRAGMENT}
  query GetParent($id: uuid!) {
    person_by_pk(id: $id) {
      ...PersonFragment
      user {
        ...UserFragment
      }
    }
  }
`;

export const GET_PARENTS = gql`
  ${PERSON_FRAGMENT}
  query GetParents(
    $organizationId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [person_order_by!]
    $search: person_bool_exp!
  ) {
    person_aggregate(
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          { person_type: { _eq: guardian } }
          $search
        ]
      }
    ) {
      ...PersonTotals
    }
    person(
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          { person_type: { _eq: guardian } }
          $search
        ]
      }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...PersonFragment
    }
  }
`;
