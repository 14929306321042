import { ApolloClient, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { getMainDefinition } from "@apollo/client/utilities";
import { cache } from "./cache";
import { createClient } from "graphql-ws";

export default function createApolloClient(
  uri: string,
  accessToken: string,
  role: string
) {
  // from https://hasura.io/docs/latest/subscriptions/integrations/apollo-subscriptions/
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "x-hasura-role": role,
  };

  const wsLink = new GraphQLWsLink(
    createClient({
      url: uri.replace("https://", "wss://").replace("http://", "ws://"),
      connectionParams: {
        headers,
      },
      /**
       * Safari on iOS will close connection after coming back from background: https://github.com/enisdenjo/graphql-ws/discussions/488
       * Tried all the solution suggested in that discussion, none of them work. What work is to enable retry using recipe
       * from https://the-guild.dev/graphql/ws/recipes#client-usage-with-retry-on-any-connection-problem
       */
      shouldRetry: (shouldRetryEvent) => {
        return true;
      },
    })
  );

  const batchLink = new BatchHttpLink({
    uri,
    headers,
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    batchLink
  );

  return new ApolloClient({
    link: splitLink,
    cache,
    connectToDevTools: true,
  });
}
