import { gql } from "@apollo/client";

export const GET_GLOSSARY = gql`
  query GetGlossary {
    glossary_by_organization {
      glossary {
        type
      }
      organization_id
      enrollment_period_id
      default_singular
      default_plural
      alias_singular
      alias_plural
    }
  }
`;
