import { Icon, Text } from "@chakra-ui/react";
import _ from "lodash";
import React, { useState } from "react";
import { RiRefreshLine } from "react-icons/ri";
import { FormStatusMenu } from "src/components/Menus/FormStatusMenu";
import { Glossary } from "src/components/Text/Glossary";
import { FORM_STATUS } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { UPDATE_BULK_FORM_STATUS } from "../graphql/mutations";
import { FETCH_FORM_STATUS } from "../graphql/queries";
import { SchoolFormId } from "../types";

interface Props {
  fetchSelectedFormKeys: () => Promise<SchoolFormId[]>;
  refetchForms: () => Promise<unknown>;
}

const MAX_COUNT = 30000; // limiting to 30K to prevent memory and cache overflow.

export const FormStatusMenuBulk = ({
  fetchSelectedFormKeys,
  refetchForms,
}: Props) => {
  const [selectedAppIds, setSelectedAppIds] = useState<string[]>([]);
  const [uniqueStatus, setUniqueStatus] = useState<GQL.form_status_enum>();
  const toast = useAvelaToast();

  const [UpdateBulkFormStatus, { remoteData: updateBulkFormStatusData }] =
    useRemoteDataMutation<
      GQL.UpdateBulkFormStatus,
      GQL.UpdateBulkFormStatusVariables
    >(UPDATE_BULK_FORM_STATUS);

  const [fetchAppStatus] = useLazyRemoteDataQuery<
    GQL.FetchFormStatus,
    GQL.FetchFormStatusVariables
  >(FETCH_FORM_STATUS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const handleOnClick = async (evt: React.MouseEvent): Promise<boolean> => {
    const appKeys = await fetchSelectedFormKeys();
    const appIds = _.uniq(appKeys.map((key) => key.formId).filter(isNotNull));

    setSelectedAppIds(appIds);

    if (appIds.length > MAX_COUNT) {
      setUniqueStatus(undefined);
    }

    if (appIds.length <= MAX_COUNT) {
      const results = await fetchAppStatus({
        variables: { form_ids: appIds },
      });
      const statuses = results.data?.form.map((app) => app.status) || [];

      setUniqueStatus(statuses.length === 1 ? statuses[0] : undefined);
    }

    return true;
  };

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "No, cancel",
      },
      confirmButton: {
        label: "Yes, change status",
        colorScheme: "red",
      },
      translate: true,
    });

  const handleOnSelectStatusChange = async (value: GQL.form_status_enum) => {
    setHeader(
      <Text>
        <Glossary>{`${FORM_STATUS[value].statusDialogHeader}`}</Glossary>
      </Text>
    );
    setBody(
      <Text>
        <Glossary>{`${FORM_STATUS[value].statusDialogDescription}`}</Glossary>
      </Text>
    );

    const createExtraOptions = () => {
      if (value === GQL.form_status_enum.Submitted) {
        return {
          submitted_at: "now()",
          deleted_at: null,
          submitted_before: true,
        };
      }

      if (value === GQL.form_status_enum.Deleted) {
        return { deleted_at: "now()", submitted_at: null };
      }

      if (
        value === GQL.form_status_enum.Verified ||
        value === GQL.form_status_enum.LotteryReady
      )
        return { deleted_at: null };

      return { submitted_at: null, deleted_at: null };
    };

    if (await confirm()) {
      try {
        await UpdateBulkFormStatus({
          variables: {
            form_ids: selectedAppIds,
            skipOfferWaitlist: value !== GQL.form_status_enum.Cancelled,
            set: {
              status: value,
              source: null,
              ...createExtraOptions(),
            },
          },
        });
        await refetchForms();

        toast({ title: "Form status updated" });
      } catch (err) {
        toast.error({ title: "Error updating form status" });
      }
    }
  };

  return (
    <>
      <FormStatusMenu
        formStatus={uniqueStatus}
        label="Update status"
        leftIcon={<Icon as={RiRefreshLine} />}
        onSelect={handleOnSelectStatusChange}
        onClick={handleOnClick}
        isLoading={updateBulkFormStatusData.isLoading()}
      />
      {confirmationDialog}
    </>
  );
};
