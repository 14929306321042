import { StatusTag } from "src/components/DataDisplay/StatusTag";
import {
  FormSchoolRankSubStatusIndex,
  FORM_SCHOOL_RANK_SUB_STATUS,
} from "src/constants";
import * as GQL from "src/types/graphql";

export type Waitlist = {
  readonly status: GQL.waitlist_status_enum;
  readonly waitlist_position: { readonly position: number | null } | null;
};
interface SubStatusTagProps {
  formSchoolRankSubStatus: FormSchoolRankSubStatusIndex;
  waitlist: Waitlist | null;
  hasOffer: boolean;
}

export function SubStatusTag(props: SubStatusTagProps) {
  const { formSchoolRankSubStatus, waitlist, hasOffer } = props;
  const waitlistPosition = waitlist?.waitlist_position?.position;

  let substatusLabel;

  if (
    !hasOffer &&
    waitlist?.status === GQL.waitlist_status_enum.Waitlisted &&
    waitlistPosition
  ) {
    substatusLabel = `${GQL.waitlist_status_enum.Waitlisted}, #${waitlistPosition}`;
  }

  const status = FORM_SCHOOL_RANK_SUB_STATUS[formSchoolRankSubStatus];

  return <StatusTag customLabel={substatusLabel} status={status} />;
}
