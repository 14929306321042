import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { CustomQuestion, WithId } from "src/types/formTemplate";
import {
  GetCustomQuestionType_custom_question_type_by_pk,
  Organization,
} from "src/types/graphql";
import { CloneIdsGroupedBySourceIds, mapCloneIdsBySourceIds } from "./helpers";
import { AnswerBankRecord } from "../list/schemas/AnswerBank";

type CustomQuestionContextProps = {
  organization: Organization;
  customQuestion: CustomQuestion<WithId>;
  customQuestionType: GetCustomQuestionType_custom_question_type_by_pk;
  formId: uuid;
  personId: uuid;
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds;
  setShowAnswerBank: (value: boolean) => void;
  setAnswerToEdit: (value: AnswerBankRecord | null) => void;
};

const CustomQuestionContext = createContext<CustomQuestionContextProps>({
  organization: {} as Organization,
  customQuestion: {} as CustomQuestion<WithId>,
  customQuestionType: {} as GetCustomQuestionType_custom_question_type_by_pk,
  formId: "",
  personId: "",
  sourceIdCloneIdMapping: {} as CloneIdsGroupedBySourceIds,
  setShowAnswerBank: () => {},
  setAnswerToEdit: () => {},
});

export const CustomQuestionContextProvider: FunctionComponent<
  PropsWithChildren<Omit<CustomQuestionContextProps, "sourceIdCloneIdMapping">>
> = (props) => {
  const { children, ...contextProps } = props;

  const sourceIdCloneIdMapping = useMemo(() => {
    return mapCloneIdsBySourceIds(
      contextProps.customQuestionType,
      contextProps.customQuestion.nestedQuestions
    );
  }, [contextProps.customQuestionType, contextProps.customQuestion]);

  return (
    <CustomQuestionContext.Provider
      value={{ ...contextProps, sourceIdCloneIdMapping }}
    >
      {children}
    </CustomQuestionContext.Provider>
  );
};

export function useCustomQuestionContext() {
  const context = useContext(CustomQuestionContext);
  if (context === undefined) {
    throw new Error(
      "useCustomQuestionContext must be used within a CustomQuestionContextProvider"
    );
  }

  return context;
}
