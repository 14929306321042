import { Box, MenuList } from "@chakra-ui/react";
import React from "react";

export const NoUndoAvailable: React.FC = () => {
  return (
    <MenuList>
      <Box paddingX="3" color="blackAlpha.500">
        No previous answer available
      </Box>
    </MenuList>
  );
};
