import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { RiMore2Fill } from "react-icons/ri";

export const DefaultOverflowMenu: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  // No menu needed if all children are visible
  if (React.Children.count(children) === 0) return null;
  return (
    <Menu onOpen={() => {}}>
      <MenuButton
        as={Button}
        variant="banner"
        size="sm"
        leftIcon={<RiMore2Fill />}
      >
        More
      </MenuButton>
      <MenuList>
        <MenuGroup>
          {
            // Note: Children for these menus are usually themselves buttons, so
            // simply wrapping them in <MenuItem> leads to misleading UI
            // behavior (i.e. the menu row highlights, but isn't actually
            // clickable unless you are directly over the child button itself).
            // TODO: Convert children to <MenuItem>s for cleaner L&F.
            children
          }
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
