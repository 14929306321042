import { useEffect } from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { loading, notAsked, success } from "src/types/remoteData";
import { GET_FORM_DATA_IMPORT_ROW_AGGREGATES } from "./graphql/queries";
import {
  FormImportFlowAction,
  FormImportFlowActionType,
} from "./state/actions";
import { FlowStatus } from "./state/constants";
import type { FormImportFlowState } from "./state/reducer";
import { showInvalidImportToast } from "./toasts";

const POLL_INTERVAL_MS = 10_000;

interface UseFormImportRowAggregatesConfig {
  formImport: GQL.GetFormImport_form_import | null;
  dispatch: React.Dispatch<FormImportFlowAction>;
  state: FormImportFlowState;
}

export type FormImportRowAggregates = {
  formImportId: uuid;
  completed: number;
  failed: number;
  inProgress: number;
};

export function useFormImportRowAggregates(
  options: UseFormImportRowAggregatesConfig
) {
  const toast = useAvelaToast();

  const { formImport, dispatch, state } = options;

  const formImportId = state.formImportId || "";
  const enrollmentPeriodId = state.enrollmentPeriodId || "";
  const formTemplateId = state.formTemplateId || "";
  const finalStatusReported = state.finalStatusReported;
  const formTemplateName = state.formTemplateName || "";

  const formImportIsValid =
    formImport?.status === GQL.form_import_status_enum.Valid;

  const {
    remoteData: _remoteData,
    startPolling,
    stopPolling,
  } = useRemoteDataQuery<
    GQL.GetFormImportRowAggregates,
    GQL.GetFormImportRowAggregatesVariables
  >(GET_FORM_DATA_IMPORT_ROW_AGGREGATES, {
    skip: !formImportId || !formImportIsValid,
    variables: {
      form_import_id: formImportId,
    },
  });

  let remoteData = _remoteData.andThen<FormImportRowAggregates>((data) => {
    return success({
      formImportId: data.form_import[0]?.id ?? "",
      completed: data.completed?.aggregate?.count ?? 0,
      failed: data.failed?.aggregate?.count ?? 0,
      inProgress: data.in_progress?.aggregate?.count ?? 0,
    });
  });

  const hasDataForCurrentFormImportId =
    remoteData.hasData() && remoteData.data.formImportId === formImportId;

  if (!hasDataForCurrentFormImportId) {
    if (!formImportId) {
      remoteData = notAsked();
    } else if (_remoteData.isLoading()) {
      remoteData = loading();
    } else {
      remoteData = notAsked();
    }
  }

  const { inProgress = 0, completed = 0 } =
    hasDataForCurrentFormImportId && remoteData.hasData()
      ? remoteData.data
      : {};

  const hasError = remoteData.hasError();

  // Infer that the row imports are done and are not downstream of another import starting or in progress.
  const isFinished =
    formImportIsValid &&
    hasDataForCurrentFormImportId &&
    !remoteData.isLoading() &&
    inProgress === 0;

  const shouldPoll =
    formImportIsValid &&
    hasDataForCurrentFormImportId &&
    inProgress > 0 &&
    !hasError;

  let status: FlowStatus;
  if (hasError) {
    status = FlowStatus.FAILURE;
  } else if (!hasDataForCurrentFormImportId && !remoteData.isLoading()) {
    status = FlowStatus.STOPPED;
  } else if (shouldPoll) {
    status = FlowStatus.STARTED;
  } else {
    status = FlowStatus.SUCCESS;
  }

  useEffect(() => {
    if (shouldPoll) {
      startPolling(POLL_INTERVAL_MS);
    } else {
      stopPolling();
    }

    return stopPolling;
  }, [shouldPoll, startPolling, stopPolling]);

  useEffect(() => {
    if (isFinished && !finalStatusReported) {
      if (completed > 0) {
        toast({
          description:
            completed === 1 ? "1 form imported" : `${completed} forms imported`,
          id: "FormImportFinished",
          isClosable: true,
          status: "success",
          title: "Hooray!",
        });
      } else {
        showInvalidImportToast(toast);
      }

      dispatch({
        type: FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE,
        value: {
          formImportId,
          enrollmentPeriodId,
          formTemplateId,
          formTemplateName,
          finalStatusReported: true,
        },
      });
    }
  }, [
    dispatch,
    isFinished,
    toast,
    completed,
    formImportId,
    formTemplateName,
    enrollmentPeriodId,
    finalStatusReported,
    formTemplateId,
  ]);

  useEffect(() => {
    if (hasError) {
      toast({
        description:
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
        title: "Error loading form import data",
      });
    }
  }, [hasError, toast]);

  return { remoteData, status };
}
