import { Flex, Heading } from "@chakra-ui/react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_GLOSSARY_LIST } from "src/scenes/admin/glossaries/graphql/queries";
import { GlossaryList } from "src/scenes/admin/glossaries/List";
import * as GQL from "src/types/graphql";

export const Index: React.FC = () => {
  const { remoteData: glossaryRemoteData } =
    useRemoteDataQuery<GQL.GetGlossaryList>(GET_GLOSSARY_LIST);

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Heading as="h1" size="xl" fontWeight="600">
        Glossaries
      </Heading>
      <GlossaryList data={glossaryRemoteData} />
    </Flex>
  );
};

export { Edit } from "./Edit";
