import { Flex, InputProps, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

type Props = {
  name: string;
  isExternal: boolean;
} & InputProps;

export const TagGroupName: React.FC<Props> = ({
  name,
  isExternal,
  fontSize,
}) => {
  return (
    <Flex gap={2} alignItems="center">
      <Text fontSize={fontSize ?? "xs"}>{name} tags</Text>

      {isExternal ? (
        <Tooltip label={`${name} tags can be seen by parents`} hasArrow>
          <div>
            <RiEyeLine />
          </div>
        </Tooltip>
      ) : (
        <Tooltip label={`${name} tags cannot be seen by parents`} hasArrow>
          <div>
            <RiEyeOffLine />
          </div>
        </Tooltip>
      )}
    </Flex>
  );
};
