import { Flex, Heading, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { Card } from "src/components/Layout/Card";
import { useOrganization } from "src/hooks/useOrganization";
import {
  formatIsoDateToOrgLongDate,
  formatIsoDateToOrgTime,
} from "src/services/format";
import * as GQL from "src/types/graphql";
import _ from "lodash";

interface ImportantDatesProps {
  formTemplates: GQL.GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates[];
}

type ImportantDate = {
  isoDate: string;
  label: string;
};

export function ImportantDates(props: ImportantDatesProps) {
  const { formTemplates } = props;

  const organization = useOrganization();

  const importantDates: ImportantDate[] = useMemo(() => {
    const unsortedDates: ImportantDate[] = [];

    formTemplates.forEach((formTemplate) => {
      if (formTemplate.open_at) {
        unsortedDates.push({
          isoDate: formTemplate.open_at,
          label: `${formTemplate.name} form opens`,
        });
      }

      if (formTemplate.closed_at) {
        unsortedDates.push({
          isoDate: formTemplate.closed_at,
          label: `${formTemplate.name} form closes`,
        });
      }

      if (formTemplate.reopen_at) {
        unsortedDates.push({
          isoDate: formTemplate.reopen_at,
          label: `${formTemplate.name} form reopens`,
        });
      }

      if (formTemplate.offer_start_at) {
        unsortedDates.push({
          isoDate: formTemplate.offer_start_at,
          label: "Offers start",
        });
      }
    });

    return _.sortBy(unsortedDates, (importantDate) => importantDate.isoDate);
  }, [formTemplates]);

  if (importantDates.length === 0) {
    return null;
  }

  return (
    <Card
      bg="gray.100"
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Heading
        as="h3"
        fontSize="0.75rem"
        fontWeight="600"
        textTransform="uppercase"
      >
        Important dates
      </Heading>

      <Flex overflowX="auto">
        {importantDates.map((importantDate) => (
          <Flex
            color="gray.700"
            direction="column"
            key={importantDate.label}
            paddingInline={4}
            _first={{ paddingInlineStart: "0" }}
            _notLast={{
              borderColor: "gray.300",
              borderInlineEndStyle: "solid",
              borderInlineEndWidth: "1px",
            }}
          >
            <Text fontSize="md" whiteSpace="nowrap">
              {formatIsoDateToOrgLongDate(importantDate.isoDate, organization)}
            </Text>

            <Text fontSize="md" whiteSpace="nowrap">
              {formatIsoDateToOrgTime(importantDate.isoDate, organization)}
            </Text>

            <Text fontSize="sm" whiteSpace="nowrap">
              {importantDate.label}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}
