export type ProgramType = {
  id?: string;
  label: string;
  order: number;
};

export type ProgramGroupType = {
  id?: string;
  organization_id: string;
  name: string;
  programs: ProgramType[];
  programs_to_delete_ids?: string[];
};

export type ProgramFormInputType = {
  id?: string;
  label: string;
  order: string;
};

export function programFormInputToProgramType(
  programFormInput: ProgramFormInputType
) {
  const program = {
    label: programFormInput.label,
    order: programFormInput.order ? Number(programFormInput.order) : Number.NaN,
  };
  return programFormInput.id
    ? { id: programFormInput.id, ...program }
    : program;
}

export function programToProgramFormInputType(
  program: ProgramType
): ProgramFormInputType {
  return {
    id: program.id,
    label: program.label,
    order: program.order.toString(),
  };
}

export function validate(programGroup: ProgramGroupType) {
  const { organization_id, name, programs } = programGroup;
  return (
    organization_id.length > 0 &&
    name.length > 0 &&
    programs.every(
      ({ label, order }) => label.length > 0 && !Number.isNaN(order)
    )
  );
}
