import { MenuItem, Skeleton } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { asyncForEach } from "src/services/asyncHelpers";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { useFetchGradeIdsWithFormSchool } from "../graphql/hooks";
import {
  MAKE_BULK_FORM_SCHOOL_OFFER,
  MAKE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE,
} from "../graphql/mutations";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import type { FormSchool, FormSchoolStatus } from "../types";
import { canMakeOffers } from "../validations/makeOffers";
import {
  ConfirmationResult,
  useMakeOfferConfirmationDialog,
} from "./useMakeOfferConfirmationDialog";

interface Props {
  selectedFormSchoolStatuses: RD.RemoteData<unknown, FormSchoolStatus[]>;
  selectedFormSchools: FormSchool[];
  onRefetch?: () => void;
  menuLabel?: string;
  onMakeOfferSuccess?: () => void;
}

export const MakeOffer = ({
  selectedFormSchoolStatuses: selectedFormSchoolsStatuses,
  selectedFormSchools,
  onRefetch,
  menuLabel = "Make offers",
  onMakeOfferSuccess,
}: Props) => {
  const [makeBulkFormSchoolOffer] = useRemoteDataMutation<
    GQL.MakeBulkFormSchoolOffer,
    GQL.MakeBulkFormSchoolOfferVariables
  >(MAKE_BULK_FORM_SCHOOL_OFFER);

  const toast = useAvelaToast();

  const { confirm, confirmationDialog } = useMakeOfferConfirmationDialog(
    selectedFormSchoolsStatuses
  );

  const fetchGradeIdsWithFormSchool = useFetchGradeIdsWithFormSchool();
  const handleOnClick = React.useCallback(
    async (evt: React.MouseEvent) => {
      if (!selectedFormSchoolsStatuses.hasData()) {
        throw new Error("Invalid selected rows");
      }

      const schoolForms = selectedFormSchoolsStatuses.data;
      const count = schoolForms.length;
      // programID may be a program ID or null when a grade does not have programs
      // programID is undefined when the makeOffers action is cancelled
      const confirmationResult: ConfirmationResult = await confirm(schoolForms);

      if (confirmationResult.result === "Cancelled") return;

      const loadingToastId = toast({
        title: `Working hard`,
        description: `Making ${count} offers...`,
        status: "loading",
        duration: null,
      });

      const programID: string | null = confirmationResult.programId;

      try {
        const formSchoolGrades = await fetchGradeIdsWithFormSchool(
          schoolForms,
          programID === "" ? null : programID
        );

        await asyncForEach(
          _.chunk(formSchoolGrades, MAKE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE),
          async (formSchoolGradesChunk) => {
            const formIds = formSchoolGradesChunk.map((result) => {
              if (!result.form_id || !result.school_id || !result.grade_id) {
                throw new Error("data");
              }
              return result.form_id;
            });

            const formSchoolRankIds = formSchoolGradesChunk
              .map((result) => result.form_school_rank_id)
              .filter(isNotNull);

            await makeBulkFormSchoolOffer({
              variables: {
                form_ids: formIds,
                delete_offers_where: {
                  deleted_at: { _is_null: true },
                  _or: formSchoolGradesChunk.map((item) => ({
                    form_id: { _eq: item.form_id },
                    school_id: { _eq: item.school_id },
                    grade_id: { _eq: item.grade_id },
                  })),
                },
                insert_offers: formSchoolGradesChunk.map((result) => ({
                  form_id: result.form_id,
                  school_id: result.school_id,
                  grade_id: result.grade_id,
                  status: GQL.offer_status_enum.Offered,
                })),
                delete_waitlists_where: {
                  deleted_at: { _is_null: true },
                  _or: formSchoolGradesChunk.map((result) => ({
                    form_id: { _eq: result.form_id },
                    school_id: { _eq: result.school_id },
                  })),
                },
                form_school_rank_ids: formSchoolRankIds,
              },
              refetchQueries: [FETCH_OFFER_WAITLIST_STATUS],
            });
          }
        );

        if (onRefetch) onRefetch();

        if (onMakeOfferSuccess) onMakeOfferSuccess();

        toast.close(loadingToastId);
        toast({
          title: "Hooray",
          description: `${formSchoolGrades.length} offers made`,
          status: "success",
        });
      } catch (err: any) {
        toast.close(loadingToastId);
        if (err.message === "data") {
          toast.error({
            title: "Invalid grades in forms",
            description:
              "One or more items in your selection have mismatching grades",
          });
        } else {
          toast.error({
            title: "Something went wrong!",
            description: "Check your network and try again.",
          });
        }
        console.error(err);
      }
    },
    [
      confirm,
      fetchGradeIdsWithFormSchool,
      makeBulkFormSchoolOffer,
      onRefetch,
      selectedFormSchoolsStatuses,
      toast,
      onMakeOfferSuccess,
    ]
  );
  return (
    <>
      <RemoteDataView
        remoteData={selectedFormSchoolsStatuses}
        loading={<Skeleton height={5} margin={3} />}
        error={() => <GenericError />}
        config={{ showDataWhileReloading: false }}
      >
        {(formSchools) => {
          const canMake = canMakeOffers(formSchools, selectedFormSchools);

          return (
            <MenuItem
              textColor="gray.700"
              fontWeight="400"
              fontSize="sm"
              onClick={handleOnClick}
              isDisabled={!canMake}
            >
              {menuLabel}
            </MenuItem>
          );
        }}
      </RemoteDataView>
      {confirmationDialog}
    </>
  );
};
