import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFilePicker } from "src/hooks/useFilePicker";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "../../services/url";
import { useFormTemplates } from "../Providers/FormTemplateProvider";
import { useFormImportFlow } from "./FormImportFlowContext";
import { FormImportFlowActionType } from "./state/actions";
import { useFormImportFileUpload } from "./useFormImportFileUpload";

interface FormImportFileUploadFlowOptions {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
  redirectToCreateFormsPage?: boolean;
}

export function useFormImportFileUploadFlow(
  options: FormImportFileUploadFlowOptions
) {
  const {
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
    redirectToCreateFormsPage: redirectAfterFilePicked = true,
  } = options;

  const { files, openFilePicker } = useFilePicker({ accept: ".csv" });
  const navigate = useNavigate();
  const organization = useOrganization();

  const {
    dispatch,
    state: { formImportId, importFileUploadStatus },
  } = useFormImportFlow();

  const [file] = files;

  useFormImportFileUpload({
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
    file,
  });
  const { selectedNavFormTemplate } = useFormTemplates();

  useEffect(() => {
    if (file && organization.hasData()) {
      if (formImportId) {
        // Clear any existing form import references
        dispatch({
          type: FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE,
          value: null,
        });
      }

      if (selectedNavFormTemplate === undefined) {
        throw new Error("Unable to get the current form");
      }

      if (redirectAfterFilePicked) {
        navigate(
          Url.OrgAdmin.FormImport.view(
            organization.data,
            selectedNavFormTemplate?.id ?? ""
          ),
          {
            replace: false,
            state: undefined,
          }
        );
      }
    }
  }, [
    formImportId,
    dispatch,
    file,
    navigate,
    organization,
    redirectAfterFilePicked,
    selectedNavFormTemplate,
  ]);

  return { openFilePicker, status: importFileUploadStatus };
}
