import { Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import {
  NestedRouteOutletTabs,
  NestedRouteTab,
} from "src/components/Layout/NestedRouteOutletTabs";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { AdminHeading } from "src/components/Text/AdminHeading";
import { EnrollmentPeriodIdProvider, useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import {
  EnrollmentPeriodFormTemplates,
  EnrollmentPeriodGeneral,
  EnrollmentPeriodSchools,
  ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
  ENROLLMENT_PERIOD_GENERAL_PATH,
  ENROLLMENT_PERIOD_SCHOOLS_PATH,
} from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { GET_ENROLLMENT_PERIOD_BY_ID } from "./graphql/queries";
import { DeleteEnrollmentPeriodButton } from "src/components/Buttons/DeleteEnrollmentPeriodButton";

export type EnrollmentPeriodOutletContext = {
  enrollmentPeriod: GQL.GetEnrollment_enrollment_period_by_pk;
};

export const EditEnrollmentPeriod = (): React.ReactElement | null => {
  const organization = useOrganization();
  const { glossary } = useGlossary();
  const { enrollmentPeriodId = "" } = useParams();

  const navigationTabs: NestedRouteTab[] = useMemo(() => {
    return [
      {
        label: glossary`General`,
        type: ENROLLMENT_PERIOD_GENERAL_PATH,
        path: organization
          .map((org) => EnrollmentPeriodGeneral.edit(org, enrollmentPeriodId))
          .withDefault("#"),
        isVisible: true,
      },
      {
        label: glossary`Enrollment schools`,
        type: ENROLLMENT_PERIOD_SCHOOLS_PATH,
        path: organization
          .map((org) => EnrollmentPeriodSchools.edit(org, enrollmentPeriodId))
          .withDefault("#"),
        isVisible: true,
      },
      {
        label: glossary`Forms`,
        type: ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
        path: organization
          .map((org) =>
            EnrollmentPeriodFormTemplates.index(org, enrollmentPeriodId)
          )
          .withDefault("#"),
        isVisible: true,
      },
    ];
  }, [enrollmentPeriodId, organization, glossary]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetEnrollment,
    GQL.GetEnrollmentVariables
  >(GET_ENROLLMENT_PERIOD_BY_ID, {
    variables: { id: enrollmentPeriodId },
    fetchPolicy: "no-cache",
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.enrollment_period_by_pk === null) {
          return <NotFound />;
        }

        const { enrollment_period_by_pk: enrollmentPeriod } = data;
        return (
          <EnrollmentPeriodIdProvider enrollmentPeriodId={enrollmentPeriodId}>
            <Flex direction="column" gap={8}>
              <Breadcrumb
                items={breadcrumb.enrollmentPeriod.getBreadcrumbsForEdit(
                  organization,
                  enrollmentPeriod
                )}
              />
              <AdminHeading
                title={enrollmentPeriod.name}
                id={enrollmentPeriodId}
                actionButton={
                  <DeleteEnrollmentPeriodButton
                    id={enrollmentPeriodId}
                    enrollmentPeriodName={enrollmentPeriod.name}
                    isDisabled={
                      !!enrollmentPeriod.form_templates_aggregate.aggregate
                        ?.count
                    }
                  />
                }
              />

              <NestedRouteOutletTabs<EnrollmentPeriodOutletContext>
                tabs={navigationTabs}
                outletContext={{ enrollmentPeriod }}
              />
            </Flex>
          </EnrollmentPeriodIdProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
