import { useCallback, useState } from "react";

export type PaginationVariables = {
  limit: number;
  offset: number;
};

export type PaginationQuery = PaginationVariables & {
  count: number;
};

export type PageState = {
  currentPage: number;
  pageSize: number;
};

export type PaginationInfo = PageState & {
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
};

export const calculatePages = (
  limit: number,
  offset: number,
  count: number
): PaginationInfo => {
  const result = {
    currentPage: offset > 0 ? Math.ceil(offset / limit) + 1 : 1,
    totalPages: count > limit ? Math.ceil(count / limit) : 1,
    pageSize: limit,
  };
  return {
    ...result,
    hasNext: result.currentPage < result.totalPages,
    hasPrevious: result.currentPage > 1,
  };
};

export const calculatePagination = (currentPage: number, pageSize: number) => {
  return {
    limit: pageSize,
    offset: currentPage > 1 ? pageSize * (currentPage - 1) : 0,
  };
};

export const usePaginationQuery = (): [
  PaginationVariables,
  (info: PageState) => void
] => {
  const [pagination, setPagination] = useState({ limit: 25, offset: 0 });
  const updatePagination = useCallback(
    ({ currentPage, pageSize }: PageState) => {
      const result = calculatePagination(currentPage, pageSize);
      setPagination(result);
    },
    [setPagination]
  );

  return [pagination, updatePagination];
};
