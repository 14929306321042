import { Button } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import * as GQL from "src/types/graphql";

type DeleteTagGroupProps = {
  tagGroup: GQL.GetTagGroupsByEnrollmentPeriod_tag_group;
  onDeleteTagGroupSaving?: (id: uuid, onSuccess: () => void) => Promise<void>;
  onClose: () => void;
};
export const DeleteTagGroup: React.FC<DeleteTagGroupProps> = ({
  tagGroup,
  onDeleteTagGroupSaving,
  onClose,
}) => {
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: "Delete tag group?",
    body: `Deleting the ${tagGroup.name} tag group will remove it permanently and move any tags within the group to the “Internal” tag group.`,
    cancelButton: { label: "Cancel" },
    confirmButton: { label: "Yes, delete" },
  });

  const handleDeleteClick = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    if (onDeleteTagGroupSaving) onDeleteTagGroupSaving(tagGroup.id, onClose);
  }, [confirm, onDeleteTagGroupSaving, onClose, tagGroup]);
  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        size="sm"
        onClick={handleDeleteClick}
      >
        Delete tag group
      </Button>

      {confirmationDialog}
    </>
  );
};
