import { z } from "zod";
import {
  asRequiredField,
  emptyStringAsNull,
  nullableWithDefault,
} from "../services/zodExtra";
import { NameSchema, NameValidator } from "./Name";
import * as GQL from "src/types/graphql";

export const BASE_INITIAL_STUDENT_FORM: StudentFormType = {
  first_name: "",
  middle_name: "",
  last_name: "",
  birth_date: "",
  active: false,
  avatar: "",
  reference_id: "",
  applicant_attending_schools: [],
};

export const StudentAttendanceItemSchema = z.object({
  attendance_status: z
    .enum(Object.keys(GQL.attendance_status_type_enum) as [string, ...string[]])
    .transform((item) => item as GQL.attendance_status_type_enum)
    .nullish()
    .default(null),
  description: z.string().nullish().default(null),
  school_id: z.string().nullish().default(null),
  enrollment_period_id: z.string(),
});

export const StudentAttendanceSchema = z.object({
  applicant_attending_schools: z.array(StudentAttendanceItemSchema).default([]),
});

export const StudentSchema = z
  .object({
    birth_date: nullableWithDefault(z.string(), ""),
    active: z.boolean().default(false),
    avatar: nullableWithDefault(z.string(), ""),
    reference_id: emptyStringAsNull(z.string()),
  })
  .merge(NameSchema)
  .merge(NameSchema)
  .merge(StudentAttendanceSchema);

export const StudentValidator = NameValidator;

export type StudentFormType = z.infer<typeof StudentSchema>;

export const StudentAttendanceValidator = asRequiredField(
  StudentAttendanceItemSchema,
  "enrollment_period_id",
  "attendance_status"
);

export const StudentAttendanceValidatorCurrent = asRequiredField(
  StudentAttendanceItemSchema,
  "school_id",
  "attendance_status"
);
