import { Button, Text } from "@chakra-ui/react";
import React from "react";
import { Card } from "src/components/Layout/Card";
import { Dropdown } from "./Dropdown";
import { GradesGroupLists, OrderedItem } from "./helpers";

interface GradesGroupCardProps {
  gradeValues: OrderedItem[];
  gradeOptions: OrderedItem[];
  onChangeGrades: (values: OrderedItem[]) => void;
  onRemoveGrade: (item: OrderedItem) => void;
  programValues: OrderedItem[];
  programOptions: OrderedItem[];
  onChangePrograms: (values: OrderedItem[]) => void;
  onDelete: () => void;
}

export const GradesGroupCard: React.FC<GradesGroupCardProps> = ({
  gradeValues,
  gradeOptions,
  onChangeGrades,
  onRemoveGrade,
  programValues,
  programOptions,
  onChangePrograms,
  onDelete,
}) => (
  <Card
    display="flex"
    flexDirection="row"
    showBorder
    gap={1}
    padding={6}
    width="100%"
    alignItems="end"
  >
    <Dropdown
      itemType="Grades"
      options={gradeOptions}
      initialValues={gradeValues}
      onChange={onChangeGrades}
      onRemoveItem={onRemoveGrade}
    />
    <Dropdown
      itemType="Programs"
      options={programOptions}
      initialValues={programValues}
      onChange={onChangePrograms}
    />
    <Button
      aria-label="Remove grades group"
      minWidth="fit-content"
      variant="outline"
      colorScheme="gray"
      onClick={onDelete}
    >
      Remove grades group
    </Button>
  </Card>
);

export const GradesGroupText: React.FC<GradesGroupLists> = ({
  grades,
  programs,
}) => (
  <Text color="blackAlpha.700">
    {grades.map((grade) => grade.label).join(", ")}
    {programs.length > 0 &&
      " | " + programs.map((program) => program.label).join(", ")}
  </Text>
);
