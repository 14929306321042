import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tag as ChakraTag,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FormSchoolRankSubStatusIndex } from "src/constants";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";
import { SubStatusTag } from "src/scenes/parent/formTemplates/components/FormTemplateCard/FormSchoolRanks/SubStatusTag";
import * as GQL from "src/types/graphql";
import { BoundaryTag as BoundaryTagView } from "../Tags/BoundaryTag";

export type Waitlist = {
  readonly status: GQL.waitlist_status_enum;
  readonly waitlist_position: { readonly position: number | null } | null;
};
export type Offer = {};
export type Tag = FormSchoolRankTag | BoundaryTag;
export type FormSchoolRankTag = {
  readonly type: "FormSchoolRankTag";
  readonly id: uuid;
  readonly name: string;
  readonly description: string | null;
};

export type BoundaryTag = {
  readonly type: "BoundaryTag";
  readonly name: string;
};

type Props = {
  tags: readonly Tag[];
  schoolName: string;
  subStatus: FormSchoolRankSubStatusIndex;
  isOpen: boolean;
  onCancel: () => void;
  waitlist: Waitlist | null;
  offer: Offer | null;
};
export const TaggingInfoDialog: React.FC<Props> = ({
  tags,
  schoolName,
  subStatus,
  isOpen,
  onCancel,
  waitlist,
  offer,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      closeOnOverlayClick
      size="sm"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          as={Flex}
          direction="column"
          gap={4}
          padding={4}
          className={WEGLOT_APPLY_CLASS}
        >
          <Text fontSize="sm" fontWeight="600" className={WEGLOT_SKIP_CLASS}>
            {schoolName}
          </Text>

          {subStatus && (
            <SubStatusTag
              formSchoolRankSubStatus={subStatus}
              waitlist={waitlist}
              hasOffer={!!offer}
            />
          )}

          {tags.filter(isBoundaryTag).map((tag) => (
            <Flex direction="column" gap={2} key={tag.name}>
              <Flex>
                <BoundaryTagView key={tag.name} tag={tag} />
              </Flex>
            </Flex>
          ))}

          {tags.filter(isFormSchoolRankTag).map((tag) => (
            <Flex direction="column" gap={2} key={tag.id}>
              <Flex>
                <ChakraTag key={tag.id} variant="tag" fontSize="xs">
                  <Box whiteSpace="nowrap">{tag.name}</Box>
                </ChakraTag>
              </Flex>
              {tag.description && <Text fontSize="sm">{tag.description}</Text>}
            </Flex>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function isFormSchoolRankTag(tag: Tag): tag is FormSchoolRankTag {
  return tag.type === "FormSchoolRankTag";
}

function isBoundaryTag(tag: Tag): tag is BoundaryTag {
  return tag.type === "BoundaryTag";
}
