import React from "react";
import { GET_GLOSSARY } from "src/hooks/useGlossary/graphql/queries";
import { fromGQL } from "src/hooks/useGlossary/services";
import {
  Alias,
  GetGlossaryFunction,
  GlossaryType,
} from "src/hooks/useGlossary/types";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";

/**
 * Contexts
 */

export const GlossaryContext = React.createContext<
  GetGlossaryFunction | undefined
>(undefined);

export const EnrollmentPeriodIdContext = React.createContext<uuid | undefined>(
  undefined
);
type EnrollmentPeriodIdContextProviderProps = {
  children: React.ReactNode;
  enrollmentPeriodId: uuid;
};
export const EnrollmentPeriodIdProvider: React.FC<
  EnrollmentPeriodIdContextProviderProps
> = ({ children, enrollmentPeriodId }) => {
  return (
    <EnrollmentPeriodIdContext.Provider value={enrollmentPeriodId}>
      {children}
    </EnrollmentPeriodIdContext.Provider>
  );
};
type GlossaryContextProviderProps = { children: React.ReactNode };
export const GlossaryContextProvider: React.FC<
  GlossaryContextProviderProps
> = ({ children }) => {
  /**
   * List of glossary might grow over time and becoming too big to retrieve all in a single request.
   * Consider strategy for improving performance.
   * One options is to delay the retriaval and only retrieve the glossary on demand while caching it.
   */
  const { remoteData } = useRemoteDataQuery<GQL.GetGlossary>(GET_GLOSSARY);
  const getAlias = React.useCallback(
    (
      glossaryType: GlossaryType,
      arg?: { organizationId?: uuid; enrollmentPeriodId?: uuid }
    ): Alias | undefined => {
      if (!remoteData.hasData()) {
        return undefined;
      }

      const glossary = fromGQL(remoteData.data);

      return (
        glossary[glossaryType]?.[arg?.enrollmentPeriodId ?? ""] ??
        glossary[glossaryType]?.[arg?.organizationId ?? ""] ??
        glossary[glossaryType]?.default
      );
    },

    [remoteData]
  );
  return (
    <GlossaryContext.Provider value={getAlias}>
      {children}
    </GlossaryContext.Provider>
  );
};
