import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import { FunctionComponent } from "react";
import { UploadFileInput, UploadFileInputProps } from "../UploadFileInput";

type Props = Omit<UploadFileInputProps, "onUpload"> & {
  name: string;
};

export const FormikUploadFileInput: FunctionComponent<Props> = (props) => {
  const { name, ...uploadFileInputProps } = props;

  const [, { error, touched }, { setTouched, setValue }] = useField(name);

  const handleOnUpload = (file: File) => {
    setValue(file);
  };

  return (
    <Flex
      direction="column"
      onBlur={() => {
        setTouched(true);
      }}
    >
      <FormControl isInvalid={touched && Boolean(error)}>
        <UploadFileInput onUpload={handleOnUpload} {...uploadFileInputProps} />
        {touched && error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Flex>
  );
};
