import { Text } from "@chakra-ui/react";
import { useOrganization } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDate } from "src/services/format";
import { DisclaimerText } from "../Text/DisclaimerText";

type Props = {
  disclaimer: string;
  signature?: string;
  signedAt?: string;
};

export const DisclaimerQuestionAnswer: React.FC<Props> = ({
  disclaimer,
  signature,
  signedAt,
}) => {
  const organization = useOrganization();
  return (
    <>
      <DisclaimerText text={disclaimer} />
      <Text fontSize="sm" color="gray.600" fontWeight="500">
        Signature:
      </Text>
      {signature ? (
        <>
          <Text>{signature}</Text>
          {signedAt && (
            <Text variant="placeholder" fontSize="xs">
              Signed at: {formatIsoDateToOrgDate(signedAt, organization)}
            </Text>
          )}
        </>
      ) : (
        <Text variant="placeholder">No signature</Text>
      )}
    </>
  );
};
