import {
  QueryHookOptions,
  QueryOptions,
  TypedDocumentNode,
} from "@apollo/client";
import { DocumentNode } from "graphql";
import {
  useRemoteDataQuery,
  useRemoteDataQueryPromise,
} from "./useRemoteDataQuery";

export interface PaginationVariables {
  limit?: number | null;
  offset?: number | null;
}
/**
 * This hook provides multiple query hooks for manipulating paginated data,
 * specifically providing access to both the paginated data and unpaginated data
 * for the same query and variables.  This should help ensure that parameters
 * like search and filter criteria are consistently represented across both
 * datasets (e.g. paginated data being displayed in a table and corresponding
 * unpaginated data being exported).
 */
export function usePaginatedRemoteDataQuery<
  TData,
  TVariables extends PaginationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables: TVariables
) {
  const usePaginatedQuery = (options?: QueryHookOptions<TData, TVariables>) =>
    useRemoteDataQuery<TData, TVariables>(query, {
      variables,
      ...options,
    });
  const useUnpaginatedQueryPromise = (
    options?: QueryOptions<TVariables, TData>
  ) => {
    const { ...unpaginatedVariables } = variables;
    unpaginatedVariables.limit = unpaginatedVariables.offset = null;
    return useRemoteDataQueryPromise<TData, TVariables>(query, {
      variables: unpaginatedVariables,
      ...options,
    });
  };
  return { usePaginatedQuery, useUnpaginatedQueryPromise };
}
