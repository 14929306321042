import { html } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/utils";
import * as GQL from "src/types/graphql";

export const FORM_STARTED = {
  type: GQL.message_template_type_enum.FormStarted,
  emailSubject:
    "You started your {{organization.name}}!/¡Ha comenzado su aplicación {{organization.name}}!",
  emailHtml: html`
    <p>
      Dear {{parent.name}},<br />
      <br />
      Thank you for starting your {{organization.name}} for {{student.name}} for
      {{enrollmentPeriod.name}}!<br />
      <br />
      Complete your form here by {{form.closeDate}}.
      <a href="{{{applyUrl}}}">{{{applyUrl}}}</a><br />
      <br />
      If you need help, please contact
      <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.<br />
      <br />
      -{{organization.name}} Team<br />
    </p>
    —--
    <p>
      Estimado/a {{parent.name}},<br />
      <br />
      ¡Gracias por comenzar su {{organization.name}} para {{student.name}} para
      {{enrollmentPeriod.name}}!<br />
      <br />
      Finalice su aplicación antes del {{form.closeDate}} aquí.
      <a href="{{{applyUrl}}}">{{{applyUrl}}}</a><br />
      <br />
      Si necesita ayuda, por favor contacte a
      <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.<br />
      <br />
      -Equipo de {{organization.name}}<br />
    </p>
  `,
  emailText: `
Dear {{parent.name}},

Thank you for starting your {{organization.name}} for {{student.name}} for {{enrollmentPeriod.name}}!

Complete your form here by {{form.closeDate}}. {{{applyUrl}}}

If you need help, please contact {{supportEmail}}

-{{organization.name}} Team

—--

Estimado/a {{parent.name}},

¡Gracias por comenzar su {{organization.name}} para {{student.name}} para {{enrollmentPeriod.name}}!

Finalice su aplicación antes del {{form.closeDate}} aquí. {{{applyUrl}}}

Si necesita ayuda, por favor contacte a {{supportEmail}}

-Equipo de {{organization.name}}
`,
  sms: `You've started your {{organization.name}}! Submit by {{form.closeDate}}. ¡Ha comenzado su {{organization.name}}! Envíela antes del {{form.closeDate}}.`
};
