// Especially useful for Array.filter(), as this reduces the array type as well.
export function isNotNull<T extends {}>(e: T | null | undefined): e is T {
  return e !== null && e !== undefined;
}

// Checks to the see if a provided value is among the values of an object, and
// reduces the type accordingly. This is particularly useful for filtering enum
// values; for example `array.filter(isValueOf(SomeEnum))` will extract only the
// values matching the enum, and also reduces the type of result to SomeEnum[].
export const isValueOf =
  <T, E extends Record<keyof E, T>>(enumObject: E) =>
  (value: T): value is E[keyof E] => {
    return Object.values(enumObject).includes(value);
  };
