import { Box, Flex, FormLabel, Select, Switch } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { DEFAULT_VERIFICATION } from "src/components/Form/QuestionForm/constants";
import {
  FormType,
  getVerificationFormValue,
  Verification as VerificationFormType,
} from "src/components/Form/QuestionForm/formik";
import { CustomFormInput } from "src/components/Inputs/CustomFormInput";
import { FormInput } from "src/components/Inputs/FormInput";
import * as AF from "src/types/formTemplate";

type Props = {
  questionId: uuid;
  verificationOptions: AF.FormVerification<AF.WithId>[];
};
export const Verification: React.FC<Props> = ({
  questionId,
  verificationOptions,
}) => {
  const form = useFormikContext<FormType>();

  const onChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        verifications: {
          ...form.touched.verifications,
          [questionId]: {
            id: true,
            label: false,
          },
        },
      });
      form.setValues((values) => {
        switch (value) {
          case "NEW":
            return updateVerification(questionId, values, {
              type: "new",
              id: "NEW",
              label: values.verifications[questionId]?.label ?? "",
            });

          default:
            return updateVerification(questionId, values, {
              type: "existing",
              id: value,
            });
        }
      }, true);
    },
    [form, questionId]
  );

  const onLabelChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        verifications: {
          ...form.touched.verifications,
          [questionId]: { label: true },
        },
      });
      form.setValues((values): FormType => {
        return updateVerification(questionId, values, {
          ...(values.verifications[questionId] ?? DEFAULT_VERIFICATION),
          label: value,
        });
      }, true);
    },
    [form, questionId]
  );
  const verification = getVerificationFormValue(questionId, form.values);

  if (verification.type === "disabled") {
    return null;
  }

  const idTouched = form.touched.verifications?.[questionId]?.id;
  const idError = form.errors.verifications?.[questionId]?.id;

  const labelTouched = form.touched.verifications?.[questionId]?.label;
  const labelError = form.errors.verifications?.[questionId]?.label;

  return (
    <Flex direction="column" gap="3">
      <CustomFormInput
        label="Verification"
        error={idTouched ? idError : undefined}
      >
        <Select
          placeholder="Select an existing verification tag"
          value={verification.id}
          onChange={onChangeHandler}
        >
          {verificationOptions.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            );
          })}
          <option value="NEW">Create new verification</option>
        </Select>
      </CustomFormInput>
      {verification.type === "new" && (
        <FormInput
          label="New verification name"
          value={verification.label ?? ""}
          onChange={onLabelChangeHandler}
          error={labelTouched ? labelError : undefined}
        />
      )}
    </Flex>
  );
};

const EXCLUDED_QUESTION_TYPE_FOR_VERIFICATION: AF.QuestionType[] = [
  AF.GradesType,
];
type VerificationSwitchProps = {
  questionId: uuid;
  questionType?: AF.Question<AF.WithId>["type"] | "";
};
export const VerificationSwitch: React.FC<VerificationSwitchProps> = ({
  questionId,
  questionType,
}) => {
  const form = useFormikContext<FormType>();
  const verification = getVerificationFormValue(questionId, form.values);

  React.useEffect(() => {
    if (verification.type === "disabled") {
      return;
    }

    if (
      questionType === "" ||
      questionType === undefined ||
      EXCLUDED_QUESTION_TYPE_FOR_VERIFICATION.includes(questionType)
    ) {
      form.setValues((values) => {
        return updateVerification(questionId, values, {
          type: "disabled",
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  const onChangeHandler = React.useCallback(() => {
    switch (verification.type) {
      case "new":
      case "existing":
        form.setValues((values) => {
          return updateVerification(questionId, values, { type: "disabled" });
        }, false);
        break;

      case "disabled":
        if (verification.id === "NEW") {
          form.setValues((values) => {
            return updateVerification(questionId, values, {
              type: "new",
              id: "NEW",
              label: values.verifications[questionId]?.label ?? "",
            });
          }, false);
        } else {
          form.setValues((values) => {
            return updateVerification(questionId, values, {
              type: "existing",
              id: values.verifications[questionId]?.id ?? "",
              label: values.verifications[questionId]?.label ?? "",
            });
          }, false);
        }
        break;
    }
  }, [form, questionId, verification.id, verification.type]);

  if (
    questionType === undefined ||
    questionType === "" ||
    EXCLUDED_QUESTION_TYPE_FOR_VERIFICATION.includes(questionType)
  ) {
    return null;
  }

  return (
    <FormLabel
      htmlFor={`verificationSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`verificationSwitch${questionId}`}
        isChecked={verification.type !== "disabled"}
        onChange={onChangeHandler}
      />
      <Box>Has verification</Box>
    </FormLabel>
  );
};

function updateVerification(
  questionId: uuid,
  existingValues: FormType,
  updatedFields: Partial<VerificationFormType>
): FormType {
  return {
    ...existingValues,
    verifications: {
      ...existingValues.verifications,
      [questionId]: {
        ...(existingValues.verifications[questionId] ?? DEFAULT_VERIFICATION),
        ...updatedFields,
      } as VerificationFormType,
    },
  };
}
