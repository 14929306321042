import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { RiAddCircleLine } from "react-icons/ri";

type AddQuestionButtonProps = ButtonProps & {
  children: string;
  onClick: () => void;
};
export const AddQuestionButton: React.FC<AddQuestionButtonProps> = ({
  children,
  onClick,
  ...buttonProps
}) => {
  return (
    <Button
      variant="ghost"
      colorScheme="gray"
      leftIcon={<RiAddCircleLine />}
      size="sm"
      alignSelf="flex-end"
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
