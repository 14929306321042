import { gql } from "@apollo/client";
import { CUSTOM_QUESTION_ANSWERS_FRAGMENT } from "src/operations/fragments/form";
import {
  FORM_QUESTION_FRAGMENT,
  FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT,
  QUESTION_OPTION_FRAGMENT,
} from "src/operations/fragments/formTemplate";
import { PERSON_ANSWER_BANK_FRAGMENT } from "./fragments";

export const GET_CUSTOM_QUESTION = gql`
  ${FORM_QUESTION_FRAGMENT}
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}
  ${QUESTION_OPTION_FRAGMENT}

  query GetCustomQuestion($question_id: uuid!) {
    question_by_pk(id: $question_id) {
      ...FormQuestionFragment

      custom_question {
        custom_question_relationships(
          order_by: { cloned_question: { order: asc } }
        ) {
          id
          cloned_question {
            ...FormQuestionWithoutBranchingFragment
          }
          custom_question_type_field_id
        }
        custom_question_type {
          id
          name
          custom_question_type_fields(order_by: { question: { order: asc } }) {
            question_id
            question {
              ...FormQuestionWithoutBranchingFragment
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOM_QUESTION_ANSWER = gql`
  ${CUSTOM_QUESTION_ANSWERS_FRAGMENT}

  query GetCustomQuestionAnswer($form_id: uuid!, $question_id: uuid!) {
    custom_question_answer(
      where: {
        _and: [
          { form_id: { _eq: $form_id } }
          { question_id: { _eq: $question_id } }
        ]
      }
    ) {
      ...CustomQuestionAnswersFragment
    }
  }
`;

export const GET_CUSTOM_QUESTION_TYPE = gql`
  ${QUESTION_OPTION_FRAGMENT}
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}
  query GetCustomQuestionType($custom_question_type_id: uuid!) {
    custom_question_type_by_pk(id: $custom_question_type_id) {
      id
      name
      custom_question_type_fields(order_by: { question: { order: asc } }) {
        question_id
        question {
          ...FormQuestionWithoutBranchingFragment
        }
      }
    }
  }
`;

export const GET_PERSON_ANSWER_BANK = gql`
  ${PERSON_ANSWER_BANK_FRAGMENT}

  query GetPersonAnswerBank(
    $person_id: uuid!
    $custom_question_type_id: uuid!
  ) {
    person_answer_bank(
      where: {
        _and: [
          { custom_question_type_id: { _eq: $custom_question_type_id } }
          { person_id: { _eq: $person_id } }
        ]
      }
      order_by: { last_used_at: desc }
    ) {
      ...PersonAnswerBankFragment
    }
  }
`;

export const GET_SELECTED_PERSON_ANSWER_BANK = gql`
  ${PERSON_ANSWER_BANK_FRAGMENT}

  query GetSelectedPersonAnswerBank($form_id: uuid!, $question_id: uuid!) {
    custom_question_answer_bank_relationship(
      where: {
        _and: [
          { form_id: { _eq: $form_id } }
          { custom_question_id: { _eq: $question_id } }
        ]
      }
    ) {
      person_answer_bank {
        ...PersonAnswerBankFragment
      }
    }
  }
`;
