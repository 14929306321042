import { useApolloClient } from "@apollo/client";
import * as DateFns from "date-fns";
import _ from "lodash";
import React, { useMemo } from "react";
import { Selected, Tag, TagsPopoverProps } from "src/components/Tags";
import { Mode, ModeAction } from "src/components/Tags/types";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import {
  PriorityGroupUpdate,
  useGetPriorityGroupUpdates,
} from "src/hooks/useGetPriorityGroupUpdates";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as List from "src/services/list";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RemoteData from "src/types/remoteData";
import { SchoolFormId } from "../forms/types";
import {
  ADD_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS,
  ADD_FORM_TAG,
  DELETE_TAG,
  EDIT_TAG,
  NEW_TAG,
  NEW_TAG_GROUP,
  REMOVE_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS,
  REMOVE_FORM_TAG,
  EDIT_TAG_GROUP,
  DELETE_TAG_GROUP,
} from "./graphql/mutations";
import {
  GET_FORM_TAGS_AND_FORM_SCHOOL_TAGS,
  GET_TAGS_BY_ENROLLMENT_PERIOD,
  GET_TAGS_IDS_BY_TAG_GROUP,
} from "./graphql/queries";
import { useOptimisticUpdate } from "./useOptimisticUpdate";
import { GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD } from "../forms/graphql/queries";

const BATCH_SIZE = 1000;

export type TagUpdate = {
  tagUpdateType: "apply" | "remove";
  tagId: uuid;
};

type Props = {
  enrollmentPeriodId: uuid;
  tagGroupId: uuid | null;
};
type ReturnType = {
  tagsProps: TagsPopoverProps;
  setSelectedRows: (
    selectedRows: RemoteData.RemoteData<unknown, SchoolFormId[]>
  ) => void;
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
  setSelectedTagGroupId: (tagGroupId: uuid | null) => void;
  isDirty: boolean;
  resetTags: (isBulkAction: boolean) => void;
};
export function useTags({ enrollmentPeriodId, tagGroupId }: Props): ReturnType {
  const [isDirty, setIsDirty] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<
    RemoteData.RemoteData<unknown, SchoolFormId[]>
  >(RemoteData.loading());

  const [selectedTagGroupId, setSelectedTagGroupId] =
    React.useState<uuid | null>(tagGroupId);

  const isAvelaOrgAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);

  const { remoteData: enrollmentPeriodTags } = useRemoteDataQuery<
    GQL.GetTagsByEnrollmentPeriod,
    GQL.GetTagsByEnrollmentPeriodVariables
  >(GET_TAGS_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      tag_group_id: selectedTagGroupId ?? "",
    },
    skip: !enrollmentPeriodId || !selectedTagGroupId,
  });

  const { remoteData: allTagGroupsRemoteData } = useRemoteDataQuery<
    GQL.GetTagGroupsByEnrollmentPeriod,
    GQL.GetTagGroupsByEnrollmentPeriodVariables
  >(GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      is_external: !isAvelaOrgAdmin ? { _eq: false } : {},
    },
    skip: !enrollmentPeriodId,
  });

  const [queryFormTagsAndFormSchoolTags, formTagsAndFormSchoolTags] =
    useLazyRemoteDataQuery<
      GQL.GetFormTagsAndFormSchoolTags,
      GQL.GetFormTagsAndFormSchoolTagsVariables
    >(GET_FORM_TAGS_AND_FORM_SCHOOL_TAGS, { fetchPolicy: "cache-and-network" });

  const [addFormSchoolTagAndUpdatePriorityGroups] = useRemoteDataMutation<
    GQL.AddFormSchoolTagAndUpdatePriorityGroups,
    GQL.AddFormSchoolTagAndUpdatePriorityGroupsVariables
  >(ADD_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS);

  const [addFormTag] = useRemoteDataMutation<
    GQL.AddFormTag,
    GQL.AddFormTagVariables
  >(ADD_FORM_TAG);

  const [removeFormSchoolTagAndUpdatePriorityGroups] = useRemoteDataMutation<
    GQL.RemoveFormSchoolTagAndUpdatePriorityGroups,
    GQL.RemoveFormSchoolTagAndUpdatePriorityGroupsVariables
  >(REMOVE_FORM_SCHOOL_TAG_AND_UPDATE_PRIORITY_GROUPS);

  const [removeFormTag] = useRemoteDataMutation<
    GQL.RemoveFormTag,
    GQL.RemoveFormTagVariables
  >(REMOVE_FORM_TAG);

  const [searchKeyword, setSearchKeyword] = React.useState("");
  const onSearch = async (keyword: string) => {
    setSearchKeyword(keyword);
  };
  const filterByKeyword = React.useCallback(
    (tagName: string): boolean => {
      if (searchKeyword.trim() === "") return true;
      return tagName.toLowerCase().includes(searchKeyword.toLowerCase());
    },
    [searchKeyword]
  );

  const {
    optimisticUpdateMap,
    applyTagOptimistically,
    removeTagOptimistically,
    resetOptimisticUpdate,
  } = useOptimisticUpdate();

  const tagGroups = React.useMemo(() => {
    if (allTagGroupsRemoteData.hasData()) {
      return allTagGroupsRemoteData.data.tag_group;
    }
    return [];
  }, [allTagGroupsRemoteData]);

  const tags: RemoteData.RemoteData<unknown, Tag[]> = React.useMemo(() => {
    return RemoteData.toTuple3(
      enrollmentPeriodTags,
      formTagsAndFormSchoolTags.remoteData,
      selectedRows
    ).map(([tags, allTags, rows]) => {
      const tagsCounter = countTags(tags, allTags);
      return List.filterMap(tags.enrollment_period_tag, (tag) => {
        if (!filterByKeyword(tag.name)) {
          return null;
        }

        let selected: Selected;
        let isUpdating = false;
        const optimisticSelected = optimisticUpdateMap.get(tag.id);
        if (optimisticSelected) {
          isUpdating = true;
          selected = optimisticSelected;
        } else {
          const totalFormSchool = rows.length;
          const selectedCount = tagsCounter.get(tag.id);
          selected =
            selectedCount === 0
              ? "None"
              : selectedCount === totalFormSchool
              ? "All"
              : "Partial";
        }

        return {
          id: tag.id,
          name: tag.name,
          description: tag.description,
          lastUsedAt: tag.last_used_at
            ? DateFns.parseISO(tag.last_used_at)
            : null,
          selected,
          isUpdating,
        };
      });
    });
  }, [
    enrollmentPeriodTags,
    formTagsAndFormSchoolTags.remoteData,
    selectedRows,
    filterByKeyword,
    optimisticUpdateMap,
  ]);

  const toast = useAvelaToast();
  const client = useApolloClient();
  const getPriorityGroupUpdates = useGetPriorityGroupUpdates();

  const applyTag = React.useCallback(
    async (tagId: string) => {
      try {
        if (!selectedRows.hasData())
          throw new Error("Unable to apply tags since no rows are selected");
        const tagUpdate: TagUpdate = {
          tagUpdateType: "apply",
          tagId: tagId,
        };

        // do this in batch for better performance
        // benchmark data:
        // without batch: 5000 rows => 17.36s
        // batch (1000): 5000 rows => 3.78s
        const chunk = _.chunk(selectedRows.data, BATCH_SIZE);
        await Promise.all(
          chunk.map(async (rows) => {
            const priorityGroupUpdates: PriorityGroupUpdate[] =
              await getPriorityGroupUpdates(rows, tagUpdate);

            // for rows with formSchoolRankId, add to form_school_tag table
            const form_school_tags = rows.flatMap((row) => {
              if (!row.formSchoolRankId) {
                return [];
              }
              return [
                {
                  tag_id: tagId,
                  form_id: row.formId,
                  school_id: row.schoolId,
                },
              ];
            });

            // for rows without formSchoolRankId, add to form_tag table
            const form_tags = rows.flatMap((row) => {
              if (row.formSchoolRankId) {
                return [];
              }

              return [{ tag_id: tagId, form_id: row.formId }];
            });

            await Promise.all([
              addFormSchoolTagAndUpdatePriorityGroups({
                variables: {
                  form_school_tags,
                  waitlist_updates: priorityGroupUpdates,
                },
              }),
              addFormTag({ variables: { form_tags } }),
            ]);
          })
        );

        setIsDirty(true);
        await client.refetchQueries({
          include: [
            GET_FORM_TAGS_AND_FORM_SCHOOL_TAGS,
            GET_TAGS_BY_ENROLLMENT_PERIOD,
          ],
        });
      } catch (error) {
        console.error(error);
        toast.error({ title: "Unable to apply tags" });
      }
    },
    [
      addFormSchoolTagAndUpdatePriorityGroups,
      addFormTag,
      client,
      getPriorityGroupUpdates,
      selectedRows,
      toast,
    ]
  );

  const removeTag = React.useCallback(
    async (tagId: string) => {
      try {
        if (!selectedRows.hasData())
          throw new Error("Unable to remove tags since no rows are selected");
        const tagUpdate: TagUpdate = {
          tagUpdateType: "remove",
          tagId: tagId,
        };

        // need to delete in batches since we'll hit the limit of the size of the condition
        // and postgres will run out of memory.
        const chunk = _.chunk(selectedRows.data, BATCH_SIZE);
        await Promise.all(
          chunk.map(async (rows) => {
            const priorityGroupUpdates: PriorityGroupUpdate[] =
              await getPriorityGroupUpdates(rows, tagUpdate);

            await Promise.all([
              removeFormSchoolTagAndUpdatePriorityGroups({
                variables: {
                  condition: {
                    _or: rows.flatMap((row) => {
                      if (!row.formSchoolRankId) return [];
                      return [
                        {
                          _and: [
                            { tag_id: { _eq: tagId } },
                            { form_id: { _eq: row.formId } },
                            { school_id: { _eq: row.schoolId } },
                          ],
                        },
                      ];
                    }),
                  },
                  waitlist_updates: priorityGroupUpdates,
                },
              }),
              removeFormTag({
                variables: {
                  condition: {
                    _or: rows.flatMap((row) => {
                      if (row.formSchoolRankId) return [];
                      return [
                        {
                          _and: [
                            { tag_id: { _eq: tagId } },
                            { form_id: { _eq: row.formId } },
                          ],
                        },
                      ];
                    }),
                  },
                },
              }),
            ]);
          })
        );
        setIsDirty(true);
        await client.refetchQueries({
          include: [GET_FORM_TAGS_AND_FORM_SCHOOL_TAGS],
        });
      } catch (error) {
        console.error(error);
        toast.error({
          title: "Unable to remove tags",
        });
      }
    },
    [
      selectedRows,
      client,
      getPriorityGroupUpdates,
      removeFormSchoolTagAndUpdatePriorityGroups,
      removeFormTag,
      toast,
    ]
  );

  const onTagUpdate = React.useCallback(
    async (tagId: string, selected: Selected) => {
      if (selected === "All") {
        applyTagOptimistically(tagId);
        await applyTag(tagId);
        resetOptimisticUpdate(tagId);
      } else if (selected === "None") {
        removeTagOptimistically(tagId);
        await removeTag(tagId);
        resetOptimisticUpdate(tagId);
      }
    },
    [
      applyTagOptimistically,
      applyTag,
      resetOptimisticUpdate,
      removeTagOptimistically,
      removeTag,
    ]
  );

  const setSelectedRowsWrapper = React.useCallback(
    async (selectedRows: RemoteData.RemoteData<unknown, SchoolFormId[]>) => {
      setIsDirty(false);
      setSelectedRows(selectedRows);
      selectedRows.do(async (rows) => {
        await queryFormTagsAndFormSchoolTags({
          variables: {
            form_ids: rows.flatMap((row) =>
              row.formSchoolRankId ? [] : [row.formId]
            ),
            form_school_rank_ids: rows.flatMap((row) =>
              row.formSchoolRankId ? [row.formSchoolRankId] : []
            ),
          },
        });
      });
    },
    [queryFormTagsAndFormSchoolTags]
  );

  const isOrgAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);

  const { setMode, ...tagForm } = useTagForm({
    setIsDirty,
    enrollmentPeriodId,
    selectedTagGroupId,
    setSearchKeyword,
    tagGroups,
  });

  const resetTags = React.useCallback(
    async (isBulkAction: boolean) => {
      if (isBulkAction) {
        setMode({
          type: "SelectGroup",
        });
      }
      setSearchKeyword("");
    },
    [setMode, setSearchKeyword]
  );

  const setSelectedTagGroupWrapper = React.useCallback(
    (tagGroupId: string | null) => {
      setSelectedTagGroupId(tagGroupId);
      setMode({
        type: "List",
      });
    },
    [setSelectedTagGroupId, setMode]
  );

  const selectedGroupTag = useMemo(
    () => tagGroups.find((tagGroup) => tagGroup.id === selectedTagGroupId),
    [tagGroups, selectedTagGroupId]
  );

  return {
    tagsProps: {
      searchKeyword,
      tags,
      onTagUpdate,
      onSearch,
      ...tagForm,
      config: {
        title: selectedGroupTag ? selectedGroupTag.name : "Tags",
        allowNewTag: isOrgAdmin,
        allowDeleteTag: isOrgAdmin,
        allowEditTag: isOrgAdmin,
      },
    },
    setSelectedRows: setSelectedRowsWrapper,
    setSelectedTagGroupId: setSelectedTagGroupWrapper,
    tagGroups,
    isDirty,
    resetTags,
  };
}

type TagFormProps = {
  enrollmentPeriodId: uuid;
  selectedTagGroupId: uuid | null;
  setSearchKeyword: (keyword: string) => void;
  setIsDirty: (isDirty: boolean) => void;
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
};

function useTagForm({
  enrollmentPeriodId,
  selectedTagGroupId,
  setSearchKeyword,
  setIsDirty,
  tagGroups,
}: TagFormProps) {
  const [error, setError] = React.useState<string | undefined>();
  const initialMode: Mode = selectedTagGroupId
    ? {
        type: "List",
      }
    : { type: "SelectGroup" };

  const [mode, setMode] = React.useReducer(
    (_previousMode: Mode, action: ModeAction) => {
      switch (action.type) {
        case "List":
        case "Edit":
        case "New":
        case "SelectGroup":
          setError("");
          return action;
      }
    },
    initialMode
  );

  const clearError = React.useCallback(() => {
    setError(undefined);
  }, []);

  const internalTagGroupId = React.useMemo(() => {
    return tagGroups.find((tagGroup) => tagGroup.name === "Internal")?.id;
  }, [tagGroups]);

  const newTag = useNewTag({
    setMode,
    enrollmentPeriodId,
    selectedTagGroupId,
    setSearchKeyword,
    setError,
  });

  const editTag = useEditTag({
    setMode,
    setSearchKeyword,
    setError,
  });

  const deleteTag = useDeleteTag({ setIsDirty });

  const newTagGroup = useNewTagGroup({
    enrollmentPeriodId,
    setError,
  });

  const editTagGroup = useEditTagGroup({
    setError,
  });

  const deleteTagGroup = useDeleteTagGroup({
    setError,
    internalTagGroupId,
  });

  const handleCloseManageTagGroupDialog = React.useCallback(() => {
    clearError();
  }, [clearError]);

  return {
    mode,
    setMode,
    ...newTag,
    ...editTag,
    ...deleteTag,
    ...newTagGroup,
    ...editTagGroup,
    ...deleteTagGroup,
    error,
    clearError,
    onCloseManageTagGroupDialog: handleCloseManageTagGroupDialog,
  };
}
// New tag group
type NewTagGroupProps = {
  enrollmentPeriodId: uuid;
  setError: (error: string) => void;
};

function useNewTagGroup({ enrollmentPeriodId, setError }: NewTagGroupProps) {
  const toast = useAvelaToast();

  const [newTagGroup] = useRemoteDataMutation<
    GQL.NewTagGroup,
    GQL.NewTagGroupVariables
  >(NEW_TAG_GROUP);
  const onNewTagGroupSaving = React.useCallback(
    async (
      { name, is_external, max_tags }: GQL.tag_group_insert_input,
      onSuccess: () => void
    ) => {
      if (!name) return setError("Tag Group Name is required");

      try {
        await newTagGroup({
          variables: {
            enrollment_period_id: enrollmentPeriodId,
            name: name,
            is_external: is_external ?? false,
            max_tags: max_tags,
          },
          refetchQueries: [GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD],
        });
        toast({ title: "Tag group created", status: "info", isClosable: true });
        onSuccess();
      } catch (error) {
        console.error(error);

        if (
          error instanceof Error &&
          error.message.startsWith("Uniqueness violation")
        ) {
          setError("Tag group already exists");
        } else {
          toast.error({ title: "Unable to create new tag group" });
        }
      }
    },
    [enrollmentPeriodId, newTagGroup, setError, toast]
  );

  return { onNewTagGroupSaving };
}

// Edit tag group
type EditTagGroupProps = {
  setError: (error: string) => void;
};

function useEditTagGroup({ setError }: EditTagGroupProps) {
  const toast = useAvelaToast();

  const [editTagGroup] = useRemoteDataMutation<
    GQL.EditTagGroup,
    GQL.EditTagGroupVariables
  >(EDIT_TAG_GROUP);
  const onEditTagGroupSaving = React.useCallback(
    async (
      tagGroupId: uuid,
      { name, is_external }: GQL.tag_group_insert_input,
      onSuccess: () => void
    ) => {
      if (!name) return setError("Tag Group Name is required");

      try {
        await editTagGroup({
          variables: {
            tag_group_id: tagGroupId,
            name: name,
            is_external: is_external ?? false,
          },
          refetchQueries: [GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD],
        });

        toast({ title: "Tag group updated", status: "info", isClosable: true });
        setError("");
        onSuccess();
      } catch (error) {
        console.error(error);

        if (
          error instanceof Error &&
          error.message.startsWith("Uniqueness violation")
        ) {
          setError("Tag group already exists");
        } else {
          toast.error({ title: "Unable to update tag group" });
        }
      }
    },
    [editTagGroup, setError, toast]
  );

  return { onEditTagGroupSaving };
}

// Delete tag group
type DeleteTagGroupProps = {
  setError: (error: string) => void;
  internalTagGroupId?: uuid;
};

function useDeleteTagGroup({
  setError,
  internalTagGroupId,
}: DeleteTagGroupProps) {
  const toast = useAvelaToast();

  const [queryTagIdsByTagGroup] = useLazyRemoteDataQuery<
    GQL.GetTagsIdsByTagGroup,
    GQL.GetTagsIdsByTagGroupVariables
  >(GET_TAGS_IDS_BY_TAG_GROUP, { fetchPolicy: "cache-and-network" });

  const [deleteTagGroup] = useRemoteDataMutation<
    GQL.DeleteTagGroup,
    GQL.DeleteTagGroupVariables
  >(DELETE_TAG_GROUP);
  const onDeleteTagGroupSaving = React.useCallback(
    async (tagGroupId: uuid, onSuccess: () => void) => {
      if (!internalTagGroupId)
        return toast.error({ title: "Internal group not present" });
      try {
        const tagsIds = await queryTagIdsByTagGroup({
          variables: {
            tag_group_id: tagGroupId,
          },
        });

        await deleteTagGroup({
          variables: {
            tag_group_id: tagGroupId,
            tag_ids:
              tagsIds.data?.enrollment_period_tag.map((tag) => tag.id) ?? [],
            internal_tag_group_id: internalTagGroupId,
          },
          refetchQueries: [GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD],
        });

        toast({ title: "Tag group deleted", status: "info", isClosable: true });
        setError("");
        onSuccess();
      } catch (error) {
        console.error(error);

        toast.error({ title: "Unable to delete tag group" });
      }
    },
    [deleteTagGroup, setError, toast, internalTagGroupId, queryTagIdsByTagGroup]
  );

  return { onDeleteTagGroupSaving };
}

// New tag
type NewTagProps = {
  enrollmentPeriodId: uuid;
  selectedTagGroupId: uuid | null;
  setSearchKeyword: (keyword: string) => void;
  setMode: (mode: Mode) => void;
  setError: (error: string) => void;
};

export function useNewTag({
  setMode,
  enrollmentPeriodId,
  selectedTagGroupId,
  setSearchKeyword,
  setError,
}: NewTagProps) {
  const toast = useAvelaToast();
  const onNewTag = React.useCallback(
    (initialName: string | null) => {
      setMode({ type: "New", initialName });
    },
    [setMode]
  );

  const [newTag] = useRemoteDataMutation<GQL.NewTag, GQL.NewTagVariables>(
    NEW_TAG
  );
  const onNewTagSaving = React.useCallback(
    async (values: GQL.enrollment_period_tag_insert_input) => {
      if (!values.name) return setError("Tag Name is required");

      try {
        await newTag({
          variables: {
            enrollment_period_id: enrollmentPeriodId,
            name: values.name?.trim(),
            description: values.description,
            tag_group_id: selectedTagGroupId ?? null,
          },
          refetchQueries: [GET_TAGS_BY_ENROLLMENT_PERIOD],
        });

        toast({ title: "Tag created", status: "info", isClosable: true });
        setMode({
          type: "List",
        });
        setSearchKeyword("");
      } catch (error) {
        console.error(error);
        if (
          error instanceof Error &&
          error.message.startsWith("Uniqueness violation")
        ) {
          setError("Tag already exists");
        } else {
          toast.error({ title: "Unable to create new tag" });
        }
      }
    },
    [
      enrollmentPeriodId,
      selectedTagGroupId,
      newTag,
      setError,
      setMode,
      setSearchKeyword,
      toast,
    ]
  );

  const onNewTagCancelling = React.useCallback(() => {
    setMode({
      type: "List",
    });
    setSearchKeyword("");
  }, [setMode, setSearchKeyword]);

  return { onNewTag, onNewTagCancelling, onNewTagSaving };
}

type EditTagProps = {
  setMode: (mode: Mode) => void;
  setSearchKeyword: (keyword: string) => void;
  setError: (error: string) => void;
};
export function useEditTag({
  setMode,
  setSearchKeyword,
  setError,
}: EditTagProps) {
  const toast = useAvelaToast();
  const onEditTag = React.useCallback(
    (tag: Tag) => {
      setMode({ type: "Edit", tag });
    },
    [setMode]
  );

  const [editTag] = useRemoteDataMutation<GQL.EditTag, GQL.EditTagVariables>(
    EDIT_TAG
  );
  const onEditTagSaving = React.useCallback(
    async (tag: Tag, onSuccess?: () => void) => {
      try {
        await editTag({
          variables: {
            tag_id: tag.id,
            name: tag.name.trim(),
            description: tag.description,
          },
          refetchQueries: [GET_TAGS_BY_ENROLLMENT_PERIOD],
        });

        toast({ title: "Tag updated", status: "info", isClosable: true });
        setMode({
          type: "List",
        });

        if (onSuccess) onSuccess();
        setSearchKeyword("");
      } catch (error) {
        if (
          error instanceof Error &&
          error.message.startsWith("Uniqueness violation")
        ) {
          setError("Tag already exists");
        } else {
          toast.error({ title: "Unable to update tag" });
        }
      }
    },
    [editTag, setError, setMode, setSearchKeyword, toast]
  );

  const onEditTagCancelling = React.useCallback(() => {
    setMode({
      type: "List",
    });
    setSearchKeyword("");
  }, [setMode, setSearchKeyword]);

  return { onEditTag, onEditTagCancelling, onEditTagSaving };
}

type DeletTagProps = {
  setIsDirty: (isDirty: boolean) => void;
};
export function useDeleteTag({ setIsDirty }: DeletTagProps) {
  const toast = useAvelaToast();
  const [deleteTag] = useRemoteDataMutation<
    GQL.DeleteTag,
    GQL.DeleteTagVariables
  >(DELETE_TAG);
  const onDeleteTag = React.useCallback(
    async (tag: Tag) => {
      try {
        await deleteTag({
          variables: { tag_id: tag.id },
          refetchQueries: [GET_TAGS_BY_ENROLLMENT_PERIOD],
        });
        setIsDirty(true);
      } catch (error) {
        console.error(error);
        toast.error({ title: "Unable to delete tag" });
      }

      toast({ title: "Tag deleted", status: "info", isClosable: true });
    },
    [deleteTag, setIsDirty, toast]
  );
  return {
    onDeleteTag,
  };
}

function countTags(
  enrollmentPeriodTags: GQL.GetTagsByEnrollmentPeriod,
  allTags: GQL.GetFormTagsAndFormSchoolTags
): Map<uuid, number> {
  // use imperative style for performance
  const counter: Map<uuid, number> = new Map(
    enrollmentPeriodTags.enrollment_period_tag.map((tag) => {
      return [tag.id, 0];
    })
  );

  // count form_school_tag
  for (const currentValue of allTags.tag_by_form_school) {
    if (currentValue.tag_id === null) continue;

    counter.set(
      currentValue.tag_id,
      (counter.get(currentValue.tag_id) ?? 0) + 1
    );
  }

  // count form_tag
  for (const currentValue of allTags.form_tag) {
    if (currentValue.tag_id === null) continue;

    counter.set(
      currentValue.tag_id,
      (counter.get(currentValue.tag_id) ?? 0) + 1
    );
  }
  return counter;
}
