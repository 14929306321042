import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Capacities",
      href: organization
        .map((org) => Url.OrgAdmin.CapacitiesConfig.index(org))
        .withDefault("#"),
    },
  ];
};
