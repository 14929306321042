import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import {
  FormType,
  GlossaryTypeSchema,
} from "src/scenes/admin/glossaries/types";

type Props = {};
export const GlossaryStatusSelect: React.FC<Props> = () => {
  const formik = useFormikContext<FormType>();

  const handleGlossaryStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const result = GlossaryTypeSchema.safeParse(e.target.value);
    if (!result.success) {
      return;
    }

    formik.setValues((values) => {
      switch (result.data) {
        case "Custom":
          return {
            status: "Custom",
            labels: values.labels,
          };

        case "Standard":
          return { ...values, status: "Standard" };
      }
    });
  };
  return (
    <FormControl width="auto">
      <FormLabel>Glossary</FormLabel>
      <Select
        placeholder="Select an option"
        onChange={handleGlossaryStatusChange}
        value={formik.values.status}
      >
        <option value="Custom">Custom</option>
        <option value="Standard">Standard</option>
      </Select>
    </FormControl>
  );
};
