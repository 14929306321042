import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Flex, FormLabel, Icon, Link, Text } from "@chakra-ui/react";
import React from "react";
import { RiEditLine } from "react-icons/ri";
import {
  hasInternalOnlyTag,
  InternalOnlyTag,
} from "src/components/Form/QuestionForm/QuestionExtraInfo/InternalOnlyTag";
import {
  hasRequirementTag,
  RequirementTag,
} from "src/components/Form/QuestionForm/QuestionExtraInfo/RequirementTag";
import {
  hasVerificationTags,
  VerificationTag,
} from "src/components/Form/QuestionForm/QuestionExtraInfo/VerificationTag";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { UnpublishedChangesAlert } from "src/components/Form/QuestionForm/UnpublishedChangesAlert";
import { AvailableAnswers } from "src/components/Form/QuestionForm/View/AvailableAnswers";
import { QuestionTitle } from "src/components/Form/QuestionForm/View/QuestionTitle";
import { QuestionType } from "src/components/Form/QuestionForm/View/QuestionType";
import { Card } from "src/components/Layout/Card";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import { Schools } from "./Schools";

type ViewProps = {
  question: Draft.Question;
  hasChanges: boolean;
  onEditButtonClick?: () => void;
  onRemoveButtonClick?: () => void;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const QuestionFormView: React.FC<ViewProps> = ({
  question,
  hasChanges,
  onEditButtonClick,
  onRemoveButtonClick,
  schools,
}) => {
  return (
    <Card display="flex" flexDirection="column" showBorder gap="3" padding={6}>
      {hasChanges ? <UnpublishedChangesAlert isNew={question.isNew} /> : null}
      <Flex direction="column" gap="3">
        <Tags question={question} />
        <Flex justifyContent="space-between" alignItems="flex-start">
          <QuestionTitle question={question} />

          <Flex direction="row" gap="2">
            {question.isNew && onRemoveButtonClick && (
              <Button
                colorScheme="gray"
                variant="outline"
                onClick={onRemoveButtonClick}
              >
                Remove question
              </Button>
            )}
            {onEditButtonClick && (
              <Button
                colorScheme="gray"
                variant="outline"
                leftIcon={<RiEditLine />}
                onClick={onEditButtonClick}
              >
                Edit question
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex direction="column" gap="4">
          <QuestionType questionType={question.type} />
          <AvailableAnswers
            question={Draft.fromOriginalQuestion(question)}
            schools={schools}
          />
        </Flex>
        <Flex direction="column" gap="1">
          <QuestionLink question={question} />
        </Flex>
        <Schools question={question} schools={schools} />
      </Flex>
    </Card>
  );
};

type QuestionLinkProps = { question: AF.Question<AF.WithId> };
const QuestionLink: React.FC<QuestionLinkProps> = ({ question }) =>
  question.link_text && question.link_url ? (
    <>
      <FormLabel as={Text} margin="0">
        Question link
      </FormLabel>
      <Link
        href={question.link_url}
        target="_blank"
        color="primary.500"
        alignItems="center"
        display="flex"
      >
        {question.link_text}
        <Icon marginLeft="5px" as={ExternalLinkIcon} />
      </Link>
      <Text fontSize="sm" color="gray.500">
        {question.link_url}
      </Text>
    </>
  ) : null;

type TagsProps = { question: AF.Question<AF.WithId> };
const Tags: React.FC<TagsProps> = ({ question }) => {
  if (!hasTags(question)) {
    return null;
  }

  return (
    <Flex direction="row" gap="3">
      <VerificationTag question={question} />
      <RequirementTag question={question} />
      <InternalOnlyTag question={question} />
    </Flex>
  );
};

function hasTags(question: AF.Question<AF.WithId>): boolean {
  return (
    hasVerificationTags(question) ||
    hasRequirementTag(question) ||
    hasInternalOnlyTag(question)
  );
}
