import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { Boundaries } from "src/services/url/Admin";
import { BoundaryGroupsTable } from "./components/BoundaryGroupsTable";

export const ListBoundaryGroupsPage: FunctionComponent = () => {
  return (
    <Flex direction="column" rowGap={6}>
      <Flex alignItems="center">
        <Heading as="h1" size="xl">
          Boundaries and connections
        </Heading>
        <Spacer />
        <Button as={NavLink} to={Boundaries.new()} size="xl">
          Create boundary group
        </Button>
      </Flex>
      <BoundaryGroupsTable />
    </Flex>
  );
};
