import { Flex, Heading } from "@chakra-ui/react";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { Configuration } from "./Configuration";
import { FormTemplateRules } from "./FormTemplateRules";
import { useField } from "formik";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";

export const AdvancedSettings: React.FC = () => {
  const [{ value }] = useField<boolean>("lotteryAndOffersEnabled");
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);
  const showAdvancedSettingsSection = isAvelaAdmin || value;

  return (
    <Flex direction="column" gap={6}>
      {showAdvancedSettingsSection && (
        <Heading fontSize="xl" fontWeight="600" color="gray.700">
          Advanced settings
        </Heading>
      )}

      {showAdvancedSettingsSection && <Configuration />}
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
        <FormTemplateRules />
      </WithRequiredHasuraRoles>
    </Flex>
  );
};
