import {
  Button,
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { Card } from "src/components/Layout/Card";
import { RouterLink } from "src/components/Links/RouterLink";
import { answerIsEmpty } from "src/services/formTemplate/answer";
import {
  ClonedQuestion,
  SingleSelectType,
  WithId,
} from "src/types/formTemplate";
import { OutdatedAnswerAlert } from "./form/OutdatedAnswerAlert";

type ParentGuardianCustomQuestionProps = {
  answerCustomQuestionRoute: string;
  formId: uuid;
  questionId: uuid;
  questionLabel: string;
  nestedQuestions: ClonedQuestion<WithId>[];
  answersByQuestionId: CustomQuestionAnswersByQuestionId;
};

export const ParentGuardianCustomQuestion: FunctionComponent<
  ParentGuardianCustomQuestionProps
> = (props) => {
  const {
    answerCustomQuestionRoute,
    formId,
    questionId,
    questionLabel,
    nestedQuestions,
    answersByQuestionId,
  } = props;

  const { isOpen: outdatedAlertIsOpen, onClose: outdatedAlertOnClose } =
    useDisclosure({
      defaultIsOpen: true,
    });

  const questionHasAnswer = !answerIsEmpty(answersByQuestionId);
  const answersList = getAnswersList({ nestedQuestions, answersByQuestionId });

  return (
    <Flex gap={2} direction="column">
      {outdatedAlertIsOpen && (
        <OutdatedAnswerAlert
          formId={formId}
          questionId={questionId}
          nestedQuestions={nestedQuestions}
          closeAlert={outdatedAlertOnClose}
          refetchAnswers
        />
      )}
      {questionHasAnswer && (
        <LinkBox
          as={Card}
          showBorder
          _hover={{ backgroundColor: "blackAlpha.100" }}
        >
          <LinkOverlay
            as={RouterLink}
            to={answerCustomQuestionRoute}
            _hover={{ textDecoration: "none" }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Flex direction="column">
                {answersList.map((answer) => {
                  return (
                    <Text key={answer} fontSize="md">
                      {answer}
                    </Text>
                  );
                })}
              </Flex>

              <Icon boxSize={4} as={RiArrowRightSLine} />
            </Flex>
          </LinkOverlay>
        </LinkBox>
      )}
      <Button
        as={RouterLink}
        to={answerCustomQuestionRoute}
        size="sm"
        variant="outline"
        colorScheme="gray"
        width="100%"
        _hover={{
          textDecoration: "none",
          backgroundColor: "gray.100",
        }}
      >
        Select {questionLabel}
      </Button>
    </Flex>
  );
};

const NUMBER_OF_ANSWERS_TO_DISPLAY = 2;
const CONTACT_FIELDS_TO_DISPLAY = ["first_name", "last_name", "dob"];

function getAnswersList(
  props: Pick<
    ParentGuardianCustomQuestionProps,
    "nestedQuestions" | "answersByQuestionId"
  >
): string[] {
  const { nestedQuestions, answersByQuestionId } = props;

  // ------------------------------------------------------------
  // special cased logic for SAISD based on the CQT field
  const contactFields = nestedQuestions.filter((question) => {
    return CONTACT_FIELDS_TO_DISPLAY.includes(question.key ?? "");
  });
  const questionIsContact =
    contactFields.length === CONTACT_FIELDS_TO_DISPLAY.length;
  if (questionIsContact) {
    return [
      contactFields
        .slice(0, 2)
        .map((field) => {
          return answersByQuestionId[field.id] ?? "";
        })
        .join(" "),
      contactFields
        .slice(2)
        .map((field) => {
          return answersByQuestionId[field.id] ?? "";
        })
        .join(""),
    ];
  }
  // ------------------------------------------------------------

  return nestedQuestions
    .slice(0, NUMBER_OF_ANSWERS_TO_DISPLAY)
    .map((currentQuestion) => {
      const { id, type } = currentQuestion;
      const answerValue = answersByQuestionId[id] ?? "";
      const answerText =
        type === SingleSelectType
          ? currentQuestion.options.find((option) => option.id === answerValue)
              ?.label ?? ""
          : answerValue;
      return answerText;
    });
}
