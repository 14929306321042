import React from "react";
import { FormTemplateEvent } from "./FormEvent";
import { useHint } from "./useHint";
import { HintModal } from "./HintModal";

type Action = { type: "push"; event: FormTemplateEvent } | { type: "pop" };

type FormTemplateHintContextValue = {
  pushEvent: (event: FormTemplateEvent) => void;
};

const FormTemplateHintContext =
  React.createContext<FormTemplateHintContextValue>({
    pushEvent: () => {},
  });

const emptyEvents: readonly FormTemplateEvent[] = [];
type Props = {
  children: React.ReactNode;
};
export const FormTemplateHintProvider: React.FC<Props> = ({ children }) => {
  const [eventsQueue, dispatch] = React.useReducer(reducer, emptyEvents);
  const { hint } = useHint(eventsQueue);

  const pushEvent = React.useCallback(
    (event: FormTemplateEvent) => {
      dispatch({ type: "push", event });
    },
    [dispatch]
  );

  const popEvent = React.useCallback(() => {
    dispatch({ type: "pop" });
  }, []);

  return (
    <FormTemplateHintContext.Provider value={{ pushEvent }}>
      {children}
      <HintModal hint={hint} onClose={popEvent} />
    </FormTemplateHintContext.Provider>
  );
};

function reducer(
  events: readonly FormTemplateEvent[],
  action: Action
): readonly FormTemplateEvent[] {
  switch (action.type) {
    case "push":
      return [...events, action.event];
    case "pop":
      return events.slice(1);

    default:
      const _exhaustiveCheck: never = action;
      throw new Error("Unknown action" + _exhaustiveCheck);
  }
}

export function useFormTemplateHint() {
  const context = React.useContext<FormTemplateHintContextValue>(
    FormTemplateHintContext
  );

  if (context === undefined) {
    throw new Error("useHint must be used within a FormTemplateHintProvider");
  }

  return context;
}
