import { asRequiredField, nullableWithDefault } from "src/services/zodExtra";
import { z } from "zod";

export const AddressSchema = z.object({
  street_address: nullableWithDefault(z.string(), ""),
  street_address_line_2: nullableWithDefault(z.string(), ""),
  city: nullableWithDefault(z.string(), ""),
  state: nullableWithDefault(z.string(), ""),
  zip_code: nullableWithDefault(z.string(), ""),
});

export const AddressValidator = asRequiredField(
  AddressSchema,
  "street_address",
  "city",
  "state",
  "zip_code"
);

export type AddressFormType = z.infer<typeof AddressSchema>;
