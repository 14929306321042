import { useCallback } from "react";
import { z } from "zod";
import { BULK_UPLOAD_PEOPLE } from "../components/graphql/mutations";
import * as GQL from "../types/graphql";
import { useOrganization } from "./useOrganization";
import { useRemoteDataMutation } from "./useRemoteDataMutation";

export const Person = z.object({
  id: z.string().nullish(),
  reference_id: z.string().nullish(),
  birth_date: z.string().nullish(),
  city: z.string().nullish(),
  first_name: z.string().trim().nullish(),
  last_name: z.string().trim().nullish(),
  middle_name: z.string().trim().nullish(),
  state: z.string().nullish(),
  status: z.string().nullish(),
  street_address: z.string().nullish(),
  user_id: z.string().nullish(),
  zip_code: z.string().nullish(),
  email_address: z.string().nullish(),
  phone_number: z.string().nullish(),
});

export type PersonRow = z.infer<typeof Person>;

export function useBulkUploadPeople(
  personType: keyof typeof GQL.person_type_enum
) {
  const organization = useOrganization();
  const [bulkUploadPeople] = useRemoteDataMutation<
    GQL.BulkUploadPeople,
    GQL.BulkUploadPeopleVariables
  >(BULK_UPLOAD_PEOPLE);

  return useCallback(
    async function (data: unknown[]) {
      let people: PersonRow[];

      try {
        people = data
          .map((row) => Person.parse(row))
          .filter((item) => Object.keys(item).length > 1); // this filter eliminates an entirely blank row created by the newline at the end of the file
      } catch (e) {
        console.warn("File parse error:", e);
        throw new Error("We were unable to parse this file: " + e);
      }
      if (!people.length) {
        throw new Error("No records found in file.");
      }

      const variables: GQL.BulkUploadPeopleVariables = {
        people: people.map(
          (person): GQL.BulkUploadPeopleVariables["people"][0] => ({
            ...person,
            organization_id: organization.map((d) => d.id).toNullable(),
            person_type: GQL.person_type_enum[personType],
          })
        ),
      };
      await bulkUploadPeople({
        variables,
      });
    },
    [personType, organization, bulkUploadPeople]
  );
}
