import { Icon, IconButton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { RiEditLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { RemoteDataTable } from "src/components/Table/RemoteDataTable";
import { getGlossaryStatus } from "src/scenes/admin/glossaries/services";
import { GlossaryPerOrganization } from "src/scenes/admin/glossaries/types";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";

type Props = {
  data: RD.RemoteData<unknown, GQL.GetGlossaryList>;
};
export const GlossaryList: React.FC<Props> = ({ data }) => {
  const glossaries = React.useMemo(
    () =>
      data.map((glossaryData): GlossaryPerOrganization[] => {
        return glossaryData.organization.map((org) => ({
          organizationId: org.id,
          organizationName: org.name,
          glossaryStatus: getGlossaryStatus(org.id, glossaryData.glossary),
        }));
      }),
    [data]
  );

  const columns: ColumnDef<GlossaryPerOrganization>[] = React.useMemo(() => {
    return [
      {
        id: "organization name",
        cell: (props) => props.row.original.organizationName,
        size: undefined,
      },
      {
        id: "glossary",
        size: 150,
        cell: (props) => props.row.original.glossaryStatus,
      },
      {
        id: "actions",
        size: 90, // px
        cell: (props) => {
          return (
            <IconButton
              as={NavLink}
              to={Url.Admin.Glossaries.edit(props.row.original.organizationId)}
              aria-label={`edit glossary for ${props.row.original.organizationName}`}
              icon={<Icon as={RiEditLine} />}
              variant="outline"
              colorScheme="gray"
            />
          );
        },
      },
    ];
  }, []);

  return <RemoteDataTable data={glossaries} columns={columns} />;
};
