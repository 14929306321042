import type { ColumnDef } from "@tanstack/table-core";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { formatUuid } from "src/services/id";
import { getForm } from "../helpers";
import { ColumnId } from "./constants";

type Row = {
  form: { id: uuid } | null;
};
export function buildIdColumnDef<T extends Row>(): ColumnDef<T> {
  return {
    id: ColumnId.ID,
    header: "id",
    cell: (props) => (
      <TextWithOverflowTooltip
        content={formatUuid(getForm(props.row.original).id)}
        maxWidth="9em"
      />
    ),
  };
}
