import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
  IconButton,
  Box,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { GenericError } from "../Feedback/GenericError";
import { useParentOrganizations } from "src/hooks/useOrganizations";
import { useOrganization } from "src/hooks/useOrganization";
import { CheckIcon } from "@chakra-ui/icons";
import { RiArrowDownSLine } from "react-icons/ri";

export const ParentOrganizationSwitcher: React.FC = () => {
  const organization = useOrganization();
  const organizations = useParentOrganizations();
  return (
    <RemoteDataView
      remoteData={organizations}
      error={() => <GenericError />}
      loading={<Skeleton width="100%" height="2.5rem" />}
    >
      {(data) =>
        data.length > 1 ? (
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              colorScheme="gray"
              variant="outline"
              borderColor="gray.300"
              height="auto"
            >
              <Flex alignItems="center" padding="2" paddingLeft="4" gap="2">
                <Flex direction="column" alignItems="flex-start" flexGrow="1">
                  <Box fontSize="0.75rem" fontWeight="400">
                    Active organization
                  </Box>
                  <Box fontSize="1rem" fontWeight="600">
                    {organization.map((org) => org.name).withDefault("")}
                  </Box>
                </Flex>
                <Icon as={RiArrowDownSLine} color="gray.700" boxSize={6} />
              </Flex>
            </MenuButton>
            <MenuList maxHeight="calc(100vh - 20em)" overflowY="auto">
              {data.map((org) => {
                const isSelected = organization
                  .map((currentOrg) => currentOrg.id === org.id)
                  .withDefault(false);
                return (
                  <MenuItem
                    as={NavLink}
                    key={org.path}
                    to={Url.Parent.index(org)}
                    icon={isSelected ? <CheckIcon /> : <></>}
                  >
                    {org.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        ) : null
      }
    </RemoteDataView>
  );
};
