import * as Env from "src/services/env";
import { useOrganizationPath } from "./useOrganizationPath";

export function useClientId(): string | undefined {
  const env = Env.read();
  const organization = useOrganizationPath() ?? "";
  return (
    env.REACT_APP_ORGANIZATION_AUTH0_CLIENT_IDS as Record<string, string>
  )[organization];
}
