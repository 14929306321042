import { HStack, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { ReactElement } from "react";

interface ClosableTagProps {
  label: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size: "sm" | "md" | "lg";
  isDisabled?: boolean;
}

export const ClosableTag = ({
  label,
  onClose,
  size,
  isDisabled = false,
}: ClosableTagProps): ReactElement => {
  return (
    <HStack spacing={4} gap="4px" display="inline-block">
      <Tag
        size={size}
        borderRadius="6px"
        variant="solid"
        colorScheme="gray"
        backgroundColor="gray.300"
        color="black"
        paddingX={size === "lg" ? 3 : 2}
        paddingY={size === "sm" ? "2px" : size === "md" ? "6px" : "4px"}
        fontSize={size === "lg" ? "md" : "sm"}
      >
        <TagLabel>{label}</TagLabel>
        {!isDisabled && <TagCloseButton onClick={onClose} />}
      </Tag>
    </HStack>
  );
};
