import { Box } from "@chakra-ui/react";
import Editor, { Monaco } from "@monaco-editor/react";
import React, { useCallback } from "react";

type Props = {
  height?: string;
  onChangePolicy: (policy: string) => void;
  initialPolicy?: string;
};

export const CedarEditor: React.FC<Props> = ({
  onChangePolicy: handleChangePolicy,
  initialPolicy = "",
  height = "40vh",
  ...props
}) => {
  const handleEditorWillMount = useCallback((monaco: Monaco) => {
    // Register Cedar
    monaco.languages.register({ id: "cedar" });
    monaco.languages.setMonarchTokensProvider("cedar", {
      keywords: [
        "permit",
        "when",
        "unless",
        "in",
        "has",
        "is",
        "like",
        "true",
        "false",
        "if",
        "then",
        "else",
      ],
      red: ["forbid"],
      values: ["principal", "action", "resource", "context"],
      tokenizer: {
        root: [
          [
            /[a-z_$][\w$]*/,
            {
              cases: {
                "@red": "attribute.name",
                "@values": "attribute.value",
                "@keywords": "keyword",
                "@default": "identifier",
              },
            },
          ],
          [/[ |:][A-Z][\w$]*/, "type.identifier"],
          [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
          // numbers
          [/\d*\.\d+([eE][-+]?\d+)?/, "number.float"],
          [/0[xX][0-9a-fA-F]+/, "number.hex"],
          [/\d+/, "number"],
          // whitespace
          { include: "@whitespace" },
        ],
        string: [
          [/[^\\"]+/, "string"],
          [/"/, { token: "string.quote", bracket: "@close", next: "@pop" }],
        ],
        comment: [
          [/[^/*]+/, "comment"],
          [/\/\*/, "comment", "@push"], // nested comment
          ["\\*/", "comment", "@pop"],
          [/[/*]/, "comment"],
        ],
        whitespace: [
          [/[ \t\r\n]+/, "white"],
          [/\/\/.*$/, "comment"],
        ],
      },
    });
  }, []);

  return (
    <Box border="1px solid" borderColor="gray.200">
      <Editor
        height={height}
        width="100%"
        defaultLanguage="cedar"
        defaultValue={initialPolicy}
        beforeMount={handleEditorWillMount}
        onChange={(value) => handleChangePolicy(value || "")}
        options={{
          minimap: { enabled: false },
          fontSize: 16,
        }}
      />
    </Box>
  );
};
