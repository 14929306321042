import { AnswerBankRecord } from "./schemas/AnswerBank";

/**
 * Massages the selectedAnswerBank record into the list of recently used ones.
 * Returns a list of up to MAX_ANSWER_BANK_RECORDS_TO_DISPLAY number of records.
 */
export function normalizeAnswerBankRecords(
  answerBankRecords: AnswerBankRecord[],
  selectedAnswerBankId: uuid,
  formAnswerRecord: AnswerBankRecord
): AnswerBankRecord[] {
  const filteredAnswerBankOptions = answerBankRecords.filter((record) => {
    return record.answerBankId !== selectedAnswerBankId;
  });
  return [formAnswerRecord, ...filteredAnswerBankOptions];
}
