import { Button, Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { AnswersOptions } from "src/components/Form/QuestionForm/Edit/AnswersOptions";
import { PermissionLevel } from "src/components/Form/QuestionForm/Edit/PermissionLevel";
import { QuestionLink } from "src/components/Form/QuestionForm/Edit/QuestionLink";
import { QuestionTitle } from "src/components/Form/QuestionForm/Edit/QuestionTitle";
import { QuestionType } from "src/components/Form/QuestionForm/Edit/QuestionType";
import { Verification } from "src/components/Form/QuestionForm/Edit/Verification";
import {
  FormType,
  getQuestionTypeFormValue,
  removeQuestionFormValues,
} from "src/components/Form/QuestionForm/formik";
import { ConfigureQuestion } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureQuestion";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import * as Question from "src/services/formTemplate/question";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import { QuestionKey } from "./QuestionKey";

type Props = {
  sectionType: AF.SectionType;
  questionId: uuid;
  isNew: boolean;
  optionId: uuid;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  verificationOptions: AF.FormVerification<AF.WithId>[];
};
export const AdditionalQuestion: React.FC<Props> = ({
  sectionType,
  optionId,
  questionId,
  isNew,
  schools,
  verificationOptions,
}) => {
  const form = useFormikContext<FormType>();
  const questionType = getQuestionTypeFormValue(questionId, form.values);

  const removeNewQuestion = React.useCallback(() => {
    if (
      !isNew ||
      form.values.additionalQuestions[optionId]?.questionIds === undefined
    ) {
      return;
    }

    form.setValues((values) => {
      const updatedValues: FormType = {
        ...values,
        additionalQuestions: {
          ...values.additionalQuestions,
          [optionId]: {
            ...values.additionalQuestions[optionId],
            type:
              values.additionalQuestions[optionId]?.questionIds?.length !== 1
                ? values.additionalQuestions[optionId]?.type ?? "disabled"
                : "disabled",
            questionIds:
              values.additionalQuestions[optionId]?.questionIds?.filter(
                (id) => id !== questionId
              ) ?? [],
          },
        },
      };

      return removeQuestionFormValues(updatedValues, questionId);
    });
  }, [form, isNew, optionId, questionId]);

  return (
    <Flex direction="column" gap="3">
      <Flex alignItems="flex-end" direction="row" gap="2">
        <QuestionTitle questionId={questionId} />
        <QuestionKey questionId={questionId} />

        {isNew && (
          <Button
            colorScheme="gray"
            flexShrink={0}
            onClick={removeNewQuestion}
            variant="outline"
          >
            Remove question
          </Button>
        )}
      </Flex>

      <ConfigureQuestion questionId={questionId} questionType={questionType} />

      <Verification
        questionId={questionId}
        verificationOptions={verificationOptions ?? []}
      />

      <QuestionLink questionId={questionId} />
      <PermissionLevel questionId={questionId} />
      <QuestionType questionId={questionId} isDisabled={isNew ? false : true} />

      {Question.hasOptions({ type: questionType }) && (
        <AnswersOptions
          sectionType={sectionType}
          questionId={questionId}
          schools={schools}
          showAdditionalQuestions={false}
          verificationOptions={verificationOptions}
          question={undefined}
        />
      )}
    </Flex>
  );
};
