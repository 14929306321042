import { Flex, ListItem, Tag, Text, UnorderedList } from "@chakra-ui/react";
import { Glossary } from "src/components/Text/Glossary";
import * as GQL from "src/types/graphql";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_MESSAGE_TEMPLATE_TYPES } from "../EditFormTemplateTabs/AutomaticMessages/graphql/queries";
import { formatMessageTemplates } from "../EditFormTemplateTabs/AutomaticMessages/List/helpers";
import { MESSAGE_TEMPLATES } from "src/constants";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";

interface Props {
  formTemplate: GQL.GetFormTemplatesByEnrollmentPeriod_form_template;
}

export const FormTemplateSummary: React.FC<Props> = ({ formTemplate }) => {
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageTemplateTypes,
    GQL.GetMessageTemplateTypesVariables
  >(GET_MESSAGE_TEMPLATE_TYPES, {
    variables: {
      formTemplateId: formTemplate.id
    },
    skip: !formTemplate.id || !isAvelaAdmin
  });

  const messageTemplates = remoteData.hasData()
    ? formatMessageTemplates(remoteData.data, formTemplate.id)
    : null;

  return (
    <Flex
      direction="column"
      gap={4}
      maxH={200}
      overflow="scroll"
      overflowX="hidden"
    >
      <Text>
        <Glossary>Review the form and its contents before activating:</Glossary>
      </Text>

      <UnorderedList>
        <ListItem mb={2}>
          <Flex gap={2} align="center">
            <Text>Offers & lottery</Text>
            <Tag
              size="sm"
              colorScheme={
                formTemplate.lottery_offers_enabled ? "green" : "gray"
              }
            >
              {formTemplate.lottery_offers_enabled ? "On" : "Off"}
            </Tag>
          </Flex>
        </ListItem>
        <ListItem mb={2}>
          <Flex gap={2} align="center">
            <Text>Accept new form entries</Text>
            <Tag size="sm" colorScheme={formTemplate.locked ? "gray" : "green"}>
              {formTemplate.locked ? "Off" : "On"}
            </Tag>
          </Flex>
        </ListItem>
        {isAvelaAdmin && (
          <ListItem>
            <Text mb={2}>Automatic messages</Text>
            <UnorderedList>
              {messageTemplates?.map((message, index) => (
                <ListItem key={index} mb={2}>
                  <Flex gap={2} align="center">
                    <Text>{MESSAGE_TEMPLATES[message.type]?.label}</Text>
                    <Tag
                      size="sm"
                      colorScheme={message.enabled ? "green" : "gray"}
                    >
                      {message.enabled ? "On" : "Off"}
                    </Tag>
                  </Flex>
                </ListItem>
              ))}
            </UnorderedList>
          </ListItem>
        )}
      </UnorderedList>
    </Flex>
  );
};
