import {
  Badge,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "formik";
import { PhoneInput, Props as PhoneInputProps } from "./PhoneInput";

export type Props = PhoneInputProps & {
  badge?: string;
  label?: string;
};

export const PhoneInputWithBadge: React.FC<Props> = ({
  badge,
  label,
  isRequired,
  ...props
}) => {
  const [, meta] = useField<string>(props.id);
  return (
    <FormControl isInvalid={meta.error !== undefined}>
      <FormLabel
        htmlFor={props.id}
        display="flex"
        alignItems="baseline"
        marginBottom="0.5rem"
      >
        <Box fontSize="sm">{label}</Box>
        {badge && (
          <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
            {badge ? badge : ""}
          </Badge>
        )}
      </FormLabel>
      <PhoneInput {...props} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
