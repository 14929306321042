import {
  CreateToastFnReturn,
  ToastId,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect } from "react";
import { WeglotText } from "../components";
import { WEGLOT_SCRIPT_ID } from "../constants";
import { WeglotType } from "../types";
import { createWeglotScript } from "../utils";

export const WeglotContext = createContext<WeglotType | undefined>(
  window.Weglot
);

export const useWeglotToast = (): CreateToastFnReturn => {
  const toast = useToast();
  const weglotToast = (options?: UseToastOptions): ToastId => {
    return toast({
      ...options,
      title: options?.title && <WeglotText>{options?.title}</WeglotText>,
      description: options?.description && (
        <WeglotText>{options?.description}</WeglotText>
      ),
    });
  };
  weglotToast.update = toast.update;
  weglotToast.promise = toast.promise;
  weglotToast.closeAll = toast.closeAll;
  weglotToast.close = toast.close;
  weglotToast.isActive = toast.isActive;
  return weglotToast;
};

export const useWeglotInject = (loaded: () => void): HTMLScriptElement => {
  const weglotSource = createWeglotScript(loaded);
  useEffect(() => {
    document.getElementById(WEGLOT_SCRIPT_ID) ||
      document.body.appendChild(weglotSource);
    return () => {
      !document.getElementById(WEGLOT_SCRIPT_ID) &&
        document.body.removeChild(weglotSource);
    };
  }, [weglotSource]);
  return weglotSource;
};

export const useWeglot = (): WeglotType | undefined =>
  useContext(WeglotContext);
