import { Box, Button } from "@chakra-ui/react";
import { setNestedObjectValues, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { FunctionComponent, useCallback } from "react";
import {
  AddressValidationResult,
  getSuggestedAddress,
} from "src/components/Boundary/services";
import { CompositeAddressField } from "./CompositeAddressField";
import { BaseAddress } from "./schema";

type EnterAddressProps = {
  addressFieldsAreRequired?: boolean;
  setAddressValidationResult: (
    result: AddressValidationResult | undefined
  ) => void;
};

/**
 * A pseudo-form for Addresses that is used as a nested form:
 * - Has a button that mimics type="submit" button.
 * - Doesn't use a nested <form> element, preserving the html as valid.
 */
export const EnterAddress: FunctionComponent<EnterAddressProps> = (props) => {
  const { addressFieldsAreRequired, setAddressValidationResult } = props;
  const { values, submitForm, validateForm, setTouched } =
    useFormikContext<BaseAddress>();

  const validateAndSaveAddress = useCallback(async () => {
    // mimic submit behavior without submitting
    // https://github.com/jaredpalmer/formik/issues/2734
    const validationErrors = await validateForm();
    if (!isEmpty(validationErrors)) {
      setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }

    const addressValidationVerdict = await getSuggestedAddress(
      new google.maps.Geocoder(),
      values
    );

    if (addressValidationVerdict.wasFound && addressValidationVerdict.isValid) {
      // exact match found, entered address is upserted and joins the AddressBook
      return await submitForm();
    }

    // partial validation or no validation
    setAddressValidationResult(addressValidationVerdict);
  }, [
    validateForm,
    setTouched,
    values,
    submitForm,
    setAddressValidationResult,
  ]);

  return (
    <Box padding={4}>
      <CompositeAddressField fieldsAreRequired={addressFieldsAreRequired} />
      <Button
        size="sm"
        colorScheme="gray"
        width="100%"
        onClick={validateAndSaveAddress}
      >
        Save address
      </Button>
    </Box>
  );
};
