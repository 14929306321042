import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { FormikUploadFileInput } from "src/components/Inputs/Formik/FormikUploadFileInput";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { getBreadcrumbsForEdit } from "src/services/breadcrumb/boundaries";
import { validateWithZod } from "src/services/formValidations";
import { Boundaries } from "src/services/url/Admin";
import {
  CreateBoundaryGroup,
  CreateBoundaryGroupVariables,
} from "src/types/graphql";
import { BoundaryConfigUploadTable } from "./components/BoundaryConfigUploadTable";
import { CREATE_BOUNDARY_GROUP } from "./graphql/mutations";
import { GET_BOUNDARY_GROUP_LIST } from "./graphql/queries";
import { BoundaryGroupConfigSchema, UploadBoundaryConfigForm } from "./schemas";
import { parseBoundaryConfigFiles } from "./services";

export const CreateBoundaryGroupPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const [createBoundaryGroup] = useRemoteDataMutation<
    CreateBoundaryGroup,
    CreateBoundaryGroupVariables
  >(CREATE_BOUNDARY_GROUP, { refetchQueries: [GET_BOUNDARY_GROUP_LIST] });

  const toast = useAvelaToast();

  const handleOnSubmit = async (values: UploadBoundaryConfigForm) => {
    const payload = await parseBoundaryConfigFiles(values);
    try {
      await createBoundaryGroup({
        variables: {
          geojson: payload,
        },
      });
      navigate(Boundaries.index());
      toast({
        id: "boundary-group-create-success",
        title: "Boundary group saved",
        isClosable: true,
        status: "success",
      });
    } catch (e) {
      toast({
        id: "boundary-group-create-error",
        title: "Error creating boundary group",
        description: `We were unable to create the boundary group: ${e}`,
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <Formik<UploadBoundaryConfigForm>
      initialValues={{
        geojson: new File([""], "new.geojson"),
        connections: new File([""], "new.json"),
      }}
      validate={validateWithZod(BoundaryGroupConfigSchema)}
      onSubmit={handleOnSubmit}
    >
      <Flex as={Form} direction="column" rowGap={6} minHeight="100%">
        <Breadcrumb items={getBreadcrumbsForEdit()} />
        <Flex alignItems="center">
          <Heading as="h1" size="xl">
            New boundary group
          </Heading>
          <Spacer />
          <Flex columnGap={6}>
            <FormikUploadFileInput
              name="geojson"
              accept=".geojson"
              buttonLabel="Upload GeoJSON"
            />
            <FormikUploadFileInput
              name="connections"
              accept=".json"
              buttonLabel="Upload connections"
            />
          </Flex>
        </Flex>
        <BoundaryConfigUploadTable />
        <Spacer />
        <AdminFormButtons>
          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => {
              navigate(Boundaries.index());
            }}
          >
            Back
          </Button>
          <Spacer />
          <Button type="submit">Save boundary group</Button>
        </AdminFormButtons>
      </Flex>
    </Formik>
  );
};
