import { Button, HStack, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { Ref } from "react";
import { validateWithZod } from "src/services/formValidations";
import { FormSchema, FormType } from "./Form";
import { NameInput } from "./NameInput";
import { Tag } from "./types";

type Props = {
  onSaving?: (tag: Tag) => Promise<void>;
  onCancelling?: () => void;
  tag: Readonly<Tag>;
  inputRef?: Ref<HTMLInputElement>;
  error?: string;
  clearError?: () => void;
};

export const Edit: React.FC<Props> = ({
  tag,
  onSaving,
  onCancelling,
  inputRef,
  error,
  clearError,
}) => {
  const [saving, setSaving] = React.useState(false);
  const initialValues: FormType = { name: tag.name };
  const handleSubmit = React.useCallback(
    async (values: FormType) => {
      if (onSaving) {
        setSaving(true);
        await onSaving({ ...tag, name: values.name });
        setSaving(false);
      }
    },
    [onSaving, tag]
  );

  return (
    <Formik<FormType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateWithZod(FormSchema)}
      validateOnBlur={false}
    >
      {(formik) => (
        <Stack as={Form} padding="4" spacing="8">
          <NameInput
            label="Rename tag"
            ref={inputRef}
            error={error}
            clearError={clearError}
          />
          <HStack justifyContent="flex-end">
            <Button
              type="button"
              variant="ghost"
              colorScheme="gray"
              onClick={onCancelling}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isDisabled={!formik.isValid || !formik.dirty}
              loadingText="Updating tag"
              isLoading={saving}
            >
              Update tag
            </Button>
          </HStack>
        </Stack>
      )}
    </Formik>
  );
};
