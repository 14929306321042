import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import * as Router from "react-router-dom";
import { Outlet } from "react-router-dom";
import { AuthorizationProvider } from "src/components/Providers/AuthorizationProvider";
import useAccessToken from "src/hooks/useAccessToken";
import { GlossaryContextProvider } from "src/hooks/useGlossary";
import { useLoginMethods } from "src/hooks/useLoginMethods";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import { UserPermissionsContextProvider } from "src/hooks/useUserPermissions";
import * as Url from "src/services/url";
import { isParentPortalPath } from "src/services/url";
import { Status } from "src/types/authData";
import { EXPLORE_SCHOOLS_TOKEN } from "src/types/searchParams";
import { ApolloProviderWithCredential } from "../Providers/ApolloProviderWithCredential";
import { useFlags } from "../Providers/FeatureFlagProvider";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import { LoginError } from "../Feedback/LoginError";

export function RequireAuth() {
  const { loginWithRedirect } = useAuth0();
  const accessToken = useAccessToken();
  const location = Router.useLocation();
  const params = Router.useParams();
  const [searchParams] = Router.useSearchParams();
  const isParent = isParentPortalPath(location.pathname);
  const loginMethods = useLoginMethods(isParent ? "parent" : "admin");
  const accountLookupConfig = useOrgConfig("AccountLookup");
  const org = useOrganizationPath();
  const flags = useFlags(["account-lookup"]);
  const [showLoginError, setShowLoginError] = React.useState(false);

  const exploreSavedSchoolRefIDs = decodeURIComponent(
    searchParams.get(EXPLORE_SCHOOLS_TOKEN) ?? ""
  )
    .split(",")
    .filter((x) => x);

  React.useEffect(() => {
    async function redirectIfUnauthenticated() {
      const accountLookupUrl =
        flags["account-lookup"].enabled &&
        org !== undefined &&
        accountLookupConfig.toNullable()?.disabled !== true
          ? `${window.location.origin}${Url.Parent.AccountLookup.index({
              path: org,
            })}`
          : undefined;

      if (accessToken.status === Status.UNAUTHENTICATED) {
        if (loginMethods.hasError()) {
          console.error(
            `Fatal error, unable to get Login config: ${loginMethods.error}`
          );
          setShowLoginError(true);
          return;
        }

        if (!loginMethods.hasData()) {
          return;
        }

        const page = searchParams.get("page");
        const method = searchParams.get("method");
        const hide = searchParams.get("hide");

        // passing avelaOrganization to scope for pre-registration flow
        // as documented here: https://community.auth0.com/t/receive-params-in-pre-user-registration-action/123340
        const scope = `avelaOrganization:${org}`;

        await loginWithRedirect({
          loginSource: window.location.hostname,
          avelaOrganization: org,
          scope,
          page,
          method,
          hide,
          isParent: isParentPortalPath(location.pathname),
          loginMethods: JSON.stringify(loginMethods.data),
          accountLookupUrl,
          hasExploreSavedSchools: !!exploreSavedSchoolRefIDs.length,
          appState: {
            returnTo: `${location.pathname}${location.search}`,
          },
        });
      }
    }
    redirectIfUnauthenticated();
  }, [
    accessToken.status,
    loginWithRedirect,
    location,
    params,
    loginMethods,
    searchParams,
    exploreSavedSchoolRefIDs,
    accountLookupConfig,
    org,
    flags,
  ]);

  if (showLoginError) {
    return <LoginError />;
  }

  return (
    <ApolloProviderWithCredential>
      <AuthorizationProvider>
        <UserPermissionsContextProvider>
          <GlossaryContextProvider>
            <Outlet />
          </GlossaryContextProvider>
        </UserPermissionsContextProvider>
      </AuthorizationProvider>
    </ApolloProviderWithCredential>
  );
}
