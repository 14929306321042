import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS_BY_USER_ID = gql`
  query GetOrganizationsByUserId($userId: String!) {
    organization(
      where: { people: { user_id: { _eq: $userId } } }
      order_by: { name: asc }
    ) {
      name
      path
      id
    }
  }
`;
