import { html } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/utils";
import * as GQL from "src/types/graphql";

export const WAITLIST_REMOVED = {
  type: GQL.message_template_type_enum.WaitlistRemoved,
  emailSubject:
    "You have been removed from the waitlist at {{organization.name}}/Has sido removido de la lista de espera de {{organization.name}}",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    {{student.name}} has been removed from the waitlist at
    {{waitlist.schoolName}}. If you have any questions please contact
    {{supportEmail}}. <br />
    <br />
    -{{organization.name}} Team<br />
    —---<br />
    <br />
    Estimado/a {{parent.name}},<br />
    <br />
    {{student.name}} ha sido retirado/a de la lista de espera en
    {{waitlist.schoolName}}. Si tiene alguna pregunta, por favor contacte a
    {{supportEmail}}.<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}},

{{student.name}} has been removed from the waitlist at {{waitlist.schoolName}}. If you have any questions please contact {{supportEmail}}. 

{{organization.name}} Team

——-

Estimado/a {{parent.name}},

{{student.name}} ha sido retirado/a de la lista de espera en {{waitlist.schoolName}}. Si tiene alguna pregunta, por favor contacte a {{supportEmail}}.

Equipo de {{organization.name}}`,
  sms: "{{student.name}} has been removed from the waitlist at {{waitlist.schoolName}} / {{student.name}} fue removido de la lista de espera en {{waitlist.schoolName}}"
};
