import {
  Flex,
  FormLabel,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as RD from "src/types/remoteData";
import { SchoolItem } from "../types";

type Props = {
  question: Draft.Question;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const Schools: React.FC<Props> = ({ question, schools }) => {
  return question.specificToSchools && question.specificToSchools.length > 0 ? (
    <RemoteDataView remoteData={schools} error={() => <GenericError />}>
      {(schools) => (
        <Flex direction="column" gap="2">
          <FormLabel as={Text} margin="0">
            Displayed when school selected
          </FormLabel>
          <Text color="blackAlpha.700">
            The question is revealed only when the following schools are
            selected:
          </Text>
          <UnorderedList color="blackAlpha.700">
            {toSchoolStrings(question.specificToSchools ?? [], schools).map(
              (schoolString) => (
                <ListItem key={schoolString}>{schoolString}</ListItem>
              )
            )}
          </UnorderedList>
        </Flex>
      )}
    </RemoteDataView>
  ) : (
    <></>
  );
};

function toSchoolStrings(schoolIds: uuid[], schools: SchoolItem[]): string[] {
  return schoolIds.map((schoolId) => {
    const school = schools.find((school) => school.id === schoolId);
    if (!school) {
      return "";
    }
    return `${school.label} | Id: ${school.id}`;
  });
}
