import { Skeleton } from "@chakra-ui/react";
import { RiFilePaper2Line } from "react-icons/ri";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GenericError } from "../Feedback/GenericError";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { useFormTemplates } from "../Providers/FormTemplateProvider";
import { NavItem } from "./NavItem";
import { NavLinkItem } from "./NavLinkItem";

interface NavFormsListProps {
  organization: GQL.Organization;
}

export function NavFormsList({ organization }: NavFormsListProps) {
  const { navFormTemplates } = useFormTemplates();

  return (
    <>
      <NavItem label="Forms" leadIcon={RiFilePaper2Line} />
      <RemoteDataView
        remoteData={navFormTemplates}
        error={() => <GenericError />}
        loading={<Skeleton width="16rem" height="2.25rem" />}
      >
        {(data) => (
          <>
            {data.map((formTemplate) => (
              <NavLinkItem
                key={formTemplate.id}
                label={formTemplate.name}
                leadIcon={RiFilePaper2Line}
                leadIconVisibility="compact"
                url={Url.OrgAdmin.Forms.index({
                  organization,
                  formTemplateId: formTemplate.id,
                })}
              />
            ))}
          </>
        )}
      </RemoteDataView>
    </>
  );
}
