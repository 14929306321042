import {
  Box,
  ComponentWithAs,
  Flex,
  Icon,
  IconProps,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { AnimatePresence, AnimationProps, MotionProps } from "framer-motion";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import { MotionBox } from "src/animations/MotionBox";
import {
  useAnimatePresenceWithReduceMotion,
  useAnimationWithReduceMotion,
} from "src/hooks/useAnimation";
import { useGlossary } from "src/hooks/useGlossary";
import { useNavLinkState } from "src/hooks/useNavLinkState";
import { AnimationContext } from "./AnimationContext";
import { Mode } from "./types";

export type NavItemIconVisibility = "all" | Mode;
export type NavItemIconType = IconType | ComponentWithAs<"svg", IconProps>;

export type NavItemElement = React.ReactElement<NavItemProps>;
export interface NavItemProps {
  url?: string;
  label: string;
  leadIcon?: NavItemIconType;
  trailIcon?: NavItemIconType;
  leadIconVisibility?: NavItemIconVisibility;
  trailIconVisibility?: NavItemIconVisibility;
  isExternal?: boolean;
  showSpinner?: boolean;
}
export function NavItem({
  url,
  label: originalLabel,
  leadIcon,
  trailIcon,
  leadIconVisibility = "all",
  trailIconVisibility = "all",
  isExternal,
  showSpinner,
}: NavItemProps) {
  const state = useNavLinkState(url);
  const animate = useAnimationWithReduceMotion();
  const animatePresence = useAnimatePresenceWithReduceMotion();
  const { glossary } = useGlossary();
  const label = glossary`${originalLabel}`;

  return (
    <AnimationContext.Consumer>
      {({ mode, disableAnimation = true }) => {
        const itemProps = {
          padding: "2",
          width: "100%",
          borderRadius: "5",
          backgroundColor: state === "active" ? "primary.100" : "none",
          color: state === "active" ? "primary.500" : undefined,
          "aria-current": state === "active",
          transition: "background-color .25s",
          title: mode === "compact" ? label : undefined,
        };
        let item;
        if (url === undefined) {
          item = (
            <Flex {...itemProps}>
              {children({
                mode,
                label,
                leadIcon,
                trailIcon,
                leadIconVisibility,
                trailIconVisibility,
                disableAnimation,
                showSpinner,
                animatePresence,
              })}
            </Flex>
          );
        } else if (isExternal) {
          item = (
            <Link href={url} isExternal {...itemProps}>
              {children({
                mode,
                label,
                leadIcon,
                trailIcon,
                leadIconVisibility,
                trailIconVisibility,
                disableAnimation,
                showSpinner,
                animatePresence,
              })}
            </Link>
          );
        } else {
          item = (
            <Link as={NavLink} to={url} {...itemProps}>
              {children({
                mode,
                label,
                leadIcon,
                trailIcon,
                leadIconVisibility,
                trailIconVisibility,
                disableAnimation,
                showSpinner,
                animatePresence,
              })}
            </Link>
          );
        }

        return (
          <MotionBox
            disableAnimation={disableAnimation}
            display="flex"
            as="li"
            margin="0"
            padding="0"
            justifyContent="center"
            alignSelf="stretch"
            layout
            {...animate(
              mode === "compact" ? { width: "2.25rem" } : { width: "100%" }
            )}
            transition={{ type: "inertia" }}
          >
            {item}
          </MotionBox>
        );
      }}
    </AnimationContext.Consumer>
  );
}

interface ChildrenProps {
  mode: Mode;
  label: string;
  leadIcon?: NavItemIconType;
  trailIcon?: NavItemIconType;
  leadIconVisibility?: NavItemIconVisibility;
  trailIconVisibility?: NavItemIconVisibility;
  disableAnimation: boolean;
  showSpinner?: boolean;
  animatePresence: (props: MotionProps) => AnimationProps;
}

function children({
  mode,
  label,
  leadIcon,
  trailIcon,
  leadIconVisibility,
  trailIconVisibility,
  disableAnimation,
  showSpinner,
  animatePresence,
}: ChildrenProps) {
  return (
    <Flex alignItems="center" gap="4">
      {showSpinner && <Spinner />}
      {leadIcon &&
        (mode === leadIconVisibility || leadIconVisibility === "all") && (
          <Icon
            w="5"
            h="5"
            as={leadIcon}
            color="primary.500"
            aria-hidden="true"
          />
        )}
      {mode === "full" && leadIconVisibility === "compact" && (
        <Box width="5"></Box>
      )}
      <AnimatePresence initial={false}>
        {mode === "full" && (
          <MotionBox
            disableAnimation={disableAnimation}
            as={Text}
            display="flex"
            alignItems="center"
            fontWeight="medium"
            fontSize="sm"
            {...animatePresence({
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              transition: { delay: 0.15 },
            })}
          >
            {label}
          </MotionBox>
        )}
      </AnimatePresence>
      {(mode === trailIconVisibility || trailIconVisibility === "all") &&
        trailIcon && (
          <Icon
            w="5"
            h="5"
            as={trailIcon}
            color="primary.500"
            aria-hidden="true"
          />
        )}
    </Flex>
  );
}
