import { FormikErrors, FormikTouched } from "formik";
import { GLOSSARY_TYPES } from "src/hooks/useGlossary/types";
import {
  CustomFormType,
  CustomLabelType,
  FormType,
  GlossaryStatus,
} from "src/scenes/admin/glossaries/types";
import * as GQL from "src/types/graphql";

export function initialFormValues(
  organizationId: uuid,
  gql: GQL.GetGlossaryByOrganization
): FormType {
  const customGlossary = gql.glossary_by_organization.filter(
    (g) => g.organization_id === organizationId
  );

  if (customGlossary.length > 0) {
    const customLabels = customGlossary.reduce((acc, value) => {
      if (value.glossary?.type) {
        return {
          ...acc,
          [value.glossary?.type]: {
            singular: value.alias_singular ?? "",
            plural: value.alias_plural ?? "",
          },
        };
      }
      return acc;
    }, {});

    return {
      status: "Custom",
      labels: customLabels,
    };
  }

  const defaultGlossary = gql.glossary_by_organization.filter(
    (g) => g.organization_id === null
  );

  const defaultLabels = defaultGlossary.reduce((acc, value) => {
    if (value.glossary?.type) {
      return {
        ...acc,
        [value.glossary?.type]: {
          singular: value.default_singular ?? "",
          plural: value.default_plural ?? "",
        },
      };
    }
    return acc;
  }, {});

  return {
    status: "Standard",
    labels: defaultLabels,
  };
}

export function getGlossaryStatus(
  orgId: uuid,
  glossary: GQL.GetGlossaryList_glossary[]
): GlossaryStatus {
  const hasCustom =
    glossary.find(
      (item) =>
        item.organization_id === orgId ||
        item.enrollment_period?.organization_id === orgId
    ) !== undefined;
  return hasCustom ? "Custom" : "Standard";
}

/**
 * Workaround on weird FormikErrors behavior failing to detect nested record type from zod.
 */
export function getGlossaryTypeError(
  glossaryType: GQL.glossary_type_enum,
  errors: FormikErrors<FormType>
): FormikErrors<CustomLabelType> {
  const error = errors.labels?.[glossaryType];
  return isGlossaryTypeErrors(error) ? error : {};
}

function isGlossaryTypeErrors(
  values: unknown
): values is FormikErrors<CustomLabelType> {
  return (
    typeof values === "object" &&
    values !== null &&
    ("singular" in values || "plural" in values)
  );
}

/**
 * Workaround on weird FormikTouched behavior failing to detect nested record type from zod.
 */
export function getGlossaryTypeTouched(
  glossaryType: GQL.glossary_type_enum,
  touched: FormikTouched<FormType>
): FormikTouched<CustomLabelType> {
  const touchedForGlossaryType = touched.labels?.[glossaryType];
  return isGlossaryTypeTouched(touchedForGlossaryType)
    ? touchedForGlossaryType
    : {};
}

function isGlossaryTypeTouched(
  values: unknown
): values is FormikTouched<CustomLabelType> {
  return (
    typeof values === "object" &&
    values !== null &&
    ("singular" in values || "plural" in values)
  );
}

export function toInsertMutations(
  organizationId: uuid,
  values: CustomFormType
): GQL.glossary_insert_input[] {
  return GLOSSARY_TYPES.map((glossaryType) =>
    toGlossaryInsertInput(glossaryType, organizationId, values)
  );
}

function toGlossaryInsertInput(
  glossaryType: GQL.glossary_type_enum,
  organizationId: uuid,
  values: CustomFormType
): GQL.glossary_insert_input {
  return {
    type: glossaryType,
    organization_id: organizationId,
    singular: values.labels[glossaryType]?.singular,
    plural: values.labels[glossaryType]?.plural,
  };
}
