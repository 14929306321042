import { z } from "zod";

export const MemberSchema = z.object({
  id: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
});

export type MemberType = z.infer<typeof MemberSchema>;

export const emptyTeam: TeamFormType = {
  name: "",
};

export const emptyTeamPerson: TeamPersonFormType = {
  team_id: "",
  person_id: "",
};

export const TeamSchema = z.object({
  name: z.string(),
});

export const TeamPersonSchema = z.object({
  team_id: z.string(),
  person_id: z.string(),
});

export const TeamValidator = {};

export type TeamFormType = z.infer<typeof TeamSchema>;

export type TeamPersonFormType = z.infer<typeof TeamPersonSchema>;
