import { ApolloError } from "@apollo/client";
import { FieldHelperProps } from "formik";
import { omit } from "lodash";
import { GetUserAddresses } from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import {
  AddressBookAnswer,
  BaseAddress,
  CurrentAddress,
  CURRENT_ADDRESS,
  ExistingAddress,
  EXISTING_ADDRESS,
  NO_ADDRESS,
} from "./modules/schema";

export function isAddressBlank(value: BaseAddress | undefined): boolean {
  if (value === undefined) {
    return true;
  }

  const { street_address, city, state, zip_code } = value;
  return (
    street_address === "" && city === "" && state === "" && zip_code === ""
  );
}

type RecentUserAddresses = (CurrentAddress | ExistingAddress)[];

/**
 * The address list displays three user_addresses:
 * - When initial address is blank, display three recently used user_addresses
 * - When initial address is not blank, display it above latest two addresses and omit duplicate
 */
export function getRecentUserAddresses(
  initialAddress: AddressBookAnswer | undefined,
  remoteAddressData: RemoteData<ApolloError, GetUserAddresses>
): RecentUserAddresses {
  const recentAddresses = remoteAddressData
    .map((data) =>
      data.user_address.map((address) => {
        return omit(address, "__typename");
      })
    )
    .withDefault([]);

  if (initialAddress === undefined || isAddressBlank(initialAddress)) {
    return recentAddresses.map(
      (address): ExistingAddress => ({
        ...address,
        kind: EXISTING_ADDRESS,
        user_address_id: address.id,
      })
    );
  }

  return [
    { ...initialAddress, kind: CURRENT_ADDRESS },
    ...recentAddresses
      .filter((address) => {
        return !areMatchingAddresses(initialAddress, address);
      })
      .map(
        (address): ExistingAddress => ({
          ...address,
          kind: EXISTING_ADDRESS,
          user_address_id: address.id,
        })
      )
      .slice(0, 2),
  ];
}

export function areMatchingAddresses(
  initialAddress: BaseAddress | undefined,
  comparisonAddress: BaseAddress
): boolean {
  if (initialAddress === undefined) {
    return false;
  }

  const {
    street_address: initialStreetAddress,
    street_address_line_2: initialStreetAddressLine2,
    city: initialCity,
    state: initialState,
    zip_code: initialZipCode,
  } = initialAddress;

  const { street_address, street_address_line_2, city, state, zip_code } =
    comparisonAddress;

  return (
    street_address === initialStreetAddress &&
    street_address_line_2 === initialStreetAddressLine2 &&
    city === initialCity &&
    state === initialState &&
    zip_code === initialZipCode
  );
}

type OnAddressChangeProps = {
  selectedAddressKey: string;
  recentUserAddresses: RecentUserAddresses;
  userId: string | undefined;
  helpers: FieldHelperProps<AddressBookAnswer>;
};

export async function onAddressChange(props: OnAddressChangeProps) {
  const {
    selectedAddressKey,
    recentUserAddresses,
    userId,
    helpers: { setTouched, setValue },
  } = props;

  const selectedAddress: AddressBookAnswer = {
    ...determineSelectedAddress({
      selectedAddressKey,
      recentUserAddresses,
    }),
    user_id: userId,
  };

  if (!selectedAddress) {
    return;
  }

  // set touched to true but don't run validation yet to avoid validation run multiple times with stale data
  // see: https://github.com/jaredpalmer/formik/issues/2083#issuecomment-884831583
  setTouched(true, false);
  setValue(selectedAddress);
}

type DetermineSelectedAddressProps = Pick<
  OnAddressChangeProps,
  "selectedAddressKey" | "recentUserAddresses"
>;

function determineSelectedAddress(
  props: DetermineSelectedAddressProps
): AddressBookAnswer {
  const { selectedAddressKey, recentUserAddresses } = props;

  const noAddressAnswer: AddressBookAnswer = {
    kind: NO_ADDRESS,
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  };

  if (selectedAddressKey === NO_ADDRESS) {
    return noAddressAnswer;
  }

  if (selectedAddressKey === CURRENT_ADDRESS) {
    const currentAddress = recentUserAddresses.find(
      (address) => address.kind === CURRENT_ADDRESS
    );

    if (!currentAddress) {
      return noAddressAnswer;
    }

    return currentAddress;
  }

  const selectedAddress = recentUserAddresses.find((option) => {
    return (
      option.kind === EXISTING_ADDRESS &&
      option.user_address_id === selectedAddressKey
    );
  });

  if (!selectedAddress) {
    return noAddressAnswer;
  }

  return selectedAddress;
}
