import { gql } from "@apollo/client";
export const CREATE_FORM_TEMPLATE = gql`
  mutation CreateFormTemplate($form_template: form_template_insert_input!) {
    insert_form_template_one(object: $form_template) {
      id
    }
  }
`;

export const INSERT_FORM_VERIFICATIONS = gql`
  mutation InsertFormVerifications(
    $inserts: [form_verification_insert_input!]!
  ) {
    insert_form_verification(objects: $inserts) {
      affected_rows
      returning {
        id
        label
      }
    }
  }
`;

export const UPDATE_QUESTIONS_AND_SECTIONS = gql`
  mutation UpdateQuestionsAndSections(
    $eligibilityQuestionSchoolsDeletes: eligibility_question_school_bool_exp = {
      id: { _is_null: true }
    }
    $eligibilityQuestionSchoolsInserts: [eligibility_question_school_insert_input!] = [

    ]
    $questionUpdates: [question_updates!] = []
    $disclaimerUpdates: [disclaimer_section_updates!] = []
    $schoolRankingUpdates: [schools_ranking_section_updates!] = []
    $formQuestionUpdates: [form_question_updates!] = []
    $questionOptionInserts: [form_question_option_insert_input!] = []
    $questionOptionUpdates: [form_question_option_updates!] = []
    $questionInserts: [question_insert_input!] = []
    $sectionInserts: [form_template_section_insert_input!] = []
    $sectionUpdates: [form_template_section_updates!] = []
    $additionalQuestionInserts: [additional_question_insert_input!] = []
    $gradesQuestionUpdates: [grades_question_updates!] = []
  ) {
    update_question_many(updates: $questionUpdates) {
      affected_rows
      returning {
        id
        question
      }
    }

    update_form_question_many(updates: $formQuestionUpdates) {
      affected_rows
      returning {
        question_id
        form_verification {
          id
          label
        }
      }
    }

    update_disclaimer_section_many(updates: $disclaimerUpdates) {
      affected_rows
      returning {
        id
        disclaimer
      }
    }

    update_schools_ranking_section_many(updates: $schoolRankingUpdates) {
      affected_rows
    }

    delete_eligibility_question_school(
      where: $eligibilityQuestionSchoolsDeletes
    ) {
      affected_rows
    }

    insert_eligibility_question_school(
      objects: $eligibilityQuestionSchoolsInserts
    ) {
      affected_rows
    }

    insert_form_question_option(objects: $questionOptionInserts) {
      affected_rows
    }

    update_form_question_option_many(updates: $questionOptionUpdates) {
      affected_rows
    }

    insert_question(objects: $questionInserts) {
      affected_rows
    }

    update_form_template_section_many(updates: $sectionUpdates) {
      affected_rows
    }

    insert_form_template_section(objects: $sectionInserts) {
      affected_rows
    }

    insert_additional_question(objects: $additionalQuestionInserts) {
      affected_rows
    }

    update_grades_question_many(updates: $gradesQuestionUpdates) {
      affected_rows
    }
  }
`;

// TODO: revisit this when we introduce the ability to update statuses
export const UPSERT_FORM_STATUS_DESCRIPTIONS = gql`
  mutation UpsertFormStatusDescriptions(
    $objects: [form_status_description_insert_input!]!
  ) {
    insert_form_status_description(
      objects: $objects
      on_conflict: {
        constraint: form_status_description_status_form_template_id_key
        update_columns: description
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_FORM_TEMPLATE_SETTINGS_BY_ID = gql`
  mutation UpdateFormTemplateSettingsById(
    $id: uuid!
    $form_template_settings: form_template_set_input!
    $upsert_form_template_rule: [form_template_rule_insert_input!] = []
    $upsert_rules: [rule_insert_input!] = []
    $upsert_status_rules: [status_rule_insert_input!] = []
    $deleted_rules: [uuid!] = []
    $deleted_form_template_rules: [uuid!] = []
    $skipRules: Boolean!
  ) {
    insert_form_template_rule(
      objects: $upsert_form_template_rule
      on_conflict: {
        update_columns: [operator, form_template_rule_id]
        constraint: form_template_rule_pkey
      }
    ) @skip(if: $skipRules) {
      affected_rows
    }
    insert_rule(
      objects: $upsert_rules
      on_conflict: {
        update_columns: [form_template_rule_id, type]
        constraint: rule_pkey
      }
    ) @skip(if: $skipRules) {
      affected_rows
    }
    insert_status_rule(
      objects: $upsert_status_rules
      on_conflict: {
        update_columns: [
          next_form_template_id
          type
          form_status
          offer_status
          waitlist_status
        ]
        constraint: status_rule_pkey
      }
    ) @skip(if: $skipRules) {
      affected_rows
    }
    delete_status_rule(where: { rule_id: { _in: $deleted_rules } })
      @skip(if: $skipRules) {
      affected_rows
    }
    delete_rule(where: { id: { _in: $deleted_rules } }) @skip(if: $skipRules) {
      affected_rows
    }
    update_form_template_by_pk(
      pk_columns: { id: $id }
      _set: $form_template_settings
    ) {
      id
    }
    delete_form_template_rule(
      where: { id: { _in: $deleted_form_template_rules } }
    ) @skip(if: $skipRules) {
      affected_rows
    }
  }
`;

export const UPDATE_FORM_TEMPLATE_ACTIVE_STATUS = gql`
  mutation UpdateFormTemplateActiveStatus(
    $id: uuid!
    $form_template_status: Boolean!
  ) {
    update_form_template_by_pk(
      pk_columns: { id: $id }
      _set: { active: $form_template_status }
    ) {
      id
    }
  }
`;
