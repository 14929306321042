import Immutable from "immutable";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";

type FormSchoolRank = {
  form_id: uuid;
  form: { status: GQL.form_status_enum };
  offers: { status: GQL.offer_status_enum }[];
};

type FormSchool = {
  school: { id: uuid } | null;
};

function canAcceptOfferByStatus(formSchoolRanks: FormSchoolRank[]): boolean {
  return formSchoolRanks.every((rank) => {
    if (GQL.form_status_enum.Admissions !== rank.form.status) return false;

    return rank.offers?.[0]?.status === GQL.offer_status_enum.Offered;
  });
}

function hasDuplicateOffersPerForm(formSchoolRanks: FormSchoolRank[]): boolean {
  const initialCount = Immutable.Map<string, number>();
  const offersCountPerForm = formSchoolRanks.reduce(
    (counter, formSchoolRank) => {
      return counter.update(
        formSchoolRank.form_id,
        0,
        (currentCount) => currentCount + 1
      );
    },
    initialCount
  );

  const hasDuplicateOffers = offersCountPerForm.find((value) => value > 1);

  return isNotNull(hasDuplicateOffers);
}

export function canAcceptOffers(
  formSchoolRanks: FormSchoolRank[],
  formSchools: FormSchool[]
) {
  if (formSchoolRanks.length === 0) {
    return false;
  }

  if (formSchools.some((appSchool) => !appSchool.school)) {
    return false;
  }

  const byStatus = canAcceptOfferByStatus(formSchoolRanks);
  const hasDuplicate = hasDuplicateOffersPerForm(formSchoolRanks);

  return byStatus && !hasDuplicate;
}
