import { Text } from "@chakra-ui/react";
import React from "react";
import { Key, Selection } from "../../hooks/useMultiselectState";
import { ResponsiveToolbar } from "../Toolbar/ResponsiveToolbar";
import { Banner } from "./Banner";

interface BannerSelectionProps<K extends Key, V> {
  selection: Selection<K, V>;
  showEmpty?: boolean;
  children?: React.ReactNode;
  overflowMenu?: (children: React.ReactNode) => React.ReactElement;
}

export function BannerSelection<K extends Key, V>({
  selection,
  showEmpty,
  children,
  overflowMenu,
}: BannerSelectionProps<K, V>) {
  return (
    <Banner
      isCollapsed={!showEmpty && !selection.size}
      backgroundColor="gray.200"
      justifyContent="space-between"
    >
      <ResponsiveToolbar
        overflowMenu={overflowMenu}
        leftItems={
          <Text
            fontWeight="bold"
            whiteSpace="nowrap"
            data-targetid="num-selected"
          >
            {selection.size} selected
          </Text>
        }
      >
        {children}
      </ResponsiveToolbar>
    </Banner>
  );
}
