import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GradesConfigForm } from "./Form";
import { CREATE_GRADES_CONFIG } from "./graphql/mutations";
import { GET_ORGANIZATIONS_BY_GRADES_CONFIG } from "./graphql/queries";
import { GradesConfigType, GradeType, validate } from "./types";

export const New = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [createGradesConfig, { remoteData }] = useRemoteDataMutation<
    GQL.CreateGradesConfig,
    GQL.CreateGradesConfigVariables
  >(CREATE_GRADES_CONFIG);

  const handleSubmit = async (values: GradesConfigType) => {
    if (!values) return;

    try {
      if (!validate(values)) {
        throw new Error(
          "Grades config is invalid. Make sure all required fields are non-empty."
        );
      }
      const variables: GQL.CreateGradesConfigVariables = {
        grades_config: values.grades.map(
          (grade: GradeType): GQL.grade_config_insert_input => ({
            organization_id: values.organizationId,
            label: grade.label,
            order: grade.order,
            value: grade.value,
          })
        ),
      };
      await createGradesConfig({
        variables,
        refetchQueries: [GET_ORGANIZATIONS_BY_GRADES_CONFIG],
      });

      toast({
        title: "Grades configured",
        status: "success",
        isClosable: true,
      });
      navigate(Url.Admin.GradesConfig.index());
    } catch (error: any) {
      console.error(error);
      toast({
        title: "Error while configuring grades",
        status: "error",
        description: error.message,
        isClosable: true,
      });
    }
  };

  return (
    <GradesConfigForm
      onSubmit={handleSubmit}
      submitting={remoteData.isLoading()}
    />
  );
};
