import * as RD from "src/types/remoteData";
import { useOrgConfig } from "./useOrgConfig";
import * as OrgConfig from "@avela/organization-config-sdk";

export function useTranslationOptions(): RD.RemoteData<
  unknown,
  OrgConfig.TranslationOptions.Config
> {
  const configRD = useOrgConfig("TranslationOptions");
  const defaultConfig = OrgConfig.Constants.DefaultConfig["TranslationOptions"];

  if (defaultConfig) {
    return configRD.recover(() => RD.success(defaultConfig));
  }

  return configRD;
}
