import { Button, Flex, Spacer } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { AdminHeading } from "src/components/Text/AdminHeading";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { BreadcrumbType } from "src/services/breadcrumb";
import { QuestionTypes } from "src/services/url/Admin";
import * as GQL from "src/types/graphql";
import { JsonViewer } from "../components/JsonViewer";
import { GET_QUESTION_TYPE_BY_ID } from "./api";
import { QuestionType } from "./types";

export const ViewQuestionType: React.FC = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetQuestionType,
    GQL.GetQuestionTypeVariables
  >(GET_QUESTION_TYPE_BY_ID, {
    variables: { id: id },
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.custom_question_type_by_pk === null) {
          return <NotFound />;
        }
        const questionType = convertDataToQuestionType(
          data.custom_question_type_by_pk
        );
        return (
          <Flex direction="column" gap={4} height="100%">
            <Breadcrumb items={getViewQuestionTypeBreadcrumb(questionType)} />
            <AdminHeading title={questionType.name} />
            <JsonViewer obj={questionType} />
            <AdminFormButtons>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  navigate(QuestionTypes.index());
                }}
              >
                Back
              </Button>
              <Spacer />
            </AdminFormButtons>
          </Flex>
        );
      }}
    </GQLRemoteDataView>
  );
};

function convertDataToQuestionType(
  data: GQL.GetQuestionType_custom_question_type_by_pk
): QuestionType {
  return {
    id: data.id,
    name: data.name,
    organization_id: data.organization_id,
    key: data.key ?? undefined,
    fields: data.custom_question_type_fields.map((field) => ({
      question: field.question.question,
      type: field.question.type,
      is_required: field.question.is_required,
      // key is required for CQT field questions but not in general for questions;
      // we expect this to always be non-null in practice
      key: field.question.key ?? "",
      ...getOptions(field),
    })),
  };
}

function getOptions(
  field: GQL.GetQuestionType_custom_question_type_by_pk_custom_question_type_fields
) {
  return (field.question.form_question?.form_question_options.length ?? 0) > 0
    ? {
        options: field.question.form_question?.form_question_options.map(
          (option) => ({
            label: option.label,
            value: option.value,
          })
        ),
      }
    : {};
}

function getViewQuestionTypeBreadcrumb(
  questionType: QuestionType
): BreadcrumbType[] {
  return [
    {
      label: "Question types",
      href: QuestionTypes.index(),
    },
    {
      label: questionType.name,
      href: QuestionTypes.view(questionType.id),
    },
  ];
}
