import { Heading, VStack } from "@chakra-ui/react";
import { useLocation, useSearchParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as GQL from "src/types/graphql";
import { GET_APPLICABLE_VERIFICATIONS_SUMMARIES } from "../graphql/queries";
import { BulkVerificationForm } from "./BulkVerificationForm";

export interface BulkVerificationsState {
  formTemplateId: uuid;
  formIds: uuid[];
}

export const BulkVerifications: React.FC = () => {
  const location = useLocation();
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const { selectedNavFormTemplate } = useFormTemplates();

  const { formTemplateId = "", formIds = [] } = (location.state ??
    {}) as Partial<BulkVerificationsState>;
  const isBadState = !formTemplateId || !formIds.length;

  const { remoteData: verificationsSummariesData } = useRemoteDataQuery<
    GQL.GetApplicableVerificationsSummaries,
    GQL.GetApplicableVerificationsSummariesVariables
  >(GET_APPLICABLE_VERIFICATIONS_SUMMARIES, {
    variables: {
      form_template_id: formTemplateId,
      form_ids: formIds,
    },
    skip: isBadState,
    fetchPolicy: "no-cache",
  });

  return (
    <VStack alignItems="start" minHeight="100%">
      <Breadcrumb
        mb={4}
        items={breadcrumb.form.getBreadcrumbsForBulkVerifications(
          organization,
          selectedNavFormTemplate,
          searchParams.toString()
        )}
      />

      <Heading as="h1" size="lg" pb={8}>
        Verify {formIds.length} form
        {formIds.length !== 1 ? "s" : ""}
      </Heading>

      {isBadState ? (
        <>No forms selected.</>
      ) : (
        <GQLRemoteDataView
          remoteData={verificationsSummariesData}
          error={() => <GenericError />}
          loading={<Loading />}
        >
          {(data) => (
            <BulkVerificationForm
              formIds={formIds}
              applicableVerificationsSummaries={data}
            />
          )}
        </GQLRemoteDataView>
      )}
    </VStack>
  );
};
