import { Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import * as GQL from "src/types/graphql";
import { TagsListEventHandler } from "./types";
import { TagGroupName } from "./TagGroups/TagGroupName";
import { AddTagGroup } from "./TagGroups/AddTagGroup";
import { EditTagGroup } from "./TagGroups/EditTagGroup";

export type Props = {
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
  error?: string;
  onNewTagGroupSaving?: (
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onEditTagGroupSaving?: (
    id: uuid,
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onDeleteTagGroupSaving?: (id: uuid, onSuccess: () => void) => Promise<void>;
  onCloseManageTagGroupDialog?: () => void;
} & TagsListEventHandler;

export const SelectTagGroup: React.FC<Props> = (props) => {
  const {
    tagGroups,
    error,
    onSelectTagGroup,
    onNewTagGroupSaving,
    onEditTagGroupSaving,
    onDeleteTagGroupSaving,
    onCloseManageTagGroupDialog,
  } = props;

  return (
    <Flex direction="column">
      <Flex direction="column">
        <Flex p="4" justifyContent="space-between">
          <Heading as="h3" size="md">
            Tag groups
          </Heading>

          <AddTagGroup
            onNewTagGroupSaving={onNewTagGroupSaving}
            onCloseManageTagGroupDialog={onCloseManageTagGroupDialog}
            error={error}
          />
        </Flex>
        <Divider />
      </Flex>
      <Flex direction="column" padding="4" overflowY="auto" gap={2}>
        {tagGroups.map((tagGroup) => (
          <Flex
            key={tagGroup.id}
            justifyContent="space-between"
            alignItems="center"
          >
            <TagGroupName
              name={tagGroup.name}
              fontSize="sm"
              isExternal={tagGroup.is_external}
            />
            <Flex gap={4} alignItems="center">
              {!tagGroup.is_default && (
                <EditTagGroup
                  tagGroup={tagGroup}
                  onEditTagGroupSaving={onEditTagGroupSaving}
                  onDeleteTagGroupSaving={onDeleteTagGroupSaving}
                  onCloseManageTagGroupDialog={onCloseManageTagGroupDialog}
                />
              )}

              <RiArrowRightSLine
                aria-label="Select tag group"
                onClick={() =>
                  onSelectTagGroup && onSelectTagGroup(tagGroup.id)
                }
                cursor="pointer"
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
