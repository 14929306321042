import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Icon } from "@chakra-ui/react";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";
import { csvExport } from "src/services/dataTransfer";
import * as GQL from "src/types/graphql";
import { GET_ALL_CAPACITY_FOR_ENROLLMENT_PERIOD } from "./graphql/queries";

export const ExportAllCapacitiesButton = ({
  enrollmentPeriodId,
}: {
  enrollmentPeriodId: string;
}) => {
  const toast = useAvelaToast();
  const { schoolFilter } = useSchoolAdminPermissions();
  const { remoteData: capacityData } = useRemoteDataQuery<
    GQL.GetAllCapacityForEnrollmentPeriod,
    GQL.GetAllCapacityForEnrollmentPeriodVariables
  >(GET_ALL_CAPACITY_FOR_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      school_filter: schoolFilter(),
    },
    skip: !enrollmentPeriodId,
    notifyOnNetworkStatusChange: true,
  });
  return (
    <GQLRemoteDataView remoteData={capacityData}>
      {(data) => (
        <Button
          variant="solid"
          size="md"
          leftIcon={<Icon as={DownloadIcon} />}
          onClick={() => {
            const loadingToastId = toast({
              title: `Export in progress`,
              description: `Download will start shortly`,
              status: "loading",
            });
            try {
              const programGroups =
                data.enrollment_period_by_pk?.organization?.program_groups;
              const HAS_PROGRAMS = programGroups && programGroups.length > 0;
              const records = data.grade.map((record) => ({
                ID: record.id,
                "School ID": record.school.id,
                "School Name": record.school.name,
                "Grade ID": record.grade_config.id,
                "Grade Label": record.grade_config.label,
                ...(HAS_PROGRAMS && {
                  "Program ID": record.program?.id,
                  "Program Label": record.program?.label,
                }),
                Waitlisted: record.waitlists_aggregate.aggregate?.count,
                Offered: record.offered_offers_aggregate.aggregate?.count,
                Accepted: record.accepted_offers_aggregate.aggregate?.count,
                "Total Seats": record.capacity,
                "Available Seats": record.seats_available,
              }));
              csvExport(records, "capacities.csv");
              toast.close(loadingToastId);
              toast({
                title: `Capacities exported`,
                status: "success",
              });
            } catch (e: any) {
              toast.close(loadingToastId);
              toast.error({
                title: "Something went wrong!",
                description: "Check your network and try again",
              });
              console.error(e);
            }
          }}
        >
          Export all
        </Button>
      )}
    </GQLRemoteDataView>
  );
};
