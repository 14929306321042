import * as GQL from "./graphql";

// These types are used to provide dynamic enumeration in the JSON editor.
export type EnrollmentPeriodId = uuid;
export type SchoolId = uuid;

export interface PriorityTemplateConfig {
  enrollmentPeriodId: EnrollmentPeriodId;
  templates: PriortyTemplate[];
}

export interface PriortyTemplate {
  name: string;
  /**
   * UUIDs of schools to which this template applies.  If omitted, this template
   * is used as the default for this enrollment period.
   */
  schools?: { id: SchoolId; grades?: uuid[] }[];
  priorityConfig: PriorityConfig;
}
export interface PriorityConfig {
  /**
   * The priority groups in this template, ordered by highest priority first.
   * Each priority group is an OR of tag groups, which are ANDs of tags.
   */
  priorityGroups: PriorityGroup[];
  sortFields: [SortField, SortField?, SortField?];
}

export interface SortField {
  field: GQL.priority_template_sort_field_enum;
  order: GQL.priority_template_sort_order_enum;
}

export type Tag = string;
export interface TagNegation {
  not: Tag;
}
export type TagLiteral = Tag | TagNegation;

export type TagGroup = TagLiteral[];
export type PriorityGroup = TagGroup[];

export const DEFAULT_PRIORITY_TEMPLATE: PriorityConfig = {
  priorityGroups: [],
  sortFields: [
    {
      field: GQL.priority_template_sort_field_enum.PriorityGroup,
      order: GQL.priority_template_sort_order_enum.asc,
    },
    {
      field: GQL.priority_template_sort_field_enum.LotteryOrder,
      order: GQL.priority_template_sort_order_enum.asc,
    },
    {
      field: GQL.priority_template_sort_field_enum.SchoolSubmissionTime,
      order: GQL.priority_template_sort_order_enum.asc,
    },
  ],
};

//-- Examples ------------------------------------------------------------------

export const exampleTemplateConfig: PriorityTemplateConfig = {
  enrollmentPeriodId: "1234",
  templates: [
    {
      name: "default",
      priorityConfig: {
        priorityGroups: [
          [
            ["Sibling", "Newark Resident", "Lottery"],
            ["Sibling", "Foo", "Lottery"],
          ],
          [["Sibling", "Newark Resident", { not: "Lottery" }]],
          [["Sibling", { not: "Newark Resident" }, "Lottery"]],
          [["Sibling", { not: "Newark Resident" }, { not: "Lottery" }]],
          [["Homeless", "Lottery"]],
          [["Homeless", { not: "Lottery" }]],
          [["Newark Resident", "Lottery"]],
          [["Newark Resident", { not: "Lottery" }]],
          [["Lottery"]],
          [[{ not: "Lottery" }]],
        ],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
    {
      name: "foo",
      schools: [{ id: "school-a" }, { id: "school-b" }],
      priorityConfig: {
        priorityGroups: [],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
    {
      name: "bar",
      schools: [{ id: "school-c" }, { id: "school-d" }],
      priorityConfig: {
        priorityGroups: [],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
  ],
};
