import axios from "axios";
import { useCallback } from "react";
import { GET_DOCUMENT_INFO } from "src/components/graphql/queries";
import { GENERATE_DOWNLOAD_URL } from "src/constants";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import useAccessToken from "./useAccessToken";
import { useLazyRemoteDataQuery } from "./useRemoteDataQuery";

const DownloadUrlResponseSchema = z.object({
  signed_url: z.object({
    URL: z.string(),
  }),
});

export function useDocumentStorage() {
  const documentStorageUrl = process.env.REACT_APP_AWS_FILE_UPLOAD_URL;
  const accessToken = useAccessToken();

  const [fetchDocumentInfo] = useLazyRemoteDataQuery<
    GQL.GetDocumentoInfo,
    GQL.GetDocumentoInfoVariables
  >(GET_DOCUMENT_INFO);

  const getDocumentInfo = useCallback(
    async (documentId: string) => {
      if (accessToken.status !== Status.OK) return;

      const result = await fetchDocumentInfo({
        variables: { document_id: documentId },
      });

      return result.data?.document_metadata_by_pk;
    },
    [accessToken, fetchDocumentInfo]
  );

  const getDownloadUrl = useCallback(
    async (documentId: string): Promise<string | undefined> => {
      if (!documentStorageUrl || accessToken.status !== Status.OK) {
        console.error("Document storage service unavailable:", {
          documentStorageUrl,
          accessTokenStatus: accessToken.status,
        });
        return;
      }

      try {
        const response = await axios.post(
          `${documentStorageUrl}/${GENERATE_DOWNLOAD_URL}`,
          {
            document_id: documentId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
            },
          }
        );
        return DownloadUrlResponseSchema.parse(response.data).signed_url.URL;
      } catch (err) {
        console.error(err);
      }
    },
    [documentStorageUrl, accessToken]
  );
  return { getDocumentInfo, getDownloadUrl };
}
