import { gql } from "@apollo/client";

// export const CREATE_PRIORITY_TEMPLATE = gql`
//   mutation CreatePriorityTemplate(
//     $priority_template: priority_template_insert_input!
//   ) {
//     insert_priority_template_one(object: $priority_template) {
//       id
//     }
//   }
// `;

export const CREATE_PRIORITY_TEMPLATES = gql`
  mutation CreatePriorityTemplates(
    $priority_templates: [priority_template_insert_input!]!
  ) {
    insert_priority_template(objects: $priority_templates) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_PRIORITY_TEMPLATES = gql`
  mutation DeletePriorityTemplates($priority_template_ids: [uuid!]!) {
    delete_priority_template_grade(
      where: { priority_template_id: { _in: $priority_template_ids } }
    ) {
      affected_rows
    }
    delete_priority_template_school(
      where: { priority_template_id: { _in: $priority_template_ids } }
    ) {
      affected_rows
    }
    delete_priority_template_enrollment_period(
      where: { priority_template_id: { _in: $priority_template_ids } }
    ) {
      affected_rows
    }
    delete_priority_template(where: { id: { _in: $priority_template_ids } }) {
      affected_rows
    }
  }
`;
