import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
type Props = {
  active?: boolean;
  children: React.ReactNode;
} & ButtonProps;

// TODO: Improve a11y by following this: https://inclusive-components.design/toggle-button/
export const ToggleButton: React.FC<Props> = ({
  children,
  active = false,
  ...buttonProps
}) => {
  return (
    <Button
      variant={active ? "solid" : "outline"}
      aria-pressed={active}
      colorScheme="gray"
      size="sm"
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
