import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { ResendEmailVerificationButton } from "src/components/Buttons/ResendEmailVerificationButton";
import { EmptyState } from "src/components/EmptyState";
import { Loading } from "src/components/Feedback/Loading";
import { AuthLayout } from "src/components/Layout/AuthLayout";
import { ApolloProviderWithCredential } from "src/components/Providers/ApolloProviderWithCredential";
import { usePreferredLanguageBrowserOnly } from "src/hooks/usePreferredLanguage";
import { useQueryParams } from "src/hooks/useQueryParams";
import { ReactComponent as NewMailboxSvg } from "src/images/new-mailbox.svg";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as Url from "src/services/url";

export const Expired = () => {
  const { email } = useQueryParams();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const params = useParams();

  const avelaOrganization = params["organization"] || "";
  const logout = Url.useLogout(`/${avelaOrganization}`);

  const preferredLanguageSetting = usePreferredLanguageBrowserOnly();

  React.useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          loginSource: window.location.hostname,
          avelaOrganization,
          returnTo: `${location.pathname}${location.search}`,
        },
      });
    }
  }, [
    avelaOrganization,
    isAuthenticated,
    location.pathname,
    location.search,
    loginWithRedirect,
    params,
  ]);

  if (!isAuthenticated) {
    return <Loading />;
  }
  return (
    <AuthLayout languageSelector={{ preferredLanguageSetting }}>
      <Flex
        alignItems="center"
        direction="column"
        fontSize="md"
        gap={8}
        width="100%"
      >
        <EmptyState
          description={
            email && (
              <Text textAlign="center" color="gray.600">
                The verification link to{" "}
                <Text as="span" fontWeight="600" className={WEGLOT_SKIP_CLASS}>
                  {email}
                </Text>{" "}
                has expired
              </Text>
            )
          }
          heading="Expired link"
          Svg={NewMailboxSvg}
        >
          <ApolloProviderWithCredential>
            <ResendEmailVerificationButton />
          </ApolloProviderWithCredential>
        </EmptyState>

        <Flex direction="column" alignItems="center" gap={4} marginTop={16}>
          <Text>Not you?</Text>
          <Button variant="link" as={RouterLink} to={logout}>
            Log in with a different account
          </Button>
        </Flex>
      </Flex>
    </AuthLayout>
  );
};
