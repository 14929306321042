import type { ColumnDef } from "@tanstack/table-core";
import type { GqlSchoolForm } from "../types";
import { ColumnId } from "./constants";

export function buildWaitlistPositionColumnDef(): ColumnDef<GqlSchoolForm> {
  return {
    id: ColumnId.WaitlistPosition,
    header: "position",

    accessorFn: (row) => row.waitlist_position,
  };
}
