import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { Glossary } from "../Text/Glossary";

interface StatusTagProps {
  status: StatusTagMetadata;
  variant?: "long" | "short";
  customLabel?: string | undefined;
}

export interface StatusTagMetadata {
  label: string;
  longLabel: string;
  icon?: IconType;
  variant?: string;
  colorScheme: string;
}

export const StatusTag: React.FC<StatusTagProps> = ({
  status,
  variant = "short",
  customLabel: label = undefined,
}) => {
  if (!label) {
    switch (variant) {
      case "long":
        label = status.longLabel;
        break;
      case "short":
        label = status.label;
        break;
    }
  }
  return (
    <Box display="flex" alignItems="flex-start">
      <Tag
        colorScheme={status.colorScheme}
        borderRadius="md"
        fontSize="xs"
        variant={status.variant ?? "subtle"}
      >
        {status.icon && <TagLeftIcon aria-hidden={true} as={status.icon} />}
        <TagLabel>
          <Glossary>{label}</Glossary>
        </TagLabel>
      </Tag>
    </Box>
  );
};
