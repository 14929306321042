import { Flex, Text } from "@chakra-ui/react";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { QuestionFormView } from "src/components/Form/QuestionForm/View";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { maybePluralize } from "src/services/format";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";

type AdditionalQuestionsProps = {
  option: AF.Option<AF.WithId>;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({
  option,
  schools,
}) => {
  const additionalQuestions = option.additionalQuestions;
  if (!additionalQuestions || additionalQuestions.length === 0) return null;

  return (
    <Flex direction="column" marginLeft="8" gap="2">
      <Text color="blackAlpha.700" fontSize="xs">
        {maybePluralize(
          additionalQuestions.length,
          "When selected, asks this additional question:",
          "When selected, asks these additional questions:"
        )}
      </Text>
      {additionalQuestions.map((question) => {
        return (
          <QuestionFormView
            key={question.id}
            question={Draft.fromOriginalQuestion(question)}
            schools={schools}
            hasChanges={false}
          />
        );
      })}
    </Flex>
  );
};
