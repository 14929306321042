export type PreviousForm = {
  grades_answers: { grade_config: { label: string | null } | null }[];
} | null;

type RelatedForm = {
  id: uuid;
  form_template_id: uuid;
  form_template: {
    name: string;
  };
};
type FormWithRelatedForm = {
  previous_form: RelatedForm | null;
  next_forms: RelatedForm[];
};
export function getRelatedForms(form: FormWithRelatedForm): RelatedForm[] {
  if (form.previous_form) {
    return [form.previous_form];
  }

  return form.next_forms;
}

type FormWithRelatedGrade = {
  previous_offer: {
    grade: { grade_config: { label: string } | null };
  } | null;
  previous_waitlist: {
    grade: { grade_config: { label: string } | null };
  } | null;
  previous_form: PreviousForm;
};
export function getRelatedGrade(
  form: FormWithRelatedGrade
): string | undefined {
  return (
    form.previous_offer?.grade.grade_config?.label ??
    form.previous_waitlist?.grade.grade_config?.label ??
    form.previous_form?.grades_answers[0]?.grade_config?.label ??
    undefined
  );
}

type FormWithRelatedSchool<SCHOOL> = {
  previous_offer: {
    school: SCHOOL;
  } | null;
  previous_waitlist: {
    school: SCHOOL;
  } | null;
};
export function getRelatedSchool<SCHOOL>(
  form: FormWithRelatedSchool<SCHOOL>
): SCHOOL | undefined {
  return form.previous_offer?.school ?? form.previous_waitlist?.school;
}

export function getRelatedSchoolName(
  form: FormWithRelatedSchool<{ name: string }>
): string | undefined {
  return getRelatedSchool(form)?.name;
}
type FormWithRelatedTags = FormWithRelatedSchool<{ id: uuid }> & {
  previous_form: {
    form_school_tags: {
      school_id: uuid;
      enrollment_period_tag: {
        id: uuid;
        name: string;
      };
    }[];
  } | null;
};
export function getRelatedTags(form: FormWithRelatedTags): string[] {
  const relatedSchool = getRelatedSchool(form);
  if (!relatedSchool) {
    return [];
  }

  return (
    form.previous_form?.form_school_tags.flatMap((schoolTag) => {
      if (schoolTag.school_id !== relatedSchool.id) {
        return [];
      }
      return [schoolTag.enrollment_period_tag.name];
    }) ?? []
  );
}

type FormWithFormSchoolId = FormWithRelatedForm &
  FormWithRelatedSchool<{ id: uuid }> & {
    previous_form: {
      form_school_ranks: { id: uuid; school_id: uuid }[];
    } | null;
  };
type FormSchoolId = {
  formSchoolRankId: uuid;
  formId: uuid;
  schoolId: uuid;
};
export function getFormSchoolId(
  form: FormWithFormSchoolId
): FormSchoolId | undefined {
  const relatedForm = getRelatedForms(form);
  if (!relatedForm[0]) {
    return;
  }

  const relatedSchool = getRelatedSchool(form);
  if (!relatedSchool?.id) {
    return;
  }

  const relatedFormSchoolRank = form.previous_form?.form_school_ranks.find(
    (schoolRank) => schoolRank.school_id === relatedSchool?.id
  );
  if (!relatedFormSchoolRank) {
    return;
  }

  return {
    formSchoolRankId: relatedFormSchoolRank.id,
    formId: relatedForm[0].id,
    schoolId: relatedSchool.id,
  };
}
