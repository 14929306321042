import { FunctionComponent, useMemo } from "react";
import { AvelaSelectField } from "src/components";
import { EnrollmentPeriod } from "../../types";

type Props = {
  enrollmentPeriods: EnrollmentPeriod[];
};

export const SelectEnrollmentPeriod: FunctionComponent<Props> = (props) => {
  const { enrollmentPeriods } = props;

  const enrollmentPeriodOptions = useMemo(() => {
    return enrollmentPeriods.map((enrollmentPeriod) => {
      const { id, name } = enrollmentPeriod;
      return {
        label: name,
        value: id,
      };
    });
  }, [enrollmentPeriods]);

  return (
    <AvelaSelectField
      name="enrollmentPeriodId"
      label="For what enrollment period?"
      placeholder="Select enrollment period"
      options={enrollmentPeriodOptions}
    />
  );
};
