import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { GradesConfig } from "src/services/url/Admin";
import { Edit, Index, New } from ".";

export const AdminGradesConfigRoutes = (
  <Route
    path={GradesConfig.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.grades">
          <Index />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={GradesConfig.newPath}
      element={
        <RequireUserPermissions permission="grade:create">
          <New />
        </RequireUserPermissions>
      }
    />
    <Route
      path={GradesConfig.editPath}
      element={
        <RequireUserPermissions permission="grade:update">
          <Edit />
        </RequireUserPermissions>
      }
    />
  </Route>
);
