import { Button, Divider, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { NotFound } from "src/components/Feedback/NotFound";
import { GET_PERSON_RELATIONSHIPS } from "src/components/graphql/queries";
import { DetailHeader } from "src/components/Layout/DetailHeader";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { RelationshipsTable } from "src/components/Table/RelationshipsTable";
import {
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_OFFSET,
} from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useClientId } from "src/hooks/useClientId";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import { ContactInfoSchema } from "src/schemas/Parent";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { ContactForm } from "../components/Forms/ContactForm";
import { ParentForm } from "../components/Forms/ParentForm";
import { UPDATE_CONTACT_INFO, UPDATE_PARENT } from "./graphql/mutations";
import { GET_PARENTS, GET_PARENT_BY_ID } from "./graphql/queries";
import {
  EmailRequiredParentProfileValidator,
  ParentProfileFormType,
  ParentProfileSchema,
  ParentProfileValidator,
  PhoneRequiredParentProfileValidator,
} from "./schemas";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";

export const EditParent = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const { id = "" } = useParams();
  const organization = useOrganization();
  const toast = useAvelaToast();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>();
  const client_id = useClientId();
  const isOrgAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetParent,
    GQL.GetParentVariables
  >(GET_PARENT_BY_ID, {
    variables: { id },
  });

  const [updateParent, { remoteData: updateRemoteData }] =
    useRemoteDataMutation<GQL.UpdateParent, GQL.UpdateParentVariables>(
      UPDATE_PARENT
    );

  const [updateContactInfo, { remoteData: contactInfoRemoteData }] =
    useRemoteDataMutation<
      GQL.UpdateContactInfo,
      GQL.UpdateContactInfoVariables
    >(UPDATE_CONTACT_INFO);

  const [getParents, refetchStatus] = useLazyRemoteDataQuery<
    GQL.GetParents,
    GQL.GetParentsVariables
  >(GET_PARENTS, { fetchPolicy: "network-only" });

  const { remoteData: studentRelationships } = useRemoteDataQuery<
    GQL.GetPersonRelationships,
    GQL.GetPersonRelationshipsVariables
  >(GET_PERSON_RELATIONSHIPS, {
    variables: {
      id,
    },
  });

  const handleSubmit = async (values: GQL.person_set_input) => {
    remoteData.do(async ({ person_by_pk: currentPerson }) => {
      organization.do(async (org) => {
        if (isActive !== undefined) values.active = isActive;

        setSubmitting(true);
        try {
          const contactInfo = ContactInfoSchema.parse(values);

          let parentValidator = ParentProfileValidator;

          // if we have a user ID, update contact info with the update-contact-info action
          // otherwise, insert the contact info with the parent
          // this is done to ensure contact info is still updated when a user_id is not available
          if (isOrgAdmin) {
            if (currentPerson?.user?.id) {
              // this call updates both the auth0 user profile and avela user profile
              const result = await updateContactInfo({
                variables: {
                  user_id: currentPerson?.user?.id,
                  client_id,
                  phone_number: contactInfo.phone_number,
                  email_address: contactInfo.email_address,
                },
              });
              if ((result.errors?.length ?? 0) > 0) {
                console.error(
                  result.data?.update_contact_info?.status_code,
                  JSON.stringify(result.errors)
                );
                throw new Error("Failed to update contact information.");
              }
            } else {
              parentValidator = parentValidator.merge(ContactInfoSchema);
            }
          }

          const parent = parentValidator.parse(values);

          await updateParent({ variables: { id, parent } });

          await getParents({
            variables: {
              organizationId: org.id,
              limit: PAGINATION_DEFAULT_LIMIT,
              offset: PAGINATION_DEFAULT_OFFSET,
              search: {},
            },
          });

          toast({
            id: "update-parent",
            title: "Parent updated",
            isClosable: true,
            status: "info",
          });
          navigate(Url.OrgAdmin.Parents.index(org));
        } catch (err) {
          console.error(JSON.stringify(err));
          toast.error({
            title: "Error updating parent",
          });
        } finally {
          setSubmitting(false);
        }
      });
    });
  };

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  return (
    <GQLRemoteDataView
      remoteData={RD.toTuple(remoteData, studentRelationships)}
    >
      {([data, relationshipData]) => {
        if (data.person_by_pk === null) return <NotFound />;
        const parent: GQL.GetParent_person_by_pk = data.person_by_pk;
        const parsed = ParentProfileSchema.safeParse(parent);
        if (!parsed.success) {
          console.error(parsed.error);
          return <GenericError />;
        }

        const emailLinked = parent.email_address === parent.user?.name;
        const phoneLinked = parent.phone_number === parent.user?.name;

        const validate = (values: ParentProfileFormType) => {
          if (emailLinked) {
            return validateWithZod(EmailRequiredParentProfileValidator)(values);
          }
          if (phoneLinked) {
            return validateWithZod(PhoneRequiredParentProfileValidator)(values);
          }

          return validateWithZod(ParentProfileValidator)(values);
        };
        return (
          <>
            <Breadcrumb
              items={breadcrumb.parent.getBreadcrumbsForEdit(
                organization,
                data.person_by_pk
              )}
              mb={8}
            />
            <Formik<ParentProfileFormType>
              initialValues={parsed.data}
              onSubmit={handleSubmit}
              validate={validate}
            >
              <Flex as={Form} direction="column" gap={12}>
                <DetailHeader
                  data={parent}
                  isActive={isActive}
                  type={GQL.person_type_enum.guardian}
                  onActiveChange={handleChangeStatus}
                  isLoading={
                    updateRemoteData.isLoading() ||
                    contactInfoRemoteData.isLoading() ||
                    refetchStatus.called
                  }
                />
                <Flex direction="column" gap={6}>
                  <Heading as="h2" fontSize="2xl">
                    Basic Information
                  </Heading>
                  <ParentForm />
                </Flex>
                <Divider />
                <RelationshipsTable
                  data={relationshipData.relationship_person}
                  personId={data.person_by_pk?.id}
                  entityType={GQL.person_type_enum.guardian}
                />
                <Flex direction="column" gap={6}>
                  <Heading as="h2" fontSize="2xl">
                    Contact Preferences
                  </Heading>
                  <ContactForm
                    emailLinked={emailLinked}
                    phoneLinked={phoneLinked}
                    isDisabled={!isOrgAdmin}
                  />
                </Flex>
                <Flex align="center" gap={3}>
                  <Spacer />
                  <Button
                    as={NavLink}
                    to={organization
                      .map((org) => Url.OrgAdmin.Parents.index(org))
                      .withDefault("#")}
                    isLoading={organization.isLoading()}
                    variant="link"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" marginLeft={4} isLoading={submitting}>
                    Update
                  </Button>
                </Flex>
              </Flex>
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
