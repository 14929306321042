import React from "react";
import { createContext, FunctionComponent, useContext } from "react";
import { State } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/settingsState";
import { Action, useFormTemplateSettingsProcessor } from "./processor";
import { FormTemplateSettingsType } from "./schemas";

type ContextValue = {
  state?: State;
  dispatch?: (action: Action) => void;
  formTemplateId?: uuid;
};

const EditFormTemplateSettingsContext = createContext<ContextValue>({
  state: undefined,
  dispatch: undefined,
  formTemplateId: undefined
});

type Props = {
  children: React.ReactNode;
  settingsInitialValues: FormTemplateSettingsType;
  formTemplateId: uuid;
};

export const EditFormTemplateSettingsProvider: FunctionComponent<Props> = (
  props
) => {
  const { children, settingsInitialValues, formTemplateId } = props;
  const formTemplateSettingsState = useFormTemplateSettingsProcessor(
    { settings: settingsInitialValues },
    formTemplateId
  );

  return (
    <EditFormTemplateSettingsContext.Provider
      value={{
        ...formTemplateSettingsState,
        formTemplateId
      }}
    >
      {children}
    </EditFormTemplateSettingsContext.Provider>
  );
};

export function useEditFormTemplateSettingsContext() {
  const context = useContext<ContextValue>(EditFormTemplateSettingsContext);

  if (context === undefined) {
    throw new Error(
      "useEditFormTemplateSettingsContext must be used within a FormTemplateEditSettingsProvider"
    );
  }

  return context;
}
