import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { parseCsvFile } from "src/services/dataTransfer";

export type BulkIsValidCallback = (data: any[]) => boolean;
export type BulkOnSuccessCallback = (data: any[]) => void;

export const useBulkUploadInput = (
  isValid: BulkIsValidCallback,
  onSuccess: BulkOnSuccessCallback
) => {
  const toast = useToast();

  const parseFile = useCallback(
    (file: File) => {
      parseCsvFile(
        file,
        (results: any) => {
          if (!isValid(results.data)) {
            toast({
              id: "error-uploading-doc",
              status: "error",
              title: "Import error",
              description:
                "Invalid csv format, please check our documentation.",
            });
            throw new Error("Invalid csv format");
          }

          onSuccess(results.data);
        },
        (error: any) => {
          console.error(error);
        }
      );
    },
    [isValid, onSuccess, toast]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget?.files
      ? event.currentTarget.files[0]
      : undefined;
    if (file === undefined) return;
    event.currentTarget.value = "";
    parseFile(file);
  };

  return { parseFile, handleInputChange };
};
