import {
  AddressBookAnswerSchema,
  AddressValidationSchema,
  NO_ADDRESS,
} from "src/components/Inputs/Address/Book";
import {
  BASE_INITIAL_STUDENT_FORM,
  StudentSchema,
  StudentValidator,
  StudentAttendanceSchema,
  StudentAttendanceItemSchema,
} from "src/schemas/Student";
import { z } from "zod";

export const StudentProfileSchema = StudentSchema.extend({
  address: AddressBookAnswerSchema,
});

export const StudentProfileValidator = StudentValidator.extend({
  address: AddressValidationSchema,
});

export type StudentProfileFormAttendanceItem = z.infer<
  typeof StudentAttendanceItemSchema
>;

export type StudentProfileFormAttendance = z.infer<
  typeof StudentAttendanceSchema
>;

export type StudentProfileForm = z.infer<typeof StudentProfileSchema>;

export const INITIAL_STUDENT_PROFILE: StudentProfileForm = {
  ...BASE_INITIAL_STUDENT_FORM,
  address: {
    kind: NO_ADDRESS,
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
};
