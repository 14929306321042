import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { SearchAndFilterTypes } from "src/constants";
import { handleFilterChange } from "src/services/filter";
import { DynamicQuestionFilter } from "../graphql/utils";

type QuestionOptionType = {
  id: uuid;
  label: string;
  question_id: uuid;
};
interface FormFiltersDynamicQuestionProps {
  isLoading?: boolean;
  dynamicQuestionFilters?: DynamicQuestionFilter[];
}

export const FormFiltersDynamicQuestions: React.FC<
  FormFiltersDynamicQuestionProps
> = ({ dynamicQuestionFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const buildOptionId = (option: QuestionOptionType | undefined) =>
    option ? `${option.id}~${option.question_id}` : "";

  const handleFormFilterChange = (
    filterType: SearchAndFilterTypes,
    newValue: string
  ) => {
    setSearchParams(handleFilterChange(searchParams, filterType, newValue));
  };

  const handleFormMultiSelectFilterChange = (
    newValues: QuestionOptionType[]
  ) => {
    setSearchParams(
      handleFilterChange(
        searchParams,
        SearchAndFilterTypes.DynamicQuestions,
        buildOptionId(newValues[newValues.length - 1])
      )
    );
  };

  const handleRemoveMultiSelectFilter = (value: QuestionOptionType) => {
    setSearchParams(
      handleFilterChange(
        searchParams,
        SearchAndFilterTypes.DynamicQuestions,
        buildOptionId(value)
      )
    );
  };

  const handleInitialValues = (options: QuestionOptionType[]) =>
    options.filter((option) =>
      searchParams
        .get(SearchAndFilterTypes.DynamicQuestions)
        ?.includes(buildOptionId(option))
    );
  return (
    <>
      {dynamicQuestionFilters?.map((filter) => {
        const optionsWithQuestionId =
          filter.options?.map((option) => ({
            ...option,
            question_id: filter.questionId,
          })) ?? null;
        return (
          <VStack align="inherit" key={filter.questionId}>
            <Text size="sm" fontWeight="400">
              {filter.questionLabel}
            </Text>

            {optionsWithQuestionId && optionsWithQuestionId.length <= 3 && (
              <HStack flexWrap="wrap">
                {optionsWithQuestionId.map((option) => (
                  <Button
                    key={option.id}
                    variant={
                      searchParams
                        .get(SearchAndFilterTypes.DynamicQuestions)
                        ?.includes(buildOptionId(option))
                        ? "solid"
                        : "outline"
                    }
                    colorScheme="gray"
                    onClick={() =>
                      handleFormFilterChange(
                        SearchAndFilterTypes.DynamicQuestions,
                        buildOptionId(option)
                      )
                    }
                    size="sm"
                    marginLeft="0 !important"
                    marginBottom="8px !important"
                    marginRight="8px !important"
                    overflow="hidden"
                    display="block"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    textAlign="left"
                  >
                    {option.label}
                  </Button>
                ))}
              </HStack>
            )}

            {optionsWithQuestionId && optionsWithQuestionId.length > 3 && (
              <MultiSelectInput
                size="md"
                options={optionsWithQuestionId}
                onChange={handleFormMultiSelectFilterChange}
                renderDropdownItem={(item) => (
                  <Box>
                    <span>{item.label}</span>
                  </Box>
                )}
                initialValues={handleInitialValues(optionsWithQuestionId)}
                onRemoveTag={handleRemoveMultiSelectFilter}
              />
            )}
          </VStack>
        );
      })}{" "}
    </>
  );
};
