import React, { useMemo } from "react";
import { ConfigFormLayout } from "./ConfigFormLayout";
import { JsonEditor } from "../../components/JsonEditor";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import zodToJsonSchema from "zod-to-json-schema";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = {};
export const LoginConfigForm: React.FC<Props> = () => {
  const [, meta, helpers] = useField("organizationConfigs.Login");
  const schema = useMemo(() => {
    const schema = zodToJsonSchema(OrgConfig.Login.ConfigSchema);
    return schema;
  }, []);
  return (
    <ConfigFormLayout
      type="Login"
      title="Login"
      label="Login methods"
      description="Configure the login methods for your organization."
    >
      {(config) => (
        <FormControl
          isInvalid={!!meta.error}
          height="400px"
          display="flex"
          flexDirection="column"
        >
          <JsonEditor
            hideToolbar
            height="100%"
            schema={schema}
            filename=""
            text={JSON.stringify(config, null, 2)}
            onChange={(newValue) => {
              try {
                helpers.setValue(JSON.parse(newValue));
              } catch (err) {
                console.error(err);
                helpers.setError("Invalid config");
              }
            }}
          />
          {meta.error && <FormErrorMessage>Invalid config</FormErrorMessage>}
        </FormControl>
      )}
    </ConfigFormLayout>
  );
};
