import { gql } from "@apollo/client";

export const ACTION_ITEM_FRAGMENT = gql`
  fragment ActionItemFragment on action_item {
    id
    form_id
    summary
    status
    created_at
    updated_at
  }
`;
