import { gql } from "@apollo/client";
export { GET_ENROLLMENT_PERIOD_ENUMS } from "src/scenes/admin/priorityTemplates/graphql/queries";

export const FETCH_MATCH_TEMPLATES = gql`
  query FetchMatchTemplates(
    $organization_id: uuid!
    $order_by: match_template_order_by!
  ) {
    match_template(
      where: { organization_id: { _eq: $organization_id } }
      order_by: [$order_by]
    ) {
      id
      name
      match_template_organizations {
        id
      }
    }
  }
`;
