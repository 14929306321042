import { VStack, OrderedList, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { School } from "src/scenes/parent/state/localStorage";
import { Glossary } from "src/components/Text/Glossary";
import { useGlossary } from "src/hooks/useGlossary";

type Props = {
  eligibleSchools: readonly School[];
  ineligibleSchools: readonly School[];
  removedSchools: readonly School[];
  numUnresolvedSchools: number;
};
export const ExploreSavedSchoolsDialog: React.FC<Props> = ({
  eligibleSchools,
  ineligibleSchools,
  removedSchools,
  numUnresolvedSchools,
}) => {
  const { glossary } = useGlossary();
  if (!eligibleSchools.length && ineligibleSchools.length) {
    return (
      <VStack>
        <Text alignSelf="flex-start" paddingBottom="1rem">
          <Glossary>
            You previously saved schools, but it looks like none of them are
            available and will be removed.
          </Glossary>
        </Text>
        <Text alignSelf="flex-start">
          <Glossary>You can pick different schools on the next step.</Glossary>
        </Text>
      </VStack>
    );
  } else if (
    eligibleSchools.length &&
    (ineligibleSchools.length ||
      removedSchools.length ||
      numUnresolvedSchools > 0)
  ) {
    return (
      <VStack>
        <Text alignSelf="flex-start">
          <Glossary>
            You previously saved schools, the following can be added:
          </Glossary>
        </Text>
        <VStack alignItems="flex-start" width="100%">
          <OrderedList paddingTop="1rem" paddingBottom="1rem">
            {eligibleSchools.map((item, index) => (
              <ListItem key={index}>{item.name}</ListItem>
            ))}
          </OrderedList>
        </VStack>
        {removedSchools.length && (
          <>
            <Text alignSelf="flex-start">
              <Glossary>
                The following schools exceed the limit and will be removed:
              </Glossary>
            </Text>
            <VStack alignItems="flex-start" width="100%">
              <OrderedList paddingTop="1rem" paddingBottom="1rem">
                {removedSchools.map((item, index) => (
                  <ListItem key={index}>{item.name}</ListItem>
                ))}
              </OrderedList>
            </VStack>
          </>
        )}
        {ineligibleSchools.length && (
          <>
            <Text alignSelf="flex-start">
              <Glossary>
                The following schools are not available and will be removed:
              </Glossary>
            </Text>
            <VStack alignItems="flex-start" width="100%">
              <OrderedList paddingTop="1rem" paddingBottom="1rem">
                {ineligibleSchools.map((item, index) => (
                  <ListItem key={index}>{item.name}</ListItem>
                ))}
              </OrderedList>
            </VStack>
          </>
        )}
        {numUnresolvedSchools > 0 && (
          <Text alignSelf="flex-start">
            {numUnresolvedSchools === 1
              ? glossary`Another school was saved, but is not available.`
              : glossary`Another ${numUnresolvedSchools} were saved, but they are not available.`}
          </Text>
        )}
      </VStack>
    );
  } else {
    return (
      <VStack>
        <Text alignSelf="flex-start">
          <Glossary>You previously saved the following schools:</Glossary>
        </Text>
        <VStack alignItems="flex-start" width="100%">
          <OrderedList paddingTop="1rem" paddingBottom="1rem">
            {eligibleSchools.map((item, index) => (
              <ListItem width="100%" key={index}>
                {item.name}
              </ListItem>
            ))}
          </OrderedList>
        </VStack>
        <Text alignSelf="flex-start">Would you like to add them?</Text>
      </VStack>
    );
  }
};
