import { gql } from "@apollo/client";
import { FORM_SUMMARY_FRAGMENT } from "src/operations/fragments/form";

export const GET_INACTIVE_FORMS = gql`
  ${FORM_SUMMARY_FRAGMENT}
  query GetInactiveForms($organization_id: uuid!) {
    form(
      where: {
        _and: {
          form_template: {
            deleted_at: { _is_null: true }
            active: { _eq: false }
            enrollment_period: {
              _and: {
                deleted_at: { _is_null: true }
                organization_id: { _eq: $organization_id }
              }
            }
          }
          _or: [
            { is_hidden_from_parent: { _eq: false } }
            { is_hidden_from_parent: { _is_null: true } }
          ]
        }
      }
    ) {
      ...FormSummaryFragment
      form_template {
        enrollment_period {
          name
        }
      }
    }
  }
`;
