import { Grid } from "@chakra-ui/layout";
import {
  Box,
  Button,
  FlexProps,
  FormLabel,
  GridItem,
  GridProps,
  IconButton,
} from "@chakra-ui/react";
import { Field, FieldArray } from "formik";
import { FormControl } from "formik-chakra-ui";
import React from "react";
import { RiAddCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import { FormInput } from "src/components/Inputs/FormInput";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { Option } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { SingleSelectInput } from "src/components/Inputs/QuestionDynamicInputs/SingleSelectInput";

type Props = {
  onSelectedEvents: (values: EventType[]) => void;
  selectedEvents: EventType[];
  values: WebhookFormType;
} & FlexProps &
  GridProps;

export type CredentialsType = {
  key: string;
  label: string;
};

export type EventType = {
  id: string;
  label: string;
};

export type CustomHeaderType = {
  header_key: string;
  header_value: string;
};

export type WebhookFormType = {
  name: string;
  endpoint_url: string;
  credentials_type:
    | "none"
    | "api_key"
    | "basic_auth"
    | "bearer_token"
    | "custom";
  basic_username: string;
  basic_password: string;
  bearer_token: string;
  api_key_header: string;
  api_key: string;
  custom_headers: CustomHeaderType[];
  is_active: boolean;
};

export const EMPTY_FORM_TYPE = {
  name: "",
  credentials_type: "none",
  endpoint_url: "",
  api_key: "",
  api_key_header: "",
  basic_username: "",
  basic_password: "",
  bearer_token: "",
  custom_headers: [
    {
      header_key: "",
      header_value: "",
    },
  ],
  is_active: false,
} as WebhookFormType;

const credentialsTypes: CredentialsType[] = [
  { key: "none", label: "No Auth" },
  { key: "api_key", label: "API Key" },
  { key: "basic_auth", label: "Basic Auth" },
  { key: "bearer_token", label: "Bearer Token" },
  { key: "custom", label: "Custom" },
];

const events: string[] = [
  "FORM_ADMISSIONS",
  "FORM_CANCELLED",
  "FORM_DELETED",
  "FORM_LOTTERYREADY",
  "FORM_SUBMITTED",
  "FORM_VERIFIED",

  "OFFER_OFFERED",
  "OFFER_ACCEPTED",
  "OFFER_DECLINED",
  "OFFER_REVOKED",

  "WAITLIST_WAITLISTED",
  "WAITLIST_WITHDRAWN",
  "WAITLIST_REMOVED",

  "GUARDIAN_CREATED",
  "GUARDIAN_UPDATED",

  "STUDENT_CREATED",
  "STUDENT_UPDATED",
];

export const WebhookForm: React.FC<Props> = ({
  onSelectedEvents,
  selectedEvents,
  values,
  ...props
}) => {
  const [selectedCredentialsType, setCredentialsType] =
    React.useState<Option>();

  React.useEffect(() => {
    if (
      selectedCredentialsType?.key === "custom" &&
      values.custom_headers?.length === 0
    ) {
      values.custom_headers.push({
        header_key: "",
        header_value: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCredentialsType, values.custom_headers]);

  return (
    <Grid templateColumns={"repeat(1, 1fr)"} gap={8}>
      <FormInput
        placeholder="E.g.: Store student data"
        w="100%"
        as={Field}
        type="text"
        id="name"
        name="name"
        label="Name"
        isRequired
      />

      <FormInput
        placeholder="E.g.: http://someurl:4242/webhook"
        w="100%"
        as={Field}
        type="text"
        id="endpoint_url"
        name="endpoint_url"
        label="Endpoint URL"
        isRequired
      />

      <FormControl
        id="credentials_type"
        name="credentials_type"
        isRequired
        w="100%"
      >
        <FormLabel htmlFor="credentials_type">Authentication</FormLabel>
        <SingleSelectInput
          id="credentials_type"
          isRequired
          onOptionSelected={setCredentialsType}
          options={credentialsTypes}
        />
      </FormControl>

      {selectedCredentialsType?.key === "api_key" && (
        <Grid templateColumns={"repeat(2, 1fr)"} gap={8}>
          <FormInput
            placeholder="E.g.: X_API_KEY"
            w="100%"
            as={Field}
            type="text"
            id="api_key_header"
            name="api_key_header"
            label="Header name"
            isRequired
          />
          <FormInput
            placeholder="E.g.: API key"
            w="100%"
            as={Field}
            type="text"
            id="api_key"
            name="api_key"
            label="API Key"
            isRequired
          />
        </Grid>
      )}
      {selectedCredentialsType?.key === "basic_auth" && (
        <Grid templateColumns={"repeat(2, 1fr)"} gap={8}>
          <FormInput
            placeholder="E.g.: username"
            w="100%"
            as={Field}
            type="text"
            id="basic_username"
            name="basic_username"
            label="Username"
            isRequired
          />
          <FormInput
            placeholder="E.g.: password"
            w="100%"
            as={Field}
            type="text"
            id="basic_password"
            name="basic_password"
            label="Password"
            isRequired
          />
        </Grid>
      )}
      {selectedCredentialsType?.key === "bearer_token" && (
        <FormInput
          placeholder="E.g.: token"
          w="100%"
          as={Field}
          type="text"
          id="bearer_token"
          name="bearer_token"
          label="Bearer token"
          isRequired
        />
      )}
      {selectedCredentialsType?.key === "custom" && (
        <FieldArray name="custom_headers">
          {({ insert, remove, push }) => (
            <Grid w="100%" gridTemplateColumns={"1fr 1fr 40px"} gap={2}>
              <GridItem>
                <FormLabel>Header key</FormLabel>
              </GridItem>
              <GridItem colSpan={2}>
                <FormLabel>Header value</FormLabel>
              </GridItem>
              {values.custom_headers?.map(
                (header: CustomHeaderType, index: number) => {
                  return (
                    <React.Fragment key={`custom_headers_${index}`}>
                      <GridItem>
                        <FormInput
                          placeholder="E.g.: Header"
                          w="100%"
                          as={Field}
                          type="text"
                          id={`custom_headers.${index}.header_key`}
                          name={`custom_headers.${index}.header_key`}
                          isRequired
                        />
                      </GridItem>
                      <GridItem>
                        <FormInput
                          placeholder="E.g.: Value"
                          w="100%"
                          as={Field}
                          type="text"
                          id={`custom_headers.${index}.header_value`}
                          name={`custom_headers.${index}.header_value`}
                          isRequired
                        />
                      </GridItem>
                      <GridItem>
                        <IconButton
                          aria-label="Remove header"
                          icon={<RiDeleteBin6Line />}
                          onClick={() => remove(index)}
                          variant="outline"
                          isDisabled={values.custom_headers?.length <= 1}
                        />
                      </GridItem>
                    </React.Fragment>
                  );
                }
              )}
              <GridItem colSpan={3} pt={2}>
                <Button
                  aria-label="Add new header"
                  variant="outline"
                  colorScheme="gray"
                  leftIcon={<RiAddCircleLine />}
                  onClick={() => push({ header_key: "", header_value: "" })}
                >
                  Add new header
                </Button>
              </GridItem>
            </Grid>
          )}
        </FieldArray>
      )}

      <FormControl id="events" name="events" w="100%">
        <FormLabel htmlFor="events">Events to send</FormLabel>
        <MultiSelectInput<EventType>
          size="md"
          initialValues={selectedEvents}
          onChange={onSelectedEvents}
          renderDropdownItem={renderDropdownItem}
          placeholder={"Select multiple"}
          labelAttr="label"
          options={events.map((event) => ({
            id: event,
            label: event,
          }))}
        />
      </FormControl>
    </Grid>
  );
};

const renderDropdownItem = (item: EventType) => (
  <Box>
    <span>{item.label}</span>
  </Box>
);
