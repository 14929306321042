import React from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_PARENT_ASSIGNED_FORMS } from "../../formTemplates/graphql/queries";
import { GET_STUDENTS_BY_GUARDIAN } from "../../students/graphql/queries";
import {
  INSERT_FORM,
  INSERT_FORM_AND_LINK,
  UNHIDE_AND_LINK_FORM,
} from "../graphql/mutations";
import { GET_ASSIGNED_FORM, GET_HIDDEN_FORM } from "../graphql/queries";
import { useCloneForm } from "./useCloneForm";

type AssignedForm = {
  id: uuid;
  form_template: {
    id: uuid;
  };
  person_id: uuid;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
};

export function useStartForm() {
  const { tryCloneDeletedForm } = useCloneForm();
  const [insertForm] = useRemoteDataMutation<
    GQL.InsertForm,
    GQL.InsertFormVariables
  >(INSERT_FORM);

  const [insertFormAndLink] = useRemoteDataMutation<
    GQL.InsertFormAndLink,
    GQL.InsertFormAndLinkVariables
  >(INSERT_FORM_AND_LINK, { refetchQueries: [GET_PARENT_ASSIGNED_FORMS] });

  const [unhideAndLinkForm] = useRemoteDataMutation<
    GQL.UnhideAndLinkForm,
    GQL.UnhideAndLinkFormVariables
  >(UNHIDE_AND_LINK_FORM, { refetchQueries: [GET_PARENT_ASSIGNED_FORMS] });

  const [getHiddenForm] = useLazyRemoteDataQuery<
    GQL.GetHiddenForm,
    GQL.GetHiddenFormVariables
  >(GET_HIDDEN_FORM, { fetchPolicy: "no-cache" });

  const [getAssignedForm] = useLazyRemoteDataQuery<
    GQL.GetAssignedForm,
    GQL.GetAssignedFormVariables
  >(GET_ASSIGNED_FORM, { fetchPolicy: "no-cache" });

  /**
   * This is used by blue banner button to start form from assigned form
   */
  const startAssignedForm = React.useCallback(
    async (assignedForm: AssignedForm) => {
      const hiddenFormResult = await getHiddenForm({
        variables: {
          student_id: assignedForm.person_id,
          form_template_id: assignedForm.form_template.id,
        },
      });

      const hiddenForm = hiddenFormResult.data?.form[0];
      if (hiddenForm) {
        await unhideAndLinkForm({
          variables: {
            form_id: hiddenForm.id,
            previous_form_id: assignedForm.previous_form_id,
            previous_offer_id: assignedForm.previous_offer_id,
            previous_waitlist_id: assignedForm.previous_waitlist_id,
          },
        });

        return hiddenForm.id;
      }

      const result = await insertFormAndLink({
        variables: {
          form_template_id: assignedForm.form_template.id,
          person_id: assignedForm.person_id,
          previous_form_id: assignedForm.previous_form_id,
          previous_offer_id: assignedForm.previous_offer_id,
          previous_waitlist_id: assignedForm.previous_waitlist_id,
        },
      });
      const newFormId = result.data?.insert_form?.returning[0]?.id;

      if (newFormId) {
        await tryCloneDeletedForm({
          formTemplateId: assignedForm.form_template.id,
          studentId: assignedForm.person_id,
          targetFormId: newFormId,
        });
      }

      return newFormId;
    },
    [getHiddenForm, insertFormAndLink, tryCloneDeletedForm, unhideAndLinkForm]
  );

  /**
   * This is used by new form flow where we don't really know if student has assigned form or not
   */
  const startForm = React.useCallback(
    async (props: { studentId: string; formTemplateId: string }) => {
      const [hiddenFormResult, assignedFormResult] = await Promise.all([
        getHiddenForm({
          variables: {
            student_id: props.studentId,
            form_template_id: props.formTemplateId,
          },
        }),
        getAssignedForm({
          variables: {
            student_id: props.studentId,
            form_template_id: props.formTemplateId,
          },
        }),
      ]);

      const hiddenForm = hiddenFormResult.data?.form[0];
      const assignedForm = assignedFormResult.data?.assigned_form[0];

      if (hiddenForm) {
        await unhideAndLinkForm({
          variables: {
            form_id: hiddenForm.id,
            previous_form_id: assignedForm?.previous_form_id,
            previous_offer_id: assignedForm?.previous_offer_id,
            previous_waitlist_id: assignedForm?.previous_waitlist_id,
          },
        });
        return hiddenForm.id;
      }

      let newFormId: string | undefined;
      if (assignedForm) {
        const result = await insertFormAndLink({
          variables: {
            form_template_id: assignedForm.form_template.id,
            person_id: assignedForm.person_id,
            previous_form_id: assignedForm.previous_form_id,
            previous_offer_id: assignedForm.previous_offer_id,
            previous_waitlist_id: assignedForm.previous_waitlist_id,
          },
        });
        newFormId = result.data?.insert_form?.returning[0]?.id;
      } else {
        const data = await insertForm({
          variables: {
            form_template_id: props.formTemplateId,
            person_id: props.studentId,
          },
          refetchQueries: [GET_STUDENTS_BY_GUARDIAN],
        });
        newFormId = data.data?.insert_form_one?.id;
      }

      if (!newFormId) {
        console.error("FormId is null");
        return;
      }

      await tryCloneDeletedForm({
        studentId: props.studentId,
        formTemplateId: props.formTemplateId,
        targetFormId: newFormId,
      });

      return newFormId;
    },
    [
      getAssignedForm,
      getHiddenForm,
      insertForm,
      insertFormAndLink,
      tryCloneDeletedForm,
      unhideAndLinkForm,
    ]
  );

  return {
    startForm,
    startAssignedForm,
    getAssignedForm,
    getHiddenForm,
  };
}
