import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";
import {
  EnrollmentPeriodFormTemplates,
  EnrollmentPeriodGeneral,
  FormTemplateContent,
} from "../url/OrgAdmin";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Enrollment periods",
      href: organization
        .map((org) => Url.OrgAdmin.Enrollments.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNew = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create Enrollment period",
      href: organization
        .map((org) => Url.OrgAdmin.Enrollments.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  enrollmentPeriod: GQL.EnrollmentPeriodBasics
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: enrollmentPeriod.name,
      href: organization
        .map((org) => EnrollmentPeriodGeneral.edit(org, enrollmentPeriod.id))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEditFormTemplate = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  enrollmentPeriod: GQL.EnrollmentPeriodBasics,
  formTemplate: AF.FormTemplate<AF.WithId>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForEdit(organization, enrollmentPeriod),
    {
      label: formTemplate.name,
      href: organization
        .map((org) =>
          FormTemplateContent.edit(org, enrollmentPeriod.id, formTemplate.id)
        )
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNewFormTemplate = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  enrollmentPeriod: GQL.EnrollmentPeriodBasics
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForEdit(organization, enrollmentPeriod),
    {
      label: "Create form from file",
      href: organization
        .map((org) =>
          EnrollmentPeriodFormTemplates.new(org, enrollmentPeriod.id)
        )
        .withDefault("#"),
    },
  ];
};
