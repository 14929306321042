import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { RemoteDataTable } from "src/components/Table/RemoteDataTable";
import { QuestionTypes } from "src/services/url/Admin";
import { ListQuestionTypesForOrganization_custom_question_type } from "src/types/graphql";
import { useListQuestionTypesRemoteData } from "./api";

export const ListQuestionTypes: FunctionComponent<{}> = () => {
  const questionTypesRemoteData = useListQuestionTypesRemoteData();

  return (
    <Flex direction="column" rowGap={6}>
      <Flex alignItems="center">
        <Heading as="h1" size="xl">
          Question types
        </Heading>
        <Spacer />
        <Button as={NavLink} to={QuestionTypes.new()} size="xl">
          Add question type
        </Button>
      </Flex>
      <RemoteDataTable
        data={questionTypesRemoteData}
        columns={QUESTION_TYPE_COLUMNS}
      />
    </Flex>
  );
};

const QUESTION_TYPE_COLUMNS: ColumnDef<ListQuestionTypesForOrganization_custom_question_type>[] =
  [
    { accessorKey: "name", header: "Type name" },
    {
      header: "Organization name",
      accessorFn: (row) => {
        return row.organization.name;
      },
    },
    { accessorKey: "id", header: "Type id", size: undefined }, // undefined size for a "space-between" effect
    {
      header: "Actions",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Button
            as={NavLink}
            to={QuestionTypes.view(id)}
            variant="outline"
            size="sm"
          >
            View
          </Button>
        );
      },
    },
  ];
