import { Box, BoxProps, ComponentWithAs } from "@chakra-ui/react";
import React from "react";
import { FormSection } from "src/components/Layout/FormSection";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import { FormLayout } from "./FormLayout";
import { HorizontallyBoundedContainer } from "./HorizontallyBoundedContainer";
import { AccordionStepper } from "../Buttons/AccordionStepper";

export type Step = {
  id?: string;
  title?: string;
  initialMissingRequiredQuestions?: string[];
};

type ButtonProps = {
  currentStep: number;
  steps: Step[];
};

type Props = {
  steps: Step[];
  currentStep: number;
  content: React.ReactNode;
  headers?: React.ReactNode;
  description?: string;
  buttons: (buttonProps: ButtonProps) => React.ReactNode;
} & StepProps &
  BoxProps;

export type StepProps = {
  currentStep: number; // 1-based index
  steps: Step[];
  headers?: React.ReactNode;
};

export const FormStepLayout: ComponentWithAs<"div", Props> = ({
  content,
  headers,
  steps,
  currentStep,
  buttons,
  description,
  ...props
}: Props) => {
  const title = props.title ?? steps[currentStep - 1]?.title ?? "";
  const organization = useOrganization();

  return (
    <FormLayout
      buttons={buttons({ currentStep, steps })}
      heading="Start form"
      headers={headers}
      backLink={{
        url: organization.map(Url.Parent.index).withDefault("#"),
        label: "Back to forms",
      }}
      width="100%"
      {...props}
    >
      <HorizontallyBoundedContainer
        display="flex"
        gap={5}
        flexDirection="column"
        flexGrow="1"
        width="100%"
      >
        <AccordionStepper steps={steps} currentStep={currentStep} />
        <FormSection title={title} description={description}>
          <Box fontSize="sm">{content}</Box>
        </FormSection>
      </HorizontallyBoundedContainer>
    </FormLayout>
  );
};
