import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import _ from "lodash";
import React from "react";
import { AdditionalQuestionsCheckboxCard } from "src/components/Form/QuestionForm/Edit/AdditionalQuestionsCheckboxCard";
import { EligibilityCheckboxCard } from "src/components/Form/QuestionForm/Edit/EligibilityCheckboxCard";
import {
  FormType,
  getOptionIdsFormValue,
  getQuestionTypeFormValue,
  isInvalid,
  Option,
  toOptionDraft,
} from "src/components/Form/QuestionForm/formik";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { CustomFormInput } from "src/components/Inputs/CustomFormInput";
import { Card } from "src/components/Layout/Card";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as Question from "src/services/formTemplate/question";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";

type Props = {
  sectionType: AF.SectionType;
  questionId: uuid;
  question: Draft.Question | undefined;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  showAdditionalQuestions?: boolean;
  verificationOptions: AF.FormVerification<AF.WithId>[];
};
export const AnswersOptions: React.FC<Props> = ({
  sectionType,
  questionId,
  schools,
  showAdditionalQuestions = true,
  verificationOptions,
  question,
}) => {
  const form = useFormikContext<FormType>();
  const questionType = getQuestionTypeFormValue(questionId, form.values);
  const optionIds = getOptionIdsFormValue(questionId, form.values);

  const addAnswerClickHandler = React.useCallback(() => {
    const optionId = window.crypto.randomUUID();
    form.setValues((values) => ({
      ...values,
      optionIds: {
        ...values.optionIds,
        [questionId]: [...optionIds, optionId],
      },
      options: {
        ...values.options,
        [optionId]: {
          label: "",
          isNew: true,
        },
      },
    }));
  }, [form, optionIds, questionId]);

  const onRemoveAnswerHandler = React.useCallback(
    (optionIdToRemove: uuid) => {
      const newList = optionIds.filter(
        (optionId) => optionId !== optionIdToRemove
      );
      form.setValues((values) => ({
        ...values,
        optionIds: { ...values.optionIds, [questionId]: newList },
        options: _.omit(values.options, optionIdToRemove),
      }));
    },
    [form, optionIds, questionId]
  );

  const onValueChangeHandler = React.useCallback(
    (optionId: uuid, option: Option) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        form.setTouched({
          ...form.touched,
          options: {
            ...form.touched.options,
            [optionId]: {
              ...form.touched.options?.[optionId],
              value: true,
            },
          },
        });

        const updatedOption = { ...option, value: event.target.value };
        form.setValues((values) => ({
          ...values,
          options: {
            ...values.options,
            [optionId]: updatedOption,
          },
        }));
      };
    },
    [form]
  );

  const onLabelChangeHandler = React.useCallback(
    (optionId: uuid, option: Option) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        form.setTouched({
          ...form.touched,
          options: {
            ...form.touched.options,
            [optionId]: {
              ...form.touched.options?.[optionId],
              label: true,
            },
          },
        });
        const updatedOption = { ...option, label: event.target.value };
        form.setValues((values) => ({
          ...values,
          options: {
            ...values.options,
            [optionId]: updatedOption,
          },
        }));
      };
    },
    [form]
  );

  if (!Question.hasOptions({ type: questionType })) {
    return null;
  }

  const touched = form.touched.validations?.[questionId]?.options;
  const error = form.errors.validations?.[questionId]?.options;

  return (
    <Flex direction="column" gap="3" alignItems="flex-start">
      {optionIds.map((optionId, index) => {
        const originalOption = Question.hasOptions(question)
          ? question.options.find((o) => o.id === optionId)
          : undefined;
        const option: Draft.Option = toOptionDraft(
          optionId,
          form.values,
          originalOption
        );

        const labelTouched = form.touched.options?.[optionId]?.label;
        const labelError = form.errors.options?.[optionId]?.label;

        const valueTouched = form.touched.options?.[optionId]?.value;
        const valueError = form.errors.options?.[optionId]?.value;

        return (
          <Card key={optionId} showBorder useFlex width="100%">
            <Flex gap="2" direction="row" alignItems="flex-end">
              <CustomFormInput
                id={optionId}
                label={`Answer ${index + 1}`}
                error={labelTouched ? labelError : undefined}
              >
                <Input
                  id={optionId}
                  value={option.label}
                  flexGrow="1"
                  onChange={onLabelChangeHandler(optionId, option)}
                />
              </CustomFormInput>

              <CustomFormInput
                error={valueTouched ? valueError : undefined}
                id={optionId}
                label="Answer value (optional)"
              >
                <Input
                  flexGrow={1}
                  onChange={onValueChangeHandler(optionId, option)}
                  placeholder="E.g.: RED"
                  value={option.value ?? ""}
                />
              </CustomFormInput>

              {option.isNew && (
                <Button
                  flexShrink={0}
                  variant="outline"
                  colorScheme="gray"
                  onClick={() =>
                    onRemoveAnswerHandler && onRemoveAnswerHandler(optionId)
                  }
                >
                  Remove answer
                </Button>
              )}
            </Flex>

            <Flex direction="column" paddingLeft="2rem" gap="3">
              <EligibilityCheckboxCard
                sectionType={sectionType}
                questionType={questionType}
                option={option}
                schools={schools}
              />
              {showAdditionalQuestions && (
                <AdditionalQuestionsCheckboxCard
                  sectionType={sectionType}
                  option={option}
                  schools={schools}
                  verificationOptions={verificationOptions}
                />
              )}
            </Flex>
          </Card>
        );
      })}
      <Flex direction="column" alignItems="flex-start">
        <Button colorScheme="gray" onClick={addAnswerClickHandler}>
          Add answer
        </Button>
        <FormControl isInvalid={isInvalid({ touched, error })}>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Flex>
  );
};
