import { Alert, AlertIcon, Flex, Spacer, VStack } from "@chakra-ui/react";
import React from "react";
import { PreferredLanguageSetting } from "src/hooks/usePreferredLanguage";
import { WeglotSelect } from "src/plugins/weglot";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";

type Props = {
  children: React.ReactNode;
  languageSelector?: { preferredLanguageSetting: PreferredLanguageSetting };
  alertText?: string;
};

export function AuthLayout({
  children,
  languageSelector,
  alertText,
}: Props): React.ReactElement {
  return (
    <>
      <Flex
        fontSize="sm"
        minWidth="100vw"
        minHeight="100vh"
        bg="gray.200"
        padding={3}
        direction="column"
        className={WEGLOT_APPLY_CLASS}
        alignItems="stretch"
        justifyContent="stretch"
        gap={3}
      >
        <Flex>
          <Spacer />
          {languageSelector && (
            <WeglotSelect
              colorScheme="gray"
              preferredLanguageSetting={
                languageSelector.preferredLanguageSetting
              }
            />
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="center" flexGrow="1">
          <VStack width="21.25rem" maxWidth="21.25rem">
            {!!alertText && (
              <Alert borderRadius={6} status="info" marginBottom="1rem">
                <AlertIcon />
                {alertText}
              </Alert>
            )}
            <Flex
              bg="white"
              width="21.25rem"
              maxWidth="21.25rem"
              alignItems="center"
              direction="column"
              gap={16}
              padding={6}
              borderRadius="md"
              boxShadow="md"
            >
              {children}
            </Flex>
          </VStack>
        </Flex>
      </Flex>
    </>
  );
}
