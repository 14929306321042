import { FunctionComponent } from "react";
import { AccountLookupResponse } from "./helpers";
import { CreateAnAccount } from "./results/CreateAnAccount";
import { FoundAnAccount } from "./results/FoundAnAccount";
import { NoAccountFound } from "./results/NoAccountFound";
import * as OrgConfig from "@avela/organization-config-sdk";
import { DuplicateAccountFound } from "./results/DuplicateAccountFound";
import { FoundAnAccountWithMultipleContacts } from "./results/FoundAnAccountWithMultipleContacts";

type Props = {
  lookupResponse: AccountLookupResponse;
  resetSteps: () => void;
  config: OrgConfig.AccountLookup.Config;
};

/**
 * There are three different results:
 * 1. No account login found
 * 2. We found an account
 * 3. Create your account
 */
export const ShowResultStep: FunctionComponent<Props> = (props) => {
  const { lookupResponse, resetSteps, config } = props;

  if (lookupResponse.type === "duplicate") {
    return <DuplicateAccountFound config={config} />;
  }

  const { auth0UserType, parentGuardianExists } = lookupResponse;

  const foundAnAccount = parentGuardianExists && auth0UserType !== null;
  if (foundAnAccount) {
    if (
      lookupResponse.emailAddress !== null &&
      lookupResponse.phoneNumber !== null
    ) {
      return (
        <FoundAnAccountWithMultipleContacts
          {...lookupResponse}
          emailAddress={lookupResponse.emailAddress}
          phoneNumber={lookupResponse.phoneNumber}
          config={config}
        />
      );
    }
    return <FoundAnAccount {...lookupResponse} config={config} />;
  }

  const createAnAccount = parentGuardianExists && auth0UserType === null;
  if (createAnAccount) {
    return <CreateAnAccount {...lookupResponse} config={config} />;
  }

  return <NoAccountFound resetSteps={resetSteps} config={config} />;
};
