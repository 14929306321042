import { useApolloClient } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { normalizeSearchQuery } from "src/components/graphql/utils";
import {
  AttendanceTypes,
  FILTER_TYPES,
  FormTabsTypes,
  SearchAndFilterTypes,
  getAdmissionTab,
} from "src/constants";
import {
  useOrderByParams,
  usePaginationParams,
} from "src/hooks/useCommonSearchParams";
import { useFormTemplatePermissions } from "src/hooks/useFormTemplatePermissions";
import { useGlossary } from "src/hooks/useGlossary";
import { Selection } from "src/hooks/useMultiselectState";
import { usePaginatedRemoteDataQuery } from "src/hooks/usePaginatedRemoteDataQuery";
import {
  useRemoteDataQuery,
  useRemoteDataQueryPromise,
} from "src/hooks/useRemoteDataQuery";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import useUser from "src/hooks/useUser";
import { FormListImportRow } from "src/scenes/orgAdmin/forms/components/ImportMenuInput";
import { applyFilterByCapacity } from "src/scenes/orgAdmin/forms/formFilters/utils";
import {
  EXPORT_FORMS,
  GET_FORMS_BY_FORM_TEMPLATE,
  GET_FORM_IDS_BY_FORM_TEMPLATE,
  GET_METADATA_BY_FORM_TEMPLATE,
  GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD,
} from "src/scenes/orgAdmin/forms/graphql/queries";
import {
  GqlSchoolForm,
  SchoolFormId,
  SchoolFormKeyRecordFactory,
} from "src/scenes/orgAdmin/forms/types";
import { isNotNull } from "src/services/predicates";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { toTuple3 } from "src/types/remoteData";
import * as GQLUtils from "../graphql/utils";
import { useDropoffFormsList } from "./useDropoffFormsList";
import {
  SearchParamDefaultOverrides,
  useFormsListTab,
} from "./useFormsListTab";

export type UseFormsListProps = {
  enrollmentPeriodId?: uuid | undefined;
  formTemplateId?: uuid | undefined;
  additionalSearch?: GQL.search_form_by_school_bool_exp;
  searchParamDefaultOverrides?: SearchParamDefaultOverrides;
};
export function useFormsList({
  enrollmentPeriodId,
  formTemplateId,
  additionalSearch,
  searchParamDefaultOverrides,
}: UseFormsListProps = {}) {
  const apolloClient = useApolloClient();
  const user = useUser();
  const claims = user.status === Status.OK ? user.data : null;
  const { isSchoolAdmin } = useSchoolAdmin();
  const { pruneFormTemplate } = useFormTemplatePermissions();

  const { glossary } = useGlossary();
  const { orderBy, setOrderBy } = useOrderByParams();
  const { pagination, setPagination } = usePaginationParams();
  const { selectedNavFormTemplate } = useFormTemplates();

  /**
   * Search
   */

  const [searchParams, setSearchParams] = useSearchParams();
  enrollmentPeriodId =
    enrollmentPeriodId ??
    searchParams.get(SearchAndFilterTypes.EnrollmentPeriod) ??
    "";

  formTemplateId = useMemo(
    () => formTemplateId ?? selectedNavFormTemplate?.id,
    [formTemplateId, selectedNavFormTemplate]
  );

  const programCapacityFilter = searchParams.get(
    SearchAndFilterTypes.WithinCapacity
  );

  const [selection, setSelection] = useState(
    Selection.create((app: GqlSchoolForm) =>
      // School rank is almost unique, but can be null, so combine with app ID.
      SchoolFormKeyRecordFactory({
        formId: app.form?.id ?? "",
        schoolId: app.form_school_rank?.school.id ?? null,
        formSchoolRankId: app.form_school_rank?.id ?? null,
      })
    )
  );
  const setSearchParamsAndClearSelection: typeof setSearchParams = useCallback(
    (nextInit, navigateOptions) => {
      setSearchParams(nextInit, { replace: true, ...navigateOptions });
      setSelection((s) => s.clear());
    },
    [setSearchParams, setSelection]
  );

  const onClear = useCallback(() => {
    searchParams.delete(SearchAndFilterTypes.Search);
    searchParams.delete(SearchAndFilterTypes.Offset);
    setSearchParamsAndClearSelection(searchParams);
  }, [searchParams, setSearchParamsAndClearSelection]);

  const onSearch = useCallback(
    (searchTerm: string) => {
      const normalized = normalizeSearchQuery(searchTerm);
      searchParams.set(SearchAndFilterTypes.Search, normalized);
      searchParams.delete(SearchAndFilterTypes.Offset);
      setSearchParamsAndClearSelection(searchParams);
    },
    [searchParams, setSearchParamsAndClearSelection]
  );

  const isSearchApplied = searchParams.has(SearchAndFilterTypes.Search);

  // Clear selection whenever enrollment period, search, or filters change.
  useEffect(() => {
    setSelection((s) => s.clear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentPeriodId, formTemplateId]);

  /** Form Import */
  const [formListImportData, setFormListImportData] = useState<
    FormListImportRow[]
  >([]);

  const handleOnFormListImported = (data: FormListImportRow[]) => {
    setFormListImportData(data);

    const formListImportsTab = getAdmissionTab(FormTabsTypes.FormListImports);
    if (!formListImportsTab) {
      throw new Error("Something went wrong!");
    }

    formListImportsTab.isVisible = true; // TODO investigate why this needs to be mutated
    tabs.onChange(formListImportsTab);
  };

  /**
   * Tabs
   */
  const tabs = useFormsListTab({
    formTemplateId,
    searchParams,
    setSearchParamsAndClearSelection,
    setFormListImportData,
    searchParamDefaultOverrides,
  });

  /**
   * Filters
   */
  const {
    isOpen: isFilterOpen,
    onToggle: onFilterToggle,
    onOpen: onFilterOpen,
  } = useDisclosure();

  useEffect(() => {
    if (GQLUtils.verifyIfFiltersAreApplied(searchParams)) {
      onFilterOpen();
    }
  }, [searchParams, onFilterOpen]);

  const isFilterApplied = FILTER_TYPES.some((filterType) =>
    searchParams.has(filterType)
  );

  const handleClearFilters = useCallback(() => {
    searchParams.delete(SearchAndFilterTypes.Offset);
    FILTER_TYPES.forEach((filterType) => searchParams.delete(filterType));
    setSearchParamsAndClearSelection(searchParams);
  }, [searchParams, setSearchParamsAndClearSelection]);

  /**
   * Forms List
   */
  const selectAll = useCallback(
    (count: number) => {
      setSelection((s) => s.selectAll(count));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearAll = useCallback(() => {
    searchParams.delete(SearchAndFilterTypes.Search);
    searchParams.delete(SearchAndFilterTypes.Offset);
    FILTER_TYPES.forEach((filterType) => searchParams.delete(filterType));
    setSearchParamsAndClearSelection(searchParams);
  }, [searchParams, setSearchParamsAndClearSelection]);

  const searchExp = {
    _and: [
      GQLUtils.buildSearchAndFilterQuery(searchParams, claims),
      { ...GQLUtils.buildFormSchoolRankFilterQuery(searchParams, claims) },
      GQLUtils.buildSelectedTabFilter(searchParams),
      GQLUtils.buildFormSchoolFilter(formListImportData),
      ...(additionalSearch ? [additionalSearch] : []),
    ],
  };

  const { remoteData: metadataRD } = useRemoteDataQuery<
    GQL.GetMetadataByFormTemplate,
    GQL.GetMetadataByFormTemplateVariables
  >(GET_METADATA_BY_FORM_TEMPLATE, {
    variables: {
      form_template_id: formTemplateId ?? "",
      skip_capacities: !programCapacityFilter,
      program_equals_exp:
        programCapacityFilter && programCapacityFilter !== "1"
          ? { program_id: { _eq: programCapacityFilter } }
          : {},
    },
    skip: !formTemplateId,
  });
  const formTemplateData = useMemo(
    () =>
      metadataRD.map(
        (data) =>
          data.form_template_by_pk &&
          pruneFormTemplate(data.form_template_by_pk)
      ),
    [metadataRD, pruneFormTemplate]
  );

  const { remoteData: tagGroupsData } = useRemoteDataQuery<
    GQL.GetTagGroupsByEnrollmentPeriod,
    GQL.GetTagGroupsByEnrollmentPeriodVariables
  >(GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period_id: enrollmentPeriodId ?? "",
    },
    skip: !enrollmentPeriodId,
  });

  const { usePaginatedQuery, useUnpaginatedQueryPromise } =
    usePaginatedRemoteDataQuery<
      GQL.GetFormsByFormTemplate,
      GQL.GetFormsByFormTemplateVariables
    >(GET_FORMS_BY_FORM_TEMPLATE, {
      form_template_id: formTemplateId ?? "",
      search: searchExp,
      order_by: GQLUtils.buildOrderClause(
        orderBy,
        claims?.role ?? HasuraRole.USER
      ),
      // Need to query all rows (unpaginated) if applying capacity filter
      ...(!programCapacityFilter
        ? {
            limit: pagination.limit,
            offset: pagination.offset,
          }
        : { limit: undefined, offset: undefined }),
      skip_rank: isSchoolAdmin,
      include_waitlist_position: tabs.current.key === FormTabsTypes.Waitlists,
    });

  const { remoteData, refetch } = usePaginatedQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip:
      !enrollmentPeriodId ||
      !formTemplateId ||
      tabs.current.type === FormTabsTypes.DropoffForms,
  });

  const fetchAll = useUnpaginatedQueryPromise();
  const fetchAllIds = useRemoteDataQueryPromise<
    GQL.GetFormIdsByFormTemplate,
    GQL.GetFormIdsByFormTemplateVariables
  >(GET_FORM_IDS_BY_FORM_TEMPLATE, {
    variables: {
      form_template_id: formTemplateId ?? "",
      search: searchExp,
    },
  });

  const onFetchAll = useCallback(async () => {
    const result = await fetchAll();
    if (result.error) throw new Error(result.error.message);
    return programCapacityFilter
      ? applyFilterByCapacity(
          result.data.search_form_by_school,
          formTemplateData.toNullable()?.enrollment_period.grades ?? []
        )
      : result.data.search_form_by_school;
  }, [fetchAll, programCapacityFilter, formTemplateData]);

  const onFetchAllIds = useCallback(async (): Promise<SchoolFormId[]> => {
    if (programCapacityFilter) {
      const result = await fetchAll();
      if (result.error) throw new Error(result.error.message);
      const forms = applyFilterByCapacity(
        result.data.search_form_by_school,
        formTemplateData.toNullable()?.enrollment_period.grades ?? []
      );
      return forms.map((app) => ({
        formId: app.form?.id ?? "",
        schoolId: app.form_school_rank?.school.id ?? null,
        formSchoolRankId: app.form_school_rank?.id ?? null,
      }));
    }
    const result = await fetchAllIds();
    if (result.error) throw new Error(result.error.message);
    return result.data.search_form_by_school.map(
      ({ form_id, form_school_rank_id, school_id }) => ({
        formId: form_id ?? "",
        schoolId: school_id ?? null,
        formSchoolRankId: form_school_rank_id ?? null,
      })
    );
  }, [fetchAll, fetchAllIds, programCapacityFilter, formTemplateData]);

  const fetchDetailed = useCallback(
    async (ids: SchoolFormId[]) => {
      const result = await apolloClient.query<
        GQL.ExportForms,
        GQL.ExportFormsVariables
      >({
        query: EXPORT_FORMS,
        variables: {
          skip_rank: isSchoolAdmin,
          form_ids: Array.from(new Set(ids.map((id) => id.formId)).values()),
          form_school_rank_ids: ids
            .map((id) => id.formSchoolRankId)
            .filter(isNotNull),
        },
        fetchPolicy: "no-cache",
      });
      if (result.error) throw new Error(result.error.message);
      return result.data;
    },
    [apolloClient, isSchoolAdmin]
  );

  const handleAttendanceFilterChange = useCallback(
    (value: string) => {
      if (value === searchParams.get(SearchAndFilterTypes.Attendance)) return;
      switch (value) {
        case AttendanceTypes.Applying: {
          searchParams.set(
            SearchAndFilterTypes.Attendance,
            AttendanceTypes.Applying
          );
          searchParams.delete(SearchAndFilterTypes.School);
          searchParams.delete(SearchAndFilterTypes.AttendingSchool);
          break;
        }
        case AttendanceTypes.Attending: {
          searchParams.set(
            SearchAndFilterTypes.Attendance,
            AttendanceTypes.Attending
          );
          searchParams.delete(SearchAndFilterTypes.School);
          searchParams.delete(SearchAndFilterTypes.AttendingSchool);
          break;
        }
        default: {
          searchParams.delete(SearchAndFilterTypes.Attendance);
          break;
        }
      }
      setSearchParamsAndClearSelection(searchParams);
    },
    [searchParams, setSearchParamsAndClearSelection]
  );

  const dropoffForms = useDropoffFormsList({
    formTemplateId,
    enrollmentPeriodId,
    tabs,
  });

  return {
    tabs,
    search: {
      onSearch,
      value: searchParams.get(SearchAndFilterTypes.Search) ?? "",
      onClear,
      placeholder: glossary`Search by form ID, student name or ID, parent name or ID`,
      expression: searchExp,
      params: searchParams,
      isApplied: isSearchApplied,
    },
    dropoffForms,
    remoteData: toTuple3(remoteData, formTemplateData, tagGroupsData).map(
      ([data, formTemplate, tagGroups]) => {
        const forms = programCapacityFilter
          ? applyFilterByCapacity(
              data.search_form_by_school,
              formTemplate?.enrollment_period.grades ?? []
            )
          : [];
        const tableData = programCapacityFilter
          ? forms.slice(pagination.offset, pagination.offset + pagination.limit)
          : data.search_form_by_school;

        const count = programCapacityFilter
          ? forms.length
          : data.search_form_by_school_aggregate.aggregate?.count ?? 0;

        return {
          tableData,
          formTemplate,
          tagGroups,
          count,
        };
      }
    ),
    formsList: {
      clearAll,
      selectAll,
      refetch,
      fetchAll: onFetchAll,
      fetchAllIds: onFetchAllIds,
      fetchDetailed,
      sort: setOrderBy,
      imported: handleOnFormListImported,
      setAttendance: handleAttendanceFilterChange,
    },
    setSearchParamsAndClearSelection,
    setSelection,
    selection,
    pagination,
    setPagination,
    filter: {
      isOpen: isFilterOpen,
      open: isFilterOpen,
      toggle: onFilterToggle,
      clear: handleClearFilters,
      isApplied: isFilterApplied,
    },
    enrollmentPeriodId,
  };
}
