import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
  LocationBoundariesFilterRuleOptions,
  LocationBoundariesFilterRuleSchema,
} from "src/schemas/formTemplateFilters";
import { EligibleFilterType } from "src/types/formTemplateFilters";
import { FormType, getLocationBoundariesFilterFormValue } from "../formik";

type Props = {
  questionId: uuid;
  disabled?: boolean;
};
export const ConfigureLocationBoundaries: React.FC<Props> = ({
  questionId,
  disabled,
}) => {
  const form = useFormikContext<FormType>();

  const filters = getLocationBoundariesFilterFormValue(questionId, form.values);
  const rule = filters[0]?.config.rule;

  const onChangeMainSwitchHandler = React.useCallback(() => {
    form.setValues((values) => {
      if (filters.length > 0)
        return {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: [],
          },
        };
      return {
        ...values,
        formTemplateFilters: {
          ...values.formTemplateFilters,
          [questionId]: [
            {
              type: EligibleFilterType.LocationBoundariesFilter,
              config: {
                rule: LocationBoundariesFilterRuleOptions.Filter,
              },
            },
          ],
        },
      };
    }, false);
  }, [filters.length, form, questionId]);

  const onRuleChangeHandler = React.useCallback(
    (rule: LocationBoundariesFilterRuleOptions) => {
      form.setValues((values) => {
        return {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: [
              {
                type: EligibleFilterType.LocationBoundariesFilter,
                config: {
                  rule,
                },
              },
            ],
          },
        };
      }, false);
    },
    [form, questionId]
  );

  useEffect(() => {
    if (disabled) {
      // when disabled, it means other questions already have location boundaries filter on, we need to turn this one off since can there be only one.
      form.setValues((values) => {
        return {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: [],
          },
        };
      });
    }
    // only run this effect when disabled value is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  let state;
  if (disabled) {
    state = "disabled" as const;
  } else if (filters.length > 0) {
    state = "on" as const;
  } else {
    state = "off" as const;
  }

  return (
    <Flex direction="column" width="70%" gap="4">
      <Flex direction="column">
        <Text mb={1}>Location boundaries</Text>
        <FormLabel
          htmlFor={`locationBoundaries${questionId}`}
          display="flex"
          flexDirection="row"
          gap="2"
          margin={0}
          mb={1}
        >
          <Switch
            id={`locationBoundaries${questionId}`}
            isChecked={state === "on"}
            onChange={onChangeMainSwitchHandler}
            isDisabled={disabled}
          />
          <Box>{state === "on" ? "On" : "Off"}</Box>
        </FormLabel>

        {state === "on" && (
          <Text fontSize="sm" fontWeight="400" color="gray.600">
            The answer to this question can be used to affect displayed schools
            based on configured school boundaries.
          </Text>
        )}

        {state === "off" && (
          <Text fontSize="sm" fontWeight="400" color="gray.600">
            The answer to this question will not affect displayed schools based
            on configured school boundaries.
          </Text>
        )}

        {state === "disabled" && (
          <Text fontSize="sm" fontWeight="400" color="gray.600">
            There's already an Address question with Location boundaries turned
            on
          </Text>
        )}
      </Flex>

      {state === "on" && (
        <FormControl>
          <Select
            value={rule}
            onChange={(e) => {
              const result = LocationBoundariesFilterRuleSchema.safeParse(
                e.target.value
              );
              const rule = result.success
                ? result.data
                : LocationBoundariesFilterRuleOptions.Filter;
              onRuleChangeHandler(rule);
            }}
          >
            <option value={LocationBoundariesFilterRuleOptions.Filter}>
              Only show schools with boundaries
            </option>
            <option value={LocationBoundariesFilterRuleOptions.ShowFirst}>
              Show schools within boundaries at the top of the list
            </option>
          </Select>
        </FormControl>
      )}
    </Flex>
  );
};
