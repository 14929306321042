import { FormLabel } from "@chakra-ui/react";
import { FunctionComponent } from "react";

type AddressBookLabelProps = {
  id: string;
  label: string;
};

export const AddressBookLabel: FunctionComponent<AddressBookLabelProps> = (
  props
) => {
  const { id, label } = props;
  return (
    <FormLabel
      fontSize="sm"
      htmlFor={id}
      display="flex"
      gap={3}
      alignItems="center"
      fontWeight="500"
    >
      {label}
    </FormLabel>
  );
};
