import {
  Alert,
  Button,
  Divider,
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import {
  BaseAddressSchema,
  CURRENT_ADDRESS,
  NO_ADDRESS,
  isAddressBlank,
} from "src/components/Inputs/Address/Book";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { Glossary } from "src/components/Text/Glossary";
import { useDeleteConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserProfile } from "src/hooks/useUserProfile";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { AddressSchema } from "src/schemas/Address";
import { validateWithZod } from "src/services/formValidations";
import { fullName } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { UPDATE_STUDENT } from "../../orgAdmin/students/graphql/mutations";
import { StudentForm } from "../components/Forms/StudentForm";
import { SOFT_DELETE_STUDENT } from "./graphql/mutations";
import {
  GET_STUDENTS_BY_GUARDIAN,
  GET_STUDENT_BY_ID_PARENT_PORTAL,
} from "./graphql/queries";
import {
  StudentProfileForm,
  StudentProfileSchema,
  StudentProfileValidator,
} from "./schemas";

export function Edit() {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const organization = useOrganization();
  const { id = "" } = useParams();
  const userProfile = useUserProfile();
  const { confirm, confirmationDialog, setHeader } =
    useDeleteConfirmationDialog({
      body: (
        <Flex direction="column" gap={3}>
          <Text>This can’t be undone nor recovered.</Text>
        </Flex>
      ),
    });

  const { remoteData } = useRemoteDataQuery<
    GQL.GetStudentByIdParentPortal,
    GQL.GetStudentByIdParentPortalVariables
  >(GET_STUDENT_BY_ID_PARENT_PORTAL, {
    variables: { id },
    fetchPolicy: "no-cache",
  });

  const [updateStudent] = useRemoteDataMutation<
    GQL.UpdateStudent,
    GQL.UpdateStudentVariables
  >(UPDATE_STUDENT);

  const [deleteStudent] = useRemoteDataMutation<
    GQL.SoftDeleteStudent,
    GQL.SoftDeleteStudentVariables
  >(SOFT_DELETE_STUDENT);

  function showErrorToast(title: string, description?: string) {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: title,
        description:
          description ??
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
      });
    }
  }

  const toastId = "EditStudent";

  React.useEffect(() => {
    if (remoteData.hasError()) {
      showErrorToast("Edit Student");
    }
  });

  const handleSubmit = async (form: StudentProfileForm) => {
    if (!organization.hasData() || !userProfile.hasData()) {
      showErrorToast("Edit Student");
      return;
    }

    setSubmitting(true);
    try {
      // when editing student profile, changing birth_date is not allowed.
      // don't try to include current_schools either.
      const {
        birth_date,
        address,
        applicant_attending_schools,
        ...omittedBirthdateValues
      } = StudentProfileSchema.parse(form);
      const parsedAddress = BaseAddressSchema.parse(address);

      await updateStudent({
        variables: {
          id,
          student: {
            ...omittedBirthdateValues,
            ...parsedAddress,
          },
        },
        refetchQueries: [GET_STUDENTS_BY_GUARDIAN],
      });
      navigate(Url.Parent.Student.index(organization.data));
    } catch (err) {
      console.error(err);
      showErrorToast("Edit Student");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteStudent = async () => {
    if (!organization.hasData() || !userProfile.hasData()) {
      showErrorToast("Edit Student");
      return;
    }

    const student = remoteData.map((student) => ({
      name: student.person_by_pk ? fullName(student.person_by_pk) : "",
      hasForms: student.person_by_pk?.forms_aggregate.aggregate?.count,
    }));

    if (student.hasData() && student.data.hasForms) {
      showErrorToast("Delete Student", "Student has active forms.");
      return;
    }

    const name = student.hasData() ? student.data.name : "";
    setHeader(
      <>
        Delete <span className={WEGLOT_SKIP_CLASS}>{name}</span>?
      </>
    );

    if (!(await confirm())) {
      return;
    }

    setDeleting(true);
    try {
      await deleteStudent({
        variables: {
          id,
        },
        refetchQueries: [GET_STUDENTS_BY_GUARDIAN],
      });
      toast({
        id: "delete-student",
        title: "Student deleted",
        isClosable: true,
        status: "info",
      });

      navigate(Url.Parent.Student.index(organization.data));
    } catch (err) {
      showErrorToast("Delete Student");
    } finally {
      setDeleting(false);
    }
  };

  return (
    <ParentFormsLayout
      paddingX={0}
      heading={glossary`Edit student`}
      backLink={organization
        .map((org) => ({
          url: Url.Parent.Student.index(org),
          label: glossary`Back to students`,
        }))
        .withDefault(undefined)}
    >
      <ParentRemoteDataLayout remoteData={remoteData}>
        {(data) => {
          // allow pre-existing null address column values
          const parsedAddress = AddressSchema.parse(data.person_by_pk);
          const addressKind = isAddressBlank(parsedAddress)
            ? NO_ADDRESS
            : CURRENT_ADDRESS;

          const parsedStudent = StudentProfileSchema.safeParse({
            ...data.person_by_pk,
            address: {
              kind: addressKind,
              ...parsedAddress,
            },
          });

          if (!parsedStudent.success) {
            console.error(parsedStudent.error);
            return (
              <Alert status="error">
                <Glossary>Error loading student</Glossary>
              </Alert>
            );
          }

          return (
            <Formik<StudentProfileForm>
              initialValues={parsedStudent.data}
              onSubmit={handleSubmit}
              validate={validateWithZod(StudentProfileValidator)}
              validateOnMount={true}
            >
              {(formikProps) => {
                return (
                  <Flex direction="column" as={Form} gap={6}>
                    <StudentForm
                      editMode={true}
                      student={parsedStudent.data}
                      paddingX={6}
                    />

                    <Divider />
                    <Grid gap={3} gridTemplateColumns="1fr 1fr" paddingX={6}>
                      <Button
                        as={NavLink}
                        flexGrow="1"
                        colorScheme="gray"
                        to={organization
                          .map((org) => Url.Parent.Student.index(org))
                          .withDefault("#")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        isLoading={submitting}
                        isDisabled={!formikProps.dirty || !formikProps.isValid}
                        flexGrow="1"
                      >
                        Save changes
                      </Button>

                      <Button
                        gridColumn="1/3"
                        variant="ghost"
                        colorScheme="gray"
                        isLoading={deleting}
                        leftIcon={<RiDeleteBin6Line />}
                        onClick={handleDeleteStudent}
                        isDisabled={
                          !!data.person_by_pk?.forms_aggregate.aggregate?.count
                        }
                      >
                        <Glossary>Delete student</Glossary>
                      </Button>

                      {!!data.person_by_pk?.forms_aggregate.aggregate
                        ?.count && (
                        <Text
                          gridColumn="1/3"
                          textAlign="center"
                          variant="helperText"
                        >
                          {`To remove ${data.person_by_pk?.first_name}, cancel or delete any on going forms first`}
                        </Text>
                      )}
                    </Grid>
                  </Flex>
                );
              }}
            </Formik>
          );
        }}
      </ParentRemoteDataLayout>
      {confirmationDialog}
    </ParentFormsLayout>
  );
}
