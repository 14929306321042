import { Flex, Radio, RadioGroup, Skeleton } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { BulkMultiSelect } from "src/components/BulkMultiSelect";
import { Item } from "src/components/BulkMultiSelect/types";
import { GenericError } from "src/components/Feedback/GenericError";
import {
  Eligibility,
  EligibilityExclusion,
  EligibilityInclusion,
  FormType,
  getEligibilityFormValue,
} from "src/components/Form/QuestionForm/formik";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { CheckboxCard } from "src/components/Layout/CheckboxCard";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";

function isEnable(
  eligibility: Eligibility | undefined
): eligibility is EligibilityInclusion | EligibilityExclusion {
  return eligibility?.type === "includes" || eligibility?.type === "excludes";
}

type EligibilityCheckboxCardProps = {
  sectionType: AF.SectionType;
  questionType: AF.QuestionType;
  option: Draft.Option;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
};
export const EligibilityCheckboxCard: React.FC<
  EligibilityCheckboxCardProps
> = ({ sectionType, questionType, option, schools }) => {
  const form = useFormikContext<FormType>();

  const eligibility = getEligibilityFormValue(option.id, form.values);
  const schoolIds = React.useMemo(
    () => eligibility.schoolIds ?? [],
    [eligibility.schoolIds]
  );

  const onCheckHandler = React.useCallback(
    (value: boolean): void => {
      if (value) {
        form.setValues((values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: "includes",
              schoolIds,
            },
          },
        }));
      } else {
        form.setValues((values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: "disabled",
            },
          },
        }));
      }
    },
    [form, option.id, schoolIds]
  );

  const onRadioChangeHandler = React.useCallback(
    (nextValue: string): void => {
      if (nextValue === "includes" && eligibility.type === "excludes") {
        form.setValues((values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: "includes",
              schoolIds: values.eligibilities[option.id]?.schoolIds ?? [],
            },
          },
        }));
      } else if (nextValue === "excludes" && eligibility.type === "includes") {
        form.setValues((values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: "excludes",
              schoolIds: values.eligibilities[option.id]?.schoolIds ?? [],
            },
          },
        }));
      }
    },
    [eligibility.type, form, option.id]
  );

  const onSchoolIdRemovedHandler = React.useCallback(
    (removedSchool: Item): void => {
      form.setTouched({ eligibilities: { [option.id]: { schoolIds: true } } });
      form.setValues(
        (values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: form.values.eligibilities[option.id]?.type ?? "includes",
              schoolIds: schoolIds.filter((id) => id !== removedSchool.id),
            },
          },
        }),
        true
      );
    },
    [form, option.id, schoolIds]
  );

  const onSchoolIdsAddedHandler = React.useCallback(
    (addedSchools: Item[]): void => {
      form.setTouched({ eligibilities: { [option.id]: { schoolIds: true } } });
      form.setValues(
        (values) => ({
          ...values,
          eligibilities: {
            ...values.eligibilities,
            [option.id]: {
              type: values.eligibilities[option.id]?.type ?? "includes",
              schoolIds: schoolIds.concat(addedSchools.map((s) => s.id)),
            },
          },
        }),
        true
      );
    },
    [form, option.id, schoolIds]
  );

  if (sectionType !== "PreRankingSection" || questionType !== "SingleSelect") {
    return null;
  }

  return (
    <CheckboxCard
      label="Answer includes or excludes specific schools"
      isChecked={isEnable(eligibility)}
      onCheck={onCheckHandler}
    >
      {isEnable(eligibility) ? (
        <RemoteDataView
          remoteData={schools}
          error={() => <GenericError />}
          loading={
            <Flex direction="column" gap="3">
              <Skeleton height="16" />
              <Skeleton height="10" width="20" />
            </Flex>
          }
        >
          {(schools) => {
            const error = form.errors.eligibilities?.[option.id]?.schoolIds;
            return (
              <Flex direction="column" gap="2">
                <RadioGroup
                  display="flex"
                  flexDirection="column"
                  gap="1"
                  value={eligibility.type}
                  onChange={onRadioChangeHandler}
                >
                  <Radio value="includes">Includes schools</Radio>
                  <Radio value="excludes">Excludes schools</Radio>
                </RadioGroup>
                <BulkMultiSelect
                  label="Enter any number of school ids below, separated by a comma (,)."
                  formTextareaProps={{ placeholder: "School ids" }}
                  addButtonProps={{ _hover: { bg: "gray.200" } }}
                  error={
                    form.touched.eligibilities?.[option.id]?.schoolIds &&
                    typeof error === "string"
                      ? error
                      : undefined
                  }
                  items={schools}
                  selectedIds={schoolIds}
                  onItemRemoved={onSchoolIdRemovedHandler}
                  onItemsAdded={onSchoolIdsAddedHandler}
                />
              </Flex>
            );
          }}
        </RemoteDataView>
      ) : null}
    </CheckboxCard>
  );
};
