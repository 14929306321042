import { FormImportFlowAction, FormImportFlowActionType } from "./actions";
import { FlowStatus } from "./constants";
import { getCurrentFormImport, saveCurrentFormImport } from "./localStorage";

export type FormImportFlowState = {
  formImportId: uuid | null;
  enrollmentPeriodId: uuid | null;
  formTemplateId: uuid | null;
  formTemplateName: string;
  finalStatusReported: boolean;
  importFileUploadStatus: FlowStatus;
};

export function getInitialState(): FormImportFlowState {
  return {
    formImportId: null,
    enrollmentPeriodId: null,
    formTemplateId: null,
    formTemplateName: "",
    finalStatusReported: false,
    ...(getCurrentFormImport() || {}),
    importFileUploadStatus: FlowStatus.STOPPED,
  };
}

export function reducer(
  state: FormImportFlowState,
  action: FormImportFlowAction
): FormImportFlowState {
  switch (action.type) {
    case FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE: {
      saveCurrentFormImport(null);

      return {
        ...state,
        formImportId: null,
        enrollmentPeriodId: null,
        finalStatusReported: false,
        importFileUploadStatus: action.value,
      };
    }

    case FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE: {
      saveCurrentFormImport(action.value);

      return {
        ...state,
        ...(action.value || {
          formImportId: null,
          enrollmentPeriodId: null,
          finalStatusReported: false,
        }),
      };
    }

    case FormImportFlowActionType.FILE_UPLOAD_SUCCESS: {
      saveCurrentFormImport({
        finalStatusReported: false,
        ...action.value,
      });

      return {
        ...state,
        ...action.value,
        importFileUploadStatus: FlowStatus.SUCCESS,
      };
    }
  }
}
