import React, { useMemo } from "react";
import { ConfigFormLayout } from "./ConfigFormLayout";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import {
  LanguageMultiSelect,
  transformLanguageToMultiSelect,
  transformMultiSelectToLanguage,
} from "../services";
import { useWeglot } from "src/plugins/weglot/hooks";
import { useField } from "formik";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = {};
export const TranslationOptionsConfigForm: React.FC<Props> = () => {
  const weglot = useWeglot();
  const renderDropdownItem = (item: LanguageMultiSelect) => {
    return (
      <Box>
        <span>{item.name}</span>
      </Box>
    );
  };

  const [, meta, helpers] = useField(
    "organizationConfigs.TranslationOptions.languages"
  );

  const handleChangeLanguages = (values: LanguageMultiSelect[]) => {
    const languages = transformMultiSelectToLanguage(values);
    helpers.setValue(languages);
  };

  const options = useMemo(() => {
    if (!weglot?.options.languages) return [];

    const languages = [
      "en",
      ...weglot.options.languages.map((l) => l.language_to),
    ];
    return transformLanguageToMultiSelect(languages, weglot.getLanguageName);
  }, [weglot]);

  return (
    <ConfigFormLayout
      type="TranslationOptions"
      title="Translation Options"
      label="Translate into different languages"
      description="Enables translation for the Parent portal into multiple languages."
    >
      {(config) => {
        const initialValues = !weglot
          ? []
          : transformLanguageToMultiSelect(
              config.languages ??
                OrgConfig.TranslationOptions.DefaultConfig.languages,
              weglot.getLanguageName
            );

        return (
          <FormControl isInvalid={!!meta.error}>
            <FormLabel>
              Translate to
              <MultiSelectInput<LanguageMultiSelect>
                size="md"
                onChange={handleChangeLanguages}
                options={options}
                initialValues={initialValues}
                renderDropdownItem={renderDropdownItem}
                labelAttr="name"
              />
            </FormLabel>
            {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
          </FormControl>
        );
      }}
    </ConfigFormLayout>
  );
};
