import { Box, Button, HStack } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { useFormImportFileUploadFlow } from "src/components/FormImport/useFormImportFileUploadFlow";
import { useFormImportTemplateDownload } from "src/components/FormImport/useFormImportTemplateDownload";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { Glossary } from "src/components/Text/Glossary";
import { ReactComponent as ClipboardSvg } from "src/images/clipboard.svg";
import { HasuraRole } from "src/types/hasuraRole";

interface NoFormsProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function NoForms(props: NoFormsProps) {
  const { enrollmentPeriodId, formTemplateId, formTemplateName } = props;

  const { startDownload } = useFormImportTemplateDownload({
    formTemplateId,
  });

  const { openFilePicker } = useFormImportFileUploadFlow({
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
  });

  const { state } = useFormImportFlow();

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description={
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            <WithUserPermissions permissions={["form_template:create"]}>
              To create entries download the template, fill it and import it. Or
              wait for <Glossary>students</Glossary> to submit the form!
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
        }
        heading="No form responses yet"
        Svg={ClipboardSvg}
      >
        <HStack>
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            <WithUserPermissions permissions={["form_template:read"]}>
              <Button
                colorScheme="gray"
                onClick={startDownload}
                variant="outline"
              >
                Download template
              </Button>
            </WithUserPermissions>
            <WithUserPermissions permissions={["form_template:create"]}>
              <Button
                isDisabled={!!state.formImportId}
                onClick={openFilePicker}
                variant="solid"
              >
                Create from file
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
        </HStack>
      </EmptyState>
    </Box>
  );
}
