import { html } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/utils";
import * as GQL from "src/types/graphql";

export const FORM_CANCELLED = {
  type: GQL.message_template_type_enum.FormCancelled,
  emailSubject: "You've canceled your {{organization.name}} form.",
  emailHtml: html` <p>
    Dear {{parent.name}}, <br />
    <br />
    You have canceled your form for {{student.name}} for
    {{enrollmentPeriod.name}}. If this was a mistake, please contact your school
    or district administrator in order to reinstate your form.<br />
  </p>`,
  emailText: `Dear {{parent.name}},

You have canceled your form for {{student.name}} for {{enrollmentPeriod.name}}. If this was a mistake, please contact your school or district administrator in order to reinstate your form.`,
  sms: "You've cancelled your form for {{organization.name}} form for {{student.name}}."
};
