import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Text
} from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import {
  InformationPopover,
  InformationPopoverTypes
} from "./InformationPopover";
import { FormTemplateSettingsType } from "./schemas";

interface Props {
  errors: FormikErrors<FormTemplateSettingsType>;
  descriptionRemainingChars: number;
}
export const BasicInfoForm: React.FC<Props> = ({
  errors,
  descriptionRemainingChars
}) => {
  return (
    <>
      <Heading fontSize="xl" fontWeight="600" color="gray.700" my={2}>
        Information
      </Heading>
      <HStack>
        <FormControl>
          <FormLabel>
            <Flex gap={2} align="center">
              <Text>Form name</Text>
              <InformationPopover id={InformationPopoverTypes.Name} />
            </Flex>
          </FormLabel>
          <InputWithValidationControl id="name" name="name" isRequired={true} />
        </FormControl>

        <FormControl>
          <FormLabel>
            <Flex gap={2} align="center">
              <Text>Form key</Text>
              <InformationPopover id={InformationPopoverTypes.Key} />
            </Flex>
          </FormLabel>
          <InputWithValidationControl id="key" name="key" isRequired={true} />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>
          <Flex gap={2} align="center">
            <Text>Form description</Text>
            <InformationPopover id={InformationPopoverTypes.Description} />
          </Flex>
        </FormLabel>
        <TextareaWithValidationControl id="description" name="description" />
        {!errors.description && (
          <FormHelperText>
            {descriptionRemainingChars} characters left
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
