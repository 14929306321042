import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";

export function toOptionWithoutBranching(
  value: GQL.QuestionOptionFragment
): AF.Option<AF.WithId> {
  return {
    id: value.id,
    label: value.label,
    value: value.value ?? undefined,
    translate_options: value.translate_options ?? undefined,
  };
}
