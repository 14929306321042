import axios, { AxiosResponse } from "axios";
import React, { useMemo } from "react";
import useAccessToken from "src/hooks/useAccessToken";
import useUser from "src/hooks/useUser";
import { Status } from "src/types/authData";
import * as RD from "src/types/remoteData";

export class PolicyManagementError extends Error {
  constructor(message: string, readonly cause?: any) {
    super(message);
    this.cause = cause;
  }
}

type PolicyManagementProp =
  | {
      type: "DEFAULT";
      key?: never;
      organizationId: string;
      skip?: boolean;
    }
  | {
      type: "GET_POLICY";
      key: string;
      organizationId: string;
      skip?: boolean;
    };

export const usePolicyManagement = (prop: PolicyManagementProp) => {
  const baseUrl = process.env.REACT_APP_AUTHORIZATION_URL;
  const user = useUser();
  const accessToken = useAccessToken();
  const [bearerToken, setBearerToken] = React.useState<string>();

  const [isReady, setIsReady] = React.useState<boolean>(false);

  const [policy, setPolicy] = React.useState<RD.RemoteData<Error, string>>(
    RD.notAsked()
  );

  React.useEffect(() => {
    if (accessToken.status === Status.OK)
      setBearerToken(`Bearer ${accessToken.data}`);
  }, [accessToken]);

  React.useEffect(() => {
    if (!baseUrl || !bearerToken) return;
    if (user.status !== "ok") return;
    setIsReady(true);
  }, [bearerToken, baseUrl, user]);

  const fetchPolicy = React.useCallback(
    async (key: string): Promise<void> => {
      let remoteDataResponse: RD.RemoteData<Error, string>;
      setPolicy(RD.loading());
      if (!isReady || user.status !== "ok") {
        return;
      }

      let response: AxiosResponse<string>;
      try {
        response = await axios.get(`${baseUrl}/policy?key=${key}`, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/octet-stream",
          },
          validateStatus: () => true,
        });
      } catch (error) {
        const err = RD.failure<Error, string>(
          new PolicyManagementError("Failed to fetch policy.", {
            cause: error,
          })
        );
        setPolicy(err);
        return;
      }

      if (response.status !== 200 && response.status !== 201) {
        console.error("[usePolicyManagement]", response.data);
        remoteDataResponse = RD.failure(
          new PolicyManagementError("Failed to get policy.", {
            status: response.status,
            statusText: response.statusText,
          })
        );
      } else remoteDataResponse = RD.success(response.data);

      setPolicy(remoteDataResponse);
    },
    [bearerToken, baseUrl, user, isReady]
  );

  const storePolicy = React.useCallback(
    async (key: string, value: string): Promise<any> => {
      let remoteDataResponse: { ok: true };
      setPolicy(RD.loading());
      if (!isReady) {
        throw new PolicyManagementError("Not ready to store policy");
      }

      let response: AxiosResponse<{ ok: true; message?: string }>;
      try {
        response = await axios.put(
          `${baseUrl}/policy`,
          {
            key,
            value,
          },
          {
            headers: {
              Authorization: bearerToken,
              "Content-Type": "application/json",
            },
            validateStatus: () => true,
          }
        );
      } catch (error) {
        throw new PolicyManagementError("Failed to store policy.", {
          cause: error,
        });
      }

      if (response.status !== 200 && response.status !== 201) {
        console.error("[usePolicyManagement]", response.data);
        throw new PolicyManagementError(
          `Failed to store policy due to ${response.data.message}`
        );
      } else remoteDataResponse = response.data;

      return remoteDataResponse;
    },
    [bearerToken, baseUrl, isReady]
  );

  useMemo(() => {
    if (prop.skip) {
      // set default values and continue
      setPolicy(RD.success(""));
      return;
    }
    switch (prop.type) {
      case "GET_POLICY":
        fetchPolicy(prop.key);
        break;
    }
  }, [fetchPolicy, prop.type, prop.key, prop.skip]);

  return {
    storePolicy,
    policy,
  };
};
