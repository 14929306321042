import { ApolloError, gql } from "@apollo/client";
import { useOrganization } from "src/hooks/useOrganization";
import useUser from "src/hooks/useUser";
import * as GQL from "../types/graphql";
import * as RemoteData from "../types/remoteData";
import { useRemoteDataQuery } from "./useRemoteDataQuery";

export const GET_TEAM_ID = gql`
  query GetTeamId($organizationId: uuid!, $userId: String!) {
    person(
      where: {
        organization_id: { _eq: $organizationId }
        user_id: { _eq: $userId }
      }
      limit: 1
    ) {
      person_teams {
        team_id
      }
    }
  }
`;

export type UseTeamType = RemoteData.RemoteData<TeamError, string | null>;

export type TeamError = ServerError | NotFoundError;
export class ServerError {
  readonly kind = "ServerError";
  constructor(public error: ApolloError) {}
}
export class NotFoundError {
  readonly kind = "NotFoundError";
}

export function isTeamError(error: unknown): error is TeamError {
  return error instanceof ServerError || error instanceof NotFoundError;
}

export function useTeam(): UseTeamType {
  const organization = useOrganization();
  const user = useUser();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetTeamId,
    GQL.GetTeamIdVariables
  >(GET_TEAM_ID, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
      userId: user.status === "ok" ? user.data.id : "",
    },
    skip:
      organization === undefined ||
      user.status !== "ok" ||
      user.data.role === "user",
  });

  return remoteData
    .mapError<TeamError>((error) => new ServerError(error))
    .andThen((data) => {
      const teamData = data.person[0]?.person_teams[0]?.team_id ?? null;

      return RemoteData.success(teamData);
    });
}
