import { Box, HStack } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import _ from "lodash";
import { VerificationTag } from "src/components/DataDisplay/VerificationTag";
import * as GQL from "src/types/graphql";
import { getForm } from "../helpers";
import { ColumnId } from "./constants";

type VerificationsColumnDefConfig = {
  formTemplate?: GQL.FormTemplateFragment;
};

type Row = {
  form: {
    form_verification_results: {
      form_verification: { id: uuid };
      verification_status: GQL.verification_status_enum;
    }[];
  } | null;
};
export function buildVerificationsColumnDef<T extends Row>({
  formTemplate,
}: VerificationsColumnDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form?.form_verification_results,
    id: ColumnId.Verifications,
    header: "verifications",

    cell: (props) => {
      const form = getForm(props.row.original);

      const vTags = _.sortBy(
        formTemplate?.form_verifications,
        (verifications) => verifications.label
      ).map((verification, index) => {
        const result = form?.form_verification_results.find(
          (result) => result.form_verification.id === verification.id
        );

        return (
          <Box key={index}>
            <VerificationTag
              status={
                result?.verification_status ??
                GQL.verification_status_enum.Pending
              }
              label={verification.label}
            />
          </Box>
        );
      });

      return <HStack>{vTags}</HStack>;
    },
  };
}
