import { Flex, Radio, RadioGroup } from "@chakra-ui/react";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { Card } from "src/components/Layout/Card";
import { Form } from "src/services/url/Parent";
import {
  useDeleteCustomQuestionAnswers,
  useSaveCustomQuestionAnswers,
} from "../api";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import {
  transformAnswerBankFieldsToValues,
  transformValuesIntoAnswerBankRecordFields,
} from "../helpers";
import { AnswerBankRadio } from "./AnswerBankRadio";
import { normalizeAnswerBankRecords } from "./helpers";
import { AnswerBankRecord, DoNotAnswerKind } from "./schemas/AnswerBank";

type AnswerBankRadioGroupProps = {
  answerBankRecords: AnswerBankRecord[];
  selectedAnswerBankId: uuid | undefined;
  formAnswerValues: CustomQuestionAnswersByQuestionId;
};

type RadioValue = uuid | "DO_NOT_ANSWER" | undefined;

/**
 * The UX of Answer Bank behaves very similarly to that of AddressBook:
 *
 * 1. Displays all answer bank options, which includes the currently selected option.
 * 2. The current selected option is always displayed first in the list of options.
 * 3. When the question is not required, the answer can be de-selected.
 */
export const AnswerBankRadioGroup: FunctionComponent<
  AnswerBankRadioGroupProps
> = (props) => {
  const { answerBankRecords, selectedAnswerBankId, formAnswerValues } = props;
  const { organization, customQuestion, formId, sourceIdCloneIdMapping } =
    useCustomQuestionContext();
  const questionIsRequired = customQuestion.requirement === "Required";

  const saveCustomQuestionAnswers = useSaveCustomQuestionAnswers();
  const deleteCustomQuestionAnswers = useDeleteCustomQuestionAnswers();

  const [radioValue, setRadioValue] = useState<RadioValue>(
    selectedAnswerBankId ?? DoNotAnswerKind
  );
  const { step = "" } = useParams();
  const navigate = useNavigate();

  const normalizedAnswerBankRecords = useMemo(() => {
    if (!selectedAnswerBankId) {
      return answerBankRecords;
    }
    const formAnswerAsAnswerBankRecord: AnswerBankRecord = {
      kind: "ANSWER_BANK_RECORD",
      fields: transformValuesIntoAnswerBankRecordFields(
        formAnswerValues,
        customQuestion.nestedQuestions,
        sourceIdCloneIdMapping
      ),
      answerBankId: selectedAnswerBankId,
    };
    return normalizeAnswerBankRecords(
      answerBankRecords,
      selectedAnswerBankId,
      formAnswerAsAnswerBankRecord
    );
  }, [
    formAnswerValues,
    customQuestion.nestedQuestions,
    sourceIdCloneIdMapping,
    selectedAnswerBankId,
    answerBankRecords,
  ]);

  const handleOnOptionChange = useCallback(
    async (selectedRadioValue: RadioValue) => {
      const newlySelectedAnswerBank = normalizedAnswerBankRecords.find(
        (record) => {
          return record.answerBankId === selectedRadioValue;
        }
      );

      // display visual update before request to give feedback sooner
      setRadioValue(selectedRadioValue);

      if (selectedRadioValue === DoNotAnswerKind) {
        await deleteCustomQuestionAnswers({
          formId: formId,
          customQuestion: customQuestion,
        });
      }

      if (selectedRadioValue && newlySelectedAnswerBank) {
        await saveCustomQuestionAnswers({
          formId: formId,
          customQuestion: customQuestion,
          answerBankId: selectedRadioValue,
          answersByQuestionId: transformAnswerBankFieldsToValues(
            newlySelectedAnswerBank.fields
          ),
        });
      }

      navigate(Form.edit(organization, formId, parseInt(step, 10)), {
        state: { questionId: customQuestion.id },
      });
    },
    [
      customQuestion,
      deleteCustomQuestionAnswers,
      formId,
      navigate,
      normalizedAnswerBankRecords,
      organization,
      saveCustomQuestionAnswers,
      step,
    ]
  );

  return (
    <Flex direction="column" gap={2}>
      <RadioGroup onChange={handleOnOptionChange} value={radioValue}>
        <Flex direction="column" gap={2}>
          {normalizedAnswerBankRecords.map((option) => {
            return (
              <AnswerBankRadio
                key={option.answerBankId}
                answerBankRecord={option}
                selected={radioValue === option.answerBankId}
              />
            );
          })}
          {!questionIsRequired && (
            <Card showBorder padding={0}>
              <Radio value={DoNotAnswerKind} isInvalid={false} padding={4}>
                Do not enter {customQuestion.question.toLocaleLowerCase()}
              </Radio>
            </Card>
          )}
        </Flex>
      </RadioGroup>
    </Flex>
  );
};
