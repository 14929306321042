import { gql } from "@apollo/client";
import { SIMPLE_SCHOOL_FRAGMENT } from "src/operations/fragments/school";

export const GET_TEAM_MEMBER = gql`
  query GetTeamMember($personID: uuid!, $organizationID: uuid!) {
    person(
      where: {
        id: { _eq: $personID }
        person_type: { _in: [orgAdmin, schoolAdmin, districtAdmin] }
      }
    ) {
      id
      first_name
      last_name
      email_address
      person_type
      person_teams {
        team {
          id
          name
        }
      }
      user {
        id
        name
        user_group_id
        school_users {
          user_id
          school_id
          school {
            name
          }
          school_access_permission
        }
      }
    }
    school(where: { organization_id: { _eq: $organizationID } }) {
      id
      name
      street_address
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers(
    $organizationId: uuid!
    $limit: Int!
    $offset: Int!
    $orderBy: [person_order_by!]
  ) {
    person(
      where: {
        organization_id: { _eq: $organizationId }
        person_type: { _in: [orgAdmin, schoolAdmin, districtAdmin] }
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      first_name
      last_name
      email_address
      person_type
      person_teams {
        team {
          name
        }
      }
      user {
        id
        name
        user_group {
          id
          name
          label
        }
        school_users {
          school {
            id
            name
          }
        }
      }
    }

    person_aggregate(
      where: {
        organization_id: { _eq: $organizationId }
        person_type: { _in: [orgAdmin, schoolAdmin, districtAdmin] }
      }
    ) {
      totals: aggregate {
        total: count
      }
    }
  }
`;

export const GET_TEAM = gql`
  query GetTeam($teamID: uuid!) {
    team(where: { id: { _eq: $teamID }, deleted_at: { _is_null: true } }) {
      id
      name
      team_members {
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query GetTeams(
    $organizationId: uuid!
    $limit: Int!
    $offset: Int!
    $orderBy: [team_order_by!]
  ) {
    team(
      where: {
        organization_id: { _eq: $organizationId }
        deleted_at: { _is_null: true }
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      name
    }

    team_aggregate(
      where: {
        organization_id: { _eq: $organizationId }
        deleted_at: { _is_null: true }
      }
    ) {
      totals: aggregate {
        total: count
      }
    }
  }
`;

export const GET_AVAILABLE_TEAMS = gql`
  query GetAvailableTeams($organizationId: uuid!) {
    team(
      where: {
        organization_id: { _eq: $organizationId }
        deleted_at: { _is_null: true }
      }
    ) {
      id
      name
    }
  }
`;

export const GET_AVAILABLE_MEMBERS = gql`
  query GetAvailableMembers($organizationId: uuid!) {
    person(
      where: {
        organization_id: { _eq: $organizationId }
        person_type: { _in: [orgAdmin, schoolAdmin, districtAdmin] }
        _not: { person_teams: {} }
      }
    ) {
      id
      first_name
      last_name
    }
  }
`;

export const GET_SCHOOLS_BY_ORG_ID = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}
  query GetSchoolsByOrgID($organizationID: uuid!) {
    school(where: { organization_id: { _eq: $organizationID } }) {
      ...SimpleSchoolFragment
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroups {
    user_group {
      id
      name
      label
      person_type
    }
  }
`;
