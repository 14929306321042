import * as z from "zod";

export const CredentialsTypeSchema = z.enum([
  "none",
  "api_key",
  "basic_auth",
  "bearer_token",
  "custom",
]);

export type CredentialsType = z.infer<typeof CredentialsTypeSchema>;

export const WebhookBasicSchema = z.object({
  pk: z.string().ulid(),
  name: z.string(),
  is_active: z.boolean(),
});

export type WebhookBasic = z.infer<typeof WebhookBasicSchema>;

export const NoAuthSchema = z.object({
  type: z.literal("none"),
});

export type NoAuth = z.infer<typeof NoAuthSchema>;

export const ApiKeyAuthSchema = z.object({
  type: z.literal("api_key"),
  api_key: z.string(),
  api_key_header: z.string(),
});

export type ApiKeyAuth = z.infer<typeof ApiKeyAuthSchema>;

export const BasicAuthSchema = z.object({
  type: z.literal("basic_auth"),
  basic_username: z.string(),
  basic_password: z.string(),
});

export type BasicAuth = z.infer<typeof BasicAuthSchema>;

export const BearerTokenAuthSchema = z.object({
  type: z.literal("bearer_token"),
  bearer_token: z.string(),
});

export type BearerTokenAuth = z.infer<typeof BearerTokenAuthSchema>;

export const CustomAuthSchema = z.object({
  type: z.literal("custom"),
  custom_headers: z
    .object({
      header_key: z.string(),
      header_value: z.string(),
    })
    .array(),
});

export type CustomAuth = z.infer<typeof CustomAuthSchema>;

const CredentialsSchema = z.discriminatedUnion("type", [
  NoAuthSchema,
  ApiKeyAuthSchema,
  BasicAuthSchema,
  BearerTokenAuthSchema,
  CustomAuthSchema,
]);

export type Credentials = z.infer<typeof CredentialsSchema>;

export const WebhookSchema = z.object({
  pk: z.string().ulid(),
  organization_id: z.string().uuid().optional(),
  endpoint_url: z.string().url(),
  name: z.string(),
  is_active: z.boolean(),
  credentials: CredentialsSchema,
  events: z.array(z.string()),
});
