import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import useUser from "src/hooks/useUser";
import { VerifyEmail } from "src/scenes/public/VerifyEmail";
import { Status } from "src/types/authData";
import { HasuraRole } from "src/types/hasuraRole";
import { AccessDenied } from "../Feedback/AccessDenied";
import { Loading } from "../Feedback/Loading";

type Props = {
  roles: HasuraRole[];
};
export const RequireHasuraRoles: React.FC<Props> = ({ roles }) => {
  const user = useUser();
  const organization = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();

  if (user.status === "loading") {
    return <Loading />;
  }

  if (user.status === Status.NOT_VERIFIED) {
    return <VerifyEmail />;
  }

  if (
    user.status === Status.UNAUTHENTICATED ||
    !roles.includes(user.data.role)
  ) {
    if (organization.hasData()) {
      if (roles.find((role) => role === HasuraRole.ADMIN)) {
        setTimeout(() => {
          navigate(`/${organization.data.path}`);
        }, 2000);

        return (
          <AccessDenied message="You don't have access to this page, redirecting..." />
        );
      } else if (roles.find((role) => role === HasuraRole.USER)) {
        if (location.search.includes("password")) {
          return <Navigate to={`/${organization.data.path}/admin`} />;
        }

        setTimeout(() => {
          navigate(`/${organization.data.path}/admin`);
        }, 2000);
        return (
          <AccessDenied message="A Parent account is required to perform Parent actions. Redirecting..." />
        );
      }
    }

    return <AccessDenied />;
  }

  return <Outlet />;
};
