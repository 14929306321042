import { Button, useDisclosure } from "@chakra-ui/react";
import { RiAddCircleLine } from "react-icons/ri";
import * as GQL from "src/types/graphql";
import { AddRelationshipDialog } from "../Dialogs/AddRelationshipDialog";

interface AddRelationshipButtonProps {
  id: string;
  entityType: GQL.person_type_enum;
}

export const AddRelationshipButton = ({
  id,
  entityType,
}: AddRelationshipButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <Button
        aria-label="Add relationship"
        leftIcon={<RiAddCircleLine />}
        variant="outline"
        onClick={onOpen}
        colorScheme="gray"
      >
        Add relationship
      </Button>
      <AddRelationshipDialog
        id={id}
        isOpen={isOpen}
        entityType={entityType}
        onCancel={onClose}
        onAddRelationshipSuccess={onClose}
      />
    </>
  );
};
