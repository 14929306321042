import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as TagIllustration } from "src/images/tag.svg";
import { ConfigWithDefault, TagsListEventHandler } from "./types";

type NoTagsFoundProps = {
  searchKeyword: string | undefined;
  config: ConfigWithDefault;
  eventHandlers: TagsListEventHandler;
};
export const NoTagsFound: React.FC<NoTagsFoundProps> = ({
  searchKeyword,
  config,
  eventHandlers,
}) => {
  return (
    <Flex alignItems="center" direction="column" paddingBottom="3" gap="2">
      <TagIllustration aria-hidden />
      No tags found
      {searchKeyword && config.allowNewTag && (
        <Button
          variant="outline"
          colorScheme="gray"
          onClick={() => {
            if (eventHandlers.onNewTag) {
              eventHandlers.onNewTag(searchKeyword);
            }
          }}
        >
          Create "{searchKeyword}"
        </Button>
      )}
    </Flex>
  );
};
