import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FunctionComponent } from "react";
import { AddressValidationResult } from "src/components/Boundary/services";
import { FormattedAddress } from "./FormattedAddress";
import { BaseAddress } from "./schema";

type ResolveInvalidAddressProps = {
  setAddressValidationResult: (
    result: AddressValidationResult | undefined
  ) => void;
};

export const ResolveInvalidAddress: FunctionComponent<
  ResolveInvalidAddressProps
> = (props) => {
  const { setAddressValidationResult } = props;
  const { values, submitForm } = useFormikContext<BaseAddress>();

  return (
    <Box width="100%">
      <Box padding={4}>
        <Text fontWeight={700}>The entered address cannot be found</Text>
        <Text color="blackAlpha.800" fontWeight={400}>
          <FormattedAddress address={values} />
        </Text>
      </Box>
      <Divider />
      <Flex padding={4} gap={2}>
        <Button
          variant="ghost"
          colorScheme="gray"
          onClick={submitForm}
          width="50%"
        >
          Use entered
        </Button>
        <Button
          colorScheme="gray"
          onClick={() => {
            setAddressValidationResult(undefined);
          }}
          width="50%"
        >
          Edit address
        </Button>
      </Flex>
    </Box>
  );
};
