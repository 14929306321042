import { Flex, Text } from "@chakra-ui/react";
import { InfoBadge } from "src/components/DataDisplay/InfoBadge";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { FORM_STATUS } from "src/constants";
import { ParentCopyright } from "src/constants/strings";
import { useOrganization } from "src/hooks/useOrganization";
import * as GQL from "src/types/graphql";

type StatusesAndNextStepsProps = {
  actionItems: Pick<
    GQL.FormSummaryFragment_action_items,
    "id" | "status" | "summary"
  >[];
  formStatus: GQL.form_status_enum;
  closed_at: string | null;
  statusDescriptions: Pick<
    GQL.FormStatusDescription,
    "description" | "status"
  >[];
};

const lockedInfoBadgeStatus = {
  label: "Locked",
  colorScheme: "gray",
  longLabel: "Locked",
};

const summaryTextStyles = {
  fontSize: "sm",
  fontStyle: "italic",
};

export function StatusesAndNextSteps(props: StatusesAndNextStepsProps) {
  const { actionItems, formStatus, closed_at, statusDescriptions } = props;
  const organization = useOrganization();

  let summaryContent;

  if (actionItems.length > 0) {
    const todos = actionItems.filter(
      (actionItem) => actionItem.status === GQL.action_item_status_enum.Pending
    );

    if (todos.length > 0) {
      summaryContent = todos.map(({ id, summary }) => (
        <Text {...summaryTextStyles} key={id}>
          {summary}
        </Text>
      ));
    }
  }

  if (!summaryContent && statusDescriptions.length) {
    const { description } =
      statusDescriptions.find((item) => item.status === formStatus) ?? {};

    if (description?.trim().length) {
      summaryContent = <Text {...summaryTextStyles}>{description}</Text>;
    }
  }

  if (!summaryContent) {
    summaryContent = (
      <Text {...summaryTextStyles}>
        {
          ParentCopyright.DefaultFormNextStep(closed_at ?? "", organization)[
            formStatus
          ]
        }
      </Text>
    );
  }

  const isFormLocked = formStatus === GQL.form_status_enum.LotteryReady;

  return (
    <Flex direction="column" gap={2} width="100%">
      <Flex alignItems="center" gap={2}>
        <StatusTag status={FORM_STATUS[formStatus]} variant="short" />

        {isFormLocked && <InfoBadge status={lockedInfoBadgeStatus} />}
      </Flex>

      <div>
        <Text fontSize="xs" fontWeight="600" textTransform="uppercase">
          Next step
        </Text>

        {summaryContent}
      </div>
    </Flex>
  );
}
