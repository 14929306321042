import { z } from "zod";

const MAX_FILE_SIZE = 128_000_000;
const FileSchema = z
  .instanceof(File)
  .refine((file) => file.size > 0, `Please upload a non-empty file.`)
  .refine(
    (file) => file.size <= MAX_FILE_SIZE,
    "File size exceeds 128 MB limit."
  );

// TODO: create a custom type that reads the file and parses the json
export const BoundaryGroupConfigSchema = z.object({
  geojson: FileSchema.refine(
    (file) => file.name.split(".")[1] === "geojson",
    'Please upload a ".geojson" file.'
  ),
  connections: FileSchema.refine(
    (file) => file.name.split(".")[1] === "json",
    'Please upload a ".json" file.'
  ),
});

export type UploadBoundaryConfigForm = z.infer<
  typeof BoundaryGroupConfigSchema
>;

export const GeoJsonSchema = z.object({
  type: z.literal("FeatureCollection"),
  name: z.string(),
  crs: z.object({}),
  features: z
    .object({
      type: z.literal("Feature"),
      properties: z.object({}),
      geometry: z.object({
        type: z.literal("Polygon"),
        coordinates: z.number().array().array().array(),
      }),
    })
    .array(),
});

const ConnectionsJsonAssociationsSchema = z
  .object({
    enrollmentPeriodId: z.string().uuid(),
    geoJSONPropertyValue: z.string(),
    eligibility: z.boolean().optional(),
    schools: z
      .object({
        id: z.string().uuid(),
        grades: z
          .object({
            id: z.string().uuid(),
          })
          .array(),
        // .optional(), TODO: check if grades can be empty
      })
      .array(),
  })
  .array();

export type TConnectionsAssociation = z.infer<
  typeof ConnectionsJsonAssociationsSchema
>;

export const ConnectionsJsonSchema = z.object({
  organizationId: z.string().uuid(),
  geoJSONPropertyKey: z.string(),
  associations: ConnectionsJsonAssociationsSchema,
  tags: z
    .object({
      name: z.string(),
    })
    .array(),
});
