import { Flex, Heading, Text } from "@chakra-ui/react";
import { useField } from "formik";
import { useOutletContext } from "react-router-dom";
import { AvelaSelectField } from "src/components";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";
import { FormikCustomSelect } from "src/components/Inputs/CustomSelect";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import { FormTemplateRuleType, WhatShouldHappenEnum } from "./schemas";
import {
  FormTemplateRuleTypes,
  FormTemplateStatusRuleOptions,
  FormTemplateWhatShouldHappenOptions
} from "./utils";

export const FormTemplateRules: React.FC = () => {
  const [{ value: formTemplateRule, name }] =
    useField<FormTemplateRuleType>("formTemplateRule");
  const { enrollmentPeriod, formTemplate } =
    useOutletContext<FormTemplateOutletContext>();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormTemplatesByEnrollmentPeriod,
    GQL.GetFormTemplatesByEnrollmentPeriodVariables
  >(GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period: enrollmentPeriod.id
    },
    fetchPolicy: "no-cache"
  });

  return (
    <Flex direction="column" gap={6}>
      <Heading fontSize="lg" color="gray.700">
        Rules
      </Heading>

      {formTemplateRule?.rules.map((rule, index) => (
        <Flex direction="column" key={index} gap={4}>
          <Flex gap={4}>
            <AvelaSelectField
              name={`${name}.rules.${index}.whatShouldHappen`}
              label="What should happen after this form?"
              options={FormTemplateWhatShouldHappenOptions}
            />

            {rule?.whatShouldHappen ===
              WhatShouldHappenEnum.SubmitAndContinue && (
              <GQLRemoteDataView remoteData={remoteData}>
                {(data) => {
                  const nextFormTemplateOptions = data.form_template
                    .filter((ft) => ft.id !== formTemplate.id)
                    .map((ft) => ({
                      label: ft.name,
                      value: ft.id
                    }));

                  return (
                    <AvelaSelectField
                      name={`${name}.rules.${index}.nextTemplateId`}
                      label="Select form to follow"
                      placeholder="Select form to follow"
                      options={nextFormTemplateOptions}
                    />
                  );
                }}
              </GQLRemoteDataView>
            )}
          </Flex>

          {rule?.whatShouldHappen ===
            WhatShouldHappenEnum.SubmitAndContinue && (
            <Flex gap={4} alignItems="end">
              <AvelaSelectField
                name={`${name}.rules.${index}.type`}
                label="When should this form be visible?"
                options={FormTemplateRuleTypes}
              />

              <Text pb={2}>is</Text>

              <FormikCustomSelect
                name={`${name}.rules.${index}.statusOrSubstatus`}
                items={FormTemplateStatusRuleOptions}
                renderItem={(item) => (
                  <Text color="gray.900" pl={item.isSubStatus ? 4 : 0}>
                    {item.label}
                  </Text>
                )}
                renderSelectedItem={(item) => (item ? item.label : "")}
                label=""
                // Add this prop to not break the page layout
                openDirection={
                  formTemplateRule?.rules.length - 1 === index ? "up" : "down"
                }
              />
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};
