import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { RankedSchool } from "src/services/formTemplate/preRankingSection";

type Props = {
  body: string;
  confirmation: string;
  schoolsToRemove: readonly RankedSchool[];
  rankingEnabled: boolean;
};
export const RankedSchoolsRemovalConfirmationDialogBody: React.FC<Props> = ({
  schoolsToRemove,
  body,
  confirmation,
  rankingEnabled,
}) => {
  return (
    <Flex direction="column" gap={3}>
      <Text>{body}</Text>
      <List>
        {schoolsToRemove.map((rankedSchool) => {
          return (
            <SchoolItem
              rankedSchool={rankedSchool}
              rankingEnabled={rankingEnabled}
            />
          );
        })}
      </List>
      <Text fontWeight="bold">{confirmation}</Text>
    </Flex>
  );
};

const SchoolItem = ({
  rankedSchool,
  rankingEnabled,
}: {
  rankedSchool: RankedSchool;
  rankingEnabled: boolean;
}) => {
  return (
    <ListItem
      key={rankedSchool.school.id}
      className={WEGLOT_SKIP_CLASS}
      listStyleType={rankingEnabled ? "none" : "disc"}
      ml={rankingEnabled ? 0 : 4}
    >
      {rankingEnabled && `${rankedSchool.rank + 1} - `}
      {rankedSchool.school.name}
    </ListItem>
  );
};
