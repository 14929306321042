import { Navigate } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import useAccessToken from "src/hooks/useAccessToken";
import { useQueryParams } from "src/hooks/useQueryParams";
import * as URL from "src/services/url";
import * as AuthData from "src/types/authData";

/*
    When a user navigates to "/" in apply they are redirected depending upon who they are.

    If the user is not logged in they are sent to the Avela marketing page.

    Otherwise they are redirected to a landing page based on the role obtained from the Auth Token.

    https://app.asana.com/0/1200299036901049/1202809829743113/f
*/
export const Home = () => {
  const accessToken = useAccessToken();
  const { path } = useQueryParams();

  switch (accessToken.status) {
    case AuthData.Status.LOADING:
      return <Loading></Loading>;
    case AuthData.Status.UNAUTHENTICATED:
      if (typeof path === "string") {
        window.location.replace(path);
      } else {
        window.location.replace(URL.AvelaMarketingPage.URL);
      }
      return <Loading></Loading>;
    case AuthData.Status.OK:
      return <Navigate to={URL.redirect}></Navigate>;
  }
};
