import { Button } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { ThemeTypings } from "@chakra-ui/react";
import * as React from "react";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";

export type ButtonProps = {
  colorScheme?: ThemeTypings["colorSchemes"];
  label: string;
  hidden?: boolean;
  isLoading?: boolean;
  loadingText?: string;
};

export interface ConfirmationDialogProps {
  isOpen: boolean;
  header: React.ReactNode;
  body: React.ReactNode;
  translate?: boolean;
  cancelButton?: ButtonProps;
  confirmButton?: ButtonProps;
  onCancel: () => void;
  onConfirm: () => void;
}

const DEFAULT_CANCEL_BUTTON: ButtonProps = {
  label: "Cancel",
  colorScheme: "gray",
};
const DEFAULT_CONFIRM_BUTTON: ButtonProps = {
  label: "Confirm",
  colorScheme: "primary",
};

/**
 * Avoid using this directly, and prefer using useConfirmationDialog hook instead.
 */
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  header,
  body,
  cancelButton = DEFAULT_CANCEL_BUTTON,
  confirmButton = DEFAULT_CONFIRM_BUTTON,
  onCancel,
  onConfirm,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancel}
        isCentered
      >
        <AlertDialogOverlay className={WEGLOT_APPLY_CLASS}>
          <AlertDialogContent marginX={4}>
            <AlertDialogHeader fontSize="lg">{header}</AlertDialogHeader>
            <AlertDialogBody fontSize="md">{body}</AlertDialogBody>

            <AlertDialogFooter>
              {!cancelButton.hidden && (
                <Button
                  ref={cancelRef}
                  colorScheme={cancelButton.colorScheme ?? "gray"}
                  variant="ghost"
                  onClick={onCancel}
                >
                  {cancelButton.label}
                </Button>
              )}

              <Button
                colorScheme={confirmButton.colorScheme ?? "primary"}
                onClick={onConfirm}
                ml={3}
                isLoading={confirmButton.isLoading}
                loadingText={confirmButton.loadingText}
              >
                {confirmButton.label}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
