import { Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import { Glossary } from "../Text/Glossary";
import { InfoAlert } from "./InfoAlert";

type Props = {
  text: string;
  helpText?: string;
};
export const NoDataInfoAlert: React.FC<Props> = ({
  text,
  helpText = "click here for help.",
}) => {
  const organization = useOrganization();
  return (
    <InfoAlert
      children={
        <Text>
          <Glossary>{text}</Glossary>{" "}
          <Link
            to={organization
              .map((org) => Url.Parent.help(org))
              .withDefault("#")}
          >
            <Text as="span" fontWeight="bold" textDecoration="underline">
              <Glossary>{helpText}</Glossary>
            </Text>
          </Link>
        </Text>
      }
      styleProps={{
        fontSize: "md",
        alignItems: "center",
        fontWeight: 400,
      }}
    />
  );
};
