import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  GetPersonAnswerBank,
  GetPersonAnswerBankVariables,
  GetSelectedPersonAnswerBank,
  GetSelectedPersonAnswerBankVariables,
  InsertPersonAnswerBank,
  InsertPersonAnswerBankVariables,
  UpdatePersonAnswerBank,
  UpdatePersonAnswerBankVariables,
} from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { CloneIdsGroupedBySourceIds } from "../context/helpers";
import { AnswerBankRecord } from "../list/schemas/AnswerBank";
import {
  INSERT_PERSON_ANSWERS,
  UPDATE_PERSON_ANSWERS,
} from "./graphql/mutations";
import {
  GET_PERSON_ANSWER_BANK,
  GET_SELECTED_PERSON_ANSWER_BANK,
} from "./graphql/queries";
import { formatQueriedAnswerBankRecords } from "./helpers";

export function useGetPersonAnswerBankQuery(
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds,
  variables: GetPersonAnswerBankVariables
): RemoteData<ApolloError, AnswerBankRecord[]> {
  const { remoteData } = useRemoteDataQuery<
    GetPersonAnswerBank,
    GetPersonAnswerBankVariables
  >(GET_PERSON_ANSWER_BANK, { variables, fetchPolicy: "cache-and-network" });

  return useMemo(() => {
    return remoteData.map((data) => {
      const { person_answer_bank } = data;

      return formatQueriedAnswerBankRecords(
        person_answer_bank,
        sourceIdCloneIdMapping
      );
    });
  }, [sourceIdCloneIdMapping, remoteData]);
}

export function useGetSelectedPersonAnswerBankQuery(
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds,
  variables: GetSelectedPersonAnswerBankVariables
): RemoteData<ApolloError, AnswerBankRecord[]> {
  const { remoteData } = useRemoteDataQuery<
    GetSelectedPersonAnswerBank,
    GetSelectedPersonAnswerBankVariables
  >(GET_SELECTED_PERSON_ANSWER_BANK, { variables, fetchPolicy: "no-cache" });

  return useMemo(() => {
    return remoteData.map((data) => {
      const { custom_question_answer_bank_relationship } = data;

      return formatQueriedAnswerBankRecords(
        custom_question_answer_bank_relationship.map((relationship) => {
          return relationship.person_answer_bank;
        }),
        sourceIdCloneIdMapping
      );
    });
  }, [sourceIdCloneIdMapping, remoteData]);
}

export function useGetSelectedPersonAnswerBankRawQuery(
  variables: GetSelectedPersonAnswerBankVariables
) {
  const { remoteData } = useRemoteDataQuery<
    GetSelectedPersonAnswerBank,
    GetSelectedPersonAnswerBankVariables
  >(GET_SELECTED_PERSON_ANSWER_BANK, { variables, fetchPolicy: "no-cache" });
  return remoteData;
}

export function useInsertPersonAnswerBankMutation() {
  const [insertPersonAnswerBankEntry] = useRemoteDataMutation<
    InsertPersonAnswerBank,
    InsertPersonAnswerBankVariables
  >(INSERT_PERSON_ANSWERS);

  return insertPersonAnswerBankEntry;
}

export function useUpdatePersonAnswerBankMutation() {
  const [updatePersonAnswerBankEntry] = useRemoteDataMutation<
    UpdatePersonAnswerBank,
    UpdatePersonAnswerBankVariables
  >(UPDATE_PERSON_ANSWERS);

  return updatePersonAnswerBankEntry;
}
