import { FormControl, FormLabel, Stack, StyleProps } from "@chakra-ui/react";
import { SelectControl } from "formik-chakra-ui";
import { ChangeEvent, FunctionComponent, ReactElement } from "react";

type SelectOption = {
  label: string;
  value: string;
  className?: string;
};

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  options: SelectOption[];
  onChange?: (id: string) => void;
  isDisabled?: boolean;
  AlertComponent?: ReactElement;
} & StyleProps;

/**
 * Convenience wrapper around the Select input.
 * Use this in (Formik) forms.
 */
export const AvelaSelectField: FunctionComponent<Props> = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    onChange,
    isDisabled,
    AlertComponent,
    ...styleProps
  } = props;

  return (
    <FormControl>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Stack gap={2}>
        {AlertComponent}
        <SelectControl
          {...styleProps}
          id={name}
          name={name}
          selectProps={{
            placeholder: placeholder,
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const selectedId = event.target.value;
            onChange?.(selectedId);
          }}
          isDisabled={isDisabled}
        >
          {options.map((option) => {
            const { label, value, className } = option;
            return (
              <option key={value} value={value} className={className}>
                {label}
              </option>
            );
          })}
        </SelectControl>
      </Stack>
    </FormControl>
  );
};
