import * as DateFns from "date-fns";
import * as DateFnsTz from "date-fns-tz";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Organization } from "../types/graphql";
import * as RD from "../types/remoteData";

type Person = { first_name: string | null; last_name: string | null };
export const fullName = (
  person: Partial<Person> | null | undefined
): string => {
  if (!person) return "";
  const { first_name, last_name } = person;
  return `${first_name ?? ""} ${last_name ?? ""}`.trim();
};

export const shortName = (
  person: Partial<Person> | null | undefined
): string => {
  return person?.first_name ?? person?.last_name ?? "";
};

type Address = {
  street_address: string | null | undefined;
  street_address_line_2?: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zip_code: string | null | undefined;
};
export const address = (address: Address | null | undefined): string => {
  if (!address) return "";
  return `${address.street_address ?? ""} ${
    address.street_address_line_2 ?? ""
  }${address.city ? `, ${address.city}` : ""}${
    address.state ? `, ${address.state}` : ""
  }${address.zip_code ? `, ${address.zip_code}` : ""}`;
};

export const formatIsoDateToOrg = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>,
  format: string
): string => {
  return organization
    .map((org) => {
      const formattedDate = formatIsoDate(isoDate, format, org.timezone_name);
      return formattedDate;
    })
    .withDefault("");
};

export const formatIsoDate = (
  isoDate: string,
  format: string,
  timezoneName?: string
): string => {
  const date = DateFns.parseISO(isoDate);

  if (timezoneName) {
    return DateFnsTz.formatInTimeZone(date, timezoneName, format);
  }

  return DateFns.formatDate(date, format);
};

export const formatIsoDateToDescriptionDate = (
  isoDate: string | null,
  organization: RD.RemoteData<unknown, Organization>
): string => {
  if (isoDate === null) {
    return "";
  }
  return formatIsoDateToOrg(isoDate, organization, "MMMM do");
};

export const formatIsoDateToOrgDate = (
  isoDate: string | null,
  organization: RD.RemoteData<unknown, Organization>
): string => {
  if (isoDate === null) {
    return "";
  }
  return formatIsoDateToOrg(isoDate, organization, "MM/dd/yyyy");
};

export const formatIsoDateToOrgLongDate = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return formatIsoDateToOrg(isoDate, organization, "d MMMM y");
};

export const formatIsoDateToOrgMessageDate = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return formatIsoDateToOrg(isoDate, organization, "MMMM d y");
};

export const formatIsoDateToOrgTime = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return formatIsoDateToOrg(isoDate, organization, "h:mmaaa zzz");
};

export const formatIsoDateToOrgTimeWithoutTimezone = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return formatIsoDateToOrg(isoDate, organization, "h:mmaaa");
};

export const formatIsoDateToOrgDateTime = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return `${formatIsoDateToOrgDate(
    isoDate,
    organization
  )} ${formatIsoDateToOrgTime(isoDate, organization)}`;
};

export const formatIsoDateToMessageTimestamp = (
  isoDate: string,
  organization: RD.RemoteData<unknown, Organization>
) => {
  return `${formatIsoDateToOrgMessageDate(
    isoDate,
    organization
  )} ${formatIsoDateToOrgTimeWithoutTimezone(isoDate, organization)}`;
};

export const formatIsoDateToIso8601 = (
  isoDate: string | null,
  organization: RD.RemoteData<unknown, Organization>
) => {
  if (isoDate === null) {
    return "";
  }
  // Use this instead of DateFns.formatISO so we can enforce organization's timezone heren instead of using browser's timezone.
  return formatIsoDateToOrg(isoDate, organization, "yyyy-MM-dd'T'HH:mm:ssXX");
};

export const normalizeToAWSAcceptableKey = (key: string): string => {
  let cleanKey = key.replace(/[^a-zA-Z0-9-()._ ]/g, "");
  cleanKey = cleanKey.replace(/ /g, "_");
  return cleanKey.toLowerCase();
};

export function splitPascalCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function capitalizeFirstLetter(sentence: string): string {
  const firstLetter = sentence.charAt(0).toLocaleUpperCase();
  const restOfSentence = sentence.slice(1);

  return `${firstLetter}${restOfSentence}`;
}

export function oxfordComma(values: readonly string[]): string {
  if (values.length === 0) return "";
  if (values.length === 1) return values[0] as string;
  if (values.length === 2) return values.join(" and ");
  return values.slice(0, -1).join(", ") + ", and " + values[values.length - 1];
}

export function formatUSPhoneNumber(value: string): string {
  // Remove all non-digit characters
  const canonicalNumber = canonicalPhoneNumber(value);
  if (!canonicalNumber.startsWith("+1")) return formatPhoneNumberIntl(value);

  // Apply phone number formatting
  let formattedNumber = "+1";
  if (canonicalNumber.length > 2) {
    formattedNumber += ` (${canonicalNumber.slice(2, 5)}`;
  }
  if (canonicalNumber.length > 5) {
    formattedNumber += `) ${canonicalNumber.slice(5, 8)}`;
  }
  if (canonicalNumber.length > 8) {
    formattedNumber += `-${canonicalNumber.slice(8)}`;
  }
  return formattedNumber;
}

/**
 * The canonical format for a phone number is just numerical digits preceded by
 * a single "+", which provides support for all US and international phone
 * numbers.
 *
 * This function strips all non-digits and prepends a "+" if not already
 * present.  Numbers without a leading '+' are treated as US phone numbers,
 * where a leading "1" is also prepended.
 *
 * @returns A canonical phone number for the provided string, or the empty
 *          string if it contained no phone digits.
 */
export function canonicalPhoneNumber(value: string): string {
  const phoneDigits = value.replace(/[^\d+]/g, "");
  return (
    phoneDigits &&
    (/^[+1]/.test(phoneDigits) ? "+" : "+1") + phoneDigits.replace(/\D/g, "")
  );
}

/**
 * Take a numerical `count`, a term or phrase in singular form, and optionally
 * the plural form of the term or phrase. When the `count` equals 1, the
 * singular form will be returned. Otherwise, the plural form will be returned.
 *
 * The most common use case is for single terms which become plural with "s"
 * being appended to the end. As a convenience, when a plural form is not
 * explicitly provided, the singular form will be pluralized in this manner. It
 * is the responsibility of the consuming code to ensure the correct
 * pluralization is achieved.
 *
 * This is a simplified, naive implementation for pluralization. More robust
 * solutions can be utilized at a later time if needed.
 *
 * @param count The value determining singular or plural.
 * @param singular The singular form of the term to maybe be pluralized.
 * @param plural The plural form of the term.
 *
 * @returns The singular form of the term when the given `count` is 1. Returns
 * the plural form otherwise.
 */
export function maybePluralize(
  count: number,
  singular: string,
  plural = `${singular}s`
) {
  return count === 1 ? singular : plural;
}
