import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { PriorityTemplates } from "src/services/url/Admin";
import { Index } from ".";
import { New } from "./New";

export const AdminPriorityTemplatesRoutes = (
  <Route
    path={PriorityTemplates.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="priority_template:read">
          <Index />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={PriorityTemplates.newPath}
      element={
        <RequireUserPermissions permission="priority_template:create">
          <New />
        </RequireUserPermissions>
      }
    />
    {/* <Route
        path={PriorityTemplates.editPath}
        element={
          <RequireUserPermissions permission="priority_template:update">
            <Edit />
          </RequireUserPermissions>}
      /> */}
  </Route>
);
