import { Text } from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiChat2Line } from "react-icons/ri";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { formatIsoDateToOrgDateTime } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { MessageStatusTag } from "./components/MessageStatusTag";

interface MessagesListProps {
  messages?: GQL.GetMessages_message_adhoc[];
  isLoading?: boolean;
  onSort: (columnName: string, sortType: SortType) => void;
  offset: number;
  limit: number;
  count: number;
  onFetchMore: (limit: number, offset: number) => void;
}

export const MessagesList: React.FC<MessagesListProps> = ({
  messages,
  isLoading,
  onSort,
  offset,
  limit,
  count,
  onFetchMore,
}) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();

  const columns: ColumnDef<GQL.GetMessages_message_adhoc>[] = useMemo(
    () => [
      {
        accessorKey: "from",
        header: "from",
        accessorFn: (row) => row.sender_person?.full_name,
      },
      {
        accessorKey: "about",
        header: "about",
        cell: (props) => {
          const cellText =
            props.row.original.message_adhoc_payload_email?.message_body &&
            props.row.original.message_adhoc_payload_email?.message_body.length
              ? props.row.original.message_adhoc_payload_email?.message_body
              : props.row.original.message_adhoc_payload_sms?.message_body;

          return (
            <Text
              width="500px"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {cellText}
            </Text>
          );
        },
      },
      {
        accessorKey: "recipients",
        header: "recipients",
        accessorFn: (row) =>
          `${row.message_adhoc_receivers_aggregate_sent?.aggregate?.count}/${row.message_adhoc_receivers_aggregate?.aggregate?.count}`,
      },
      {
        accessorKey: "status",
        header: "status",
        cell: (props) => (
          <MessageStatusTag
            sentRecipients={
              props.row.original.message_adhoc_receivers_aggregate_sent
                ?.aggregate?.count
            }
            totalRecipients={
              props.row.original.message_adhoc_receivers_aggregate?.aggregate
                ?.count
            }
          />
        ),
      },
      {
        accessorKey: "sent",
        header: "sent",
        cell: (props) => {
          const sentAt = props.row.original.message_adhoc_receivers.find(
            (receiver) => receiver.sent_at
          )?.sent_at;

          if (
            props.row.original.message_adhoc_receivers_aggregate_sent?.aggregate
              ?.count &&
            sentAt
          ) {
            return formatIsoDateToOrgDateTime(sentAt, organization);
          }
          return (
            <Text fontWeight="400" fontStyle="italic" color="gray.500">
              Not sent
            </Text>
          );
        },
      },
    ],
    [organization]
  );

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  function createRowURL(row: Row<GQL.GetMessages_message_adhoc>): string {
    if (row.original.id)
      return Url.OrgAdmin.Messages.view(organization, row.original.id);
    return "#";
  }

  return messages && messages.length ? (
    <PaginatedTable<GQL.GetMessages_message_adhoc>
      data={messages}
      columns={columns}
      isLoading={isLoading}
      sortableColumnIds={["sent", "from"]}
      onChangeSort={handleChangeSort}
      count={count}
      offset={offset}
      limit={limit}
      onFetchMore={onFetchMore}
      createRowURL={
        userPermissions.hasOne("form:read") ? createRowURL : undefined
      }
    />
  ) : (
    <NoDataTable icon={RiChat2Line} text="No messages found" />
  );
};
