import { ApolloError } from "@apollo/client";
import { GET_PROFILE_BY_USER_ID_AND_ORG_ID } from "../scenes/parent/profile/graphql/queries";
import * as GQL from "../types/graphql";
import * as RemoteData from "../types/remoteData";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import useUser from "./useUser";
import { useOrganization } from "./useOrganization";

export type UserProfileError =
  | ServerError
  | UnauthenticatedError
  | ProfileNotFoundError;
class ServerError {
  readonly kind = "ServerError";
  constructor(public error: ApolloError) {}
}
class UnauthenticatedError {
  readonly kind = "UnauthenticatedError";
}
class ProfileNotFoundError {
  readonly kind = "ProfileNotFoundError";
}

export function useUserProfile(): RemoteData.RemoteData<
  UserProfileError,
  GQL.ProfileFragment
> {
  const user = useUser();
  const organization = useOrganization();
  const userId = user.status === "ok" ? user.data.id : null;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetProfileByUserIdAndOrgId,
    GQL.GetProfileByUserIdAndOrgIdVariables
  >(GET_PROFILE_BY_USER_ID_AND_ORG_ID, {
    variables: {
      user_id: userId ?? "",
      organizationId: organization.map((org) => org.id).withDefault(""),
    },
    skip: userId == null || !organization.hasData(),
  });

  if (user.status === "loading") return RemoteData.loading();
  if (user.status === "unauthenticated")
    return RemoteData.failure(new UnauthenticatedError());

  return remoteData
    .mapError<UserProfileError>((error) => new ServerError(error))
    .andThen((data) => {
      const profile = data.person[0];
      if (profile === undefined)
        return RemoteData.failure(new ProfileNotFoundError());

      return RemoteData.success(profile);
    });
}
