import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import * as Boundary from "src/components/Boundary/services";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import { RankedSchoolsRemovalConfirmationDialogBody } from "src/scenes/parent/forms/components/Dialogs/RankedSchoolsRemovalConfirmationDialogBody";
import { calculateSchoolsToRemoveByLocation } from "src/services/formTemplate/preRankingSection";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { GET_BOUNDARIES_BY_FORM } from "../Boundary/queries";
import { addressLookup } from "../Boundary/services";
import { GET_GRADE_CONFIG_ANSWER } from "./graphql/queries";
import {
  AddressAnswer,
  isLocationBoundariesFilter,
} from "./QuestionForm/formik";
import { useFlags } from "../Providers/FeatureFlagProvider";

type Props = { formId: uuid; rankingEnabled: boolean };

export function useUpdateFormAddress({ formId, rankingEnabled }: Props) {
  const { glossary } = useGlossary();
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: "Edit address?",
    body: null,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, continue" },
  });
  const apolloClient = useApolloClient();

  useLoadGooglePlacesScript();

  const calculateSchoolsToRemove = useCallback(
    async function (
      rankedSchools: GQL.GetSchoolsRank_form_school_rank[],
      completeQuestions: readonly AF.Question<AF.WithId>[],
      question: AF.Address<AF.WithId>,
      answer?: AddressAnswer
    ): Promise<GQL.GetSchoolsRank_form_school_rank[]> {
      if (!question.filters?.some(isLocationBoundariesFilter)) {
        return [];
      }

      const gradeConfigQuery = apolloClient.query<
        GQL.GetGradeConfigAnswer,
        GQL.GetGradeConfigAnswerVariables
      >({
        query: GET_GRADE_CONFIG_ANSWER,
        variables: {
          form_id: formId,
        },
      });

      const boundariesQuery = apolloClient.query<
        GQL.GetBoundariesByForm,
        GQL.GetBoundariesByFormVariables
      >({
        query: GET_BOUNDARIES_BY_FORM,
        variables: {
          form_id: formId,
        },
      });

      const [gradeConfig, boundaries] = await Promise.all([
        gradeConfigQuery,
        boundariesQuery,
      ]);

      if (!window.google?.maps) {
        throw new Error("Unable to load google maps");
      }

      const location = answer
        ? await addressLookup(new google.maps.Geocoder(), answer)
        : undefined;

      const schoolIdsToRemove = calculateSchoolsToRemoveByLocation(
        rankedSchools.flatMap((rankedSchool) => rankedSchool.school),
        completeQuestions,
        gradeConfig.data.grades_answer[0]?.grade_config_id ?? undefined,
        Boundary.fromGQL(boundaries.data),
        location
      ).map((school) => school.id);

      if (schoolIdsToRemove.length === 0) return [];

      const rankedSchoolsToRemove = rankedSchools.filter((rankedSchool) =>
        schoolIdsToRemove.includes(rankedSchool.school.id)
      );

      return rankedSchoolsToRemove;
    },
    [formId, apolloClient]
  );

  const confirmEligibilityQuestionUpdate = useCallback(
    async function (
      rankedSchoolsToRemove: GQL.GetSchoolsRank_form_school_rank[]
    ): Promise<boolean> {
      if (rankedSchoolsToRemove.length === 0) {
        return true;
      }

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            schoolsToRemove={rankedSchoolsToRemove}
            body={glossary`Editing your address will impact your eligibility and remove these schools from your ranking:`}
            confirmation="Are you sure you want to edit your address?"
            rankingEnabled={rankingEnabled}
          />
        ),
      });
      return isConfirmed;
    },
    [confirm, glossary, rankingEnabled]
  );

  const flags = useFlags(["eligibility-service"]);
  if (flags["eligibility-service"].enabled) {
    return {
      confirm: () => true,
      confirmationDialog: null,
      calculateSchoolsToRemove: () => [],
    };
  }

  return {
    confirm: confirmEligibilityQuestionUpdate,
    confirmationDialog,
    calculateSchoolsToRemove,
  };
}
