import { Box, Heading, List, ListItem, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { StudentAvatar } from "src/components/Layout/Avatar";
import { Glossary } from "src/components/Text/Glossary";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { fullName } from "src/services/format";
import * as GQL from "src/types/graphql";
import { uniqBy } from "lodash";

interface StudentsWithFormsProps {
  forms: GQL.FormSummaryFragment[];
}

export function StudentsWithForms(props: StudentsWithFormsProps) {
  const { forms } = props;

  const filteredStudentWithForms = useMemo(
    () => uniqBy(forms, (form) => form.person.id),
    [forms]
  );

  const studentsWithForms = useMemo(() => {
    if (filteredStudentWithForms.length > 0) {
      return (
        <List spacing={2}>
          {filteredStudentWithForms.map((form) => {
            const name = fullName(form.person);

            return (
              <ListItem
                alignItems="center"
                display="flex"
                gap={2}
                key={form.id}
              >
                <StudentAvatar size="sm" student={form.person} />

                <Box className={WEGLOT_SKIP_CLASS} fontWeight="700">
                  {name}
                </Box>
              </ListItem>
            );
          })}
        </List>
      );
    } else {
      return (
        <Text color="gray.500" fontSize="sm" fontStyle="italic">
          <Glossary>No student has a form on this enrollment period.</Glossary>
        </Text>
      );
    }
  }, [filteredStudentWithForms]);

  return (
    <VStack align="start" spacing={2}>
      <Heading as="h3" fontSize="lg" fontWeight="600">
        <Glossary>Students with forms</Glossary>
      </Heading>

      {studentsWithForms}
    </VStack>
  );
}
