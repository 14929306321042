import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import { HtmlHTMLAttributes, useCallback, useEffect, useState } from "react";
import { HiTranslate } from "react-icons/hi";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { PreferredLanguageSetting } from "src/hooks/usePreferredLanguage";
import { useTranslationOptions } from "src/hooks/useTranslationOptions";
import { WEGLOT_APPLY_CLASS, WEGLOT_SKIP_CLASS } from "../constants";
import { WeglotContext, useWeglot, useWeglotInject } from "../hooks";
import { WeglotType } from "../types";

export type PreferredLanguage = { code: string; name: string };

export type WeglotWrapProps = HtmlHTMLAttributes<any> & {
  as?: any;
  skip?: boolean;
  children: React.ReactNode;
};

export type WeglotToggleProps = {
  apply: boolean;
  children: React.ReactNode;
};

const WeglotWrap = ({
  skip = false,
  children,
  as,
  ...props
}: WeglotWrapProps) => {
  const As = as;
  return (
    <As className={skip ? WEGLOT_SKIP_CLASS : WEGLOT_APPLY_CLASS} {...props}>
      {children}
    </As>
  );
};

export const WeglotBlock = ({ children, ...props }: WeglotWrapProps) => (
  <WeglotWrap as="div" {...props}>
    {children}
  </WeglotWrap>
);

export const WeglotText = ({ children, ...props }: WeglotWrapProps) => (
  <WeglotWrap
    as="span"
    style={{ textDecoration: "none", display: "contents" }}
    {...props}
  >
    {children}
  </WeglotWrap>
);

export const WeglotInject: React.FC<
  Omit<WeglotWrapProps, "translateClass">
> = ({ children }) => {
  const [weglot, setWeglot] = useState<WeglotType | undefined>(window.Weglot);
  useWeglotInject(() => setWeglot(window.Weglot));

  return (
    <WeglotContext.Provider value={weglot}>{children}</WeglotContext.Provider>
  );
};

type Props = {
  preferredLanguageSetting: PreferredLanguageSetting;
  colorScheme?: ButtonProps["colorScheme"];
  variant?: ButtonProps["variant"];
};
export const WeglotSelect: React.FC<Props> = ({ ...props }) => {
  const configRD = useTranslationOptions();
  return (
    <RemoteDataView
      remoteData={configRD}
      loading={<Skeleton height="2rem" width="3.25rem" />}
      error={() => null}
    >
      {(config) => (
        <CustomWeglotSelect
          {...props}
          languages={config.languages ?? []}
          enabled={config !== null}
        />
      )}
    </RemoteDataView>
  );
};

type CustomProps = {
  enabled: boolean;
  languages: string[] | undefined;
} & Props;
const CustomWeglotSelect: React.FC<CustomProps> = ({
  colorScheme = "primary",
  preferredLanguageSetting,
  languages,
  enabled,
  variant,
}) => {
  const [currentLang, setCurrentLang] = useState("");
  const [langs, setLangs] = useState<PreferredLanguage[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const weglot = useWeglot();

  const { preferredLanguage, savePreferredLanguage } = preferredLanguageSetting;

  const refreshSwitcherData = useCallback(() => {
    if (preferredLanguage) {
      weglot?.switchTo(preferredLanguage);
    }

    setCurrentLang(preferredLanguage ?? weglot?.getCurrentLang() ?? "");
    if (weglot) {
      setLangs(
        weglot.options.languages
          .filter((l) => !languages || languages.includes(l.language_to))
          .map((l) => {
            return {
              code: l.language_to,
              name: weglot.getLanguageName(l.language_to),
            };
          })
          .concat([
            {
              code: weglot.options.language_from,
              name: weglot.getLanguageName(weglot.options.language_from),
            },
          ])
          .sort((a, b) => (a > b ? 1 : -1))
      );
    }
  }, [preferredLanguage, weglot, languages]);

  useEffect(() => {
    if (weglot && weglot.initialized) {
      refreshSwitcherData();
    }
  }, [weglot, refreshSwitcherData]);

  const switchLanguage = (code: string) => {
    weglot?.switchTo(code);
    if (savePreferredLanguage) savePreferredLanguage(code);
    refreshSwitcherData();
    setIsOpen(false);
  };

  return enabled ? (
    <Menu isOpen={isOpen}>
      <MenuButton
        as={Button}
        onClick={() => setIsOpen(!isOpen)}
        colorScheme={colorScheme}
        variant={variant}
        padding="1"
        size="sm"
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
        _focus={{ background: "none" }}
      >
        <Flex alignItems="center">
          <Icon as={HiTranslate} boxSize={6} aria-label="Select language" />
          <ChevronDownIcon boxSize={5} />
        </Flex>
      </MenuButton>
      <MenuList
        zIndex={100}
        maxHeight={window.innerHeight - 100}
        overflow="scroll"
      >
        {langs.map((l) => (
          <MenuItem
            className={WEGLOT_SKIP_CLASS}
            key={l.code}
            onClick={() => switchLanguage(l.code)}
            icon={l.code === currentLang ? <CheckIcon /> : <></>}
          >
            {l.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : null;
};
