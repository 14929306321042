import * as DateFns from "date-fns";
import * as z from "zod";

export enum EligibleFilterType {
  BirthdateBeforeFilter = "BirthdateBeforeFilter",
  BirthdateAfterFilter = "BirthdateAfterFilter",
  BirthdateBetweenFilter = "BirthdateBetweenFilter",
  SchoolEligibilityFilter = "SchoolEligibilityFilter",
  LocationBoundariesFilter = "LocationBoundariesFilter",
}

// --- SCHEMAS --- //
const DateWithoutTimeFormat = "yyyy-MM-dd";

const DateWithoutTime = z
  .string({
    invalid_type_error: `Date format must be ${DateWithoutTimeFormat}`,
  })
  .refine((value) => DateFns.isMatch(value, DateWithoutTimeFormat));

export const BirthdateBeforeFilterSchema = z.object({
  type: z.literal(EligibleFilterType.BirthdateBeforeFilter),
  config: z.object({
    endDate: DateWithoutTime,
    keys: z.array(z.string()),
  }),
});

export const BirthdateAfterFilterSchema = z.object({
  type: z.literal(EligibleFilterType.BirthdateAfterFilter),
  config: z.object({
    startDate: DateWithoutTime,
    keys: z.array(z.string()),
  }),
});

export const BirthdateBetweenFilterSchema = z.object({
  type: z.literal(EligibleFilterType.BirthdateBetweenFilter),
  config: z.object({
    startDate: DateWithoutTime,
    endDate: DateWithoutTime,
    keys: z.array(z.string()),
  }),
});

export enum LocationBoundariesFilterRuleOptions {
  Filter = "Filter",
  ShowFirst = "ShowFirst",
}

export const LocationBoundariesFilterRuleSchema = z.nativeEnum(
  LocationBoundariesFilterRuleOptions
);
export const LocationBoundariesFilterSchema = z.object({
  type: z.literal(EligibleFilterType.LocationBoundariesFilter),
  config: z.object({
    rule: LocationBoundariesFilterRuleSchema,
  }),
});

export const SchoolEligibilityFilterSchema = z.object({
  type: z.literal(EligibleFilterType.SchoolEligibilityFilter),
  config: z.object({
    keys: z.array(z.string()),
  }),
});

export const FormTemplateFilterSchema = z.discriminatedUnion("type", [
  BirthdateBeforeFilterSchema,
  BirthdateAfterFilterSchema,
  BirthdateBetweenFilterSchema,
  LocationBoundariesFilterSchema,
  SchoolEligibilityFilterSchema,
]);

export const FormTemplateFiltersSchema = z.array(FormTemplateFilterSchema);
