import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import {
  SectionFormDialog,
  SectionFormType
} from "src/components/Form/SectionForm/SectionFormDialog";
import { createNewSection } from "src/components/Form/SectionForm/services";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as GQL from "src/types/graphql";
import { useEditFormTemplateContext } from "../EditFormTemplateTabs/Content/context";

type AddSectionButtonProps = ButtonProps & {
  children?: string;
  newOrder: number;
  applicableSectionTypes: string[];
};

export const AddSectionButton: React.FC<AddSectionButtonProps> = ({
  children,
  onClick,
  newOrder,
  applicableSectionTypes,
  leftIcon,
  ...buttonProps
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { dispatch, formTemplateId } = useEditFormTemplateContext();

  const handleAddNewSection = useCallback(
    (values: SectionFormType) => {
      if (!dispatch) {
        return;
      }

      const newSection = createNewSection(values);

      switch (values.type) {
        case GQL.form_template_section_type_enum.SchoolRankingSection:
          dispatch({
            type: "AddNewSchoolRanking",
            newSection: newSection as Draft.NewSchoolRanking,
            atIndex: newOrder
          });
          break;
        default:
          dispatch({
            type: "AddNewSection",
            atIndex: newOrder,
            newSection
          });
          break;
      }

      if (formTemplateId) {
        dispatch({ type: "Save", formTemplateId });
      }

      onClose();
    },
    [dispatch, formTemplateId, newOrder, onClose]
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={leftIcon ?? <RiAddCircleLine />}
        size="sm"
        alignSelf="flex-end"
        onClick={onOpen}
        {...buttonProps}
      >
        {children ?? "Add section"}
      </Button>

      <SectionFormDialog
        isOpen={isOpen}
        onCreate={handleAddNewSection}
        onCancelButtonClick={onClose}
        applicableSectionTypes={applicableSectionTypes}
      />
    </>
  );
};
