import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import * as RemoteData from "../types/remoteData";

export function useRemoteDataMutation<
  TData,
  TVariables = OperationVariables,
  TContext = DefaultContext
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext>
): MutationTuple<TData, TVariables, TContext, ApolloCache<any>> {
  const [mutate, result] = useMutation(mutation, options);
  return [
    mutate,
    { ...result, remoteData: RemoteData.fromApolloResult(result) },
  ];
}

// TYPES
export interface MutationRemoteData<TData>
  extends Omit<MutationResult<TData>, "data" | "error" | "loading"> {
  remoteData: RemoteData.RemoteData<ApolloError, TData | null | undefined>;
}

type MutationTuple<
  TData,
  TVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
> = [
  (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>
  ) => Promise<FetchResult<TData>>,
  MutationRemoteData<TData>
];
