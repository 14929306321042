import { ApolloError } from "@apollo/client";
import { GET_ORGANIZATIONS } from "../scenes/admin/organizations/graphql/queries";
import * as GQL from "../types/graphql";
import * as RemoteData from "../types/remoteData";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import { GET_ORGANIZATIONS_BY_USER_ID } from "./graphql/queries";
import useUser from "./useUser";
import { Status } from "src/types/authData";

export function useOrganizations(): RemoteData.RemoteData<
  ApolloError,
  GQL.GetOrganizations_organization[]
> {
  const { remoteData } =
    useRemoteDataQuery<GQL.GetOrganizations>(GET_ORGANIZATIONS);
  return remoteData.map((data) => data.organization);
}

type Organization = { id: uuid; name: string; path: string };
export function useParentOrganizations(): RemoteData.RemoteData<
  ApolloError,
  Organization[]
> {
  const user = useUser();
  const userId = user.status === Status.OK ? user.data.id : null;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetOrganizationsByUserId,
    GQL.GetOrganizationsByUserIdVariables
  >(GET_ORGANIZATIONS_BY_USER_ID, {
    variables: { userId: userId ?? "" },
    skip: userId === null,
  });

  return remoteData.map((data) => data.organization);
}
