import { Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import { debounce, uniq } from "lodash";
import React, { useMemo } from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import priorityTemplateSchema from "src/schemas/priorityTemplate.json";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { PriorityTemplateConfig } from "src/types/priorityTemplate";
import { injectSchemaEnums, JsonEditor } from "../components/JsonEditor";
import { CREATE_PRIORITY_TEMPLATES } from "./graphql/mutations";
import { GET_ENROLLMENT_PERIOD_ENUMS } from "./graphql/queries";

const ExampleOutline: PriorityTemplateConfig = {
  enrollmentPeriodId: "[uuid]",
  templates: [
    {
      name: "[example default]",
      priorityConfig: {
        priorityGroups: [
          [
            ["tagA", "tagB"],
            ["tagA", { not: "tagC" }],
          ],
          [["tagD", "tagE"]],
        ],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
          {
            field: GQL.priority_template_sort_field_enum.LotteryOrder,
            order: GQL.priority_template_sort_order_enum.asc,
          },
          {
            field: GQL.priority_template_sort_field_enum.SchoolSubmissionTime,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
    {
      name: "[example template 1]",
      schools: [{ id: "[school-uuid-1]" }, { id: "[school-uuid-2]" }],
      priorityConfig: {
        priorityGroups: [[["tagB"]]],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
          {
            field: GQL.priority_template_sort_field_enum.LotteryOrder,
            order: GQL.priority_template_sort_order_enum.asc,
          },
          {
            field: GQL.priority_template_sort_field_enum.SchoolSubmissionTime,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
    // {
    //   name: "[example template 2]",
    //   schools: [
    //     { id: "[school-uuid-3]", grades: ["[grade-uuid-1]", "[grade-uuid-2]"] },
    //     { id: "[school-uuid-4]" },
    //   ],
    //   priorityConfig: {
    //     priorityGroups: [[["tagB"]]],
    //     sortFields: [{ field: "submitted_at", order: "asc" }],
    //   },
    // },
  ],
};

type sort_fields_input = {
  [k: `sort_field_${number}`]:
    | GQL.priority_template_sort_field_enum
    | undefined;
  [k: `sort_order_${number}`]:
    | GQL.priority_template_sort_order_enum
    | undefined;
};

export function New() {
  const toast = useToast();
  const [json, setJson] = React.useState(
    JSON.stringify(ExampleOutline, undefined, 2)
  );
  const [isValid, setIsValid] = React.useState(true);

  // Enrollment period ID extracted from JSON, if available.
  const [enrollmentPeriodId, setEnrollmentPeriodId] = React.useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parseEnrollmentPeriod = React.useCallback(
    debounce((text: string) => {
      try {
        const config = JSON.parse(text);
        setEnrollmentPeriodId(config.enrollmentPeriodId ?? "");
      } catch (error) {}
    }, 500),
    []
  );
  const handleChange = (text: string) => {
    setJson(text);
    parseEnrollmentPeriod(text);
  };

  // Enumerate available values from the database and inject them into the schema.
  const { remoteData } = useRemoteDataQuery<GQL.GetEnrollmentPeriodEnums, {}>(
    GET_ENROLLMENT_PERIOD_ENUMS
  );
  const enrollmentPeriods = useMemo(
    () => (remoteData.hasData() ? remoteData.data.enrollment_period : []),
    [remoteData]
  );
  const enrollmentPeriod = useMemo(
    () => enrollmentPeriods.find((ep) => ep.id === enrollmentPeriodId),
    [enrollmentPeriods, enrollmentPeriodId]
  );
  const schemaWithEnums = useMemo(() => {
    const validTags = enrollmentPeriod?.enrollment_period_tags ?? [];
    const validSchools = uniq(
      enrollmentPeriod?.grades.flatMap((g) => g.school_id) ?? []
    );
    return injectSchemaEnums(priorityTemplateSchema, {
      EnrollmentPeriodId: enrollmentPeriods.map((ep) => ep.id),
      SchoolId: validSchools,
      Tag: validTags.map((tag) => tag.name),
    });
  }, [enrollmentPeriods, enrollmentPeriod]);

  const [createPriorityTemplate] = useRemoteDataMutation<
    GQL.CreatePriorityTemplates,
    GQL.CreatePriorityTemplatesVariables
  >(CREATE_PRIORITY_TEMPLATES);
  const uploadJSONHandler = async () => {
    // Validity is computed asynchronously, so there's a brief window (a
    // fraction of a second) in which `isValid` may be true while `json` isn't
    // actually valid.
    // TODO: Add synchronous validation of JSON.
    if (!isValid) {
      toast({
        title: "Invalid priority template.",
        status: "error",
        isClosable: true,
      });
      return;
    }
    const priorityTemplateConfig: PriorityTemplateConfig = JSON.parse(json);
    const { enrollmentPeriodId, templates } = priorityTemplateConfig;
    const priority_templates = templates.map(
      ({
        name,
        schools,
        priorityConfig,
      }): GQL.priority_template_insert_input => {
        const priorityTemplate: GQL.priority_template_insert_input &
          sort_fields_input = {
          name,
          enrollment_period_id: enrollmentPeriodId,
          config: priorityConfig,
        };
        // It'd be simpler to just enumerate these, but I think this loop may be
        // less error-prone / one less thing to remember if we decide to add
        // more sort fields (which would otherwise fail silently).
        for (let i = 0; i < priorityConfig.sortFields.length; ++i) {
          priorityTemplate[`sort_field_${i + 1}`] =
            priorityConfig.sortFields[i]?.field;
          priorityTemplate[`sort_order_${i + 1}`] =
            priorityConfig.sortFields[i]?.order;
        }
        if (schools) {
          // Note: This assumes grade IDs are from grade.id not grade_config.id.
          // As such, it actually does not matter that they are nested under
          // specific schools -- which also means that it is possible to nest a
          // grade under a school to which it does not belong.  We may want
          // something more robust here, but since grades aren't a priority yet,
          // I'm deferring this until it's going to be used.
          const template_grades = schools
            .flatMap<GQL.priority_template_grade_insert_input | null>(
              ({ grades }) =>
                grades?.length ? grades.map((grade_id) => ({ grade_id })) : null
            )
            .filter(isNotNull);
          const template_schools = schools
            .map<GQL.priority_template_school_insert_input | null>(
              ({ id, grades }) =>
                grades?.length
                  ? null
                  : {
                      enrollment_period_id: enrollmentPeriodId,
                      school_id: id,
                    }
            )
            .filter(isNotNull);

          return {
            ...priorityTemplate,
            priority_template_grades: { data: template_grades },
            priority_template_schools: { data: template_schools },
          };
        } else {
          return {
            ...priorityTemplate,
            priority_template_enrollment_periods: {
              data: [{ enrollment_period_id: enrollmentPeriodId }],
            },
          };
        }
      }
    );

    try {
      await createPriorityTemplate({
        variables: { priority_templates },
      });

      toast({
        title: "Priority templates created",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error creating priority templates",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" gap={3} height="100%">
      <JsonEditor
        title="Create priority template"
        schema={schemaWithEnums}
        text={json}
        onChange={handleChange}
        onChangeValidity={setIsValid}
        filename="priorityTemplateConfig.json"
        height="100%"
      />
      <Flex gap={3}>
        <Spacer />
        <Button type="button" isDisabled={!isValid} onClick={uploadJSONHandler}>
          Create
        </Button>
      </Flex>
    </Flex>
  );
}
