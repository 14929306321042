import * as z from "zod";
import { WebhookSchema } from "./base";

export const CreateWebhookRequestSchema = WebhookSchema.omit({
  pk: true,
  events: true,
  organization_id: true,
  is_active: true,
}).strict();

export type CreateWebhookRequest = z.infer<typeof CreateWebhookRequestSchema>;

export const CreateWebhookResponseSchema = z.object({
  pk: z.string(),
});

export type CreateWebhookResponse = z.infer<typeof CreateWebhookResponseSchema>;
