import * as GQL from "src/types/graphql";
import { z } from "zod";

const CustomLiteral = z.literal("Custom");
const StandardLiteral = z.literal("Standard");
export const GlossaryTypeSchema = z.union([CustomLiteral, StandardLiteral]);
const CustomLabelSchema = z.object({
  singular: z.string().min(1, "This field is required"),
  plural: z.string().min(1, "This field is required"),
});
export type CustomLabelType = z.infer<typeof CustomLabelSchema>;
const CustomFormSchema = z.object({
  status: CustomLiteral,
  labels: z.record(z.nativeEnum(GQL.glossary_type_enum), CustomLabelSchema),
});
export type CustomFormType = z.infer<typeof CustomFormSchema>;
const StandardLabelSchema = z.object({
  singular: z.string(),
  plural: z.string(),
});
const StandardFormSchema = z.object({
  status: StandardLiteral,
  labels: z.record(z.nativeEnum(GQL.glossary_type_enum), StandardLabelSchema),
});
export const FormSchema = z.discriminatedUnion("status", [
  CustomFormSchema,
  StandardFormSchema,
]);
export type FormType = z.infer<typeof FormSchema>;

export type GlossaryStatus = "Custom" | "Standard";

export type GlossaryPerOrganization = {
  organizationId: uuid;
  organizationName: string;
  glossaryStatus: GlossaryStatus;
};
