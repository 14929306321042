import { useToast } from "@chakra-ui/react";
import { DeleteButton } from "src/components/Buttons/DeleteButton";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_GRADE_CONFIG } from "./graphql/mutations";

interface DeleteGradeConfigButtonProps {
  id: string;
  onDelete: () => void;
}

export const DeleteGradeConfigButton: React.FC<
  DeleteGradeConfigButtonProps
> = ({ id, onDelete }) => {
  const toast = useToast();

  const [deleteGradeConfig, { remoteData }] = useRemoteDataMutation<
    GQL.DeleteGradeConfig,
    GQL.DeleteGradeConfigVariables
  >(DELETE_GRADE_CONFIG);

  const handleDelete = async () => {
    try {
      await deleteGradeConfig({ variables: { id: id } });
      onDelete();
    } catch (error: any) {
      console.error(error);
      if (!toast.isActive(id)) {
        if (error.message.includes("Foreign key violation.")) {
          toast({
            id,
            title: "Error deleting grades",
            description:
              "One or more in the grade group are in use on existing forms",
            isClosable: true,
            status: "error",
          });
        } else {
          toast({
            id,
            title: "Something went wrong",
            description: "Check your network and try again",
            isClosable: true,
            status: "error",
          });
        }
      }
    }
  };

  return (
    <DeleteButton
      label="Delete"
      loading={remoteData.isLoading()}
      onDelete={handleDelete}
      confirmationHeader="Delete Grades Configuration"
    />
  );
};
