import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

type Props = {
  message?: string;
};

export function AccessDenied({ message }: Props) {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Access Denied</AlertTitle>
      <AlertDescription>
        {" "}
        {message !== undefined
          ? message!
          : "You don't have access to this page"}
      </AlertDescription>
    </Alert>
  );
}
