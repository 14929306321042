import type { GlossaryFn } from "src/types/glossary";

interface SchoolSelectionLimitTextOptions {
  /**
   * Whether or not the maximum number of schools have been selected.
   */
  limitReached: boolean;

  /**
   * The optional, maximum number of schools that can be selected.
   */
  maxSchools?: number;

  /**
   * The optional, minimum number of schools that must be selected.
   */
  minSchools?: number;
}

/**
 * A function which returns assistive text to display with school selection.
 * Return the assistive copy to display with school selection.
 */
export function schoolSelectionLimitText(
  glossary: GlossaryFn,
  options: SchoolSelectionLimitTextOptions
): string | null {
  const { limitReached, maxSchools = 0, minSchools = 1 } = options;

  if (limitReached) {
    return glossary`School limit reached`;
  }

  if (minSchools > 1) {
    if (maxSchools > minSchools) {
      return glossary`Add between ${minSchools} to ${maxSchools} schools`;
    }

    if (maxSchools === minSchools) {
      return glossary`Add ${maxSchools} schools`;
    }

    // Ignore a maximum below the minimum.
    return glossary`Add at least ${minSchools} schools`;
  }

  if (maxSchools > 1) {
    return glossary`Add up to ${maxSchools} schools`;
  }

  if (maxSchools > 0) {
    return glossary`Add up to ${maxSchools} school`;
  }

  return null;
}
