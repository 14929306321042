import * as GQL from "src/types/graphql";
import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, Organization>,
  formTemplate: { id: uuid; name: string } | undefined,
  params?: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    ...(formTemplate
      ? [
          {
            label: formTemplate.name ?? "Forms",
            href: organization
              .map((org) =>
                Url.OrgAdmin.Forms.index({
                  organization: org,
                  formTemplateId: formTemplate.id,
                  params,
                })
              )
              .withDefault("#"),
          },
        ]
      : []),
  ];
};

export const getBreadcrumbsForView = (
  organization: RD.RemoteData<unknown, Organization>,
  form: GQL.GetFormViewById_form_by_pk,
  params: string
): BreadcrumbType[] => {
  const { person } = form;
  return [
    ...getBreadcrumbsForList(
      organization,
      {
        id: form.form_template.id,
        name: form.form_template.name,
      },
      params
    ),
    {
      label: `${person.first_name} ${person.last_name}'s response`,
      href: Url.OrgAdmin.Forms.view({
        organization,
        formTemplateId: form.form_template.id,
        id: form.id,
        params,
      }),
    },
  ];
};

export const getBreadcrumbsForSendMessage = (
  organization: RD.RemoteData<unknown, Organization>,
  formTemplate: { id: uuid; name: string } | undefined,
  params: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization, formTemplate, params),
    {
      label: "Send message",
      href: organization
        .map((org) => Url.OrgAdmin.Messages.new(org, params))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForBulkVerifications = (
  organization: RD.RemoteData<unknown, Organization>,
  formTemplate: { id: uuid; name: string } | undefined,
  params: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization, formTemplate, params),
    {
      label: "Verify",
      href: !formTemplate
        ? "#"
        : organization
            .map((org) =>
              Url.OrgAdmin.Forms.verifications({
                organization: org,
                formTemplateId: formTemplate.id,
                params,
              })
            )
            .withDefault("#"),
    },
  ];
};
