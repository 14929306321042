import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { RiArrowDownSLine, RiCalendarTodoLine } from "react-icons/ri";
import * as GQL from "src/types/graphql";
import { GenericError } from "../Feedback/GenericError";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { Mode } from "../Navigation";
import { useEnrollmentPeriod } from "../Providers/EnrollmentPeriodProvider";

interface EnrollmentPeriodSelectInputProps {
  mode?: Mode;
  organization: GQL.Organization;
}

export const EnrollmentPeriodSelectInput: React.FC<
  EnrollmentPeriodSelectInputProps
> = ({ mode = "full", organization }) => {
  const {
    enrollmentPeriods,
    selectedEnrollmentPeriod,
    setSelectedEnrollmentPeriodId,
  } = useEnrollmentPeriod();

  return (
    <RemoteDataView
      loading={<Skeleton width="16rem" height="2.5rem" />}
      remoteData={enrollmentPeriods}
      error={() => <GenericError />}
    >
      {(enrollmentPeriods) => (
        <Menu>
          {mode === "compact" && (
            <MenuButton
              as={IconButton}
              icon={<RiCalendarTodoLine />}
              colorScheme="gray"
              variant="outline"
              backgroundColor="white"
            ></MenuButton>
          )}

          {mode === "full" && (
            <MenuButton
              as={Button}
              colorScheme="gray"
              variant="outline"
              backgroundColor="white"
              width="100%"
            >
              <Flex>
                <Flex flex={1} gap={2} alignItems="center" overflowX="hidden">
                  <RiCalendarTodoLine color="gray.700" />
                  <Text fontSize="sm">{selectedEnrollmentPeriod?.name}</Text>
                </Flex>
                <RiArrowDownSLine color="gray.700" />
              </Flex>
            </MenuButton>
          )}

          <Portal>
            <MenuList maxHeight="calc(100vh - 20em)" overflowY="auto">
              {enrollmentPeriods.map((enrollmentPeriod) => {
                const isSelected =
                  enrollmentPeriod.id === selectedEnrollmentPeriod?.id;
                return (
                  <MenuItem
                    icon={isSelected ? <CheckIcon /> : <></>}
                    value={enrollmentPeriod.id}
                    key={enrollmentPeriod.id}
                    onClick={() => {
                      setSelectedEnrollmentPeriodId(enrollmentPeriod.id);
                    }}
                  >
                    {enrollmentPeriod.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Portal>
        </Menu>
      )}
    </RemoteDataView>
  );
};
