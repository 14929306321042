import { useCallback, useContext, useMemo } from "react";
import { Permission } from "src/types/permissions";
import { UserPermissionsContext } from "./context";

export * from "./context";

export type UserPermissionReturnType = {
  isLoading: boolean;
  hasOne: (permissions: Permission) => boolean;
  hasSome: (permissions: Permission[]) => boolean;
  hasAll: (permissions: Permission[]) => boolean;
  attributes: {
    currentSchoolIds: string[];
    applyingSchoolIds: string[];
  };
};

/**
 * Retrieve permissions from Auth0 JWT.   only org-admin are required to have permissions.
 */
export function useUserPermissions(): UserPermissionReturnType {
  const state = useContext(UserPermissionsContext);

  const hasOne = useCallback(
    (permission: Permission) =>
      !state.isRequired ||
      state.permissions.find((item) => item === permission) !== undefined,
    [state]
  );

  const hasSome = useCallback(
    (permission: Permission[]) => permission.some((item) => hasOne(item)),
    [hasOne]
  );
  const hasAll = useCallback(
    (permission: Permission[]) => permission.every((item) => hasOne(item)),
    [hasOne]
  );

  const attributes = useMemo(
    () => ({
      currentSchoolIds: state.attributes.currentSchoolIds,
      applyingSchoolIds: state.attributes.applyingSchoolIds,
    }),
    [state.attributes]
  );

  const isLoading = useMemo(() => state.isLoading, [state.isLoading]);

  return {
    isLoading,
    hasOne,
    hasSome,
    hasAll,
    attributes,
  };
}
