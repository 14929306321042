import { Flex, Tag } from "@chakra-ui/react";
import * as AF from "src/types/formTemplate";

interface QuestionTagProps {
  question: AF.Question<AF.WithId>;
  section?: AF.Section<AF.WithId>;
}

interface SectionTagProps {
  question?: AF.Question<AF.WithId>;
  section: AF.Section<AF.WithId>;
}

type Props = QuestionTagProps | SectionTagProps;

export const InternalOnlyTag: React.FC<Props> = ({ question, section }) => {
  if (
    question &&
    (question.type === AF.GradesType ||
      question.permissionLevel === undefined ||
      question.permissionLevel.trim() === "")
  ) {
    return null;
  }

  if (section && !section.permissionLevel) {
    return null;
  }

  return (
    <Flex direction="row">
      <Tag size="sm">Internal-Only</Tag>
    </Flex>
  );
};

export function hasInternalOnlyTag(question: AF.Question<AF.WithId>): boolean {
  return InternalOnlyTag({ question }) !== null;
}
