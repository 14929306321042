import { Box } from "@chakra-ui/react";
import { useField } from "formik";
import { SelectControl } from "formik-chakra-ui";
import React from "react";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import * as AFF from "src/services/formTemplateFilters";
import { NoDataInfoAlert } from "../../Feedback/NoDataInfoAlert";
import { BaseInputProps, Option } from "./Question";

export interface GradeSingleSelectProps extends BaseInputProps {
  isRequired?: boolean;
  options: Option[];
  onOptionSelected?: (option: Option) => void;
  applicant: AFF.Types.Applicant;
}

export const GradeSingleSelectInput: React.FC<GradeSingleSelectProps> = ({
  id,
  options,
  onOptionSelected,
  isDisabled = false,
  isRequired = false,
  applicant,
}) => {
  const [field, , helpers] = useField<FormikFieldValue>(id);

  const value = field.value;

  const disabled = isDisabled ? isDisabled : options.length === 0;

  React.useEffect(() => {
    if (!onOptionSelected) return;
    for (const option of options) {
      if (option.key === value) {
        onOptionSelected(option);
        return;
      }
    }
  }, [value, options, onOptionSelected]);
  return (
    <>
      {options.length === 0 && (
        <Box mb={2}>
          <NoDataInfoAlert
            text={`No available grades for ${applicant.first_name}. Review the student’s date of birth or`}
          />
        </Box>
      )}
      <SelectControl
        isDisabled={disabled}
        name={id}
        selectProps={{ placeholder: "Select answer" }}
        isRequired={isRequired}
        onChange={(event) => {
          // set as touched so it triggers autosave on change instead of on blur.
          helpers.setTouched(true);
          field.onChange(event);
        }}
      >
        {options.map((o) => (
          <option
            className={o.translate ? WEGLOT_APPLY_CLASS : WEGLOT_SKIP_CLASS}
            value={o.key}
            key={o.key}
          >
            {o.label ?? o.key}
          </option>
        ))}
      </SelectControl>
    </>
  );
};
