import * as RD from "src/types/remoteData";
import { maybePluralize } from "src/services/format";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import { ApolloError, useReactiveVar } from "@apollo/client";
import { deletedRankedSchoolsVar } from "../store";
import { useGlossary } from "src/hooks/useGlossary";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import * as GQL from "src/types/graphql";
import { useEligibilityService } from "src/services/eligibility/useEligibilityService";
import * as Answer from "src/services/formTemplate/answer";
import { useUpdateRankedSchoolsRemoteData } from "src/hooks/useSchoolRank";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { INSERT_FORM_SCHOOLS_RANK } from "../graphql/mutations";
import * as FormTemplateSection from "src/services/formTemplate/useFormTemplateSection";

type Props = {
  formId: uuid;
  formTemplateId: uuid;
  schoolRanksRemoteData: RD.RemoteData<ApolloError, GQL.GetSchoolsRank>;
  refetchSchoolRanks: () => Promise<unknown>;
};
type ReturnValue = {
  clearSchoolRemoveAlert: () => void;
  deletedSchoolsRankAlert: React.ReactNode;
  selectedSchoolRanksRemoteData: RD.RemoteData<Error, GQL.GetSchoolsRank>;
};
export const useSelectedSchoolRanks = ({
  formId,
  formTemplateId,
  schoolRanksRemoteData,
  refetchSchoolRanks,
}: Props): ReturnValue => {
  const { glossary } = useGlossary();
  const flags = useFlags(["eligibility-service"]);
  const isEnabled = flags["eligibility-service"].enabled;

  // Frontend eligibility
  const deletedSchoolsRank = useReactiveVar(deletedRankedSchoolsVar);
  const clearSchoolRemoveAlert = () => {
    deletedRankedSchoolsVar([]);
  };

  // Backend eligibility
  const preRankingSectionRemoteData =
    FormTemplateSection.usePreRankingSectionRemoteData(formTemplateId);
  const schoolRankingSectionRemoteData =
    FormTemplateSection.useSchoolRankingSectionRemoteData(formTemplateId);
  const answersRemoteData = Answer.useAnswers(formId);

  const { getIneligibleSchoolIdsByAnswers } = useEligibilityService({
    formTemplateId,
  });
  const updateRankedSchoolRemoteData = useUpdateRankedSchoolsRemoteData(
    RD.toTuple(schoolRanksRemoteData, schoolRankingSectionRemoteData).map(
      ([selectedSchoolRanks, schoolRankingSection]) => {
        return selectedSchoolRanks.form_school_rank.map((schoolRank) => {
          return {
            form_id: formId,
            schools_ranking_section_id: schoolRankingSection.id,
            school_id: schoolRank.school.id,
          };
        });
      }
    )
  );

  const [insertSchoolRank] = useRemoteDataMutation<
    GQL.InsertFormSchoolsRank,
    GQL.InsertFormSchoolsRankVariables
  >(INSERT_FORM_SCHOOLS_RANK);

  const hasRunRef = useRef(false);
  const [enforcementStatus, setEnforcementStatus] = useState<
    RD.RemoteData<Error, {}>
  >(isEnabled ? RD.loading() : RD.notAsked());
  useEffect(() => {
    async function enforceEligibility() {
      const remoteData = RD.toTuple5(
        preRankingSectionRemoteData,
        schoolRankingSectionRemoteData,
        answersRemoteData,
        schoolRanksRemoteData,
        updateRankedSchoolRemoteData
      );

      if (hasRunRef.current) {
        return;
      }
      if (!isEnabled) {
        return;
      }

      if (remoteData.isLoading()) {
        return;
      }
      if (remoteData.hasError()) {
        setEnforcementStatus(RD.failure(remoteData.error));
        return;
      }
      if (!remoteData.hasData()) {
        setEnforcementStatus(
          RD.failure(
            new Error("Failed to get eligibility", {
              cause: "Missing required data",
            })
          )
        );
        return;
      }

      try {
        hasRunRef.current = true;
        const [
          preRankingSection,
          schoolRankingSection,
          answers,
          schoolsRank,
          updateRankedSchool,
        ] = remoteData.data;
        const preRankingQuestions = preRankingSection.questions ?? [];
        const ineligibleSchoolIds = await getIneligibleSchoolIdsByAnswers({
          answers: Answer.getFormikInitialValues(
            preRankingQuestions,
            answers.form_answer,
            answers.grades_answer,
            answers.form_address,
            answers.custom_question_answer
          ),
        });
        const eligibleSchoolIds = schoolsRank.form_school_rank.flatMap(
          (schoolRank) => {
            if (ineligibleSchoolIds.includes(schoolRank.school.id)) {
              return [];
            }

            return [schoolRank.school.id];
          }
        );
        const upsertedSchoolRanks = eligibleSchoolIds.map((schoolId, index) => {
          return {
            form_id: formId,
            schools_ranking_section_id: schoolRankingSection.id,
            school_id: schoolId,
            rank: index,
          };
        });
        const { getDeletedRanks, getDeletedOffers, getDeletedWaitlists } =
          updateRankedSchool;
        const deletedSchoolRanks = getDeletedRanks(upsertedSchoolRanks);
        const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
        const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);
        const variables = {
          deleted_school_ranks: deletedSchoolRanks,
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
          upserted_school_ranks: upsertedSchoolRanks,
        };
        await insertSchoolRank({
          variables,
        });
        const deletedSchoolIds: string[] =
          deletedSchoolRanks._or?.flatMap((item) =>
            item.school_id?._eq ? [item.school_id._eq] : []
          ) ?? [];
        deletedRankedSchoolsVar(deletedSchoolIds);
        await refetchSchoolRanks();
        setEnforcementStatus(RD.success({}));
      } catch (error) {
        console.error(error);
        setEnforcementStatus(
          RD.failure(
            error instanceof Error
              ? error
              : new Error("Failed to get eligibility", { cause: error })
          )
        );
      }
    }

    enforceEligibility();
  }, [
    answersRemoteData,
    isEnabled,
    formId,
    insertSchoolRank,
    preRankingSectionRemoteData,
    refetchSchoolRanks,
    schoolRankingSectionRemoteData,
    schoolRanksRemoteData,
    updateRankedSchoolRemoteData,
    getIneligibleSchoolIdsByAnswers,
  ]);

  const deletedSchoolsRankCount: number = deletedSchoolsRank.length;
  const remoteData = useMemo(
    () =>
      isEnabled
        ? RD.map2(
            enforcementStatus,
            schoolRanksRemoteData,
            (_, selectedSchools) => selectedSchools
          )
        : schoolRanksRemoteData,
    [isEnabled, enforcementStatus, schoolRanksRemoteData]
  );
  return {
    clearSchoolRemoveAlert,
    selectedSchoolRanksRemoteData: remoteData,
    deletedSchoolsRankAlert:
      deletedSchoolsRank.length > 0 ? (
        <InfoAlert
          closeAlert={() => clearSchoolRemoveAlert()}
          styleProps={{ alignItems: "center" }}
        >
          {maybePluralize(
            deletedSchoolsRankCount,
            glossary`${deletedSchoolsRankCount} school was removed`,
            glossary`${deletedSchoolsRankCount} schools were removed`
          )}
        </InfoAlert>
      ) : null,
  };
};
