import { Flex, Heading } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { AddRelationshipButton } from "../Buttons/AddRelationshipButton";
import { DeleteRelationshipButton } from "../Buttons/DeleteRelationshipButton";
import { WithRequiredHasuraRoles } from "../Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "../Permissions/WithUserPermissions";
import { Table } from "./Table";
import {
  ColumnType,
  buildRelationshipPersonIdColumnDef,
} from "./columns/RelationshipPersonIdColumn";
import { useOrganization } from "src/hooks/useOrganization";

interface RelationshipsTableProps {
  personId: string | undefined;
  data: GQL.GetPersonRelationships_relationship_person[];
  entityType: GQL.person_type_enum;
}

export const RelationshipsTable = ({
  data,
  personId,
  entityType,
}: RelationshipsTableProps) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();

  const tableData: ColumnType[] = data.map((item) =>
    item.first?.id === personId
      ? { id: item.id, person: item.second }
      : { id: item.id, person: item.first }
  );

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      buildRelationshipPersonIdColumnDef<ColumnType>({
        organization,
      }),
      {
        accessorKey: "first_name",
        header: "first name",
        accessorFn: (row) => row?.person.first_name || "",
      },
      {
        accessorKey: "middle_name",
        header: "middle name",
        accessorFn: (row) => row?.person.middle_name || "",
      },
      {
        accessorKey: "last_name",
        header: "last name",
        accessorFn: (row) => row?.person.last_name || "",
      },

      ...(userPermissions.hasSome(["user:update"])
        ? [
            {
              id: "Actions",
              cell: ({ row }: { row: Row<ColumnType> }) => {
                const id: string = row.original.id;
                return (
                  <WithRequiredHasuraRoles
                    roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                  >
                    <WithUserPermissions permissions={["user:update"]}>
                      <DeleteRelationshipButton id={id} />
                    </WithUserPermissions>
                  </WithRequiredHasuraRoles>
                );
              },
            },
          ]
        : []),
    ],
    [userPermissions, organization]
  );

  return (
    <Flex direction="column" gap={2}>
      <Flex justifyContent="space-between">
        <Heading as="h2" fontSize="2xl" fontWeight="400">
          Relationships
        </Heading>
        {personId && (
          <AddRelationshipButton id={personId} entityType={entityType} />
        )}
      </Flex>
      <Table
        data={tableData}
        columns={columns}
        noDataMessage="This user has no relationships"
      />
    </Flex>
  );
};
