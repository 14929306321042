import { Radio, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { FormattedAddress } from "./FormattedAddress";
import { BaseAddress } from "./schema";

export enum SuggestedAddressType {
  Original = "original",
  Suggested = "suggested",
}

export type SuggestedAddressesProps = {
  type: SuggestedAddressType;
  address: BaseAddress;
};

export const SuggestedAddressRadioOption: FunctionComponent<
  SuggestedAddressesProps
> = (props) => {
  const { type, address } = props;

  const title =
    type === SuggestedAddressType.Original
      ? "Originally entered"
      : "Suggested address";

  return (
    <Radio
      value={type}
      display="flex"
      padding={4}
      isInvalid={false} // disable red ring
      alignItems="baseline"
    >
      <Text fontWeight={600}>{title}</Text>
      <FormattedAddress address={address} />
    </Radio>
  );
};
