import { getRelatedGrade } from "./related";

type FormWithGradesAnswer = {
  grades_answers: { grade_config: { label: string | null } | null }[];
} | null;
type FormWithGrade = {
  previous_offer: {
    grade: { grade_config: { label: string } | null };
  } | null;
  previous_waitlist: {
    grade: { grade_config: { label: string } | null };
  } | null;
  previous_form: FormWithGradesAnswer;
} & FormWithGradesAnswer;

export function getGrade(form: FormWithGrade | undefined | null) {
  let grade = form?.grades_answers[0]?.grade_config?.label;
  if (!grade && form) {
    grade = getRelatedGrade(form);
  }

  return grade;
}
