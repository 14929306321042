import { gql } from "@apollo/client";

export const ADD_TO_WAITLIST_BULK_MAX_INPUT_SIZE = 500;
export const ADD_TO_WAITLIST_BULK = gql`
  mutation AddToWaitlistsBulk(
    $form_ids: [uuid!]
    $delete_waitlists_where: waitlist_bool_exp!
    $insert_waitlists: [waitlist_insert_input!]!
    $delete_offers_where: offer_bool_exp!
    $update_form_school_rank: form_school_rank_bool_exp!
  ) {
    update_form(
      where: { id: { _in: $form_ids } }
      _set: { status: Admissions }
    ) {
      affected_rows
    }
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    insert_waitlist(objects: $insert_waitlists) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    update_form_school_rank(
      where: $update_form_school_rank
      _set: { status: null }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_FROM_WAITLIST_BULK_MAX_INPUT_SIZE = 500;
export const REMOVE_FROM_WAITLIST_BULK = gql`
  mutation RemoveFromWaitlistsBulk($where: waitlist_bool_exp!) {
    update_waitlist(_set: { status: Removed }, where: $where) {
      affected_rows
    }
  }
`;
