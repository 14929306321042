import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Card } from "src/components/Layout/Card";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { answerIsEmpty } from "src/services/formTemplate/answer";
import * as AF from "src/types/formTemplate";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import { HasuraRole } from "src/types/hasuraRole";
import { OutdatedAnswerAlert } from "../Inputs/QuestionDynamicInputs/CustomQuestion/modules/form/OutdatedAnswerAlert";
import { NoAnswerText } from "./QuestionAnswer";

type CustomQuestionAnswerProps = {
  formId: uuid;
  questionId: uuid;
  nestedQuestions: ClonedQuestion<WithId>[];
  answer: Record<string, string>;
  referenceId?: string;
  questionColor?: string;
  questionFontWeight?: string;
  answerColor?: string;
  answerFontWeight?: string;
};

export const CustomQuestionAnswer: React.FC<CustomQuestionAnswerProps> = ({
  formId,
  questionId,
  nestedQuestions,
  answer,
  referenceId,
  questionColor,
  questionFontWeight,
  answerColor,
  answerFontWeight,
}) => {
  const hasAdminPermission = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.DISTRICT_ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.SCHOOL_ADMIN,
  ]);

  const { isOpen: outdatedAlertIsOpen, onClose: outdatedAlertOnClose } =
    useDisclosure({
      defaultIsOpen: true,
    });

  const nestedQuestionsAndAnswers = useMemo(
    () =>
      nestedQuestions.map((currentQuestion) => {
        const { id, question, type } = currentQuestion;
        const answerValue = answer[id];
        const answerText =
          type === AF.SingleSelectType
            ? currentQuestion.options.find(
                (option) => option.id === answerValue
              )?.label
            : answerValue;
        return { id, question, answer: answerText };
      }),
    [answer, nestedQuestions]
  );

  const hasAnswer = useMemo(() => !answerIsEmpty(answer), [answer]);

  return (
    <Box>
      {hasAdminPermission && (
        <Text fontSize="sm" color="gray.500" fontWeight="400">
          <Text as="span">
            External Ref. ID:{" "}
            {referenceId ?? (
              <Text as="span" fontStyle="italic" color="gray.400">
                No external Ref. ID
              </Text>
            )}
          </Text>
        </Text>
      )}
      {hasAdminPermission && outdatedAlertIsOpen && (
        <Box my={2}>
          <OutdatedAnswerAlert
            formId={formId}
            questionId={questionId}
            nestedQuestions={nestedQuestions}
            closeAlert={outdatedAlertOnClose}
            refetchAnswers
          />
        </Box>
      )}
      {hasAnswer ? (
        <Card showBorder padding={4}>
          <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
            {nestedQuestionsAndAnswers.map(({ id, question, answer }) => {
              return (
                <GridItem key={id}>
                  <Flex direction="column" gap={2}>
                    <Text
                      fontSize="sm"
                      color={questionColor}
                      fontWeight={questionFontWeight}
                      whiteSpace="pre-wrap"
                    >
                      {question}
                    </Text>
                    {answer ? (
                      <Text color={answerColor} fontWeight={answerFontWeight}>
                        {answer}
                      </Text>
                    ) : (
                      <NoAnswerText
                        answerColor={answerColor}
                        answerFontWeight={answerFontWeight}
                      />
                    )}
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>
        </Card>
      ) : (
        <NoAnswerText />
      )}
    </Box>
  );
};
