import { Input, InputProps } from "@chakra-ui/input";
import { CustomFormInput, CustomFormInputProps } from "./CustomFormInput";

export type FormInputProps = InputProps & {
  error?: string;
  label?: string;
  flex?: string | number;
  isRequired?: boolean;
  customFormInputProps?: Omit<
    CustomFormInputProps,
    "children" | "error" | "label" | "flex" | "isRequired"
  >;
};

export const FormInput: React.FC<FormInputProps> = ({
  customFormInputProps,
  ...props
}) => (
  <CustomFormInput
    error={props.error}
    label={props.label}
    flex={props.flex}
    isRequired={props.isRequired}
    id={props.id}
    {...customFormInputProps}
  >
    <Input id={props.id} {...props} />
  </CustomFormInput>
);
