import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { UPLOAD_RELATIONSHIPS } from "../graphql/mutations";
import { BulkUploadInput } from "../Inputs/BulkUploadInput";

const Relationship = z.object({
  first_person: z.string().nullish(),
  second_person: z.string().nullish(),
});

type RelationshipRow = z.infer<typeof Relationship>;

function isValid(data: unknown): data is RelationshipRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

function isValidRow(data: unknown): data is RelationshipRow {
  const { success } = Relationship.safeParse(data);
  return success;
}

export const UploadRelationships: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const toast = useToast();

  const [UploadRelationships] = useRemoteDataMutation<
    GQL.UploadRelationships,
    GQL.UploadRelationshipsVariables
  >(UPLOAD_RELATIONSHIPS);

  const handleUpload = async (data: unknown[]) => {
    let relationshipArray: RelationshipRow[];
    try {
      relationshipArray = data
        .map((row) => Relationship.parse(row))
        .filter((row) => row.first_person && row.second_person);
    } catch (e) {
      toast({
        id: "parsing-csv",
        title: "Error parsing CSV",
        description: `We were unable to parse the CSV: ${e}`,
        isClosable: true,
        status: "error",
      });
      return;
    }

    if (relationshipArray.length > 0) {
      try {
        await UploadRelationships({
          variables: {
            relationships: relationshipArray,
          },
        });
        toast({
          id: "upload-relationship-success",
          title: "Relationships uploaded",
          isClosable: true,
          status: "success",
        });
      } catch (e) {
        toast({
          id: "upload-relationship-error",
          title: "Error uploading relationships",
          description: "Error: invalid ids",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      {children}
    </BulkUploadInput>
  );
};
