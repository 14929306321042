import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  GetCustomQuestionType,
  GetCustomQuestionTypeVariables,
} from "src/types/graphql";
import { GET_CUSTOM_QUESTION_TYPE } from "./graphql/queries";

export function useGetCustomQuestionTypeQuery(
  variables: GetCustomQuestionTypeVariables
) {
  const { remoteData } = useRemoteDataQuery<
    GetCustomQuestionType,
    GetCustomQuestionTypeVariables
  >(GET_CUSTOM_QUESTION_TYPE, { variables });

  return remoteData.map((data) => {
    return data.custom_question_type_by_pk;
  });
}
