import { useCallback } from "react";
import * as GQL from "src/types/graphql";
import { SetVisibilityMenu } from "./SetVisibilityMenu";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { GET_FORM_VIEW_BY_ID } from "../graphql/queries";
import { UPDATE_FORM_VISIBILITY_BY_ID } from "../graphql/mutations";
import { useAvelaToast } from "src/hooks/useAvelaToast";

interface SetVisibilityMenuSingleProps {
  formVisibility: boolean | null;
  formId: string;
}

export function SetVisibilityMenuSingle({
  formVisibility,
  formId,
}: SetVisibilityMenuSingleProps) {
  const toast = useAvelaToast();
  const [UpdateFormVisibilityById] = useRemoteDataMutation<
    GQL.UpdateFormVisibilityById,
    GQL.UpdateFormVisibilityByIdVariables
  >(UPDATE_FORM_VISIBILITY_BY_ID, { refetchQueries: [GET_FORM_VIEW_BY_ID] });

  const handleVisibilityChange = useCallback(
    async (visibility: boolean) => {
      try {
        await UpdateFormVisibilityById({
          variables: {
            form_id: formId,
            visibility: visibility,
          },
        });

        if (!toast.isActive(formId)) {
          toast({
            id: formId,
            title: "Visibility updated",
            isClosable: true,
          });
        }
      } catch (error) {
        console.error(error);
        if (!toast.isActive(formId)) {
          toast({
            id: formId,
            title: "Error updating visibility",
            status: "error",
            isClosable: true,
          });
        }
      }
    },
    [UpdateFormVisibilityById, formId, toast]
  );
  return (
    <SetVisibilityMenu
      formVisibility={formVisibility}
      onVisibilityChange={handleVisibilityChange}
    />
  );
}
