import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Progress,
  Text,
} from "@chakra-ui/react";
import { Cell, ColumnDef, Header, Row } from "@tanstack/table-core";
import { groupBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { RiAddLine, RiClipboardLine, RiCloseLine } from "react-icons/ri";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { BannerSelection } from "src/components/Table/BannerSelection";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { usePaginationParams } from "src/hooks/useCommonSearchParams";
import { Selection } from "src/hooks/useMultiselectState";
import { checkboxColumnDef } from "src/hooks/useTableSelection";
import { csvExport } from "src/services/dataTransfer";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { GradeProgramCapacity } from "./types";

interface Props {
  data: GQL.GetSchoolGradeCapacity;
  isLoading: boolean;
  selection: Selection<string, GradeProgramCapacity>;
  onSelectionChange: (
    selection: Selection<string, GradeProgramCapacity>
  ) => void;
}

export const CapacityList: React.FC<Props> = ({
  data,
  isLoading,
  selection,
  onSelectionChange,
}) => {
  const {
    pagination: { offset, limit },
    setPagination,
  } = usePaginationParams();
  const programGroups =
    data.enrollment_period_by_pk?.organization?.program_groups;
  const [selectedProgramGroup, setSelectedProgramGroup] = useState<{
    id: uuid;
    name: string;
  }>();
  const HAS_PROGRAMS = programGroups && programGroups.length > 0;

  useEffect(() => {
    onSelectionChange(selection.clear());
    setPagination(limit, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgramGroup]);

  const capacityData =
    selectedProgramGroup || !HAS_PROGRAMS
      ? // Currently, we only support one program group per grade, so for
        // simplicity we'll assume that if a program group is selected it matches
        // the existing grade breakdown.
        data.grade.map(toGradeProgramCapacity)
      : Object.values(groupBy(data.grade, (grade) => grade.grade_config.id))
          .map(toGradeCapacity)
          .filter(isNotNull);
  const count = capacityData.length;
  const tableData = capacityData.slice(offset, offset + limit);

  const columns: ColumnDef<GradeProgramCapacity>[] = useMemo(
    () => [
      checkboxColumnDef({
        id: "is_selected",
        selection,
        onSelectionChange,
        count,
      }),
      {
        header: "grade",
        cell: ({ row }) => <Text fontSize="md">{row.original.gradeLabel}</Text>,
      },
      ...(programGroups?.length
        ? [
            {
              id: "program",
              header: () =>
                selectedProgramGroup ? (
                  <>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="ghost"
                        size="xs"
                        rightIcon={<ChevronDownIcon />}
                        marginLeft={-2} // Matches the padding of the button.
                        textTransform="uppercase"
                      >
                        {selectedProgramGroup.name}
                      </MenuButton>
                      <MenuList textTransform="none">
                        <MenuGroup title="Set program" fontSize="xs">
                          {programGroups &&
                            programGroups.map((group) => (
                              <MenuItem
                                key={group.id}
                                onClick={() => setSelectedProgramGroup(group)}
                              >
                                {group.name}
                              </MenuItem>
                            ))}
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                    <IconButton
                      icon={<RiCloseLine />}
                      aria-label="collapse programs"
                      variant="ghost"
                      size="xs"
                      onClick={() => setSelectedProgramGroup(undefined)}
                    />
                  </>
                ) : (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<RiAddLine />}
                      variant="ghost"
                      size="xs"
                    />
                    <MenuList textTransform="none">
                      <MenuGroup title="Add program" fontSize="xs">
                        {programGroups &&
                          programGroups.map((group) => (
                            <MenuItem
                              key={group.id}
                              onClick={() => setSelectedProgramGroup(group)}
                            >
                              {group.name}
                            </MenuItem>
                          ))}
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                ),
              cell: ({ row }: { row: Row<GradeProgramCapacity> }) =>
                selectedProgramGroup && (
                  <Text fontSize="md">{row.original.programLabel}</Text>
                ),
            },
          ]
        : []),
      {
        accessorKey: "waitlistedCount",
        header: "waitlisted",
        size: 120,
        cell: ({ row, getValue }) => (
          <Text fontSize="md" align="right">
            {getValue()}
          </Text>
        ),
      },
      {
        accessorKey: "offeredCount",
        header: "offered",
        size: 120,
        cell: ({ row, getValue }) => (
          <Text fontSize="md" align="right">
            {getValue()}
          </Text>
        ),
      },
      {
        accessorKey: "acceptedCount",
        header: "accepted",
        size: 120,
        cell: ({ row, getValue }) => (
          <Text fontSize="md" align="right">
            {getValue()}
          </Text>
        ),
      },
      {
        accessorKey: "totalSeats",
        header: "total seats",
        size: 120,
        cell: ({ row, getValue }) => (
          <Text fontSize="md" align="right">
            {getValue()}
          </Text>
        ),
      },
      {
        accessorKey: "availableSeats",
        header: "available",
        size: 120,
        cell: ({ row, getValue }) => (
          <HStack>
            <Progress
              width="100%"
              colorScheme="gray"
              size="xs"
              value={getAvailableSeatsPercentage(row.original)}
            />
            <Text fontSize="md">{getValue()}</Text>
          </HStack>
        ),
      },
    ],
    [count, onSelectionChange, selection, programGroups, selectedProgramGroup]
  );

  const toast = useAvelaToast();

  const rowStyleProps = (row: Row<GradeProgramCapacity>) => {
    const programGroup = tableData.filter(
      (grade) => grade.gradeId === row.original.gradeId
    );

    const isLastChild =
      programGroup.length > 1 &&
      programGroup[programGroup.length - 1]?.id === row.original.id;

    if (isLastChild)
      return {
        borderBottom: "3px solid #E2E8F0",
      };
    return {};
  };

  const headerStyleProps = (header: Header<GradeProgramCapacity, unknown>) => {
    if (header.id === "program")
      return {
        borderRight: "1px solid #E2E8F0",
      };
    header.headerGroup.headers.forEach((siblingHeader) => {
      if (siblingHeader.id === "program") {
        return {
          borderRight: "none",
        };
      }
    });

    if (header.id === "grade")
      return {
        borderRight: "1px solid #E2E8F0",
      };
    return {
      borderRight: "none",
    };
  };

  const cellStyleProps = (cell: Cell<GradeProgramCapacity, unknown>) => {
    if (cell.column.id === "grade" && !cell.row.original.programId) {
      return {
        borderRight: "1px solid #E2E8F0",
      };
    }
    if (cell.column.id === "program")
      return {
        borderRight: "1px solid #E2E8F0",
      };
    return {
      borderRight: "none",
    };
  };

  return tableData.length ? (
    <PaginatedTable<GradeProgramCapacity>
      data={tableData}
      columns={columns}
      isLoading={isLoading}
      count={count}
      offset={offset}
      limit={limit}
      onFetchMore={setPagination}
      tablePadding="0"
      getRowStyleProps={rowStyleProps}
      getHeaderStyleProps={headerStyleProps}
      getCellStyleProps={cellStyleProps}
      banner={() => (
        <BannerSelection selection={selection}>
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<Icon as={DownloadIcon} />}
            onClick={async () => {
              const loadingToastId = toast({
                title: `Export in progress`,
                description: `Download will start shortly`,
                status: "loading",
              });
              try {
                const records = (
                  await selection.materialize(async () => capacityData)
                ).map((record) => {
                  return {
                    ...((selectedProgramGroup || !HAS_PROGRAMS) && {
                      ID: record.id,
                    }),
                    "School ID": record.schoolId,
                    "School Name": record.schoolName,
                    "Grade ID": record.gradeId,
                    "Grade Label": record.gradeLabel,
                    ...(selectedProgramGroup && {
                      "Program ID": record.programId,
                      "Program Label": record.programLabel,
                    }),
                    Waitlisted: record.waitlistedCount,
                    Offered: record.offeredCount,
                    Accepted: record.acceptedCount,
                    "Total Seats": record.totalSeats,
                    "Available Seats": record.availableSeats,
                  };
                });
                csvExport(records, "capacities.csv");
                toast.close(loadingToastId);
                toast({
                  title: `Capacities exported`,
                  status: "success",
                });
              } catch (e: any) {
                toast.close(loadingToastId);
                toast.error({
                  title: "Something went wrong!",
                  description: "Check your network and try again",
                });
                console.error(e);
              }
            }}
          >
            Export
          </Button>
        </BannerSelection>
      )}
    />
  ) : (
    <NoDataTable icon={RiClipboardLine} text="No grades found" />
  );
};

function toGradeProgramCapacity(
  grade: GQL.GetSchoolGradeCapacity_grade
): GradeProgramCapacity {
  return {
    id: grade.id,
    schoolId: grade.school.id,
    schoolName: grade.school.name,
    gradeId: grade.grade_config.id,
    gradeLabel: grade.grade_config.label,
    programId: grade.program?.id ?? null,
    programLabel: grade.program?.label ?? null,
    totalSeats: grade.capacity ?? 0,
    acceptedCount: grade.accepted_offers_aggregate.aggregate?.count ?? 0,
    offeredCount: grade.offered_offers_aggregate.aggregate?.count ?? 0,
    waitlistedCount: grade.waitlists_aggregate.aggregate?.count ?? 0,
    availableSeats: grade.seats_available ?? 0,
  };
}

function sum(xs: (number | null | undefined)[]) {
  return xs.reduce<number>((s, x) => s + (x ?? 0), 0);
}

// This is only used for aggregated state when there are programs
function toGradeCapacity(
  grades: GQL.GetSchoolGradeCapacity_grade[]
): GradeProgramCapacity | null {
  const grade = grades[0];
  if (!grade) return null;
  return {
    id: grade.grade_config.id,
    schoolId: grade.school.id,
    schoolName: grade.school.name,
    gradeId: grade.grade_config.id,
    gradeLabel: grade.grade_config.label,
    programId: null,
    programLabel: null,
    totalSeats: sum(grades.map((g) => g.capacity)),
    acceptedCount: sum(
      grades.map((g) => g.accepted_offers_aggregate.aggregate?.count)
    ),
    offeredCount: sum(
      grades.map((g) => g.offered_offers_aggregate.aggregate?.count)
    ),
    // Currently, waitlisted forms are duplicated across programs, so
    // just take any one.  This might change in the future.
    waitlistedCount: Math.max(
      ...grades
        .map((g) => g.waitlists_aggregate.aggregate?.count)
        .filter(isNotNull)
    ),
    availableSeats: sum(grades.map((g) => g.seats_available)),
  };
}

function getAvailableSeatsPercentage(grade: GradeProgramCapacity): number {
  if (!grade.totalSeats) return 100;

  const total = grade.totalSeats;
  const available = grade.availableSeats;

  return (100 * (total - available)) / total;
}
