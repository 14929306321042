import { Box, Flex, FormLabel, Switch } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import {
  FormType,
  getQuestionLinkFormValue,
  QuestionLinkType,
} from "src/components/Form/QuestionForm/formik";
import { FormInput } from "src/components/Inputs/FormInput";

enum QuestionLinkEnum {
  text = "text",
  url = "url",
}
type Props = { questionId: uuid };
export const QuestionLink: React.FC<Props> = ({ questionId }) => {
  const form = useFormikContext<FormType>();
  const questionLink = getQuestionLinkFormValue(questionId, form.values);

  const handleInputChange = React.useCallback(
    (value: string, field: QuestionLinkEnum) => {
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        questionLinks: {
          ...form.touched.questionLinks,
          [questionId]: {
            ...form.touched.questionLinks?.[questionId],
            [field]: true,
          },
        },
      });
      form.setValues((values) => {
        return {
          ...values,
          questionLinks: {
            ...values.questionLinks,
            [questionId]: {
              ...questionLink,
              [field]: value,
            },
          },
        };
      }, true);
    },
    [form, questionId, questionLink]
  );

  if (questionLink.type === "disabled") {
    return null;
  }

  const textTouched = form.touched.questionLinks?.[questionId]?.text;
  const textError = form.errors.questionLinks?.[questionId]?.text;

  const urlTouched = form.touched.questionLinks?.[questionId]?.url;
  const urlError = form.errors.questionLinks?.[questionId]?.url;

  return (
    <Flex direction="column" gap="3">
      <FormInput
        label="Question link text"
        value={questionLink.text ?? ""}
        onChange={(event) =>
          handleInputChange(event.target.value, QuestionLinkEnum.text)
        }
        error={textTouched ? textError : undefined}
      />
      <FormInput
        label="Question link URL"
        value={questionLink.url ?? ""}
        onChange={(event) =>
          handleInputChange(event.target.value, QuestionLinkEnum.url)
        }
        error={urlTouched ? urlError : undefined}
      />
    </Flex>
  );
};

export const QuestionLinkSwitch: React.FC<Props> = ({ questionId }) => {
  const form = useFormikContext<FormType>();
  const questionLink = getQuestionLinkFormValue(questionId, form.values);
  const [value, setValue] = useState<QuestionLinkType>(questionLink.type);

  const onChangeHandler = React.useCallback(() => {
    const nextValue = value === "disabled" ? "checked" : "disabled";

    if (nextValue === "disabled") {
      form.setValues((values) => {
        return {
          ...values,
          questionLinks: {
            ...values.questionLinks,
            [questionId]: {
              type: nextValue,
              text: "",
              url: "",
            },
          },
        };
      }, false);
    }

    form.setValues((values) => {
      return {
        ...values,
        questionLinks: {
          ...values.questionLinks,
          [questionId]: {
            type: nextValue,
            text: questionLink.text ?? "",
            url: questionLink.url ?? "",
          },
        },
      };
    }, false);

    setValue(nextValue);
  }, [form, questionId, questionLink.text, questionLink.url, value]);

  return (
    <FormLabel
      htmlFor={`questionLinkSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`questionLinkSwitch${questionId}`}
        isChecked={value !== "disabled"}
        onChange={onChangeHandler}
      />
      <Box>Show link for question</Box>
    </FormLabel>
  );
};
