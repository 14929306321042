import {
  Flex,
  FormLabel,
  IconButton,
  Select,
  SelectProps,
} from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
  onChange: (currentPage: number, pageSize: number) => void;
};

type ItemSizeSelectProps = SelectProps & {
  selected: number;
  onChanged: (selected: number) => void;
};

type PageSelectProps = {
  currentPage: number;
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
  onChange: (page: number) => void;
};

const PageSelect = ({
  currentPage,
  totalPages,
  onChange,
  hasPrevious,
  hasNext,
}: PageSelectProps) => (
  <Flex direction="row" alignItems="center" gap={2}>
    <IconButton
      borderRadius="9999px"
      aria-label="Previous"
      variant="outline"
      icon={<FaAngleLeft />}
      onClick={() => onChange(currentPage - 1)}
      isDisabled={!hasPrevious}
    />
    <Select
      id="PageSelect"
      fontWeight={700}
      value={currentPage}
      onChange={(e: { target: { value: any } }) =>
        onChange(Number(e.target.value))
      }
    >
      {[...Array(totalPages)].map((e, i) => (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      ))}
    </Select>
    <FormLabel
      htmlFor="PageSelect"
      aria-label={`of ${totalPages}`}
      margin="0"
      flexShrink="0"
    >
      of {totalPages}
    </FormLabel>
    <IconButton
      borderRadius="full"
      aria-label="next"
      variant="outline"
      icon={<FaAngleRight />}
      onClick={() => onChange(currentPage + 1)}
      isDisabled={!hasNext}
    />
  </Flex>
);

const ItemSizeSelect = ({
  selected,
  onChanged,
  ...props
}: ItemSizeSelectProps) => {
  return (
    <Flex direction="row" alignItems="center" gap={2}>
      <FormLabel htmlFor="ItemSizeSelect" margin="0" flexShrink="0">
        Items per page
      </FormLabel>
      <Select
        id="ItemSizeSelect"
        value={selected}
        onChange={(e: { target: { value: any } }) =>
          onChanged(Number(e.target.value))
        }
        {...props}
      >
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        <option value={500}>500</option>
      </Select>
    </Flex>
  );
};

export function Pagination({
  currentPage,
  totalPages,
  pageSize,
  hasNext,
  hasPrevious,
  onChange,
}: PaginationProps) {
  return (
    <Flex gap="4rem">
      <ItemSizeSelect
        selected={pageSize}
        onChanged={(value) => onChange(1, Number(value))}
      />
      <PageSelect
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={(page: number) => onChange(page, pageSize)}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
      />
    </Flex>
  );
}
