import { makeVar } from "@apollo/client";
import * as GQL from "src/types/graphql";

/**
 * Local state to store the list of deleted ranked school ids. This is used for communication between StepPreRanking component and StepRankSchool component.
 */
export const deletedRankedSchoolsVar = makeVar<uuid[]>([]);

/**
 * Local state to keep track of the current ranked schools list. This is used as a workaround the issue with stale data when working with graphql data directly (possible due to caching?)
 */
export const rankedSchoolsVar = makeVar<GQL.GetSchoolsRank_form_school_rank[]>(
  []
);

export type RankedSchoolEdit = {
  before: uuid[];
  after: uuid[];
};
/**
 * Keep track of changes on ranked schools so we can calculate any new school specifics question
 */
export const rankedSchoolsEditVar = makeVar<RankedSchoolEdit | null>(null);
