import { Text } from "@chakra-ui/react";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";

type QuestionTitleProps = {
  question: Draft.Question;
};
export const QuestionTitle: React.FC<QuestionTitleProps> = ({ question }) => {
  return (
    <Text fontSize="lg">
      <Text
        as="span"
        color="blackAlpha.800"
        fontWeight="500"
        whiteSpace="pre-wrap"
      >
        {question.question}
      </Text>

      {!!question.key && (
        <>
          <Text as="span" color="blackAlpha.600" fontWeight="500">
            {" | "}Question key:{" "}
          </Text>

          <Text as="span" color="blackAlpha.600" fontWeight="400">
            {question.key}
          </Text>
        </>
      )}
    </Text>
  );
};
