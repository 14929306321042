import * as React from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

export interface DeleteConfirmationProps {
  isOpen: boolean;
  header?: string;
  body?: React.ReactNode;
  cancelLabel?: string;
  deleteLabel?: string;
  onDelete: () => void;
  onCancel: () => void;
}

/**
 * @deprecated Use useDeleteConfirmationDialog hooks instead
 */
export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  isOpen,
  header = "Delete Confirmation",
  body = "Are you sure? You can't undo this action afterwards.",
  cancelLabel = "Cancel",
  deleteLabel = "Delete",
  onDelete,
  onCancel,
}) => {
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      header={header}
      body={body}
      cancelButton={{ label: cancelLabel }}
      confirmButton={{ label: deleteLabel, colorScheme: "red" }}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  );
};
