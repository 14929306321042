import React from "react";
import { UPDATE_PREFERRED_LANGUAGE } from "../plugins/weglot/components/graphql/mutations";
import * as GQL from "../types/graphql";
import { useRemoteDataMutation } from "./useRemoteDataMutation";
import { useUserProfile } from "./useUserProfile";

export type PreferredLanguageSetting = {
  preferredLanguage: string | undefined;
  savePreferredLanguage?: (language: string) => void;
};

/**
 * Get preferred language, we will first retrieve preferred language from database,
 * if that doesn't exists then we will use browser's preferred language, and save it to database.
 * We will also save the preferred language when user manually switch the language through language selector dropdown.
 * Note: This hook can only be used in components that are accessed by an authenticated user.
 */
export const usePreferredLanguage: () => PreferredLanguageSetting = () => {
  const userProfile = useUserProfile();
  const [update] = useRemoteDataMutation<
    GQL.UpdatePreferredLanguage,
    GQL.UpdatePreferredLanguageVariables
  >(UPDATE_PREFERRED_LANGUAGE);
  const [preferredLanguage, setPreferredLanguage] = React.useState<
    string | undefined
  >(undefined);

  const savePreferredLanguage = React.useCallback(
    (language: string) => {
      if (!userProfile.hasData()) {
        return;
      }

      try {
        update({
          variables: {
            person_id: userProfile.data.id,
            preferred_language: language,
          },
        });
      } catch (err) {
        // Just log error and fail silently when we can't persist the preferred language
        console.error(err);
      }
    },
    [update, userProfile]
  );

  React.useEffect(() => {
    if (!userProfile.hasData()) {
      return;
    }

    const userProfilePreferredLanguage = userProfile.data.preferred_language;
    const browserPreferredLanguage = toLanguageCode(navigator.language);

    if (userProfilePreferredLanguage !== null) {
      setPreferredLanguage(userProfilePreferredLanguage);
    } else if (browserPreferredLanguage) {
      setPreferredLanguage(browserPreferredLanguage);
      savePreferredLanguage(browserPreferredLanguage);
    }
  }, [preferredLanguage, savePreferredLanguage, userProfile]);

  return { preferredLanguage, savePreferredLanguage };
};

/**
 * Use this hook to set preferred language for components that don't require authentication.
 * This needs to be a separate hook from usePreferredLanguage
 * because graphql calls can only be made inside ApolloProvider.
 */
export const usePreferredLanguageBrowserOnly: () => PreferredLanguageSetting =
  () => {
    const [preferredLanguage, setPreferredLanguage] = React.useState<
      string | undefined
    >(undefined);

    React.useEffect(() => {
      const browserPreferredLanguage = toLanguageCode(navigator.language);
      if (browserPreferredLanguage) {
        setPreferredLanguage(browserPreferredLanguage);
      }
    }, [preferredLanguage]);

    return { preferredLanguage };
  };

function toLanguageCode(language: string): string | undefined {
  // based on list of available languages https://weglot.com/documentation/available-languages/
  const lowerCaseLanguage = language.toLocaleLowerCase();
  if (lowerCaseLanguage.startsWith("pt-br")) {
    return "pt-br";
  }

  return lowerCaseLanguage.split("-")[0];
}
