import { HStack, VStack } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { RiClipboardLine, RiFilter3Line } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { getCurrentFormImport } from "src/components/FormImport/state/localStorage";
import { SearchInput } from "src/components/Inputs/SearchInput";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import {
  FormTabsTypes,
  SearchAndFilterTypes,
  VisibilityOptions,
} from "src/constants";
import { EnrollmentPeriodIdProvider } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useFormsList } from "src/scenes/orgAdmin/forms/components/useFormsList";
import * as RD from "src/types/remoteData";
import { FormsList } from "../FormsList";
import { FormSearchContext } from "../context";
import { FormFilters } from "../formFilters/FormFilters";
import { FormImportErrorsDialog } from "./FormImportErrorsDialog";

export const ImportedForms = (): React.ReactElement | null => {
  const { formImportId = "", enrollmentPeriodId = "" } =
    getCurrentFormImport() ?? {};
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const { setSelectedEnrollmentPeriodId } = useEnrollmentPeriod();

  useEffect(() => {
    // ensure that the enrollment period for the currentFormImport matches the enrollment period in the window location
    // this prevents users from getting stuck on the ImportedForms component without any visible data when imported form data
    // should be visible
    if (
      searchParams.get(SearchAndFilterTypes.EnrollmentPeriod) !==
      enrollmentPeriodId
    ) {
      setSelectedEnrollmentPeriodId(enrollmentPeriodId);
    }
  }, [searchParams, enrollmentPeriodId, setSelectedEnrollmentPeriodId]);

  const {
    remoteData,
    pagination,
    setPagination,
    selection,
    setSelection,
    search,
    filter,
    formsList,
  } = useFormsList({
    additionalSearch: {
      form: {
        form_import_rows: {
          form_import_id: {
            _eq: formImportId,
          },
        },
      },
    },
    enrollmentPeriodId,
    searchParamDefaultOverrides: {
      [SearchAndFilterTypes.Visibility]: [
        VisibilityOptions.NotVisible,
        VisibilityOptions.Visible,
      ].join("_"),
    },
  });

  const { formImportRowAggregates } = useFormImportFlow();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showErrors, setShowErrors] = useState(true);

  const dismissError = useCallback(() => {
    setShowErrors(false);
  }, []);

  let alertContent: ReactNode;
  if (
    formImportRowAggregates.hasData() &&
    formImportRowAggregates.data.failed > 0 &&
    showErrors
  ) {
    const errorCount = formImportRowAggregates.data.failed;

    alertContent = (
      <>
        <Alert status="warning" color="orange.900">
          <AlertIcon />

          <AlertDescription flexGrow={1}>
            {errorCount === 1
              ? `${errorCount} error creating forms`
              : `${errorCount} errors creating forms`}

            {" - "}

            <Button
              color="orange.900"
              display="inline-block"
              fontWeight="bold"
              onClick={onOpen}
              textDecoration="underline"
              variant="link"
            >
              See details
            </Button>
          </AlertDescription>

          <CloseButton alignSelf="flex-start" onClick={dismissError} />
        </Alert>

        <FormImportErrorsDialog
          formImportId={formImportId}
          isOpen={isOpen}
          onClose={onClose}
        />
      </>
    );
  }

  return (
    <FormSearchContext.Provider value={search.expression}>
      <EnrollmentPeriodIdProvider enrollmentPeriodId={enrollmentPeriodId}>
        <VStack align="stretch" minBlockSize="100%">
          <HStack py={6}>
            <SearchInput {...search} />
            <Button
              variant="outline"
              leftIcon={<RiFilter3Line />}
              onClick={filter.toggle}
            >
              Filter
            </Button>
          </HStack>

          <HStack alignItems="start" flexGrow={1}>
            <VStack
              alignSelf="stretch"
              gap={5}
              width={filter.isOpen ? "70%" : "100%"}
            >
              {alertContent}

              <GQLRemoteDataView remoteData={remoteData}>
                {({ tableData, formTemplate, tagGroups, count }) => {
                  if (!tableData?.length || !formTemplate) {
                    return (
                      <NoDataTable
                        buttonText="Clear"
                        icon={RiClipboardLine}
                        onButtonAction={formsList.clearAll}
                        text="No forms found"
                      />
                    );
                  } else {
                    return (
                      <FormsList
                        enrollmentPeriodId={enrollmentPeriodId}
                        forms={tableData}
                        formTemplate={formTemplate}
                        limit={pagination.limit}
                        offset={pagination.offset}
                        count={count}
                        selection={selection}
                        onSelectionChange={setSelection}
                        onRefetch={formsList.refetch}
                        onFetchAll={formsList.fetchAll}
                        onFetchAllIds={formsList.fetchAllIds}
                        onFetchByIds={formsList.fetchDetailed}
                        onFetchMore={setPagination}
                        onSort={formsList.sort}
                        isLoading={remoteData.isLoading()}
                        onSelectAll={formsList.selectAll}
                        tabType={FormTabsTypes.Submissions}
                        tagGroups={tagGroups.tag_group}
                        setAttendance={formsList.setAttendance}
                      />
                    );
                  }
                }}
              </GQLRemoteDataView>
            </VStack>

            {filter.isOpen && (
              <FormFilters
                organizationId={organization
                  .map((org) => org.id)
                  .withDefault("")}
                formTemplateRemoteData={remoteData
                  .mapError<Error>(_.identity)
                  .andThen((data) =>
                    data.formTemplate
                      ? RD.success(data.formTemplate)
                      : RD.failure(new Error("Invalid form template"))
                  )}
                onToggleFilter={filter.toggle}
                onClearFilters={filter.clear}
                enrollmentPeriodId={enrollmentPeriodId}
              />
            )}
          </HStack>
        </VStack>
      </EnrollmentPeriodIdProvider>
    </FormSearchContext.Provider>
  );
};
