import { Flex, Heading, Text } from "@chakra-ui/react";
import { FormikContextType, useFormikContext } from "formik";
import React from "react";
import { getGlossaryTypeTouched } from "src/scenes/admin/glossaries/services";
import { CustomLabelType, FormType } from "src/scenes/admin/glossaries/types";
import * as GQL from "src/types/graphql";
import { GlossaryTypeForm } from "./GlossaryTypeForm";

type Props = {};
export const CustomGlossaryForm: React.FC<Props> = () => {
  const formik = useFormikContext<FormType>();
  const onSingularChange = React.useCallback(
    (glossaryType: GQL.glossary_type_enum, value: string) => {
      onChangeHandler(glossaryType, formik, "singular", value);
    },
    [formik]
  );
  const onPluralChange = React.useCallback(
    (glossaryType: GQL.glossary_type_enum, value: string) => {
      onChangeHandler(glossaryType, formik, "plural", value);
    },
    [formik]
  );

  const glossaryTypes = Object.values(GQL.glossary_type_enum);

  return (
    <Flex direction="column" gap="3">
      <Flex direction="column" gap="2">
        <Heading as="h2" fontSize="1.25rem" fontWeight="600">
          Custom labels
        </Heading>
        <Text>
          Custom labels are visible to both families and admins, reflected
          immediately after saving. Statuses and message templates will not be
          affected.
        </Text>
      </Flex>
      {glossaryTypes.map((glossaryType) => {
        return (
          <GlossaryTypeForm
            key={glossaryType}
            glossaryType={glossaryType}
            onSingularChange={onSingularChange}
            onPluralChange={onPluralChange}
          />
        );
      })}
    </Flex>
  );
};

function onChangeHandler(
  glossaryType: GQL.glossary_type_enum,
  formik: FormikContextType<FormType>,
  key: keyof CustomLabelType,
  value: string
) {
  if (formik.values.status === "Standard") {
    return;
  }

  formik.setTouched({
    ...formik.touched,
    labels: {
      ...formik.touched.labels,
      [glossaryType]: {
        ...getGlossaryTypeTouched(glossaryType, formik.touched),
        [key]: true,
      },
    },
  });
  formik.setValues((values) => {
    return {
      ...values,
      labels: {
        ...values.labels,
        [glossaryType]: {
          ...values.labels[glossaryType],
          [key]: value.toLowerCase(),
        },
      },
    };
  });
}
