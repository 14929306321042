import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { Glossaries } from "src/services/url/Admin";
import { Index } from ".";
import { Edit } from "./Edit";

export const AdminGlossariesRoutes = (
  <Route
    path={Glossaries.indexPath}
    element={
      <PreserveState>
        <Index />
      </PreserveState>
    }
  >
    <Route path={Glossaries.editPath} element={<Edit />} />
  </Route>
);
