import { z } from "zod";

export const EnrollmentPeriodFormSchema = z.object({
  name: z.string().min(1, "Enrollment period name is required."),
  active: z.boolean(),
  key: z.string().nullable(),
});

export type EnrollmentPeriodFormType = z.infer<
  typeof EnrollmentPeriodFormSchema
>;
