import { gql } from "@apollo/client";

export const CREATE_PROGRAM_GROUP = gql`
  mutation CreateProgramGroup($program_group: program_group_insert_input!) {
    insert_program_group_one(object: $program_group) {
      id
    }
  }
`;

export const UPDATE_PROGRAM_GROUP = gql`
  mutation UpdateProgramGroup(
    $program_group_id: uuid!
    $program_group_set: program_group_set_input!
    $programs: [program_insert_input!]!
    $programs_to_delete_ids: [uuid!]!
  ) {
    update_program_group_by_pk(
      pk_columns: { id: $program_group_id }
      _set: $program_group_set
    ) {
      id
    }
    insert_program(
      objects: $programs
      on_conflict: { constraint: program_pkey, update_columns: [label, order] }
    ) {
      affected_rows
    }
    delete_program(where: { id: { _in: $programs_to_delete_ids } }) {
      affected_rows
    }
  }
`;

export const DELETE_PROGRAM_GROUP = gql`
  mutation DeleteProgramGroup($id: uuid!) {
    delete_program(where: { program_group_id: { _eq: $id } }) {
      affected_rows
    }
    delete_program_group_by_pk(id: $id) {
      id
    }
  }
`;
