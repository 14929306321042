import { gql } from "@apollo/client";
import { FORM_SUMMARY_FRAGMENT } from "src/operations/fragments/form";
export const GET_IMPORTANT_DATES_BY_ENROLLMENT_PERIOD = gql`
  ${FORM_SUMMARY_FRAGMENT}
  query GetImportantDatesByEnrollmentPeriod($organization_id: uuid!) {
    enrollment_period(
      where: {
        active: { _eq: true }
        organization_id: { _eq: $organization_id }
        deleted_at: { _is_null: true }
      }
    ) {
      id
      active
      name
      form_templates(
        where: { active: { _eq: true }, deleted_at: { _is_null: true } }
      ) {
        id
        name
        active
        open_at
        closed_at
        offer_start_at
        reopen_at
        locked
        sections(where: { type: { _eq: SchoolRankingSection } }) {
          id
        }
        forms(
          where: {
            _or: [
              { is_hidden_from_parent: { _eq: false } }
              { is_hidden_from_parent: { _is_null: true } }
            ]
            deleted_at: { _is_null: true }
          }
        ) {
          ...FormSummaryFragment
        }
      }
    }
  }
`;
