import { Button, InputGroup, Text } from "@chakra-ui/react";
import React from "react";
import { VscJson } from "react-icons/vsc";

interface UploadJsonInputProps {
  onUpload: (data: string) => void;
  buttonLabel?: string;
  label?: string;
}

export const UploadJsonInput: React.FC<UploadJsonInputProps> = ({
  onUpload,
  buttonLabel = "Upload JSON",
  label,
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  const loadJSONHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.currentTarget?.files
      ? event.currentTarget.files[0]
      : undefined;
    if (file === undefined) return;

    const text = await file.text();

    onUpload(text);
  };

  return (
    <>
      {label && <Text mb="10px">{label}</Text>}
      <InputGroup flexBasis="fit-content">
        <input
          type="file"
          accept="*.json"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={loadJSONHandler}
        ></input>
        <Button leftIcon={<VscJson />} onClick={() => fileRef.current?.click()}>
          {buttonLabel}
        </Button>
      </InputGroup>
    </>
  );
};
