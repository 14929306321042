import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import Markdown from "markdown-to-jsx";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";

export interface AnnouncementDialogProps {
  announcement: GQL.FetchAnnouncements_announcement;
}

export const AnnouncementDialog = ({
  announcement,
}: AnnouncementDialogProps) => {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent m={4} className={WEGLOT_APPLY_CLASS}>
        <ModalHeader fontSize="lg">{announcement.title}</ModalHeader>

        <ModalBody>
          <Markdown>{announcement.description}</Markdown>
        </ModalBody>

        <ModalFooter>
          <Flex direction="column" gap={2} w="100%">
            {announcement.link_url && announcement.link_text && (
              <Flex w="100%">
                <Button
                  as={Link}
                  variant="solid"
                  href={announcement.link_url}
                  w="100%"
                  _hover={{ textDecoration: "none" }}
                >
                  {announcement.link_text}
                </Button>
              </Flex>
            )}
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Continue
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
