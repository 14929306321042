import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import * as Turf from "@turf/helpers";
import { geojsonType, getGeom } from "@turf/invariant";
import * as R from "src/types/result";

export * from "@turf/helpers";

export function safeParse(value: unknown): R.Result<Turf.GeoJSONObject> {
  try {
    geojsonType(value, "FeatureCollection", "geojson");
  } catch (e) {
    if (e instanceof Error) {
      return R.failure(e.message);
    }

    return R.failure("Unexpected error in parsing GeoJSON");
  }

  return R.success(value as Turf.GeoJSONObject);
}

export function isFeatureCollection(
  geoJSON: Turf.GeoJSONObject
): geoJSON is Turf.FeatureCollection {
  return geoJSON.type === "FeatureCollection";
}

export function safeGetGeometry(
  geoJSON: Turf.GeoJSONObject,
  geoJsonProperty: { key: string; value: string }
): R.Result<Turf.Geometry> {
  if (!isFeatureCollection(geoJSON)) {
    return R.failure(
      `Unsupported GeoJSON type: ${geoJSON.type}. We currently only support FeatureCollection`
    );
  }

  const feature = geoJSON.features.find((feature) => {
    const propertyValue = feature.properties?.[geoJsonProperty.key];
    return propertyValue === geoJsonProperty.value;
  });

  if (feature === undefined) {
    return R.failure(`Unable to get geometry for ${geoJsonProperty.value}`);
  }

  return R.success(getGeom(feature as any)); // I think  the type definition for getGeom is wrong here.
}

export function isInside(
  geometry: Turf.Geometry,
  location: Turf.Position
): R.Result<boolean> {
  const { type } = geometry;

  switch (type) {
    case "Polygon":
      return geometryToPolygon(geometry).map((polygon) => {
        return booleanPointInPolygon(location, polygon);
      });
    case "MultiPolygon":
      return geometryToMultiPolygon(geometry).map((multiPolygon) => {
        return booleanPointInPolygon(location, multiPolygon);
      });
    default:
      return R.failure(`Unsupported geometry type: ${geometry.type}`);
  }
}

export function isOutside(
  geometry: Turf.Geometry,
  location: Turf.Position
): R.Result<boolean> {
  return isInside(geometry, location).map((value) => !value);
}

function geometryToPolygon(geometry: Turf.Geometry): R.Result<Turf.Polygon> {
  const coordinates = geometry.coordinates as Turf.Position[][]; // geometry type is Polygon, it's okay to just assert here
  return coordinatesToPaths(coordinates).map((paths) => {
    return Turf.polygon(paths).geometry;
  });
}

function geometryToMultiPolygon(
  geometry: Turf.Geometry
): R.Result<Turf.MultiPolygon> {
  const coordinates = geometry.coordinates as Turf.Position[][][];
  return multiPolygonCoordinatesToPaths(coordinates).map((paths) => {
    return Turf.multiPolygon(paths).geometry;
  });
}

function multiPolygonCoordinatesToPaths(
  coordinates: Turf.Position[][][]
): R.Result<Turf.Position[][][]> {
  return R.join(coordinates.map(coordinatesToPaths));
}

function coordinatesToPaths(
  coordinates: Turf.Position[][]
): R.Result<Turf.Position[][]> {
  return R.join(coordinates.map(positionsToPath));
}

function positionsToPath(
  positions: Turf.Position[]
): R.Result<Turf.Position[]> {
  return R.join(positions.map(positionToPath));
}

export function positionToPath(
  position: Turf.Position
): R.Result<Turf.Position> {
  const [lng, lat] = position;
  if (lng === undefined || lat === undefined) {
    return R.failure(`Invalid position format: ${position}`);
  }

  return R.success([lng, lat]);
}
