import { useNavigate, useSearchParams } from "react-router-dom";
import * as Url from "src/services/url";
import { useOrganization } from "./useOrganization";

export interface NewMessageState {
  parentsIdList: string[];
  studentsIdList: string[];
  formsIdList: string[];
  emailBody?: string;
  smsBody?: string;
}

export function useSendMessage(formTemplateId?: uuid) {
  const navigate = useNavigate();
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const handleSendMessage = (
    parentIds: uuid[] = [],
    studentIds: uuid[] = [],
    formIds: uuid[] = [],
    emailBody?: string,
    smsBody?: string
  ) => {
    navigate(
      organization
        .map((org) =>
          formTemplateId
            ? Url.OrgAdmin.Forms.newMessage({
                organization: org,
                formTemplateId,
                params: searchParams.toString(),
              })
            : Url.OrgAdmin.Messages.new(org, searchParams.toString())
        )
        .withDefault("#"),
      {
        state: {
          parentsIdList: parentIds,
          studentsIdList: studentIds,
          formsIdList: formIds,
          emailBody,
          smsBody,
        } as NewMessageState,
      }
    );
  };

  return { handleSendMessage };
}
