import { useNavigate } from "react-router";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import {
  CREATE_DEFAULT_INTERNAL_TAG_GROUP,
  CREATE_ENROLLMENT_PERIOD,
} from "./graphql/mutations";
import { GET_ENROLLMENT_PERIODS } from "./graphql/queries";
import { GeneralForm } from "./scenes/General/GeneralForm";

export const NewEnrollmentPeriod = () => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const [createEnrollmentPeriod, { remoteData }] = useRemoteDataMutation<
    GQL.CreateEnrollment,
    GQL.CreateEnrollmentVariables
  >(CREATE_ENROLLMENT_PERIOD);

  const [createDefaultInternalTagGroup] = useRemoteDataMutation<
    GQL.CreateDefaultInternalTagGroup,
    GQL.CreateDefaultInternalTagGroupVariables
  >(CREATE_DEFAULT_INTERNAL_TAG_GROUP);

  const [getEnrollmentPeriods, refetchStatus] = useLazyRemoteDataQuery<
    GQL.GetEnrollments,
    GQL.GetEnrollmentsVariables
  >(GET_ENROLLMENT_PERIODS, { fetchPolicy: "network-only" });

  const handleSubmit = async (values: GQL.enrollment_period_insert_input) => {
    organization.do(async (org) => {
      await createEnrollmentPeriod({
        variables: {
          enrollment: {
            ...values,
            organization_id: org.id,
          },
        },
        onCompleted: (data) => {
          if (data.insert_enrollment_period_one) {
            createDefaultInternalTagGroup({
              variables: {
                enrollment_period_id: data.insert_enrollment_period_one.id,
              },
            });
          }
        },
      });
      await getEnrollmentPeriods({
        variables: { organization_id: org.id, order_by: {} },
      });
      navigate(Url.OrgAdmin.Enrollments.index(org));
    });
  };

  return (
    <>
      <Breadcrumb
        items={breadcrumb.enrollmentPeriod.getBreadcrumbsForNew(organization)}
        mb={8}
      />
      <GeneralForm
        onSubmit={handleSubmit}
        submitting={remoteData.isLoading() || refetchStatus.called}
      />
    </>
  );
};
