import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { Tag } from "./types";
import { FormSchema, FormType } from "./Form";
import { TextareaWithValidationControl } from "../Inputs/TextareaWithValidationControl";
import { validateWithZod } from "src/services/formValidations";

type UpdateTagDescriptionProps = {
  isOpen: boolean;
  tag: Tag;
  onEditTagSaving: (values: FormType) => void;
  onClose: () => void;
};
export const UpdateTagDescriptionDialog: React.FC<
  UpdateTagDescriptionProps
> = ({ tag, isOpen, onEditTagSaving, onClose }) => {
  const initialValues = {
    name: tag.name,
    description: tag.description ?? "",
  };

  return (
    <Formik<FormType>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onEditTagSaving}
      validate={validateWithZod(FormSchema)}
    >
      {({ resetForm }) => {
        const onCloseModal = () => {
          resetForm();
          onClose();
        };
        return (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent as={Form}>
              <ModalHeader fontSize="lg">{`Update description for ${tag.name}`}</ModalHeader>
              <ModalBody as={Flex} direction="column" gap={2}>
                <FormControl>
                  <TextareaWithValidationControl
                    id="description"
                    name="description"
                    mb={2}
                  />
                  <Text fontSize="xs" color="gray.600" fontWeight="400">
                    Note this description will be visible to parents if this tag
                    is added an a parent-facing tag group!
                  </Text>
                </FormControl>
              </ModalBody>
              <ModalFooter gap={2}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={onCloseModal}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm">
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
