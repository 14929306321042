import { Link } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import type { OrganizationError } from "src/hooks/useOrganization";
import { formatUuid } from "src/services/id";
import * as Url from "src/services/url";
import type { GlossaryFn } from "src/types/glossary";
import type * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { RelatedFormsColumnIds } from "../helpers";

type Row = {
  id: string;
  form_template: GQL.GetFormsByStudentId_form_form_template;
};

type CellFn<T extends Row> = Exclude<ColumnDef<T>["cell"], undefined | string>;

type FormIdColumnCellProps<T extends Row> = Parameters<CellFn<T>>[0] & {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  formTemplateId: uuid;
  formId: uuid;
};

function FormIdColumnCell<T extends Row>(props: FormIdColumnCellProps<T>) {
  const { organization, formTemplateId, formId } = props;

  const [searchParams] = useSearchParams();

  const url = Url.OrgAdmin.Forms.view({
    organization,
    formTemplateId,
    id: formId,
    params: searchParams.toString(),
  });

  return (
    <Link
      as={RouterLink}
      color="primary.500"
      to={url}
      textDecoration="underline"
    >
      {formatUuid(formId)}
    </Link>
  );
}

type FormIdDefConfig = {
  glossary: GlossaryFn;
  organization: RemoteData<OrganizationError, GQL.Organization>;
};

export function buildFormIdColumnDef<T extends Row>({
  glossary,
  organization,
}: FormIdDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.id,
    id: RelatedFormsColumnIds.FormId,
    header: glossary`form id`,

    cell: (props) => (
      <FormIdColumnCell
        {...props}
        organization={organization}
        formTemplateId={props.row.original.form_template.id}
        formId={props.row.original.id}
      />
    ),
  };
}
