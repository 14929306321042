import { BoxProps, Flex, Spinner } from "@chakra-ui/react";

export function Loading(props: BoxProps) {
  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Spinner color="primary.500" size="lg" />
    </Flex>
  );
}
