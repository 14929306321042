import { gql } from "@apollo/client";

export const GET_BOUNDARY_GROUP_LIST = gql`
  query GetBoundaryGroupList {
    geojson {
      id
      school_boundaries {
        school {
          name
        }
        enrollment_period {
          name
        }
        grade {
          grade_config {
            label
          }
        }
      }
      organization {
        name
      }
    }
  }
`;
