import {
  DocViewerActions,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  RESET_STATE,
  SET_CURRENT_PAGE,
  SET_NUM_PAGES,
  SET_ROTATION,
  SET_ZOOM_LEVEL,
} from "./actions";

export interface DocViewerState {
  zoomLevel: number;
  rotation: number;
  currentPage: number;
  numPages: number;
}

export const initialDocViewerState: DocViewerState = {
  zoomLevel: 1,
  rotation: 0,
  currentPage: 1,
  numPages: 0,
};

export const reducer = (
  state = initialDocViewerState,
  action: DocViewerActions
): DocViewerState => {
  switch (action.type) {
    case SET_ZOOM_LEVEL: {
      const value = action.value;
      const zoomLevel = Math.min(
        Math.max(value, MIN_ZOOM_LEVEL),
        MAX_ZOOM_LEVEL
      );
      return { ...state, zoomLevel };
    }

    case SET_ROTATION: {
      const value = action.value;
      const rotationVal = value < 0 ? 270 : value > 270 ? 0 : value;
      return { ...state, rotation: rotationVal };
    }

    case SET_NUM_PAGES: {
      const value = action.value;
      return { ...state, numPages: value };
    }

    case SET_CURRENT_PAGE: {
      const value = action.value;
      const currentPage = Math.min(Math.max(value, 1), state.numPages);
      return { ...state, currentPage };
    }

    case RESET_STATE: {
      return initialDocViewerState;
    }

    default:
      return state;
  }
};
