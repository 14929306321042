export interface IResult<T> {
  success: boolean;
  map<U>(f: (data: T) => U): Result<U>;
  withDefault<T2>(defaultData: T2): T | T2;
  recoverWithDefault(f: (error: string) => T): T;
  crash(): T;
}

class Success<T> implements IResult<T> {
  readonly success = true;
  constructor(public data: T) {}
  map<U>(f: (data: T) => U): Result<U> {
    return success(f(this.data));
  }
  withDefault<T2>(defaultData: T2): T | T2 {
    return this.data;
  }
  recoverWithDefault(f: (error: string) => T): T {
    return this.data;
  }
  crash(): T {
    return this.data;
  }
}

class Failure<T> implements IResult<T> {
  readonly success = false;
  constructor(public error: string) {}
  map<U>(_f: (data: T) => U): Result<U> {
    return failure(this.error);
  }
  withDefault<T2>(defaultData: T2): T | T2 {
    return defaultData;
  }
  recoverWithDefault(f: (error: string) => T): T {
    return f(this.error);
  }
  crash(): T {
    throw new Error(this.error);
  }
}

export type Result<T> = Success<T> | Failure<T>;

export function success<T>(value: T): Result<T> {
  return new Success(value);
}

export function failure<T>(error: string): Result<T> {
  return new Failure(error);
}

export function join<T>(list: Result<T>[]): Result<T[]> {
  const newList: T[] = [];
  for (const item of list) {
    if (!item.success) {
      return failure(item.error);
    }

    newList.push(item.data);
  }

  return success(newList);
}
