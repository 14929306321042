import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertStatus,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Link,
} from "@chakra-ui/react";
import Immutable from "immutable";
import React from "react";
import { RouterLink } from "../Links/RouterLink";
import { Card, CardProps } from "./Card";

type CustomContent = React.ReactNode;
type ContentLink = {
  type: "link";
  label: string;
  url: string;
  isExternal?: boolean;
};
type ContentButton = {
  type: "button";
  label: string;
  onClick: () => Promise<void>;
};
type Content =
  | CustomContent
  | { description: string; button: ContentButton | ContentLink };

export type CardAlertProps = {
  content: Content;
} & AlertProps;

export type Props = {
  alerts: CardAlertProps[];
  cardProps: Omit<CardProps, "children">;
  colorScheme: string;
} & BoxProps;

const defaultButtonProps: ButtonProps = {
  variant: "outline",
  size: "xs",
  lineHeight: "1",
  backgroundColor: "white",
};

export const CardWithAlert: React.FC<Props> = ({
  alerts,
  cardProps,
  colorScheme,
  children,
  ...boxProps
}) => {
  const [isButtonLoading, setButtonLoading] = React.useState<
    Immutable.Set<number>
  >(Immutable.Set());

  const clickHandler = React.useCallback(
    (button: ContentButton, index: number) => {
      return async () => {
        setButtonLoading((value) => value.add(index));
        try {
          await button.onClick();
        } finally {
          setButtonLoading((value) => value.remove(index));
        }
      };
    },
    []
  );

  return (
    <Box {...boxProps}>
      {alerts.map(({ content, ...alertProps }, index) => {
        let button: React.ReactNode;
        const commonButtonProps: ButtonProps = {
          ...defaultButtonProps,
          colorScheme: getAlertButtonColorScheme(
            alertProps.status,
            colorScheme
          ),
        };
        if (!isCustomContent(content)) {
          switch (content.button.type) {
            case "button":
              button = (
                <Button
                  {...commonButtonProps}
                  onClick={clickHandler(content.button, index)}
                  isLoading={isButtonLoading.has(index)}
                >
                  {content.button.label}
                </Button>
              );
              break;
            case "link":
              if (content.button.isExternal) {
                button = (
                  <Button
                    as={Link}
                    href={content.button.url}
                    isExternal={content.button.isExternal}
                    {...commonButtonProps}
                  >
                    {content.button.label}
                  </Button>
                );
              } else {
                button = (
                  <Button
                    {...commonButtonProps}
                    as={RouterLink}
                    to={content.button.url}
                  >
                    {content.button.label}
                  </Button>
                );
              }
              break;
            default:
          }
        }
        return (
          <Alert
            variant="solid"
            key={index}
            status="success"
            borderTopRadius={6}
            justifyContent="space-between"
            marginBlockEnd={-3} // Make the alert overlap underneath the card below it
            paddingBlockEnd={5}
            paddingBlockStart={2}
            paddingInline={4}
            {...alertProps}
          >
            {isCustomContent(content) ? (
              content
            ) : (
              <>
                <AlertDescription color="white" fontSize="sm" fontWeight="500">
                  {content.description}
                </AlertDescription>
                {button}
              </>
            )}
          </Alert>
        );
      })}
      <Card position="relative" {...cardProps}>
        {children}
      </Card>
    </Box>
  );
};

function isCustomContent(content: Content): content is CustomContent {
  return !(typeof (content as any).description === "string");
}

/**
 * Choose button color in the following order:
 * 1) Match specified alert status color
 * 2) Match card accent color
 * 3) Fallback to status color
 *
 * The provided values must be string representation of color and not hex.
 **/
function getAlertButtonColorScheme(
  alertStatus?: AlertStatus,
  colorScheme?: string
) {
  if (alertStatus !== undefined) {
    return getStatusColorScheme(alertStatus);
  }

  if (colorScheme !== undefined) {
    return colorScheme;
  }

  return getStatusColorScheme("info");
}

const ALERT_STATUSES = {
  info: { colorScheme: "blue" },
  warning: { colorScheme: "orange" },
  success: { colorScheme: "green" },
  error: { colorScheme: "red" },
  loading: { colorScheme: "blue" },
};

function getStatusColorScheme(status: AlertStatus) {
  return ALERT_STATUSES[status].colorScheme;
}
