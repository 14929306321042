import Immutable from "immutable";
import React from "react";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as State from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/state";
import * as Question from "src/services/formTemplate/question";
import { toQuestions } from "src/services/formTemplate/section";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";

export type Action =
  | { type: "UpdateQuestion"; question: Draft.Question }
  | {
      type: "SwitchToExistingVerification";
      question: Draft.Question;
      formVerifications: readonly AF.FormVerification<AF.WithId>[];
    }
  | {
      type: "UpdateDisclaimer";
      disclaimerSection: Draft.Disclaimer;
    }
  | { type: "UpdateSection"; section: Draft.Section }
  | { type: "Load"; json: string }
  | { type: "Refresh"; formTemplate: AF.FormTemplate<AF.WithId> }
  | { type: "Save"; formTemplateId: uuid }
  | { type: "SaveComplete" }
  | { type: "Reset"; formTemplate: AF.FormTemplate<AF.WithId> }
  | { type: "ResetComplete" }
  | {
      type: "AddNewQuestion";
      sectionId: uuid;
      newQuestionId: uuid;
      atIndex: number;
    }
  | {
      type: "RemoveNewQuestion";
      questionId: uuid;
    }
  | {
      type: "UpdateNewQuestion";
      newQuestion: Draft.NewQuestion;
    }
  | {
      type: "AddNewSection";
      newSection: Draft.NewSection;
      atIndex: number;
    }
  | {
      type: "UpdateNewSection";
      newSection: Draft.NewSection;
    }
  | {
      type: "RemoveNewSection";
      sectionId: uuid;
    }
  | {
      type: "AddNewSchoolRanking";
      newSection: Draft.NewSchoolRanking;
      atIndex: number;
    }
  | {
      type: "UpdateSchoolRanking";
      schoolRanking: Draft.SchoolRanking;
    }
  | {
      type: "UpdateNewSchoolRanking";
      schoolRanking: Draft.NewSchoolRanking;
    }
  | {
      type: "RemoveNewSchoolRanking";
      sectionId: uuid;
    };

export function reducer(state: State.State, action: Action): State.State {
  switch (action.type) {
    case "Save":
      return state.set("save", action.formTemplateId);

    case "SaveComplete":
      return state.set("save", undefined);

    case "Load":
      return load(state, action.json);

    case "Reset":
      return reset(state, action.formTemplate);

    case "ResetComplete":
      return state.set("reset", undefined);

    case "UpdateQuestion":
      return updateQuestion(state, action.question);

    case "UpdateDisclaimer":
      return updateDisclaimer(state, action.disclaimerSection);

    case "Refresh":
      return refresh(action.formTemplate);

    case "SwitchToExistingVerification":
      return switchToExistingVerificationInState(
        state,
        action.question,
        action.formVerifications
      );

    case "AddNewQuestion":
      return addNewQuestion(state, action);

    case "RemoveNewQuestion":
      return removeNewQuestion(state, action);

    case "UpdateNewQuestion":
      return updateNewQuestion(state, action.newQuestion);

    case "AddNewSection":
      return addNewSection(state, action);

    case "UpdateNewSection":
      return updateNewSection(state, action.newSection);

    case "RemoveNewSection":
      return removeNewSection(state, action);

    case "UpdateSection":
      return updateSection(state, action.section);

    case "AddNewSchoolRanking":
      return addNewSection(state, action);

    case "UpdateSchoolRanking":
      return updateSection(state, action.schoolRanking);

    case "UpdateNewSchoolRanking":
      return updateNewSection(state, action.schoolRanking);

    case "RemoveNewSchoolRanking":
      return removeNewSection(state, action);

    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

function updateSection(state: State.State, draft: Draft.Section): State.State {
  const originalSectionState = state
    .get("sections")
    .get(State.SectionId(draft.id));
  if (!originalSectionState) {
    return state;
  }

  const original = originalSectionState.original;
  const hasChanges = Draft.hasSectionChanges(original, draft);

  return state.update("sections", (section) =>
    section.set(State.SectionId(draft.id), {
      ...originalSectionState,
      draft: hasChanges ? draft : undefined
    })
  );
}

function addNewSection(
  state: State.State,
  {
    newSection,
    atIndex
  }: {
    newSection: Draft.NewSection;
    atIndex: number;
  }
) {
  return state
    .update("sortedSections", (sortedSections) =>
      sortedSections.insert(atIndex, State.SectionId(newSection.id))
    )
    .update("newSections", (newSections) =>
      newSections.set(State.SectionId(newSection.id), newSection)
    )
    .update("sortedQuestions", (sortedQuestions) =>
      sortedQuestions.set(State.SectionId(newSection.id), Immutable.List())
    );
}

function updateNewSection(state: State.State, newSection: Draft.NewSection) {
  return state.update("newSections", (newSections) =>
    newSections.set(State.SectionId(newSection.id), newSection)
  );
}

function removeNewSection(
  state: State.State,
  { sectionId }: { sectionId: uuid }
) {
  return state
    .update("sortedSections", (sortedSections) =>
      sortedSections.filter((id) => id.get("sectionId") !== sectionId)
    )
    .update("newSections", (newSections) =>
      newSections.remove(State.SectionId(sectionId))
    );
}

function updateNewQuestion(state: State.State, newQuestion: Draft.NewQuestion) {
  return state.update("newQuestions", (newQuestions) => {
    return newQuestions.set(State.QuestionId(newQuestion.id), newQuestion);
  });
}

function addNewQuestion(
  state: State.State,
  {
    sectionId,
    newQuestionId,
    atIndex
  }: {
    sectionId: uuid;
    newQuestionId: uuid;
    atIndex: number;
  }
) {
  return state.update("sortedQuestions", (sortedQuestions) => {
    return sortedQuestions.update(State.SectionId(sectionId), (questionIds) =>
      questionIds?.insert(atIndex, State.QuestionId(newQuestionId))
    );
  });
}

function removeNewQuestion(
  state: State.State,
  { questionId }: { questionId: uuid }
) {
  return state
    .update("sortedQuestions", (sortedQuestions) => {
      return sortedQuestions.map((questionIds) =>
        questionIds?.filter((key) => key.get("questionId") !== questionId)
      );
    })
    .update("newQuestions", (newQuestions) =>
      newQuestions.remove(State.QuestionId(questionId))
    );
}

function switchToExistingVerification(
  question: Draft.Question,
  formVerifications: readonly AF.FormVerification<AF.WithId>[]
): Draft.Question {
  const updatedQuestion = Question.hasOptions(question)
    ? {
        ...question,
        options: question.options.map((option) => ({
          ...option,
          additionalQuestions: option.additionalQuestions?.map(
            (additionalQuestion: Draft.Question) => {
              return switchToExistingVerification(
                additionalQuestion,
                formVerifications
              );
            }
          )
        }))
      }
    : question;

  if (updatedQuestion.type === "Grades") {
    return updatedQuestion;
  }

  const newVerification = updatedQuestion.newFormVerification;
  if (newVerification === undefined) {
    // this question doesn't have new verification. we should keep the existing one.
    return updatedQuestion;
  }

  const formVerification = formVerifications.find(
    (v) => v.label === newVerification.label
  );

  if (formVerification === undefined) {
    // we couldn't find the existing verification!
    throw new Error(
      "Unable to switch to existing verification because it doesn't get created"
    );
  }

  return {
    ...updatedQuestion,
    formVerification: formVerification,
    newFormVerification: undefined
  };
}

function switchToExistingVerificationInState(
  state: State.State,
  parentQuestion: Draft.Question,
  formVerifications: readonly AF.FormVerification<AF.WithId>[]
): State.State {
  if (parentQuestion.isNew) {
    return state.update("newQuestions", (newQuestions) => {
      return newQuestions.update(
        State.QuestionId(parentQuestion.id),
        (newQuestion) => {
          if (!newQuestion) {
            return undefined;
          }

          return Draft.asNew(
            switchToExistingVerification(newQuestion, formVerifications)
          );
        }
      );
    });
  } else {
    return state.update("questions", (questions) => {
      return questions.update(State.QuestionId(parentQuestion.id), (change) => {
        if (!change) {
          return undefined;
        }

        const draft: Draft.Question =
          change.draft ?? Draft.fromOriginalQuestion(change.original);

        const updatedDraft = switchToExistingVerification(
          draft,
          formVerifications
        );

        return {
          ...change,
          draft: updatedDraft
        };
      });
    });
  }
}

function reset(
  state: State.State,
  formTemplate: AF.FormTemplate<AF.WithId>
): State.State {
  const questions = state.get("questions").map(clearChange);
  const disclaimers = state.get("disclaimers").map(clearChange);
  const sections = state.get("sections").map(clearChange);

  return state
    .set("questions", questions)
    .set("disclaimers", disclaimers)
    .set("sections", sections)
    .set("reset", formTemplate.id)
    .set("newQuestions", Immutable.Map())
    .set("sortedQuestions", toSortedQuestions(formTemplate))
    .set("newSections", Immutable.Map())
    .set("sortedSections", toSortedSections(formTemplate));
}

function refresh(formTemplate: AF.FormTemplate<AF.WithId>): State.State {
  return reset(toState(formTemplate), formTemplate);
}

function clearChange<T, U>(change: State.Change<T, U>): State.Change<T, U> {
  return { ...change, draft: undefined };
}

function updateQuestion(
  state: State.State,
  draft: Draft.Question
): State.State {
  if (Draft.isNew(draft)) {
    return updateNewQuestion(state, draft);
  }

  const originalQuestionState = state
    .get("questions")
    .get(State.QuestionId(draft.id));
  if (!originalQuestionState) {
    return state;
  }

  const original = originalQuestionState.original;
  const hasChanges = Draft.hasChanges(original, draft);

  const updatedState: State.State = state.update("questions", (questions) =>
    questions.set(State.QuestionId(draft.id), {
      ...originalQuestionState,
      draft: hasChanges ? draft : undefined
    })
  );

  return updatedState;
}

function updateDisclaimer(
  state: State.State,
  draft: Draft.Disclaimer
): State.State {
  if (draft.isNew) {
    return updateNewSection(state, draft as Draft.NewDisclaimer);
  }

  const disclaimerState = state
    .get("disclaimers")
    .get(State.SectionId(draft.id));
  if (!disclaimerState) {
    return state;
  }

  const original = disclaimerState.original;
  const hasChanges = Draft.hasChanges(original, draft);

  return state.set(
    "disclaimers",
    state.get("disclaimers").set(State.SectionId(draft.id), {
      ...disclaimerState,
      draft: hasChanges ? draft : undefined
    })
  );
}

function toState(formTemplate: AF.FormTemplate<AF.WithId>): State.State {
  const questions: [State.QuestionId, State.QuestionChange][] =
    formTemplate.sections
      .flatMap(toQuestions)
      .map((q) => [State.QuestionId(q.id), { original: q, draft: undefined }]);

  const disclaimers: [State.SectionId, State.DisclaimerChange][] =
    formTemplate.sections.flatMap((section) => {
      if (section && section.type === AF.DisclaimerSectionType) {
        return [
          [
            State.SectionId(section.id),
            { original: section, draft: undefined } as State.DisclaimerChange
          ]
        ];
      }

      return [];
    });

  const questionsSort: State.SortedQuestions = toSortedQuestions(formTemplate);

  const sections: [State.SectionId, State.SectionChange][] =
    formTemplate.sections.flatMap((section) => {
      if (section) {
        return [
          [
            State.SectionId(section.id),
            { original: section, draft: undefined } as const
          ]
        ];
      }

      return [];
    });

  const sortedSections = toSortedSections(formTemplate);

  return State.NewState({
    questions: Immutable.Map(questions),
    disclaimers: Immutable.Map(disclaimers),
    sortedQuestions: questionsSort,
    sections: Immutable.Map(sections),
    sortedSections: sortedSections
  });
}

function toSortedSections(
  formTemplate: AF.FormTemplate<AF.WithId>
): State.SortedSections {
  return Immutable.List(
    formTemplate.sections
      .map((section) => (section ? State.SectionId(section.id) : null))
      .filter(isNotNull)
  );
}

function toSortedQuestions(
  formTemplate: AF.FormTemplate<AF.WithId>
): State.SortedQuestions {
  return formTemplate.sections.reduce((acc, section) => {
    if (!section) {
      return acc;
    }

    if (
      section.type === "DisclaimerSection" ||
      section.type === "SchoolRankingSection"
    ) {
      return acc;
    }

    return acc.set(
      State.SectionId(section.id),
      Immutable.List(section.questions.map((q) => State.QuestionId(q.id)))
    );
  }, Immutable.Map() as State.SortedQuestions);
}

export function useFormTemplateProcessor(
  formTemplate: AF.FormTemplate<AF.WithId>
) {
  const initialState = toState(formTemplate);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const json = loadFromLocalstorage(formTemplate.id);
    dispatch({
      type: "Load",
      json
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state.get("save")) {
      saveToLocalstorage(state.set("save", undefined), formTemplate.id);
      dispatch({ type: "SaveComplete" });
    }

    if (state.get("reset")) {
      clearLocalstorage(formTemplate.id);
      dispatch({ type: "ResetComplete" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return { state, dispatch };
}

export function filterChanges(
  state: State.State
): (
  | State.QuestionChange
  | State.DisclaimerChange
  | Draft.NewQuestion
  | State.SectionChange
)[] {
  const questions = state.get("questions");
  const disclaimers = state.get("disclaimers");
  const newQuestions = state.get("newQuestions");
  return questions
    .valueSeq()
    .concat(disclaimers.valueSeq())
    .flatMap((change) => {
      return change.draft ? [change] : [];
    })
    .concat(newQuestions.valueSeq())
    .toArray();
}

export function filterSectionChanges(
  state: State.State
): (State.SectionChange | Draft.NewSection)[] {
  const sections = state.get("sections");
  const newSections = state.get("newSections");

  return sections
    .valueSeq()
    .flatMap((change) => {
      return change.draft ? [change] : [];
    })
    .concat(newSections.valueSeq())
    .toArray();
}

function loadFromLocalstorage(formTemplateId: uuid): string {
  return localStorage.getItem(formTemplateId) ?? "{}";
}

function load(state: State.State, json: string): State.State {
  return State.deserialize(state, JSON.parse(json));
}

function saveToLocalstorage(state: State.State, formTemplateId: uuid): void {
  const stateWithoutOriginal = State.serialize(state);
  localStorage.setItem(formTemplateId, JSON.stringify(stateWithoutOriginal));
}

function clearLocalstorage(formTemplateId: uuid): void {
  localStorage.removeItem(formTemplateId);
}
