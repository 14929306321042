import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikValues } from "formik";
import React from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { NextStepsInfoPopover } from "src/components/Inputs/NextStepsInfoPopover";
import { FORM_STATUS_DESCRIPTION_MAX_CHARS } from "src/constants";

interface Props {
  initialValues: NextStepFormValues;
  handleSubmit: (
    values: NextStepFormValues,
    initialValues: NextStepFormValues
  ) => Promise<boolean>;
  onClick?: (evt: React.MouseEvent) => Promise<boolean>;
  placeHolderText?: string;
  disableReset?: boolean;
  placement?: PlacementWithLogical;
  closeOnBlur?: boolean;
  hasErrors?: boolean;
  leftIcon?: React.ReactElement;
}

export interface NextStepFormValues extends FormikValues {
  id?: uuid;
  summary: string;
  enrollmentDescription: string;
  sendMessage: boolean;
}

export const NextStepMenu = ({
  initialValues,
  handleSubmit,
  onClick,
  placeHolderText,
  disableReset = false,
  placement = "bottom",
  closeOnBlur = true,
  hasErrors = false,
  leftIcon,
}: Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const onSubmit = async (values: NextStepFormValues) => {
    if (await handleSubmit(values, initialValues)) onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, handleChange, isSubmitting, setFieldValue, resetForm }) => {
        const handleOpen = () => {
          resetForm();
          onOpen();
        };

        const handleClose = () => {
          onClose();
          resetForm();
        };

        const handleResetDescription = () => {
          setFieldValue("summary", values.enrollmentDescription);
        };

        const showRequired = values.summary.length === 0;
        const remainingChars =
          FORM_STATUS_DESCRIPTION_MAX_CHARS - values.summary.length;
        const isError = remainingChars < 0 || showRequired;

        return (
          <Popover
            placement={placement}
            isOpen={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            closeOnBlur={closeOnBlur}
            initialFocusRef={textareaRef}
          >
            <PopoverTrigger>
              <Button
                rightIcon={<RiArrowDropDownLine />}
                variant="banner"
                size="sm"
                leftIcon={leftIcon}
                onClick={(evt) => {
                  if (onClick) onClick(evt);
                }}
              >
                Set status description
              </Button>
            </PopoverTrigger>
            <PopoverContent width="25rem">
              {hasErrors && (
                <Text padding={8} size="2xl" textColor="red">
                  Error loading data
                </Text>
              )}
              {!hasErrors && (
                <Form>
                  <Flex direction="column" margin={4} gap={2}>
                    <FormControl isInvalid={isError}>
                      <FormLabel>
                        <HStack spacing={0}>
                          <Text marginRight={2}>Status description</Text>
                          <NextStepsInfoPopover />
                        </HStack>
                      </FormLabel>
                      <Textarea
                        id="summary"
                        name="summary"
                        value={values.summary}
                        onChange={handleChange}
                        placeholder={
                          placeHolderText ??
                          "What do you want the family to know or do next?"
                        }
                        ref={textareaRef}
                        minHeight="7rem"
                      />
                      <Flex direction="row" gap={2}>
                        {!isError ? (
                          <FormHelperText marginTop={1.5}>
                            {remainingChars}
                          </FormHelperText>
                        ) : (
                          <FormErrorMessage marginTop={1.5}>
                            {showRequired
                              ? "A status description is required."
                              : `Limit reached ${remainingChars}.`}
                          </FormErrorMessage>
                        )}
                        <Spacer />
                        <Button
                          variant="ghost"
                          size="xs"
                          fontSize="sm"
                          fontWeight="normal"
                          onClick={handleResetDescription}
                          marginTop={1.5}
                          isDisabled={disableReset}
                        >
                          Reset description
                        </Button>
                      </Flex>
                      <Flex direction="row" gap={2}>
                        <Field
                          type="checkbox"
                          name="sendMessage"
                          size="sm"
                          checked={values.sendMessage}
                        />
                        Send message
                      </Flex>
                    </FormControl>
                    <Text fontSize=".875rem" color="gray.600">
                      Draft message after updating status description
                    </Text>
                    <Flex
                      direction="row"
                      gap={2}
                      marginTop={4}
                      marginBottom={4}
                      justifyContent="end"
                    >
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          onClose();
                          resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="solid"
                        size="sm"
                        type="submit"
                        isDisabled={isSubmitting || isError}
                      >
                        Update description
                      </Button>
                    </Flex>
                  </Flex>
                </Form>
              )}
            </PopoverContent>
          </Popover>
        );
      }}
    </Formik>
  );
};
