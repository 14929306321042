import { Icon, IconButton, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_PERSON_RELATIONSHIP } from "../graphql/mutations";
import { GET_PERSON_RELATIONSHIPS } from "../graphql/queries";
import { Glossary } from "../Text/Glossary";

interface DeleteRelationshipButtonProps {
  id: string;
}

export const DeleteRelationshipButton = ({
  id,
}: DeleteRelationshipButtonProps) => {
  const { glossary } = useGlossary();

  const toast = useAvelaToast();

  const [deletePersonRelationship] = useRemoteDataMutation<
    GQL.DeletePersonRelationship,
    GQL.DeletePersonRelationshipVariables
  >(DELETE_PERSON_RELATIONSHIP, {
    refetchQueries: [GET_PERSON_RELATIONSHIPS],
  });

  const handleDeleteRelationship = useCallback(async () => {
    try {
      await deletePersonRelationship({ variables: { id: id } });

      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Relationship deleted`,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: glossary`Error deleting relationship`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  }, [deletePersonRelationship, glossary, id, toast]);

  const {
    confirm: confirmRelationshipDeletion,
    confirmationDialog: deleteConfirmationDialog,
  } = useConfirmationDialog({
    header: glossary`Delete relationship?`,
    body: (
      <Text>
        <Glossary>
          This will prevent the parent from accessing and editing this student’s
          profile.
        </Glossary>
      </Text>
    ),
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, delete relationship",
      colorScheme: "red",
    },
    translate: true,
  });

  const handleDeleteClick = useCallback(async () => {
    if (!(await confirmRelationshipDeletion())) {
      return;
    }

    handleDeleteRelationship();
  }, [confirmRelationshipDeletion, handleDeleteRelationship]);

  return (
    <>
      <IconButton
        aria-label="Delete relationship"
        icon={<Icon as={RiDeleteBin6Line} />}
        variant="outline"
        onClick={handleDeleteClick}
      />
      {deleteConfirmationDialog}
    </>
  );
};
