import { Flex } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  CloseButton,
  FlexProps,
  Link,
  List,
  ListItem,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FocusEvent, FocusEventHandler } from "react";
import { Link as RouterLink } from "react-router-dom";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as Url from "src/services/url";
import { HasuraRole } from "src/types/hasuraRole";
import { AddressForm } from "./AddressForm";
import { NameForm } from "./NameForm";

type Props = FlexProps & {
  firstName?: string | null;
  lastName?: string | null;
  isDisabled?: boolean;
  forms?:
    | {
        id: string;
        enrollmentPeriodName: string;
        formTemplateName: string;
        formTemplateId: string;
      }[]
    | null;
};

export const StudentForm: React.FC<Props> = ({
  firstName = null,
  lastName = null,
  isDisabled = false,
  forms = null,
  ...props
}) => {
  const isAllowedToEditRefId = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);
  const organization = useOrganization();
  const {
    isOpen: showBirthdateWarning,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const handleOnFocus: FocusEventHandler = (event: FocusEvent) => {
    if (event.target.id === "birth_date" && forms && forms.length > 0) {
      onOpen();
    }
  };

  return (
    <Flex direction="column" align="left" gap={0} {...props}>
      <NameForm onFocus={handleOnFocus} isDisabled={isDisabled} />
      {showBirthdateWarning && (
        <Alert status="warning" marginBottom="1.5rem">
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              Changing Date of birth can impact {firstName ?? ""}{" "}
              {lastName ?? ""}
              ’s forms. Please review the following forms after updating:
            </Text>
            <List>
              {forms?.map((form) => (
                <ListItem key={form.id}>
                  <Link
                    as={RouterLink}
                    to={Url.OrgAdmin.Forms.view({
                      organization,
                      formTemplateId: form.formTemplateId,
                      id: form.id,
                    })}
                    fontWeight="700"
                    state={{ showBirthdateWarning: true }}
                  >
                    {form.formTemplateName} for {form.enrollmentPeriodName}
                  </Link>
                </ListItem>
              ))}
            </List>
          </VStack>
          <Spacer />
          <CloseButton onClick={onClose} />
        </Alert>
      )}
      <InputWithValidationControl
        isRequired={false}
        inputProps={{ placeholder: "E.g.: 12345678" }}
        name="reference_id"
        id="reference_id"
        label={"Reference ID (Optional)"}
        isDisabled={!isAllowedToEditRefId}
      />
      <AddressForm isDisabled={isDisabled} />
    </Flex>
  );
};
