import { Box } from "@chakra-ui/react";
import type { ComponentProps, ReactElement } from "react";
import type { RemoteData } from "src/types/remoteData";

type RemoteDataLoadingIndicatorProps = ComponentProps<typeof Box> & {
  loadingElement?: ReactElement | null;
  reloadingElement?: ReactElement | null;
  remoteData: RemoteData<unknown, unknown>;
};

/**
 * Show the given loading indicator within an announced region. Will show the
 * `loadingElement` content when the given `remoteData` is loading. Alternately,
 * when the given `remoteData` has data and is loading, and `reloadingElement`
 * is provided, that element will be displayed instead of `loadingElement`.
 */
export function RemoteDataLoadingIndicator(
  props: RemoteDataLoadingIndicatorProps
): ReactElement | null {
  const { loadingElement, reloadingElement, remoteData, ...boxProps } = props;

  let children = loadingElement;
  if (
    remoteData.isLoading() &&
    remoteData.hasData() &&
    reloadingElement !== undefined
  ) {
    children = reloadingElement;
  }

  return (
    <Box
      aria-busy={remoteData.isLoading()}
      aria-live="polite"
      display={remoteData.isLoading() ? undefined : "none"}
      role="progressbar"
      {...boxProps}
    >
      {children}
    </Box>
  );
}
