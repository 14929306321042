import { Text } from "@chakra-ui/layout";
import { Box, Spinner } from "@chakra-ui/react";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import * as GQL from "src/types/graphql";
import { CouldNotCreateForms } from "./CouldNotCreateForms";
import { CouldNotReadFile } from "./CouldNotReadFile";
import { CreatingForms } from "./CreatingForms";
import { ImportedForms } from "./ImportedForms";
import { ImportingFile } from "./ImportingFile";

function LoadingIndicator() {
  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <Spinner color="primary.500" label="Loading form import data" size="lg" />
    </Box>
  );
}

export function ImportStatusContent() {
  const { formImport, formImportRowAggregates, state } = useFormImportFlow();

  const status = state.importFileUploadStatus;

  if (["started", "failure"].includes(status)) {
    /*
     * A failure to upload the file is announced via toast. However, there is
     * currently no way for the user to retry the upload from this view.
     */
    return <ImportingFile />;
  }

  if (formImport.hasError() || formImportRowAggregates.hasError()) {
    /*
     * Either the user has an auth issue, or there's a problem the user cannot
     * fix. Communicate via toast.
     *
     * TODO: Replace false loading indicator with proper error view.
     */
    return <LoadingIndicator />;
  }

  /*
   * Assign the form import data for the current form import id.
   * If the ids do not match, this indicates that a new form import has
   * been created and the previous one should be ignored.
   */
  const formImportData =
    formImport.hasData() && formImport.data?.id === state.formImportId
      ? formImport.data
      : null;

  if (formImportData?.status === GQL.form_import_status_enum.Invalid) {
    return (
      <CouldNotReadFile
        enrollmentPeriodId={formImportData.enrollment_period?.id ?? ""}
        formTemplateId={state.formTemplateId ?? ""}
        formTemplateName={state.formTemplateName ?? ""}
      />
    );
  }

  if (formImportData?.status === GQL.form_import_status_enum.Valid) {
    if (
      !formImportRowAggregates.hasData() ||
      formImportRowAggregates.data.inProgress > 0
    ) {
      return (
        <CreatingForms
          enrollmentPeriodId={formImportData.enrollment_period?.id ?? ""}
        />
      );
    }

    const { completed } = formImportRowAggregates.data;

    if (completed === 0) {
      return (
        <CouldNotCreateForms
          enrollmentPeriodId={formImportData.enrollment_period?.id ?? ""}
          formTemplateId={state.formTemplateId ?? ""}
          formTemplateName={state.formTemplateName ?? ""}
        />
      );
    }

    return <ImportedForms />;
  }

  if (status === "stopped") {
    /*
     * When there is no upload in progress, the page was visited through direct
     * navigation rather than through the file upload flow.
     */

    if (!state.formImportId) {
      // TODO: Redirect to Forms in this case?
      return <Text>Forms have not yet been imported.</Text>;
    }

    if (formImportData == null) {
      /*
       * With no file upload, and the form import record not (yet)
       * retrieved, the assumption is that it is still being loaded.
       */
      return <LoadingIndicator />;
    }
  }

  return <ImportingFile />;
}
