import { Button, Spacer, Stack } from "@chakra-ui/react";
import { Map, Set } from "immutable";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { UPSERT_VERIFICATION_STATUSES } from "../graphql/mutations";
import { CompositeStatus, Mixed, VerificationInput } from "./VerificationInput";

type VerificationSummary = {
  verification: GQL.GetApplicableVerificationsSummaries_form_verification;
  applicableAppIds: Set<uuid>;
  applicableVerificationResults: Map<uuid, GQL.verification_status_enum>;
  compositeStatus: CompositeStatus;
};

type BulkVerifcationsFormProps = {
  formIds: uuid[];
  applicableVerificationsSummaries: GQL.GetApplicableVerificationsSummaries;
};

export const BulkVerificationForm: React.FC<BulkVerifcationsFormProps> = ({
  formIds,
  applicableVerificationsSummaries,
}) => {
  const navigate = useNavigate();
  const toast = useAvelaToast();

  const verificationSummaries = useMemo<readonly VerificationSummary[]>(
    () =>
      applicableVerificationsSummaries.form_verification.map((verification) =>
        processVerificationSummary(verification, formIds)
      ),
    [formIds, applicableVerificationsSummaries]
  );
  const [newStatuses, setNewStatuses] = useState(Map<uuid, CompositeStatus>());

  const [upsertVerifications] = useRemoteDataMutation<
    GQL.UpsertVerificationStatuses,
    GQL.UpsertVerificationStatusesVariables
  >(UPSERT_VERIFICATION_STATUSES);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const changes = verificationSummaries.flatMap((summary) => {
        const newStatus = newStatuses.get(summary.verification.id);
        if (
          !newStatus ||
          newStatus === summary.compositeStatus ||
          newStatus === Mixed
        ) {
          return [];
        }
        const applicableIds = summary.applicableAppIds.filter(
          (appId) =>
            summary.applicableVerificationResults.get(
              appId,
              GQL.verification_status_enum.Pending
            ) !== newStatus
        );
        if (applicableIds.isEmpty()) return [];
        return [
          { verificationId: summary.verification.id, newStatus, applicableIds },
        ];
      });
      if (!changes.length) {
        toast({ title: `No changes made.` });
        navigate(-1);
        return;
      }
      toast({
        title: `Verifying ${formIds.length} forms...`,
      });
      for (const change of changes) {
        const form_ids = change.applicableIds.toArray();
        await upsertVerifications({
          variables: {
            form_ids,
            form_verification_id: change.verificationId,
            verification_results: form_ids.map((appId) => ({
              form_id: appId,
              form_verification_id: change.verificationId,
              verification_status: change.newStatus,
            })),
          },
        });
      }
      toast({
        title: `Verified ${formIds.length} forms.`,
      });
      navigate(-1);
    } catch (error: unknown) {
      console.error(error);
      toast.error({
        title: `Error verifying ${formIds.length} forms.`,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Stack flexGrow="1" w="100%">
        {verificationSummaries.map((verificationSummary) => {
          const {
            verification,
            applicableAppIds,
            compositeStatus: existingCompositeStatus,
          } = verificationSummary;
          const newCompositeStatus = newStatuses.get(
            verification.id,
            existingCompositeStatus
          );

          return (
            <VerificationInput
              key={verification.id}
              label={verification.label}
              showMixed={existingCompositeStatus === Mixed}
              status={newCompositeStatus}
              onStatusChange={(status) =>
                setNewStatuses((statuses) =>
                  status === existingCompositeStatus
                    ? statuses.delete(verification.id)
                    : statuses.set(verification.id, status)
                )
              }
              additionalInfo={`${applicableAppIds.size} out of ${formIds.length} are applicable for this
                verification`}
            />
          );
        })}
      </Stack>

      <AdminFormButtons>
        <Button
          variant="outline"
          colorScheme="gray"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Spacer />
        <Button
          variant="solid"
          type="submit"
          isDisabled={newStatuses.isEmpty() || isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </AdminFormButtons>
    </>
  );
};

function processVerificationSummary(
  verification: GQL.GetApplicableVerificationsSummaries_form_verification,
  formIds: uuid[]
): VerificationSummary {
  const applicableAppIds = Set(
    verification.form_questions.some((q) => q.question.form_template_section_id)
      ? formIds
      : verification.form_questions.flatMap((q) =>
          q.question.ancestor_questions.flatMap((aq) =>
            aq.form_question_option.form_answer_options.map(
              (aao) => aao.form_answer.form_id as string
            )
          )
        )
  );
  const applicableVerificationResults = Map(
    verification.form_verification_results
      .filter((avr) => applicableAppIds.contains(avr.form_id))
      .map((avr) => [avr.form_id, avr.verification_status])
  );
  let statuses = Set(applicableVerificationResults.valueSeq());
  if (applicableVerificationResults.size < applicableAppIds.size) {
    statuses = statuses.add(GQL.verification_status_enum.Pending);
  }
  const compositeStatus: CompositeStatus =
    statuses.size > 1
      ? Mixed
      : statuses.first() ?? GQL.verification_status_enum.Pending;
  return {
    verification,
    applicableAppIds,
    applicableVerificationResults,
    compositeStatus,
  };
}
