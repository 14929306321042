import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { EnrollmentPeriodOutletContext } from "src/scenes/orgAdmin/enrollmentPeriods/Edit";
import { validateWithZod } from "src/services/formValidations";
import { FormTemplateContent } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { CREATE_FORM_TEMPLATE } from "../graphql/mutations";
import { GET_FORM_TEMPLATE_BY_ID } from "../graphql/queries";

const FormSchema = z.object({
  id: z.string(),
  enrollment_period_id: z.string(),
  name: z.string().min(1, "Form name is required"),
  key: z.string().min(1, "Form key is required"),
  description: z.string().optional()
});

type FormType = z.infer<typeof FormSchema>;

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const CreateFromScratchDialog: React.FC<Props> = ({
  isOpen,
  onCancel
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const organization = useOrganization();
  const { enrollmentPeriod } =
    useOutletContext<EnrollmentPeriodOutletContext>();

  const [createFormTemplate] = useRemoteDataMutation<
    GQL.CreateFormTemplate,
    GQL.CreateFormTemplateVariables
  >(CREATE_FORM_TEMPLATE);

  const initialValues: FormType = {
    id: crypto.randomUUID(),
    enrollment_period_id: enrollmentPeriod.id,
    name: "",
    key: "",
    description: ""
  };

  const handleSubmitForm = useCallback(
    (values: FormType) => {
      const variables: GQL.CreateFormTemplateVariables = {
        form_template: {
          ...values
        }
      };

      createFormTemplate({
        variables,
        refetchQueries: [
          GET_FORM_TEMPLATE_BY_ID,
          GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD
        ],
        onCompleted: (data) => {
          const formTemplateId = data.insert_form_template_one?.id || "";
          navigate(
            organization
              .map((org) =>
                FormTemplateContent.edit(
                  org,
                  enrollmentPeriod.id,
                  formTemplateId
                )
              )
              .withDefault("#")
          );
        }
      })
        .then(() => {
          toast({
            title: "Form created",
            status: "success",
            isClosable: true
          });
          onCancel();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error creating form",
            status: "error",
            isClosable: true
          });
        });
    },
    [
      createFormTemplate,
      enrollmentPeriod,
      navigate,
      onCancel,
      organization,
      toast
    ]
  );

  return (
    <Formik<FormType>
      initialValues={initialValues}
      enableReinitialize
      validateOnBlur={true}
      onSubmit={handleSubmitForm}
      validate={validateWithZod(FormSchema)}
    >
      {({ values, handleReset, handleSubmit }) => {
        const onCancelClick = () => {
          handleReset();
          onCancel();
        };

        return (
          <Modal isOpen={isOpen} onClose={onCancel}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize="lg" fontWeight="700">
                Create form from scratch
              </ModalHeader>

              <ModalBody>
                <Flex as={Form} direction="column" gap={2}>
                  <InputWithValidationControl
                    id="name"
                    name="name"
                    label="Form name"
                    inputProps={{
                      placeholder: "E.g.: Registration 2024"
                    }}
                    isRequired
                  />

                  <InputWithValidationControl
                    id="key"
                    name="key"
                    label="Form key"
                    inputProps={{
                      placeholder: "E.g.: registration-2024-form"
                    }}
                    isRequired
                  />

                  <TextareaWithValidationControl
                    id="description"
                    name="description"
                    label="Form description"
                  />
                </Flex>
              </ModalBody>

              <ModalFooter gap={2}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
                <Button type="submit" onClick={() => handleSubmit()}>
                  Create form
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
