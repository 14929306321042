import { uniqBy } from "lodash";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";

export type OrderedItem = {
  id: string;
  label: string;
  order: number;
};

export type GradeProgram = {
  grade: OrderedItem;
  program: OrderedItem | null;
};

export type GradeProgramWithID = GradeProgram & {
  id: string;
};

export type GradesGroupLists = {
  grades: OrderedItem[];
  programs: OrderedItem[];
};

export function gradesGroupToLists(
  gradesGroup: GQL.GetGradesGroupsByEnrollmentSchool_grades_group
): GradesGroupLists {
  // Check that enrollment period and school are consistent between grades group and program groups in it.
  if (
    !gradesGroup.grade_programs.every(
      (gradeProgram) =>
        gradesGroup.enrollment_period_id ===
          gradeProgram.enrollment_period_id &&
        gradesGroup.school_id === gradeProgram.school_id
    )
  ) {
    console.error(
      `Grades group id ${gradesGroup.id} includes grade program(s) from different enrollment periods and/or schools.`
    );
  }

  const gradePrograms = gradesGroupToGradePrograms(gradesGroup);

  const { grades, programs } = gradeProgramsToLists(gradePrograms);

  // The number of grade programs should be equal to number of grades * number of programs.
  if (grades.length * programs.length !== gradesGroup.grade_programs.length) {
    console.error(
      "Grades group is not a valid cross product of grades and programs"
    );
  }

  return { grades, programs };
}

export function gradesGroupToGradePrograms(
  gradesGroup: GQL.GetGradesGroupsByEnrollmentSchool_grades_group
): GradeProgramWithID[] {
  return gradesGroup.grade_programs.map((gradeProgram) => ({
    id: gradeProgram.id,
    grade: gradeProgram.grade_config,
    program: gradeProgram.program,
  }));
}

function gradeProgramsToLists(gradePrograms: GradeProgram[]): GradesGroupLists {
  const grades = uniqBy(
    gradePrograms.map((gradeProgram) => gradeProgram.grade),
    "id"
  );
  const programs = uniqBy(
    gradePrograms.map((gradeProgram) => gradeProgram.program),
    "id"
  ).filter(isNotNull);

  return { grades, programs };
}

export function listsToGradesGroupInsert({
  lists,
  enrollmentPeriodId,
  schoolId,
}: {
  lists: GradesGroupLists;
  enrollmentPeriodId: string;
  schoolId: string;
}): GQL.grades_group_insert_input {
  const gradePrograms = listsToGradePrograms(lists);
  const formattedGradePrograms = formatGradeProgramsToGradesGroupInsert({
    gradePrograms,
    enrollmentPeriodId,
    schoolId,
  });
  return {
    enrollment_period_id: enrollmentPeriodId,
    school_id: schoolId,
    grade_programs: {
      data: formattedGradePrograms,
      on_conflict: {
        constraint:
          GQL.grade_constraint
            .grade_enrollment_period_id_grade_config_id_school_id_program_id,
        update_columns: [GQL.grade_update_column.grades_group_id],
      },
    },
  };
}

export function listsToGradePrograms({
  grades,
  programs,
}: GradesGroupLists): GradeProgram[] {
  return grades.flatMap((grade) =>
    programs.map((program) => ({
      grade: grade,
      program: program,
    }))
  );
}

function formatGradeProgramsToGradesGroupInsert({
  gradePrograms,
  enrollmentPeriodId,
  schoolId,
}: {
  gradePrograms: GradeProgram[];
  enrollmentPeriodId: string;
  schoolId: string;
}): GQL.grade_insert_input[] {
  return gradePrograms.map((gradeProgram) => ({
    enrollment_period_id: enrollmentPeriodId,
    school_id: schoolId,
    grade_config_id: gradeProgram.grade.id,
    program_id: gradeProgram.program?.id,
  }));
}

export function orderedItemSortFn(a: OrderedItem, b: OrderedItem) {
  return a.order - b.order;
}
