import { gql } from "@apollo/client";

export const GET_WAITLIST_TAGS = gql`
  query GetWaitlistTags($form_id: uuid!, $school_id: uuid!) {
    form_school_rank(
      where: { form_id: { _eq: $form_id }, school_id: { _eq: $school_id } }
    ) {
      id
      tags {
        enrollment_period_tag {
          id
          name
        }
      }
      waitlists(where: { deleted_at: { _is_null: true } }) {
        id
        status
        position
        grade_id
      }
    }
  }
`;

export const FETCH_PRIORITY_TEMPLATES_BY_GRADE = gql`
  query FetchPriorityTemplatesByGrade($grade_ids: [uuid!]!) {
    grade_resolved_priority_template(where: { grade_id: { _in: $grade_ids } }) {
      grade_id
      priority_template {
        config
      }
    }
  }
`;

export const FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS_MAX_INPUT_SIZE = 500;
export const FETCH_FORM_SCHOOL_GRADE_IDS_AND_TAGS = gql`
  query FetchFormSchoolGradeIdsAndTags($matching: form_school_grade_bool_exp!) {
    form_school_grade(where: $matching) {
      form_id
      school_id
      grade_id
      form_school_rank {
        id
        tags {
          enrollment_period_tag {
            name
          }
        }
      }
    }
  }
`;
