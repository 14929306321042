import {
  Alert,
  AlertDescription,
  AlertIcon,
  ColorModeScript,
} from "@chakra-ui/react";
import "@fontsource/inter";
import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { version } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./scenes/App";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container!);

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  Sentry.init({
    dsn: "https://0c812c8cee5d4b2bbf82fe8f0b13a9a1@o4504255885344768.ingest.sentry.io/4504255888293888",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      new Integrations.CaptureConsole({ levels: ["error"] }),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT ?? "local", // set to local by default since when running locally, you'll likely comment out the if check.

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
  });
}

root.render(
  <ErrorBoundary
    fallback={
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Error</AlertDescription>
      </Alert>
    }
  >
    <React.StrictMode>
      <ColorModeScript />
      <App />
    </React.StrictMode>
  </ErrorBoundary>
);

console.log("Running with React", version);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
