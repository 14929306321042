import Immutable from "immutable";
import { z } from "zod";
import * as Draft from "./messagesDraft";
import { ListMessageTemplatesForm } from "../EditFormTemplateTabs/AutomaticMessages/List/schemas";
import { Change, FormTemplateIdType, WithDraft } from "./common";

export type ListMessagesChange = Change<
  ListMessageTemplatesForm,
  Draft.ListMessagesDraft
>;

type StateInternal = {
  listMessages: Immutable.Map<FormTemplateIdType, ListMessagesChange>;
  save: uuid | undefined;
  reset: uuid | undefined;
};
export type State = Immutable.Record<StateInternal>;
export const NewState = Immutable.Record<StateInternal>({
  listMessages: Immutable.Map(),
  save: undefined,
  reset: undefined
});
/**
 * Local storage state
 */
export const LocalstorageSchema = z.object({
  listMessages: z.record(z.string().uuid(), z.object({ draft: z.any() }))
});

export type LocalStorageState = z.infer<typeof LocalstorageSchema>;

export function serialize(state: State): LocalStorageState {
  const listMessages = state
    .get("listMessages")
    .map((change) => {
      return { draft: change.draft } as const;
    })
    .filter((change): change is WithDraft<Draft.ListMessagesDraft> => {
      return change.draft !== undefined;
    })
    .mapKeys((key) => key.get("formTemplateId"))
    .toObject();

  const localStorageState: LocalStorageState = {
    listMessages
  };

  return localStorageState;
}

export function deserialize(state: State, json: unknown): State {
  const result = LocalstorageSchema.safeParse(json);
  const savedState: LocalStorageState | undefined = result.success
    ? result.data
    : undefined;

  if (!savedState) {
    return state;
  }

  return NewState({
    listMessages: state.get("listMessages").map((change, key) => {
      return {
        ...change,
        draft: savedState.listMessages[key.get("formTemplateId")]?.draft as
          | Draft.ListMessagesDraft
          | undefined
      };
    })
  });
}
