import * as Env from "src/services/env";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SCRIPT_ID,
  WEGLOT_SKIP_CLASS,
} from "../constants";

export const createWeglotScript = (initializeCallback: () => void) => {
  const weglotSource = document.createElement("script");
  weglotSource.id = WEGLOT_SCRIPT_ID;
  weglotSource.src = "https://cdn.weglot.com/weglot.min.js";
  initializeCallback &&
    weglotSource.addEventListener("load", () =>
      initializeWeglot(initializeCallback)
    );
  return weglotSource;
};

export const initializeWeglot = (callback: () => void) => {
  const { REACT_APP_WEGLOT_APIKEY } = Env.read();
  window.Weglot.on("initialized", callback);
  window.Weglot.initialize({
    api_key: REACT_APP_WEGLOT_APIKEY,
    dynamics: [
      {
        value: `.${WEGLOT_APPLY_CLASS}`,
      },
    ],
    excluded_blocks: [
      {
        value: `.${WEGLOT_SKIP_CLASS}`,
      },
    ],
    hide_switcher: true,
  });
};
