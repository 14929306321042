import { gql } from "@apollo/client";

export const CREATE_PARENT = gql`
  mutation CreateParent($parent: person_insert_input!) {
    insert_person_one(object: $parent) {
      id
    }
  }
`;

export const BULK_CREATE_PARENTS = gql`
  mutation BulkCreateParents($parents: [person_insert_input!]!) {
    insert_person(objects: $parents) {
      affected_rows
    }
  }
`;

export const UPDATE_PARENT = gql`
  mutation UpdateParent($id: uuid!, $parent: person_set_input) {
    update_person_by_pk(pk_columns: { id: $id }, _set: $parent) {
      id
    }
  }
`;

export const DELETE_ORGANIZATION_PARENT = gql`
  mutation DeleteOrganizationParent($parentId: uuid!) {
    delete_person_by_pk(id: $parentId) {
      id
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation UpdateContactInfo(
    $user_id: String!
    $client_id: String
    $email_address: String = ""
    $phone_number: String = ""
  ) {
    update_contact_info(
      request: {
        user_id: $user_id
        client_id: $client_id
        email_address: $email_address
        phone_number: $phone_number
      }
    ) {
      message
      status_code
      user_id
    }
  }
`;
