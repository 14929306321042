import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organization: organization_insert_input!) {
    insert_organization_one(object: $organization) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $id: uuid!
    $organization: organization_set_input
  ) {
    update_organization_by_pk(pk_columns: { id: $id }, _set: $organization) {
      id
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement(
    $id: uuid!
    $announcement: announcement_set_input
  ) {
    update_announcement_by_pk(pk_columns: { id: $id }, _set: $announcement) {
      id
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($announcement: announcement_insert_input!) {
    insert_announcement_one(object: $announcement) {
      id
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: uuid!) {
    delete_organization_by_pk(id: $id) {
      id
    }
  }
`;
