import { Avatar as ChakraAvatar, AvatarProps } from "@chakra-ui/react";
import { useField } from "formik";
import React, { FunctionComponent } from "react";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserProfile } from "src/hooks/useUserProfile";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { GET_AVATARS } from "src/scenes/parent/students/graphql/queries";
import { fullName } from "src/services/format";
import * as GQL from "src/types/graphql";

const DEFAULT_COLOR = "gray.300";
const COLORS: string[] = [
  "red.300",
  "orange.300",
  "yellow.300",
  "green.300",
  "primary.300",
  "blue.300",
  "cyan.300",
  "purple.300",
  "pink.300",
];

export type Student = {
  avatar: string | null;
  first_name: string | null;
  last_name: string | null;
};
type Props = { student?: Student | null } & AvatarProps;

export const StudentAvatar: FunctionComponent<Props> = (props) => {
  const { student, ...avatarProps } = props;

  const name = student ? fullName(student) : undefined;
  return (
    <ChakraAvatar
      name={name}
      bg={student?.avatar ?? DEFAULT_COLOR}
      size="xl"
      {...avatarProps}
      color="black"
      className={WEGLOT_SKIP_CLASS}
    />
  );
};

export const StudentFormAvatar: FunctionComponent<Props> = (props) => {
  const { student, ...avatarProps } = props;

  const organization = useOrganization();
  const userProfile = useUserProfile();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetAvatars,
    GQL.GetAvatarsVariables
  >(GET_AVATARS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      guardian_id: userProfile.map((profile) => profile.id).withDefault(""),
    },
    skip: !organization.hasData() || !userProfile.hasData(),
  });

  if (!remoteData.hasData()) {
    return null;
  }

  const currentAvatar = props.student?.avatar;
  const randomAvatar = getRandomAvatar(remoteData.data);

  const bg = {
    bg: currentAvatar ? currentAvatar : randomAvatar,
  };

  return <StudentAvatarField {...bg} {...avatarProps} />;
};

const StudentAvatarField: React.FC<Props> = ({ student, bg, ...props }) => {
  const [input, , helper] = useField("avatar");
  const [firstNameInput] = useField("first_name");
  const [lastNameInput] = useField("last_name");
  const name = fullName({
    first_name: firstNameInput.value,
    last_name: lastNameInput.value,
  });
  React.useEffect(() => {
    // set avatar field only if it's empty (i.e. for new student)
    if (input && helper && input.value === "" && bg) helper.setValue(bg ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, bg]);
  return (
    <StudentAvatar
      name={name}
      {...props}
      bg={bg ?? DEFAULT_COLOR}
      color="black"
    />
  );
};

function getRandomAvatar(getAvatars: GQL.GetAvatars): string | undefined {
  const usedAvatars = getAvatars.person
    .flatMap((person) =>
      person.first_relationship.map((r1) => r1.second.avatar)
    )
    .concat(
      getAvatars.person.flatMap((person) =>
        person.second_relationship.map((r2) => r2.first.avatar)
      )
    )
    .filter((avatar): avatar is string => avatar !== null);
  const availableAvatars = COLORS.filter(
    (color) => !usedAvatars.includes(color)
  );

  return availableAvatars[Math.floor(Math.random() * availableAvatars.length)];
}
