import { MenuItem, MenuList, Tag } from "@chakra-ui/react";
import React from "react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import * as AF from "src/types/formTemplate";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import { Props, Loading } from "./Undo";
import { gql } from "@apollo/client";
import _ from "lodash";
import { Timestamp } from "./Timestamp";
import { NoUndoAvailable } from "./NoUndoAvailable";
import { removeConsecutiveDuplicates, filterEmpty } from "./helpers";
import { Answer } from "src/services/formTemplate";

type MultiSelectProps = Omit<Props, "question"> & {
  initialFocusRef: React.RefObject<HTMLButtonElement>;
  question: AF.MultiSelect<AF.WithId>;
};
export const MultiSelectUndo: React.FC<MultiSelectProps> = (props) => {
  const { formId, question, setAnswer, initialFocusRef, answer } = props;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetMultiSelectAnswerHistory,
    GQL.GetMultiSelectAnswerHistoryVariables
  >(GET_MULTI_SELECT_ANSWER_HISTORY, {
    variables: {
      formId,
      questionId: question.id,
      formQuestionOptionFilter: question.options.map((o) => {
        return {
          row_data: {
            _contains: {
              form_question_option_id: o.id,
            },
          },
        };
      }),
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <RemoteDataView
      error={GenericError}
      remoteData={remoteData}
      loading={<Loading />}
    >
      {(data) => {
        const history = consolidate(
          data.audit_form_transaction,
          question,
          answer
        );
        if (history.length === 0) {
          return <NoUndoAvailable />;
        }

        return (
          <MenuList maxHeight="20rem" overflowY="auto">
            {history.map((item, index) => {
              return (
                <MenuItem
                  onClick={() => setAnswer(item.value.map((o) => o.id))}
                  key={index}
                  display="flex"
                  gap="2"
                  ref={index === 0 ? initialFocusRef : undefined}
                  alignItems="center"
                >
                  {item.value.map((option) => {
                    return (
                      <Tag background="gray.300" key={option.id}>
                        {option.label}
                      </Tag>
                    );
                  })}
                  {item.timestamp && <Timestamp timestamp={item.timestamp} />}
                </MenuItem>
              );
            })}
          </MenuList>
        );
      }}
    </RemoteDataView>
  );
};

type UndoItem = {
  answerId: string;
  value: { id: string; label: string }[];
  timestamp?: string;
};
export function consolidate(
  list: AuditFormTransaction[],
  question: AF.MultiSelect<AF.WithId>,
  answer: Answer.FormikFieldValue
): UndoItem[] {
  return _.flow(
    toUndoItem(question),
    removeConsecutiveDuplicates,
    filterEmpty,
    removeCurrentAnswerForMultiSelect(answer)
  )(list);
}

const removeCurrentAnswerForMultiSelect =
  (answer: Answer.FormikFieldValue) =>
  (list: UndoItem[]): UndoItem[] => {
    if (
      _.isEqual(
        list[0]?.value.map((o) => o.id),
        answer
      )
    ) {
      return _.drop(list);
    }
    return list;
  };

const toUndoItem =
  (question: AF.MultiSelect<AF.WithId>) => (list: AuditFormTransaction[]) => {
    const { formAnswerIds, formAnswerOptionMap } = groupByTableName(list);
    const optionsMap = new Map<string, string>(
      question.options.map((o) => [o.id, o.label])
    );

    return formAnswerIds.flatMap((answerId) => {
      const optionActions = formAnswerOptionMap.get(answerId);
      if (!optionActions) return [];

      let timestamp: string | undefined;
      const options = optionActions.flatMap((optionAction) => {
        timestamp = optionAction.action_tstamp_tx;
        const optionId = optionAction.row_data.form_question_option_id;
        if (!optionId) return [];

        const label = optionsMap.get(optionId);
        if (!label) return [];

        return [{ id: optionId, label }];
      });

      if (options.length === 0) {
        return [];
      }

      return { answerId, value: options, timestamp };
    });
  };

function groupByTableName(list: AuditFormTransaction[]) {
  const formAnswerIdSet: Set<string> = new Set();
  const formAnswerOptionMap: Map<string, LoggedAction[]> = new Map();
  list.forEach((item) => {
    if (item.logged_actions.length === 0) {
      return;
    }

    item.logged_actions.forEach((loggedAction) => {
      switch (loggedAction.table_name) {
        case "form_answer":
          if (loggedAction.row_id) {
            formAnswerIdSet.add(loggedAction.row_id);
          }
          break;

        case "form_answer_option":
          const answerId = loggedAction.row_data.form_answer_id;
          if (!answerId) {
            return;
          }

          const loggedActions = formAnswerOptionMap.get(answerId);
          if (loggedActions) {
            loggedActions.push(loggedAction);
          } else {
            formAnswerOptionMap.set(answerId, [loggedAction]);
          }
          break;
      }
    });
  });

  return { formAnswerIds: Array.from(formAnswerIdSet), formAnswerOptionMap };
}

export const GET_MULTI_SELECT_ANSWER_HISTORY = gql`
  query GetMultiSelectAnswerHistory(
    $formId: uuid!
    $questionId: uuid!
    $formQuestionOptionFilter: [audit_logged_actions_bool_exp!]
  ) {
    audit_form_transaction(
      where: { form_id: { _eq: $formId } }
      order_by: { action_tstamp_tx: desc }
    ) {
      form_id
      logged_actions(
        where: {
          _or: [
            {
              form_id: { _eq: $formId }
              table_name: { _in: ["form_answer"] }
              row_data: { _contains: { question_id: $questionId } }
            }
            {
              table_name: { _in: ["form_answer_option"] }
              _or: $formQuestionOptionFilter
            }
          ]
          action: { _in: ["I", "U"] }
        }
      ) {
        action_tstamp_tx
        row_id
        row_data
        table_name
        action
      }
    }
  }
`;

type AuditFormTransaction =
  GQL.GetMultiSelectAnswerHistory_audit_form_transaction;
type LoggedAction =
  GQL.GetMultiSelectAnswerHistory_audit_form_transaction_logged_actions;
