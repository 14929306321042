import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { AnswersOptions } from "src/components/Form/QuestionForm/Edit/AnswersOptions";
import { GradeQuestionForm } from "src/components/Form/QuestionForm/Edit/GradeQuestion";
import { QuestionTitle } from "src/components/Form/QuestionForm/Edit/QuestionTitle";
import { QuestionType } from "src/components/Form/QuestionForm/Edit/QuestionType";
import { Verification } from "src/components/Form/QuestionForm/Edit/Verification";
import {
  FormType,
  getQuestionTypeFormValue,
  toFormValues,
  updateQuestion,
} from "src/components/Form/QuestionForm/formik";
import { ConfigureQuestion } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureQuestion";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { useFormValidation } from "src/components/Form/QuestionForm/useFormValidation";
import { Card } from "src/components/Layout/Card";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { AddressQuestionForm } from "./AddressQuestion";
import { PermissionLevel } from "./PermissionLevel";
import { QuestionKey } from "./QuestionKey";
import { QuestionLink } from "./QuestionLink";

export type QuestionFormEditProps = {
  verificationOptions: AF.FormVerification<AF.WithId>[];
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  onCancelButtonClick: () => void;
  onUpdate: (question: Draft.Question) => Promise<void>;
  question: Draft.Question;
  sectionType: AF.SectionType;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
  otherQuestions: readonly Draft.Question[];
};
export function QuestionFormEdit({
  onCancelButtonClick,
  onUpdate,
  verificationOptions,
  schools,
  question,
  sectionType,
  gradesConfig,
  otherQuestions,
}: QuestionFormEditProps) {
  const initialValues: FormType = React.useMemo(
    () => toFormValues(question),
    [question]
  );
  const validate = useFormValidation({ verificationOptions });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const toast = useAvelaToast();

  const onSubmit = React.useCallback(
    async (values: FormType) => {
      setIsSubmitting(true);
      try {
        await onUpdate(updateQuestion(question, values, verificationOptions));
      } catch (err: unknown) {
        console.error(err);
        toast.error({
          title: "Oops! Something went wrong",
          containerStyle: { marginBottom: "7rem" },
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [onUpdate, question, toast, verificationOptions]
  );

  return (
    <Card display="flex" flexDirection="column" showBorder gap="3" padding={6}>
      <Formik<FormType>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => {
          const questionType = getQuestionTypeFormValue(
            question.id,
            formik.values
          );

          const questionTypeTouched =
            formik.touched.questionTypes?.[question.id];
          const questionTypeError = formik.errors.questionTypes?.[question.id];

          let questionForm;
          switch (questionType) {
            case AF.GradesType:
              questionForm = (
                <GradeQuestionForm
                  questionId={question.id}
                  gradesConfig={gradesConfig}
                />
              );
              break;

            case AF.AddressType:
              questionForm = (
                <AddressQuestionForm
                  questionId={question.id}
                  verificationOptions={verificationOptions ?? []}
                  schools={schools}
                  sectionType={sectionType}
                  otherQuestions={otherQuestions}
                />
              );
              break;

            default:
              questionForm = (
                <>
                  <Flex direction="row" gap="4">
                    <ConfigureQuestion
                      questionId={question.id}
                      questionType={questionType}
                    />
                  </Flex>
                  <Flex direction="column" flexGrow="1" gap="3">
                    <Verification
                      questionId={question.id}
                      verificationOptions={verificationOptions ?? []}
                    />
                    <QuestionLink questionId={question.id} />
                    <PermissionLevel questionId={question.id} />

                    <AnswersOptions
                      sectionType={sectionType}
                      questionId={question.id}
                      schools={schools}
                      verificationOptions={verificationOptions}
                      question={question}
                    />
                  </Flex>
                </>
              );
              break;
          }
          return (
            <Flex as={Form} direction="column" gap="6">
              <QuestionTitle questionId={question.id} />
              <QuestionKey questionId={question.id} />

              <QuestionType
                isDisabled={!question.isNew ?? true}
                questionId={question.id}
                error={questionTypeTouched ? questionTypeError : undefined}
              />

              {questionForm}

              <Flex gap="3" justifyContent="right" paddingY="3">
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={onCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button type="submit" isLoading={isSubmitting}>
                  Update question
                </Button>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Card>
  );
}
