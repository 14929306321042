import { Flex, Tag, Text, Tooltip } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { FormStatusDescriptionFormControl } from "src/components/Inputs/FormStatusDescriptionFormControl";
import { FORM_STATUS } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { validateWithZod } from "src/services/formValidations";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { UPSERT_FORM_STATUS_DESCRIPTIONS } from "../../graphql/mutations";
import { EditFormTemplateActionBar } from "../components/ActionBar";
import { FormTemplateDefaultCard } from "../../components/FormTemplateDefaultCard";
import { useEditFormTemplateStatusesContext } from "./context";
import { FormTemplateId, hasChanges, toOriginal } from "../../types/common";
import { GenericError } from "src/components/Feedback/GenericError";

const FormSchema = z.object({
  [GQL.form_status_enum.InProgress]: z.string().min(1),
  [GQL.form_status_enum.Submitted]: z.string().min(1),
  [GQL.form_status_enum.Verified]: z.string().min(1),
  [GQL.form_status_enum.LotteryReady]: z.string().min(1),
  [GQL.form_status_enum.Admissions]: z.string().min(1),
  [GQL.form_status_enum.Cancelled]: z.string().min(1)
});

export type StatusDescriptionsFormType = z.infer<typeof FormSchema>;

const StatusViewOrder = [
  GQL.form_status_enum.InProgress,
  GQL.form_status_enum.Submitted,
  GQL.form_status_enum.Verified,
  GQL.form_status_enum.LotteryReady,
  GQL.form_status_enum.Admissions,
  GQL.form_status_enum.Cancelled
] as const;

interface Props {
  actionBarRef: React.RefObject<HTMLDivElement>;
  formTemplate: AF.FormTemplate<AF.WithId>;
  isLoading: boolean;
}
const FORM_ID = "form-template-statuses-form";
export const StatusDescriptionsForm: React.FC<Props> = ({
  actionBarRef,
  formTemplate,
  isLoading
}) => {
  const id = formTemplate.id;
  const toast = useAvelaToast();
  const [statusEditMode, setStatusEditMode] =
    useState<GQL.form_status_enum | null>(null);

  const { state, dispatch } = useEditFormTemplateStatusesContext();

  const [upsertFormStatusDescriptions] = useRemoteDataMutation<
    GQL.UpsertFormStatusDescriptions,
    GQL.UpsertFormStatusDescriptionsVariables
  >(UPSERT_FORM_STATUS_DESCRIPTIONS);

  const handleSubmit = async (values: StatusDescriptionsFormType) => {
    try {
      const upsertObjects: GQL.form_status_description_insert_input[] =
        Object.keys(values).map((status) => ({
          form_template_id: id,
          status: status as GQL.form_status_enum,
          description: values[status as keyof StatusDescriptionsFormType]
        }));

      await upsertFormStatusDescriptions({
        variables: {
          objects: upsertObjects
        }
      });

      if (dispatch) {
        dispatch({
          type: "Refresh",
          initialValues: { statuses: values },
          formTemplateId: id
        });
      }

      toast({
        title: "Success",
        description: "Form status descriptions updated",
        status: "success",
        duration: 2000
      });
    } catch (e) {
      toast({
        title: "Something went wrong",
        description: "Failed to update form status description",
        status: "error",
        duration: 2000
      });
    }
  };

  const handleUpdateStatusClick = useCallback(
    (values: StatusDescriptionsFormType) => {
      if (dispatch) {
        dispatch({
          type: "UpdateStatuses",
          statuses: {
            isDraft: true,
            ...values
          },
          formTemplateId: id
        });

        dispatch({
          type: "Save",
          formTemplateId: id
        });
      }

      setStatusEditMode(null);
    },
    [id, dispatch]
  );

  const draft = useMemo(
    () => state?.get("statuses")?.get(FormTemplateId(id))?.draft,
    [id, state]
  );

  const original = useMemo(
    () => state?.get("statuses")?.get(FormTemplateId(id))?.original,
    [id, state]
  );

  const formikInitialValues = useMemo(() => {
    if (draft) {
      return toOriginal(draft);
    } else {
      return original;
    }
  }, [draft, original]);

  const hasChangesResult = hasChanges(original, draft);

  if (!formikInitialValues) return <GenericError />;

  return (
    <Formik<StatusDescriptionsFormType>
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
      validate={validateWithZod(FormSchema)}
      enableReinitialize
    >
      {({ values, handleChange, resetForm }) => {
        const handleResetForm = () => {
          resetForm();

          if (dispatch) {
            dispatch({
              type: "Reset",
              formTemplateId: id
            });
          }
        };

        const handleCancelClick = () => {
          resetForm();
          setStatusEditMode(null);
        };

        return (
          <>
            <Flex as={Form} id={FORM_ID} direction="column" gap={6} mt={4}>
              {StatusViewOrder.map((status, index) => {
                const defaultValue = values[status];

                return (
                  <FormTemplateDefaultCard
                    key={index}
                    title={FORM_STATUS[status].label}
                    footerInfo={
                      statusEditMode === status ? (
                        <FormStatusDescriptionFormControl
                          value={defaultValue}
                          onChange={handleChange}
                          onCancelClick={handleCancelClick}
                          onUpdateStatusClick={() =>
                            handleUpdateStatusClick(values)
                          }
                          name={status}
                          key={status}
                        />
                      ) : (
                        <Flex direction="column" width="100%">
                          <Text fontSize="sm" color="gray.700">
                            Status description
                          </Text>

                          <Text>{defaultValue}</Text>
                        </Flex>
                      )
                    }
                    tag={
                      status === GQL.form_status_enum.Admissions && (
                        <Tag
                          size="sm"
                          variant="solid"
                          color="blackAlpha.900"
                          bg="gray.200"
                          width="fit-content"
                          fontSize="xs"
                        >
                          Lottery & offers
                        </Tag>
                      )
                    }
                    actionSwitch={
                      statusEditMode !== status &&
                      status === GQL.form_status_enum.Admissions &&
                      !formTemplate.lotteryOffersEnabled ? (
                        <Tooltip
                          label={
                            <Text align="center">
                              To activate this status, turn on Lottery & Offers
                              in Settings
                            </Text>
                          }
                          hasArrow
                          placement="top"
                          borderRadius="md"
                          w={222}
                          p={2}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="400"
                            color="blackAlpha.600"
                          >
                            Inactive
                          </Text>
                        </Tooltip>
                      ) : (
                        statusEditMode !== status && (
                          <Text fontSize="sm" fontWeight="400">
                            Active
                          </Text>
                        )
                      )
                    }
                    onEditClick={
                      statusEditMode !== status
                        ? () => setStatusEditMode(status)
                        : undefined
                    }
                  />
                );
              })}
            </Flex>

            <EditFormTemplateActionBar
              actionBarRef={actionBarRef}
              formId={FORM_ID}
              isLoading={isLoading}
              hasChanges={hasChangesResult}
              onClearChanges={handleResetForm}
            />
          </>
        );
      }}
    </Formik>
  );
};
