import { Box, FormLabel, Switch } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import {
  FormType,
  getRequirementFormValue,
  Requirement,
} from "src/components/Form/QuestionForm/formik";
import * as AF from "src/types/formTemplate";

const EXCLUDED_QUESTION_TYPE_FOR_REQUIREMENT: AF.QuestionType[] = [
  AF.GradesType,
];
type RequirementSwitchProps = {
  questionId: uuid;
  questionType?: AF.Question<AF.WithId>["type"] | "";
};
export const RequirementSwitch: React.FC<RequirementSwitchProps> = ({
  questionId,
  questionType,
}) => {
  const form = useFormikContext<FormType>();
  const requirement = getRequirementFormValue(questionId, form.values);
  const [value, setValue] = useState<Requirement>(requirement);

  React.useEffect(() => {
    if (requirement === undefined) {
      return;
    }

    if (
      questionType === "" ||
      questionType === undefined ||
      EXCLUDED_QUESTION_TYPE_FOR_REQUIREMENT.includes(questionType)
    ) {
      form.setValues((values) => {
        return {
          ...values,
          requirements: {
            ...values.requirements,
            [questionId]: undefined,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  const onChangeHandler = React.useCallback(() => {
    const nextValue = value ? undefined : "Required";

    form.setValues((values) => {
      return {
        ...values,
        requirements: {
          ...values.requirements,
          [questionId]: nextValue,
        },
      };
    }, false);

    setValue(nextValue);
  }, [form, questionId, value]);

  if (
    questionType === undefined ||
    questionType === "" ||
    EXCLUDED_QUESTION_TYPE_FOR_REQUIREMENT.includes(questionType)
  ) {
    return null;
  }

  return (
    <FormLabel
      htmlFor={`requirementSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`requirementSwitch${questionId}`}
        isChecked={value === "Required"}
        onChange={onChangeHandler}
      />
      <Box>Required</Box>
    </FormLabel>
  );
};
