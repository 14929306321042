import { Flex, FlexProps } from "@chakra-ui/react";
import MonacoEditor, { EditorProps } from "@monaco-editor/react";
import React from "react";

export type MustacheEditorProps = { containerProps?: FlexProps } & EditorProps;
export const MustacheEditor: React.FC<MustacheEditorProps> = ({
  containerProps,
  ...editorProps
}) => {
  return (
    <Flex
      height="100%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      paddingTop="3"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      <MonacoEditor
        options={{
          lineNumbers: "off",
          renderLineHighlight: "none",
          glyphMargin: false,
          minimap: { enabled: false },
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
        }}
        {...editorProps}
      />
    </Flex>
  );
};
