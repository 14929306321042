import { AuthorizationMap } from "@avela/avela-authorization-sdk";
import React, { useCallback, useMemo, useState } from "react";
import { RemoteData, notAsked } from "src/types/remoteData";

export const FORM_AUTHORIZATION_SCOPE = "form-authorization" as const;
export const PERMISSIONS_AUTHORIZATION_SCOPE = "permissions" as const;

export type AuthorizationScope =
  | typeof FORM_AUTHORIZATION_SCOPE
  | typeof PERMISSIONS_AUTHORIZATION_SCOPE;

export interface AuthorizationContextType {
  scopedPermissions: Record<
    AuthorizationScope,
    Record<string, RemoteData<Error, AuthorizationMap>>
  >;
  setPermissionInScope: (
    scope: AuthorizationScope,
    permissions: RemoteData<Error, AuthorizationMap>,
    index?: string
  ) => void;
}

const defaultScopedPermissions: AuthorizationContextType["scopedPermissions"] =
  {
    "form-authorization": {},
    permissions: {},
  };

const AuthorizationContext = React.createContext<
  AuthorizationContextType | undefined
>(undefined);

interface AuthorizationProviderProps {}

export function AuthorizationProvider({
  children,
}: React.PropsWithChildren<AuthorizationProviderProps>) {
  const [scopedPermissions, setScopedPermissions] = useState(
    defaultScopedPermissions
  );

  const setPermissionInScope = useCallback(
    (
      scope: AuthorizationScope,
      permissions: RemoteData<Error, AuthorizationMap>,
      index?: string
    ) => {
      setScopedPermissions((prev) => ({
        ...prev,
        [scope]: {
          // intentionally dropping all other indexes within the same scope
          // avoids holding data in memory for indexes that are not being used
          [index ?? "default"]: permissions,
        },
      }));
    },
    [setScopedPermissions]
  );

  return (
    <AuthorizationContext.Provider
      value={{ scopedPermissions, setPermissionInScope }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}

/**
 * Hook to get permissions from the context
 *
 * @param scope AuthorizationScope
 * @returns permissions
 */
export function useAuthorizationScoped(
  scope: AuthorizationScope,
  index?: string
): AuthorizationContextType["scopedPermissions"][typeof scope][string] {
  const context = React.useContext(AuthorizationContext);
  if (context === undefined) {
    console.error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
  }

  return context?.scopedPermissions[scope][index ?? "default"] ?? notAsked();
}

/**
 * Hook to dispatch permissions to the context
 *
 * @param scope AuthorizationScope
 * @returns setPermissionInScope
 */
export function usePermissionDispatcher(
  scope: AuthorizationScope,
  index?: string
) {
  const context = React.useContext(AuthorizationContext);
  if (context === undefined) {
    console.error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
    throw new Error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
  }
  const setPermissionInScope = context.setPermissionInScope;

  const hasData = useMemo(
    () =>
      context.scopedPermissions[scope][index ?? "default"]?.hasData() ?? false,
    [context.scopedPermissions, scope, index]
  );

  return useCallback(
    (permissions: RemoteData<Error, AuthorizationMap>) => {
      if (!hasData) {
        setPermissionInScope(scope, permissions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
