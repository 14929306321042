import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React from "react";
import { FormInput } from "src/components/Inputs/FormInput";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { validateWithZod } from "src/services/formValidations";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { ADD_PERSON_RELATIONSHIP } from "../graphql/mutations";
import { GET_PERSON_RELATIONSHIPS } from "../graphql/queries";

const FormSchema = z.object({
  relationshipId: z.string().min(36, "A 36 digit ID is required"),
});

type FormType = z.infer<typeof FormSchema>;
type Props = {
  id: string;
  isOpen: boolean;
  entityType: GQL.person_type_enum;
  onCancel: () => void;
  onAddRelationshipSuccess: () => void;
};
export const AddRelationshipDialog: React.FC<Props> = ({
  id,
  isOpen,
  entityType,
  onCancel,
  onAddRelationshipSuccess,
}) => {
  const toast = useToast();
  const { glossary } = useGlossary();

  const initialValues = {
    relationshipId: "",
  };

  const dialogBodyText = `Enter a ${
    entityType === GQL.person_type_enum.guardian ? "Student" : "Parent"
  } ID to add as a relationship.`;

  const nonExistingRelationshipError = `${
    entityType === GQL.person_type_enum.guardian ? "Student" : "Parent"
  } ID doesn't exist.`;

  const [addPersonRelationship] = useRemoteDataMutation<
    GQL.AddPersonRelationship,
    GQL.AddPersonRelationshipVariables
  >(ADD_PERSON_RELATIONSHIP, {
    refetchQueries: [GET_PERSON_RELATIONSHIPS],
  });

  const onSubmit = React.useCallback(
    async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
      try {
        await addPersonRelationship({
          variables: {
            relationship: {
              first_person: id,
              second_person: values.relationshipId,
            },
          },
        });
        onAddRelationshipSuccess();

        if (!toast.isActive(id)) {
          toast({
            id,
            title: glossary`Relationship added`,
            isClosable: true,
          });
        }
      } catch (error: any) {
        console.error(error);

        if (
          error?.graphQLErrors?.length > 0 &&
          error?.graphQLErrors[0]?.extensions?.code === "constraint-violation"
        ) {
          formikHelpers.setFieldError(
            "relationshipId",
            nonExistingRelationshipError
          );
        } else if (!toast.isActive(id)) {
          toast({
            id,
            title: glossary`Error adding relationship`,
            description:
              "Please try again later or report the problem to our support team.",
            isClosable: true,
            status: "error",
          });
        }
      }
    },
    [
      addPersonRelationship,
      glossary,
      id,
      toast,
      onAddRelationshipSuccess,
      nonExistingRelationshipError,
    ]
  );

  const onCancelButtonClick = React.useCallback(
    (formik: FormikProps<FormType>) => {
      formik.resetForm();
      onCancel();
    },
    [onCancel]
  );
  return (
    <Formik<FormType>
      initialValues={initialValues}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={onSubmit}
      validate={validateWithZod(FormSchema)}
    >
      {(formik) => {
        return (
          <Modal isOpen={isOpen} onClose={onCancel}>
            <ModalOverlay />
            <ModalContent as={Form}>
              <ModalHeader>Add relationship</ModalHeader>
              <ModalBody as={Flex} direction="column" gap={4}>
                <Text>{dialogBodyText}</Text>
                <Field name="relationshipId">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <FormInput
                        label={
                          entityType === GQL.person_type_enum.guardian
                            ? "Student ID"
                            : "Parent ID"
                        }
                        {...field}
                        error={meta.error}
                      />
                    );
                  }}
                </Field>
              </ModalBody>
              <ModalFooter gap={2}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={() => onCancelButtonClick(formik)}
                >
                  Cancel
                </Button>
                <Button type="submit">Add relationship</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
