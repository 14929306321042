import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { SchoolRankingEditList } from "src/components/Form/SchoolRankingEditList";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { SchoolWithFakeRank } from "src/scenes/parent/forms/LateEdit";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { RemoteDataView } from "../../Layout/RemoteDataView";
import { MultiSelectOrderableList } from "../../MultiSelectOrderableList";
import { schoolSelectionLimitText } from "./helpers";
import { useEligibleSchools } from "src/services/eligibility/useEligibleSchools";
import { SchoolListItem } from "./SchoolListItem";
import { Loading, SchoolWithBoundaryTags } from ".";
import * as BoundaryService from "src/components/Boundary/services";
import * as RD from "src/types/remoteData";
import { StatusTagMetadata } from "src/components/DataDisplay/StatusTag";
import { EligibilityServiceError } from "src/components/Feedback/EligibilityServiceError";

export type School = {
  readonly id: uuid;
  readonly name: string;
  readonly street_address: string | null;
  readonly boundaryTags?: readonly string[];
};
export type RankedSchool = {
  readonly rank: number;
  readonly school: School;
  readonly sub_status: string | null;
};

interface LateMultiSelectSchoolRankProps {
  formTemplateId: uuid;
  formId: uuid;
  preRankingSection: AF.PreRankingSection<AF.WithId>;
  schoolRankingSection: AF.SchoolRankingSection<AF.WithId>;
  newSchools: SchoolWithFakeRank[];
  selectedSchoolsRank: readonly GQL.GetSchoolsRank_form_school_rank[];
  onIsDropdownOpenChange?: (isOpen: boolean) => void;
  onLateDeleteSchoolRank: (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => void;
  onLateSelectedItem: (school: School) => void;
  onLateDeleteNewSchool: (school: School) => void;
}

export const LateMultiSelectSchoolRank: React.FC<
  LateMultiSelectSchoolRankProps
> = ({
  formId,
  formTemplateId,
  preRankingSection,
  schoolRankingSection,
  onIsDropdownOpenChange,
  newSchools,
  selectedSchoolsRank,
  onLateDeleteSchoolRank,
  onLateSelectedItem,
  onLateDeleteNewSchool,
}) => {
  const { glossary } = useGlossary();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const selectedSchools = React.useMemo(
    () => [
      ...selectedSchoolsRank?.map(
        (form_school_rank) => form_school_rank.school
      ),
      ...newSchools,
    ],
    [selectedSchoolsRank, newSchools]
  );

  const eligibleSchoolsRemoteData = useEligibleSchools({
    formId,
    formTemplateId,
    preRankingSection,
    selectedSchools,
  });

  const schoolLimitReached =
    schoolRankingSection.maxSchools === undefined
      ? false
      : selectedSchools.length >= schoolRankingSection.maxSchools;
  const renderListItem = (school: School) => (
    <SchoolListItem
      school={school}
      showAddress
      textProps={{ fontWeight: 600 }}
    />
  );

  const renderSelectItem = (school: School, subStatus?: StatusTagMetadata) => (
    <SchoolListItem
      school={school}
      textProps={{ fontWeight: 600 }}
      subStatus={subStatus}
    />
  );
  const {
    confirm: confirmLateDeletion,
    confirmationDialog: lateDeleteConfirmationDialog,
    setBody,
  } = useConfirmationDialog({
    header: glossary`Remove school?`,
    body: null,
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, remove",
      colorScheme: "red",
    },
    translate: true,
  });

  const handleLateDeleteSchoolRank = async (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => {
    if (
      schoolRank.sub_status === GQL.offer_status_enum.Accepted ||
      schoolRank.sub_status === GQL.offer_status_enum.Offered
    ) {
      setBody(
        <Text>
          This will remove it from your child’s form and decline the offer.
        </Text>
      );
    } else if (schoolRank.sub_status === GQL.waitlist_status_enum.Waitlisted) {
      setBody(
        <Text>
          This will remove it from your child’s form and withdraw from the
          waitlist.
        </Text>
      );
    } else {
      setBody(<Text>This will remove it from your child’s form.</Text>);
    }
    if (!(await confirmLateDeletion({}))) {
      return;
    }

    onLateDeleteSchoolRank(schoolRank);
  };

  const handleLateDeleteNewSchool = async (school: School) => {
    setBody(<Text>This will remove it from your child’s form.</Text>);

    if (!(await confirmLateDeletion({}))) {
      return;
    }
    onLateDeleteNewSchool(school);
  };

  const renderSelectedItems = (schools: readonly School[]) => {
    const schoolsRank: readonly GQL.GetSchoolsRank_form_school_rank[] =
      selectedSchoolsRank.map((sr) => {
        const school = schools.find((s) => s.id === sr.school.id);
        return {
          ...sr,
          school: {
            ...sr.school,
            boundaryTags: school?.boundaryTags,
          },
        };
      });
    return (
      <Flex
        direction="column"
        alignItems="center"
        maxWidth="25rem"
        width="100%"
        alignSelf="center"
      >
        {schools.length === 0 ? (
          <Box fontSize="xs" fontWeight="600">
            <Glossary>There are no schools selected</Glossary>
          </Box>
        ) : (
          <SchoolRankingEditList
            newSchools={newSchools}
            schoolsRank={schoolsRank}
            onDeleteSchoolRank={handleLateDeleteSchoolRank}
            onDeleteNewSchool={handleLateDeleteNewSchool}
            renderSelectItem={renderSelectItem}
            rankingEnabled={schoolRankingSection.rankingEnabled ?? false}
          />
        )}
      </Flex>
    );
  };

  let schoolSelectionLimit: React.ReactNode = null;
  if (!isDropdownOpen) {
    const text = schoolSelectionLimitText(glossary, {
      limitReached: schoolLimitReached,
      maxSchools: schoolRankingSection.maxSchools,
      minSchools: schoolRankingSection.minSchools,
    });

    if (text) {
      schoolSelectionLimit = (
        <Text
          textAlign="center"
          alignSelf="center"
          color="gray.500"
          fontSize="sm"
        >
          {text}
        </Text>
      );
    }
  }

  const { withBoundaryTagsForList } = BoundaryService.useBoundaryTags<School>({
    formId,
  });
  const remoteData = useMemo(() => {
    return eligibleSchoolsRemoteData.andThen((eligibleSchools) => {
      // Add boundary tags to eligible schools list
      const eligibleSchoolsWithBoundaryTagsRD = withBoundaryTagsForList(
        eligibleSchools
      ).map(BoundaryService.sortByBoundaryTags);

      // Add boundary tags to selected schools list
      const selectedSchoolsWithBoundaryTagsRD =
        withBoundaryTagsForList(selectedSchools);

      // Combine two remote data into tuple
      return RD.toTuple(
        eligibleSchoolsWithBoundaryTagsRD,
        selectedSchoolsWithBoundaryTagsRD
      );
    });
  }, [eligibleSchoolsRemoteData, selectedSchools, withBoundaryTagsForList]);

  return (
    <Box width="100%" position="relative" display="block">
      <RemoteDataView
        error={(error) => <EligibilityServiceError error={error} />}
        remoteData={remoteData}
        loading={<Loading />}
      >
        {([
          eligibleSchoolsWithBoundaryTags,
          selectedSchoolsWithBoundaryTags,
        ]) => {
          return (
            <>
              <MultiSelectOrderableList<SchoolWithBoundaryTags>
                items={eligibleSchoolsWithBoundaryTags}
                selectedItems={selectedSchoolsWithBoundaryTags}
                keyAttr="id"
                searchableFields={["name", "street_address"]}
                onSelectedItemChange={(school) => onLateSelectedItem(school)}
                renderListItem={renderListItem}
                renderSelectedItems={renderSelectedItems}
                button={{
                  label: glossary`Add school`,
                  disabled: schoolLimitReached,
                }}
                onIsDropdownOpenChange={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                  if (onIsDropdownOpenChange) onIsDropdownOpenChange(isOpen);
                }}
              />

              {schoolSelectionLimit}
            </>
          );
        }}
      </RemoteDataView>
      {lateDeleteConfirmationDialog}
    </Box>
  );
};
