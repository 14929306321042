import {
  Button,
  ButtonProps,
  Flex,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import React from "react";
import { ClosableTag } from "../Feedback/ClosableTag";
import {
  CustomFormInput,
  CustomFormInputProps,
} from "../Inputs/CustomFormInput";
import { processIds } from "./services";
import { Item } from "./types";

type Props = {
  formTextareaProps?: Omit<CustomFormInputProps, "children"> & TextareaProps;
  addButtonProps?: ButtonProps;
  items: Item[];
  selectedIds: string[];
  onItemRemoved?: (item: Item) => void;
  onItemsAdded?: (items: Item[], invalidIds: string[]) => void;
  label: string;
  error?: string;
};
export const BulkMultiSelect: React.FC<Props> = ({
  formTextareaProps,
  onItemRemoved,
  items,
  selectedIds,
  onItemsAdded,
  label,
  error,
  addButtonProps,
}) => {
  const [value, setValue] = React.useState("");
  const addButtonClicked = React.useCallback(() => {
    if (!onItemsAdded) {
      return;
    }
    const [newSelectedItems, invalidIds] = processIds(
      value,
      items,
      selectedIds
    );
    onItemsAdded(newSelectedItems, invalidIds);
    setValue(invalidIds.join(","));
  }, [items, onItemsAdded, selectedIds, value]);
  return (
    <Flex direction="column" alignItems="flex-start" gap="2">
      <CustomFormInput
        label={label}
        error={error}
        formLabelProps={{ color: "gray.600" }}
        formControlProps={{ height: "unset" }}
        {...formTextareaProps}
      >
        <Flex gap="3" direction="column">
          {selectedIds.length > 0 && (
            <Flex gap="3" wrap="wrap">
              {selectedIds.map((id) => {
                const item = items.find((item) => item.id === id);
                if (!item) return null;
                return (
                  <ClosableTag
                    key={id}
                    label={item.label}
                    onClose={() => {
                      if (onItemRemoved) onItemRemoved(item);
                    }}
                    size={"sm"}
                  />
                );
              })}
            </Flex>
          )}
          <Textarea
            bg="white"
            height="16"
            {...formTextareaProps}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            value={value}
          />
        </Flex>
      </CustomFormInput>
      <Button
        size="md"
        variant="outline"
        colorScheme="gray"
        onClick={addButtonClicked}
        {...addButtonProps}
      >
        Add
      </Button>
    </Flex>
  );
};
