import { MenuList, Skeleton } from "@chakra-ui/react";
import React from "react";
import * as AF from "src/types/formTemplate";
import { SingleSelectUndo } from "./SingleSelectUndo";
import { FreeTextUndo } from "./FreeTextUndo";
import { MultiSelectUndo } from "./MultiSelectUndo";
import { GradesUndo } from "./GradesUndo";
import { UndoMenu } from "./UndoMenu";
import { Answer } from "src/services/formTemplate";

export type Props = Readonly<{
  question: AF.Question<AF.WithId>;
  formId: uuid;
  answer: Answer.FormikFieldValue;
  setAnswer: (value: Answer.FormikFieldValue) => void;
}>;
export const Undo: React.FC<Props> = (props) => {
  const { question, ...restProps } = props;
  let undo: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;
  switch (question.type) {
    case AF.FreeTextType:
    case AF.EmailType:
    case AF.PhoneNumberType:
      undo = (initialFocusRef) => (
        <FreeTextUndo
          {...restProps}
          question={question}
          initialFocusRef={initialFocusRef}
        />
      );
      break;
    case AF.SingleSelectType:
      undo = (initialFocusRef) => (
        <SingleSelectUndo
          {...restProps}
          question={question}
          initialFocusRef={initialFocusRef}
        />
      );
      break;
    case AF.MultiSelectType:
      undo = (initialFocusRef) => (
        <MultiSelectUndo
          {...restProps}
          question={question}
          initialFocusRef={initialFocusRef}
        />
      );
      break;
    case AF.GradesType:
      undo = (initialFocusRef) => (
        <GradesUndo
          {...restProps}
          question={question}
          initialFocusRef={initialFocusRef}
        />
      );
      break;
    default:
      return null;
  }

  return <UndoMenu>{undo}</UndoMenu>;
};

export const Loading = () => {
  return (
    <MenuList display="flex" flexDirection="column" gap="2" paddingX="3">
      <Skeleton width="100%" height="1.5rem" />
      <Skeleton width="100%" height="1.5rem" />
    </MenuList>
  );
};
