import { Input } from "@chakra-ui/react";
import { FieldHookConfig, useField } from "formik";
import React from "react";
import { DefaultInputComponentProps } from "react-phone-number-input";
import { canonicalPhoneNumber, formatUSPhoneNumber } from "src/services/format";

export type Props = {
  id: string;
  name: string;
  isRequired?: boolean;
} & FieldHookConfig<string> &
  DefaultInputComponentProps;

export const PhoneInput: React.FC<Props> = (config) => {
  const [field, , helpers] = useField<string>(config.id);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "+") {
      helpers.setValue("");
      return;
    }
    helpers.setTouched(event.target.value !== "");
    helpers.setValue(canonicalPhoneNumber(event.target.value));
  };

  return (
    <Input
      id={config.id}
      name={config.id}
      type="tel"
      value={formatUSPhoneNumber(field.value)}
      onChange={handleOnChange}
      isDisabled={config.isDisabled}
      isRequired={config.isRequired}
    />
  );
};
