import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FORM_STATUS } from "src/constants";
import { useGlossary } from "src/hooks/useGlossary";
import * as GQL from "src/types/graphql";
import { Glossary } from "../Text/Glossary";

interface FormStatusMenuProps {
  formStatus?: GQL.form_status_enum;
  onSelect: (status: GQL.form_status_enum) => void;
  onClick?: (evt: React.MouseEvent) => Promise<boolean>;
  label: string;
  leftIcon?: React.ReactElement;
  isLoading: boolean;
}

export function FormStatusMenu({
  formStatus,
  onSelect,
  onClick,
  label,
  leftIcon,
  isLoading,
}: FormStatusMenuProps) {
  const { glossary } = useGlossary();
  const handleSelectChange = (value: string | string[]) => {
    onSelect(value as GQL.form_status_enum);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<RiArrowDropDownLine />}
        size="sm"
        variant="banner"
        leftIcon={leftIcon}
        onClick={onClick}
      >
        {label}
      </MenuButton>

      <MenuList>
        <MenuOptionGroup onChange={handleSelectChange} value={formStatus || ""}>
          {(Object.keys(FORM_STATUS) as GQL.form_status_enum[]).map(
            (key, index) =>
              FORM_STATUS[key].isActive && (
                <MenuItemOption
                  key={index}
                  value={key}
                  isDisabled={
                    isLoading ||
                    key === GQL.form_status_enum.Withdrawn ||
                    key === GQL.form_status_enum.Admissions
                  }
                >
                  <Text>
                    <Glossary>{FORM_STATUS[key].label}</Glossary>
                  </Text>

                  <Text fontSize="sm">
                    {formStatus === key
                      ? glossary`${FORM_STATUS[key].menuItemSelectedDescription}`
                      : glossary`${FORM_STATUS[key].menuItemDescription}`}
                  </Text>
                </MenuItemOption>
              )
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
