import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
  DrawerContent,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import {
  RiArchiveLine,
  RiCalendarTodoLine,
  RiHome2Line,
  RiLogoutCircleRLine,
  RiQuestionLine,
  RiSettings4Line,
  RiUserLine,
} from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserProfile } from "src/hooks/useUserProfile";
import { ReactComponent as AvelaLogo } from "src/images/logo-gray.svg";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";
import * as Format from "src/services/format";
import * as Url from "src/services/url";
import { NavGroup, NavLinkItem } from ".";
import { AnimationContext } from "./AnimationContext";
import { ParentOrganizationSwitcher } from "./ParentOrganizationSwitcher";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const DrawerNav: React.FC<Props> = ({ isOpen, onClose }) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const userProfile = useUserProfile();
  const location = useLocation();

  React.useEffect(() => {
    // close the drawer when user click on menu item, thus change the url location.
    onClose();
  }, [location, onClose]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left">
      <DrawerOverlay />
      <DrawerContent className={WEGLOT_APPLY_CLASS}>
        <Flex
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <DrawerHeader flexGrow="1">
            {userProfile
              .map((profile) => {
                const fullName = Format.fullName(profile);
                return fullName ? (
                  <>
                    Hi, <span className={WEGLOT_SKIP_CLASS}>{fullName}</span>
                  </>
                ) : null;
              })
              .withDefault("")}
          </DrawerHeader>
          <DrawerCloseButton size="lg" />
        </Flex>
        <ParentOrganizationSwitcher />
        <DrawerBody position="relative">
          {/* There's some weird behavior where the link item is rendered offset to the left
           * when we have MotionBox inside the Drawer. Disabling it fix the issue
           */}
          <AnimationContext.Provider
            value={{ mode: "full", disableAnimation: true }}
          >
            <NavGroup>
              <NavLinkItem
                url={organization.map(Url.Parent.index).withDefault("#")}
                label="Home"
                leadIcon={RiHome2Line}
              />

              <NavLinkItem
                label="Important dates"
                leadIcon={RiCalendarTodoLine}
                url={organization
                  .map(Url.Parent.ImportantDate.index)
                  .withDefault("#")}
              />

              <NavLinkItem
                label={glossary`Students`}
                leadIcon={RiUserLine}
                url={organization
                  .map(Url.Parent.Student.index)
                  .withDefault("#")}
              />

              <NavLinkItem
                label={glossary`History`}
                leadIcon={RiArchiveLine}
                url={organization
                  .map(Url.Parent.History.index)
                  .withDefault("#")}
              />

              <NavLinkItem
                url={organization.map(Url.Parent.help).withDefault("#")}
                label="Help"
                leadIcon={RiQuestionLine}
              />
            </NavGroup>
            <NavGroup label="Account">
              <NavLinkItem
                url={organization.map(Url.Parent.Profile.edit).withDefault("#")}
                label="Profile Settings"
                leadIcon={RiSettings4Line}
              />
              <NavLinkItem
                url={Url.logout(location.pathname)}
                label="Log out"
                leadIcon={RiLogoutCircleRLine}
              />
            </NavGroup>
          </AnimationContext.Provider>
        </DrawerBody>
        <DrawerFooter justifyContent="left">
          <Text
            fontSize="xs"
            display="flex"
            alignItems="center"
            gap={1}
            color="gray.400"
          >
            Powered by <AvelaLogo aria-label="Avela" />
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export function useDrawerNav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return {
    isOpen,
    onClose,
    onOpen,
    drawerNav: <DrawerNav isOpen={isOpen} onClose={onClose} />,
  };
}
