import { Box, Flex, Tag as ChakraTag, useDisclosure } from "@chakra-ui/react";
import { FormSchoolRankSubStatusIndex } from "src/constants";
import { useOrganization } from "src/hooks/useOrganization";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { shouldShowApplicationSchoolRankOptionsMenu } from "../helpers";
import * as OptionsMenu from "./OptionsMenu";
import * as SubStatusTag from "./SubStatusTag";
import _ from "lodash";
import React, { useMemo } from "react";
import * as TaggingInfoDialog from "src/components/Dialogs/TaggingInfoDialog";
import * as GQL from "src/types/graphql";
import * as BoundaryService from "src/components/Boundary/services";
import { BoundaryTag } from "src/components/Tags/BoundaryTag";

export type Form = {
  readonly id: uuid;
  readonly status: GQL.form_status_enum;
};
export type Offer = OptionsMenu.Offer;
export type Waitlist = SubStatusTag.Waitlist;
export type RankedSchool = {
  readonly sub_status: string | null;
  readonly tags: readonly Tag[];
};
export type Tag = {
  readonly id: uuid;
  readonly enrollment_period_tag: {
    readonly name: string;
    readonly description: string | null;
  };
};
export type School = BoundaryService.WithBoundaryTags<OptionsMenu.School>;
export interface FormSchoolRankDetailProps {
  formTemplateId: uuid;
  acceptedOfferSchoolName: string | undefined;
  form: Form;
  offer: Offer | null;
  formSchoolRank: RankedSchool;
  waitlist: Waitlist | null;
  gradeLabel: string;
  school: School;
  studentName: string;
}
export function FormSchoolRankDetail(props: FormSchoolRankDetailProps) {
  const {
    formTemplateId,
    acceptedOfferSchoolName,
    form,
    offer,
    formSchoolRank,
    waitlist,
    gradeLabel,
    school,
    studentName,
  } = props;

  const organization = useOrganization();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const showSubstatus =
    (form.status === GQL.form_status_enum.Submitted ||
      form.status === GQL.form_status_enum.Verified ||
      form.status === GQL.form_status_enum.LotteryReady ||
      form.status === GQL.form_status_enum.Admissions) &&
    !!formSchoolRank.sub_status;

  const showOptionsMenu =
    organization.hasData() &&
    shouldShowApplicationSchoolRankOptionsMenu(
      offer,
      waitlist,
      organization.data
    );

  const { tags, firstTag } = useMemo(() => {
    const boundaryTags: readonly TaggingInfoDialog.Tag[] = (
      school.boundaryTags ?? []
    ).map((name) => ({
      type: "BoundaryTag",
      name,
    }));

    const formSchoolRankTags: readonly TaggingInfoDialog.Tag[] = _.orderBy(
      formSchoolRank.tags,
      (tag) => tag.enrollment_period_tag.name.toLocaleLowerCase()
    ).map((tag) => ({
      type: "FormSchoolRankTag",
      id: tag.id,
      name: tag.enrollment_period_tag.name,
      description: tag.enrollment_period_tag.description,
    }));

    const tags: readonly TaggingInfoDialog.Tag[] =
      boundaryTags.concat(formSchoolRankTags);

    const tag = tags[0];
    let firstTag: React.ReactNode = null;
    switch (tag?.type) {
      case "FormSchoolRankTag":
        firstTag = (
          <ChakraTag key={tag.id} variant="tag" fontSize="xs">
            <Box whiteSpace="nowrap">{tag.name}</Box>
          </ChakraTag>
        );
        break;
      case "BoundaryTag":
        firstTag = <BoundaryTag tag={tag} fontSize="xs" />;
        break;
    }

    return {
      tags,
      firstTag,
    };
  }, [formSchoolRank.tags, school.boundaryTags]);

  const allTagsCount = tags.length;
  const formSchoolRankTagging = useMemo(() => {
    if (showSubstatus && allTagsCount > 0) {
      return (
        <Flex>
          <ChakraTag
            key="form-school-tag"
            variant="tag"
            fontSize="xs"
            cursor="pointer"
          >
            <Box>+{tags.length} more</Box>
          </ChakraTag>
        </Flex>
      );
    }

    if (!showSubstatus && allTagsCount > 1) {
      return (
        <Flex>
          <ChakraTag
            key="form-school-tag"
            variant="tag"
            fontSize="xs"
            cursor="pointer"
          >
            <Box>+{allTagsCount - 1} more</Box>
          </ChakraTag>
        </Flex>
      );
    }
  }, [allTagsCount, showSubstatus, tags.length]);

  return (
    <Flex alignItems="center" gap={2} flexGrow={1}>
      <Flex direction="column" onClick={onOpen} cursor="pointer">
        <Flex alignItems="start" gap="2">
          <div className={WEGLOT_SKIP_CLASS}>{school.name}</div>
          {showSubstatus ? (
            <SubStatusTag.SubStatusTag
              formSchoolRankSubStatus={
                formSchoolRank.sub_status as FormSchoolRankSubStatusIndex
              }
              waitlist={waitlist}
              hasOffer={!!offer}
            />
          ) : (
            firstTag
          )}
        </Flex>
        {formSchoolRankTagging}
      </Flex>

      {showOptionsMenu && (
        <OptionsMenu.OptionsMenu
          formTemplateId={formTemplateId}
          acceptedOfferSchoolName={acceptedOfferSchoolName}
          formId={form.id}
          offer={offer}
          waitlist={waitlist}
          gradeLabel={gradeLabel}
          school={school}
          studentName={studentName}
        />
      )}

      <TaggingInfoDialog.TaggingInfoDialog
        isOpen={isOpen}
        offer={offer}
        waitlist={waitlist}
        onCancel={onClose}
        schoolName={school.name}
        tags={tags}
        subStatus={formSchoolRank.sub_status as FormSchoolRankSubStatusIndex}
      />
    </Flex>
  );
}
