import { MenuItem, Text } from "@chakra-ui/react";
import React from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { GET_ACTIVE_FORM_TEMPLATES } from "src/scenes/parent/formTemplates/graphql/queries";
import * as GQL from "src/types/graphql";
import { WITHDRAW_FORM } from "../../graphql/mutations";

interface Props {
  formId: uuid;
  schoolId: uuid;
  school: string;
  grade: string;
}

export const WithdrawApp = ({ formId, schoolId, school, grade }: Props) => {
  const [withdrawApp] = useRemoteDataMutation<
    GQL.WithdrawForm,
    GQL.WithdrawFormVariables
  >(WITHDRAW_FORM);

  const toast = useAvelaToast({ position: "bottom" });

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "No, cancel",
      },
      confirmButton: {
        label: "Yes, withdraw",
        colorScheme: "red",
      },
      translate: true,
    });

  const handleOnClick = async (evt: React.MouseEvent) => {
    setHeader(<Text>Withdraw form?</Text>);
    setBody(
      <Text>{`This will remove your form from ${school} for the ${grade} waitlist.\n\n This can't be undone nor recovered.`}</Text>
    );

    if (await confirm()) {
      try {
        await withdrawApp({
          variables: {
            form_id: formId,
            school_id: schoolId,
          },
          refetchQueries: [GET_ACTIVE_FORM_TEMPLATES],
        });

        toast({
          title: "Withdrawn from waitlist",
          status: "info",
        });
      } catch (err) {
        toast.error({
          title: "Something went wrong!",
          description: "Check your network and try again.",
        });
        console.error(err);
      }
    }
  };

  return (
    <>
      <MenuItem
        textColor="gray.700"
        fontWeight="400"
        fontSize="sm"
        onClick={handleOnClick}
      >
        Withdraw from waitlist
      </MenuItem>
      {confirmationDialog}
    </>
  );
};
