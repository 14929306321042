import { Flex } from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import { FormTemplateCard } from "./FormTemplateCard";

interface Props {
  formTemplates: GQL.GetFormTemplatesByEnrollmentPeriod_form_template[];
}

export const FormTemplatesList: React.FC<Props> = ({ formTemplates }) => {
  return (
    <Flex direction="column" gap={4}>
      {formTemplates.map((formTemplate) => (
        <FormTemplateCard key={formTemplate.id} formTemplate={formTemplate} />
      ))}
    </Flex>
  );
};
