import { Flex, Image, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";

export const ScheduledDowntime: FunctionComponent = () => {
  const scheduledDowntimeMessage =
    process.env.REACT_APP_MAINTENANCE_MESSAGE ??
    "You’ve caught us hard at work, we should be back by 1 AM CDT";

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center "
      gap={2}
      padding={16}
      height="100vh"
    >
      <Image
        src="/scheduled-downtime.svg"
        aria-label="404 Error"
        width="175"
        height="175"
      />
      <Text as="h1" fontSize="xl" textAlign="center" paddingTop={4}>
        Hang tight!
      </Text>
      <Text as="h2" fontSize="lg" textAlign="center" textColor="gray.500">
        {scheduledDowntimeMessage}
      </Text>
    </Flex>
  );
};
