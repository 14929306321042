import { Divider, Flex } from "@chakra-ui/react";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { MotionBox } from "src/animations/MotionBox";
import { useAnimationWithReduceMotion } from "src/hooks/useAnimation";
import { Mode, NavGroupElement, NavItemElement } from ".";
import { AnimationContext } from "./AnimationContext";

type NavChildren =
  | NavItemElement[]
  | NavGroupElement[]
  | NavGroupElement
  | NavItemElement;

type Props = {
  children: NavChildren;
  isOpen: boolean;
};

function withDivider(children: NavChildren, mode: Mode) {
  if (!Array.isArray(children)) return children;
  return (children as React.ReactElement[]).reduce(
    (acc, child, index) =>
      index === 0
        ? [child]
        : [
            ...acc,
            <AnimatePresence key={`divider-${index}`}>
              {mode === "compact" && (
                <MotionBox
                  as={Divider}
                  aria-hidden="true"
                  backgroundColor="primary.100"
                  height="1px"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.15 }}
                />
              )}
              {mode === "full" && <MotionBox height="2px" />}
            </AnimatePresence>,
            child,
          ],
    [] as React.ReactNode[]
  );
}
export function LeftNav({ children, isOpen }: Props) {
  const animate = useAnimationWithReduceMotion();
  const mode: Mode = isOpen ? "full" : "compact";
  return (
    // animation of left nav is creating weird offsets
    // when refreshing, collapsing/expanding.  disabling animation fixed it
    <AnimationContext.Provider value={{ mode, disableAnimation: true }}>
      <LayoutGroup>
        <MotionBox
          layout
          initial={false}
          as="nav"
          display="flex"
          backgroundColor="gray.50"
          flexDirection="column"
          height="100%"
          position="relative"
          overflowX="auto"
          {...animate(
            mode === "compact" ? { width: "4.5rem" } : { width: "20rem" }
          )}
        >
          <MotionBox
            layout
            initial={false}
            paddingTop="2rem"
            paddingBottom="2rem"
            {...animate(
              mode === "compact"
                ? {
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }
                : {
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }
            )}
          >
            <Flex as="ul" direction="column" gap={0}>
              {withDivider(children, mode)}
            </Flex>
          </MotionBox>
        </MotionBox>
      </LayoutGroup>
    </AnimationContext.Provider>
  );
}
