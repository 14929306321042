import { gql } from "@apollo/client";

export const UPSERT_USER_ADDRESS = gql`
  mutation UpsertUserAddress($user_address: user_address_insert_input!) {
    insert_user_address_one(
      object: $user_address
      on_conflict: {
        constraint: user_address_user_id_street_address_street_address_line_2_city_
        update_columns: [updated_at]
      }
    ) {
      id
      updated_at
    }
  }
`;
