import * as ls from "src/services/localStorage";
import { EXPLORE_SAVED_SCHOOL_IDS } from "src/types/localStorageKeys";
export type School = {
  id: uuid;
  referenceID: string;
  name: string;
  gradeConfigIDs: uuid[];
};

export type Context = {
  exploreSavedSchools: School[];
  unresolvedReferenceIDs: string[];
};

export function getContext(): Context | null {
  return ls.getLocalStorageRecord<Context>(EXPLORE_SAVED_SCHOOL_IDS);
}

export function saveContext(ctx: Context | null): void {
  return ls.saveLocalStorageRecord<Context>(EXPLORE_SAVED_SCHOOL_IDS, ctx);
}
