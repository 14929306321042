import { html } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/utils";
import * as GQL from "src/types/graphql";

export const WAITLIST_WITHDRAWN = {
  type: GQL.message_template_type_enum.WaitlistWithdrawn,
  emailSubject:
    "You have withdrawn from the waitlist at {{organization.name}}/Una oferta de {{organization.name}} ha sido revocada/Te has retirado de la lista de espera de {{organization.name}}",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    You have withdrawn {{student.name}} from the waitlist at
    {{waitlist.schoolName}}.<br />
    <br />
    If you need help, please contact {{supportEmail}}<br />
    <br />
    -{{organization.name}} Team<br />
    —---<br />
    <br />
    Estimado/a {{parent.name}},<br />
    <br />
    Ha retirado a {{student.name}} de la lista de espera en
    {{waitlist.schoolName}}.<br />
    <br />
    Si necesita ayuda, por favor contacte a {{supportEmail}}<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}}, 
You have withdrawn {{student.name}} from the waitlist at {{waitlist.schoolName}}.

If you need help, please contact {{supportEmail}}

{{organization.name}} Team

——-

Estimado/a {{parent.name}}
Ha retirado a {{student.name}} de la lista de espera en {{waitlist.schoolName}}.

Si necesita ayuda, por favor contacte a {{supportEmail}}

Equipo de {{organization.name}}`,
  sms: "You have withdrawn {{student.name}} from the waitlist at {{waitlist.schoolName}}. / Has retirado a {{student.name}} de la lista de espera en {{waitlist.schoolName}}."
};
