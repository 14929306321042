import { Outlet, Route } from "react-router-dom";
import { FormImportFlowProvider } from "src/components/FormImport/FormImportFlowContext";
import { EnrollmentPeriodProvider } from "src/components/Providers/EnrollmentPeriodProvider";
import { FormTemplateProvider } from "src/components/Providers/FormTemplateProvider";
import { AuthCallback } from "../components/Permissions/AuthCallback";
import { HasuraRoleRedirector } from "../components/Permissions/HasuraRoleRedirector";
import { RequireAuth } from "../components/Permissions/RequireAuth";
import { RequireEmailVerification } from "../components/Permissions/RequireEmailVerification";
import { Auth0ProviderWithHistory } from "../components/Providers/Auth0ProviderWithHistory";
import * as Url from "../services/url";
import { ORGANIZATION } from "../services/url/constants";
import { AvelaAdminRoutes } from "./admin";
import { OrgAdminRoutes } from "./orgAdmin/routes";
import { ParentPortalRoutes } from "./parent/routes";
import * as Public from "./public";
import { Logout } from "./public/Logout";
import { FeatureFlagProvider } from "src/components/Providers/FeatureFlagProvider";

export const AuthorizedAvelaRoutes = (
  <Route
    element={
      <Auth0ProviderWithHistory>
        <FeatureFlagProvider>
          <Outlet />
        </FeatureFlagProvider>
      </Auth0ProviderWithHistory>
    }
  >
    <Route path={Url.AuthCallback.path} element={<AuthCallback />} />
    <Route path={Url.AuthCallback.orgPath} element={<AuthCallback />} />
    <Route path="/" element={<Public.Home />} />

    <Route
      path={Url.emailVerificationPath}
      element={<Public.EmailVerification />}
    />

    <Route path={Url.logoutPath} element={<Logout />} />
    <Route element={<RequireAuth />}>
      <Route element={<RequireEmailVerification />}>
        <Route path={Url.redirectPath} element={<HasuraRoleRedirector />} />
        {AvelaAdminRoutes}
      </Route>

      <Route path={`/${ORGANIZATION}`}>
        <Route path={Url.verifyEmailPath} element={<Public.VerifyEmail />} />

        <Route element={<RequireEmailVerification />}>
          <Route
            element={
              <FormImportFlowProvider>
                <EnrollmentPeriodProvider>
                  <FormTemplateProvider>
                    <Outlet />
                  </FormTemplateProvider>
                </EnrollmentPeriodProvider>
              </FormImportFlowProvider>
            }
          >
            {OrgAdminRoutes}
          </Route>
          {ParentPortalRoutes}
        </Route>
      </Route>
    </Route>
  </Route>
);
