import _ from "lodash";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { isNotNull } from "../../predicates";
import { Strict } from "../Strict";
import { sortByOrder } from "../sorter";
import { toQuestionWithBranching } from "./question";

export function fromGQL(
  value: GQL.FormTemplateSectionFragment,
  strict: Strict = "strict"
): AF.GeneralSection<AF.WithId> {
  if (value.type !== GQL.form_template_section_type_enum.GeneralSection) {
    throw new Error(
      `Invalid section type, expecting a "GeneralSection", but got ${value.type} instead`
    );
  }
  if (!value.questions) {
    throw new Error("Missing questions data");
  }
  return {
    id: value.id,
    type: AF.GeneralSectionType,
    title: value.title,
    description: value.description ?? "",
    key: value.key,
    permissionLevel: value.permission_level,
    familyEditableStatuses: value.family_editable_statuses,
    questions: _.sortBy(value.questions, sortByOrder)
      .map((question) => toQuestionWithBranching(question, strict))
      .filter(isNotNull),
    filters: AFF.parseFilters(value.filters),
  };
}
