import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as ConfigForm from "./components/ConfigForm";
import { AllOrgConfigs } from "./types";
import { validateWithZod } from "src/services/formValidations";
import { z } from "zod";
import { InputControl } from "formik-chakra-ui";
import {
  AnnouncementForm,
  DESCRIPTION_CONTENT_LIMIT,
} from "./components/AnnouncementForm";

export const FormikSchema = z.object({
  name: z.string().min(1, "This field is required"),
  path: z.string().min(1, "This field is required"),
  timezoneName: z.string().min(1, "This field is required"),
  organizationConfigs: ConfigForm.ForkmikSchema,
  noStudentAnnouncement: z.object({
    id: z.string(),
    title: z.string(),
    description: z
      .string()
      .max(
        DESCRIPTION_CONTENT_LIMIT,
        `Description must be ${DESCRIPTION_CONTENT_LIMIT} characters or less.`
      ),
    active: z.boolean(),
    showType: z.string(),
    linkActive: z.boolean(),
    linkText: z.string().optional(),
    linkUrl: z.string().optional(),
  }),
});

export type OrganizationFormSchema = z.infer<typeof FormikSchema>;

interface OrganizationFormProps {
  organization?: GQL.GetOrganization_organization_by_pk;
  configs?: AllOrgConfigs;
  onSubmit: (values: OrganizationFormSchema) => void;
  submitting: boolean;
}

export const OrganizationForm = ({
  organization,
  configs,
  onSubmit,
  submitting,
}: OrganizationFormProps) => {
  const isNew = !organization;
  const timezoneNames = React.useMemo(() => {
    return (Intl as any).supportedValuesOf("timeZone");
  }, []);

  /* October 2024, 
    we only have NO_STUDENT condition
    that belongs on the PARENT_PORTAL entry_point
    with the INFO type announcement 
    but we can have more in the future
  */
  const noStudentAnnouncement = organization?.announcements.find(
    (announcement) =>
      announcement.condition === GQL.announcement_condition_enum.NO_STUDENT
  );

  const initialValues: OrganizationFormSchema = useMemo(() => {
    return {
      organizationConfigs: ConfigForm.getInitialValues(configs),
      name: organization?.name || "",
      path: organization?.path || "",
      timezoneName: organization?.timezone_name || "+00:00",
      noStudentAnnouncement: {
        id: noStudentAnnouncement?.id || "",
        title: noStudentAnnouncement?.title || "",
        description: noStudentAnnouncement?.description || "",
        active: noStudentAnnouncement?.active ?? false,
        showType:
          noStudentAnnouncement?.display_type ??
          GQL.announcement_display_type_enum.BANNER,
        linkActive:
          !!noStudentAnnouncement?.link_text &&
          !!noStudentAnnouncement?.link_url,
        linkText: noStudentAnnouncement?.link_text || "",
        linkUrl: noStudentAnnouncement?.link_url || "",
      },
    };
  }, [
    configs,
    organization?.name,
    organization?.path,
    organization?.timezone_name,
    noStudentAnnouncement,
  ]);

  function editPolicyUrl(
    organization: GQL.GetOrganization_organization_by_pk
  ): string {
    return Url.Admin.Organizations.editPolicy(organization.id);
  }

  return (
    <Formik<OrganizationFormSchema>
      initialValues={initialValues}
      validate={validateWithZod(FormikSchema)}
      onSubmit={onSubmit}
    >
      <Form>
        <VStack align="left" spacing={8}>
          <Heading as="h1" size="xl">
            {isNew
              ? "Create Organization"
              : `Edit ${organization?.name || "Organization"}`}
          </Heading>
          <Heading as="h2" size="lg">
            Basic information
          </Heading>
          <Flex direction="column">
            <InputControl name="name" label="Organization Name">
              <Input size="sm" placeholder="Organization Name" />
            </InputControl>
          </Flex>
          <Flex direction="column">
            <InputControl name="path" label="Path">
              <Input size="sm" placeholder="Path" />
            </InputControl>
          </Flex>
          <Flex direction="column">
            <FormControl>
              <FormLabel htmlFor="timezoneName">
                Timezone offset from UTC
              </FormLabel>
              <Field as={Select} id="timezoneName" name="timezoneName">
                {timezoneNames.map((value: string) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Field>
            </FormControl>
          </Flex>

          <hr />

          {!!organization && (
            <>
              <Heading as="h2" size="lg">
                Access & policies
              </Heading>
              <p>Configure access policies for the organization.</p>
              <Flex align="center">
                <Button
                  as={NavLink}
                  colorScheme="gray"
                  to={editPolicyUrl(organization)}
                  aria-label={`Edit access policies`}
                  variant="outline"
                >
                  Edit access policies
                </Button>
                <Spacer />
              </Flex>
              <hr />
            </>
          )}

          <Heading as="h2" size="lg">
            General settings
          </Heading>

          <ConfigForm.ConfigForm />

          <AnnouncementForm />

          <Flex align="center">
            <Spacer />
            <Button
              as={NavLink}
              to={Url.Admin.Organizations.index()}
              variant="link"
            >
              Cancel
            </Button>
            <Button type="submit" marginLeft={4} isLoading={submitting}>
              {isNew ? "Create" : "Update"}
            </Button>
          </Flex>
        </VStack>
      </Form>
    </Formik>
  );
};
