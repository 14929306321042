import { gql } from "@apollo/client";

export const CREATE_MATCH_TEMPLATES = gql`
  mutation CreateMatchTemplates(
    $match_templates: [match_template_insert_input!]!
  ) {
    insert_match_template(objects: $match_templates) {
      returning {
        id
      }
    }
  }
`;

export const SELECT_MATCH_TEMPLATE = gql`
  mutation SelectMatchTemplate(
    $organization_id: uuid!
    $match_template_id: uuid!
  ) {
    insert_match_template_organization_one(
      object: {
        organization_id: $organization_id
        match_template_id: $match_template_id
      }
      on_conflict: {
        constraint: match_template_organization_organization_id_key
        update_columns: [match_template_id]
      }
    ) {
      id
    }
  }
`;

export const DELETE_MATCH_TEMPLATES = gql`
  mutation DeleteMatchTemplates($match_template_ids: [uuid!]!) {
    delete_match_template_organization(
      where: { match_template_id: { _in: $match_template_ids } }
    ) {
      affected_rows
    }
    delete_match_template(where: { id: { _in: $match_template_ids } }) {
      affected_rows
    }
  }
`;
