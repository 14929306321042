import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Icon, Link, Text } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/table-core";
import { useCallback } from "react";
import { RiTeamLine } from "react-icons/ri";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { BannerSelection } from "src/components/Table/BannerSelection";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useTableSelection } from "src/hooks/useTableSelection";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { csvExport } from "src/services/dataTransfer";
import { getReferenceId } from "src/services/id";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";

interface ParentsListProps {
  parents: GQL.GetParents_person[];
  limit: number;
  offset: number;
  count: number;
  onFetchAll: () => Promise<GQL.GetStudents_person[]>;
  onFetchMore: (limit: number, offset: number) => void;
  onChangeSort: (columnName: string, sortType: SortType) => void;
  onClearSearch: () => void;
}

export const ParentsList: React.FC<ParentsListProps> = ({
  parents,
  limit,
  offset,
  count,
  onFetchAll,
  onFetchMore,
  onChangeSort,
  onClearSearch,
}) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();

  const toast = useAvelaToast();

  const { selection, checkboxColumnDef } = useTableSelection({
    getId: (person: GQL.GetStudents_person) => person.id,
    count,
  });

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onChangeSort(headerId, sortType);
    },
    [onChangeSort]
  );

  function createRowLink(row: Row<GQL.GetParents_person>, text: string | null) {
    return text && userPermissions.hasOne("user:update") ? (
      <Link
        color="primary.500"
        href={organization
          .map((org) => Url.OrgAdmin.Parents.edit(org, row.original.id))
          .withDefault("#")}
        textDecoration="underline"
      >
        {text}
      </Link>
    ) : (
      text
    );
  }

  const columns: ColumnDef<GQL.GetParents_person>[] = [
    checkboxColumnDef({ id: "is_selected" }),
    {
      id: "id",
      cell: (props) => (
        <TextWithOverflowTooltip
          content={getReferenceId(props.row.original)}
          maxWidth="9em"
        />
      ),
    },
    {
      id: "first_name",
      header: "First name",
      cell: (props) => createRowLink(props.row, props.row.original.first_name),
    },
    {
      id: "last_name",
      header: "Last name",
      cell: (props) => createRowLink(props.row, props.row.original.last_name),
    },
    {
      id: "email",
      header: "Email",
      cell: (props) =>
        props.row.original.email_address || (
          <Text variant="placeholder">No email</Text>
        ),
    },
    {
      id: "phone",
      header: "Phone",
      cell: (props) =>
        props.row.original.phone_number || (
          <Text variant="placeholder">No phone</Text>
        ),
    },
    {
      id: "active",
      header: "status",
      accessorFn: (row) => (row.active ? "Active" : "Not Active"),
    },
  ];

  return parents.length ? (
    <PaginatedTable<GQL.GetParents_person>
      data={parents}
      columns={columns}
      limit={limit}
      offset={offset}
      count={count}
      onFetchMore={onFetchMore}
      sortableColumnIds={["first_name", "last_name"]}
      onChangeSort={handleChangeSort}
      banner={() => (
        <WithUserPermissions permissions={["user:read"]}>
          <BannerSelection selection={selection}>
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={DownloadIcon} />}
              onClick={async () => {
                toast({
                  title: "Export in progress",
                  description: "Download will start shortly",
                });
                const records = await selection.materialize(onFetchAll);
                csvExport(
                  records.map(({ __typename, ...person }) => person),
                  "parents.csv"
                );
              }}
            >
              Export
            </Button>
          </BannerSelection>
        </WithUserPermissions>
      )}
    />
  ) : (
    <NoDataTable
      icon={RiTeamLine}
      text="No parents found"
      buttonText="Clear search"
      onButtonAction={onClearSearch}
    />
  );
};
