import React from "react";
import * as Router from "react-router-dom";
import { Outlet } from "react-router-dom";
import useUser from "src/hooks/useUser";
import * as Url from "src/services/url";
import { Status } from "src/types/authData";

export function RequireEmailVerification() {
  const navigate = Router.useNavigate();
  const user = useUser();
  const { organization } = Router.useParams();

  React.useEffect(() => {
    async function redirect() {
      if (user.status === Status.NOT_VERIFIED && organization) {
        navigate(Url.verifyEmail(organization));
      }
    }
    redirect();
  }, [user.status, navigate, organization]);

  if (user.status === Status.NOT_VERIFIED) {
    return null;
  }

  return <Outlet />;
}
