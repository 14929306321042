import { Text } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { Glossary } from "src/components/Text/Glossary";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import * as Form from "src/services/form";
import type { GlossaryFn } from "src/types/glossary";
import { ColumnId } from "./constants";

type SchoolColumnDefConfig = {
  glossary: GlossaryFn;
};

type Row = {
  form_school_rank: { school: { name: string | null } | null } | null;
  form: {
    previous_offer: { school: { name: string } } | null;
    previous_waitlist: { school: { name: string } } | null;
  } | null;
};
export function buildSchoolColumnDef<T extends Row>({
  glossary,
}: SchoolColumnDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form_school_rank?.school?.name,
    id: ColumnId.School,
    header: glossary`apl. school`,

    cell: (props) => {
      let school = props.row.original.form_school_rank?.school?.name;
      if (!school && props.row.original.form) {
        school = Form.Related.getRelatedSchoolName(props.row.original.form);
      }

      if (school) {
        return (
          <TextWithOverflowTooltip
            minWidth="160px"
            maxWidth="260px"
            content={school}
          />
        );
      }

      return (
        <Text minWidth="160px" variant="placeholder">
          <Glossary>No school</Glossary>
        </Text>
      );
    },
  };
}
