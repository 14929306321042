import { BoxProps } from "@chakra-ui/react";
import React from "react";
import { ParentFormsLayoutInner } from "./ParentFormsLayoutInner";
import {
  BackLinkProps,
  ParentFormsLayoutOuter,
} from "./ParentFormsLayoutOuter";

type Props = {
  heading: React.ReactNode;
  children: React.ReactNode;
  headers?: React.ReactNode;
  footers?: React.ReactNode;
  backLink?: BackLinkProps;
} & BoxProps;

export const ParentFormsLayout: React.FC<Props> = ({
  heading,
  children,
  headers,
  footers,
  backLink,
  ...props
}: Props) => {
  return (
    <ParentFormsLayoutOuter backLink={backLink} heading={heading} {...props}>
      <ParentFormsLayoutInner footers={footers} headers={headers}>
        {children}
      </ParentFormsLayoutInner>
    </ParentFormsLayoutOuter>
  );
};
