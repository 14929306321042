import { Flex, Image, Text } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Flex direction="column" justifyContent="center" gap={2} paddingTop={32}>
      <Image src="/404.svg" aria-label="404 Error" width="175" height="175" />
      <Text as="h1" fontSize="xl" textAlign="center" paddingTop={4}>
        404
      </Text>
      <Text as="h2" fontSize="lg" textAlign="center" textColor="gray.500">
        Page Not Found
      </Text>
    </Flex>
  );
}
