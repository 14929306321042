import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useReducer,
} from "react";
import { DocViewerActions } from "./actions";
import { DocViewerState, initialDocViewerState, reducer } from "./reducer";

export const DocViewerContext = createContext<{
  state: DocViewerState;
  dispatch: Dispatch<DocViewerActions>;
}>({ state: initialDocViewerState, dispatch: () => null });

export const DocViewerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDocViewerState);

  return (
    <DocViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </DocViewerContext.Provider>
  );
};
