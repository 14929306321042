import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { DataLabel } from "src/components/Inputs/DataLabel";
import { PERMISSION_LEVELS } from "src/constants";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import { InfoBadge } from "../../DataDisplay/InfoBadge";
import {
  UploadFileQuestion,
  UploadFileQuestionProps,
} from "../../UploadButtons/UploadFileQuestion/UploadFileQuestion";
import { CustomQuestion, CustomQuestionProps } from "./CustomQuestion";
import {
  DynamicFormAddressBook,
  DynamicFormAddressBookProps,
} from "./DynamicFormAddressBook";
import { EmailInput } from "./EmailInput";
import { FreeTextInput } from "./FreeTextInput";
import {
  GradeSingleSelectInput,
  GradeSingleSelectProps,
} from "./GradeSingleSelectInput";
import { MultiSelect, MultiSelectProps } from "./MultiSelectInput";
import { PhoneNumberInput } from "./PhoneNumberInput";
import { SingleSelectInput, SingleSelectProps } from "./SingleSelectInput";

export interface BaseInputProps {
  id: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

export interface Option {
  key: string;
  label?: string;
  translate?: boolean;
  value?: string;
}

// IF NEEDED and future: Create and update props for each new input type
type DynamicInputProps =
  | ({ kind: typeof AF.SingleSelectType } & SingleSelectProps)
  | ({ kind: typeof AF.MultiSelectType } & MultiSelectProps)
  | ({ kind: typeof AF.FreeTextType } & BaseInputProps)
  | ({ kind: typeof AF.FileUploadType } & UploadFileQuestionProps)
  | ({ kind: typeof AF.GradesType } & GradeSingleSelectProps)
  | ({ kind: typeof AF.EmailType } & BaseInputProps)
  | ({ kind: typeof AF.PhoneNumberType } & BaseInputProps)
  | ({ kind: typeof AF.AddressType } & DynamicFormAddressBookProps)
  | ({ kind: typeof AF.CustomQuestionType } & CustomQuestionProps);

export const DynamicInput: React.FC<DynamicInputProps> = (props) => {
  switch (props.kind) {
    case AF.SingleSelectType:
      return SingleSelectInput(props);
    case AF.MultiSelectType:
      return MultiSelect(props);
    case AF.FreeTextType:
      return FreeTextInput(props);
    case AF.FileUploadType:
      return (
        <UploadFileQuestion
          id={props.id}
          formId={props.formId}
          isDisabled={props.isDisabled}
        />
      );
    case AF.GradesType:
      return GradeSingleSelectInput(props);
    case AF.EmailType:
      return EmailInput(props);
    case AF.PhoneNumberType:
      return PhoneNumberInput(props);
    case AF.AddressType:
      return DynamicFormAddressBook(props);
    case AF.CustomQuestionType:
      return <CustomQuestion {...props} />;
    default:
      const _exhaustiveCheck: never = props;
      return _exhaustiveCheck;
  }
};

export type QuestionProps = {
  question: string;
  key?: string;
  isRequired?: boolean;
  link_url?: string;
  link_text?: string;
  permissionLevel?: string;
  applicant: AFF.Types.Applicant;
  labelPostElement?: React.ReactNode;
} & DynamicInputProps;

export const Question: React.FC<QuestionProps> = (props) => {
  const [, meta] = useField(props.id);
  return (
    <FormControl
      isRequired={props.isRequired}
      isInvalid={meta.error !== undefined}
      isDisabled={props.isDisabled}
    >
      <FormLabel
        fontSize="sm"
        htmlFor={props.id}
        display="flex"
        gap={3}
        alignItems="center"
      >
        <DataLabel>{props.question}</DataLabel>
        {props.permissionLevel && (
          <InfoBadge status={PERMISSION_LEVELS.admin} />
        )}
        <Spacer />
        <Box marginRight={-3}>
          {props.labelPostElement && props.labelPostElement}
        </Box>
      </FormLabel>
      <DynamicInput {...props} />
      {props.link_url && (
        <FormHelperText
          color="primary.500"
          fontSize="14px"
          textDecoration="underline"
        >
          <Link href={props.link_url} target="_blank">
            {props.link_text ?? props.link_url}
            <Icon marginLeft="5px" as={ExternalLinkIcon} />
          </Link>
        </FormHelperText>
      )}
    </FormControl>
  );
};
