import { MotionBox } from "src/animations/MotionBox";
import { useAnimationWithReduceMotion } from "src/hooks/useAnimation";
import * as GQL from "src/types/graphql";
import { EnrollmentPeriodSelectInput } from "../Inputs/EnrollmentPeriodSelectInput";
import { AnimationContext } from "./AnimationContext";

interface EnrollmentPeriodDropdownProps {
  organization: GQL.Organization;
}

export function NavEnrollmentPeriodDropdown({
  organization,
}: EnrollmentPeriodDropdownProps) {
  const animate = useAnimationWithReduceMotion();

  return (
    <AnimationContext.Consumer>
      {({ mode, disableAnimation = true }) => {
        return (
          <MotionBox
            disableAnimation={disableAnimation}
            display="flex"
            as="li"
            margin="0"
            padding="0"
            justifyContent="center"
            alignSelf="stretch"
            layout
            {...animate(
              mode === "compact" ? { width: "2.25rem" } : { width: "100%" }
            )}
            transition={{ type: "inertia" }}
          >
            <EnrollmentPeriodSelectInput
              mode={mode}
              organization={organization}
            />
          </MotionBox>
        );
      }}
    </AnimationContext.Consumer>
  );
}
