import { FunctionComponent, useMemo } from "react";
import { AvelaSelectField } from "src/components";
import { useGlossary } from "src/hooks/useGlossary";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { fullName } from "src/services/format";
import { GuardianPerson } from "../../types";

type Props = {
  studentsByGuardian: GuardianPerson[];
};

export const SelectStudent: FunctionComponent<Props> = (props) => {
  const { studentsByGuardian } = props;
  const { glossary } = useGlossary();

  const studentOptions = useMemo(() => {
    return studentsByGuardian.map((student) => {
      return {
        label: fullName(student),
        value: student.id,
        className: WEGLOT_SKIP_CLASS,
      };
    });
  }, [studentsByGuardian]);

  return (
    <AvelaSelectField
      name="studentId"
      label="Who is this form for?"
      placeholder={glossary`Select student`}
      options={studentOptions}
    />
  );
};
