import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GenericError } from "./GenericError";

type Props = {
  children: React.ReactNode;
};
export const GenericErrorBoundary: React.FC<Props> = ({ children }) => {
  return <ErrorBoundary fallback={<GenericError />}>{children}</ErrorBoundary>;
};
