import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Schools",
      href: organization
        .map((org) => Url.OrgAdmin.Schools.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNew = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create School",
      href: organization
        .map((org) => Url.OrgAdmin.Schools.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  school: GQL.GetSchool_school_by_pk
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: school.name,
      href: organization
        .map((org) => Url.OrgAdmin.Schools.edit(org, school.id))
        .withDefault("#"),
    },
  ];
};
