import * as GQL from "src/types/graphql";
import {
  formatIsoDateToOrgLongDate,
  formatIsoDateToOrgTime,
  formatIsoDateToDescriptionDate,
} from "../services/format";
import { Organization } from "../types/graphql";
import { RemoteData } from "../types/remoteData";
/*
  We will probably need to rethink this file when introducing the status update feature.
 */
export const ParentCopyright = {
  FormTemplateDescription: (
    organization: RemoteData<unknown, Organization>,
    formTemplate?: GQL.GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates
  ): { [k: string]: string } => {
    const closeDate = formTemplate?.closed_at
      ? `${formatIsoDateToDescriptionDate(
          formTemplate?.closed_at,
          organization
        )}`
      : "forms close";
    return {
      NoForms: `Apply until ${closeDate}.`,
      WithForms: `Apply or edit forms until ${closeDate}.`,
    };
  },
  DefaultFormNextStep: (
    closedAt: string,
    organization: RemoteData<unknown, Organization>
  ) => {
    const closeDate = closedAt
      ? `${formatIsoDateToOrgLongDate(
          closedAt,
          organization
        )}, ${formatIsoDateToOrgTime(closedAt, organization)}`
      : "forms close";
    // Even if the next step doesn't show up to the parents, these need to be non-empty strings to pass validation.
    return {
      NotStarted: "",
      InProgress: `Submit form by ${closeDate}.`,
      Submitted: `You're all set! You can make edits until forms close.`,
      Cancelled:
        "You can no longer edit the form or create new forms for the same school year and student. Contact the district for more information.",
      Deleted: "This form has been deleted.",
      LotteryReady:
        "Your form was received on time and is proceeding to the lottery! We'll let you know when offers go out.",
      Withdrawn:
        "You can no longer edit the form or create new forms for the same school year and student. Contact the district for more information.",
      Verified:
        "Great, all your information is verified! Schools are selecting forms for the next phase, so you can't edit it anymore. We'll let you know when the offers start.",
      Admissions:
        "Your form may be going through the lottery and get an offer or placement soon. We’ll let you know when you get one!",
    };
  },
};
