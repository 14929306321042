import { ApolloError } from "@apollo/client";
import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC, useCallback, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  SchoolType,
  TeamMemberFormType,
  TeamType,
  UserGroupType,
  parseAccessPermission,
  toAccessPermission,
} from "src/schemas/TeamMember";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { personTypeToHasuraRole } from "src/types/hasuraRole";
import { toTuple3 } from "src/types/remoteData";
import { TeamMemberForm } from "../components/Forms/TeamMemberForm";
import { EDIT_AUTH0_USER } from "./graphql/mutations";
import {
  GET_AVAILABLE_TEAMS,
  GET_TEAM_MEMBER,
  GET_USER_GROUPS,
} from "./graphql/queries";

type EditTeamMemberFromData = {
  email_address: string;
  first_name: string;
  last_name: string;
  person_type: GQL.person_type_enum;
  user_group_id: string | null;
  user_schools: SchoolType[];
  organization_schools: SchoolType[];
  user_roles: UserGroupType[];
  teams: GQL.GetAvailableTeams["team"];
  person_team_id: string;
  user_id: string;
  enable_attending_schools: boolean;
  enable_applying_schools: boolean;
};

type EditTeamMemberContainerProps = {
  data: EditTeamMemberFromData;
  user_id: string;
};

const EditTeamMemberContainer: FC<EditTeamMemberContainerProps> = ({
  data,
  user_id,
}) => {
  const [selectedSchools, setSelectedSchools] = useState<SchoolType[]>(
    data.user_schools
  );
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedUserGroup, setSelectedRole] = useState<UserGroupType>();
  const [selectedPersonTeam, setSelectedPersonTeam] = useState<TeamType>();
  const [submitting, setSubmitting] = useState(false);
  const organization = useOrganization();

  const [updateTeamMember] = useRemoteDataMutation<
    GQL.EditAuth0User,
    GQL.EditAuth0UserVariables
  >(EDIT_AUTH0_USER);

  const handleSubmit = useCallback(
    async (teamMember: TeamMemberFormType) => {
      setSubmitting(true);
      try {
        if (!organization.hasData()) throw Error("Invalid organization.");
        if (!selectedUserGroup) throw new Error("No selected user group");

        const role = personTypeToHasuraRole(selectedUserGroup.person_type);
        const school_permissions = toAccessPermission(teamMember, role);

        const req: GQL.EditAuth0UserVariables = {
          user_id: user_id,
          first_name: teamMember.first_name,
          last_name: teamMember.last_name,
          school_ids: selectedSchools.map((x) => x.id) || [],
          hasura_role: role,
          user_group_id: selectedUserGroup.id,
          school_access_permission: school_permissions,
          team_id: selectedPersonTeam?.id || "",
        };

        await updateTeamMember({
          variables: req,
        });
        toast({
          id: "update-team-member",
          title: "Team member updated",
          isClosable: true,
          status: "info",
        });

        navigate(Url.OrgAdmin.Team.index(organization.data));
      } catch (err) {
        const description = (): string => {
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "User already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to edit team member.";
            }
          }
          return "Check your network and try again.";
        };

        toast({
          id: "update-team-member",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [
      updateTeamMember,
      navigate,
      user_id,
      toast,
      organization,
      selectedSchools,
      selectedUserGroup,
      selectedPersonTeam,
    ]
  );

  return (
    <>
      <Breadcrumb
        items={breadcrumb.team.getBreadcrumbsForEditMember(
          organization,
          user_id
        )}
        mb={8}
      />

      <Formik<TeamMemberFormType> initialValues={data} onSubmit={handleSubmit}>
        {() => {
          return (
            <Flex as={Form} direction="column" gap={6}>
              <Heading as="h1" fontSize="2xl" fontWeight="600">
                Edit Team Member
              </Heading>
              <TeamMemberForm
                onChangeUserRole={setSelectedRole}
                onChangePersonTeam={setSelectedPersonTeam}
                onSelectSchool={setSelectedSchools}
                selectedUserGroup={selectedUserGroup}
                allSchools={data.organization_schools}
                initialSchools={selectedSchools}
                userGroups={data.user_roles}
                disableEmail={true}
                teams={data.teams}
                showTeam={true}
                disableRole={data.person_type === GQL.person_type_enum.admin}
              />
              <Flex align="center" gap={6}>
                <Spacer />
                <Button
                  as={NavLink}
                  to={organization
                    .map((org) => Url.OrgAdmin.Team.index(org))
                    .withDefault("#")}
                  isLoading={organization.isLoading()}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button type="submit" isLoading={submitting}>
                  Update
                </Button>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </>
  );
};

export const EditTeamMember: FC<{}> = () => {
  const { id = "" } = useParams();
  const organization = useOrganization();

  const { remoteData: teamMemberData } = useRemoteDataQuery<
    GQL.GetTeamMember,
    GQL.GetTeamMemberVariables
  >(GET_TEAM_MEMBER, {
    variables: {
      personID: id,
      organizationID: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: availableTeams } = useRemoteDataQuery<
    GQL.GetAvailableTeams,
    GQL.GetAvailableTeamsVariables
  >(GET_AVAILABLE_TEAMS, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "cache-and-network",
  });

  const { remoteData: userGroupData } = useRemoteDataQuery<GQL.GetUserGroups>(
    GET_USER_GROUPS,
    { fetchPolicy: "no-cache" }
  );

  const remoteData = toTuple3(teamMemberData, userGroupData, availableTeams);

  if (remoteData.isLoading()) {
    return <Loading></Loading>;
  }

  return (
    <GQLRemoteDataView
      remoteData={remoteData.map(
        ([
          teamMemberData,
          userGroupData,
          teamsData,
        ]): EditTeamMemberFromData => {
          const person = teamMemberData.person[0];

          if (!person || !person.user) {
            throw new ApolloError({
              errorMessage: "Unable to load user data",
            });
          }

          const school_permissions = parseAccessPermission(
            person.user.school_users
          );

          return {
            email_address: person.email_address || "",
            first_name: person.first_name || "",
            last_name: person.last_name || "",
            user_group_id: person.user.user_group_id,
            person_type: person.person_type || GQL.person_type_enum.guardian,
            user_schools:
              person.user.school_users.map((x) => {
                return { id: x.school_id, name: x.school.name } as SchoolType;
              }) || [],
            organization_schools:
              teamMemberData.school.map((x) => {
                return { id: x.id, name: x.name } as SchoolType;
              }) || [],
            teams: teamsData.team,
            person_team_id: person.person_teams[0]?.team.id || "",
            user_roles: userGroupData.user_group,
            user_id: person.user.id,
            ...school_permissions,
          };
        }
      )}
    >
      {(data) => {
        return (
          <EditTeamMemberContainer
            data={data}
            user_id={data.user_id}
          ></EditTeamMemberContainer>
        );
      }}
    </GQLRemoteDataView>
  );
};
