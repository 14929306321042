import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import * as DateFns from "date-fns";
import { useFormikContext } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import {
  BirthdateEligibilityFilter,
  EligibleFilterType,
  FormTemplateFilter,
} from "src/types/formTemplateFilters";
import * as GQL from "src/types/graphql";
import { FormType, getBirthdateFilterFormValue } from "../formik";

type Props = {
  questionId: uuid;
  disabled?: boolean;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
};
export const ConfigureDateOfBirth: React.FC<Props> = ({
  questionId,
  disabled,
  gradesConfig,
}) => {
  const form = useFormikContext<FormType>();

  const birthdateFilters = getBirthdateFilterFormValue(questionId, form.values);

  const onChangeMainSwitchHandler = React.useCallback(() => {
    form.setValues((values) => {
      if (birthdateFilters.length > 0)
        return {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: [],
          },
        };
      return {
        ...values,
        formTemplateFilters: {
          ...values.formTemplateFilters,
          [questionId]: [
            {
              type: EligibleFilterType.BirthdateBeforeFilter,
              config: {
                endDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                keys: gradesConfig.map((grade) => grade.id),
              },
            },
          ],
        },
      };
    }, false);
  }, [form, questionId, birthdateFilters, gradesConfig]);

  const getFilterByGradeId = React.useCallback(
    (gradeId: uuid) => {
      return birthdateFilters.filter((filter) =>
        filter.config.keys.includes(gradeId)
      );
    },
    [birthdateFilters]
  );

  const getFilterTextAndDates = (filter: FormTemplateFilter) => {
    if (filter.type === EligibleFilterType.BirthdateBeforeFilter)
      return `before ${filter.config.endDate}`;
    if (filter.type === EligibleFilterType.BirthdateAfterFilter)
      return `after ${filter.config.startDate}`;
    if (filter.type === EligibleFilterType.BirthdateBetweenFilter)
      return `between ${filter.config.startDate} and ${filter.config.endDate}`;
  };

  const isFilterChecked = React.useCallback(
    (
      gradeConfig: GQL.GetGradesConfigByOrganization_grade_config,
      filter: BirthdateEligibilityFilter
    ) => {
      return filter.config.keys.includes(gradeConfig.id);
    },
    []
  );

  const handleCheckboxChange = React.useCallback(
    (
      gradeConfig: GQL.GetGradesConfigByOrganization_grade_config,
      filterIndex: number
    ) => {
      form.setValues((values) => {
        const birthdateFilters = values.formTemplateFilters[questionId];
        if (birthdateFilters === undefined) {
          return values;
        }

        const getNewFilterObj = (
          filter: FormTemplateFilter
        ): FormTemplateFilter => {
          if (filter.type === EligibleFilterType.BirthdateBeforeFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                keys: isFilterChecked(gradeConfig, filter)
                  ? filter.config.keys.filter((key) => key !== gradeConfig.id)
                  : [...filter.config.keys, gradeConfig.id],
              },
            };
          if (filter.type === EligibleFilterType.BirthdateAfterFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                keys: isFilterChecked(gradeConfig, filter)
                  ? filter.config.keys.filter((key) => key !== gradeConfig.id)
                  : [...filter.config.keys, gradeConfig.id],
              },
            };
          if (filter.type === EligibleFilterType.BirthdateBetweenFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                keys: isFilterChecked(gradeConfig, filter)
                  ? filter.config.keys.filter((key) => key !== gradeConfig.id)
                  : [...filter.config.keys, gradeConfig.id],
              },
            };

          return filter;
        };
        const updatedValues: FormType = {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: birthdateFilters.map((filter, index) =>
              filterIndex === index ? getNewFilterObj(filter) : filter
            ),
          },
        };
        return updatedValues;
      });
    },
    [form, isFilterChecked, questionId]
  );

  const handleChangeFilterType = React.useCallback(
    (value: string, filterIndex: number) => {
      form.setValues((values) => {
        const birthdateFilters = getBirthdateFilterFormValue(
          questionId,
          values
        );
        // const birthdateFilters = values.formTemplateFilters[questionId];
        if (birthdateFilters === undefined) {
          return values;
        }
        const filter = birthdateFilters[filterIndex];
        if (filter === undefined) {
          return values;
        }

        if (value === filter.type) return values;

        if (value === EligibleFilterType.BirthdateBeforeFilter) {
          const updatedValues: FormType = {
            ...values,
            formTemplateFilters: {
              ...values.formTemplateFilters,
              [questionId]: birthdateFilters.map((filter, index) =>
                filterIndex === index
                  ? {
                      type: value,
                      config: {
                        ...filter.config,
                        endDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                      },
                    }
                  : filter
              ),
            },
          };
          return updatedValues;
        }
        if (value === EligibleFilterType.BirthdateAfterFilter) {
          const updatedValues: FormType = {
            ...values,
            formTemplateFilters: {
              ...values.formTemplateFilters,
              [questionId]: birthdateFilters.map((filter, index) =>
                filterIndex === index
                  ? {
                      type: value,
                      config: {
                        ...filter.config,
                        startDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                      },
                    }
                  : filter
              ),
            },
          };
          return updatedValues;
        }

        if (value === EligibleFilterType.BirthdateBetweenFilter) {
          const updatedValues: FormType = {
            ...values,
            formTemplateFilters: {
              ...values.formTemplateFilters,
              [questionId]: birthdateFilters.map((filter, index) =>
                filterIndex === index
                  ? {
                      type: value,
                      config: {
                        ...filter.config,
                        startDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                        endDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                      },
                    }
                  : filter
              ),
            },
          };
          return updatedValues;
        }

        return values;
      });
    },
    [form, questionId]
  );

  const handleChangeDate = React.useCallback(
    (value: string, filterIndex: number, type: "startDate" | "endDate") => {
      form.setValues((values) => {
        const birthdateFilters = values.formTemplateFilters[questionId];
        if (birthdateFilters === undefined) {
          return values;
        }

        const getNewFilterObj = (
          filter: FormTemplateFilter
        ): FormTemplateFilter => {
          if (filter.type === EligibleFilterType.BirthdateBeforeFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                [type]: value,
              },
            };
          if (filter.type === EligibleFilterType.BirthdateAfterFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                [type]: value,
              },
            };
          if (filter.type === EligibleFilterType.BirthdateBetweenFilter)
            return {
              ...filter,
              config: {
                ...filter.config,
                [type]: value,
              },
            };

          return filter;
        };

        const updatedValues: FormType = {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: birthdateFilters.map((filter, index) =>
              index === filterIndex ? getNewFilterObj(filter) : filter
            ),
          },
        };
        return updatedValues;
      });
    },
    [form, questionId]
  );

  const handleRemoveFilterConfig = React.useCallback(
    (filterIndex: number) => {
      form.setValues((values) => {
        const birthdateFilters = values.formTemplateFilters[questionId];
        if (birthdateFilters === undefined) {
          return values;
        }

        const updatedValues: FormType = {
          ...values,
          formTemplateFilters: {
            ...values.formTemplateFilters,
            [questionId]: birthdateFilters.filter(
              (_config, index) => index !== filterIndex
            ),
          },
        };
        return updatedValues;
      });
    },
    [form, questionId]
  );

  const handleAddNewDateRange = () => {
    form.setValues((values) => {
      const birthdateFilters = values.formTemplateFilters[questionId];
      if (birthdateFilters === undefined) {
        return values;
      }

      const updatedValues: FormType = {
        ...values,
        formTemplateFilters: {
          ...values.formTemplateFilters,
          [questionId]: [
            ...birthdateFilters,
            {
              type: EligibleFilterType.BirthdateBeforeFilter,
              config: {
                endDate: DateFns.format(new Date(), "yyyy-MM-dd"),
                keys: gradesConfig.map((grade) => grade.id) ?? [],
              },
            },
          ],
        },
      };
      return updatedValues;
    });
  };

  return (
    <Flex direction="column" width="70%">
      <Text mb={1}>Limit available options based on date of birth</Text>
      <FormLabel
        htmlFor={`dateOfBirthSwitch${questionId}`}
        display="flex"
        flexDirection="row"
        gap="2"
        margin={0}
        mb={1}
      >
        <Switch
          id={`dateOfBirthSwitch${questionId}`}
          isChecked={birthdateFilters.length > 0}
          onChange={onChangeMainSwitchHandler}
          isDisabled={disabled}
        />
        <Box>{birthdateFilters.length > 0 ? "On" : "Off"}</Box>
      </FormLabel>

      <Text fontSize="sm" fontWeight="400" color="gray.600">
        Will restrict the available grades for parents based on the students
        entered date of birth.
      </Text>

      {birthdateFilters.length > 0 && (
        <Card variant="outline" my={4}>
          {gradesConfig.map((gradeConfig) => (
            <Flex direction="row">
              <Box
                sx={{
                  paddingX: 6,
                  paddingY: 2,
                  fontSize: "xs",
                }}
                width={{ sm: "30%", lg: "20%", xl: "15%" }}
              >
                {gradeConfig.label}
              </Box>
              <Divider orientation="vertical" />
              <Box
                sx={{
                  paddingX: 6,
                  paddingY: 2,
                  fontSize: "xs",
                }}
              >
                {getFilterByGradeId(gradeConfig.id).map((filter, index) => (
                  <Text key={`${filter.type}${index}`}>
                    When DOB is {getFilterTextAndDates(filter)}
                  </Text>
                ))}
                {getFilterByGradeId(gradeConfig.id).length === 0 && (
                  <Text>Always available</Text>
                )}
              </Box>
            </Flex>
          ))}
        </Card>
      )}

      <Text mb={2}>Limit options</Text>
      {birthdateFilters.length > 0 &&
        birthdateFilters.map((filter, filterIndex) => (
          <Box key={filterIndex}>
            <Card variant="outline" p={6} my={2}>
              <Text fontSize="xs" color="gray.800" mb={2}>
                Selected grades
              </Text>
              <Box
                mb={4}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                {gradesConfig.map((gradeConfig) => (
                  <Checkbox
                    isChecked={isFilterChecked(gradeConfig, filter)}
                    onChange={() =>
                      handleCheckboxChange(gradeConfig, filterIndex)
                    }
                    isDisabled={disabled}
                    mb={2}
                  >
                    <Text fontSize="xs" fontWeight="400">
                      {gradeConfig.label}
                    </Text>
                  </Checkbox>
                ))}
              </Box>
              <Text fontSize="xs" color="gray.800" mb={2}>
                Date of birth range
              </Text>

              <Select
                value={filter.type}
                onChange={(event) =>
                  handleChangeFilterType(event.target.value, filterIndex)
                }
                mb={2}
                isDisabled={disabled}
              >
                <option value={EligibleFilterType.BirthdateBeforeFilter}>
                  Date of birth before
                </option>
                <option value={EligibleFilterType.BirthdateAfterFilter}>
                  Date of birth after
                </option>
                <option value={EligibleFilterType.BirthdateBetweenFilter}>
                  Date of birth between
                </option>
              </Select>

              <Text variant="helperText" mb={4}>
                Selected grades will be displayed if the students birthdate
                falls in between or on the selected dates.
              </Text>

              <Flex direction="row" gap={2} mb={4}>
                {(filter.type === EligibleFilterType.BirthdateAfterFilter ||
                  filter.type ===
                    EligibleFilterType.BirthdateBetweenFilter) && (
                  <InputControl
                    inputProps={{
                      type: "date",
                      value: filter.config.startDate,
                      onChange: (event) =>
                        handleChangeDate(
                          event.target.value,
                          filterIndex,
                          "startDate"
                        ),
                      isDisabled: disabled,
                    }}
                    labelProps={{
                      fontSize: "xs",
                      color: "gray.800",
                      mb: 2,
                    }}
                    name="start_date"
                    label={
                      filter.type === EligibleFilterType.BirthdateBetweenFilter
                        ? "Start date"
                        : "Date"
                    }
                  />
                )}

                {(filter.type === EligibleFilterType.BirthdateBetweenFilter ||
                  filter.type === EligibleFilterType.BirthdateBeforeFilter) && (
                  <InputControl
                    inputProps={{
                      type: "date",
                      value: filter.config.endDate,
                      onChange: (event) =>
                        handleChangeDate(
                          event.target.value,
                          filterIndex,
                          "endDate"
                        ),

                      isDisabled: disabled,
                    }}
                    labelProps={{
                      fontSize: "xs",
                      color: "gray.800",
                      mb: 2,
                    }}
                    name="end_date"
                    label={
                      filter.type === EligibleFilterType.BirthdateBetweenFilter
                        ? "End date"
                        : "Date"
                    }
                  />
                )}
              </Flex>

              {!disabled && (
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => handleRemoveFilterConfig(filterIndex)}
                  width="20%"
                >
                  Remove
                </Button>
              )}
            </Card>
          </Box>
        ))}
      {birthdateFilters.length > 0 && !disabled && (
        <Button
          colorScheme="gray"
          alignSelf="flex-start"
          onClick={handleAddNewDateRange}
        >
          Add new date range
        </Button>
      )}
    </Flex>
  );
};
