import { Button, Flex, Heading, HStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  getParentSearchQuery,
  normalizeSearchQuery,
} from "src/components/graphql/utils";
import { SearchInput } from "src/components/Inputs/SearchInput";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { UploadPeopleMenu } from "src/components/UploadButtons/UploadPeopleMenu";
import { SearchAndFilterTypes } from "src/constants";
import {
  useOrderByParams,
  usePaginationParams,
} from "src/hooks/useCommonSearchParams";
import { useOrganization } from "src/hooks/useOrganization";
import { usePaginatedRemoteDataQuery } from "src/hooks/usePaginatedRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GET_PARENTS } from "./graphql/queries";
import { ParentsList } from "./List";

export const Parents = () => {
  const organization = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderBy, setOrderBy } = useOrderByParams();
  const { pagination, setPagination } = usePaginationParams();
  const { usePaginatedQuery, useUnpaginatedQueryPromise } =
    usePaginatedRemoteDataQuery<GQL.GetParents, GQL.GetParentsVariables>(
      GET_PARENTS,
      {
        organizationId: organization.map((org) => org.id).withDefault(""),
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: [orderBy ? { [orderBy.sortKey]: orderBy.sortType } : {}],
        search: searchParams.has(SearchAndFilterTypes.Search)
          ? getParentSearchQuery(
              searchParams.get(SearchAndFilterTypes.Search) ?? ""
            )
          : {},
      }
    );
  const { remoteData, refetch } = usePaginatedQuery({
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
  });
  const fetchAll = useUnpaginatedQueryPromise();

  const handleBulkUploadComplete = () => {
    refetch();
  };

  const handleFetchAll = async () => {
    const result = await fetchAll();
    if (result.error) throw new Error(result.error.message);
    return result.data.person;
  };

  const handleActionSearch = useCallback(
    (searchTerm: string) => {
      const normalized = normalizeSearchQuery(searchTerm);
      if (!normalized) {
        searchParams.delete(SearchAndFilterTypes.Search);
      } else {
        searchParams.set(SearchAndFilterTypes.Search, normalized);
      }
      searchParams.delete(SearchAndFilterTypes.Offset);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleClearSearch = useCallback(
    () => handleActionSearch(""),
    [handleActionSearch]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => (
        <Flex direction="column" rowGap={6}>
          <Breadcrumb
            items={breadcrumb.parent.getBreadcrumbsForList(organization)}
          />

          <HStack justifyContent="space-between">
            <Heading as="h1">Parents</Heading>
            <HStack>
              <WithUserPermissions permissions={["user:create"]}>
                <UploadPeopleMenu
                  onComplete={handleBulkUploadComplete}
                  personType="guardian"
                />
                <Button
                  colorScheme="primary"
                  as={NavLink}
                  to={organization
                    .map((org) => Url.OrgAdmin.Parents.new(org))
                    .withDefault("#")}
                  size="xl"
                >
                  Create parent
                </Button>
              </WithUserPermissions>
            </HStack>
          </HStack>

          <HStack>
            <SearchInput
              onSearch={handleActionSearch}
              value={searchParams.get(SearchAndFilterTypes.Search) ?? ""}
              onClear={handleClearSearch}
              placeholder="Search by Parent name, ID, email address or phone number"
            />
          </HStack>

          <ParentsList
            parents={data.person}
            limit={pagination.limit}
            offset={pagination.offset}
            count={data.person_aggregate.totals?.total ?? 0}
            onFetchAll={handleFetchAll}
            onFetchMore={setPagination}
            onChangeSort={setOrderBy}
            onClearSearch={handleClearSearch}
          />
        </Flex>
      )}
    </GQLRemoteDataView>
  );
};
