import { Card, Checkbox, Grid } from "@chakra-ui/react";
import { useField } from "formik";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { CustomQuestionAnswer } from "src/components/Form/QuestionForm/formik";
import { Question } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { answerIsEmpty } from "src/services/formTemplate/answer";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import {
  formatCustomQuestionTypeFieldAsProperties,
  formatEmptyInitialValues,
} from "./form/helpers";

type AdminCustomQuestionProps = {
  formId: uuid;
  customQuestionId: uuid;
  nestedQuestions: ClonedQuestion<WithId>[];
  isRequired: boolean | undefined;
  isDisabled: boolean | undefined;
};

export const AdminCustomQuestion: FunctionComponent<
  AdminCustomQuestionProps
> = (props) => {
  const { customQuestionId, formId, nestedQuestions, isRequired, isDisabled } =
    props;

  const [{ value }, , { setValue, setTouched }] =
    useField<CustomQuestionAnswer>(customQuestionId);

  const [hasAnswer, setHasAnswer] = useState<boolean>(
    !answerIsEmpty(value.answersByQuestionId)
  );

  const withPrefix = useCallback(
    (fieldId: string) => {
      return [customQuestionId, "answersByQuestionId", fieldId].join(".");
    },
    [customQuestionId]
  );

  const nestedQuestionsFieldProps = useMemo(() => {
    return formatCustomQuestionTypeFieldAsProperties({
      applicant: { first_name: null, birth_date: null },
      fieldQuestions: nestedQuestions,
      formId,
    });
  }, [nestedQuestions, formId]);

  const toggleHasAnswer = useCallback(() => {
    if (hasAnswer) {
      setHasAnswer(false);
      setValue({
        ...value,
        answersByQuestionId: formatEmptyInitialValues(
          nestedQuestions.map((nestedQuestion) => nestedQuestion.id)
        ),
      });
    } else {
      setHasAnswer(true);
    }
    setTouched(true);
  }, [hasAnswer, nestedQuestions, setTouched, setValue, value]);

  return (
    <>
      {!isRequired && (
        <Checkbox
          isChecked={!hasAnswer}
          onChange={toggleHasAnswer}
          isInvalid={false}
          mb={2}
        >
          Do not answer
        </Checkbox>
      )}
      <Card padding={4} gap={4} border="2px solid white">
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
          {nestedQuestionsFieldProps.map((fieldProps) => {
            return (
              <Question
                key={fieldProps.id}
                {...fieldProps}
                id={withPrefix(fieldProps.id)}
                isDisabled={(!isRequired && !hasAnswer) || isDisabled} // Disable if not required and no answer selected
              />
            );
          })}
        </Grid>
      </Card>
    </>
  );
};
