import { DocumentNode, useApolloClient } from "@apollo/client";
import React from "react";
import { Props as TagsProps } from "src/components/Tags/TagsList";
import { TagsPopover } from "src/components/Tags/TagsPopover";
import * as RemoteData from "src/types/remoteData";
import { FormId } from "../forms/types";
import { useFormTags } from "./useFormTags";

type Props = {
  enrollmentPeriodId: uuid;
  formId: FormId;
  refetchQueries?: DocumentNode[];
} & Omit<TagsProps, "tags" | "onSearch" | "onTagUpdate">;
export const FormTagsPopover: React.FC<Props> = ({
  enrollmentPeriodId,
  formId,
  refetchQueries,
  ...overrideTagsProps
}) => {
  const client = useApolloClient();
  const { resetFormTags, setSelectedRows, tagsProps, isDirty } = useFormTags({
    enrollmentPeriodId,
  });

  const onClose = React.useCallback(() => {
    if (isDirty) {
      client.refetchQueries({
        include: refetchQueries,
      });
    }
    resetFormTags();
  }, [client, isDirty, refetchQueries, resetFormTags]);

  const onOpen = React.useCallback(async () => {
    setSelectedRows(RemoteData.success([formId]));
  }, [formId, setSelectedRows]);

  return (
    <TagsPopover
      buttonProps={{
        size: "xs",
        variant: "tag",
      }}
      onClose={onClose}
      onOpen={onOpen}
      {...tagsProps}
      {...overrideTagsProps}
    />
  );
};
