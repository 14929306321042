import { DocRenderer } from "./DocRenderer";
import { HeicImageRenderer } from "./HeicImageRenderer";
import { ImageRenderer } from "./ImageRenderer";
import { PdfRenderer } from "./PdfRenderer";

export const ProxyRenderer: DocRenderer = ({ contentType, documentData }) => {
  switch (contentType) {
    case "application/pdf":
      return (
        <PdfRenderer documentData={documentData} contentType={contentType} />
      );
    case "image/heic":
    case "application/octet-stream":
      return <HeicImageRenderer documentData={documentData} />;
    case "image/png":
    case "image/jpg":
    case "image/gif":
    default:
      return (
        <ImageRenderer documentData={documentData} contentType={contentType} />
      );
  }
};
