import { FormikErrors } from "formik";
import _ from "lodash";
import React from "react";
import { QUESTION_TYPE_TEXT } from "src/components/Form/QuestionForm/constants";
import {
  FormSchema,
  FormType,
  getAllQuestionIds,
  getOptionIdsFormValue,
  getQuestionTypeFormValue,
  getVerificationFormValue,
} from "src/components/Form/QuestionForm/formik";
import { Question } from "src/services/formTemplate";
import { validateWithZod } from "src/services/formValidations";
import * as AF from "src/types/formTemplate";

type Props = {
  verificationOptions?: AF.FormVerification<AF.WithId>[];
};
export const useFormValidation = ({ verificationOptions }: Props) => {
  const validate = React.useCallback(
    async (formValues: FormType) => {
      const errors: FormikErrors<FormType> = _.merge(
        await validateWithZod(FormSchema)(formValues),
        validateNewVerificationLabel(formValues, verificationOptions),
        validateOptions(formValues)
      );
      if (_.isEmpty(errors)) {
        return undefined;
      }

      return errors;
    },
    [verificationOptions]
  );

  return validate;
};

function validateOptions(formValues: FormType): FormikErrors<FormType> {
  const allQuestionIds = getAllQuestionIds(formValues);
  const validations = Object.fromEntries(
    allQuestionIds.flatMap((questionId) => {
      const questionType = getQuestionTypeFormValue(questionId, formValues);
      const optionIds = getOptionIdsFormValue(questionId, formValues);
      const totalOptions = optionIds.length ?? 0;
      if (!Question.hasOptions({ type: questionType })) {
        return [];
      }

      if (totalOptions < 2) {
        return [
          [
            questionId,
            {
              options: `At least 2 answers are required for ${
                QUESTION_TYPE_TEXT[
                  getQuestionTypeFormValue(questionId, formValues)
                ].label
              }`,
            },
          ],
        ];
      }

      return [];
    })
  );

  if (_.isEmpty(validations)) {
    return {};
  }

  return { validations };
}

const validateNewVerificationLabel = (
  formValues: FormType,
  verificationOptions?: AF.FormVerification<AF.WithId>[]
): FormikErrors<FormType> => {
  const allQuestionIds = getAllQuestionIds(formValues);
  const verifications = Object.fromEntries(
    allQuestionIds.flatMap((questionId) => {
      const verification = getVerificationFormValue(questionId, formValues);
      if (verification.type !== "new" || !verificationOptions) {
        return [];
      }

      const newLabel = verification.label.trim();
      const labelExists =
        verificationOptions.find((option) => option.label === newLabel) !==
        undefined;
      if (!labelExists) {
        return [];
      }

      return [
        [
          questionId,
          {
            label:
              "A new verification cannot have the same name as an existing verification",
          },
        ],
      ];
    })
  );

  if (_.isEmpty(verifications)) {
    return {};
  }
  return { verifications };
};
