import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import useUser from "src/hooks/useUser";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { VerifyEmail } from "src/scenes/public/VerifyEmail";
import { Status } from "src/types/authData";
import { Permission } from "src/types/permissions";
import { AccessDenied } from "../Feedback/AccessDenied";
import { Loading } from "../Feedback/Loading";

type Props = {
  children?: ReactElement | ReactElement[];
  permission: Permission;
};
export const RequireUserPermissions = ({ children, permission }: Props) => {
  const user = useUser();
  const userPermissions = useUserPermissions();
  const organization = useOrganization();
  const navigate = useNavigate();

  if (user.status === "loading") {
    return <Loading />;
  }

  if (user.status === Status.NOT_VERIFIED) {
    return <VerifyEmail />;
  }

  if (
    user.status === Status.UNAUTHENTICATED ||
    !userPermissions.hasOne(permission)
  ) {
    if (organization.hasData()) {
      setTimeout(() => {
        navigate(`/${organization.data.path}/admin`);
      }, 2000);

      return (
        <AccessDenied message="You don't have access to this page, redirecting..." />
      );
    }

    return <AccessDenied />;
  }

  return <>{children}</>;
};
