import { gql } from "@apollo/client";

export const CREATE_STUDENT = gql`
  mutation CreateStudent($student: person_insert_input!) {
    insert_person_one(object: $student) {
      id
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($id: uuid!, $student: person_set_input) {
    update_person_by_pk(pk_columns: { id: $id }, _set: $student) {
      id
    }
  }
`;

export const DELETE_ORGANIZATION_STUDENT = gql`
  mutation DeleteOrganizationStudent($studentId: uuid!) {
    delete_person_by_pk(id: $studentId) {
      id
    }
  }
`;

export const REPLACE_APPLICANT_ATTENDING_SCHOOLS = gql`
  mutation replaceApplicantAttendingSchools(
    $delete_attending_schools: applicant_attending_school_bool_exp!
    $insert_attending_schools: [applicant_attending_school_insert_input!]!
  ) {
    applicant_attending_schools_deleted: delete_applicant_attending_school(
      where: $delete_attending_schools
    ) {
      affected_rows
      returning {
        person_id
        school_id
        enrollment_period_id
      }
    }

    applicant_attending_schools_create: insert_applicant_attending_school(
      objects: $insert_attending_schools
      on_conflict: {
        constraint: applicant_attending_school_person_id_enrollment_period_id_key
        update_columns: [school_id, attendance_status, description]
      }
    ) {
      affected_rows
      returning {
        person_id
        school_id
        enrollment_period_id
      }
    }
  }
`;
