import { Text, Link, Flex, Heading, IconButton } from "@chakra-ui/react";
import * as Url from "src/services/url";
import { Link as RouterLink } from "react-router-dom";
import * as GQL from "src/types/graphql";
import { useOrganization } from "src/hooks/useOrganization";
import * as Form from "src/services/form";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { RiExternalLinkLine } from "react-icons/ri";

type Props = {
  form: GQL.GetPreviousFormByFormId_form_by_pk;
};
export const RelatedForms: React.FC<Props> = ({ form }) => {
  const organization = useOrganization();
  const relatedForms = Form.Related.getRelatedForms(form);

  if (relatedForms.length === 0) {
    return null;
  }

  return (
    <Flex
      direction="column"
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="blackAlpha.80"
      padding="4"
      gap="4"
    >
      <Heading as="h2" fontSize="md" fontWeight="600">
        Related forms
      </Heading>
      {relatedForms.map((relatedForm) => {
        const link = Url.OrgAdmin.Forms.view({
          organization,
          id: relatedForm.id,
          formTemplateId: relatedForm.form_template_id,
        });

        return (
          <Flex
            key={relatedForm.id}
            direction="row"
            gap="2"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex direction="column" overflow="hidden">
              <Text fontSize="sm" color="gray.600">
                {relatedForm.form_template.name}
              </Text>
              <TextWithOverflowTooltip
                content={relatedForm.id}
                color="primary.500"
              >
                <Link as={RouterLink} to={link}>
                  {relatedForm.id}
                </Link>
              </TextWithOverflowTooltip>
            </Flex>
            <Flex>
              <IconButton
                as={RouterLink}
                to={link}
                aria-label="Open related form in new tab"
                variant="ghost"
                icon={<RiExternalLinkLine />}
                target="_blank"
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
