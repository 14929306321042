import { gql } from "@apollo/client";
export const CREATE_M2M_CREDENTIAL = gql`
  mutation CreateM2MCredential(
    $client_name: String!
    $organization_id: String!
    $user_group_id: String!
  ) {
    create_m2m_credential(
      request: {
        client_name: $client_name
        organization_id: $organization_id
        user_group_id: $user_group_id
      }
    ) {
      message
      status_code
      client_id
      client_secret
    }
  }
`;
