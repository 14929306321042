import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { FormImportFlowActionType } from "src/components/FormImport/state/actions";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";

export function DoneButton() {
  const organization = useOrganization();
  const { selectedNavFormTemplate } = useFormTemplates();

  const { dispatch, state } = useFormImportFlow();

  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: "Once you're done, you won't be able to come back to this screen.",
    header: "Done working with these forms?",
    cancelButton: { colorScheme: "primary", label: "Cancel" },
    confirmButton: { colorScheme: "primary", label: "Yes, done" },
  });

  const navigate = useNavigate();

  const handleDoneClick = useCallback(async () => {
    const isConfirmed = await confirm();

    if (!isConfirmed) {
      return;
    }

    // Clear references to the current form import
    dispatch({
      type: FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE,
      value: null,
    });

    if (organization.hasData() && selectedNavFormTemplate !== undefined) {
      const params = new URLSearchParams({
        enrollmentPeriod: state.enrollmentPeriodId ?? "",
      });

      navigate(
        Url.OrgAdmin.Forms.index({
          organization: organization.data,
          formTemplateId: selectedNavFormTemplate.id,
          params: params.toString(),
        })
      );
    }
  }, [
    confirm,
    dispatch,
    navigate,
    organization,
    selectedNavFormTemplate,
    state.enrollmentPeriodId,
  ]);

  return (
    <>
      <Button onClick={handleDoneClick}>Done</Button>
      {confirmationDialog}
    </>
  );
}
