import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useLoginMethods } from "src/hooks/useLoginMethods";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  SchoolType,
  TeamMemberFormType,
  TeamType,
  UserGroupType,
  createEmptyTeamMember,
  toAccessPermission,
} from "src/schemas/TeamMember";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { personTypeToHasuraRole } from "src/types/hasuraRole";
import { isOnlySSO } from "src/types/loginMethod";
import { toTuple3 } from "src/types/remoteData";
import { TeamMemberForm } from "../components/Forms/TeamMemberForm";
import {
  CREATE_AUTH0_USER,
  CREATE_SSO_ADMIN_PROFILE,
} from "./graphql/mutations";
import {
  GET_AVAILABLE_TEAMS,
  GET_SCHOOLS_BY_ORG_ID,
  GET_USER_GROUPS,
} from "./graphql/queries";

export const NewTeamMember = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const flags = useFlags(["current-applying-schools"]);
  const loginMethods = useLoginMethods("admin");
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupType>();
  const [submitting, setSubmitting] = useState(false);
  const [selectedSchools, setSelectedSchools] = useState<SchoolType[]>([]);
  const [selectedPersonTeam, setSelectedPersonTeam] = useState<TeamType>();
  const [createTeamMember] = useRemoteDataMutation<
    GQL.CreateAuth0User,
    GQL.CreateAuth0UserVariables
  >(CREATE_AUTH0_USER);
  const organization = useOrganization();

  const [createSSOAdminProfile] = useRemoteDataMutation<
    GQL.CreateSSOAdminProfile,
    GQL.CreateSSOAdminProfileVariables
  >(CREATE_SSO_ADMIN_PROFILE);

  const { remoteData: schoolData } = useRemoteDataQuery<
    GQL.GetSchoolsByOrgID,
    GQL.GetSchoolsByOrgIDVariables
  >(GET_SCHOOLS_BY_ORG_ID, {
    variables: {
      organizationID: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: userGroupData } = useRemoteDataQuery<GQL.GetUserGroups>(
    GET_USER_GROUPS,
    { fetchPolicy: "no-cache" }
  );

  const { remoteData: availableTeams } = useRemoteDataQuery<
    GQL.GetAvailableTeams,
    GQL.GetAvailableTeamsVariables
  >(GET_AVAILABLE_TEAMS, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "cache-and-network",
  });

  const remoteData = toTuple3(schoolData, userGroupData, availableTeams);

  const handleSubmit = useCallback(
    async (value: TeamMemberFormType) => {
      setSubmitting(true);
      try {
        if (!organization.hasData()) throw new Error("Invalid organization.");
        if (!selectedUserGroup) throw new Error("no selected user group");
        if (!loginMethods.hasData()) throw new Error("");

        const role = personTypeToHasuraRole(selectedUserGroup.person_type);
        const school_permissions = toAccessPermission(value, role);

        const req: GQL.CreateAuth0UserVariables = {
          email_address: value.email_address,
          first_name: value.first_name,
          last_name: value.last_name,
          school_ids: selectedSchools.map((x) => x.id) || [],
          hasura_role: role,
          user_group_id: selectedUserGroup.id,
          organization_id: organization.data.id,
          school_access_permission: school_permissions,
          team_id: selectedPersonTeam?.id || "",
        };
        if (isOnlySSO(loginMethods.data)) {
          await createSSOAdminProfile({ variables: req });
        } else {
          await createTeamMember({
            variables: req,
          });
        }

        toast({
          id: "create-team-member",
          title: "Team member created",
          isClosable: true,
          status: "info",
        });

        navigate(Url.OrgAdmin.Team.index(organization.data));
      } catch (err) {
        const description = (): string => {
          if (err instanceof Error) {
            if (err.message.toLowerCase().includes("conflict")) {
              return "User already exists.";
            }
            if (err.message.toLowerCase().includes("unauthorized")) {
              return "Not authorized to create team member.";
            }
          }
          return "Check your network and try again.";
        };
        toast({
          id: "create-team-member",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [
      createTeamMember,
      createSSOAdminProfile,
      toast,
      navigate,
      organization,
      selectedUserGroup,
      selectedSchools,
      loginMethods,
      selectedPersonTeam,
    ]
  );

  const initialValues = createEmptyTeamMember(
    flags["current-applying-schools"].enabled
      ? "school:attending"
      : "school:applying"
  );

  if (remoteData.isLoading()) {
    return <Loading></Loading>;
  }
  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {([schoolData, userGroupData, teamsData]) => {
        return (
          <>
            <Breadcrumb
              items={breadcrumb.team.getBreadcrumbsForNewMember(organization)}
              mb={8}
            />

            <Formik<TeamMemberFormType>
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Flex as={Form} direction="column" gap={6}>
                    <Heading as="h1" fontSize="2xl" fontWeight="600">
                      Create Team Member
                    </Heading>
                    <TeamMemberForm
                      onChangeUserRole={setSelectedUserGroup}
                      onChangePersonTeam={setSelectedPersonTeam}
                      onSelectSchool={setSelectedSchools}
                      selectedUserGroup={selectedUserGroup}
                      allSchools={schoolData.school}
                      initialSchools={selectedSchools}
                      userGroups={userGroupData.user_group}
                      teams={teamsData.team}
                    />
                    <Flex align="center" gap={6}>
                      <Spacer />
                      <Button
                        as={NavLink}
                        to={organization
                          .map((org) => Url.OrgAdmin.Team.index(org))
                          .withDefault("#")}
                        isLoading={organization.isLoading()}
                        variant="link"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" isLoading={submitting}>
                        Create
                      </Button>
                    </Flex>
                  </Flex>
                );
              }}
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
