import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import {
  DELETE_CUSTOM_QUESTION_ANSWERS,
  UPSERT_CUSTOM_QUESTION_ANSWERS,
} from "src/scenes/parent/forms/graphql/mutations";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { getSingleCustomQuestionAnswerUpsertPayload } from "src/services/formTemplate/answer";
import {
  CustomQuestion,
  SingleSelectType,
  WithId,
} from "src/types/formTemplate";
import {
  DeleteCustomQuestionAnswers,
  DeleteCustomQuestionAnswersVariables,
  GetCustomQuestionAnswer,
  GetCustomQuestionAnswerVariables,
  GetCustomQuestionAnswer_custom_question_answer,
  UpsertCustomQuestionAnswers,
  UpsertCustomQuestionAnswersVariables,
} from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import {
  GET_CUSTOM_QUESTION_ANSWER,
  GET_SELECTED_PERSON_ANSWER_BANK,
} from "./graphql/queries";

export function useGetCustomQuestionAnswerQuery(
  variables: GetCustomQuestionAnswerVariables
): RemoteData<ApolloError, CustomQuestionAnswersByQuestionId | null> {
  const { remoteData } = useRemoteDataQuery<
    GetCustomQuestionAnswer,
    GetCustomQuestionAnswerVariables
  >(GET_CUSTOM_QUESTION_ANSWER, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  const formattedRemoteData = useMemo(() => {
    return remoteData.map((data) => {
      const { custom_question_answer } = data;
      if (!custom_question_answer) {
        return null;
      }
      return formatCustomQuestionAnswersResponse(custom_question_answer);
    });
  }, [remoteData]);

  return formattedRemoteData;
}

function formatCustomQuestionAnswersResponse(
  customQuestionAnswers: GetCustomQuestionAnswer_custom_question_answer[]
): CustomQuestionAnswersByQuestionId {
  if (!customQuestionAnswers) {
    return {};
  }

  return customQuestionAnswers.reduce<Record<uuid, string>>(
    (accumulator, currentAnswer) => {
      const nestedQuestionId = currentAnswer.form_answer.question_id;
      const nestedQuestionType =
        currentAnswer.form_answer.form_question.question.type;
      const value =
        nestedQuestionType === SingleSelectType
          ? currentAnswer.form_answer.form_answer_options[0]
              ?.form_question_option_id
          : currentAnswer.form_answer.free_text_answer;
      accumulator[nestedQuestionId] = value ?? "";
      return accumulator;
    },
    {}
  );
}

type MutateCustomQuestionAnswerProps = {
  formId: uuid;
  customQuestion: CustomQuestion<WithId>;
};

type SaveCustomQuestionAnswerProps = MutateCustomQuestionAnswerProps & {
  answersByQuestionId: CustomQuestionAnswersByQuestionId;
  answerBankId: uuid;
  refetchAnswers?: boolean;
};

export function useSaveCustomQuestionAnswers() {
  const [upsertCustomQuestionAnswers] = useRemoteDataMutation<
    UpsertCustomQuestionAnswers,
    UpsertCustomQuestionAnswersVariables
  >(UPSERT_CUSTOM_QUESTION_ANSWERS, {
    fetchPolicy: "no-cache",
  });

  return async (props: SaveCustomQuestionAnswerProps) => {
    const {
      formId,
      customQuestion,
      answersByQuestionId,
      answerBankId,
      refetchAnswers,
    } = props;
    await upsertCustomQuestionAnswers({
      variables: {
        form_id: formId,
        question_id: customQuestion.id,
        single_select_question_ids: customQuestion.nestedQuestions
          .filter((nestedQuestion) => nestedQuestion.type === SingleSelectType)
          .map((nestedQuestion) => nestedQuestion.id),
        custom_question_answers: getSingleCustomQuestionAnswerUpsertPayload(
          formId,
          customQuestion,
          answersByQuestionId
        ),
        no_answer_bank_relationship: false,
        answer_bank_id: answerBankId,
      },
      refetchQueries: [
        // the following two queries could be inactive
        {
          query: GET_CUSTOM_QUESTION_ANSWER,
          variables: { form_id: formId, question_id: customQuestion.id },
        },
        {
          query: GET_SELECTED_PERSON_ANSWER_BANK,
          variables: { form_id: formId, question_id: customQuestion.id },
        },
        // refetchAnswers is only true on the Form page, where the GET_FORM_ANSWERS_BY_ID is guaranteed to be active
        ...(refetchAnswers ? [GET_FORM_ANSWERS_BY_ID] : []),
      ],
      awaitRefetchQueries: true,
    });
  };
}

export function useDeleteCustomQuestionAnswers() {
  const [deleteCustomQuestionAnswers] = useRemoteDataMutation<
    DeleteCustomQuestionAnswers,
    DeleteCustomQuestionAnswersVariables
  >(DELETE_CUSTOM_QUESTION_ANSWERS, {
    fetchPolicy: "no-cache",
  });

  return async (props: MutateCustomQuestionAnswerProps) => {
    const { formId, customQuestion } = props;
    await deleteCustomQuestionAnswers({
      variables: {
        form_id: formId,
        question_id: customQuestion.id,
        nested_question_ids: customQuestion.nestedQuestions.map(
          (nestedQuestion) => {
            return nestedQuestion.id;
          }
        ),
      },
    });
  };
}
