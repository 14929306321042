import { gql } from "@apollo/client";
export const EDIT_AUTH0_USER = gql`
  mutation EditAuth0User(
    $user_id: String!
    $first_name: String!
    $last_name: String!
    $hasura_role: String!
    $user_group_id: String!
    $school_ids: [String]
    $school_access_permission: String
    $team_id: String!
  ) {
    edit_auth0_user(
      request: {
        first_name: $first_name
        last_name: $last_name
        hasura_role: $hasura_role
        user_group_id: $user_group_id
        user_id: $user_id
        school_ids: $school_ids
        school_access_permission: $school_access_permission
        team_id: $team_id
      }
    ) {
      message
      status_code
    }
  }
`;

export const CREATE_AUTH0_USER = gql`
  mutation CreateAuth0User(
    $email_address: String!
    $first_name: String!
    $last_name: String!
    $organization_id: String!
    $hasura_role: String!
    $user_group_id: String!
    $school_ids: [String]
    $school_access_permission: String
    $team_id: String!
  ) {
    create_auth0_user(
      request: {
        email_address: $email_address
        first_name: $first_name
        last_name: $last_name
        organization_id: $organization_id
        hasura_role: $hasura_role
        user_group_id: $user_group_id
        school_ids: $school_ids
        school_access_permission: $school_access_permission
        team_id: $team_id
      }
    ) {
      message
      status_code
      user_id
    }
  }
`;

export const CREATE_SSO_ADMIN_PROFILE = gql`
  mutation CreateSSOAdminProfile(
    $email_address: String!
    $first_name: String!
    $hasura_role: String!
    $last_name: String!
    $school_ids: [String]
    $organization_id: String!
    $user_group_id: String!
    $school_access_permission: String
    $team_id: String!
  ) {
    create_sso_admin_profile(
      request: {
        email_address: $email_address
        first_name: $first_name
        last_name: $last_name
        organization_id: $organization_id
        user_group_id: $user_group_id
        hasura_role: $hasura_role
        school_ids: $school_ids
        school_access_permission: $school_access_permission
        team_id: $team_id
      }
    ) {
      message
      status_code
      user_id
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($name: String!, $organizationId: uuid!) {
    insert_team_one(object: { organization_id: $organizationId, name: $name }) {
      id
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation EditTeam($teamId: uuid!, $name: String!) {
    update_team_by_pk(pk_columns: { id: $teamId }, _set: { name: $name }) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($teamId: uuid!) {
    update_team_by_pk(
      pk_columns: { id: $teamId }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
    delete_person_team(where: { team_id: { _eq: $teamId } }) {
      affected_rows
    }
  }
`;

export const ASSIGN_MEMBERS_TO_TEAM = gql`
  mutation AssignMembersToTeam(
    $teamId: uuid!
    $input: [person_team_insert_input!]!
  ) {
    delete_person_team(where: { team_id: { _eq: $teamId } }) {
      affected_rows
    }

    insert_person_team(objects: $input) {
      affected_rows
    }
  }
`;
