import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryHookOptions,
  QueryLazyOptions,
  QueryOptions,
  QueryResult,
  SubscriptionHookOptions,
  SubscriptionResult,
  TypedDocumentNode,
  useApolloClient,
  useLazyQuery,
  useQuery,
  useSubscription,
} from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CHUNKED_BATCH_SIZE } from "src/constants";
import { lazyAsyncMap } from "src/services/asyncHelpers";
import * as RemoteData from "../types/remoteData";

export function useRemoteDataSubscription<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>
): SubscriptionRemoteData<TData, TVariables> {
  const result = useSubscription<TData, TVariables>(query, options);
  const [cache, setCache] = React.useState<TData | undefined>(undefined);

  React.useEffect(() => {
    if (result.data) {
      setCache(result.data);
    }
  }, [result.data]);

  return React.useMemo(() => {
    const withPreviousData = { ...result, previousData: cache };
    return {
      ...withPreviousData,
      remoteData: RemoteData.fromApolloResult(withPreviousData),
    };
  }, [cache, result]);
}

export function useRemoteDataQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryRemoteData<TData, TVariables> {
  const result = useQuery<TData, TVariables>(query, options);
  return React.useMemo(
    () => ({
      ...result,
      remoteData: RemoteData.fromApolloResult(result),
    }),
    [result]
  );
}
export function useLazyRemoteDataQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  const [get, result] = useLazyQuery(query, options);

  return [get, { ...result, remoteData: RemoteData.fromApolloResult(result) }];
}

/**
 * Unlike the above hooks, this one returns a functional query—i.e. it returns a
 * promise that yields the results of the query, and does not provide static
 * access to that data.  This is somewhat less "React-like", and is intended to
 * be used in cases where the React model clashes with other systems.
 *
 * Notably, modern browsers require download triggers to be connected to a real
 * user click event (for security), which empirically seems to mean that the
 * synthetic download trigger must be in the same callstack as the originating
 * click handler.  This functional query behaves better in this manner, as it
 * allows the data to be both fetched and processed from within the same click
 * handler.
 */
export function useRemoteDataQueryPromise<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<QueryOptions<TVariables, TData>, "query">
): () => Promise<ApolloQueryResult<TData>> {
  const client = useApolloClient();
  const runQuery = useCallback(
    () => client.query({ query, ...options }),
    [client, query, options]
  );
  return runQuery;
}

/**
 * This runs multiple versions of the specified query with different variables
 * in batches, primarily to support queries with very large inputs that would
 * otherwise fail if attempted inside a single query.  The batch size is chosen
 * to match Apollo's default batch size.
 */
export function useChunkedRemoteDataQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variableChunks: TVariables[],
  options?: Omit<QueryHookOptions<TData, TVariables>, "variables">
) {
  const [fetch] = useLazyRemoteDataQuery(query, options);
  const [remoteData, setRemoteData] = useState<
    RemoteData.RemoteData<ApolloError, TData[]>
  >(RemoteData.notAsked());
  // Keep track of the currently running promise to be able to preempt older
  // promises.
  const activePromise = useRef<Promise<void> | null>(null);

  useEffect(() => {
    async function doQueryBatches() {
      const results: TData[] = [];
      const queryResults = lazyAsyncMap(
        variableChunks,
        (variables) => fetch({ variables }),
        CHUNKED_BATCH_SIZE
      );
      for await (const queryResult of queryResults) {
        if (thisPromise !== activePromise.current) return;
        const chunkData = RemoteData.fromApolloResult(queryResult);
        if (chunkData.hasError()) {
          setRemoteData(RemoteData.failure(chunkData.error));
          return;
        } else if (!chunkData.hasData()) {
          console.warn("Query returned no data.");
          continue;
        }
        results.push(chunkData.data);
      }
      setRemoteData(RemoteData.success(results));
    }

    if (options?.skip) {
      setRemoteData(RemoteData.notAsked());
      activePromise.current = null;
      return;
    }
    setRemoteData(RemoteData.loading());
    const thisPromise = (activePromise.current = doQueryBatches());
  }, [fetch, options?.skip, variableChunks]);

  return { remoteData };
}

// TYPES
export interface QueryRemoteData<TData, TVariables extends OperationVariables>
  extends Omit<
    QueryResult<TData, TVariables>,
    "data" | "loading" | "error" | "previousData"
  > {
  remoteData: RemoteData.RemoteData<ApolloError, TData>;
}

type QueryTuple<TData, TVariables extends OperationVariables> = [
  (
    options?: QueryLazyOptions<TVariables>
  ) => Promise<LazyQueryResult<TData, TVariables>>,
  QueryRemoteData<TData, TVariables>
];

export interface SubscriptionRemoteData<
  TData,
  TVariables extends OperationVariables
> extends Omit<
    SubscriptionResult<TData, TVariables>,
    "data" | "loading" | "error" | "previousData"
  > {
  remoteData: RemoteData.RemoteData<ApolloError, TData>;
}
