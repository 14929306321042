import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { Glossary } from "src/components/Text/Glossary";
import {
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_OFFSET,
} from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { StudentForm } from "../components/Forms/StudentForm";
import { CREATE_STUDENT } from "./graphql/mutations";
import { GET_STUDENTS } from "./graphql/queries";
import {
  INITIAL_STUDENT_PROFILE,
  StudentProfileForm,
  StudentProfileSchema,
  StudentProfileValidator,
} from "./schemas";

export const NewStudent = () => {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);
  const toast = useAvelaToast();
  const navigate = useNavigate();
  const organization = useOrganization();
  const userPermissions = useUserPermissions();
  const [createStudent] = useRemoteDataMutation<
    GQL.CreateStudent,
    GQL.CreateStudentVariables
  >(CREATE_STUDENT);
  const [getStudents] = useLazyRemoteDataQuery<
    GQL.GetStudents,
    GQL.GetStudentsVariables
  >(GET_STUDENTS, { fetchPolicy: "network-only" });

  const handleSubmit = async (form: StudentProfileForm) => {
    organization.do(async (org) => {
      setSubmitting(true);
      try {
        const { applicant_attending_schools, ...student } =
          StudentProfileSchema.parse(form);

        await createStudent({
          variables: {
            student: {
              ...student,
              organization_id: org.id,
              person_type: GQL.person_type_enum.applicant,
              applicant_attending_schools: {
                data: [],
              },
            },
          },
        });
        await getStudents({
          variables: {
            organizationId: org.id,
            limit: PAGINATION_DEFAULT_LIMIT,
            offset: PAGINATION_DEFAULT_OFFSET,
            search: {},
          },
        });
        navigate(Url.OrgAdmin.Students.index(org));
      } catch (err) {
        console.error(err);
        toast.error({ title: glossary`Error adding student` });
      } finally {
        setSubmitting(false);
      }
    });
  };

  const canCreateUser = userPermissions.hasOne("user:create");

  if (!canCreateUser) {
    return <NotFound></NotFound>;
  }

  return (
    <RequireUserPermissions permission={"user:create"}>
      <Breadcrumb
        items={breadcrumb.student.getBreadcrumbsForNew(organization)}
        mb={8}
      />
      <Formik<StudentProfileForm>
        initialValues={INITIAL_STUDENT_PROFILE}
        onSubmit={handleSubmit}
        validate={validateWithZod(StudentProfileValidator)}
      >
        <Flex as={Form} direction="column" gap={6}>
          <Heading as="h1" fontSize="2xl" fontWeight="600">
            <Glossary>Create student</Glossary>
          </Heading>
          <StudentForm />
          <Flex align="center" gap={3}>
            <Spacer />
            <Button
              as={NavLink}
              to={organization
                .map((org) => Url.OrgAdmin.Students.index(org))
                .withDefault("#")}
              isLoading={organization.isLoading()}
              variant="link"
            >
              Cancel
            </Button>
            {canCreateUser && (
              <Button type="submit" marginLeft={4} isLoading={submitting}>
                Create
              </Button>
            )}
          </Flex>
        </Flex>
      </Formik>
    </RequireUserPermissions>
  );
};
