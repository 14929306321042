import { List, Set } from "immutable";
import { Item } from "./types";

type SelectionResult = [Item[], string[]];
export function processIds(
  value: string,
  items: readonly Item[],
  selectedItemIds: readonly string[]
): SelectionResult {
  const ids = Set(
    value
      .split(",")
      .map((id) => id.trim())
      .filter((id) => id.length > 0)
  );

  const selectedSet = Set(selectedItemIds);

  const [newSelectedItems, invalidIds] = items.reduce(
    ([currentSelectedItems, currentIds], item) => {
      if (currentIds.has(item.id)) {
        if (!selectedSet.has(item.id)) {
          return [currentSelectedItems.push(item), currentIds.remove(item.id)];
        } else {
          return [currentSelectedItems, currentIds.remove(item.id)];
        }
      }

      return [currentSelectedItems, currentIds];
    },
    [List<Item>(), ids]
  );

  return [newSelectedItems.toArray(), invalidIds.toArray()];
}
