import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DeleteConfirmation } from "src/components/Dialogs/DeleteConfirmation";
import { Loading } from "src/components/Feedback/Loading";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_ORGANIZATION } from "./graphql/mutations";

interface DeleteOrganizationButtonProps {
  id: string;
  name: string;
  onDelete: () => void;
}

export const DeleteOrganizationButton = ({
  id,
  name,
  onDelete,
}: DeleteOrganizationButtonProps) => {
  const [deleteOrganization, { remoteData, reset }] = useRemoteDataMutation<
    GQL.DeleteOrganization,
    GQL.DeleteOrganizationVariables
  >(DELETE_ORGANIZATION);
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const toast = useToast();

  const handleDelete = React.useCallback(async () => {
    try {
      setConfirmOpen(false);
      await deleteOrganization({
        variables: { id: id },
      });
      onDelete();
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Error deleting organization",
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  }, [deleteOrganization, id, onDelete, toast]);

  const handleConfirmDelete = React.useCallback(() => {
    reset();
    setConfirmOpen(true);
  }, [setConfirmOpen, reset]);
  const handleCancel = React.useCallback(() => {
    setConfirmOpen(false);
  }, [setConfirmOpen]);

  if (remoteData.isLoading()) return <Loading />;

  return (
    <>
      <IconButton
        aria-label={`Edit ${name}`}
        icon={<Icon as={RiDeleteBin6Line} />}
        variant="ghost"
        onClick={handleConfirmDelete}
      />
      <DeleteConfirmation
        header="Delete Organization"
        isOpen={confirmOpen}
        onDelete={handleDelete}
        onCancel={handleCancel}
      />
    </>
  );
};
