import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { useQueryParams } from "src/hooks/useQueryParams";
import { Expired } from "./Expired";
import { Success } from "./Success";

export const EmailVerification = () => {
  const { success, message } = useQueryParams();
  if (success !== "true") {
    if (message === "Access expired.") {
      return <Expired />;
    } else {
      return (
        <GenericError
          message={typeof message === "string" ? message : undefined}
        />
      );
    }
  }
  return <Success />;
};
