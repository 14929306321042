import { Box } from "@chakra-ui/react";
import MonacoEditor from "@monaco-editor/react";

export const JsonViewer: React.FC<{ obj: object }> = (props) => (
  <Box
    height="100%"
    minHeight="20rem"
    borderWidth="1px"
    borderStyle="solid"
    borderColor="gray.200"
  >
    <MonacoEditor
      language="json"
      value={JSON.stringify(props.obj, null, 2)}
      options={{ scrollBeyondLastLine: false, readOnly: true }}
    />
  </Box>
);
