import { ApolloError, useApolloClient } from "@apollo/client";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { useFlags } from "flagsmith/react";
import JSZip from "jszip";
import React, { useCallback } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useDocumentStorage } from "src/hooks/useDocumentStorage";
import { useOrganization } from "src/hooks/useOrganization";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { csvExport } from "src/services/dataTransfer";
import { flattenForm } from "src/services/dataTransfer/export";
import * as FormTemplate from "src/services/formTemplate";
import { Question } from "src/services/formTemplate";
import { getAllDocumentIDs } from "src/services/formTemplate/answer";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import {
  EXPORT_FORMS,
  EXPORT_QUESTIONS_AND_VERIFICATIONS,
  GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD,
} from "../graphql/queries";
import { CombinedData } from "./types";

interface ExportMenuProps {
  formData: RemoteData<ApolloError | Error, CombinedData>;
  enrollmentPeriodId: string;
}

export const ExportMenu: React.FC<ExportMenuProps> = ({
  formData,
  enrollmentPeriodId,
}) => {
  const [isExporting, setIsExporting] = React.useState<boolean>(false);
  const apolloClient = useApolloClient();
  const toast = useAvelaToast();
  const organization = useOrganization();
  const flags = useFlags(["current-applying-schools"]);
  const { isSchoolAdmin } = useSchoolAdmin();
  const { getDownloadUrl, getDocumentInfo } = useDocumentStorage();

  const handleExportCSV = useCallback(
    async () =>
      formData.do(async (app) => {
        const questionsAndVerifications = await apolloClient.query<
          GQL.ExportQuestionsAndVerifications,
          GQL.ExportQuestionsAndVerificationsVariables
        >({
          query: EXPORT_QUESTIONS_AND_VERIFICATIONS,
          variables: {
            form_template_id: app.form.form_template.id,
          },
        });
        if (questionsAndVerifications.error)
          throw new Error(questionsAndVerifications.error.message);
        const questionsMap = Question.toQuestionMap(
          questionsAndVerifications.data.question_by_form_template
        );
        const questionIdsInOrder = Question.getQuestionIdsInOrder(
          FormTemplate.fromGQL(app.form.form_template).sections
        );
        const schoolRankingSection = app.form.form_template.sections.find(
          (section) =>
            section?.type ===
            GQL.form_template_section_type_enum.SchoolRankingSection
        );
        const rankingEnabled =
          schoolRankingSection?.schools_ranking_section?.ranking_enabled ??
          false;
        const skipRank = isSchoolAdmin || !rankingEnabled;
        const formVerifications =
          questionsAndVerifications.data.form_verification;
        const disclaimerSectionTitle =
          questionsAndVerifications.data.form_template_section[0]?.title ??
          null;
        const tagGroupsData = await apolloClient.query<
          GQL.GetTagGroupsByEnrollmentPeriod,
          GQL.GetTagGroupsByEnrollmentPeriodVariables
        >({
          query: GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD,
          variables: {
            enrollment_period_id: enrollmentPeriodId,
          },
        });
        if (tagGroupsData.error) throw new Error(tagGroupsData.error.message);
        const tagGroups = tagGroupsData.data.tag_group;
        const result = await apolloClient.query<
          GQL.ExportForms,
          GQL.ExportFormsVariables
        >({
          query: EXPORT_FORMS,
          variables: {
            skip_rank: skipRank,
            form_ids: [app.form.id],
            form_school_rank_ids: app.schoolsRank.map(
              (schoolRank) => schoolRank.id
            ),
          },
        });
        if (result.error) {
          console.error(result.error);
          toast.error({ title: "Error exporting forms" });
        }
        csvExport(
          flattenForm({
            records: result.data,
            // TODO: Remove non-null assertion.
            // Once frontend bulk export gets deprecated, we can directly pass in organization.
            organization: organization.toNullable()!,
            questionsMap,
            questionIdsInOrder,
            formVerifications,
            disclaimerSectionTitle,
            tagGroups,
            options: {
              skipRank,
              includeAttendingSchool: flags["current-applying-schools"].enabled,
            },
          }),
          "form.csv"
        );
      }),
    [
      formData,
      apolloClient,
      enrollmentPeriodId,
      organization,
      isSchoolAdmin,
      toast,
      flags,
    ]
  );

  const handleExportFiles = useCallback(async () => {
    formData.do(async (app) => {
      const {
        form_answers: answers,
        sections,
        form: {
          person: { first_name: firstName, last_name: lastName },
          form_template: { name: formName },
        },
      } = app;

      setIsExporting(true);
      const progressToast = toast({
        status: "info",
        title: "Export in progress",
        description: "Download will start shortly, please keep this page open",
        icon: <Spinner size="sm" />,
        duration: null,
      });

      const questions = sections.flatMap((section) => {
        if (section === undefined) return [];

        switch (section.type) {
          case AF.PreRankingSectionType:
          case AF.GeneralSectionType:
            return section.questions;
          default:
            return [];
        }
      });

      const documentIds = getAllDocumentIDs(questions, answers);

      const zip = new JSZip();

      try {
        await Promise.all(
          documentIds.map(async (id) => {
            const url = await getDownloadUrl(id);
            const documentInfo = await getDocumentInfo(id);
            if (!url) return;

            return fetch(url, {
              method: "GET",
            })
              .then((response) => {
                return response.arrayBuffer();
              })
              .then((documentData) => {
                zip.file(`${id}-${documentInfo?.filename}`, documentData);
              });
          })
        );

        zip.generateAsync({ type: "blob" }).then((blob) => {
          toast.close(progressToast);
          setIsExporting(false);
          saveAs(blob, `${firstName}-${lastName}-${formName}.zip`);
          setTimeout(() => {
            toast({
              status: "success",
              title: "Export done",
              description: "Files downloaded successfully",
            });
          }, 500);
        });
      } catch (error) {
        console.error(error);
        toast.close(progressToast);
        setIsExporting(false);
        setTimeout(() => {
          toast({
            status: "error",
            title: "Oops! Something went wrong!",
            description: "Check your network and try again later",
          });
        }, 500);
      }
    });
  }, [formData, getDownloadUrl, getDocumentInfo, toast]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={RiDownloadLine} />}
        rightIcon={<ChevronDownIcon />}
      >
        Export
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleExportCSV}>Form as .CSV</MenuItem>
        <MenuItem onClick={handleExportFiles} isDisabled={isExporting}>
          Attached files
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
