import { Icon } from "@chakra-ui/react";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { RiTextWrap } from "react-icons/ri";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSendMessage } from "src/hooks/useSendMessage";
import * as GQL from "src/types/graphql";
import { FETCH_FORM_STATUSES_BY_ID_STATUS } from "../../enrollmentPeriods/scenes/FormTemplates/graphql/queries";
import {
  BULK_CANCEL_ACTION_ITEMS,
  BULK_CREATE_ACTION_ITEMS,
} from "../graphql/mutations";
import { FETCH_ACTION_ITEMS } from "../graphql/queries";
import { GqlForm } from "../types";
import { NextStepFormValues, NextStepMenu } from "./NextStepMenu";
import { useGetMessageRecipients } from "src/hooks/useGetMessageRecipients";

interface Props {
  formTemplateId: uuid;
  handleNextStepBulk: () => Promise<GqlForm[]>;
}

const mapInitialValues = (
  actionItems: GQL.ActionItemFragment[] = [],
  formStatusDescription = "",
  isMixedContent = false
): NextStepFormValues => ({
  summary:
    actionItems[0]?.summary ?? (isMixedContent ? "" : formStatusDescription),
  enrollmentDescription: formStatusDescription,
  showRequired: false,
  sendMessage: false,
});

export const NextStepMenuBulk = ({
  formTemplateId,
  handleNextStepBulk,
}: Props) => {
  const toast = useAvelaToast();

  const [forms, setForms] = useState<GqlForm[]>([]);

  const [appStatuses, setAppStatuses] = useState<GQL.form_status_enum[]>([]);

  const [actionItems, setActionItems] = useState<
    GQL.FetchActionItems_action_item[]
  >([]);

  const [enrollmentStatusDescription, setEnrollmentStatusDescription] =
    useState<string>("");

  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const { selectedNavFormTemplate } = useFormTemplates();

  const { handleSendMessage } = useSendMessage(selectedNavFormTemplate?.id);
  const { getApplicantAndRecipientsForForms } = useGetMessageRecipients();

  const [fetchFormTemplateStatus] = useLazyRemoteDataQuery<
    GQL.FetchFormStatusesByIdStatus,
    GQL.FetchFormStatusesByIdStatusVariables
  >(FETCH_FORM_STATUSES_BY_ID_STATUS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) =>
      setEnrollmentStatusDescription(
        data.form_status_description[0]?.description || ""
      ),
    onError: (error) => {
      console.error(error);
      setHasErrors(true);
      setEnrollmentStatusDescription("");
    },
  });

  const [fetchActionItems] = useLazyRemoteDataQuery<
    GQL.FetchActionItems,
    GQL.FetchActionItemsVariables
  >(FETCH_ACTION_ITEMS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => setActionItems(data.action_item || []),
    onError: (error) => {
      console.error(error);
      setHasErrors(true);
      setActionItems([]);
    },
  });

  const [bulkCreateActionItems] = useRemoteDataMutation<
    GQL.BulkCreateActionItems,
    GQL.BulkCreateActionItemsVariables
  >(BULK_CREATE_ACTION_ITEMS);

  const [bulkCancelActionItems] = useRemoteDataMutation<
    GQL.BulkCancelActionItems,
    GQL.BulkCancelActionItemsVariables
  >(BULK_CANCEL_ACTION_ITEMS);

  const upsertActionItems = async ({
    summary,
    enrollmentDescription,
  }: NextStepFormValues) => {
    if (!summary && summary.length === 0) {
      throw new Error("A status description is required.");
    }

    const useEnrollmentDescription = summary === enrollmentDescription;

    if (useEnrollmentDescription) {
      await bulkCancelActionItems({
        variables: {
          form_ids: forms?.map((app) => app.id),
        },
        refetchQueries: [FETCH_ACTION_ITEMS],
      });
    } else {
      await bulkCreateActionItems({
        variables: {
          form_ids: forms?.map((app) => app.id),
          data: forms?.map((app) => ({
            form_id: app.id,
            summary,
            status: GQL.action_item_status_enum.Pending,
          })),
        },
        refetchQueries: [FETCH_ACTION_ITEMS],
      });
    }

    toast({
      description: "Status description have been updated",
      status: "info",
      duration: 2000,
    });
  };

  const handleSubmit = async (values: NextStepFormValues): Promise<boolean> => {
    try {
      upsertActionItems(values);
      const formIds = forms.map((form) => form.id);
      const { recipients, applicants } =
        await getApplicantAndRecipientsForForms(formIds);

      // if sendMessage is checked then navigate to new message route after a sight delay,
      // so it allows user to see status is updated
      if (values.sendMessage) {
        setTimeout(() => {
          handleSendMessage(recipients, applicants, formIds, values.summary);
        }, 200);
      }

      return true;
    } catch (e) {
      console.error(e);
      toast.error({
        title: "Something went wrong",
        description: "Please try again later or contact our team",
      });
      return false;
    }
  };

  const handleOnClick = async (evt: React.MouseEvent): Promise<boolean> => {
    setHasErrors(false);

    const apps = (await handleNextStepBulk()) || [];
    setForms(apps);
    doFetchActionItems(apps);
    doFetchEnrollmentStatusDescription(apps);
    return true;
  };

  const doFetchActionItems = (apps: GqlForm[]) => {
    if (apps?.length !== 1 || !apps[0]) {
      setActionItems([]);
      return;
    }

    const app = apps[0];

    fetchActionItems({
      variables: {
        form_id: app.id,
        status: GQL.action_item_status_enum.Pending,
      },
    });
  };

  const doFetchEnrollmentStatusDescription = (apps: GqlForm[]) => {
    const appStatuses = _.uniq(apps.map((app) => app.status));
    setAppStatuses(appStatuses);

    if (appStatuses.length > 1) {
      setEnrollmentStatusDescription("");
      return;
    }

    fetchFormTemplateStatus({
      variables: {
        form_template_id: formTemplateId,
        form_status: appStatuses,
      },
    });
  };

  const initialValues = useMemo(() => {
    return mapInitialValues(
      actionItems,
      enrollmentStatusDescription,
      forms.length > 1
    );
  }, [actionItems, enrollmentStatusDescription, forms]);

  const isMixedContent = forms.length > 1;
  const isMixedStatus = appStatuses.length > 1;

  return (
    <NextStepMenu
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      onClick={handleOnClick}
      placeHolderText={
        isMixedContent ? "Type to replace mixed content" : undefined
      }
      disableReset={isMixedStatus}
      placement="bottom-end"
      hasErrors={hasErrors}
      leftIcon={<Icon as={RiTextWrap} />}
    />
  );
};
