import { BoxProps } from "@chakra-ui/react";
import {
  AnimationControls,
  MotionProps,
  useReducedMotion,
} from "framer-motion";

type AnimationProps = Omit<BoxProps, "transition"> & MotionProps;

export function useAnimationWithReduceMotion(): (
  props: AnimationProps
) => AnimationProps | { animate: AnimationControls } {
  const reduceMotion = useReducedMotion();
  return (props: AnimationProps) => {
    if (reduceMotion) {
      // don't animate, so just set the style props directly into the component
      return props;
    }

    // set the style props into animate props so we can have nice animation
    return { animate: props as AnimationControls }; // I can't figure out how to align the type here, so have to settle with type casting for now.
  };
}

export function useAnimatePresenceWithReduceMotion(): (
  props: MotionProps
) => AnimationProps {
  const reduceMotion = useReducedMotion();
  return ({ initial, animate, transition }) => ({
    initial: reduceMotion ? undefined : initial,
    animate: reduceMotion ? undefined : animate,
    transition: reduceMotion ? undefined : transition,
  });
}
