import * as DateFns from "date-fns";
import _ from "lodash";
import * as Schemas from "src/schemas/formTemplateFilters";
import * as Types from "src/types/formTemplateFilters";

function parseDate(value: date): Date {
  return DateFns.parse(value, "yyyy-MM-dd", new Date());
}

export function parseFilters(data: any): Types.FormTemplateFilters {
  if (!data || data.length === 0 || data === "[]") return [];

  return Schemas.FormTemplateFiltersSchema.parse(
    _.isString(data) ? JSON.parse(data) : data
  );
}

export function performFilters(
  options: Types.Option[],
  applicant: Types.Applicant,
  filters: Types.FormTemplateFilters
): Types.Option[] {
  if (!applicant.birth_date) {
    return options;
  }

  if (filters.length === 0) {
    // no filters
    return options;
  }

  const birthDate = parseDate(applicant.birth_date);
  return options.filter((option) =>
    isEligibleForFilters(option, birthDate, filters)
  );
}

function isFilterApplicable(
  filter: Types.FormTemplateFilter,
  option: Types.Option
): boolean {
  switch (filter.type) {
    case Schemas.EligibleFilterType.BirthdateAfterFilter:
    case Schemas.EligibleFilterType.BirthdateBeforeFilter:
    case Schemas.EligibleFilterType.BirthdateBetweenFilter:
      return filter.config.keys.includes(option.key);

    default:
      return false;
  }
}

export function isEligibleForFilters(
  option: Types.Option,
  birthDate: Date,
  filters: Types.FormTemplateFilters
): boolean {
  const results = filters.map((filter): Eligibility => {
    switch (filter.type) {
      case Schemas.EligibleFilterType.BirthdateBeforeFilter:
        return isEligibleForBirthdateBeforeFilter(option, birthDate, filter);

      case Schemas.EligibleFilterType.BirthdateAfterFilter:
        return isEligibleForBirthdateAfterFilter(option, birthDate, filter);

      case Schemas.EligibleFilterType.BirthdateBetweenFilter:
        return isEligibleForBirthdateBetweenFilter(option, birthDate, filter);

      default:
        //Need to implement school eligibility filter, for now we only support birthdate filter.
        return "NotApplicable";
    }
  });

  // we only care for applicable filters
  const applicableResults = results.filter(
    (result) => result !== "NotApplicable"
  );

  if (applicableResults.length === 0) {
    // no applicable result
    return true;
  }

  // at least one eligible result, otherwise, it's not eligible
  return applicableResults.some((result) => result === "Eligible");
}

type Eligibility = "NotApplicable" | "Eligible" | "NotEligible";

function isEligibleForBirthdateBeforeFilter(
  option: Types.Option,
  birthDate: Date,
  filter: Types.BirthdateBeforeFilter
): Eligibility {
  if (!isFilterApplicable(filter, option)) {
    return "NotApplicable";
  }

  const endDate = parseDate(filter.config.endDate);
  return DateFns.isBefore(birthDate, endDate) ||
    DateFns.isSameDay(birthDate, endDate)
    ? "Eligible"
    : "NotEligible";
}

export function isEligibleForBirthdateAfterFilter(
  option: Types.Option,
  birthDate: Date,
  filter: Types.BirthdateAfterFilter
): Eligibility {
  if (!isFilterApplicable(filter, option)) {
    return "NotApplicable";
  }

  const startDate = parseDate(filter.config.startDate);
  return DateFns.isAfter(birthDate, startDate) ||
    DateFns.isSameDay(birthDate, startDate)
    ? "Eligible"
    : "NotEligible";
}

export function isEligibleForBirthdateBetweenFilter(
  option: Types.Option,
  birthDate: Date,
  filter: Types.BirthdateBetweenFilter
): Eligibility {
  if (!isFilterApplicable(filter, option)) {
    return "NotApplicable";
  }

  const startDate = parseDate(filter.config.startDate);
  const endDate = parseDate(filter.config.endDate);
  return (DateFns.isAfter(birthDate, startDate) ||
    DateFns.isSameDay(birthDate, startDate)) &&
    (DateFns.isBefore(birthDate, endDate) ||
      DateFns.isSameDay(birthDate, endDate))
    ? "Eligible"
    : "NotEligible";
}

export * as Schemas from "src/schemas/formTemplateFilters";
export * as Types from "src/types/formTemplateFilters";
