import { Box, Button, HStack } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { useFormImportFileUploadFlow } from "src/components/FormImport/useFormImportFileUploadFlow";
import { useFormImportTemplateDownload } from "src/components/FormImport/useFormImportTemplateDownload";
import { ReactComponent as BrokenFileSvg } from "src/images/broken-file.svg";

interface CouldNotReadFileProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function CouldNotReadFile(props: CouldNotReadFileProps) {
  const { enrollmentPeriodId, formTemplateId, formTemplateName } = props;

  const { startDownload } = useFormImportTemplateDownload({
    formTemplateId,
  });

  const { openFilePicker } = useFormImportFileUploadFlow({
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
    redirectToCreateFormsPage: false,
  });

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description="The file's format or type could be invalid. Download the template and try again."
        heading="Could not read file"
        Svg={BrokenFileSvg}
      >
        <HStack>
          <Button colorScheme="gray" onClick={startDownload} variant="outline">
            Download template
          </Button>

          <Button onClick={openFilePicker} variant="solid">
            Retry
          </Button>
        </HStack>
      </EmptyState>
    </Box>
  );
}
