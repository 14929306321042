import { Button, Flex, Text } from "@chakra-ui/react";
import { FunctionComponent, RefObject } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { MessageTemplate } from "src/services/url/OrgAdmin";
import { EditFormTemplateActionBar } from "../../components/ActionBar";

type Props = {
  actionBarRef: RefObject<HTMLDivElement>;
  isPreview: boolean;
  isViewOnly: boolean;
  setIsPreview: (value: boolean) => void;
  formId: string;
  formTemplateId: string;
  hasChanges: boolean;
  onClearChanges: () => void;
};

/**
 * When editing a Message Template, users can preview their changes.
 * Going between editing and preview looks like a navigation event, but is not.
 * The parent page simply toggles the edit page and preview page.
 */
export const EditMessageTemplateActionBar: FunctionComponent<Props> = (
  props
) => {
  const organization = useOrganization();

  const { enrollmentPeriodId = "" } = useParams();
  const {
    actionBarRef,
    isPreview,
    setIsPreview,
    formId,
    formTemplateId,
    isViewOnly,
    hasChanges,
    onClearChanges
  } = props;

  return (
    <EditFormTemplateActionBar actionBarRef={actionBarRef}>
      {isViewOnly || !isPreview ? (
        <Button
          variant="outline"
          as={NavLink}
          to={organization
            .map((org) =>
              MessageTemplate.index(org, enrollmentPeriodId, formTemplateId)
            )
            .withDefault("#")}
          colorScheme="gray"
        >
          Back
        </Button>
      ) : (
        <Button
          variant="outline"
          onClick={() => setIsPreview(false)}
          colorScheme="gray"
        >
          Back
        </Button>
      )}

      <Flex direction="column" gap={2}>
        <Flex gap={2}>
          {hasChanges && (
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={onClearChanges}
              gridColumn="3"
            >
              Clear unpublished changes
            </Button>
          )}

          {isPreview ? (
            <Button form={formId} type="submit" isDisabled={isViewOnly}>
              Done
            </Button>
          ) : (
            <Button form={formId} type="submit">
              Preview
            </Button>
          )}
        </Flex>
        {hasChanges && (
          <Text textAlign="right" fontSize="xs" color="gray.500">
            Unpublished changes
          </Text>
        )}
      </Flex>
    </EditFormTemplateActionBar>
  );
};
