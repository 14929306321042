import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { RiMore2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Glossary } from "src/components/Text/Glossary";
import { OrganizationError } from "src/hooks/useOrganization";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { useDeleteStudentAction } from "src/scenes/orgAdmin/students/useDeleteStudentAction";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";

interface StudentListItemMenuProps {
  onDeleteStudentSuccess?: () => void;
  organization: RemoteData<OrganizationError, GQL.Organization>;
  person: GQL.GetStudentsByGuardianId_person;
}

export function StudentListItemMenu(props: StudentListItemMenuProps) {
  const { onDeleteStudentSuccess, organization, person } = props;

  const { confirmationDialog: deleteConfirmationDialog, requestDeleteStudent } =
    useDeleteStudentAction({
      onSuccess: onDeleteStudentSuccess,
      studentId: person.id,
    });

  const hasForms = !!person.forms_aggregate?.aggregate?.count;

  let deleteActionMessage;
  if (hasForms) {
    deleteActionMessage = (
      <>
        <Text as="div" display="block" fontSize="sm">
          To remove{" "}
          <span className={WEGLOT_SKIP_CLASS}>{person.first_name}</span>, cancel
          or delete any ongoing forms first
        </Text>
      </>
    );
  }

  return (
    <>
      <Menu>
        <MenuButton
          aria-label="Options"
          as={IconButton}
          borderRadius="9999px"
          colorScheme="gray"
          icon={<RiMore2Fill />}
          variant="outline"
        />

        <MenuList maxWidth="260px">
          <MenuItem
            as={Link}
            to={organization
              .map((org) => Url.Parent.Form.new(org, { studentId: person.id }))
              .withDefault("#")}
          >
            Start form
          </MenuItem>

          <MenuItem
            as={Link}
            to={organization
              .map((org) => Url.Parent.Student.edit(org, person.id))
              .withDefault("#")}
          >
            <Glossary>Edit student</Glossary>
          </MenuItem>

          <MenuDivider />

          <MenuItem
            display="block"
            isDisabled={hasForms}
            onClick={requestDeleteStudent}
          >
            <div>
              <Glossary>Delete student</Glossary>
            </div>
            {deleteActionMessage}
          </MenuItem>
        </MenuList>
      </Menu>

      {deleteConfirmationDialog}
    </>
  );
}
