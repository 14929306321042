import * as GQL from "src/types/graphql";
import { FormTemplateHint, HintContent } from "./FormTemplateHint";
import React from "react";
import * as RD from "src/types/remoteData";
import {
  FormEvent,
  FormTemplateEvent,
  OfferEvent,
  WaitlistEvent,
} from "./FormEvent";
import Mustache from "mustache";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FORM_TEMPLATE_HINT_FOR_PARENT } from "./graphql/queries";
import { useOrganization } from "src/hooks/useOrganization";

export function useHint(eventsQueue: readonly FormTemplateEvent[]): {
  hint: RD.RemoteData<unknown, HintContent> | undefined;
} {
  const organization = useOrganization();
  const { remoteData: getFormTemplateHintForParent } = useRemoteDataQuery<
    GQL.GetFormTemplateHintForParent,
    GQL.GetFormTemplateHintForParentVariables
  >(GET_FORM_TEMPLATE_HINT_FOR_PARENT, {
    variables: { organizationId: organization.toNullable()?.id ?? "" },
    skip: !organization.hasData(),
  });

  const formTemplateHints = React.useMemo(() => {
    return getFormTemplateHintForParent.map(
      (data): readonly FormTemplateHint[] => {
        return data.form_template_hint.flatMap((ftm) => {
          const formMessage = toFormHint(ftm);
          if (!formMessage) {
            return [];
          }
          return [formMessage];
        });
      }
    );
  }, [getFormTemplateHintForParent]);

  const hint = React.useMemo(() => {
    const event = eventsQueue[0];
    if (!event) {
      return undefined;
    }

    const _hint = eventToHint(event, formTemplateHints);
    if (_hint) {
      return RD.success(_hint);
    }
  }, [eventsQueue, formTemplateHints]);

  return { hint };
}

function eventToHint(
  event: FormTemplateEvent,
  formHintConfig: RD.RemoteData<unknown, readonly FormTemplateHint[]>
): HintContent | undefined {
  if (formHintConfig.isLoading()) {
    return;
  }

  if (!formHintConfig.hasData()) {
    return;
  }

  switch (event.type) {
    case "offer":
      return offerEventToHint(event, formHintConfig.data);

    case "form":
      return formEventToHint(event, formHintConfig.data);

    case "waitlist":
      return waitlistEventToHint(event, formHintConfig.data);

    default:
      const _exhaustiveCheck: never = event;
      console.error("Unknown event: " + _exhaustiveCheck);
      return;
  }
}

function offerEventToHint(
  offerEvent: OfferEvent,
  config: readonly FormTemplateHint[]
): HintContent | undefined {
  for (const formHint of config) {
    if (
      formHint.type === GQL.status_rule_type_enum.OfferStatus &&
      formHint.formTemplateId === offerEvent.formTemplateId &&
      formHint.status === offerEvent.status
    ) {
      return {
        title: Mustache.render(formHint.title, offerEvent),
        body: Mustache.render(formHint.body, offerEvent),
        imageType: formHint.imageType,
      };
    }
  }
}

function formEventToHint(
  formEvent: FormEvent,
  config: readonly FormTemplateHint[]
): HintContent | undefined {
  for (const formHint of config) {
    if (
      formHint.type === GQL.status_rule_type_enum.FormStatus &&
      formHint.formTemplateId === formEvent.formTemplateId &&
      formHint.status === formEvent.status
    ) {
      return {
        title: Mustache.render(formHint.title, formEvent),
        body: Mustache.render(formHint.body, formEvent),
        imageType: formHint.imageType,
      };
    }
  }
}

function waitlistEventToHint(
  waitlistEvent: WaitlistEvent,
  config: readonly FormTemplateHint[]
): HintContent | undefined {
  for (const formHint of config) {
    if (
      formHint.type === GQL.status_rule_type_enum.WaitlistStatus &&
      formHint.formTemplateId === waitlistEvent.formTemplateId &&
      formHint.status === waitlistEvent.status
    ) {
      return {
        title: Mustache.render(formHint.title, waitlistEvent),
        body: Mustache.render(formHint.body, waitlistEvent),
        imageType: formHint.imageType,
      };
    }
  }
}

function toFormHint(
  formTemplateHint: GQL.GetFormTemplateHintForParent_form_template_hint
): FormTemplateHint | undefined {
  switch (formTemplateHint.status_type) {
    case GQL.status_rule_type_enum.OfferStatus:
      if (!formTemplateHint.offer_status) {
        console.error(
          "Invalid form_template_hint: " + JSON.stringify(formTemplateHint)
        );
        return;
      }

      return {
        formTemplateId: formTemplateHint.form_template_id,
        type: formTemplateHint.status_type,
        status: formTemplateHint.offer_status,
        title: formTemplateHint.title,
        body: formTemplateHint.body,
        imageType: formTemplateHint.image_type,
      };
    case GQL.status_rule_type_enum.FormStatus:
      if (!formTemplateHint.form_status) {
        console.error(
          "Invalid form_template_hint: " + JSON.stringify(formTemplateHint)
        );
        return;
      }

      return {
        formTemplateId: formTemplateHint.form_template_id,
        type: formTemplateHint.status_type,
        status: formTemplateHint.form_status,
        title: formTemplateHint.title,
        body: formTemplateHint.body,
        imageType: formTemplateHint.image_type,
      };

    case GQL.status_rule_type_enum.WaitlistStatus:
      if (!formTemplateHint.waitlist_status) {
        console.error(
          "Invalid form_template_hint: " + JSON.stringify(formTemplateHint)
        );
        return;
      }

      return {
        formTemplateId: formTemplateHint.form_template_id,
        type: formTemplateHint.status_type,
        status: formTemplateHint.waitlist_status,
        title: formTemplateHint.title,
        body: formTemplateHint.body,
        imageType: formTemplateHint.image_type,
      };

    default:
      const _exhaustiveCheck: never = formTemplateHint.status_type;
      console.error(
        "Unhandled form_template_hint.status_type" + _exhaustiveCheck
      );
      return undefined;
  }
}
