import { ApolloError, useApolloClient } from "@apollo/client";
import React, { useEffect, useMemo } from "react";
import {
  useBoundariesMap,
  useFormAddressPosition,
} from "src/components/Boundary/useBoundary";
import { useGradeAnswer } from "src/components/Form/useGradeAnswer";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { getFormikInitialValues } from "src/services/formTemplate/answer";
import {
  getEligibleSchools,
  getEligibleSchoolsByLocation,
} from "src/services/formTemplate/preRankingSection";
import { getCompleteApplicableQuestions } from "src/services/formTemplate/question";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useEligibilityService } from "src/services/eligibility/useEligibilityService";
import { GET_SCHOOLS_FOR_FORM } from "./graphql/queries";

type School = {
  readonly id: uuid;
  readonly name: string;
  readonly street_address: string | null;
};

type Props = {
  readonly formId: uuid;
  readonly formTemplateId: uuid;
  readonly preRankingSection: AF.PreRankingSection<AF.WithId>;
  readonly selectedSchools: readonly School[];
};

export function useEligibleSchools({
  formTemplateId,
  formId,
  preRankingSection,
  selectedSchools,
}: Props): RD.RemoteData<Error, School[]> {
  const flags = useFlags(["eligibility-service"]);
  const isServiceDisabled = !flags["eligibility-service"].enabled;

  // Eligibility service is enabled
  const eligibilityService = useEligibilityService({ formTemplateId });
  const [ineligibleSchoolIdsRemoteData, setIneligibleSchoolIdsRemoteData] =
    React.useState<RD.RemoteData<Error, string[]>>(RD.loading());
  const apolloClient = useApolloClient();
  const { remoteData: schoolsRemoteData } = useRemoteDataQuery<
    GQL.GetSchoolsForFormTemplate,
    GQL.GetSchoolsForFormTemplateVariables
  >(GET_SCHOOLS_FOR_FORM, {
    variables: { form_id: formId },
    skip: isServiceDisabled,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (!flags["eligibility-service"].enabled) {
      return;
    }

    const doAsync = async () => {
      try {
        setIneligibleSchoolIdsRemoteData(RD.loading());
        const ineligibleSchoolIds =
          await eligibilityService.getIneligibleSchoolIdsByFormId({
            formId,
          });
        setIneligibleSchoolIdsRemoteData(RD.success(ineligibleSchoolIds));
      } catch (err) {
        console.error(err);
        setIneligibleSchoolIdsRemoteData(
          RD.failure(
            err instanceof Error
              ? err
              : new Error("Unknown error", { cause: err })
          )
        );
      }
    };
    doAsync();
  }, [
    apolloClient,
    eligibilityService,
    flags,
    formId,
    preRankingSection.questions,
  ]);

  const eligibleSchoolsRemoteData: RD.RemoteData<Error, School[]> =
    useMemo(() => {
      if (isServiceDisabled) {
        return RD.notAsked();
      }

      return RD.map2(
        ineligibleSchoolIdsRemoteData,
        schoolsRemoteData,
        (ineligibleSchoolIds, schools) => {
          const selectedSchoolIds = selectedSchools.map((school) => school.id);
          const eligibleSchools = schools.school_by_form.flatMap(
            (schoolByForm) => {
              if (schoolByForm.school === null) {
                return [];
              }
              if (ineligibleSchoolIds.includes(schoolByForm.school.id)) {
                return [];
              }
              if (selectedSchoolIds.includes(schoolByForm.school.id)) {
                return [];
              }

              return [schoolByForm.school];
            }
          );

          return eligibleSchools;
        }
      );
    }, [
      ineligibleSchoolIdsRemoteData,
      isServiceDisabled,
      schoolsRemoteData,
      selectedSchools,
    ]);

  // Eligibility service is disabled
  const frontendEligibleSchools = useFrontendEligibleSchools({
    formId,
    preRankingSection,
    selectedSchools,
    disabled: !isServiceDisabled,
  });

  if (isServiceDisabled) {
    return frontendEligibleSchools;
  }

  return eligibleSchoolsRemoteData;
}

type FrontendProps = {
  readonly formId: uuid;
  readonly preRankingSection: AF.PreRankingSection<AF.WithId>;
  readonly selectedSchools: readonly School[];
  readonly disabled: boolean;
};
function useFrontendEligibleSchools({
  formId,
  preRankingSection,
  selectedSchools,
  disabled,
}: FrontendProps): RD.RemoteData<ApolloError, School[]> {
  const boundariesMapRemoteData = useBoundariesMap({ formId });
  const gradeConfigRemoteData = useGradeAnswer({ formId });

  const { remoteData: answersRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "no-cache",
    skip: disabled,
  });
  const { remoteData: getSchoolsRemoteData } = useRemoteDataQuery<
    GQL.GetSchoolsForFormTemplate,
    GQL.GetSchoolsForFormTemplateVariables
  >(GET_SCHOOLS_FOR_FORM, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "no-cache",
    skip: disabled,
  });

  const addressLocationRemoteData = useFormAddressPosition({
    formId,
  }).mapError((e) => new ApolloError({ errorMessage: e.message }));

  return React.useMemo(() => {
    if (disabled) {
      return RD.notAsked();
    }

    return RD.toTuple5(
      getSchoolsRemoteData,
      answersRemoteData,
      addressLocationRemoteData,
      boundariesMapRemoteData,
      gradeConfigRemoteData
    ).map(
      ([
        getSchoolsData,
        answersData,
        addressLocation,
        boundariesMap,
        gradeConfigId,
      ]) => {
        const { questions } = preRankingSection;
        // calculate eligible schools
        const answers = getFormikInitialValues(
          questions,
          answersData.form_answer,
          answersData.grades_answer,
          answersData.form_address,
          answersData.custom_question_answer
        );
        const completeQuestions = getCompleteApplicableQuestions(
          questions,
          answers,
          { rankedSchoolIds: [], previousFormSchoolIds: [] }
        );
        type GQLSchool = GQL.GetSchoolsForFormTemplate_school_by_form_school;
        const allSchools: School[] = getSchoolsData.school_by_form
          .map(({ school }) => school)
          .filter((school): school is GQLSchool => school !== null);

        let eligibleSchools: School[] = getEligibleSchools(
          allSchools,
          completeQuestions,
          answers
        ).filter(
          (school) =>
            // remove schools that are already selected
            !selectedSchools
              .map((school: School) => school.id)
              .includes(school.id)
        );

        eligibleSchools = eligibleSchools.filter(
          (school) =>
            // remove schools that are already selected
            !selectedSchools
              .map((school: School) => school.id)
              .includes(school.id)
        );

        eligibleSchools = getEligibleSchoolsByLocation(
          eligibleSchools,
          questions,
          gradeConfigId,
          boundariesMap,
          addressLocation
        );
        return eligibleSchools;
      }
    );
  }, [
    addressLocationRemoteData,
    answersRemoteData,
    boundariesMapRemoteData,
    disabled,
    getSchoolsRemoteData,
    gradeConfigRemoteData,
    preRankingSection,
    selectedSchools,
  ]);
}
