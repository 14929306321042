import { gql } from "@apollo/client";
import { SCHOOL_FRAGMENT } from "./queries";

export const CREATE_SCHOOL = gql`
  mutation CreateSchool($school: school_insert_input!) {
    insert_school_one(object: $school) {
      id
    }
  }
`;

export const BULK_CREATE_SCHOOLS = gql`
  mutation BulkCreateSchools($schools: [school_insert_input!]!) {
    insert_school(objects: $schools) {
      affected_rows
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  ${SCHOOL_FRAGMENT}
  mutation UpdateSchool($id: uuid!, $school: school_set_input) {
    update_school_by_pk(pk_columns: { id: $id }, _set: $school) {
      ...SchoolFragment
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation DeleteSchool($id: uuid!) {
    delete_school_by_pk(id: $id) {
      id
    }
  }
`;
