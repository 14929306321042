import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Data Services",
      href: organization
        .map((org) => Url.OrgAdmin.DataServices.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNewWebhook = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create Webhook",
      href: organization
        .map((org) => Url.OrgAdmin.Webhooks.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEditWebhook = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  id: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Edit Webhook",
      href: organization
        .map((org) => Url.OrgAdmin.Webhooks.edit(org, id))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNewApiCredential = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create API Credential",
      href: organization
        .map((org) => Url.OrgAdmin.Webhooks.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEditApiCredential = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  id: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Edit API Credential",
      href: organization
        .map((org) => Url.OrgAdmin.Webhooks.edit(org, id))
        .withDefault("#"),
    },
  ];
};
