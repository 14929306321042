import { isEqual, pick, zip } from "lodash";
import {
  ClonedQuestion,
  EmailType,
  FreeTextType,
  Option,
  PhoneNumberType,
  SingleSelectType,
  WithId,
} from "src/types/formTemplate";
import {
  GetCustomQuestionType_custom_question_type_by_pk,
  GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options,
} from "src/types/graphql";

export type CloneIdsGroupedBySourceIds = Record<
  uuid,
  { clonedQuestionId: uuid; type: string; optionIdMapping?: Record<uuid, uuid> }
>;

/**
 * AnswerBank records only know about its CustomQuestionType and the respective
 * (CustomQuestionType)Fields.
 *
 * The nestedQuestions of a CustomQuestion know its source via its customQuestionTypeFieldId.
 * That property is used to construct this mapping from source to clone.
 *
 * When setting the answer to a CustomQuestion in Formik, the questionIds and optionIds are based
 * on the AnswerBank (source). However these ids must be converted to that of the cloned records (target).
 */
export function mapCloneIdsBySourceIds(
  customQuestionType: GetCustomQuestionType_custom_question_type_by_pk,
  nestedQuestions: ClonedQuestion<WithId>[]
): CloneIdsGroupedBySourceIds {
  return customQuestionType.custom_question_type_fields.reduce<CloneIdsGroupedBySourceIds>(
    (accumulator, currentField, index) => {
      const { question_id: fieldId } = currentField;
      const clonedQuestion = nestedQuestions[index];

      if (
        !(
          clonedQuestion && clonedQuestion.customQuestionTypeFieldId === fieldId
        ) ||
        currentField.question.type !== clonedQuestion.type
      ) {
        throw new Error(
          `Could not find cloned question corresponding to customQuestionTypeFieldId ${fieldId} `
        );
      }

      switch (clonedQuestion.type) {
        case FreeTextType:
        case EmailType:
        case PhoneNumberType:
          accumulator[fieldId] = {
            clonedQuestionId: clonedQuestion.id,
            type: clonedQuestion.type,
          };
          break;
        case SingleSelectType:
          verifyFormOptions(
            currentField.question.form_question?.form_question_options,
            clonedQuestion.options
          );

          const sourceOptionIds =
            currentField.question.form_question?.form_question_options.map(
              (option) => {
                return option.id;
              }
            );

          const targetOptionIds = clonedQuestion.options.map((option) => {
            return option.id;
          });

          accumulator[fieldId] = {
            clonedQuestionId: clonedQuestion.id,
            type: clonedQuestion.type,
            optionIdMapping: Object.fromEntries(
              zip(sourceOptionIds, targetOptionIds)
            ),
          };
          break;
        default: {
          const _exhaustiveCheck: never = clonedQuestion;
          return _exhaustiveCheck;
        }
      }

      return accumulator;
    },
    {}
  );
}

/**
 * These options are both provided in sorted order so it's assumed they're matching:
 * - sourceFormOptions is sorted via GQL query
 * - clonedFormOptions is sorted client side
 */
function verifyFormOptions(
  sourceFormOptions:
    | GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options[]
    | undefined,
  clonedFormOptions: Option<WithId>[]
): void {
  if (!sourceFormOptions) {
    throw new Error("Source form options is undefined.");
  }

  if (sourceFormOptions.length !== clonedFormOptions.length) {
    throw new Error("Form option lengths do not match.");
  }

  const fieldsToPick = ["label", "value"];
  const optionsAreEqual =
    zip(sourceFormOptions, clonedFormOptions).filter((options) => {
      const [source, clone] = options;
      return isEqual(pick(source, fieldsToPick), pick(clone, fieldsToPick));
    }).length === sourceFormOptions.length;

  if (!optionsAreEqual) {
    throw new Error("Source form options do not match cloned form options.");
  }
}
