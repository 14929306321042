import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SharedState } from "src/services/sharedState";
import { Loading } from "../Feedback/Loading";
import { useQueryParams } from "src/hooks/useQueryParams";
import { AccessDenied } from "../Feedback/AccessDenied";
import { useAuth0 } from "@auth0/auth0-react";
import { logout } from "src/services/url";
import { getAuthContext } from "../Providers/state/localStorage";

/*
    The purpose of this component is to handle any authorization postprocessing after authorization has been completed by auth 0.
*/
export function AuthCallback() {
  const navigate = useNavigate();
  const url = useReactiveVar(SharedState.redirectURL);
  const queryParams = useQueryParams();
  const { error: auth0Error } = useAuth0();
  const authContext = getAuthContext();

  React.useEffect(() => {
    if (auth0Error !== undefined)
      navigate(logout(`/${authContext?.organization}` ?? undefined));

    if (url !== null) {
      // WARNING: when url is updated the whole component re-renders
      // this could be mitigated by using ReplaySubject in RXJS, however, rxjs adds a lot of bytes to our bundle.
      navigate(url);
    }
  }, [navigate, url, auth0Error, authContext]);

  if (queryParams["error"]) {
    console.error(`${queryParams["error_description"]}`);
    return <AccessDenied></AccessDenied>;
  }

  // It's possible for the user to get stuck here at the "/auth" endpoint if the redirect in Auth0ProviderWithHistory never happens
  //   or someone navigates directly to it.
  return <Loading></Loading>;
}
