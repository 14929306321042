import { Badge, Box, FormLabel } from "@chakra-ui/react";
import { InputControl, InputControlProps } from "formik-chakra-ui";
import React from "react";

export declare type Props = InputControlProps & {
  badge?: string;
};

export const InputControlWithBadge: React.FC<Props> = (props) => {
  return (
    <Box>
      <FormLabel
        htmlFor={props.id}
        display="flex"
        alignItems="baseline"
        marginBottom="0.5rem"
      >
        <Box fontSize="sm">{props.label}</Box>
        {props.badge && (
          <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
            {props.badge}
          </Badge>
        )}
      </FormLabel>
      <InputControl {...props} label="" />
    </Box>
  );
};
