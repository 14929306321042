import { Text, VStack } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { FORM_STATUS } from "src/constants";
import type { OrganizationError } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDate } from "src/services/format";
import type * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { getForm } from "../helpers";
import { ColumnId } from "./constants";

type StatusColumnDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  header?: string;
};

type Row = {
  form: { status: GQL.form_status_enum } | null | undefined;
  form_school_rank: {
    form_school_offer_status_history: {
      submitted_at: timestamptz | null;
    } | null;
  } | null;
};
export function buildStatusColumnDef<T extends Row>({
  header,
  organization,
}: StatusColumnDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form?.status,
    id: ColumnId.Status,
    header: header ?? "status",

    cell: (props) => (
      <VStack align="left">
        <StatusTag status={FORM_STATUS[getForm(props.row.original).status]} />

        {props.row.original.form_school_rank?.form_school_offer_status_history
          ?.submitted_at && (
          <Text fontSize="xs">
            {formatIsoDateToOrgDate(
              props.row.original.form_school_rank
                ?.form_school_offer_status_history?.submitted_at || "",
              organization
            )}
          </Text>
        )}
      </VStack>
    ),
  };
}
