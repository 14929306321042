import { ApolloError as ApolloErrorType } from "@apollo/client";
import React from "react";
import { Navigate } from "react-router-dom";
import { NotFoundError as OrgNotFoundError } from "src/hooks/useOrganization";
import { useOrganizations } from "src/hooks/useOrganizations";
import useUser from "src/hooks/useUser";
import { VerifyEmail } from "src/scenes/public/VerifyEmail";
import * as URL from "src/services/url";
import { Status } from "src/types/authData";
import { HasuraRole } from "src/types/hasuraRole";
import { AccessDenied } from "../Feedback/AccessDenied";
import { ApolloError } from "../Feedback/ApolloError";
import { Loading } from "../Feedback/Loading";
import { OrganizationError } from "../Feedback/OrganizationError";
import { RemoteDataView } from "../Layout/RemoteDataView";

export function HasuraRoleRedirector(): React.ReactElement {
  const user = useUser();
  const organizations = useOrganizations();

  if (user.status === Status.LOADING) {
    return <Loading />;
  }

  if (user.status === Status.NOT_VERIFIED) {
    return <VerifyEmail />;
  }

  if (user.status === Status.UNAUTHENTICATED) {
    return <AccessDenied />;
  }

  const destination = organizations.map((orgs): string | null => {
    const firstOrgId = user.data.organizations[0];
    const firstOrg = orgs.find((org) => org.id === firstOrgId) ?? orgs[0];
    if (firstOrg === undefined) return null;

    switch (user.data.role) {
      case HasuraRole.ORG_ADMIN:
      case HasuraRole.DISTRICT_ADMIN:
      case HasuraRole.SCHOOL_ADMIN:
        return "/" + firstOrg.path + "/admin";
      case HasuraRole.USER:
        return "/" + firstOrg.path;
      default:
        return null;
    }
  });

  switch (user.data.role) {
    case HasuraRole.ADMIN:
      return <Navigate to={URL.Admin.Path} />;
    case HasuraRole.ORG_ADMIN:
    case HasuraRole.DISTRICT_ADMIN:
    case HasuraRole.SCHOOL_ADMIN:
    case HasuraRole.USER:
      return (
        <RemoteDataView
          remoteData={destination}
          error={(err: ApolloErrorType) => <ApolloError error={err} />}
        >
          {(path: string | null) => {
            if (path === null)
              return (
                <OrganizationError
                  error={new OrgNotFoundError()}
                ></OrganizationError>
              );
            return <Navigate to={path} />;
          }}
        </RemoteDataView>
      );
    default:
      return <AccessDenied />;
  }
}
