import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Text,
  theme,
} from "@chakra-ui/react";

export enum ConfigureQuestionMenuTabs {
  ConfigureQuestion = "ConfigureQuestion",
  DateOfBirth = "DateOfBirth",
  LocationBoundaries = "LocationBoundaries",
}

type TabSeparator = { type: "Separator"; label: string };
type Tab = {
  type: ConfigureQuestionMenuTabs;
  label: string;
  status?: "on" | "off";
};
type TabItem = TabSeparator | Tab;

type Props = {
  activeTab: ConfigureQuestionMenuTabs;
  onMenuChange: (newTab: ConfigureQuestionMenuTabs) => void;
  items: TabItem[];
};
export const ConfigureQuestionMenu: React.FC<Props> = ({
  activeTab,
  onMenuChange,
  items,
}) => {
  return (
    <Card variant="outline" height="fit-content" width="30%">
      <CardBody style={{ padding: 0 }}>
        <Box
          style={{
            background:
              activeTab === ConfigureQuestionMenuTabs.ConfigureQuestion
                ? theme.colors.gray[200]
                : "transparent",
            color: "black",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            fontWeight: 500,
            justifyContent: "flex-start",
            width: "100%",
          }}
          as={Button}
          onClick={() =>
            onMenuChange(ConfigureQuestionMenuTabs.ConfigureQuestion)
          }
        >
          Question configuration
        </Box>
        {items.map((item) => (
          <TabMenuItem
            key={item.label}
            item={item}
            onMenuChange={onMenuChange}
            activeTab={activeTab}
          />
        ))}
      </CardBody>
    </Card>
  );
};

type TabMenuItemProps = {
  item: TabItem;
  activeTab: ConfigureQuestionMenuTabs;
  onMenuChange: (newTab: ConfigureQuestionMenuTabs) => void;
};
function TabMenuItem({ item, activeTab, onMenuChange }: TabMenuItemProps) {
  switch (item.type) {
    case "Separator":
      return (
        <Box p={4} fontSize="sm">
          {item.label}
        </Box>
      );

    default:
      return (
        <Box
          style={{
            background:
              activeTab === item.type ? theme.colors.gray[200] : "transparent",
            color: "black",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            fontWeight: 500,
            justifyContent: "space-between",
            width: "100%",
            bottom: 0,
            display: "flex",
          }}
          as={Button}
          onClick={() => onMenuChange(item.type)}
        >
          <Text>{item.label}</Text>
          {item.status !== undefined && (
            <Badge
              sx={{ padding: 1, borderRadius: 10 }}
              colorScheme={item.status === "on" ? "green" : "gray"}
            >
              {item.status === "on" ? "On" : "Off"}
            </Badge>
          )}
        </Box>
      );
  }
}
