import React from "react";
import {
  getSaveStatus,
  SaveStatus,
  SaveStatuses,
} from "src/services/formTemplate/question";

export type AutosaveAction =
  | {
      action: "update";
      saving: boolean;
      questionId: uuid;
    }
  | { action: "reset" };

const autosaveReducer = (
  state: SaveStatuses,
  action: AutosaveAction
): SaveStatuses => {
  switch (action.action) {
    case "update":
      return {
        ...state,
        [action.questionId]: action.saving ? "Saving" : "Saved",
      };
    case "reset":
      return {};
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
};

type Props = {
  duration?: number;
};
export const useAutosaveReducer = (props?: Props) => {
  const [autosaveState, updateAutosaveState] = React.useReducer(
    autosaveReducer,
    {}
  );

  const autosaveStatus: SaveStatus = getSaveStatus(autosaveState);
  const onAutosave = (questionId: uuid, saving: boolean) => {
    updateAutosaveState({
      action: "update",
      saving,
      questionId,
    });
  };

  React.useEffect(() => {
    if (autosaveStatus === "Saved") {
      setTimeout(() => {
        updateAutosaveState({ action: "reset" });
      }, props?.duration ?? 5000);
    }
  }, [autosaveStatus, props?.duration]);

  return { autosaveStatus, onAutosave };
};
