import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { RiArrowDropDownLine, RiEyeLine } from "react-icons/ri";
import { VISIBILITY_FILTER_OPTIONS, VisibilityOptions } from "src/constants";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";

const visibleTooltipText =
  "Display this form for parents on its current status";
const notVisibleTooltipText =
  "Hide form from parents until they choose to start this form on their own";

const visibleDialog = {
  key: VisibilityOptions.Visible,
  header: "Set to Visible?",
  body: "Setting this form to Visible will display it for parents on their current status until its visibility is changed again.",
  bulkBody:
    "Setting these items to Visible will display them for parents on their current status until their visibility is changed again.",
};
const notVisibleDialog = {
  key: VisibilityOptions.NotVisible,
  header: "Set to Not Visible?",
  body: "Setting this form to Not visible will hide it from parents until they choose to start this form on their own, or its visibility is changed again.",
  bulkBody:
    "Setting these items to Not visible will hide them from parents until they choose to start these forms on their own, or their visibility is changed again.",
};

interface SetVisibilityMenuProps {
  formVisibility?: boolean | null;
  onVisibilityChange: (visibility: boolean) => void;
  isBulkAction?: boolean;
  formsCount?: number;
  onInitialClick?: () => void;
}

export function SetVisibilityMenu({
  formVisibility,
  onVisibilityChange,
  isBulkAction,
  formsCount,
  onInitialClick,
}: SetVisibilityMenuProps) {
  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      body: formVisibility ? visibleDialog.body : notVisibleDialog.body,
      header: formVisibility ? visibleDialog.header : notVisibleDialog.header,
      confirmButton: {
        label: "Yes, set visibility",
      },
    });

  const handleVisibilityChange = useCallback(
    async (value: string | string[]) => {
      if (isBulkAction) {
        setHeader(
          `Set ${formsCount} items to ${
            value === "true" ? "Not Visible" : "Visible"
          }?`
        );
        setBody(
          value === "true" ? notVisibleDialog.bulkBody : visibleDialog.bulkBody
        );
      }
      if (await confirm()) {
        onVisibilityChange(value === "true");
      }
    },
    [confirm, onVisibilityChange, setHeader, setBody, isBulkAction, formsCount]
  );

  const getMenuItemLabel = useMemo(() => {
    if (isBulkAction) {
      return (
        <Flex gap={2} alignItems="center">
          <Icon as={RiEyeLine} />
          <Text>Set visibility</Text>
        </Flex>
      );
    }

    return <Text>Set visibility</Text>;
  }, [isBulkAction]);

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<RiArrowDropDownLine />}
          size="sm"
          variant="banner"
          onClick={onInitialClick}
        >
          {getMenuItemLabel}
        </MenuButton>

        <MenuList>
          <MenuOptionGroup
            onChange={isBulkAction ? undefined : handleVisibilityChange}
            value={isBulkAction ? "" : formVisibility ? "true" : "false"}
          >
            {VISIBILITY_FILTER_OPTIONS.map((option) => (
              <MenuItemOption
                key={option.key}
                value={
                  option.key === VisibilityOptions.Visible ? "false" : "true"
                }
                onClick={
                  isBulkAction
                    ? () =>
                        handleVisibilityChange(
                          option.key === VisibilityOptions.Visible
                            ? "false"
                            : "true"
                        )
                    : undefined
                }
              >
                <Tooltip
                  label={
                    option.key === VisibilityOptions.Visible
                      ? visibleTooltipText
                      : notVisibleTooltipText
                  }
                  placement="left"
                  hasArrow
                  padding={2}
                  borderRadius={4}
                  right={10}
                >
                  <Text>{option.label}</Text>
                </Tooltip>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {confirmationDialog}
    </>
  );
}
