import { useToast } from "@chakra-ui/react";
import { DeleteButton } from "src/components/Buttons/DeleteButton";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_PROGRAM_GROUP } from "./graphql/mutations";

interface DeleteProgramGroupButtonProps {
  id: string;
  onDelete: () => void;
}

export const DeleteProgramGroupButton: React.FC<
  DeleteProgramGroupButtonProps
> = ({ id, onDelete }) => {
  const toast = useToast();

  const [deleteProgramGroup, { remoteData }] = useRemoteDataMutation<
    GQL.DeleteProgramGroup,
    GQL.DeleteProgramGroupVariables
  >(DELETE_PROGRAM_GROUP);

  const handleDelete = async () => {
    try {
      await deleteProgramGroup({ variables: { id: id } });
      onDelete();
    } catch (error) {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Error deleting program group",
          description:
            "Please try again or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <DeleteButton
      label="Delete"
      loading={remoteData.isLoading()}
      onDelete={handleDelete}
      confirmationHeader="Delete Program Group"
    />
  );
};
