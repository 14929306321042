import { Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { Verification } from "src/components/Form/QuestionForm/Edit/Verification";
import {
  FormType,
  getLocationBoundariesFilterFormValue,
  isLocationBoundariesFilter,
} from "src/components/Form/QuestionForm/formik";
import { ConfigureLocationBoundaries } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureLocationBoundaries";
import { ConfigureQuestion } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureQuestion";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import {
  ConfigureQuestionMenu,
  ConfigureQuestionMenuTabs,
} from "../QuestionConfiguration/ConfigureQuestionMenu";
import { PermissionLevel } from "./PermissionLevel";
import { QuestionLink } from "./QuestionLink";

export type AddressQuestionFormProps = {
  verificationOptions: AF.FormVerification<AF.WithId>[];
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  questionId: uuid;
  sectionType: AF.SectionType;
  otherQuestions: readonly Draft.Question[];
};
export function AddressQuestionForm({
  verificationOptions,
  questionId,
  otherQuestions,
}: AddressQuestionFormProps) {
  const [configureQuestionTab, setConfigureQuestionTab] =
    React.useState<ConfigureQuestionMenuTabs>(
      ConfigureQuestionMenuTabs.ConfigureQuestion
    );

  const formik = useFormikContext<FormType>();

  const handleConfigureMenuChange = (newTab: ConfigureQuestionMenuTabs) => {
    setConfigureQuestionTab(newTab);
  };

  const filters = getLocationBoundariesFilterFormValue(
    questionId,
    formik.values
  );

  const alreadyHasLocationBoundariesFilter = otherQuestions.some(
    (otherQuestion) =>
      (otherQuestion.filters?.filter(isLocationBoundariesFilter).length ?? 0) >
      0
  );

  return (
    <>
      <Flex direction="row" gap="4">
        <ConfigureQuestionMenu
          activeTab={configureQuestionTab}
          onMenuChange={handleConfigureMenuChange}
          items={[
            { type: "Separator", label: "Limit available options" },
            {
              type: ConfigureQuestionMenuTabs.LocationBoundaries,
              label: "Location Boundaries",
              status: filters.length > 0 ? "on" : "off",
            },
          ]}
        />
        {configureQuestionTab ===
          ConfigureQuestionMenuTabs.ConfigureQuestion && (
          <ConfigureQuestion
            questionId={questionId}
            questionType={AF.AddressType}
          />
        )}
        {configureQuestionTab ===
          ConfigureQuestionMenuTabs.LocationBoundaries && (
          <ConfigureLocationBoundaries
            questionId={questionId}
            disabled={alreadyHasLocationBoundariesFilter}
          />
        )}
      </Flex>
      {configureQuestionTab === ConfigureQuestionMenuTabs.ConfigureQuestion && (
        <Flex direction="column" flexGrow="1" gap="3">
          <Verification
            questionId={questionId}
            verificationOptions={verificationOptions ?? []}
          />
          <QuestionLink questionId={questionId} />
          <PermissionLevel questionId={questionId} />
        </Flex>
      )}
    </>
  );
}
