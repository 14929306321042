import { gql } from "@apollo/client";

export const FETCH_USER_PERMISSIONS = gql`
  query FetchUserPermissions($userID: String!) {
    user_by_pk(id: $userID) {
      school_users {
        school_access_permission
      }
      user_group {
        name
        user_group_permissions {
          permission
        }
      }
    }
  }
`;

export const FETCH_USER_ATTRIBUTES = gql`
  query FetchUserAttributes($userID: String!) {
    user_by_pk(id: $userID) {
      school_users {
        school_id
        school_access_permission
      }
    }
  }
`;
