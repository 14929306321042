import { Box, BoxProps } from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
  sticky?: boolean;
} & BoxProps;
export const AdminFormButtons: React.FC<Props> = ({
  children,
  sticky = true,
  ...boxProps
}) => {
  return (
    <Box
      display="flex"
      direction="row"
      gap={3}
      justifyContent="end"
      width="calc(100% + (var(--chakra-space-10) * 2))"
      marginLeft="calc(-1 * var(--chakra-space-10))"
      marginBottom="calc(-1 * var(--chakra-space-10))"
      paddingX="10"
      paddingY="5"
      bg="white"
      zIndex="1"
      {...(sticky
        ? {
            shadow: "dark-lg",
            position: "sticky",
            bottom: "calc(-1 * var(--chakra-space-10))",
          }
        : {})}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
