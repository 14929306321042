import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Parents",
      href: organization
        .map((org) => Url.OrgAdmin.Parents.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNew = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create Parent",
      href: organization
        .map((org) => Url.OrgAdmin.Parents.new(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  parent: GQL.GetParent_person_by_pk
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: `${parent.first_name} ${parent.last_name}`,
      href: organization
        .map((org) => Url.OrgAdmin.Parents.edit(org, parent.id))
        .withDefault("#"),
    },
  ];
};
