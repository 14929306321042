import { Code, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { Table } from "src/components/Table/Table";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { DeleteProgramGroupButton } from "./DeleteButton";

interface ProgramsListProps {
  programGroups: GQL.GetProgramGroups_program_group[];
  onDelete: () => void;
}

export const ProgramsList: React.FC<ProgramsListProps> = ({
  programGroups,
  onDelete,
}) => {
  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const columns: ColumnDef<GQL.GetProgramGroups_program_group>[] = useMemo(
    () => [
      {
        accessorKey: "organization_id",
        header: "organization id",
      },
      {
        accessorKey: "name",
        header: "program group",
      },
      {
        id: "Programs",
        cell: (props) =>
          props.row.original.programs.map((program) => (
            <HStack paddingY={2} key={program.id}>
              <Text>ID:</Text>
              <Code>{program.id}</Code>
              <Text>Label:</Text>
              <Code>{program.label}</Code>
            </HStack>
          )),
      },
      {
        id: "Actions",
        cell: (props) => {
          const programGroup = props.row.original;
          return (
            <>
              <IconButton
                as={NavLink}
                to={Url.Admin.Programs.edit(programGroup.id)}
                aria-label={`Edit ${programGroup.name}`}
                icon={<Icon as={RiPencilLine} />}
                variant="outline"
              />
              <DeleteProgramGroupButton
                id={programGroup.id}
                onDelete={handleDelete}
              />
            </>
          );
        },
      },
    ],
    [handleDelete]
  );

  if (programGroups.length === 0) return <span>There are no programs.</span>;

  return (
    <Table<GQL.GetProgramGroups_program_group>
      data={programGroups}
      columns={columns}
    />
  );
};
