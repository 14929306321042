import type { ApolloError } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import * as RemoteData from "src/types/remoteData";
import { GET_FORM_IMPORT } from "./graphql/queries";
import {
  FormImportFlowAction,
  FormImportFlowActionType,
} from "./state/actions";
import { FlowStatus } from "./state/constants";
import type { FormImportFlowState } from "./state/reducer";
import { showInvalidImportToast } from "./toasts";

const POLL_INTERVAL_MS = 10_000;

interface FormImportOptions {
  formImportId: string | null;
  dispatch: React.Dispatch<FormImportFlowAction>;
  state: FormImportFlowState;
}

export function useFormImport(options: FormImportOptions) {
  const { formImportId, dispatch, state } = options;

  const enrollmentPeriodId = state.enrollmentPeriodId || "";

  const toast = useAvelaToast();

  const {
    remoteData: _remoteData,
    startPolling,
    stopPolling,
  } = useRemoteDataQuery<GQL.GetFormImport, GQL.GetFormImportVariables>(
    GET_FORM_IMPORT,
    {
      skip: !formImportId,
      variables: {
        id: formImportId ?? "",
      },
    }
  );

  const formImportRemoteData = _remoteData.andThen((data) => {
    return RemoteData.success(data.form_import[0] ?? null);
  });

  const remoteData = useMemo(() => {
    // Pass the remote data through when the
    if (
      !formImportRemoteData.hasData() ||
      formImportRemoteData.data?.id === formImportId
    ) {
      return formImportRemoteData;
    }

    if (formImportId) {
      return RemoteData.loading<
        ApolloError,
        GQL.GetFormImport_form_import | null
      >(null);
    }

    return RemoteData.notAsked<
      ApolloError,
      GQL.GetFormImport_form_import | null
    >();
  }, [formImportId, formImportRemoteData]);

  let data: GQL.GetFormImport_form_import | null = null;
  // Ignore data from previous form import
  if (remoteData.hasData() && remoteData.data?.id === formImportId) {
    data = remoteData.data;
  }

  const hasError = remoteData.hasError();

  useEffect(() => {
    if (hasError) {
      toast({
        description:
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
        title: "Error loading form import data",
      });
    }
  }, [hasError, toast]);

  const importIsInvalid =
    remoteData.hasData() &&
    remoteData.data?.status === GQL.form_import_status_enum.Invalid;

  useEffect(() => {
    if (importIsInvalid && !state.finalStatusReported) {
      showInvalidImportToast(toast);

      if (state.formTemplateId === null) {
        throw new Error("Form id is null");
      }

      dispatch({
        type: FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE,
        value: {
          formImportId: formImportId ?? "",
          formTemplateId: state.formTemplateId,
          formTemplateName: state.formTemplateName,
          enrollmentPeriodId,
          finalStatusReported: true,
        },
      });
    }
  }, [
    formImportId,
    dispatch,
    enrollmentPeriodId,
    importIsInvalid,
    state,
    toast,
  ]);

  const shouldPoll =
    data === null || data.status === GQL.form_import_status_enum.New;

  let status: FlowStatus;
  if (hasError || data?.status === GQL.form_import_status_enum.Invalid) {
    status = FlowStatus.FAILURE;
  } else if (shouldPoll) {
    status = FlowStatus.STARTED;
  } else {
    status = FlowStatus.SUCCESS;
  }

  useEffect(() => {
    /*
     * After generating an form import, a record will not be immediately
     * available for the given id. Poll for the record until it is available.
     *
     * When the record is available, a status of "New" means that the import has
     * not yet been processed. In this case, continue to poll until the status
     * changes.
     */
    if (shouldPoll) {
      startPolling(POLL_INTERVAL_MS);
    } else {
      stopPolling();
    }

    // Ensure polling stops when component unmounts.
    return stopPolling;
  }, [data, shouldPoll, startPolling, stopPolling]);

  return { remoteData, status };
}
