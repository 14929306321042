import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccessDenied } from "src/components/Feedback/AccessDenied";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as GQL from "src/types/graphql";
import { toTuple } from "src/types/remoteData";
import { GET_STUDENTS_BY_GUARDIAN } from "../../students/graphql/queries";
import { GET_ACTIVE_ENROLLMENT_PERIODS } from "../graphql/queries";
import { StartNewFormWrapper } from "./components/StartNewFormWrapper";

export const StartNewForm: FC = () => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const userProfile = useUserProfile();

  const [enrollmentPeriodIsLocked, setEnrollmentPeriodIsLocked] =
    useState<boolean>(false);

  const { remoteData: studentsRemoteData } = useRemoteDataQuery<
    GQL.GetStudentsByGuardianId,
    GQL.GetStudentsByGuardianIdVariables
  >(GET_STUDENTS_BY_GUARDIAN, {
    fetchPolicy: "no-cache",
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      guardian_id: userProfile.map((user) => user.id).withDefault(""),
    },
    skip: !organization.hasData() || !userProfile.hasData(),
  });

  const { remoteData: enrollmentPeriodsRemoteData } = useRemoteDataQuery<
    GQL.GetActiveEnrollmentPeriods,
    GQL.GetActiveEnrollmentPeriodsVariables
  >(GET_ACTIVE_ENROLLMENT_PERIODS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
    onCompleted: (data) => {
      if (data.enrollment_period.length === 0) {
        setEnrollmentPeriodIsLocked(true);

        if (organization.hasData()) {
          setTimeout(() => {
            navigate(`/${organization.data.path}`);
          }, 2000);
        }
      }
    },
  });

  const mergedData = toTuple(studentsRemoteData, enrollmentPeriodsRemoteData);

  if (enrollmentPeriodIsLocked) {
    return (
      <AccessDenied message="This enrollment period is currently locked, redirecting..." />
    );
  }

  return (
    <ParentRemoteDataLayout remoteData={mergedData}>
      {([studentsByGuardianData, enrollmentPeriodData]) => {
        const studentsByGuardian = studentsByGuardianData.person;
        const enrollmentPeriods = enrollmentPeriodData.enrollment_period;
        return (
          <StartNewFormWrapper
            studentsByGuardian={studentsByGuardian}
            enrollmentPeriods={enrollmentPeriods}
          />
        );
      }}
    </ParentRemoteDataLayout>
  );
};
