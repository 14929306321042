import { Map, Set, ValueObject } from "immutable";
import { useCallback, useState } from "react";
import * as List from "../services/list";

/**
 * Catch-all type representing keys compatible with this selection model (via
 * ImmutableJS).
 */
export type Key = primitive | ValueObject;

export abstract class Selection<K extends Key, V> {
  abstract getKey(value: V): K;
  abstract get size(): number;
  abstract add(...values: V[]): Selection<K, V>;
  abstract remove(...values: V[]): Selection<K, V>;
  abstract includesKey(key: K): boolean;
  abstract materialize(fetchAll: () => Promise<V[]>): Promise<V[]>;
  abstract materializeAny<A>(fetchAll: () => Promise<[K, A][]>): Promise<A[]>;
  abstract materializeKeys(fetchAll: () => Promise<K[]>): Promise<K[]>;
  abstract get isInclusive(): boolean;
  abstract get keysIncludedOrExcluded(): K[];

  static create<K extends Key, V>(
    getKey: (value: V) => K,
    contents: V[] = []
  ): Selection<K, V> {
    return new InclusiveSelection(
      getKey,
      Map(contents.map((v) => [getKey(v), v]))
    );
  }

  selectAll(universeSize: number): Selection<K, V> {
    return new ExclusiveSelection(this.getKey, universeSize);
  }
  clear(): Selection<K, V> {
    return new InclusiveSelection(this.getKey);
  }
  includes(value: V) {
    return this.includesKey(this.getKey(value));
  }
}

class InclusiveSelection<K extends Key, V> extends Selection<K, V> {
  constructor(
    readonly getKey: (value: V) => K,
    readonly selected = Map<K, V>()
  ) {
    super();
  }

  get size() {
    return this.selected.size;
  }
  add(...values: V[]) {
    return new InclusiveSelection(
      this.getKey,
      this.selected.merge(values.map((v) => [this.getKey(v), v]))
    );
  }
  remove(...values: V[]) {
    return new InclusiveSelection(
      this.getKey,
      this.selected.removeAll(values.map((v) => this.getKey(v)))
    );
  }
  includesKey(key: K) {
    return this.selected.has(key);
  }

  async materializeKeys(): Promise<K[]> {
    return this.selected.keySeq().toArray();
  }
  async materialize(): Promise<V[]> {
    return this.selected.valueSeq().toArray();
  }
  async materializeAny<A>(fetchAll: () => Promise<[K, A][]>): Promise<A[]> {
    return List.filterMap(await fetchAll(), ([key, value]) =>
      this.includesKey(key) ? value : null
    );
  }
  get isInclusive() {
    return true;
  }
  get keysIncludedOrExcluded() {
    return this.selected.keySeq().toArray();
  }
}

class ExclusiveSelection<K extends Key, V> extends Selection<K, V> {
  constructor(
    readonly getKey: (value: V) => K,
    readonly universeSize: number,
    readonly unselected = Set<K>()
  ) {
    super();
  }

  get size() {
    return this.universeSize - this.unselected.size;
  }
  add(...values: V[]) {
    return new ExclusiveSelection(
      this.getKey,
      this.universeSize,
      this.unselected.subtract(values.map((v) => this.getKey(v)))
    );
  }
  remove(...values: V[]) {
    return new ExclusiveSelection(
      this.getKey,
      this.universeSize,
      this.unselected.union(values.map((v) => this.getKey(v)))
    );
  }
  includesKey(key: K) {
    return !this.unselected.has(key);
  }

  async materializeKeys(fetchAll: () => Promise<K[]>): Promise<K[]> {
    return (await fetchAll()).filter((key) => this.includesKey(key));
  }
  async materialize(fetchAll: () => Promise<V[]>): Promise<V[]> {
    return (await fetchAll()).filter((value) => this.includes(value));
  }
  async materializeAny<A>(fetchAll: () => Promise<[K, A][]>): Promise<A[]> {
    return List.filterMap(await fetchAll(), ([key, value]) =>
      this.includesKey(key) ? value : null
    );
  }
  get isInclusive() {
    return false;
  }
  get keysIncludedOrExcluded() {
    return this.unselected.keySeq().toArray();
  }
}

/**
 * The key feature of this state hook is the ability to automatically toggle
 * between inclusive and exclusive representations of the selection state. This
 * provides the ability to select all or most items without needing to reference
 * every individual item.
 */
export function useMultiselectState<K extends Key, V>(
  initial: Selection<K, V>
) {
  const [selection, setSelection] = useState<Selection<K, V>>(initial);
  const toggleSelectAll = useCallback((totalCount: number) => {
    setSelection((s) =>
      s.size < totalCount ? s.selectAll(totalCount) : s.clear()
    );
  }, []);
  const toggleSelectItem = useCallback((item: V) => {
    setSelection((s) => (s.includes(item) ? s.remove(item) : s.add(item)));
  }, []);

  return {
    selection,
    setSelection,
    toggleSelectAll,
    toggleSelectItem,
  };
}
