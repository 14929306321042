import { gql } from "@apollo/client";
import {
  ADDRESS_ANSWERS_FRAGMENT,
  CUSTOM_QUESTION_ANSWERS_FRAGMENT,
  FORM_ANSWER_FRAGMENT,
  FORM_DISCLAIMER_FRAGMENT,
  FORM_FRAGMENT,
  FORM_VERIFICATION_RESULT_FRAGMENT,
  GRADES_ANSWERS_FRAGMENT,
} from "src/operations/fragments/form";
import {
  SCHOOL_WITH_GRADES_FRAGMENT,
  SIMPLE_SCHOOL_FRAGMENT,
} from "src/operations/fragments/school";

// This query is shared between parents and admin, but they have different permissions.
// TODO: split this (and other shared queries)
export const GET_FORM_ANSWERS_BY_ID = gql`
  ${FORM_ANSWER_FRAGMENT}
  ${GRADES_ANSWERS_FRAGMENT}
  ${ADDRESS_ANSWERS_FRAGMENT}
  ${CUSTOM_QUESTION_ANSWERS_FRAGMENT}
  ${FORM_DISCLAIMER_FRAGMENT}

  query GetFormAnswersById($form_id: uuid!) {
    form_answer(where: { form_id: { _eq: $form_id } }) {
      ...FormAnswerFragment
    }
    grades_answer(where: { form_id: { _eq: $form_id } }) {
      ...GradesAnswersFragment
    }
    form_address(where: { form_id: { _eq: $form_id } }) {
      ...AddressAnswersFragment
    }
    custom_question_answer(where: { form_id: { _eq: $form_id } }) {
      ...CustomQuestionAnswersFragment
    }
    form_disclaimer(where: { form_id: { _eq: $form_id } }) {
      ...FormDisclaimerFragment
    }
  }
`;

export const GET_FORM_DISCLAIMER_BY_ID = gql`
  ${FORM_DISCLAIMER_FRAGMENT}

  query GetFormDisclaimerById($form_id: uuid!) {
    form_disclaimer(where: { form_id: { _eq: $form_id } }) {
      ...FormDisclaimerFragment
    }
  }
`;

export const GET_FORM_BY_ID = gql`
  ${FORM_FRAGMENT}

  query GetFormById($form_id: uuid!) {
    form(where: { id: { _eq: $form_id }, deleted_at: { _is_null: true } }) {
      ...FormFragment
      person {
        first_name
        last_name
        avatar
        birth_date
      }
    }
  }
`;

export const GET_ELIGIBILITY_ANSWERS = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}

  query GetEligibilityAnswers($form_id: uuid!) {
    form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          form_question: {
            _and: {
              category: { _eq: EligibilityQuestion }
              question: {
                form_question: {
                  form_question_options: {
                    eligibility_schools: { is_eligible: { _eq: true } }
                  }
                }
              }
            }
          }
        }
      }
    ) {
      id
      question_id
      form_answer_options {
        form_question_option {
          eligibility_schools {
            is_eligible
            school {
              ...SimpleSchoolFragment
            }
          }
          question_id
        }
      }
    }
  }
`;

export const GET_PREVIOUS_FORM_SCHOOLS = gql`
  query GetPreviousFormSchools($form_id: uuid!) {
    form_by_pk(id: $form_id) {
      previous_form {
        form_school_ranks {
          school_id
        }
      }
      previous_offer {
        school_id
      }
      previous_waitlist {
        school_id
      }
    }
  }
`;

export const GET_SCHOOLS_RANK = gql`
  ${SCHOOL_WITH_GRADES_FRAGMENT}
  ${FORM_VERIFICATION_RESULT_FRAGMENT}

  query GetSchoolsRank($form_id: uuid!) {
    form_school_rank(
      where: { form_id: { _eq: $form_id } }
      order_by: { rank: asc }
    ) {
      id
      rank
      sub_status
      schools_ranking_section_id
      school {
        ...SchoolWithGradesFragment
      }
      form {
        id
        status
        form_verification_results {
          ...FormVerificationResultFragment
        }
      }
      form_verifications_flattened {
        verifications
      }
      offers(where: { deleted_at: { _is_null: true } }) {
        id
        status
      }
      waitlists(where: { deleted_at: { _is_null: true } }) {
        id
        status
        waitlist_position {
          position
        }
      }
    }
  }
`;

export const GET_ACTIVE_ENROLLMENT_PERIODS = gql`
  query GetActiveEnrollmentPeriods($organization_id: uuid!) {
    enrollment_period(
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          active: { _eq: true }
          deleted_at: { _is_null: true }
        }
      }
    ) {
      id
      name
      form_templates(
        where: {
          _and: {
            active: { _eq: true }
            locked: { _eq: false }
            deleted_at: { _is_null: true }
          }
        }
      ) {
        id
        name
        description
        open_at
      }
    }
  }
`;

export const GET_ASSIGNED_FORM = gql`
  query GetAssignedForm($student_id: uuid!, $form_template_id: uuid!) {
    assigned_form(
      where: {
        person_id: { _eq: $student_id }
        form_template_id: { _eq: $form_template_id }
        has_started: { _eq: false }
        deleted_at: { _is_null: true }
      }
    ) {
      id
      person_id
      form_template {
        id
      }
      previous_form_id
      previous_offer_id
      previous_waitlist_id
    }
  }
`;

export const GET_HIDDEN_FORM = gql`
  query GetHiddenForm($student_id: uuid!, $form_template_id: uuid!) {
    form(
      where: {
        person_id: { _eq: $student_id }
        form_template_id: { _eq: $form_template_id }
        is_hidden_from_parent: { _eq: true }
      }
    ) {
      id
    }
  }
`;

export const GET_LAST_DELETED_FORM = gql`
  query GetLastDeletedForm($student_id: uuid!, $form_template_id: uuid!) {
    form(
      where: {
        deleted_at: { _is_null: false }
        person_id: { _eq: $student_id }
        form_template_id: { _eq: $form_template_id }
      }
      order_by: { deleted_at: desc_nulls_last }
      limit: 1
    ) {
      id
    }
  }
`;

export const GET_SOURCE_AND_TARGET_FORM_TEMPLATE_IDS = gql`
  query GetSourceAndTargetFormTemplateIds(
    $source_form_id: uuid!
    $target_form_id: uuid!
  ) {
    source_form: form_by_pk(id: $source_form_id) {
      form_template_id
    }
    target_form: form_by_pk(id: $target_form_id) {
      form_template_id
    }
  }
`;

export const GET_CUSTOM_QUESTION_ANSWERS = gql`
  query GetCustomQuestionAnswers($form_id: uuid!) {
    custom_question_answer(where: { form_id: { _eq: $form_id } }) {
      form_answer {
        question_id
      }
    }
    custom_question_answer_bank_relationship(
      where: { form_id: { _eq: $form_id } }
    ) {
      custom_question_id
      person_answer_bank_id
    }
  }
`;
