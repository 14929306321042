import React, { useContext } from "react";
import {
  EnrollmentPeriodIdContext,
  GlossaryContext,
} from "src/hooks/useGlossary/context";
import { replaceWords } from "src/hooks/useGlossary/services";
import { GLOSSARY_TYPES } from "src/hooks/useGlossary/types";
import { useOrganization } from "src/hooks/useOrganization";
import type { GlossaryFn } from "src/types/glossary";

export {
  EnrollmentPeriodIdContext,
  EnrollmentPeriodIdProvider,
  GlossaryContext,
  GlossaryContextProvider,
} from "src/hooks/useGlossary/context";
export { GET_GLOSSARY as GET_GLOSSARY_BY_ORGANIZATION } from "src/hooks/useGlossary/graphql/queries";

type EnrollmentPeriodId = { enrollmentPeriodId: uuid; organizationId?: never };
type OrganizationId = { organizationId: uuid; enrollmentPeriodId?: never };
export type Props = EnrollmentPeriodId | OrganizationId;

/**
 * Hook for getting glossary template literals function for glossary based text replacement.
 * @param props Use this props to override enrollmentPeriodId OR organizationId value from context, or you're unable to pass the value through context.
 */
export function useGlossary(props?: Props): { glossary: GlossaryFn } {
  const organization = useOrganization();
  const enrollmentPeriodIdFromContext: string | undefined = useContext(
    EnrollmentPeriodIdContext
  );

  const enrollmentPeriodId: string | undefined =
    props?.enrollmentPeriodId ?? enrollmentPeriodIdFromContext;
  const organizationId: string | undefined =
    props?.organizationId ?? organization.toNullable()?.id;

  const getAlias = useContext(GlossaryContext);
  const replaceWithGlossaryAlias = React.useCallback(
    (value: string): string => {
      if (getAlias === undefined) {
        return value;
      }

      const words = GLOSSARY_TYPES.flatMap((glossaryType) => {
        const alias = getAlias(glossaryType, {
          organizationId,
          enrollmentPeriodId,
        });
        if (alias === undefined) {
          return [];
        }
        return [
          { oldWord: alias.default.singular, newWord: alias.alias.singular },
          { oldWord: alias.default.plural, newWord: alias.alias.plural },
        ];
      });
      return replaceWords(value, words);
    },
    [enrollmentPeriodId, getAlias, organizationId]
  );

  const glossary = React.useCallback(
    (strings: TemplateStringsArray, ...values: any[]) => {
      const interpolatedString = String.raw({ raw: strings }, ...values);
      const updatedString = replaceWithGlossaryAlias(interpolatedString);
      return updatedString;
    },
    [replaceWithGlossaryAlias]
  );

  return { glossary };
}
