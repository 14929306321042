import { Outlet } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { Loading } from "../Feedback/Loading";
import { OrganizationError } from "../Feedback/OrganizationError";

export function RequireOrganization() {
  const organization = useOrganization();

  if (organization.isLoading()) {
    return <Loading />;
  }

  if (organization.hasError()) {
    return <OrganizationError error={organization.error} />;
  }

  return <Outlet />;
}
