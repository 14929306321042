import { Flex, Text, VStack } from "@chakra-ui/react";
import { useMemo, type ComponentProps, type ReactNode } from "react";
import { Prettify } from "src/types/utils";

const configsBySize = {
  sm: {
    descriptionFontSize: "md",
    descriptionOnlyFontSize: "1.125rem",
    headingFontSize: "1.125rem",
    svgSize: "80px",
  },
  md: {
    descriptionFontSize: "1.125rem",
    descriptionOnlyFontSize: "1.125rem",
    headingFontSize: "lg",
    svgSize: "120px",
  },
  lg: {
    descriptionFontSize: "md",
    descriptionOnlyFontSize: "lg",
    headingFontSize: "xl",
    svgSize: "150px",
  },
};

type HeadingAndOrDescription =
  | {
      /**
       * Inline content—e.g. text and links—further communiating the intention
       * of this empty state.
       *
       * This content is enclosed in a paragraph element and must consist only
       * of phrasing content.
       *
       * Required when `heading` is not provided.
       */
      description: ReactNode;

      /**
       * Brief text generally stating the intention of this empty state.
       *
       * Required when `description` is not provided.
       */
      heading?: string;
    }
  | {
      description?: ReactNode;
      heading: string;
    };

type Graphic =
  | {
      Svg: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
      graphic?: never;
    }
  | {
      graphic: ReactNode;
      Svg?: never;
    };

type EmptyStateProps = Prettify<
  ComponentProps<typeof Flex> & {
    children?: ReactNode | ReactNode[];
    size?: keyof typeof configsBySize;
  } & HeadingAndOrDescription &
    Graphic
>;

export function EmptyState(props: EmptyStateProps) {
  const {
    children,
    description,
    graphic,
    heading,
    size = "md",
    Svg,
    ...flexProps
  } = props;

  const config = configsBySize[size];
  const { headingFontSize, svgSize } = config;

  let { descriptionFontSize } = config;
  if (!heading) {
    descriptionFontSize = config.descriptionOnlyFontSize;
  }

  let graphicElement = graphic;
  if (Svg) {
    graphicElement = <Svg aria-hidden height={svgSize} width={svgSize} />;
  }

  const isReactDescription = useMemo(() => {
    return typeof description === "object";
  }, [description]);

  return (
    <Flex
      alignItems="center"
      direction="column"
      gap={4}
      maxInlineSize="520px"
      width="100%"
      {...flexProps}
    >
      {graphicElement}

      <VStack textAlign="center">
        {!!heading && (
          <Text color="gray.700" fontSize={headingFontSize} fontWeight="600">
            {heading}
          </Text>
        )}

        {!!description && isReactDescription ? (
          <>{description}</>
        ) : (
          <Text color="gray.600" fontSize={descriptionFontSize}>
            {description}
          </Text>
        )}
      </VStack>

      {children}
    </Flex>
  );
}

export function EmptyStateCard(props: EmptyStateProps) {
  return (
    <EmptyState
      {...props}
      bg="white"
      borderRadius="md"
      boxShadow="md"
      gap={4}
      maxInlineSize="auto"
      padding={6}
    />
  );
}
