export function formatUuid(uuid: string): string {
  return uuid.toLowerCase();
}

/**
 * @returns The entity's reference_id if set, else a derived identifier.
 */
export function getReferenceId(entity: {
  id: string;
  reference_id: string | null;
}): string {
  if (entity === null) return "";
  // Treat NULL and the empty string as the same.
  return entity.reference_id || formatUuid(entity.id);
}
