import { Flex, Text, TextProps } from "@chakra-ui/react";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { SchoolWithBoundaryTags } from ".";
import { BoundaryTag } from "src/components/Tags/BoundaryTag";
import {
  StatusTag,
  StatusTagMetadata,
} from "src/components/DataDisplay/StatusTag";

type Props = {
  school: SchoolWithBoundaryTags;
  showAddress?: boolean;
  textProps?: TextProps;
  subStatus?: StatusTagMetadata;
};
export const SchoolListItem: React.FC<Props> = ({
  school,
  showAddress,
  textProps,
  subStatus,
}) => {
  return (
    <Flex direction="column" className={WEGLOT_SKIP_CLASS} gap={2}>
      <Flex direction="column">
        <Text {...textProps}>{school.name}</Text>
        {showAddress && <Text>{school.street_address}</Text>}
      </Flex>
      {subStatus && <StatusTag status={subStatus} />}
      <Flex direction="row" gap="2">
        {school.boundaryTags?.map((tag) => (
          <BoundaryTag tag={tag} key={tag} size="sm" />
        ))}
      </Flex>
    </Flex>
  );
};
