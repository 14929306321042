import { useField } from "formik";
import { FunctionComponent } from "react";
import { JsonEditor, JsonEditorProps } from "./JsonEditor";

type Props = Omit<JsonEditorProps, "text" | "onChange" | "onChangeValidity"> & {
  fieldName: string;
};

export const FormikJsonEditor: FunctionComponent<Props> = (props) => {
  const { fieldName, ...jsonEditorProps } = props;
  const [{ value }, , { setValue }] = useField(fieldName);

  return <JsonEditor text={value} onChange={setValue} {...jsonEditorProps} />;
};
