import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { RiMore2Fill } from "react-icons/ri";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as List from "src/services/list";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import { FormSchool } from "../types";
import { AcceptOffer } from "./AcceptOffer";
import { AddToWaitlists } from "./AddToWaitlists";
import { ClearSubstatus } from "./ClearSubstatus";
import { DeclineOffer } from "./DeclineOffer";
import { ExcludeForm } from "./ExcludeForm";
import { MakeOffer } from "./MakeOffer";
import { RemoveFromWaitlists } from "./RemoveFromWaitlists";
import { RevokeOffer } from "./RevokeOffer";

type Props = {
  selectedFormSchools: RD.RemoteData<unknown, FormSchool[]>;
  onRefetch?: () => void;
};
export const SingleMoreMenu: React.FC<Props> = ({
  selectedFormSchools,
  onRefetch,
}) => {
  const searchKeys: GQL.form_school_rank_bool_exp[] = React.useMemo(
    () =>
      List.filterMap(selectedFormSchools.withDefault([]), (appSchool) => {
        if (!appSchool.form || !appSchool.school) return null;

        return {
          form_id: { _eq: appSchool.form.id },
          school_id: { _eq: appSchool.school.id },
        };
      }),
    [selectedFormSchools]
  );

  const { remoteData: statusData } = useRemoteDataQuery<
    GQL.FetchOfferWaitlistStatus,
    GQL.FetchOfferWaitlistStatusVariables
  >(FETCH_OFFER_WAITLIST_STATUS, {
    variables: { search_keys: searchKeys },
    skip: !selectedFormSchools.hasData(),
    fetchPolicy: "cache-and-network",
  });

  const selectedFormSchoolStatuses = React.useMemo(() => {
    return statusData.map((data) => data.form_school_rank);
  }, [statusData]);

  const selectedFormSchoolsList = selectedFormSchools.withDefault([]);

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        variant="banner"
        size="sm"
        leftIcon={<RiMore2Fill />}
        onClick={() => {}}
      >
        More
      </MenuButton>

      <MenuList>
        <MenuGroup title="Waitlist">
          <AddToWaitlists
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchoolsList}
            onRefetch={onRefetch}
            menuLabel="Add to waitlist"
          />
          <RemoveFromWaitlists
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchoolsList}
            onRefetch={onRefetch}
            menuLabel="Remove from waitlist"
          />
        </MenuGroup>

        <Divider />

        <MenuGroup title="Offer">
          <MakeOffer
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchoolsList}
            onRefetch={onRefetch}
            menuLabel="Make offer"
          />
          <AcceptOffer
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchools.withDefault([])}
            onRefetch={onRefetch}
            menuLabel="Accept offer"
          />
          <DeclineOffer
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchoolsList}
            onRefetch={onRefetch}
            menuLabel="Decline offer"
          />
          <RevokeOffer
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            selectedFormSchools={selectedFormSchoolsList}
            onRefetch={onRefetch}
            menuLabel="Revoke offer"
          />
        </MenuGroup>

        <WithRequiredHasuraRoles
          roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
        >
          <Divider />
          <MenuGroup>
            <ExcludeForm
              selectedFormSchoolStatuses={selectedFormSchoolStatuses}
              onRefetch={onRefetch}
            />
          </MenuGroup>
        </WithRequiredHasuraRoles>
        <Divider />
        <MenuGroup>
          <ClearSubstatus
            selectedFormSchoolStatuses={selectedFormSchoolStatuses}
            onRefetch={onRefetch}
          />
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
