export function sortByOrder(a: { order: number }): number {
  return a.order;
}

export function sortList<ITEM>(
  list: readonly ITEM[],
  sourceIndex: number,
  destinationIndex: number
): readonly ITEM[] {
  if (sourceIndex === destinationIndex) {
    return list;
  }

  const movedItem = list[sourceIndex];
  if (movedItem === undefined) return list;

  const sortedList = [...list];
  sortedList.splice(sourceIndex, 1);
  sortedList.splice(destinationIndex, 0, movedItem);
  return sortedList;
}
