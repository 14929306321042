import { useOutletContext, useParams } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import { FETCH_FORM_TEMPLATE_STATUSES } from "../../graphql/queries";
import {
  StatusDescriptionsForm,
  StatusDescriptionsFormType
} from "./StatusDescriptionsForm";
import { EditFormTemplateStatusesProvider } from "./context";
import { useCallback, useMemo } from "react";
import { ParentCopyright } from "src/constants/strings";
import { useOrganization } from "src/hooks/useOrganization";

type FormStatusDescriptions =
  GQL.FetchFormTemplateStatuses_form_status_description[];

const StatusViewOrder = [
  GQL.form_status_enum.InProgress,
  GQL.form_status_enum.Submitted,
  GQL.form_status_enum.Verified,
  GQL.form_status_enum.LotteryReady,
  GQL.form_status_enum.Admissions,
  GQL.form_status_enum.Cancelled
] as const;

export const StatusesTab: React.FC = () => {
  const organization = useOrganization();
  const { formTemplateId = "" } = useParams();
  const { actionBarRef, formTemplate, closedAtDate } =
    useOutletContext<FormTemplateOutletContext>();

  const { remoteData } = useRemoteDataQuery<
    GQL.FetchFormTemplateStatuses,
    GQL.FetchFormTemplateStatusesVariables
  >(FETCH_FORM_TEMPLATE_STATUSES, {
    variables: {
      form_template_id: formTemplateId
    },
    fetchPolicy: "no-cache"
  });

  const defaultFormNextStep = useMemo(
    () => ParentCopyright.DefaultFormNextStep(closedAtDate, organization),
    [closedAtDate, organization]
  );

  const getFormStatusDescription = useCallback(
    (
      status: GQL.form_status_enum,
      formStatusDescriptions: FormStatusDescriptions
    ) => {
      return formStatusDescriptions?.find(
        (formStatusDescription) => formStatusDescription.status === status
      )?.description;
    },
    []
  );

  const getFormattedStatusDescriptions = useCallback(
    (formStatusDescriptions: FormStatusDescriptions) =>
      StatusViewOrder.map((status) => ({
        [status]:
          getFormStatusDescription(status, formStatusDescriptions) ??
          defaultFormNextStep[status]
      })),
    [getFormStatusDescription, defaultFormNextStep]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        const initialValues: StatusDescriptionsFormType = Object.assign(
          {},
          ...getFormattedStatusDescriptions(data.form_status_description)
        );

        return (
          <EditFormTemplateStatusesProvider
            formTemplateId={formTemplateId}
            statusesInitialValues={initialValues}
          >
            <StatusDescriptionsForm
              actionBarRef={actionBarRef}
              formTemplate={formTemplate}
              isLoading={remoteData.isLoading()}
            />
          </EditFormTemplateStatusesProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
