import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { EnrollmentPeriodOutletContext } from "../../Edit";
import { UPDATE_ENROLLMENT_PERIOD_INFO_BY_ID } from "../../graphql/mutations";
import {
  GET_ENROLLMENT_PERIODS,
  GET_ENROLLMENT_PERIOD_BY_ID,
} from "../../graphql/queries";
import { EnrollmentPeriodFormType } from "../../schemas";
import { GeneralForm } from "./GeneralForm";

export const GeneralTab: React.FC = () => {
  const toast = useToast();

  const { enrollmentPeriod } =
    useOutletContext<EnrollmentPeriodOutletContext>();

  const [updateEnrollmentPeriodInfoById, { remoteData: updateRemoteData }] =
    useRemoteDataMutation<
      GQL.UpdateEnrollmentPeriodInfoById,
      GQL.UpdateEnrollmentPeriodInfoByIdVariables
    >(UPDATE_ENROLLMENT_PERIOD_INFO_BY_ID);

  const handleSubmit = useCallback(
    async (values: EnrollmentPeriodFormType) => {
      try {
        await updateEnrollmentPeriodInfoById({
          variables: {
            id: enrollmentPeriod.id,
            enrollment_period_info: values,
          },
          refetchQueries: [GET_ENROLLMENT_PERIODS, GET_ENROLLMENT_PERIOD_BY_ID],
        });
        toast({
          title: "Success",
          description: "Enrollment period updated",
          status: "success",
          duration: 2000,
        });
      } catch (e) {
        toast({
          title: "Something went wrong",
          description: "Failed to update enrollment period information",
          status: "error",
          duration: 2000,
        });
      }
    },
    [enrollmentPeriod, toast, updateEnrollmentPeriodInfoById]
  );

  return (
    <GeneralForm
      onSubmit={handleSubmit}
      submitting={updateRemoteData.isLoading()}
      enrollmentPeriod={enrollmentPeriod}
    />
  );
};
