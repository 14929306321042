import { Button, Flex } from "@chakra-ui/react";
import { RiSurveyLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { ParentFormsLayoutInner } from "src/components/Layout/ParentFormsLayoutInner";
import { ParentFormsLayoutOuter } from "src/components/Layout/ParentFormsLayoutOuter";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ImportantDateListItem } from "./components/ImportantDateListItem";
import { GET_IMPORTANT_DATES_BY_ENROLLMENT_PERIOD } from "./graphql/queries";

export function List() {
  const organization = useOrganization();

  const { remoteData: enrollmentPeriodsRemoteData } = useRemoteDataQuery<
    GQL.GetImportantDatesByEnrollmentPeriod,
    GQL.GetImportantDatesByEnrollmentPeriodVariables
  >(GET_IMPORTANT_DATES_BY_ENROLLMENT_PERIOD, {
    fetchPolicy: "cache-and-network",

    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
  });

  return (
    <ParentFormsLayoutOuter heading="Important dates">
      <ParentRemoteDataLayout remoteData={enrollmentPeriodsRemoteData}>
        {(enrollmentPeriods) => {
          const footers = (
            <ParentFormsLayoutFooter justifyContent="stretch">
              <Button
                as={Link}
                flexGrow={1}
                leftIcon={<RiSurveyLine />}
                to={organization.map(Url.Parent.Form.new).withDefault("#")}
              >
                New form
              </Button>
            </ParentFormsLayoutFooter>
          );

          return (
            <ParentFormsLayoutInner footers={footers}>
              <Flex flexDirection="column" gap={5}>
                {enrollmentPeriods.enrollment_period.map((enrollmentPeriod) => (
                  <ImportantDateListItem enrollmentPeriod={enrollmentPeriod} />
                ))}
              </Flex>
            </ParentFormsLayoutInner>
          );
        }}
      </ParentRemoteDataLayout>
    </ParentFormsLayoutOuter>
  );
}
