const TABLE_NAME_MAPPER: Record<string, string> = {
  application_address: "form_address",
  application_answer_option: "form_answer_option",
  application_answer_verification_result: "form_answer_verification_result",
  application_answer: "form_answer",
  application_disclaimer: "form_disclaimer",
  application_form_section_type: "form_template_section_type",
  application_form_section: "form_template_section",
  application_form: "form_template",
  application_import_review_status: "form_import_review_status",
  application_import_row_status: "form_import_row_status",
  application_import_row: "form_import_row",
  application_import: "form_import",
  application_offer: "offer",
  application_question_option: "form_question_option",
  application_question: "form_question",
  application_school_offer_status_history: "form_school_offer_status_history",
  application_school_rank: "form_school_rank",
  application_school_tag: "form_school_tag",
  application_source: "form_source",
  application_verification_result_status: "form_verification_result_status",
  application_verification_result: "form_verification_result",
  application_verification: "form_verification",
  application_waitlist_position: "waitlist_position",
  application_waitlist_status: "waitlist_status",
  application_waitlist: "waitlist",
  application: "form",
  application_question_school: "form_question_school",
  application_offer_status: "offer_status",
  application_question_category: "form_question_category",
  application_import_status: "form_import_status",
  application_school_rank_status: "form_school_rank_status",
  application_status_description: "form_status_description",
  message_application_status: "message_form_status",
  application_status: "form_status",
  application_transition: "form_transition",
  message_adhoc_application: "message_adhoc_form",
  tag_by_application_school: "tag_by_form_school",
  message_application_offer_status: "message_offer_status",
};

const COLUMN_NAME_MAPPER: Record<string, string> = {
  application_answer_id: "form_answer_id",
  application_form_id: "form_template_id",
  application_form_section_id: "form_template_section_id",
  application_id: "form_id",
  application_import_id: "form_import_id",
  application_offer_id: "offer_id",
  application_offer_transition: "offer_transition",
  application_question_option_id: "form_question_option_id",
  application_school_rank_id: "form_school_rank_id",
  application_transition: "form_transition",
  application_verification_id: "form_verification_id",
  application_verification_result_id: "form_verification_result_id",
  application_waitlist_id: "waitlist_id",
};

export function tableName(tableName: string): string {
  return TABLE_NAME_MAPPER[tableName] ?? tableName;
}

export function columnName(columnName: string): string {
  return COLUMN_NAME_MAPPER[columnName] ?? columnName;
}

export function renameProperties<T>(obj: T): T {
  if (obj !== null && obj !== undefined && typeof obj === "object") {
    // Create a new object to hold the renamed properties
    const result: any = Array.isArray(obj) ? [] : {};

    // Iterate over the properties of the object
    for (const key in obj) {
      // If the key exists in the lookup, use the new name, otherwise use the original name
      const newKey = COLUMN_NAME_MAPPER[key] || key;

      // Recursively rename properties of the object's properties
      result[newKey] = renameProperties(obj[key]);
    }

    return result;
  }

  // Base case: if it's not an object, return it as is
  return obj;
}
