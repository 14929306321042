import { Text } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import _ from "lodash";
import { ColumnId } from "./constants";

type Row = {
  form_school_rank: {
    lottery_order: number | null;
  } | null;
};
export function buildTiebreakerColumnDef<T extends Row>(): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form_school_rank?.lottery_order,
    id: ColumnId.Tiebreaker,
    header: "tiebreaker",

    cell: (props) => {
      const tieBreaker = props.row.original.form_school_rank?.lottery_order;

      if (tieBreaker == null) {
        return (
          <Text variant="placeholder" textAlign="right">
            No value
          </Text>
        );
      }

      return <Text textAlign="right">{_.round(tieBreaker, 2)}</Text>;
    },
  };
}
