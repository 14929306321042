import { useApolloClient } from "@apollo/client";
import { MenuItem, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { Glossary } from "src/components/Text/Glossary";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useAddToWaitListBulk } from "src/hooks/useWaitlistActions";
import * as RD from "src/types/remoteData";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import type { FormSchool, FormSchoolStatus } from "../types";
import { canAddToWaitlists } from "../validations/addToWaitlists";

interface Props {
  selectedFormSchoolStatuses: RD.RemoteData<unknown, FormSchoolStatus[]>;
  selectedFormSchools: FormSchool[];
  onRefetch?: () => void;
  menuLabel?: string;
}

const CONFIRMATION_BODY_TEXT =
  "You will add the selected forms to each school and grade waitlist. You can remove them anytime.";

export const AddToWaitlists = ({
  selectedFormSchoolStatuses,
  selectedFormSchools,
  onRefetch,
  menuLabel = "Add to waitlists",
}: Props) => {
  const addToWaitListBulk = useAddToWaitListBulk();

  const toast = useAvelaToast();
  const client = useApolloClient();

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "Cancel",
      },
      confirmButton: {
        label: "Yes, add to waitlists",
        colorScheme: "blue",
      },
      translate: true,
    });

  const handleOnClick = async (evt: React.MouseEvent) => {
    if (!selectedFormSchoolStatuses.hasData()) {
      throw new Error("Invalid selected rows");
    }

    const schoolForms = selectedFormSchoolStatuses.data;
    const count = schoolForms.length;

    setHeader(<Text>{`Add ${count} forms to waitlists?`}</Text>);
    setBody(
      <Text>
        <Glossary>{CONFIRMATION_BODY_TEXT}</Glossary>
      </Text>
    );

    if (await confirm()) {
      const loadingToastId = toast({
        title: `Working hard`,
        description: `Waitlisting ${count} forms...`,
        status: "loading",
        duration: null,
      });

      try {
        const addResult = await addToWaitListBulk(schoolForms);
        if (addResult.errors)
          throw new Error(`GraphQL errors: ${addResult.errors}`);

        client.refetchQueries({
          include: [FETCH_OFFER_WAITLIST_STATUS],
        });
        if (onRefetch) onRefetch();

        toast.close(loadingToastId);
        toast({
          title: "Hooray",
          description: `${count ?? "Some"} forms waitlisted`,
          status: "success",
        });
      } catch (err: any) {
        toast.close(loadingToastId);
        if (err.message === "data") {
          toast.error({
            title: "Invalid grades in forms",
            description:
              "One or more items in your selection have mismatching grades",
          });
        } else {
          toast.error({
            title: "Something went wrong!",
            description: "Check your network and try again.",
          });
        }
        console.error(err);
      }
    }
  };

  return (
    <>
      <RemoteDataView
        remoteData={selectedFormSchoolStatuses}
        loading={<Skeleton height={5} margin={3} />}
        error={() => <GenericError />}
        config={{ showDataWhileReloading: false }}
      >
        {(formSchools: FormSchoolStatus[]) => {
          const canAdd = canAddToWaitlists(formSchools, selectedFormSchools);

          return (
            <MenuItem
              textColor="gray.700"
              fontWeight="400"
              fontSize="sm"
              onClick={handleOnClick}
              isDisabled={!canAdd}
            >
              {menuLabel}
            </MenuItem>
          );
        }}
      </RemoteDataView>
      {confirmationDialog}
    </>
  );
};
