import { Box, Button, Flex } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { usePreferredLanguageBrowserOnly } from "src/hooks/usePreferredLanguage";
import { ReactComponent as ConnectSvg } from "src/images/connect.svg";
import { EmptyState } from "../EmptyState";
import { AuthLayout } from "../Layout/AuthLayout";

export const LoginError: FunctionComponent = () => {
  const preferredLanguageSetting = usePreferredLanguageBrowserOnly();
  return (
    <AuthLayout languageSelector={{ preferredLanguageSetting }}>
      <EmptyState
        minHeight="35rem"
        height="100%"
        justifyContent="center"
        description={
          <Flex direction="column" gap={4}>
            <Box>We couldn't log you in, that's on us. Try again later.</Box>
            <Flex justifyContent="center">
              <Button
                variant="link"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Log in
              </Button>
            </Flex>
          </Flex>
        }
        heading="Something went wrong"
        Svg={ConnectSvg}
      />
    </AuthLayout>
  );
};
