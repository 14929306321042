import { gql } from "@apollo/client";

export const GET_TAGS_BY_ENROLLMENT_PERIOD = gql`
  query GetTagsByEnrollmentPeriod(
    $enrollment_period_id: uuid!
    $tag_group_id: uuid!
  ) {
    enrollment_period_tag(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        tag_group_id: { _eq: $tag_group_id }
      }
      order_by: { name: asc }
    ) {
      id
      name
      description
      last_used_at
    }
  }
`;

export const GET_TAGS_IDS_BY_TAG_GROUP = gql`
  query GetTagsIdsByTagGroup($tag_group_id: uuid) {
    enrollment_period_tag(where: { tag_group_id: { _eq: $tag_group_id } }) {
      id
    }
  }
`;

export const GET_FORM_TAGS_AND_FORM_SCHOOL_TAGS = gql`
  query GetFormTagsAndFormSchoolTags(
    $form_school_rank_ids: [uuid!]!
    $form_ids: [uuid!]!
  ) {
    tag_by_form_school(
      where: { form_school_rank_id: { _in: $form_school_rank_ids } }
    ) {
      form_school_rank_id
      form_id
      tag_id
    }

    form_tag(where: { form_id: { _in: $form_ids } }) {
      form_id
      tag_id
    }
  }
`;

export const GET_FORM_TAGS = gql`
  query GetFormTags($form_ids: [uuid!]!) {
    form_tag(where: { form_id: { _in: $form_ids } }) {
      form_id
      tag_id
    }
  }
`;

export const GET_INTERNAL_DEFAULT_TAG_GROUP = gql`
  query GetInternalDefaultTagGroup($enrollment_period_id: uuid!) {
    tag_group(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        is_default: { _eq: true }
      }
    ) {
      id
      name
    }
  }
`;

export const GET_TAG_NAME_FROM_ID = gql`
  query GetTagNameFromId($tag_id: uuid!) {
    enrollment_period_tag_by_pk(id: $tag_id) {
      name
    }
  }
`;
