import { Button, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { useOrganization } from "src/hooks/useOrganization";
import { maybePluralize } from "src/services/format";
import { SUBMIT_FORM } from "../../graphql/mutations";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useWeglotToast } from "src/plugins/weglot";
import { useFormikContext } from "formik";
import { Step } from "src/components/Layout/FormStepLayout";

type SubmitFormButtonProps = {
  steps: Step[];
  isLoading?: boolean;
};

export const SubmitFormButton = ({
  steps,
  isLoading,
}: SubmitFormButtonProps) => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const toast = useWeglotToast();
  const { formId = "", step = "" } = useParams();
  const formik = useFormikContext();

  const [submitForm, submitStatus] = useRemoteDataMutation<
    GQL.SubmitForm,
    GQL.SubmitFormVariables
  >(SUBMIT_FORM);

  const handleSubmit = useCallback(async () => {
    try {
      await submitForm({
        variables: {
          form_id: formId,
        },
      });

      organization.do((org) => {
        navigate(Url.Parent.index(org));
      });

      toast({
        title: "Hooray!",
        description: "Form successfully submitted.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error submitting form",
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
        isClosable: true,
      });
    }
  }, [formId, navigate, organization, submitForm, toast]);

  const stepsWithMissingRequiredFields = useMemo(() => {
    return steps.flatMap(
      (stepObj, i): { missingRequiredQuestions: string[]; index: number }[] => {
        const index = i + 1;
        /*
              If is the current step, we need to verify if the user is changing answers
              and trying to submit the form wihout required fields.
              Case 1: User is in the current step and has not filled all required fields, it will keep the step in the error list and populate the error fields list.
              Case 2: User is in the current step and has filled all required fields, it will remove the step from the error list.
            */
        if (step === index.toString()) {
          // If formik is not defined, it means that the user is on the school ranks step, which doesn't apply the logic.
          if (!formik) {
            if (
              stepObj.initialMissingRequiredQuestions &&
              stepObj.initialMissingRequiredQuestions.length > 0
            ) {
              return [
                {
                  missingRequiredQuestions:
                    stepObj.initialMissingRequiredQuestions,
                  index,
                },
              ];
            }

            return [];
          }

          if (formik.isValid) {
            return [];
          }

          const missingRequiredQuestions = Object.keys(formik.errors);
          return [
            {
              missingRequiredQuestions,
              index,
            },
          ];
        }

        /*
              If it's not the current step, we need to verify if the user has not filled all required fields.
              Case 1: User has not filled all required fields, it will keep the step in the error list.
              Case 2: User has filled all required fields, it will remove the step from the error list.
            */
        return stepObj.initialMissingRequiredQuestions?.length
          ? [
              {
                missingRequiredQuestions:
                  stepObj.initialMissingRequiredQuestions,
                index,
              },
            ]
          : [];
      }
    );
  }, [formik, step, steps]);

  const missingRequiredFieldsCount = useMemo(() => {
    return stepsWithMissingRequiredFields.flatMap(
      (step) => step.missingRequiredQuestions
    ).length;
  }, [stepsWithMissingRequiredFields]);

  const handleNavigateToNextRequiredField = useCallback(() => {
    if (stepsWithMissingRequiredFields[0]) {
      organization.do((org) =>
        navigate(
          Url.Parent.Form.edit(
            org,
            formId,
            stepsWithMissingRequiredFields[0]?.index
          ),
          { state: { runValidation: true } }
        )
      );
    }
  }, [formId, navigate, organization, stepsWithMissingRequiredFields]);

  const { state } = useLocation();

  useEffect(() => {
    if (state?.runValidation && formik) {
      formik.validateForm();
      formik.setTouched(
        Object.fromEntries(
          Object.keys(formik.values ?? {}).map((key) => [key, true])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    confirm,
    confirmationDialog: nextRequiredQuestionDialog,
    setBody,
  } = useConfirmationDialog({
    header: "Missing required answers",
    body: <></>,
    cancelButton: {
      label: "Cancel",
      hidden: true,
    },
    confirmButton: {
      label: "Go to next required field",
    },
    translate: true,
  });

  const handleSubmitClick = useCallback(async () => {
    if (missingRequiredFieldsCount > 0) {
      setBody(
        <Text>
          There are{" "}
          <Text
            as={"span"}
            fontWeight={700}
          >{`${missingRequiredFieldsCount} more required ${maybePluralize(
            missingRequiredFieldsCount,
            "field",
            "fields"
          )}`}</Text>{" "}
          that need to be filled in before this form can be submitted.
        </Text>
      );
      if (!(await confirm())) {
        return;
      }

      handleNavigateToNextRequiredField();
    } else {
      handleSubmit();
    }
  }, [
    confirm,
    handleNavigateToNextRequiredField,
    missingRequiredFieldsCount,
    setBody,
    handleSubmit,
  ]);

  return (
    <>
      <Button
        onClick={handleSubmitClick}
        isLoading={submitStatus.remoteData.isLoading()}
        isDisabled={isLoading}
      >
        Submit
      </Button>
      {nextRequiredQuestionDialog}
    </>
  );
};
