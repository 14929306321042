import { FunctionComponent } from "react";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { AddressForm } from "src/scenes/orgAdmin/components/Forms/AddressForm";
import { HasuraRole } from "src/types/hasuraRole";
import { FormikAddressBook, FormikAddressBookProps } from "../Address/Book";
import { BaseInputProps } from "./Question";

export type DynamicFormAddressBookProps = BaseInputProps &
  Pick<FormikAddressBookProps, "disableAddressBook">;

export const DynamicFormAddressBook: FunctionComponent<
  DynamicFormAddressBookProps
> = (props) => {
  const { id, isRequired, disableAddressBook = false, isDisabled } = props;
  const isAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.DISTRICT_ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.SCHOOL_ADMIN,
  ]);

  if (isAdmin) {
    return (
      <AddressForm
        fieldPrefix={id}
        isRequired={isRequired}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <FormikAddressBook
      fieldName={id}
      addressIsRequired={Boolean(isRequired)}
      showStandardLabel={false}
      disableAddressBook={disableAddressBook}
    />
  );
};
