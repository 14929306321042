import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { toBaseQuestion } from "./question";
import _ from "lodash";
import { sortByOrder } from "./sorter";
import { toOptionWithoutBranching } from "./option";

export function formatQueryResponseAsCustomQuestionProps(
  value: Omit<GQL.QuestionFragment, "grades_question">
): AF.CustomQuestion<AF.WithId> {
  if (
    value.type !== GQL.question_type_enum.CustomQuestion ||
    !value.custom_question
  ) {
    throw new Error(
      `Invalid question type, unable to convert ${value.type} into a CustomQuestion.`
    );
  }

  return {
    ...formatQuestionResponseAsProps(value),
    type: AF.CustomQuestionType,
    customQuestionTypeId: value.custom_question.custom_question_type.id,
    nestedQuestions: value.custom_question.custom_question_relationships
      .map((relationship) => {
        const customQuestionTypeFieldId =
          relationship.custom_question_type_field_id;
        const questionProps = formatNestedQuestionAsProps(
          relationship.cloned_question
        );
        // questionProps can be null, so append fieldId when it is not null
        return questionProps
          ? { ...questionProps, customQuestionTypeFieldId }
          : null;
      })
      .filter(isNotNull),
  };
}

export function formatNestedQuestionAsProps(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.ClonedQuestion<AF.WithId> | null {
  switch (value.type) {
    case GQL.question_type_enum.FreeText:
      return toFreeText(value);
    case GQL.question_type_enum.SingleSelect:
      return toSingleSelect(value);
    case GQL.question_type_enum.Email:
      return toEmail(value);
    case GQL.question_type_enum.PhoneNumber:
      return toPhoneNumber(value);
    case GQL.question_type_enum.Address:
    case GQL.question_type_enum.CustomQuestion:
    case GQL.question_type_enum.FileUpload:
    case GQL.question_type_enum.Grades:
    case GQL.question_type_enum.MultiSelect:
      throw new Error(`${value.type} is not supported as nested question`);
    default:
      const _exhaustiveCheck: never = value.type;
      return _exhaustiveCheck;
  }
}

export function toFreeText(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FreeText<AF.WithId> {
  return {
    ...formatQuestionResponseAsProps(value),
    type: AF.FreeTextType,
  };
}

export function toEmail(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Email<AF.WithId> {
  return {
    ...formatQuestionResponseAsProps(value),
    type: AF.EmailType,
  };
}

export function toPhoneNumber(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.PhoneNumber<AF.WithId> {
  return {
    ...formatQuestionResponseAsProps(value),
    type: AF.PhoneNumberType,
  };
}

function toSingleSelect(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.SingleSelect<AF.WithId> {
  validateSingleSelect(value);
  return {
    ...formatQuestionResponseAsProps(value),
    type: AF.SingleSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toOptionWithoutBranching),
  };
}

function validateSingleSelect(
  value: GQL.FormQuestionWithoutBranchingFragment
): void {
  if (value.type !== GQL.question_type_enum.SingleSelect) {
    throw new Error(
      `Invalid question type, expecting "SingleSelect", got a ${value.type}`
    );
  }
  if ((value.form_question?.form_question_options ?? []).length === 0) {
    throw new Error("Missing options for SingleSelect question");
  }
}

/**
 * TODO, consolidate and test the following:
 * 1  ./preRankingSection/** 's toBaseGeneralQuestion and toBaseQuestion
 * 2. ./generalSection/** 's toBaseFormQuestion
 *
 * They are largely the same, with the exception of "specificToSchools".
 * This feels too risky of a refactoring so delay it to another time.
 */
function formatQuestionResponseAsProps(
  question: GQL.FormQuestionWithoutBranchingFragment
): Omit<AF.FormQuestion<AF.WithId>, "type"> {
  return {
    ...toBaseQuestion(question),
    category: AF.GeneralCategoryType,
  };
}
