import { MenuItem, Skeleton, Text } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { asyncForEach } from "src/services/asyncHelpers";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import {
  DECLINE_BULK_FORM_SCHOOL_OFFER,
  DECLINE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE,
} from "../graphql/mutations";
import { FETCH_OFFER_WAITLIST_STATUS } from "../graphql/queries";
import type { FormSchool, FormSchoolStatus } from "../types";
import { canDeclineOffers } from "../validations/declineOffers";

interface Props {
  selectedFormSchoolStatuses: RD.RemoteData<unknown, FormSchoolStatus[]>;
  selectedFormSchools: FormSchool[];
  onRefetch?: () => void;
  menuLabel?: string;
}

const CONFIRMATION_BODY_TEXT =
  "Declining these offers will prevent or undo the families’ acceptance.";

export const DeclineOffer = ({
  selectedFormSchoolStatuses,
  selectedFormSchools,
  onRefetch,
  menuLabel = "Decline offers",
}: Props) => {
  const [declineBulkFormSchoolOffer] = useRemoteDataMutation<
    GQL.DeclineBulkFormSchoolOffer,
    GQL.DeclineBulkFormSchoolOfferVariables
  >(DECLINE_BULK_FORM_SCHOOL_OFFER);

  const toast = useAvelaToast();

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "Cancel",
      },
      confirmButton: {
        label: "Yes, decline offers",
        colorScheme: "blue",
      },
      translate: true,
    });

  const handleOnClick = async (evt: React.MouseEvent) => {
    if (!selectedFormSchoolStatuses.hasData()) {
      throw new Error("Invalid selected rows");
    }

    const schoolForms = selectedFormSchoolStatuses.data;
    const count = schoolForms.length;

    setHeader(<Text>{`Decline ${count} offers?`}</Text>);
    setBody(<Text>{CONFIRMATION_BODY_TEXT}</Text>);

    if (await confirm()) {
      const loadingToastId = toast({
        title: `Working hard`,
        description: `Declining ${count} offers...`,
        status: "loading",
        duration: null,
      });

      try {
        const offers: GQL.offer_bool_exp[] = schoolForms.map((result) => {
          if (!result.offers?.[0]?.id) {
            throw new Error("data");
          }

          return {
            id: { _eq: result.offers?.[0].id },
          };
        });

        await asyncForEach(
          _.chunk(offers, DECLINE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE),
          (offerChunk) =>
            declineBulkFormSchoolOffer({
              variables: {
                declinedCondition: { _or: offerChunk },
              },
              refetchQueries: [FETCH_OFFER_WAITLIST_STATUS],
            })
        );

        if (onRefetch) onRefetch();

        toast.close(loadingToastId);
        toast({
          description: `${offers.length} offers declined`,
          status: "info",
        });
      } catch (err: any) {
        toast.close(loadingToastId);
        if (err.message === "data") {
          toast.error({
            title: "Invalid grades in forms",
            description:
              "One or more items in your selection have mismatching grades",
          });
        } else {
          toast.error({
            title: "Something went wrong!",
            description: "Check your network and try again.",
          });
        }
        console.error(err);
      }
    }
  };

  return (
    <WithUserPermissions permissions={["form:update"]}>
      <RemoteDataView
        remoteData={selectedFormSchoolStatuses}
        loading={<Skeleton height={5} margin={3} />}
        error={() => <GenericError />}
        config={{ showDataWhileReloading: false }}
      >
        {(formSchools) => {
          const canDecline = canDeclineOffers(formSchools, selectedFormSchools);

          return (
            <MenuItem
              textColor="gray.700"
              fontWeight="400"
              fontSize="sm"
              onClick={handleOnClick}
              isDisabled={!canDecline}
            >
              {menuLabel}
            </MenuItem>
          );
        }}
      </RemoteDataView>
      {confirmationDialog}
    </WithUserPermissions>
  );
};
