import { gql } from "@apollo/client";
import {
  SCHOOL_GRADES_FRAGMENT,
  SIMPLE_SCHOOL_FRAGMENT,
} from "src/operations/fragments/school";

export const GET_SCHOOLS_FOR_FORM = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}
  ${SCHOOL_GRADES_FRAGMENT}

  query GetSchoolsForFormTemplate($form_id: uuid!) {
    school_by_form(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          school: { status: { _eq: "Active" } }
        }
      }
      order_by: { school: { name: asc } }
    ) {
      school {
        ...SimpleSchoolFragment
        ...SchoolGradesFragment
      }
    }
  }
`;
