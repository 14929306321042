import React, { useState, useRef, useEffect, useCallback } from "react";
import { Textarea, useFormControlContext, Box } from "@chakra-ui/react";
import { useField } from "formik";
import { BaseInputProps } from "./Question";

export const FreeTextInput: React.FC<BaseInputProps> = ({
  id,
  isDisabled = false,
}) => {
  const [field, , helpers] = useField(id);
  const [isScrollable, setIsScrollable] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { isInvalid } = useFormControlContext();

  const [lineHeight, setLineHeight] = useState(24); // Default value, will be updated
  const MAX_LINES = 8;

  useEffect(() => {
    if (textareaRef.current) {
      const style = window.getComputedStyle(textareaRef.current);
      const calculatedLineHeight = parseInt(style.lineHeight, 10);
      setLineHeight(calculatedLineHeight || 24); // Use 24 as fallback if calculation fails
    }
  }, []); // Run once on mount

  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const style = window.getComputedStyle(textarea);
      const paddingTop = parseFloat(style.paddingTop);
      const paddingBottom = parseFloat(style.paddingBottom);
      const totalPadding = paddingTop + paddingBottom;

      textarea.style.height = "auto";

      const contentHeight = textarea.scrollHeight - totalPadding;

      const newHeight = Math.min(
        Math.max(contentHeight, lineHeight),
        MAX_LINES * lineHeight
      );

      textarea.style.height = `${newHeight + totalPadding}px`;

      setIsScrollable(contentHeight > MAX_LINES * lineHeight);

      if (contentHeight > newHeight) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  }, [lineHeight, MAX_LINES]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [field.value, lineHeight, adjustTextareaHeight]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    helpers.setTouched(true);
    field.onChange(event);
  };

  return (
    <Box
      className={isScrollable ? "scrollable" : ""}
      sx={{
        "& .growing-textarea": {
          lineHeight: `${lineHeight}px`,
          minHeight: `${lineHeight}px`,
          maxHeight: `${MAX_LINES * lineHeight}px`,
          resize: "none",
          paddingTop: "calc(0.5rem - 1px) !important",
          paddingBottom: "calc(0.5rem + 1px) !important",
        },
        "&.scrollable .growing-textarea": {
          overflowY: "auto",
        },
      }}
    >
      <Textarea
        {...field}
        id={id}
        name={id}
        ref={textareaRef}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        onChange={handleChange}
        className="growing-textarea"
        overflowY={isScrollable ? "auto" : "hidden"}
        rows={1}
      />
    </Box>
  );
};
