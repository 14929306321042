import { Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  text: string;
  keyword: string;
};

export const HighlightedText: React.FC<Props> = ({ text, keyword }) => {
  const regex = new RegExp(`(${keyword})`, "gi");
  const parts = text.split(regex);
  const highlighted = parts.map((part, i) => {
    if (part.toLowerCase() === keyword.toLowerCase()) {
      return (
        <Text as="b" key={i}>
          {part}
        </Text>
      );
    } else {
      return <React.Fragment key={i}>{part}</React.Fragment>;
    }
  });
  return <>{highlighted}</>;
};
