import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { GET_GRADES_CONFIG_BY_ORGANIZATION } from "../../enrollmentPeriods/scenes/FormTemplates/graphql/queries";
import {
  GradesQuestionFilter,
  formatDynamicQuestionFilters,
  formatGradesQuestionFilters,
} from "../graphql/utils";
import { FormFiltersDynamicQuestions } from "./FormFiltersDynamicQuestions";
import { FormFiltersGradesQuestion } from "./FormFiltersGradesQuestion";

interface FormFiltersAdvancedToolsProps {
  isLoadingMoreFilters?: boolean;
  onRenderingMoreFilters?: () => void;
  formTemplateRemoteData: RemoteData<Error, GQL.FormTemplateFragment>;
}

const defaultDynamicQuestionFiltersLimit = 10;

export const FormFiltersAdvancedTools: React.FC<
  FormFiltersAdvancedToolsProps
> = ({
  formTemplateRemoteData,
  isLoadingMoreFilters,
  onRenderingMoreFilters,
}) => {
  const organization = useOrganization();
  const [advancedFiltersLimit, setAdvancedFiltersLimit] = useState<number>(0);
  const [gradesQuestionFilters, setGradesQuestionFilters] =
    useState<GradesQuestionFilter>();

  useRemoteDataQuery<
    GQL.GetGradesConfigByOrganization,
    GQL.GetGradesConfigByOrganizationVariables
  >(GET_GRADES_CONFIG_BY_ORGANIZATION, {
    skip: !formTemplateRemoteData.hasData() || advancedFiltersLimit === 0,
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
    },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setGradesQuestionFilters(formatGradesQuestionFilters(data));
    },
  });

  const allDynamicQuestionFilters = useMemo(
    () =>
      formTemplateRemoteData.map(formatDynamicQuestionFilters).withDefault([]),
    [formTemplateRemoteData]
  );
  const renderedDynamicQuestionFilters = useMemo(
    () => allDynamicQuestionFilters.slice(0, advancedFiltersLimit),
    [allDynamicQuestionFilters, advancedFiltersLimit]
  );
  const hasMoreFiltersToRender =
    allDynamicQuestionFilters.length > advancedFiltersLimit;
  const isRenderingMoreFilters = isLoadingMoreFilters && hasMoreFiltersToRender;

  useEffect(() => {
    if (isRenderingMoreFilters) {
      setAdvancedFiltersLimit(
        advancedFiltersLimit + defaultDynamicQuestionFiltersLimit
      );
      onRenderingMoreFilters && onRenderingMoreFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRenderingMoreFilters]);

  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        {({ isExpanded }) => {
          if (isExpanded && !advancedFiltersLimit) {
            setAdvancedFiltersLimit(defaultDynamicQuestionFiltersLimit);
          }

          if (!isExpanded && advancedFiltersLimit) {
            setAdvancedFiltersLimit(0);
          }
          return (
            <>
              <AccordionButton paddingX="0">
                <Text variant="filterLabel" flex="1" textAlign="left">
                  Advanced Tools
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="0" mt={4}>
                <VStack align="inherit">
                  <FormFiltersGradesQuestion
                    gradesQuestionFilters={gradesQuestionFilters}
                  />
                  <FormFiltersDynamicQuestions
                    dynamicQuestionFilters={renderedDynamicQuestionFilters}
                  />
                </VStack>
                {hasMoreFiltersToRender && (
                  <Flex direction="column" gap="4" alignItems="center" mt={2}>
                    <Skeleton height="2rem" width="100%" borderRadius="md" />
                    <Skeleton height="2rem" width="100%" borderRadius="md" />
                    <Skeleton height="2rem" width="100%" borderRadius="md" />
                  </Flex>
                )}
              </AccordionPanel>
            </>
          );
        }}
      </AccordionItem>
    </Accordion>
  );
};
