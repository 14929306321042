import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import {
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_OFFSET,
} from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ParentForm } from "../components/Forms/ParentForm";
import { CREATE_PARENT } from "./graphql/mutations";
import { GET_PARENTS } from "./graphql/queries";
import {
  INITIAL_PARENT_FORM,
  ParentProfileFormType,
  ParentProfileValidator,
} from "./schemas";

export const NewParent = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const toast = useAvelaToast();
  const organization = useOrganization();
  const [createParent] = useRemoteDataMutation<
    GQL.CreateParent,
    GQL.CreateParentVariables
  >(CREATE_PARENT);
  const [getParents] = useLazyRemoteDataQuery<
    GQL.GetParents,
    GQL.GetParentsVariables
  >(GET_PARENTS, { fetchPolicy: "network-only" });

  const handleSubmit = React.useCallback(
    async (values: ParentProfileFormType) => {
      if (!organization.hasData()) {
        toast.error({ title: "Error creating parent" });
        return;
      }

      setSubmitting(true);
      try {
        const parent = ParentProfileValidator.parse(values);
        await createParent({
          variables: {
            parent: {
              ...parent,
              organization_id: organization.data.id,
              person_type: GQL.person_type_enum.guardian,
            },
          },
        });
        await getParents({
          variables: {
            organizationId: organization.data.id,
            limit: PAGINATION_DEFAULT_LIMIT,
            offset: PAGINATION_DEFAULT_OFFSET,
            search: {},
          },
        });
        navigate(Url.OrgAdmin.Parents.index(organization.data));
      } catch (error) {
        console.error(error);
        toast.error({ title: "Error creating parent" });
      } finally {
        setSubmitting(false);
      }
    },
    [createParent, getParents, navigate, organization, toast]
  );

  return (
    <>
      <Breadcrumb
        items={breadcrumb.parent.getBreadcrumbsForNew(organization)}
        mb={8}
      />
      <Formik<ParentProfileFormType>
        initialValues={INITIAL_PARENT_FORM}
        onSubmit={handleSubmit}
        validate={validateWithZod(ParentProfileValidator)}
      >
        <Flex as={Form} direction="column" gap={6}>
          <Heading as="h1" fontSize="2xl" fontWeight="600">
            Create parent
          </Heading>
          <ParentForm />
          <Flex align="center" gap={6}>
            <Spacer />
            <Button
              as={NavLink}
              to={organization
                .map((org) => Url.OrgAdmin.Parents.index(org))
                .withDefault("#")}
              isLoading={organization.isLoading()}
              variant="link"
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={submitting}>
              Create
            </Button>
          </Flex>
        </Flex>
      </Formik>
    </>
  );
};
