import _ from "lodash";
import * as AFQ from "src/services/formTemplate/question";
import { removeFields, toJSON } from "src/services/object";
import * as AF from "src/types/formTemplate";
import { IsDraft } from "./common";

export type IsNew = {
  readonly isNew: boolean;
};

type Draft<ORIGINAL> = ORIGINAL & IsDraft & IsNew;

type DraftQuestion = {
  readonly newFormVerification?: AF.FormVerification<AF.WithoutId>;
} & IsNew;

export type Option = Draft<AF.Option<AF.WithId>> & {
  additionalQuestions?: Question[];
};

export type SingleSelect = Draft<
  Omit<AF.SingleSelect<AF.WithId>, "options"> & { options: Option[] }
>;

type MultiSelect = Draft<
  Omit<AF.MultiSelect<AF.WithId>, "options"> & {
    options: Option[];
  }
>;

export type Question = (
  | SingleSelect
  | MultiSelect
  | Draft<AF.Grades<AF.WithId>>
  | Draft<AF.FreeText<AF.WithId>>
  | Draft<AF.FileUpload<AF.WithId>>
  | Draft<AF.Email<AF.WithId>>
  | Draft<AF.PhoneNumber<AF.WithId>>
  | Draft<AF.Address<AF.WithId>>
  | Draft<AF.CustomQuestion<AF.WithId>>
) &
  DraftQuestion;

export type NewQuestion = Question & { readonly isNew: true };

export type Disclaimer = Draft<AF.DisclaimerSection<AF.WithId>>;
export type NewDisclaimer = Disclaimer & { readonly isNew: true };

export type SchoolRanking = Draft<AF.SchoolRankingSection<AF.WithId>>;
export type NewSchoolRanking = SchoolRanking & { readonly isNew: true };

export type Section = Draft<AF.Section<AF.WithId>>;
export type NewSection = Section & { readonly isNew: true };

export function fromOriginalOption(original: AF.Option<AF.WithId>): Option {
  return {
    ...original,
    isDraft: true,
    isNew: isNew(original),
    additionalQuestions: original.additionalQuestions?.map((q) =>
      fromOriginalQuestion(q)
    )
  };
}

export function fromOriginalQuestion(
  original: AF.Question<AF.WithId>
): Question {
  switch (original.type) {
    case AF.SingleSelectType:
    case AF.MultiSelectType:
      return {
        ...original,
        isDraft: true,
        isNew: isNew(original),
        options: original.options.map(fromOriginalOption)
      };

    default:
      return { ...original, isDraft: true, isNew: isNew(original) };
  }
}

export function fromOriginalSection(original: AF.Section<AF.WithId>): Section {
  return fromOriginal(original);
}

export function fromOriginalDisclaimer(
  original: AF.DisclaimerSection<AF.WithId>
): Disclaimer {
  return fromOriginal(original);
}

function fromOriginal<ORIGINAL extends {}>(
  original: ORIGINAL
): Draft<ORIGINAL> {
  return { ...original, isDraft: true, isNew: isNew(original) };
}

export function toOriginal<ORIGINAL>(draft: Draft<ORIGINAL>): ORIGINAL {
  return removeFields(draft, "isDraft", "isNew") as ORIGINAL;
}

export function hasChanges<ORIGINAL extends {}>(
  original: ORIGINAL | undefined,
  draft: Draft<ORIGINAL> | undefined
): boolean {
  if (original === undefined) {
    return true;
  }

  if (draft === undefined) {
    return false;
  }

  return !_.isEqual(toJSON(original), toJSON(toOriginal(draft)));
}

export function hasSectionChanges(
  original: AF.Section<AF.WithId>,
  draft: Section | undefined
): boolean {
  if (original === undefined) {
    return true;
  }

  if (draft === undefined) {
    return false;
  }

  return !_.isEqual(
    _.pick(original, "title", "description", "key", "permissionLevel"),
    _.pick(draft, "title", "description", "key", "permissionLevel")
  );
}

export function toQuestionWithoutId(
  newQuestion: Question
): AF.Question<AF.WithoutId> {
  const { isDraft, isNew, ...question } = newQuestion;

  const questionWithoutId = AFQ.toQuestionWithoutId(question);
  if (AFQ.hasOptions(questionWithoutId) && AFQ.hasOptions(newQuestion)) {
    return {
      ...questionWithoutId,
      options: newQuestion.options.map(AFQ.toOptionWithoutId)
    };
  }

  if (
    AFQ.hasCustomQuestionType(questionWithoutId) &&
    AFQ.hasCustomQuestionType(newQuestion)
  ) {
    return {
      ...questionWithoutId,
      customQuestionTypeId: newQuestion.customQuestionTypeId
    };
  }

  return { ...questionWithoutId };
}

export function asNew<T>(from: T): T & { isNew: true } {
  return { ...from, isNew: true };
}

export function isNew<T>(draft: T): draft is T & { isNew: true } {
  return (draft as any)?.isNew === true;
}

export function getAdditionalQuestions(parentQuestion: Question): Question[] {
  if (!AFQ.hasOptions(parentQuestion)) {
    return [];
  }

  return parentQuestion.options.flatMap(
    (option) => option.additionalQuestions ?? []
  );
}
