import { Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  FormType,
  getBirthdateFilterFormValue,
} from "src/components/Form/QuestionForm/formik";
import { ConfigureQuestion } from "src/components/Form/QuestionForm/QuestionConfiguration/ConfigureQuestion";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { ConfigureDateOfBirth } from "../QuestionConfiguration/ConfigureDateOfBirth";
import {
  ConfigureQuestionMenu,
  ConfigureQuestionMenuTabs,
} from "../QuestionConfiguration/ConfigureQuestionMenu";
import { QuestionLink } from "./QuestionLink";
export type GradeQuestionFormProps = {
  questionId: uuid;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
};
export function GradeQuestionForm({
  questionId,
  gradesConfig,
}: GradeQuestionFormProps) {
  const [configureQuestionTab, setConfigureQuestionTab] =
    React.useState<ConfigureQuestionMenuTabs>(
      ConfigureQuestionMenuTabs.ConfigureQuestion
    );

  const formik = useFormikContext<FormType>();

  const handleConfigureMenuChange = (newTab: ConfigureQuestionMenuTabs) => {
    setConfigureQuestionTab(newTab);
  };

  const questionBirthdateFilter = getBirthdateFilterFormValue(
    questionId,
    formik.values
  );

  return (
    <>
      <Flex direction="row" gap="4">
        <ConfigureQuestionMenu
          activeTab={configureQuestionTab}
          onMenuChange={handleConfigureMenuChange}
          items={[
            { type: "Separator", label: "Limit available options" },
            {
              type: ConfigureQuestionMenuTabs.DateOfBirth,
              label: "Date of birth",
              status: questionBirthdateFilter.length > 0 ? "on" : "off",
            },
          ]}
        />
        {configureQuestionTab ===
          ConfigureQuestionMenuTabs.ConfigureQuestion && (
          <ConfigureQuestion
            questionId={questionId}
            questionType={AF.GradesType}
          />
        )}
        {configureQuestionTab === ConfigureQuestionMenuTabs.DateOfBirth && (
          <ConfigureDateOfBirth
            questionId={questionId}
            gradesConfig={gradesConfig}
          />
        )}
      </Flex>
      {configureQuestionTab === ConfigureQuestionMenuTabs.ConfigureQuestion && (
        <Flex direction="column" flexGrow="1" gap="3">
          <QuestionLink questionId={questionId} />
        </Flex>
      )}
    </>
  );
}
