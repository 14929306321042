import { gql } from "@apollo/client";

export const GET_FORM_TEMPLATE_HINT_FOR_PARENT = gql`
  query GetFormTemplateHintForParent($organizationId: uuid!) {
    form_template_hint(
      where: {
        form_template: {
          enrollment_period: {
            active: { _eq: true }
            organization_id: { _eq: $organizationId }
          }
        }
      }
    ) {
      form_template_id
      status_type
      form_status
      offer_status
      waitlist_status
      title
      body
      image_type
    }
  }
`;
