import { ApolloError } from "@apollo/client";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import * as GQL from "src/types/graphql";
import { notAsked, RemoteData } from "src/types/remoteData";
import { FormImportFlowAction } from "./state/actions";
import { FlowStatus } from "./state/constants";
import { FormImportFlowState, getInitialState, reducer } from "./state/reducer";
import { useFormImport } from "./useFormImport";
import {
  FormImportRowAggregates,
  useFormImportRowAggregates,
} from "./useFormImportRowAggregates";

type FormImportFlowContextType = {
  formImport: RemoteData<ApolloError, GQL.GetFormImport_form_import | null>;

  formImportRowAggregates: RemoteData<ApolloError, FormImportRowAggregates>;

  dispatch: Dispatch<FormImportFlowAction>;
  importStatus: FlowStatus;
  state: FormImportFlowState;
};

const FormImportFlowContext = createContext<FormImportFlowContextType>({
  formImport: notAsked(),
  formImportRowAggregates: notAsked(),
  dispatch() {},
  importStatus: FlowStatus.STOPPED,

  state: {
    formImportId: null,
    enrollmentPeriodId: null,
    formTemplateId: null,
    finalStatusReported: false,
    formTemplateName: "",
    importFileUploadStatus: FlowStatus.STOPPED,
  },
});

interface FormImportFlowProviderProps {
  children: ReactNode;
}

export function FormImportFlowProvider(props: FormImportFlowProviderProps) {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const toast = useAvelaToast();

  const { remoteData: formImport, status: formImportStatus } = useFormImport({
    formImportId: state.formImportId,
    dispatch,
    state,
  });

  const {
    remoteData: formImportRowAggregates,
    status: formImportRowAggregatesStatus,
  } = useFormImportRowAggregates({
    formImport: formImport.hasData() ? formImport.data : null,
    dispatch,
    state,
  });

  useEffect(() => {
    if (state.importFileUploadStatus === "failure") {
      toast({
        description:
          "Please try again later or report the problem to our support team.",
        id: "FormImportFileUploadError",
        isClosable: true,
        status: "error",
        title: "Error uploading form import file",
      });
    }
  }, [toast, state.importFileUploadStatus]);

  let importStatus: FlowStatus;
  if (formImportStatus !== FlowStatus.SUCCESS) {
    importStatus = formImportStatus;
  } else {
    importStatus = formImportRowAggregatesStatus;
  }

  return (
    <FormImportFlowContext.Provider
      value={{
        formImport,
        formImportRowAggregates,
        dispatch,
        importStatus,
        state,
      }}
    >
      {props.children}
    </FormImportFlowContext.Provider>
  );
}

export function useFormImportFlow(): FormImportFlowContextType {
  return useContext(FormImportFlowContext);
}
