import React from "react";
import { ConfigFormLayout } from "./ConfigFormLayout";
import { Input } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";

type Props = {};
export const AccountLookupConfigForm: React.FC<Props> = () => {
  return (
    <ConfigFormLayout
      type="AccountLookup"
      title="Account Lookup"
      label="Enable account lookup"
      description=""
    >
      {() => (
        <InputControl
          name={`organizationConfigs.AccountLookup.supportUrl`}
          label="Support URL"
        >
          <Input size="sm" placeholder="Support URL" />
        </InputControl>
      )}
    </ConfigFormLayout>
  );
};
