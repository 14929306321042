import { Flex, Heading, Tag, Text } from "@chakra-ui/react";
import {
  RiArrowRightLine,
  RiChat3Line,
  RiMailLine,
  RiErrorWarningLine,
} from "react-icons/ri";
import { useOrganization } from "src/hooks/useOrganization";
import {
  formatIsoDateToMessageTimestamp,
  formatUSPhoneNumber,
} from "src/services/format";
import * as GQL from "src/types/graphql";
import { FormattedFormMessage, getFormattedTransition } from "./helpers";
import { useMemo } from "react";

interface Props {
  formMessage: FormattedFormMessage;
}

export const FormMessageCard: React.FC<Props> = ({ formMessage }) => {
  const organization = useOrganization();

  const messageStatus = useMemo(() => {
    switch (formMessage.status) {
      case GQL.message_status_enum.Failed:
      case GQL.message_status_enum.NotSent:
        return (
          <Flex gap={1} alignItems="center">
            <Text color="gray.300">
              <RiErrorWarningLine size="20px" />
            </Text>
            <Text
              fontSize="sm"
              fontWeight="400"
              color="gray.600"
              textAlign="end"
            >
              Not sent
            </Text>
          </Flex>
        );
      case GQL.message_status_enum.Pending:
        return (
          <Text
            fontSize="sm"
            fontWeight="400"
            color="gray.600"
            textAlign="end"
            fontStyle="italic"
          >
            Pending...
          </Text>
        );
      case GQL.message_status_enum.Sent:
        return (
          <Text fontSize="sm" fontWeight="400" color="gray.600" textAlign="end">
            Sent{" "}
            {formMessage.sentAt &&
              formatIsoDateToMessageTimestamp(formMessage.sentAt, organization)}
          </Text>
        );
      default:
        return "Unknown";
    }
  }, [formMessage, organization]);

  const messageContent = useMemo(() => {
    if (formMessage.messageType === GQL.message_type_enum.email) {
      return (
        <Flex direction="column" gap={4}>
          {formMessage.subject && (
            <Flex direction="column">
              <Text fontSize="sm" fontWeight="400" color="gray.600">
                Subject
              </Text>

              <Text>{formMessage.subject}</Text>
            </Flex>
          )}

          {formMessage.body && (
            <Flex direction="column">
              <Text fontSize="sm" fontWeight="400" color="gray.600">
                Body
              </Text>

              <Text whiteSpace="pre-wrap">{formMessage.body}</Text>
            </Flex>
          )}
        </Flex>
      );
    } else {
      return formMessage.body && <Text>{formMessage.body}</Text>;
    }
  }, [formMessage]);

  const messageRecipient = useMemo(() => {
    if (formMessage.messageType === GQL.message_type_enum.email) {
      return (
        <>
          <Text color="gray.300">
            <RiMailLine />
          </Text>

          {formMessage.recipient ? (
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              Email to {formMessage.recipient}
            </Text>
          ) : (
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              Email
            </Text>
          )}
        </>
      );
    } else {
      return (
        <>
          <Text color="gray.300">
            <RiChat3Line />
          </Text>

          {formMessage.recipient ? (
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              SMS to {formatUSPhoneNumber(formMessage.recipient)}
            </Text>
          ) : (
            <Text fontSize="sm" fontWeight="400" color="gray.600">
              SMS
            </Text>
          )}
        </>
      );
    }
  }, [formMessage]);

  return (
    <Flex
      direction="column"
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="blackAlpha.80"
      padding="6"
      gap={5}
    >
      <Flex gap={2} alignItems="center">
        <Heading as="h3" fontWeight={700} fontSize="md">
          {formMessage.sender}
        </Heading>

        {formMessage.transition && (
          <Tag variant="solid" background="gray.500" fontSize="xs" gap={1}>
            Automatic: {getFormattedTransition(formMessage.transition)?.from}
            {getFormattedTransition(formMessage.transition)?.to && (
              <>
                <RiArrowRightLine />
                {getFormattedTransition(formMessage.transition)?.to}
              </>
            )}
          </Tag>
        )}
      </Flex>

      {messageContent}

      <Flex justifyContent="space-between" w="100%">
        <Flex gap={1} alignItems="center">
          {messageRecipient}
        </Flex>

        {messageStatus}
      </Flex>
    </Flex>
  );
};
