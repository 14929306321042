import * as _ from "lodash";
import { useCallback } from "react";

/*
A simple hook to perform pagination and sorting on "LOCAL" data
 */
export function useLocalData<TData>(
  data: TData[]
): [(sortPagination: SortPagination) => TData[], (sort: Sort) => TData[]] {
  const getSortedUnpagedData = useCallback(
    ({ sortType, sortKey }: Sort) => {
      const result = sortType === UNSORTED ? data : _.sortBy(data, [sortKey]);
      if (sortType === ASCENDING) result.reverse();
      return result;
    },
    [data]
  );

  const getSortedAndPaginatedData = useCallback(
    ({ limit, offset, sortType, sortKey }: SortPagination): TData[] => {
      const result = getSortedUnpagedData({ sortType, sortKey });
      return result.slice(offset, offset + limit);
    },
    [getSortedUnpagedData]
  );

  return [getSortedAndPaginatedData, getSortedUnpagedData];
}

export const UNSORTED = "unsorted";
export const ASCENDING = "asc";
export const DESCENDING = "desc";

export type SortType = typeof UNSORTED | typeof ASCENDING | typeof DESCENDING;

export interface Sort {
  sortKey: string;
  sortType: SortType;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export type SortPagination = Sort & Pagination;
