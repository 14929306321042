import { Card, Flex, Heading, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface FormSectionProps {
  title?: string;
  description?: string;
  children: ReactElement;
}

export const FormSection: React.FC<FormSectionProps> = ({
  children,
  description,
  title,
}) => {
  return (
    <Card padding={4}>
      <Flex direction="column" gap={3} role="main">
        {title && (
          <Heading as="h2" fontSize="md" color="primary.500" fontWeight="600">
            {title}
          </Heading>
        )}
        {description && (
          <Text fontSize="sm" color="gray.600" whiteSpace="pre-wrap">
            {description}
          </Text>
        )}
        {children}
      </Flex>
    </Card>
  );
};
