import { Button, Tag } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { Table } from "src/components/Table/Table";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { SELECT_MATCH_TEMPLATE } from "./graphql/mutations";

interface Props {
  matchTemplates: GQL.FetchMatchTemplates_match_template[];
  onRefetch: () => Promise<any>;
}

export const MatchTemplateList: React.FC<Props> = ({
  matchTemplates,
  onRefetch,
}) => {
  const organization = useOrganization();

  const [selectMatchTemplate] = useRemoteDataMutation<
    GQL.SelectMatchTemplate,
    GQL.SelectMatchTemplateVariables
  >(SELECT_MATCH_TEMPLATE);

  const handleSelectTemplate = useCallback(
    async (templateId: uuid) => {
      if (!organization.hasData()) return;
      await selectMatchTemplate({
        variables: {
          organization_id: organization.data.id,
          match_template_id: templateId,
        },
      });
      await onRefetch();
    },
    [organization, selectMatchTemplate, onRefetch]
  );

  const columns: ColumnDef<GQL.FetchMatchTemplates_match_template>[] = useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
      },
      {
        id: "status",
        cell: (props) =>
          props.row.original.match_template_organizations.length ? (
            <Tag colorScheme="gray" borderRadius="md">
              Used for lottery
            </Tag>
          ) : null,
      },
      {
        id: "actions",
        cell: (props) =>
          props.row.original.match_template_organizations.length ? null : (
            <Button onClick={() => handleSelectTemplate(props.row.original.id)}>
              Use for lottery
            </Button>
          ),
      },
    ],
    [handleSelectTemplate]
  );

  if (matchTemplates.length === 0) return <span>No match templates yet.</span>;

  return (
    <Table<GQL.FetchMatchTemplates_match_template>
      data={matchTemplates}
      columns={columns}
    />
  );
};
