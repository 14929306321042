import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  ThemingProps,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";
import * as Url from "src/services/url";

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  action?: {
    label: string;
    variant: ThemingProps<"Button">["variant"];
  };
};

export const ForceLogout: React.FC<Props> = ({
  children,
  title = "Your session has expired",
  description = "You will need to login again",
  action = {
    label: "Log in",
    variant: "solid",
  },
}) => {
  const location = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const loginRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  useEffect(() => onOpen());

  const handleLogin = () => {
    onClose();
    navigate(Url.logout(location.pathname));
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={loginRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay className={WEGLOT_APPLY_CLASS}>
          <AlertDialogContent paddingTop={4} paddingBottom={2}>
            <AlertDialogBody>
              <Text fontSize="1.125em" fontWeight="700">
                {title}
              </Text>
              <Text mt={4}>{description}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                type="button"
                ref={loginRef}
                colorScheme="blue"
                onClick={handleLogin}
                variant={action.variant}
              >
                {action.label}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {children}
    </>
  );
};
