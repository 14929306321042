import { Checkbox, CheckboxProps } from "@chakra-ui/react";

/**
 * Returns a checkbox whose target area will completely fill its containing
 * table cell.  Note: the containing cell must be have `position: relative`.
 */
export function CheckboxCell(props: CheckboxProps) {
  const { onChange, ...styleProps } = props;
  return (
    <>
      <Checkbox
        // Hidden checkbox to establish layout spacing.
        visibility="hidden"
      />
      <Checkbox
        variant="opaque"
        // Completely fill the closest positioned ancestor (i.e. table cell).
        position="absolute"
        inset={0}
        justifyContent="center"
        onChange={onChange}
        {...styleProps}
      />
    </>
  );
}
