import { CustomQuestionTypeFieldTypes } from "src/schemas/CustomQuestionType";
import {
  EmailType,
  FreeTextType,
  PhoneNumberType,
  SingleSelectType,
} from "src/types/formTemplate";
import {
  PersonAnswerBankFragment,
  PersonAnswerBankFragment_person_answers,
} from "src/types/graphql";
import { CloneIdsGroupedBySourceIds } from "../context/helpers";
import {
  AnswerBankRecord,
  AnswerBankRecordKind,
} from "../list/schemas/AnswerBank";
import { AnswerBankRecordField } from "../list/schemas/AnswerBankField";

export function formatQueriedAnswerBankRecords(
  answerBankRecords: PersonAnswerBankFragment[],
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds
): AnswerBankRecord[] {
  return answerBankRecords.map((record) => {
    const { id, person_answers } = record;
    return {
      answerBankId: id,
      kind: AnswerBankRecordKind,
      fields: person_answers.map((personAnswer) => {
        return formatQueriedAnswerBankPersonAnswer(
          personAnswer,
          sourceIdCloneIdMapping
        );
      }),
    };
  });
}

function formatQueriedAnswerBankPersonAnswer(
  personAnswer: PersonAnswerBankFragment_person_answers,
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds
): AnswerBankRecordField {
  const {
    custom_question_type_field: {
      question: { id, type, question },
    },
    answer,
    person_answer_options,
  } = personAnswer;

  const commonProperties = {
    questionLabel: question,
    customQuestionTypeFieldId: id,
    clonedQuestionId: getCloneId(sourceIdCloneIdMapping, id),
  };

  // use type assertion as the GQL for question type is based on the broader question_type_enum
  const fieldType = type as CustomQuestionTypeFieldTypes;

  switch (fieldType) {
    case FreeTextType:
    case EmailType:
    case PhoneNumberType:
      return {
        ...commonProperties,
        questionType: fieldType,
        answer: answer ?? "",
      };
    case SingleSelectType:
      const singleSelectField = {
        ...commonProperties,
        questionType: fieldType,
      };

      const formAnswerOption = person_answer_options[0]?.form_question_option;

      if (!formAnswerOption) {
        return singleSelectField;
      }

      return {
        ...singleSelectField,
        customQuestionTypeFieldSelectOptionId: formAnswerOption.id,
        customQuestionTypeFieldSelectOptionLabel: formAnswerOption.label,
        clonedQuestionSelectOptionId: getCloneId(
          sourceIdCloneIdMapping,
          id,
          formAnswerOption.id
        ),
        clonedQuestionSelectOptionLabel: formAnswerOption.label,
      };
    default: {
      // cannot do exhaustiveness because type is question_type_enum
      // and Typescript ignores the casting
      throw new Error(`Unexpected type: ${fieldType}.`);
    }
  }
}

function getCloneId(
  sourceIdCloneIdMapping: CloneIdsGroupedBySourceIds,
  sourceId: uuid,
  sourceOptionId?: uuid
): uuid {
  const clone = sourceIdCloneIdMapping[sourceId];

  if (!clone) {
    throw new Error(`Could not find clone for sourceId ${sourceId}.`);
  }

  if (!sourceOptionId) {
    return clone.clonedQuestionId;
  }

  const optionCloneId = clone.optionIdMapping?.[sourceOptionId];

  if (!optionCloneId) {
    throw new Error(
      `Could not find option clone for sourceId ${sourceId}, optionId ${sourceOptionId}`
    );
  }

  return optionCloneId;
}
