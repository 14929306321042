import { Grid, GridProps } from "@chakra-ui/react";
import React, { FocusEventHandler } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { NameFormType } from "src/schemas/Name";

type Props = {
  onFocus?: FocusEventHandler;
  firstName?: { label?: string };
  middleName?: { label?: string };
  lastName?: { label?: string };
  hide?: Array<keyof NameFormType>;
  isDisabled?: boolean;
} & GridProps;
export const NameForm: React.FC<Props> = ({
  onFocus = () => {},
  firstName,
  middleName,
  lastName,
  hide,
  isDisabled = false,
  ...props
}) => (
  <Grid
    gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
    gap={3}
    alignItems="flex-end"
    {...props}
  >
    {!hide?.includes("first_name") && (
      <InputWithValidationControl
        inputProps={{ placeholder: "E.g.: Alex" }}
        name="first_name"
        id="first_name"
        label={firstName?.label ?? "First name"}
        isRequired={true}
        labelProps={{ requiredIndicator: <></> }}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
    )}
    {!hide?.includes("middle_name") && (
      <InputWithValidationControl
        isRequired={false}
        inputProps={{ placeholder: "E.g.: Dan" }}
        name="middle_name"
        id="middle_name"
        label={`${middleName?.label ?? "Middle name"} (Optional)`}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
    )}
    {!hide?.includes("last_name") && (
      <InputWithValidationControl
        inputProps={{ placeholder: "E.g.: Doe" }}
        name="last_name"
        id="last_name"
        label={lastName?.label ?? "Last name"}
        isRequired={true}
        labelProps={{ requiredIndicator: <></> }}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
    )}
    {!hide?.includes("birth_date") && (
      <InputWithValidationControl
        inputProps={{ type: "date" }}
        id="birth_date"
        name="birth_date"
        label="Date of birth"
        isRequired={true}
        labelProps={{ requiredIndicator: <></> }}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
    )}
  </Grid>
);
