import * as z from "zod";

export const PermissionSchema = z.union([
  z.literal("form_template:create"),
  z.literal("form_template:delete"),
  z.literal("form_template:read"),
  z.literal("form_template:update"),
  z.literal("form:create"),
  z.literal("form:delete"),
  z.literal("form:read"),
  z.literal("form:update"),
  z.literal("form:import"),
  z.literal("automatic_message:create"),
  z.literal("automatic_message:delete"),
  z.literal("automatic_message:read"),
  z.literal("automatic_message:update"),
  z.literal("boundaries:create"),
  z.literal("boundaries:delete"),
  z.literal("boundaries:read"),
  z.literal("enrollment_period:create"),
  z.literal("enrollment_period:delete"),
  z.literal("enrollment_period:read"),
  z.literal("enrollment_period:update"),
  z.literal("grade:create"),
  z.literal("grade:delete"),
  z.literal("grade:read"),
  z.literal("grade:update"),
  z.literal("match:create"),
  z.literal("match:delete"),
  z.literal("match:read"),
  z.literal("match:update"),
  z.literal("message:create"),
  z.literal("message:read"),
  z.literal("organization:create"),
  z.literal("organization:delete"),
  z.literal("organization:read"),
  z.literal("organization:update"),
  z.literal("priority_template:create"),
  z.literal("priority_template:delete"),
  z.literal("priority_template:read"),
  z.literal("priority_template:update"),
  z.literal("program:create"),
  z.literal("program:delete"),
  z.literal("program:read"),
  z.literal("program:update"),
  z.literal("question_type:create"),
  z.literal("question_type:read"),
  z.literal("school:create"),
  z.literal("school:delete"),
  z.literal("school:read"),
  z.literal("school:update"),
  z.literal("school.grade:create"),
  z.literal("school.grade:delete"),
  z.literal("school.grade:read"),
  z.literal("school.grade:update"),
  z.literal("team:create"),
  z.literal("team:delete"),
  z.literal("team:read"),
  z.literal("team:update"),
  z.literal("user:create"),
  z.literal("user:delete"),
  z.literal("user:read"),
  z.literal("user:update"),
  z.literal("tag_group:read"),
  z.literal("tag_group:create"),
  z.literal("tag_group:update"),
  z.literal("tag_group:delete"),

  z.literal("data_services:create"),
  z.literal("data_services:delete"),
  z.literal("data_services:read"),
  z.literal("data_services:update"),

  // navigation permissions
  z.literal("navigation.admin.form_templates"),
  z.literal("navigation.admin.forms"),
  z.literal("navigation.admin.automated_messages"),
  z.literal("navigation.admin.boundaries"),
  z.literal("navigation.admin.capacities"),
  z.literal("navigation.admin.enrollment_periods"),
  z.literal("navigation.admin.glossaries"),
  z.literal("navigation.admin.grades"),
  z.literal("navigation.admin.messages"),
  z.literal("navigation.admin.question_types"),
  z.literal("navigation.admin.organizations"),
  z.literal("navigation.admin.priority_templates"),
  z.literal("navigation.admin.programs"),
  z.literal("navigation.admin.schools"),
  z.literal("navigation.admin.team"),
  z.literal("navigation.admin.users"),
  z.literal("navigation.admin.data_services"),

  // school access permissions
  z.literal("school:applying"),
  z.literal("school:attending"),
  z.literal("school:all"),
]);

export type Permission = z.infer<typeof PermissionSchema>;
