import { Link, Text, VStack } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import type { OrganizationError } from "src/hooks/useOrganization";
import { fullName } from "src/services/format";
import { getReferenceId } from "src/services/id";
import * as Url from "src/services/url";
import type { GlossaryFn } from "src/types/glossary";
import type * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { getForm } from "../helpers";
import { ColumnId } from "./constants";

type Row = {
  form: {
    id: uuid;
    person: {
      id: uuid;
      first_name: string | null;
      last_name: string | null;
      reference_id: string | null;
    };
  } | null;
};

type CellFn<T extends Row> = Exclude<ColumnDef<T>["cell"], undefined | string>;

type StudentColumnCellProps<T extends Row> = Parameters<CellFn<T>>[0] & {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  formTemplateId: uuid;
};

function StudentColumnCell<T extends Row>(props: StudentColumnCellProps<T>) {
  const { organization, formTemplateId } = props;

  const [searchParams] = useSearchParams();

  const { person } = getForm(props.row.original);
  const studentName = fullName(person) || "No name";
  const rowForm = props.row.original.form;

  let nameElement;

  if (rowForm) {
    const url = Url.OrgAdmin.Forms.view({
      organization,
      formTemplateId,
      id: rowForm.id,
      params: searchParams.toString(),
    });

    nameElement = (
      <Link
        as={RouterLink}
        color="primary.500"
        to={url}
        textDecoration="underline"
      >
        {studentName}
      </Link>
    );
  } else {
    nameElement = <Text>{studentName}</Text>;
  }

  return (
    <VStack align="start">
      {nameElement}
      <TextWithOverflowTooltip
        content={getReferenceId(person)}
        fontSize="xs"
        width="9em"
      />
    </VStack>
  );
}

type StudentColumnDefConfig = {
  glossary: GlossaryFn;
  organization: RemoteData<OrganizationError, GQL.Organization>;
  formTemplateId: uuid;
};

export function buildStudentColumnDef<T extends Row>({
  glossary,
  organization,
  formTemplateId,
}: StudentColumnDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.form?.person,
    id: ColumnId.Student,
    header: glossary`student`,

    cell: (props) => (
      <StudentColumnCell
        {...props}
        organization={organization}
        formTemplateId={formTemplateId}
      />
    ),
  };
}
