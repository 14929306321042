import _ from "lodash";

/**
 * This produces an asynchronous generator that applies the provided async
 * function to elements of the provided array in batches.  Each batch is not
 * executed until the first element of that batch is requested, which means that
 * it is possible to abort execution simply by not requesting further elements.
 *
 * The batch size defaults to 1, leading to fully sequential processing of the
 * input array.
 */
export async function* lazyAsyncMap<TIn, TOut>(
  array: TIn[],
  mapFn: (element: TIn, index?: number) => Promise<TOut>,
  batchSize = 1
) {
  let count = 0;
  for (const batch of _.chunk(array, batchSize)) {
    const offset = count;
    yield* await Promise.all(
      batch.map((element, i) => mapFn(element, offset + i))
    );
    count += batch.length;
  }
}

export async function asyncForEach<TIn>(
  array: TIn[],
  forEachFn: (element: TIn, index?: number) => Promise<any>,
  batchSize?: number
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for await (const _ of lazyAsyncMap(array, forEachFn, batchSize));
}

/**
 * Similar to Promise.all(), but executes sequentially in batches.
 */
export async function asyncMap<TIn, TOut>(
  array: TIn[],
  mapFn: (element: TIn, index?: number) => Promise<TOut>,
  batchSize?: number
) {
  const results: TOut[] = [];
  for await (const result of lazyAsyncMap(array, mapFn, batchSize))
    results.push(result);
  return results;
}

export async function asyncFlatMap<TIn, TOut>(
  array: TIn[],
  mapFn: (element: TIn, index?: number) => Promise<TOut[]>,
  batchSize?: number
) {
  const results: TOut[] = [];
  for await (const result of lazyAsyncMap(array, mapFn, batchSize))
    results.push(...result);
  return results;
}

export async function asyncReduce<TIn, TOut>(
  array: TIn[],
  reduceFn: (accumulator: TOut, element: TIn, index: number) => Promise<TOut>,
  initialValue: TOut
) {
  let result = initialValue;
  let i = 0;
  for (const element of array) result = await reduceFn(result, element, i++);
  return result;
}
