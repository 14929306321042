import React, { useEffect, useState } from "react";
import { Loading } from "src/components/Feedback/Loading";
import { DefaultBranding } from "src/configs/branding";
import { Branding, PublicBrandingSchema } from "src/schemas/Branding";
import * as Env from "src/services/env";
import { AvelaAdminOrg } from "src/services/url/Admin";
import { BrandedChakraProvider } from "./BrandedChakraProvider";

export interface BrandingContextType {
  isLoading: boolean;
  branding: Branding | undefined;
}

const defaultBrandingContext: BrandingContextType = {
  isLoading: false,
  branding: undefined,
};

const BrandingContext = React.createContext<BrandingContextType | undefined>(
  undefined
);

interface BrandingProviderProps {
  organizationPath: string | undefined;
  assetsBaseUrl?: string;
}

export function BrandingProvider({
  organizationPath,
  // This is a hack to allow the auth page to inject a value for this env var.
  // TODO: Remove this hack.
  assetsBaseUrl = Env.read().REACT_APP_ASSETS_BASE_URL,
  children,
}: React.PropsWithChildren<BrandingProviderProps>) {
  const [isLoading, setIsLoading] = useState(true);
  const [branding, setBranding] = useState<Branding>();

  useEffect(() => {
    async function fetchBranding() {
      let rawConfig;
      try {
        rawConfig = await (
          await fetch(`${assetsBaseUrl}/branding/${organizationPath}.json`)
        ).json();
      } catch (err) {
        console.warn(`Could not fetch branding for ${organizationPath}:`, err);
        return undefined;
      }

      const parsed = PublicBrandingSchema.safeParse(rawConfig);
      if (!parsed.success) {
        console.error(
          `Unable to parse branding for ${organizationPath}:`,
          parsed.error
        );
        return undefined;
      }

      return {
        colors: parsed.data.colors,
        logo: {
          white: {
            url: parsed.data.logos.whiteSvg,
            alt: `${parsed.data.name} logo`,
          },
          color: {
            url: parsed.data.logos.colorSvg,
            alt: `${parsed.data.name} logo`,
          },
        },
      };
    }

    if (!organizationPath) {
      setBranding(undefined);
      setIsLoading(false);
      return;
    } else if (organizationPath === AvelaAdminOrg) {
      setBranding(DefaultBranding);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    fetchBranding().then((branding) => {
      setBranding(branding);
      setIsLoading(false);
    });
  }, [organizationPath, assetsBaseUrl]);

  return (
    <BrandingContext.Provider value={{ isLoading, branding }}>
      {isLoading ? (
        <BrandedChakraProvider>
          <Loading />
        </BrandedChakraProvider>
      ) : (
        children
      )}
    </BrandingContext.Provider>
  );
}

export function useBranding(): BrandingContextType {
  const context = React.useContext(BrandingContext);
  if (context === undefined) {
    console.error("useBranding() must be used within a BrandingProvider");
  }

  return context ?? defaultBrandingContext;
}
