import { gql } from "@apollo/client";

export const CREATE_BOUNDARY_GROUP = gql`
  mutation CreateBoundaryGroup($geojson: geojson_insert_input!) {
    insert_geojson_one(object: $geojson) {
      id
    }
  }
`;

export const DELETE_BOUNDARY_GROUP = gql`
  mutation DeleteBoundaryGroup($id: uuid!) {
    delete_geo_eligibility(
      where: { school_boundary: { geojson_id: { _eq: $id } } }
    ) {
      affected_rows
    }
    delete_school_boundary(where: { geojson_id: { _eq: $id } }) {
      affected_rows
    }
    delete_boundary_tag(where: { geojson_id: { _eq: $id } }) {
      affected_rows
    }
    delete_geojson_by_pk(id: $id) {
      id
    }
  }
`;
