import { Pages } from "src/auth/types";
import * as OrgConfig from "@avela/organization-config-sdk";

export function isOnlySSO(methods: OrgConfig.Login.LoginMethod[]): boolean {
  if (methods.length > 1) {
    return false;
  }

  if (methods.find((m) => m.method === "sso") !== undefined) {
    return true;
  }

  return false;
}

export function isEmailPasswordEnabled(
  methods: OrgConfig.Login.LoginMethod[]
): boolean {
  return methods.find((m) => m.method === "email") !== undefined;
}

export function isSMSEnabled(methods: OrgConfig.Login.LoginMethod[]): boolean {
  return methods.find((m) => m.method === "sms") !== undefined;
}

export function getSSOsForPage(
  page: Pages,
  methods: OrgConfig.Login.LoginMethod[]
): OrgConfig.Login.SSO[] {
  if (page !== Pages.Login) {
    return [];
  }

  return methods.filter((m): m is OrgConfig.Login.SSO => {
    return m.method === "sso";
  });
}
