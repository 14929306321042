import _ from "lodash";
import * as AFF from "src/services/formTemplateFilters";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { Strict } from "../Strict";
import { toOptionWithoutBranching } from "../option";
import { sortByOrder } from "../sorter";
import { toBaseFormQuestion } from "./question";

export function toSingleSelectWithBranching(
  value: GQL.FormQuestionFragment,
  strict: Strict
): AF.SingleSelect<AF.WithId> {
  validateSingleSelect(value);
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.SingleSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map((value) => toOptionWithBranching(value, strict)),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

function toOptionWithBranching(
  value: GQL.FormQuestionOptionFragment,
  strict: Strict
): AF.Option<AF.WithId> {
  const optionWithoutBranching = toOptionWithoutBranching(value);

  return {
    ...optionWithoutBranching,
    additionalQuestions:
      value.additional_questions.length > 0
        ? value.additional_questions
            .filter((question) => question.question !== null)
            .map((q) => toQuestionWithoutBranching(q.question, strict))
            .filter(isNotNull)
        : undefined,
  };
}

/**
 * This is only used by toOptionWithBranching
 */
function toQuestionWithoutBranching(
  value:
    | GQL.FormQuestionOptionFragment_additional_questions_question
    | null
    | undefined,
  strict: Strict
): AF.Question<AF.WithId> | null {
  if (!value) {
    return null;
  }
  if (
    value.form_question?.category !==
    GQL.form_question_category_enum.GeneralQuestion
  ) {
    const error = `Invalid question category for GeneralSection, expecting "GeneralCategory", got a ${value.form_question?.category}`;
    if (strict === "strict") {
      throw new Error(error);
    } else {
      console.warn(error);
    }
  }

  switch (value.type) {
    case GQL.question_type_enum.FileUpload:
      return toFileUpload(value);
    case GQL.question_type_enum.FreeText:
      return toFreeText(value);
    case GQL.question_type_enum.MultiSelect:
      return toMultiSelectWithoutBranching(value);
    case GQL.question_type_enum.SingleSelect:
      return toSingleSelectWithoutBranching(value);
    case GQL.question_type_enum.Email:
      return toEmail(value);
    case GQL.question_type_enum.PhoneNumber:
      return toPhoneNumber(value);
    case GQL.question_type_enum.Address:
      return toAddress(value);
    case GQL.question_type_enum.Grades:
      throw new Error("Grades question is not supported");
    case GQL.question_type_enum.CustomQuestion:
      throw new Error("Custom question is not supported");
    default:
      const _exhaustiveCheck: never = value.type;
      return _exhaustiveCheck;
  }
}

export function toSingleSelectWithoutBranching(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): AF.SingleSelect<AF.WithId> {
  validateSingleSelect(value);
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.SingleSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toOptionWithoutBranching),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

export function toMultiSelectWithBranching(
  value: GQL.FormQuestionFragment,
  strict: Strict
): AF.MultiSelect<AF.WithId> {
  validateMultiSelect(value);
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.MultiSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map((value) => toOptionWithBranching(value, strict)),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

export function toMultiSelectWithoutBranching(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): AF.MultiSelect<AF.WithId> {
  validateMultiSelect(value);
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.MultiSelectType,
    options: _.sortBy(
      value.form_question?.form_question_options ?? [],
      sortByOrder
    ).map(toOptionWithoutBranching),
    filters: AFF.parseFilters(value.form_question?.filters),
  };
}

export function toFreeText(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FreeText<AF.WithId> {
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.FreeTextType,
  };
}

export function toFileUpload(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FileUpload<AF.WithId> {
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.FileUploadType,
  };
}

export function toEmail(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Email<AF.WithId> {
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.EmailType,
  };
}

export function toPhoneNumber(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.PhoneNumber<AF.WithId> {
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.PhoneNumberType,
  };
}

export function toAddress(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Address<AF.WithId> {
  const question = toBaseFormQuestion(value);
  return {
    ...question,
    type: AF.AddressType,
  };
}

/**
 * Validation
 */
function validateSingleSelect(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): void {
  if (value.type !== GQL.question_type_enum.SingleSelect) {
    throw new Error(
      `Invalid question type, expecting "SingleSelect", got a ${value.type}`
    );
  }
  if ((value.form_question?.form_question_options ?? []).length === 0) {
    throw new Error("Missing options for SingleSelect question");
  }
}

function validateMultiSelect(
  value: GQL.FormQuestionOptionFragment_additional_questions_question
): void {
  if (value.type !== GQL.question_type_enum.MultiSelect) {
    throw new Error(
      `Invalid question type, expecting "MultiSelect", got a ${value.type}`
    );
  }
  if ((value.form_question?.form_question_options ?? []).length === 0) {
    throw new Error("Missing options for MultiSelect question");
  }
}
