import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import {
  Recipient,
  RecipientsTable,
} from "src/components/Table/RecipientsTable";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as GQL from "src/types/graphql";
import { GET_MESSAGE_BY_ID } from "./graphql/queries";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";

export const ViewMessage: React.FC = () => {
  const { id } = useParams();
  const organization = useOrganization();
  const { messageAdhocReceiverFilter } = useSchoolAdminPermissions();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageById,
    GQL.GetMessageByIdVariables
  >(GET_MESSAGE_BY_ID, {
    variables: {
      message_id: id ?? "",
      message_receiver_filter: messageAdhocReceiverFilter(),
    },
  });

  if (!id) return <NotFound />;

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.message_adhoc_by_pk === null) return <NotFound />;

        const recipients: Recipient[] = []; // do this way to eliminate nullables
        data.message_adhoc_by_pk.message_adhoc_receivers.forEach((receiver) => {
          if (receiver.receiver_person != null)
            recipients.push({
              ...receiver.receiver_person,
              message_status: receiver.message_status,
            });
        });

        return (
          <Flex direction="column" gap={8} width="100%">
            <Breadcrumb
              items={breadcrumb.messages.getBreadcrumbsForView(
                organization,
                data.message_adhoc_by_pk
              )}
            />
            <Heading as="h1" size="lg">
              Messages
            </Heading>

            <Flex gap={6}>
              <Flex width="60%">
                <RecipientsTable recipients={recipients} />
              </Flex>

              <Flex direction="column" gap={4} width="40%">
                <Text as="h2" fontWeight="700" fontSize="lg">
                  Messages
                </Text>

                <Text fontWeight={600}>Email</Text>
                <Flex direction="column">
                  <Text fontSize="sm">Subject</Text>
                  <Text color="gray.600">
                    {
                      data.message_adhoc_by_pk.message_adhoc_payload_email
                        ?.message_subject
                    }
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text fontSize="sm">Content</Text>
                  <Text color="gray.600">
                    {
                      data.message_adhoc_by_pk.message_adhoc_payload_email
                        ?.message_body
                    }
                  </Text>
                </Flex>

                <Divider />

                <Text fontWeight={600}>SMS</Text>
                <Flex direction="column">
                  <Text fontSize="sm">Content</Text>
                  <Text color="gray.600">
                    {
                      data.message_adhoc_by_pk.message_adhoc_payload_sms
                        ?.message_body
                    }
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </GQLRemoteDataView>
  );
};
