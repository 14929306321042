import { Button, ButtonProps } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useEditFormTemplateContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content";

type RemoveSectionButtonProps = ButtonProps & {
  sectionId: uuid;
};

export const RemoveSectionButton: React.FC<RemoveSectionButtonProps> = ({
  sectionId,
}) => {
  const { dispatch, formTemplateId } = useEditFormTemplateContext();
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: "Remove unpublished section?",
    body: "This will remove the section and any questions you have added within it. This can’t be undone.",
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Remove",
      colorScheme: "red",
    },
    translate: true,
  });

  const onRemoveButtonClick = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    if (!dispatch) {
      return;
    }

    dispatch({ type: "RemoveNewSection", sectionId });

    if (formTemplateId) {
      dispatch({ type: "Save", formTemplateId });
    }
  }, [dispatch, confirm, sectionId, formTemplateId]);

  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<RiDeleteBin7Line />}
        alignSelf="flex-end"
        onClick={onRemoveButtonClick}
      >
        Remove section
      </Button>
      {confirmationDialog}
    </>
  );
};
