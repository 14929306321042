import { addDays, format, isToday, isTomorrow } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

const PRESET_TIMES_LENGTH = 3;
const TARGET_HOURS = [0, 9, 17]; // Midnight, 9am, 5pm

export type PresetTimeOption = {
  key: number; // index
  value: string; // DateISOString in UTC time
  label: string; // ["Today"|"Tomorrow"] at [H]:00 ["AM"/"PM"]
};
export function getPresetTimeOptions(
  currentTime: Date,
  timezoneName: string
): PresetTimeOption[] {
  const currentZonedTime = toZonedTime(currentTime, timezoneName);
  const presetTimes: Date[] = [];
  let offsetDays = 0;

  while (presetTimes.length < PRESET_TIMES_LENGTH) {
    for (const hour of TARGET_HOURS) {
      const targetTime = addDays(currentZonedTime, offsetDays);
      targetTime.setHours(hour, 0, 0, 0);
      if (targetTime.getTime() > currentZonedTime.getTime()) {
        presetTimes.push(targetTime);
      }
      if (presetTimes.length >= PRESET_TIMES_LENGTH) {
        break;
      }
    }
    offsetDays += 1;
  }

  return presetTimes.map((presetTime, idx) => ({
    key: idx,
    value: fromZonedTime(presetTime, timezoneName).toISOString(),
    label: formatPresetTimeLabel(presetTime),
  }));
}

// ["Today"|"Tomorrow"] at [H]:00 ["AM"/"PM"]
export function formatPresetTimeLabel(date: Date): string {
  if (isToday(date)) {
    return `Today at ${format(date, "h:mm a")}`;
  } else if (isTomorrow(date)) {
    return `Tomorrow at ${format(date, "h:mm a")}`;
  } else {
    return format(date, "MMMM do 'at' h:mm a");
  }
}

export function getDayWithSuffix(date: Date) {
  const day = date.getDate();
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export enum RepeatMode {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
}

export const repeatModes = [
  RepeatMode.Daily,
  RepeatMode.Weekly,
  RepeatMode.Monthly,
  RepeatMode.Yearly,
];

type RepeatModeOption = {
  label: string;
  formatFormDescription: (time: Date | undefined) => string;
  formatDescription: (time: Date) => string;
};

export const REPEAT_MODE_OPTIONS: {
  [key in RepeatMode]: RepeatModeOption;
} = {
  [RepeatMode.Daily]: {
    label: "Daily",
    formatFormDescription: (time: Date | undefined) =>
      `Every day ${time ? `at ${format(time, "h:mm aa")}` : ""}`,
    formatDescription: (time: Date) => `daily at ${format(time, "h:mm aa")}`,
  },
  [RepeatMode.Weekly]: {
    label: "Weekly",
    formatFormDescription: (time: Date | undefined) =>
      `Every week ${time ? `on ${format(time, "EEEE 'at' h:mm aa")}` : ""}`,
    formatDescription: (time: Date) =>
      `weekly on ${format(time, "EEEE 'at' h:mm aa")}`,
  },
  [RepeatMode.Monthly]: {
    label: "Monthly",
    formatFormDescription: (time: Date | undefined) =>
      `Every month ${
        time
          ? `on the ${getDayWithSuffix(time)} at ${format(time, "h:mm aa")}`
          : ""
      }`,
    formatDescription: (time: Date) =>
      `monthly on the ${getDayWithSuffix(time)} at ${format(time, "h:mm aa")}
`,
  },
  [RepeatMode.Yearly]: {
    label: "Yearly",
    formatFormDescription: (time: Date | undefined) =>
      `Every year ${time ? `on ${format(time, "MMMM d 'at' h:mm aa")}` : ""} `,
    formatDescription: (time: Date) =>
      `yearly on ${format(time, "MMMM d 'at' h:mm aa")}`,
  },
};
