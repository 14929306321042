import _ from "lodash";

type UndoItem<T> = { value: T };
export function removeConsecutiveDuplicates<T, U extends UndoItem<T>>(
  list: readonly U[]
): U[] {
  return _.reject(list, (object, i) => {
    return i > 0 && _.isEqual(list[i - 1]?.value, object.value);
  });
}

export function filterEmpty<T, U extends UndoItem<T>>(list: readonly U[]): U[] {
  return list.filter((i) => !_.isEmpty(i.value));
}

export const removeCurrentAnswer =
  <T, U extends UndoItem<T>>(answer: unknown) =>
  (list: U[]): U[] => {
    if (_.isEqual(list[0]?.value, answer)) {
      return _.drop(list);
    }

    return list;
  };
