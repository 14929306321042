import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserFragment on user {
    id
    name
    created_at
    updated_at
  }
`;
