import { Icon } from "@chakra-ui/icons";
import { As, Box, Button, Text, VStack } from "@chakra-ui/react";

interface NoDataTableProps {
  icon?: As;
  text?: string;
  buttonText?: string;
  onButtonAction?: () => void;
}

export const NoDataTable: React.FC<NoDataTableProps> = ({
  icon,
  text = "No items found",
  buttonText,
  onButtonAction,
}) => {
  return (
    <VStack
      height="50vh"
      align="center"
      justify="center"
      color="gray.500"
      width="100%"
    >
      {icon && <Icon fontSize="6xl" as={icon} mb={8} />}
      <Box>
        <Text fontSize="xl" mb={8}>
          {text}
        </Text>
      </Box>
      {buttonText && (
        <Button onClick={onButtonAction} variant="outline" colorScheme="gray">
          {buttonText}
        </Button>
      )}
    </VStack>
  );
};
