import { Button, Icon } from "@chakra-ui/react";
import { RiUploadLine } from "react-icons/ri";
import { BulkUploadInput } from "src/components/Inputs/BulkUploadInput";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { BULK_INSERT_GRADE_PROGRAMS } from "./graphql/mutations";

interface BulkUploadEnrollmentSchoolsProps {
  onComplete: () => void;
}

const Grade = z.object({
  school_id: z.string(),
  enrollment_period_id: z.string(),
  name: z.string(),
});

type GradeRow = z.infer<typeof Grade>;

function isValid(data: unknown): data is GradeRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

function isValidRow(data: unknown): data is GradeRow {
  const { success } = Grade.safeParse(data);
  return success;
}

export const BulkUploadEnrollmentSchools = ({
  onComplete,
}: BulkUploadEnrollmentSchoolsProps) => {
  const [BulkInsertEnrollmentSchools] = useRemoteDataMutation<
    GQL.BulkInsertGradePrograms,
    GQL.BulkInsertGradeProgramsVariables
  >(BULK_INSERT_GRADE_PROGRAMS);

  const parseData = (data: unknown[]): GradeRow[] => {
    const parsedGrades: (GradeRow | null)[] = data.map((row) => {
      const grade = Grade.parse(row);

      const { school_id, enrollment_period_id, name } = grade;

      if (grade) {
        return {
          school_id,
          enrollment_period_id,
          name,
        };
      } else {
        return null;
      }
    });

    const grades = parsedGrades.filter((s): s is GradeRow => !!s);

    return grades;
  };

  const handleUpload = async (data: unknown[]) => {
    let grades;
    try {
      grades = parseData(data);
    } catch (e) {
      console.error("We were unable to parse the csv", e);
    }

    if (grades && grades.length > 0) {
      await BulkInsertEnrollmentSchools({
        variables: {
          grade_programs: grades,
        },
      });

      onComplete();
    }
  };

  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      <Button leftIcon={<Icon as={RiUploadLine} />} size="md" variant="outline">
        Import
      </Button>
    </BulkUploadInput>
  );
};
