import React from "react";
import { ConfigFormLayout } from "./ConfigFormLayout";
import { Input } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";

type Props = {};
export const ScheduleExportConfigForm: React.FC<Props> = () => {
  return (
    <ConfigFormLayout
      type="ScheduleExport"
      title="Export"
      label="Scheduling"
      description="Enables the organization to set up scheduled and recurring exports."
    >
      {() => {
        return (
          <InputControl
            name={`organizationConfigs[ScheduleExport].secretArn`}
            label="Secret ARN"
          >
            <Input size="sm" placeholder="Enter ARN suffix" />
          </InputControl>
        );
      }}
    </ConfigFormLayout>
  );
};
