import { Box, Button, HStack, useDisclosure } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { useFormImportFileUploadFlow } from "src/components/FormImport/useFormImportFileUploadFlow";
import { ReactComponent as BrokenFileSvg } from "src/images/broken-file.svg";
import { FormImportErrorsDialog } from "./FormImportErrorsDialog";

interface CouldNotCreateFormsProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function CouldNotCreateForms(props: CouldNotCreateFormsProps) {
  const { enrollmentPeriodId, formTemplateId, formTemplateName } = props;

  const { openFilePicker } = useFormImportFileUploadFlow({
    enrollmentPeriodId,
    formTemplateId,
    formTemplateName,
  });

  const {
    state: { formImportId },
  } = useFormImportFlow();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description="The file's contents may not meet the format requirements or may simply be empty."
        heading="Could not create responses"
        Svg={BrokenFileSvg}
      >
        <HStack>
          <Button colorScheme="gray" onClick={onOpen} variant="outline">
            See details
          </Button>

          <Button onClick={openFilePicker} variant="solid">
            Retry
          </Button>
        </HStack>
      </EmptyState>

      <FormImportErrorsDialog
        formImportId={formImportId ?? ""}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
}
