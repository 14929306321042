import { Flex, FormLabel, Text } from "@chakra-ui/react";
import React from "react";
import * as AF from "src/types/formTemplate";
import { QUESTION_TYPE_TEXT } from "../constants";

type Props = { questionType: AF.QuestionType };
export const QuestionType: React.FC<Props> = ({ questionType }) => {
  const { label, description } = QUESTION_TYPE_TEXT[questionType];
  return (
    <Flex direction="column" gap="2">
      <FormLabel as={Text} margin="0">
        Question type
      </FormLabel>
      <Text color="blackAlpha.700">{`${label} - ${description}`}</Text>
    </Flex>
  );
};
