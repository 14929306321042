import { Box, Button, Spinner } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyState } from "src/components/EmptyState";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";

interface CreatingFormsProps {
  enrollmentPeriodId: uuid;
}

export function CreatingForms(props: CreatingFormsProps) {
  const { selectedNavFormTemplate } = useFormTemplates();

  const navigate = useNavigate();
  const organization = useOrganization();

  const handleComeBackLaterClick = useCallback(() => {
    if (organization.hasData()) {
      const params = new URLSearchParams({
        enrollmentPeriod: props.enrollmentPeriodId,
      });

      if (selectedNavFormTemplate === undefined) {
        throw new Error("selectedNavFormTemplate is undefined");
      }

      navigate(
        Url.OrgAdmin.Forms.index({
          organization: organization.data,
          formTemplateId: selectedNavFormTemplate.id,
          params: params.toString(),
        })
      );
    }
  }, [
    navigate,
    organization,
    selectedNavFormTemplate,
    props.enrollmentPeriodId,
  ]);

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description={
          <>
            Import in progress for {selectedNavFormTemplate?.name ?? "Form"}.
            This can take a few, you can come back later.
          </>
        }
        graphic={<Spinner color="primary.500" label="" size="xl" />}
        heading="Creating forms"
      >
        <Button
          colorScheme="gray"
          onClick={handleComeBackLaterClick}
          variant="outline"
        >
          Come back later
        </Button>
      </EmptyState>
    </Box>
  );
}
