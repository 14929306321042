import React from "react";
import { createContext, FunctionComponent, useContext } from "react";
import { State } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/messagesState";
import { Action, useFormTemplateMessagesProcessor } from "./processor";
import { ListMessageTemplatesForm } from "./schemas";

type ContextValue = {
  state?: State;
  dispatch?: (action: Action) => void;
  formTemplateId?: uuid;
};

const EditFormTemplateMessagesContext = createContext<ContextValue>({
  state: undefined,
  dispatch: undefined,
  formTemplateId: undefined
});

type Props = {
  children: React.ReactNode;
  listMessagesInitialValues: ListMessageTemplatesForm;
  formTemplateId: uuid;
};

export const EditFormTemplateMessagesProvider: FunctionComponent<Props> = (
  props
) => {
  const { children, listMessagesInitialValues, formTemplateId } = props;
  const formTemplateMessagesState = useFormTemplateMessagesProcessor(
    { listMessages: listMessagesInitialValues },
    formTemplateId
  );

  return (
    <EditFormTemplateMessagesContext.Provider
      value={{
        ...formTemplateMessagesState,
        formTemplateId
      }}
    >
      {children}
    </EditFormTemplateMessagesContext.Provider>
  );
};

export function useEditFormTemplateMessagesContext() {
  const context = useContext<ContextValue>(EditFormTemplateMessagesContext);

  if (context === undefined) {
    throw new Error(
      "useEditFormTemplateMessagesContext must be used within a FormTemplateEditMessagesProvider"
    );
  }

  return context;
}
