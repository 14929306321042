import { Flex, Tag } from "@chakra-ui/react";
import * as AF from "src/types/formTemplate";

type Props = { question: AF.Question<AF.WithId> };

export const VerificationTag: React.FC<Props> = ({ question }) => {
  if (
    question.type === AF.GradesType ||
    question.formVerification === undefined
  ) {
    return null;
  }

  return (
    <Flex direction="row">
      <Tag size="sm">{question.formVerification.label} verification</Tag>
    </Flex>
  );
};

export function hasVerificationTags(question: AF.Question<AF.WithId>): boolean {
  return VerificationTag({ question }) !== null;
}
