import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { SortType, UNSORTED } from "../components/Table/SortButton";
import { SearchAndFilterTypes } from "../constants";
import * as GQL from "../types/graphql";
import { isGqlOrderBy } from "../types/graphqlExtra";

export interface SortField {
  sortKey: string;
  sortType: GQL.order_by;
}

type OrderByProps = { defaultOrderBy?: SortField };
export function useOrderByParams(props?: OrderByProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { defaultOrderBy } = props ? props : { defaultOrderBy: undefined };

  const sortKey = searchParams.get(SearchAndFilterTypes.SortKey) ?? "";
  const sortType = searchParams.get(SearchAndFilterTypes.SortType);
  const orderBy: SortField | null = React.useMemo(() => {
    return sortType && isGqlOrderBy(sortType) ? { sortKey, sortType } : null;
  }, [sortKey, sortType]);

  React.useEffect(() => {
    if (!orderBy && defaultOrderBy) {
      searchParams.set(SearchAndFilterTypes.SortKey, defaultOrderBy.sortKey);
      searchParams.set(SearchAndFilterTypes.SortType, defaultOrderBy.sortType);
      setSearchParams(searchParams, { replace: true });
    }
  }, [orderBy, defaultOrderBy, searchParams, setSearchParams]);

  const setOrderBy = useCallback(
    (columnName: string, sortType: SortType) => {
      if (!columnName || sortType === UNSORTED) {
        searchParams.delete(SearchAndFilterTypes.SortKey);
        searchParams.delete(SearchAndFilterTypes.SortType);
      } else {
        searchParams.set(SearchAndFilterTypes.SortKey, columnName);
        searchParams.set(SearchAndFilterTypes.SortType, sortType);
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return { orderBy: orderBy ?? props?.defaultOrderBy ?? null, setOrderBy };
}

export interface Pagination {
  limit: number;
  offset: number;
}

export function usePaginationParams(
  defaultValue: Pagination = { limit: 25, offset: 0 }
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const limitString = searchParams.get(SearchAndFilterTypes.Limit);
  const offsetString = searchParams.get(SearchAndFilterTypes.Offset);
  const pagination = {
    limit: limitString ? parseInt(limitString) : defaultValue.limit,
    offset: offsetString ? parseInt(offsetString) : defaultValue.offset,
  };

  const setPagination = useCallback(
    (limit: number, offset: number) => {
      searchParams.set(SearchAndFilterTypes.Limit, limit.toString());
      searchParams.set(SearchAndFilterTypes.Offset, offset.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return { pagination, setPagination };
}
