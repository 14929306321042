import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { CARD_PROPS } from "../Step";
import { NavLink } from "react-router-dom";
import * as OrgConfig from "@avela/organization-config-sdk";

type Props = {
  config: OrgConfig.AccountLookup.Config;
  resetSteps: () => void;
};

export const NoAccountFound: FunctionComponent<Props> = (props) => {
  const { resetSteps, config } = props;

  return (
    <Flex {...CARD_PROPS} padding={8} gap={4} textAlign="center">
      <Heading as="h2" size="lg" fontSize="1.25rem" fontWeight={700}>
        No account login found
      </Heading>
      <Text fontSize="1rem">
        We were unable to find an account login. You can try again with
        different information or reach out to your school district.
      </Text>
      <Button
        onClick={() => {
          resetSteps();
        }}
      >
        Try again
      </Button>
      <Button
        as={NavLink}
        to={config.supportUrl}
        variant="outline"
        colorScheme="gray"
        target="_blank"
      >
        Stuck? Get help
      </Button>
    </Flex>
  );
};
