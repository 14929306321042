import {
  AddressBookAnswerSchema,
  AddressValidationSchema,
  NO_ADDRESS,
} from "src/components/Inputs/Address/Book";
import {
  BASE_INITIAL_PARENT_FORM,
  ParentSchema,
  ParentValidator,
} from "src/schemas/Parent";
import { nullableWithDefault } from "src/services/zodExtra";
import { z } from "zod";

export const ParentProfileSchema = ParentSchema.extend({
  address: AddressBookAnswerSchema,
});

export const ParentProfileValidator = ParentValidator.extend({
  address: AddressValidationSchema,
});

export const ContactInfoSchema = z.object({
  phone_number: nullableWithDefault(z.string(), ""),
  email_address: nullableWithDefault(z.string(), ""),
});

export type ParentProfileFormType = z.infer<typeof ParentProfileSchema>;

export const INITIAL_PARENT_FORM: ParentProfileFormType = {
  ...BASE_INITIAL_PARENT_FORM,
  address: {
    kind: NO_ADDRESS,
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
};
