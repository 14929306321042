import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Icon } from "@chakra-ui/react";
import { FunctionComponent, useCallback } from "react";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { csvExport } from "src/services/dataTransfer";
import {
  GetSchoolsAndGradesForEnrollmentPeriod,
  GetSchoolsAndGradesForEnrollmentPeriodVariables,
} from "src/types/graphql";
import { GET_SCHOOLS_AND_GRADES_FOR_ENROLLMENT_PERIOD } from "./graphql/queries";

type Props = {
  organizationId: uuid;
  enrollmentPeriodId: uuid;
};

export const ExportEnrollmentPeriodSchoolGradesButton: FunctionComponent<
  Props
> = (props) => {
  const { organizationId, enrollmentPeriodId } = props;

  const toast = useAvelaToast();

  const { remoteData } = useRemoteDataQuery<
    GetSchoolsAndGradesForEnrollmentPeriod,
    GetSchoolsAndGradesForEnrollmentPeriodVariables
  >(GET_SCHOOLS_AND_GRADES_FOR_ENROLLMENT_PERIOD, {
    variables: {
      organization_id: organizationId,
      enrollment_period_id: enrollmentPeriodId,
    },
    skip: !enrollmentPeriodId,
    notifyOnNetworkStatusChange: true,
  });

  const handleOnExportClick = useCallback(
    (data: GetSchoolsAndGradesForEnrollmentPeriod) => {
      return () => {
        const loadingToastId = toast({
          title: `Export in progress`,
          description: `Download will start shortly`,
          status: "loading",
        });

        try {
          const records = data.school.flatMap((record) => {
            const { id, name, grades } = record;
            return grades.map((grade) => {
              const {
                id: gradeId,
                grade_config: { label },
              } = grade;
              return {
                "Enrollment Period ID": enrollmentPeriodId,
                "School ID": id,
                "School Name": name,
                "Grade Config Label": label,
                "Grade ID": gradeId,
              };
            });
          });
          csvExport(records, "enrollmentPeriodSchoolsAndGrades.csv");
          toast.close(loadingToastId);
          toast({
            title: "Enrollment period schools and grades exported",
            status: "success",
          });
        } catch (e: any) {
          toast.close(loadingToastId);
          toast.error({
            title: "Something went wrong!",
            description: "Check your network and try again",
          });
          console.error(e);
        }
      };
    },
    [enrollmentPeriodId, toast]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => (
        <Button
          variant="solid"
          size="md"
          leftIcon={<Icon as={DownloadIcon} />}
          onClick={handleOnExportClick(data)}
        >
          Export
        </Button>
      )}
    </GQLRemoteDataView>
  );
};
