import { Button, Icon, Link } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { RiDownloadLine } from "react-icons/ri";

type DocDownloadProps = {
  href: string;
  disabled?: boolean;
};

export const DocDownloadButton: FC<PropsWithChildren<DocDownloadProps>> = ({
  href,
  disabled,
}: PropsWithChildren<DocDownloadProps>) => {
  return (
    <Button
      width={8}
      height={8}
      background="gray.100"
      color="black"
      aria-label="Download file"
      variant="ghost"
      padding={0}
      isDisabled={disabled}
      as={Link}
      href={href}
    >
      <Icon as={RiDownloadLine} size={25} />
    </Button>
  );
};
