import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Select,
  Text,
} from "@chakra-ui/react";
import { Field, useField, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import * as GQL from "src/types/graphql";
import { OrganizationFormSchema } from "../Form";

interface AnnouncementDisplayType {
  label: string;
  value: GQL.announcement_display_type_enum;
}

type AnnouncementDisplayTypes = Record<
  GQL.announcement_display_type_enum,
  AnnouncementDisplayType
>;

const ANNOUNCEMENT_DISPLAY_TYPES: AnnouncementDisplayTypes = {
  [GQL.announcement_display_type_enum.BANNER]: {
    label: "Banner",
    value: GQL.announcement_display_type_enum.BANNER,
  },
  [GQL.announcement_display_type_enum.DIALOG]: {
    label: "Dialog",
    value: GQL.announcement_display_type_enum.DIALOG,
  },
};

export const DESCRIPTION_CONTENT_LIMIT = 550;

export function AnnouncementForm() {
  const formik = useFormikContext<OrganizationFormSchema>();

  const isActive = formik.values.noStudentAnnouncement.active;

  const [descriptionField, descriptionFieldValidation, descriptionHelpers] =
    useField("noStudentAnnouncement.description");
  const [showTypeField] = useField("noStudentAnnouncement.showType");
  const [linkActiveField] = useField("noStudentAnnouncement.linkActive");

  useEffect(() => {
    if (!linkActiveField.value) {
      formik.setFieldValue("noStudentAnnouncement.linkText", "");
      formik.setFieldValue("noStudentAnnouncement.linkUrl", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkActiveField.value]);

  const descriptionCounter = useMemo(
    () => DESCRIPTION_CONTENT_LIMIT - descriptionField.value?.length,
    [descriptionField.value]
  );

  const handleOnChangeDescription = useCallback(() => {
    if (descriptionFieldValidation.touched) return;

    descriptionHelpers.setTouched(true);
  }, [descriptionHelpers, descriptionFieldValidation.touched]);

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h3" size="md">
        Alerts
      </Heading>

      <Flex direction="column" gap={4}>
        <Flex direction="column">
          <SwitchInput<Boolean>
            label="Alert when no students belong to a parent"
            name="noStudentAnnouncement.active"
            checked={[true, ""]}
            unchecked={[false, ""]}
            direction="row"
          />
          <Text fontSize="sm" color="gray.600" ml={12}>
            An alert that’s displayed to parents when they have no students.
            This alert will always display to the parent once every time they
            login as long as they have no students.
          </Text>
        </Flex>

        {isActive ? (
          <Flex direction="column" marginLeft={12}>
            <FormControl mb={4}>
              <FormLabel htmlFor="noStudentAnnouncement.showType">
                Type
              </FormLabel>
              <Field
                as={Select}
                id="noStudentAnnouncement.showType"
                name="noStudentAnnouncement.showType"
              >
                {(
                  Object.keys(
                    ANNOUNCEMENT_DISPLAY_TYPES
                  ) as GQL.announcement_display_type_enum[]
                ).map((key) => (
                  <option
                    value={ANNOUNCEMENT_DISPLAY_TYPES[key].value}
                    key={ANNOUNCEMENT_DISPLAY_TYPES[key].value}
                  >
                    {ANNOUNCEMENT_DISPLAY_TYPES[key].label}
                  </option>
                ))}
              </Field>

              {showTypeField.value ===
              GQL.announcement_display_type_enum.BANNER ? (
                <FormHelperText>
                  The alert will appear on the family portal homepage. It will
                  not prevent the parent from completing any actions.
                </FormHelperText>
              ) : (
                <FormHelperText>
                  The alert will appear on the family portal homepage. The
                  parent must acknowledge the message to close the dialog and
                  take any actions.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Title</FormLabel>
              <InputWithValidationControl
                inputProps={{ placeholder: "Enter a title" }}
                name="noStudentAnnouncement.title"
                isRequired
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="noStudentAnnouncement.linkActive">
                Link
              </FormLabel>
              <Flex direction="column">
                <SwitchInput<Boolean>
                  label="Show link"
                  name="noStudentAnnouncement.linkActive"
                  checked={[true, ""]}
                  unchecked={[false, ""]}
                  direction="row"
                />
                <Text fontSize="sm" color="gray.600">
                  Show a button in the dialog that links to an external site.
                </Text>
              </Flex>
            </FormControl>

            {formik.values.noStudentAnnouncement.linkActive ? (
              <Flex direction="column" ml={12}>
                <FormControl>
                  <FormLabel htmlFor="noStudentAnnouncement.linkText">
                    Link text
                  </FormLabel>
                  <InputWithValidationControl
                    inputProps={{ placeholder: "e.g.: Action button text" }}
                    name="noStudentAnnouncement.linkText"
                    isRequired
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="noStudentAnnouncement.linkUrl">
                    Link URL
                  </FormLabel>
                  <InputWithValidationControl
                    inputProps={{ placeholder: "e.g.: https://avela.org/" }}
                    name="noStudentAnnouncement.linkUrl"
                    isRequired
                  />
                </FormControl>
              </Flex>
            ) : null}

            <FormControl>
              <FormLabel>Description</FormLabel>
              <TextareaWithValidationControl
                textareaProps={{ placeholder: "Enter a description", mb: 2 }}
                name="noStudentAnnouncement.description"
                isRequired
                onChange={handleOnChangeDescription}
              />

              {(!descriptionFieldValidation.touched ||
                !descriptionFieldValidation.error) && (
                <Text fontSize="sm" lineHeight="1rem">
                  {descriptionCounter}
                </Text>
              )}
            </FormControl>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
}
