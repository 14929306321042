import { useMemo } from "react";
import { ELEVATED_SCHOOL_ADMIN_GROUP_NAME } from "src/constants";
import { Status } from "src/types/authData";
import { HasuraRole } from "src/types/hasuraRole";
import useUser from "./useUser";

type UseSchoolAdmin = {
  isSchoolAdminRole: boolean;
  isElevatedSchoolAdmin: boolean;
  isSchoolAdmin: boolean;
  hasAttendingSchools: boolean;
  hasApplyingSchools: boolean;
  attendingSchoolIds: uuid[];
  applyingSchoolIds: uuid[];
};

export function useSchoolAdmin(): UseSchoolAdmin {
  const user = useUser();
  const claims = user.status === Status.OK ? user.data : null;

  const isSchoolAdminRole = claims?.role === HasuraRole.SCHOOL_ADMIN;
  const isElevatedSchoolAdmin =
    claims?.userGroup === ELEVATED_SCHOOL_ADMIN_GROUP_NAME;
  const isSchoolAdmin = isSchoolAdminRole || isElevatedSchoolAdmin;

  const attendingSchoolIds = useMemo(
    () => (isSchoolAdmin && claims?.currentSchoolIds) || [],
    [isSchoolAdmin, claims]
  );
  const applyingSchoolIds = useMemo(
    () => (isSchoolAdmin && claims?.applyingSchoolIds) || [],
    [isSchoolAdmin, claims]
  );

  const hasAttendingSchools = attendingSchoolIds.length > 0;
  const hasApplyingSchools = applyingSchoolIds.length > 0;

  return {
    isSchoolAdminRole,
    isElevatedSchoolAdmin,
    isSchoolAdmin,
    hasAttendingSchools,
    hasApplyingSchools,
    attendingSchoolIds,
    applyingSchoolIds,
  };
}
