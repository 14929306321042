import { Box } from "@chakra-ui/layout";
import React from "react";

interface OverlayProps {
  onClick?: () => void;
}

export const Overlay: React.FC<OverlayProps> = ({ onClick }) => {
  return (
    <Box
      position="fixed"
      width="100%"
      height="100%"
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor="#00000040"
      zIndex={2}
      onClick={onClick}
    />
  );
};
