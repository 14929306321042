import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export type NestedRouteTab = {
  label: string;
  type: string;
  path: string;
  isVisible: boolean;
};

type Props<OutletContext extends object> = {
  tabs: NestedRouteTab[];
  outletContext: OutletContext;
};

/**
 * A react-router based navigation bar in the visual form of Chakra-UI tabs.
 *
 * The FunctionComponent type doesn't support nested generics.
 * The following is used instead of FunctionComponent<Props<T>>.
 */
export const NestedRouteOutletTabs = <T extends object>(props: Props<T>) => {
  const { tabs, outletContext } = props;
  const navigate = useNavigate();
  const tabIndex = useTabIndex(tabs);

  const navigateOnChange = useCallback(
    (index: number) => {
      const selectedTab = tabs[index];
      if (!selectedTab) {
        return;
      }
      // navigate to sibling route
      navigate(selectedTab.path);
    },
    [tabs, navigate]
  );

  return (
    <Tabs isLazy index={tabIndex} onChange={navigateOnChange}>
      <TabList>
        {tabs.map(
          (tab) => tab.isVisible && <Tab key={tab.type}>{tab.label}</Tab>
        )}
      </TabList>
      <TabPanels>
        {tabs.map(
          (tab) =>
            tab.isVisible && (
              <TabPanel key={tab.type}>
                <Outlet context={outletContext} />
              </TabPanel>
            )
        )}
      </TabPanels>
    </Tabs>
  );
};

/**
 * Determine tab index based on current path.
 */
function useTabIndex(tabs: NestedRouteTab[]): number {
  const { pathname } = useLocation();

  const pathMatches = useMemo(() => {
    return tabs.map(({ path }) => {
      return pathname.startsWith(path);
    });
  }, [tabs, pathname]);

  const tabIndex = pathMatches.indexOf(true);
  return tabIndex !== -1 ? tabIndex : 0;
}
