import { FetchResult } from "@apollo/client";
import { hasPermission } from "@avela/avela-authorization-sdk";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
} from "@chakra-ui/react";
import { Form, Formik, FormikProps } from "formik";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { BoundariesMap, addressLookup } from "src/components/Boundary/services";
import { useBoundariesMap } from "src/components/Boundary/useBoundary";
import {
  FORM_AUTHORIZATION_SCOPE,
  useAuthorizationScoped,
} from "src/components/Providers/AuthorizationProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { FormQuestion } from "src/scenes/parent/forms/components/Inputs/FormQuestion";
import { DELETE_ANSWERS } from "src/scenes/parent/forms/graphql/mutations";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { Answer } from "src/services/formTemplate";
import {
  findFormAddressAnswer,
  findGradeAnswer,
  getAddressAnswersDeletes,
  getAddressAnswersInserts,
  getCustomQuestionAnswerUpsertPayload,
  getFreeTextAnswersInserts,
  getGradesAnswersInserts,
  getMultiSelectAnswersInserts,
  getSingleSelectAnswersInserts,
} from "src/services/formTemplate/answer";
import {
  RankedSchool,
  calculateSchoolsToRemove,
} from "src/services/formTemplate/preRankingSection";
import * as Question from "src/services/formTemplate/question";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import {
  UPSERT_FORM_ANSWERS,
  UPSERT_FORM_ANSWERS_AND_DELETE_SCHOOLS_RANK,
} from "../../graphql/mutations";
import {
  GET_FORM_VIEW_BY_ID,
  GET_SCHOOLS_RANK_VIEW,
} from "../../graphql/queries";
import { useEligibilityService } from "src/services/eligibility/useEligibilityService";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { RankedSchoolsRemovalConfirmationDialogBody } from "src/scenes/parent/forms/components/Dialogs/RankedSchoolsRemovalConfirmationDialogBody";
import { useGlossary } from "src/hooks/useGlossary";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useFrontEndRankedSchoolsRemoval } from "./useFrontEndRankededSchoolsRemoval";
import { Undo } from "./Undo";

type Props = {
  formTemplateId: uuid;
  formId: uuid;
  personId: uuid;
  applicant: AFF.Types.Applicant;
  schoolRankingSectionId: uuid | undefined;
  questions: AF.Question<AF.WithId>[];
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  custom_question_answer_bank_relationships?: GQL.CustomQuestionAnswerBankRelationshipsFragment[];
  customQuestionTypes: GQL.GetCustomQuestionTypesByOrg_custom_question_type[];
  rankedSchools: RankedSchool[];
  onCloseEdit: () => void;
  previousFormSchoolIds: uuid[];
  rankingEnabled: boolean;
};

export const PreRankingSectionEditForm: React.FC<Props> = (props) => {
  const {
    formTemplateId,
    formId,
    personId,
    schoolRankingSectionId,
    questions,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships,
    customQuestionTypes,
    rankedSchools,
    onCloseEdit,
    previousFormSchoolIds,
  } = props;
  const {
    getDeletedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
    getUpsertedRanks,
  } = useRankedSchools(
    rankedSchools.map((item) => ({
      form_id: formId,
      schools_ranking_section_id: schoolRankingSectionId ?? "",
      school_id: item.school.id,
    }))
  );
  const [upsertFormAnswers, upsertFormAnswersStatus] = useRemoteDataMutation<
    GQL.UpsertFormAnswer,
    GQL.UpsertFormAnswerVariables
  >(UPSERT_FORM_ANSWERS);

  const [deleteAnswers] = useRemoteDataMutation<
    GQL.DeleteAnswers,
    GQL.DeleteAnswersVariables
  >(DELETE_ANSWERS);

  const [
    upsertFormAnswersAndDeleteSchoolsRank,
    upsertFormAnswersAndDeleteSchoolsRankStatus,
  ] = useRemoteDataMutation<
    GQL.UpsertFormAnswerAndDeleteSchoolsRank,
    GQL.UpsertFormAnswerAndDeleteSchoolsRankVariables
  >(UPSERT_FORM_ANSWERS_AND_DELETE_SCHOOLS_RANK);

  useLoadGooglePlacesScript();

  const isUpserting = RD.toTuple(
    upsertFormAnswersStatus.remoteData,
    upsertFormAnswersAndDeleteSchoolsRankStatus.remoteData
  ).isLoading();

  const initialAnswers = Answer.getFormikInitialValues(
    questions,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships
  );
  const rankedSchoolIds = rankedSchools.map(
    (rankedSchool) => rankedSchool.school.id
  );
  const toast = useAvelaToast();
  const boundariesMap = useBoundariesMap({ formId });
  const flags = useFlags(["eligibility-service"]);
  const eligibilityService = useEligibilityService({ formTemplateId });
  const submitHandler = async (answers: Answer.FormikValues) => {
    try {
      if (!boundariesMap.hasData()) {
        return;
      }

      if (!window.google?.maps) {
        return;
      }

      const completeQuestions = Question.getCompleteQuestions(questions);
      const applicableQuestions = Question.getCompleteApplicableQuestions(
        questions,
        answers,
        { rankedSchoolIds, previousFormSchoolIds }
      );
      const modifiedQuestions = applicableQuestions.filter(
        (q) => !_.isEqual(answers[q.id], initialAnswers[q.id])
      );

      const freeTextAnswers = getFreeTextAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleSelectAnswers = getSingleSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const multiSelectAnswers = getMultiSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleOrMultiSelectAnswers =
        singleSelectAnswers.concat(multiSelectAnswers);
      const gradesAnswers = getGradesAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const addressAnswers = getAddressAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const formAddressDeletes = getAddressAnswersDeletes(
        formId,
        modifiedQuestions,
        answers
      );
      const {
        customQuestionAnswers,
        answerBankIds,
        answerBankAnswers,
        newAnswerBankEntries,
        customQuestionAnswersToDelete,
        customQuestionAnswerFieldsToDelete,
      } = getCustomQuestionAnswerUpsertPayload(
        formId,
        personId,
        modifiedQuestions,
        answers,
        customQuestionTypes
      );

      const customQuestions = modifiedQuestions.filter(
        (q) => q.type === AF.CustomQuestionType
      );

      const customQuestionIds = customQuestions.map((cq) => cq.id);

      const customQuestionSingleSelectQuestionIds: uuid[] =
        customQuestions.flatMap((q) =>
          (q as AF.CustomQuestion<AF.WithId>).nestedQuestions
            .filter((nq) => nq.type === AF.SingleSelectType)
            .map((nq) => nq.id)
        );
      // Existing single and multi select question answers,
      // including those that are nested in custom questions,
      // will be deleted
      const singleAndMultiSelectQuestionsIds: uuid[] =
        singleOrMultiSelectAnswers
          .map((a) => a.question_id)
          .filter(isNotNull)
          .concat(customQuestionSingleSelectQuestionIds)
          .concat(customQuestionAnswerFieldsToDelete); // this deliberately overloads "singleAndMultiSelectQuestionsIds" to support "Do not answer"

      let newSchoolsRank: GQL.form_school_rank_insert_input[];
      let deletedSchoolRanks: GQL.form_school_rank_bool_exp;
      let deletedOffers: GQL.offer_bool_exp;
      let deletedWaitlists: GQL.waitlist_bool_exp;

      if (schoolRankingSectionId === undefined) {
        // no school ranks section, so school ranks should be empty
        newSchoolsRank = [];
        deletedSchoolRanks = {};
        deletedOffers = {
          deleted_at: { _is_null: true },
          _or: [],
        };
        deletedWaitlists = {
          deleted_at: { _is_null: true },
          _or: [],
        };
      } else {
        const gradeConfigId = findGradeAnswer(completeQuestions, answers);
        const address = findFormAddressAnswer(completeQuestions, answers);
        const location = await addressLookup(
          new google.maps.Geocoder(),
          address
        );

        let schoolsIdToRemove: string[];
        if (flags["eligibility-service"].enabled) {
          const ineligibleSchoolIds =
            await eligibilityService.getIneligibleSchoolIdsByAnswers({
              answers,
            });
          schoolsIdToRemove = rankedSchools.flatMap((rs) =>
            ineligibleSchoolIds.includes(rs.school.id) ? [rs.school.id] : []
          );
        } else {
          schoolsIdToRemove = calculateSchoolsToRemove(
            rankedSchools,
            applicableQuestions,
            answers,
            gradeConfigId?.gradeConfigId,
            location,
            boundariesMap.data
          ).map((schoolRank) => schoolRank.school.id);
        }

        newSchoolsRank = getUpsertedRanks(
          _.sortBy(
            rankedSchools.filter(
              (rankedSchool) =>
                !schoolsIdToRemove.includes(rankedSchool.school.id)
            ),
            (schoolRank) => schoolRank.rank
          ).map((schoolRank) => {
            return {
              form_id: formId,
              schools_ranking_section_id: schoolRankingSectionId,
              school_id: schoolRank.school.id,
            };
          })
        );
        deletedSchoolRanks = getDeletedRanks(newSchoolsRank);
        deletedOffers = getDeletedOffers(newSchoolsRank);
        deletedWaitlists = getDeletedWaitlists(newSchoolsRank);
      }

      const allNewAnswers = freeTextAnswers.concat(singleOrMultiSelectAnswers);
      const refetchQueries = [
        GET_FORM_VIEW_BY_ID,
        GET_FORM_ANSWERS_BY_ID,
        GET_SCHOOLS_RANK_VIEW,
      ];

      const inapplicableQuestions = Question.findInapplicableQuestionsToDelete(
        completeQuestions,
        applicableQuestions,
        answers
      );

      const upsertFormAnswersPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswer>
      > => {
        return upsertFormAnswers({
          variables: {
            form_id: formId,
            answers: allNewAnswers,
            custom_question_ids: customQuestionIds,
            single_or_multi_select_question_ids:
              singleAndMultiSelectQuestionsIds,
            grades_answers: gradesAnswers,
            address_answers: addressAnswers,
            address_answer_deletes: formAddressDeletes,
            custom_question_answers: customQuestionAnswers,
            answer_bank_ids: answerBankIds,
            answer_bank_answers: answerBankAnswers,
            new_answer_bank_entries: newAnswerBankEntries,
            deleted_answers_custom_question_ids: customQuestionAnswersToDelete,
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
      };
      const upsertFormAnswersAndDeleteSchoolsRankPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswerAndDeleteSchoolsRank>
      > => {
        return upsertFormAnswersAndDeleteSchoolsRank({
          variables: {
            form_id: formId,
            answers: allNewAnswers,
            custom_question_ids: customQuestionIds,
            single_or_multi_select_question_ids:
              singleAndMultiSelectQuestionsIds,
            grades_answers: gradesAnswers,
            custom_question_answers: customQuestionAnswers,
            answer_bank_ids: answerBankIds,
            answer_bank_answers: answerBankAnswers,
            new_answer_bank_entries: newAnswerBankEntries,
            deleted_answers_custom_question_ids: customQuestionAnswersToDelete,
            delete_offers_where: deletedOffers,
            delete_waitlists_where: deletedWaitlists,
            deleted_school_ranks: deletedSchoolRanks,
            upserted_school_ranks: newSchoolsRank,
            address_answers: addressAnswers,
            address_answer_deletes: formAddressDeletes,
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
      };
      const deleteAnswersPromise = (): void | Promise<
        FetchResult<GQL.DeleteAnswers>
      > => {
        return inapplicableQuestions.length > 0
          ? deleteAnswers({
              variables: {
                form_id: formId,
                question_ids: inapplicableQuestions,
              },
            })
          : _.noop();
      };

      const chooseUpsertFormAnswerPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswer>
      > => {
        return schoolRankingSectionId === undefined
          ? upsertFormAnswersPromise()
          : upsertFormAnswersAndDeleteSchoolsRankPromise();
      };
      await Promise.all([
        chooseUpsertFormAnswerPromise(),
        deleteAnswersPromise(),
      ]);

      onCloseEdit();
    } catch (error: unknown) {
      console.error(error);
      toast.error({ title: "Error updating form" });
    }
  };

  return (
    <Formik<Answer.FormikValues>
      initialValues={initialAnswers}
      onSubmit={submitHandler}
      validate={validateFormTemplate(questions, {
        rankedSchoolIds,
        previousFormSchoolIds,
      })}
    >
      {(formikProps) => {
        return (
          <EditForm
            {...props}
            boundariesMap={boundariesMap}
            formikProps={formikProps}
            isUpserting={isUpserting}
            rankedSchoolIds={rankedSchoolIds}
          />
        );
      }}
    </Formik>
  );
};

type EditFormProps = Props & {
  boundariesMap: RD.RemoteData<Error, BoundariesMap>;
  formikProps: FormikProps<Answer.FormikValues>;
  isUpserting: boolean;
  rankedSchoolIds: string[];
};
const EditForm = ({
  formikProps,
  questions,
  rankedSchoolIds,
  previousFormSchoolIds,
  formId,
  formTemplateId,
  applicant,
  onCloseEdit,
  boundariesMap,
  isUpserting,
  rankedSchools,
  rankingEnabled,
}: EditFormProps) => {
  const completeQuestions = useMemo(
    () =>
      Question.getCompleteApplicableQuestions(questions, formikProps.values, {
        rankedSchoolIds,
        previousFormSchoolIds,
      }),
    [formikProps.values, previousFormSchoolIds, questions, rankedSchoolIds]
  );

  const authorizationMap = useAuthorizationScoped(FORM_AUTHORIZATION_SCOPE);

  const canEditQuestion = React.useCallback(
    (question: AF.Question<AF.WithId>) => {
      if (authorizationMap.hasData()) {
        return hasPermission({
          action: {
            type: "Form::Action",
            id: "edit",
          },
          resource: {
            type: "FormQuestion",
            id: question.key || question.id,
          },
          authorizationMap: authorizationMap.data,
        });
      }
      return false;
    },
    [authorizationMap]
  );

  const eligibilityService = useEligibilityService({ formTemplateId });
  const [isEligibilityCheckingState, setIsEligibilityCheckingState] = useState<
    RD.RemoteData<Error, {}>
  >(RD.notAsked());
  const shouldRunEligibilityCheck = useMemo(
    () =>
      rankedSchools.length > 0 &&
      eligibilityService.shouldRunEligibilityCheck({
        questions,
        answers: {
          previous: formikProps.initialValues,
          current: formikProps.values,
        },
      }) &&
      !isUpserting,
    [
      eligibilityService,
      formikProps.initialValues,
      formikProps.values,
      isUpserting,
      questions,
      rankedSchools.length,
    ]
  );

  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: null,
    header: "Edit form",
  });
  const { glossary } = useGlossary();
  const flags = useFlags(["eligibility-service", "admin-form-undo"]);
  const frontEndSchoolsRemoval = useFrontEndRankedSchoolsRemoval({
    formId,
    rankedSchools,
    completeQuestions: completeQuestions,
    answers: formikProps.values,
  });
  const checkSchoolsEligibility = useCallback(async () => {
    setIsEligibilityCheckingState(RD.loading());
    try {
      let schoolsToRemove: RankedSchool[];
      if (!flags["eligibility-service"].enabled) {
        if (!frontEndSchoolsRemoval.hasData()) {
          console.error(
            "Unable to perform front end eligbility check, data is not fully loaded yet."
          );
          return;
        }
        schoolsToRemove = frontEndSchoolsRemoval.data;
      } else {
        const ineligibleSchoolIds =
          await eligibilityService.getIneligibleSchoolIdsByAnswers({
            answers: formikProps.values,
          });
        schoolsToRemove = rankedSchools.filter((rs) =>
          ineligibleSchoolIds.includes(rs.school.id)
        );
      }

      setIsEligibilityCheckingState(RD.success({}));
      if (schoolsToRemove.length === 0) {
        await formikProps.submitForm();
      }

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            rankingEnabled={rankingEnabled}
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, eligibility will change and these schools will be removed from the list:`}
            confirmation="Are you sure?"
          />
        ),
      });
      if (!isConfirmed) {
        return;
      }

      formikProps.submitForm();
    } catch (error) {
      console.error(error);
      setIsEligibilityCheckingState(RD.failure(error as Error));
    }
  }, [
    confirm,
    eligibilityService,
    flags,
    formikProps,
    frontEndSchoolsRemoval,
    glossary,
    rankedSchools,
    rankingEnabled,
  ]);

  return (
    <Flex direction="column" as={Form} noValidate gap={3}>
      {confirmationDialog}
      <Flex direction="column" gap="2">
        {completeQuestions.map((question) => (
          <FormQuestion
            formId={formId}
            applicant={applicant}
            key={question.id}
            question={question}
            marginBottom={4}
            disableAddressBook
            isDisabled={!canEditQuestion(question)}
            labelPostElement={
              flags["admin-form-undo"].enabled ? (
                <Undo
                  answer={formikProps.values[question.id]}
                  question={question}
                  formId={formId}
                  setAnswer={(value) =>
                    formikProps.setFieldValue(question.id, value)
                  }
                />
              ) : null
            }
          />
        ))}
        {shouldRunEligibilityCheck &&
          !isEligibilityCheckingState.hasError() && (
            <Alert status="warning" rounded="md">
              <AlertIcon />
              <AlertDescription>
                By changing the response on this question, you may impact
                eligibility and certain schools may be removed from the
                selection.
              </AlertDescription>
            </Alert>
          )}
        {isEligibilityCheckingState.hasError() && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Unable to check eligibility</AlertTitle>
            <AlertDescription>
              Our engines are not powering up. Try again later.
            </AlertDescription>
          </Alert>
        )}
      </Flex>
      <Flex justifyContent="flex-end" gap={3}>
        <Button
          type="button"
          variant="ghost"
          colorScheme="gray"
          onClick={onCloseEdit}
        >
          Cancel
        </Button>
        {shouldRunEligibilityCheck ? (
          <Button
            type="button"
            onClick={checkSchoolsEligibility}
            isDisabled={
              isEligibilityCheckingState.isLoading() ||
              frontEndSchoolsRemoval.isLoading()
            }
            isLoading={isEligibilityCheckingState.isLoading() || isUpserting}
            loadingText="Checking eligibility..."
          >
            Save
          </Button>
        ) : (
          <Button
            type="submit"
            isDisabled={!formikProps.isValid || !boundariesMap.hasData()}
            isLoading={isUpserting}
          >
            Save
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
