import { Box, Button, Divider, Flex, RadioGroup } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FunctionComponent, useCallback } from "react";
import { BaseAddress } from "./schema";
import {
  SuggestedAddressRadioOption,
  SuggestedAddressType,
} from "./SuggestedAddressRadioOption";

type FormikAddressSuggestionFormProps = {
  originalAddress: BaseAddress;
  suggestedAddress: BaseAddress;
};

export const PickSuggestedAddress: FunctionComponent<
  FormikAddressSuggestionFormProps
> = (props) => {
  const { originalAddress, suggestedAddress } = props;
  const { setValues, submitForm } = useFormikContext<BaseAddress>();

  const setNewAddress = useCallback(
    (radioValue: SuggestedAddressType) => {
      if (radioValue === SuggestedAddressType.Original) {
        setValues(originalAddress);
      }

      if (radioValue === SuggestedAddressType.Suggested) {
        setValues(suggestedAddress);
      }
    },
    [setValues, originalAddress, suggestedAddress]
  );

  return (
    <RadioGroup onChange={setNewAddress} defaultValue="original">
      <Flex direction="column">
        <SuggestedAddressRadioOption
          type={SuggestedAddressType.Original}
          address={originalAddress}
        />
        <Divider />
        <SuggestedAddressRadioOption
          type={SuggestedAddressType.Suggested}
          address={suggestedAddress}
        />
        <Divider />
        <Box padding={4}>
          <Button colorScheme="gray" width="100%" onClick={submitForm}>
            Save address
          </Button>
        </Box>
      </Flex>
    </RadioGroup>
  );
};
