import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { SortType, UNSORTED } from "src/components/Table/SortButton";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GET_ENROLLMENT_PERIODS } from "./graphql/queries";
import { EnrollmentsList } from "./List";

export const Enrollments = (): React.ReactElement | null => {
  const organization = useOrganization();
  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.GetEnrollments,
    GQL.GetEnrollmentsVariables
  >(GET_ENROLLMENT_PERIODS, {
    variables: {
      organization_id: organization.map((org) => org.id).toNullable(),
      order_by: {},
    },
    skip: !organization.hasData(),
    fetchPolicy: "network-only",
  });

  const handleSort = useCallback(
    (columnName: string, sortType: SortType) => {
      if (sortType === UNSORTED) {
        refetch({ order_by: { [columnName]: null } });
      } else {
        refetch({ order_by: { [columnName]: sortType } });
      }
    },
    [refetch]
  );

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Breadcrumb
        items={breadcrumb.enrollmentPeriod.getBreadcrumbsForList(organization)}
      />
      <HStack width="100%" justifyContent="space-between">
        <Heading as="h1" size="xl">
          Enrollment periods
        </Heading>
        <WithUserPermissions permissions={["enrollment_period:create"]}>
          <Button
            as={NavLink}
            to={organization
              .map((org) => Url.OrgAdmin.Enrollments.new(org))
              .withDefault("#")}
            size="xl"
          >
            Create new enrollment period
          </Button>
        </WithUserPermissions>
      </HStack>
      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <EnrollmentsList
            enrollments={data.enrollment_period}
            onSort={handleSort}
          />
        )}
      </GQLRemoteDataView>
    </Flex>
  );
};
