import { gql } from "@apollo/client";

export const BULK_INSERT_GRADE_PROGRAMS = gql`
  mutation BulkInsertGradePrograms($grade_programs: [grade_insert_input!]!) {
    insert_grade(objects: $grade_programs) {
      affected_rows
    }
  }
`;

export const DELETE_GRADE_BY_PK = gql`
  mutation DeleteGradeByPk($id: uuid!) {
    delete_grade_by_pk(id: $id) {
      id
    }
  }
`;

export const BULK_INSERT_GRADES_GROUPS = gql`
  mutation BulkInsertGradesGroups(
    $grades_groups: [grades_group_insert_input!]!
  ) {
    insert_grades_group(objects: $grades_groups) {
      affected_rows
    }
  }
`;

export const DELETE_GRADES_GROUPS = gql`
  mutation DeleteGradesGroups($grades_group_ids: [uuid!]!) {
    delete_grade(where: { grades_group_id: { _in: $grades_group_ids } }) {
      affected_rows
    }
    delete_grades_group(where: { id: { _in: $grades_group_ids } }) {
      affected_rows
    }
  }
`;
