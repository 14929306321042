import { Button, Flex, Heading, Spacer, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import { mapCredentials } from "./functions";
import { UpdateWebhookRequest } from "./types/updateWebhook";
import {
  useWebhookManagementAPI,
  WehookManagementError,
} from "./useWebhookManagementAPI";
import {
  EMPTY_FORM_TYPE,
  EventType,
  WebhookForm,
  WebhookFormType,
} from "./WebhookForm";
import { Loading } from "src/components/Feedback/Loading";

export const EditWebhook = () => {
  const toast = useToast();
  const organization = useOrganization();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const api = useWebhookManagementAPI();
  const { pk } = useParams();
  const [selectedEvents, setSelectedEvents] = React.useState<EventType[]>([]);
  const [initialValues, setInitialValules] =
    React.useState<WebhookFormType>(EMPTY_FORM_TYPE);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!organization.hasData() || !pk) return;

    api
      .getWebhookByPk(organization.data.id, pk)
      .then((response) => {
        if (!response) return;

        const anyCreds = response.credentials as any;

        setInitialValules({
          name: response.name,
          endpoint_url: response.endpoint_url,
          credentials_type: anyCreds?.type,
          api_key: anyCreds?.api_key || "",
          api_key_header: anyCreds?.api_key_header || "",
          basic_username: anyCreds?.basic_username || "",
          basic_password: anyCreds?.basic_password || "",
          bearer_token: anyCreds?.bearer_token || "",
          custom_headers: anyCreds?.custom_headers || [
            { header_key: "", header_value: "'" },
          ],
          is_active: response.is_active,
        });

        setSelectedEvents(
          response.events.map(
            (event): EventType => ({ id: event, label: event })
          )
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.getWebhookByPk, organization.hasData, pk]);

  const handleSubmit = useCallback(
    async (webhook: WebhookFormType) => {
      if (!organization.hasData() || !pk) throw Error("Invalid organization.");

      setSubmitting(true);
      try {
        const organization_id = organization.data.id;

        const request: UpdateWebhookRequest = {
          name: webhook.name,
          endpoint_url: webhook.endpoint_url,
          credentials: mapCredentials(webhook),
          is_active: webhook.is_active,
        };

        const response = await api.updateWebhook(organization_id, pk, request);

        if (!response?.pk) {
          throw new WehookManagementError(
            "Webhook did not have a primary key",
            { request, response }
          );
        }

        await api.subscribeEvents(organization_id, response.pk, {
          event_names: selectedEvents.map((value) => value.id),
        });

        navigate(
          organization
            .map(
              (org) => `${Url.OrgAdmin.DataServices.index(org)}?tab=Webhooks`
            )
            .withDefault("#")
        );

        toast({
          id: "update-webhook",
          title: `Webhook updated`,
          isClosable: true,
          status: "info",
        });
      } catch (err) {
        const description = (): string => {
          console.error(err);
          return "Check your network and try again.";
        };
        toast({
          id: "update-webhook",
          title: `Something went wrong!`,
          description: description(),
          isClosable: true,
          status: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
    [api, navigate, organization, pk, selectedEvents, toast]
  );

  return (
    <>
      <Breadcrumb
        items={breadcrumb.dataServices.getBreadcrumbsForEditWebhook(
          organization,
          pk!
        )}
        mb={8}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Formik<WebhookFormType>
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values }) => {
            return (
              <Flex as={Form} direction="column" gap={6} height="95%">
                <Heading as="h1" fontSize="2xl" fontWeight="600">
                  Edit webhook
                </Heading>
                <WebhookForm
                  onSelectedEvents={setSelectedEvents}
                  selectedEvents={selectedEvents}
                  values={values}
                />
                <Spacer />
                <Flex align="center" gap={6}>
                  <Button
                    as={NavLink}
                    to={organization
                      .map(
                        (org) =>
                          `${Url.OrgAdmin.DataServices.index(org)}?tab=Webhooks`
                      )
                      .withDefault("#")}
                    isLoading={organization.isLoading()}
                    variant="outline"
                    colorScheme="gray"
                  >
                    Back
                  </Button>
                  <Spacer />
                  <Button type="submit" isLoading={submitting}>
                    Update
                  </Button>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
      )}
    </>
  );
};
