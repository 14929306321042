import { ArrowBackIcon } from "@chakra-ui/icons";
import { Flex, Heading, LinkBox } from "@chakra-ui/react";
import { ReactNode, useEffect, useRef } from "react";
import { LinkProps, Link as RouterLink, useLocation } from "react-router-dom";

export type BackLinkProps = {
  url: LinkProps["to"];
  label: string;
  state?: LinkProps["state"];
};

interface ParentFormsLayoutOuterProps {
  backLink?: BackLinkProps;
  children: ReactNode;
  heading: ReactNode;
}

export function ParentFormsLayoutOuter(props: ParentFormsLayoutOuterProps) {
  const { backLink, children, heading, ...containerProps } = props;
  const { pathname, state } = useLocation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (ref.current && pathname) {
      ref.current.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [pathname, ref, state]);

  return (
    <Flex flexDirection="column" overflow="auto" {...containerProps} ref={ref}>
      <Flex
        alignItems="center"
        background="primary.500"
        color="white"
        gap={2}
        padding={3}
      >
        {backLink && (
          <LinkBox
            alignItems="center"
            aria-label={backLink.label}
            as={RouterLink}
            display="flex"
            to={backLink.url}
            state={backLink.state}
          >
            <ArrowBackIcon boxSize={6} />
          </LinkBox>
        )}

        <Heading as="h1" fontSize="lg" fontWeight="700">
          {heading}
        </Heading>
      </Flex>

      {children}
    </Flex>
  );
}
