import * as ls from "src/services/localStorage";

const LOCAL_STORAGE_KEY = "CurrentFormImport";

export type CurrentFormImport = {
  formImportId: uuid;
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;

  /**
   * Whether or not the UI has displayed the successful or unsuccessful
   * conclusion to the current form import.
   */
  finalStatusReported: boolean;
};

export function getCurrentFormImport(): CurrentFormImport | null {
  return ls.getLocalStorageRecord<CurrentFormImport>(LOCAL_STORAGE_KEY);
}

export function saveCurrentFormImport(
  currentFormImport: CurrentFormImport | null
): void {
  return ls.saveLocalStorageRecord<CurrentFormImport>(
    LOCAL_STORAGE_KEY,
    currentFormImport
  );
}
