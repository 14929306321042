import { gql } from "@apollo/client";
import { ORGANIZATION_FRAGMENT } from "src/hooks/useOrganization";
import { BASIC_FORM_TEMPLATE_FRAGMENT } from "src/operations/fragments/formTemplate";

export const GET_FORM_TEMPLATE_BY_ID = gql`
  ${BASIC_FORM_TEMPLATE_FRAGMENT}

  query GetFormTemplateById($form_template_id: uuid!) {
    form_template_by_pk(id: $form_template_id) {
      ...BasicFormTemplateFragment
      closed_at
      enrollment_period {
        name
        organization_id
      }
    }
  }
`;

/**
 * FIXME: https://app.asana.com/0/0/1205940094442035/f
 *
 * Created while FormTemplates lives under Avela Admin.
 * Remove when FormTemplates is moved into Org Admin.
 */
export const GET_FORM_TEMPLATE_ORGANIZATION = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetFormTemplateOrganization($formTemplateId: uuid!) {
    organization(
      where: {
        enrollment_periods: { form_templates: { id: { _eq: $formTemplateId } } }
      }
      limit: 1
    ) {
      ...Organization
    }
  }
`;

export const GET_FORM_VERIFICATIONS = gql`
  query GetFormVerifications($formTemplateId: uuid!) {
    form_verification(where: { form_template_id: { _eq: $formTemplateId } }) {
      id
      label
    }
  }
`;

export const GET_SCHOOLS_FOR_FORM_TEMPLATE_EDIT = gql`
  query GetSchoolsForFormTemplateEdit($formTemplateId: uuid!) {
    school(
      where: {
        grades: {
          enrollment_period: {
            deleted_at: { _is_null: true }
            form_templates: {
              id: { _eq: $formTemplateId }
              deleted_at: { _is_null: true }
            }
          }
        }
      }
    ) {
      id
      name
    }
  }
`;

export const GET_GRADES_CONFIG_BY_ORGANIZATION = gql`
  query GetGradesConfigByOrganization($organizationId: uuid!) {
    grade_config(where: { organization_id: { _eq: $organizationId } }) {
      label
      id
      order
    }
  }
`;

export const GET_QUESTION_TYPES_BY_ORGANIZATION = gql`
  query GetQuestionTypesByOrganization($organizationId: uuid!) {
    custom_question_type(where: { organization_id: { _eq: $organizationId } }) {
      id
      name
      custom_question_type_fields(order_by: { question: { order: asc } }) {
        question_id
        question {
          id
          type
          is_required
          key
          link_text
          link_url
          order
          permission_level
          question
          form_question {
            category
            form_question_options {
              id
              label
              order
              translate_options
              value
            }
          }
        }
      }
    }
  }
`;

export const FORM_STATUS_DESCRIPTION_FRAGMENT = gql`
  fragment FormStatusDescriptionFragment on form_status_description {
    id
    form_template_id
    status
    description
  }
`;

export const FETCH_FORM_TEMPLATE_STATUSES = gql`
  ${FORM_STATUS_DESCRIPTION_FRAGMENT}
  query FetchFormTemplateStatuses($form_template_id: uuid!) {
    form_status_description(
      where: {
        form_template_id: { _eq: $form_template_id }
        status: { _nin: [Deleted, Withdrawn] }
      }
    ) {
      ...FormStatusDescriptionFragment
    }
  }
`;

export const FETCH_FORM_STATUSES_BY_ID_STATUS = gql`
  ${FORM_STATUS_DESCRIPTION_FRAGMENT}
  query FetchFormStatusesByIdStatus(
    $form_template_id: uuid!
    $form_status: [form_status_enum!]
  ) {
    form_status_description(
      where: {
        _and: [
          { form_template_id: { _eq: $form_template_id } }
          { status: { _in: $form_status } }
        ]
      }
    ) {
      ...FormStatusDescriptionFragment
    }
  }
`;

export const GET_FORM_TEMPLATE_SETTINGS_BY_ID = gql`
  query GetFormTemplateSettingsById(
    $form_template_id: uuid!
    $skip_form_template_rule: Boolean!
  ) {
    form_template_by_pk(id: $form_template_id) {
      id
      name
      key
      description
      open_at
      closed_at
      offer_start_at
      lottery_offers_enabled
      reopen_at
      active
      locked
      form_template_rule @skip(if: $skip_form_template_rule) {
        id
        rules {
          id
          type
          status_rule {
            next_form_template_id
            form_status
            offer_status
            type
            waitlist_status
          }
        }
      }
    }
  }
`;
