import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiEditLine } from "react-icons/ri";
import {
  SectionFormDialog,
  SectionFormType,
} from "src/components/Form/SectionForm/SectionFormDialog";
import { useEditFormTemplateContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as GQL from "src/types/graphql";

type AddSectionButtonProps = ButtonProps & {
  section: Draft.Section | Draft.NewSection;
};
export const EditSectionButton: React.FC<AddSectionButtonProps> = ({
  section,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { dispatch, formTemplateId } = useEditFormTemplateContext();

  const handleOnUpdate = useCallback(
    (values: SectionFormType) => {
      if (!dispatch) {
        return;
      }

      if (
        values.type === GQL.form_template_section_type_enum.SchoolRankingSection
      ) {
        const schoolRanking = section as
          | Draft.SchoolRanking
          | Draft.NewSchoolRanking;

        const updatedSchoolRanking:
          | Draft.NewSchoolRanking
          | Draft.SchoolRanking = {
          ...schoolRanking,
          title: values.title,
          description: values.description,
          key: values.key,
          permissionLevel: values.permissionLevel,
          familyEditableStatuses:
            values.familyEditableStatuses?.map((status) => status.id) ?? [],
          minSchools: values.minSchools,
          maxSchools: values.maxSchools,
          exploreUrl: values.exploreUrl,
          rankingEnabled: values.rankingEnabled,
        };
        if (updatedSchoolRanking.isNew) {
          dispatch({
            type: "UpdateNewSchoolRanking",
            schoolRanking: { ...updatedSchoolRanking, isNew: true },
          });
        } else {
          dispatch({
            type: "UpdateSchoolRanking",
            schoolRanking: updatedSchoolRanking,
          });
        }
        if (formTemplateId) {
          dispatch({ type: "Save", formTemplateId });
        }
      } else {
        const updatedSection = {
          ...section,
          title: values.title,
          description: values.description,
          key: values.key,
          permissionLevel: values.permissionLevel,
          familyEditableStatuses:
            values.familyEditableStatuses?.map((status) => status.id) ?? null,
        };
        if (updatedSection.isNew) {
          dispatch({
            type: "UpdateNewSection",
            newSection: { ...updatedSection, isNew: true },
          });
        } else {
          dispatch({ type: "UpdateSection", section: updatedSection });
        }
        if (formTemplateId) {
          dispatch({ type: "Save", formTemplateId });
        }
      }

      onClose();
    },
    [dispatch, formTemplateId, section, onClose]
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<RiEditLine />}
        alignSelf="flex-end"
        onClick={onOpen}
      >
        Edit section
      </Button>

      <SectionFormDialog
        isOpen={isOpen}
        section={section}
        onCreate={handleOnUpdate}
        onCancelButtonClick={onClose}
      />
    </>
  );
};
