import { Button, Flex, Spacer } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { StepProps } from "src/components/Layout/FormStepLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { useFormSteps } from "src/hooks/useFormSteps";
import { WeglotText } from "src/plugins/weglot";
import { SaveStatus as SaveStatusType } from "src/services/formTemplate/question";
import { SaveStatus } from "./SaveStatus";
import { SubmitFormButton } from "./SubmitFormButton";

export type FormButtonsProps = {
  saveStatus?: SaveStatusType;
  overrideNextButton?: {
    label?: string;
    action?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    hide?: boolean;
  };
  overridePreviousButton?: {
    label?: string;
    action?: () => void;
    showIcon?: boolean;
    hide?: boolean;
  };
  hasBeenSubmittedBefore: boolean | null;
} & StepProps;

export const FormButtons = ({
  saveStatus,
  currentStep,
  steps, // 1-base index
  overrideNextButton,
  overridePreviousButton,
  hasBeenSubmittedBefore,
}: FormButtonsProps) => {
  const { onPrevious, onNext } = useFormSteps({
    currentStep,
    steps,
  });

  let nextLabel = overrideNextButton?.label;
  if (nextLabel === undefined) {
    if (currentStep === steps.length) {
      nextLabel = "Submit";
    } else {
      nextLabel = "Next";
    }
  }

  let previousLabel = overridePreviousButton?.label;
  if (previousLabel === undefined) {
    if (currentStep === 1) {
      previousLabel = "Cancel";
    } else {
      previousLabel = "Back";
    }
  }

  let showPreviousIcon = overridePreviousButton?.showIcon;
  if (showPreviousIcon === undefined) {
    showPreviousIcon = currentStep >= 2;
  }

  const overrideNextAction = overrideNextButton?.action;
  const overridePreviousAction = overridePreviousButton?.action;

  return (
    <ParentFormsLayoutFooter alignItems="center">
      <Flex direction="column" width="100%" gap={2}>
        {hasBeenSubmittedBefore && (
          <SubmitFormButton steps={steps} isLoading={saveStatus === "Saving"} />
        )}

        <Flex justifyContent="space-between">
          {!overridePreviousButton?.hide ? (
            <Button
              colorScheme="gray"
              leftIcon={showPreviousIcon ? <FaArrowLeft /> : undefined}
              onClick={() => {
                if (overridePreviousAction) {
                  overridePreviousAction();
                } else {
                  onPrevious();
                }
              }}
              size="md"
              variant="ghost"
            >
              <WeglotText>{previousLabel}</WeglotText>
            </Button>
          ) : null}
          {saveStatus ? (
            <SaveStatus status={saveStatus} notSaving={<Spacer />} />
          ) : (
            <Spacer />
          )}
          {!overrideNextButton?.hide && (
            <Button
              colorScheme="gray"
              isLoading={overrideNextButton?.isLoading}
              type="submit"
              isDisabled={
                (overrideNextButton?.disabled === undefined
                  ? false
                  : overrideNextButton.disabled) || saveStatus === "Saving"
              }
              rightIcon={<FaArrowRight />}
              size="md"
              variant="ghost"
              onClick={() => {
                if (overrideNextAction) {
                  overrideNextAction();
                } else {
                  onNext();
                }
              }}
            >
              <WeglotText>{nextLabel}</WeglotText>
            </Button>
          )}
        </Flex>
      </Flex>
    </ParentFormsLayoutFooter>
  );
};
