import {
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { RiEditLine } from "react-icons/ri";
import { Card } from "src/components/Layout/Card";
import { Glossary } from "src/components/Text/Glossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GradesGroupCard, GradesGroupText } from "./GradesGroupCard";
import {
  BULK_INSERT_GRADES_GROUPS,
  DELETE_GRADES_GROUPS,
} from "./graphql/mutations";
import { GET_GRADES_GROUPS_BY_ENROLLMENT_SCHOOL } from "./graphql/queries";
import {
  GradeProgramWithID,
  GradesGroupLists,
  gradesGroupToGradePrograms,
  gradesGroupToLists,
  listsToGradesGroupInsert,
  OrderedItem,
  orderedItemSortFn,
} from "./helpers";

type Mode = "View" | "Edit";
type GradesGroup = {
  id: string;
  lists: GradesGroupLists;
  gradePrograms: GradeProgramWithID[];
};

interface SchoolGradeProgramsCardProps {
  schoolId: string;
  schoolName: string;
  enrollmentPeriodId: string;
  gradeConfigs: GQL.GetEnrollmentSchoolsAndConfigs_grade_config[];
  programConfigs: GQL.GetEnrollmentSchoolsAndConfigs_program_group_programs[];
}

export const SchoolGradeProgramsCard: React.FC<
  SchoolGradeProgramsCardProps
> = ({
  schoolId,
  schoolName,
  enrollmentPeriodId,
  gradeConfigs,
  programConfigs,
}) => {
  const toast = useToast();
  const [mode, setMode] = useState<Mode>("View");

  const [initialGradesGroups, setInitialGradesGroups] =
    useState<GradesGroup[]>();
  const [gradesGroups, setGradesGroups] = useState<GradesGroupLists[]>([]);
  const [gradeOptions, setGradeOptions] = useState<OrderedItem[]>();

  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.GetGradesGroupsByEnrollmentSchool,
    GQL.GetGradesGroupsByEnrollmentSchoolVariables
  >(GET_GRADES_GROUPS_BY_ENROLLMENT_SCHOOL, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      school_id: schoolId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const groups: GradesGroup[] = data.grades_group.map((gradesGroup) => ({
        id: gradesGroup.id,
        lists: gradesGroupToLists(gradesGroup),
        gradePrograms: gradesGroupToGradePrograms(gradesGroup),
      }));
      setInitialGradesGroups(groups);
      setGradesGroups(
        groups && groups.length > 0
          ? groups.map((group) => group.lists)
          : [{ grades: [], programs: [] }]
      );
      // Needed to enforce grades being in only one grades group
      const excludeGradeOptions = groups.flatMap((group) =>
        group.lists.grades.map((grade) => grade.id)
      );
      setGradeOptions(
        gradeConfigs.filter((grade) => !excludeGradeOptions.includes(grade.id))
      );
    },
  });

  const reset = useCallback(async () => {
    await refetch();
    setMode("View");
  }, [refetch]);

  const [bulkInsertGradesGroups, { remoteData: updateGradesGroups }] =
    useRemoteDataMutation<
      GQL.BulkInsertGradesGroups,
      GQL.BulkInsertGradesGroupsVariables
    >(BULK_INSERT_GRADES_GROUPS);

  const [deleteGradesGroups, { remoteData: deletedGradesGroups }] =
    useRemoteDataMutation<
      GQL.DeleteGradesGroups,
      GQL.DeleteGradesGroupsVariables
    >(DELETE_GRADES_GROUPS);

  const isLoading =
    remoteData.isLoading() ||
    updateGradesGroups.isLoading() ||
    deletedGradesGroups.isLoading();

  const handleSubmit = useCallback(async () => {
    if (gradesGroups.some((gradesGroup) => gradesGroup.grades.length === 0)) {
      toast({
        id: "update-grades-groups-error",
        status: "error",
        title: "Error updating grades groups",
        description: "Grades cannot be empty.",
        position: "bottom-right",
      });
      throw new Error("Grades cannot be empty.");
    }
    if (gradesGroups.some((gradesGroup) => gradesGroup.programs.length === 0)) {
      toast({
        id: "update-grades-groups-error",
        status: "error",
        title: "Error updating grades groups",
        description: "Programs cannot be empty.",
        position: "bottom-right",
      });
      throw new Error("Programs cannot be empty.");
    }

    const formattedNewGradesGroups: GQL.grades_group_insert_input[] =
      gradesGroups.map((gradesGroup) =>
        listsToGradesGroupInsert({
          lists: gradesGroup,
          enrollmentPeriodId,
          schoolId,
        })
      );

    try {
      if (formattedNewGradesGroups.length) {
        await bulkInsertGradesGroups({
          variables: {
            grades_groups: formattedNewGradesGroups,
          },
        });
      }
      if (initialGradesGroups) {
        await deleteGradesGroups({
          variables: {
            grades_group_ids: initialGradesGroups.map(
              (gradesGroup) => gradesGroup.id
            ),
          },
        });
      }

      await reset();
      toast({
        id: "update-grades-groups-success",
        title: "School updated",
        isClosable: true,
        status: "success",
        position: "bottom-right",
      });
    } catch (err) {
      console.error(err);
      toast({
        id: "update-grades-groups-error",
        title: "Error updating grades groups",
        description:
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
      });
    }
  }, [
    bulkInsertGradesGroups,
    deleteGradesGroups,
    enrollmentPeriodId,
    gradesGroups,
    initialGradesGroups,
    reset,
    schoolId,
    toast,
  ]);

  const GradesGroupForms =
    gradeOptions &&
    gradesGroups.map((gradesGroup, index) => (
      <GradesGroupCard
        gradeValues={gradesGroup.grades}
        gradeOptions={gradeOptions}
        onChangeGrades={(grades: OrderedItem[]) => {
          const groups = [...gradesGroups];
          groups.splice(index, 1, {
            grades: grades.sort(orderedItemSortFn),
            programs: gradesGroup.programs,
          });
          setGradesGroups(groups);
          // Enforce grade can only be in one grades group
          setGradeOptions(
            gradeOptions.filter((grade) => !grades.includes(grade))
          );
        }}
        onRemoveGrade={(deletedGrade: OrderedItem) => {
          const groups = [...gradesGroups];
          groups.splice(index, 1, {
            grades: gradesGroup.grades.filter(
              (grade) => grade.id !== deletedGrade.id
            ),
            programs: gradesGroup.programs,
          });
          setGradesGroups(groups);
          // Enforce grade can only be in one grades group
          const gradeOptionToAdd = gradeConfigs.find(
            (grade) => grade.id === deletedGrade.id
          );
          gradeOptionToAdd &&
            setGradeOptions(
              [...gradeOptions, gradeOptionToAdd].sort(orderedItemSortFn)
            );
        }}
        programValues={gradesGroup.programs}
        programOptions={programConfigs}
        onChangePrograms={(programs: OrderedItem[]) => {
          const groups = [...gradesGroups];
          groups.splice(index, 1, {
            grades: gradesGroup.grades,
            programs: programs.sort(orderedItemSortFn),
          });
          setGradesGroups(groups);
        }}
        onDelete={() => {
          const gradeOptionsToAdd = gradesGroup.grades;
          gradeOptionsToAdd &&
            setGradeOptions(
              [...gradeOptions, ...gradeOptionsToAdd].sort(orderedItemSortFn)
            );
          const groups = [...gradesGroups];
          groups.splice(index, 1);
          setGradesGroups(groups);
        }}
        key={index}
      />
    ));

  return (
    <Card
      display="flex"
      flexDirection="column"
      showBorder
      gap={4}
      padding={6}
      width="100%"
    >
      <Flex justifyContent="space-between">
        <Heading fontSize="lg" mb={4}>
          <Text as="span" color="blackAlpha.800">
            {schoolName}{" "}
          </Text>
          <Text as="span" color="gray.500">
            | {schoolId}
          </Text>
        </Heading>
        {mode === "View" && (
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<RiEditLine />}
            onClick={() => setMode("Edit")}
          >
            <Glossary>Edit school</Glossary>
          </Button>
        )}
      </Flex>
      {isLoading ? (
        <Spinner color="primary.500" />
      ) : mode === "View" || !gradeOptions ? (
        <Flex direction="column" gap={2}>
          <Text fontSize="xs" color="gray.800">
            Grades
          </Text>
          {(!initialGradesGroups || initialGradesGroups.length === 0) && (
            <Text as="i" color="blackAlpha.700">
              No grades
            </Text>
          )}
          {initialGradesGroups?.map(({ id, lists }) => (
            <GradesGroupText {...lists} key={id} />
          ))}
        </Flex>
      ) : (
        <>
          {GradesGroupForms}
          <Button
            aria-label="Add grades group"
            width="fit-content"
            colorScheme="gray"
            onClick={() =>
              setGradesGroups([...gradesGroups, { grades: [], programs: [] }])
            }
          >
            Add grades group
          </Button>
          <Flex gap="3" justifyContent="right" paddingY="3">
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit}>
              <Glossary>Update school</Glossary>
            </Button>
          </Flex>
        </>
      )}
    </Card>
  );
};
