import {
  Table as ChakraTable,
  LinkBox,
  LinkOverlay,
  Skeleton,
  StyleProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { flexRender, useReactTable } from "@tanstack/react-table";
import {
  Cell,
  ColumnDef,
  ExpandedState,
  Header,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/table-core";
import { useCallback, useState } from "react";
import { ariaSort } from "src/services/ariaHelpers";
import { Glossary } from "../Text/Glossary";
import { SortButton, SortType, UNSORTED } from "./SortButton";

export interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  count?: number;
  sortableColumnIds?: string[];
  onChangeSort?: (columnName: string, sortType: SortType) => void;
  createRowURL?: (row: Row<T>) => string;
  onClickRow?: (row: Row<T>) => void; // if createRowURL is set, onClickRow will not fire
  isLoading?: boolean;
  banner?: () => JSX.Element;
  hideHeader?: boolean;
  /**
   * defaultSortId & defaultSortType
   * This only affect the style of the column header, you'll need to sort the data source separately
   **/
  defaultSort?: { sortId: string; sortType: SortType };
  getSubRows?: (originalRow: T, index: number) => undefined | T[];
  getRowStyleProps?: (row: Row<T>) => StyleProps;
  getHeaderStyleProps?: (row: Header<T, unknown>) => StyleProps;
  getCellStyleProps?: (cell: Cell<T, unknown>) => StyleProps;
  tablePadding?: string;
  noDataMessage?: string;
  tableHeader?: React.FC<any>;
}

export function Table<T>(props: TableProps<T>) {
  const {
    data,
    columns: [...columns], // Make a mutable copy.
    sortableColumnIds,
    onChangeSort,
    createRowURL,
    onClickRow,
    count = data.length,
    isLoading,
    banner,
    hideHeader,
    getSubRows,
    getRowStyleProps,
    getHeaderStyleProps,
    getCellStyleProps,
    tablePadding,
    noDataMessage = "No data",
    tableHeader,
  } = props;

  const [sortId, setSortId] = useState<string | null>(
    props.defaultSort?.sortId ?? null
  );
  const [sortType, setSortType] = useState<SortType>(
    props.defaultSort?.sortType ?? UNSORTED
  );
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSubRows,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  });

  const handleChangeSort = useCallback(
    (id: string, sortType: SortType) => {
      setSortId(id);
      setSortType(sortType);
      onChangeSort && onChangeSort(id, sortType);
    },
    [onChangeSort]
  );

  const sortable = useCallback(
    (id: string): boolean => {
      return sortableColumnIds?.includes(id) || false;
    },
    [sortableColumnIds]
  );

  const TableHeader = tableHeader;

  return (
    <VStack width="100%" alignItems="left">
      {banner && banner()}
      {TableHeader ? (
        <TableHeader count={count} />
      ) : (
        <Text textTransform="uppercase" fontSize="xs" fontWeight="bold">
          Items: {count}
        </Text>
      )}
      <TableContainer
        width="100%"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="0.8em"
        padding={tablePadding ?? "0.8em"}
      >
        <ChakraTable size="sm" variant="unstyled">
          {!hideHeader && (
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      aria-sort={
                        sortId === header.id ? ariaSort(sortType) : "none"
                      }
                      paddingY={0}
                      paddingX={sortable(header.id) ? 2 : 4}
                      position="relative" // Make contents position relative to the cell.
                      height="5em"
                      width={
                        header.column.columnDef.size
                          ? `${header.column.columnDef.size}px`
                          : undefined
                      }
                      {...(getHeaderStyleProps && getHeaderStyleProps(header))}
                    >
                      {sortable(header.id) ? (
                        <SortButton
                          onChange={handleChangeSort}
                          headerId={header.id}
                          headerLabel={
                            typeof header.column.columnDef.header === "string"
                              ? header.column.columnDef.header
                              : header.id
                          }
                          sort={sortId === header.id ? sortType : UNSORTED}
                        />
                      ) : header.isPlaceholder ? null : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
          )}
          <Tbody>
            {!isLoading &&
              table.getRowModel().rows.map((row, i) => (
                <LinkBox
                  as={Tr}
                  key={row.id}
                  height="60px"
                  _hover={{ backgroundColor: "gray.100" }}
                  {...((!hideHeader || i !== 0) && {
                    borderTop: "1px solid",
                    borderColor: "gray.200",
                  })}
                  {...(onClickRow && {
                    onClick() {
                      // Skip this trigger if it was a text selection action.
                      if (!window.getSelection()?.toString()) onClickRow(row);
                    },
                  })}
                  {...((!!onClickRow || !!createRowURL) && {
                    cursor: "pointer",
                    tabIndex: 0,
                  })}
                  {...(getRowStyleProps && getRowStyleProps(row))}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      position="relative"
                      {...(getRowStyleProps && getRowStyleProps(row))}
                      borderRight={
                        cell.column.id === "program"
                          ? "1px solid #E2E8F0"
                          : undefined
                      }
                      {...(getCellStyleProps && getCellStyleProps(cell))}
                    >
                      {createRowURL ? (
                        <LinkOverlay
                          href={createRowURL ? createRowURL(row) : undefined}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </LinkOverlay>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </Td>
                  ))}
                </LinkBox>
              ))}
            {isLoading &&
              [...Array(10)].map((num, index) => (
                <Tr key={index}>
                  {columns.map((cell, cellIndex) => (
                    <Td key={cellIndex}>
                      <Skeleton height="20px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            {!isLoading && table.getRowModel().rows.length === 0 && (
              <Tr key="no-data-text" fontSize="sm" p={2}>
                <Td>
                  <Glossary>{noDataMessage}</Glossary>
                </Td>
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </VStack>
  );
}
