import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  createQuestion,
  FormType,
  getAdditionalQuestionFormValue,
  removeQuestionFormValues,
} from "src/components/Form/QuestionForm/formik";
import { SchoolItem } from "src/components/Form/QuestionForm/types";
import { Card } from "src/components/Layout/Card";
import { CheckboxCard } from "src/components/Layout/CheckboxCard";
import { AddQuestionButton } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/components/AddQuestionButton";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as List from "src/services/list";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import { AdditionalQuestion } from "./AdditionalQuestion";

type AdditionalQuestionsCheckboxCardProps = {
  sectionType: AF.SectionType;
  option: Draft.Option;
  schools: RD.RemoteData<unknown, SchoolItem[]>;
  verificationOptions: AF.FormVerification<AF.WithId>[];
};
export const AdditionalQuestionsCheckboxCard: React.FC<
  AdditionalQuestionsCheckboxCardProps
> = ({ sectionType, option, schools, verificationOptions }) => {
  const form = useFormikContext<FormType>();
  const additionalQuestion = getAdditionalQuestionFormValue(
    option.id,
    form.values
  );

  const hasAdditionalQuestions = additionalQuestion.type === "enabled";

  const addNewAdditionalQuestion = React.useCallback(
    (index: number) => {
      form.setTouched({
        additionalQuestions: {
          [option.id]: {
            questionIds: true,
          },
        },
      });
      const newAdditionalQuestionId = crypto.randomUUID();
      form.setValues(
        (values) => ({
          ...values,
          additionalQuestions: {
            ...values.additionalQuestions,
            [option.id]: {
              type: "enabled",
              questionIds: List.insertAt(
                additionalQuestion.questionIds ?? [],
                newAdditionalQuestionId,
                index
              ),
            },
          },
          questionTitles: {
            ...values.questionTitles,
            [newAdditionalQuestionId]: "",
          },
          validations: {
            ...values.validations,
            [newAdditionalQuestionId]: { options: "" },
          },
        }),
        true
      );
    },
    [additionalQuestion.questionIds, form, option.id]
  );

  const onCheckHandler = React.useCallback(
    (isChecked: boolean) => {
      form.setTouched({ additionalQuestions: { [option.id]: { type: true } } });
      form.setValues((values) => {
        const updatedValues: FormType = {
          ...values,
          additionalQuestions: {
            ...values.additionalQuestions,
            [option.id]: isChecked
              ? {
                  type: "enabled",
                  questionIds: additionalQuestion.questionIds,
                }
              : {
                  type: "disabled",
                  questionIds: [],
                },
          },
        };

        if (isChecked) {
          return updatedValues;
        }

        return removeQuestionFormValues(
          updatedValues,
          ...additionalQuestion.questionIds
        );
      });
      if (isChecked && (additionalQuestion.questionIds.length ?? 0) === 0) {
        addNewAdditionalQuestion(0);
      }
    },
    [addNewAdditionalQuestion, additionalQuestion.questionIds, form, option.id]
  );

  const formik = useFormikContext<FormType>();
  const touched = form.touched.additionalQuestions?.[option.id]?.questionIds;
  const error = form.errors.additionalQuestions?.[option.id]?.questionIds;
  const hasExistingAdditionalQuestions =
    option.additionalQuestions?.find((question) => !Draft.isNew(question)) !==
    undefined;

  return (
    <CheckboxCard
      label="Ask for additional information if this answer is selected"
      isChecked={hasAdditionalQuestions}
      onCheck={onCheckHandler}
      checkboxProps={{
        isDisabled: hasExistingAdditionalQuestions,
      }}
    >
      {hasAdditionalQuestions && (
        <Flex direction="column" gap="3">
          <Flex direction="column">
            <AddQuestionButton
              onClick={() => {
                addNewAdditionalQuestion(0);
              }}
              variant="outline"
            >
              Add question
            </AddQuestionButton>
            <FormControl
              isInvalid={touched && error === "string"}
              justifyContent="right"
              display="flex"
            >
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          </Flex>
          {additionalQuestion.questionIds &&
            additionalQuestion.questionIds.map(
              (additionalQuestionId, questionIndex) => {
                const original: AF.Question<AF.WithId> | undefined =
                  option.additionalQuestions?.find(
                    (q) => q.id === additionalQuestionId
                  );
                const draft: Draft.Question | Draft.NewQuestion = original
                  ? Draft.fromOriginalQuestion(original)
                  : Draft.asNew(
                      Draft.fromOriginalQuestion(
                        createQuestion(
                          additionalQuestionId,
                          formik.values,
                          verificationOptions
                        )
                      )
                    );

                return (
                  <Flex direction="column" gap="2" key={additionalQuestionId}>
                    <Card bg="white" showBorder useFlex>
                      <AdditionalQuestion
                        sectionType={sectionType}
                        optionId={option.id}
                        questionId={additionalQuestionId}
                        isNew={Draft.isNew(draft)}
                        schools={schools}
                        verificationOptions={verificationOptions}
                      />
                    </Card>
                    <AddQuestionButton
                      onClick={() => {
                        addNewAdditionalQuestion(questionIndex + 1);
                      }}
                      variant="outline"
                    >
                      Add
                    </AddQuestionButton>
                  </Flex>
                );
              }
            )}
        </Flex>
      )}
    </CheckboxCard>
  );
};
