import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { FormMustacheInput } from "src/components/Inputs/FormMustacheInput";
import { GENERIC_ERROR, MESSAGE_TEMPLATES } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { SmsInputStats } from "src/scenes/orgAdmin/components/Forms/MessageForm";
import { FormTemplateOutletContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/Edit";
import {
  hasChanges,
  MessageTemplateId,
  toOriginal
} from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/common";
import { validateWithZod } from "src/services/formValidations";
import { MessageTemplate } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { UPDATE_MESSAGE_TEMPLATE } from "../graphql/mutations";
import {
  GET_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATE_TYPES
} from "../graphql/queries";
import { PreviewMessageTemplate } from "./components/PreviewMessageTemplate";
import { FormSchema, FormType } from "./components/types";
import { VARIABLES } from "./constants";
import { useEditFormTemplateEditMessagesContext } from "./context";
import { EditMessageTemplateActionBar } from "./EditMessageTemplateActionBar";

const FORM_ID = "edit-form-templates-edit-message-template";

interface Props {
  isLoading: boolean;
  messageEnabled?: boolean;
}

export const EditMessageTemplateForm: FunctionComponent<Props> = ({
  isLoading,
  messageEnabled
}) => {
  const organization = useOrganization();
  const {
    formTemplateId = "",
    messageTemplateType = "",
    enrollmentPeriodId = "",
    isViewOnly = ""
  } = useParams();
  const [isPreview, setIsPreview] = useState(isViewOnly === "true");
  const { actionBarRef } = useOutletContext<FormTemplateOutletContext>();
  const { state, dispatch } = useEditFormTemplateEditMessagesContext();

  const templateType = messageTemplateType as GQL.message_template_type_enum;

  const [updateMessageTemplate] = useRemoteDataMutation<
    GQL.UpdateMessageTemplate,
    GQL.UpdateMessageTemplateVariables
  >(UPDATE_MESSAGE_TEMPLATE);

  const toast = useAvelaToast();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values: FormType) => {
      if (isPreview) {
        try {
          await updateMessageTemplate({
            variables: {
              objects: {
                form_template_id: formTemplateId,
                type: templateType,
                email_subject: values.emailSubject,
                email_plain_text: values.emailText,
                email_markup: values.emailMarkup,
                sms_body: values.smsBody,
                enabled: messageEnabled ?? false
              }
            },
            refetchQueries: [GET_MESSAGE_TEMPLATE, GET_MESSAGE_TEMPLATE_TYPES]
          });

          if (dispatch) {
            dispatch({
              type: "Refresh",
              initialValues: { editMessages: values },
              messageTemplateType: templateType
            });
          }

          toast({
            title: "Template updated",
            status: "success",
            containerStyle: {
              marginBottom: "6rem"
            }
          });

          // doing this after 250ms to allow dispatcher to work to Refresh the state
          setTimeout(
            () =>
              navigate(
                organization
                  .map((org) =>
                    MessageTemplate.index(
                      org,
                      enrollmentPeriodId,
                      formTemplateId
                    )
                  )
                  .withDefault("#")
              ),
            250
          );
        } catch (err: unknown) {
          console.error(err);
          toast.error(GENERIC_ERROR);
        }
      } else {
        setIsPreview(true);
      }
    },
    [
      isPreview,
      navigate,
      formTemplateId,
      templateType,
      toast,
      updateMessageTemplate,
      organization,
      enrollmentPeriodId,
      messageEnabled,
      dispatch
    ]
  );

  const handleValidate = useCallback(
    async (values: FormType) => {
      if (dispatch) {
        dispatch({
          type: "UpdateEditMessages",
          editMessages: {
            isDraft: true,
            ...values
          },
          messageTemplateType: templateType
        });

        dispatch({
          type: "Save",
          messageTemplateType: templateType
        });
      }

      return await validateWithZod(FormSchema)(values);
    },
    [templateType, dispatch]
  );

  const draft = useMemo(
    () =>
      state?.get("editMessages")?.get(MessageTemplateId(templateType))?.draft,
    [templateType, state]
  );

  const original = useMemo(
    () =>
      state?.get("editMessages")?.get(MessageTemplateId(templateType))
        ?.original,
    [templateType, state]
  );

  const formikInitialValues = useMemo(() => {
    if (draft) {
      return toOriginal(draft);
    } else {
      return original;
    }
  }, [draft, original]);

  const hasChangesResult = hasChanges(original, draft);

  if (!formikInitialValues) return <GenericError />;

  if (!formTemplateId || !templateType) {
    return <GenericError />;
  }

  return (
    <Formik<FormType>
      initialValues={formikInitialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validate={handleValidate}
    >
      {({ values, resetForm, errors }) => {
        const handleResetForm = () => {
          resetForm();

          if (dispatch) {
            dispatch({
              type: "Reset",
              messageTemplateType: templateType
            });
          }
        };

        return (
          <Flex
            as={Form}
            id={FORM_ID}
            gap={4}
            minHeight="100%"
            direction="column"
          >
            <Heading as="h2" fontSize="xl" my={4} fontWeight={600}>
              {isPreview ? "Preview " : "Edit "}
              {MESSAGE_TEMPLATES[templateType].label}
            </Heading>
            <Flex direction="column" gap={4}>
              <Heading as="h3" fontSize="md" fontWeight={400}>
                Email message
              </Heading>
              {isPreview ? (
                <PreviewMessageTemplate formValue={values} />
              ) : (
                <Stack gap="5" flexGrow="1">
                  {isLoading ? (
                    <Stack gap="5">
                      <Skeleton height="8" />
                      <Grid
                        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
                        gap="5"
                        templateRows="auto"
                      >
                        <GridItem>
                          <Skeleton height="32" />
                        </GridItem>
                        <GridItem>
                          <Skeleton height="32" />
                        </GridItem>
                      </Grid>
                      <Skeleton height="8" />
                    </Stack>
                  ) : (
                    <>
                      <FormMustacheInput<FormType>
                        id="emailMarkup"
                        name="emailSubject"
                        label="Subject line"
                      />

                      <Grid
                        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
                        gap="5"
                        templateRows="auto"
                        flexGrow="0"
                      >
                        <GridItem>
                          <FormMustacheInput<FormType>
                            id="emailMarkup"
                            name="emailMarkup"
                            label="HTML"
                            mustacheProps={{
                              language: "html",
                              containerProps: { height: "20rem" }
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <FormMustacheInput<FormType>
                            name="emailText"
                            label="Plain text"
                            mustacheProps={{
                              containerProps: { height: "20rem" }
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Flex direction="column" gap={4}>
                        <Heading as="h3" fontSize="md" fontWeight={400}>
                          SMS message
                        </Heading>
                        <FormMustacheInput<FormType> name="smsBody" />
                        {!errors.smsBody && (
                          <SmsInputStats value={values.smsBody} />
                        )}
                      </Flex>
                    </>
                  )}
                  <Flex gap={4} direction="column">
                    <Heading as="h3" fontSize="md" fontWeight={400}>
                      Variables
                    </Heading>
                    <Box fontSize="sm" __css={{ columnWidth: "30rem" }}>
                      {Object.keys(VARIABLES).map((key) => (
                        <HStack key={key}>
                          <Text>{VARIABLES[key]}: </Text>
                          <pre>{`{{${key}}}`}</pre>
                        </HStack>
                      ))}
                    </Box>
                  </Flex>
                </Stack>
              )}
            </Flex>
            <EditMessageTemplateActionBar
              actionBarRef={actionBarRef}
              formId={FORM_ID}
              isPreview={isPreview}
              isViewOnly={isViewOnly === "true"}
              setIsPreview={setIsPreview}
              formTemplateId={formTemplateId}
              hasChanges={hasChangesResult}
              onClearChanges={handleResetForm}
            />
          </Flex>
        );
      }}
    </Formik>
  );
};
