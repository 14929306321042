import _, { Many } from "lodash";

/**
 * Apply filter and map with single iteration to improve performance.
 * It will filter out any null or undefined of the return value of the mapper function.
 */
export function filterMap<T, V>(
  list: readonly T[],
  mapper: (from: T) => V | null | undefined
): V[] {
  // Do this imperatively for performance
  const newList: V[] = [];
  for (const item of list) {
    const newItem = mapper(item);
    if (newItem) {
      newList.push(newItem);
    }
  }
  return newList;
}

export function orderByIgnoreCase<T>(
  list: readonly T[],
  orderBy: (datum: T) => string,
  orders?: Many<boolean | "asc" | "desc">
): T[] {
  return _.orderBy(
    list,
    (datum) => orderBy(datum).toLocaleLowerCase(),
    (orders = "asc")
  );
}

export function insertAt<T>(list: readonly T[], item: T, index: number) {
  return [...list.slice(0, index), item, ...list.slice(index)];
}

export function append<T>(list: readonly T[], item: T) {
  return [...list, item];
}
