import * as GQL from "src/types/graphql";

type FormSchoolRank = {
  form: { status: GQL.form_status_enum };
  waitlists: { status: GQL.waitlist_status_enum }[];
};

type FormSchool = {
  school: { id: uuid } | null;
};

export function canRemoveFromWaitlists(
  formSchoolRanks: FormSchoolRank[],
  formSchools: FormSchool[]
): boolean {
  if (formSchools.some((appSchool) => !appSchool.school)) {
    return false;
  }

  return formSchoolRanks.every((rank) => {
    if (GQL.form_status_enum.Admissions !== rank.form.status) return false;

    if (rank.waitlists[0]?.status !== GQL.waitlist_status_enum.Waitlisted)
      return false;

    return true;
  });
}
