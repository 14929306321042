import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { AttendanceTypes, SearchAndFilterTypes } from "src/constants";
import * as GQL from "src/types/graphql";
import { useSchoolAdmin } from "./useSchoolAdmin";
import { useSchoolAdminPermissions } from "./useSchoolAdminPermissions";

type UseFormTemplatePermissions = {
  pruneFormTemplate<T extends GQL.FormTemplateFragment>(
    formTemplate: T,
    attendanceContext?: AttendanceTypes | string | null
  ): T;
};

export function useFormTemplatePermissions(): UseFormTemplatePermissions {
  const { isElevatedSchoolAdmin } = useSchoolAdmin();
  const { hasSectionAccess, hasQuestionAccess } = useSchoolAdminPermissions();
  const [searchParams] = useSearchParams();
  const attendanceContextFromParams = searchParams.get(
    SearchAndFilterTypes.Attendance
  );

  const pruneFormTemplateCallback = useCallback(
    <T extends GQL.FormTemplateFragment>(
      formTemplate: T,
      attendanceContext: string | null = attendanceContextFromParams
    ) =>
      isElevatedSchoolAdmin
        ? pruneFormTemplate(formTemplate, hasSectionAccess, (q) =>
            hasQuestionAccess(q, attendanceContext)
          )
        : formTemplate,
    [
      isElevatedSchoolAdmin,
      hasSectionAccess,
      hasQuestionAccess,
      attendanceContextFromParams,
    ]
  );

  return {
    pruneFormTemplate: pruneFormTemplateCallback,
  };
}

function pruneFormTemplate<T extends GQL.FormTemplateFragment>(
  formTemplate: T,
  hasSectionAccess: (q: GQL.FormTemplateSectionFragment) => boolean,
  hasQuestionAccess: (q: GQL.FormQuestionWithoutBranchingFragment) => boolean
): T {
  return {
    ...formTemplate,
    sections: formTemplate.sections.filter(hasSectionAccess).map((section) => {
      return {
        ...section,
        questions: section.questions.filter(hasQuestionAccess).map((q) => {
          return {
            ...q,
            form_question: q.form_question && {
              ...q.form_question,
              form_question_options: q.form_question.form_question_options.map(
                (option) => ({
                  ...option,
                  additional_questions: option.additional_questions.filter(
                    (aq) => hasQuestionAccess(aq.question)
                  ),
                })
              ),
            },
            grades_question: q.grades_question && {
              ...q.grades_question,
              grades_additional_questions:
                q.grades_question.grades_additional_questions.filter((gaq) =>
                  hasQuestionAccess(gaq.question)
                ),
            },
            custom_question: q.custom_question && {
              ...q.custom_question,
              custom_question_relationships:
                q.custom_question.custom_question_relationships.filter((cqr) =>
                  hasQuestionAccess(cqr.cloned_question)
                ),
            },
          };
        }),
      };
    }),
  };
}
