import _ from "lodash";
import * as AFF from "src/services/formTemplateFilters";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { toBaseQuestion } from "../../question";
import { sortByOrder } from "../../sorter";
import { toQuestionWithoutBranching } from "../question";

export function toGradesQuestion(
  value: GQL.QuestionFragment
): AF.Grades<AF.WithId> {
  if (value.type !== GQL.question_type_enum.Grades) {
    throw new Error(
      `Invalid question type, unable to convert ${value.type} into a grades question.`
    );
  }

  if (value.grades_question === null) {
    throw new Error(
      `Invalid data for grades question type, grades_question is null.`
    );
  }

  const grades_question: GQL.QuestionFragment_grades_question =
    value.grades_question;

  const grades: GQL.GradeFragment_grade_config[] = grades_question.grades
    .map((g) => g.grade_config)
    .filter((g): g is GQL.GradeFragment_grade_config => g !== null);

  const options: AF.Option<AF.WithId>[] = _.sortBy(grades, sortByOrder).map(
    (gradeConfig: GQL.GradeFragment_grade_config) => {
      const additionalQuestions: AF.Question<AF.WithId>[] = _.sortBy(
        grades_question.grades_additional_questions.filter((q) =>
          isNotNull(q.question)
        ),
        (q) => q.question.order
      )
        .filter((gaq) => gaq.grade_config_id === gradeConfig.id)
        .map((q) => toQuestionWithoutBranching(q.question))
        .filter(isNotNull);
      return {
        id: gradeConfig.id,
        label: gradeConfig.label,
        additionalQuestions,
      };
    }
  );

  return {
    ...toBaseQuestion(value),
    type: AF.GradesType,
    options,
    filters: AFF.parseFilters(value.grades_question?.filters),
  };
}
