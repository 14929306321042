import { Button, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { HasuraRole } from "src/types/hasuraRole";
import * as GQL from "src/types/graphql";
import { ManageTagGroupDialog } from "./ManageTagGroupDialog";

type EditTagGroupProps = {
  tagGroup: GQL.GetTagGroupsByEnrollmentPeriod_tag_group;
  error?: string;
  onEditTagGroupSaving?: (
    id: uuid,
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onDeleteTagGroupSaving?: (id: uuid, onSuccess: () => void) => Promise<void>;
  onCloseManageTagGroupDialog?: () => void;
};
export const EditTagGroup: React.FC<EditTagGroupProps> = ({
  tagGroup,
  error,
  onEditTagGroupSaving,
  onDeleteTagGroupSaving,
  onCloseManageTagGroupDialog,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCancelClick = useCallback(() => {
    if (onCloseManageTagGroupDialog) onCloseManageTagGroupDialog();
    onClose();
  }, [onClose, onCloseManageTagGroupDialog]);

  return (
    <>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}>
        <WithUserPermissions permissions={["tag_group:update"]}>
          <Button
            variant="outline"
            size="xs"
            colorScheme="gray"
            onClick={onOpen}
          >
            Edit
          </Button>
        </WithUserPermissions>
      </WithRequiredHasuraRoles>

      <ManageTagGroupDialog
        tagGroup={tagGroup}
        isOpen={isOpen}
        error={error}
        onClose={onClose}
        onEditTagGroupSaving={onEditTagGroupSaving}
        onCancelButtonClick={handleCancelClick}
        onDeleteTagGroupSaving={onDeleteTagGroupSaving}
      />
    </>
  );
};
