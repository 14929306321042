import * as GQL from "src/types/graphql";

export const LATE_EDIT_STATUSES: GQL.form_status_enum[] = [
  GQL.form_status_enum.LotteryReady,
  GQL.form_status_enum.Verified,
  GQL.form_status_enum.Admissions,
  GQL.form_status_enum.Cancelled,
];

export const isFamilyEditableStatus = (
  sections: Array<{ family_editable_statuses?: GQL.form_status_enum[] }>,
  status: GQL.form_status_enum
): boolean => {
  return sections.some(
    (section) =>
      Array.isArray(section.family_editable_statuses) &&
      section.family_editable_statuses.includes(status)
  );
};
