import * as OrgConfig from "@avela/organization-config-sdk";
import { useEnv } from "src/services/env";
import { useOrganizationPath } from "./useOrganizationPath";
import * as RD from "src/types/remoteData";

export function useOrgConfig<T extends OrgConfig.Type>(type: T) {
  const env = useEnv();
  const org = useOrganizationPath();

  const remoteConfig = OrgConfig.useOrgConfig<T>(
    { CONFIG_URL: env.REACT_APP_CONFIG_URL },
    org ?? "",
    type
  );

  return RD.fromRemoteConfig(remoteConfig);
}
