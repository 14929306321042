import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import React from "react";

type CustomInputProps = Omit<InputProps, "display">;

/**
 * This component provides the ability to specify an input component with custom
 * content. This is implemented by adding a hidden input element adjacent to the
 * custom content---all input props are passed to this element, and click events
 * are synthetically transferred.
 *
 * Note: This has currently only been tested with file input; additional
 * tweaking may be required to support other types of input.
 */
export const CustomInput: React.FC<CustomInputProps> = ({
  children,
  onClick,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <InputGroup onClick={() => inputRef.current?.click()}>
      <Input
        ref={inputRef}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick(e);
        }}
        display="none"
        {...props}
      />
      {children}
    </InputGroup>
  );
};
