import { useApolloClient } from "@apollo/client";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { ClientStorageTypes, SearchAndFilterTypes } from "src/constants";
import { useOrganization } from "src/hooks/useOrganization";
import { GET_ENROLLMENT_PERIODS } from "src/scenes/orgAdmin/enrollmentPeriods/graphql/queries";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";

interface EnrollmentPeriod {
  id: uuid;
  name: string;
}
interface State {
  enrollmentPeriods: RD.RemoteData<unknown, EnrollmentPeriod[]>;
}

interface EnrollmentPeriodContextType extends State {
  selectedEnrollmentPeriod: EnrollmentPeriod | undefined;
  setSelectedEnrollmentPeriodId: (enrollmentPeriodId: uuid) => void;
  fetchEnrollmentPeriods: (organizationId: uuid) => Promise<void>;
}

const EnrollmentPeriodContext =
  React.createContext<EnrollmentPeriodContextType>({
    enrollmentPeriods: RD.notAsked(),
    selectedEnrollmentPeriod: undefined,
    setSelectedEnrollmentPeriodId: () => {},
    fetchEnrollmentPeriods: async () => {},
  });

interface EnrollmentPeriodProviderProp {
  children: React.ReactNode;
}

const initialState: State = {
  enrollmentPeriods: RD.notAsked(),
};

function useSelectedEnrollmentPeriod(
  enrollmentPeriods: RD.RemoteData<unknown, EnrollmentPeriod[]>
) {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (!enrollmentPeriods.hasData()) {
      return;
    }

    const urlEnrollmentPeriodId = searchParams.get(
      SearchAndFilterTypes.EnrollmentPeriod
    );

    const localEnrollmentPeriodId = localStorage.getItem(
      ClientStorageTypes.EnrollmentPeriod
    );

    let enrollmentPeriodId = urlEnrollmentPeriodId || localEnrollmentPeriodId;

    // Initial setup of local storage
    if (!localEnrollmentPeriodId) {
      localStorage.setItem(
        ClientStorageTypes.EnrollmentPeriod,
        enrollmentPeriodId || enrollmentPeriods.data[0]?.id || ""
      );
    }

    if (
      !enrollmentPeriodId ||
      !enrollmentPeriods.data.some((period) => period.id === enrollmentPeriodId)
    ) {
      /*
       * When no valid enrollment period id is persisted, default to the first
       * enrollment period.
       */
      enrollmentPeriodId = enrollmentPeriods.data[0]?.id ?? "";
    }

    if (
      enrollmentPeriodId &&
      searchParams.get(SearchAndFilterTypes.EnrollmentPeriod) !==
        enrollmentPeriodId
    ) {
      /*
       * When an enrollment period is available to select, and its id is not
       * already set in the search params, update the search params.
       */
      searchParams.set(
        SearchAndFilterTypes.EnrollmentPeriod,
        enrollmentPeriodId
      );
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, enrollmentPeriods, setSearchParams]);

  const selectedEnrollmentPeriod = React.useMemo(() => {
    return enrollmentPeriods
      .map((list) =>
        list.find(
          (enrollmentPeriod) =>
            enrollmentPeriod.id ===
            searchParams.get(SearchAndFilterTypes.EnrollmentPeriod)
        )
      )
      .withDefault(undefined);
  }, [enrollmentPeriods, searchParams]);

  const setSelectedEnrollmentPeriodId = (enrollmentPeriodId: string) => {
    localStorage.setItem(
      ClientStorageTypes.EnrollmentPeriod,
      enrollmentPeriodId
    );
    searchParams.set(SearchAndFilterTypes.EnrollmentPeriod, enrollmentPeriodId);
    setSearchParams(searchParams);
  };

  return {
    selectedEnrollmentPeriod,
    setSelectedEnrollmentPeriodId,
  };
}

export function EnrollmentPeriodProvider(props: EnrollmentPeriodProviderProp) {
  const { children } = props;
  const [state, setState] = React.useState(initialState);
  const organization = useOrganization();
  const client = useApolloClient();

  const { selectedEnrollmentPeriod, setSelectedEnrollmentPeriodId } =
    useSelectedEnrollmentPeriod(state.enrollmentPeriods);

  async function fetchEnrollmentPeriods(organizationId: uuid) {
    setState({ enrollmentPeriods: RD.loading() });
    const remoteData = RD.fromApolloResult(
      await client.query<GQL.GetEnrollments, GQL.GetEnrollmentsVariables>({
        query: GET_ENROLLMENT_PERIODS,
        variables: {
          organization_id: organizationId,
          order_by: { created_at: GQL.order_by.asc },
        },
      })
    );

    setState({
      enrollmentPeriods: remoteData.map((data) => data.enrollment_period),
    });
  }

  React.useEffect(() => {
    if (organization.hasData() && state.enrollmentPeriods.isNotAsked()) {
      setState({ enrollmentPeriods: RD.loading() });
      fetchEnrollmentPeriods(organization.data.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return (
    <EnrollmentPeriodContext.Provider
      value={{
        ...state,
        selectedEnrollmentPeriod,
        setSelectedEnrollmentPeriodId,
        fetchEnrollmentPeriods,
      }}
    >
      {children}
    </EnrollmentPeriodContext.Provider>
  );
}

export function useEnrollmentPeriod() {
  const context = React.useContext(EnrollmentPeriodContext);
  if (context === undefined) {
    throw new Error(
      "useEnrollmentPeriod must be used within a EnrollmentPeriodContext"
    );
  }

  return context;
}
