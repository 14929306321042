import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Collapse,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { VerificationOptions } from "../graphql/utils";

type Props = {
  options: VerificationOptions[];
  onChange: (verificationFilters: VerificationFilters) => void;
  isLoading?: boolean;
  initialValues: VerificationFilters;
};

export type VerificationStatus = "All" | "Pending" | "Verified" | "Rejected";

export interface VerificationFilters {
  status: VerificationStatus;
  verificationIds: string[];
}

export const initialVerificationFilters: VerificationFilters = {
  status: "All",
  verificationIds: [],
};

const statusList: VerificationStatus[] = ["Pending", "Verified", "Rejected"];

export const FormFiltersVerifications = ({
  options,
  onChange,
  isLoading,
  initialValues,
}: Props) => {
  const [verificationFilters, setVerificationFilters] = useState(initialValues);

  useEffect(() => {
    setVerificationFilters(initialValues);
  }, [initialValues]);

  const handleStatusChange = (value: string) => {
    if (value === verificationFilters.status) return;

    updateVerificationFilters({
      status: value as VerificationStatus,
      verificationIds: [],
    });
  };

  const handleVerificationChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = target;
    const { status, verificationIds } = verificationFilters;

    const foundIdx = verificationIds.findIndex((id) => id === value);

    if (checked && foundIdx === -1) {
      updateVerificationFilters({
        status: status,
        verificationIds: [...verificationIds, value],
      });
    } else if (!checked && foundIdx > -1) {
      updateVerificationFilters({
        status: status,
        verificationIds: verificationIds.filter((id) => id !== value),
      });
    }
  };

  const updateVerificationFilters = (
    verificationFilters: VerificationFilters
  ) => {
    setVerificationFilters(verificationFilters);
    onChange(verificationFilters);
  };

  const isChecked = (status: string, verificationId: string) => {
    return (
      verificationFilters.status === status &&
      verificationFilters.verificationIds.findIndex(
        (id) => id === verificationId
      ) > -1
    );
  };

  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <AccordionButton paddingX="0">
          <Text variant="filterLabel" flex="1" textAlign="left">
            Verifications
          </Text>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p="0" mt={4}>
          <RadioGroup
            value={verificationFilters.status}
            onChange={handleStatusChange}
          >
            <VStack align="stretch">
              <Radio value="All">All</Radio>
              {statusList.map((status) => (
                <div key={status}>
                  <Radio value={status}>{status}</Radio>
                  <Collapse in={verificationFilters.status === status}>
                    <VStack align="stretch" paddingX={4}>
                      {options.map((verification) => (
                        <Checkbox
                          key={verification.id}
                          value={verification.id}
                          onChange={handleVerificationChange}
                          isChecked={isChecked(status, verification.id)}
                        >
                          {verification.label}
                        </Checkbox>
                      ))}
                    </VStack>
                  </Collapse>
                </div>
              ))}
            </VStack>
          </RadioGroup>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
