import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Teams & members",
      href: organization
        .map((org) => Url.OrgAdmin.Team.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNewTeam = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create team",
      href: organization
        .map((org) => Url.OrgAdmin.Team.newTeam(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEditTeam = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  id: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Edit team",
      href: organization
        .map((org) => Url.OrgAdmin.Team.editTeam(org, id))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForNewMember = (
  organization: RD.RemoteData<unknown, GQL.Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Create Team Member",
      href: organization
        .map((org) => Url.OrgAdmin.Team.newMember(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForEditMember = (
  organization: RD.RemoteData<unknown, GQL.Organization>,
  id: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Edit Team Member",
      href: organization
        .map((org) => Url.OrgAdmin.Team.editMember(org, id))
        .withDefault("#"),
    },
  ];
};
