import { FunctionComponent, useState } from "react";
import { AddressValidationResult } from "src/components/Boundary/services";
import { EnterAddress } from "./EnterAddress";
import { PickSuggestedAddress } from "./PickSuggestedAddress";
import { ResolveInvalidAddress } from "./ResolveInvalidAddress";

type ValidateNewAddressOrchestratorProps = {
  addressIsRequired?: boolean;
};

/**
 * Determines which phase of address validation to display.
 *
 * 1) Base case: displays a (further) nested AddressForm that collects address information.
 * 2) Saving the entered address invokes validation via GoogleMaps API and can result in three different states:
 *  a) The address validation succeeds with no issue and saved.
 *  b) The address validation partially succeeds, display a suggested address and prompts user input.
 *  c) The address validation fails and prompts user input.
 *
 * Based on Google's recommended practices:
 * https://developers.google.com/maps/documentation/address-validation/overview
 */
export const ValidateNewAddressOrchestrator: FunctionComponent<
  ValidateNewAddressOrchestratorProps
> = (props) => {
  const { addressIsRequired } = props;

  const [addressValidationResult, setAddressValidationResult] = useState<
    AddressValidationResult | undefined
  >();

  const addressValidationNotDispatched = !addressValidationResult;
  if (addressValidationNotDispatched) {
    return (
      <EnterAddress
        addressFieldsAreRequired={addressIsRequired}
        setAddressValidationResult={setAddressValidationResult}
      />
    );
  }
  const { wasFound, isValid, originalAddress, suggestedAddress } =
    addressValidationResult;
  const partiallyValidAddressEntered = wasFound && !isValid && suggestedAddress;
  const invalidAddressEntered = !wasFound && !isValid;

  return (
    <>
      {partiallyValidAddressEntered && (
        <PickSuggestedAddress
          originalAddress={originalAddress}
          suggestedAddress={suggestedAddress}
        />
      )}
      {invalidAddressEntered && (
        <ResolveInvalidAddress
          setAddressValidationResult={setAddressValidationResult}
        />
      )}
    </>
  );
};
