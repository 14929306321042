import { Icon } from "@chakra-ui/icons";
import { IconButton, useToast } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useMemo } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { SortType } from "src/components/Table/SortButton";
import { Table } from "src/components/Table/Table";
import { useDeleteConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { DELETE_PRIORITY_TEMPLATES } from "./graphql/mutations";

interface Props {
  priorityTemplates: GQL.FetchPriorityTemplates_priority_template[];
  onSort: (columnName: string, sortType: SortType) => void;
  onRefetch: () => Promise<any>;
}

export const PriorityTemplateList: React.FC<Props> = ({
  priorityTemplates,
  onSort,
  onRefetch,
}) => {
  const toast = useToast();
  const [deletePriorityTemplates] = useRemoteDataMutation<
    GQL.DeletePriorityTemplates,
    GQL.DeletePriorityTemplatesVariables
  >(DELETE_PRIORITY_TEMPLATES);

  const { confirm, confirmationDialog } = useDeleteConfirmationDialog({});

  const handleDelete = useCallback(
    async (priorityTemplateId: uuid) => {
      if (!(await confirm())) return;
      try {
        await deletePriorityTemplates({
          variables: {
            priority_template_ids: [priorityTemplateId],
          },
        });
        toast({
          title: "Priority template deleted",
          status: "info",
          isClosable: true,
        });
        onRefetch();
      } catch (error) {
        console.error(error);
        toast({
          title: "Error deleting priority template",
          status: "error",
          isClosable: true,
        });
      }
    },
    [confirm, deletePriorityTemplates, toast, onRefetch]
  );

  const columns: ColumnDef<GQL.FetchPriorityTemplates_priority_template>[] =
    useMemo(
      () => [
        {
          id: "organization",
          cell: (props) =>
            props.row.original.enrollment_period.organization?.name,
        },
        {
          id: "enrollment period",
          cell: (props) => props.row.original.enrollment_period.name,
        },
        {
          id: "template",
          cell: (props) => props.row.original.name,
        },
        {
          id: "template ID",
          cell: (props) => props.row.original.id,
        },
        {
          id: "Actions",
          cell: (props) => {
            const id: string = props.row.original.id;
            return (
              <>
                {/* <IconButton
                  as={NavLink}
                  to={Url.Admin.PriorityTemplates.edit(id)}
                  aria-label={`Edit ${id}`}
                  icon={<Icon as={RiPencilLine} />}
                  variant="outline"
                />    */}
                <IconButton
                  onClick={() => handleDelete(id)}
                  aria-label={`Delete ${id}`}
                  icon={<Icon as={RiDeleteBinLine} />}
                  variant="outline"
                />
              </>
            );
          },
        },
      ],
      [handleDelete]
    );

  if (priorityTemplates.length === 0)
    return <span>No priority templates yet.</span>;

  return (
    <>
      <Table<GQL.FetchPriorityTemplates_priority_template>
        data={priorityTemplates}
        columns={columns}
        sortableColumnIds={["enrollment period", "organization", "template"]}
        onChangeSort={onSort}
      />
      {confirmationDialog}
    </>
  );
};
