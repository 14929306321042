import React from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { Address } from "./Address";
import { Name } from "./Name";

export const New: React.FC = () => {
  const { step = "1" } = useParams();
  switch (parseInt(step)) {
    case 1:
      return <Name />;

    case 2:
      return <Address />;

    default:
      return <NotFound />;
  }
};
