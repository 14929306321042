import React from "react";
import * as RD from "src/types/remoteData";
import { Table, TableProps } from "./Table";

type Props<T> = {
  data: RD.RemoteData<unknown, T[]>;
} & Omit<TableProps<T>, "data" | "isLoading">;
export function RemoteDataTable<T>({ data, ...props }: Props<T>) {
  return (
    <Table<T>
      data={data.hasData() ? data.data : []}
      isLoading={data.isLoading()}
      {...props}
    />
  );
}
