import { Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  text: string;
};

export const DisclaimerText: React.FC<Props> = ({ text }) => (
  <Text
    as="pre"
    fontSize="sm"
    backgroundColor="blackAlpha.50"
    height="350px"
    borderRadius="8px"
    padding={4}
    overflowX="auto"
    whiteSpace="break-spaces"
    mb={6}
  >
    {text}
  </Text>
);
