import { useCallback, useState } from "react";

interface UseFilePickerOptions {
  accept?: string;
}

export function useFilePicker(options: UseFilePickerOptions) {
  const { accept } = options;

  const [files, setFiles] = useState<File[]>([]);

  const openFilePicker = useCallback(() => {
    const input: HTMLInputElement = document.createElement("input");
    input.type = "file";

    if (accept) {
      input.accept = accept;
    }

    input.onchange = () => {
      setFiles(Array.from(input.files ?? []));
    };

    input.click();
  }, [accept]);

  return { files, openFilePicker };
}
