import { gql } from "@apollo/client";

// export const FETCH_PRIORITY_TEMPLATES_BY_GRADE = gql`
//   query FetchPriorityTemplatesByGrade(
//     $order_by: grade_priority_template_order_by!
//   ) {
//     grade_priority_template(order_by: [$order_by]) {
//       grade {
//         enrollment_period {
//           name
//           organization {
//             name
//           }
//         }
//         school {
//           name
//         }
//       }
//       priority_template {
//         id
//         name
//       }
//     }
//   }
// `;

export const GET_ENROLLMENT_PERIOD_ENUMS = gql`
  query GetEnrollmentPeriodEnums {
    enrollment_period(where: { deleted_at: { _is_null: true } }) {
      id
      enrollment_period_tags {
        id
        name
      }
      grades {
        school_id
      }
    }
  }
`;

export const FETCH_PRIORITY_TEMPLATES = gql`
  query FetchPriorityTemplates($order_by: priority_template_order_by!) {
    priority_template(order_by: [$order_by]) {
      id
      name
      enrollment_period {
        name
        organization {
          name
        }
      }
    }
  }
`;

// export const GET_PRIORITY_TEMPLATE_BY_ID = gql`
//   ${BASIC_FORM_TEMPLATE_FRAGMENT}

//   query GetPriorityTemplateById($form_template_id: uuid!) {
//     form_template_by_pk(id: $form_template_id) {
//       ...BasicFormTemplateFragment
//       enrollment_period {
//         name
//       }
//     }
//   }
// `;
