import type { FlowStatus } from "./constants";
import type { CurrentFormImport } from "./localStorage";

export enum FormImportFlowActionType {
  CURRENT_FORM_IMPORT_UPDATE = "CURRENT_FORM_IMPORT_UPDATE",
  FILE_UPLOAD_STATUS_UPDATE = "FILE_UPLOAD_STATUS_UPDATE",
  FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS",
}

export type CurrentFormImportUpdate = {
  type: FormImportFlowActionType.CURRENT_FORM_IMPORT_UPDATE;
  value: CurrentFormImport | null;
};

export type ImportFileUploadStatusUpdate = {
  type: FormImportFlowActionType.FILE_UPLOAD_STATUS_UPDATE;
  value: FlowStatus;
};

export type ImportFileUploadStatusSuccess = {
  type: FormImportFlowActionType.FILE_UPLOAD_SUCCESS;
  value: {
    formImportId: uuid;
    enrollmentPeriodId: uuid;
    formTemplateId: uuid;
    formTemplateName: string;
  };
};

export type FormImportFlowAction =
  | CurrentFormImportUpdate
  | ImportFileUploadStatusUpdate
  | ImportFileUploadStatusSuccess;
