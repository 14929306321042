import { useMemo } from "react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { formatQueryResponseAsCustomQuestionProps } from "src/services/formTemplate/customQuestion";
import {
  GetCustomQuestion,
  GetCustomQuestionVariables,
} from "src/types/graphql";
import { GET_CUSTOM_QUESTION } from "./graphql/queries";

export function useGetCustomQuestionQuery(
  variables: GetCustomQuestionVariables
) {
  const { remoteData } = useRemoteDataQuery<
    GetCustomQuestion,
    GetCustomQuestionVariables
  >(GET_CUSTOM_QUESTION, {
    variables,
  });

  const formattedRemoteData = useMemo(() => {
    return remoteData.map((data) => {
      const { question_by_pk } = data;
      if (!question_by_pk || !question_by_pk.custom_question) {
        return null;
      }

      return {
        customQuestion:
          formatQueryResponseAsCustomQuestionProps(question_by_pk),
        customQuestionType: question_by_pk.custom_question.custom_question_type,
      };
    });
  }, [remoteData]);

  return formattedRemoteData;
}
