import type { AvelaToast } from "src/hooks/useAvelaToast";

export function showInvalidImportToast(toast: AvelaToast) {
  toast.error({
    description: "Check your network and try again.",
    isClosable: true,
    status: "error",
    title: "Something went wrong!",
  });
}
