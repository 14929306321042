import { Box, Flex, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";
import { MultiSelectInput } from "../MultiSelectInput";
import { BaseInputProps, Option } from "./Question";

export interface MultiSelectProps extends BaseInputProps {
  options: Option[];
}

type MultiSelectOption = Omit<Option, "key"> & { id: string };

const toMultiSelectOption = (option: Option) => {
  const { key, ...rest } = option;
  return {
    ...rest,
    id: key,
  };
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  options,
  isDisabled,
}) => {
  const [field, meta, helpers] = useField<string[]>(id);
  const multiSelectOptions = options.map(toMultiSelectOption);
  const initialValues = multiSelectOptions.filter((o) =>
    field.value?.includes(o.id)
  );

  const onChangeHandler = (values: MultiSelectOption[]) => {
    // wrapping this on setTimeout to avoid weird bug where this trigger max update stack on react component.
    setTimeout(() => {
      helpers.setValue(
        values.map((o) => o.id),
        true
      );
      helpers.setTouched(true, false);
    }, 0);
  };

  return (
    <Flex
      direction="column"
      onBlur={() => {
        helpers.setTouched(true);
      }}
    >
      <MultiSelectInput<MultiSelectOption>
        size="md"
        options={multiSelectOptions}
        initialValues={initialValues}
        onChange={onChangeHandler}
        isDisabled={isDisabled}
        renderDropdownItem={(item) => {
          return (
            <Box
              className={
                item.translate ? WEGLOT_APPLY_CLASS : WEGLOT_SKIP_CLASS
              }
            >
              {item.label}
            </Box>
          );
        }}
      />
      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </Flex>
  );
};
