import { Button, Flex, Heading, HStack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Link as NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { SortType, UNSORTED } from "src/components/Table/SortButton";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as breadcrumb from "src/services/breadcrumb";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { BulkUploadSchools } from "./BulkUploadSchools";
import { GET_SCHOOLS } from "./graphql/queries";
import { SchoolsList } from "./List";

export function Schools() {
  const organization = useOrganization();
  const [pagination, setPagination] = useState({ limit: 25, offset: 0 });
  const { remoteData, refetch, fetchMore } = useRemoteDataQuery<
    GQL.GetSchools,
    GQL.GetSchoolsVariables
  >(GET_SCHOOLS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      limit: pagination.limit,
      offset: pagination.offset,
      order_by: [{ name: GQL.order_by.asc }],
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
  });

  const handleSort = useCallback(
    (columnName: string, sortType: SortType) => {
      if (sortType === UNSORTED) {
        refetch({ order_by: [{ [columnName]: null }] });
      } else {
        refetch({ order_by: [{ [columnName]: sortType }] });
      }
    },
    [refetch]
  );

  const handleChange = () => {
    refetch();
  };

  const onFetchMore = (limit: number, offset: number) => {
    fetchMore({
      variables: {
        limit: limit,
        offset: offset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult as GQL.GetSchools;
      },
    });

    setPagination({ limit, offset });
  };

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => (
        <Flex direction="column" alignItems="flex-start" rowGap={6}>
          <Breadcrumb
            items={breadcrumb.school.getBreadcrumbsForList(organization)}
          />
          <HStack width="100%" justifyContent="space-between">
            <Heading as="h1">
              <Glossary>Schools</Glossary>
            </Heading>
            <HStack>
              <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
                <BulkUploadSchools onComplete={handleChange} />
              </WithRequiredHasuraRoles>
              <WithUserPermissions permissions={["school:create"]}>
                <Button
                  as={NavLink}
                  to={organization
                    .map((org) => Url.OrgAdmin.Schools.new(org))
                    .withDefault("#")}
                  size="xl"
                >
                  <Glossary>Create school</Glossary>
                </Button>
              </WithUserPermissions>
            </HStack>
          </HStack>
          <SchoolsList
            schools={data.school}
            onChange={handleChange}
            limit={pagination.limit}
            offset={pagination.offset}
            count={data.school_aggregate.totals?.total ?? 0}
            onFetchMore={onFetchMore}
            onSort={handleSort}
          />
        </Flex>
      )}
    </GQLRemoteDataView>
  );
}
