import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as GQL from "src/types/graphql";

export const Mixed = "Mixed";
export type CompositeStatus = GQL.verification_status_enum | typeof Mixed;

const tooltipLockedText =
  "This information has been verified and can no longer be edited by a parent/guardian";

type VerificationInputProps = {
  label: string;
  showMixed?: boolean;
  status: CompositeStatus;
  onStatusChange: (status: CompositeStatus) => void;
  additionalInfo?: string;
};
// TODO: Combine this implementation with the one in QuestionList?
export const VerificationInput: React.FC<VerificationInputProps> = ({
  label,
  showMixed,
  status,
  onStatusChange,
  additionalInfo,
}) => {
  const userPermissions = useUserPermissions();
  const toggleStatusHandler = (targetStatus: CompositeStatus) => () =>
    onStatusChange(
      status === targetStatus
        ? GQL.verification_status_enum.Pending
        : targetStatus
    );

  return (
    <Flex
      direction="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      minW="30rem"
      maxW="45rem"
      width="75%"
      p={4}
      gap={4}
      alignItems="flex-start"
    >
      <Flex gap={2}>
        <Tag
          size="sm"
          variant="solid"
          color="blackAlpha.900"
          bg="gray.200"
          lineHeight={1.3}
        >
          {label} verification
        </Tag>
        <Box
          visibility={
            status !== GQL.verification_status_enum.Verified
              ? "hidden"
              : undefined
          }
          bgColor="gray.500"
          borderRadius="2"
          paddingLeft={1}
          paddingRight={1}
          fontSize="sm"
          fontWeight="700"
          textColor="white"
        >
          <Tooltip
            label={<Text align="center">{tooltipLockedText}</Text>}
            placement="top"
            hasArrow
            arrowSize={15}
            gutter={20}
            minW="22rem"
          >
            LOCKED
          </Tooltip>
        </Box>
      </Flex>
      <VStack alignItems="start" w="100%">
        <Flex w="100%" gap={1}>
          <Text lineHeight="2">
            Is the {label.toLocaleLowerCase()} information correct?
          </Text>
          <Spacer width={6} />
          <HStack>
            {showMixed && (
              <Button
                size="sm"
                colorScheme="gray"
                color="gray.700"
                variant={status === Mixed ? "solid" : "ghost"}
                _disabled={{}}
                isDisabled={!userPermissions.hasOne("form:update")}
                onClick={toggleStatusHandler(Mixed)}
              >
                Mixed
              </Button>
            )}
            <Button
              size="sm"
              leftIcon={<RiCloseLine />}
              colorScheme="gray"
              color="gray.700"
              variant={
                status === GQL.verification_status_enum.Rejected
                  ? "solid"
                  : "ghost"
              }
              _disabled={{}}
              isDisabled={!userPermissions.hasOne("form:update")}
              onClick={toggleStatusHandler(
                GQL.verification_status_enum.Rejected
              )}
            >
              No
            </Button>
            <Button
              size="sm"
              leftIcon={<RiCheckLine />}
              colorScheme="gray"
              color="gray.700"
              variant={
                status === GQL.verification_status_enum.Verified
                  ? "solid"
                  : "ghost"
              }
              _disabled={{}}
              isDisabled={!userPermissions.hasOne("form:update")}
              onClick={toggleStatusHandler(
                GQL.verification_status_enum.Verified
              )}
            >
              Yes
            </Button>
          </HStack>
        </Flex>
        {additionalInfo && (
          <Text color="blackAlpha.700" size="xs">
            {additionalInfo}
          </Text>
        )}
      </VStack>
    </Flex>
  );
};
