import { InputControl } from "formik-chakra-ui";
import React from "react";
import { PhoneInput } from "../PhoneInput";
import { BaseInputProps } from "./Question";

export const PhoneNumberInput: React.FC<BaseInputProps> = ({
  id,
  isDisabled,
}) => {
  return (
    <InputControl
      id={id}
      name={id}
      inputProps={{ as: PhoneInput }}
      isDisabled={isDisabled}
    />
  );
};
