import * as GQL from "src/types/graphql";
import { OrganizationFormSchema } from "../Form";
export interface LanguageMultiSelect {
  id: string;
  name: string;
}

export const transformLanguageToMultiSelect = (
  languageCodes: string[],
  getLanguageName: (code: string) => string
): LanguageMultiSelect[] => {
  return languageCodes.map((languageCode) => {
    return { id: languageCode, name: getLanguageName(languageCode) };
  });
};

export const transformMultiSelectToLanguage = (
  languages: LanguageMultiSelect[]
): string[] => {
  return languages.map((language) => language.id);
};

/* October 2024, 
  we only have NO_STUDENT condition
  that belongs on the PARENT_PORTAL entry_point
  with the INFO type announcement 
  but we can have more in the future
*/
export const toGQLNoStudentAnnouncement = (
  values: OrganizationFormSchema,
  orgId: uuid
): GQL.announcement_insert_input => ({
  organization_id: orgId,
  title: values.noStudentAnnouncement.title,
  description: values.noStudentAnnouncement.description,
  active: values.noStudentAnnouncement.active,
  type: GQL.announcement_type_enum.INFO,
  entry_point: GQL.announcement_entry_point_enum.PARENT_PORTAL,
  condition: GQL.announcement_condition_enum.NO_STUDENT,
  display_type: values.noStudentAnnouncement
    .showType as GQL.announcement_display_type_enum,
  link_text: values.noStudentAnnouncement.linkText,
  link_url: values.noStudentAnnouncement.linkUrl,
});
